// 预览新闻
import React, { Component } from 'react'
import './index.scss';
import { Form, Select,Button,Row ,Col,Radio,Input } from 'antd';
import {selectNewsById} from "../../../../api/information";
import {files} from '../../../../api/zixun';
import saveAs from 'file-saver';
import { getSearch } from '@/until/libs';
import S from 'gongyongicon'
const { Option } = Select;

class LookNews extends Component {
    state = {
        form:{},
        newsLabel:[],
        fileList:[]
    }

    componentDidMount() {
        let param = getSearch(this.props.location.search);
        if(param.newsId != ''){
            selectNewsById(param.newsId).then(e => {
                this.setState(
                    {
                        form:e,
                        newsLabel:(!e || e.newsLabel=== '')?[]:eval('(' + e.newsLabel + ')')
                    });
            })
            files({id: param.newsId}).then(e => {
                if (e) {
                    this.setState({
                        fileList: e
                    })
                }
            })
        }else{
            this.setState(
                {
                    form:JSON.parse(localStorage.getItem('previewNews')),
                    newsLabel:JSON.parse(localStorage.getItem('previewNews')).newsLabel?JSON.parse(localStorage.getItem('previewNews')).newsLabel:[],
                    fileList:JSON.parse(localStorage.getItem('previewNews')).fileList?JSON.parse(localStorage.getItem('previewNews')).fileList:[]
                }
            )
        }
    }

    down = (item) => {
        saveAs(window.$$imgSrc(item.fileId), item.fileName)
    }

    render() {
        let {form,fileList} = this.state;
        return (
            <div className='look_news_box'>
                <div className='top_title'>
                    <div className='return_wapper'>
                        <img src={require('@/assets/img/zixun/return.png')}/>
                        <span onClick={() => this.props.history.goBack()}>返回</span>
                    </div>
                    <span  className='newsTitle'>资讯详情</span>
                </div>
                <div className="breadCrumb">
                    <span>首页></span>
                    <span>深度资讯></span>
                    <span>查看详情</span>
                </div>
                <div className='main_box'>
                    <div className='news_title'>
                        {form.newsTitle}
                    </div>
                    <div className='bq_box'>
                        { this.state.newsLabel?this.state.newsLabel.map(tag => (
                            <span className='tab_box'>{tag}</span>
                        )):''}
                        <span>{form.releaseTime}</span>
                        { form.articleSource?
                            <span>来源：{form.articleSource}</span>:''}
                        <span style={{float: 'right'}}>作者：{form.authorName}</span>
                    </div>
                    <div className="newsContent" dangerouslySetInnerHTML={{__html:  form.newsContent}}/>
                    {
                        fileList.length>0?<div className='enclosureCon'>
                            <div className='enclosureTitle'><span>附件</span></div>
                            <div className='fileList'>
                                {
                                    fileList.map((item)=>{
                                        return(
                                            <div className='fileItem'>
                                                <span>{item.fileSuffix==="doc" || item.fileSuffix==='docx'?<S.WordSvg/>:item.fileSuffix==="txt"?<S.ArichText/>:<S.Pdf/>}</span>
                                                <span>{item.fileName}</span>
                                                <span className='downIcon' onClick={()=>this.down(item)}><S.DownIcon/>&nbsp;&nbsp;下载</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>:''
                    }
                </div>
            </div>
        )
    }
}


LookNews = Form.create()(LookNews);
export default LookNews;
