import React, { Component } from 'react';
import {  Button,Input, Table, Modal, message } from 'antd';
import './index.scss';
import { selectVirtualOrg, appenter, downTemplate, importVirtualOrg} from '@/api/platform-business/index.js'
import Batch from '@/components/import';
import moment from "moment";
import UploadImg from '../../../../components/enterprise_upload_img/index.js';
import FormAlike from "../../../../components/FormAlike";

export default class index extends Component {
    state = {
        total:0,
        pageNum: 1,
        pageSize: 10,
        schoolRunMode: undefined,   //办学方式
        schoolRunLevel: undefined,   //办学层次
        schoolRunType: undefined,    //办学类型
        searchVal: '',
        dataSource: [],
        fangshiSel: [],
        cengciSel: [],
        typeSel: [],
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        columns: [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {
                title: 'logo',
                dataIndex: 'enterpriseLogo',
                key: 'enterpriseLogo',
                align: 'center',
                render: (text, item, index) => {
                    if (item.enterpriseLogo !== null){
                        //不为空展示logo
                        return <div className='not_opens'>
                            <div className="img_top">
                                <img  src={window.$$uploadRc(item.enterpriseLogo)} alt="" />
                            </div>
                        </div>
                    }else{
                        return <div className='not_opens'>
                            <div className="img_top">
                                <img src={require('@/assets/img/not_open.png')} alt="" />
                            </div>
                        </div>
                    }
                }
            },
            {title: '虚拟机构名称', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'center',},
            {title: '机构识别码', dataIndex: 'enterpriseCode', key: 'enterpriseCode', align: 'center',},
            {title: '所属范围', dataIndex: 'manageRangeName', key: 'useRange', align: 'center', render: (text, item) => item.manageRange === '0'? '全国':item.manageRangeName.join(",")},
            {title: '加入日期', dataIndex: 'settledDate', key: 'settledDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '到期日期', dataIndex: 'settledEndDate', key: 'settledEndDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '状态', align: 'center', render: (text, item, index) =>  <span className='serial'>试用({item.onTrialNum})  正式({item.formalNum})</span>,},
            {
                title: '操作', align: 'center', fixed: 'right',
                width: 260,
                render: (text, record) => {
                    const {RenderButton} = React;
                   return <p className='action vertical_j'>
                        {/*<span onClick={() => this.props.history.push(`/transResource/temporaryProject/${record.resourceId}`)}>查看</span>*/}
                        <div>
                            <RenderButton id='OBS-OM-01-03-a_btnDetail'>
                            <span onClick={() => this.lookDetil(record)}>进入详情</span>
                            </RenderButton>
                            <RenderButton id='OBS-OM-01-03-a_btnEdit'>
                            <span onClick={() => this.edit(record)}>编辑信息</span>
                            </RenderButton>
                            <RenderButton id='OBS-OM-01-03-a_btnConfig'>
                            <span onClick={() => this.isApplication(record)}>配置应用</span>
                            </RenderButton>
                        </div>
                    </p>
                }
            }
        ],
        batch: false,
        errorMessage: '',
        btnActive: false,
        fileName: '',
        isApplication: false, // 应用接入弹层
        selectedTags: ['Books'], // 接入应用选择数据
        isSchool: false, // 院系管理弹层
        oftenFlag: true,
        is_input: true, // 院系管理是否可编辑
        userInfo: JSON.parse(localStorage.getItem("userInfo"))
    }
    componentDidMount() {
        this.getVirtualOrgList();
    }
    //获取列表
    getVirtualOrgList = () => {
        let params = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            enterpriseName: this.state.searchVal
        }
        selectVirtualOrg(params).then(res => {
            if (res) {
                this.setState({
                    total: res.total,
                    dataSource: res.data
                })
            }
        })
    }
    //打开应用接入弹窗
    isApplication = item => {
        this.setState({ isApplication: true, enterpriseId: item.id})
        sessionStorage.setItem('enterpriseId', item.id);
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.getVirtualOrgList());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.getVirtualOrgList());
        window.elGoTop && window.elGoTop('body');
    }
    //检索
    search = (e) => {
      this.setState({ searchVal: e }, () => this.getVirtualOrgList());
    }
    //新增
    add = () => {
        sessionStorage.setItem("school_type", 'add');
        sessionStorage.setItem('enterpriseId', '');
        this.props.history.push('/main/platform-business/add-enterprise');
    }
    //编辑
    edit = item => {
        sessionStorage.setItem('enterpriseId', item.id);
        sessionStorage.setItem("school_type", 'edit');
        this.props.history.push('/main/platform-business/add-enterprise');
    }
    //查看详情
    lookDetil = item => {
        sessionStorage.setItem('enterpriseId', item.id);
        // sessionStorage.setItem("school_type", 'edit');
        this.props.history.push('/main/platform-business/detil-enterprise');
    }
    selectedTags = (e) => {
        this.setState({ selectedTags: e });
    }
    //应用接入
    save = () => {
        console.log('appDelay', this)
        this.appForm.props.form.validateFields((err, values) => {
            if (!err) {
                let { oftenFlag,selectedTags,enterpriseId,userInfo } = this.state;
                if (selectedTags.length === 0) {
                    return this.mesWarning('请选择应用');
                }
                if (oftenFlag) {
                    this.setState({ oftenFlag: false });
                    let list = [];
                    selectedTags.forEach(function(item,index){
                        let app = {accessType: values.accessType,startDate: values.startDate,endDate: values.endDate,id: item.id};
                        list.push(app)
                    });
                    appenter(
                      {
                          enterpriseId: enterpriseId,
                          list: list,
                          createUserId: userInfo.id,
                          updateUserId: userInfo.id,
                      }
                    ).then(res => {
                        if (res) {
                            this.mesSuccess('配置成功');
                            this.getVirtualOrgList();
                        }
                    })
                    setTimeout(() => this.setState({ oftenFlag: true, isApplication: false }), 2500)
                }
            }
        })
    }
    getFormData=(formData)=>{
        console.log("1111111111111",formData.get("file"))
        formData.set("id","")
        this.setState({
            batchRef: formData
        },()=>{
            console.log("batchRef",this.state.batchRef)
        })
    }
    sure = (e) => {
        this.setState({ btnActive: true, fileId: e });
    }
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    saveBatch = () => { // 管理机构信息导入
        let fileValue = this.state.batchRef;
        console.log("file",fileValue)
        importVirtualOrg(this.state.batchRef).then(res => {
            if (res) {
                this.mesSuccess('导入成功');
                this.getVirtualOrgList()
            }
        }).catch(err => {
            console.log(err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    render() {
        let { total, columns, dataSource, searchVal, pageSize, batch, btnActive, errorMessage,isApplication } = this.state;
        const {RenderButton} = React;
        return (
            <div className='in_school_box enterprise_settlement'>
                <div className="in_school_content">
                    <div className='top'>
                        <div className="left">
                            <RenderButton id='OBS-OM-01-03-a_btnLoad'>
                            <Button className='add_btn_j' onClick={() => this.setState({ batch: true })} style={{ marginRight: '20px' }} >导入虚拟机构</Button>
                            </RenderButton>
                            <RenderButton id='OBS-OM-01-03-a_btnAdd'>
                            <Button onClick={this.add} className='add_btn_j no_color' >创建虚拟机构</Button>
                            </RenderButton>
                        </div>
                        <div className="right">
                            <Input.Search className="lcl_left" value={searchVal} onChange={e => this.setState({ searchVal: e.target.value })} onSearch={this.search} placeholder='请输入机构名称' />
                        </div>
                    </div>
                    <div className="bottom">
                        <Table rowKey={v => v['enterpriseId']} pagination={
                            {
                                total,
                                pageSize:pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['5', '10', '20', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns} dataSource={dataSource} />
                    </div>
                </div>
                <Modal className='batch' visible={batch} title='虚拟机构信息导入'
                       closable={false}
                       footer={
                           <div style={{ width: "100%" }}>
                               <div className='pos-btn'>
                                   <Button onClick={this.closeBatch}>取消</Button>
                                   <Button disabled={!btnActive} className={btnActive ? "" : "gray"}
                                           onClick={this.saveBatch}>确定</Button>
                               </div>
                               {errorMessage ?
                                   <div className='error'>
                                       <span>请下载查看</span>
                                       <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                   </div>
                                   : null}
                           </div>
                       }
                >
                    <Batch
                        ref={ref => this.batchRef = ref}
                        getFormData={this.getFormData}
                        sure={this.sure}
                        reset={() => this.setState({ errorMessage: '', btnActive: false })}
                        template={downTemplate(5)}
                        pullError={(val) => this.setState(val)}
                        titleType='虚拟机构'
                    />
                </Modal>
                {/*应用接入弹层*/}
                <Modal
                    title='应用接入'
                    visible={isApplication}
                    destroyOnClose={true}
                    closable={false}
                    className='configuration_modals_gljg'
                    width={660}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({ isApplication: false })}>取消</Button>
                            <Button onClick={()=>{this.save()}}>确认添加</Button>
                        </div>
                    }
                >
                    <FormAlike wrappedComponentRef={form => {this.appForm = form}} selectedTags={this.selectedTags} formType='yyjr'/>
                </Modal>
            </div>
        )
    }
}
