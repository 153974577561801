import React, { Component } from 'react'

export default class Resourcemg extends Component {
    render() {
        return (
            <div>
                Resource-mgResource-mgResource-mgResource-mg
            </div>
        )
    }
}
