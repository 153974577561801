/*已发布*/
import React, { Component } from 'react';
import {Form, Select, Button, Input, Table, Spin, Checkbox, message,Popover} from 'antd';
import './index.scss';
import {selectNews, getPlatformList, selectNewsParentCategory,selectNewsTwoCategory,releaseNews,deleteNewsToRecycle,topNews,checkTopNum} from "../../../../api/information";
import RenderButton from "../../../../components/renderButton";
const { Option } = Select;

class Published extends Component {
    state = {
        total:0,
        pageNum: 1,
        pageSize: 10,
        columns: [
            {title: '新闻标题', dataIndex: 'newsTitle', key: 'newsTitle', width:180,align: 'center',fixed: 'left', 
                render: (text, record) => {
                    return (
                        <div>
                            {
                                <Popover placement="bottom" content={(<p style={{maxWidth:"300px",wordBreak: 'break-all'}}>  {record.newsTitle}</p>)} title="">
                                    <div className="content-name">
                                        {record.newsTitle}
                                    </div>    
                                </Popover> 
                            }
                        </div>
                    )
                }
            },
            {title: '一级栏目', dataIndex: 'oneCategoryName', key: 'oneCategoryName',width:180, align: 'center',fixed: 'left',render: (text,record) =>{
                    if(!text){
                        return '————'
                    }else{
                        return text
                    }
                }},
            {title: '二级栏目', dataIndex: 'twoCategoryName', key: 'twoCategoryName',width:180, align: 'center',fixed: 'left',render: (text,record) =>{
                    if(!text){
                        return '————'
                    }else{
                        return text
                    }
                }},
            {title: '所属业务系统', dataIndex: 'platformName', key: 'platformName', align: 'center',},
            {title: <div>发布时间</div>, defaultSortOrder:"descend",width:250, dataIndex: 'releaseTime', key: 'releaseTime', align: 'center', sorter:true},
            {title: '发布终端', dataIndex: 'releaseTerminal',key: 'releaseTerminal', align: 'center',render: (text,record) =>{
                    if(record.releaseTerminal === 1){
                        return 'WEB'
                    }else if(record.releaseTerminal === 2){
                        return 'APP'
                    }else{
                        return 'WEB/APP'
                    }
                }},
            {title: '发布状态', dataIndex: 'releaseStatus', key: 'releaseStatus', align: 'center',render: (text,record) =>{
                    if(record.releaseStatus === 3){
                        return '已发布'
                    }else{
                        return '未发布'}
                }},
            {title: '置顶', dataIndex: 'isTop', key: 'isTop', align: 'center',render: (text,record) =>{
                    if(record.isTop === 0){
                        return '否'
                    }else{
                        return '是'}
                }},
            {
                title: '操作',  align: 'center',
                width: 220,
                render: (text, record) => {
                    return <p className='action vertical_j'>
                        <div>
                            {record.releaseStatus == 2 ? <RenderButton id='NC_01_B03_c_btnrelease'><span onClick={()=> this.showFbCon(record)}>&emsp;&emsp;发布</span></RenderButton>
                                :<RenderButton id='NC_01_B03_c_btnCanceltherelease'><span onClick={()=> this.showFbCon(record)}>取消发布</span></RenderButton>
                            }
                            {record.isTop == 0 ? <RenderButton id='NC_01_B03_c_btnrelease'><span onClick={()=> this.showTopCon(record)}>置顶</span></RenderButton>
                                :<RenderButton id='NC_01_B03_c_btnCanceltherelease'><span onClick={()=> this.showTopCon(record)}>取消置顶</span></RenderButton>
                            }
                            
                        </div>
                        <div>
                            <RenderButton id='NC_01_B03_c_btneditor'><span onClick={()=>this.addNews(record)}>编辑</span></RenderButton>
                            <RenderButton id='NC_01_B03_c_btndelete'><span className='long-title' onClick={()=>this.showDeleteConfirm(record)}>删除</span></RenderButton>
                            <RenderButton id='NC_01_B03_c_btnlook'><span onClick={()=> this.props.history.push(`/main/group-column/lookNews?${decodeURI(JSON.stringify({ newsId: record.id }))}`)}>查看</span></RenderButton>
                        </div>
                    </p>
                }
            }
        ],
        dataSource: [],
        tableLoading: false, // 是否显示加载中
        selectedRowKeys: [],// 选择的新闻id
        disabled: false, // 是否可选择
        search: '',//搜索框文本
        oneCategoryId: '',
        twoCategoryId: '',
        platform: '',
        organizationId: null,
        asc: true,
        platformList: [],
        useType: 1,
        parentCategoryList:[],
        twoCategoryList:[],
        checked:false
    }
    componentDidMount() {
        this.getPlatformList()
        this.selectNewsParentCategory()
        this.selectNewsTwoCategory()
        this.selectNews()
    }
    //查询一级栏目
    selectNewsParentCategory = async () =>{
        let data={
            organizationId: this.state.organizationId,
            useType:this.state.useType,
            platform: this.state.platform
        }
        await selectNewsParentCategory(data).then(e => {
            e.splice(0, 0, {
                id:'',
                categoryName: '全部一级栏目'
            });
            this.setState({parentCategoryList: e})
        })
    }
    //查询二级栏目
    selectNewsTwoCategory = async () =>{
        let data={
            organizationId: this.state.organizationId,
            useType:this.state.useType,
            parentId: this.state.oneCategoryId,
            platform: this.state.platform
        }
        await selectNewsTwoCategory(data).then(e => {
            e.splice(0, 0, {
                id:'',
                categoryName: '全部二级栏目'
            });
            this.setState({twoCategoryList: e})
        })
    }
    //加载业务系统列表
    getPlatformList = async()=>{
        let data={
            organizationId: this.state.organizationId,
            queryType: this.state.useType
        }
        await getPlatformList(data).then(e => {
            e.splice(0, 0, {
                platform:'',
                platformName: '全部系统'
            });
            this.setState({platformList: e})
        })
    }
    //新闻列表
    selectNews = async ()=>{
        let data={
            search: this.state.search,
            oneCategoryId: this.state.oneCategoryId,
            twoCategoryId: this.state.twoCategoryId,
            platform: this.state.platform,
            organizationId: this.state.organizationId,
            asc: this.state.asc,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            useType: this.state.useType
        }
        await selectNews(data).then(e=>{
            if(this.state.selectedRowKeys.length>0){
                for (let item in e.data){
                    if(this.state.selectedRowKeys.indexOf(e.data[item].id)===-1){
                        this.setState({
                            checked:false
                        })
                        break;
                    }else{
                        this.setState({
                            checked:true
                        })
                    }
                }
            }
            this.setState({
                dataSource:e.data,total:e.total,tableLoading:false,disabled:e.data.length<1?true:false
            })
        })
    }
    //分页切换
    changePage=(pageNum)=>{
        this.setState({
            pageNum:pageNum
        },()=>{
            //列表方法
            this.selectNews()
        })
    }

    showSizeChange = (current, size) => {
        this.setState({
            pageSize: size,pageNum:1
        },()=>{
            //列表方法
            this.selectNews()
        })
    }
    // 多选
    onSelectChange = (selectedRowKeys,selectedRows) => {
        for (let item in this.state.dataSource){
            if(selectedRowKeys.indexOf( this.state.dataSource[item].id)===-1){
                this.setState({
                    checked:false
                })
                break;
            }else{
                this.setState({
                    checked:true
                })
            }
        }
        this.setState({ selectedRowKeys });
    }
    //table全选
    onSelectAll = (selected, selectedRows, changeRows)=>{
        let selectedIds = this.state.selectedRowKeys
        if(selected ===true){
            selectedRows.forEach((item)=> {
                selectedIds.push(item.id)
            });
        }else{
            let arr=[]
            this.state.dataSource.forEach((item)=>{
                arr.push(item.id)
            })
            let rs=[]
            selectedIds.forEach((i)=>{
                if(arr.indexOf(i) === -1){
                    rs.push(i)
                }
            })
            selectedIds=rs
        }
        this.setState({
            selectedRowKeys: selectedIds,
            checked: selected
        })
    }
    // 点击全选
    onChange = e => {
        let selectedRowKeys = this.state.selectedRowKeys
        if (e.target.checked) {
            this.state.dataSource.forEach((item)=>{
                selectedRowKeys.push(item.id)
            })
            this.setState({
                selectedRowKeys: selectedRowKeys,
                checked:true
            });
        } else {
            let arr=[]
            this.state.dataSource.forEach((item)=>{
                arr.push(item.id)
            })
            let rs=[]
            selectedRowKeys.forEach((i)=>{
                if(arr.indexOf(i) === -1){
                    rs.push(i)
                }
            })
            selectedRowKeys=rs
            this.setState({
                selectedRowKeys: selectedRowKeys,
                checked:false
            });
        }
    }
    // 删除提示
    showDeleteConfirm = (e) => {
        let arr = []
        if(e != undefined){
            arr.push(e.id)
        }else{
            arr = this.state.selectedRowKeys
        }
        this.hint({
            'title': '温馨提示',
            'content': '您是否要删除所选数据？',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '删除',
            'isCancel': true,
            'sure': () => {this.delete(arr)}
        })
    }
    //删除函数
    delete = (e) => {
        deleteNewsToRecycle(e).then(res=>{
            message.destroy()
            message.success('删除成功')
            this.setState({
                pageNum:1
            })
            this.selectNews()
        })
    }
    // 发布/取消发布提示
    showFbCon= (e) => {
        let content = '';
        if(e.releaseStatus === 2){
            content = '请确认是否发布（'+e.newsTitle+'）这篇新闻？'
        }else{
            content = '请确认是否取消发布（'+e.newsTitle+'）这篇新闻？'
        }
        this.hint({
            'title': '温馨提示',
            'content': content,
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': () => {this.release(e)}
        })
    }
    // 发布/取消发布函数
    release = (e) => {
        let data={
            newsId:e.id
        }
        let tip=''
        if(e.releaseStatus === 2){
            tip = '发布成功'
        }else{
            tip = '取消发布成功'
        }
        releaseNews(data).then(res =>{
            message.destroy()
            message.success(tip)
            this.selectNews()
        })
    }
    // 置顶/取消置顶提示
    showTopCon= (e) => {
        if(e.releaseStatus === 2){
            return  message.warning("请先发布该新闻！")
        }
        let content = '';
        if(e.isTop === 1){
            content = '请确认是否取消置顶（'+e.newsTitle+'）这篇新闻？'
        }else{
            content = '请确认是否置顶（'+e.newsTitle+'）这篇新闻？'
        }
        this.hint({
            'title': '温馨提示',
            'content': content,
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,

            'sure': () => {this.top(e)}
        })
    }
    top = (e)=>{
        let data={
            newsId:e.id
        }
        if(e.isTop === 0){
            checkTopNum(data).then(res=>{
                if(res === true){
                    message.warning("当前栏目下仅支持置顶4条！")
                }else{
                    topNews(data).then(res =>{
                        message.destroy()
                        message.success('置顶成功')
                        this.selectNews()
                    })
                }
            })
        }else{
            topNews(data).then(res =>{
                message.destroy()
                message.success('取消置顶成功')
                this.selectNews()
            })
        }
    }
    // 新增
    addNews = (e) => {
        this.props.history.push(`/main/group-column/addnews?${decodeURI(JSON.stringify({ id: e!== undefined?e.id:'' }))}`)
    }

    //table 时间列排序
    tableOnChange =(pagination, filters, sorter, extra) =>{
        let sort =true;
        if(typeof(sorter.order)!=='undefined'){
            if(sorter.order.replace('end','') ==='asc'){
                sort = false
            }
        }
        this.setState({
            asc: sort
        },()=>{
            this.selectNews()
        })
    }
    //搜索
    search =()=>{
        this.setState({
            pageNum: 1
        },()=>{
            this.selectNews()
        })
    }
    //业务系统change事件
    platformChange = e =>{
        this.setState({
            platform: e,
            oneCategoryId:'',
            twoCategoryId:'',
            pageNum: 1,
            parentCategoryList:[],
            twoCategoryList:[]
        },()=>{
            this.selectNewsParentCategory()
            this.selectNewsTwoCategory()
            this.selectNews()
        })
    }
    //一级栏目change事件
    oneCategoryChange = e =>{
        this.setState({
            twoCategoryId:'',
            oneCategoryId: e,
            pageNum: 1,
            twoCategoryList:[]
        },()=>{
            this.selectNewsTwoCategory()
            this.selectNews()
        })
    }
    //二级栏目change事件
    twoCategoryChange = e =>{
        this.setState({
            twoCategoryId: e,
            pageNum: 1
        },()=>{
            this.selectNews()
        })
    }
    render() {
        let { columns,total,pageSize,dataSource,pageNum,tableLoading,selectedRowKeys,platformList, parentCategoryList, twoCategoryList} = this.state;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
            onChange: this.changePage,
            onShowSizeChange: this.showSizeChange
        }
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelectAll: this.onSelectAll
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div className='published'>
                <div className="filter_controll">
                    <div className="item_wapper" style={{marginBottom: '20px'}}>
                        <div className='yewuxitong'>
                            <span style={{ marginRight: '20px' }}>请选择业务系统</span>
                            <Select placeholder='请选择业务系统' value={this.state.platform} onChange={(e) => this.platformChange(e)}>
                                {platformList.length ? platformList.map((item, index) => {
                                    return <Option key={item.platform} value={item.platform}>{item.platformName}</Option>
                                }) : ""}
                            </Select>
                        </div>
                        <div>
                            <span style={{ marginRight: '20px' }}>一级栏目</span>
                            <Select placeholder='请选择一级栏目' value={this.state.oneCategoryId} onChange={(e) => this.oneCategoryChange(e)}>
                                {parentCategoryList.length ? parentCategoryList.map((item, index) => {
                                    return <Option key={item.id} value={item.id}>{item.categoryName}</Option>
                                }) : ""}
                            </Select>
                        </div>
                        <div>
                            <span style={{ marginRight: '20px' }}>二级栏目</span>
                            <Select placeholder='请选择二级栏目' value={this.state.twoCategoryId} onChange={(e) => this.twoCategoryChange(e)}>
                                {twoCategoryList.length ? twoCategoryList.map((item, index) => {
                                    return <Option key={item.id} value={item.id}>{item.categoryName}</Option>
                                }) : ""}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="in_school_content">
                    <div className='top'>
                        <div className="left">
                            <RenderButton id='NC_01_B03_c_btnaddnews'>
                                <Button type="primary" style={{ marginRight: '20px' }} onClick={()=>this.addNews()}>新增新闻</Button>
                            </RenderButton>
                        </div>
                        <div className="right">
                            <Input.Search className="lcl_left" onChange={(e) => this.setState({ search: e.target.value })} onSearch={this.search} placeholder='请输入新闻标题' />
                            <span className='all_total'>共{this.state.total}条新闻资讯</span>
                        </div>
                    </div>
                    <div className="bottomss">
                        {
                            tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                                <div className='all_wapper'>
                                    <Table
                                        className='organ-table organ-table-live'
                                        columns={columns}
                                        dataSource={dataSource}
                                        onChange={this.tableOnChange}
                                        pagination={pagination}
                                        rowSelection={rowSelection}
                                        rowKey={(v)=>v.id}
                                        scroll={{ x: 1500 }}
                                        locale={{
                                            emptyText: <>
                                                <div className="Empty-conet-zixun">
                                                    <div className="img">
                                                        <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                                        <span>暂无数据</span>
                                                    </div>
                                                </div>
                                            </>
                                        }}
                                    />
                                    <div className='check_box'>
                                        <Checkbox
                                            disabled={this.state.disabled}
                                            onChange={this.onChange}
                                            checked={this.state.checked}
                                        >
                                            全选
                                        </Checkbox>
                                        <RenderButton id='NC_01_B03_c_btnbatchdelete'>
                                            <Button type="primary" disabled={!hasSelected} onClick={()=>this.showDeleteConfirm()}>批量删除</Button>
                                        </RenderButton>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

Published = Form.create()(Published);
export default Published;
