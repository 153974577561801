import React, { useEffect, useState } from "react";
import { Modal, Button } from 'antd';

import { getTeacherInfo } from '../../api/school_settlement';
import { shugang } from '../../icon_type'
import style from './index.module.scss';

function ViewTeacherInformation(props) {
    const {
        visible, onCancel,
        userId, enterpriseId,
    } = props;
    const [detailInfo, setDetailInfo] = useState({});
    useEffect(() => {
        async function fn() {
            const res = await getTeacherInfo({ enterpriseId, userId });
            setDetailInfo(res || {})
        }
        visible && fn();
    }, [visible])
    return (
        <Modal
            visible={visible}
            title="查看成员信息"
            className="global_modal"
            closable={false}
            getContainer={false}
            bodyStyle={{
                height: 490,
                overflow: 'auto'
            }}
            footer={<Button size="large" type="primary" onClick={onCancel}>关 闭</Button>}
        >
            <h1 className={style.title}><span style={{ position: 'relative', top: 2, left: -15 }}>{shugang}</span>教师个人信息</h1>
            <div className={style.information}>
                <div className={style.information_left}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>姓名</div>
                        <div className={style.information_value}>{detailInfo.teacherName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>性别</div>
                        <div className={style.information_value}>{detailInfo.sexName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>出生日期</div>
                        <div className={style.information_value}>{detailInfo.birthday}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>最高学历</div>
                        <div className={style.information_value}>{detailInfo.highestEducationName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>证件类型</div>
                        <div className={style.information_value}>{detailInfo.cardTypeName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>籍贯</div>
                        <div className={style.information_value}>{detailInfo.provinceName}-{detailInfo.cityName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>政治面貌</div>
                        <div className={style.information_value}>{detailInfo.polityFaceName}</div>
                    </div>
                </div>
                <div className={style.information_right}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>手机</div>
                        <div className={style.information_value}>{detailInfo.telNum}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>国籍</div>
                        <div className={style.information_value}>{detailInfo.nationalityName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>年龄</div>
                        <div className={style.information_value}>{detailInfo.age}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>最高学位</div>
                        <div className={style.information_value}>{detailInfo.highestDegreeName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>证件号码</div>
                        <div className={style.information_value}>{detailInfo.idCard}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>民族</div>
                        <div className={style.information_value}>{detailInfo.nationName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>邮箱</div>
                        <div className={style.information_value}>{detailInfo.email}</div>
                    </div>
                </div>
            </div>
            <div className={style.personal_profile}>
                <div className={style.lable}>个人简介</div>
                <div className={style.value}>{detailInfo.introduction}</div>
            </div>
            <h1 className={style.title}><span style={{ position: 'relative', top: 2, left: -15 }}>{shugang}</span>教师院校信息</h1>
            <div className={style.information}>
                <div className={style.information_left}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>工号</div>
                        <div className={style.information_value}>{detailInfo.jobNum}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>教师类型</div>
                        <div className={style.information_value}>{detailInfo.teacherTypeName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>教师职务</div>
                        <div className={style.information_value}>{detailInfo.professionalCallName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>办学层次</div>
                        <div className={style.information_value}>{detailInfo.schoolRunLevelName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>是否为班主任</div>
                        <div className={style.information_value}>{detailInfo.ifMainTeacherName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>教职工类别</div>
                        <div className={style.information_value}>{detailInfo.facultyCategoryName}</div>
                    </div>
                </div>
                <div className={style.information_right}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>入职日期</div>
                        <div className={style.information_value}>{detailInfo.joinTime}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>教师职称</div>
                        <div className={style.information_value}>{detailInfo.professionalCallName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>是否在编</div>
                        <div className={style.information_value}>{detailInfo.ifOfficeName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>人员状态</div>
                        <div className={style.information_value}>{detailInfo.positionStatusName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>专业技术职务</div>
                        <div className={style.information_value}>{detailInfo.technologyPostName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>现任岗位类别</div>
                        <div className={style.information_value}>{detailInfo.postCategoryName}</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ViewTeacherInformation;