


import React from 'react';


//实训场景管理
export default props => {
    return<svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.84907 13.7719C1.82635 13.7719 1.80362 13.7707 1.78101 13.7684C1.03842 13.693 0.558594 13.1885 0.558594 12.4834V1.44695C0.558594 0.870693 0.996524 0.258301 1.80815 0.258301L7.10537 0.274024C7.69886 0.274024 8.18342 0.738716 8.18342 1.30986V6.16522C8.18342 6.53692 7.88205 6.8383 7.51035 6.8383C7.13865 6.8383 6.83727 6.53692 6.83727 6.16522V1.61937L1.90475 1.60472V12.4255L6.35395 12.4101H6.35632C6.72699 12.4101 7.02805 12.7099 7.02934 13.0809C7.03063 13.4526 6.73033 13.755 6.35858 13.7563L1.85139 13.7719H1.84907Z" fill="#6A7E9C"/>
        <path d="M10.1481 5.81567C9.77714 5.81567 9.47598 5.51537 9.47512 5.14416L9.46941 2.66179L7.00681 1.59284C6.6658 1.44481 6.50938 1.0484 6.6574 0.707444C6.80548 0.366436 7.20211 0.210121 7.54285 0.358036L10.1333 1.48245C10.152 1.49058 10.1704 1.49952 10.1883 1.50932C10.4775 1.66747 10.8152 2.02151 10.8152 2.4988L10.8213 5.14103C10.8222 5.51273 10.5216 5.81481 10.1498 5.81567H10.1481Z" fill="#6A7E9C"/>
        <path d="M5.39166 4.08883H3.2473C2.8756 4.08883 2.57422 3.78745 2.57422 3.41575C2.57422 3.04405 2.8756 2.74268 3.2473 2.74268H5.39166C5.76337 2.74268 6.06474 3.04405 6.06474 3.41575C6.06474 3.78745 5.76337 4.08883 5.39166 4.08883ZM5.39166 6.24629H3.2473C2.8756 6.24629 2.57422 5.94491 2.57422 5.57321C2.57422 5.20151 2.8756 4.90013 3.2473 4.90013H5.39166C5.76337 4.90013 6.06474 5.20151 6.06474 5.57321C6.06474 5.94491 5.76337 6.24629 5.39166 6.24629Z" fill="#BABABA"/>
        <path d="M10.1089 13.6307C8.20295 13.6307 6.65234 12.0802 6.65234 10.1743C6.65234 8.26838 8.20295 6.71777 10.1089 6.71777C11.6245 6.71777 12.9494 7.68819 13.4054 9.13261C13.5173 9.48708 13.3207 9.86519 12.9662 9.97714C12.6117 10.0892 12.2337 9.89244 12.1217 9.53797C11.8433 8.65634 11.0345 8.06398 10.1089 8.06398C8.94522 8.06398 7.9985 9.0107 7.9985 10.1743C7.9985 11.3379 8.94522 12.2846 10.1089 12.2846C10.678 12.2846 11.2116 12.0614 11.6114 11.6561C11.8723 11.3915 12.2985 11.3884 12.5632 11.6495C12.8278 11.9105 12.8308 12.3367 12.5698 12.6014C11.9152 13.2651 11.0411 13.6307 10.1089 13.6307Z" fill="#6A7E9C"/>
        <path d="M11.098 10.1739L11.474 9.79796C11.7369 9.53508 11.7369 9.10894 11.474 8.84606C11.2111 8.58319 10.785 8.58324 10.5221 8.84606L10.1462 9.22202L9.77015 8.84606C9.50721 8.58324 9.08108 8.58324 8.81825 8.84606C8.55537 9.10894 8.55537 9.53508 8.81825 9.79796L9.19421 10.1739L8.81825 10.5499C8.55537 10.8127 8.55537 11.2389 8.81825 11.5018C8.88068 11.5644 8.95487 11.614 9.03655 11.6478C9.11823 11.6817 9.20579 11.699 9.2942 11.6989C9.4664 11.6989 9.63871 11.6331 9.77015 11.5018L10.1462 11.1258L10.5221 11.5018C10.6535 11.6331 10.8258 11.6989 10.9981 11.6989C11.1703 11.6989 11.3426 11.6331 11.474 11.5018C11.7369 11.2389 11.7369 10.8127 11.474 10.5499L11.098 10.1739Z" fill="#BABABA"/>
    </svg>

}