import React, { Component } from 'react'
import { Button, Form, Input, Row, Col, Select, Divider, Table, Pagination } from 'antd';
import S from 'gongyongicon';
import { get } from 'lodash';

import TextOverflowEllipsis from '../../components/text_overflow_ellipsis';
import ViewTeacherInformation from '../../components/view_teacher_information';
import { getTeacherPage, getTeacherType, getHighestEducation, delTeacher, resetUserPassword, getDefaultPasswordByEnterpriseId } from '../../api/school_settlement';
import iconType from '../../icon_type';

import style from './index.module.scss';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xxl: { span: 5 },
        xl: { span: 6 },
        lg: { span: 6 },
    },
    wrapperCol: {
        xxl: { span: 18 },
        xl: { span: 16 },
        lg: { span: 16 },
    },
};
export default Form.create()(class MemberListTeacherManagement extends Component {
    enterpriseId = this.getUrlType(this.props.location.search, 'id')
    state = {
        data: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        highestEducationData: [],
        teacherTypeData: [],
        userId: '',
        visible: false,
    }
    async componentDidMount() {
        const highestEducationData = await getHighestEducation();
        const teacherTypeData = await getTeacherType();
        this.setState({
            highestEducationData,
            teacherTypeData,
        })
        this.getSchoolIntoManagePage();
    }
    getSchoolIntoManagePage = async () => {
        const { pageNum, pageSize } = this.state;
        const value = this.props.form.getFieldsValue();
        const res = await getTeacherPage({
            ...value,
            pageNum,
            pageSize,
            enterpriseId: this.enterpriseId,
        });
        this.setState({
            data: get(res, 'data', []),
            total: get(res, 'total', 0),
        })
    }
    handleReset = () => {
        this.props.form.resetFields();
        this.getSchoolIntoManagePage();
    }
    //分页
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => {
            this.getSchoolIntoManagePage();
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({ pageNum: 1, pageSize }, () => {
            this.getSchoolIntoManagePage();
        })
    };

    delete = (userId) => {
        this.hint({
            type: 'warning',
            title: '温馨提示',
            content: '您确定要删除该用户吗？',
            isCancel: true,
            sure: async () => {
                try {
                    await delTeacher({ enterpriseId: this.enterpriseId, userId })
                    this.getSchoolIntoManagePage();
                    this.mesSuccess('删除成功');
                } catch (error) {
                    this.mesWarning('删除失败');
                }
            }
        });
    }
    resetPaw = async (userId) => {
        const param = {
            id: this.enterpriseId,
        }
        try {
            const res = await getDefaultPasswordByEnterpriseId(param)
            this.hint({
                type: 'warning',
                title: '温馨提示',
                content: '您确定要将此用户密码重置吗？重置后密码为'+'【'+ res +'】',
                isCancel: true,
                sure: async () => {
                    try {
                        await resetUserPassword({enterpriseId: this.enterpriseId, userId})
                        this.getSchoolIntoManagePage();
                        this.mesSuccess('操作成功');
                    } catch (error) {
                        this.mesWarning('操作失败');
                    }
                }
            });
        } catch (error) {
        }
    }
    see = (userId) => {
        this.setState({
            visible: true,
            userId
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            data, pageNum, pageSize, total,
            highestEducationData = [], teacherTypeData = [],
            visible, userId,
        } = this.state;
        const { RenderButton } = React;
        const columns = [
            {
                title: '姓名',
                dataIndex: 'teacherName',
                key: 'teacherName',
                align: 'center',
            },
            {
                title: '性别',
                dataIndex: 'sexName',
                key: 'sexName',
                align: 'center',
            },
            {
                title: '工号/帐号',
                dataIndex: 'jobNum',
                key: 'jobNum',
                align: 'center',
                render: (text, item) => text || '--',

            },
            {
                title: '最高学历',
                dataIndex: 'highestEducationName',
                key: 'highestEducationName',
                align: 'center',
                render: (text, item) => text || '--',
            },
            {
                title: '教师类型',
                dataIndex: 'teacherTypeName',
                key: 'teacherTypeName',
                align: 'center',
            },
            {
                title: '手机号',
                dataIndex: 'telNum',
                key: 'telNum',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'op',
                key: 'op',
                align: 'center',
                width: 300,
                render: (_, r) => {
                    const { userId } = r;
                    return (
                        <div className="action vertical_j">
                            <RenderButton id="OBSMM-01-B01-a-btnShowData">
                            <span onClick={() => this.see(userId)}>查看</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-B01-a-btnUpdata">
                            <span onClick={() => this.props.history.push(`/main/institutional-settlement/school_teacher_updata?id=${this.enterpriseId}&userId=${userId}`)}>修改</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-B01-a-btnDelete">
                            <span onClick={() => this.delete(userId)}>删除</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-B01-a-btnResetPassword">
                            <span onClick={() => this.resetPaw(userId)}>重置密码</span>
                            </RenderButton>
                        </div>
                    )
                }
            },
        ];

        return (
            <div className={style.MemberListTeacherManagement}>
                <div className={style.search}>
                    <Form {...formItemLayout} colon={false}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="教师类型">
                                    {getFieldDecorator("teacherType", {

                                    })(
                                        <Select
                                            placeholder="请选择教师类型"
                                        >
                                            {teacherTypeData.map(item => <Option key={item.code} value={item.code}>{item.desc}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="最高学历">
                                    {getFieldDecorator("highestEducation", {

                                    })(
                                        <Select
                                            placeholder="请选择最高学历"
                                        >
                                            {highestEducationData.map(item => <Option key={item.code} value={item.code}>{item.desc}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="搜索教师">
                                    {getFieldDecorator("keyWord", {

                                    })(
                                        <Input placeholder="请输入教师名称/手机号" prefix={iconType.magnifier} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6} className={style.expand}>
                                <Button style={{ marginRight: 20 }} onClick={this.handleReset}>重 置</Button>
                                <Button type="primary" onClick={this.getSchoolIntoManagePage}>查 询</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={style.table_list}>
                    <div className={style.create_school}>
                        <RenderButton id="OBSMM-01-B01-a-btnAddTeacher">
                        <Button type="primary" onClick={() => this.props.history.push(`/main/institutional-settlement/school_teacher_add?id=${this.enterpriseId}`)}>新增教师</Button>
                        </RenderButton>
                        <div>共{total}个人</div>
                    </div>
                    <Divider />
                    <div className={style.antd_table_wrap}>
                        <Table
                            rowKey="id"
                            dataSource={data}
                            columns={columns}
                            pagination={false}
                            scroll={{
                                y: true
                            }}
                            locale={{
                                emptyText: <img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                            }}
                            footer={() => {
                                return <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                    onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                    onChange={p => this.pageChange(p)}
                                />
                            }}
                        />
                    </div>
                </div>
                <ViewTeacherInformation
                    enterpriseId={this.enterpriseId}
                    userId={userId}
                    visible={visible}
                    onCancel={()=>this.setState({visible:false})}
                />
            </div>
        )
    }
})
