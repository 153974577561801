import React, { Component } from 'react'

export default class ContentResource extends Component {
    render() {
        return (
            <div>
              content-resourcecontent-resourcecontent-resourcecontent-resourcecontent-resource
            </div>
        )
    }
}
