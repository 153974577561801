import React from 'react';
import {Button, Form, Input, Modal, message, Select,Upload} from "antd";
import '../index.scss';
import ImgCrop from "antd-img-crop";
import moment from "moment";
import OSS from "ali-oss";
import {getOssToken} from '@/api/ali/index.js'
import {practTrainOssImgUrl} from '@/config/secret.js';
const {Option} = Select;
const {TextArea} = Input;
class AddFilesModel extends React.Component {
    state = {
        oneClassifyDetail: {},
        timeList:[],
        addressVisible:false,
        iconUrl: '',
        filesUrl: '',
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
    }
    async componentDidMount() {
        await this.getOssToken();
    }

    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
    }
    //get oss token
    async getOssToken() {
        let res = await getOssToken();
        if (res) {
            let client = new OSS({
                region: "oss-cn-beijing",
                accessKeyId: res.accessKeyId,
                accessKeySecret: res.keySecret,
                stsToken: res.token,
                bucket: res.bucket,
                secure: true
            });
            this.setState({client})
        }
    }
    //上传图片
    changeLoad = async (info,type) => {
        try {
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            let name = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
            name = `/cvec-front-img/${practTrainOssImgUrl}/${ymd}/${name}`
            let result = await this.state.client.put(name, info);
            console.log('result', result)
            let {url} = result
            if (result && type===1) {
                this.setState({
                    iconUrl: url,
                    loading: false
                });
            }else {
                this.setState({
                    filesUrl: url,
                    loading: false
                });
            }

        } catch (e) {
            console.log(e);
        }
    };

    addDiv=(data)=>{
        const styles = {
            display: 'flex',
            justifyContent: 'space-around'
        }
        return <div className='add_image_div'>
            <div style={{...styles}}>
                <img style={{width: "19px", height: "19px", display: "block"}}
                     src={window.$$img('cvec-portal-front-pingtai/Circl/add_icon.png')} alt=""/>
            </div>
            <span style={{color: "#3E78ED", fontSize: "12px", marginTop: "8px", display: 'inline-block'}}>{data===1?"点击上传安装包的icon":"点击上传安装包"}</span>
        </div>;
    }
    render() {
        const {saveLoading, timeList,iconUrl,filesUrl} = this.state;
        const {addFilesVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        const props = {
            //aspect:160/88,
            resize: true, //裁剪是否可以调整大小
            resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
            modalTitle: "上传图片", //弹窗标题
            modalWidth: 600, //弹窗宽度
            rotate: true,
            width: 160,  //裁剪宽度
            height: 88, //裁剪高度
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <div>
                <Modal
                    width='800px'
                    title="增加安装文件"
                    wrapClassName='practTrain_modal_box'
                    visible={addFilesVisible}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.checkCancel()}>取消</Button>
                            <Button loading={saveLoading} onClick={() => this.generateAddress()}
                                    type='primary'>确定</Button>
                        </div>
                    }
                >
                    <Form {...formItemLayout} className="practTrain_form">
                        <Form.Item label='安装包名称'>
                        {
                            getFieldDecorator('categoryName', {
                                initialValue:undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入安装包名称'
                                    },
                                ]
                            })(<Input placeholder='请输入安装包名称' className="sold-box"
                                      suffix={<span
                                          className="suffixStyle">{getFieldValue('categoryName') ? getFieldValue('categoryName').length : "0"}/20</span>}/>)
                        }
                    </Form.Item>
                        <Form.Item label='版本号'>
                            {
                                getFieldDecorator('categoryName', {
                                    initialValue:undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入版本号'
                                        },
                                    ]
                                })(<Input placeholder='请输入版本号' className="sold-box"
                                          suffix={<span
                                              className="suffixStyle">{getFieldValue('categoryName') ? getFieldValue('categoryName').length : "0"}/20</span>}/>)
                            }
                        </Form.Item>
                        <Form.Item label='文件类型'>
                            {
                                getFieldDecorator('categoryName', {
                                    initialValue:undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入文件类型'
                                        },
                                    ]
                                })(<Input placeholder='请输入文件类型' className="sold-box"
                                          suffix={<span
                                              className="suffixStyle">{getFieldValue('categoryName') ? getFieldValue('categoryName').length : "0"}/20</span>}/>)
                            }
                        </Form.Item>
                        <Form.Item label='文件提供商'>
                            {
                                getFieldDecorator('categoryName', {
                                    initialValue:undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择文件提供商'
                                        },
                                    ]
                                })(
                                    <Select placeholder="请选择文件提供商" onChange={(value) => this.oneClassifyChange(value)}
                                            getPopupContainer={triggerNode => triggerNode.parentNode}>
                                        {timeList && timeList.length ? timeList.map((item, index) => {
                                            return <Option key={index}
                                                           value={item.categoryId}>{item.categoryName}</Option>
                                        }) : ""}
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item label='适配环境'>
                            {
                                getFieldDecorator('categoryName', {
                                    initialValue:undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入适配环境'
                                        },
                                    ]
                                })(<Input placeholder='请输入适配环境' className="sold-box"
                                          suffix={<span
                                              className="suffixStyle">{getFieldValue('categoryName') ? getFieldValue('categoryName').length : "0"}/20</span>}/>)
                            }
                        </Form.Item>
                        <Form.Item label='文件大小'>
                            {
                                getFieldDecorator('categoryName', {
                                    initialValue:undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入文件大小'
                                        },
                                    ]
                                })(<Input placeholder='请输入文件大小' className="sold-box"
                                          suffix={<span
                                              className="suffixStyle">{getFieldValue('categoryName') ? getFieldValue('categoryName').length : "0"}/20</span>}/>)
                            }
                        </Form.Item>
                        <Form.Item label='适用场景'>
                            {
                                getFieldDecorator('categoryName', {
                                    initialValue:undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入适用场景'
                                        },
                                    ]
                                })( <TextArea   autoSize={{minRows: 3, maxRows: 5}} placeholder="请输入适用场景" />)
                            }
                            <span
                                className="suffixStyle">{getFieldValue("liveIntroduction") ? getFieldValue("liveIntroduction").length : 0}/300</span>
                        </Form.Item>
                        <Form.Item label='备注说明'>
                            {
                                getFieldDecorator('categoryName', {
                                    initialValue:undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入备注说明'
                                        },
                                    ]
                                })( <TextArea   autoSize={{minRows: 3, maxRows: 5}} placeholder="请输入备注说明" />)
                            }
                            <span
                                className="suffixStyle">{getFieldValue("liveIntroduction") ? getFieldValue("liveIntroduction").length : 0}/300</span>
                        </Form.Item>
                        <Form.Item className='form-item' label="icon" >
                            {
                                getFieldDecorator('iconUrl', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'icon不能为空'
                                        }
                                    ]
                                })(
                                    <ImgCrop {...props}>
                                        <Upload
                                            accept='.jpg,.png,.jpeg,.bmp'
                                            listType="picture-card"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.changeLoad(file,1)}
                                        >
                                            {

                                                iconUrl ? <div className='replace_img'>
                                                        <img
                                                            style={{width: "100%", height: '100%'}}
                                                            src={iconUrl} alt="avatar"/>
                                                    </div>
                                                    :
                                                    this.addDiv(1)
                                            }
                                        </Upload>
                                    </ImgCrop>
                                )
                            }
                            <div className="tishi_wrap">
                                <p className='tishi'>支持格式：.jpg、.jpeg、.png、.bmp<br/>图片尺寸：160*100，不超过1M</p>
                            </div>
                        </Form.Item>
                        <Form.Item className='form-item' label="上传安装包">
                            {
                                getFieldDecorator('filesUrl', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '上传安装包不能为空'
                                        }
                                    ]
                                })(
                                    <ImgCrop {...props}>
                                        <Upload
                                            accept='.jpg,.png,.jpeg,.bmp'
                                            listType="picture-card"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.changeLoad(file)}
                                        >
                                            {

                                                filesUrl? <div className='replace_img'>
                                                        <img
                                                            style={{width: "100%", height: '100%'}}
                                                            src={filesUrl} alt="avatar"/>
                                                    </div>
                                                    :
                                                    this.addDiv()
                                            }
                                        </Upload>
                                    </ImgCrop>
                                )
                            }
                            <div className="tishi_wrap">
                                <p className='tishi'>支持格式：.jpg、.jpeg、.png、.bmp<br/>图片尺寸：160*100，不超过1M</p>
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>

        )
    }
}

AddFilesModel = Form.create()(AddFilesModel)
export default AddFilesModel;

