import React, { Component } from "react";
import './index.scss';
import TextArea from "antd/lib/input/TextArea";
import { Button, Table, Input, Col, Checkbox,    Tabs,  Tag,   Form, DatePicker, message, Select, Modal, } from 'antd';
import { wholeCircleList, circleList,banCircle,unsealCircle,deleteCircle, closedCircle, hasAuditList, noAuditList,showTopicDetail,auditPass,auditRefuse, blackList, orgList, } from '@/api/circle/index'
import moment from 'moment';

const { Search } = Input;
const { TabPane } = Tabs;

const { Option } = Select
const { RangePicker } = DatePicker;
const { Column } = Table;

export default class index extends Component {
  state = {
    count: 0,
    comment_data: [ ],
    portraitId:"",
    // portraitId:
    //   localStorage.getItem("userInfo") &&
    //     JSON.parse(localStorage.getItem("userInfo"))
    //     ? JSON.parse(localStorage.getItem("userInfo")).user.portraitId
    //     : "",
    idea: "",
    com_d: "",

    _topicId: "",
    _showTopicDetailData: [],
  };

  componentDidMount() {
       
      //console.log(this.props.location.pathname,'----------') // 通过动态路由方式取值 get方式时取值，取出来的时?aid=1这种格式，需手动转或者引入url模块，进行路由解析
      //console.log(this.props.match.params.id,'11111111'); // 动态路由方式时取值 此方法较为常用
      this.showTopicDetail();
  }

  //查看话题详情
  async showTopicDetail() {
    let _tid = this.props.match.params.id;  //动态路由方式时取值
    //console.log(this.props.match.params.id,'11111111'); 
    let params = {
      topicId: _tid,
      
    };

    const res = await showTopicDetail(params);
    
    console.log(res)
    if (res ) {   
        this.setState({
          _showTopicDetailData: res,
        });
    }
    console.log(res,"111111")
  }

    //话题审核通过
    handleauditPass = ()=> {
      let _tid = this.props.match.params.id;  //动态路由方式时取值
      
      auditPass({
        topicId: _tid,
      }).then(res => {
          if (res && res.code === 0) {
            //message.success(res.message)
            message.success('操作成功')
          } else {
            message.error(res.message)
          }
      })

    }

    //话题审核拒绝
    handleauditRefuse = ()=> {
      let _tid = this.props.match.params.id;  //动态路由方式时取值
      
      auditRefuse({
        topicId: _tid,
      }).then(res => {
          if (res && res.code === 0) {
            message.success('操作成功')
          } else {
            message.error(res.message)
          }
      })

    }
    

  render() {
    let { count, comment_data, portraitId, counts, _topicId, _showTopicDetailData,} = this.state;
    const communityDetailData = sessionStorage.communityDetail ? JSON.parse(sessionStorage.communityDetail) : {};
    return (
      <div className="topic_wrap_new">
        <div className="hseader_wrap">
          <div className="head_new">
            <div className="head_l">
              <span onClick={() => this.props.history.push('/main/circle-manage/topic-audit')}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.258 5.0247H2.17301L7.01502 1.14361C7.08697 1.09578 7.14821 1.03353 7.19485 0.960793C7.2415 0.88806 7.27252 0.806432 7.28597 0.721081C7.29942 0.63573 7.29499 0.548517 7.27297 0.464966C7.25096 0.381415 7.21183 0.303347 7.15806 0.235708C7.1043 0.16807 7.03707 0.112334 6.96064 0.0720329C6.88421 0.0317315 6.80025 0.00774268 6.71406 0.00158606C6.62788 -0.00457055 6.54136 0.00723925 6.45998 0.0362673C6.37859 0.0652954 6.30413 0.110909 6.2413 0.170219L0.00159455 5.16198V5.38661C-0.000531515 5.41569 -0.000531515 5.44488 0.00159455 5.47396V5.53636C-0.000531515 5.56544 -0.000531515 5.59464 0.00159455 5.62372V5.84835L6.2413 10.8401C6.30521 10.8917 6.37866 10.9303 6.45747 10.9535C6.53627 10.9767 6.61888 10.9842 6.70058 10.9755C6.78228 10.9668 6.86146 10.9421 6.93361 10.9028C7.00576 10.8635 7.06947 10.8104 7.12109 10.7465C7.17272 10.6826 7.21124 10.6091 7.23448 10.5303C7.25771 10.4515 7.2652 10.3689 7.25651 10.2872C7.24782 10.2055 7.22312 10.1263 7.18383 10.0542C7.14453 9.98204 7.09141 9.91833 7.0275 9.86671L2.17301 6.27264H11.258C15.2389 6.27264 18.4836 9.06803 18.4836 12.5123C18.4836 15.9567 15.2389 18.752 11.258 18.752H1.6364C1.47091 18.752 1.3122 18.8178 1.19518 18.9348C1.07817 19.0518 1.01243 19.2105 1.01243 19.376C1.01243 19.5415 1.07817 19.7002 1.19518 19.8172C1.3122 19.9342 1.47091 20 1.6364 20H11.258C15.9877 20 19.7315 16.643 19.7315 12.5123C19.7315 8.38166 15.9877 5.0247 11.258 5.0247Z"
                    fill="#333333"
                  />
                </svg>

                <span className="back_text">返回</span>
              </span>
            </div>
            <div className="detail_title">
              
              <span>话题审核详情</span>
            </div>
          </div>
        </div>
        <div className="breadcrumb"> 
              <span className="first_bread">话题审核管理 </span>&nbsp;>&nbsp;
              <span className="second_bread"> 话题审核详情</span>
        </div>
        <div className="content">

          <div className="c_left">
            
          
          {console.log(this.state._showTopicDetailData)}
          
              <div className="cicle_main_warp">
                <div className="cicle_main_name_warp">
                  <div className="cicle_user_name">
                    <img src={window.$$uploadRc('gr.png')} alt="" />
                  </div>
                  <div className="cicle_main_name">
                    <p className="cname">{this.state._showTopicDetailData.circleName}</p>
                    <p className="cinfo"><span className="mright10">圈主：{this.state._showTopicDetailData.circleOwner} </span> <span className="mleft10">所在机构：{this.state._showTopicDetailData.orgName}</span></p>
                  </div>
                  <div className="go_cicle">
                    <span className="go_link">进入圈子 ></span>

                  </div>
                </div>
              </div>

                <div className="cicle_main_content">
                  <div className="content_title">
                    {this.state._showTopicDetailData.topicTitle}
              </div>
                  <div className="content_main">
                    {this.state._showTopicDetailData.content}
              </div>
                  <div className="content_img">
                    {this.state._showTopicDetailData.fjType===0?  
                      
                    <img src={this.state._showTopicDetailData.coverUrl} alt="" />
                      
                      //  {_showTopicDetailData && _showTopicDetailData.map((v, i) => {
                      //    return <img src={v.coverUrl} alt="" />
                      //    })
                      //  }
                     
                   :''}
                      
                    {this.state._showTopicDetailData.fjType===2? (<audio width="520" height="10" src="" controls="controls" type="audio/mpeg"/> ):""}
                    { this.state._showTopicDetailData.fjType===1?  (<video width="520" height="300" src="" controls="controls" type="video/mp4" /> ):''}
                    {/* <img src={require("@/assets/img/images/gr.png")} alt="" /> */}
                  </div>
                </div>

                <div className="cicle_mian_tag_wrap">
                  <div className="cicle_mian_tag_main">
                  
                    <img className="mright10" src={this.state._showTopicDetailData.memberAvatar} alt="" />
                    <span className="tag_name font16 mright10">{this.state._showTopicDetailData.userName}</span>
                    <span className="tag_time font14">发布时间：{this.state._showTopicDetailData.publishTime}</span>
                  </div>

                </div>

  
          </div>
          
        </div>
        <div className="tfooter_wrap">
            <div className="head_new">
            <Button type="primary" style={{marginRight:'20px'}} onClick={() => this.handleauditPass()}>审核通过</Button>
            <Button type="default" onClick={() => this.handleauditRefuse()}>拒 绝</Button>
            </div>
        
        </div>
      </div>
    );
  }
}
