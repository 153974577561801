// 角色能力列表
import React from 'react';
import {Form, Row, Col, Collapse} from 'antd';
import { roleMenu } from '@/api/platform-business/index.js';
import { roleDetail,roleListMenu } from '@/api/role/index.js';
const { Panel } = Collapse;
class RoleAbility extends React.Component {
    state = {
        roleList: [],//权限能力列表
        roleListArr: [],//权限能力处理过数据
        roleNum:0,//权限能力tab
        roleDetails: {},
        ability: {},
        menuList:'',//一级菜单列表
        menuActive:''
    };

    async componentWillMount() {
        let {ability}=this.props
        this.setState({ability: ability}, () => {
            this.getAbilityList(ability.roleId)
            this.getRole(ability.roleId)
        });
    }
    //获取权限列表数据
    async getAbilityList(id){
        const  roleList=await roleMenu({roleId:id})
        let roleListArr=[]
        roleList&&roleList.length>0&&roleList.map((item,index)=>{
            if(item.children){
                roleListArr.push(...item.children);
            }

        })
        this.setState({
            roleList,
            roleListArr
        })
    }
    //能力权限列表切换事件
    changeRoleNum=(index)=>{
        this.setState({
            roleNum:index
        })
    }
    async getRole(roleId) {
        let res = await roleListMenu({roleId: roleId,platform:''});
        const resArr = new Map();
        const menuLis=[]
        if (res.result !== null) {
            const newArr= res.filter((a) => !resArr.has(a.platform) && resArr.set(a.platform, 1))
            newArr.map((item,index)=>{
                menuLis.push({'platform':item.platform,'platformName':item.platformName})

            })
            if (res) {
                this.setState({
                    roleDetails: res,
                    menuLis,
                    menuActive:menuLis[0].platform
                });
            }
        } else {
            this.setState({
                roleDetails: res,
                menuLis,
                menuActive: []
            });
        }
    }
    //一级菜单切换
    menuChange=(id)=>{
        this.setState({
            menuActive:id
        })
    }
    render() {
        let {roleList, roleDetails, ability, roleListArr, roleNum,menuLis,menuActive} = this.state;
        const roleListMenu=(data)=>{
            const {roleNum}=this.state
            return(
              data.map((item,index)=>{
                  if(roleNum===index&&menuActive===item.platform){
                      if(item.children&&item.children.length>0){
                          return(
                            <Collapse className='coll_wapper' defaultActiveKey={item.menuId}>
                                <Panel header={item.menuName} key={item.menuId}>
                                    { roleListMenu(item.children)}
                                </Panel>
                            </Collapse>
                          )
                      }else{
                          return(
                            <Collapse defaultActiveKey="1" className='coll_wapper_two' defaultActiveKey={item.menuId}>
                                <Panel header={item.menuName} key={item.menuId}>
                                    <p className='active_sty'>.{item.menuName}</p>
                                </Panel>
                            </Collapse>
                          )}
                  }
              })
            )
        }
        return (
          <div className='ability_mod'>
              <div className='abi_top'>
                  <div className='top_wapper wapper_item'>
                      <Row className='top_item'>
                          <Col span={6}>姓名</Col>
                          <Col span={12}>{ability.fullName}</Col>
                      </Row>
                      <Row className='top_item'>
                          <Col span={6}>账号</Col>
                          <Col span={12}>{ability.userName}</Col>
                      </Row>
                      <Row className='top_item'>
                          <Col span={6}>手机</Col>
                          <Col span={16}>{ability.telNum}</Col>
                      </Row>
                  </div>
                  <Row className='wapper_item'>
                      <Col span={4}>角色名称</Col>
                      <Col span={8}>{roleDetails.roleName}</Col>
                  </Row>
                  <Row className='wapper_item'>
                      <Col span={4}>角色说明</Col>
                      <Col span={8}>{roleDetails.content}</Col>
                  </Row>
              </div>
              {/*按钮区*/}
              <div className='btn_wapper'>
                  {menuLis&&menuLis.map((item,index)=>{
                      return(
                          <div onClick={()=>{this.menuChange(item.platform)}} className={`cons_btn ${menuActive===item.platform?'blue_back':'no_back'}`} no_back>{item.platformName}</div>
                      )
                  })}
              </div>
              <div className='btn_wapper'>
                  {
                      roleList&&roleList.length>0&&roleList.map((item,index)=>{
                          if(menuActive===item.platform){
                              return(
                                  <div onClick={()=>{this.changeRoleNum(index)}} className={`pt_btn ${roleNum===index?'pt_blue_back':'pt_no_back'}`} style={{marginRight: '20px'}}>
                                      {item.menuName}
                                  </div>
                              )
                          }

                      })
                  }
              </div>
              {roleListMenu(roleListArr)}
          </div>
        );
    }
}

RoleAbility = Form.create()(RoleAbility);
export default RoleAbility;
