import { CHANGE_ACTIVE, GET_ROLES, GET_MENU } from './action_type';
import { getRole, getMenu } from '@/api/login';


export const changeIndex = (data) => {
    return {
        type: CHANGE_ACTIVE,
        data: data
    }
}

//获取按钮权限
export const getRoles = data => {
    return async dispatch => {
        let res = await getRole({ platform: 'OBS' });
        if (res) {
            dispatch({
                type: GET_ROLES,
                data: Array.isArray(res) ? res : []
            })
        }
    }
}


//获取菜单权限
export const getMenus = data => {
    return async dispatch => {
        let res = await getMenu({ platform: 'OBS' });
        if (res) {
            dispatch({
                type: GET_MENU,
                data: Array.isArray(res) ? res : []
            })
        }
    }
}












