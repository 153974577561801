import React from 'react'


export default props => {
    return <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0.390625" y="3.06348" width="8" height="8" fill="black">
            <rect fill="white" x="0.390625" y="3.06348" width="8" height="8" />
            <path d="M6.93889 10.0635C6.87957 10.0636 6.82082 10.052 6.76601 10.0293C6.71119 10.0066 6.66141 9.97335 6.61952 9.93135L1.51564 4.82747C1.43456 4.74206 1.39006 4.62835 1.39164 4.5106C1.39321 4.39284 1.44073 4.28036 1.52406 4.19715C1.60739 4.11393 1.71993 4.06657 1.83769 4.06516C1.95545 4.06375 2.06909 4.1084 2.15439 4.1896L7.25827 9.2926C7.32146 9.35574 7.3645 9.43621 7.38194 9.52383C7.39938 9.61144 7.39044 9.70226 7.35624 9.78479C7.32205 9.86732 7.26415 9.93785 7.18985 9.98746C7.11556 10.0371 7.02822 10.0635 6.93889 10.0635Z" />
        </mask>
        <path d="M6.93889 10.0635C6.87957 10.0636 6.82082 10.052 6.76601 10.0293C6.71119 10.0066 6.66141 9.97335 6.61952 9.93135L1.51564 4.82747C1.43456 4.74206 1.39006 4.62835 1.39164 4.5106C1.39321 4.39284 1.44073 4.28036 1.52406 4.19715C1.60739 4.11393 1.71993 4.06657 1.83769 4.06516C1.95545 4.06375 2.06909 4.1084 2.15439 4.1896L7.25827 9.2926C7.32146 9.35574 7.3645 9.43621 7.38194 9.52383C7.39938 9.61144 7.39044 9.70226 7.35624 9.78479C7.32205 9.86732 7.26415 9.93785 7.18985 9.98746C7.11556 10.0371 7.02822 10.0635 6.93889 10.0635Z" fill="#3E78ED" />
        <path d="M6.61952 9.93135L6.76112 9.79011L6.76094 9.78993L6.61952 9.93135ZM1.51564 4.82747L1.37054 4.96522L1.37422 4.96889L1.51564 4.82747ZM2.15439 4.1896L2.29584 4.04812L2.29229 4.04473L2.15439 4.1896ZM7.25827 9.2926L7.11686 9.43403L7.1169 9.43407L7.25827 9.2926ZM6.93847 9.86348C6.90553 9.86355 6.8729 9.8571 6.84246 9.84451L6.68955 10.2141C6.76874 10.2469 6.85362 10.2637 6.93932 10.2635L6.93847 9.86348ZM6.84246 9.84451C6.81202 9.83192 6.78438 9.81343 6.76112 9.79011L6.47792 10.0726C6.53844 10.1333 6.61036 10.1814 6.68955 10.2141L6.84246 9.84451ZM6.76094 9.78993L1.65706 4.68605L1.37422 4.96889L6.4781 10.0728L6.76094 9.78993ZM1.66069 4.68978C1.61553 4.6422 1.59074 4.57886 1.59162 4.51327L1.19165 4.50793C1.18939 4.67785 1.2536 4.84192 1.37059 4.96517L1.66069 4.68978ZM1.59162 4.51327C1.59249 4.44768 1.61896 4.38502 1.66538 4.33867L1.38273 4.05563C1.26249 4.17571 1.19392 4.33801 1.19165 4.50793L1.59162 4.51327ZM1.66538 4.33867C1.7118 4.29231 1.77449 4.26593 1.84008 4.26514L1.83529 3.86517C1.66537 3.86721 1.50297 3.93555 1.38273 4.05563L1.66538 4.33867ZM1.84008 4.26514C1.90568 4.26436 1.96898 4.28923 2.0165 4.33446L2.29229 4.04473C2.1692 3.92757 2.00521 3.86314 1.83529 3.86517L1.84008 4.26514ZM2.01298 4.33103L7.11686 9.43403L7.39968 9.15117L2.2958 4.04816L2.01298 4.33103ZM7.1169 9.43407C7.1521 9.46925 7.17607 9.51407 7.18579 9.56287L7.57809 9.48478C7.55292 9.35836 7.49082 9.24224 7.39964 9.15113L7.1169 9.43407ZM7.18579 9.56287C7.1955 9.61168 7.19052 9.66227 7.17147 9.70824L7.54101 9.86134C7.59035 9.74225 7.60325 9.6112 7.57809 9.48478L7.18579 9.56287ZM7.17147 9.70824C7.15243 9.75421 7.12017 9.7935 7.07879 9.82113L7.30092 10.1538C7.40812 10.0822 7.49168 9.98043 7.54101 9.86134L7.17147 9.70824ZM7.07879 9.82113C7.03741 9.84876 6.98876 9.8635 6.939 9.86348L6.93879 10.2635C7.06769 10.2635 7.19372 10.2254 7.30092 10.1538L7.07879 9.82113Z" fill="#3E78ED" mask="url(#path-1-outside-1)" />
        <path d="M6.94019 10.0635L6.94011 10.1635C7.08662 10.1636 7.22721 10.1056 7.33101 10.0022L7.33116 10.002L12.554 4.77829L12.5541 4.77836L12.5571 4.77505C12.6529 4.67025 12.7046 4.53255 12.7014 4.3906C12.6982 4.24865 12.6404 4.1134 12.54 4.013C12.4396 3.9126 12.3044 3.85479 12.1624 3.8516C12.0205 3.84842 11.8828 3.90011 11.778 3.9959L11.7779 3.99583L11.7747 3.99899L6.55061 9.22225C6.55052 9.22234 6.55043 9.22243 6.55034 9.22251C6.47256 9.29931 6.41943 9.39755 6.39775 9.5047C6.37604 9.61196 6.38685 9.72326 6.42878 9.82435C6.47071 9.92544 6.54185 10.0117 6.6331 10.0721C6.72435 10.1325 6.83156 10.1643 6.94099 10.1635L6.94019 10.0635ZM6.94019 10.0635C7.06023 10.0636 7.1754 10.016 7.26044 9.93134L12.4833 4.70759C12.5617 4.62179 12.6041 4.50905 12.6014 4.39284C12.5988 4.27663 12.5515 4.1659 12.4693 4.08371C12.3871 4.00151 12.2764 3.95419 12.1602 3.95158C12.044 3.94897 11.9312 3.99129 11.8454 4.06971L6.94019 10.0635Z" fill="#3E78ED" stroke="#3E78ED" stroke-width="0.2" />
    </svg>
}