// 机构导入结果页
import React, { Component } from 'react'
import { Select, Button, Input, Table, Modal, message,Form,Row, Col, Checkbox, Radio, DatePicker } from 'antd';
import './index.scss';
import {importRecordDetail} from "../../../../api/platform-school";
import {twoImportMembers ,againImport,twoGeneralImport} from "../../../../api/platform-business";
const { Option } = Select;

class index extends Component {
    state = {
        isModal: false, // 弹层
        nameLength: 0, // 新增弹框姓名长度
        dataSource: [],
        facultyList: [], // 院系list
        majorList: [], // 专业list
        isChoose: false,//修改弹窗
        isModify: false,//修改弹窗
        isProject: false,//修改弹窗
        isAdd: false,//不导入弹窗
        recordId: "",
        createTime: '',
        importUserName: '',
        fileName: '',
        peopleTotalNum: '',
        positionLength: 0, // 新增弹框职务长度
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        yuanxiaoSel:[],
        orgMember: {}, //机构成员json  有是修改没是新增
        orgMemberId:"", //删除时用
        highestDegreeOption:[],//学位下拉
        highestEducationOption:[],//学历下拉
        cardTypeOption:[{
            code:'1',
            name:'身份证'
        }],//证件下拉
        Batch:false,
        btnActive: false,
        errorMessage:'',
        batchRef:{},//文件
        propertyList: [],
        importStatus:""
    }

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log("jsonparam",jsonparam)
        this.setState({
            recordId: jsonparam.recordId,
            enterpriseId: jsonparam.enterpriseId,
        },()=> {
            this.importRecordDetail()
        })
    }

    async importRecordDetail(){
        const param = {
            recordId: this.state.recordId
        }
        const res = await importRecordDetail(param)
        console.log('99999999=====》》》', res)
        this.setState({
            dataSource: res.importErrVoList,
            facultyList: res.facultyList,
            majorList: res.majorList,
            createTime: res.createTime,
            importUserName: res.importUserName,
            fileName: res.fileName,
            peopleTotalNum: res.peopleTotalNum,
            errRecordId: '',
            importStatus:res.importStatus,
        })
    }
    //以下结果再校验
    commitBatch = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        twoGeneralImport({recordId:recordId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('校验成功');
                this.importRecordDetail()
                // if (Object.prototype.toString.call(res.message) === '[object String]' && res.message !== 'success') {
                //     this.setState({ errorMessage: res.message, fileName: this.batchRef.state.batchTitle });
                // }
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
            console.log("重新拉取列表")
            this.importRecordDetail()
        });
    }
    //以下结果再导入
    againImport = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        againImport({recordId:recordId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('导入成功');
                this.importRecordDetail()
                // if (Object.prototype.toString.call(res.message) === '[object String]' && res.message !== 'success') {
                //     this.setState({ errorMessage: res.message, fileName: this.batchRef.state.batchTitle });
                // }
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
            console.log("重新拉取列表")
            this.importRecordDetail()
        });
    }
    //确定导入
    sureImport = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        twoImportMembers({recordId:recordId,enterpriseId:this.state.enterpriseId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('导入成功');
                this.importRecordDetail(this.state.enterpriseId)
                // if (Object.prototype.toString.call(res.message) === '[object String]' && res.message !== 'success') {
                //     this.setState({ errorMessage: res.message, fileName: this.batchRef.state.batchTitle });
                // }
            }
        }).catch(err => {
            console.log("err",err)
        })
        // this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
        //     console.log("重新拉取列表")
        //     this.importRecordDetail()
        // });
    }
    //确定导入后跳页
    institutionsDetail = enterpriseId => {
        console.log("enterpriseId: ",enterpriseId)
        this.props.history.push(`/main/platform-business/member-detail?${encodeURI(JSON.stringify(enterpriseId))}`);
    }
     goBack = () => {
        this.props.history.push(`/main/platform-business/member-detail?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId}))}`);
    }
// 不导入
    async handImport(record) {
        Modal.confirm({
            title: '温馨提示',
            content: '您确定不导入吗',
            okText: '确定',
            cancelText: '取消',
            onOk() {
            },
            onCancel() {
            }
        });
    }
    render() {
        let { dataSource,importUserName,createTime,fileName , isModal,nameLength,provinceSel,citySel,positionLength,yuanxiaoSel,highestDegreeOption,highestEducationOption,cardTypeOption,propertyList} = this.state;
        let {peopleTotalNum,importStatus} = this.state
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '学生姓名', dataIndex: 'errUserName',key:'errUserName',align: 'center'},
            {title: '手机', dataIndex: 'errTelNum',key:'errTelNum',align: 'center'},
            {title: '工号/学号', dataIndex: 'errJobNum',key:'errJobNum',align: 'center'},
            {title: '角色', dataIndex: 'errRole',key:'',align: 'center'},
            {title: '职称', dataIndex: 'errProfessionalCall',key:'errProfessionalCall',align: 'center'},
            {title: '异常原因', dataIndex: 'errReason', key:'errReason'},
            {title: '状态', align: 'center', render: (text, item, index ) =>  <span className='serial'>
                    {
                        item.errStatus === 1 ? '校验失败' : item.importStatus === 2 ? '校验成功':
                            item.errStatus === 3 ?  '导入成功' : item.errStatus === 4 ?  '不再倒入' : ''
                    }</span>,},
            // {
            //     title: '操作',
            //     key: 'operate',
            //     align:'center',
            //     fixed:'right',
            //     width:'200',
            //     render:(record)=><div className="op-span">
            //         <span className="but" onClick={() => this.setState({ isModal: true })}>修改</span>
            //         <span className="but"  onClick={() => this.handImport(record)}>不导入</span>
            //     </div>},
        ];
        return (
            <div className='mchresult_box'>
                <div className="oneline">
                    <div className="one">
                        <p>导入人：{importUserName}</p><p>导入时间：{createTime}</p><p>导入文件名称：{fileName}</p>
                    </div>
                    <div className="two">
                        <p>导入结果：</p><p className="ok">导入成功</p>
                    </div>
                    <div className="two">
                        <p>新增成员（{peopleTotalNum}）</p> <span className='tip_title'>提示：导入角色为管理员的，如果需要修改请导入后去机构管理-进入详情-绑定或解绑对应管理员账号</span>
                    </div>
                    <div className='xian'></div>
                    <div className="five">
                        {
                            importStatus ===1?<Button type="primary" onClick={this.goBack}>返回</Button>:
                                <Button type="primary" onClick={this.sureImport}>确认导入</Button>
                        }<p>导入校验结果：{dataSource.length>0? "校验失败"+dataSource.length+"条":"暂无失败校验"}</p>
                    </div>
                </div>
                <div className="twoline">
                    <div className="topline">
                        <div className="left">
                            {/*<Button className="buttonl" type="primary" onClick={this.commitBatch}>以下结果再次校验</Button>*/}
                            {/*<Button className="buttonm" onClick={this.importSchoolTeachers}>以下结果再导入</Button>*/}
                        </div>
                        <div className="right">共失败{dataSource.length}人</div>
                    </div>
                    <div className="tablelist">
                        <Table  columns={columns} dataSource={dataSource} />
                    </div>
                </div>

                {/*修改成员弹层开始*/}
                <Modal
                    title='修改成员信息'
                    visible={isModal}
                    destroyOnClose={true}
                    closable={false}
                    className='binding_modal'
                    footer={
                        <div className='foot_botton'>
                            <Button className="addbutton" onClick={this.addOrganizatioMembers}>确认添加</Button>
                            <Button className="delete" onClick={() => this.setState({ isModal: false })}>取消</Button>
                        </div>
                    }
                >
                    <div className="modaltitle">
                        个人信息
                    </div>
                    <Form className='lt_form_wapper'>
                        <Form.Item label={`姓名`}>
                            {getFieldDecorator('orgMemberName', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入姓名`,
                                    },
                                    /*   {
                                           validator:this.nameLength
                                       }*/
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{nameLength}/20</span>
                        </Form.Item>
                        <Form.Item label={`电话`}>
                            {getFieldDecorator('telNum', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入电话`,
                                    },
                                    {
                                        validator:this.validatePhone
                                    }
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入电话" style={{ width: 294 , marginTop:"4px"}}/>)}
                        </Form.Item>
                        <Form.Item className="sex" label={`性别`}>
                            {
                                getFieldDecorator('sex', {
                                    initialValue: '',
                                })(
                                    <Radio.Group style={{ width: '100%' }}>
                                        <Radio value={0}>男</Radio>
                                        <Radio value={1}>女</Radio>
                                    </Radio.Group>
                                )
                            }
                        </Form.Item>
                        <Form.Item className='date_input' label={`出生日期`}>
                            {getFieldDecorator('birthday', {

                            })(<DatePicker   placeholder='请选择出生日期' style={{width: '100%'}} />)}
                        </Form.Item>
                        <Form.Item className='date_input' label={`籍贯`}>
                            <div className="all">
                                {/*<div className="totle">*/}
                                {/*    <span className="title">籍贯</span></div>*/}
                                {getFieldDecorator('provinceName', {
                                })(
                                    <Select placeholder='请选择省'
                                    >
                                        {
                                            provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                        }
                                    </Select>
                                )}
                                {getFieldDecorator('cityName', {
                                })(
                                    <Select placeholder='请选择市'
                                    >
                                        {
                                            citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                        }
                                    </Select>)}
                            </div>
                        </Form.Item>
                        <Form.Item label={`最高学历`}>
                            {
                                getFieldDecorator('highestEducation', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '最高学历不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选则最高学历'>
                                    {
                                        highestEducationOption && highestEducationOption.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`最高学位`}>
                            {
                                getFieldDecorator('highestDegree', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '最高学位不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选则最高学位'>
                                    {
                                        highestDegreeOption && highestDegreeOption.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`证件类型`}>
                            {getFieldDecorator('cardType', {
                            })(<Select placeholder='请选择证件类型'>
                                {
                                    cardTypeOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                }
                            </Select>)}
                        </Form.Item>
                        <Form.Item label={`证件号码`}>
                            {getFieldDecorator('idCard', {
                            })(<Input  className="input" maxLength={100}/>)}
                        </Form.Item>
                        <div className="modaltitle">
                            机构信息
                        </div>
                        <Form.Item label={`工号/帐号`}>
                            <div className="all">
                                {/*<div className="totle">*/}
                                {/*    <span className='red'>*</span><span className="title">工号/帐号</span></div>*/}

                                {getFieldDecorator('jobNum', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请填写工号/帐号",
                                        },
                                    ],
                                })(<Input placeholder="请输入工号" style={{ width: 330 , marginTop:"4px"}}/>)}
                            </div>
                        </Form.Item>
                        <Form.Item className='date_input' label={`入职日期`}>
                            <div className="all" style={{width: '330px'}}>
                                {/*<div className="totle">*/}
                                {/*    <span className="title">入职日期</span></div>*/}
                                {getFieldDecorator('inductionTime ', {

                                })(<DatePicker placeholder='请选择入职日期' style={{width: '100%'}} />)}
                            </div>
                        </Form.Item>
                        <Form.Item label={`教师类型`}>
                            {
                                getFieldDecorator('professionalCall', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '教师类型不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选择教师类型'>
                                    {
                                        propertyList && propertyList.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`负责角色`}>
                            {
                                getFieldDecorator('roleId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '负责角色不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选择负责角色'>
                                    {
                                        yuanxiaoSel && yuanxiaoSel.map(v => {
                                            return <Option value={v.roleId} key={v.roleId}>{v.roleName}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`职务`}>
                            {getFieldDecorator('position', {
                                rules: [
                                    {
                                        required: false,
                                        message: `请输入职务`,
                                    },

                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入职务" style={   { width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{positionLength}/30</span>
                        </Form.Item>
                        <Form.Item label={`邮箱`}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: false,
                                        message: "请输入邮箱",
                                    },
                                    {
                                        validator:this.validateInvestmail
                                    }
                                ]
                            })(<Input  className="input" maxLength={100}/>)}
                        </Form.Item>
                    </Form>
                </Modal>
                {/*修改成员弹层结束*/}

            </div>
        )
    }
}

index = Form.create()(index);
export default index;
