/**
 * 推荐管理
 */

import { post } from '../../until/axios_instans';
//推荐管理查询
export function getRecommendLists(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/operate/recommend/getRecommendList", data);
}

//推荐栏目列表
export function getRecommendTypeList(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/common/recommendType/getRecommendTypes", data);
}
//推荐保存
export function updateRecommend(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/operate/recommend/updateRecommend", data);
}
//资源类型列表
export function getResourceTypeList(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/common/resourceType/getResourceTypes", data);
}
//资源类型列表
export function recommendMove(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/recommend/recommendMove", data);
}

//筛选条件，热门方向列表
export function getSubjectList(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/common/subject/getSubjectList", data);
}
//筛选条件，院校专题列表
export function getSpecialList(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/common/special/getSpecialList", data);
}
//筛选条件，基础专业列表
export function getBaseSchoolMajorList(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/common/examType/baseSchoolMajor", data);
}
