/*机构管理*/
import React, {Component} from "react";
import {
    Row,
    Input,
    Table,
    Col,
    Modal, Button, Form, message, Spin
} from "antd";
import {withRouter} from "react-router";
import {selectLabel, selectAllLabelList} from "../../../../../api/peixun";
import RenderButton from "../../../../../components/renderButton";

@withRouter
class AgencyLabel extends Component {
    state = {
        columns: [
            {title: '标签名称', dataIndex: 'labelName', key: 'labelName', width: 150, align: 'left'},
            {
                title: '使用状态',
                dataIndex: 'isDisable',
                key: 'isDisable',
                align: 'center',
                render: (text, record) => {
                    return (
                        record.isDisable === 0 ? <span>正常</span> : <span style={{color: '#E34949'}}>禁用</span>
                    )
                },
            },
            {
                title: '来 源',
                dataIndex: 'labelType',
                key: 'labelType',
                align: 'center',
                render: (text, record) => {
                    return (
                        <span>机构自建</span>
                    )
                },
            },
            {
                title: '在用机构数',
                dataIndex: 'enterpriseNum',
                key: 'enterpriseNum',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'x',
                align: 'center',
                render: (text, record) => <div
                    className="render-span">
                    <div className="open-span">
                        <RenderButton id='OBS-TS-02-03-b_btnViewTab'>
                            <span onClick={() => this.handLink(record)}>查看</span>
                        </RenderButton>
                    </div>
                </div>,
            },
        ],
        data: [],
        enterpriseId: "159428a764d54d53991c8b37cce049d0",
        labelName: "",
        modelType: 'add',
        pageNum: 1,//当前页数
        pageSize: 10,//每页数据数量
        total: 0,//总条数
    }

    componentDidMount() {
        this.selectAllLabelList()
    }

    selectAllLabelList = async () => {
        const {pageNum, pageSize,labelName} = this.state
        let data = {
            pageNum,
            pageSize,
            labelName
        }
        await selectAllLabelList(data).then(e => {
            this.setState({data: e.data, total: e.total})
        })
    }

    handLink = (record) => {
        let data = {
            id: record.labelId,
            labelName: record.labelName,
        }
        this.props.history.push({
            pathname: '/main/training-center/useAgency',
            search: JSON.stringify(data)
        })
    }
//新增分类
    classifyAdd = (type, record) => {
        let value = record ? record.labelName : ''
        console.log("val", value)
        this.setState({
            labelVisibily: true,
            modelType: type,
            labelId: record.id
        }, () => {
            this.props.form.setFieldsValue({'labelName': value})
        })

    }

    showDisable = (id, type) => { // 禁用提示
        this.setState({
            id: id,
            isDisable: type
        })
        this.hint({
            'title': '温馨提示',
            'content': `此操作会${type === 0 ? '启动' : '禁用'}该数据，确定${type === 0 ? '启动' : '禁用'}吗？`,
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': this.disableLabel
        })
    }
    disableLabel = async () => {
        this.mesSuccess("禁用成功", 2000)
    }

    showDeleteConfirm = (id) => { // 删除提示
        this.setState({
            id: id
        })
        this.hint({
            'title': '温馨提示',
            'content': '此操作会永久删除该数据，确定删除吗？',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': this.deleteLabel
        })
    }

    //删除资源
    deleteLabel = async () => {
        this.mesSuccess("删除成功", 2000)
    }
    getLabelName = (e) => {
        this.setState({  pageNum:1}, () => {
            this.selectAllLabelList()
        })
    }
    handleSearch=(e)=>{
        this.setState({
            labelName: e.target.value
        })

    }
    //二级模糊查询
    handleWebsiteChange = value => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            //请求方法
            autoCompleteResult = ['11', '222', '333'];
        }
        this.setState({autoCompleteResult});
    };
    showConfirm = () => {
        this.setState({
            confirmVisibily: true
        })
    }
    relevanceShow = (item) => {
        console.log('item11111111', item)
        // gl_lable_name
        this.setState({
            relevanceVisibily: true,
            relationId: item.id,
            gl_lable_name: item.labelName
        })

    }
    handleConfirmOk = () => {
        this.setState({
            cancelConfirmOk: false
        }, () => {
            this.mesSuccess("删除成功")
        })
    }
    cancelConfirmOk = () => {
        this.setState({
            relevanceVisibily: false
        })
    }
    changePage = (pageNum) => {
        this.setState({
            pageNum: pageNum
        }, () => {
            //列表方法
            this.selectAllLabelList()
        })
    }
    callback = async (key) => {
        await this.setState({activeKey: key})
    };

    render() {
        const {columns, data, autoCompleteResult, labelVisibily, confirmVisibily, relevanceVisibily, modelType, pageNum, pageSize, total, tableLoading, relationId, gl_lable_name, activeKey} = this.state
        const {getFieldDecorator} = this.props.form;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            onChange: this.changePage
        }
        return (
            <div className="AgencyLabel" style={{padding: '0'}}>
                <div className="top">
                    <Col span={8} className="sort_boxs">
                    </Col>
                    <Col className='top_r btn_box' span={16}>
                        <span className="li_span">共有{total}个{this.props.activeKey===1?"平台创建标签":'机构创建标签'}</span>
                        <div className='search-box'>
                            <Input.Search className="type-input" placeholder='输入标签名称'  onChange={(e)=>{this.handleSearch(e)}} onSearch={this.getLabelName}
                                          value={this.state.labelName} />
                        </div>
                    </Col>
                </div>
                {
                    tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                        <Table
                            className='label-table'
                            columns={columns}
                            dataSource={data}
                            pagination={pagination}
                            locale={{
                                emptyText: <>
                                    <div className="Empty-conet">
                                        <div className="img">
                                            <img src={require('../../../../../assets/img/zixun/pic-4.png')}/>
                                            <span>暂无数据</span>
                                        </div>
                                    </div>
                                </>
                            }}
                        />
                    )
                }
            </div>
        );
    }
}

AgencyLabel = Form.create()(AgencyLabel);
export default AgencyLabel;
