/*任务管理*/
import React, {Component} from "react";
import {
    Input,
    Table,
    AutoComplete,
    Col,
    Select,
    Tree, Modal, Button, Form, Spin, message,
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import {selectNewsCategory, selectPlatformForCategory, deleteNewsCategory,updateNewsCategory,saveNewsCategorySort} from "../../../../api/information";
import "../../../../until/libs/cookie";
import RenderButton from "../../../../components/renderButton";
import allSystems from "@/assets/img/all-systems.png"
import allSystemsSelected from "@/assets/img/all-systems-selected.png"
import trainingSystems from "@/assets/img/training-system.png"
import trainingSystemsSelected from "@/assets/img/training-system-selected.png"
import trainingnews from "@/assets/img/training-news.png"
import trainingnewsSelected from "@/assets/img/training-news-selected.png"
import big from "@/assets/img/big.png"
import bigSelected from "@/assets/img/big-selected.png"


// import allSystems from "*.png";
// import allSystemsSelected from "*.png";
// import trainingSystems from "*.png";
// import trainingSystemsSelected from "*.png";
// import trainingnews from "*.png";
// import trainingnewsSelected from "*.png";
// import big from "*.png";
// import bigSelected from "*.png";
//拖拽需要开始
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import S from 'gongyongicon';
const {Option, OptGroup} = Select;
const AutoCompleteOption = AutoComplete.Option;
const {TreeNode} = Tree;
const {confirm} = Modal;
const { TextArea } = Input

let dragingIndex = -1;
function compareSortFunction(propeties) {
    let compareSort = (rowa, rowb) => sortFunction(rowa[propeties], rowb[propeties]);
    return compareSort;
}

function sortFunction(a, b) {
    if (!a) {
        a = "";
    }
    if (!b) {
        b = "";
    }
    if ((typeof a) !== "number") {
        return a.localeCompare(b);
    }
}
class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);
//拖拽结束
@withRouter
class Column extends Component {
    state = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        columns: [
            {title: '栏目名称', dataIndex: 'categoryName', key: 'categoryName',  width: 200,align: 'left',ellipsis: true},
            {title: '所属业务系统', dataIndex: 'platformName', key: 'platformName', width: 200, align: 'center'},
            {title: '资讯总条数', dataIndex: 'newsCount', key: 'newsCount', width: 200, align: 'center'},
            {title: '栏目级别', dataIndex: '', key: '', width: 200, align: 'center', render: (text,record) =>{
                    if(record.parentId = '0'){
                        return '一级'
                    }else{
                        return '二级'
                    }}
            },
            {title: '创建时间', dataIndex: 'createTime', key: 'createTime', width: 240, align: 'center',
            // sorter: compareSortFunction("updateTime"),defaultSortOrder :'descend'
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'x',
                align: 'center',
                width: 200,
                render:(text, record) => <div className="render-span">
                    <span onClick={()=> this.setState({classifyEditVisibily: true,id: record.id,categoryName: record.categoryName})}>修改</span> | <span onClick={() => this.showDeleteConfirm(record)}>删除</span></div>,
            },
        ],
        data: [],
        tableLoading: false, // 是否显示加载中
        tabList: [],
        tabIndex: 0,
        classifyEditVisibily: false, // 编辑弹框
        categoryName: '', // 编辑栏目名称
        id: '',
        organizationId: null,
        asc: false,
        releaseTerminal:1,
        paixunshow: false,//是否使用拖拽
        dataSortId:[],//拖拽排序后的ID
    }

    componentDidMount() {
        this.selectNewsCategory()
        this.selectNewsCategoryListPage()
    }
    //加载业务系统列表
    selectNewsCategory = () => {
        let data={
            organizationId: this.state.organizationId,
            useType: 1
        }
        selectPlatformForCategory(data).then(e => {
            let count = 0;
            e.forEach((cur,index,arr) =>{
                count += cur.count
            })
            e.splice(0, 0, {
                platform:'',
                platformName: '全部系统',
                count:count
            });
            this.setState({tabList: e})
            let tabList=this.state.tabList
            console.log("tabList",tabList)
            const imgUrl="cvec-space-front-guanli/zixun/icon/"
            for(let i in tabList){
                console.log(tabList[i])
                switch (true){
                    case tabList[i].platformName==="全部系统":
                        tabList[i].img=imgUrl+"all-systems.png"
                        tabList[i].imgSelected=imgUrl+"all-systems-selected.png"
                        break;
                    case tabList[i].platformName==="平台门户":
                        tabList[i].img=imgUrl+"training-news.png"
                        tabList[i].imgSelected=imgUrl+"training-news-selected.png"
                        break;
                    case tabList[i].platformName==="双高系统":
                        tabList[i].img=imgUrl+"big.png"
                        tabList[i].imgSelected=imgUrl+"big-selected.png"
                        break;
                    default :
                        tabList[i].img=imgUrl+"training-news.png"
                        tabList[i].imgSelected=imgUrl+"training-news-selected.png"
                        break;
                }
            }


        })
    }
    //加载列表
    selectNewsCategoryListPage = () => {
        let platform = '';
        if(this.state.tabList.length>0){
            platform = this.state.tabList[this.state.tabIndex].platform;
        }
        this.setState({
            platform:platform
        })
        let data = {
            platform: platform,
            organizationId: this.state.organizationId,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            asc:this.state.asc,
            useType:1,
            releaseTerminal:this.state.releaseTerminal
        }
        selectNewsCategory(data).then(e => {
            console.log('e.data',e.data)
            this.setState({data: e.data,total:e.total,tableLoading:false})
        })
    }

    // 删除提示
    showDeleteConfirm = (item) => {
        this.setState({
            id: item.id
        })
        this.hint({
            'title': '温馨提示',
            'content': '请确认是否要删除('+item.categoryName+')此栏目,删除后栏目下的新闻也将删除，请慎重操作，删除后可点击新增进行添加栏目。',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': this.delete
        })
    }

    delete = () =>{
        const {id} = this.state
        let data = {
            categoryId: id
        }
        deleteNewsCategory(data).then((res) => {
            if(res){
                this.mesSuccess('删除成功', 1000)
                this.selectNewsCategory()
                this.selectNewsCategoryListPage()
                this.setState({paixunshow: false})
            }else {
                this.mesError("删除失败")
            }
        })
    }
    //选择业务系统
    changeSearch = index =>{
        this.setState({
            tabIndex: index,
            pageNum: 1
        },()=>{
            this.selectNewsCategoryListPage()
        })
        let tabList=this.state.tabList
        const imgUrl="cvec-space-front-guanli/zixun/icon/"
        for(let i in tabList){
            console.log(tabList[i])
            switch (true){
                case tabList[i].platformName=="全部系统":
                    tabList[i].img=imgUrl+"all-systems.png"
                    tabList[i].imgSelected=imgUrl+"all-systems-selected.png"
                    break;
                case tabList[i].platformName=="平台门户":
                    tabList[i].img=imgUrl+"training-system.png"
                    tabList[i].imgSelected=imgUrl+"training-system-selected.png"
                    break;
                case tabList[i].platformName=="活动大赛":
                    tabList[i].img= imgUrl+"training-news.png"
                    tabList[i].imgSelected=imgUrl+"training-news-selected.png"
                    break;
                case tabList[i].platformName=="大国工匠":
                    tabList[i].img=imgUrl+"big.png"
                    tabList[i].imgSelected=imgUrl+"big-selected.png"
                    break;
                case tabList[i].platformName=="平台门户":
                    tabList[i].img=imgUrl+"training-news.png"
                    tabList[i].imgSelected=imgUrl+"training-news-selected.png"
                    break;
                case tabList[i].platformName=="双高系统":
                    tabList[i].img=imgUrl+"big.png"
                    tabList[i].imgSelected=imgUrl+"big-selected.png"
                    break;

            }
        }
    }

    //编辑change
    changeEdit = (e) => {
        let categoryName = e.target.value.replace(/\s+/g, '')
        this.setState({
            categoryName: categoryName
        })
    }
    //分页切换
    changePage=(pageNum)=>{
        this.setState({
            pageNum:pageNum
        },()=>{
            //列表方法
            this.selectNewsCategoryListPage()
        })
    }

    showSizeChange = (current, size) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            this.selectNewsCategoryListPage()
        })
    }
    // 新增编辑栏目
    classifyAdd = (type) => {
        this.props.history.push('/main/group-column/addColumn')
    }

    //修改
    update = () => {
        if(this.state.categoryName === ''){
            message.error('栏目名称不能为空');
        }else{
            let data = {
                id: this.state.id,
                organizationId: this.state.organizationId,
                categoryName: this.state.categoryName
            }
            updateNewsCategory (data).then(e =>{
                if (e === true) {
                    message.error('栏目名称已存在');
                }else{
                    message.success('修改成功');
                    this.setState({
                        classifyEditVisibily:false
                    })
                    this.componentDidMount()
                }
            })
        }
    }
    //table 时间列排序
    tableOnChange =(pagination, filters, sorter, extra) =>{
        let asc= true
        if(typeof(sorter.order)!=='undefined'){
            if(sorter.order.replace('end','') ==='asc'){
                asc = false
            }
        }
        this.setState({
            asc:asc
        },()=>{
            this.selectNewsCategoryListPage()
        })
    }
    //拖拽开始
    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    moveRow = (dragIndex, hoverIndex) => {
        const { data } = this.state;
        const dragRow = data[dragIndex];
        this.setState(
            update(this.state, {
                data: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            }),() => {
                let dataSortId = []
                this.state.data.map((item,index)=>{
                    dataSortId.push(item.id)
                })
                this.setState({dataSortId})
            }
        );
    };
    //拖拽结束
    // 排序
    paixun = () =>{
        this.setState({paixunshow: true})
    }
    // 保持排序
    keepSorted = () =>{
        this.setState({paixunshow: false})
        let data = this.state.dataSortId
        saveNewsCategorySort(data).then(res=>{
            console.log('ressss',res)
            this.selectNewsCategoryListPage()
        })

    }
    // 取消排序
    cancelSorted = () =>{
        this.setState({paixunshow: false})
        this.selectNewsCategoryListPage()
    }
    render() {
        const {columns, data, pageNum, pageSize, total,tableLoading,tabList,tabIndex,classifyEditVisibily,categoryName,id} = this.state
        const {getFieldDecorator} = this.props.form;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
            onChange: this.changePage,
            onShowSizeChange: this.showSizeChange
        }
        return (
            <div className='cloumn_center'>
                <div className='tob_tab'>
                    <img src={require('../../../../assets/img/lmglbg.png')} className={'lmglbg'} alt={''}/>
                    {/*<div className='top_title'>业务系统</div>*/}
                    <div className='tab_btn_group'>
                        {
                            tabList.map((item,index) => {
                                return (
                                    <div
                                        key={index}
                                        className={tabIndex===index?'active_tab':'no_active_tab'}
                                        onClick={() => this.changeSearch(index)}
                                    >
                                        <div>
                                            <div className='active_title'>
                                                <div>{item.platformName}</div>
                                                {/*<img src='http://top.bdimg.com/frontend/static/index/index.jpg'/>*/}
                                                {tabIndex===index? <img src={ window.$$img(item.imgSelected)}/>: <img src={ window.$$img(item.img)}/>}
                                            </div>
                                            <div className='ln_number'>栏目：{item.count}</div>
                                        </div>
                                        {/*<div>*/}
                                        {/*    <div className='active_title'>*/}
                                        {/*        {item.platformName}</div>*/}
                                        {/*    <div className='ln_number'>栏目：{item.count}</div>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    {*/}
                                        {/*        tabIndex===index?<img src={item.img_act}/>:<img src={item.img}/>*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="organs bg_fff" style={{padding:'0'}}>
                    <div className="top">
                        {this.state.paixunshow ? <Col span={24} className="sort_boxs">
                            <Button className='sure_sort' type='primary' onClick={()=>this.keepSorted()}>保持排序</Button>
                            <Button className='sure_sort quxiao_sort' onClick={()=>this.cancelSorted()}>取消排序</Button>
                            <span className={'tiptext'}><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.40137 12.9014C3.08756 12.9014 0.401367 10.2152 0.401367 6.90137C0.401367 3.58756 3.08756 0.901367 6.40137 0.901367C9.71518 0.901367 12.4014 3.58756 12.4014 6.90137C12.4014 10.2152 9.71516 12.9014 6.40137 12.9014ZM6.65479 3.40246H6.40448C6.12443 3.40246 6.15451 3.62153 6.15451 3.90274V7.90506C6.15451 8.18665 6.12443 8.40534 6.40448 8.40534H6.65479C6.93519 8.40534 6.90476 7.93633 6.90476 7.65508V3.90274C6.90477 3.62153 6.93484 3.40246 6.65479 3.40246ZM6.52826 9.15637C6.1823 9.15637 5.90185 9.43641 5.90185 9.78121C5.90185 10.126 6.1823 10.4057 6.52826 10.4057C6.8743 10.4057 7.15468 10.1261 7.15468 9.78121C7.15468 9.43641 6.87428 9.15637 6.52826 9.15637Z" fill="#E75301"/>
</svg>拖拽排序后请保存，第一行拖拽无效且无法改变顺序</span>
                        </Col> : <Col span={12} className="sort_boxs">
                            <RenderButton id='NC_01_B01_c_btnaddcolumn'>
                                <Button className='sure_sort' onClick={() => {
                                    this.classifyAdd('add', '')
                                }} type='primary'>新增栏目</Button>
                            </RenderButton>
                            <span className={'paixu'} onClick={()=>this.paixun()}><S.SortSvg/>排序</span>
                        </Col>}
                    </div>
                    {
                        tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                            <div>
                                {
                                    this.state.paixunshow ?<DndProvider backend={HTML5Backend}>
                                        <Table
                                            className='organ-table organ-table-live'
                                            columns={columns}
                                            onChange={this.tableOnChange}
                                            pagination={pagination}
                                            components={this.components}
                                            onRow={(record, index) => ({
                                                index,
                                                moveRow: this.moveRow,
                                            })}
                                            expandedRowRender={
                                                (record) => {
                                                    return (
                                                        record?.nextLevelCategory?.map((item, index) => {
                                                            return (
                                                                <ul key={index} className='table-list' style={{'width':'100%'}}>
                                                                    <li className="li-name" style={{'textAlign': 'left', 'width': '20.8%'}}>
                                                                        <span></span> {item.categoryName}
                                                                    </li>
                                                                    <li style={{'textAlign': 'left','width': '18.1%'}}>{item.platformName}</li>
                                                                    <li style={{'textAlign': 'left','width': '15.8%'}}>{item.newsCount}</li>
                                                                    <li style={{'textAlign': 'left','width': '14.9%'}}>二级</li>
                                                                    <li style={{'textAlign': 'left','width': '19.9%'}}>{item.updateTime}</li>
                                                                    <li style={{'textAlign': 'left','width': '7%'}} className="render-span">
                                                                        <RenderButton id='NC_01_B01_c_btnrevise'>
                                                                            <span onClick={()=> this.setState({classifyEditVisibily: true,id: item.id,categoryName: item.categoryName})} >修改</span>
                                                                        </RenderButton>丨<RenderButton id='NC_01_B01_c_btndelete'><span onClick={() => this.showDeleteConfirm(item)}>删除</span></RenderButton>
                                                                    </li>
                                                                </ul>)
                                                        })
                                                    )
                                                }
                                            }
                                            dataSource={data}
                                            rowClassName={record => record.nextLevelCategory && record.nextLevelCategory.length === 0 && 'noExpand'}
                                            locale={{
                                                emptyText: <>
                                                    <div className="Empty-conet-zixun">
                                                        <div className="img">
                                                            <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                                            <span>暂无数据</span>
                                                        </div>
                                                    </div>
                                                </>
                                            }}
                                        />
                                    </DndProvider> : <Table
                                        className='organ-table organ-table-live'
                                        columns={columns}
                                        onChange={this.tableOnChange}
                                        pagination={pagination}
                                        expandedRowRender={
                                            (record) => {
                                                return (
                                                    record?.nextLevelCategory?.map((item, index) => {
                                                        return (
                                                            <ul key={index} className='table-list' style={{'width':'100%'}}>
                                                                <li className="li-name" style={{'textAlign': 'left', 'width': '20.8%'}}>
                                                                    <span></span> {item.categoryName}
                                                                </li>
                                                                <li style={{'textAlign': 'left','width': '18.1%'}}>{item.platformName}</li>
                                                                <li style={{'textAlign': 'left','width': '15.8%'}}>{item.newsCount}</li>
                                                                <li style={{'textAlign': 'left','width': '14.9%'}}>二级</li>
                                                                <li style={{'textAlign': 'left','width': '19.9%'}}>{item.updateTime}</li>
                                                                <li style={{'textAlign': 'left','width': '7%'}} className="render-span">
                                                                    <RenderButton id='NC_01_B01_c_btnrevise'>
                                                                        <span onClick={()=> this.setState({classifyEditVisibily: true,id: item.id,categoryName: item.categoryName})} >修改</span>
                                                                    </RenderButton>丨<RenderButton id='NC_01_B01_c_btndelete'><span onClick={() => this.showDeleteConfirm(item)}>删除</span></RenderButton>
                                                                </li>
                                                            </ul>)
                                                    })
                                                )
                                            }
                                        }
                                        dataSource={data}
                                        rowClassName={record => record.nextLevelCategory && record.nextLevelCategory.length === 0 && 'noExpand'}
                                        locale={{
                                            emptyText: <>
                                                <div className="Empty-conet-zixun">
                                                    <div className="img">
                                                        <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                                        <span>暂无数据</span>
                                                    </div>
                                                </div>
                                            </>
                                        }}
                                    />
                                }
                            </div>
                        )}
                </div>
                <Modal
                    title={'请修改下方栏目名称'}
                    width={660}
                    wrapClassName='modal_con'
                    visible={classifyEditVisibily}
                    destroyOnClose={true}
                    closable={false}
                    id={id}
                    categoryName={categoryName}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={()=> this.setState({classifyEditVisibily: false})}>取消</Button>
                            <Button type='primary' onClick={this.update}>确定</Button>
                        </div>
                    }>
                    <div className='transfer-c'>
                        <div className='input_wapper'>
                            {/*<Input  onChange={this.changeEdit.bind(this)} value={categoryName} placeholder="请输入栏目名称" maxLength={20} suffix={<span className='lens'>*/}
                            {/*                    {categoryName ? categoryName.length : 0}/20</span>} className='edit_long_short'/>*/}
                            <TextArea  onChange={this.changeEdit.bind(this)} value={categoryName} placeholder="请输入栏目名称" maxLength={20}
                                       autoSize={{ minRows: 3, maxRows: 5 }} />

                            <span className="suffixStyle">{categoryName ? categoryName.length : 0}/20</span>
                        </div>
                    </div>
                </Modal>
            </div>

        );
    }
}

Column = Form.create()(Column);
export default Column;
