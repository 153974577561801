import React, { Component } from 'react';
import { Select, Button, Input, Table,Tabs, Modal, message } from 'antd';
import { virtualOrgMembers } from '../../../../api/platform-school/index'
import './index.scss';
import moment from "moment";
const { Option } = Select;
const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

// const Demo = () => (
//     <Tabs defaultActiveKey="1" onChange={callback}>
//         <TabPane tab="全部" key="1">
//         </TabPane>
//         <TabPane tab="未处理" key="2">
//         </TabPane>
//         <TabPane tab="已处理" key="3">
//         </TabPane>
//     </Tabs>
// );


export default class index extends Component {

    state = {
        listData:[],//虚拟机构数据
        pageNum:1,//页码
        pageSize: 10,
        total: 0,
        virtualEnterpriseName: ''


    }
    componentWillMount() {
        this.virtualOrgMembers()
    }
    async virtualOrgMembers(){
        // const {pageNum}=this.state
        const param = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            virtualEnterpriseName: this.state.virtualEnterpriseName
        }
        const res = await virtualOrgMembers(param)
        console.log('res 7707', res)
        this.setState({
            listData: res.data,
            total: res.total
        })
    }

    toTeacher(record){
        let data = {
            enterpriseId: record.id
        }
        this.props.history.push({
            pathname: '/main/platform-business/vir-teamanage',
            search: JSON.stringify(data)
        })
    }
    toStudent(record){
        let data = {
            enterpriseId: record.id
        }
        this.props.history.push({
            pathname: '/main/platform-business/vir-stumanage',
            search: JSON.stringify(data)
        })
    }

    //检索
    search = (e) => {
        this.setState({ virtualEnterpriseName: e }, () => this.virtualOrgMembers());
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.virtualOrgMembers());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.virtualOrgMembers());
        window.elGoTop && window.elGoTop('body');
    }

    render() {
        let { listData,total,pageSize } = this.state;
        const {RenderButton} = React;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: 'logo', dataIndex: 'enterpriseLogo',key:'enterpriseLogo',align: 'center',width: 124,
                render: (text, item, index) => {
                    if (item.enterpriseLogo !== null){
                        //不为空展示logo
                        return <div className='not_opens'>
                            <div className="img_top">
                                <img  src={window.$$uploadRc(item.enterpriseLogo)} alt="" />
                            </div>
                        </div>
                    }else{
                        return <div className='not_opens'>
                            <div className="img_top">
                                <img src={require('@/assets/img/not_open.png')} alt="" />
                            </div>
                        </div>
                    }
                }
            },
            {title: '虚拟机构名称', dataIndex: 'enterpriseName',key:'enterpriseName', },
            {title: '简称', dataIndex: 'shorterForm',key:'shorterForm', },
            {title: '机构识别码', dataIndex: 'enterpriseCode',key:'enterpriseCode', },
            {title: '所属范围', dataIndex: 'manageRangeName', key: 'useRange', align: 'center', render: (text, item) => item.manageRange === '0'? '全国':item.manageRangeName.join(",")},
            {title: '加入日期', dataIndex: 'settledDate', key: 'settledDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '到期日期', dataIndex: 'settledEndDate', key: 'settledEndDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '教师人数', dataIndex: 'teacherNum',key:'teacherNum',},
            {title: '学生人数', dataIndex: 'studentNum',key:'studentNum',},
            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:'200',
                render:(text, record)=> {
                    return <div className="op_span">
                        <div className="main">
                            <RenderButton id='OBS-OM-01-07-a_btnTeacher'>
                            <span className="but" onClick={() =>{this.toTeacher(record)}}>教师管理</span>
                            </RenderButton>
                            <RenderButton id='OBS-OM-01-07-a_btnStudent'>
                            <span className="but" onClick={() =>{this.toStudent(record)}}>学生管理</span>
                            </RenderButton>
                        </div>
                    </div>
                }},
        ];

        return (
            <div className='managestu_boxsss'>
                <div className="twolist">
                    <div className="top_list">
                        <div className="leftlist">
                            {/*<Demo />*/}
                        </div>
                        <div className="right_list">
                            {/*<span className="number">共15条记录</span>*/}
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入虚拟机构名称' />
                        </div>
                    </div>
                    <div className="table_list">
                        <Table rowKey={v => v['enterpriseId']} pagination={
                            {
                                total,
                                pageSize:pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['5', '10', '20', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns} dataSource={listData} scroll={{ x: 2500}} />
                    </div>
                </div>

            </div>
        )
    }
}
