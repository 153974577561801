import { createHashHistory } from 'history';
const history = createHashHistory();

export default {
    CHANGE_ACTIVE(state, { data }) {
        state.activeKey = data;
        sessionStorage.setItem('header_menu_key', data);
    },
    GET_ROLES(state, { data }) {
        state.buttonRole = data;
    },
    GET_MENU(state, { data }) {
        let hash = window.location.hash.slice(1);
        function jump(url) {
            if (hash === '/' || hash === '/login' && url) {
                history.push(url);
            }
        }
        const path = data[0]?.children[0]?.menuUrl;
        if (path) {
            jump(path);
        } else {
            jump(data[0]?.children[0]?.children[0]?.menuUrl);
        }

        state.menuList = data;


    }
}