import React, { Component } from 'react'
import { Button, Form, Input, Row, Col, Select, Divider, Table, Pagination } from 'antd';
import S from 'gongyongicon';
import { get } from 'lodash';

import ConfigureApplicationModal from '../../components/config_application_modal';
import TextOverflowEllipsis from '../../components/text_overflow_ellipsis';
import { getSchoolIntoManagePage, getSchoolType, getSchoolLevel } from '../../api/school_settlement';
import { queryProvince, queryCity } from '../../api/components';

import style from './index.module.scss';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xxl: { span: 5 },
        xl: { span: 6 },
        lg: { span: 6 },
    },
    wrapperCol: {
        xxl: { span: 18 },
        xl: { span: 16 },
        lg: { span: 16 },
    },
};
export default Form.create()(class School_settlement_manage extends Component {
    state = {
        isExpand: false,
        data: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        configAppVisible: false,
        provinceData: [],
        cityData: [],
        schoolTypeData: [],
        schoolLevelData: [],
        enterpriseId: '',
    }
    async componentDidMount() {
        const provinceData = await queryProvince();
        const schoolTypeData = await getSchoolType();
        const schoolLevelData = await getSchoolLevel();
        this.setState({
            provinceData,
            schoolTypeData,
            schoolLevelData,
        })
        this.getSchoolIntoManagePage();
    }
    getSchoolIntoManagePage = async () => {
        const { pageNum, pageSize } = this.state;
        const value = this.props.form.getFieldsValue();
        const res = await getSchoolIntoManagePage({
            ...value,
            pageNum,
            pageSize,
        });
        this.setState({
            data: get(res, 'data', []),
            total: get(res, 'total', 0),
        })
    }
    handleReset = () => {
        this.props.form.resetFields();
        this.getSchoolIntoManagePage();
    }
    //分页
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => {
            this.getSchoolIntoManagePage();
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({ pageNum: 1, pageSize }, () => {
            this.getSchoolIntoManagePage();
        })
    };
    handleCancel = () => {
        this.setState({ configAppVisible: false })
    }
    handleConfigAppVisible = (enterpriseId) => {
        this.setState({
            configAppVisible: true,
            enterpriseId,
        })
    }
    handleProvinceChange = async (provinceCode)=>{
        const cityData = await queryCity({provinceCode});
        this.setState({
            cityData,
        })
        this.props.form.setFieldsValue({
            cityId: undefined
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            isExpand, data, pageNum, pageSize, total,
            configAppVisible,
            provinceData, cityData, schoolTypeData=[], schoolLevelData=[],
            enterpriseId, 
        } = this.state;
        const { RenderButton } = React;
        const columns = [
            { 
                title: '序号', 
                dataIndex: 'xh', 
                key: 'xh', 
                align: 'center',
                render:(text,r,i) => i+1
            },
            {
                title: '学校名称',
                dataIndex: 'enterpriseName',
                key: 'enterpriseName',
                align: 'center',
                width: 200,
                render: (text) => <TextOverflowEllipsis text={text} />
            },
            {
                title: '学校类型',
                dataIndex: 'schoolTypeName',
                key: 'schoolTypeName',
                align: 'center',
            },
            {
                title: '所在地',
                dataIndex: 'cityName',
                key: 'cityName',
                align: 'center',
            },
            {
                title: '办学层次',
                dataIndex: 'schoolRunLevelName',
                key: 'schoolRunLevelName',
                align: 'center',
            },
            {
                title: '开通时间',
                dataIndex: 'settledDate',
                key: 'settledDate',
                align: 'center',
            },
            {
                title: '到期时间',
                dataIndex: 'settledEndDate',
                key: 'settledEndDate',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'op',
                key: 'op',
                align: 'center',
                width: 300,
                render: (_, r) => {
                    const { id, enterpriseName } = r;
                    return (
                        <div className="action vertical_j">
                            <RenderButton id="OBSMM-01-A01-b-btnGoInNeirong">
                            <span onClick={() => { this.props.history.push(`/main/institutional-settlement/school_settlement_detail?id=${id}&name=${enterpriseName}学校详情`) }}>进入详情</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-A01-b-btnUpdataSchoolInformation">
                            <span onClick={() => this.props.history.push(`/main/institutional-settlement/college_information_entry?id=${id}`)}>编辑学校信息</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-A01-b-btnConfigureApplication">
                            <span onClick={() => this.handleConfigAppVisible(id)}>配置应用</span>
                            </RenderButton>
                        </div>
                    )
                }
            },
        ];

        return (
            <div className={style.school_settlement_manage}>
                <div className={style.search}>
                    <Form {...formItemLayout} colon={false}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="学校类型">
                                    {getFieldDecorator("schoolType", {

                                    })(
                                        <Select
                                            placeholder="请选择学校类型"
                                        >
                                            {schoolTypeData.map(item => <Option key={item.schoolTypeCode} value={item.schoolTypeCode}>{item.schoolTypeName}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="办学层次">
                                    {getFieldDecorator("schoolRunLevel", {

                                    })(
                                        <Select
                                            placeholder="请选择办学层次"
                                        >
                                            {schoolLevelData.map(item => <Option key={item.schoolLevelCode} value={item.schoolLevelCode}>{item.schoolLevelName}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {isExpand ? (<>
                                <Col span={6}>
                                    <Form.Item label="所在地">
                                        {getFieldDecorator("provinceId", {

                                        })(
                                            <Select
                                                placeholder="请选择所在地"
                                                onChange={this.handleProvinceChange}
                                            >
                                                {provinceData.map(item => <Option value={item.provinceCode}>{item.name}</Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        {getFieldDecorator("cityId", {

                                        })(
                                            <Select
                                                placeholder="请选择所在市"
                                            >
                                                {cityData.map(item => <Option value={item.cityCode}>{item.name}</Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </>) : (
                                <Col span={12} className={style.expand}>
                                    <RenderButton id="OBSMM-01-A01-b-btnReset">
                                    <Button style={{ marginRight: 20 }} onClick={this.handleReset}>重 置</Button>
                                    </RenderButton>
                                    <RenderButton id="OBSMM-01-A01-b-btnSearch">
                                    <Button type="primary" onClick={this.getSchoolIntoManagePage}>查 询</Button>
                                    </RenderButton>
                                    <div onClick={() => { this.setState({ isExpand: true }) }} style={{ color: '#3e78ed', cursor: 'pointer', marginLeft: 20 }}>
                                        <span style={{ paddingRight: 5 }}>展开</span><S.XiaArrow />
                                    </div>
                                </Col>
                            )}
                        </Row>
                        {isExpand && (
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item label="学校名称" style={{ marginBottom: 14 }}>
                                        {getFieldDecorator("schoolName", {

                                        })(
                                            <Input placeholder="请输入学校名称" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={18} className={style.expand}>
                                    <RenderButton id="OBSMM-01-A01-b-btnReset">
                                    <Button style={{ marginRight: 20 }} onClick={this.handleReset}>重 置</Button>
                                    </RenderButton>
                                    <RenderButton id="OBSMM-01-A01-b-btnSearch">
                                    <Button type="primary" onClick={this.getSchoolIntoManagePage}>查 询</Button>
                                    </RenderButton>
                                    <div onClick={() => { this.setState({ isExpand: false }) }} style={{ color: '#3e78ed', cursor: 'pointer', marginLeft: 20 }}>
                                        <span style={{ paddingRight: 5 }}>收起</span><S.ShangArrow />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
                <div className={style.table_list}>
                    <div className={style.create_school}>
                        <RenderButton id="OBSMM-01-A01-b-btnAddSchool">
                        <Button type="primary" onClick={() => this.props.history.push('/main/institutional-settlement/college_information_entry')}>创建学校</Button>
                        </RenderButton>
                        <div>共{total}个院校入驻</div>
                    </div>
                    <Divider />
                    <div className={style.antd_table_wrap}>
                        <Table
                            rowKey="id"
                            dataSource={data}
                            columns={columns}
                            pagination={false}
                            scroll={{
                                y: true
                            }}
                            locale={{
                                emptyText: <img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                            }}
                            footer={() => {
                                return <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                    onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                    onChange={p => this.pageChange(p)}
                                />
                            }}
                        />
                    </div>
                </div>
                <ConfigureApplicationModal
                    configAppVisible={configAppVisible}
                    onCancel={this.handleCancel}
                    enterpriseId={enterpriseId}
                />
            </div>
        )
    }
})
