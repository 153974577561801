import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import '../index.scss';
import {showSet} from '@/api/craftsman-app/index'

const {Option} = Select;

class ConfigureToHomeModel extends React.Component {
    state = {
        moduleList:{},
        discoverId:'',
        courseCode:''
    }
    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.moduleVisible&&this.state.discoverId!==nextProps.discoverId ) {
            console.log("nextProps.discoverId---",nextProps.discoverId)
            this.setState({
                moduleList: nextProps.moduleList,
                discoverId:nextProps.discoverId,
                courseCode:nextProps.courseCode
            })
        }
    }



    //一级分类查询
   moduleChange = (value) => {
        this.setState({
            moduleValue: value,
        })
    };

   shoewSetSave= () => {
        showSet({discoverId:this.state.discoverId,courseCode:this.state.courseCode,moduleId:this.state.moduleValue}).then((res) => {
            if (res.code === 0) {
                message.success('设置成功');
                this.props.form.resetFields()//清空表单
                this.props.checkCancel()
            }
        });
    }


    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
    }

    render() {
        const {saveLoading, moduleList} = this.state;
        const {moduleVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 9},
            wrapperCol: {span: 14}
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <Modal
                title='提示'
                width='660px'
                wrapClassName='training_modal_box'
                visible={moduleVisible}
                closable={false}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.checkCancel()}>取消</Button>
                        <Button loading={saveLoading} onClick={() => this.shoewSetSave()} type='primary'>保存</Button>
                    </div>
                }
            >
                <Form {...formItemLayout}>
                    <Form.Item label='请选择您将课程放置在首页板块'>
                        {
                            getFieldDecorator('categoryId', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择板块'
                                    },
                                ]
                            })(
                                <Select placeholder="请选择板块" onChange={(value) => this.moduleChange(value)}
                                       >
                                    {moduleList && moduleList.length ? moduleList.map((item, index) => {
                                        return <Option key={index}
                                                       value={item.moduleId}>{item.moduleName}</Option>
                                    }) : ""}
                                </Select>)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

ConfigureToHomeModel = Form.create()(ConfigureToHomeModel)
export default ConfigureToHomeModel;
