import { post,get} from '@/until/axios_instans';

//办学方式SEL
export function getRunningSchoolSel(data) {
    return post('/api/ucenter/api/v1/school/runmode', data);
}

//办学层次SEL
export function getRunningLeaveSel(data) {
    return post('/api/ucenter/api/v1/school/runlevel', data);
}

//管理部门SEL
export function getRunningdepartmentSel(data) {
    return post('/api/ucenter/api/v1/school/runlevel', data);
}

//院校列表
export function getSchoolList(data) {
    return post('/api/ucenter/api/v1/new/enterprise/selectSchool', data);
}

//院校删除
export function deleteSchoolItem(data) {
    return post('/api/ucenter/api/v1/enterprise/delete', data);
}

//公共下拉
export function globalSel(data) {
    return post('/api/ucenter/api/v1/powerdic/type', data)
}

//院校添加
export function addItem(data) {
    return post('/api/ucenter/api/v1/new/enterprise/insertSchool', data);
}

//院校编辑
export function editItem(data) {
    return post('/api/ucenter/api/v1/new/enterprise/updateSchool', data);
}

//省下拉
export function getProvinceSel(data) {
    return post('/api/ucenter/api/v1/area/province', data);
}
//市下拉
export function getCitySel(data) {
    return post('/api/ucenter/api/v1/area/city', data);
}
//区|县下拉
export function getDistrictSel(data) {
    return post('/api/ucenter/api/v1/area/area', data);
}
//院校详情
export function getSchoolDetail(data) {
    return post('/api/ucenter/api/v1/new/enterprise/schoolDetail', data);
}

//院校详情
export function selectSchoolLabel(data) {
    return post('/api/ucenter/api/v1/sharing/selectSchoolLabel', data);
}

//应用列表
export function applist(data) {
    return post('/api/ucenter/api/v1/application/applist', data);
}

//应用接入
export function appenter(data) {
    return post('/api/ucenter/api/v1/enterpriseApplication/appenter', data);
}

//应用延期
export function appAccessManageDelay(data) {
    return post('/api/ucenter/api/v1/application/appAccessManageDelay', data);
}

//应用记录
export function applicationRecord(data) {
    return post('/api/ucenter/api/v1/enterpriseApplication/applicationRecord', data);
}

//院系查询
export function selectFaculty(data) {
    return post('/api/ucenter/api/v1/new/faculty/selectFaculty', data);
}

//院系修改
export function updateFaculty(data) {
    return post('/api/ucenter/api/v1/new/faculty/updateFaculty', data);
}

//导入院校
export function importSchool(data) {
    return post('/api/ucenter/api/v1/new/members/importSchool', data);
}

//虚拟机构列表
export function selectVirtualOrg(data) {
    return post('/api/ucenter/api/v1/new/enterprise/selectVirtualOrg', data);
}

//虚拟机构详情
export function virtualOrgDetail(data) {
    return post('/api/ucenter/api/v1/new/enterprise/virtualOrgDetail', data);
}

//修改虚拟机构信息
export function updateVirtualOrg(data) {
    return post('/api/ucenter/api/v1/new/enterprise/updateVirtualOrg', data);
}

//新增虚拟机构
export function insertVirtualOrg(data) {
    return post('/api/ucenter/api/v1/new/enterprise/insertVirtualOrg', data);
}

//应用列表不分页
export function selectApplications(data) {
    return post('/api/ucenter/api/v1/enterpriseApplication/selectApplications', data);
}

//机构或学校停用应用
export function stopApplications(data) {
    return post('/api/ucenter/api/v1/enterpriseApplication/stopApplications', data);
}
//分页查询管理机构
export function selectManageOrg(data) {
    return post('/api/ucenter/api/v1/new/enterprise/selectManageOrg', data);
}
//查询管理机构详情
export function manageOrgDetail(data) {
    return post('/api/ucenter/api/v1/new/enterprise/manageOrgDetail', data);
}
//新增管理机构
export function insertManageOrg(data) {
    return post('/api/ucenter/api/v1/new/enterprise/insertManageOrg', data);
}
//修改管理机构
export function updateManageOrg(data) {
    return post('/api/ucenter/api/v1/new/enterprise/updateManageOrg', data);
}

//管理机构查询下级机构列表分页
export function selectLastOrgList(data) {
    return post('/api/ucenter/api/v1/new/enterprise/selectLastOrgList', data);
}

//管理机构添加下级机构
export function insertLastOrg(data) {
    return post('/api/ucenter/api/v1/new/enterprise/insertLastOrg', data);
}
//下载模板
export const downTemplate = (type) => {
    return '/api/ucenter/api/v1/sharing/downTemplate?type=' + type
}
//导入管理机构校验
export function checkImportManageOrg(data) {
    return post('/api/ucenter/api/v1/new/members/checkImportManageOrg', data);
}
//管理机构列表
export function getManagementInstitutionsList(data) {
    return post('/api/ucenter/api/v1/new/members/getManagementInstitutionsList', data);
}
//管理机构成员列表
export function getOrganizatioMembers(data) {
    return post('/api/ucenter/api/v1/new/members/getOrganizatioMembers', data);
}
//导入管理机构成员校验
export function generalImport(data) {
    return post('/api/ucenter/api/v1/new/members/generalImport',data);
}
//导入管理机构成员第二次校验
export function twoGeneralImport(data) {
    return post('/api/ucenter/api/v1/new/members/twoGeneralImport',data);
}
//导入管理机构成员确定导入
export function twoImportMembers(data) {
    return post('/api/ucenter/api/v1/new/members/twoImportMembers',data);
}
//导入管理机构成员
export function importMembers(data) {
    return post('/api/ucenter/api/v1/new/members/importMembers', data);
}
//以下结果再导入
export function againImport(data) {
    return post('/api/ucenter/api/v1/new/members/againImport', data);
}
//新增管理机构成员
export function addOrganizatioMembers(data) {
    return post('/api/ucenter/api/v1/new/members/addOrganizatioMembers', data);
}
//修改管理机构成员
export function updateOrganizatioMembers(data) {
    return post('/api/ucenter/api/v1/new/members/updateOrganizatioMembers', data);
}
//删除管理机构成员
export function deleteOrganizatioMembers(data) {
    return get('/api/ucenter/api/v1/new/members/deleteOrganizatioMembers', data);
}
//重置管理机构成员密码
export function formattingPassword(data) {
    return get('/api/ucenter/api/v1/new/members/formattingPassword', data);
}

//查询管理者账号列表不分页
export function selectManagement(data) {
    return post('/api/ucenter/api/v1/new/management/selectManagement', data);
}

//绑定/解绑用户
export function bindingManagement(data) {
    return post('/api/ucenter/api/v1/new/management/bindingManagement', data);
}

//停用/启用管理者账号
export function stopAndEnableManagement(data) {
    return post('/api/ucenter/api/v1/new/management/stopAndEnableManagement', data);
}

//删除管理者账号
export function deleteManagement(data) {
    return post('/api/ucenter/api/v1/new/management/deleteManagement', data);
}

//添加管理者账号
export function insertManagement(data) {
    return post('/api/ucenter/api/v1/new/management/insertManagement', data);
}
//权限管理列表
export function roleMenu(data) {
    return post('/api/ucenter/api/v1/sdk/role/menu', data);
}

//导入管理机构
export function importManageOrg(data) {
    return post('/api/ucenter/api/v1/new/members/importManageOrg', data);
}

//导入虚拟机构
export function importVirtualOrg(data) {
    return post('/api/ucenter/api/v1/new/members/importVirtualOrg', data);
}

//导入院校教师
export function importSchoolTeachers(data) {
    return post('/api/ucenter/api/v1/new/members/importSchoolTeachers', data);
}

//导入院校教师第一次校验
export function checkImportSchoolTeachers(data) {
    return post('/api/ucenter/api/v1/new/members/checkImportSchoolTeachers', data);
}

//导入院校教师第二次校验
export function checkAgainSchoolTeachers(data) {
    return post('/api/ucenter/api/v1/new/members/checkAgainSchoolTeachers', data);
}

//导入院校学生
export function importSchoolStudents(data) {
    return post('/api/ucenter/api/v1/new/members/importSchoolStudents', data);
}

//导入院校学生第一次校验
export function checkImportSchoolStudents(data) {
    return post('/api/ucenter/api/v1/new/members/checkImportSchoolStudents', data);
}

//导入院校学生第二次校验
export function checkAgainSchoolStudents(data) {
    return post('/api/ucenter/api/v1/new/members/checkAgainSchoolStudents', data);
}

//导入虚拟机构教师
export function importVirtualOrgTeachers(data) {
    return post('/api/ucenter/api/v1/new/members/importVirtualOrgTeachers', data);
}

//导入虚拟机构教师第一次校验
export function checkImportVirtualOrgTeachers(data) {
    return post('/api/ucenter/api/v1/new/members/checkImportVirtualOrgTeachers', data);
}

//导入虚拟机构教师第二次校验
export function checkAgainVirtualOrgTeachers(data) {
    return post('/api/ucenter/api/v1/new/members/checkAgainVirtualOrgTeachers', data);
}

//导入虚拟机构学生
export function importVirtualOrgStudents(data) {
    return post('/api/ucenter/api/v1/new/members/importVirtualOrgStudents', data);
}

//导入虚拟机构学生第一次校验
export function checkImportVirtualOrgStudents(data) {
    return post('/api/ucenter/api/v1/new/members/checkImportVirtualOrgStudents', data);
}

//导入虚拟机构学生第二次校验
export function checkAgainVirtualOrgStudents(data) {
    return post('/api/ucenter/api/v1/new/members/checkAgainVirtualOrgStudents', data);
}
//修改导入的院系和专业信息
export function updateImportFacultyOrMajor(data) {
    return post('/api/ucenter/api/v1/sharing/updateImportFacultyOrMajor', data);
}

//新增/修改机构开通信息
export function insertAndUpdateOpenMessage(data) {
    return post('/api/ucenter/api/v1/new/enterprise/insertAndUpdateOpenMessage', data);
}

//查询被选中机构列表
export function selectLastOrgListYes(data) {
    return post('/api/ucenter/api/v1/new/enterprise/selectLastOrgListYes', data);
}
