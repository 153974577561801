// 添加消息发送对象
import React from 'react';
import { Input, Select, Form,Table, Pagination ,Row,Col,Checkbox,Button} from 'antd';
import './index.scss';
import {Link,withRouter} from "react-router-dom";
import { selectLastOrgList, getProvinceSel, globalSel,selectLastOrgListYes} from '@/api/platform-business/index.js'
const { Search } = Input;
const { Option } = Select;
const { Column } = Table;


class AddMessageSend extends React.Component {
    state = {
        disabled:false,//checkbox单项点击禁用
        disabledAll:false,//checkbox全选点击禁用
        clickNumber:1,//添加点击次数
        classId: '',
        className: '',
        checkList: [{
            id: '1',
            disabled: false,
        }], //所有学员列表（左侧）
        checkedList: [], // 已选择列表（右侧）
        checkedListMiddle:[],//已选择列表（右侧中间变量）
        pageNum:1,//当前页数
        pageSize:10,//每页数据数量
        total:0,//总条数
        value: [],//左侧列表选中的数组(userId)
        searchValue: '',//查询内容
        deleteStudent: [],//删除的学员数据
        newCheckedList: [], //暂存数据
        oldValue:[],
        courseId: '',
        chapterName: '',
        selectAll: false,//是否全选
        batchOperationList:[],
        childArr:[],
        enterpriseId: "123",
        type:0,
        columnSchool: [
            {title: '机构名称', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'center',},
            {title: '所属省市', dataIndex: 'provinceName', key: 'provinceName', align: 'center'},
            {title: '办学层次', dataIndex: 'schoolRunLevelName', key: 'schoolRunLevelName', align: 'center',},
            {title: '办学方式', dataIndex: 'schoolRunModeName', key: 'schoolRunModeName', align: 'center'},
            {title: '操作', dataIndex: '', key: '', align: 'center',
                render: (text, record) => {
                    return<>
                        {
                            ( this.state.value.includes(record.id) ) ?
                                <span style={{'color': '#888'}}>添加</span> : <span className="text" onClick={()=>this.addClass(text)}>
                            添加
                        </span>
                        }

                    </>
                }
            }
        ],
        subDataSource: [],
        manageStatus: '0', // 状态
        lastEnterpriseName: '', // 机构名称
        provinceId: '', // 省的编号
        schoolRunLevel: '', // 办学层次
        provinceSel: [], // 省数据
        cengciSel: [], // 办学层次
        enterpriseName: '', // 父级传过来的机构名称
        enterpriseTotal: 0,
    };
    componentDidMount() {
        this.props.onRef(this)
        this.selectLastOrgListYes()
        this.getLastOrgList()
        this.getProvinceSel();  //省下拉框
        this.getCengciSel();    //办学层次下拉框
    }
    manageStatus = (e) => { // 课程状态切换
        console.log('eee',e)
        this.setState({
            manageStatus: e
        })
        let {onEnterprise} = this.state;
        this.setState({ manageStatus: e }, () => this.getLastOrgList(onEnterprise));
    }
    //管理机构查询下级机构列表分页
    getLastOrgList = () => {
        let {managementOrgDetail} = this.props
        let {manageStatus,lastEnterpriseName,pageNum,pageSize, provinceId, schoolRunLevel } = this.state
        let params = {
            pageNum: pageNum,
            pageSize: pageSize,
            id: managementOrgDetail.id,
            enterpriseName: lastEnterpriseName,
            // manageRange: managementOrgDetail.manageRange && managementOrgDetail.manageRange !== '0'? managementOrgDetail.manageRange.split(','): [],
            manageRange:  [],
            manageStatus: manageStatus,
            manageOrgType: managementOrgDetail.manageOrgType,
            provinceId: provinceId,
            schoolRunLevel: schoolRunLevel
        }
        console.log('省份---', provinceId)
        console.log('层次---', schoolRunLevel)
        selectLastOrgList(params).then(res => {
            if (res) {
                console.log('resresresres----', res)
                this.setState({
                    subDataSource: res.data,
                    total: res.total,
                    onEnterprise :managementOrgDetail,
                    enterpriseName: managementOrgDetail.enterpriseName
                })
            }
        })
    }
    selectLastOrgListYes =()=>{
        let {managementOrgDetail} = this.props
        let {manageStatus,lastEnterpriseName,pageNum,pageSize, provinceId, schoolRunLevel } = this.state
        let params = {
            pageNum: pageNum,
            pageSize: pageSize,
            id: managementOrgDetail.id,
            enterpriseName: lastEnterpriseName,
            // manageRange: managementOrgDetail.manageRange && managementOrgDetail.manageRange !== '0'? managementOrgDetail.manageRange.split(','): [],
            manageRange:  [],
            manageStatus: manageStatus,
            manageOrgType: managementOrgDetail.manageOrgType,
            provinceId: provinceId,
            schoolRunLevel: schoolRunLevel
        }
        selectLastOrgListYes(params).then(res => {
            if (res) {
                let arr=res
                let ids = []
                arr&&arr.map(item=>{
                    ids.push(item.id)
                })
                this.setState({
                    checkedListMiddle: arr,
                    value:ids
                },()=>{
                    console.log('checkedListMiddle',this.state.checkedListMiddle,this.state.value)
                })
            }
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.getLastOrgList())
        // window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.getLastOrgList())
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        if (nextProps.managementOrgDetail !== this.state.managementOrgDetail) {
            this.setState({
                checkList:[],
                checkedListMiddle:[],
                disabled:false,
                disabledAll:false,
                selectAll:false,
                value:[],
            },()=>{
                this.selectLastOrgListYes()
                this.getLastOrgList()
                this.getProvinceSel();  //省下拉框
                this.getCengciSel();    //办学层次下拉框
            })
        }
    }
    // 删除右侧一条数据
    deleteCheck = (label) => {
        //删除右侧数据
        // LastOrgList
        const {checkedList,value,checkList,checkedListMiddle} = this.state
        console.log('删除-------》',label.id,checkedList)
        let chapterId = label.id
        let checkedListCopy = checkedListMiddle.filter(item => item.id !== chapterId)
        checkList.map(i=>{
            if( i.id===label.id){
                delete i.disabled
            }
        })
        this.setState({
            checkedList: checkedListCopy,
            disabled:false,
            disabledAll:false
        })
        //左侧数据取消选中
        this.setState({
            value: value.filter(item => item !== chapterId),
            selectAll: false
        })
        //    右侧删除数据
        this.setState({
            checkedListMiddle:checkedListCopy
        })
    }

    //添加课程
    addClass(label){
        console.log('label',label)
        const {value,checkedListMiddle} =this.state
        value.push(label.id)
        label.disabled=true
        let arr=checkedListMiddle
        if(arr.length==0){
            arr.push(label)
            this.setState({
                checkedListMiddle:arr,
                value
            },()=>{
                this.props.saveLastOrg()
            })
        }else {
            let arrId=[]
            arr.map(i=>{
                arrId.push(i.id)
            })
            if(arrId.includes(label.id)){
                return
            }else {
                arr.push(label)
                this.setState({
                    checkedListMiddle:arr,
                    value
                },()=>{
                    this.noSame(arr)
                })
            }

        }
    }
    //批量添加
    batchAddition= async () =>{
        let { value, checkedListMiddle,LastOrgList } = this.state
        if (LastOrgList&&LastOrgList.length>0) {
            let arr=checkedListMiddle.concat(LastOrgList)
            let isarr = this.noSame(arr)
            isarr.map(item=>{
                value.push(item.id)
            })
            this.setState({
                checkedListMiddle:this.noSame(arr),
                value: value
            })
        } else {
            this.mesWarning('请选择添加的机构！')
        }


    }
    //数组去重
    noSame(arr){
        let returnArr=[]
        arr.map(i=>{
            if(returnArr.indexOf(i)===-1){
                returnArr.push(i)
            }
        })
        return returnArr
    }
    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res.unshift({provinceCode: '0', name: '全国'})
        res && this.setState({ provinceSel: res.filter(v => v.provinceCode !== '0'), manageRangeSel: res });
    }
    //办学层次Sel
    getCengciSel = async () => {
        let res = await globalSel({ type: 3 });
        res && this.setState({ cengciSel: res });
    }
    manageProvince = (e) => { // 省下拉数据切换
        this.setState({ provinceId: e }, () => this.getLastOrgList());
    }
    manageSchoolRunLevel = (e) => { // 省下拉数据切换
        this.setState({ schoolRunLevel: e }, () => this.getLastOrgList());
    }
    onSelectChange = (selectedRowKeys, selectedRows) => { // 表格多选
        this.setState({ LastOrgList: selectedRows,selectedRowKeys: selectedRowKeys},()=>{
        });
    };
    render() {
        const {total,pageSize,checkedListMiddle,provinceSel,cengciSel,enterpriseName,
            columnSchool,subDataSource,value}=this.state
        const { getFieldDecorator } = this.props.form
        const rowSelection = {
            type: 'checkbox',
            getCheckboxProps(record) {
                return { // 配置默认勾选的列
                    disabled:( value.includes(record.id))
                }
            },
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="addCourse">
                <Row type="flex" justify="space-between">
                    <Col span={16}>
                        <h4>{enterpriseName}</h4>
                        <div className="bottom-cont">
                            <div className="sech-right">
                                <Form>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item >
                                                <Col span={6}><span>所属省市</span></Col>
                                                <Col span={17}>
                                                    {
                                                        getFieldDecorator('provinceId', {
                                                            initialValue: ''
                                                        })(
                                                            <Select allowClear={true}
                                                                placeholder='请选择省' onChange={e => this.manageProvince(e)}>
                                                                {
                                                                    provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                                                }
                                                            </Select>
                                                        )
                                                    }
                                                </Col>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item>
                                                <Col span={6}><span>办学层次</span></Col>
                                                <Col span={17}>
                                                {
                                                    getFieldDecorator('schoolRunLevel', {
                                                        initialValue: ''
                                                    })(
                                                        // <Search value={searchValue}
                                                        //         placeholder="请输入计划名称"
                                                        //         maxLength={20} onBlur={this.searchListByName}/>
                                                        <Select placeholder='请选择办学层次' allowClear={true} onChange={e => this.manageSchoolRunLevel(e)}>
                                                            {
                                                                cengciSel && cengciSel.map(v => {
                                                                    return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    )
                                                }
                                                </Col>
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item >
                                                <Col span={6}><span>状态</span></Col>
                                                <Col span={17}>
                                                    {
                                                        getFieldDecorator('manageStatus', {
                                                            initialValue: ''
                                                        })(
                                                            <Select defaultValue="0" onChange={e => this.manageStatus(e)}>
                                                                <Option value="0">全部</Option>
                                                                <Option value="1">管理中</Option>
                                                                <Option value='2'>未管理</Option>
                                                            </Select>
                                                        )
                                                    }
                                                </Col>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item>
                                                <Col span={17}>
                                                    {
                                                        getFieldDecorator('lastEnterpriseName', {
                                                            initialValue: '',
                                                        })(
                                                            <Search className='inputWapper'
                                                                    placeholder="请输入名称"
                                                                    onChange={e => this.setState({ lastEnterpriseName: e.target.value })}
                                                            />
                                                        // <Input.Search className="lcl_left" onChange={e => this.setState({ searchVal: e.target.value })} onSearch={this.search} placeholder='请输入机构名称' />
                                                        )
                                                    }
                                                </Col>
                                                <Col span={6} className='button_box'>
                                                    <Button onClick={()=>this.getLastOrgList()}>查询</Button>
                                                </Col>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                            <div className="cont">
                                <div className="cont_top">
                                        <Table rowSelection={rowSelection}
                                               // rowKey={v => v['enterpriseId']}
                                               rowKey="id"
                                               pagination={
                                            {
                                                total,
                                                pageSize:pageSize,
                                                onChange: this.pageChange,
                                                onShowSizeChange: this.onShowSizeChange,
                                                showSizeChanger: true,
                                                // position:'bottomRight'
                                            }
                                        } columns={columnSchool} dataSource={subDataSource} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-h4">
                            <Button onClick={()=>this.batchAddition()} className='ass_no_btn'>
                                批量添加
                            </Button>
                        </div>
                    </Col>
                    <Col span={8}>
                        <h4>已添加机构</h4>
                        <div className='right_item_wappers'>
                            <ul className='right_item_wapper'>
                                {checkedListMiddle?.map(label => (
                                    <li key={label.id}>{label.enterpriseName}<em><img onClick={() => this.deleteCheck(label)} src={require("@/assets/img/icon-4.png")}/></em></li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
AddMessageSend = Form.create()(AddMessageSend)
export default withRouter(AddMessageSend);
