import LoadableComponent from "../until/LoadableComponent";
 
let moduleFile = require.context("../page", true, /\index.(jsx|js)$/);
let result = moduleFile.keys().reduce((prev, item) => {
  let str = item.split("/")[item.split("/").length - 2];
  let name = str[0].toLocaleUpperCase() + str.slice(1);
  prev = Object.assign({}, prev, {
    [name]: LoadableComponent(import("../page" + item.slice(1))),
  });
  return prev;
}, {});

export  {result}