import React, {Component} from 'react';
import {Col, Button, Input, Form, message, Modal} from 'antd';
import {ExclamationCircleFilled, SearchOutlined} from '@ant-design/icons';
import './index.scss';
import MyTable from '@/components/Table';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import OneClassify from "./components/oneclassify";
import TwoClassify from "./components/twoclassify";
import TransferClassify from "./components/transferclassify";
import {classifyFirstList, classifySecondList, classifyDelete} from '@/api/craftsman-app/index'

class CourseClassify extends Component {
    state = {
        type: true,
        expandedRowKeys: [],
        categoryId: '',   //一级分类Id
        twoClassifyList: {},
        dataSource: [],
        data: {},
        oneClassifyDetail: {},
        twoClassifyDetail: {},
        twoClassifyOne: {},
        oneVisible: false,
        twoVisible: false,
        visible: false,
        transferVisible: false,

    }

    componentDidMount() {
        this.classifyFirstList()
    }

    //课程分类一级分类列表
    async classifyFirstList() {
        const oneClassifyList = await classifyFirstList({})
        if (oneClassifyList) {
            this.setState({
                dataSource: oneClassifyList
            })
        }
    }

    //课程分类二级分类列表
    async classifySecondList() {
        const {categoryId, twoClassifyList} = this.state
        const res = await classifySecondList({parentId: categoryId})
        if (res) {
            twoClassifyList[categoryId] = res
            this.setState({
                twoClassifyList,
            })
        }
    }

    //刷新一级分类数据
    oneRefreshData = () => {
        this.setState({
            visible: false,
            oneVisible: false,
        }, () => this.classifyFirstList())
    }
    //刷新二级分类数据
    twoRefreshData = () => {
        this.setState({
            visible: false,
            twoVisible: false,
        }, () => this.classifySecondList())
    }
    //刷新分类转移数据
    TransferRefreshData = () => {
        this.setState({
            visible: false,
            twoVisible: false,
            transferVisible:false,
        }, () =>
            this.classifyFirstList(),
            this.classifySecondList())
    }
    //取消弹框
    checkCancel = () => {
        this.setState({
            oneVisible: false,
            transferVisible: false,
            twoVisible: false,
            data: {}
        })
    }
    //新增一级分类
    oneClassifyModel = (record) => {
        this.setState({
            oneVisible: !this.state.oneVisible,
            oneClassifyDetail: record
        })
    }
    //新增二级分类
    twoClassifyModel = (record, v) => {
        this.setState({
            twoVisible: !this.state.twoVisible,
            twoClassifyDetail: v,
            twoClassifyOne: record,
        }, () => {
            this.classifyFirstList()
        })
    }
    //分类转移
    transferClassifyModel = (record, data) => {
        this.setState({
            transferVisible: !this.state.transferVisible,
            transferOneDetail: record,
            transferTwoDetail: data
        })
    }

    //单选展开收起
    isShow(data) {
        let {categoryId} = data;
        const {expandedRowKeys, dataSource} = this.state;
        let num = dataSource.findIndex(e => e.categoryId === categoryId)
        if (expandedRowKeys.includes(num)) {
            expandedRowKeys.splice(expandedRowKeys.findIndex(element => element === num), 1)
        } else {
            expandedRowKeys.push(num);
        }
        this.setState({
            expandedRowKeys,
            categoryId: categoryId,
        }, () => {
            this.classifySecondList()
        })
    }

    //删除一级分类
    delOneClassify(record) {
        let that = this;
        const {dataSource} = this.state;
        Modal.confirm({
            title: '温馨提醒',
            content: "确认删除【" + record.categoryName + "】分类 吗？",
            type: 'warning',
            isCancel: true,
            onOk: () => {
                classifyDelete({categoryId: record.categoryId}).then((res) => {
                    if (res.code === 0) {
                        message.success("删除成功");
                        that.classifyFirstList();
                    }
                });
            },
        });
    }

    //删除二级分类
    delTwoClassify(record, v) {
        let that = this;
        Modal.confirm({
            title: '温馨提醒',
            content: "确认删除【 " + v.categoryName + " 】分类 吗？",
            type: 'warning',
            isCancel: true,
            onOk: () => {
                classifyDelete({categoryId: v.categoryId}).then((res) => {
                    if (res.code === 0) {
                        message.success("删除成功");
                        that.classifySecondList();
                    }
                });
            },
        });
    }

    render() {
        let {dataSource, twoClassifyList, oneVisible, twoVisible, transferVisible, pagination, expandedRowKeys, twoClassifyOne, twoClassifyDetail, transferOneDetail, transferTwoDetail} = this.state;
        let columns = [
            {title: '分类名称', dataIndex: 'categoryName', key: 'categoryName', width: "40%",},
            {title: '更新时间', dataIndex: 'updateTime', key: 'updateTime', align: 'center', width: "20%",
                render: (text, record, index) => <p>{record.updateTime && record.updateTime.substring(0, 10)}</p>
            },
            {title: '更新人', dataIndex: 'updateUserName', key: 'updateUserName', align: 'center', width: "20%"},
            {title: '操作', dataIndex: 'categoryId', key: 'categoryId', align: 'center',
                width: "20%",
                render: (text, record, index) => <p className='action vertical_j'>
                    <span onClick={() => {
                        this.delOneClassify(record);
                    }}>删除</span>
                    <span onClick={() => this.oneClassifyModel(record)}>编辑</span>
                </p>
            }
        ]
        return (
            <div className='publicstyle course_classify'>
                <div className="padd">
                    <div className="top">
                        <Col span={12} className="sort_boxs">
                            <Button className='sure_sort' type='primary'
                                    onClick={() => this.oneClassifyModel('')}>新增一级分类</Button>
                            <Button className='add_two_btn'
                                    onClick={() => this.twoClassifyModel('', '')}>新增二级分类</Button>
                        </Col>
                    </div>

                    <div className="class_set_container">
                        <div style={{position: 'relative'}}>
                            <DndProvider backend={HTML5Backend}>
                                <MyTable
                                    enumeration='categoryId'
                                    options={
                                        {
                                            columns,
                                            dataSource,
                                            // isDrab: isDrab,
                                            onRow: (record, index) => ({
                                                index,
                                            }),
                                            expandIcon: ({expanded, onExpand, record}) =>
                                                expanded ? (
                                                    <span className='pointer' onClick={() => this.isShow(record)}>↑</span>
                                                ) : (
                                                    <span className='pointer' onClick={() => this.isShow(record)}>↓</span>
                                                ),
                                            expandedRowKeys,
                                            expandedRowRender: record => (
                                                <ul className='ul_list'>
                                                    {twoClassifyList[record.categoryId] && twoClassifyList[record.categoryId].length ? twoClassifyList[record.categoryId].map(v =>
                                                        <li style={{margin: 0}} key={v.categoryId}>
                                                            <span className='child_li' style={{width: "40%"}}>{v.categoryName}</span>
                                                            <span className='child_li' style={{width: "20%", textAlign: "center"}}>{v.updateTime && v.updateTime.substring(0, 10)}</span>
                                                            <span className='child_li' style={{width: "20%", textAlign: "center"}}>{v.updateUserName}</span>
                                                            <span className='child_li' style={{width: "20%", textAlign: "center"}}>
                                                                <p className='action vertical_j'>
                                                                    <span
                                                                        onClick={() => this.transferClassifyModel(record, v)}>分类转移</span>
                                                                    <span
                                                                        onClick={() => this.twoClassifyModel(record, v)}>编辑</span>
                                                                    <span
                                                                        onClick={() => this.delTwoClassify(record, v)}>删除</span>
                                                                </p>
                                                            </span>
                                                        </li>
                                                    ) : ''}
                                                </ul>
                                            ),
                                            pagination: false
                                        }
                                    }
                                />
                            </DndProvider>
                        </div>
                    </div>
                </div>
                <OneClassify
                    oneVisible={oneVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    oneClassifyDetail={this.state.oneClassifyDetail}
                    oneRefreshData={this.oneRefreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                <TwoClassify
                    twoVisible={twoVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    twoClassifyDetail={twoClassifyDetail}
                    twoClassifyOne={twoClassifyOne}
                    twoRefreshData={this.twoRefreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                <TransferClassify
                    transferVisible={transferVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    transferTwoDetail={transferTwoDetail}
                    TransferRefreshData={this.TransferRefreshData.bind(this)}
                    transferOneDetail={transferOneDetail}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
            </div>
        )
    }
}

CourseClassify = Form.create()(CourseClassify)
export default CourseClassify;
