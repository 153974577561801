import {
    SAVE_previewQuestionList
} from './action_type';
import { getPreviewQuestionList } from '../.././../api/save_question/index';

// 保存预览数据
export const fetchPreviewQuestionList = data => {
    return async dispatch => {
        console.log(data,dispatch,"7788");
        let res = await getPreviewQuestionList(data)
        if (res.message === 'success') {
            dispatch({
                type: SAVE_previewQuestionList,
                res
            })
        }
    }
}

//编辑的时候的保存
export const _edit_PreviewQuestionList = send_obj => {
    return async dispatch => {
       
        dispatch({
            type: SAVE_previewQuestionList,
            res:{
                result:send_obj
            }
        })
    }
}