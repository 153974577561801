import React, { Component } from 'react'

export default class Hardware extends Component {
    render() {
        return (
            <div>
                hard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_record
            </div>
        )
    }
}
