


import React from 'react';


//分类标签
export default props => {
    return <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.15274 13.6659C3.90357 13.6659 1.78847 10.5813 1.70004 10.45C1.61803 10.3283 1.57422 10.1849 1.57422 10.0381V2.95946C1.57422 2.5522 1.90432 2.2221 2.31158 2.2221C5.32998 2.2221 6.29699 0.714525 6.30583 0.699306C6.37311 0.584392 6.47024 0.48984 6.58693 0.425688C6.70361 0.361536 6.83549 0.330179 6.96857 0.334945C7.10164 0.339712 7.23094 0.380425 7.34273 0.452761C7.45453 0.525097 7.54465 0.626356 7.60353 0.745789C8.45846 2.48012 11.4691 2.22883 11.4991 2.22576C11.6016 2.21544 11.7052 2.22674 11.8031 2.25892C11.901 2.2911 11.9911 2.34344 12.0675 2.41258C12.1439 2.48171 12.205 2.5661 12.2468 2.6603C12.2886 2.7545 12.3102 2.85641 12.3101 2.95946V7.67857C12.3101 8.08577 11.98 8.41593 11.5728 8.41593C11.1656 8.41593 10.8354 8.08577 10.8354 7.67857V3.71517C9.79396 3.68951 7.9928 3.45491 6.8641 2.2441C6.19116 2.82231 4.99162 3.52511 3.04888 3.66992V9.79562C3.49248 10.3674 5.0689 12.1888 7.14843 12.1912C7.25939 12.1888 9.29032 12.1115 10.5668 9.6938C10.7568 9.33368 11.2029 9.19588 11.5631 9.386C11.9232 9.57612 12.061 10.0222 11.8709 10.3823C10.1572 13.6286 7.27467 13.6659 7.15274 13.6659Z" fill="#6A7E9C"/>
        <path d="M6.46812 9.49236C6.37127 9.4925 6.27535 9.47348 6.18587 9.43642C6.09639 9.39936 6.01511 9.34498 5.94672 9.27641L4.44251 7.77219C4.15458 7.4842 4.15458 7.01737 4.44251 6.72938C4.73049 6.4414 5.19733 6.4414 5.48531 6.72938L6.46812 7.7122L8.51273 5.66758C8.80071 5.37966 9.26755 5.37972 9.55553 5.66758C9.84351 5.95557 9.84351 6.4224 9.55553 6.71039L6.98952 9.27641C6.92112 9.34497 6.83984 9.39934 6.75036 9.4364C6.66089 9.47346 6.56497 9.49248 6.46812 9.49236Z" fill="#BABABA"/>
    </svg>

}