import React, {Component} from 'react';
import {Col, Radio, Input} from 'antd';
import MyTable from '@/components/Table';
import {applyPageList, getResourceApplyStatusList, getRegisterTimes} from '@/api/practTrain/index.js'
import './index.scss';
import ProgressModel from "./components/progress";

class Applaymg extends Component {
    state = {
        progressVisible: false,
        applyId: null,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            pageSizeOptions: ["10", "20", "50", "100"]
        },
        params:  {
            applyStatus: null,
            applyTimeType:null,
            keyword:''
        },
        dataSource: [],
        applyStatusList: [],
        registerTimesList: []
    }

    componentDidMount() {
        this.getApplyPageList()
        this.getResourceApplyStatusList()
        this.getRegisterTimes()
    }

    async getApplyPageList(){
        let {pagination,params} = this.state;
        let applyList = await applyPageList({...params, pageNum:pagination.current, pageSize:pagination.pageSize});
        if(applyList){
            pagination.total = applyList.total;
            this.setState({
                dataSource: applyList.data,
                pagination
            },()=>{
                window.elGoTop && window.elGoTop('body')
            })
        }
    }

    async getResourceApplyStatusList (){
       let applyStatusList = await getResourceApplyStatusList({})
        if(applyStatusList){
            this.setState({
                applyStatusList
            })
        }
    }

    async getRegisterTimes (){
        let registerTimesList = await getRegisterTimes({})
        if(registerTimesList){
            this.setState({
                registerTimesList
            })
        }
    }

    onShowSizeChange = (pageNum, pageSize)=>{
        let {pagination} = this.state;
        pagination.current = pageNum
        pagination.pageSize = pageSize
        this.setState({
            pagination
        },()=>{
            this.getApplyPageList()
        })
    }

    pageChange = (pageNum) =>{
        let {pagination} = this.state;
        pagination.current = pageNum
        this.setState({
            pagination
        },()=>{
            this.getApplyPageList()
        })
    }

    timeRange = (e) => {
        let {params} = this.state;
        params.applyTimeType = e.target.value
        this.setState({
            params
        },()=>{
            this.pageChange(1)
        })
    }

    progessRange = (e) => {
        let {params} = this.state;
        params.applyStatus = e.target.value
        this.setState({
            params
        },()=>{
            this.pageChange(1)
        })
    }

    //搜索
    searchValueChange(e) {
        let {params} = this.state;
        params.keyword = e.target.value
        this.setState({
            params
        })
    }

    //取消弹框
    checkCancel = () => {
        this.setState({
            progressVisible: false,
        })
    }

    //处理进度弹窗
    progress = (applyId) => {
        this.setState({
            progressVisible: !this.state.progressVisible,
            applyId
        })
    }

    render() {
        const {registerTimesList, dataSource, applyId, pagination, params, applyStatusList, progressVisible} = this.state
        const columns = [
            {
                title: '序号', dataIndex: 'index', key: 'index', align: "center",width:"80px",
                render: (text,data, index) => <p>{index+1}</p>
            },
            {title: '申请学校名称', dataIndex: 'orgName', key: 'orgName', align: "center",width:"150px"},
            {title: '申请资源名称', dataIndex: 'resourceName', key: 'resourceName', align: "center",width:"150px"},
            {title: '资源供应商', dataIndex: 'resourceSupplier', key: 'resourceSupplier', align: "center",},
            {title: '适配范围', dataIndex: 'resourceRange', key: 'resourceRange', align: "center",},
            {title: '申请者', dataIndex: 'applyUserName', key: 'applyUserName', align: "center",},
            {title: '申请时间', dataIndex: 'createTime', key: 'createTime', align: "center",},
            {title: '有效时间', dataIndex: 'resourceBeginTime', key: 'resourceBeginTime', align: "center",
                render: (text,data) => <p>{data.resourceBeginTime}～{data.resourceEndTime}</p>
            },
            {title: '是否已购买', dataIndex: 'resourceType', key: 'resourceType', align: "center",
                render: (text) =>{
                    let params = {1:"已购买" ,2:"试用形式" ,3:"自主形式"}
                    return <p>
                        <span>{params[parseInt(text)]}</span>
                    </p>
                }
            },
            {title: '机构状态', dataIndex: 'orgStatus', key: 'orgStatus', align: "center",
                render: (text) => <p>{text === '1'?'正式': '临时'}</p>
            },
            {
                title: '处理进度', dataIndex: 'applyStatus', key: 'applyStatus', align: "center",
                render: (text, record, index) => <p className=''>
                    <span className={`${text === '0' ? "notusedActive" : ""}`}>{text === '0' ? "未处理" : "已经下发"}</span>
                </p>
            },
            {
                title: '操作', dataIndex: 'address', key: 'operation', align: "center", width: "200px", fixed: 'right',
                render: (text, record, index) => <p className='action vertical_j'>
                    <React.RenderButton text='查看详情' id="btn_ShowXiangQing08">
                    <span onClick={() => {
                        this.props.history.push('/main/practTrain/applay-mg-detail?applyId='+record.applyId)
                    }}>查看详情</span>
                    </React.RenderButton>
                    <React.RenderButton text='处理进度' id="btn_ProcessingProgress09">
                    <span onClick={() => this.progress(record.applyId)}>处理进度</span>
                    </React.RenderButton>
                </p>
            }
        ]
        const {$$img} = window
        const {RenderButton} = React;
        return (
            <div className={'applayMg training-warp'}>
                <h2 className={'training-tit'}>资源下发申请</h2>
                <div className="applayMg-header">
                    <div className={'adaptationRange'}>
                        <label className={'Range-name'}>全部时间</label>
                        <Radio.Group id={'classHeight'} className={`range-group`} onChange={this.timeRange.bind(this)}>
                            <Radio.Button value={null}>全部</Radio.Button>
                            {registerTimesList && registerTimesList.length ? registerTimesList.map((item, index) =>(
                                <Radio.Button key={index} value={item.type}>{item.desc}</Radio.Button>
                            )) : ""}
                        </Radio.Group>
                    </div>
                    <div className={'adaptationRange'}>
                        <label className={'Range-name'}>全部进度</label>
                        <Radio.Group id={'classHeight'} className={`range-group`} onChange={this.progessRange.bind(this)}>
                            <Radio.Button value={null}>全部</Radio.Button>
                            {applyStatusList && applyStatusList.length ? applyStatusList.map((item, index)=>(
                                <Radio.Button key={index} value={item.type}>{item.desc}</Radio.Button>
                            )):''}
                        </Radio.Group>
                    </div>
                </div>
                <div className="training-top">
                    <Col span={6}>
                        {/*<Button type='primary'>导出资源信息</Button>*/}
                    </Col>
                    <Col className='training-top-r' span={18}>
                        <React.RenderButton text='搜索' id="btn_SearchDate07">
                            <Input style={{width: "360px", marginRight: "12px"}}
                                   prefix={<img src={$$img('cvec-space-front-guanli/zhihuishixun/search.svg')} alt=""/>}
                                   placeholder='请输入学校名称或者资源名称'
                                   value={params.keyword}
                                   onChange={this.searchValueChange.bind(this)}
                                   onPressEnter={() => this.pageChange(1)}/>
                        </React.RenderButton>
                        <label>共查询到{pagination.total}个资源课程</label>
                        {/*<Button type='primary' onClick={() => getData()} className='search'>查询</Button>*/}
                    </Col>
                </div>
                <div className={'check_container training-table '} style={{position: 'relative'}}>
                    <MyTable
                        options={
                            {
                                dataSource,
                                columns,
                                pagination: {
                                    showSizeChanger: true,
                                    onChange: this.pageChange,
                                    ...pagination,
                                    onShowSizeChange: this.onShowSizeChange
                                }
                            }
                        }
                        isDrab={false}
                        fixededit={true}
                    />
                </div>
                {/*处理进度弹窗 start*/}
                <ProgressModel
                    progressVisible={progressVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    getList={()=>this.getApplyPageList()}
                    applyId={applyId}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*处理进度弹窗 end*/}
            </div>
        );
    }
}

export default Applaymg;
