/**
 * 资源浏览
 */

//import {  post, get } from "@/until/http";
import { post } from '../../until/axios_instans';
//资源浏览页
export function browsingResource(data) {
    return post(window.apiPrefix+"/api/v1/rcenter/personal/resource/browsingResource", data);
}








