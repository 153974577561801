import { result } from './result'
export default [
    {
        name: '资讯管理',
        path: '/main/group-column',
        component: result['Group-column'],
        children: [
            {
                path: '/main/group-column',
                redirect: '/main/group-column/column'
            },
            {
                name: '栏目管理',
                path: '/main/group-column/column',
                component: result['Column']
            },
            {
                name: '添加栏目',
                path: '/main/group-column/addColumn',
                component: result['AddColumn']
            },
            {
                name: '新增新闻',
                path: '/main/group-column/addnews',
                component: result['AddNews']
            },
            {
                name: '预览新闻',
                path: '/main/group-column/lookNews',
                component: result['LookNews']
            },
            {
                name: '预览新闻',
                path: '/main/group-column/lookRelationNews',
                component: result['LookRelationNews']
            },
            {
                name: '已发布',
                path: '/main/group-column/published',
                component: result['Published']
            },
            {
                name: '草稿箱',
                path: '/main/group-column/drafts',
                component: result['Drafts']
            },
            {
                name: '回收站',
                path: '/main/group-column/recycleBin',
                component: result['RecycleBin']
            },
            {
                name: '专题配置',
                path: '/main/group-column/thematicConfiguration',
                component: result['ThematicConfiguration']
            },
            {
                name: '新增专题',
                path: '/main/group-column/addThematic',
                component: result['AddThematic']
            },
            {
                name: '专题配置管理',
                path: '/main/group-column/thematicConsole',
                component: result['ThematicConsole']
            },
            {
                name: '一个专题',
                path: '/main/group-column/specialTopic',
                component: result['SpecialTopic']
            },
            {
                name: '预览一个专题',
                path: '/main/group-column/specialTopicPage',
                component: result['SpecialTopicPage']
            },
            {
                name: '推送管理',
                path: '/main/group-column/pushManagement',
                component: result['PushManagement']
            },
            {
                name: '新增推送',
                path: '/main/group-column/addPush',
                component: result['AddPush']
            },
        ]
    },
    //官网资讯管理
    {
        name: '资讯管理',
        path: '/main/group-info',
        component: result['Group-info'],
        children: [
            {
                path: '/main/group-info',
                redirect: '/main/group-info/information'
            },
            {
                name: '资讯管理',
                path: '/main/group-info/information',
                component: result['Information']
            },
            {
                name: '新建资讯',
                path: '/main/group-info/add-information',
                component: result['Add-information']
            },
        ]
    },
]
