// 机构管理列表
import React, { Component } from 'react';
import { Radio, Button, Input, Table, Modal, message } from 'antd';
import './index.scss';
import { appenter, selectManageOrg,downTemplate,checkImportManageOrg, selectLastOrgList, insertLastOrg, importManageOrg} from '@/api/platform-business/index.js'
import Batch from '@/components/import';
import FormAlike from "../../../../components/FormAlike";
import moment from "moment";
import AddNewsSend from "../../compents/addNewsSend";

export default class index extends Component {
  state = {
    selectedRowKeys: [],
    total:0,
    pageNum: 1,
    pageSize: 5,
    searchVal: '',
    dataSource: [],
    fangshiSel: [],
    cengciSel: [],
    leixingSel: [],
    provinceSel: [], // 所在地省下拉列表
    citySel: [], // 所在地市
    columns: [
      {title: '序号', dataIndex: 'source', key: 'source', align: 'center',render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
      {title: '管理机构名称', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'center',},
      {title: '所管范围', dataIndex: 'manageRangeName', key: 'manageRangeName', align: 'center', render: (text, item) => item.manageRange === '0'? '全国':item.manageRangeName.join(",")},
      {title: '管理院校数', dataIndex: 'managementSchoolNum', key: 'managementSchoolNum', align: 'center',},
      {title: '加入日期', dataIndex: 'settledDate', key: 'settledDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
      {title: '到期日期', dataIndex: 'settledEndDate', key: 'settledEndDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
      {title: '状态', align: 'center', render: (text, item) =>  <span className='serial'>试用({item.onTrialNum})  正式({item.formalNum})</span>,},
      {
        title: '操作', dataIndex: 'address', key: 'address', align: 'center', fixed: 'right',
        width: 220,
        render: (text, record) => {
          const {RenderButton} = React;
          return <p className='action vertical_j'>
            <div>
              <RenderButton id='OBS-OM-01-01-a_btnDetail'>
                <span onClick={() => this.lookDetil(record)}>进入详情</span>
              </RenderButton>
              <RenderButton id='OBS-OM-01-01-a_btnEdit'>
                <span onClick={() => this.edit(record)}>编辑信息</span>
              </RenderButton>
            </div>
            <div>
              <RenderButton id='OBS-OM-01-01-a_btnConfig'>
                <span onClick={() => this.isApplication(record)}>配置应用</span>
              </RenderButton>
              <RenderButton id='OBS-OM-01-01-a_btnOrg'>
                <span onClick={() => this.managementOrg(record)}>管理机构</span>
              </RenderButton>
            </div>
          </p>
        }
      }
    ],
    batch: false,
    errorMessage: '',
    btnActive: false,
    fileName: '',
    isApplication: false, // 应用接入弹层
    selectedTags: [], // 接入应用选择数据
    applicationList: [], // 应用列表
    isSchool: false, // 院系管理弹层
    is_input: true, // 院系管理是否可编辑
    oftenFlag: true,
    columnSchool: [
      {title: '序号', dataIndex: 'source', key: 'source', align: 'center',render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
      {title: '机构名称', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'center',},
      {title: '所在地', dataIndex: 'provinceName', key: 'provinceName', align: 'center'},
      {title: '办学层次', dataIndex: 'schoolRunLevelName', key: 'schoolRunLevelName', align: 'center',},
      {title: '办学方式', dataIndex: 'schoolRunModeName', key: 'schoolRunModeName', align: 'center'}
    ],
    subPageNum: 1,
    subPageSize: 5,
    subSearchVal: '',
    subDataSource: [],
    manageStatus: 0,
    managementOrgDetail: null,
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    addSend:true
  }
  componentDidMount() {
    this.selectManageOrg();
  }
  //获取列表
  selectManageOrg = () => {
    let params = {
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
      enterpriseName:this.state.enterpriseName
    }
    selectManageOrg(params).then(res => {
      if (res) {
        this.setState({
          total: res.total,
          dataSource: res.data
        })
      }
    })
  }
  //打开应用接入弹窗
  isApplication = item => {
    this.setState({ isApplication: true, enterpriseId: item.id})
    sessionStorage.setItem('enterpriseId', item.id);
  }
  managementOrg = item => { // 管理机构
    this.setState({ isSchool: true, managementOrgDetail: item })
    this.getLastOrgList(item)
  }
  //管理机构查询下级机构列表分页
  getLastOrgList = (item) => {
    let {manageStatus, lastEnterpriseName, subPageNum, subPageSize, provinceId,schoolRunLevel} = this.state
    console.log('item', item)
    let params = {
      pageNum: subPageNum,
      pageSize: subPageSize,
      id: item.id,
      enterpriseName: lastEnterpriseName,
      manageRange: item.manageRange && item.manageRange !== '0'? item.manageRange.split(','): [],
      // manageRange: [],
      manageStatus: manageStatus,
      manageOrgType: item.manageOrgType,
      provinceId: provinceId,
      schoolRunLevel: schoolRunLevel
    }
    selectLastOrgList(params).then(res => {
      if (res) {
        this.setState({
          subDataSource: res.data,
          onEnterprise :item
        })
      }
    })
  }
  //分页切换
  pageChange = (pageNum) => {
    this.setState({ pageNum }, () => this.selectManageOrg());
    window.elGoTop && window.elGoTop('body');
  }
  //分页条数切换
  onShowSizeChange = (pageNum, pageSize) => {
    this.setState({ pageSize }, () => this.selectManageOrg());
    window.elGoTop && window.elGoTop('body');
  }
  //检索
  search = (e) => {
    this.setState({ enterpriseName: e }, () => this.selectManageOrg());
  }
  lastSearch = (e) => {
    let {onEnterprise} = this.state;
    this.setState({ lastEnterpriseName: e }, () => this.getLastOrgList(onEnterprise));
  }
  //新增
  add = () => {
    sessionStorage.setItem("school_type", 'add');
    sessionStorage.setItem('enterpriseId', '');
    this.props.history.push('/main/platform-business/add-management');
  }
  //编辑
  edit = item => {
    console.log("item",item)
    sessionStorage.setItem('enterpriseId', item.id);
    sessionStorage.setItem("school_type", 'edit');
    this.props.history.push('/main/platform-business/add-management');
  }
  //查看详情
  lookDetil = item => {
    console.log("item",item)
    sessionStorage.setItem('enterpriseId', item.id);
    // sessionStorage.setItem("school_type", 'edit');
    this.props.history.push('/main/platform-business/detil-management');
  }
  saveLastOrg = () => {
    let {oftenFlag, managementOrgDetail} = this.state
    let  LastOrgList = this.child.state.checkedListMiddle //获取
    console.log('LastOrgList',LastOrgList)
    if (LastOrgList&&LastOrgList.length>0) {
      if (oftenFlag) {
        this.setState({ oftenFlag: false });
        let list = [];
        console.log('LastOrgList-->', LastOrgList)

        LastOrgList.forEach(function(item,index){
          let LastOrg = {enterpriseId: item.id};
          list.push(LastOrg)
        });
        insertLastOrg(
          {
            lastOrgId: managementOrgDetail.id,
            enterpriseIds: list,
          }
        ).then(res => {
          if (res) {
            this.mesSuccess('操作成功');
            this.child.state.LastOrgList = []
            this.child.state.selectedRowKeys = []
            this.setState({
              isSchool: false
            })
          }
        })
        setTimeout(() => this.setState({ oftenFlag: true, isSchool: false }), 2500)
      }

    } else {
      this.mesWarning('请选择机构！');
    }
  }
  //应用接入
  save = () => {
    console.log('appDelay', this)
    this.appForm.props.form.validateFields((err, values) => {
      if (!err) {
        let { oftenFlag,selectedTags,enterpriseId,userInfo } = this.state;
        if (selectedTags.length === 0) {
          return this.mesWarning('请选择应用');
        }
        if (oftenFlag) {
          this.setState({ oftenFlag: false });
          let list = [];
          selectedTags.forEach(function(item,index){
            let app = {accessType: values.accessType,startDate: values.startDate,endDate: values.endDate,id: item.id};
            list.push(app)
          });
          appenter(
            {
              enterpriseId: enterpriseId,
              list: list,
              createUserId: userInfo.id,
              updateUserId: userInfo.id,
            }
          ).then(res => {
            if (res) {
              this.mesSuccess('配置成功');
              this.selectManageOrg()
            }
          })
          setTimeout(() => this.setState({ oftenFlag: true, isApplication: false }), 2500)
        }
      }
    })
  }
  selectedTags = (e) => {
    this.setState({ selectedTags: e });
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log('selectedRowKeys changed: ', selectedRows);
    this.setState({ LastOrgList: selectedRows });
  };
  // onSelectChange = selectedRowKeys => {
  //     console.log('selectedRowKeys changed: ', selectedRowKeys);
  //     this.setState({ selectedRowKeys });
  // };
  manageStatus = (e) => {
    let {onEnterprise} = this.state;
    this.setState({ manageStatus: e.target.value, lastEnterpriseName: '' }, () => this.getLastOrgList(onEnterprise));
  }
  getFormData=(formData)=>{
    console.log("1111111111111",formData.get("file"))
    formData.set("id","")
    this.setState({
      batchRef: formData
    },()=>{
      console.log("batchRef",this.state.batchRef)
    })
  }
  sure = (e) => {
    this.setState({ btnActive: true, fileId: e });
  }
  closeBatch = () => {
    this.setState({ batch: false, errorMessage: '', btnActive: false });
  }
  saveBatch = () => { // 管理机构信息导入
    let fileValue = this.state.batchRef;
    console.log("file",fileValue)
    importManageOrg(this.state.batchRef).then(res => {
      if (res) {
        this.mesSuccess('导入成功');
        this.selectManageOrg()
      }
    }).catch(err => {
      console.log(err)
    })
    this.setState({ batch: false, errorMessage: '', btnActive: false });
  }
  render() {
    let { total, columns, dataSource, searchVal, pageSize,
      batch, btnActive, errorMessage, isApplication, isSchool,
      columnSchool,subDataSource, managementOrgDetail, selectedRowKeys,
      lastEnterpriseName,manageStatus, addSend} = this.state;
    // let rowSelection = {
    //     onChange: this.onSelectChange,
    //   getCheckboxProps(record) {
    //     return {
    //       checked: record.manageStatus === 1, // 配置默认勾选的列
    //     };
    //   },
    //     type: 'checkbox'
    // };

    const rowSelection = {
      // selectedRowKeys,
      // onChange: this.onSelectChange
      type: 'checkbox',
      getCheckboxProps(record) {
        return {
          defaultChecked: record.manageStatus == 1 // 配置默认勾选的列
        }
      },
      onChange: this.onSelectChange,
    };
    const {RenderButton} = React;
    return (
      <div className='in_school_box mana_organization_hadd'>
        <div className="in_school_content">
          <div className='top'>
            <div className="left">
              <RenderButton id='OBS-OM-01-01-a_btnLoad'>
                <Button className='add_btn_j' onClick={() => this.setState({ batch: true })} style={{ marginRight: '20px' }} >导入管理机构</Button>
              </RenderButton>
              <RenderButton id='OBS-OM-01-01-a_btnAdd'>
                <Button onClick={this.add} className='add_btn_j no_color' >创建管理机构</Button>
              </RenderButton>
            </div>
            <div className="right">
              <Input.Search className="lcl_left" onChange={e => this.setState({ searchVal: e.target.value })} onSearch={this.search} placeholder='请输入机构名称' />
            </div>
          </div>
          <div className="bottom">
            <Table rowKey={v => v['enterpriseId']} pagination={
              {
                total,
                pageSize:pageSize,
                onChange: this.pageChange,
                onShowSizeChange: this.onShowSizeChange,
                pageSizeOptions: ['5', '10', '20', '40'],
                showSizeChanger: true,
                // position:'bottomRight'
              }
            } columns={columns} dataSource={dataSource} />
          </div>
        </div>
        <Modal className='batch' visible={batch} title='管理机构信息导入'
               closable={false}
               footer={
                 <div style={{ width: "100%" }}>
                   <div className='pos-btn'>
                     <Button onClick={this.closeBatch}>取消</Button>
                     <Button disabled={!btnActive} className={btnActive ? "" : "gray"}
                             onClick={this.saveBatch}>确定</Button>
                   </div>
                   {errorMessage ?
                     <div className='error'>
                       <span>请下载查看</span>
                       <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                     </div>
                     : null}
                 </div>
               }
        >
          {/*<Batch*/}
          {/*    ref={ref => this.batchRef = ref}*/}
          {/*    sure={() => this.setState({ btnActive: true })}*/}
          {/*    template={downTemplate(4)}*/}
          {/*    action={checkImportManageOrg}*/}
          {/*    reset={() => this.setState({ errorMessage: '', btnActive: false })}*/}
          {/*    pullError={(val) => this.setState(val)}*/}
          {/*    titleType='管理机构'*/}
          {/*/>*/}
          <Batch
            ref={ref => this.batchRef = ref}
            getFormData={this.getFormData}
            sure={this.sure}
            reset={() => this.setState({ errorMessage: '', btnActive: false })}
            template={downTemplate(4)}
            pullError={(val) => this.setState(val)}
            titleType='管理机构'
          />
        </Modal>
        {/*应用接入弹层*/}
        <Modal
          title='应用接入'
          visible={isApplication}
          destroyOnClose={true}
          closable={false}
          className='configuration_modals_gljg'
          width={650}
          footer={
            <div className='modal_btn_j'>
              <Button onClick={() => this.setState({ isApplication: false })}>取消</Button>
              <Button onClick={()=>{this.save()}}>确认添加</Button>
            </div>
          }
        >
          <FormAlike wrappedComponentRef={form => {this.appForm = form}} selectedTags={this.selectedTags} formType='yyjr'/>
        </Modal>
        {/*院系管理弹层*/}
        {/*<Modal*/}
        {/*    title={*/}
        {/*        <div className='title_top_wapper'>*/}
        {/*            <div>*/}
        {/*                <span>{managementOrgDetail? managementOrgDetail.enterpriseName: null}</span><span className='school_small_title'>管理机构</span>*/}
        {/*            </div>*/}
        {/*            <div className='title_right_schhol'>所属范围： {managementOrgDetail? managementOrgDetail.manageRangeName.join(","): '全国'}</div>*/}
        {/*        </div>*/}
        {/*    }*/}
        {/*    visible={isSchool}*/}
        {/*    destroyOnClose={true}*/}
        {/*    closable={false}*/}
        {/*    className='configuration_modals_gljg'*/}
        {/*    width={800}*/}
        {/*    footer={*/}
        {/*        <div className='modal_btn_j'>*/}
        {/*            <Button onClick={() => this.setState({ isSchool: false })}>关闭</Button>*/}
        {/*            <Button onClick={() => this.saveLastOrg()}>确认</Button>*/}
        {/*        </div>*/}
        {/*    }*/}
        {/*>*/}
        {/*    <div className=''>*/}
        {/*        <div className='tops'>*/}
        {/*            <div className="left">*/}
        {/*                <span style={{color: '#000',marginRight: '10px'}}>状态</span>*/}
        {/*                <Radio.Group defaultValue={0} size="small" onChange={e => this.manageStatus(e)}>*/}
        {/*                    <Radio.Button value={0}>全部</Radio.Button>*/}
        {/*                    <Radio.Button value={1}>管理中</Radio.Button>*/}
        {/*                    <Radio.Button value={2}>未管理</Radio.Button>*/}
        {/*                </Radio.Group>*/}
        {/*            </div>*/}
        {/*            <div className="right">*/}
        {/*                <Input.Search className="lcl_left" onChange={e => this.setState({ lastEnterpriseName: e.target.value })} onSearch={this.lastSearch} placeholder='请输入机构名称' />*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="bottom">*/}
        {/*            <Table rowSelection={rowSelection} rowKey={v => v['enterpriseId']} pagination={*/}
        {/*                {*/}
        {/*                    total,*/}
        {/*                    pageSize:pageSize,*/}
        {/*                    onChange: this.pageChange,*/}
        {/*                    onShowSizeChange: this.onShowSizeChange,*/}
        {/*                    pageSizeOptions: ['5', '10', '20', '40'],*/}
        {/*                    showSizeChanger: true,*/}
        {/*                    // position:'bottomRight'*/}
        {/*                }*/}
        {/*            } columns={columnSchool} dataSource={subDataSource} />*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</Modal>*/}

        <Modal visible={isSchool} wrapClassName='modal_student modal_change'
               width={1000}
               closable={false}
               footer={
                 <div className='modal_btn_j'>
                   <Button onClick={() => this.setState({ isSchool: false })}>取 消</Button>
                   <Button type='primary' onClick={() => this.saveLastOrg()}>确 定</Button>
                 </div>
               }
        >
          <AddNewsSend manageStatus = {manageStatus}
                       onRef={(ref)=>this.child=ref}
                       lastEnterpriseName={lastEnterpriseName}
                       managementOrgDetail={managementOrgDetail}
                       saveLastOrg={this.saveLastOrg}
                       chapterList={'chapterList'}
          />
        </Modal>
      </div>
    )
  }
}
