import React,{useState,useEffect,forwardRef} from 'react';
import { queryFacultySelect } from '../../api/components';
import { Select } from 'antd';

const {Option} = Select;

const FacultySelect = (props,ref) => {
    const [list,setList] = useState([]);

    useEffect(()=>{
        const fn = async () => {
            try {
                const res = await queryFacultySelect({id:props.ids});
                setList(res);
            } catch (error) {
                console.log(error);
            }
        };
        if(props.ids){
            fn();
        }
    },[props.ids]);

    return (
        <Select
            {...props}
            ref={ref}
            getPopupContainer={triggerNode => triggerNode.parentElement}
        >
            {
                Array.isArray(list) && list.map((item) => {
                    return <Option key={item.id} value={item.id}>
                        {item.facultyName}
                    </Option>
                })

            }
        </Select>
    )
}

export default forwardRef(FacultySelect);
