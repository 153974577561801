/*平台管理*/
import React, {Component} from "react";
import {
    Row,
    Input,
    Table,
    AutoComplete,
    Col,
    Modal, Button, Form, message, Spin,Tabs
} from "antd";
import {withRouter} from "react-router";
import {insertLabel,selectLabel,updateLabel,disableLabel,deleteLabel} from "../../../../../api/peixun/index"
import RenderButton from "@/components/renderButton";
const {TabPane} = Tabs;
const AutoCompleteOption = AutoComplete.Option;


@withRouter
class PlatformLabel extends Component {
    state = {
        columns: [
            {title: '标签名称', dataIndex: 'labelName', key: 'labelName', width: 150, align: 'left'},
            {
                title: '使用状态',
                dataIndex: 'isDisable',
                key: 'isDisable',
                align: 'center',
                render: (text, record) => {

                    return (
                        record.isDisable === 0 ? <span>正常</span> : <span style={{color:'#E34949'}}>禁用</span>
                    )
                },
            },
            {
                title: '来 源',
                dataIndex: 'labelType',
                key: 'labelType',
                align: 'center',
                render: (text, record) => {
                    return (
                        record.labelType === 1 ? <span>平台默认</span> : record.labelType === 2 ? <span>机构自建</span> : ""
                    )
                },
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'x',
                align: 'center',
                render: (text, record) =>{
                    return (
                        <div
                            className="render-span">
                            <div className="open-span">
                                {
                                    record.isDisable === 1 ?
                                        <>
                                        <RenderButton id='OBS-TS-02-03-b_btnEnableTab'>
                                            <span onClick={() => this.showDisable(record.id, 0)}>启动</span>
                                        </RenderButton>
                                            <RenderButton id='OBS-TS-02-03-b_btnEditTab'>
                                                <span onClick={() => {this.classifyAdd('edit', record)}}> 编辑</span>
                                            </RenderButton></>:

                                    record.isDisable === 0 ?
                                        <RenderButton id='OBS-TS-02-03-b_btnFobTab'>
                                            <span onClick={() => this.showDisable(record.id, 1)}> 禁用</span>
                                        </RenderButton>:

                                    <></>
                                }
                                <RenderButton id='OBS-TS-02-03-b_btnDelTab'>
                                    <span onClick={() => this.showDeleteConfirm(record.id)}>删除</span>
                                </RenderButton>

                            </div>
                        </div>
                    )
                }
            },
        ],
        data:[],
        enterpriseId:"0",
        labelName:"",
        modelType:'add',
        pageNum:1,//当前页数
        pageSize:10,//每页数据数量
        total:0,//总条数
    }

    componentDidMount() {
        this.selectLabelList()
    }

    //查询标签列表
    selectLabelList = async () =>{
        const {pageNum,pageSize,enterpriseId,labelName} = this.state
        let data = {
            pageNum:pageNum,
            pageSize:pageSize,
            labelName:labelName,
            //查询内容
            enterpriseId:enterpriseId,
            labelType:1
        }
        await selectLabel(data).then(e=>{
            if(e){
                this.setState({
                    data:e.data,
                    total:e.total,
                })
            }
        })
    }

//新增分类
    classifyAdd = (type, record) => {
        let value = record ? record.labelName : ''
        console.log("val", value)
        this.setState({
            labelVisibily: true,
            modelType: type,
            labelId: record.id
        }, () => {
            this.props.form.setFieldsValue({'labelName': value})
        })

    }

    //新增/修改标签
    insertLabel = () => {
        const {modelType,enterpriseId,labelId} = this.state
        if(modelType === 'add'){
            //新增标签
            let labelType = '1'
            if (enterpriseId !== '0'){
                labelType = '2'
            }
            let data = {
                enterpriseId:enterpriseId,
                labelName: this.props.form.getFieldValue(`labelName`),
                labelType:labelType
            }
            insertLabel(data).then(e=>{
                if(e){
                    this.mesSuccess("新增成功",2000)
                }
                this.setState({
                    labelVisibily:false
                },()=>{
                    this.selectLabelList()
                })
            })
        }else {
            //修改标签
            let data = {
                enterpriseId:enterpriseId,
                labelName: this.props.form.getFieldValue(`labelName`),
                id:labelId
            }
            updateLabel(data).then(e=>{
                if(e){
                    this.mesSuccess("修改成功",2000)
                }
                this.setState({
                    labelVisibily:false
                },()=>{
                    this.selectLabelList()
                })
            })
        }
    }

    //新增分类保存
    // insertLabel = () => {
    //     this.props.form.validateFields((err, info) => {
    //         if (!err) {
    //             let labelName = info.labelName
    //             if (this.state.modelType === 'add') {
    //                 insertLabel({enterpriseId: this.state.enterpriseId, labelName}).then(e => {
    //                     this.mesSuccess("添加成功", 2000)
    //                     this.selectLabel()
    //                     this.setState({
    //                         labelVisibily: false
    //                     })
    //                 })
    //             } else {
    //                 updateLabel({id: this.state.labelId, labelName,enterpriseId:this.state.enterpriseId}).then(e => {
    //                     this.mesSuccess("编辑成功", 2000)
    //                     this.selectLabel()
    //                     this.setState({
    //                         labelVisibily: false
    //                     })
    //                 })
    //             }
    //         }
    //     })
    // }

    // selectLabel = () => {
    //     const {enterpriseId, labelName,pageNum, pageSize} = this.state
    //     selectLabel({enterpriseId, labelName, pageNum, pageSize}).then(e => {
    //         this.setState({data: e.data, total: e.total,tableLoading:false})
    //     })
    // }
    showDisable = (id,type) => { // 禁用提示
        this.setState({
            id: id,
            isDisable: type
        })
        this.hint({'title': '温馨提示','content': `此操作会${type===0?'启动':'禁用'}该数据，确定${type===0?'启动':'禁用'}吗？`,'type': 'warning','cancelText': '取消', 'okText': '确定','isCancel': true,'sure': this.disableLabel })
    }
    //启动/禁用标签
    disableLabel = async() => {
        const {id,isDisable} = this.state
        let data = {
            id: id,
            isDisable: isDisable
        }
        await disableLabel(data).then(e => {
            if(e){
                this.selectLabelList()
                if(isDisable === 1){
                    this.mesSuccess("禁用成功", 2000)
                }else {
                    this.mesSuccess("启用成功", 2000)
                }
            }else {
                if(isDisable === 1){
                    this.mesError("禁用失败", 2000)
                }else {
                    this.mesError("启用失败", 2000)
                }
            }
        })
    }

    showDeleteConfirm = (id) => { // 删除提示
        this.setState({
            id: id
        })
        this.hint({'title': '温馨提示','content': '此操作会永久删除该数据，确定删除吗？','type': 'warning','cancelText': '取消', 'okText': '确定','isCancel': true,'sure': this.deleteLabel })
    }

    //删除标签
    deleteLabel = async () => {
        const {id} = this.state
        let data = {
            id : id
        }
        await deleteLabel(data).then(e => {
            if(e){
                this.selectLabelList()
                this.mesSuccess("删除成功", 2000)
            }else {
                this.mesError("删除失败", 2000)
            }
        })
    }
    getLabelName = (e) => {
        this.setState({pageNum: 1}, () => {
            this.searchLabel()
        })
    }
    handleSearch=(e)=>{
        this.setState({
            labelName: e.target.value
        })

    }

    //根据名称查询标签列表
    searchLabel = async () =>{
        const {pageNum,pageSize,enterpriseId,labelName} = this.state
        let data = {
            pageNum:pageNum,
            pageSize:pageSize,
            //查询内容
            enterpriseId:enterpriseId,
            labelName:labelName,
            labelType:1
        }
        await selectLabel(data).then(e=>{
            if(e){
                this.setState({
                    data:e.data,
                    total:e.total,
                })
            }
        })
    }

    //二级模糊查询
    handleWebsiteChange = value => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            //请求方法
            autoCompleteResult = ['11', '222', '333'];
        }
        this.setState({autoCompleteResult});
    };
    showConfirm = () => {
        this.setState({
            confirmVisibily: true
        })
    }
    relevanceShow = (item) => {
        console.log('item11111111',item)
        // gl_lable_name
        this.setState({
            relevanceVisibily: true,
            relationId:item.id,
            gl_lable_name:item.labelName
        })

    }
    handleConfirmOk = () => {
        this.setState({
            cancelConfirmOk: false
        }, () => {
            this.mesSuccess("删除成功")
        })
    }
    cancelConfirmOk = () => {
        this.setState({
            relevanceVisibily: false
        })
    }
    changePage=(pageNum)=>{
        this.setState({
            pageNum:pageNum
        },()=>{
            //列表方法
            this.selectLabelList()
        })
    }
    callback = async (key) => {
        await this.setState({activeKey: key})
    };
    render() {
        const {columns, data, autoCompleteResult, labelVisibily, confirmVisibily, relevanceVisibily, modelType, pageNum, pageSize, total,tableLoading,relationId,gl_lable_name,activeKey} = this.state
        const {getFieldDecorator} = this.props.form;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            onChange: this.changePage
        }
        return (
            <div className="platformLabel" style={{padding:'0'}}>
                <div className="top">
                    <Col span={8} className="sort_boxs">
                        <RenderButton id='OBS-TS-02-03-b_btnAddTab'>
                            <Button className='sure_sort' onClick={() => {
                                this.classifyAdd('add', '')
                            }} type='primary'>新增标签</Button>
                        </RenderButton>



                        <p className='_tip'>最多可添加10个标签</p>
                    </Col>
                    <Col className='top_r btn_box' span={16}>
                        <span className="li_span">共有{total}个平台创建标签</span>
                        <div className='search-box'>
                            <Input.Search className="type-input" placeholder='输入标签名称' onChange={(e)=>{this.handleSearch(e)}} onSearch={this.getLabelName}
                                          value={this.state.labelName} />
                        </div>
                    </Col>
                </div>
                {
                    tableLoading? <Spin className="Spin-content" tip="加载中"/>:(
                        <Table
                            className='label-table'
                            columns={columns}
                            dataSource={data}
                            pagination={pagination}
                            locale={{emptyText:<><div className="Empty-conet">
                                    <div className="img">
                                        <img src={require('../../../../../assets/img/zixun/pic-4.png')}/>
                                        <span>暂无数据</span>
                                    </div>
                                </div></>}}
                        />
                    )
                }
                <Modal
                    title=""
                    className="confirm-modal_con"
                    visible={confirmVisibily}
                    onOk={this.handleConfirmOk}
                    onCancel={this.handleConfirmOk}
                    closable={false}
                >
                    <p className="title"> 温馨提示</p>
                    <p className="text">删除该标签，所有相关联的课程或培训计划，将自动移除该标签请确认是否要删除。</p>
                </Modal>
                <Modal
                    title={modelType === 'add' ? '添加标签' : '编辑标签'}
                    destroyOnClose={true}
                    wrapClassName='modal_con'
                    visible={labelVisibily}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => {
                                this.setState({labelVisibily: false})
                            }}>取消</Button>
                            <Button onClick={() => this.insertLabel()} type='primary'>保存</Button>
                        </div>
                    }>
                    <Form>
                        <Form.Item>
                            <Row>
                                <Col span={5}>
                                    <span><span className='red'>*</span>标签名称</span>
                                </Col>
                                <Col span={15}>
                                    {
                                        getFieldDecorator('labelName', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请填写标签'
                                                },
                                                {
                                                    max:6,
                                                    message: '标签最多填写6个字'
                                                },
                                                {
                                                    pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                                                    message: '禁止输入特殊字符',
                                                }
                                            ],
                                            getValueFromEvent: event => {
                                                return event.target.value.replace(/\s+/g, '')
                                            }
                                        })(
                                            <Input maxLength={6} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`labelName`) ? this.props.form.getFieldValue(`labelName`).length : 0}/6</span>}/>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
                {/*<Modal*/}
                {/*    title="关联内容"*/}
                {/*    className="confirm-modal_con"*/}
                {/*    width='60%'*/}
                {/*    visible={relevanceVisibily}*/}
                {/*    onOk={this.handleConfirmOk}*/}
                {/*    onCancel={this.cancelConfirmOk}*/}
                {/*    closable={false}*/}
                {/*>*/}
                {/*    <Relevance labelId={relationId} refresh={()=>this.selectLabel()} gl_lable_name={gl_lable_name}/>*/}
                {/*</Modal>*/}
            </div>
        );
    }
}

PlatformLabel = Form.create()(PlatformLabel);
export default PlatformLabel;
