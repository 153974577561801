/*标签管理*/
import React, {Component} from "react";
import {
    AutoComplete, Form,Tabs
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import PlatformLabellist from './platformLabel'
import AgencyLabellist from './agencyLabel'
// import Cookies from 'js-cookie';
// import RenderButton from '../../components/renderButton/index'
const {TabPane} = Tabs;
const AutoCompleteOption = AutoComplete.Option;

@withRouter
class Relevance extends Component {
    state = {
        activeKey: "1",
    }

    componentDidMount() {
    }

    changePage=(pageNum)=>{
        this.setState({
            pageNum:pageNum
        },()=>{
            //列表方法
            this.selectLabel()
        })
    }
    callback = async (key) => {
        await this.setState({activeKey: key})
    };
    render() {
        const {activeKey} = this.state
        let platformLabel = <div className="Chat-left">
            <PlatformLabellist/>
        </div>
        let agencyLabel = <div className="Agency_cont">
            {/*<div className="top_right">*/}
            {/*    <span className="li_span">共有10个机构创建标签</span>*/}
            {/*</div>*/}
            <AgencyLabellist activeKey={activeKey}/>
        </div>
        return (
            <div className="relevance bg_fff" style={{padding:'0'}}>
                <div className="tab_ul">
                    <Tabs activeKey={activeKey} onChange={this.callback.bind(this)}>
                        <TabPane tab="平台标签" key="1">
                        </TabPane>
                        <TabPane tab="机构标签" key="2">
                        </TabPane>
                    </Tabs>
                    {
                        activeKey === "1" ? platformLabel : agencyLabel
                    }
                </div>

            </div>
        );
    }
}

Relevance = Form.create()(Relevance);
export default Relevance;
