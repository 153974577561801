import React from "react";
import { connect } from "react-redux";
import { Form, Button, Table, Pagination, Modal, Input, message } from "antd";
import "./index.scss";
import {
  addClassify,
  listQuery,
  delClassify,
  enableClassify,
} from "@/api/activitySeries/addClasses";
export default connect()(
  Form.create()(
    class AddClasses extends React.Component {
      state = {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        addClass: false,
        randomkey: this.randomkey(),
        classifyName: "",
        classList: [],
      };
      componentDidMount() {
        this.listQuery();
      }
      //随机key值
      randomkey() {
        let Reno = Math.random(1000 * 100) + "";
        let keyId = Reno.replace(/^0\./, "");
        return keyId;
      }

      //新增分类
      async addClassify() {
        let res = await addClassify({ classifyName: this.state.classifyName });
        if (res.code === 0) {
          message.success("添加成功");
          this.listQuery();
          this.setState({
            addClass: false,
            randomkey: this.randomkey(),
          });
        }
      }

      //分类列表
      async listQuery() {
        const { pageSize, pageNum } = this.state;
        let params = {
          pageSize: pageSize,
          pageNum: pageNum,
        };
        let res = await listQuery(params);
        if (res) {
          this.setState({
            classList: res.data,
            total: res.total,
          });
        }
      }
      //删除分类
      async delClassify(id) {
        let that = this;
        Modal.confirm({
          title: "提示",
          content: "确定删除该分类？",
          okText: "确定",
          cancelText: "取消",
          onOk() {
            delClassify({ id: id }).then((res) => {
              if (res && res.code === 0) {
                message.success("删除成功!");
                that.listQuery();
              }
            });
          },
          onCancel() {},
        });
      }
      async enableClassify(id) {
        let that = this;
        Modal.confirm({
          title: "提示",
          content: "确定启用该分类？",
          okText: "确定",
          cancelText: "取消",
          onOk() {
            enableClassify({ id: id, enable: 1 }).then((res) => {
              if (res && res.code === 0) {
                message.success("启用成功!");
                that.listQuery();
              }
            });
          },
          onCancel() {},
        });
      }
      //pageSize变化
      onShowSizeChange = (current, pageSize) => {
        this.setState(
          {
            pageNum: 1,
            pageSize: pageSize,
          },
          () => {
            this.listQuery();
          }
        );
      };
      //pageNum变化
      onPageNumChange = (page, pageSize) => {
        this.setState(
          {
            pageNum: page,
          },
          () => {
            this.listQuery();
          }
        );
      };
      render() {
        const columns = [
          {
            title: "序号",
            key: "1",
            width: 150,
            render: (a, b, c) => (
              <span>{this.state.pageNum - 1 + "" + (c + 1)}</span>
            ),
          },
          {
            title: "类别名称",
            dataIndex: "classifyName",
            key: "2",
            width: 150,
          },
          { title: "更新时间", dataIndex: "updateTime", key: "3", width: 200 },
          { title: "添加人员", dataIndex: "updateUser", key: "4", width: 200 },
          {
            title: "操作",
            key: "operation",
            fixed: "right",
            width: 220,
            render: (e) => (
              <div className="operation_btn">
                {e.enable === 0 ? (
                  <>
                    <span
                      onClick={() => {
                        this.enableClassify(e.id);
                      }}
                    >
                      启用
                    </span>
                    <span
                      onClick={() => {
                        this.delClassify(e.id);
                      }}
                    >
                      删除
                    </span>
                  </>
                ) : (
                  <span className="enable">已启用</span>
                )}
              </div>
            ),
          },
        ];
        const { total, pageNum, pageSize, addClass, classList } = this.state;
        return (
          <div className="add_classes_wrap">
            <div className="content">
              <div className="top">
                <Button
                  onClick={() => {
                    this.setState({
                      addClass: true,
                    });
                  }}
                >
                  新增类别
                </Button>
              </div>
              {/* top --- end */}
              <div className="table_content">
                <Table
                  columns={columns}
                  dataSource={classList}
                  scroll={{ x: 800, y: 460 }}
                  pagination={false}
                />
                <div className="pagination_wrap">
                  {total ? (
                    <div className="pagination_style">
                      <Pagination
                        showSizeChanger
                        pageSizeOptions={["10", "20", "30"]}
                        onShowSizeChange={this.onShowSizeChange}
                        onChange={this.onPageNumChange}
                        defaultCurrent={1}
                        current={pageNum}
                        pageSize={pageSize}
                        total={total}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <Modal
              key={this.state.randomkey}
              visible={addClass}
              title="新增类别"
              closable={false}
              width="660px"
              wrapClassName="add_modal"
              footer={[
                <div className="modal_footer_btn_l" key="1">
                  <Button
                    onClick={() => {
                      this.setState({
                        addClass: false,
                        randomkey: this.randomkey(),
                      });
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    onClick={() => {
                      this.addClassify();
                    }}
                  >
                    确定
                  </Button>
                </div>,
              ]}
            >
              <Input
                style={{ width: 330 }}
                onChange={(e) => {
                  this.setState({
                    classifyName: e.target.value,
                  });
                }}
                maxLength={20}
                placeholder="请输入新增类别"
              />
            </Modal>
          </div>
        );
      }
    }
  )
);
