import React, { Component } from 'react'
import './index.scss'
import { Input, Select, Button, Table, Modal, Drawer, Form, message, Col, Upload, Tooltip } from 'antd'
import { uploadFile } from '../../../api/ali'
import MaxLengthInput from '../../../components/hhinput'
import { ImagePreview } from 'gongyongpicpreview'
import S from 'gongyongicon'
import { page, shebei_save, optionlist, shebei_remove, listByLevel, listByParentId, shebei_edit, cvecequipmenttypedetail, pageListdara,resourcechapterlist } from '../../../api/shixun2/index'
const { Option } = Select;
const colors = [
    '#fff'
]
const Yichang = () => {
    return <div>
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11.5L0 11.5C9.59018e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5L0 11.5Z" fill="#F3B714" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6516 6.32578V13.2258C12.6516 13.8609 12.1367 14.3758 11.5016 14.3758C10.8665 14.3758 10.3516 13.8609 10.3516 13.2258V6.32578C10.3516 5.69065 10.8665 5.17578 11.5016 5.17578C12.1367 5.17578 12.6516 5.69065 12.6516 6.32578ZM11.5016 17.8258C12.1367 17.8258 12.6516 17.3109 12.6516 16.6758C12.6516 16.0407 12.1367 15.5258 11.5016 15.5258C10.8664 15.5258 10.3516 16.0407 10.3516 16.6758C10.3516 17.3109 10.8664 17.8258 11.5016 17.8258Z" fill="white" />
        </svg>
    </div>
}

const Shnchupic = () => {
    return <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.4892 3.66372L15.8791 15.1407C15.8381 15.9134 15.5153 16.6403 14.977 17.1721C14.4386 17.7039 13.7256 18.0002 12.9846 18H6.63577C5.89484 18 5.18207 17.7036 4.64388 17.1718C4.1057 16.6401 3.78299 15.9132 3.74204 15.1407L3.13187 3.66372H1.40509C1.24326 3.66372 1.08807 3.59659 0.973636 3.47709C0.859207 3.3576 0.794922 3.19554 0.794922 3.02655C0.794922 2.85756 0.859207 2.6955 0.973636 2.576C1.08807 2.45651 1.24326 2.38938 1.40509 2.38938H18.1848C18.3466 2.38938 18.5018 2.45651 18.6162 2.576C18.7306 2.6955 18.7949 2.85756 18.7949 3.02655C18.7949 3.19554 18.7306 3.3576 18.6162 3.47709C18.5018 3.59659 18.3466 3.66372 18.1848 3.66372H16.4892ZM4.35374 3.66372L4.96009 15.0706C4.98388 15.5179 5.1708 15.9386 5.48245 16.2464C5.7941 16.5543 6.2068 16.7257 6.63577 16.7257H12.9846C13.4135 16.7257 13.8263 16.5543 14.1379 16.2464C14.4496 15.9386 14.6365 15.5179 14.6603 15.0706L15.2666 3.66372H4.35374ZM7.50679 1.27434H12.0831C12.2449 1.27434 12.4001 1.20721 12.5145 1.08771C12.6289 0.968222 12.6932 0.806156 12.6932 0.637168C12.6932 0.468181 12.6289 0.306114 12.5145 0.186622C12.4001 0.06713 12.2449 0 12.0831 0H7.50679C7.34496 0 7.18976 0.06713 7.07533 0.186622C6.9609 0.306114 6.89662 0.468181 6.89662 0.637168C6.89662 0.806156 6.9609 0.968222 7.07533 1.08771C7.18976 1.20721 7.34496 1.27434 7.50679 1.27434Z" fill="white" />
        <path d="M7.67432 7.4067V12.5064C7.67432 12.6754 7.7386 12.8375 7.85303 12.957C7.96746 13.0765 8.12266 13.1436 8.28449 13.1436C8.44631 13.1436 8.60151 13.0765 8.71594 12.957C8.83037 12.8375 8.89466 12.6754 8.89466 12.5064V7.4067C8.89466 7.23771 8.83037 7.07565 8.71594 6.95615C8.60151 6.83666 8.44631 6.76953 8.28449 6.76953C8.12266 6.76953 7.96746 6.83666 7.85303 6.95615C7.7386 7.07565 7.67432 7.23771 7.67432 7.4067ZM10.7252 7.4067V12.5064C10.7252 12.6754 10.7894 12.8375 10.9039 12.957C11.0183 13.0765 11.1735 13.1436 11.3353 13.1436C11.4972 13.1436 11.6524 13.0765 11.7668 12.957C11.8812 12.8375 11.9455 12.6754 11.9455 12.5064V7.4067C11.9455 7.23771 11.8812 7.07565 11.7668 6.95615C11.6524 6.83666 11.4972 6.76953 11.3353 6.76953C11.1735 6.76953 11.0183 6.83666 10.9039 6.95615C10.7894 7.07565 10.7252 7.23771 10.7252 7.4067Z" fill="white" />
    </svg>

}
const CHkanpic = () => {
    return <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6002 16.9028L15.7083 14.1141C17.1959 12.6135 18.1159 10.5861 18.1159 8.35117C18.1159 3.74646 14.2307 0 9.4554 0C4.68015 0 0.794922 3.74646 0.794922 8.35117C0.794922 12.9559 4.68015 16.7023 9.4554 16.7023C11.4333 16.7023 13.2534 16.0522 14.713 14.9717L17.6575 17.8111C17.7193 17.871 17.7927 17.9185 17.8735 17.9509C17.9543 17.9833 18.041 18 18.1285 18C18.2161 18 18.3028 17.9833 18.3836 17.9509C18.4644 17.9185 18.5378 17.871 18.5995 17.8111C18.7245 17.6907 18.7948 17.5274 18.7949 17.3571C18.795 17.1867 18.725 17.0233 18.6002 16.9028ZM2.1273 8.35117C2.1273 4.45503 5.41496 1.28479 9.4554 1.28479C13.4958 1.28479 16.7835 4.45503 16.7835 8.35117C16.7835 12.2473 13.4958 15.4175 9.4554 15.4175C5.41496 15.4175 2.1273 12.2473 2.1273 8.35117Z" fill="white" />
        <path d="M12.4905 7.70817H10.1215V5.45978C10.1215 5.28941 10.0513 5.12601 9.92638 5.00554C9.80144 4.88506 9.63199 4.81738 9.45531 4.81738C9.27862 4.81738 9.10918 4.88506 8.98424 5.00554C8.85931 5.12601 8.78912 5.28941 8.78912 5.45978V7.70817H6.45745C6.28077 7.70817 6.11132 7.77585 5.98638 7.89633C5.86145 8.0168 5.79126 8.18019 5.79126 8.35057C5.79126 8.52094 5.86145 8.68434 5.98638 8.80481C6.11132 8.92529 6.28077 8.99297 6.45745 8.99297H8.78912V11.2414C8.78912 11.4117 8.85931 11.5751 8.98424 11.6956C9.10918 11.8161 9.27862 11.8838 9.45531 11.8838C9.63199 11.8838 9.80144 11.8161 9.92638 11.6956C10.0513 11.5751 10.1215 11.4117 10.1215 11.2414V8.99297H12.4905C12.6672 8.99297 12.8366 8.92529 12.9615 8.80481C13.0865 8.68434 13.1567 8.52094 13.1567 8.35057C13.1567 8.18019 13.0865 8.0168 12.9615 7.89633C12.8366 7.77585 12.6672 7.70817 12.4905 7.70817Z" fill="white" />
    </svg>

}

// select 供应商 
//供应商
function _supplierId(name) {
    const search = window.location.href
    if (!search) return null
    const item = search.split('&').find(v => {
        return v.includes(name)
    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}

// 设备分类
function _categoryId(name) {
    const search = window.location.href
    if (!search) return null
    const item = search.split('&').find(v => {
        return v.includes(name)
    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}
// categoryParentId设备分类父级id
function _categoryParentId(name) {
    const search = window.location.href
    if (!search) return null
    const item = search.split('&').find(v => {
        return v.includes(name)
    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}
export class index extends Component {
    state = {
        visible: false,//查看
        operation: false,//操作记录
        addvisible: false,//添加
        delete: false,//删除,
        filesUrl: "",
        uploadiIagesList: [],
        isup: 'add',
        //当前编辑的id
        editId: null,
        // 4个搜索框，
        _shebeiname: '',


        _img_visible: false,// 图片预览的显示野牛仓
        dataSource: [],//大表格
        dataSourceLogin: true,//大表戈加载

        pageSize: 10,
        pageNum: 1,
        total: 0,
        dataSourceTable: [], //操作表格
        dataSourceTableLogin: true,//操作记录lodin g
        pageSizeLogin: 10,
        pageNumLogin: 1,
        totalLogin: 0,

        _supplierId: _supplierId('supplierId'),//供应商id
        _categoryId: _categoryId('categoryId'),//设备分类id
        _categoryParentId: _categoryParentId('categoryParentId'),//设备分类父级id
        _optionlist: [],//供应商下拉框弹框
        _op_tionlist_select: [],//供应商下拉框筛选
        _gongyingshang: undefined,//初始值供应商

        _deletelid: '',//删除id
        _listByLevel: [],//设备大类
        _list_dalei: [],//设备大类  下拉框 弹窗
        _dalei: undefined,//设置值大类

        _tan_c: [],//设备类别 下拉框 弹窗
        _listByParentId: [],//设备类别

        _shebeileibie: undefined,//设备类别初始值
        __detail: {},//详情
        ImgList: [],
        curPreview:0,
    }
    // 删除Model
    deleteModal = id => {
        this.setState({
            delete: true,
            _deletelid: id
        });
    };

    deleteOk = async v => {


        const { _deletelid } = this.state
        await shebei_remove({ equipmentTypeId: _deletelid }).then(res => {
            if (res.code === 0) {
                const { _categoryId, _supplierId, _categoryParentId, pageSizeLogin, pageNumLogin } = this.state
                this.getData({
                    categoryParentId: _categoryParentId,
                    categoryId: _categoryId,
                    supplierId: _supplierId,
                    pageNum: this.state.pageNum,
                    pageSize: this.state.pageSize
                })
                this.mesSuccess("删除成功")
            } else {
                this.mesWarning('删除失败')
            }

        })
        this.setState({
            delete: false,
        });
    };

    deleteCancel = e => {
        console.log(e);
        this.setState({
            delete: false,
        });
    };
    // 添加
    addshowModal = async (v) => {
        if (this.state.isup === 'add') {
            this.setState(() => {
                return {
                    addvisible: true
                }
            })
        } else if (this.state.isup === 'edit') {
            const det = await cvecequipmenttypedetail({ equipmentTypeId: v.equipmentTypeId })
            const _tan_c = await listByParentId({//分类
                parentId: det.categoryParentId
            })
            this.setState(() => {
                return {
                    addvisible: true,
                    _tan_c: _tan_c,
                    uploadiIagesList: det.imgs
                }
            }, () => {



                let hh = {
                    equipmentName: det.equipmentName,
                    supplierId: det.supplierId,
                    categoryParentId: det.categoryParentId,
                    categoryId: det.categoryId,
                    equipmentParam: det.equipmentParam,
                    equipmentExplain: det.equipmentExplain,
                    uploadiIagesList: det.imgs,
                }

                this.props.form.setFieldsValue(hh)


            })
        }
    };

    addhandleOk = e => {
        if (this.state.isup === "add") {
            e.preventDefault();

            this.props.form.validateFields(["equipmentName", "supplierId", "categoryParentId", "categoryId", "equipmentParam", "equipmentExplain"], async (err, values) => {
                console.log(values, 'value')
                const { uploadiIagesList } = this.state
                const arr = []
                uploadiIagesList.forEach(img => {
                    arr.push({
                        imgUrl: img.fpName,
                        mediaType: img.mediaType
                    })
                })

                if (!err) {
                    let b = await shebei_save({
                        equipmentName: values.equipmentName,
                        supplierId: values.supplierId,
                        categoryParentId: values.categoryParentId,
                        categoryId: values.categoryId,
                        equipmentParam: values.equipmentParam,
                        equipmentExplain: values.equipmentExplain,
                        imgs: arr
                    })
                    if (b.code === 0) {
                        this.mesSuccess("添加成功")
                        const { _categoryId, _supplierId, _categoryParentId, pageSizeLogin, pageNumLogin } = this.state
                        this.getData({
                            categoryParentId: _categoryParentId,
                            categoryId: _categoryId,
                            supplierId: _supplierId,
                            pageNum: this.state.pageNum,
                            pageSize: this.state.pageSize
                        })
                        this.setState(() => {
                            return {
                                addvisible: false,
                                uploadiIagesList: [],
                                editId: null
                            }
                        });
                    } else {
                        this.mesWarning('添加失败')

                    }

                }
            })
            // 编辑
        } else {
            this.props.form.validateFields(["equipmentName", "supplierId", "categoryParentId", "categoryId", "equipmentParam", "equipmentExplain"], async (err, values) => {

                if (!err) {
                    const { editId, uploadiIagesList } = this.state
                    const arr = []
                    uploadiIagesList.forEach(img => {

                        arr.push({
                            imgUrl: img.fpName ? img.fpName : img.imgUrl,
                            mediaType: img.mediaType,
                            typeImgId: img.typeImgId

                        })
                    })
                    const { code } = await shebei_edit({ ...values, equipmentTypeId: editId, imgs: arr })

                    if (code === 0) {
                        this.mesSuccess("修改成功")
                    } else {
                        this.mesWarning('修改失败')
                    }
                    const { _categoryId, _supplierId, _categoryParentId, pageSizeLogin, pageNumLogin } = this.state
                    this.getData({
                        categoryParentId: _categoryParentId,
                        categoryId: _categoryId,
                        supplierId: _supplierId,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize
                    })
                    this.setState(() => {
                        return {

                            addvisible: false,
                            uploadiIagesList: [],
                            editId: null
                        }
                    })
                }
            })
        }
    };


    addhandleCancel = e => {
        console.log(e);
        this.setState({
            addvisible: false,
            uploadiIagesList: [],
            editId: null
        });
    };
    // 操作记录
    operationModal = () => {
        this.getDataList({
            pageSize: this.state.pageSizeLogin,
            pageNum: this.state.pageNumLogin
        })
        this.setState({
            operation: true,
        });


    };

    operationleOk = e => {
        console.log(e);
        this.setState({
            operation: false,
        });
    };

    operationCancel = e => {
        console.log(e);
        this.setState({
            operation: false,
        });
    };
    // operationleOk
    // 查看抽屉
    showDrawer = async (id) => {
        const _detail = await cvecequipmenttypedetail({
            equipmentTypeId: id
        })

        this.setState({
            visible: true,
            __detail: _detail
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });

    };
    // 输入筛选
    headerSearch = (target) => {
        const { name, value } = target
        console.log(name, value, '999')
        this.setState(() => {
            return {
                [name]: value
            }
        })
    }
    // 查询按钮
    chaxun = () => {
        const { _shebeiname, _dalei, _gongyingshang, _shebeileibie, pageSize, pageNum } = this.state
        this.getData({
            equipmentName: _shebeiname,
            categoryParentId: _dalei,
            supplierId: _gongyingshang,
             categoryId: _shebeileibie,
            pageSize: pageSize,
            pageNum: pageNum
        })
    }
    // 重置按钮
    resete = () => {
        const { _shebeiname, _listByLevel, _listByParentId } = this.state
        this.setState(() => {
            return {

                _dalei: undefined,//设备大类
                _shebeiname: undefined,//设备名称
                _gongyingshang: undefined,//供应商
                _shebeileibie: undefined,//类别

            }
        }, () => {
            this.getData({
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize
            })
        })
    }

    // 供应商
    onChange = (item) => {
        this.setState(() => {
            return {
                _gongyingshang: item
            }
        })

    }
    // 设备大类
    thunder_onChange = async (item) => {
        await this.getlistByParentId(item)
        this.setState(() => {
            return {
                _dalei: item
            }
        })
    }

    // 设备类别category
    category_onChange = (item) => {
        this.setState(() => {
            return {
                _shebeileibie: item
            }
        })

    }




    // 弹框 设备大类
    Equipmentadd_onChange = async (item) => {
        this.props.form.setFieldsValue({ categoryId: undefined })
        const _tan_c = await listByParentId({//分类
            parentId: item
        })
        this.setState(() => {
            return {

                _tan_c: _tan_c
            }
        })
    }


    //上传图片
    changeLoad = async (file) => {
        if (file.size) {
            let size = file.size / 1024 / 1024
            if (size > 1) {
                message.error('上传失败，不能超过1M')
                return false
            }
        }
        try {
            const fromdata = new FormData()
            fromdata.append('file', file)
            fromdata.append('folder', 'ab')
            uploadFile(fromdata).then(res => {
                if (res) {
                    this.setState((state) => {
                        const { uploadiIagesList } = this.state
                        uploadiIagesList.push({ ...res })
                        return {
                            uploadiIagesList
                        }
                    }, () => {
                        console.log(this.state.uploadiIagesList)
                    })

                } else {
                    console.log(res)

                }
            })
        } catch (err) {
            console.log(err)
        }
    };
    _img_visiblecancel = () => {
        this.setState(() => {
            return {
                _img_visible: false
            }
        })
    }

    addDiv = () => {
        const styles = {
            display: 'flex',
            justifyContent: 'space-around'
        }
        return <><div className='add_image_div'>
            <div style={{ ...styles }}>
                <img style={{ width: "19px", height: "19px", display: "block" }}
                    src={window.$$img('cvec-portal-front-pingtai/Circl/add_icon.png')} alt="" />
            </div>
            <span style={{ color: "#3E78ED", fontSize: "12px", marginTop: "8px", display: 'inline-block' }}>{"上传图片"}</span>

        </div>

        </>;
    }
    componentDidMount() {
        const { _categoryId, _supplierId, _categoryParentId, pageSizeLogin, pageNumLogin } = this.state
        this.getData({
            categoryParentId: _categoryParentId,
            categoryId: _categoryId,
            supplierId: _supplierId,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })

        this.getoptionlist()
        this.getlistByLevel()

    }
    // 设备大类下拉框
    getlistByLevel = async (p) => {
        const ip_by = await listByLevel({
            categoryLevel: '1'
        })
        if (ip_by) {
            this.setState(() => {
                return {
                    _listByLevel: ip_by
                }
            })
        } else {
            this.mesWarning('数据错误')
        }

    }
    // 设备分类
    getlistByParentId = async (parentId) => {
        const ip_by = await listByParentId({
            parentId
        })
        this.setState(() => {
            return {
                _listByParentId: ip_by
            }
        })
    }


    // 供应商下卡框
    getoptionlist = async (pp) => {
        const _op_tion = await optionlist(pp)
        if (_op_tion) {
            this.setState(() => {
                return {
                    _op_tionlist_select: _op_tion

                }
            })

        } else {
            this.mesWarning('数据错误')
        }

    }

    getData = async (pp) => {

        const _page = await page(pp)
        const { pageSize, pageNum, _categoryId, _supplierId, _categoryParentId, dataSourceLogin } = this.state
        if (pp) {
            if (
                _page && _page.data
            ) {
                this.setState(() => {
                    return {
                        dataSource: _page.data,
                        dataSourceLogin: false,
                        pageNum: pageNum,
                        pageSize: pageSize,
                        total: _page.total,

                    }
                })
            } else {
                this.mesWarning('数据错误')
            }

        } else {
            this.mesWarning('数据错误')
        }

    }

    //pageSize变化
    onShowSizeChange = (current, pageSize) => {

        this.setState(() => ({
            pageNum: current,
            pageSize: pageSize,
        }), () => {
            const { pageSize, pageNum, _categoryId, _supplierId, _categoryParentId } = this.state
            this.getData({
                categoryParentId: _categoryParentId,
                categoryId: _categoryId,
                supplierId: _supplierId,
                pageSize: pageSize,
                pageNum: pageNum,

            });
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState(() => ({
            pageNum: current,
            pageSize: pageSize
        }), () => {
            const { pageSize, pageNum, _categoryId, _supplierId, _categoryParentId } = this.state
            this.getData({
                categoryParentId: _categoryParentId,
                categoryId: _categoryId,
                supplierId: _supplierId,
                pageSize: pageSize,
                pageNum: pageNum,

            });
        }
        );
    };
    getDataList = (pp) => {
        return pageListdara({
            operateType: 3,
            pageSize: pp.pageSize,
            pageNum: pp.pageNum
        }).then(res => {

            if (res && res.data) {

                this.setState(() => {
                    return {
                        dataSourceTable: res.data,
                        totalLogin: res.total,
                        pageSizeLogin: res.pageSize,
                        pageNumLogin: res.pageNum,
                        dataSourceTableLogin: false

                    }
                })
            } else {
                this.mesWarning('数据错误')
            }

        })
    }
    //pageSize变化
    onShowSizeChangeLogin = (current, pageSizeLogin) => {
        // console.log(current, pageSize, ":sdd")
        this.setState({
            pageNumLogin: current,
            pageSizeLogin: pageSizeLogin,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSizeLogin,
                pageNum: this.state.pageNumLogin
            });
        }
        );
    };

    //pageNum变化
    onPageNumChangeLogin = (current, pageSizeLogin) => {
        // console.log(current, pageSize, ":sdd")
        this.setState({
            pageNumLogin: current,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSizeLogin,
                pageNum: this.state.pageNumLogin
            });
        }
        );
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { filesUrl, dataSource, dataSourceTable, __detail } = this.state
        const { RenderButton } = React;
        const formItemLayout_aaaa = {
            labelCol: {
                xs: { span: 2 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 2 },
                sm: { span: 12 },
            },
        };

        // Table
        const columns = [
            {
                title: '设备图片',
                dataIndex: 'name',
                align: "center",
                render: (a, b, ) => {
                    // console.log(v, b, 'vvvbbb')
                    return <div >
                        <img style={{ width: "70px", height: "50px" }} src={window.$$imgSrc(b.imgUrl)}></img>

                    </div>
                },

            },
            {
                title: '设备名称',
                dataIndex: 'equipmentName',
                align: "center",
            },
            {
                title: '设备类别',
                dataIndex: 'categoryName',
                align: "center",
            },
            {
                title: '设备参数',
                dataIndex: 'equipmentParam',
                align: "center",
                render: (text, record, index) => <p>
                    <Tooltip title={record.equipmentParam} placement="bottom" overlayClassName="PublicTableTooltip">

                        {record.equipmentParam && record.equipmentParam.length > 18 ? record.equipmentParam.substring(0, 18) + '...' : record.equipmentParam}
                    </Tooltip>
                </p>,
            }, {
                title: '备注',
                dataIndex: 'equipmentExplain',

                render: (text, record, index) => <p>
                    <Tooltip title={record.equipmentExplain} placement="bottom" overlayClassName="PublicTableTooltip">

                        {record.equipmentExplain && record.equipmentExplain.length > 18 ? record.equipmentExplain.substring(0, 18) + '...' : record.equipmentExplain}
                    </Tooltip>

                </p>,
                align: "center",
            },

            {
                title: '操作',
                render: (v) => {
                    // console.log(v, b, 'vvvbbb')
                    return <div className='action vertical_j' style={{ cursor: 'pointer' }}>
                        <RenderButton id="ABB-01-A03-b_btnView"> <span onClick={() => {
                            this.showDrawer(v.equipmentTypeId)
                        }} >查看</span></RenderButton>

                        <RenderButton id="ABB-01-A03-b_btnEdit">
                            <span onClick={() => {

                                this.setState(() => {
                                    return {
                                        isup: 'edit',
                                           //存储当前行的id,
                                        // editId: '假装有一个id'
                                    }
                                }, async (parentId) => {
                                    const _optionlist = await optionlist()//供应商
                                    const _list_dalei = await listByLevel({
                                        categoryLevel: '1', parentId
                                    })//大类

                                    this.setState(() => {
                                        return {
                                            _optionlist: _optionlist,
                                            _list_dalei: _list_dalei,
                                            editId: v.equipmentTypeId,



                                        }
                                    }, () => {
                                        this.addshowModal(v)
                                    })

                                })
                            }}>修改</span>
                        </RenderButton>

                        <RenderButton id="ABB-01-A03-b_btnDel">
                            
                            <span onClick={() => {
                            
                                this.deleteModal(v.equipmentTypeId)
                            }}>删除</span>
                            
                        </RenderButton>

                    </div>
                },
                align: "center",
            },
        ];
        // 操作记录Table

        const columnsTable = [
            {
                title: '操作人',
                dataIndex: 'account',
                key: 'name',

            },
            {
                title: '硬件设备型号管理',
                dataIndex: 'operationDesc',
                key: 'age',
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'address',
            },
            {
                title: '动作',
                dataIndex: 'actionDscp',
                key: 'address',
            },
        ].map((i) => {
            i.align = "center"
            return i
        });

        return (
            <div className="Equipment_model_management">
                <div className="Equipment_model_management_top">
                    <div>
                        <Input placeholder="设备名称"
                            name="_shebeiname"

                            onChange={(e) => this.headerSearch(e.target)} value={this.state._shebeiname}
                            style={{ width: 200 }}
                        />
                        <Select
                            style={{ width: 200, marginLeft: 30 }}
                            placeholder="供应商"
                            name="_gongyingshang"
                            value={this.state._gongyingshang}
                            onChange={this.onChange}
                        >

                            {
                                this.state._op_tionlist_select.map((item, index) => {

                                    return <Option key={item.supplierId}>
                                        {item.supplierName}
                                    </Option>
                                })

                            }
                        </Select>

                        <Select
                            style={{ width: 200, marginLeft: 30 }}
                            placeholder="设备大类"
                            value={this.state._dalei}
                            onChange={this.thunder_onChange}
                            name="_shebeileibie"
                        >
                            {
                                this.state._listByLevel.map((item, index) => {
                                    return <Option key={item.categoryId}>
                                        {item.categoryName}
                                    </Option>
                                })

                            }
                        </Select>
                        <Select

                            style={{ width: 200, marginLeft: 30 }}
                            placeholder="设备类别"
                            value={this.state._shebeileibie}
                            onChange={this.category_onChange}

                        >
                            {
                                this.state._listByParentId.map((item, index) => {

                                    return <Option key={item.categoryId}>
                                        {item.categoryName}
                                    </Option>
                                })


                            }
                        </Select>
                    </div>
                    <div>
                        <Button style={{ marginRight: 12 }}
                            onClick={() => {
                                this.resete()
                            }}
                        >重置</Button>
                        <Button type="primary" onClick={() => {
                            this.chaxun()
                        }}>查询</Button>
                    </div>
                </div>
                <div className="Equipment_model_management_main">
                    <RenderButton id="ABB-01-A03-b_btnAdd">
                        <Button type="primary" onClick={() => {
                            this.setState(() => {
                                return {
                                    isup: 'add'
                                }
                            }, async (parentId) => {
                                const _optionlist = await optionlist()//供应商
                                const _list_dalei = await listByLevel({
                                    categoryLevel: '1', parentId
                                })//大类

                                this.setState(() => {
                                    return {
                                        _optionlist: _optionlist,
                                        _list_dalei: _list_dalei,


                                    }
                                }, () => {
                                    this.addshowModal()
                                })

                            })
                        }}>添加</Button></RenderButton>
                    <RenderButton id="ABB-01-A03-b_btnOperatingRecord"><Button onClick={this.operationModal}>操作记录</Button></RenderButton>


                </div>
                <div className="Equipment_model_management_main_table">
                    <Table dataSource={dataSource} columns={columns} rowKey='equipmentTypeId'

                        scroll={{ y: "calc(100vh - 460px)", x: "calc(100% - 700px)" }}
                        loading={this.state.dataSourceLogin}
                        pagination={
                            {
                                defaultCurrent: 1,
                                pageSizeOptions: ['10', '20', '30'],
                                showSizeChanger: true,
                                onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                onChange: (a, b) => this.onPageNumChange(a, b),
                                current: this.state.pageNum,
                                pageSize: this.state.pageSize,
                                total: this.state.total,
                            }

                        } />
                </div>
                <div>

                    <Drawer
                        title="设备型号信息"
                        placement="right"
                        closable={false}
                        // onClose={this.onClose}
                        visible={this.state.visible}
                        width={600}
                        className="information"

                    >
                        <p><span>设备型号:</span><span>{__detail.equipmentTypeId}</span></p>
                        <p><span>供应商:</span><span>{__detail.supplierName}</span></p>
                        <p><span>设备大类:</span><span>{__detail.categoryParentName}</span></p>
                        <p><span>设备类别:</span><span>{__detail.categoryName}</span></p>
                        <p style={{ display: 'flex' }}><span>设备参数:</span><span className="parameters">
                            <span>
                                {__detail.equipmentParam}
                            </span></span></p>
                        <p style={{ display: 'flex' }}><span>备注说明:</span><span className="parameters">
                            <span>{__detail.equipmentExplain}
                            </span></span></p>
                        <p style={{ display: 'flex' }}><span>设备图片:</span><span className='chakan_img_wrappers'>{

                            Array.isArray(__detail.imgs) && __detail.imgs.map((v,index) => {
                                return <div key={v.typeImgId} className='appss'>
                                    <img alt='' style={{ width: '100%', height: '100%' }} onClick={() => {
                                        this.setState(() => {
                                            return {
                                                _img_visible: true
                                            }
                                        })
                                    }} src={window.$$imgSrc(v.imgUrl)}></img>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        className='displaynodean'>

                                        <span onClick={() => {
                                           
                                             
                                            this.setState((state) => {
                                                return {
                                                    _img_visible: true,
                                                    ImgList: __detail.imgs.map(o => {
                                                        debugger
                                                        o.fpName = o.imgUrl
                                                        return o
                                                    }),
                                                    curPreview:index,
                                                }
                                            })
                                        }}> <CHkanpic></CHkanpic></span>
                                    </div>
                                </div>
                            })

                        }</span></p>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e8e8e8',
                                padding: '10px 16px',
                                textAlign: 'right',
                                left: 0,
                                background: '#fff',
                                borderRadius: '0 0 4px 4px',
                            }}
                        >

                            <Button onClick={this.onClose} type="primary" style={{ width: 90, height: 40, position: 'relative', right: 400 }}>
                                我知道了
                            </Button>
                        </div>
                    </Drawer>

                </div>
                {/* 操作记录 */}
                <Modal
                    title={<div style={{ fontWeight: "bold", color: '#333', fontSize: 18 }}>操作记录</div>}
                    visible={this.state.operation}

                    onCancel={this.operationCancel}
                    bodyStyle={{ height: 300,overflow:'auto' }}
                    width={1000}
                    centered
                    closable={false}
wrapClassName="EquipmentClass"
                    footer={[


                        <Button key="submit" type="primary" onClick={this.operationleOk} style={{ width: 90, height: 38 }}>
                            我知道了
                        </Button>,]}
                >
                    <div>
                        <Table dataSource={dataSourceTable} columns={columnsTable}
                            loading={this.state.dataSourceTableLogin}
                            scroll={{ y: 350 }}
                            pagination={
                                {
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['10', '20', '30'],
                                    showSizeChanger: true,
                                    onShowSizeChange: (a, b) => this.onShowSizeChangeLogin(a, b),
                                    onChange: (a, b) => this.onPageNumChangeLogin(a, b),
                                    current: this.state.pageNumLogin,
                                    pageSize: this.state.pageSizeLogin,
                                    total: this.state.totalLogin,
                                }
                            } />;
                    </div>

                </Modal>
                {/* 添加 */}
                <div>

                    <Modal key={this.state.addvisible}//强制更新
                        title={<div style={{ fontSize: 18, color: '#333', fontWeight: 500 }}>{this.state.isup === "add" ? "添加资源" : '修改资源'}</div>}
                        visible={this.state.addvisible}
                        onOk={this.addhandleOk}
                        onCancel={this.addhandleCancel}
                        className={'addvisible_cont'}
                        width={660}
                        centered={true}
                        destroyOnClose
                        wrapClassName='Device_information'
                    >
                        <Form {...formItemLayout_aaaa}>
                            <Form.Item label="设备名称">
                                {getFieldDecorator('equipmentName', {
                                    rules: [{ required: true, message: '请输入设备名称' }],
                                })(
                                    <MaxLengthInput placeholder='请输入设备名称' style={{ height: 40, paddingRight: 50 }} maxLength={30}></MaxLengthInput>,
                                )}
                            </Form.Item>
                            <Form.Item label="供应商">
                                {getFieldDecorator('supplierId', {
                                    rules: [{ required: true, message: '请选择供应商' },],
                                })(
                                    <Select
                                        placeholder="请选择供应商"
                                    >
                                        {
                                            this.state._optionlist.map((item, index) => {
                                                return <Option key={item.supplierId}>
                                                    {item.supplierName}
                                                </Option>
                                            })
                                        }
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item label="设备大类">
                                {getFieldDecorator('categoryParentId', {
                                    rules: [{ required: true, message: '请选择设备大类' }],
                                })(
                                    <Select
                                        placeholder="请选择设备大类"

                                        onChange={this.Equipmentadd_onChange}
                                    >
                                        {
                                            this.state._list_dalei.map((item, index) => {
                                                return <Option key={item.categoryId}>
                                                    {item.categoryName}
                                                </Option>
                                            })

                                        }
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item label="设备类别">
                                {getFieldDecorator('categoryId', {
                                    rules: [{ required: true, message: '请选择设备类别' }],
                                })(
                                    <Select


                                        placeholder="请选择设备类别"



                                    >
                                        {
                                            this.state._tan_c.map((item, index) => {
                                                return <Option key={item.categoryId}>
                                                    {item.categoryName}
                                                </Option>
                                            })

                                        }
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item label="设备参数">
                                {getFieldDecorator('equipmentParam', {
                                    rules: [{ required: true, message: '请输入设备参数' }],
                                })(


                                    <MaxLengthInput placeholder='请输入设备参数' type='textarea' isTextarea={true} style={{
                                        height: 100, paddingRight: 50, resize: 'none'
                                    }} maxLength={300}></MaxLengthInput>

                                )}
                            </Form.Item>
                            <Form.Item label="备注">
                                {getFieldDecorator('equipmentExplain', {
                                    rules: [{ required: false, message: '请输入备注' }],
                                })(

                                    <MaxLengthInput placeholder='请输入备注' type='textarea' isTextarea={true} style={{ height: 100, paddingRight: 50, resize: "none" }} maxLength={100}></MaxLengthInput>

                                )}
                            </Form.Item>
                            <Form.Item className='form-item' label="设备图片">
                                <div style={{ display: 'flex' }}>


                                    <div style={{ display: 'flex' }} className='up_load_img_wrappers'>
                                        {
                                            Array.isArray(this.state.uploadiIagesList) && this.state.uploadiIagesList.map((v,i) => {
                                                return <div className='apps'>
                                                    <img alt='' key={v.fpName} src={window.$$imgSrc(v.fpName ? v.fpName : v.imgUrl)} style={{ width: '100%', height: '100%' }} />
                                                    <div className='displaynodeandblock'>
                                                        <span
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                             
                                                                this.setState((state) => {
                                                                    const index = state.uploadiIagesList.findIndex(kkk => kkk.fpName === v.fpName)
                                                                    state.uploadiIagesList.splice(index, 1)
                                                                    return {
                                                                        uploadiIagesList: state.uploadiIagesList
                                                                    }
                                                                })
                                                            }}>  <S.Del1/></span>
                                                        <span
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                                                            
                                                                this.setState((state) => {
                                                                   
                                                                    return {    
                                                                        _img_visible: true,
                                                                        curPreview:i,
                                                                        ImgList: state.uploadiIagesList.map((o,p) => {
                                                                            debugger
                                                                            o.fpName = o.fpName ||o.imgUrl
                                                                            
                                                                            return o
                                                                        })
                                                                    }
                                                                },()=>{

                                                                    console.log(this.state.ImgList,';;;;')
                                                                })
                                                            }}> <S.FangDa/></span>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    {
                                        Array.isArray(this.state.uploadiIagesList) && this.state.uploadiIagesList.length < 3 && getFieldDecorator('imgs', {
                                            initialValue: '',
                                            valuePropName: 'fileList',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '上传图片不能为空'
                                                }
                                            ]
                                        })(

                                            <Upload

                                                accept='.jpg,.png,.jpeg,.bmp'
                                                listType="picture-card"
                                                showUploadList={false}
                                                beforeUpload={(file) => this.changeLoad(file)}
                                            >
                                                {

                                                    filesUrl ? <div className='replace_img'>
                                                     <S.Blue_plus/>
                                                    </div>
                                                        :
                                                        this.addDiv()
                                                }

                                            </Upload>

                                        )
                                    }
                                    {
                                        Array.isArray(this.state.uploadiIagesList) && this.state.uploadiIagesList.length > 0 ?
                                            null : <div className="tishi_wrap_equi">
                                                <p className='tishiyu'>最多上传3张图片<br />支持jpg、jpeg、png格式，图片不超过1M</p>
                                            </div>
                                    }

                                </div>
                            </Form.Item>
                        </Form>

                    </Modal>
                    {/* 删除 */}
                    
                    <Modal
                        title={<div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}>删除提示:
                    
                        {
                                dataSource.find((v, i) => {
                                    return v.equipmentTypeId === this.state._deletelid
                                })?.equipmentName
                            }



                        </div>}
                        visible={this.state.delete}
                        onOk={this.deleteOk}
                        onCancel={this.deleteCancel}
                        bodyStyle={{ height: 130 }}
                        width={450}
                        centered
                        closable={false}
                        wrapClassName='details_delete_she_emt'

                    >
                        <div>
                            <p><span><Yichang></Yichang></span><span>是否确定删除此条设备？</span></p>

                        </div>

                    </Modal>

                    {this.state._img_visible &&
                 
                    // uploadiIagesList
                        <ImagePreview
                            visible={this.state._img_visible}
                            ImgList={this.state.ImgList}
                            onCancel={this._img_visiblecancel}
                            srcProp="fpName"
                            curPreview={this.state.curPreview}
                        ></ImagePreview>
                        
                    }
                    {console.log(this.state.ImgList,'llllll')}
                </div>
            </div>
        )
    }
}
export default Form.create()(index);
