import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import './index.scss';
import {Button, Input, Col, Modal, Row, Form, message, Select,Tooltip,Table} from 'antd';
import MyTable from '@/components/Table';
import update from "immutability-helper";
import AddCourse from "./components/index";
import TransferClassify from "../components/transferclassify";
import {managerPageList,firstSelect,secondSelect,showCancel,setTop,delById,selectList} from '@/api/craftsman-app/index'
import ConfigureToHomeModel from "./components/ConfigureToHome";
const {Option} = Select;
const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}

class DiscoverManage extends React.Component {
    state = {
        isTop:false,  //置顶
        empty:true,
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            categoryId:"",
            categoryIdParent:"",
            courseType:'',
            isOpenClass:'',
            keyWord:null,
            pageSizeOptions: ["10", "20", "30", "100"], //默认分页
        },
        isTable: true,
        name: "",//方向名称 全模糊
        keyName: "",
        displayOrder: "",//排序
        visible: false,
        isDrab: false,
        expandedRowKeys: [],
        dataSource: [],
        oneClassifyList:[],  //一级分类列表
        twoClassifyList:[], //二级分类列表
        categoryParentName:'',
        categoryName:'',
        courseName:'',
        discoverId:'',
        moduleVisible:false,
        moduleList:[],
        courseCode:''
    }

    componentDidMount() {
        this.managerPageList();
        this.firstSelect();
        this.selectList();
    }
    //版块列表
    async managerPageList() {
        const {pagination} =this.state
        const res = await managerPageList(this.state.pagination)
        pagination.total = res ? res.total : 0
        this.setState({
            dataSource: res.data,
            pagination:pagination
        }, () => {
            window.elGoTop && window.elGoTop('body')
        })
    }

    // 模块下拉列表
    async selectList() {
        const res = await selectList({
        })
        console.log("res---",res)
        this.setState({
            moduleList: res
        })
    }

    //是否开放班级查询
    isOpenChange = (value) => {
        const {pagination} = this.state
        pagination.isOpenClass=value
        this.setState({
            pagination: pagination
        }, () => {
            this.managerPageList()
        })
    };

    //班级类型查询
    courseTypeChange = (value) => {
        const {pagination} = this.state
        pagination.courseType=value
        this.setState({
            pagination: pagination
        }, () => {
            this.managerPageList()
        })
    };

    //一级分类查询
    oneClassifyChange = (value) => {
        const {pagination} = this.state
        pagination.categoryIdParent=value
        pagination.categoryId=''
        this.setState({
            pagination: pagination,
            twoClassifyList:[]
        }, () => {
            if (value!==null){
                this.secondSelect()
            }
            this.managerPageList()
        })
    };

    //二分级分类查询
    twoClassifyChange = (value) => {
        const {pagination} = this.state
        pagination.categoryId=value
        this.setState({
            pagination: pagination
        }, () => {
            this.managerPageList()
        })
    };

    // 课程分类一级分类下拉列表
    async firstSelect() {
        const {pagination} = this.state
        const res = await firstSelect({
            "categoryId" :''
        })
        this.setState({
            oneClassifyList: res
        })
    }
    // 课程分类二级分类下拉列表
    async secondSelect() {
        const {pagination} = this.state
        const res = await secondSelect({
            "parentId" :pagination.categoryIdParent
        })
        this.setState({
            twoClassifyList: res
        })
    }

    //搜索条件修改
    onKeyNameChange = ({target: {value}}) => {
        let pagination = this.state.pagination;
        pagination.keyWord=value
        this.setState({
            pagination: pagination
        });
    };
    //检索查询
    queryBtn = () => {
        let pagination = this.state.pagination;
        pagination.current = 1
        this.setState({
            pagination: pagination
        }, () => this.managerPageList());
    }
    //检索重置
    resetBtn = () => {
        let pagination = this.state.pagination;
        pagination.current = 1
        pagination.pageSize=10
        pagination.total= 0
        pagination.categoryId=""
        pagination.categoryIdParent=""
        pagination.courseType=''
        pagination.isOpenClass=''
        pagination.keyWord=''
        this.setState({
            pagination: pagination,
            twoClassifyList:[]
        }, () => this.managerPageList());
    }

    //新增板块
    addCourse = (record) => {
        this.setState({
            visible: !this.state.visible,
        })
    }
    //取消弹框
    checkCancel = () => {
        this.setState({
            visible: false,
            transferVisible: false,
            moduleVisible:false
        })
        this.managerPageList();
    }
    // 配置首页展示
    setShow=(type,courseName,discoverId,courseCode)=>{
        if (type===1){
            Modal.confirm({
                title: '温馨提示',
                content: '您确认要关闭'+courseName+'课程在App端首页的展现么？确认后，此课程将不会在App端的首页上展现。（但您仍旧可以通过App端的【发现】查看此课程）',
                type: 'warning',
                isCancel: true,
                onOk: () => {
                    showCancel({discoverId:discoverId}).then((res) => {
                        if (res.code === 0) {
                            message.success('解除成功');
                            this.managerPageList();
                        }
                    });

                }
            })
        }else{
            this.setState({
                discoverId:discoverId,
                courseCode:courseCode,
                moduleVisible : !this.state.moduleVisible
            })

        }

    }
    //调整分类
    transferClassifyModel = (record) => {
        this.setState({
            categoryParentName:record.categoryParentName,
            categoryName:record.categoryName,
            courseName:record.courseName,
            discoverId:record.discoverId,
            transferVisible: !this.state.transferVisible,
        })
    }
    //取消置顶
    setTop = (type,discoverId) => {
        let data = {discoverId: discoverId, isTop: type===0?1:0}
        setTop(data).then((res) => {
            if (res.code === 0) {
                message.success(type===0?"置顶成功":"取消置顶成功")
                this.managerPageList();
            }
        });
    }
    // 从APP下架
    appSold=(courseName,discoverId)=>{
        Modal.confirm({
            title: '温馨提示',
            content: '您确认要将'+courseName+'课程下架么？如果确定下架后，App端将无法再看到此课程。',
            type: 'warning',
            isCancel: true,
            onOk: () => {
                delById({discoverId:discoverId}).then((res) => {
                    if (res.code === 0) {
                        message.success('下架成功');
                        this.managerPageList();
                    }
                });
            }
        })
    }
    //分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination},()=> this.managerPageList())
    }
    //pageSize变化
    onShowSizeChange = (pageNum, pageSize) => {
        const {pagination} = this.state;
        pagination.pageNum = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.managerPageList())
    };
    render() {
        const {moduleVisible,pagination, isDrab, visible, dataSource,oneClassifyList,twoClassifyList,categoryParentName,categoryName,courseName,transferVisible,discoverId,moduleList,courseCode} = this.state;
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 16},
        };
        const  columns = [
            {title: '课程编号', dataIndex: 'courseCode', key: 'courseCode', align: 'center',width:'100px',},
            {title: '课程名称', dataIndex: 'courseName', key: 'courseName', align: 'center',width:'200px', ellipsis: true,
                render: (text, record, index) => <p className="stick">
                    <Tooltip placement="topLeft" title={record.courseName}>
                    <span>{record.courseName}</span>
                    </Tooltip>
                    <span className="stick_btn" style={{display: record.isTop===1? 'inline-block':'none'}}>置顶</span>
                </p>
            },
            {title: '课程类型', dataIndex: 'courseType', key: 'courseType', align: 'center',width:'100px',
                render: (text, record) =>
                { //课程类型 0-在线课 1-混合课 2-线下课
                    return (
                        <span>{(text===0 && "在线课")|| (text===1 && "混合课") ||  (text===2 && "线下课") }</span>
                    )
                }
                },
            {title: '一级分类', dataIndex: 'categoryParentName', key: 'categoryParentName', align: 'center',width:'150px'},
            {title: '二级分类', dataIndex: 'categoryName', key: 'categoryName', align: 'center'},
            {title: '所属机构', dataIndex: 'originName', key: 'originName', align: 'center',width:'150px'},
            {title: '开放班级', dataIndex: 'isOpenClass', key: 'isOpenClass', align: 'center',
                render:(text,record) =>
                {//是否开放班级 0否 1是
                    return (
                        <span>{(text===0 && "否") || (text===1 && "是")}</span>
                    )
                }
                },
            {title: '课程教师', dataIndex: 'courseTeacher', key: 'courseTeacher', align: 'center'},
            {title: '是否首页展示', dataIndex: 'isShow', key: 'isShow', align: 'center',width:'100px',
                render:(text,record) =>
                {//是否开放班级 0否 1是
                    return (
                        <span>{(text===0 && "否") || (text===1 && "是")}</span>
                    )
                }
            },
            {title: '推送到首页时间', dataIndex: 'createTime', key: 'createTime', align: 'center',width:'130px',
                render:(text,record)=>{
                    return <div>{text ? text.substring(0,10) :'---'}</div>;
                }
            },
            {
                title: '操作', dataIndex: 'equipmentId', key: 'equipmentId', fixed: 'right', align: 'center',width:'190px',
                render: (text, record, index) => <div className='action vertical_j'>
                    <p>
                        <span onClick={()=>this.setShow(record.isShow,record.courseName,record.discoverId,record.courseCode)}> {record.isShow===0?"配置到首页":"解除首页展示"}</span>
                        <span onClick={()=>this.setTop(record.isTop,record.discoverId)}>{(record.isTop===0 ? "置顶" : "取消置顶")}</span>
                    </p>
                    <p>
                        <span  onClick={()=>this.appSold(record.courseName,record.discoverId)}> 从APP下架</span>
                        <span  onClick={() => this.transferClassifyModel(record)}>调整分类</span>
                    </p>
                </div>
            }
        ]
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle discover_manage'>
                <div className="search_btn_box">
                        <Row className="navtop mb20_fff">
                            <Col span={8}>
                              <span className="w100">是否有开放班级</span>
                                <Select placeholder="请选择" className="w260" value={pagination.isOpenClass} onChange={this.isOpenChange} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    <Option value="">不限</Option>
                                    <Option value="1">是</Option>
                                    <Option value="0">否</Option>
                                </Select>
                            </Col>
                            <Col span={8}>
                                <span className="w100">课程类型</span>
                                <Select placeholder="请选择" className="w260" value={pagination.courseType} onChange={this.courseTypeChange} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    <Option value="">不限</Option>
                                    <Option value="0">在线课</Option>
                                    <Option value="1">混合课</Option>
                                    <Option value="2">线下课</Option>
                                </Select>

                            </Col>
                            <Col span={8}>
                                <span className="w100">一级分类</span>
                                <Select placeholder="请选择一级分类" className="w260" onChange={this.oneClassifyChange} value={pagination.categoryIdParent} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    <Option  value=''>不限</Option>
                                    {oneClassifyList && oneClassifyList.length ? oneClassifyList.map((item, index) => {
                                        return <Option key={index} value={item.categoryId}>{item.categoryName}</Option>
                                    }) : ""}
                                </Select>
                            </Col>
                            <Col span={8}>
                                <span className="w100">二级分类</span>
                                <Select placeholder="请选择二级分类" className="w260" onChange={this.twoClassifyChange} value={pagination.categoryId} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    <Option  value=''>不限</Option>
                                    {twoClassifyList && twoClassifyList.length ? twoClassifyList.map((item, index) => {
                                        return <Option key={index} value={item.categoryId}>{item.categoryName}</Option>
                                    }) : ""}
                                </Select>
                            </Col>
                            <Col span={16}>
                                <Button className='reset_btn'   onClick={() => this.resetBtn()}>重置</Button>
                                <Button type="primary" className="search_btn" onClick={() => this.queryBtn()}>搜 索</Button>
                            </Col>
                        </Row>
                </div>
                <div className="padd">
                    <div className="top">
                        <Col span={12} className="sort_boxs">
                            <Button className='sure_sort' type='primary' onClick={() => this.addCourse()}>新增课程</Button>
                        </Col>
                        <Col className='top_r btn_box' span={12}>
                            <Input value={pagination.keyWord} onChange={this.onKeyNameChange.bind(this)} onPressEnter={()=>this.queryBtn()} placeholder='请输入课程名称或者课程编号'/>
                            <span className="all_course">共{dataSource.length}门课程</span>
                        </Col>
                    </div>
                    <div className="check_container"
                         style={{position: 'relative'}}>
                        <Table
                            rowKey={v => v.discoverId}
                            columns={columns} dataSource={dataSource}
                               pagination={ {onChange: this.pageChange, ...pagination, showSizeChanger:true, onShowSizeChange: this.onShowSizeChange}}
                            /* locale={{emptyText:<><div className="Empty-conet">
                                     <div className="img">
                                         <img src={require('../../assets/img/images/Emptyp-icon.png')}/>
                                         <span>暂无数据</span>
                                     </div>
                                 </div></>}}*/
                        />
                        <DndProvider backend={HTML5Backend}>

                        {/*    <MyTable
                                className="index_block"
                                options={
                                    {
                                        dataSource,
                                        columns,
                                        pagination: {onChange: this.pageChange, ...pagination, showSizeChanger:true, onShowSizeChange: this.onShowSizeChange},
                                        onRow: (record, index) => ({
                                            index,
                                            moveRow: this.moveRow,
                                        })
                                    }
                                }
                                enumeration="discoverId"
                                isDrab={isDrab}
                                fixededit={true}
                            />*/}
                        </DndProvider>
                    </div>
                </div>
                {visible?
                <AddCourse
                    visible={visible}
                    checkCancel={this.checkCancel.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                ></AddCourse>
                    :""}
                <TransferClassify
                TransferType={0}
                transferVisible={transferVisible}
                categoryParentName={categoryParentName}
                categoryName={categoryName}
                courseName={courseName}
                discoverId={discoverId}
                checkCancel={this.checkCancel.bind(this)}
                wrappedComponentRef={form => {
                    this.reportReasonForm = form;
                }}
            />
            <ConfigureToHomeModel
                moduleVisible={moduleVisible}
                moduleList={moduleList}
                discoverId={discoverId}
                courseCode={courseCode}
                checkCancel={this.checkCancel.bind(this)}
                wrappedComponentRef={form => {
                    this.reportReasonForm = form;
                }}
            ></ConfigureToHomeModel>
            </div>
        )
    }
}


DiscoverManage = connect(mapStateToProps, mapDispatchToProps)(DiscoverManage);
DiscoverManage = Form.create()(DiscoverManage)
export default DiscoverManage;
