import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import './index.scss';


export default class OBreadcrumb extends Component {
    jump = (path) => {
        if (path === this.props.location.pathname) {
            return;
        }
        this.props.history.push(path);
    }

    render() {

        const { list = [] } = this.props;
        // console.log("props",this.props)
        return (
            <div className='breadcrumb_box'>
                <Breadcrumb separator={<span style={{ color: '#000' }}>{'/'}</span>}>
                    {
                        list.length && list.map((v, i) => {
                            if (v) {
                                return <Breadcrumb.Item key={i} style={{ color: '#000' }}
                                                        className={v.menuUrl ? 'pointer' : 'default'}
                                                        onClick={() => this.jump(v.menuUrl)}>
                                    {v.menuName}
                                </Breadcrumb.Item>
                            }
                        })
                    }
                </Breadcrumb>
            </div>
        )
    }
}


export { default as BreadcrumbFC, useBreadcrumbFC } from './BreadcrumbFC'