import { result } from './result'

export default [
    {
        name: 'APP内容管理',
        path: '/main/craftsman-app',
        component: result['Craftsman-app'],
        children: [
            {
                path: '/main/craftsman-app',
                redirect: '/main/craftsman-app/config-manage',

            },
            {
                name: '配置管理',
                path: '/main/craftsman-app/config-manage',
                component: result['Config-manage']
            },
            {
                name: '课程分类',
                path: '/main/craftsman-app/classify-manage',
                component: result['Classify-manage']
            },
            {
                name: '首页课程管理',
                path: '/main/craftsman-app/course-manage',
                component: result['Course-manage']
            },
            {
                name: '发现首页课程管理',
                path: '/main/craftsman-app/discover-manage',
                component: result['Discover-manage']
            },
        ]
    },

]