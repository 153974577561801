import './index.scss';
import React from 'react';
import { checkUrl } from '@/until/libs';

export default props => {
    const { imgBoxClass, content, src } = props;
    return <div className='not_found_com'>
        <div className={imgBoxClass}>
            <img src={checkUrl(src) ? src : src} alt="" />
        </div>
        <div className='not_found_con'>
            {content}
        </div>
    </div>
};