import { post,get } from "../../until/axios_instans";

// oos图片的
export function getImageOssBaseurl(data) {

  return post("/api/gongyong/api/v1/address/type", data);
}
//视频音频
export function downloadFile(id,name) {
  return post(`/api/gongyong/api/v1/sdk/gongYong/media/downloadFile/${id}/${name}`);
}
export function selectInformation() {
  return get(`/api/v1/information/selectInformation`);
}


