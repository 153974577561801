import React, { useState, useEffect } from 'react';


const MyImage = ({ className, id, type, defaultSrc = '123', imgOption, onClick, suffix = '', staticSrc }) => {
    const { $$imgSrc } = window;
    let [index, setIndex] = useState(0);
    let [imgList, setList] = useState([]);
    let s = suffix[0] === '.' ? suffix : '.' + suffix;
    useEffect(() => {
        let arr = type ? [`${id}_${type}${s}`, id] : [id, `${id}_m${s}`];
        arr.push(defaultSrc)
        arr = arr.map((url) => $$imgSrc(url))
        arr.push(staticSrc)
        setList([...arr]);
        return () => setIndex(0)
    }, [id]);
    const errorFn = e => {
        if (index < imgList.length - 1) {
            index++;
            setIndex(index)
            e.target.src = $$imgSrc(imgList[index]);
        }
    }
    const imgProps = {
        ...imgOption,
        className,
        onClick,
        src: imgList[index],
        onError: errorFn
    }
    return imgList[index] ? <img {...imgProps} /> : null
}

export default MyImage;