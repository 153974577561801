import React, { Component } from 'react';
import './index.scss';
import { Layout } from 'antd';
import RouterView from '@/router/router_view';
import Menu from '@/components/Menu';
import OBreadcrumb from '@/components/Breadcrumb';
import { connect } from 'react-redux';
import routerList from '@/router/router_config.js';
const { Sider, Content } = Layout;

const menuList = [
    {
        menuName: '标签分类',
        id: 'sub1',
        icon: '',
        children: [
            {
                menuName: '分类管理',
                id: '11',
                menuUrl: '/main/training-center/classification'
            },
            {
                menuName: '标签管理',
                id: '12',
                menuUrl: '/main/training-center/relevance'
            }
        ]
    }
]

class index extends Component {
    render() {
        let path = this.props.location.pathname;
        let { menuList } = this.props;
        console.log('menuList培训',menuList)
        let propsMenuList = menuList.find((v) => v.menuName === "培训");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        console.log('propsMenuList',propsMenuList)
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        let two, three;
        propsMenuList && propsMenuList.forEach(v => {
            v.children && v.children.forEach(k => {
                if (k.menuUrl === path) {
                    two = v;
                    three = k;
                }
            })
        })

        let arr = [one, two, three];
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    <Menu {...this.props} openArr={[]} menuList={propsMenuList} />
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    {
                        arr[2]? <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />:''
                    }
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}
index = connect(mapStateToProps)(index);
export default index;
