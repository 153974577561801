import { result } from './result'

const institutional_settlement_router = [
    {
        name: '机构入驻管理',
        path: '/main/institutional-settlement',
        component: result['Institutional-settlement'],
        children: [
            {
                path: '/main/institutional-settlement',
                redirect: '/main/institutional-settlement/platform-user-manage',
            },
            {
                name: '平台用户管理',
                path: '/main/institutional-settlement/platform-user-manage',
                component: result['Platform-user-manage']
            },
            {
                name: '平台用户管理-修改教师',
                path: '/main/institutional-settlement/platform_user_teacher_updata',
                component: result['Teacher_add']
            },
            {
                name: '平台用户管理-修改学生',
                path: '/main/institutional-settlement/platform_user_student_updata',
                component: result['Student_add']
            },
            {
                name: '学校成员管理',
                path: '/main/institutional-settlement/school-member-manage',
                component: result['School-member-manage']
            },
            {
                name: '学校入驻管理',
                path: '/main/institutional-settlement/school-settlement-manage',
                component: result['School_settlement_manage']
            },
            {
                name: '创建/编辑学校',
                path: '/main/institutional-settlement/college_information_entry',
                component: result['College_information_entry']
            },
            {
                name: '学校详情',
                path: '/main/institutional-settlement/school_settlement_detail',
                component: result['School_settlement_detail']
            },
            {
                name: '虚拟机构管理',
                path: '/main/institutional-settlement/virtual_organization_manage',
                component: result['Virtual_organization_manage']
            },
            {
                name: '创建虚拟机构',
                path: '/main/institutional-settlement/create_virtual_organization',
                component: result['Create_virtual_organization']
            },
            {
                name: '修改虚拟机构',
                path: '/main/institutional-settlement/updata_virtual_organization',
                component: result['Create_virtual_organization']
            },
            {
                name: '虚拟机构详情',
                path: '/main/institutional-settlement/virtual_organization_detail',
                component: result['Virtual_organization_detail']
            },
            {
                name: '管理机构管理',
                path: '/main/institutional-settlement/manage_mechanism_manage',
                component: result['Manage_mechanism_manage']
            },
            {
                name: '管理机构信息录入',
                path: '/main/institutional-settlement/manage_mechanism_add',
                component: result['Manage_mechanism_add']
            },
            {
                name: '管理机构信息修改',
                path: '/main/institutional-settlement/manage_mechanism_updata',
                component: result['Manage_mechanism_add']
            },
            {
                name: '管理机构信息详情',
                path: '/main/institutional-settlement/manage_mechanism_info',
                component: result['Manage_mechanism_info']
            },
            {
                name: '管理机构成员管理',
                path: '/main/institutional-settlement/organization_member_management',
                component: result['Organization_member_management']
            },
            {
                name: '成员列表',
                path: '/main/institutional-settlement/member_list',
                component: result['Member_list']
            },
            {
                name: '新增成员',
                path: '/main/institutional-settlement/member_list_teacher_add',
                component: result['Teacher_add']
            },
            {
                name: '修改成员',
                path: '/main/institutional-settlement/member_list_teacher_updata',
                component: result['Teacher_add']
            },
            {
                name: '虚拟机构成员管理',
                path: '/main/institutional-settlement/virtual_organization_members',
                component: result['Virtual_organization_members']
            },
            {
                name: '教师管理',
                path: '/main/institutional-settlement/teacher_management',
                component: result['Teacher_management']
            },
            {
                name: '虚拟机构-新增教师',
                path: '/main/institutional-settlement/virtual_teacher_add',
                component: result['Teacher_add']
            },
            {
                name: '虚拟机构-修改教师',
                path: '/main/institutional-settlement/virtual_teacher_updata',
                component: result['Teacher_add']
            },
            {
                name: '学生管理',
                path: '/main/institutional-settlement/student_management',
                component: result['Student_management']
            },
            {
                name: '新增教师',
                path: '/main/institutional-settlement/school_teacher_add',
                component: result['Teacher_add']
            },
            {
                name: '学校成员-编辑教师',
                path: '/main/institutional-settlement/school_teacher_updata',
                component: result['Teacher_add']
            },
            {
                name: '新增教师',
                path: '/main/institutional-settlement/manage_teacher_add',
                component: result['Teacher_add']
            },
            {
                name: '新增教师',
                path: '/main/institutional-settlement/virtual_teacher_add',
                component: result['Teacher_add']
            },
            {
                name: '新增学生',
                path: '/main/institutional-settlement/school_student_add',
                component: result['Student_add']
            },
            {
                name: '学校成员-修改学生',
                path: '/main/institutional-settlement/school_student_updata',
                component: result['Student_add']
            },
            {
                name: '新增学生',
                path: '/main/institutional-settlement/virtual_student_add',
                component: result['Student_add']
            },
            {
                name: '虚拟机构-修改学生',
                path: '/main/institutional-settlement/virtual_student_updata',
                component: result['Student_add']
            },
            {
                name: '学校成员列表教师管理',
                path: '/main/institutional-settlement/member_list_teacher_management',
                component: result['Member_list_teacher_management']
            },
            {
                name: '学校成员列表学生管理',
                path: '/main/institutional-settlement/member_list_student_management',
                component: result['Member_list_student_management']
            },
        ]
    },
]

const institutional_settlement_global_router = [

]


export { institutional_settlement_router, institutional_settlement_global_router }