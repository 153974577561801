// import React, { Component } from 'react';
// import './index.scss';
// import { Layout } from 'antd';
// import RouterView from '@/router/router_view';
// import Menu from '@/components/Menu';
// import OBreadcrumb from '@/components/Breadcrumb';
// import routerList from '@/router/router_config.js';
// const { Sider, Content } = Layout;

// const menuList = [
//     {
//         title: '圈子管理',
//         key: '71',
//         icon: 'icon_circle_manage.svg',
//         path: '/main/circle-manage/manage'
//     },
    
//     {
//         title: '话题审核管理',
//         key: '72',
//         icon: 'icon_topic-audit.svg',
//         path: '/main/circle-manage/topic-audit'
//     },
//     {
//         title: '话题举报管理',
//         key: '73',
//         icon: 'icon_topic-report.svg',
//         path: '/main/circle-manage/topic-report'
//     },
//     {
//         title: '评论举报管理',
//         key: '74',
//         icon: 'icon_comment-report.svg',
//         path: '/main/circle-manage/comment-report'
//     },
//     {
//         title: '人员黑名单',
//         key: '75',
//         icon: 'icon_blacklist.svg',
//         path: '/main/circle-manage/blacklist',
//         //isHade: true
//     },
//     // {
//     //     title: '人员黑名单',
//     //     key: '76',
//     //     path: '/main/circle-manage/blacklist',
//     //     isHade: true
//     // },
    
// ]



// export default class Resource extends React.Component {
//     render() {
//         let path = this.props.location.pathname;
//         let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
//         let two = menuList.find(v => v.path === path);
//         let arr = [{ ...one, title: one.name }, two];
//         return (
//             <Layout className='resource_box'>
//                 <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
//                     {/* <SiderLogo></SiderLogo> */}
//                     {/* <Menu {...this.props}  menuList={menuList.filter(v=>!v.isHade)} /> */}
//                     <Menu {...this.props}  menuList={menuList} />
//                 </Sider>
//                 <Layout>
//                     {/* <OHeader /> */}
//                     <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
//                     <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
//                         <RouterView routers={this.props.routers}></RouterView>
//                         {/* console.log(this.props) */}
//                     </Content>
//                 </Layout>
//             </Layout>
//         )
//     }
// }





import React, { Component } from 'react';
import './index.scss';
import { Layout } from 'antd';
import RouterView from '@/router/router_view';
import Menu from '@/components/Menu';
import OBreadcrumb from '@/components/Breadcrumb';
import routerList from '@/router/router_config.js';
const { Sider, Content } = Layout;

const menuList = [
    // {
    //     title: '圈子管理',
    //     key: '71',
    //     icon: 'icon_circle_manage.svg',
    //     path: '/main/circle-manage/manage'
    // },
    
    // {
    //     title: '话题审核管理',
    //     key: '72',
    //     icon: 'icon_topic-audit.svg',
    //     path: '/main/circle-manage/topic-audit'
    // },
    // {
    //     title: '话题举报管理',
    //     key: '73',
    //     icon: 'icon_topic-report.svg',
    //     path: '/main/circle-manage/topic-report'
    // },
    // {
    //     title: '评论举报管理',
    //     key: '74',
    //     icon: 'icon_comment-report.svg',
    //     path: '/main/circle-manage/comment-report'
    // },
    // {
    //     title: '人员黑名单',
    //     key: '75',
    //     icon: 'icon_blacklist.svg',
    //     path: '/main/circle-manage/blacklist',
    //     //isHade: true
    // },



    {
        menuName: '圈子管理',
        id: 'sub1',
        icon: 'icon_circle_manage.svg',
        menuUrl: '/main/circle-manage/manage'
    },
    {
        menuName: '话题审核管理',
        id: 'sub2',
        icon: 'icon_topic-audit.svg',
        menuUrl: '/main/circle-manage/topic-audit'
    },
    {
        menuName: '话题举报管理',
        id: 'sub3',
        icon: 'icon_topic-report.svg',
        menuUrl: '/main/circle-manage/topic-report'
    },
    {
        menuName: '评论举报管理',
        id: 'sub4',
        icon: 'icon_comment-report.svg',
        menuUrl: '/main/circle-manage/comment-report',

        //isHade: true
    },
    {
        menuName: '人员黑名单',
        id: 'sub5',
        icon: 'icon_blacklist.svg',
        menuUrl: '/main/circle-manage/blacklist',
        //isHade: true
    },

    
]



export default class Resource extends React.Component {
    render() {
        let path = this.props.location.pathname;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        let two = menuList.find(v => v.path === path);
        let arr = [{ ...one, title: one.name }, two];
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    {/* <SiderLogo></SiderLogo> */}
                    {/* <Menu {...this.props}  menuList={menuList.filter(v=>!v.isHade)} /> */}
                    <Menu {...this.props}  menuList={menuList} />
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                        {/* console.log(this.props) */}
                    </Content>
                </Layout>
            </Layout>
        )
    }
}