import React, { Component } from 'react'
import './index.scss';
import { Form, Select, Input, Radio, Row, Col, Upload, Button, DatePicker, Space, Tag , Modal,Divider  } from 'antd';
import { getProvinceSel, getCitySel, deleteLabel, manageOrgDetail,insertManageOrg,updateManageOrg} from '@/api/platform-business/index.js';
// import AddLable from "../../../../components/addLable/index";
import ImgCrop from 'antd-img-crop';
import { LinkedinOutlined, DeleteOutlined} from '@ant-design/icons';
import setName from 'classnames';
import AddLable from "../../../../components/addLable";
import moment from "moment";
import UploadImg from '../../../../components/enterprise_upload_img/index.js';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
};
const { CheckableTag } = Tag;
class index extends Component {
    state = {
        selectedTags: [], // 我的标签选择数据
        tagsData:[],//标签列表
        isTab: false, // 添加机构弹层
        deletTag: false, // 标签是否可以编辑
        imageUrl: '',
        loading: false,
        fangshiSel: [],
        cengciSel: [],
        yuanxiaoSel: [],
        bumenSel: [],
        provinceSel: [],
        citySel: [],
        districtSel: [],
        manageRangeSel: [],
        ruzhuRad: [],
        fanweiRad: [],
        provinceCode: '',
        cityCode: '',
        provinceName: null,
        cityName: null,
        areaName: null,
        oftenFlag: true,
        yxmc: 0,
        yxjc: 0,
        ywmc: 0,
        ywjc: 0,
        xx: 0,
        xxdz: 0,
        lxr: 0,
        lxsj: 0,
        yxjj: 0,
        selectValue:[]
    }
    componentDidMount() {
        this.getProvinceSel();  //省下拉框
    }
    // 我的标签--选择
    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        this.setState({ selectedTags: nextSelectedTags });
    }
    //信息回显
    init = () => {
        let enterpriseId = sessionStorage['enterpriseId'];
        console.log("enterpriseId",enterpriseId)
        if (enterpriseId) {
            manageOrgDetail({ enterpriseId:enterpriseId }).then(res => {
                console.log("res",res)
                if (res) {
                    let { provinceId, cityId } = res;
                    this.setState({
                        tagsData: res.labelList,
                        provinceCode: res.provinceId,
                        cityId: res.cityId,
                        provinceName: res.provinceName,
                        cityName: res.cityName,
                        isOpenDomain: res.isOpenDomain,
                    }, () => {
                        this.getCitySel();
                    })
                    this.setState({
                        yxmc: res.enterpriseName && res.enterpriseName.length,
                        yxjc: res.shorterForm && res.shorterForm.length,
                        ywmc: res.enName && res.enName.length,
                        ywjc: res.enShorterForm && res.enShorterForm.length,
                        xx: res.schoolMotto && res.schoolMotto.length,
                        xxdz: res.detailAddress && res.detailAddress.length,
                        lxr: res.contact && res.contact.length,
                        lxsj: res.mobilePhone && res.mobilePhone.length,
                        yxjj: res.simpleDesc && res.simpleDesc.length
                    })
                    this.props.form.setFieldsValue({
                        enterpriseName:res.enterpriseName,
                        enterpriseCode:res.enterpriseCode,
                        shorterForm:res.shorterForm,
                        enterpriseLogo:res.enterpriseLogo,
                        settledDate: res.settledDate ? moment(res.settledDate, 'YYYY-MM-DD') : null,
                        settledEndDate:res.settledEndDate ? moment(res.settledEndDate, 'YYYY-MM-DD') : null,
                        manageRange:res.manageRange ? res.manageRange.split(',') : undefined,
                        manageOrgType:res.manageOrgType ? res.manageOrgType.split(',') : undefined,
                        enterpriseDomain:res.enterpriseDomain,
                        isOpenDomain:res.isOpenDomain,
                        cityId: res.cityId ? res.cityId : undefined,
                        provinceId: res.provinceId ? res.provinceId : undefined,
                        labelList: res.labelList,
                        simpleDesc:res.simpleDesc,
                    });
                }
            })
        }
    }

    updateTable = () => {
        if (this.state.deletTag === false) {
            this.setState({
                deletTag: true
            })
        } else {
            this.setState({
                deletTag: false
            })
        }

    }
    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res.unshift({provinceCode: '0', name: '全国'})
        res && this.setState({ provinceSel: res.filter(v => v.provinceCode !== '0'), manageRangeSel: res });
    }
    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    addLableList = () => {
        let { tagsData } = this.state;
        this.AddLableForm.props.form.validateFields((err, values) => {
            if (!err) {
                tagsData.push({id: '', labelName: values.shorterForm})
            }
            this.setState({
                isTab:false
            })
    })
   }
    deleteLabel = (tag) => {
        console.log('', )
        let { tagsData } = this.state;
        const nextSelectedTags = tagsData.filter(t => t.labelName !== tag.labelName);
        this.setState({ tagsData: nextSelectedTags });
    }
    //保存
    save = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { provinceName, cityName, oftenFlag,tagsData} = this.state;
                if (values.settledDate.isAfter(values.settledEndDate)) {
                    return this.mesWarning('开始日期不能小于结束日期')
                }
                console.log('tagsDatatagsData', tagsData.map(e => e.labelName))
                let type = sessionStorage['school_type'];
                if (type === 'add') {                               //add代表新增院校
                    if (oftenFlag) {
                        this.setState({ oftenFlag: false });
                        console.log("values",values)
                        insertManageOrg(
                            {
                                ...values,
                                provinceName,
                                cityName,
                                manageRange: values.manageRange.join(","),
                                manageOrgType: values.manageOrgType.join(","),
                                labelList:tagsData.map(e => e.labelName)
                            }
                        ).then(res => {
                            if (res) {
                                this.mesSuccess('保存成功');
                                this.props.history.push('/main/platform-business/management_organization');
                            }
                        })
                        setTimeout(() => this.setState({ oftenFlag: true }), 2500)
                    }
                } else if (type === 'edit') {                           //edit代表编辑院校
                    updateManageOrg({
                        ...values,
                        provinceName,
                        cityName,
                        manageRange: values.manageRange.join(","),
                        manageOrgType: values.manageOrgType.join(","),
                        labelList: tagsData,
                        id: sessionStorage['enterpriseId']
                    }).then(res => {
                        if (res) {
                            this.mesSuccess('保存成功');
                            this.props.history.push('/main/platform-business/management_organization');
                        }
                    })
                }
            }
        })
    }
    //省下拉框change
    changeProvince = (e, item) => {
        let { props: { children } } = item;
        this.props.form.setFieldsValue({ cityId: undefined, areaId: undefined, areaRange: '' });
        this.setState({ provinceCode: e, cityName: '', areaName: '', provinceName: children, citySel: [], districtSel: [] }, () => this.getCitySel());
    }
    //市下拉框change
    changeCity = (e, item) => {
        let { props: { children } } = item;
        this.props.form.setFieldsValue({ areaId: undefined, areaRange: '' });
        this.setState({ cityCode: e, cityName: children, areaName: '', districtSel: [] });
    }
    setLen = val => {
        this.setState(val);
    }
    saveManageRange = val => {
            var _=this
        if (val.includes('0')) {
            console.log('***********',val)

        }else{
            this.props.form.setFieldsValue({
                manageRange: ["0"],
            });

        }
    }
    addItem=(e)=>{
        console.log('e%%%%%',e)
        this.props.form.setFieldsValue({
            manageRange: ["0"],
        });
    }

    handleImgChange= (fileId, mediaType) =>{
        this.props.form.setFieldsValue({"enterpriseLogo":fileId})
        this.setState({
            fileId,
            mediaType
        })
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let { imageUrl, loading, yuanxiaoSel, fangshiSel, cengciSel, isOpenDomain, provinceSel, citySel, isTab, deletTag, manageRangeSel } = this.state;
        const props = {
            //aspect:160/88,
            resize: true, //裁剪是否可以调整大小
            resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
            modalTitle: "上传图片", //弹窗标题
            modalWidth: 600, //弹窗宽度
            rotate: true,
            width: 160,  //裁剪宽度
            height: 88, //裁剪高度
        };
        const addDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/add_icon.png')} alt="" />
            </div>
            <span>上传logo</span>
        </div>
        const lodingDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/loading_icon.png')} alt="" />
            </div>
            <span>请稍后...</span>
        </div>
        let { yxmc, yxjc, ywmc, ywjc, xx, xxdz, lxr, lxsj, yxjj, selectedTags, tagsData } = this.state;
        return (
            <div className='add_school_box'>
                <div className="title">
                    创建管理机构
                </div>
                <Form>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>管理机构名称</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseName', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '管理机构名称不能为空'
                                            }
                                        ]
                                    })(
                                        <Input placeholder='请输入管理机构名称'
                                            onChange={e => this.setLen({ yxmc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxmc === 0 ? 'zero' : '')}>{yxmc}</span>/50</span>} maxLength={50} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>管理机构标识</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseCode', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '管理机构标识不能为空'
                                            }
                                        ]
                                    })(
                                        <Input
                                            placeholder='请输入管理机构标识'
                                            onChange={e => this.setLen({ yxjc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxjc === 0 ? 'zero' : '')}>{yxjc}</span>/15</span>}
                                            maxLength={15} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>管理机构简称</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('shorterForm', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '管理机构简称不能为空'
                                            }
                                        ]
                                    })(
                                        <Input
                                            placeholder='请输入管理机构简称'
                                            onChange={e => this.setLen({ yxjc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxjc === 0 ? 'zero' : '')}>{yxjc}</span>/20</span>}
                                            maxLength={20} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>管理机构logo</span></Col>
                            <Col span={3} style={{ marginRight: '30px' }}>
                                {
                                    getFieldDecorator('enterpriseLogo', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'logo不能为空'
                                            }
                                        ]
                                    })(
                                        <UploadImg
                                            handleImgChange={this.handleImgChange}
                                            html='上传封面'/>
                                    )
                                }
                            </Col>
                            <Col span={5}>
                                <p className='tishi'>支持jpg/png/jpeg格式，</p>
                                <p className='tishi'>建议最佳尺寸160x88px，不超过5M</p>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>管理机构类型</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('manageOrgType', {
                                        initialValue: undefined,
                                    })(
                                        <Select placeholder='请选择管理机构类型' mode="multiple">
                                            <Option value={"1"}>管理(院校)</Option>
                                            <Option value={"6"}>管理(虚拟机构)</Option>
                                            <Option value={"2"}>管理(企业)</Option>
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>所管范围</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('manageRange', {
                                        initialValue: undefined,
                                    })(
                                        <Select placeholder='请选择所管范围' mode="multiple" onChange={e => this.saveManageRange(e)}
                                                dropdownRender={menu => (
                                                  <div>
                                                      {menu}
                                                      <Divider style={{ margin: '4px 0' }} />
                                                      <div
                                                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                                                        onMouseDown={e => e.preventDefault()}
                                                        onClick={this.addItem}
                                                      >
                                                        全国
                                                      </div>
                                                  </div>
                                                )}
                                        >
                                            {
                                                manageRangeSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                            }
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Row>
                        <Col span={2}>
                            <span style={{ position: 'relative', top: '10px' }}><span className='red'></span>所在省市</span>
                        </Col>
                        <Col className='location_box' span={16}>
                            <Form.Item>
                                {
                                    getFieldDecorator('provinceId', {
                                        initialValue: undefined,
                                    })(
                                        <Select
                                            onChange={this.changeProvince}
                                            placeholder='请选择省'>
                                            {
                                                provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                {
                                    getFieldDecorator('cityId', {
                                        initialValue: undefined
                                    })(
                                        <Select
                                            onChange={this.changeCity}
                                            placeholder='请选择市'
                                        >
                                            {
                                                citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>管理机构简介</span></Col>
                            <Col style={{ position: 'relative' }} span={10}>
                                <span className='textarea_len'>
                                    <span className={setName('current_len', yxjj === 0 ? 'zero' : '')}>{yxjj}</span>/200
                                </span>
                                {
                                    getFieldDecorator('simpleDesc', {
                                        initialValue: ''
                                    })(
                                        <TextArea
                                            placeholder='请输入管理机构简介'
                                            onChange={e => this.setLen({ yxjj: e.target.value.length })}
                                            maxLength={200}
                                            style={{ resize: 'none' }} rows={5} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>二级域名</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('isOpenDomain', {
                                        initialValue: ''
                                    })(
                                        <Radio.Group style={{ width: '100%' }} onChange={e => this.setState({ isOpenDomain: e.target.value })}>
                                            <Radio value={0}>不启动</Radio>
                                            <Radio value={1}>启动</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    {isOpenDomain === 1?<Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'/></span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseDomain', {
                                        initialValue: '',
                                    })(
                                      <Input addonBefore="https://" addonAfter=".cveducloud.com"/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>: null}
                    {/*<Form.Item>*/}
                    {/*    <Row>*/}
                    {/*        <Col span={2}><span><span className='red'>*</span>到期日期</span></Col>*/}
                    {/*        <Col span={10}>*/}
                    {/*            {*/}
                    {/*                getFieldDecorator('settledEndDate', {*/}
                    {/*                    initialValue: '',*/}
                    {/*                    rules: [*/}
                    {/*                        {*/}
                    {/*                            required: true,*/}
                    {/*                            message: '请选择到期日期'*/}
                    {/*                        }*/}
                    {/*                    ]*/}

                    {/*                })(*/}
                    {/*                    <DatePicker placeholder='请选择到期日期' style={{width: '100%'}} />*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Form.Item>*/}
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>开通信息</span></Col>
                            <Col span={5}>
                                <Form.Item>
                                    <Row className='is-data_wapper'>
                                        <Col span={5}><span><span className='red'>*</span>开始日期</span></Col>
                                        <Col span={17}>
                                            {
                                                getFieldDecorator('settledDate', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择到期日期'
                                                        }
                                                    ]
                                                })(
                                                  <DatePicker />
                                                )
                                            }
                                        </Col>

                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item>
                                    <Row className='is-data_wapper'>
                                        <Col span={5}><span><span className='red'>*</span>结束日期</span></Col>
                                        <Col span={17}>
                                            {
                                                getFieldDecorator('settledEndDate', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择到期日期'
                                                        }
                                                    ]
                                                })(
                                                  <DatePicker />
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                    {/*<p className='xian'></p>*/}
                    <div className="mini_title title">
                        <span>我的标签</span>
                        <span className='tabButton' onClick={() => this.updateTable()}>编辑</span>
                        <span className='tabButton' onClick={() => this.setState({isTab: true})}>添加标签</span>
                        <span className='tabButton tabTip'>提示：您最多添加4个标签</span>
                    </div>
                    <Form.Item>
                        <Row>
                            <Col span={12} className='jigou_tab_wapper'>
                                    {tagsData.map((tag, index) => (
                                        <Tag color="#3E78ED" className='tag_item'
                                            key={tag.id}

                                        >
                                            {tag.labelName? tag.labelName :tag}
                                            {deletTag === true ? <DeleteOutlined className="tag_icon" onClick={() => this.deleteLabel(tag)}/> : null}
                                        </Tag>
                                    ))}
                            </Col>
                        </Row>
                    </Form.Item>
                    {/*添加标签弹层*/}
                </Form>
                <div className="fix_controll">
                    <Button className='add_btn_j' onClick={this.save} style={{ marginRight: '40px', width: '100px', height: '39px' }}>保存</Button>
                    <Button onClick={() => this.props.history.goBack()} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>

                <div>
                    <Modal
                        title='添加机构标签'
                        visible={isTab}
                        destroyOnClose={true}
                        closable={false}
                        className='configuration_modal'
                        footer={
                            <div className='modal_btn_j'>
                                <Button onClick={() => this.setState({isTab: false})}>取消</Button>
                                <Button onClick={this.addLableList}>确定</Button>
                            </div>
                        }
                    >
                        <AddLable wrappedComponentRef={form => {this.AddLableForm = form;}}/>
                    </Modal>
                </div>
            </div>
        )
    }
}


index = Form.create()(index);
export default index;
