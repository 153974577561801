/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Component } from 'react';
import { Button, Table, Pagination, Checkbox, Input, Radio } from 'antd';
import {
    queryModalMechanismTableData
} from '../../api/components';
import S from 'gongyongicon';
import ProvinceRangeSelect from '../province_range_select';
import SchoolRunningLevelSelect from '../school_running_level_select';
import {fangdaIcon} from '../../icon_type';
import styles from './index.module.scss';
import '../confirm';

const that = new Component();

const MechanismModal = (props) => {
    const { onOk, onCancel, item } = props;
    const [total, setTotal] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [dataSource, setDataSource] = useState([]);//表格数据
    const [provinceCode, setProvinceCode] = useState(undefined);//省市选择
    const [level, setLevel] = useState(undefined);//办学层次
    const [status, setStatus] = useState(0);//状态
    const [name, setName] = useState('');//文件名称
    const [tableSelect, setTableSelect] = useState([]);//多选选中的表格key
    const [selectTableData, setSelectTableData] = useState([]);//已经选择的表格数据和右侧数据
    const [tableLoading, setTableLoading] = useState(false);

    //获取表格数据
    const getData = async (flag) => {
        if(!item.id)return;
        setTableLoading(true);
        const params = { 
            adminOrgId:item.id, pageNum, pageSize,
        };
        if(!flag){
            if (name) {
                params.schoolName = name;
            };
            if (provinceCode) {
                params.ascriptionProvinceCode = provinceCode;
            };
            if (level) {
                params.schoolRunLevel = level;
            };
            if (status) {
                params.adminStatus = Number(status);
            };
        };
        try {
            const res = await queryModalMechanismTableData(params);
            setDataSource(res.data);
            setTotal(res.total);
        } catch (error) { } finally {
            setTableLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [pageNum, pageSize, status]);

    //pageSize变化
    const onShowSizeChange = pageSize => {
        setPageNum(1);
        setPageSize(pageSize);
    };

    //pageNum变化
    const pageChange = (pageNum) => {
        setPageNum(pageNum);
    };

    //批量添加
    const batchAdd = async () => {
        let flag = false;
        selectTableData.forEach(it => {
            tableSelect.forEach(its => {
                if (it.adminOrgId === its.adminOrgId) {
                    flag = true;
                }
            })
        });
        if (flag) {
            that.mesWarning("不能添加已经选择过的机构");
            return;
        };
        const arr = [...selectTableData, ...tableSelect];
        setSelectTableData(arr);
        setTableSelect([]);
    };

    //右侧删除已选择
    const onDelete = (id) => {
        const newList = selectTableData.filter(it => it.adminOrgId !== id);
        setSelectTableData(newList);
    };

    //开始搜索
    const onSubmit = () => {
        getData();
    };
    //全选反选切换
    const checkedAll = () => {
        let { tableSelect, dataSource } = this.state;
        if (tableSelect.length === dataSource.length) {
            this.setState({ setTableSelect: [] });
            return;
        }
        this.setState({
            setTableSelect: dataSource,
        });
    }
    const columns = [
        {
            title: "机构名称",
            key: "enterpriseName",
            dataIndex: "enterpriseName",
            ellipsis: true,
            width: '40%',
        },
        {
            title: "所属省市",
            key: "ascriptionProvinceName",
            dataIndex: "ascriptionProvinceName",
            align: "center",
        },
        {
            title: "办学层次",
            key: "schoolLevelName",
            dataIndex: "schoolLevelName",
            align: "center",
        },
        {
            title: "办学方式",
            key: "schoolRunModeName",
            dataIndex: "schoolRunModeName",
            align: "center",
        },
        {
            title: "操作",
            key: "option",
            dataIndex: "option",
            align: "center",
            render: (_, item) => {
                return (item.admin || selectTableData.some(it => (it.adminOrgId === item.adminOrgId)))
                ? <a style={{ color: '#888' }}>添加</a>
                : <a onClick={() => {
                    setSelectTableData([...selectTableData, item])
                }}>添加</a>
            }
        },
    ];

    const rowSelection = {
        selectedRowKeys: tableSelect.map(it => it.adminOrgId),
        onChange: (_, items) => setTableSelect(items),
        getCheckboxProps : record => ({
            disabled: record.admin === true
        })
    };

    return (
        <div className={styles["mechanism_modal"]}>
            <div className="mechanism_modal_box">
                <div className="box_header">
                    <div className="box_header_left">{item.name}机构管理</div>
                    <div className="box_header_right">已添加机构（{selectTableData.length}所）</div>
                </div>
                <div className="box_content">
                    <div className="box_content_left">
                        <div className="left_search">
                            <div className="left_search_top">
                                <span>所属省市</span>
                                <ProvinceRangeSelect 
                                    showSearch
                                    allowClear
                                    id={item.id}
                                    style={{ width: 230 }}
                                    placeholder="请选择省市"
                                    optionFilterProp="children"
                                    onChange={e=>setProvinceCode(e)}
                                    value={provinceCode}
                                />
                                <span>办学层次</span>
                                <SchoolRunningLevelSelect
                                    showSearch
                                    allowClear
                                    style={{ width: 230 }}
                                    value={level}
                                    placeholder="请选择办学层次"
                                    onChange={e => setLevel(e)}
                                />
                            </div>
                            <div className="left_search_bottom">
                                <span>状态</span>
                                <Radio.Group onChange={e=>setStatus(e.target.value)} value={status}>
                                    <Radio value={0}>全部</Radio>
                                    <Radio value={1}>管理中</Radio>
                                    <Radio value={2}>未管理</Radio>
                                </Radio.Group>
                                <Input
                                    style={{ width: 230 }}
                                    placeholder="请输入管理机构名称查询"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    prefix={fangdaIcon}
                                />
                                <Button
                                    onClick={() => {
                                        setName('');
                                        setLevel(undefined);
                                        setProvinceCode(undefined);
                                        setStatus(0);
                                        getData(true);
                                    }}
                                >重置</Button>
                                <Button
                                    type="primary"
                                    onClick={onSubmit}
                                >查询</Button>
                            </div>
                        </div>
                        <Table
                            rowSelection={rowSelection}
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            rowKey="adminOrgId"
                            loading={tableLoading}
                            scroll={{
                                y: true
                            }}
                            locale={{
                                emptyText: <img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                            }}
                            footer={() => <>
                                <div className="table_footer_left">
                                    <Checkbox
                                        checked={tableSelect.length === dataSource.length && dataSource.length !== 0}
                                        disabled={dataSource.length === 0}
                                        onChange={() => setTableSelect(tableSelect.length === dataSource.length ? [] : dataSource)}
                                        // onChange={checkedAll}
                                    >
                                        全选
                                    </Checkbox>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={() => batchAdd()}
                                    >批量添加</Button>
                                </div>
                                <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                    onShowSizeChange={(c, z) => onShowSizeChange(z)}
                                    onChange={p => pageChange(p)}
                                />
                            </>}
                        />
                    </div>
                    <div className="box_content_right">
                        {
                            Array.isArray(selectTableData) && selectTableData.map(it => {
                                return <div className="right_item" key={it.adminOrgId}>
                                    <span>{it.enterpriseName}</span><S.Yuanclose_icon className="item_text_delete" onClick={() => onDelete(it.adminOrgId)} />
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="box_footer">
                    <Button size="large" onClick={onCancel}>取消</Button>
                    <Button size="large" type="primary" onClick={() => onOk(selectTableData)}>确定</Button>
                </div>
            </div>
        </div>
    )
}
export default MechanismModal;