import React, { Component } from 'react';
import { Select, Button, Input, Table } from 'antd';
import './index.scss';
import { schoolMembersTable } from '../../../../api/platform-school/index'
import {getProvinceSel, getCitySel, globalSel} from '../../../../api/platform-business/index'
import moment from "moment";
const { Option } = Select;

export default class index extends Component {
    state = {
        dataSource : [],
        pageNum: 1,
        pageSize: 10,
        total: '',
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        schoolRunLevel: '',
        schoolType: '',
        schoolRunLevelDicType: 3, // 办学层次
        schoolTypeDicType: 4, // 学校类型
        schoolRunLevelList: [], // 办学层次下拉
        schoolTypeList: [], // 学校类型下拉
        provinceName: '',
        enterpriseName: '',
        settledMode: '',
        enterpriseId: '',

    }


    componentDidMount() {
        this.schoolMembersTable()
        this.getProvinceSel()
        this.getCitySel()
        this.getSchoolRunLevel()
        this.getSchoolType()
    }

    async schoolMembersTable(){
        const param = {
            cityId: this.state.cityId,
            enterpriseName: this.state.enterpriseName,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            provinceId: this.state.provinceCode,
            schoolRunLevel: this.state.schoolRunLevel,
            schoolType: this.state.schoolType,
            settledMode: this.state.settledMode,
        }
        const res = await schoolMembersTable(param)
        console.log('res========>>>>', res)
        this.setState({
            dataSource: res.data,
            total: res.total
        })
    }

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        console.log('省的e======>>>>>>>>>', e)
        this.setState({
            provinceCode: e,
            citySel: [],
            provinceName: item.props.children
            }, () => {
                this.getCitySel()
                this.schoolMembersTable()
            }
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        if(item.props.value === ''){
            this.setState({
                cityId: item.props.value,
                cityName: '',
            }, () => this.schoolMembersTable());
        } else {
            this.setState({
                cityId: e,
                cityName: item.props.children,
            }, () => this.schoolMembersTable());
        }
    }

    // 办学层次下拉
    getSchoolRunLevel = async () => {
        if (this.state.schoolRunLevelDicType) {
            let schoolRunLevelRes = await globalSel({ type: this.state.schoolRunLevelDicType });
            schoolRunLevelRes && this.setState({ schoolRunLevelList: schoolRunLevelRes });
        }
    }

    // 办学层次下拉框change
    changeSchoolRunLevel = (e, item) => {
        this.setState({ schoolRunLevel: e}, () => this.schoolMembersTable());
    }

    // 学校类型下拉
    getSchoolType = async () => {
        if (this.state.schoolTypeDicType) {
            let schoolTypeRes = await globalSel({ type: this.state.schoolTypeDicType });
            console.log('schoolTypeRes', schoolTypeRes)
            schoolTypeRes && this.setState({ schoolTypeList: schoolTypeRes });
        }
    }

    // 学校类型下拉框change
    changeSchoolType = (e, item) => {
        this.setState({
            schoolType: e
        }, () =>
            this.schoolMembersTable()
        );
    }

    //检索
    search = (e) => {
        console.log('eeee检索eeee', e)
        this.setState({ enterpriseName: e }, () => this.schoolMembersTable());
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.schoolMembersTable());
        window.elGoTop && window.elGoTop('body');
    }

    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.schoolMembersTable());
        window.elGoTop && window.elGoTop('body');
    }

    async toTeacher(record){
        let data = {
            enterpriseId: record.id
        }
    this.props.history.push({
        pathname: '/main/platform-business/tea-manage',
        search: JSON.stringify(data)
    })
    }
    async toStudent(record){
        console.log('record ========>>>>', record)
        let data = {
            enterpriseId: record.id
        }
        this.props.history.push({
            pathname: '/main/platform-business/stu-manage',
            search: JSON.stringify(data)
        })
    }
    render() {
        let { dataSource,citySel,provinceSel,schoolRunLevelList,schoolTypeList,pageSize,total} = this.state;
      const  columns=[
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '标识码', dataIndex: 'enterpriseCode',key:'enterpriseCode', },
            {title: '学校名称', dataIndex: 'enterpriseName',key:'enterpriseName',ellipsis: true,},
            {title: '学校类型', dataIndex: 'schoolType',key:'schoolType',},
            {title: '所在地', dataIndex: 'cityName',key:'cityName',},
            {title: '办学层次', dataIndex: 'schoolRunLevel', key:'schoolRunLevel',},
            {title: '加入时间', dataIndex: 'settledDate', key: 'settledDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '到期时间', dataIndex: 'settledEndDate', key: 'settledEndDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '教师人数', dataIndex: 'teacherNum', key:'teacherNum'},
            {title: '学生人数', dataIndex: 'studentNum', key:'studentNum'},
            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:260,
                render: (text, record) => {
                    const {RenderButton} = React;
                    return <div className="opspan">
                        <RenderButton id='OBS-OM-01-04-a_btnTeacher'>
                        <span className="but" onClick={() =>{this.toTeacher(record)}}>教师管理</span>
                        </RenderButton>
                        <RenderButton id='OBS-OM-01-04-a_btnStudent'>
                        <span className="but"  onClick={() =>{this.toStudent(record)}}>学生管理</span>
                        </RenderButton>
                    </div>
                }
            }

        ]
        return (
            <div className='managestu_box'>
                <div className="oneline">
                    <div className="one">
                    <div>
                        <span style={{ marginRight: '20px' }}>学校类型</span>
                        <Select placeholder='全部'
                                onChange={this.changeSchoolType}>
                            <Option value={''}>全部</Option>
                            {
                                schoolTypeList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                            }
                        </Select>
                    </div>
                    <div>
                        <span style={{ marginRight: '20px' }}>办学层次</span>
                        <Select placeholder='全部'
                                onChange={this.changeSchoolRunLevel}>
                            <Option value={''}>全部</Option>
                            {
                                schoolRunLevelList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                            }
                        </Select>
                    </div>
                    {/*<div>*/}
                    {/*    <span style={{ marginRight: '20px' }}>状态</span>*/}
                    {/*    <Select placeholder='全部' >*/}
                    {/*        <Option value={''}>全部</Option>*/}
                    {/*    </Select>*/}
                    {/*</div>*/}
                    </div>
                    <div className="two">
                    <div>
                        <span style={{ marginRight: '20px' }}>所在地</span>
                        <Select placeholder='请选择省'
                                onChange={this.changeProvince}
                        >
                            <Option value={''}>全部</Option>
                            {
                                provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                            }
                        </Select>
                        <Select placeholder='请选择市'
                                onChange={this.changeCity}
                        >
                            <Option value={''}>全部</Option>
                            {
                                citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                            }
                        </Select>
                    </div>
                    </div>
                </div>
                <div className="twoline">
                        <div className="topline">
                            <div className="right_list">
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入学校名称' />
                            </div>
                        </div>
                        <div className="tablelist">
                            <Table rowKey={v => v['enterpriseId']} pagination={
                                {
                                    total,
                                    pageSize:pageSize,
                                    onChange: this.pageChange,
                                    onShowSizeChange: this.onShowSizeChange,
                                    pageSizeOptions: ['5', '10', '20', '40'],
                                    showSizeChanger: true,
                                    // position:'bottomRight'
                                }
                            } columns={columns} dataSource={dataSource} />
                        </div>
                </div>

            </div>
        )
    }
}
