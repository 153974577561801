/*分类管理*/
import React, {Component} from "react";
import {
    Row,
    Input,
    Table,
    AutoComplete,
    Col,
    Select,
    Tree, Modal, Button, Form, Spin
} from "antd";
import "./index.scss";
import {withRouter} from "react-router";
import "../../../../components/global_components/confirm";
import {selectPlatformCategory, updateCategory, addCategory, deleteOneCategory} from '../../../../api/peixun/index'
import RenderButton from "../../../../components/renderButton";

@withRouter
class Classification extends Component {
    state = {
        classifyVisibily: false,//新增分类弹出
        pageNum: 1,
        pageSize: 5,
        categoryNameValue: '',
        total: 0,
        name: '',  // 搜索名称
        editId: '', //编辑选择的分类id
        type: '',  // add新增，edit编辑
        confirmVisibily: false,//提示弹框
        columns: [
            {title: '分类名称', dataIndex: 'categoryName', key: 'categoryName', width: 300, align: 'left'},
            {
                title: '状态 ', dataIndex: 'isDelete', key: 'isDelete', width: 200, align: 'center',
                render: (text, record) => <div>
                    {
                        text === 0 ? <span>启用</span> : <span style={{color: '#E34949'}}>禁用</span>
                    }
                </div>,
            },
            {title: '适用范围', dataIndex: 'fitScope', key: 'fitScope', width: 200, align: 'center'},
            {
                title: '在用机构数', dataIndex: 'useNum', key: 'useNum', align: 'center',
                render: (text, record) => <div>{
                    text ? '--' : '--'
                }</div>,
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'x',
                align: 'center',
                width: 220,
                render: (text, record) => <div className="render-span">
                    <RenderButton id="OBS-TS-02-01-b_btnEditSort">
                        <span onClick={() => this.editCategory(text, record)}>编辑</span></RenderButton><RenderButton
                    id="OBS-TS-02-01-b_btnDelSort"> | <span onClick={() => {
                    this.classifyRemove(record, text.id)
                }}>删除</span></RenderButton></div>,
            },
        ],
        categoryList: [],
        tableLoading: false,
        userInfo: JSON.parse(localStorage.getItem("userInfo"))
    }
    classifyRemove = (record, id) => { // 删除提示
        if (record.platformCategorySecondVoList) {
            this.hint({'title': '温馨提示','content': '你确定要删除此分类吗？','type': 'warning','cancelText': '取消', 'okText': '确定','isCancel': true,'sure': () => this.deleteOneCategory(id) })
            // this.hint({
            //     'title': '温馨提示',
            //     'content': '你确定要删除此分类吗？',
            //     'type': 'warning',
            //     'cancelText': '取消',
            //     'okText': '确定',
            //     'sure': () => this.deleteOneCategory(id),
            //     'isCancel': true
            // })
        } else {
            this.mesWarning('当前分类因存在二级分类不可删除')
        }
    }

    deleteOneCategory = (id) => {
        deleteOneCategory({categoryId: id}).then(e => {
            this.selectPlatformCategory()
            this.mesSuccess("删除成功")
        })
    }

    gotoLook = (oneLevelName, twoLevelName) => {
        let data = {oneLevelName, twoLevelName};
        this.props.history.push({
            pathname: "/main/training-center/classificationLook",
            search: JSON.stringify(data)
        })
    }

    editCategory = (text, item) => {
        this.setState({
            classifyVisibily: true,
            type: 'edit',
            editId: text.id,
            categoryNameValue: item.categoryName
        })
    }

    componentDidMount() {
        this.selectPlatformCategory()
    }

    selectPlatformCategory = async () => {
        const {pageNum, pageSize, name} = this.state
        await selectPlatformCategory({pageNum, pageSize, name}).then(e => {
            this.setState({categoryList: e.data, total: e.total})
        })
    }

    changeCategory = async () => {
        const {type, editId, userInfo} = this.state
        this.props.form.validateFields(async (err, value) => {
            if (!err) {
                if (type === 'add') {
                    let data = {
                        enterpriseId: 0,
                        categoryName: value.categoryName,
                        categoryLevel: 1,
                        parentId: 0,
                        createUserId: userInfo.userId
                    }
                    await addCategory(data).then(e => {
                        this.setState({
                            type: '',
                            classifyVisibily: false,
                            categoryNameValue: ''
                        }, () => {
                            this.selectPlatformCategory()
                            this.mesSuccess('新增成功')
                        })
                    })
                } else {
                    let data = {
                        id: editId,
                        categoryName: value.categoryName
                    }
                    await updateCategory(data).then(e => {
                        this.setState({
                            type: '',
                            classifyVisibily: false,
                            editId: '',
                            categoryNameValue: ''
                        }, () => {
                            this.selectPlatformCategory()
                            this.mesSuccess("编辑成功")
                        })
                    }).catch(a => {
                    })
                }
            }
        })
    }

    //分页切换
    changePage = (cur) => { // 分页
        this.setState({
            pageNum: cur
        }, () => {
            //列表方法
            this.selectPlatformCategory()
        })
    }

    getName = async () => {
        this.setState({
            pageNum:1
        },()=>{
            this.selectPlatformCategory()
        })

    }
    handleSearch=(e)=>{
        this.setState({
            name: e.target.value
        })

    }
    close = () => {
        this.setState({classifyVisibily: false, type: '', categoryNameValue: ''})
    }

    render() {
        const {pageNum, pageSize, total, tableLoading, columns, categoryList, classifyVisibily} = this.state
        const {getFieldDecorator} = this.props.form;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            onChange: this.changePage
        }
        return (
            <div className="classification bg_fff" style={{padding: '0'}}>
                <div className="top">
                    <Col span={12} className="sort_boxs">
                        <RenderButton id="OBS-TS-02-01-b_btnAddSort">
                            <Button className='sure_sort'
                                    onClick={() => this.setState({classifyVisibily: true, type: 'add'})}
                                    type='primary'>新增分类</Button>
                        </RenderButton>

                    </Col>
                    <Col className='top_r btn_box ' span={12}>
                        <div className='search-box'>
                            <Input.Search className="type-input" placeholder='请输入分类名称' onChange={(e)=>{this.handleSearch(e)}} onSearch={this.getName}
                                          value={this.state.name}/>
                        </div>
                    </Col>
                </div>
                {
                    tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                        <Table
                            className='organ-table'
                            columns={columns}
                            pagination={pagination}
                            scroll={{x: 1200}}
                            expandedRowRender={
                                (record) => {
                                    return (
                                        record?.platformCategorySecondVoList?.map((item, index) => {
                                            return (
                                                <ul key={index} className='table-list'>
                                                    <li className="li-name"
                                                        style={{'textAlign': 'left', 'width': '316px'}}>
                                                        <span></span> {item.categoryName}</li>
                                                    {
                                                        item.isDelete === 0 ? <li style={{'width': '200px'}}>启用</li>
                                                            : <li style={{'width': '200px'}}>禁用</li>
                                                    }
                                                    <li style={{'width': '200px'}}>{item.fitScope}</li>
                                                    <li style={{
                                                        width: 'calc(100% - 936px)',
                                                        paddingLeft: '32px'
                                                    }}>{item.useNum}
                                                    </li>
                                                    <li style={{width: '220px', paddingLeft: '34px'}}
                                                        className="render-span">
                                                        <RenderButton id='OBS-TS-02-01-b_btnViewsort'>
                                                            <span onClick={() => {
                                                                this.gotoLook(record.categoryName, item.categoryName)
                                                            }}>
                                                            查看
                                                        </span>
                                                        </RenderButton>
                                                    </li>
                                                </ul>)
                                        })
                                    )
                                }
                            }
                            rowClassName={record => record.platformCategorySecondVoList && record.platformCategorySecondVoList.length === 0 && 'noExpand'}
                            dataSource={categoryList}
                            locale={{
                                emptyText: <>
                                    <div className="Empty-conet">
                                        <div className="img">
                                            <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                            <span>暂无数据</span>
                                        </div>
                                    </div>
                                </>
                            }}
                        />
                    )}
                <Modal
                    title={this.state.type === 'add' ? '新增分类' : this.state.type === 'edit' ? '编辑分类' : ""}
                    wrapClassName='modal_cons modal_con_cl'
                    visible={classifyVisibily}
                    destroyOnClose={true}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.close()}>取消</Button>
                            <Button onClick={() => this.changeCategory()} type='primary'>确定</Button>
                        </div>
                    }>
                    <div className='transfer-c'>
                        <Form>
                            <Form.Item>
                                <Row>
                                    <Col span={4}>
                                        <span><span className='red'>*</span>分类名称</span>
                                    </Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('categoryName', {
                                                initialValue: this.state.categoryNameValue,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入分类名称'
                                                    },
                                                    {
                                                        max: 10,
                                                        message: "分类名称最多10个字"
                                                    },
                                                    {
                                                        pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                                                        message: '禁止输入特殊字符',
                                                    }
                                                ],
                                                getValueFromEvent: event => {
                                                    return event.target.value.replace(/\s+/g, '')
                                                }
                                            })(
                                                <Input placeholder="请输入分类名称" maxLength={10} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`categoryName`) ? this.props.form.getFieldValue(`categoryName`).length : 0}/10</span>}/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

Classification = Form.create()(Classification);
export default Classification;
