import React, { Component } from 'react';
import { Input, Table, Pagination, Button } from 'antd';
import { manageOrgMembersPage } from '../../api/organization_member_management';
import iconType from '../../icon_type';
import styles from './index.module.scss';


class OrganizationMemberManagement extends Component {
    state = {
        dataSource: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        isZhanKai: 'false',
        visible: false,
        searchValue: "",
    };

    componentDidMount() {
        this.manageOrgMembersPage()
    };

    //列表
    manageOrgMembersPage = async () => {
        const { pageNum, pageSize, searchValue } = this.state;
        const params = {
            pageNum: pageNum,
            pageSize: pageSize,
            manageEnterpriseName: searchValue
        };
        try {
            const res = await manageOrgMembersPage(params);
            this.setState({
                dataSource: res.data,
                total: res.total
            });
        } catch (error) {
            console.warn(error);
        }
    };

    //分页
    pageChange = (pageNum, pageSize) => {
        this.setState({ pageNum, pageSize }, () => {
            this.manageOrgMembersPage()
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({ pageNum: 1, pageSize }, () => {
            this.manageOrgMembersPage()
        })
    };

    /*搜索*/
    onSearch = e => {
        this.manageOrgMembersPage();
    };

    columns = [
        {
            title: '序号',
            dataIndex: 'x',
            key: 'x',
            align: 'center',
            render: (a, b, c) => <span>{c + 1}</span>
        },
        {
            title: '虚拟机构名称',
            dataIndex: 'enterpriseName',
            key: 'enterpriseName',
            align: 'center',
            render: (text, item) => text || '--',
        },

        {
            title: '机构识别码',
            dataIndex: 'enterpriseCode',
            key: 'enterpriseCode',
            align: 'center',
            render: (text, item) => text || '--',
        },

        {
            title: '所属范围',
            dataIndex: 'manageRange',
            key: 'manageRange',
            align: 'center',
            render: (text, item) => text || '--',
        },
        {
            title: '成员人数',
            dataIndex: 'memberNum',
            key: 'memberNum',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '20%',
            render: (text, item, index) => {
                return <div>
                    <React.RenderButton id="OBSMM-01-B05-b-btnMemberInformation">
                    <span className="but" onClick={() => this.props.history.push(`/main/institutional-settlement/member_list?id=${item.id}`)}>成员列表</span>
                    </React.RenderButton>
                </div>
            }
        },
    ];

    render() {
        const { dataSource, pageSize, pageNum, total, searchValue } = this.state;
        return (
            <div className={styles["mechanism"]}>
                <div className='Shared_bottom'>
                    <div className='filetypebox'>
                        <Input
                            placeholder="请输入管理机构名称"
                            style={{ width: 230 }}
                            value={searchValue}
                            prefix={iconType.magnifier}
                            onChange={(e) => {
                                this.setState({
                                    searchValue: e.target.value
                                })
                            }}
                        />
                        <React.RenderButton id="OBSMM-01-B05-b-btnSearch">
                        <Button className='resetbtn' onClick={() => this.onSearch()}>查 询</Button>
                        </React.RenderButton>
                        <span style={{ color: "#888", marginRight: 30 }}>
                            共{total || 0}个管理机构
                        </span>
                    </div>
                    <div className='list_of_institutions'>
                        <Table
                            dataSource={dataSource}
                            columns={this.columns}
                            pagination={false}
                            rowKey="id"
                            scroll={{
                                y: true
                            }}
                            locale={{
                                emptyText: <img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                            }}
                            footer={() => {
                                return <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                    onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                    onChange={(p, n) => this.pageChange(p, n)}
                                />
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default OrganizationMemberManagement;