
import update from 'immutability-helper';

export default {
    SET_visibily(state, { data }) {
        state.visibily = data;
    },
    MOVE(state, { data }) {
        let { dragIndex, hoverIndex } = data;
        const dragRow = state.dataSource[dragIndex];
        let res = update(state, {
            dataSource: {
                $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]]
            }
        });
        state.dataSource = res.dataSource;
    },
    SET_DRAB(state, { data }) {
        state.isDrab = data;
    }
}