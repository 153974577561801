import React, { Component } from 'react'
import { Button } from 'antd'
import './index.less'

const LeftIcon = () => {
    return <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00642 4.01977H1.73841L5.61202 0.91489C5.66958 0.876625 5.71858 0.826822 5.75589 0.768635C5.7932 0.710448 5.81802 0.645146 5.82878 0.576865C5.83954 0.508585 5.836 0.438814 5.81838 0.371973C5.80077 0.305132 5.76947 0.242678 5.72645 0.188567C5.68344 0.134456 5.62966 0.0898674 5.56852 0.0576263C5.50738 0.0253852 5.4402 0.00619414 5.37126 0.00126885C5.30231 -0.00365644 5.23309 0.0057914 5.16798 0.0290139C5.10288 0.0522363 5.04331 0.0887272 4.99304 0.136175L0.00127564 4.12959V4.30929C-0.000425212 4.33255 -0.000425212 4.35591 0.00127564 4.37917V4.42909C-0.000425212 4.45236 -0.000425212 4.47571 0.00127564 4.49898V4.67868L4.99304 8.67209C5.04417 8.71339 5.10294 8.74421 5.16598 8.7628C5.22902 8.78139 5.29511 8.78737 5.36047 8.78042C5.42582 8.77347 5.48917 8.75371 5.54689 8.72228C5.60462 8.69084 5.65558 8.64835 5.69688 8.59721C5.73818 8.54608 5.769 8.48732 5.78759 8.42428C5.80617 8.36123 5.81216 8.29515 5.80521 8.22979C5.79826 8.16443 5.7785 8.10108 5.74706 8.04336C5.71563 7.98564 5.67313 7.93467 5.622 7.89338L1.73841 5.01812H9.00642C12.1912 5.01812 14.7869 7.25443 14.7869 10.0099C14.7869 12.7653 12.1912 15.0016 9.00642 15.0016H1.30912C1.17673 15.0016 1.04976 15.0542 0.956147 15.1479C0.862533 15.2415 0.809942 15.3684 0.809942 15.5008C0.809942 15.6332 0.862533 15.7602 0.956147 15.8538C1.04976 15.9474 1.17673 16 1.30912 16H9.00642C12.7902 16 15.7852 13.3144 15.7852 10.0099C15.7852 6.70534 12.7902 4.01977 9.00642 4.01977Z" fill="#333333" />
        </svg>

        <span> &nbsp;
            返回
         </span>
    </>

}




export default class HeaderText extends Component {

    render() {
        const { CenterText, handClick, isnone, RightText, RightClick, isShow } = this.props
        return (
            <div className='HeaderText'>

                {!isnone ? <div className="HeaderTextleft" onClick={handClick}>
                    <LeftIcon />
                </div> : ''}
                <div className="HeaderTextright">
                    {CenterText}
                </div>
                <div className="HeaderTextBtn">
                    {isShow ? <Button type="primary" onClick={RightClick}>{RightText}</Button> : ''}
                </div>
            </div>
        )
    }
}
