import React, { Component } from "react";
import "./index.scss";
import {
  DatePicker,
  Select,
  Button,
  Table,
  Modal,
  Form,
  Menu,
  Dropdown,
  Icon,
  Drawer,
  message,
  Col,
  Row
} from "antd";
import {
  mechanismList,
  mechanismResult,
  anyMechanismOrg,
  anyMechanismView,
  resourceProviderDetail,
  resourceUpdate,
} from "../../../api/shixun2";
import { Zheng } from "../../../components/shixunsvg1.20/index";
import MaxLengthInput from "../../../components/hhinput";
import {organizationingResultStatus,keyToValues}from'../../../until/libs/tools'

const { Option } = Select;
export class index extends Component {
  state = {
    startValue: null,
    endValue: null,
    endOpen: false,
    visible: false, //申请详情
    visibleEcho: false, //申请处理
    visiblexiang: false, //申请详情
    visiblechuxiang: false, //处理详情
    visibleZyxq: false, //抽屉
    visibleDropdown:false,
    pageSize: 10,
    pageNum: 1,
    total:0,
    dataSource: [],
    dataSourceLogin: true,//loging
    option: [],
    optionOrg: [],
    detailData: {},
    resourceProvider: {},
    dataSourcetablechu: [],
    applyId: "",
    Cldetil:{},
    dataSourcetablechuxiang:[]
  };

  // 查看抽屉
  componentDidMount() {
    mechanismResult().then((res) => {
      console.log(res);
      this.setState({
        option: res,
      });
    });
    anyMechanismOrg().then((res) => {
      console.log(res);
      this.setState({
        optionOrg: res,
      });
    });
    this.queryTable();
    // this.getdata()
  }
  // getdata=(l)=>{
  //   return anyMechanismView(l).then(res=>{
  //     if(res){
  //       this.setState(()=>{
  //         return {
  //           dataSourcetablechuxiang:res
  //         }
  //       })
  //     }else{
  //       message.info('数据错误')
  //     }
  //     debugger
  //   })

  // }
  showDrawer = (record) => {
    console.log(record);
    resourceProviderDetail({ resourceId: record.resourceId ,orgId:record.orgId}).then((res) => {
      console.log(res);
      this.setState({
        resourceProvider: res,
      });
    });
    this.setState({
      visibleZyxq: true,
    });
  };

  onClose = () => {
    this.setState({
      visibleZyxq: false,
    });
  };
  //处理详情

  visiblechuxiangModal = async (id) => {
  debugger
    const _detil=await anyMechanismView({
      applyId:id
    })
    this.setState({
      visiblechuxiang: true,
      Cldetil:_detil
      // Installation_anzhuangbao: true
    },()=>{

       anyMechanismView({applyId:_detil.applyId}).then(res=>{
        if(res){
          debugger
          this.setState(()=>{
            return {
              dataSourcetablechuxiang:[res]
            }
          })
        }else{
          message.info('数据错误')
        }
        debugger
      })
  
    });
 
  };
  visiblechuxiangOk = (e) => {
    console.log(e);

    this.setState({
      visiblechuxiang: false,
    });
  };

  visiblechuxiangCancel = (e) => {
    console.log(e);

    this.setState({
      visiblechuxiang: false,
    });
  };
  //申请详情

  visiblexiangModal = () => {
    this.setState({
      visiblexiang: true,
      // Installation_anzhuangbao: true
    });
  };
  visiblexiangOk = (e) => {
    console.log(e);

    this.setState({
      visiblexiang: false,
    });
  };

  visiblexiangCancel = (e) => {
    console.log(e);

    this.setState({
      visiblexiang: false,
    });
  };
  //申请处理

  visibleEchoModal = (record) => {
    this.setState(
      {
        visibleEcho: true,
        applyId: record.applyId,
        // Installation_anzhuangbao: true
      },
      () => {
        anyMechanismView({ applyId: record.applyId }).then((res) => {
          console.log(res);
          this.setState({
            detailData: res,
            dataSourcetablechu: [
              {
                equipmentCode: res.equipmentCode || "",
                mainboard: res.mainboard || "",
                equipmentTypeName: res.equipmentTypeName || "",
                campusName: res.campusName || "",
                placeName: res.placeName || "",
                classroomName: res.classroomName || "",
              },
            ],
          });
        });
      }
    );
  };
  visibleEchoOk = (e) => {
    console.log(e);
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values);
        if (this.state.resultValue) {
          resourceUpdate({
            applyId: this.state.applyId,
            applyStatus: this.state.resultValue,
            applyExplain: values.applyExplain,
          }).then(() => {
            this.props.form.setFieldsValue({
              applyExplain: undefined,
            });
            this.queryTable();
            this.setState({
              visibleEcho: false,
              resultValue: undefined,
              applyId: undefined,
            });
          });
        } else {
          message.error("请选择处理结果");
        }
      }
    });
  };
  visibleEchoCancel = (e) => {
    console.log(e);
    this.props.form.setFieldsValue({
      applyExplain: undefined,
    });
    this.setState({
      visibleEcho: false,
      resultValue: undefined,
      applyId: undefined,
    });
  };

  // 申请详情
  showModal = (record) => {
    this.setState(
      {
        visible: true,
      },
      () => {
        anyMechanismView({ applyId: record.applyId })
          .then((res) => {
            console.log(res);
            this.setState({
              detailData: res,
              dataSourcetable: [
                {mainboardSmbios:res.mainboardSmbios,
                  equipmentCode: res.equipmentCode,
                  mainboard: res.mainboard,
                  equipmentTypeName: res.equipmentTypeName,
                  campusName: res.campusName,
                  placeName: res.placeName,
                  classroomName: res.classroomName,
                },
              ],
            });
          })
          .catch(() => {
            alert(1);
            this.setState({
              detailData: {},
            });
          });
      }
    );
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  // 申请机构
  shen_onChange = (value) => {
    this.setState({
      orgId: value,
    });
    console.log(`selected ${value}`);
  };
  // 处理机构
  chu_onChange = (value) => {
    console.log(`selected ${value}`);
    this.setState({
      applyStatus: value,
    });
  };

  // 时间
  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  resetBtn = () => {
    this.setState(
      {
        startValue: undefined,
        endValue: undefined,
        orgId: undefined,
        applyStatus: undefined,
      },
      () => {
        this.queryTable();
      }
    );
  };
  getTimer(startValue) {
    var date = new Date(startValue);
    console.log(date);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;

    var w = date.getDay();

    var h = date.getHours();
    h = h < 10 ? "0" + h : h;
    var min = date.getMinutes();
    min = min < 10 ? "0" + min : min;
    var s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
  }
  queryTable = () => {
    const { startValue, endValue, orgId, applyStatus, pageSize, pageNum, dataSourceLogin, } =
      this.state;

    mechanismList({
      startTime: startValue ? this.getTimer(startValue) : undefined,
      endValue: endValue ? this.getTimer(endValue) : undefined,
      orgId,
      applyStatus,
      pageSize,
      pageNum,

    }).then((res) => {
      if (res) {
        this.setState({
          dataSource: res.data,
          dataSourceLogin: false,
          total: res.total,
          pageSize: res.pageSize,
          pageNum: res.pageNum,
        });
      }
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  render() {
    const { RenderButton } = React
    const { startValue, endValue, endOpen,Cldetil,dataSourcetablechuxiang } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout_apply = {
      labelCol: {
        span:4
      },
      wrapperCol: {
       span:18
      },
      // klgfjkfdljg
    };
    // 处理详情


    const columnstablechuxiang = [
      {
        title: "设备编号",
        dataIndex: "equipmentCode",
        key: "equipmentCode",
        align: "center",
      },
      {
        title: "设备主板号",
        dataIndex: "mainboard",
        key: "mainboard",
      
        align: "center",
      },
      {
        title: "设备类别",
        dataIndex: "equipmentTypeName",
        key: "equipmentTypeName",
        align: "center",
      },
      {
        title: "所在学校",
        dataIndex: "campusName",
        key: "campusName",
        align: "center",
      },
      {
        title: "所在场地",
        dataIndex: "placeName",
        key: "placeName",
        align: "center",
      },
      {
        title: "所在教室",
        dataIndex: "classroomName",
        key: "classroomName",
        align: "center",

      },
      {
        title: "处理详情",
        dataIndex: "applyStatus",
        key: "applyStatus",
        align: "center",
        // render:(a,b)=>(
        //   <div>
        //     {b.applyStatus==="1"?'等待处理':b.applyStatus==="2"?'处理成功':b.applyStatus==="3"?'处理失败':b.applyStatus==="4"?'处理未响应':''}
        //   </div>
        // )
      },
    ];
    // 申请处理table

    const columnstablechu = [
      {
        title: "设备编号",
        dataIndex: "equipmentCode",
        key: "equipmentCode",
        align: "center",
        width: "15%",
      },
      {
        title: "设备主板号",
        dataIndex: "mainboard",
        key: "mainboard",
        align: "center",
      },
      {
        title: "设备类别",
        dataIndex: "equipmentTypeName",
        key: "equipmentTypeName",
        align: "center",
      },
      {
        title: "所在学校",
        dataIndex: "campusName",
        key: "campusName",
        align: "center",
      },
      {
        title: "所在场地",
        dataIndex: "placeName",

        align: "center",
      },
      {
        title: "所在教室",
        dataIndex: "classroomName",
        key: "classroomName",
        align: "center",
      },
      {
        title: "处理结果",

        align: "center",
        width:120,
        render: (v, b, value) => {
          const {visibleDropdown,resultValue}=this.state;
          return (
            <div className="action vertical_j resultStatus" style={{ cursor: "pointer" }}>
              <Dropdown 
                overlay={
                  <Menu >
                    {
                      organizationingResultStatus.map(item=>(
                        <p 
                          onClick={()=>this.setState({resultValue:item.key,visibleDropdown:false})}
                          key={item.key} 
                          className={`result_status_menu_item ${item.key===resultValue?'result_status_menu_item_select':''}`}
                        >{item.value}</p>
                      ))
                    }
                  </Menu>
                } 
                trigger={['click']}
                visible={visibleDropdown}
                onVisibleChange={(vis)=>this.setState({visibleDropdown:vis})}
              >
                <p className="result_status_link">
                  {keyToValues(resultValue,organizationingResultStatus)||'请选择'} 
                  <Icon type="down" />
                </p>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    // 申请详情table

    const columnstable = [
   
      {
        title: "设备主板号",
        dataIndex: "mainboard",
        key: "mainboard",
        align: "center",
        width:150,
      },
      {
        title: "主板smBIOS",
        dataIndex: "mainboardSmbios",
        key: "mainboardSmbios",
        align: "center",
        width:300,

      },
      {
        title: "设备类别",
        dataIndex: "equipmentTypeName",
        key: "equipmentTypeName",
        align: "center",
        width:120,
      },
      {
        title: "所在学校",
        dataIndex: "campusName",
        key: "campusName",
        align: "center",
        width:150,
      },
      {
        title: "所在场地",
        dataIndex: "placeName",
        key: "placeName",
        align: "center",
        width:150,
      },
      {
        title: "所在教室",
        dataIndex: "classroomName",
        key: "classroomName",
        align: "center",
        width:120,
      },
    ];
    // 首页table
    // const dataSource = [
    //   {
    //     key: "1",
    //     name: "胡彦斌",
    //     age: 32,
    //     address: "西湖区湖底公园1号",
    //   },
    //   {
    //     key: "2",
    //     name: "胡彦祖",
    //     age: 42,
    //     address: "西湖区湖底公园1号",
    //   },
    // ];

    const columns = [
      {
        title: "序号",
        dataIndex: "name",
        key: "name",
        width: 70,

        align: 'center',
        render: (v, iten, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        title: "申请机构",
        dataIndex: "orgName",
        key: "orgName",
        width: 230,
        align: "center",
      },
      {
        title: "申请类型",
        dataIndex: "applyType",
        key: "applyType",
        width: 230,
        align: "center",
      },
      {
        title: "资源名称",
        dataIndex: "resourceName",
        width: 230,
        key: "resourceName",
        render: (text, record) => (
          <div className="underline">
            <RenderButton id="ABB-01-C01-b_btnViewResource">
              <span
                onClick={() => {
                  this.showDrawer(record);
                }}
              >
               {record.resourceName}
            </span>
            </RenderButton>
          </div>
        ),

        align: "center",
      },
    
      {
        title: "申请人",
        dataIndex: "createUserName",
        key: "createUserName",
        width: 230,
        align: "center",
      },
      {
        title: "申请时间",
        dataIndex: "createTime",
        key: "createTime",
        width: 230,
        align: "center",
      },
      {
        title: "设备主板号",
        dataIndex: "mainboard",
        key: "mainboard",
        width: 230,
        align: "center",
      },
      {
        title: "主板smBIOS",
        dataIndex:"mainboardSmbios",
        key: "validTime",
        width: 230,
        align: "center",
      },
      {
        title: "处理状态",
        dataIndex: "applyStatus",
        key: "applyStatus",
        width: 230,
        align: "center",
       
      },
     
      {
        title: "操作",
        width: 230,
        fixed: "right",
        align: "center",

        render: (v, b, value) => {
          return (
            <div className="action vertical_j" style={{ cursor: "pointer" }}>
              <RenderButton id="ABB-01-C01-b_btnApplicationDetails">
                <span
                  onClick={() => {
                    this.showModal(b);
                  }}
                >
                  申请详情
              </span>
              </RenderButton>
              {/* <RenderButton id="ABB-01-C01-b_btnManage">
                {
                  b.applyStatusCode === 2 ? <span  onClick={() => {
                    this.visiblechuxiangModal(b.applyId);
                  }}>处理详情</span> : <span
                    style={{
                      color: b.applyStatusCode === 4 ? '#ccc' : '#3E78ED'
                    }}
                    onClick={() => {
                      if (b.applyStatusCode === 4) {
                        return
                      }
                      this.visibleEchoModal(b);
                    }}
                  >
                    处理
               </span>
                }

              </RenderButton> */}

            </div>
          );
        },
      },
    ];
    const {
      dataSource,
      option,
      optionOrg,
      detailData,
      dataSourcetable,
      resourceProvider,
      dataSourcetablechu,
    } = this.state;
    const paginationProps = {
      showSizeChanger: true, //设置每页显示数据条数
      showQuickJumper: false,
      pageSize: this.state.pageSize,
      total: this.state.total, //数据的总的条数
      current: this.state.pageNum,
      onChange: (page) => {
        this.setState(
          {
            pageNum: page,
          },
          () => {
            this.queryTable();
          }
        );
        console.log(page);
      }, //点击当前页码
      onShowSizeChange: (current, pageSize) => {
        //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
        console.log(pageSize, current);
        this.setState(
          {
            pageSize,
          },
          () => {
            this.queryTable();
          }
        );
        // this.onShowSizeChange(current, pageSize);
      },
    };
    return (
      <div className="Organization_processing">
        <div className="Organization_processing_top">
          <div>
            <span>
              <DatePicker
                disabledDate={this.disabledStartDate}
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                value={startValue}
                placeholder="请选择日期"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
              <span style={{ marginRight: 20, marginLeft: 20 }}>至</span>
              <DatePicker
                disabledDate={this.disabledEndDate}
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                value={endValue}
                placeholder="请结束日期"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </span>
            <span style={{ marginLeft: 20 }}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="申请机构"
                optionFilterProp="children"
                value={this.state.orgId}
                onChange={this.shen_onChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {optionOrg && optionOrg.length > 0
                  ? optionOrg.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>{item.enterpriseName}</Option>
                    );
                  })
                  : null}
              </Select>
            </span>
            <span style={{ marginLeft: 20 }}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="处理状态"
                value={this.state.applyStatus}
                optionFilterProp="children"
                onChange={this.chu_onChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {option && option.length > 0
                  ? option.map((item) => {
                    return <Option key={item.type} value={item.type}>{item.desc}</Option>;
                  })
                  : null}
              </Select>
            </span>
          </div>
          <div>
            <Button style={{ marginRight: 20 }} onClick={this.resetBtn}>
              重置
            </Button>
            <Button type="primary" onClick={this.queryTable} style={{ marginRight: 20 }}>
              查询
            </Button>
                <span>共{this.state.total}条机构申请</span>
          </div>
        </div>
        <div className="Organization_processing_bottom">
          <Table
            rowKey='applyId'
            dataSource={dataSource}
            columns={columns}
            pagination={paginationProps}
            loading={this.state.dataSourceLogin}
            scroll={{ y: "calc(100vh - 360px)", x: "calc(100% - 700px)" }}
          />
        </div>

        <Modal
          title={
            <div style={{ fontWeight: 600, color: "#333", fontSize: 18 }}>
              申请详情
            </div>
          }
          visible={this.state.visible}
          onCancel={this.handleCancel}
          bodyStyle={{ height: 380 }}
          width={800}
          centered
          closable={false}
          wrapClassName="details_delete_ting"
          footer={[
            <Button
              key="submit"
              type="primary"
              style={{ width: 90, height: 40 }}
              onClick={this.handleOk}
            >
              确认
            </Button>,
          ]}
        >
          <div className="details_delete_ting_info">
            <Row>
              <Col span={8}>
                <Col span={10}>申请机构:</Col>
                <Col span={14}>{detailData?.orgName }</Col>
              </Col>
              <Col span={8}>
                <Col span={10}>申请时间:</Col>
                <Col span={14}>{detailData?.createTime }</Col>
              </Col>
              <Col span={8}>
                <Col span={10}>申请类型:</Col>
                <Col span={14}>{detailData?.applyType }</Col>
              </Col>
              <Col span={8}>
                <Col span={10}>申请人:</Col>
                <Col span={14}>{detailData?.createUserName }</Col>
              </Col>
              <Col span={8}>
                <Col span={10}>资源名称:</Col>
                <Col span={14}>{detailData?.resourceName }</Col>
              </Col>
            </Row>
          </div>
          <div className="details_delete_ting_title">
              <Zheng />
            <span>申请操作设备</span>
          </div>
          <Table
            dataSource={dataSourcetable}
            columns={columnstable}
            pagination={false}
            scroll={{ x: 800 }}
            locale={{
              emptyText:<img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
            }}
          />
        </Modal>
        <Modal
          title={
            <div style={{ fontWeight: 600, color: "#333", fontSize: 18 }}>
              申请处理
            </div>
          }
          visible={this.state.visibleEcho}
          onOk={this.visibleEchoOk}
          onCancel={this.visibleEchoCancel}
          bodyStyle={{ height: 400 }}
          width={800}
          centered
          closable={false}
          wrapClassName="details_delete_origin"
        >
          <Row>
            <Form >
              <Col span={20}>
                <Form.Item label="处理说明" {...formItemLayout_apply}>
                  {getFieldDecorator("applyExplain", {
                    rules: [{ required: true, message: "请输入处理说明" }],
                  })(
                    <MaxLengthInput
                      placeholder="请输入处理说明"
                      type={"textarea"}
                      isTextarea={true}
                      style={{
                        height: 100,
                        paddingRight: 50,
                        resize: "none",
                      }}
                      maxLength={100}
                    ></MaxLengthInput>
                  )}
                </Form.Item>
              </Col>
            </Form>
          </Row>
          <div>
            <div style={{ padding: 20,marginLeft:14  }}>
              <span>
                <Zheng />
              </span>
              <span style={{ marginLeft: 10 }}>申请操作设备</span>
            </div>
            <div>
              <Table
                dataSource={dataSourcetablechu}
                columns={columnstablechu}
                pagination={false}
              />
            </div>
          </div>
        </Modal>
        {/* 处理详情 */}
        <Modal
          title={
            <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
              处理详情
            </div>
          }
          visible={this.state.visiblechuxiang}
          onOk={this.visiblechuxiangOk}
          onCancel={this.visiblechuxiangCancel}
          bodyStyle={{ height: 380 }}
          width={800}
          centered
          closable={false}
          wrapClassName="details_deletechu"
        >
          <div>
            <div>
              <div>
                <span>处理人:</span>
        <div>{Cldetil?.applyUserName}</div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <div>
                <span>处理说明:</span>
                <div>
                {Cldetil?.applyExplain}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{marginBottom:20}}>
              <span>
                <Zheng />
              </span>
              <span style={{ marginLeft: 10,fontSize:18,color:'#333' }}>申请操作设备</span>
            </div>
            <div>
              {" "}
              <Table
                dataSource={dataSourcetablechuxiang}
                columns={columnstablechuxiang}
                pagination={false}
              />
            </div>
          </div>
        </Modal>
        {/* 抽屉 */}
        <div>
          <Drawer
            title="安装包详情"
            placement="right"
            closable={false}
            // onClose={this.onClose}

            visible={this.state.visibleZyxq}
            width={600}
            className="information_tion_ing"
          >
            <p>
              <span>资源名称:</span>
              <span>
                {resourceProvider?.resourceName}
              </span>
            </p>

            <p>
              <span>资源类型:</span>
              <span>
                {resourceProvider.resourceTypeDesc
                  ? resourceProvider.resourceTypeDesc
                  : ""}
              </span>
            </p>

            <p>
              <span>提供商:</span>
              <span>
                {resourceProvider.supplierName
                  ? resourceProvider.supplierName
                  : ""}
              </span>
            </p>

            <p>
              <span>文件类型:</span>
              <span>
                {resourceProvider
                ?.resourceLoadOriginType }
              </span>
            </p>
            <p>
              <span>版本号:</span>
              <span>
                {resourceProvider.resourceVersion
                  ? resourceProvider.resourceVersion
                  : ""}
              </span>
            </p>
            <p>
              <span>适配硬件:</span>
              <span>
                {resourceProvider?.resourceAdaptiveHardwareDesc}
              </span>
            </p>
            <p>
              <span>资源大小:</span>
              <span>
                {resourceProvider
                  ?.resourceSizeDesc}
              </span>
            </p>

            <p style={{ display: "flex" }}>
              <span>适用场景:</span>
              <span className="parameters">
                <span>
                  {resourceProvider.resourceRange
                    ? resourceProvider.resourceRange
                    : ""}
                </span>
              </span>
            </p>

            <p style={{ display: "flex" }}>
              <span>备注说明:</span>
              <span className="parameters">
                <span>
                  {resourceProvider.resourceExplain
                    ? resourceProvider.resourceExplain
                    : ""}
                </span>
              </span>
            </p>
            <p>
              <span>授权状态:</span>
              <span>
                {resourceProvider?.authorizationStatus}
              </span>
            </p>

            <p>
              <span>授权节点:</span>
              <span>
                {resourceProvider?.authorizationNum}
              </span>
            </p>

            <div
              style={{
                position: "absolute",

                bottom: 0,

                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "right",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
              }}
            >
              <Button
                onClick={this.onClose}
                type="primary"
                style={{
                  width: 90,
                  height: 40,
                  position: "relative",
                  right: 400,
                }}
              >
                我知道了
              </Button>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

export default Form.create()(index);
