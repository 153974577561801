import { SET_visibily, MOVE,SET_DRAB } from './action_type';

export const set_visibily = data => {
    return {
        type: SET_visibily,
        data
    }
}

export const moveFn = data => {
    return {
        type: MOVE,
        data
    }
}

export const set_drab = data => {
    return {
        type: SET_DRAB,
        data
    }
}