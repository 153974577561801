import { post } from '@/until/axios_instans'

// 查询平台用户分页列表
export const getPlatformUserPage = (data) => {
    return post("/api/ucenter/api/v1/adminUser/getPlatformUserPage", data);
};

// 查询平台用户分页列表
export const manageOrgMembersPage = (data) => {
    return post("/api/ucenter/api/v1/adminUser/manageOrgMembersPage", data);
}

//删除学生信息
export const deleteStudent = (data) => {
    return post("/api/ucenter/api/v1/adminUser/deleteStudent", data);
}


//分页查询学校成员管理列表接口
export const schoolMembersPage = (data) => {
    return post("/api/ucenter/api/v1/adminUser/schoolMembersPage", data);
}


//分页查询虚拟机构成员管理列表接口
export const virtualOrgMembersPage = (data) => {
    return post("/api/ucenter/api/v1/adminUser/virtualOrgMembersPage", data);
}

//根据userId重置密码接口
export const resetUserPassword = (data) => {
    return post("/api/ucenter/api/v1/adminUser/resetUserPassword", data);
}


//设置身份
export const setIdentity = (data) => {
    return post("/api/ucenter/api/v1/adminUser/setIdentity", data);
}

//根据userId删除教师接口
export const delTeacher = (data) => {
    return post("/api/ucenter/api/v1/adminUser/delTeacher", data);
}


//机构类型查询
export const selectEnterpriseType = (data) => {
    return post("/api/ucenter/api/v1/adminUser/selectEnterpriseType", data);
}

//用户身份查询
export const selectIdentityType = (data) => {
    return post("/api/ucenter/api/v1/adminUser/selectIdentityType", data);
}





