import React from 'react';
import {Button, Form, Input, Modal, message, Select, Table, Checkbox, Empty,Tooltip} from "antd";
import '../index.scss';
import {getUserInfo, saveDownloadPage} from '@/api/practTrain/index.js'

const {Option} = Select;
const {Search} = Input;

class UserSetModel extends React.Component {
    state = {
        timeList: [],
        addressVisible: false,
        UserInfoList: [],//发送用户列表
        telNum: '',  //手机号(精确查找)
        selectedRows: [],
        selectedRowKeys: [],
        timeValue: "", //有效时间
        getAddress: {},  //下载页地址和密码
        softwareId: '',//安装软件id
        isSendNode: '', //是否发送短信 0否 1是
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.userVisible) {
            this.setState({
                timeValue: nextProps.timeValue,
                userVisible: nextProps.userVisible,
                getAddress: nextProps.getAddress,
                softwareId: nextProps.softwareId,
            })
        }
    }

    checkCancel = () => {
        this.props.checkCancel()
        this.setState({
            telNum: '',
            UserInfoList: [],

        })
    }
    //检索查询
    onSearchChange = () => {
        const {telNum} = this.state;
        this.setState({
            telNum: telNum
        }, () => {
            this.UserInfoList()
        });
    }

    //搜索条件修改
    onKeyNameChange = ({target: {value}}) => {
        this.setState({
            telNum: value.replace(/^\s/, "")
        });
    };

    //获取发送用户列表
    async UserInfoList() {
        const UserInfoList = await getUserInfo({
            telNum: this.state.telNum
        })
        if (UserInfoList && UserInfoList.result !== null) {
            let UserInfo = [UserInfoList]
            this.setState({
                UserInfoList: UserInfo,
            })
        } else {
            this.mesWarning('没有找到您要查找的用户')
            this.setState({
                UserInfoList: []
            })
        }
    }

    //确定发送
    confirmSend = (record) => {
        let successBack = () => {
            this.props.form.resetFields()//清空表单
            this.setState({
                isSendNode: '',
                selectedRows: [],
                selectedRowKeys: [],
                saveLoading: false,
            }, () => {
                this.checkCancel()
            });
        }
        if (this.state.selectedRowKeys.length > 0) {
            const {getAddress, timeValue, softwareId, UserInfoList, isSendNode} = this.state
            let params =
                {
                    "downloadPageUrl": getAddress.address,
                    "password": getAddress.password,
                    "softwareId": softwareId,
                    "dayNum": timeValue,
                    "userId": UserInfoList[0].userId,
                    "userName": UserInfoList[0].userName,
                    "enterpriseName": UserInfoList[0].enterpriseName,
                    "factoryName": UserInfoList[0].factoryName,
                    "telNum": UserInfoList[0].telNum,
                    "positions": UserInfoList[0].positions,
                    "isSendNode": isSendNode ? 1 : 0,
                }
            saveDownloadPage(params).then(res => {
                if (res) {
                    this.mesSuccess('发送成功');
                    successBack();
                }
            })
        } else {
            this.mesWarning("没有选择可以发送的用户，请先添加用户")
            return
        }

    }
    //是否发送短信
    SendNodeChange = (e) => {
        let isSendNode = e.target.checked
        this.setState({
            isSendNode: isSendNode
        })
    }

    render() {
        const {saveLoading, telNum} = this.state;
        const {userVisible} = this.props
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.state.selectedRowKeys = selectedRowKeys
                this.state.selectedRows = selectedRows
            },
            getCheckboxProps: (record) => ({
                disabled: record.isDiscoverCourse === 1, // Column configuration not to be checked
                name: record.courseName,
            }),
        };
        const Columns = [

            {title: 'ID', dataIndex: 'jobNum', key: 'jobNum', align: 'center',ellipsis: true,},
            {title: '姓名', dataIndex: 'userName', key: 'userName', align: 'center',ellipsis: true,},
            {title: '手机号', dataIndex: 'telNum', key: 'telNum', align: 'center',ellipsis: true,},
            {title: '所属机构', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'center',ellipsis: true,},
            {title: '院系/部门', dataIndex: 'factoryName', key: 'factoryName', align: 'center',ellipsis: true,},
            {title: '职务', dataIndex: 'positions', key: 'positions', align: 'center',ellipsis: true,},

        ];
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const {$$img, $$uploadRc} = window
        return (
            <div>
                <Modal
                    width='800px'
                    title="安装包使用者设置"
                    wrapClassName='practTrain_modal_box'
                    visible={userVisible}
                    destroyOnClose={true}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.checkCancel()}>取 消</Button>
                            <Button loading={saveLoading} onClick={() => this.confirmSend()}
                                    type='primary'>确定发送</Button>
                            <div className="hint-tit">
                                <Checkbox onChange={this.SendNodeChange}></Checkbox>
                                <p>
                                    <span className="dot"></span>站内信通知的同时，向使用者手机发送手机短信通知提取地址和密码</p>
                            </div>

                        </div>
                    }
                >
                    <div className='phone-box'>
                        <span className="phone-person">接收人的手机号码</span>
                        <Input style={{width: "330px", marginLeft: "20px"}}
                               prefix={<img src={$$img('cvec-space-front-guanli/zhihuishixun/search.svg')} alt="" style={{cursor:'pointer'}} onClick={(e)=>this.onSearchChange(e)}/>}
                               placeholder='手机号码'
                               value={telNum} onChange={this.onKeyNameChange.bind(this)}
                               onPressEnter={(e)=>this.onSearchChange(e)}
                        />
                        {/*<Search className='formsearch'*/}
                        {/*        placeholder="手机号码"*/}
                        {/*        allowClear*/}
                        {/*        value={telNum}*/}
                        {/*        onChange={this.onKeyNameChange.bind(this)}*/}
                        {/*        onSearch={(e) => this.onSearchChange(e)}*/}
                        {/*        style={{width: "330px", marginLeft: '20px'}}*/}
                        {/*     // prefix={<Icon type="search" style={{color: "#BEBEBE"}}/>}*/}
                        {/*/>*/}
                    </div>
                    <Table
                        className="minheight"
                        rowKey={(v) => v.userId}
                        rowSelection={{
                            ...rowSelection,
                        }}
                        columns={Columns}
                        dataSource={this.state.UserInfoList}
                        pagination={false}
                        locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无用户"/>}}
                    />
                </Modal>
            </div>

        )
    }
}

UserSetModel = Form.create()(UserSetModel)
export default UserSetModel;

