import React from 'react';


//培训-首页
export default props => {
    return<svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.04803 6.70994H1.48167C1.10992 6.70994 0.808594 6.40862 0.808594 6.03686V1.47044C0.808594 1.09869 1.10992 0.797363 1.48167 0.797363H6.04803C6.41979 0.797363 6.72111 1.09869 6.72111 1.47044V6.03686C6.72111 6.40862 6.41979 6.70994 6.04803 6.70994ZM2.15475 5.36378H5.37496V2.14352H2.15475V5.36378ZM6.04803 13.2195H1.48167C1.10992 13.2195 0.808594 12.9181 0.808594 12.5464V7.97995C0.808594 7.60825 1.10992 7.30688 1.48167 7.30688H6.04803C6.41979 7.30688 6.72111 7.60825 6.72111 7.97995V12.5464C6.72111 12.9181 6.41979 13.2195 6.04803 13.2195ZM2.15475 11.8733H5.37496V8.65303H2.15475V11.8733Z" fill="#6A7E9C"/>
        <path d="M12.6536 6.70994H8.08714C7.71544 6.70994 7.41406 6.40861 7.41406 6.03686V1.47044C7.41406 1.09869 7.71544 0.797363 8.08714 0.797363H12.6536C13.0253 0.797363 13.3266 1.09869 13.3266 1.47044V6.03686C13.3266 6.40861 13.0253 6.70994 12.6536 6.70994ZM8.76022 5.36378H11.9805V2.14352H8.76022V5.36378Z" fill="#BABABA"/>
        <path d="M12.6536 13.2192H8.08714C7.71544 13.2192 7.41406 12.9178 7.41406 12.5461V7.97972C7.41406 7.60802 7.71544 7.30664 8.08714 7.30664H12.6536C13.0253 7.30664 13.3266 7.60802 13.3266 7.97972V12.5461C13.3266 12.9178 13.0253 13.2192 12.6536 13.2192ZM8.76022 11.8731H11.9805V8.65279H8.76022V11.8731Z" fill="#6A7E9C"/>
    </svg>

}