
//前台加密主要是为了防止post请求明文传递密码


//使用例子
// var CryptoJS = require("crypto-js");

// var data = [{id: 1}, {id: 2}]

// // Encrypt
// var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

// // Decrypt
// var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
// var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


export const SECRETKEY = 'front_666666';
//应用类型改资源类型 29个删除改成5个
//教学资源  实训资源  文献资料  行业资源  其他
export const resourceFormatList = [
    {
        id: 1, text: '教学资源'
    }, {
        id: 2, text: '实训资源'
    }, {
        id: 3, text: '文献资料'
    }, {
        id: 4, text: '行业资源'
    }, {
        id: 99, text: '其它'
    }
]

export const mediumTypeList = [
    {
        id: 1, text: '文本'
    }, {
        id: 5, text: '视频'
    }, {
        id: 4, text: '音频'
    }, {
        id: 8, text: 'PPT'
    }, {
        id: 3, text: '图片'
    }, {
        id: 6, text: '动画'
    }, {
        id: 9, text: '网页链接'
    }, {
        id: 2, text: '微课'
    }, {
        id: 7, text: '虚拟仿真'
    }, {
        id: 99, text: '其它'
    }
]
const REACT_APP_BUILD_URL = process.env.REACT_APP_BUILD_URL;
const urlObj = {};

if (REACT_APP_BUILD_URL && REACT_APP_BUILD_URL.includes('pro')) {
    urlObj.baseUrl = "https://gw.cveducloud.com";
    urlObj.previewUrl = localStorage.getItem("innerWebsite");
    urlObj.resource_preview = localStorage.getItem("innerWebsite")+'public_resource';
    urlObj.liveUrl  = '';
} else if (REACT_APP_BUILD_URL && REACT_APP_BUILD_URL.includes('pre')) {
    urlObj.liveUrl  = 'http://localhost:3000/#/livepageroom';
    urlObj.baseUrl = "https://gw.cveducloud.com";
    urlObj.previewUrl = localStorage.getItem("innerWebsite");
    urlObj.resource_preview = localStorage.getItem("innerWebsite")+'public_resource';
}else{
    urlObj.liveUrl  = 'http://localhost:3000/#/livepageroom';
    urlObj.previewUrl = localStorage.getItem("innerWebsite");
    urlObj.resource_preview = 'https://fj.cveducloud.com/public_resource/cvecdev';
    urlObj.baseUrl = "https://gw.cveducloud.com";
}

export const previewUrl = urlObj.previewUrl;
export const resource_preview = urlObj.resource_preview;
export const baseUrl = urlObj.baseUrl
export const liveUrl = urlObj.liveUrl;

export const commonalityUrl = 'https://office.cveducloud.com/?ssl=1&';
export const turningSupportedArr = ['txt', 'doc', 'rtf', 'docx', 'xls', 'xlsx'];     //此文件类型用iframe加载需要更换白色背景
export const supportPaging = ['ppt', 'pdf', 'pptx', 'xls', 'xlsx'];         //此文件类型支持分页切换

// oss地址
export const OSS_URL = "https://fj.cveducloud.com/public_resource/static_src/"
//分辨率
export const RESOLVING_POWER= "?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_160,h_219,m_fast"

export const practTrainOssImgUrl = 'cvec-manage-front-yunying/practTrain';
