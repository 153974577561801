import React, {Component} from 'react';
import './index.scss';
import {Col, Button, Form, Modal, Input, message} from 'antd';
import Zmage from 'react-zmage'
import {ExclamationCircleFilled, SyncOutlined, ReloadOutlined} from '@ant-design/icons';
import {getReportOne, getReportersBytype, getReportNumbers, getTipOffType,freeze,setProcess,getRecord} from '@/api/report/report'
import {browsingResource} from '@/api/transResource'
import {resourceShelfOperate,} from '@/api/grounding';
import {connect} from "react-redux";
import Check from "../check";
import setName from "classnames";
import OffShelf from "../../components/OffShelfModal";
import FilePreview from "@/components/FilePreview"; //资源查看

const {TextArea} = Input
const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


class ReportDetail extends React.Component {
    state = {
        lastPage: [true,true,true,true,true,true,true],
        saveLoading: false,
        examineReason: null,
        examineReasonNum: 0,
        reportersBytype: [],
        resourceDetails: {},
        reporNumbers:  {"totalNumbers":0,"emphasisNumbers":0},
        oldReporNumbers: {"totalNumbers":0,"emphasisNumbers":0},
        newReporNumbers:{"totalNumbers":0,"emphasisNumbers":0},
        resourceId: "",
        processState:0,
        sourceState:0,
        ids: [],
        lookVisible:false,
        shelfStatus:'1',
        type: 1,
        pageSize: "3",
        pageNumber: ["1","1","1","1","1","1","1"],
        reporDetails: {},
        fixed: true,
        clientHeight: '',
        fileId: '',
        seconds: 10,
        pageLength: 0,
        visible: false,
        reportTypeData: [],
        total:'',
        lastProcessType:"0",
        frozenReasonInfo:{},
        contheight:0
    }
    goBack = () => {
        this.props.history.goBack()
    }


    componentDidMount() {
        let ctx = this;
        setInterval(() => {
            let {seconds} = ctx.state;
            seconds = seconds - 1
            if (seconds === 0) {
                seconds = 10
                ctx.getReportNumbers(ctx.state.resourceId).then(()=>{
                    let oldNumber=this.state.oldReporNumbers
                    let reporNumbers=this.state.reporNumbers
                    let newReporNumbers=this.state.newReporNumbers
                    newReporNumbers.emphasisNumbers= parseInt(reporNumbers.emphasisNumbers) -parseInt(oldNumber.emphasisNumbers)
                    newReporNumbers.totalNumbers= parseInt(reporNumbers.totalNumbers) -parseInt(oldNumber.totalNumbers)
                    this.setState({
                        newReporNumbers:newReporNumbers
                    })
                })
            }
            this.setState({
                seconds
            });
        }, 1000)
        const resourceId = this.props.match.params.resourceId
        const lastProcessType = this.props.match.params.lastProcessType
        const processState=this.props.match.params.processState
        const sourceState=this.props.match.params.sourceState
        this.setState({
            resourceId: resourceId,
            lastProcessType:lastProcessType,
            processState:processState,
            sourceState:sourceState
        }, () => {
            // ctx.getResourceDetails()
            ctx.getReportOne()
            ctx.getRecord();
            ctx.times()
            ctx.getReportNumbers().then(()=>{
                this.setState({oldReporNumbers:this.state.reporNumbers})
            })
        })
        ctx.getTipOffType().then(()=>{
            for(var i=0;i<this.state.reportTypeData.length;i++){
                let tmpageNumber=this.state.pageNumber
                tmpageNumber[i]=1
                this.setState({pageNumber:tmpageNumber},()=>{
                    ctx.getReportersBytype(i)
                })
            }

        })
    }
    async getResourceDetails(resourceId) {
        const resourceDetails = await browsingResource({"id": resourceId})
        this.setState({
            fileId: resourceDetails.transInfo.transId ? resourceDetails.transInfo.transId : resourceDetails.fileId,
            transStatus: resourceDetails.transInfo.transStatus,
            shelfStatus: resourceDetails.shelfStatus
        })
    }

//举报记录列表
    async getReportersBytype(type) {
        const {resourceId, pageSize, pageNumber} = this.state
        const reportersBytype = await getReportersBytype({
            "resourceId": resourceId,
            "type": type,
            "pageSize": pageSize,
            "pageNum": pageNumber[type],

        })
        console.log(reportersBytype, '777')
        var tmpreportersBytype=this.state.reportersBytype;
        tmpreportersBytype[type]=reportersBytype
        this.setState({
            reportersBytype: tmpreportersBytype,
            total: reportersBytype.total
        })
        let tmplastPage=this.state.lastPage
        if (Math.ceil(parseInt(reportersBytype.total)/parseInt(this.state.pageSize))<=pageNumber[type]){
            // console.log("zzzzzz---",parseInt(reportersBytype.total)/parseInt(this.state.pageSize))
            tmplastPage[type]=true
        }else
        {
            // console.log("tttt---",parseInt(reportersBytype.total)/parseInt(this.state.pageSize))
            tmplastPage[type]=false
        }
        this.setState({
            lastPage: tmplastPage
        })

    }
    //举报类型
    async getTipOffType() {
        const reportTypeData = await getTipOffType()
        this.setState({
            reportTypeData
        })
    }

    //无问题处理
    async setNoProblem (){
        const data={
            resourceId:this.state.resourceId,
            lastProcessType:0,
             processState:1
        }
        let res = await setProcess(data)
        if (res && res.code == 0) {
            message.success("该资源已处理")
            this.goBack()
        }
    }

    //下架处理
    async setOffProblem (){
        let ids = []
        ids.push(this.state.resourceId);
        this.setState({
            visible: !this.state.lookVisible,
            ids:ids
        })
    }
   //完成
    lookCancel = () => {
        this.setState({
            lookVisible: false,
            shelfStatus:0,
            ids: []
        })
    };
//取消
    lookClose = () => {
        this.setState({lookVisible: false})
    };

    //冻结窗口
    freeze = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    //冻结资源
    async freezeResource (){
        var idsArr=[this.state.resourceId];
        const data={
            ids:idsArr,
            reason:this.state.examineReason,
            type:"1"
        }
        let res = await freeze(data)
        if (res && res.code == 0) {
            message.success("冻结成功")
        }
    }

    examineReasonChange(e) {
        const value = e.target.value
        this.setState({
            examineReason: value,
            examineReasonNum: value.length
        })
    }
    windowWidth=()=>{
        if (document.getElementById('top_height') == null) {
            return;
        }
        var contheight = document.getElementById('top_height').clientHeight+103
        if (contheight === null) {
            return
        } else {
            this.setState({
                contheight: contheight<0?0:contheight
            },()=>{
                this.onScorll()
            });
        }
    }
    onScorll = () => {//滚动条设置
        if(this.state.sourceState !=0){
            window.scrollTo({
                top:this.state.contheight,
                behavior: "smooth"
            })
        }
    }

    //资源浏览
    async getReportOne(resourceId) {
        const reporDetails = await getReportOne({"id": resourceId})
        this.setState({
            reporDetails: reporDetails
        },()=>
            this.windowWidth()
           // this.onScorll()
        )

    }

    //举报原因
    async getRecord() {
        const frozenReasonInfo = await getRecord({"resourceId": this.state.resourceId})
        this.setState({
            frozenReasonInfo: frozenReasonInfo
        })
    }

    //举报条数
    async getReportNumbers() {
        const reporNumbers = await getReportNumbers({"reportId": this.state.resourceId})
        this.setState({
            reporNumbers: reporNumbers
        })
    }
    //手动刷新举报记录
    clickRefresh =async () => {
        let that=this
        for(var i=0;i<this.state.reportTypeData.length;i++){
            let tmpageNumber=this.state.pageNumber
            tmpageNumber[i]=1
            await this.setState({pageNumber:tmpageNumber},()=>{
                console.log("i2----",i)
                that.getReportersBytype(i)
            })
        }
        let newReporNumbers=this.state.newReporNumbers
        newReporNumbers.emphasisNumbers= 0
        newReporNumbers.totalNumbers= 0
        this.setState({newReporNumbers:newReporNumbers,oldReporNumbers:this.state.reporNumbers})
    }
    //下一批
    nextPage = (type,total) => {
        console.log(type,total)
        let tmpageNumber=this.state.pageNumber
        if (Math.ceil(parseInt(total)/parseInt(this.state.pageSize))<=tmpageNumber[type]){
            message.warning("已经是最后一页了")
            return
        }
        tmpageNumber[type]=tmpageNumber[type]+1
        this.setState({pageNumber:tmpageNumber},()=>{
            this.getReportersBytype(type)
        })
    }
    //取消
    checkCancel = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    //保存
    selectType() {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({
                    saveLoading: true
                })
                this.freezeResource().then(()=>{
                    this.props.form.resetFields();
                    this.setState({
                        saveLoading: false,
                        visible: !this.state.visible,
                        processState:1
                    },()=>{
                        this.goBack()
                    })
                })
            }
        })
    }

    render() {
        const {reporDetails, fileId, reportersBytype, seconds, lastPage,visible, saveLoading, examineReasonNum, examineReason, reportTypeData,newReporNumbers,lastProcessType,frozenReasonInfo,processState,ids,shelfStatus} = this.state
        const formItemLayout = {
            labelCol: {span: 0},
            wrapperCol: {span: 24},
        };

       const timesFun= (timesData)=> {
            //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
            var dateBegin = new Date(timesData.replace(/-/g, "/"));//将-转化为/，使用new Date
            var dateEnd = new Date();//获取当前时间
            var dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数
            var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
            var leave1 = dateDiff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
            var hours = Math.floor(leave1 / (3600 * 1000))//计算出小时数
            //计算相差分钟数
            var leave2 = leave1 % (3600 * 1000)    //计算小时数后剩余的毫秒数
            var minutes = Math.floor(leave2 / (60 * 1000))//计算相差分钟数
            //计算相差秒数
            var leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
            var seconds = Math.round(leave3 / 1000);
            var timesString = '';

            if (dayDiff != 0) {
                timesString = timesData;
            } else if (dayDiff == 0 && hours != 0) {
                timesString = hours + '小时之前';
            } else if (dayDiff == 0 && hours == 0) {
                timesString = minutes + '分钟之前';
            }

            return  timesString
        }

        const {getFieldDecorator} = this.props.form;
        return (
            <div className='trans_box'>
                <div>
                    <div className="header bg_fff">
                        <div className="back" onClick={() => this.goBack()}>
                            <img alt="" src={require("@/assets/img/grounding/back.png")} className="back-icon"/>
                            返回
                        </div>
                        <div className='title'>
                            <h2>{reporDetails.resourceName}</h2>
                        </div>
                    </div>
                    <div className="show_box" id='top_height'>
                        <div className="left">
                            <div className="watch">
                                {/* resourceType   文件类型
                                resourceContent     txt文件值
                                fileId             文件ID
                                pageSize           pdf文档默认显示几页 不传 默认为 2
                                startTime          视频默认第几秒开始  不传 默认为 0 */}
                                {/* txet文档ID  733566D96F8D128256C6B7D1535919717FFE3ADDBBB4BF31F5CE1EFD585C2AD9
                                doc文档ID   bd23f32efc5149e68d3db315d4d65dcb
                                视频ID  1E95B3EFBB161B8FB63CABA2B9F8C90B88FFDC476F21142D5676AC307BF501E0
                                音频ID  B3D00837947C4079CD027A82723AD39FE1E801C34A9EB974363211B1BA3A07FA
                                */}
                                <FilePreview pageSize={1} startTime={0} fileId={fileId} resourceType={reporDetails.resourceType} resourceContent={reporDetails.resourceContent}/>
                            </div>
                            <div className="watch_bottom bg_fff">
                                <Col span={15}>
                                    <img className='iconphoto' src={require('@/assets/img/iconphoto.png')}/>
                                    {/* 🐖*/}
                                    <span>
                            上传人所属机构：{reporDetails.uploadUnitName} 上传人姓名：{reporDetails.uploadName} 丨 {reporDetails.onShelfTime} 更新
                           </span>
                                </Col>
                                <Col className='end' span={9}>
                        <span style={{marginRight: '36px'}}>
                            {/*<b>🐕</b>*/}
                            <span>浏览量：{reporDetails.openNum}</span>
                        </span>
                                    <span>
                            {/*<b>🐕</b>*/}
                            <span>收藏量：{reporDetails.collectNum}</span>
                        </span>
                                </Col>
                            </div>
                        </div>
                        <div className="right bg_fff">
                            <div className="right_top">
                                <div className="title">
                                    <h2>{reporDetails.resourceName}</h2>
                                </div>
                                <div className='auth'>
                                    <img src={reporDetails.authorImg} alt=""/>
                                    <span>
                          作者：{reporDetails.authorName ? reporDetails.authorName : "未知"}
                      </span>
                                </div>
                                <div className="affiliation">
                            {/*        职校分类：{reporDetails.stageName}&lt;{reporDetails.bigMajorId}&lt;{reporDetails.medMajorName}*/}
                                    职校分类：<span className={!reporDetails.stageName?"":"affiname"}>{reporDetails.stageName}</span><span className={!reporDetails.bigMajorName?"":"affiname"}>{reporDetails.bigMajorName}</span><span>{reporDetails.medMajorName}</span>
                                </div>
                                {reporDetails.resourceHotSeniorName ? <div className='tag'>
                                    <span>{reporDetails.resourceHotSeniorName}</span>
                                </div>: ''}
                            </div>
                            <div className="right_bottom">
                                <h2>一句话介绍</h2>
                                <p>
                                    {reporDetails.simpleIntroduce}
                                </p>
                            </div>
                            <div className="right_bottom">
                                <h2>资源处理记录</h2>
                                <div className="xiajia_box">
                                    {frozenReasonInfo && frozenReasonInfo.length ? frozenReasonInfo.map((item, index) => {
                                           return <div className="xiajia_cont" key={index}>
                                                <p className="xiajia_header">
                                                    <span className="xiajia_time">时间：{item.frozenTime}</span>
                                                    <span className="xiajia_name">审核人：{item.frozenUserName}</span>
                                                </p>
                                                <p className="xiajia_desc">{item.frozenReason}</p>
                                            </div>
                                        }) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="record_wai">
                    <div className="record_box">
                        <div className="alert">
                         <span>
                        <ExclamationCircleFilled className="report-icon" twoToneColor="#eb2f96"/>
                        <span className="see-tit">您查看资源的时候正有</span>
                        <i style={{color: '#E71B18'}}>{newReporNumbers.totalNumbers}条该资源的举报</i>
                        <span className="see-tit">上传，其中色情与反动言论的举报{newReporNumbers.emphasisNumbers}条举报记录</span>
                        </span>
                            <span>
                                {seconds}s后自动刷新
                              </span>
                        </div>
                        <h2>
                            <span>举报记录</span>
                            <span className='pointer' onClick={() => this.clickRefresh()}
                                  style={{fontWeight: 'normal'}}><SyncOutlined
                                className="update-icon"/>刷新举报记录</span>
                        </h2>
                        {/*0:色情 1:骚扰 2:反动言论 3:暴力 4:广告或垃圾信息 5:抄袭或未授权 6:其他}*/}
                        {reportTypeData && reportTypeData.length ? reportTypeData.map((item, index) => {
                           return    reportersBytype[index]&&reportersBytype[index].data && reportersBytype[index].data.length?
                               <div className="pornographic" key={index}>
                                   <div className="top">
                                       <div className='left'>
                                           <span className="inform-type">
                                                <span className={ `differentCommon different${index}`}>举报类型：{reportTypeData[index].tipOffTypeName}</span>
                                           </span>
                                           <i></i>
                                           <img alt="" src={require("@/assets/img/grounding/person-icon.png")}
                                                className="person-icon"/>
                                           <span> {reportersBytype[index]!=undefined?reportersBytype[index].total:0}人举报</span>
                                       </div>
                                       <div className='right harass-right'>
                                           <Button onClick={() => this.nextPage(index,reportersBytype[index].total)}
                                                   disabled={lastPage[index]}><ReloadOutlined
                                               className="refresh-icon"/>下一批</Button>
                                       </div>
                                   </div>
                                   <div className="list">
                                       {
                                           reportersBytype[index]&&reportersBytype[index].data && reportersBytype[index].data.length ? reportersBytype[index].data.map((item, index1) => {
                                               return <li key={index1}>
                                                   <div className="left">
                                                       <img src={window.$$img(item.tipOffUserFileId)} alt=""/>
                                                   </div>
                                                   <div className="right">
                                                       <h3>{item.tipOffUserName}</h3>
                                                       <div className="time">{timesFun(item.createTime)}</div>
                                                       <p className="content">
                                                           {item.tipOffDescribe}
                                                       </p>
                                                       <div className="img_list">
                                                           {item.tipOffImages.split(",").map((v, i) => {
                                                                   return <Zmage key={i} src={window.$$img(v)} alt="展示序列图片"/>
                                                                   // <img key={i} src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598170409452&di=0ea93d0a5ff5182b27d9fadb9238672e&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201707%2F10%2F20170710210234_y3Kf5.jpeg" alt="" />
                                                           })}
                                                       </div>
                                                   </div>
                                               </li>
                                           }) : ""
                                       }
                                   </div>
                               </div>:""

                        }) : ""}

                    </div>
                    <div className="action_bttom pos_f bg_fff">
                        <div className='action'>
                            {processState==1&&lastProcessType==0&&shelfStatus==1?
                            <Button className='ant_blue' onClick={() => this.setOffProblem()}>下架</Button>
                                :""}
                            {processState==1&&lastProcessType==0&&shelfStatus==0?
                            <Button className='ant_blue'>已下架</Button>
                            :""}
                            {processState==0?
                            <Button className='ant_blue' onClick={() => this.setNoProblem()}>没有问题</Button>
                            :""}
                            {processState==0?
                                <Button className='blue'  onClick={() => this.freeze()}>冻结资源</Button>
                                :""}
                            <Button className='blue' onClick={() => this.goBack()}>返回</Button>
                        </div>
                    </div>
                </div>
                <Modal
                    title='冻结原因'
                    wrapClassName='modal_box'
                    visible={visible}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.checkCancel()}>取消</Button>
                            <Button loading={saveLoading} onClick={() => this.selectType()} type='primary'>保存</Button>
                        </div>
                    }
                >
                    <Form {...formItemLayout}>
                        <Form.Item>
                            {
                                getFieldDecorator('examineReason', {
                                    rules: [{required: true, message: '请输入冻结原因'},
                                        {max: 100, message: '冻结原因不能超过100字'}]
                                })(<div className="sold-box">
                                    <TextArea value={examineReason} onChange={this.examineReasonChange.bind(this)}
                                              placeholder='请输入冻结原因'
                                              autoSize={{minRows: 3, maxRows: 5}}/>
                                    <span className="suffixStyle">{examineReasonNum}/100</span>
                                </div>)
                            }
                        </Form.Item>
                    </Form>
                </Modal>
                {/*操作下架*/}
                <OffShelf
                    ids={ids}
                    visible={this.state.lookVisible}
                    checkCancel={()=>this.lookClose()}
                    refreshData={()=>this.lookCancel()}
                    wrappedComponentRef={form => {
                        this.offShelfForm = form;
                    }}
                ></OffShelf>
            </div>
        )
    }
}

ReportDetail = connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
ReportDetail = Form.create()(ReportDetail)
export default ReportDetail;
