import React,{useState,useEffect,forwardRef} from 'react';
import { queryProvinceRange, queryProvince } from '../../api/components';
import { Select } from 'antd';

const {Option} = Select;

const ProvinceRangeSelect = (props,ref) => {
    const [list,setList] = useState([]);

    useEffect(()=>{
        const fn = async () => {
            try {
                const res = await queryProvinceRange({adminOrgId:props.id});
                const flag = Array.isArray(res) && res.some(it=>it.regionCode===0);
                if(flag){
                    const rec = await queryProvince();
                    setList(rec);
                    return;
                }
                setList(res);
            } catch (error) {
                console.log(error);
            }
        };
        fn();
    },[]);

    return (
        <Select
            {...props}
            ref={ref}
            getPopupContainer={triggerNode => triggerNode.parentElement}
        >
            {
                Array.isArray(list) && list.map((item) => {
                    return <Option key={item.regionCode || item.provinceCode}>
                        {item.regionName || item.name}
                    </Option>
                })

            }
        </Select>
    )
}

export default forwardRef(ProvinceRangeSelect);