import React, { Component } from 'react'

export default class Courselog extends Component {
    render() {
        return (
            <div>
               course-logcourse-logcourse-logcourse-logcourse-logcourse-logcourse-logcourse-logcourse-logcourse-logcourse-log
            </div>
        )
    }
}
