import React, { Component } from 'react';
import './index.scss';
import { Layout } from 'antd';
import RouterView from '@/router/router_view';
import Menu from '@/components/Menu';
import OBreadcrumb from '@/components/Breadcrumb';
import routerList from '@/router/router_config.js';
import { connect } from 'react-redux';
import BreadcrumbFC, { BreadcrumbFCContext } from '../../components/Breadcrumb/BreadcrumbFC'

const { Sider, Content } = Layout;

const menuList = [
    {
        menuName: '成员管理',
        id: 'sub1',
        icon: 'member_icon.svg',
        menuUrl: '/main/capacity-auth/member'
    },
    {
        menuName: '角色权限管理',
        id: 'sub2',
        icon: 'role_icon.svg',
        menuUrl: '/main/capacity-auth/role-auth'
    },
    {
        menuName: '菜单管理',
        id: 'sub3',
        icon: 'menu_icon.svg',
        menuUrl: '/main/capacity-auth/menu'
    },
    {
        menuName: '添加成员',
        id: 'sub10',
        menuUrl: '/main/capacity-auth/add-member',
        isHade: true
    },
    {
        menuName: '编辑成员',
        id: 'sub4',
        menuUrl: '/main/capacity-auth/edit-member',
        isHade: true
    },
    // {
    //     menuName: '角色分配',
    //     id: 'sub5',
    //     menuUrl: '/main/capacity-auth/roleDistribution',
    //     isHade: true
    // },
    {
        menuName: '应用管理',
        id: 'sub6',
        icon: 'yingyong_icon.svg',
        children: [
            {
                menuName: '配置管理',
                id: '0',
                menuUrl: '/main/capacity-auth/configuration'
            },
            {
                menuName: '菜单管理',
                id: '1',
                menuUrl: '/main/capacity-auth/menu-config',
                isHade: true
            },
            {
                menuName: '接入管理',
                id: '2',
                menuUrl: '/main/capacity-auth/access'
            },
            {
                menuName: '智慧教学-接入管理',
                id: '3',
                menuUrl: '/main/capacity-auth/cut-setSchool',
                isHade: true
            }
        ]
    }
]

class index extends Component {
    state = {
        beforeBreadcrumb: [{ name: '权限管理' }],
        list: []
    }
    preBreadcrumbList = []

    handleChangeBeforeBreadcrumb = (menu) => {
        this.setState({
            beforeBreadcrumb: [
                { name: '权限管理' },
                ...this.menuToBreadcrumb(menu)
            ]
        })
    }

    menuToBreadcrumb = (menu, breadcrumb = []) => {
        if (menu.parent) {
            this.menuToBreadcrumb(menu.parent, breadcrumb)
        }
        breadcrumb.push({
            name: menu.menuName,
            path: menu.menuUrl
        })
        return breadcrumb
    }

    render() {
        const { menuList } = this.props;
        const sonList = menuList.find(v => v.menuName === '能力权限')?.children ?? [];
        const { beforeBreadcrumb, list } = this.state
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    <Menu
                        {...this.props}
                        menuList={sonList}
                        onJump={(menu) => this.handleChangeBeforeBreadcrumb(menu)}
                    />
                </Sider>
                <Layout>
                    <BreadcrumbFCContext.Provider
                        value={{
                            list: list,
                            setList: (newList) => {
                                if (JSON.stringify(this.preBreadcrumbList) !== JSON.stringify(newList)) {
                                    this.preBreadcrumbList = newList
                                    this.setState({ list: newList })
                                }
                            }
                        }}
                    >
                        <BreadcrumbFC beforeBreadcrumb={beforeBreadcrumb} list={list}/>
                        <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                            <RouterView routers={this.props.routers}/>
                        </Content>
                    </BreadcrumbFCContext.Provider>
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}
index = connect(mapStateToProps)(index);
export default index;