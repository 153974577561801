/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useState, useEffect } from 'react';
import { Button, Form, Input, Select, Upload, DatePicker, Spin } from 'antd';
import ImgCrop from "antd-img-crop";
import moment from 'moment';
import DropDownMultipleSelection from '../../components/drop_down_multiple_selection';
import SelectLabelModal from '../../components/major_type_select';
import { uploadFile } from '../../api/manage_mechanism_manage';
import { uploadIcon, lookIcon, deleteIcon } from '../../icon_type';
import {
    getSchoolType, getSchoolLevel, getSchoolMode, enterSchoolInfo,
    getSchoolInfoDetail, schoolInfoUpdate
} from '../../api/school_settlement';
import { queryProvince, queryCity } from '../../api/components';
import { _get_query, disabledDate, afterDisabledDate } from '../../components/method_tools';
import style from './index.module.scss';

const { Option } = Select;
const that = new Component();
const format = 'YYYY-MM-DD';
const formItemLayout = {
    labelCol: {
        xxl: { span: 2 },
        xl: { span: 3 },
        lg: { span: 3 },
        md: { span: 3 },
    },
    wrapperCol: {
        xxl: { span: 15 },
        xl: { span: 15 },
        lg: { span: 18 },
        md: { span: 15 },
        span: 12
    },
};

const ImgCropProps = {
    aspect: 317 / 40,
    resize: true, //裁剪是否可以调整大小
    resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
    modalTitle: "上传logo", //弹窗标题
    modalWidth: 600, //弹窗宽度
    rotate: false,
    width: 317,  //裁剪宽度
    height: 40, //裁剪高度
};

let cloneImg = undefined;//图片选择的克隆地址

function CollegeInformationEntry(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = props.form;
    const id = _get_query('id');
    const [loading, setLoading] = useState(false);
    const [schoolTypeData, setSchoolTypeData] = useState([]);  //学校类型
    const [provinceData, setProvinceData] = useState([]);  //所属省
    const [cityData, setCityData] = useState([]);  //所属市
    const [schoolLevelData, setSchoolLevelData] = useState([]);  //办学层次
    const [schoolModeData, setSchoolModeData] = useState([]);  //办学方式
    const [imgModalVisble,setImgModalVisble] = useState(false);//logo预览

    useEffect(() => {
        async function getDownData() {
            const schoolData = await getSchoolType();
            const province = await queryProvince();
            const schoolLevel = await getSchoolLevel();
            const schoolMode = await getSchoolMode();
            setSchoolTypeData(schoolData || []);
            setProvinceData(province || []);
            setSchoolLevelData(schoolLevel);
            setSchoolModeData(schoolMode);
        }
        getDownData();
    }, [])
    
    useEffect(() => {
        async function fn() {
            try {
                const res = await getSchoolInfoDetail({ id });
                const city = await queryCity({ provinceCode: res.provinceId });
                setCityData(city);
                setFieldsValue({
                    ...res,
                    settledDate: moment(res.settledDate),
                    settledEndDate: moment(res.settledEndDate),
                    foundedTime: res.foundedTime ? moment(res.foundedTime) : undefined,
                })
            } catch (error) {
                console.log(error);
            }
        }
        id && fn();
    }, [])

    //上传图片
    const changeLoad = async info => {
        setLoading(true);
        const fromData = new FormData()
        fromData.append('file', info)
        fromData.append('folder', 'cn')
        try {
            const uploadResult = await uploadFile(fromData);
            setLoading(false);
            setFieldsValue({ enterpriseLogo: uploadResult.fpName });
        } catch (error) {
            console.log(error);
        }
    };

    const handleProvinceChange = async (provinceCode) => {
        const city = await queryCity({ provinceCode });
        setCityData(city);
        setFieldsValue({
            cityId: undefined
        })
    };

	const removeNbsp = e => {
		const val = e.target.value;
		if(typeof val !== 'string'){
			return val;
		};
		return val.replace(/\s+/g, "");
	}


    const handleSubmit = () => {
        validateFields(async (err, values) => {
            if (!err) {
                const { settledDate, settledEndDate, foundedTime } = values;
                values.settledDate = settledDate.format(format);
                values.settledEndDate = settledEndDate.format(format);
                foundedTime && (values.foundedTime = foundedTime.format(format));
                if (id) {
                    values.schoolId = id;
                    await schoolInfoUpdate(values);
                    that.mesSuccess("修改成功");
                } else {
                    await enterSchoolInfo(values);
                    that.mesSuccess("创建成功");
                }
                props.history.push('/main/institutional-settlement/school-settlement-manage')
            }
        })
    };

    //预览图片
    const onImgLook = e => {
        e.stopPropagation();
        setImgModalVisble(true);
    };
    
    return (
        <div className={style.CollegeInformationEntry}>
            <Form {...formItemLayout} colon={false}>
                <Form.Item label="学校名称">
                    {getFieldDecorator("enterpriseName", {
                        initialValue: '',
                        rules: [{ required: true, message: '学校名称不能为空' }],
                        getValueFromEvent:removeNbsp
                    })(
                        <Input
                            className="max_input"
                            placeholder='请输入学校名称'
                            size="large"
                            maxLength={30}
                            style={{ width: 500 }}
                            suffix={
                                <span style={{ color: "#888", fontSize: 12 }}>
                                    {getFieldValue("enterpriseName") ? getFieldValue("enterpriseName").length : 0}/
                                    <span style={{ color: "#333" }}>30</span>
                                </span>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item label="学校标识">
                    {getFieldDecorator("enterpriseCode", {
                        initialValue: '',
                        rules: [{ required: true, message: '学校标识不能为空' }],
                        getValueFromEvent:removeNbsp
                    })(
                        <Input
                            className="max_input"
                            placeholder='请输入学校标识'
                            size="large"
                            maxLength={15}
                            style={{ width: 500 }}
                            suffix={
                                <span style={{ color: "#888", fontSize: 12 }}>
                                    {getFieldValue("enterpriseCode") ? getFieldValue("enterpriseCode").length : 0}/
                                    <span style={{ color: "#333" }}>15</span>
                                </span>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item label="学校简称">
                    {getFieldDecorator("simpleDesc", {
                        getValueFromEvent:removeNbsp,
                        initialValue: '',
                    })(
                        <Input
                            className="max_input"
                            placeholder='请输入学校简称'
                            size="large"
                            maxLength={20}
                            style={{ width: 500 }}
                            suffix={
                                <span style={{ color: "#888", fontSize: 12 }}>
                                    {getFieldValue("simpleDesc") ? getFieldValue("simpleDesc").length : 0}/
                                    <span style={{ color: "#333" }}>20</span>
                                </span>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item label="英文名称">
                    {getFieldDecorator("enName", {
                        getValueFromEvent: (event) => {
                            console.log(event.target.value.replace(/[\u4e00-\u9fa50-9]/g,''));
                            //正则匹配的是汉字和数字
                            return event.target.value.replace(/[\u4e00-\u9fa50-9]/g,'')
                        },
                        initialValue: '',
                    })(
                        <Input
                            className="max_input"
                            placeholder='请输入英文名称'
                            size="large"
                            maxLength={200}
                            style={{ width: 500 }}
                            suffix={
                                <span style={{ color: "#888", fontSize: 12 }}>
                                    {getFieldValue("enName") ? getFieldValue("enName").length : 0}/
                                    <span style={{ color: "#333" }}>200</span>
                                </span>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item className='upload_box' label={'学校logo'}>
                    {getFieldDecorator('enterpriseLogo', {
                        getValueFromEvent: () => {
                            return cloneImg;
                        }
                    })(
                        <div>
                            <ImgCrop {...ImgCropProps}>
                                <Upload
                                    accept='.jpg,.png,.jpeg'
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={changeLoad}
                                >
                                    {
                                        loading
                                            ? <Spin />
                                            : (getFieldValue("enterpriseLogo")
                                                ? <div className='resource_replace_img'>
                                                    <div className="resource_replace_img_modal">
                                                        <span style={{ cursor: 'pointer' }} onClick={() => { cloneImg = getFieldValue("enterpriseLogo") }}>{uploadIcon}</span>
                                                        <span onClick={onImgLook} style={{ cursor: 'pointer' }}>{lookIcon}</span>
                                                        <span 
                                                            onClick={e=>{
                                                                e.stopPropagation();
                                                                cloneImg='';
                                                                setFieldsValue({enterpriseLogo:''});
                                                            }} 
                                                            style={{ cursor: 'pointer' }}
                                                        >{deleteIcon}</span>
                                                    </div>
                                                    <div className="img_s">
                                                        <img
                                                            style={{ width: "100%" }}
                                                            src={window.$$imgSrc(getFieldValue("enterpriseLogo"))} alt="avatar"
                                                        />
                                                    </div>
                                                </div>
                                                : <div className='add_image_div'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around',
                                                            marginBottom: 5
                                                        }}
                                                    >
                                                        <img style={{ width: "19px", height: "19px", display: "block" }} src={window.$$img('cvec-portal-front-pingtai/Circl/add_icon.png')} alt="" />
                                                    </div>
                                                    <span style={{ color: '#3E78ED' }}>上传Logo</span>
                                                </div>)
                                    }
                                </Upload>
                            </ImgCrop>
                        </div>
                    )
                    }
                    <div className="tishi_wrap">
                        <p
                            className='tishi'
                        >
                            支持jpg/png/jpeg格式，<br />
                            建议最佳尺寸317x40px，不超过5M
                        </p>
                    </div>
                </Form.Item>
                <Form.Item label="主管部门">
                    {getFieldDecorator("departmentName", {
                        initialValue: '',
                        rules: [{ required: true, message: '主管部门不能为空' }],
                        getValueFromEvent:removeNbsp
                    })(
                        <Input
                            className="max_input"
                            placeholder='请输入主管部门'
                            size="large"
                            maxLength={50}
                            style={{ width: 500 }}
                            suffix={
                                <span style={{ color: "#888", fontSize: 12 }}>
                                    {getFieldValue("departmentName") ? getFieldValue("departmentName").length : 0}/
                                    <span style={{ color: "#333" }}>50</span>
                                </span>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item label="创办时间" className={style.shijian}>
                    {getFieldDecorator('foundedTime')(
                        <DatePicker
                            placeholder="请选择日期"
                            style={{ width: 260 }}
                            disabledDate={afterDisabledDate}
                            getCalendarContainer={triggerNode => triggerNode.parentElement}
                        />
                    )}
                </Form.Item>
                <Form.Item label="学校类型">
                    {getFieldDecorator('schoolTypeIds', {
                        initialValue: [],
                        rules: [{ required: true, message: '学校类型不能为空' }]
                    })(
                        <DropDownMultipleSelection
                            data={schoolTypeData}
                            codeKeyName="schoolTypeCode"
                            keyName="schoolTypeName"
                            placeholder="请选择学校类型"
                        />
                    )}
                </Form.Item>
                <Form.Item label="所属省/市">
                    {getFieldDecorator('ascriptionProvince', {
                        // initialValue: undefined,
                        rules: [{ required: true, message: '所属省/市不能为空' }]
                    })(
                        <Select
                            placeholder="请选择所属省/市"
                            style={{ width: 500 }}
                            size='large'
                            getPopupContainer={triggerNode => triggerNode.parentElement}
                        >
                            {provinceData.map(item => <Option key={item.provinceCode} value={item.provinceCode}>{item.name}</Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="所在地" required style={{ marginBottom: 0 }}>
                    <Form.Item label={undefined} style={{ display: 'inline-block', width: 250, marginRight: 19 }}>
                        {getFieldDecorator('provinceId', {
                            rules: [{ required: true, message: '所在地不能为空' }]
                        })(
                            <Select
                                placeholder="请选择省"
                                size='large'
                                onChange={handleProvinceChange}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                            >
                                {provinceData.map(item => <Option key={item.provinceCode} value={item.provinceCode}>{item.name}</Option>)}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={undefined} style={{ display: 'inline-block', width: 250 }}>
                        {getFieldDecorator('cityId', {
                            rules: [{ required: true, message: '所在地不能为空' }]
                        })(
                            <Select
                                placeholder="请选择市"
                                size='large'
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                            >
                                {cityData.map(item => <Option key={item.cityCode} value={item.cityCode}>{item.name}</Option>)}
                            </Select>
                        )}
                    </Form.Item>
                </Form.Item>
                <Form.Item label="办学层次">
                    {getFieldDecorator('schoolRunLevelIds', {
                        initialValue: [],
                        rules: [{ required: true, message: '办学层次不能为空' }]
                    })(
                        <DropDownMultipleSelection
                            data={schoolLevelData}
                            codeKeyName="schoolLevelCode"
                            keyName="schoolLevelName"
                            placeholder="请选择院办学层次"
                        />
                    )}
                </Form.Item>
                <Form.Item label="办学方式">
                    {getFieldDecorator('schoolRunMode', {
                        initialValue: undefined,
                        rules: [{ required: true, message: '办学方式不能为空' }]
                    })(
                        <Select
                            placeholder="请选择办学方式"
                            style={{ width: 500 }}
                            size='large'
                            getPopupContainer={triggerNode => triggerNode.parentElement}
                        >
                            {schoolModeData.map(item => <Option key={item.schoolModelCode} value={item.schoolModelCode}>{item.schoolModelName}</Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="学校网址">
                    {getFieldDecorator("websiteAdd", {
                        initialValue: '',
                        rules: [
                            { pattern: /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/, message: '请输入正确链接地址' }
                        ],
                        getValueFromEvent:removeNbsp
                    })(
                        <Input
                            placeholder='请输入学校网址'
                            size="large"
                            style={{ width: 500 }}
                        />
                    )}
                </Form.Item>
                <Form.Item label="平台使用日期" required style={{ marginBottom: 0 }} className={style.shijian}>
                    <Form.Item label={undefined} style={{ display: 'inline-block', width: 260 }}>
                        {getFieldDecorator('settledDate', {
                            rules: [
                                { required: true, message: '开通日期不能为空' },
                                (rule, val, callback) => {
                                    // getFieldValue
                                    const endTime = getFieldValue('settledEndDate');
                                    if (
                                        val && endTime &&
                                        moment(endTime.format(format)).diff(moment(val.format(format)), 'days') < 0
                                    ) {
                                        callback("开始时间不能大于结束时间")
                                    } else {
                                        if (endTime) validateFields(['settledEndDate']);
                                        callback();
                                    }
                                }
                            ]
                        })(
                            <DatePicker
                                placeholder="请选择开通日期"
                                style={{ width: 260 }}
                                disabledDate={disabledDate}
                                getCalendarContainer={triggerNode => triggerNode.parentElement}
                            />
                        )}
                    </Form.Item>
                    <span style={{ display: 'inline-block', width: '55px', textAlign: 'center' }}>至</span>
                    <Form.Item label={undefined} style={{ display: 'inline-block', width: 260 }}>
                        {getFieldDecorator('settledEndDate', {
                            rules: [
                                { required: true, message: '到期日期不能为空' },
                                (rule, val, callback) => {
                                    // getFieldValue
                                    const startTime = getFieldValue('settledDate');
                                    if (
                                        val && startTime &&
                                        moment(val.format(format)).diff(moment(startTime.format(format)), 'days') < 0
                                    ) {
                                        callback("结束时间不能小于开始时间")
                                    } else {
                                        if (startTime) validateFields(['settledDate']);
                                        callback();
                                    }
                                }
                            ]
                        })(
                            <DatePicker
                                placeholder="请选择到期日期"
                                style={{ width: 260 }}
                                disabledDate={disabledDate}
                                getCalendarContainer={triggerNode => triggerNode.parentElement}
                            />
                        )}
                    </Form.Item>
                </Form.Item>
                <Form.Item label="机构标签">
                    {getFieldDecorator('labelIds', {

                    })(
                        <SelectLabelModal
                            isEdit
                        />
                    )}
                </Form.Item>
            </Form>

            <div className={style.fixed_footer}>
                <Button onClick={handleSubmit} type="primary" style={{ marginRight: 30 }}>{id ? '确定修改' : '确定创建'}</Button>
                <Button onClick={() => {
                    props.history.push('/main/institutional-settlement/school-settlement-manage')
                }}>取 消</Button>
            </div>
            <div className={`material_audit_box_modal ${!imgModalVisble ? 'material_audit_box_modal_none' : ''}`} onClick={() => setImgModalVisble(false)}>
                <img alt="" src={window.$$imgSrc(getFieldValue("enterpriseLogo"))} width="500"/>
            </div>
        </div>
    )
}
export default Form.create()(CollegeInformationEntry);