/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import './index.scss';
import { Select, Icon, Button, Dropdown, Menu, Input, Table, Tooltip, Modal, message } from 'antd';
import { getList, initPassword, deleteItem, changeUserState, globalSel, getEnterprisList, getDataArea, setDataArea, template, toAction, errTemplate } from '@/api/member/index.js';
import DataDistribution from './dataDistribution';
import Batch from '@/components/batch';
import axios from 'axios';
const { Option } = Select;




export default class index extends Component {
    state = {
        columns: [
            {
                title: '成员名称',
                width: 180,
                dataIndex: 'fullName',
                key: 'fullName',
                align:'center'
                // fixed: 'left',
            },
            {
                title: '适用身份',
                width: 100,
                dataIndex: 'identityName',
                key: 'identityName',
                align:'center'
                // fixed: 'left',
            },
            {
                title: '成员账号',
                dataIndex: 'userName',
                key: 'userName',
                width: 150,
                align:'center'
            },
            {
                title: '角色名称',
                dataIndex: 'roleName',
                key: 'roleName',
                width: 150,
                align:'center'
            },
            {
                title: '联系方式',
                dataIndex: 'telNum',
                key: 'telNum',
                width: 150,
                align:'center',
                render: (text, item, index) => {
                    return <span>
                        {item.telCheck === 1 ? <img style={{ marginRight: '5px' }} src={require('@/assets/img/yes.png')} alt='' /> : null}
                        {text}
                    </span>
                }
            },
            {
                title: '系统注册时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 150,
                align:'center'
            },
            {
                title: '所在机构',
                dataIndex: 'organizationName',
                key: 'organizationName',
                width: 150,
                align:'center'
            },
            {
                dataIndex: 'ifDisable',
                key: 'ifDisable',
                width: 150,
                align:'center',
                render: (text) => {
                    if (text === 0) {
                        return <span>有效</span>
                    } else if (text === 1) {
                        return <span>禁用</span>
                    }
                },
                title: () => {
                    let activeKey = this.state.status === '' ? 'all' : this.state.status;
                    return <Dropdown overlay={
                        <Menu onClick={this.roleStatusChange.bind(this)} className='downmenu' selectedKeys={[activeKey]} style={{ width: "100px", textAlign: "center" }}>
                            <Menu.Item key={'all'}>
                                <a>全部</a>
                            </Menu.Item>
                            <Menu.Item key={'0'}>
                                <a>有效</a>
                            </Menu.Item>
                            <Menu.Item key={'1'}>
                                <a>禁用</a>
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a onClick={e => e.preventDefault()} style={{ color: '#000', opacity: ".85" }}>
                            状态 <Icon style={{ color: "#0D1731", opacity: ".2" }} type="caret-down" />
                        </a>
                    </Dropdown>
                }
            },
            {
                title: '数据权限',
                dataIndex: 'dataPermission',
                key: 'dataPermission',
                width: 150,
                align:'center',
                render: (text) => {
                    if (text === 0) {
                        return <span>部分</span>
                    } else if (text === 1) {
                        return <span>全部</span>
                    }
                },
            },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 300,
                align:'center',
                render: (text, item) => {
                    let el = <>
                        <i onClick={() => this.changeState(item)}>{item.ifDisable === 0 ? '禁用' : item.ifDisable === 1 ? '启用' : ''}</i>
                        <i onClick={() => this.edit(item)}>编辑</i>
                        <i onClick={() => this.deleteItem(item)}>删除</i>
                    </>

                    return <p className='shu_btn_j'>
                        {/* <span onClick={() => this.allotData(item)}>数据分配</span> */}
                        <span onClick={() => this.initPassword(item)}>初始密码</span>
                        <span onClick={() => this.roleBution(item)}>角色分配</span>
                        <Tooltip overlayClassName='my_tip' placement='bottom' title={el}><Button className='btn'>更多<img src={require('@/assets/img/down.png')} alt='' /></Button></Tooltip>
                    </p>
                }
            }
        ],
        dataSource: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        fullName: '',   //用户姓名
        identityId: '',  //身份id
        organizationId: '',  //机构id
        enterprisList: [],
        statusList: [],
        isBlock: false,
        dataList: [],
        checkedKeys: [],
        status: '',
        batch: false,
        errorMessage: '',
        btnActive: false,
        fileName: ''
    }
    roleStatusChange = e => {
        let key = e.key;
        let status = key === 'all' ? '' : key;
        this.setState({ status }, () => this.getList());
    }
    //角色分配
    roleBution = item => {
        sessionStorage.setItem('roleDistribution', JSON.stringify(item));
        this.props.history.push('/main/capacity-auth/roleDistribution');
    }
    //数据分配
    allotData = item => {
        this.getDataArea(item);
    }
    //获取数据分配数据
    getDataArea = item => {
        getDataArea({
            user_num: item.userName
        }).then(res => {
            if (res) {
                let { areaDataVoList, userChooseList } = res;
                this.setState({ dataList: areaDataVoList, checkedKeys: userChooseList, option: item, isBlock: true });
            }
        })
    }
    //编辑成员
    edit = item => {
        this.props.history.push(`/main/capacity-auth/edit-member?${decodeURI(JSON.stringify({ id: item.id }))}`)
    }
    //机构下拉
    getEnterprisList = () => {
        getEnterprisList().then(res => {
            if (res) {
                this.setState({ enterprisList: res });
            }
        })
    }
    //身份下拉
    getStatusSel = () => {
        globalSel({ type: 7 }).then(res => {
            if (res) {
                this.setState({ statusList: res })
            }
        })
    }
    //禁用启用
    changeState = item => {
        Modal.confirm({
            title: '您确定要禁用吗？',
            zIndex: 2000,
            onOk: () => {
                changeUserState({
                    id: item.id,
                    status: item.ifDisable === 0 ? 1 : 0
                }).then(res => {
                    if (res) {
                        message.success(item.ifDisable === 0 ? '禁用成功' : '启用成功');
                        this.getList();
                    }
                })
            },
            onCancel: () => {

            }
        })
    }
    //删除
    deleteItem = item => {
        Modal.confirm({
            title: '您确定要删除吗？',
            className: 'reset_weight',
            zIndex: 2000,
            onOk: () => {
                deleteItem({
                    id: item.id
                }).then(res => {
                    if (res) {
                        message.success('删除成功');
                        this.getList();
                    }
                })
            },
            onCancel: () => {

            }
        })
    }
    initPassword = item => {
        Modal.confirm({
            title: '您确定要初始化密码吗？',
            onOk: () => {
                initPassword({ id: item.id }).then(res => {
                    if (res) {
                        message.success('初始化成功');
                        this.getList();
                    }
                })
            },
            onCancel: () => {

            }
        })
    }
    componentDidMount() {
        this.getList();
        this.getStatusSel();
        this.getEnterprisList();
        // this.getDataArea();
    }
    //获取列表数据
    getList = () => {
        let params = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            fullName: this.state.fullName,
            identityId: this.state.identityId,
            organizationId: this.state.organizationId,
            status: this.state.status
        }
        getList(params).then(res => {
            if (res) {
                this.setState({
                    dataSource: res.data,
                    total: res.total
                })
            }
        })
    }
    addMember = () => {
        this.props.history.push('/main/capacity-auth/add-member')
    }
    pageChange = (page, pageSize) => {
        this.setState({ pageNum: page }, () => {
            this.getList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    onShowSizeChange = (current, size) => {
        this.setState({ pageSize: size }, () => {
            this.getList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    save = () => {
        setDataArea({
            // userName:this.dataRef.props.option.userName,
            userName: 'kbj',
            areaIdList: this.dataRef.state.checkedKeys.map(v => v.areaId)
        }).then(res => {
            if (res) {
                message.success('分配成功');
                this.setState({ isBlock: false });
            }
        })
    }
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
        this.getList();
        this.batchRef.clear();
    }
    //下载文件
    dowLoadFile = (template) => {
        axios.get(template, {
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `成员错误信息.csv`);
            link.click();
        })
    }
    render() {
        const { columns, dataSource, total, checkedKeys, enterprisList, statusList, isBlock, dataList, option, batch, errorMessage, btnActive, fileName, pageSize } = this.state;
        return (
            <div className='member_box'>
                <div className="filter_controll">
                    <div>
                        <span style={{ marginRight: '20px' }}>所属机构</span>
                        <Select placeholder='请选择所属机构' onChange={e => {
                            this.setState({ organizationId: e }, () => this.getList())
                        }} >
                            <Option value={''}>全部</Option>
                            {
                                enterprisList.map(v => {
                                    return <Option value={v.id} key={v.id}>{v.enterpriseName}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div>
                        <span style={{ marginRight: '20px' }}>身份类型</span>
                        <Select placeholder='请选择身份类型' onChange={e => {
                            this.setState({ identityId: e }, () => this.getList())
                        }} >
                            <Option value={''}>全部</Option>
                            {
                                statusList.map(v => {
                                    return <Option value={v.code} key={v.code}>{v.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="member_content">
                    <div className='top'>
                        <div className="left">
                            <span>
                                <Button onClick={this.addMember} className='add_btn_j' style={{ marginRight: '20px' }} >添加成员</Button>
                                <Button onClick={() => this.setState({ batch: true })} className='dr_btn_j'>导入成员</Button>
                            </span>
                            {/* <span>验证标识</span>
                            
                            */}
                        </div>
                        <div className="right">
                            <Input.Search onChange={(e) => this.setState({ fullName: e.target.value })} onSearch={this.getList} placeholder='请输入个人姓名' />
                        </div>
                    </div>
                    <div className="bottom">
                        <Table rowKey={v => v['id']} pagination={
                            {
                                total: total,
                                pageSize: pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['10', '20', '30', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns}
                            scroll={{ x: 1450 }}
                            dataSource={dataSource} />
                    </div>
                </div>
                <Modal
                    visible={isBlock}
                    destroyOnClose={true}
                    closable={false}
                    className='data_modal'
                    title={<div className='modal_title'>
                        <span>数据分配</span>
                        <span>已选择</span>
                    </div>}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({ isBlock: false })}>取消</Button>
                            <Button onClick={this.save} type='primary'>保存</Button>
                        </div>
                    }
                >
                    <DataDistribution ref={e => this.dataRef = e} checkedKeys={checkedKeys} option={option} dataList={dataList} />
                </Modal>
                <Modal className='batch' visible={batch} title='批量导入成员'
                    closable={false}
                    footer={
                        <div style={{ width: "100%" }}>
                            <div className='pos-btn'>
                                <Button onClick={this.closeBatch}>取消</Button>
                                <Button disabled={btnActive ? false : true} className={btnActive ? "" : "gray"}
                                    onClick={this.closeBatch}>确定</Button>

                            </div>
                            {errorMessage ?
                                <div className='error'>
                                    <span>{`· 成员模版（${fileName}）.${errorMessage}，请下载查看`}</span>
                                    <span onClick={() => this.dowLoadFile(errTemplate)}>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                </div>
                                : null}
                        </div>
                    }
                >
                    <Batch
                        ref={ref => this.batchRef = ref}
                        sure={() => this.setState({ btnActive: true })}
                        template={template}
                        action={toAction}
                        reset={() => this.setState({ errorMessage: '', btnActive: false })}
                        pullError={(val) => this.setState(val)}
                        titleType='成员'
                    />
                </Modal>
            </div>
        )
    }
}
