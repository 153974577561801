import React from "react";
import {
  Button,
  Tabs,
  Select,
  Input,
  Form,
  Table,
  Modal,
  Pagination,
  Row, Col, message, Popover, Tag, Switch, Upload, Statistic, Slider,Spin,Drawer
} from "antd";
import "./index.scss";
import {withRouter, Link} from "react-router-dom";
import Banned from '@/components/banned';
import Processing from '@/components/processing';
import LivePreview from "@/components/LivePreview";
import { commonalityUrl, turningSupportedArr, previewUrl, resource_preview } from '@/config/secret.js';
import { baseURL } from '@/config/index.js';
import  RenderImg from '@/components/renderImg';
// import Footer from "@/components/footer";
import PreviewLiveInfo from "@/components/previewLiveInfo";
import {
  getTeacherLiveDetail,
  selectLiveResource,
  updateLiveResource,
  updateIsStudentLook,
  getChatMsgForDanmu,
  deleteLiveResource,
  getReportersByType,
  pauseLive,
  frozenAndForceEndLive, adminJoinLiveRoom, getReportNumbers, continueLive,getTipOffPage
} from "@/api/lives/live";
import moment from "moment";
import playbackCanvasBarrage from "@/components/utils/playbackCanvasBarrage";

const {TabPane} = Tabs;
const { Countdown } = Statistic;
let newDate = new Date().getTime()
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

export default Form.create()(
  withRouter(
    class Teacherlivepage extends React.Component {
      state = {
        liveVisible:false,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        changecurrentTime:0,
        zhengzaijinxing:0,
        auditDetails: false,
        breadcrumbData: [
          {
            name: "个人中心",
            path: "/",
          },
          {
            name: "我的直播",
            path: "/",
          },
          {
            name: "教师直播详情",
            path: "/",
          },
        ], //面包屑
        liveStatusFlag: 0,
        canvasBarrage:null,
        isCamera: false,
        eleVideo:null,
        isVoice: false,
        activeKey: "1",
        coverFlag: false,
        addFlag: false,
        barrageStatus:true,
        taskTable: false,
        Edittitle:false,
        resourceDetial:{},
        type:1,//1视频
        columns: [
          {
            title: '名称',
            dataIndex: 'resourceName',
            key: 'resourceName'
          },
          {
            title: '格式',
            dataIndex: 'resourceSuffix',
            key: 'resourceSuffix'
          },
          {
            title: '大小',
            dataIndex: 'resourceSize',
            key: 'resourceSize',
            render: (text, record) => (
              text < 1024 ? text + "K" :  (text/1024/100).toFixed(2) + "M"
            )
          }, {
            title: '上传时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (item) => {
              return <div className='time'>
                {item}
              </div>
            }
          },
          {
            title: '操作',
            dataIndex: 'oper',
            key: 'oper',
            render: (text, record) => {
              return <div className='oper'>
                <span onClick={() => this.showDrawer(record)}>预览</span>
              </div>
            }
          }],
        dataSource: [],
        data: [
          {
            title: '标题',
            dataIndex: 'videoTitle',
            key: 'videoTitle',
            render: (text, record) => {
              return<div className='title-h3 title-h3a'>
                <img src={require('@/assets/img/images/icon-29.png')}/>【回放】{text}
              </div>
            }
          },
          {
            title: '录制时长',
            dataIndex: 'videoDuration',
            key: 'videoDuration',
            render: (text, record) => {
              return <div className='time'>
                {text}
              </div>
            }
          },
          {
            title: '状态',
            dataIndex: 'videoStatus',
            key: 'videoStatus',
            render: (text, record) => {
                return <div>
                <span>
                    {text === 1 ? "转码中": text === 2 ? "转码完成": ""}
                </span>
                </div>
            }
          },
          {
            title: '操作',
            dataIndex: 'oper',
            key: 'oper',
            render: (text, record,index) => {
              return <div className='oper'>
                <span onClick={() => this.playback1({index,record})}>查看</span>
              </div>
            }
          }],
        dataSources: [],
        dataBarrage : [],
        liveDetail: {},
        studentNum: '',
        liveDetailPlaybackVoList: [],
        taskId: '',
        userId:localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "",
        startTime: new Date().getTime(),
        timeStamp: '',
        videoUrl:"",
        isZhibo:false,
        disabled: false,
        isplay:true,
        isInfoShow:true,
        sliderVile: 100,
        videoNum:0,
        isonVideo:false,
        isdownVideo:false,
        isBarrage:1,
        playbackTime:'00:00:00',
        duration:'00:00:00',
        reportsByType0:[],
        reportsByType1:[],
        reportsByType2:[],
        reportsByType3:[],
        reportsByType4:[],
        reportsByType5:[],
        reportsByType6:[],
        pageNumByType0:1,
        pageNumByType1:1,
        pageNumByType2:1,
        pageNumByType3:1,
        pageNumByType4:1,
        pageNumByType5:1,
        pageNumByType6:1,
        pagesByType0:1,
        pagesByType1:1,
        pagesByType2:1,
        pagesByType3:1,
        pagesByType4:1,
        pagesByType5:1,
        pagesByType6:1,
        freezeModalFlag:false,
        reportCount:0,
        reportType:0,
        isReport:0,
        isPause:0,
        countdown:10,
        lastProcessTime:'',
        lastProcessType:'',
        coverFlagView:false,
        importentCount:0,
        time:new Date().format("yyyy-MM-dd hh:mm:ss"),
        full_flag:true,
        liveLoading:true,
        visible: false,
        resourceType: '',
        accetFullScreen:false,//是否开启全屏
        beforePage:0,//判断上一页路由
        banFlag:false//暂停直播
      };
      resourceIsStudentLook = (e) => {
        let isStudentLook = 0;
        if (e.isStudentLook === 0) {
          isStudentLook = 1;
        }
        e.isStudentLook = isStudentLook;
        updateLiveResource(e).then(res => {
          message.success("修改成功")
        })
      };
      updateIsStudentLook = (e) => {
        let isStudentLook = 0;
        if (e.isStudentLook === 0) {
          isStudentLook = 1;
        }
        console.log("e",e)
        // e.isStudentLook = isStudentLook;
        updateIsStudentLook({playbackId:e.id,isStudentLook:isStudentLook}).then(res => {
          message.success("修改成功")
        })
      };

      componentDidMount() {
        document.addEventListener("webkitfullscreenchange",  (e) => {
          if(!document.webkitIsFullScreen){
            this.setState({
              accetFullScreen:false
            })
          }
          if (e.target.tagName === 'VIDEO'){
            document.webkitCancelFullScreen();
            let elementById = document.getElementById('li-img');
            console.log(elementById)
            elementById.webkitRequestFullScreen();
          }
        });
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        if (param) {
          const codeParam = param.includes("%") ? decodeURI(param) : param;
          const jsonparam = JSON.parse(codeParam);
          this.setState({
            taskId: jsonparam.taskId,
            isReport:jsonparam.isReport,
            activeKey:jsonparam.activeKey?jsonparam.activeKey:'1',
            beforePage:jsonparam.beforePage
          }, () => {
            console.log("beforPage",this.state.beforePage)
            this.getLiveDetails();
            this.getLiveResource();
            this.getReports();
            this.getReportCount();
            this.reportCount();
          })
          console.log("当前直播状态：：：；",this.state.taskStatus)

        }
      }

      //自动获取当前在提交的举报记录
      reportCount = () => {
        this.reportTime = setInterval(() => {
          this.setState({
              countdown:this.state.countdown - 1
          },() => {
              if (this.state.countdown == 0){
                  this.getReportCount();
                  this.setState({
                      countdown:10
                  })
              }
          })
        },1000)
      }



      //调取举报次数的接口
      getReportCount = () => {
        console.log("时间:::::",this.state.time);
        let nowDate = new Date(new Date().getTime()-10* 1000).format("yyyy-MM-dd hh:mm:ss");
        getReportNumbers({reportId:this.state.taskId,dateTime:nowDate}).then(res => {
          if (res){
            console.log("次数",res);
            this.setState({
              reportCount:res.totalNumbers,
              importentCount:res.emphasisNumbers
            })
          }
        })
      }

      //根据type获取举报记录的数据（用于下一页）
      getReport = (type,pageNum) => {
        if (type === 0){
          getReportersByType({resourceId:this.state.taskId,type:type,pageNum:pageNum,pageSize:5}).then(res => {
            if (res){
              this.setState({
                reportsByType0:res.data,
                totalByType0:res.total,
                pagesByType0:res.pages
              })
            }
          });
        }else if (type === 1){
          getReportersByType({resourceId:this.state.taskId,type:type,pageNum:pageNum,pageSize:5}).then(res => {
            if (res){
              this.setState({
                reportsByType1:res.data,
                totalByType1:res.total,
                pagesByType1:res.pages
              })
            }
          });
        }else if (type === 2){
          getReportersByType({resourceId:this.state.taskId,type:type,pageNum:pageNum,pageSize:5}).then(res => {
            if (res){
              this.setState({
                reportsByType2:res.data,
                totalByType2:res.total,
                pagesByType2:res.pages
              })
            }
          });
        }else if (type === 3){
          getReportersByType({resourceId:this.state.taskId,type:type,pageNum:pageNum,pageSize:5}).then(res => {
            if (res){
              this.setState({
                reportsByType3:res.data,
                totalByType3:res.total,
                pagesByType3:res.pages
              })
            }
          });
        }else if (type === 4){
          getReportersByType({resourceId:this.state.taskId,type:type,pageNum:pageNum,pageSize:5}).then(res => {
            if (res){
              this.setState({
                reportsByType4:res.data,
                totalByType4:res.total,
                pagesByType4:res.pages
              })
            }
          });
        }else if (type === 5){
          getReportersByType({resourceId:this.state.taskId,type:type,pageNum:pageNum,pageSize:5}).then(res => {
            if (res){
              this.setState({
                reportsByType5:res.data,
                totalByType5:res.total,
                pagesByType5:res.pages
              })
            }
          });
        }else if (type === 6){
          getReportersByType({resourceId:this.state.taskId,type:type,pageNum:pageNum,pageSize:5}).then(res => {
            if (res){
              this.setState({
                reportsByType6:res.data,
                totalByType6:res.total,
                pagesByType6:res.pages
              })
            }
          });
        }

      };
      onClose = () => { // 关闭详情
        this.setState({
          visible: false,
        });
      };

      //同时获取所有类型的举报记录（用于刷新列表）
      getReports = () => {
        //0:涩情
        getReportersByType({resourceId:this.state.taskId,type:0}).then(res => {
          if (res){
            this.setState({
              reportsByType0:res.data,
              totalByType0:res.total
            })
          }
        });
        //1:骚扰
        getReportersByType({resourceId:this.state.taskId,type:1}).then(res => {
          if (res){
            this.setState({
              reportsByType1:res.data,
              totalByType1:res.total
            })
          }
        });
        //2:反动言论
        getReportersByType({resourceId:this.state.taskId,type:2}).then(res => {
          if (res){
            this.setState({
              reportsByType2:res.data,
              totalByType2:res.total
            })
          }
        });
        //3:暴力
        getReportersByType({resourceId:this.state.taskId,type:3}).then(res => {
          if (res){
            this.setState({
              reportsByType3:res.data,
              totalByType3:res.total
            })
          }
        });
        //4:广告或垃圾信息
        getReportersByType({resourceId:this.state.taskId,type:4}).then(res => {
          if (res){
            this.setState({
              reportsByType4:res.data,
              totalByType4:res.total
            })
          }
        });
        //5:抄袭或未授权
        getReportersByType({resourceId:this.state.taskId,type:5}).then(res => {
          if (res){
            this.setState({
              reportsByType5:res.data,
              totalByType5:res.total
            })
          }
        });
        //6:其他
        getReportersByType({resourceId:this.state.taskId,type:6}).then(res => {
          if (res){
            this.setState({
              reportsByType6:res.data,
              totalByType6:res.total
            })
          }
        })

        //修改条数
        this.setState({
          time:new Date().format("yyyy-MM-dd hh:mm:ss"),
          reportCount:0
        })
      };

      onVideo = () => {
        let { liveDetail,videoNum} = this.state
        console.log("44444444444",videoNum)
        if(document.getElementById("previewIframe")){
          console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewIframe"))
          document.getElementById("previewIframe").innerHTML=""
        }
        if(document.getElementById("previewVideo")){
          console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewVideo"))
          document.getElementById("previewVideo").innerHTML=""
        }
        this.setState({
          changecurrentTime:0,
          isonVideo:videoNum-2 < 0?true:false,
          isdownVideo:false,
          isplay:true,
          videoUrl:liveDetail.liveDetailPlaybackVoList[videoNum-1].videoUrl,
          videoNum:videoNum-1,
          mediaType:"mp4",
          videoTime:this.state.videoTime+5,
          isZhibo:true,
        },()=>{
          this.getFileUrl()
        })
      };

      downVideo = () => {
        let { liveDetail,videoNum} = this.state
        console.log("44444444444",videoNum)
        console.log("3333333333333",liveDetail.liveDetailPlaybackVoList.length)
        if(document.getElementById("previewIframe")){
          console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewIframe"))
          document.getElementById("previewIframe").innerHTML=""
        }
        if(document.getElementById("previewVideo")){
          console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewVideo"))
          document.getElementById("previewVideo").innerHTML=""
        }
        this.setState({
          changecurrentTime:0,
          videoNum:videoNum+1,
          isdownVideo:this.state.videoNum+3 > liveDetail.liveDetailPlaybackVoList.length?true:false,
          isonVideo:false,
          isplay:true,
          videoUrl:liveDetail.liveDetailPlaybackVoList[videoNum+1].videoUrl,
          mediaType:"mp4",
          videoTime:this.state.videoTime+5,
          isZhibo:true,
        },()=>{
          this.getFileUrl()
        })
      };
      playback1 = (record) =>{
        console.log("record:::", record)
        this.setState({
          // isInfoShow:false,
          dataBarrage:[],
          liveVisible:true
        },() => {
          var event = new Event('resetBarrage');
          document.dispatchEvent(event);
          console.log("222",record);
          const {liveDetail} = this.state;
          if(document.getElementById("previewIframe")){
            console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewIframe"))
            document.getElementById("previewIframe").innerHTML=""
          }
          if(document.getElementById("previewVideo")){
            console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewVideo"))
            document.getElementById("previewVideo").innerHTML=""
          }
          let param = {
            taskId:this.state.liveDetail.id,
            videoId: record.record.id,
            videoTime:30
          };
          getChatMsgForDanmu(param).then(res => {
            console.log("聊天记录res:::",res);
            res.map(item => {
              let mes = {
                value:item.chatMessage,
                time:item.videoTime
              };
              this.state.dataBarrage.push(mes);
            });
            this.setState({
              // fileId:record.fileId,
              // resourceId: record.resourceId,
              changecurrentTime:0,
              isplay: true,
              videoUrl:record.record.videoUrl,
              mediaType:"mp4",
              isZhibo:true,
              videoNum: record.index,
              isonVideo: record.index-1 < 0?true: false,
              isdownVideo: record.index+2 > liveDetail.liveDetailPlaybackVoList.length?true: false,
            },()=>{
              this.getFileUrl()
            })
          })
        })
      };
      //直播回放返回
      liveOnClose(){
        this.setState({
          liveVisible:false,
          isplay: true
        })
      }
      showDrawer = (record) => {  // 查看详情
        // this.setState({
        //   isInfoShow:false
        // });
        if(record.transStatus === 3){
          message.error("文件正在转码中，请稍后")
          return
        }
        if(record.transStatus === 2){
          message.error("文件转码失败")
          return
        }
        this.setState({
          fileId:record.fileId,
          resourceId: record.resourceId,
          resourceName:record.resourceName,
          resourceSuffix:record.resourceSuffix,
          transFileSuffix:record.transFileSuffix,
          resourceType: ['pdf','doc','docx','pptx','ppt','xlsx','xls','txt','rtf'].includes(record.resourceSuffix)? 1:4,
          //     ['jpg','jpeg','png','bmp','gif'].includes(record.resourceFormat)? 4:
          //         ['.mp4','mp4','rmvb','mov','swf','avi','rm','wmv','3gp','mpg','mpeg','wav','wave','wma','flv','wmv'].includes(record.resourceFormat)? 2:
          //             ['.mp3','mp3'].includes(record.resourceFormat)? 3:"",
          mediaType:record.resourceSuffix,
          resourceContent:record.resourceContent,
          suffix: record.transSuffix,
          previewId: record.transSuffix ==='-1'?record.fileId +'/'+ record.resourceName :record.transId +'/'+ record.resourceName +'.'+ record.transSuffix,
          photoId : record.fileId,
          visible: true,
        },()=>{
          console.log("visible",this.state.visible)
          if(this.state.resourceType === 4){
            this.setState({
              previewId : this.state.photoId
            })
          }
        })
      };

      getFileUrl() {
        let screenHeight = document.documentElement.clientHeight - 220
        const { mediaType, fileId, resourceName, content, progress,isZhibo,videoUrl,changecurrentTime} = this.state
        console.log("444444",videoUrl)
        if (mediaType === "mp4") {
          this.setState({barrageStatus:true,zhengzaijinxing:0})
          let player = document.getElementById("previewVideo");
          if(player && isZhibo === true){
            player.src = videoUrl;
            player.currentTime =changecurrentTime
            player.addEventListener("timeupdate",(e)=>{
              if (player.currentTime === player.duration){
                console.log("播放完成，可以延时一秒进行操作")
                setTimeout(() => {
                  this.setState({
                    isplay: true
                  })
                },1000)
              }
              this.setState({
                currentTime:this.formatSecond(player.currentTime),
                zhengzaijinxing:player.currentTime,
                zongshijian:player.duration
              })
            })
            player.addEventListener("loadedmetadata",(e)=> {
              console.log("time:::",player.duration);
              this.setState({
                duration: this.formatSecond(parseInt(player.duration))
              },() => {
                console.log("渲染之后：：：",this.state.duration)
              })
            })
            setTimeout(() => {
              const eleVideo = document.getElementById(`previewVideo`);
              setTimeout(() => {
                if (eleVideo) {
                  let htmlCanvasElement = document.createElement('canvas');
                  if (htmlCanvasElement) {
                    htmlCanvasElement.style.width = '100%';
                    htmlCanvasElement.style.height = '100%';
                    htmlCanvasElement.className = 'canvasBarrage';
                    htmlCanvasElement.id = 'canvasBarrage';
                    let video = document.getElementById('li-img');
                    video.appendChild(htmlCanvasElement);
                  }
                  let demoBarrage = new playbackCanvasBarrage(htmlCanvasElement, eleVideo, {
                    data: this.state.dataBarrage
                  });
                  this.setState({
                    canvasBarrage: demoBarrage,
                    eleVideo: eleVideo
                  }, () => {
                    demoBarrage.render();
                  })
                }
              }, 100)
            }, 100)
          }
          // else{
          //   player.src = `${resource_preview}/${fileId}`;
          // }
          /*   player.currentTime = this.state.progress;*/
        }
      }
      time = (value) => {
        let theTime = value;//秒
        let middle = 0;//分
        let hour = 0;//小时
        if (theTime > 59){
          middle = parseInt(theTime / 60);
          theTime = parseInt(theTime % 60);
        }
        if (middle > 59) {
          hour = parseInt(middle / 60);
          middle = parseInt(middle % 60);
        }
        theTime < 10 ? theTime = '0' + theTime : theTime = theTime
        middle < 10 ? middle = '0' + middle : middle = middle
        hour < 10 ? hour = '0' + hour : hour = hour
        return hour + ':' + middle + ':' + theTime
      }
      changeTime = (e) => {
        const vodeo =document.getElementById(`previewVideo`);
        console.log("eeeeee",vodeo.currentTime)
        console.log("eeeeee1111",vodeo.duration)
        const { value } = e.target;
        console.log("eeeeee2222222",value)
        this.setState({
          changecurrentTime: value,
        });
        vodeo.currentTime = value;
        if (value === vodeo.duration) {
          this.setState({
            isPlay: false,
          });
        }
        // this.getFileUrl()
      };
      formatSecond(time) {
        const second = Math.floor(time % 60);
        let minite = Math.floor(time / 60);
        return `${minite}:${second >= 10 ? second : `0${second}`}`;
      }
      playbackTime = () => {
        const eleVideo = document.getElementById(`previewVideo`);

        let {mm, playbackTime, ss, hh} = this.state;
        this.lianMaiTimer = setInterval(() => {
          if (eleVideo.currentTime )
            playbackTime = "";
          if (++ss === 60) {
            if (++mm === 60) {
              if (++hh === 24) {
                hh = 0;
              }
              mm = 0;
            }
            ss = 0;
          }
          playbackTime += hh < 10 ? "0" + hh : hh;
          playbackTime += ":";
          playbackTime += mm < 10 ? "0" + mm : mm;
          playbackTime += ":";
          playbackTime += ss < 10 ? "0" + ss : ss;
          this.setState({playbackTime});
        }, 1000);
      }
      async getLiveResource ()  {
        let param = {taskId : this.state.taskId, userType: 1}
        let liveResource = await selectLiveResource(param);
        console.log("11111111",liveResource)
        this.setState({
          dataSource: liveResource,
        },()=>{
          this.setState({
            liveLoading:false
          })
        })
      }

      async getLiveDetails (id)  {
        console.log('----------',this.state.taskId)
        let taskIdQo = {taskId : this.state.taskId}
        let teacherLiveDetail = await getTeacherLiveDetail(taskIdQo);
        console.log("teacherLiveDetail:::",teacherLiveDetail)
        this.setState({
          liveDetail: teacherLiveDetail,
          taskStatus:teacherLiveDetail.taskStatus,
          dataSources: teacherLiveDetail.liveDetailPlaybackVoList,
          lastProcessTime:teacherLiveDetail.updateTime,
          pauseTime:teacherLiveDetail.suspendTime
        }, () => {
          // this.timeStamp()
          console.log("赋值后：：：：",this.state.lastProcessTime)
          if (this.state.taskStatus == 3 || this.state.taskStatus == 4){
            clearInterval(this.reportTime);
          }
          if (this.state.taskStatus == 2){
            this.setState({
              liveStatusFlag:1
            })
          }
          if (this.state.pauseTime != 0){
            this.setState({
              isPause:1
            });
            this.livePauseTimer()
          }
        })
      }

      cancel = e => {
        this.setState({coverFlag: false,addFlag: false,taskTable: false,Edittitle:false});
      }
      Livebroadcast = (e) => {
        this.setState({
          taskTable: false
        })
      }
      Share = (e) => {
        this.setState({
          coverFlag: true,
        })
      }

      callback = async (key) => {
        await this.setState({activeKey: key})
      };
      // 编辑
      update = (id) => {
        // this.setState({taskTable: true});
        this.props.history.push(`/addlive?${encodeURI(JSON.stringify(
          {id:id}))}`)
      };

      // 进入直播间
      Livelink = () => {
        let {liveDetail} = this.state;
        adminJoinLiveRoom({taskId:liveDetail.id,studentId:this.state.userId}).then(res => {
          if (res){
            this.props.history.push(`/livepageroom?${encodeURI(JSON.stringify({taskId: liveDetail.id,taskName: liveDetail.taskName,isReport:this.state.isReport}))}`);
          }
        })
      };
      setFullType=(flag)=>{
        this.setState({
          full_flag:flag,
          ishover:flag
        },()=>{
          this.child.set_full_flag_child(!flag)
        })
      }
      onRef = (ref) => {
        this.child = ref
      }
      LivePlayback = () => {
        this.setState({
          dataBarrage:[],
          changecurrentTime:0,
          liveVisible:true,
          isplay : true
        },() => {
          var event = new Event('resetBarrage');
          document.dispatchEvent(event);
          // this.props.history.push(`/teacherliveroom`)
          let { dataSources} = this.state
          console.log("1111",dataSources)
          console.log("222222")
          if(document.getElementById("previewIframe")){
            console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewIframe"))
            document.getElementById("previewIframe").innerHTML=""
          }
          if(document.getElementById("previewVideo")){
            console.log(" document.getElementById(\"previewVideo\")", document.getElementById("previewVideo"))
            document.getElementById("previewVideo").innerHTML=""
          }
          let param = {
            taskId:this.state.liveDetail.id,
            videoId: dataSources[0].id,
            videoTime:30
          }
          getChatMsgForDanmu(param).then(res => {
            console.log("聊天记录res:::", res)
            res.map(item => {
              let mes = {
                value: item.chatMessage,
                time: item.videoTime
              };
              this.state.dataBarrage.push(mes);
            });
          });
          this.setState({
            videoUrl:dataSources[0].videoUrl,
            mediaType:"mp4",
            isZhibo:true,
            isonVideo:true,
          },()=>{
            this.getFileUrl()
          })
        });

      };

      playVideo = () => {
        const eleVideo = document.getElementById(`previewVideo`);
        if (eleVideo){
          eleVideo.play();
          this.setState({
            isplay: false
          })
        }else{
          message.info("视频播放出现错误，请刷新重试")
        }
      };
      pauseVideo = () => {
        const eleVideo = document.getElementById(`previewVideo`);
        if (eleVideo){
          eleVideo.pause();
          this.setState({
            isplay: true
          })
        }else{
          message.info("视频暂停出现错误，请刷新重试")
        }
      };
      sliderChange = (value) => {
        const eleVideo = document.getElementById(`previewVideo`);
        eleVideo.volume = value / 100;
        this.setState({sliderVile: value})
      }

      fullScreen = () => {
        let video = document.getElementById('li-img');
        if (video){
          video.webkitRequestFullScreen()
          this.setState({
            accetFullScreen:true
          })
        }
      }
      //关闭全屏
      closeFullScreen=()=>{
        let video = document.getElementById('li-img');
        if (video){
          document.webkitCancelFullScreen();
          this.setState({
            accetFullScreen:false
          })
        }
      }
      closeBarrage = () => {
        //停止弹幕
        let event = new Event('closeBarrage');
        document.dispatchEvent(event);
        this.setState({barrageStatus:false})
      }
      //开启弹幕
      startBarrage = () => {
        let event = new Event('startBarrage');
        document.dispatchEvent(event);
        this.setState({
          isBarrage:1
        })
      }
      closeBarrage = () => {
        //停止弹幕
        let event = new Event('closeBarrage');
        document.dispatchEvent(event);
        this.setState({
          isBarrage:0
        })
      }

      changeReportType = () => {
        frozenAndForceEndLive({taskId:this.state.taskId,lastProcessType:0,isReport:1,reason:'',userId:this.state.userId}).then(e=>{
          message.destroy();
          message.success("操作成功")
          this.props.history.goBack(-1);
        })
      };

      showFreezeModal = (e) => {
        if (e == 3){
          this.setState({
            freezeModalFlag:true,
            reportType:e,
            isPause:1
          })
        }else{
          this.setState({
            freezeModalFlag:true,
            reportType:e
          })
        }
      }


      //举报记录的下一批功能
      nextPageByType = (e) => {
        console.log("下一批");
        if (e === 0){
          let pages = this.state.pagesByType0;
          let nextPage = this.state.pageNumByType0 + 1;
          if (nextPage <= pages){
            this.getReport(e,nextPage);
          }else if (nextPage != 2){
            //回到第一页
            this.setState({
              pageNumByType0:1
            },()=> {
              this.getReport(e,this.state.pageNumByType0);
            })

          }
        }else if (e === 1){
          console.log("??????")
          let pages = this.state.pagesByType1;
          let nextPage = this.state.pageNumByType1 + 1;
          console.log("??????",pages,nextPage)
          if (nextPage <= pages){
            this.getReport(e,nextPage);
          }else if (nextPage != 2){
            //回到第一页
            this.setState({
              pageNumByType1:1
            },()=> {
              this.getReport(e,this.state.pageNumByType1);
            })
          }
        }else if (e === 2){
          let pages = this.state.pagesByType2;
          let nextPage = this.state.pageNumByType2 + 1;
          if (nextPage <= pages){
            this.getReport(e,nextPage);
          }
          else if (nextPage != 2){
            //回到第一页
            this.setState({
              pageNumByType2:1
            },()=> {
              this.getReport(e,this.state.pageNumByType2);
            })
          }
        }else if (e === 3){
          let pages = this.state.pagesByType3;
          let nextPage = this.state.pageNumByType3 + 1;
          if (nextPage <= pages){
            this.getReport(e,nextPage);
          }
          else if (nextPage != 2){
            //回到第一页
            this.setState({
              pageNumByType3:1
            },()=> {
              this.getReport(e,this.state.pageNumByType3);
            })
          }
        }else if (e === 4){
          let pages = this.state.pagesByType4;
          let nextPage = this.state.pageNumByType4 + 1;
          if (nextPage <= pages){
            this.getReport(e,nextPage);
          }
          else if (nextPage != 2){
            //回到第一页
            this.setState({
              pageNumByType4:1
            },()=> {
              this.getReport(e,this.state.pageNumByType4);
            })
          }
        }else if (e === 5){
          let pages = this.state.pagesByType5;
          let nextPage = this.state.pageNumByType5 + 1;
          if (nextPage <= pages){
            this.getReport(e,nextPage);
          }
          else if (nextPage != 2){
            //回到第一页
            this.setState({
              pageNumByType5:1
            },()=> {
              this.getReport(e,this.state.pageNumByType5);
            })
          }
        }else if (e === 6){
          let pages = this.state.pagesByType6;
          let nextPage = this.state.pageNumByType6 + 1;
          if (nextPage <= pages){
            this.getReport(e,nextPage);
          }
          else if (nextPage != 2){
            //回到第一页
            this.setState({
              pageNumByType6:1
            },()=> {
              this.getReport(e,this.state.pageNumByType6);
            })
          }
        }

      };

      //导出聊天记录
      exportChatMsg = (id) =>{
        let taskId = id;
        let chatType = 0;
        window.location.href = `${baseURL}/api/zhibo/api/v1/live/chat/exportChatMessage?taskId=${taskId}&chatType=${chatType}`;
        // window.location.href = 'http://192.168.1.117:18600/api/interaction/api/v1/live/chat/exportChatMessage?taskId=' + taskId + '&chatType=' + chatType;
      }


      //根据不同类型渲染页面（举报记录）
      report = (e) => {
        let data = [];
        let total = 0;
        let type = '';
        if (e == 0){
         data = this.state.reportsByType0;
         total = this.state.totalByType0;
         type = '色情';
        }else if (e == 1){
          data = this.state.reportsByType1;
          total = this.state.totalByType1;
          type = '骚扰';
        }else if (e == 2){
          data = this.state.reportsByType2;
          total = this.state.totalByType2;
          type = '反动言论';
        }else if (e == 3){
          data = this.state.reportsByType3;
          total = this.state.totalByType3;
          type = '暴力';
        }else if (e == 4){
          data = this.state.reportsByType4;
          total = this.state.totalByType4;
          type = '广告或垃圾信息';
        }else if (e == 5){
          data = this.state.reportsByType5;
          total = this.state.totalByType5;
          type = '抄袭或未授权';
        }else if (e == 6){
          data = this.state.reportsByType6;
          total = this.state.totalByType6;
          type = '其他';
        }
        if (data.length != 0){
          return (
              <li>
                <div className="left">
                  <h4>举报类型：{type}</h4>
                  <h2>/</h2>
                  <img src={require('@/assets/img/images/person-icon.png')}/>
                  <h3>{total}人举报</h3>
                </div>
                <div className="right">
                  <Button onClick={() => this.nextPageByType(1)}><i></i><span>下一批</span></Button>
                </div>
                {data?.map((item,index) => {
                  // console.log("item::::",item);
                  let imgList =[]
                  item.tipOffFlListQos.map((img)=>{
                    imgList.push(img.tipOffImageId)
                  })
                  return (
                      <div className="bottom-one">
                        <span>
                          {
                            item.tipOffUserFileId?
                                // <img src={`/api/zhibo/api/v1/live/resourceMedia/showThumbnail/${item.tipOffUserFileId}`}/>
                                <RenderImg size='m' id={item.tipOffUserFileId}/>
                                :<img src={window.$$uploadRc('gr.png')}/>
                          }
                        </span>
                        <span className="text">
                          <h1>{item.tipOffUserName}</h1>
                          <h3>{item.createTime}</h3>
                          <h2>{item.tipOffDescribe}</h2>
                        </span>
                        <span className="bottom-img">
                          {
                            imgList.map((src,index) => {
                              return (
                                  // <img src={`/api/zhibo/api/v1/live/resourceMedia/showThumbnail/${src}`}/>
                                  <RenderImg size='m' id={src}/>
                                  )
                            })
                          }
                        </span>
                      </div>
                  )
                })}
              </li>
          )
        }else{
          return;
        }
      }

      reportHtml = () => {
        if (this.state.reportsByType0.length || this.state.reportsByType1.length || this.state.reportsByType2.length || this.state.reportsByType3.length || this.state.reportsByType4.length || this.state.reportsByType5.length || this.state.reportsByType6.length) {
          return (
              <>
                {this.report(0)}
                {this.report(1)}
                {this.report(2)}
                {this.report(3)}
                {this.report(4)}
                {this.report(5)}
                {this.report(6)}
              </>
          )
        } else {
          return (
              <div className="Empty-conet">
                <div className="img">
                  <img src={window.$$setImgSrc("zhibo_list_Empty.jpg")}/>
                  <span>暂无举报记录</span>
                </div>
              </div>
          );
        }
      }
      viewCancel = () => {
        console.log("???????")
        this.setState({ coverFlagView:false});
      }


      Viewrecords = () => {
        this.setState({ coverFlagView:true});
      }

      goback = () => {
        this.props.history.push("/main/livebroadcast/adminlive")
      };

      //根据任务状态的不同渲染不同的按钮
      returnButton = () => {
        //任务状态(0禁止开播，1未开始，2直播中，3正常结束，4强制结束，5已冻结)
        let {taskStatus,liveStatusFlag} = this.state;
        if (taskStatus == 0){
          return(
              <div className="main-bottom">
                <Button type="primary" onClick={() => this.changeReportType()}><i>没有问题</i></Button>
                <Button onClick={() => this.showFreezeModal(2)}>禁止开播</Button>
                <Button onClick={() => this.showFreezeModal(1)}>冻结此次直播</Button>
                {/*<Button onClick={()=>this.Viewrecords()}>查看记录</Button>*/}
                {/*<Button onClick={() => this.exportChatMsg(this.state.taskId)}>导出聊天记录</Button>*/}
                <Modal title="处理记录" className='Modal-processing' visible={this.state.coverFlagView} destroyOnClose={true} maskClosable={false}
                       onCancel={() => this.viewCancel()}
                       centered={true}
                       footer={
                         <div className='pos-btn'>
                           <Button className="but" onClick={() => this.viewCancel()}>关闭</Button>
                           <Button className="but" type="primary" style={{display:"none"}} onClick={this.sureChangeCover}>提交</Button>
                         </div>
                       }
                >
                  <Processing taskId={this.state.taskId}/>
                </Modal>
                <Modal title="冻结直播" className='Modal-Banned dongjiezbmodal' width={660} visible={this.state.freezeModalFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       closable={false}
                       centered={true}
                       footer={
                         <div className='pos-btn' style={{display:"none"}}>
                           <Button className="but" onClick={this.cancel}>取消</Button>
                           <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                         </div>
                       }
                >
                  <Banned reportType={this.state.reportType} taskId={this.state.taskId} getList={
                    () => {
                      this.getLiveDetails();
                      this.getLiveResource();
                      this.getReports();
                      this.getReportCount();
                      this.reportCount();
                    }
                  } isReport={this.state.isReport}
                          cancel={()=>{this.setState({freezeModalFlag:false })}}/>
                </Modal>
              </div>
          )
        }else if (taskStatus == 1){
          return(
              <div className="main-bottom">
                <Button type="primary" onClick={() => this.changeReportType()}><i>没有问题</i></Button>
                {/*<Button onClick={() => this.showFreezeModal(2)}>禁止开播</Button>*/}
                {liveStatusFlag == 0 ?(this.state.isPause === 0 ?
                    <Button className="but" onClick={() => this.Banned(3)}>暂停直播</Button>:
                    <Button className="but" onClick={() => this.resumeLive()}>恢复直播</Button>):
                    (this.state.isPause === 0 ?
                        <Button className="but" disabled>暂停直播</Button>:
                        <Button className="but" disabled>恢复直播</Button>)}
                <Button onClick={() => this.showFreezeModal(4)}>强制结束直播</Button>
                <Button onClick={() => this.showFreezeModal(1)}>冻结此次直播</Button>
                <Modal title="冻结直播" className='Modal-Banned dongjiezbmodal' width={660} visible={this.state.freezeModalFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       closable={false}
                       centered={true}
                       footer={
                         <div className='pos-btn' style={{display:"none"}}>
                           <Button className="but" onClick={this.cancel}>取消</Button>
                           <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                         </div>
                       }
                >
                  <Banned reportType={this.state.reportType} taskId={this.state.taskId} getList={
                    () => {
                      this.getLiveDetails();
                      this.getLiveResource();
                      this.getReports();
                      this.getReportCount();
                      this.reportCount();
                    }
                  } isReport={this.state.isReport}
                          cancel={()=>{this.setState({freezeModalFlag:false })}}/>
                </Modal>
              {/*  暂停直播*/}
                <Modal title={this.returnMessage()} className='Modal-Banned dongjiezbmodal' width={660} visible={this.state.banFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={() => this.banCancel() }
                       closable={false}
                       centered={true}
                       footer={
                         <div className='pos-btn' style={{display:"none"}}>
                           <Button className="but" onClick={() => this.banCancel()}>取消</Button>
                           <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                         </div>
                       }
                >
                  <Banned reportType={this.state.reportType} taskId={this.state.taskId} getList={()=>{
                    if (this.state.reportType == 3){
                      this.setState({
                        pauseTime:600
                      },() => {
                        this.livePauseTimer();
                      })
                    }else{
                      this.props.history.goBack(-1);
                    }
                  }} isReport={this.state.isReport}  page="room"
                          pauseCancel={this.pauseCancel}
                          cancel={()=>{if (this.state.reportType == 3 && this.state.isPause == 1){
                            this.setState({banFlag:false,isPause:0})
                          }else{
                            this.setState({banFlag:false})
                          }}}/>
                </Modal>
              </div>
          )
        }else if (taskStatus == 2){
          return(
              <div className="main-bottom">
                <Button type="primary" onClick={() => this.changeReportType()}><i>没有问题</i></Button>
                {/*<Button onClick={() => this.showFreezeModal(4)}>强制结束</Button>*/}
                {/*{this.state.isPause == 0 ?*/}
                {/*    <Button className="but" onClick={() => this.showFreezeModal(3)}>暂停直播</Button>*/}
                {/*    :*/}
                {/*    <Button className="but" onClick={() => this.resumeLive()}>恢复直播</Button>*/}
                {/*}*/}
                <Button onClick={() => this.showFreezeModal(1)}>冻结此次直播</Button>
                <Button onClick={() => this.exportChatMsg(this.state.taskId)}>导出聊天记录</Button>
                <Modal title="冻结直播" className='Modal-Banned' visible={this.state.freezeModalFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       closable={false}
                       centered={true}
                       footer={
                         <div className='pos-btn' style={{display:"none"}}>
                           <Button className="but" onClick={this.cancel}>取消</Button>
                           <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                         </div>
                       }
                >
                  <Banned reportType={this.state.reportType} taskId={this.state.taskId}getList={
                    () => {
                      this.getLiveDetails();
                      this.getLiveResource();
                      this.getReports();
                      this.getReportCount();
                      this.reportCount();
                    }
                  }
                          isReport={this.state.isReport}
                          cancel={()=>{this.setState({freezeModalFlag:false,isPause:0})}}/>
                </Modal>
              </div>
          )
        }else if (taskStatus == 3){
          return(
              <div className="main-bottom">
                <Button type="primary" onClick={() => this.changeReportType()}><i>没有问题</i></Button>
                <Button onClick={() => this.showFreezeModal(1)}>冻结直播</Button>
                <Button onClick={() => this.exportChatMsg(this.state.taskId)}>导出聊天记录</Button>
                <Modal title="冻结直播" className='Modal-Banned dongjiezbmodal' width={660} visible={this.state.freezeModalFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       closable={false}
                       centered={true}
                       footer={
                         <div className='pos-btn' style={{display:"none"}}>
                           <Button className="but" onClick={this.cancel}>取消</Button>
                           <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                         </div>
                       }
                >
                  <Banned reportType={this.state.reportType} taskId={this.state.taskId}getList={
                    () => {
                      this.getLiveDetails();
                      this.getLiveResource();
                      this.getReports();
                      this.getReportCount();
                      this.reportCount();
                    }
                  } isReport={this.state.isReport}
                          cancel={()=>{this.setState({freezeModalFlag:false })}}/>
                </Modal>
              </div>
          )
        }else if (taskStatus == 4){
          return(
              <div className="main-bottom">
                <Button type="primary" onClick={() => this.changeReportType()}><i>没有问题</i></Button>
                <Button onClick={() => this.showFreezeModal(1)}>冻结直播</Button>
                <Button onClick={() => this.exportChatMsg(this.state.taskId)}>导出聊天记录</Button>
                <Modal title="冻结直播" className='Modal-Banned dongjiezbmodal' width={660} visible={this.state.freezeModalFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       closable={false}
                       centered={true}
                       footer={
                         <div className='pos-btn' style={{display:"none"}}>
                           <Button className="but" onClick={this.cancel}>取消</Button>
                           <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                         </div>
                       }
                >
                  <Banned reportType={this.state.reportType} taskId={this.state.taskId}getList={
                    () => {
                      this.getLiveDetails();
                      this.getLiveResource();
                      this.getReports();
                      this.getReportCount();
                      this.reportCount();
                    }
                  } isReport={this.state.isReport}
                          cancel={()=>{this.setState({freezeModalFlag:false })}}/>
                </Modal>
              </div>
          )
        }else{
          return(
              <div className="main-bottom">
                <Button type="primary" onClick={() => this.changeReportType()}><i>没有问题</i></Button>
                <Button onClick={()=>this.Viewrecords()}>查看记录</Button>
                <Button onClick={() => this.exportChatMsg(this.state.taskId)}>导出聊天记录</Button>
                <Modal title="处理记录" className='Modal-processing' visible={this.state.coverFlagView} destroyOnClose={true} maskClosable={false}
                       onCancel={() => this.viewCancel()}
                       centered={true}
                       footer={
                         <div className='pos-btn'>
                           <Button className="but" onClick={() => this.viewCancel()}>关闭</Button>
                           <Button className="but" type="primary" style={{display:"none"}} onClick={this.sureChangeCover}>提交</Button>
                         </div>
                       }
                >
                  <Processing taskId={this.state.taskId}/>
                </Modal>
              </div>
          )
        }
      };


      resumeLive = () => {
        let param = {
          taskId:this.state.taskId,
          createUserId:this.state.userId
        };
        continueLive(param).then(res => {
          if (res){
            this.setState({
              isPause:0
            })
          }
        })
      };

      returnProcessResult = () => {
        let e = this.state.taskStatus;
        console.log("开播",this.state.taskStatus)
        //任务状态(0禁止开播，1未开始，2直播中，3正常结束，4强制结束，5已冻结)
        if (e == 4){
          return "禁止开播"
        }else if (e == 3){
          return "强制结束"
        }else if (e == 5){
          return "冻结直播"
        }
      };
//暂停直播
      Banned = (e) =>{
        console.log("暂停直播：：：",e);
        if (e == 3){
          console.log("暂停直播：：：123123",e);
          this.setState({ banFlag:true,reportType:e,taskId:this.state.taskId,isPause:1});
        }else{
          this.setState({ banFlag:true,reportType:e,taskId:this.state.taskId});
        }
      };
      pauseLive = () => {
        let param = {
          taskId:this.state.taskId,
          createUserId:this.state.userId
        };
        pauseLive(param).then(res => {
          console.log(res)
          if (res){
            this.setState({
              pauseTime:600,
              pauseFlag:1,
              isPause:1
            },() => {
              console.log(this.state.isPause)
              this.livePauseTimer()
            })
          }
        })
      };
      livePauseTimer = () => {
        this.suspendTimer = setInterval(() => {
          let pauseTime = this.state.pauseTime - 1;
          this.setState({pauseTime:pauseTime},()=>{
            if(this.state.pauseTime === 0){
              clearInterval(this.suspendTimer);
              message.destroy();
              message.info("直播任务已恢复");
              this.resumeLive()
            }
          });
        }, 1000);
      }
      returnMessage = () =>{
        const {reportType} = this.state;
        if(reportType===3){
          return "暂停开播"
        }else if(reportType===2){
          return "禁止开播"
        }else if(reportType===1){
          return "冻结直播"
        }else if(reportType===4){
          return "强制结束"
        }
      };
      //取消暂停直播
      banCancel = () => {
        clearInterval(this.suspendTimer);
        this.setState({ banFlag:false,isPause:0,pauseTime:0});
      };
      pauseCancel = () => {
        clearInterval(this.suspendTimer);
        this.setState({ banFlag:false,pauseTime:0});
      };
      //恢复直播
      resumeLive = () => {
        let param = {
          taskId:this.state.taskId,
          createUserId:this.state.userId
        };
        continueLive(param).then(res => {
          if (res){
            this.setState({
              isPause:0,
              pauseTime:0
            })
          }
        })
      };
      render() {
        const {beforePage,changecurrentTime,isdownVideo,isonVideo,isZhibo,isplay,timeStamp, columns,disabled,barrageStatus, coverFlag,taskTable, dataSource, data, dataSources, liveDetail,addFlag,activeKey,studentNum,startTime,resourceId,mediaType,fileId,Edittitle,liveLoading} = this.state
        const {full_flag,videoUrl,resourceSuffix,transFileSuffix,resourceType, resourceContent,resourceName,liveVisible} = this.state
        console.log("isZhibo",isZhibo)
        let screenHeight = document.documentElement.clientHeight - 80
        let endtime = new Date(liveDetail.estimateStartTime).getTime()
        // let newDate = new Date().getTime()
        let Livedata = <div className="Chat-right">
          <div className="bottom-table">
            <Table className="main-box-header table-maina" cellspacing={20} columns={columns}
                   dataSource={dataSource} pagination={false} rowKey={row => row.id}
                   components={this.components}
                   onRow={(record, index) => ({
                     index,
                     moveRow: this.moveRow,
                   })}
                   locale={{emptyText:<><div className="Empty-conet">
                       <div className="img">
                         <img src={window.$$setImgSrc("zhibo_list_Empty.jpg")}/>
                         <span>暂无资料</span>
                       </div>
                     </div></>}}
            />
          </div>
        </div>
        let Liveplayback = <div className="Chat-right">
          <div className="bottom-table bottom-tablea">
            <Table className="main-box-header table-maina" cellspacing={20} columns={data}
                   dataSource={dataSources} pagination={false} rowKey={row => row.id}
                   components={this.components}
                   onRow={(record, index) => ({
                     index,
                     moveRow: this.moveRow,
                   })}
                   locale={{emptyText:<><div className="Empty-conet">
                       <div className="img">
                         <img src={window.$$setImgSrc("zhibo_list_Empty.jpg")}/>
                         <span>暂无直播回放</span>
                       </div>
                     </div></>}}
            />
          </div>
        </div>
        return (
          <div className="studentlivepage_content">
            <div className="header bg_fff">
              <div className="back" onClick={()=>this.goback()}>
                <img alt="" src={require("@/assets/img/grounding/back.png")} className="back-icon"/>
                返回
              </div>
              <div className='title'>
                <h2>{liveDetail.taskName}</h2>
              </div>
            </div>
            <div className="resource_center_wrap studentlivepage_listing">
              <div className="content_wrap">
                <div className="right right-content">
                  <div className="live-contpage-techinga">
                    {liveLoading==1?<Spin className="Spin-content" style={{minHeight:"300px"}} tip="加载中"/>:<ul className="ul-top">
                      <li className="li1" style={{width:full_flag?'70%':'100%'}}>
                        <div className="li-img">
                          {liveDetail.taskStatus === 2?<div className="mask"></div>:""}
                          {liveDetail.taskStatus === 2?<div className="live-end-button">直播已结束</div>:""}
                          {
                            liveDetail.taskImg?<span className="left-img"><RenderImg size='m' id={liveDetail.taskImg} type={liveDetail.mediaType}/> </span>:""
                          }
                        </div>
                        <div className="top-time">
                          <div className="left-text">
                            {
                              liveDetail.portraitId?<span className="left-img"><img src={window.$$uploadRc(liveDetail.portraitId)}/> </span>:
                                  <span className="left-img"><img src={require("../../../../assets/img/images/js-icon.png")}/> </span>
                            }
                            <div className="right-te">
                              {liveDetail.taskName?
                                  <div className="title">
                                    {liveDetail.openType == 1?<span>平台公开</span>: liveDetail.openType == 2?<span>校内公开</span>:<span>限定范围公开</span>}
                                    <h2>{liveDetail.taskName}</h2>
                                  </div>:""}
                              <p>
                                {liveDetail.liveLecturer?<span>讲师：<span>{liveDetail.liveLecturer}</span></span>:""}
                                {liveDetail.taskStatus === 0 ? <span>报名人数：<span>{liveDetail.studentNum}</span></span>:""}
                                {liveDetail.taskStatus === 1 ? <span>签到人数：<span>{liveDetail.signInNum}</span></span>:""}
                                {liveDetail.taskStatus === 1 ? <span>开始时间：<span>{liveDetail.liveStartTime}</span></span>:""}
                                {liveDetail.taskStatus === 2 || liveDetail.taskStatus === 3 ? <span>学生人数：<span>{liveDetail.comeTrueNum}</span></span>:""}
                                {liveDetail.taskStatus === 2 || liveDetail.taskStatus === 3 ? <span>开始时间：<span>{moment(liveDetail.liveStartTime).format("YYYY-MM-DD HH:mm")}</span></span>: ""}
                                {liveDetail.taskStatus === 2 || liveDetail.taskStatus === 3 ? <span>结束时间：<span>{moment(liveDetail.liveEndTime).format("YYYY-MM-DD HH:mm")}</span></span>: ""}
                                {/*{liveDetail.taskStatus === 2 || liveDetail.taskStatus === 3 ? <span>直播时长：<span>{liveDetail.estimateDurationToString}</span></span>:""}*/}
                                {liveDetail.taskStatus === 0 || liveDetail.taskStatus === 4 ? <span>预计开始时间：<span>{moment(liveDetail.estimateStartTime).format("YYYY-MM-DD HH:mm")}</span></span>:""}
                                {liveDetail.taskStatus === 0 || liveDetail.taskStatus === 4 ? <span>预计直播时长：<span>{liveDetail.estimatedDurationHour}小时{liveDetail.estimatedDurationMinute}分钟</span></span>:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="li2" style={{width:full_flag?'28%':0,overflow:full_flag?"hidden":'auto'}}>
                        {/*taskStatus 1未开始 2直播中 3正常结束 4异常结束*/}
                        {/*enrollStaus 0未报名 1已报名 2直播间已满 3禁止进入*/}
                        {/*newestVideoStatus  0暂无回放 1转码中 2 转码成功*/}
                        {liveDetail.taskStatus === 0 ? (
                            <div className="top-live">
                              <h4><span className="span">距离开播仅剩</span></h4>
                              <span className="spana">
                                      <Countdown value={endtime} format="D 天 H 时 m 分 s 秒" />
                                     </span>
                              <div className="but-antd">
                                <Button className="ant-btn1" type="primary" onClick={this.Livelink}>隐身进入直播间</Button>
                              </div>
                            </div>
                        ):liveDetail.taskStatus === 2 ? (
                            liveDetail.isHavePlayback === 0 ? (
                                <div className="top-live">
                                  <h4><span className="span">直播状态</span></h4>
                                  <span className="spana">直播已结束</span>
                                  <div className="but-antd on1">
                                    <Button className="ant-btn1" type="primary" disabled>查看回放</Button>
                                  </div>
                                </div>
                            ) : liveDetail.playbackStatus === 1 ? (
                                <div className="top-live active">
                                  <h4><span className="span">视频转码中……</span></h4>
                                  <span className="spana">直播已结束</span>
                                  <div className="but-antd active1">
                                    <Button className="ant-btn1" type="primary" disabled>查看回放</Button>
                                  </div>
                                </div>
                            ) : liveDetail.playbackStatus === 2 ||  liveDetail.taskStatus === 3? (
                                <div className="top-live">
                                  <h4><span className="span">直播状态</span></h4>
                                  <span className="spana">直播已结束可查看回放</span>
                                  <div className="but-antd">
                                    <Button className="ant-btn1" type="primary" onClick={this.LivePlayback}>查看回放</Button>
                                  </div>
                                </div>
                            ) : ""
                        ) :liveDetail.taskStatus === 1 ? (
                            <div className="top-live">
                              <h4><span className="span">直播状态</span></h4>
                              <span className="spana">
                              <i className="i">直播进行中...</i>
                            </span>
                              <div className="but-antd">
                                <Button className="ant-btn1" type="primary" onClick={this.Livelink}>隐身进入直播间</Button>
                              </div>
                            </div>
                        ):liveDetail.taskStatus === 3 || liveDetail.taskStatus === 5 || liveDetail.taskStatus === 4 ? (
                            <div className="top-live">
                              <h4><span className="span">直播状态</span></h4>
                              <span className="spana-on">
                                  <span>
                                    <i>该直播内容不规范，已于{this.state.lastProcessTime}处理</i>
                                    <em>处理结果：<b>{this.returnProcessResult()}</b></em>
                                  </span>
                              </span>
                            </div>
                        ) :''
                        }
                        <div className="bottom-live">
                          <h4><span className="span">直播简介</span></h4>
                          <div className="p">
                            {liveDetail.taskDesc}
                          </div>
                        </div>
                      </li>
                    </ul>}
                    {beforePage===0||beforePage==2
                        ?<div className="ul-bottom">
                          <div className="bottom-list">
                            <Tabs activeKey={activeKey} onChange={this.callback.bind(this)}>
                              <TabPane tab="直播备课包" key="1">
                              </TabPane>
                              {
                                liveDetail.taskStatus === 0||liveDetail.taskStatus === 1?"":<TabPane tab="直播回放" key="2">
                                </TabPane>
                              }

                            </Tabs>
                            {
                              activeKey === "1" ?liveLoading==1?<Spin className="Spin-content" tip="加载中"/>: Livedata : (activeKey === "2" ? Liveplayback : "")
                            }
                          </div>
                        </div>
                        :""
                    }
                  </div>
                </div>
              </div>
              {beforePage===1||beforePage==2
                  ?<div className="content-bottom">
                    <div className="content-bottom-warp">
                      <div className="top-title">
                        <img src={require('@/assets/img/images/icon-red.png')}/>
                        <span>您查看直播任务详情的时候正有<i>{this.state.reportCount}条该直播的举报</i>上传,其中色情与反动言论的举报{this.state.importentCount}条举报记录</span>
                        {this.state.taskStatus == 3 || this.state.taskStatus == 4 || this.state.taskStatus == 5 ? null : <p>{this.state.countdown}s后自动刷新</p>}
                      </div>
                      <div className="bottom">
                        <em>举报记录</em>
                        <img src={require('@/assets/img/images/circle.png')}/>
                        <span className="refreshReport" onClick={() => this.getReports()}>刷新举报记录</span>
                        {/*<span1>刷新举报记录</span1>*/}
                      </div>
                      <div className="ul-bottom">
                        <ul className="ul-title">
                          {this.reportHtml()}
                        </ul>
                      </div>
                    </div>

                  </div>
                  :""
              }
              <Drawer
                  className='drawer_wapper'
                  title={
                    <div className='detileHader'>
                      <div onClick={this.onClose} className='return_btn'><img
                          src={require('@/assets/img/zixun/return.png')}></img>返回
                      </div>
                      <div>{this.state.resourceName}</div>
                    </div>
                  }
                  placement="right"
                  closable={false}
                  onClose={this.onClose}
                  visible={this.state.visible}
                  width={'100%'}
                  destroyOnClose
              >
                <PreviewLiveInfo type={this.state.resourceType} name={this.state.resourceName} id={this.state.previewId}/>
              </Drawer>

              {/*  直播回放*/}
              <Drawer
                  className='drawer_wapper'
                  title={
                    <div className='detileHader'>
                      <div onClick={()=>this.liveOnClose()} className='return_btn'><img
                          src={require('@/assets/img/zixun/return.png')}></img>返回
                      </div>
                      <div>{this.state.resourceName}</div>
                    </div>
                  }
                  placement="right"
                  closable={false}
                  onClose={()=>this.liveOnClose()}
                  visible={this.state.liveVisible}
                  width={'100%'}
                  destroyOnClose
              >
                <div className="li-img li-video" id="li-img">
                  {mediaType === "mp4" ? (
                          <div className="video-kj">
                            <video
                                style={{ width: "100%" }}
                                // height={screenHeight-40}
                                id="previewVideo"
                                src={this.state.videoUrl}
                                // controls="controls"
                                controlsList="nodownload"
                            ></video>
                            {/*<span className="bf-antd"><img src={require("../../assets/img/images/bf-icon.png")}/></span>*/}
                            <div className="video-bottom">
                              <div className="video-left">
                                <div className="sy-s">
                                  {/*{*/}
                                  {/*  isonVideo === true?<span className="icon icon-on"><img src={require('@/assets/img/images/icon-52.png')}/></span>:*/}
                                  {/*      <span className="icon"><img src={require('@/assets/img/images/icon-49.png')} onClick={() => this.onVideo()}/></span>*/}
                                  {/*}*/}
                                  <span className="icon-center" >
                                                {
                                                  isplay === true?<img src={require('@/assets/img/images/icon-50.png')} onClick={() => this.playVideo()}/>:
                                                      <img src={require('@/assets/img/images/icon-51.png')} onClick={() => this.pauseVideo()}/>
                                                }
                                               </span>
                                  {/*{*/}
                                  {/*  isdownVideo === true?<span className="icon icon-ona"><img src={require('@/assets/img/images/icon-52.png')} /></span>:*/}
                                  {/*      <span className="icon icon-active"><img src={require('@/assets/img/images/icon-49.png')}  onClick={() => this.downVideo()}/></span>*/}
                                  {/*}*/}
                                </div>
                                <span className="time">
                                              {/*{this.state.playbackTime} / {this.state.duration}*/}
                                  <input
                                      type="range"
                                      step="0.01"
                                      className="inp-jd"
                                      max={this.state.zongshijian}
                                      value={this.state.zhengzaijinxing}
                                      onChange={this.changeTime}
                                  />
                                  {this.state.currentTime} / {this.state.duration}
                                            </span>
                              </div>
                              <div className="video-right">
                                              <span className="icon1">
                                                <img src={require('@/assets/img/images/icon-53.png')}/>
                                                <Slider defaultValue={30}
                                                        value={this.state.sliderVile}
                                                        onChange={(value) => this.sliderChange(value)}/>
                                              </span>
                                {this.state.isBarrage === 1 ? <li onClick={this.closeBarrage}><img
                                        src={require('@/assets/img/images/icon-54.png')}/>关闭弹幕
                                    </li> :
                                    <li onClick={this.startBarrage}><img
                                        src={require('@/assets/img/images/icon-54.png')}/>开启弹幕
                                    </li>}
                                {this.state.accetFullScreen?<li onClick={this.closeFullScreen}><img
                                        className="exeit-full-screen"
                                        src={require('@/assets/img/images/icon-small.png')}/>关闭全屏
                                    </li>:
                                    <li onClick={this.fullScreen}><img
                                        src={require('@/assets/img/images/icon-56.png')}/>开启全屏
                                    </li>}
                              </div>
                            </div>
                          </div>
                      ):
                      <LivePreview startTime={0} pageSize={1} fileId={fileId} resourceType={resourceType} resourceContent={resourceContent}
                                   resourceSuffix={resourceSuffix} transFileSuffix={transFileSuffix} resourceName={resourceName} setFullType={this.setFullType}
                                   onRef={this.onRef}/>
                  }
                </div>
              </Drawer>
              {
                this.returnButton()
              }

            </div>
            {/*<div className="resource_footer">*/}
            {/*  <Footer />*/}
            {/*</div>*/}
          </div>
        );
      }
    }
  )
);
