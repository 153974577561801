import {post} from '../../until/axios_instans'
//上架列表
export const getOnShel=(data)=>post(window.apiPrefix + '/api/v1/rcenter/manage/shelf/getOnShel', data);
//下架列表
export const getOffShel=(data)=>post(window.apiPrefix + '/api/v1/rcenter/manage/shelf/getOffShel', data);
// 上下架资源查看
export const getOne=(data)=>post(window.apiPrefix + '/api/v1/rcenter/manage/shelf/getOne', data);
// 资源上下架操作(含批量)
export const resourceShelfOperate=(data)=>post(window.apiPrefix + '/api/v1/rcenter/manage/shelf/resourceShelfOperate', data);
// 机构批量共享资源
export const shareResource=(data)=>post(window.apiPrefix + '/api/v1/rcenter/manage/resource/shareResource', data);
// 资源类型
export const pressType=()=>post(window.apiPrefix + '/api/v1/rcenter/common/resourceType/pressType');

//筛选条件，热门方向列表
export const getSubjectList=(data)=> post(window.apiPrefix + "/api/v1/rcenter/common/subject/getSubjectList", data);

//筛选条件，院校专题列表
export const getSpecialList=(data)=> post(window.apiPrefix + "/api/v1/rcenter/common/special/getSpecialList", data);

//下架原因列表
export const getShelvesReason=(data)=> post(window.apiPrefix + "/api/v1/rcenter/manage/shelf/getShelvesReason", data);

//筛选条件，基础专业列表
export const getBaseSchoolMajorList=(data)=> post(window.apiPrefix + "/api/v1/rcenter/common/examType/baseSchoolMajor", data);

//上下架详情
export const getResourceDetail =(data)=> post(window.apiPrefix + "/api/v1/rcenter/manage/shelf/getOne", data);
