import React, { Component } from 'react'

export default class Hard_facility extends Component {
    render() {
        return (
            <div>
                hard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facilityhard_facility
            </div>
        )
    }
}
