// 应用接入
import React from 'react';
import {Form, Input, Button, Radio, message, DatePicker, Tag} from 'antd';
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
};
// 我应用接入定义数据
const { CheckableTag } = Tag;
class AddLable extends React.Component {
    state = {
    };

    async componentWillMount() {
    }
    // 我的标签--选择
    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        this.setState({ selectedTags: nextSelectedTags });
        this.props.selectedTags(nextSelectedTags);
    }
    setLen = val => {
        this.setState(val);
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {applicationList, selectedTags} = this.state;
        return (
            <Form {...formItemLayout} style={{marginTop: '20px'}}>
                <Form.Item label='标签名称'>
                    {
                        getFieldDecorator('shorterForm', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '标签名称不能为空'
                                }
                            ]
                        })(
                            <Input
                                placeholder='10个汉字+20个英文字母或数字'
                                onChange={e => this.setLen({ yxjc: e.target.value.length })}
                                maxLength={10} />
                        )
                    }
                </Form.Item>
            </Form>
        );
    }
}

AddLable = Form.create()(AddLable);
export default AddLable;
