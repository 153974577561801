import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Checkbox, Form, Input} from 'antd'
import {Link, withRouter} from 'react-router-dom'
import {LOGIN_SUBMITFN} from '../../store/OBS/login/action'
import {login,getModulusExponent } from '../../api/login';
import {setToken} from '@/until/token.js'
import {getMenus, getRoles} from '@/store/OBS/main/action.js';
import './index.scss'
import { RSAUtils } from '../../until/rsautil'

const FormItem = Form.Item
class Login extends Component {
    componentDidMount() {
        localStorage.clear()
        console.log(this.props)

        localStorage.clear()
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                //调后端接口获取加密方式
                getModulusExponent().then(res => {
                    let { modulus, exponent } = res;
                    let key = RSAUtils.getKeyPair(exponent, '', modulus);
                    const telNum = values.userName;
                    const password = RSAUtils.encryptedString(key, values.password);
                    return { telNum, password, publicKey:modulus }
                }).then(data => {
                    login(data).then(res => {
                        if (res) {
                            setToken(res.token);
                            localStorage.setItem('userInfo', JSON.stringify(res));
                            this.props.getMenus();
                            this.props.getRoles();
                        }
                    })
                })


            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div className="obs_login_wrap">
                <div className="login_box">
                    <div className="left_login_box">
                        <div className="cont_pic">
                            <h2 className="logo"><img src={window.$$setImgSrc(window.$$logo)} alt=""/></h2>
                            <span className="img"><img src={require('../../assets/img/images/logo_bg.png')} alt=""/></span>
                        </div>
                    </div>
                    <div className="right_login_box">
                        <div className="login_c">
                            <div className="title">
                                欢迎登录
                            </div>
                            <span className="sub">Login please</span>
                            <div className="container">
                                <Form className="login_form" onSubmit={this.handleSubmit}>
                                    <FormItem className='form_it'>
                                        <p>用户名</p>
                                        {getFieldDecorator(
                                            "userName",
                                            {
                                                rules: [{ required: true, message: "请输入账号!" }, {
                                                    max: 20,
                                                    message: "用户名不能超过20位"
                                                },
                                                    {
                                                        pattern: /^(?=.*[A-Za-z])[A-Za-z\d]{0,20}$|^1[3456789]\d{9}/,
                                                        message: '用户名不符'
                                                    }],
                                                initialValue: localStorage.userName || ''
                                            },
                                        )(
                                            <Input
                                                allowClear
                                                maxLength={20}
                                                placeholder={this.props.userFlag ? "用户名" : "请输入手机号"}
                                            />
                                        )}
                                    </FormItem>
                                    <FormItem className='form-it form-it-last'>
                                        <p>密码</p>
                                        {getFieldDecorator(
                                            "password",
                                            {
                                                rules: [{ required: true, message: "请输入密码!" }],
                                                initialValue: localStorage.password || ''
                                            },

                                        )(
                                            <Input
                                                allowClear
                                                maxLength={20}
                                                style={{ width: !this.props.userFlag ? 150 : '100%' }}
                                                type={this.props.userFlag ? 'password' : 'text'}
                                                placeholder={this.props.userFlag ? "密码" : "请输入验证码"}
                                            />
                                        )}
                                    </FormItem>

                                    {/* <FormItem>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: localStorage.getItem('ischecked') == 1 ? true : false,
                            })(
                                <Checkbox

                                    onChange={this.onChangeRemember}

                                >记住密码</Checkbox>
                            )}
                        </FormItem> */}

                                    <FormItem className="submit_Input">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login_form_button"
                                            onSubmit={this.handleSubmit}
                                        >
                                            登录
                                        </Button>

                                    </FormItem>
                                    {/* <div className="login_mainwarp">
                            <div className="text_span"><Link to="/registered">申请注册</Link></div>
                            <p onClick={() => this.props.history.push('/forget')} className='forget_password'>忘记密码</p>
                        </div> */}

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = ({ LOGIN, MAIN }) => {
    return {
        userFlag: LOGIN.userFlag,
        uname: LOGIN.uname,
        pwd: LOGIN.pwd,
        menuList: MAIN.menuList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo(data) {
            dispatch(LOGIN_SUBMITFN(data))
        },
        getRoles(data) {
            dispatch(getRoles(data));
        },
        getMenus(data) {
            dispatch(getMenus(data));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(Login)))
