
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { AAA } from '@store/OBS/resource/action'
import { Layout } from 'antd';
import OBreadcrumb from '../../components/Breadcrumb'
import './index.scss'
import { Redirect } from 'react-router-dom';
import RouterView from '../../router/router_view';
import routerList from '@/router/router_config.js';
import Menu from '@/components/Menu';
const { Sider, Content } = Layout;

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
}




export default connect(mapStateToProps, mapDispatchToProps)(class Resource extends React.Component {
    render() {
        let { menuList } = this.props;
        let path = this.props.location.pathname;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        let two, three;
        menuList[0] && menuList[0].children.forEach(v => {
            v.children && v.children.forEach(k => {
                if (k.menuUrl === path) {
                    two = v;
                    three = k;
                }
            })
        })
        let arr = [one, two, three];
        let propsMenuList = menuList.find(v => v.menuName === "资源");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        let id = propsMenuList[0] && propsMenuList[0].id;
        return (
            localStorage.getItem('userInfo') ? <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    {/* <SiderLogo></SiderLogo> */}
                    {id ? <Menu {...this.props} openArr={[id]} menuList={propsMenuList} /> : null}
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout> : <Redirect to='/login' />
        )
    }
})
