import { post } from '../../../until/axios_instans'

//ygx-应用列表
export const fetchApplicationList = data => post('/api/ucenter/api/v1/application/applications', data)

//应用添加
export function addUse(data) {
    return post('/api/ucenter/api/v1/application/application', data);
}

//应用编辑
export function editUse(data) {
    return post('/api/ucenter/api/v1/application/update', data);
}

// 获取业务系统列表
export const fetchBusinessPlatformList = data => post('/api/ucenter/api/v1/menu/businessPlatformList', data)

// 应用详情
export const fetchApplicationDetail = data => post('/api/ucenter/api/v1/application/detail', data)

// 应用详情
export const fetchAppAccess = data => post('/api/ucenter/api/v1/application/appAccess', data)
// ygx-应用菜单详情
export const fetchAppmenuDetail = data => post('/api/ucenter/api/v1/application/appmenuDetail', data)