import React from 'react';


//人员管理
export default props => {
    return <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.40163 3.94097H5.93604C5.69869 3.94097 5.47889 3.81594 5.35752 3.61197L3.96188 1.26525C3.77186 0.945726 3.8768 0.532726 4.19633 0.342703C4.51596 0.152734 4.92885 0.25768 5.11887 0.577149L6.31883 2.59482H6.98793L7.9872 0.620926C8.15504 0.289234 8.56023 0.156503 8.89171 0.324395C9.22335 0.492288 9.35613 0.897265 9.18824 1.2289L8.00217 3.57191C7.94596 3.68292 7.86008 3.77618 7.75407 3.84133C7.64806 3.90648 7.52606 3.94097 7.40163 3.94097Z" fill="#BABABA"/>
        <path d="M6.83566 13.7096C6.65714 13.7096 6.48594 13.6387 6.35971 13.5125L3.7948 10.9475C3.71413 10.8668 3.65529 10.767 3.6238 10.6573C3.59231 10.5477 3.58921 10.4318 3.61479 10.3206L4.83328 5.02522C4.91663 4.66295 5.27778 4.43679 5.64016 4.52025C6.00244 4.60361 6.22849 4.96486 6.14513 5.32714L5.01022 10.2593L6.8356 12.0847L8.6472 10.2731L7.30067 5.41302C7.20138 5.05479 7.41138 4.68389 7.76957 4.58465C8.1277 4.48552 8.4987 4.69536 8.59794 5.0536L10.0493 10.2918C10.0811 10.4066 10.0819 10.5278 10.0515 10.643C10.0212 10.7582 9.9608 10.8633 9.87657 10.9475L7.31155 13.5125C7.24906 13.575 7.17487 13.6245 7.09321 13.6584C7.01156 13.6922 6.92404 13.7096 6.83566 13.7096Z" fill="#6A7E9C"/>
    </svg>

}