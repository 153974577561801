import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {Button, Select, Col, DatePicker, Input, Checkbox, Modal, Row, Cascader ,message} from 'antd';
import MyTable from '@/components/Table';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {DndProvider} from 'react-dnd';
import SetRecommend from './components/index'
import {getRecommendLists,recommendMove,getResourceTypeList,getSpecialList,getSubjectList,getBaseSchoolMajorList} from '@/api/recommend/recommend'
import {ExclamationCircleFilled} from "@ant-design/icons";
const {Option} = Select;
const {RangePicker} = DatePicker;
class Recommend extends React.Component {
    state = {
        recommendForm: '',
        btnDisabled:true,  //重置按钮
        ids: [],
        getSpecialList: [],
        subjectList: [],
        specialList: [],
        baseSchoolMajorList: [],
        resouceCategorySeniorId: undefined,//资源分类父级id
        resouceCategorySubId: undefined,//资源分类子级id
        recommendDateStart: '',//推荐开始时间
        recommendDateEnd: '',//推荐结束时间
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "100"]
        },
        subList: [],
        subHotSpecialList: [],
        reportTime: [],
        name: '',
        isBlock: false,
        isDrab: false,
        value: 'test',
      visible: false,
        resourceTypeList: [],
        recommendTypeList: [],
        recommendTypeListValue: '',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        dataSource: [],
        columns: [
            {title: '资源名称', dataIndex: 'resourceName', key: 'resourceName'},
            {title: '作者', dataIndex: 'authorName', key: 'authorName', align: 'center'},
            {title: '资源类型', dataIndex: 'resourceFormName', key: 'resourceFormName', align: 'center'},
            {title: '资源分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center'},
            {title: '推荐栏目', dataIndex: 'recommendName', key: 'recommendName', align: 'center'},
            {title: '资源格式', dataIndex: 'resourceType', key: 'resourceType', align: 'center'},
            {title: '上传人', dataIndex: 'createName', key: 'createName', align: 'center'},
            {title: '推荐时间', dataIndex: 'recommendTime', key: 'recommendTime', align: 'center'},
            {
                title: '操作', dataIndex: 'resourceId', key: 'resourceId', align: 'center', fixed: 'right',
                width: 220,
                render: (text, record) => <p className='action vertical_j' >
                    <span onClick={() => this.props.history.push(`/recommendDetail/${record.resourceId}`)}>查看</span>
                    <React.RenderButton
                        text='修改'
                        id="OBS-RC-01-04-b_btnEdit"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                    >
                        <span onClick={() => this.setUp(record)}>修改</span>
                    </React.RenderButton>
                </p>
            }
        ]
    }
    componentDidMount() {
        this.getRecommendLists()
        this.getResourceTypeList()
        this.getSubjectList()
        this.getSpecialList()
        this.getBaseSchoolMajorList()
    }

    //热门方向列表
    async getSubjectList() {
        const subjectList = await getSubjectList({})
        this.setState({
            subjectList: subjectList
        })
    }
    //院校专题列表
    async getSpecialList() {
        const specialList = await getSpecialList({})
        this.setState({
            specialList: specialList
        })
    }
    //基础专业列表
    async getBaseSchoolMajorList() {
        const baseSchoolMajorList = await getBaseSchoolMajorList({})
        this.setState({
            baseSchoolMajorList: baseSchoolMajorList
        })
    }

    //资源类型列表
    async getResourceTypeList() {
        const resourceTypeList01 = await getResourceTypeList({"id":"101"})
        const resourceTypeList02 = await getResourceTypeList({"id":"102"})
        let resourceTypeList = resourceTypeList01.concat(resourceTypeList02)
        this.setState({
            resourceTypeList: resourceTypeList
        })
    }
    //列表查询
    async getRecommendLists() {
        const {name, pagination,recommendDateStart,recommendDateEnd,resouceCategorySubId,resouceCategorySeniorId} = this.state
        const hotLists = await getRecommendLists({
            "name": name,
            "pageNum": pagination.current,
            "pageSize": pagination.pageSize,
            "recommendDateStart": recommendDateStart,
            "recommendDateEnd": recommendDateEnd,
            "resouceCategorySeniorId": resouceCategorySeniorId,
            "resouceCategorySubId": resouceCategorySubId
        })
        pagination.total = hotLists.total
        this.setState({
            dataSource: hotLists.data,
            pagination: pagination
        },()=>{
            window.elGoTop && window.elGoTop('body');
        })
    }
   async recommendMove(idFirst,idSecond){
        const moveStatus = await recommendMove({
            "idFirst": idFirst,
            "idSecond": idSecond
        })
        this.getRecommendLists()
    }
  //取消弹框
  checkCancel = () => {
    this.setState({
      visible: false
    })
  }
    //设置
  setUp = (record) => {
    this.setState({
      ids: [record.resourceId],
      visible: !this.state.visible
    })
  }
  //保存
  refreshData = () => {
    this.setState({
      visible: false,
      expandedRowKeys: [],
      selectedRowKeys: [],
      ids: []
    }, () =>  this.getRecommendLists())
  }
    //分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.current = pageNumber;

        this.setState({pagination},()=>this.getRecommendLists())
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const {pagination} = this.state;
        pagination.current = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.getRecommendLists())
    };
    //批量修改
    batchEdit = () => {
        let {selectedRowKeys,expandedRowKeys} = this.state;
        if (!selectedRowKeys.length) {
            message.warning('请选择');
            return;
        }
        this.setState({
            ids: expandedRowKeys,
          visible: !this.state.visible
        })
    }
    resourceTypeListChange = (value) => {
        this.setState({
            btnDisabled: false,
            subList: [],
            subHotSpecialList: [],
            resouceCategorySeniorId: value,
            resouceCategorySubId: undefined

        },()=>this.getRecommendLists())
    };
    subjectChange = (value) => {
        this.setState({
            btnDisabled: false,
            resouceCategorySubId: value
        },()=>this.getRecommendLists())
    };
    onChangeSpecial=(checked)=> {
        this.setState({
            btnDisabled: false,
            subHotSpecialList: checked,
            resouceCategorySubId: checked[checked.length -1]
        },()=>this.getRecommendLists())
    }
    onChangeBaseSchoolMajor=(checked)=> {
        this.setState({
            btnDisabled: false,
            subList: checked,
            resouceCategorySubId: checked[checked.length -1]
        },()=>this.getRecommendLists())
    }

    //检索查询
    queryBtn = () => {
        let  pagination=this.state.pagination;
        pagination.current=1
        this.setState({
            pagination:pagination
        }, () => this.getRecommendLists());
    }
    //搜索查询
    onHotChange = ({target: {value}}) => {
        this.setState({
            btnDisabled: false,
            name: value
        });
    };
    //日期查询
    reportTimeChange = (value, dateString)=> {
        this.setState({
            btnDisabled: false,
            reportTime: value,
            recommendDateStart: dateString[0],
            recommendDateEnd: dateString[1]
        },()=>this.getRecommendLists())
    }
    //检索重置
    resetBtn = () => {
        this.setState({
            btnDisabled: true,
            name: '',
            subList: [],
            subHotSpecialList: [],
            reportTime: [],
            resouceCategorySeniorId: undefined,
            resouceCategorySubId: undefined,
            recommendDateStart: '',
            recommendDateEnd: ''
        }, () => {
            this.getRecommendLists()
        });
    }
    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({selectedRowKeys, expandedRowKeys})
    }
    //全选反选切换
    checkedAll = () => {
        let {selectedRowKeys, dataSource} = this.state;
        if (selectedRowKeys.length === dataSource.length) {
            this.setState({selectedRowKeys: [], expandedRowKeys: []});
            return;
        }
        const keys = Object.keys(dataSource);
        const index = [];
        keys.forEach(item => {
            index.push(Number(item));
        });
        this.setState({
            selectedRowKeys: index,
            expandedRowKeys: dataSource.map(v => v.resourceId)
        });
    }
    //拖拽事件
    moveRow = (dragIndex, hoverIndex) => {
        const {dataSource} = this.state;
        const dragRowFirst = dataSource[dragIndex];
        const dragRowSecond = dataSource[hoverIndex];
        this.recommendMove(dragRowFirst.recommendId,dragRowSecond.recommendId)
        this.setState(
            update(this.state, {
                dataSource: {
                    $splice: [[hoverIndex, 1, dragRowFirst]],
                },
            }));
        this.setState(
        update(this.state, {
                dataSource: {
                    $splice: [[dragIndex, 1, dragRowSecond]]
                },
            }));
        this.setState({
            hasChanged: true,
        });
    };
    sort = () => {
        this.setState({
            isDrab: !this.state.isDrab
        })
    }
    cancelSort = () => {
        this.setState({
            isDrab: !this.state.isDrab
        })
    }
    addResource = () => {
        this.props.history.push('/main/resource/temporaryClassify')
    }

    render() {
        const {columns, selectedRowKeys,visible,subList,reportTime,subHotSpecialList,subjectList,baseSchoolMajorList,resouceCategorySeniorId,resouceCategorySubId,specialList, resourceTypeList,dataSource, name, pagination, isDrab} = this.state;
        const isMove = isDrab ? (record, index) => ({
        index,
        moveRow: this.moveRow
      }): (record, index) => ({})

        return (
            <div className='recommend_box'>
                <div className="top p20 bg_fff m20">
                    <Col span={22}>
                        <label>选择分类
                            <Select placeholder="选择分类"  value={resouceCategorySeniorId} onChange={this.resourceTypeListChange} style={{margin: '0 20px', width: '25% '}}>
                                {resourceTypeList && resourceTypeList.length ? resourceTypeList.map((item, index) => {
                                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                                }) : ""}
                            </Select>
                        </label>
                        {
                            resouceCategorySeniorId === '10101' || resouceCategorySeniorId === undefined ?
                              <Select placeholder="选择热门学习方向" style={{marginRight: '20px', width: '25% '}} value={resouceCategorySubId} onChange={this.subjectChange}>
                                  {subjectList && subjectList.length ? subjectList.map((item, index) => {
                                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                                  }) : ""}
                              </Select>:''
                        }
                        {
                            resouceCategorySeniorId && resouceCategorySeniorId === '10202' ?
                              <Cascader style={{width: '25%',marginRight:'20px'}} value={subHotSpecialList} fieldNames={{ label: 'name', value: 'id', children: 'subHotSpecial' }} size="middle" allowClear={false} options={specialList} onChange={this.onChangeSpecial} placeholder="选择院校专题" />:''
                        }
                        {
                            resouceCategorySeniorId && resouceCategorySeniorId === '10201' ?
                              <Cascader style={{width: '25%',marginRight:'20px'}} value={subList} fieldNames={{ label: 'typeName', value: 'id', children: 'subList' }} size="middle" allowClear={false} options={baseSchoolMajorList} onChange={this.onChangeBaseSchoolMajor} placeholder="选择专业分类" />:''
                        }
                        <label>
                            推荐日期
                            <RangePicker value={reportTime} onChange={this.reportTimeChange} style={{width: '25%',marginLeft:'20px'}}/>
                        </label>
                    </Col>
                    <Col span={2} className='top_r btn_box'>
                        {/*<Button type='primary' className='search reset_btn' onClick={() => this.queryBtn()}>查询</Button>*/}
                        <Button className='reset_btn' onClick={() => this.resetBtn()} disabled={this.state.btnDisabled}>重置</Button>
                        {/*<span className='showbtn' onClick={() => this.isShowForm()}>{<span*/}
                        {/*    className={isBlock ? 'up' : 'down'}>{isBlock ? "收起" : "展开"}&nbsp;&nbsp;<i className=""></i></span>}</span>*/}
                    </Col>
                </div>
                <div className="recommend_wrapper publicstyle">
                    <div className='padd'>
                            <Row className="top">
                                <Col span={12} style={{display: isDrab ? 'none' : 'block'}} >
                                    <div className="sort_boxs">
                                        <React.RenderButton
                                            text='添加推荐资源'
                                            id="OBS-RC-01-04-b_btnAdd"
                                            // type='table_btn'
                                            // event={{
                                            //     onClick: () => {
                                            //         console.log('click')
                                            //     },
                                            //     onMouseOver:()=>{
                                            //         console.log(222)
                                            //     }
                                            // }}
                                        >
                                            <Button className='sure_sort' type='primary' onClick={() => this.addResource()}>添加推荐资源</Button>
                                        </React.RenderButton>
                                        <div onClick={() => this.sort()}>
                                            <img src={require("@/assets/img/sort.png")} className="sort_img"/>
                                            <span className='sort'> 推荐排序</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12} style={{display: isDrab ? 'block' : 'none'}}>
                                    {/*<Button className='sure_sort' type='primary' onClick={() => this.saveSort()}>保存排序</Button>*/}
                                    <Button className='cancel_sort' onClick={() => this.cancelSort()}>取消排序</Button>
                                    <ExclamationCircleFilled  style={{marginRight:'10px'}} className="sort1_img"/>
                                    <span className='save_hyn'>拖拽排序后自动保存</span>
                                </Col>
                                <Col className='top_r' span={12}>
                                    <Input value={name} onChange={this.onHotChange.bind(this)} onPressEnter={()=>this.queryBtn()} placeholder='请输入资源名称/上传人/作者'/>
                                    {/* <Button className='reset_btn'>重置</Button>
                                <Button type='primary' className='ant_blue'>查询</Button>*/}
                                </Col>
                            </Row>
                            <div className="check_container" style={{position: 'relative'}}>
                                <DndProvider backend={HTML5Backend}>
                                    <MyTable
                                        options={
                                            {
                                                dataSource,
                                                columns,
                                                rowSelection: {
                                                    selectedRowKeys,
                                                    onChange: this.selectedRowChange,
                                                },
                                                pagination: {onChange: this.pageChange, ...pagination,onShowSizeChange: this.onShowSizeChange},
                                                onRow: isMove
                                            }
                                        }

                                        isDrab={isDrab}
                                        fixededit={true}
                                    />
                                </DndProvider>
                                <div className="Grounding_position">
                                    <label>
                                        <Checkbox
                                            checked={selectedRowKeys.length === dataSource.length}
                                            onChange={this.checkedAll}
                                        />
                                         <span className="Grounding_select">全选</span>
                                    </label>
                                    <Button className='ant_blue' onClick={this.batchEdit} type='primary'>批量修改</Button>
                                </div>
                            </div>
                        </div>
                    </div>
              <SetRecommend
                ids={this.state.ids}
                visible={visible}
                checkCancel={this.checkCancel.bind(this)}
                refreshData={this.refreshData.bind(this)}
                wrappedComponentRef={form => {
                  this.recommendForm = form;
                }}
              ></SetRecommend>
            </div>
        )
    }
}
export default Recommend;
