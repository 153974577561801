
import reducers from './reducers'
let defaultState = {
    activeKey: sessionStorage.getItem('header_menu_key') ? sessionStorage.getItem('header_menu_key') : '0',
    headerMenuList: [
        {
            menuUrl: "/main/resource",
            key: "0",
            menuName: "资源"
        },
        {
            menuUrl: "/main/capacity-auth",
            key: "1",
            menuName: "职业能力权限管理"
        },
        {
            menuUrl: "/main/platform-business",
            key: "2",
            menuName: "机构入驻"
        }
    ],
    buttonRole: [
        'NC_01_B01_c_btnaddcolumn',
        'NC_01_B01_c_btnrevise',
        'NC_01_B01_c_btndelete',
        'NC_01_B03_c_btnaddnews',
        'NC_01_B03_c_btnbatchdelete',
        'NC_01_B03_c_btnCanceltherelease',
        'NC_01_B03_c_btneditor',
        'NC_01_B03_c_btndelete',
        'NC_01_B03_c_btnlook',
        'NC_01_B03_c_btnrelease',
        'NC_01_B04_c_btnadd',
        'NC_01_B04_c_btnpreview',
        'NC_01_B04_c_btnTime interval',
        'NC_01_B04_c_btnSave drafts',
        'NC_01_B04_c_btnConfirmrelease',
        'NC_01_B06_c_btnConfirmrelease',
        'NC_01_B06_c_btnbatchdelete',
        'NC_01_B06_c_btnrelease',
        'NC_01_B06_c_btneditor',
        'NC_01_B06_c_btndelete',
        'NC_01_B07_c_btnConfirmrelease',
        'NC_01_B07_c_btnbatchdelete',
        'NC_01_B07_c_btnempty',
        'NC_01_B07_c_btnrestore',
        'NC_01_B07_c_btndelete',
        'NC_01_B08_c_btnConfirmrelease',
        'NC_01_B08_c_btndelete',
        'NC_01_B08_c_btnlook',
        'NC_01_B08_c_btnmanagement',
        'NC_01_B09_c_btnpreview',
        'NC_01_B09_c_btncancel',
        'NC_01_B09_c_btnsave',
        'NC_01_B11_c_btneditor',
        'NC_01_B11_c_btnNewSubcolumn',
        'NC_01_B11_c_btnRelatedinformation',
        'NC_01_B11_c_btnEditcolumn',
        'NC_01_B11_c_btnaddcolumn',
        'NC_01_B11_c_btnpreview',
        'NC_01_B11_c_btndelete',
        'NC_01_B12_c_btnnewpush',
        'NC_01_B12_c_btnConfirmrelease',
        'NC_01_B12_c_btnagain',
        'NC_01_B12_c_btndelete',
        'NC_01_B12_c_btnlook',
        'NC_01_B13_c_btnselectbews',
        'NC_01_B13_c_btnSelectobject',
        'NC_01_B13_c_btnpush',
        'NC_01_B15_c_btnbatchdelete',
        'NC_01_B15_c_btnConfirmrelease',
        'NC_01_B15_c_btndelete',
        'NC_01_B15_c_btnlook',
        'NC_01_B15_c_btnrelease',
        'NC_01_B16_c_btnpreview',
        'NC_01_B16_c_btnTime interval',
        'NC_01_B16_c_btnSave drafts',
        'NC_01_B16_c_Confirm the release'
    ],
    menuList:[]
}
const Main = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Main
