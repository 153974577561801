/**
 * 推荐资源列表
 */

import { post } from '../../until/axios_instans';

//推荐资源列表查询
export function getResourceList(data) {
    return post(window.apiPrefix+"/api/v1/rcenter/operate/resource/myResource", data);
}
