import React, { useEffect, useState } from "react";

import SchoolBasicInformation from '../../components/school_basic_Information';
import AppAccessInfo from '../../components/app_access_info';
import { getSchoolDetail } from '../../api/school_settlement';
import { _get_query } from '../../components/method_tools';

import style from './index.module.scss';

function SchoolSettlementDetail() {
    const schoolId = _get_query('id');
    const [detailInfo, setDetailInfo] = useState({});
    useEffect(() => {
        async function fn() {
            const res = await getSchoolDetail({ schoolId });
            setDetailInfo(res);
        }
        schoolId && fn();
    }, [])
    return (
        <div className={style.SchoolSettlementDetail}>
            <SchoolBasicInformation
                detailInfo={detailInfo}
            />
            <div style={{ padding: '0 30px' }}>
                <AppAccessInfo
                    id={schoolId}
                    rightObj={{
                        date:'OBSMM-01-A01-c-btnExtendedUseTime',
                        record:'OBSMM-01-A01-c-btnViewActivationRecords',
                        stop:'OBSMM-01-A01-c-btnForcedDeactivation',
                        url:'OBSMM-01-A01-c-btnModifyAccessAddress',
                        formal:'OBSMM-01-A01-c-btnBecomeOfficial',
                        open:'OBSMM-01-A01-c-btnKaiTongAgain',
                    }}
                />
            </div>
        </div>
    )
}
export default SchoolSettlementDetail