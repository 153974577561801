import React, { Component } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { HighEditor } from './edit/index';
import { getQuestionById, getTypeSelectList, updateQuestionInfo } from '../../../api/helpCenter/index';
const { Option } = Select;
class index extends Component {
    state = {
        details: null,
        selectList: [],
        istip: false,
        tip:''
    }
    // 获取地址栏参数
    GetQueryString(name) {
        const search = window.location.href;
        if (!search) return null;
        if (!search.split("?")[1]) return null;
        const item = search.split("?")[1].split("&");
        if (!item) return null;
        const fi = item.find((v) => v.includes(name))?.split("=")[1];
        if (item) {
            return fi;
        } else {
            console.log(`${name} is not in search`);
            return null;
        }
    }
    //保存
    submit = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.istip) {
                    updateQuestionInfo(
                        {
                            id: this.GetQueryString('id'),
                            ...values,
                            details: this.state.details
                        }
                    ).then(res => {
                        if (res === 'success') {
                            this.mesSuccess('保存成功');
                            this.props.history.push('/main/Generalmanagement/contenidosList');
                        } else {
                            this.mesWarning('保存失败');
                        }
                    })
                } else {
                    return;
                }

            }
        });
    }
    componentDidMount() {
        //编辑回显
        getQuestionById({ id: this.GetQueryString('id') }).then(res => {
            if (res) {
                this.props.form.setFieldsValue({
                    typeId: res.typeId,
                    title: res.title,
                })
                this.setState({
                    details: res.details
                })
            }
        })
        this.getTypeSelectList()

    }
    //分类下拉框
    getTypeSelectList = () => {
        getTypeSelectList({}).then(res => {
            if (res) {
                this.setState({
                    selectList: [...res]
                })
            }
        })
    }
    getText = (str) => {
        return str.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "");
    }
    isNull = (str) => {
        if (str === "") return true;
        var regu = "^[ ]+$";
        var re = new RegExp(regu);
        return re.test(str);
    }
    render() {
        const { details } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <div className="addProblema">
                <Form className="addProblema_form">
                    <Form.Item label="问题分类">
                        {getFieldDecorator('typeId', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择问题分类',
                                },
                            ],
                        })(
                            <Select style={{ width: '500px' }}>
                                {
                                    this.state.selectList.map((item, key) => {
                                        return <Option key={key} value={item.id}>{item.typeName}</Option>
                                    })
                                }
                            </Select>
                        )
                        }
                    </Form.Item>
                    <Form.Item label="标题" >
                        {getFieldDecorator('title', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入标题',
                                },
                            ],
                        })(
                            <Input maxLength={30} style={{ width: '500px' }} suffix={<span>{getFieldValue('title')?.length || 0}/30</span>} />
                        )
                        }
                    </Form.Item>
                    <Form.Item label="内容" >
                        {getFieldDecorator('details', {
                            // rules: [
                            //     {
                            //         required: true,
                            //         message: '请输入内容',
                            //     },
                            // ],
                        })(
                            <>
                            <HighEditor
                                montedNode={'addProblema'}
                                zIndex={1}
                                placeholder={"请输入内容"}
                                height="200"
                                // isStat={true}
                                isFocus={true}
                                onChange={(e) => {
                                    this.setState({
                                        details: e
                                    })
                                    let aa = this.getText(e);
                                    if (this.isNull(aa)) {
                                        this.setState({
                                            tip: "请输入内容",
                                            istip: false
                                        })
                                    } else {
                                        this.setState({
                                            tip: "",
                                            istip: true
                                        })
                                    }
                                }}
                                html={details && details}
                            />
                             <p style={{ color: 'red' }}>{this.state.tip}</p>
                            </>
                        )
                        }
                    </Form.Item>
                </Form>
                <div className="addProblema_footer">
                    <span>
                        <Button type="primary" onClick={() => this.submit()}>保存</Button>
                        <Button onClick={() => this.props.history.goBack()}>取消</Button>
                    </span>
                </div>
            </div>
        )
    }
}
export default Form.create()(index);