import { result } from './result'

export default [
    {
        name: '实训',
        path: '/main/PracticalTraining',
        component: result.PracticalTraining,
        children: [ 
           
            {
                path: '/main/PracticalTraining',
                redirect: '/main/PracticalTraining/Supplier',
            },
            {
                name: '供应商管理',
                path: '/main/PracticalTraining/Supplier',
                component: result['Supplier']

            },
            {
                name: '设备分类管理',
                path: '/main/PracticalTraining/classified',
                component: result['Classified']
                
            },  
            {
                name: '设备型号管理',
                path: '/main/PracticalTraining/Equipment',
                component: result['Equipment']
              
            },
            {
                name: '初始安装管理',
                path: '/main/PracticalTraining/installation',
                component: result['Installation']
              
            },
            {
                name: '初始安装管理详情页',
                path: '/main/PracticalTraining/installationXiang',
                component: result['InstallationXiang'],
                namehide: true,
            },
            {
                name: '资源授权',
                path: '/main/PracticalTraining/Authorization',
                component: result['Authorization'],
                namehide: true,
            },
            {
                name: '硬件设备注册',
                path: '/main/PracticalTraining/registration',
                component: result['Registration']
                
            },
            {
                name: '提供商管理',
                path: '/main/PracticalTraining/Trainingresource',
                component: result['Trainingresource']
              
            }, {
                name: '课程资源管理',
                path: '/main/PracticalTraining/Curriculum',
                component: result['Curriculum']
              
            },
            {
                name: '课程资源管理详情页',
                path: '/main/PracticalTraining/CurriculumXiang',
                component: result['CurriculumXiang'],
                namehide: true,
            },

            {
                name: '机构申请处理',
                path: '/main/PracticalTraining/Organizationing',
                component: result['Organizationing']
              
            },
           
        ]
    },

]