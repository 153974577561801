import { post } from '@/until/axios_instans';
import { baseUrl as baseURL } from '@/config/secret.js';
// 文件上传
export const uploadFile = data => {
    return post('/api/fujian/api/v1/fujian/uploadFile', data);
}
// 多文件上传
export const uploadFiles = data => {
    return post('/api/fujian/api/v1/fujian/uploadFiles', data);
}
export const uploadImg = data => {
    return post('/api/fujian/api/v1/fujian/uploadImg', data);
}
//文件上传
export const uploadFileUrl = (data) => baseURL + "/api/fujian/api/v1/fujian/uploadFile";