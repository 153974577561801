import React, { Component } from 'react'
import './index.scss';
import {Form, Select, Button, Row, Col, Radio, Input, message} from 'antd';
import setName from 'classnames';
import {getPlatformList,selectNewsParentCategory,addNewsCategory,checkColumnName,checkColumnNum} from "../../../../api/information";
import TopTitle from '../../../../components/Breadzx'
const { Option } = Select;
const topTitleData = {
    span_1: "资讯管理",
    span_2: "栏目管理",
    span_3: "新增栏目管理",
    span_1_c: "#333",
    span_2_c: "#333",
    container: "新增栏目管理",
};

class AddColumn extends Component {
    state = {
        columnNames:'', // 栏目名称
        platformList: [],
        platform: '',
        categoryName: '',
        columnType : 0,
        parentId: '0',
        parentCategoryList: [],
        organizationId: '',
        useType: 1,
        createUserId: JSON.parse(localStorage.getItem("userInfo")).id,
    }
    componentDidMount() {
        this.selectNewsCategory()
    }
    //查询栏目列表
    selectNewsCategory = () => {
        let data={
            organizationId: this.state.organizationId,
            // platform: this.state.tabList[this.state.tabIndex]
            queryType: this.state.useType
        }
        getPlatformList(data).then(e => {
            console.log("e",e)
            this.setState({platformList: e})
        })
    }
    //查询一级栏目
    selectNewsParentCategory = () =>{
        let data={
            organizationId: this.state.organizationId,
            useType: this.state.useType,
            platform: this.state.platform
        }
        selectNewsParentCategory(data).then(e => {
            this.setState({parentCategoryList: e})
        })
    }
    //栏目change事件
    categoryOnChange = (e) =>{
        console.log("栏目change事件", e.target.value)
        this.setState({
            columnType : e.target.value,
        })
        if(e.target.value === 0){
            this.setState({
                parentId:'0'
            })
        }else {
            this.setState({
                parentId:''
            })
        }
    }
    //所属业务change事件
    paltformOnChange = (e) =>{
        console.log("eeeeeeeeeee", e)
        if(this.state.columnType === 0){
            this.setState({
                parentId:'0'
            })
        }else {
            this.setState({
                parentId:''
            })
        }
        this.setState({
            platform:e,
            // columnType : 0,
            // parentId:'0'
        },()=>{
            this.props.form.setFieldsValue({yjlm:''})
            this.selectNewsParentCategory()
            // this.props.form.validateFields(['lmmc'])
        })
    }
    onFocus=()=>{
        if(this.state.platform === ''){
            message.warning("请选择所属业务系统！");
            return;
        }
    }
    //父级栏目change事件
    parentOnChange = (e) =>{
        this.setState({
            parentId:e
        })
    }
    //保存
    save = () => {
        this.props.form.validateFields((err, values) => {
            console.log('0-------------',values)
            if (!err) {
                let data = {
                    parentId: this.state.parentId,
                    categoryName: this.props.form.getFieldValue('lmmc'),
                    platform: this.state.platform,
                    organizationId: this.state.organizationId,
                    createUserId: this.state.createUserId
                }
                checkColumnNum (data).then(e=>{
                    if (e === true) {
                        message.error('栏目数不能超过20');
                    }else{
                        addNewsCategory (data).then(e =>{
                            if (e === true) {
                                message.error('栏目名称已存在');
                            }else{
                                message.success('保存成功');
                                this.props.history.goBack()
                            }
                        })
                    }
                })
            }
        })
    }
    checkColumnName =(rule, value, callback)=>{
        console.log("value::::", value)
        if(this.props.form.getFieldValue('ywxt') !== null && this.props.form.getFieldValue('ywxt') !== ''){
            if(!value){
                callback();
            }
            let data = {
                organizationId:this.state.organizationId,
                categoryName: value,
                platForm: this.props.form.getFieldValue('ywxt')
            }
            checkColumnName(data).then(res => {
                if (res.result === false) {
                    callback('栏目名称已存在');
                }
                // else{
                //     callback();
                // }
            })
        }else{
            callback();
        }
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let {columnNames,parentCategoryList,platformList} = this.state;
        return (
            <div style={{height: 'calc(100% - 100px)'}}>
                <TopTitle {...topTitleData} />
            <div className='add_column_box'>
                {/*<div className="title">*/}
                {/*    新增栏目*/}
                {/*</div>*/}

                <Form className='marginBottom'>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span><span className='yewu'>业务系统</span></span></div>
                            <div className='row_div_right'>

                                {
                                    getFieldDecorator('ywxt', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '业务系统不能为空'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择业务系统' onChange={this.paltformOnChange}>
                                            {platformList.length ? platformList.map((item, index) => {
                                                return <Option key={item.platform} value={item.platform}>{item.platformName}</Option>
                                            }) : ""}
                                        </Select>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div' style={{'lineHeight':'15px'}}>
                            <div className='row_div_left' ><span><span className='yewu'>栏目分类</span></span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('xwfl', {
                                        initialValue: this.state.columnType
                                    })(
                                        <Radio.Group value={this.state.columnType} onChange={this.categoryOnChange} style={{ width: '100%' }}>
                                            <Radio value={0}>一级栏目</Radio>
                                            <Radio value={1}>二级栏目</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    {this.state.columnType === 1?
                        <Form.Item>
                            <div className='row_div'>
                                <div className='row_div_left'><span><span className='red'>*</span>父级栏目</span></div>
                                <div className='row_div_right'>
                                    {
                                        getFieldDecorator('yjlm', {
                                            initialValue: undefined,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '一级栏目不能为空'
                                                }
                                            ]
                                        })(
                                            <Select placeholder='请选择一级栏目' onChange={this.parentOnChange} onFocus={this.onFocus}>
                                                {parentCategoryList.length ? parentCategoryList.map((item, index) => {
                                                    return <Option key={item.id} value={item.id}>{item.categoryName}</Option>
                                                }) : ""}
                                            </Select>
                                        )
                                    }
                                </div>
                            </div>
                        </Form.Item>: null
                    }
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span><span className='yewu'>栏目名称</span></span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('lmmc', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '栏目名称不能为空'
                                            },
                                            {
                                                pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                                                message: '禁止输入特殊字符',
                                            },
                                            // {
                                            //     validator: (rule, value, callback) => this.checkColumnName(rule, value, callback)
                                            // }
                                        ],
                                        getValueFromEvent: event => {
                                            return event.target.value.replace(/\s+/g, '')
                                        }
                                    })(
                                        <Input placeholder='请输入栏目名称' onChange={this.onChange} maxLength={20} suffix={<span>
                                                {this.props.form.getFieldValue(`lmmc`) ? this.props.form.getFieldValue(`lmmc`).length : 0}/20</span>}/>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                </Form>
                <div className="fix_controll">
                    <Button className='add_btn_j' onClick={this.save} style={{ marginRight: '40px', width: '100px', height: '39px' }}>保存</Button>
                    <Button onClick={() => this.props.history.goBack()} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>
            </div>
            </div>
        )
    }
}


AddColumn = Form.create()(AddColumn);
export default AddColumn;
