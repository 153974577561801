import React, {Component} from 'react';
import './index.scss';
import {Col, Button, Form} from 'antd';

import {connect} from 'react-redux';
import listObj from '../../store/OBS/transResource/action';
import {browsingResource} from '@/api/transResource'
import SetHot from "../temporaryProject/components";
import FilePreview from "@/components/FilePreview"; //资源查看
import TemporaryProject from "../temporaryProject";

const mapStateToProps = ({TRANSRESOURCE: {options}}) => {
    return {
        options
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        btnClick(data) {
            dispatch(listObj[data]({a: 1}))
        }
    };
}

class index extends Component {
    state = {
        resourceId: "",
        resourceDetails: {},
        fileId: '',
    }
    goBack = () => {
        this.props.history.goBack()
    }

    componentDidMount() {
        const resourceId = this.props.match.params.resourceId
        this.setState({
            resourceId: resourceId,
        }, () => {
            this.getResourceDetails(this.state.resourceId)
        })
    }

    async getResourceDetails(resourceId) {
        const resourceDetails = await browsingResource({"id": resourceId})
        console.log('resourceDetails', resourceDetails)
        this.setState({
            fileId: resourceDetails.transInfo && resourceDetails.transInfo.transId ? resourceDetails.transInfo.transId : resourceDetails.fileId,
            resourceDetails: resourceDetails
        })
    }

    //取消弹框
    checkCancel = () => {
        this.setState({
            visible: false
        })
    }
    //保存
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        })
    }
    //设置
    setUp = (record) => {
        console.log('record', record)
        /*  let ids = []
          ids.push(record.resourceId)*/
        this.setState({
            ids: [record],
            visible: !this.state.visible
        })
    }

    render() {
        let {type} = this.props.match.params;
        let part = this.props.options[type];
        const {resourceDetails, visible, fileId} = this.state
        return (
            <div className='trans_box'>
                <div className="header bg_fff">
                    <div className="back" onClick={() => this.goBack()}>
                        <img alt="" src={require("@/assets/img/grounding/back.png")} className="back-icon"/>
                        返回
                    </div>
                    <div className='title'>
                        <h2>{resourceDetails.resourceName}</h2>
                    </div>
                </div>
                <div className="show_box">
                    <div className="left">
                        <div className="watch">
                            {/* resourceType   文件类型
                                 resourceContent     txt文件值
                                fileId             文件ID
                                pageSize           pdf文档默认显示几页 不传 默认为 2
                                startTime          视频默认第几秒开始  不传 默认为 0 */}
                            {/* txet文档ID  733566D96F8D128256C6B7D1535919717FFE3ADDBBB4BF31F5CE1EFD585C2AD9
                                doc文档ID   bd23f32efc5149e68d3db315d4d65dcb
                                视频ID  1E95B3EFBB161B8FB63CABA2B9F8C90B88FFDC476F21142D5676AC307BF501E0
                                音频ID  B3D00837947C4079CD027A82723AD39FE1E801C34A9EB974363211B1BA3A07FA
                            */}
                            <FilePreview pageSize={1} fileId={fileId} startTime={0} resourceType={resourceDetails.resourceType} resourceContent={resourceDetails.resourceContent}/>
                        </div>
                        <div className="watch_bottom bg_fff">
                            <Col span={15}>
                                {/* <span>
                                    🐖
                            </span>*/}
                                <img className='iconphoto' src={require('@/assets/img/iconphoto.png')}/>
                                <span>
                                    上传人所属机构:{resourceDetails.unitName} 上传人姓名:{resourceDetails.createName} 丨 {resourceDetails.updateTime} 更新
                            </span>
                            </Col>
                            <Col className='end' span={9}>
                                <span style={{marginRight: '36px'}}>
                                    {/*<b>🐕</b>*/}
                                    <span>浏览量{resourceDetails.browseNum}</span>
                                </span>
                                <span>
                                    {/*<b>🐕</b>*/}
                                    <span>收藏量：{resourceDetails.collectNum}</span>
                                </span>
                            </Col>
                        </div>
                    </div>
                    <div className="right bg_fff">
                        <div className="right_top">
                            <div className="title">
                                <h2>{resourceDetails.resourceName}</h2>
                            </div>
                            <div className='auth'>
                                {/*  <img src={`/api/media/api/v1/media/showImage/${detailData.coverFileId}`} alt="" />*/}
                                <img src={resourceDetails.authorImg} alt=""/>
                                <span>
                                     作者：{resourceDetails.authorName ? resourceDetails.authorName : "未知"}
                            </span>
                            </div>
                            <div className="affiliation">
                                职校分类：<span className={!resourceDetails.stageName?"":"affiname"}>{resourceDetails.stageName}</span><span className={!resourceDetails.bigMajorName?"":"affiname"}>{resourceDetails.bigMajorName}</span><span>{resourceDetails.medMajorName}</span>
                            </div>
                            {resourceDetails.resourceHotSeniorName ? <div className='tag'>
                                <span>{resourceDetails.resourceHotSeniorName}</span>
                            </div>: ''}
                        </div>
                        <div className="right_bottom">
                            <h2>一句话介绍</h2>
                            <p>
                                {resourceDetails.simpleDesc}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="action_bttom  pos_f bg_fff">
                    {/* <div className='action'>
                        {
                            part.btnArr && part.btnArr.map((v, i) => <Button onClick={() => this.props.btnClick(part.btnFn[i])} type='primary' key={i} className='ant_blue'>{v}</Button>)
                        }
                        <Button className='blue' onClick={()=>this.goBack()}>返回</Button>
                    </div>*/}
                    <div className='action'>
                        {type !== "check" ? <Button className='ant_blue'
                                                    onClick={() => this.setUp(this.state.resourceId)}>设置热门方向</Button> : ""}
                        <Button className='blue' onClick={() => this.goBack()}>返回</Button>
                    </div>
                </div>
                <SetHot
                    ids={[this.state.resourceId]}
                    visible={visible}
                    checkCancel={this.checkCancel.bind(this)}
                    refreshData={this.refreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                ></SetHot>
            </div>
        )
    }
}

index = connect(mapStateToProps, mapDispatchToProps)(index);
index = Form.create()(index)
export default index;
