import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {Button, Select, Col, DatePicker, Input, Checkbox, Modal, message, Row, Cascader} from 'antd';
import MyTable from '@/components/Table';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {DndProvider} from 'react-dnd';
import {getResourceList} from '@/api/temporaryClassify/temporaryClassify'
import { getRecommendTypeList, updateRecommend,getResourceTypeList,getSubjectList,getSpecialList,getBaseSchoolMajorList} from '@/api/recommend/recommend'
const {Option} = Select

const mapStateToProps = ({RECOMMEND}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(class TemporaryClassify extends React.Component {
    state = {
        btnDisabled: true,
        getSpecialList: [],
        specialList: [],
        baseSchoolMajorList: [],
        resouceCategorySeniorId: undefined,//资源分类父级id
        resouceCategorySubId: undefined,//资源分类子级id
        subList: [],
        subHotSpecialList: [],
        resourceTypeList: [],
        recommendTypeList: [],
        recommendTypeListValue: '',
        ifRecommend: 1,//1-推荐 0 - 不推荐
        recommendType: '',//推荐类型
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "100"]
        },
        name: '',
        visibily: false,
        isBlock: false,
        isDrab: false,
        value: 'test',
        ids: [],
        selectedRowKeys: [],
        activeIndex: 0,
        dataSource: [],
        columns: [
            {title: '资源名称', dataIndex: 'resourceName', key: 'resourceName'},
            {title: '作者', dataIndex: 'authorName', key: 'authorName', align: 'center',},
            {title: '资源类型', dataIndex: 'resourceForm', key: 'resourceForm', align: 'center',},
            {title: '资源分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center',},
            {title: '资源格式', dataIndex: 'resourceType', key: 'resourceType', align: 'center',
                render:(data)=>{
                    let params = {1:"文档", 2:"视频", 3:"音频", 4:"图片"}
                    return params[data];
                }},
            {title: '上传人', dataIndex: 'createName', key: 'createName', align: 'center',},
            {title: '上传时间', dataIndex: 'createTime', key: 'createTime', align: 'center',},
        ]
    }

    componentDidMount() {
        this.getResourceList()
        this.getRecommendTypeList()
        this.getResourceTypeList()
        this.getSubjectList()
        this.getSpecialList()
        this.getBaseSchoolMajorList()
    }
    //热门方向列表
    async getSubjectList() {
        const subjectList = await getSubjectList({})
        this.setState({
            subjectList: subjectList
        })
    }
    //院校专题列表
    async getSpecialList() {
        const specialList = await getSpecialList({})
        this.setState({
            specialList: specialList
        })
    }
    //基础专业列表
    async getBaseSchoolMajorList() {
        const baseSchoolMajorList = await getBaseSchoolMajorList({})
        this.setState({
            baseSchoolMajorList: baseSchoolMajorList
        })
    }

    //资源类型列表
    async getResourceTypeList() {
        const resourceTypeList01 = await getResourceTypeList({"id":"101"})
        const resourceTypeList02 = await getResourceTypeList({"id":"102"})
        let resourceTypeList = resourceTypeList01.concat(resourceTypeList02)
        this.setState({
            resourceTypeList: resourceTypeList
        })
    }
    resourceTypeListChange = (value) => {
        this.setState({
            btnDisabled: false,
            subList: [],
            subHotSpecialList: [],
            resouceCategorySeniorId: value,
            resouceCategorySubId: undefined

        },()=>this.getResourceList())
    };
    subjectChange = (value) => {
        this.setState({
            btnDisabled: false,
            resouceCategorySubId: value
        },()=>this.getResourceList())
    };
    onChangeSpecial=(checked)=> {
        this.setState({
            btnDisabled: false,
            subHotSpecialList: checked,
            resouceCategorySubId: checked[checked.length -1]
        },()=>this.getResourceList())
    }
    onChangeBaseSchoolMajor=(checked)=> {
        this.setState({
            btnDisabled: false,
            subList: checked,
            resouceCategorySubId: checked[checked.length -1]
        },()=>this.getResourceList())
    }
    //列表查询
    async getResourceList() {
        const {name, pagination,resouceCategorySeniorId,resouceCategorySubId} = this.state
        const resourceList = await getResourceList({
            "resourceName": name,
            "pageNum": pagination.current,
            "pageSize": pagination.pageSize,
            "resouceCategorySeniorId": resouceCategorySeniorId,
            "resouceCategorySubId": resouceCategorySubId
        })
        pagination.total = resourceList.total
        this.setState({
            dataSource: resourceList.data,
            pagination: pagination
        },()=>{
            window.elGoTop && window.elGoTop('body');
        })
    }
    //检索查询
    queryBtn = () => {
        this.getResourceList()
    }
    //搜索查询
    onHotChange = ({target: {value}}) => {
        this.setState({
            btnDisabled: false,
            name: value
        });
    };
    //检索重置
    resetBtn = () => {
        this.setState({
            btnDisabled: true,
            name: '',
            subList: [],
            subHotSpecialList: [],
            reportTime: [],
            resouceCategorySeniorId: undefined,
            resouceCategorySubId: undefined,
        }, () => this.getResourceList());
    }
    //推荐栏目列表
    recommendTypeListChange = (value) => {
        const recommendTypeList = this.state.recommendTypeList
        const recommendTypeListValue = recommendTypeList[value].name
        const recommendType = recommendTypeList[value].id
        this.setState({
            recommendTypeListValue: recommendTypeListValue,
            recommendType: recommendType
        });
    };
    //设置热门方向
    async updateRecommend() {
        if (!this.state.recommendType) {
            message.warning('请选择推荐类型！');
            return;
        }
        const {ids, recommendType} = this.state
        const setList = await updateRecommend({
            "ids": ids,
            "recommendType": recommendType,
            "ifRecommend": 1
        })
        this.setState({
              visibily: !this.state.visibily,
              selectedRowKeys: []
          }, () => {
              this.getResourceList()
          }
        )
        if(setList.code === 0){
            message.success('推荐成功！')
        }
    }
    //取消弹框
    canale = () => {
        this.setState({
            visibily: !this.state.visibily
        })
    }
    //选择行
    selectedRowChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys})
    }
    //全选反选切换
    checkedAll = () => {
        let {selectedRowKeys, dataSource} = this.state;
        if (selectedRowKeys.length === dataSource.length) {
            this.setState({selectedRowKeys: []});
            return;
        }
        this.setState({
            selectedRowKeys: dataSource.map(v => v.id),
        });
    }
    //推荐栏目列表
    async getRecommendTypeList() {
        const recommendTypeList = await getRecommendTypeList({})
        this.setState({
            recommendTypeList: recommendTypeList
        })
    }
    //分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.current = pageNumber;
        this.getResourceList()
        this.setState({pagination})
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const {pagination} = this.state;
        pagination.current = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.getResourceList())
    };
    //批量设置
    batchEdit = () => {
        let {selectedRowKeys} = this.state;
        if (!selectedRowKeys.length) {
            message.warning('请选择');
            return;
        }
        this.setState({
            ids: selectedRowKeys,
            visibily: !this.state.visibily
        })
    }

    isShowForm = () => {
        this.setState({
            isBlock: !this.state.isBlock
        })
    }
    sort = () => {
        this.setState({
            isDrab:!this.state.isDrab
        })
    }
    saveSort = () => {
        this.setState({
            isDrab:!this.state.isDrab
        })
    }
    cancelSort = () => {
        this.setState({
            isDrab:!this.state.isDrab
        })
    }
    callBack = (index) => {
        this.setState({ activeIndex: index });
    }
    render() {
        const {columns, selectedRowKeys, dataSource,visibily, pagination,name,recommendTypeList,subList,subHotSpecialList,subjectList,baseSchoolMajorList,resouceCategorySeniorId,resouceCategorySubId,specialList, resourceTypeList } = this.state;
        return (
            <div className='recommend_box'>

                <div className="top p20 bg_fff m20">
                    <Col span={22}>
                        <label>选择分类
                            <Select placeholder="选择分类"  value={resouceCategorySeniorId} onChange={this.resourceTypeListChange} style={{margin: '0 20px', width: '25% '}}>
                                {resourceTypeList && resourceTypeList.length ? resourceTypeList.map((item, index) => {
                                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                                }) : ""}
                            </Select>
                        </label>
                        {
                            resouceCategorySeniorId === '10101' || resouceCategorySeniorId === undefined ?
                              <Select placeholder="选择热门学习方向" style={{marginRight: '20px', width: '25% '}}  value={resouceCategorySubId} onChange={this.subjectChange}>
                                  {subjectList && subjectList.length ? subjectList.map((item, index) => {
                                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                                  }) : ""}
                              </Select>:''
                        }
                        {
                            resouceCategorySeniorId && resouceCategorySeniorId === '10202' ?
                              <Cascader style={{marginRight: '20px', width: '25% '}}  value={subHotSpecialList} fieldNames={{ label: 'name', value: 'id', children: 'subHotSpecial' }} size="middle" allowClear={false} options={specialList} onChange={this.onChangeSpecial} placeholder="选择院校专题" />:''
                        }
                        {
                            resouceCategorySeniorId && resouceCategorySeniorId === '10201' ?
                              <Cascader style={{marginRight: '20px', width: '25% '}}  value={subList} fieldNames={{ label: 'typeName', value: 'id', children: 'subList' }} size="middle" allowClear={false} options={baseSchoolMajorList} onChange={this.onChangeBaseSchoolMajor} placeholder="选择专业分类" />:''
                        }
                        <Input style={{marginLeft: '20px', width: '25% '}}  value={name} onChange={this.onHotChange.bind(this)} onPressEnter={()=>this.queryBtn()}  placeholder='请输入资源名称/上传人/作者'/>
                    </Col>

                    <Col span={2} className='top_r btn_box'>
                        <Button className='reset_btn' onClick={() => this.resetBtn()} disabled={this.state.btnDisabled}>重置</Button>
                    </Col>
                </div>
                <div className="recommend_wrapper publicstyle">
                    <div className='padd'>
                        <div className="check_container" style={{position: 'relative'}}>
                       {/* <Row className="top m20">
                            <Col className='top_l' span={12}>
                                <div className='tabs'>
                                    <Tag onClick={() => this.callBack(0)} className={kindName({ 'active': activeIndex == 0 })}>专题资源</Tag>
                                    <Tag onClick={() => this.callBack(1)} className={kindName({ 'active': activeIndex == 1 })}>专业资源</Tag>
                                </div>
                            </Col>
                            <Col className='top_l' span={12}>
                                <Input value={name} onChange={this.onHotChange.bind(this)} onPressEnter={()=>this.queryBtn()}  placeholder='请输入资源名称/上传人/作者'/>
                                 <Button className='reset_btn'  disabled={!name} onClick={() => this.resetBtn()}>重置</Button>
                                <Button type='primary' className='ant_blue' onClick={() => this.queryBtn()}>查询</Button>
                            </Col>
                        </Row>*/}

                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                options={
                                    {
                                        dataSource,
                                        columns,
                                        rowSelection: {
                                            selectedRowKeys,
                                            onChange: this.selectedRowChange,
                                        },
                                        pagination: {onChange: this.pageChange, ...pagination,onShowSizeChange: this.onShowSizeChange}
                                    }
                                }

                                isDrab={false}
                                fixededit={true}
                            />
                        </DndProvider>
                        <div className="Grounding_position">
                             <label>
                                <Checkbox
                                  checked={selectedRowKeys.length === dataSource.length ? true : false}
                                  onChange={this.checkedAll}
                                />
                                 <span className="Grounding_select">全选</span>
                            </label>
                            <Button className='ant_blue' onClick={this.batchEdit} type='primary'>批量推荐</Button>
                        </div>
                        </div>
                    </div>
                </div>
                <Modal
                  title='设置推荐类型'
                  width={'660px'}
                  wrapClassName='modal_box'
                  visible={visibily}
                  closable={false}
                  destroyOnClose={true}
                  footer={
                      <div className='modal_btn_j'>
                          <Button onClick={() => this.canale()}>取消</Button>
                          <Button onClick={() => this.updateRecommend()} type='primary'>保存</Button>
                      </div>
                  }
                >
                    <Select placeholder="设置推荐类型" onChange={this.recommendTypeListChange} style={{width: '500px'}}>
                        {recommendTypeList && recommendTypeList.length ? recommendTypeList.map((item, index) => {
                            return <Option key={index} value={index}>{item.name}</Option>
                        }) : ""}
                    </Select>
                </Modal>
            </div>
        )
    }
})
