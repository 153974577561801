import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'antd/dist/antd.css';
import './reset_style/index.scss';
//###test next quanzi    import { apiPrefix, peixunApiPrefix } from "./config/apiPrefix"
import {
    apiPrefix,
    CircleapiPrefix,
    GongyongapiPrefix,
    peixunApiPrefix,
    hdApiPrefix,
    zhsxPrefix,
    zixunApiPrefix,
    ningxiaApiPrefix
} from './config/apiPrefix'
import RenderButton from '@/components/renderButton';
import store from './store'
import { Provider } from 'react-redux'
import { getImageOssBaseurl } from './api/Baseurl';
import '@/components/global_components/confirm/index.css'
import '@/components/global_components/confirm/index.js'
import { GetImageBaseUrl } from 'gongyongpicpreview'
import '@/components/global_components/confirm/index.js'
import { initSecretUrlByType07, baseURL } from './config'
// import Cookie from 'js-cookie';
import './until/libs/hh-fun'
import Http from './until/axios_instans'

React.RenderButton = RenderButton;


window.apiPrefix = apiPrefix();//资源api前缀
window.peixunApiPrefix = peixunApiPrefix(); //管理api前缀
window.CircleapiPrefix = CircleapiPrefix();//圈子api前缀
window.GongyongapiPrefix = GongyongapiPrefix();//公用api前缀
window.hdApiPrefix = hdApiPrefix(); //活动大赛api前缀
window.zhsxPrefix = zhsxPrefix(); //智慧实训api前缀
window.zixunApiPrefix = zixunApiPrefix(); //资讯api前缀
window.ningxiaApiPrefix = ningxiaApiPrefix()//个人中心前缀

function create(src) {
    return function (path) {
        if (!path) return;
        if (path[0] === '/') {
            return src + path.slice(1);
        } else {
            return src + path;
        }   
    };
}

getImageOssBaseurl({ type: '100007' }).then(res => {
    const {
        'static-img': staticImg,
        'front-img': frontImg,
        'office-public-resource': officePublicResource,
        'public-resource': publicResource,
        'web-office-download': webOfficeDownload,
        "logo":logo
    } = res?.config
    window.$$setImgSrc = create(staticImg);
    window.$$ImageOssBaseurl = frontImg;
    window.$$img = create(frontImg);
    window.$$uploadRc = create(publicResource);
    window.$$uploadDoc = create(officePublicResource);
    window.$$imgSrc = create(publicResource);
    window.$$imgSrcLcl = publicResource;
    window.$$createUploadFilePath = create(publicResource);
    window.$$logo = logo;
    window.apiPrefixCicle="/api/circle"   
    document.title = res?.config?.title?.OBS;//title
    document.querySelector('link[rel="icon"]').href = window.$$setImgSrc(res?.config?.favicon);//ico'
    window.$$webOfficeDownload = create(webOfficeDownload);
    if(res?.config){
        initSecretUrlByType07(res?.config)
        Http.defaults.baseURL = baseURL
    }
    ReactDOM.render(
        <Provider store={store}>
            <GetImageBaseUrl imageBaseUrl={window.$$imgSrcLcl}>
                <App />
            </GetImageBaseUrl>
        </Provider>
        ,
        document.getElementById('root')
    );
})
serviceWorker.unregister();
