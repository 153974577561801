import React from 'react';
import {Button, Form, Input, Modal, message} from "antd";
import '../index.scss';
import {classifySave, classifyUpdate,} from '@/api/craftsman-app/index'

class oneClassifyModel extends React.Component {
    state = {
        oneClassifyDetail: {},
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.oneVisible) {
            this.setState({
                oneClassifyDetail: nextProps.oneClassifyDetail,
                oneVisible: nextProps.oneVisible,
            })
        }
    }

    oneClassifySave = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    saveLoading: true
                })
                let successBack = () => {
                    this.props.oneRefreshData()
                    this.props.form.resetFields()//清空表单
                    this.checkCancel()
                    this.setState({
                        oneVisible: false,
                        saveLoading: false,
                    });
                }
                if (this.state.oneClassifyDetail.categoryId) {
                    let params =
                        {
                            "categoryLevel": 1,
                            "categoryName": values.categoryName,
                            "parentId": '0',
                            "categoryId": this.state.oneClassifyDetail.categoryId
                        }
                    classifyUpdate(params).then(res => {
                        if (res) {
                            message.success('编辑成功');
                            successBack();
                        }
                    })
                } else {
                    let params =
                        {
                            "categoryLevel": 1,
                            "categoryName": values.categoryName,
                            "parentId": '0'
                        }
                    classifySave(params).then(res => {
                        if (res) {
                            message.success('添加成功');
                            successBack();
                        }
                    })
                }
            }
        })
    }
    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
    }

    render() {
        const {saveLoading, oneClassifyDetail} = this.state;
        const {oneVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 14}
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <Modal
                width='660px'
                title="新增一级分类"
                wrapClassName='training_modal_box'
                visible={oneVisible}
                closable={false}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.checkCancel()}>取消</Button>
                        <Button loading={saveLoading} onClick={() => this.oneClassifySave()}
                                type='primary'>保存</Button>
                    </div>
                }
            >
                <Form {...formItemLayout}>
                    <Form.Item label='分类名称'>
                        {
                            getFieldDecorator('categoryName', {
                                initialValue: oneClassifyDetail.categoryName ? oneClassifyDetail.categoryName : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分类名称'
                                    },
                                    {
                                        max: 20,
                                        message: "分类名称不能超过20个字"
                                    },
                                ]
                            })(<Input placeholder='请输入分类名称' className="sold-box"
                                      suffix={<span
                                          className="suffixStyle">{getFieldValue('categoryName') ? getFieldValue('categoryName').length : "0"}/20</span>}/>)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

oneClassifyModel = Form.create()(oneClassifyModel)
export default oneClassifyModel;

