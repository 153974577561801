


import React from 'react';


//栏目管理
export default props => {
    return <svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.77504 9.47539C4.78828 9.47539 3.17188 7.85898 3.17188 5.87222C3.17188 3.88545 4.78828 2.26904 6.77504 2.26904C8.76185 2.26904 10.3783 3.88545 10.3783 5.87222C10.3788 6.31006 10.2993 6.74432 10.1435 7.15354L8.88543 6.67469C8.98289 6.41839 9.03262 6.14642 9.03216 5.87222C9.03216 4.62767 8.01964 3.6152 6.77509 3.6152C5.53055 3.6152 4.51808 4.62767 4.51808 5.87222C4.51808 7.11671 5.53055 8.12923 6.77509 8.12923C7.34276 8.12994 7.88971 7.91606 8.30632 7.53046L9.21981 8.51924C8.55234 9.13578 7.68418 9.47539 6.77504 9.47539Z" fill="#6A7E9C"/>
        <path d="M5.82422 5.90172C5.82422 6.1537 5.92431 6.39535 6.10248 6.57352C6.28066 6.75169 6.52231 6.85179 6.77428 6.85179C7.02625 6.85179 7.2679 6.75169 7.44607 6.57352C7.62424 6.39535 7.72434 6.1537 7.72434 5.90172C7.72434 5.64975 7.62424 5.4081 7.44607 5.22993C7.2679 5.05176 7.02625 4.95166 6.77428 4.95166C6.52231 4.95166 6.28066 5.05176 6.10248 5.22993C5.92431 5.4081 5.82422 5.64975 5.82422 5.90172Z" fill="#BABABA"/>
        <path d="M10.7053 13.727H2.84375C1.87883 13.727 1.09375 12.942 1.09375 11.977V2.01904C1.09375 1.05412 1.87883 0.269043 2.84375 0.269043H10.7053C11.6703 0.269043 12.4553 1.05412 12.4553 2.01904V11.977C12.4553 12.9419 11.6703 13.727 10.7053 13.727ZM2.84375 1.6152C2.62487 1.6152 2.4399 1.80016 2.4399 2.01904V11.977C2.4399 12.1959 2.62487 12.3809 2.84375 12.3809H10.7053C10.9242 12.3809 11.1091 12.1959 11.1091 11.977V2.01904C11.1091 1.80016 10.9242 1.6152 10.7053 1.6152H2.84375Z" fill="#6A7E9C"/>
        <path d="M9.08981 11.7924H4.45823C4.08653 11.7924 3.78516 11.4911 3.78516 11.1194C3.78516 10.7477 4.08653 10.4463 4.45823 10.4463H9.08981C9.46151 10.4463 9.76288 10.7477 9.76288 11.1194C9.76288 11.4911 9.46151 11.7924 9.08981 11.7924Z" fill="#BABABA"/>
    </svg>

}