import React, { Component } from 'react'
import { Button, Input, Table, Modal, Select, Form, message, Tooltip } from 'antd'
import MaxLengthInput from '../../../components/hhinput'

import { list, detail, remove, save, province, city, area, edit, pageListdara } from '../../../api/shixun2'
import './index.scss'
const { Option } = Select;
const Yichang = () => {
  return <div>
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 11.5L0 11.5C9.59018e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5L0 11.5Z" fill="#F3B714" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6516 6.32578V13.2258C12.6516 13.8609 12.1367 14.3758 11.5016 14.3758C10.8665 14.3758 10.3516 13.8609 10.3516 13.2258V6.32578C10.3516 5.69065 10.8665 5.17578 11.5016 5.17578C12.1367 5.17578 12.6516 5.69065 12.6516 6.32578ZM11.5016 17.8258C12.1367 17.8258 12.6516 17.3109 12.6516 16.6758C12.6516 16.0407 12.1367 15.5258 11.5016 15.5258C10.8664 15.5258 10.3516 16.0407 10.3516 16.6758C10.3516 17.3109 10.8664 17.8258 11.5016 17.8258Z" fill="white" />
    </svg>
  </div>
}
// 详情
function _get_query(name) {
  const search = window.location.href
  if (!search) return null
  const item = search.split('&').find(v => {
    return v.includes(name)
  });
  if (item) {
    const _item_arr = item.split('=')
    return _item_arr[1]
  } else {
    console.log(`${name} is not in search`)
    return null
  }
}

export class index extends Component {
  state = {
    visible: false,//查看
    addsup: false,//添加modal
    operation: false,//操作记录
    delete: false,//删除
    //三个搜索项
    _gongyingshang: "",
    _lianxiren: '',
    _phone: "",
    dataSource: [],//大表格
    dataSourceTable: [],//操作表格
    dataSourceloding: true,//表格loding
    dataSourceTableloding: true,//操作login
    isup: 'add',//新增编辑
    __detail: {},//详情
    supplierId: _get_query('supplierId'),
    _detailid: '',//删除id
    __province: [],//省
    _city: [],//市
    _area: [],//区
    editId: null,//编辑id
    // 操作记录
    pageNum: 1,
    pageSize: 10,
    total: 0,


  };
  // 查看
  showModal = async (id) => {
    const _detail = await detail({
      supplierId: id
    })
    this.setState({
      visible: true,
      __detail: _detail

    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
      __detail: {}//点击取消或ok的时候 设置为空

    });
  };
  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
      __detail: {}//点击取消或ok的时候 设置为空
    });
  };
  // 添加
  addsupModal = (v) => {
    if (this.state.isup === 'add') {

      this.setState(() => {
        return {
          addsup: true
        }
      })
    } else if (this.state.isup === 'edit') {

      this.setState(() => {
        return {
          addsup: true
        }
      }, () => {
        let bb = {
          supplierName: v.supplierName,
          provinceId: v.provinceId,
          cityId: v.cityId,
          areaId: v.areaId,
          supplierAddressDetail: v.supplierAddressDetail,
          supplierContact: v.supplierContact,
          supplierPhone: v.supplierPhone,
          supplierExplain: v.supplierExplain

        }
        this.props.form.setFieldsValue(bb)
      })
    }
  };


  addsupOk = e => {
    if (this.state.isup === 'add') {
      this.props.form.validateFields(["supplierName", 'provinceId', 'cityId', 'areaId', 'supplierAddressDetail', 'supplierContact', 'supplierPhone', 'supplierExplain'], async (err, values) => {

        if (!err) {
          const b = await save({
            supplierName: values.supplierName,
            provinceId: values.provinceId,
            cityId: values.cityId,
            areaId: values.areaId,
            supplierAddressDetail: values.supplierAddressDetail,
            supplierContact: values.supplierContact,
            supplierPhone: values.supplierPhone,
            supplierExplain: values.supplierExplain,
          })
          if (b.code === 0) {
            message.info('添加成功')
            this.getdata()
          }
          console.log(b, 'bb')
          this.setState(() => {
            return {
              addsup: false,
              editId: null,
            }
          });
        }
      })
    } else {
      this.props.form.validateFields(async (err, values) => {

        if (!err) {
          const { editId } = this.state
          const { code } = await edit({ ...values, supplierId: editId })
          if (code === 0) {
            this.getdata()
            message.success('修改成功')
          } else {
            message.warning('修改失败')
          }

          this.setState(() => {
            return {
              // dataSource: data,
              addsup: false,
              dataSourceloding: false,
              editId: null
            }
          })

        }
      })
    }
  }


  addsupCancel = e => {
    console.log(e);
    this.setState({
      addsup: false,
    });
  };
  // 操作记录
  operationModal = () => {
    // 操作表格
    this.getdataList({
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    })
    this.setState({
      operation: true,
    });
  };

  operationOk = e => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };

  operationCancel = e => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };
  // 删除Model

  deleteModal = id => {

    this.setState({
      delete: true,
      _detailid: id
    });
  };

  deleteOk = async v => {
    console.log(v);
    const { _detailid } = this.state
    await remove({ supplierId: _detailid }).then(res => {
      if (res.code === 0) {
        this.getdata()
        message.info('删除成功')
      } else {
        message.info('删除失败')
      }
    })
    this.setState({
      delete: false,
    });
  };

  deleteCancel = e => {
    console.log(e);
    this.setState({
      delete: false,
    });
  };
  // sekect选择省
  onChange = async (value) => {
    console.log(value, 'll')
    debugger
    this.props.form.setFieldsValue({ cityId: undefined, areaId: undefined })
    const _city = await city(
      {
        provinceCode: value
      }
    )

    this.setState(() => {
      return {
        _city: _city,


      }
    })
  }

  // select选择市
  city_onChange = async (value) => {
    this.props.form.setFieldsValue({ areaId: undefined })
    const _area = await area(
      {
        cityCode: value
      }
    )
    this.setState(() => {
      return {
        _area: _area,

      }
    })
  }


  // 请选择区 area
  area_onChange = (value) => {
    console.log(`selected ${value}`);
  }


  //表格的查询输入框
  headerSearch = (target) => {
    const { name, value } = target

    this.setState(() => {
      return {
        [name]: value
      }
    })
  }

  async componentDidMount() {
    // 大表格
    this.getdata()


  }



  // 获取大表戈的数据
  getdata = (par) => {
    return list(par).then(res => {

      if (res) {
        this.setState(() => {
          return {
            dataSource: res,
            dataSourceloding: false
          }
        })
      } else {
        message.info('没有数据')
      }
    })

  }
  getdataList = (pp) => {
    return pageListdara({
      operateType: 1,

      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    }).then(res => {
      console.log(res.data, ';;;')
      if (res && res.data) {
        this.setState(() => {
          return {
            dataSourceTable: res.data,
            total: res.total,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            dataSourceTableloding: false

          }

        })
      } else {
        message.info('数据错误')
      }
    })
  }
  //查询按钮的点击
  chaxun = () => {
    const { _gongyingshang, _phone, _lianxiren } = this.state
    // console.log(_gongyingshang, _phone, _lianxiren)
    this.getdata({
      supplierName: _gongyingshang,
      supplierPhone: _phone,
      supplierContact: _lianxiren
    })



  }
  // 重置按钮
  Reset = () => {
    this.setState(() => {
      return {
        _gongyingshang: undefined,
        _phone: undefined,
        _lianxiren: undefined
      }
    }, () => {
      this.getdata()
    })


  }

  //pageSize变化
  onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize, ":sdd")
    this.setState({
      pageNum: current,
      pageSize: pageSize,
    }, () => {
      this.getdataList({
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      });
    }
    );
  };

  //pageNum变化
  onPageNumChange = (current, pageSize) => {
    console.log(current, pageSize, ":sdd")
    this.setState({
      pageNum: current,
    }, () => {
      this.getdataList({
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      });
    }
    );
  };
  render() {
    const { RenderButton } = React
    const { dataSource, dataSourceTable, __detail } = this.state
    const { getFieldDecorator } = this.props.form;
    const formItemLayout_aaaa = {
      labelCol: {
        xs: { span: 3 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 3 },
        sm: { span: 10 },
      },
      // klgfjkfdljg
    };
    // 
    // Table
    const columns = [
      {
        title: '供应商名称',
        dataIndex: 'supplierName',
        align: "center",
        width: 180,
        render: (text, record, index) => <p>
          <Tooltip title={record.supplierName} placement="bottom" overlayClassName="TableTooltip">

            {record.supplierName && record.supplierName.length > 15 ? record.supplierName.substring(0, 15) + '...' : record.supplierName}
          </Tooltip>

        </p>,

      },
      {
        title: '所在区域',
        dataIndex: 'siteName',
        align: "center",
        width: 130

      },
      {
        title: '住址',
        dataIndex: 'supplierAddressDetail',
        align: "center",
        width: 230,
        render: (text, record, index) => <p>
          <Tooltip title={record.supplierAddressDetail} placement="bottom" overlayClassName="TableTooltip">

            {record.supplierAddressDetail && record.supplierAddressDetail.length > 10 ? record.supplierAddressDetail.substring(0, 10) + '...' : record.supplierAddressDetail}
          </Tooltip>

        </p>,


      },
      {
        title: '联系人',
        dataIndex: 'supplierContact',
        align: "center",
        width: 130

      },
      {
        title: '联系电话',
        dataIndex: 'supplierPhone',
        align: "center",
        width: 130

      },
      {
        title: '创建人',
        dataIndex: 'createUserName',
        align: "center",
        width: 130

      },
      {
        title: '添加时间',
        dataIndex: 'createTime',
        align: "center",
        width: 180

      },
      {
        title: '备注（供应商服务范围）',
        dataIndex: 'supplierExplain', align: "center",
        width: 330,

        render: (text, record, index) => <p>
          <Tooltip title={record.supplierExplain} placement="bottom" overlayClassName="TableTooltip">

            {record.supplierExplain && record.supplierExplain.length > 15 ? record.supplierExplain.substring(0, 15) + '...' : record.supplierExplain}
          </Tooltip>

        </p>,

      },
      {
        title: '操作',
        fixed: "right",
        render: (v, b, value) => {
          // console.log(v, b, 'vvvbbb')
// d
          return <div className='action vertical_j' style={{ cursor: 'pointer' }}>
            <RenderButton id="ABB-01-A01-b_btnView">
              <span onClick={() => {
                this.showModal(v.supplierId)
              }} >查看</span>
            </RenderButton>

            <RenderButton id="ABB-01-A01-b_btnEdit">
              <span onClick={() => {
                this.setState(() => {
                  return {
                    isup: 'edit',
                    // editId:'dd'
                  }
                }, async () => {
                  const __province = await province()
                  const _city = await city(
                    {
                      provinceCode: v.provinceId
                    }
                  )
                  const _area = await area(
                    {
                      cityCode: v.cityId
                    }
                  )

                  this.setState(() => {
                    return {
                      editId: v.supplierId,
                      __province: __province,
                      _city,
                      _area,

                    }
                  })
                  this.addsupModal(v)
                })
              }}>编辑</span>
            </RenderButton>

            <RenderButton id="ABB-01-A01-b_btnDel">
              <span onClick={() => {
                this.deleteModal(v.supplierId)
              }}>删除</span>
            </RenderButton>


          </div>
        },
        align: "center",
      },
    ];

    // 操作记录Table

    const columnsTable = [
      {
        title: '操作人',
        dataIndex: 'account',
        align: 'center'

      },
      {
        title: '供应商管理',
        dataIndex: 'operationDesc',
        align: 'center'
      },
      {
        title: '操作时间',
        dataIndex: 'createTime',
        align: 'center'
      },
      {
        title: '动作',
        dataIndex: 'actionDscp',
        align: 'center'
      },

    ];
    return (
      <div className="Provider_Management" >
        <div className="Provider_Management_top">
          <div className="Provider_Management_inp">
            <Input placeholder="供应商名称" name='_gongyingshang' onChange={(e) => this.headerSearch(e.target)} value={this.state._gongyingshang} />
            <Input className="inp" name='_lianxiren' onChange={(e) => this.headerSearch(e.target)} value={this.state._lianxiren} placeholder="联系人" />
            <Input className="inp" name='_phone' onChange={(e) => this.headerSearch(e.target)} value={this.state._phone} placeholder="联系电话" />
          </div>
          <div className="Provider_Management_button">
            <Button onClick={() => {
              this.Reset()
            }}>重置</Button>
            <Button type="primary" onClick={() => {
              this.chaxun()
            }}>查询</Button>
          </div>
        </div>
        {/* ****** */}
        <div className="Provider_Management_main" >
          <RenderButton id="ABB-01-A01-b_btnAdd">
            <Button type="primary" onClick={() => {
              this.setState(() => {
                return {
                  isup: 'add'
                }
              }, async () => {
                const __province = await province()
                this.setState(() => {
                  return {
                    __province: __province

                  }
                }, () => {
                  this.addsupModal()
                })

              })
            }}>添加</Button>
          </RenderButton>
          <RenderButton id="ABB-01-A01-b_btnOperatingRecord">
            <Button onClick={this.operationModal}>操作记录</Button>
          </RenderButton>



        </div>
        <div className="Provider_Management_buttom">
          <Table dataSource={dataSource} rowKey='supplierId' columns={columns} pagination={false} loading={this.state.dataSourceloding} 
          
         
          />
        </div>

        <Modal
          title={<div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}>设备供应商详情</div>}
          visible={this.state.visible}

          onCancel={this.handleCancel}
          bodyStyle={{ height: 350 }}
          width={660}
          centered
          wrapClassName='details'


          closable={false}
          footer={[


            <Button key="submit" type="primary" onClick={this.handleOk}>
              我知道了
            </Button>,]}

        >
          <p><span >供应商名称：</span>{__detail.supplierName}</p>
          <p><span>所在区域：</span>{__detail.siteName}</p>
          <p> <span>地址：</span>{__detail.supplierAddressDetail}</p>
          <p><span>联系人：</span>{__detail.supplierContact}</p>
          <p><span>联系电话：</span>{__detail.supplierPhone}</p>
          <p><span>备注：</span>{__detail.supplierExplain}</p>
        </Modal>
        {/* 添加 */}
        <Modal
          key={this.state.addsup}//强制更新
          title={<div div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}>
            {this.state.isup === "add" ? '添加供应商设备' : '修改供应商设备'}</div >}
          visible={this.state.addsup}
          onOk={() => {
            this.addsupOk()
          }}
          onCancel={this.addsupCancel}
          bodyStyle={{ height: 300,overflow:'auto' }}
          width={800}
          centered
          destroyOnClose
          closable={false}
          wrapClassName='details_add'
        >
          <Form {...formItemLayout_aaaa}>
            <Form.Item label="供应商名称">
              {getFieldDecorator('supplierName', {
                rules: [{ required: true, message: '请输入供应商名称' }],
              })(
                <MaxLengthInput placeholder='请输入供应商名称' style={{ height: 40, paddingRight: 50 }} maxLength={30}></MaxLengthInput>,
              )}
            </Form.Item>
            <Form.Item className='sheng_shi_qu'>
              <Form.Item label="所在区域">
                {getFieldDecorator('provinceId', {
                  rules: [{ required: true, message: '请输入省' }],
                })(
                  <Select
                    style={{ width: 200 }}
                    placeholder="请选择省"
                    optionFilterProp="children"
                    onChange={this.onChange}
                  >

                    {
                      this.state.__province.map((item, index) => {

                        return <Option key={item.provinceCode}>
                          {item.name}
                        </Option>
                      })

                    }

                  </Select>
                )}
              </Form.Item>

              <Form.Item label={undefined}>
                {getFieldDecorator('cityId', {
                  rules: [{ required: true, message: '请选择市' }],
                })(
                  <Select
                    showSearch
                    style={{ width: 200, marginLeft: 20 }}
                    placeholder="请选择市"
                    optionFilterProp="children"
                    onChange={this.city_onChange}

                  >
                    {
                      this.state._city.map((item, index) => {

                        return <Option key={item.cityCode}>
                          {item.name}
                        </Option>
                      })

                    }
                  </Select>
                )}
              </Form.Item>
              <Form.Item label={undefined}>
                {getFieldDecorator('areaId', {
                  rules: [{ required: true, message: '请选择区' }],
                })(
                  <Select
                    showSearch
                    style={{ width: 200, marginLeft: 20 }}
                    placeholder="请选择区"
                    optionFilterProp="children"
                    onChange={this.area_onChange}

                  >

                    {
                      this.state._area.map((item, index) => {

                        return <Option key={item.areaCode}>
                          {item.name}
                        </Option>
                      })

                    }
                  </Select>
                )}
              </Form.Item>
            </Form.Item>
            <Form.Item label="地址">
              {getFieldDecorator('supplierAddressDetail', {
                rules: [{ required: true, message: '请输入地址' }],
              })(
                <MaxLengthInput placeholder='请输入地址' style={{ height: 40, paddingRight: 50 }} maxLength={50}></MaxLengthInput>
              )}
            </Form.Item>
            <Form.Item label="联系人">
              {getFieldDecorator('supplierContact', {
                rules: [{ required: true, message: '请输入联系人' }],
              })(
                <MaxLengthInput placeholder='请输入供应商联系人' style={{ height: 40, paddingRight: 50 }} maxLength={10}></MaxLengthInput>
              )}
            </Form.Item>
            <Form.Item label="联系电话">
              {getFieldDecorator('supplierPhone', {
                rules: [{ required: true, message: '请输入手机号' },
                {
                  pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号'
                },

                ],
              })(
                <Input placeholder='请输入联系电话' style={{ height: 40 }}></Input>
              )}
            </Form.Item>
            {/* <span className="jj"> */}

            <Form.Item label="备注">
              {getFieldDecorator('supplierExplain', {
                rules: [{ required: false, message: '请输入备注' }]
              })(
                <MaxLengthInput placeholder='请输入备注' type={'textarea'} isTextarea={true}
                  style={{
                    height: 100,  resize: "none"
                  }} maxLength={100}></MaxLengthInput>
              )}
            </Form.Item>
            {/* </span> */}
          </Form>


        </Modal >

        {/* 操作记录 */}
        <Modal Modal
          title={<div div style={{ fontWeight: "bold", color: '#333', fontSize: 18 }}> 操作记录</div >}
          visible={this.state.operation}

          onCancel={this.operationCancel}
          bodyStyle={{ height: 300,overflow:'auto' }}
          width={1000}
          centered
          closable={false}
          wrapClassName='details_record'
          footer={
            [
              <Button key="submit" type="primary" onClick={this.operationOk}>
                我知道了
            </Button>,]}
        >
          <div>
            <Table dataSource={dataSourceTable} columns={columnsTable} loading={this.state.dataSourceTableloding}
              scroll={{ y: 350 }}
              pagination={
                {
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '30'],
                  showSizeChanger: true,
                  onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                  onChange: (a, b) => this.onPageNumChange(a, b),
                  current: this.state.pageNum,
                  pageSize: this.state.pageSize,
                  total: this.state.total,
                }
              } />
          </div>

        </Modal >
        {/* 删除 */}

        < Modal
          title={< div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}> 删除提示：{
            dataSource.find((v, i) => {
              return v.supplierId === this.state._detailid
            })?.supplierName

          }</div >}
          visible={this.state.delete}
          onOk={this.deleteOk}
          onCancel={this.deleteCancel}
          bodyStyle={{ height: 30 }}
          width={450}
          centered
          closable={false}
          wrapClassName='details_delete'

        >
          <div>
            <p style={{ display: 'flex' }}><span><Yichang></Yichang></span><span style={{ marginLeft: 15, color: '#333' }}>是否删除该供应商？</span></p>

          </div>

        </Modal >
      </div >
    )
  }
}

export default Form.create()(index);
