/**
 * 热门专业方向与专业方向
 */

import { post } from '../../until/axios_instans';
//热门专业方向
export function getSubjectList(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/operate/subject/getSpecialList", data);
}

//删除专业方向
export function delSubject(data) {
    return post(window.apiPrefix+'/api/v1/rcenter/operate/subject/delSpecial', data);
}

//更新专业方向排序
export function sortItem(data) {
    return post(window.apiPrefix+'/api/v1/rcenter/operate/subject/updateSpecialOrder', data);
}

//更新专业方向排序
export function updateSubject(data) {
    return post(window.apiPrefix+'/api/v1/rcenter/operate/subject/updateSpecial', data);
}

//添加专业方向
export function addSubject(data) {
    return post(window.apiPrefix+'/api/v1/rcenter/operate/subject/addSpecial', data);
}


