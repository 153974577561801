import React, { Component } from 'react'

export default class Course_class extends Component {
    render() {
        return (
            <div>
              course_class
            </div>
        )
    }
}
