import { post } from '@/until/axios_instans';


//列表
export function getList(data) {
    return post('/api/ucenter/api/v1/adminUser/userList', data)
}
//初始化密码
export function initPassword(data) {
    return post('/api/ucenter/api/v1/adminUser/resetPassword', data)
}
//删除
export function deleteItem(data) {
    return post('/api/ucenter/api/v1/adminUser/delAdminUser', data)
}
//修改用户
export function resetItem(data) {
    return post('/api/ucenter/api/v1/adminUser/addUser', data)
}
//添加用户
export function addItem(data) {
    return post('/api/ucenter/api/v1/adminUser/addUser', data)
}
//生成账号
export function createUser(data) {
    return post('/api/ucenter/api/v1/adminUser/getUserNum', data)
}
//用户详情
export function getUserDetail(data) {
    return post('/api/ucenter/api/v1/adminUser/getUserDetail', data)
}
//禁用启用用户
export function changeUserState(data) {
    return post('/api/ucenter/api/v1/adminUser/editUserStatus', data)
}
//禁用启用用户
export function userAllot(data) {
    return post('/api/ucenter/api/v1/adminUser/bindRole', data)
}
//角色下拉
export function roleSel(data) {
    return post('/api/ucenter/api/v1/adminUser/getRoleList', data)
}
//身份下拉
export function globalSel(data) {
    return post('/api/ucenter/api/v1/powerdic/type', data)
}
//机构下拉
export function getEnterprisList(data) {
    return post('/api/ucenter/api/v1/adminUser/getEnterprisList', data)
}

//获取数据范围
export function getDataArea(data) {
    return post('/api/ucenter/api/v1/adminUser/getDataArea', data);
}

//获取数据范围
export function setDataArea(data) {
    return post('/api/ucenter/api/v1/adminUser/setDataPermission', data);
}

//角色分配
export function bindRole(data) {
    return post('/api/ucenter/api/v1/adminUser/bindRole', data);
}

//权限菜单
export function jurisdictionList(data) {
    return post('/api/ucenter/api/v1/menu/upMenuList', data);
}

//选中的权限菜单
export function roleMenuId(data) {
    return post('/api/ucenter/api/v1/role/roleMenuId', data);
}

//系统列表
export function getSystemList(data) {
    return post('/api/ucenter/api/v1/menu/menuList', data);
}

//批量导入学员模板
export const template = '/api/ucenter/api/v1/adminUser/downUserTemplate';

//导入成员
export function toAction(data) {
    return post('/api/ucenter/api/v1/adminUser/importUsers', data);
}

//导出成员错误信息
export const errTemplate = '/api/ucenter/api/v1/adminUser/exportUsersError';