import {post} from '../../until/axios_instans'
//帮助中心 内容列表
export const questionPage=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/questionPage', data);
//分类下拉框
export const getTypeSelectList=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/getTypeSelectList', data);
//新增问题
export const addQuestionInfo=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/addQuestionInfo', data);
//问题删除
export const deleteQuestion=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/deleteQuestion', data);
//问题编辑回显
export const getQuestionById=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/getQuestionById', data);
//问题编辑保存
export const updateQuestionInfo=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/updateQuestionInfo', data);


//分类列表
export const getTypePage=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/getTypePage', data);
// 分类新增
export const addTypeInfo=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/addTypeInfo', data);
//分类编辑
export const updateTypeName=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/updateTypeName', data);
// 分类删除
export const deleteType=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/deleteType', data);
//分类状态
export const updateStatus=(data)=>post(window.ningxiaApiPrefix + '/api/v1/help/manage/updateStatus', data);




