import React, { useEffect, useState } from "react";
import { Modal, Button } from 'antd';
import S from 'gongyongicon';

import { getStudentInfo } from '../../api/school_settlement';

import style from './index.module.scss';

function ViewStudentInformation(props) {
    const {
        visible, onCancel,
        userId, enterpriseId,
    } = props;
    const [detailInfo, setDetailInfo] = useState({});
    useEffect(() => {
        async function fn() {
            const res = await getStudentInfo({ organization: enterpriseId, id: userId });
            setDetailInfo(res || {})
        }
        visible && fn();
    }, [visible])
    return (
        <Modal
            visible={visible}
            title="查看成员信息"
            className="global_modal"
            closable={false}
            getContainer={false}
            bodyStyle={{
                height: 490,
                overflow: 'auto'
            }}
            footer={<Button size="large" type="primary" onClick={onCancel}>关 闭</Button>}
        >
            <h1 className={style.title}><span style={{ position: 'relative', top: 3, left: -15 }}><S.SvgBluel /></span>学生个人信息</h1>
            <div className={style.information}>
                <div className={style.information_left}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>姓名</div>
                        <div className={style.information_value}>{detailInfo.fullName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>性别</div>
                        <div className={style.information_value}>{detailInfo.sexName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>出生日期</div>
                        <div className={style.information_value}>{detailInfo.birthday}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>证件类型</div>
                        <div className={style.information_value}>{detailInfo.cardTypeName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>学习层次</div>
                        <div className={style.information_value}>{detailInfo.learningLevelName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>籍贯</div>
                        <div className={style.information_value}>{detailInfo.province}-{detailInfo.city}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>邮箱</div>
                        <div className={style.information_value}>{detailInfo.email}</div>
                    </div>
                </div>
                <div className={style.information_right}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>手机</div>
                        <div className={style.information_value}>{detailInfo.telNum}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>国籍</div>
                        <div className={style.information_value}>{detailInfo.nationalityName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>年龄</div>
                        <div className={style.information_value}>{detailInfo.age}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>证件号码</div>
                        <div className={style.information_value}>{detailInfo.idCard}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>民族</div>
                        <div className={style.information_value}>{detailInfo.nationalsName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>政治面貌</div>
                        <div className={style.information_value}>{detailInfo.politicalOutlookName}</div>
                    </div>
                </div>
            </div>
            <div className={style.personal_profile}>
                <div className={style.lable}>个人简介</div>
                <div className={style.value}>{detailInfo.introduction}</div>
            </div>
            <h1 className={style.title}><span style={{ position: 'relative', top: 3, left: -15 }}><S.SvgBluel /></span>学生院校信息</h1>
            <div className={style.information}>
                <div className={style.information_left}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>学号</div>
                        <div className={style.information_value}>{detailInfo.jobNum}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>院系</div>
                        <div className={style.information_value}>{detailInfo.facultyName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>行政班级</div>
                        <div className={style.information_value}>{detailInfo.className}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>招生方式</div>
                        <div className={style.information_value}>{detailInfo.enrollStudentTypeName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>监护人电话</div>
                        <div className={style.information_value}>{detailInfo.guardianPhone}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>在校状态</div>
                        <div className={style.information_value}>{detailInfo.studentStatusName}</div>
                    </div>
                </div>
                <div className={style.information_right}>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>学制</div>
                        <div className={style.information_value}>{detailInfo.eduSystemName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>专业</div>
                        <div className={style.information_value}>{detailInfo.majorName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>学生来源</div>
                        <div className={style.information_value}>{detailInfo.studentSourceName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>招生对象</div>
                        <div className={style.information_value}>{detailInfo.enrollStudentObjName}</div>
                    </div>
                    <div className={style.information_item}>
                        <div className={style.information_lable}>户口类型</div>
                        <div className={style.information_value}>{detailInfo.studentLiveTypeName}</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ViewStudentInformation;