import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import '../classify-manage/index.scss';
import {firstSelect,secondSelect,addDiscoverCourse,updateCategory} from '@/api/craftsman-app/index'

const {Option} = Select;
class transferClassifyModel extends React.Component {
    state = {
        data:{},
        oldtransferVisible:false,
        TransferType:'',
        categoryParentId:'',
        newcategoryParentId:'',
        categoryId:'',
        oneClassifyList:[],  //一级分类列表
        twoClassifyList:[], //二级分类列表
        selectedRowKeys:[],
        categoryParentName:'',
        categoryName:'',
        courseName:'',
        discoverId:''
    }
    componentDidMount() {
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //添加
        if(nextProps.transferVisible&&nextProps.TransferType===1&&this.state.selectedRowKeys!==nextProps.selectedRowKeys){
            console.log('TransferType',nextProps.TransferType)


                this.setState({
                    selectedRowKeys:nextProps.selectedRowKeys,
                    TransferType:nextProps.TransferType,
                    oldtransferVisible:nextProps.transferVisible,
                    categoryParentId:''
                },()=>{this.firstSelect()})
            }
        //调整分类
        if(nextProps.transferVisible&&nextProps.TransferType===0&&this.state.courseName!==nextProps.courseName){
                {
                this.setState({
                    TransferType:nextProps.TransferType,
                    oldtransferVisible:nextProps.transferVisible,
                    categoryParentId:'',
                    categoryParentName:nextProps.categoryParentName,
                    categoryName:nextProps.categoryName,
                    courseName:nextProps.courseName,
                    discoverId:nextProps.discoverId
                },()=>{this.firstSelect()})
            }
        }
    }

    // 课程分类一级分类下拉列表
    async firstSelect() {
        const {newcategoryParentId} = this.state
        const res = await firstSelect({
            "categoryId" :newcategoryParentId
        })
        this.setState({
            oneClassifyList: res
        })
    }
    // 课程分类二级分类下拉列表
    async secondSelect() {
        const {newcategoryParentId} = this.state
        console.log("zzzz---",newcategoryParentId)
        const res = await secondSelect({
            "parentId" :newcategoryParentId
        })
        this.setState({
            twoClassifyList: res
        })
    }

    //一级分类查询
    oneClassifyChange = (value) => {
        this.setState({
            newcategoryParentId:value,
            categoryId:''
        }, () => {
            if (value!==null){
                this.secondSelect()
            }
        })
    };

    saveDiscover = (value) => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //添加
                if (this.state.TransferType === 1) {
                    let data = {categoryId: values.categoryId, courseCodeList: this.state.selectedRowKeys}
                    addDiscoverCourse(data).then((res) => {
                        if (res.code === 0) {
                            message.success("添加成功")
                            this.props.form.resetFields()//清空表单
                            this.props.checkCancel()
                        }
                    });
                } else
                {
                    let data = {categoryId: values.categoryId, discoverId: this.state.discoverId}
                    updateCategory(data).then((res) => {
                        if (res.code === 0) {
                            message.success("调整成功")
                            this.props.form.resetFields()//清空表单
                            this.props.checkCancel()
                        }
                    });

                }
            }
        })
    };

    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.form.setFieldsValue({newcategoryParentId:""})
        this.props.form.setFieldsValue({categoryId:""})
        this.props.checkCancel()
    }

    checkPreCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.form.setFieldsValue({newcategoryParentId:""})
        this.props.form.setFieldsValue({categoryId:""})
        this.props.checkPreCancel()
    }
    render() {
        const {saveLoading,TransferType,oneClassifyList,twoClassifyList,newcategoryParentId,categoryId,selectedRowKeys,categoryParentName,categoryName,courseName} = this.state;
        const {transferVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 14}
        };
        const {getFieldDecorator,getFieldValue} = this.props.form;
        return (
            <Modal
                title={TransferType===0? '调整课程分类' : '提示'}
                width='660px'
                wrapClassName='training_modal_box'
                visible={transferVisible}
                closable={false}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() =>TransferType==1?this.checkPreCancel():this.checkCancel()}>取消</Button>
                        <Button loading={saveLoading} onClick={() => this.saveDiscover()} type='primary'>保存</Button>
                    </div>
                }
            >
                <Form {...formItemLayout}>
                    <p className={'training_modal_info'}>{TransferType===0? '您将'+courseName+'课程从「'+categoryParentName+'」-「'+categoryName+'」转移到以下分类' : '您即将将'+selectedRowKeys.length+'门课程，添加到如下分类中：'}</p>
                    <Form.Item label='一级分类'>
                        {
                            getFieldDecorator('newcategoryParentId', {
                                initialValue: newcategoryParentId ? newcategoryParentId :undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择分类所属类别'
                                    },
                                ]
                            })(
                                <Select placeholder="请选择分类所属类别" onChange={(value) => this.oneClassifyChange(value)}>
                                    {oneClassifyList && oneClassifyList.length ? oneClassifyList.map((item, index) => {
                                        return <Option key={index} value={item.categoryId}>{item.categoryName}</Option>
                                    }) : ""}

                                </Select>)
                        }
                    </Form.Item>
                    <Form.Item label='二级分类'>
                        {
                            getFieldDecorator('categoryId', {
                                initialValue: categoryId ? categoryId : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择分类所属类别'
                                    },
                                ]
                            })(
                                <Select placeholder="请选择分类所属类别"   >
                                    {twoClassifyList && twoClassifyList.length ? twoClassifyList.map((item, index) => {
                                        return <Option key={index} value={item.categoryId}>{item.categoryName}</Option>
                                    }) : ""}

                                </Select>)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

transferClassifyModel = Form.create()(transferClassifyModel)
export default transferClassifyModel;
