import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import { updateRecommend,getRecommendTypeList} from '@/api/recommend/recommend'

const {Option} = Select


class SetRecommend extends React.Component {
  state = {
    saveLoading: false,
    visible: false,
    ifRecommend: 1,//1-推荐 0 - 不推荐
    recommendType: '',//推荐类型
    recommendTypeList:[],
    recommendTypeListValue: '',
    ids: [],
  }
  componentDidMount() {
    this.getRecommendTypeList()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.visible){
      this.setState({
        ids: nextProps.ids,
        visible: nextProps.visible
      })
    }
  }
  checkCancel = () => {
    this.props.form.resetFields("hotValue")//清空表单
    this.props.checkCancel()
  }
  //推荐栏目列表
  async getRecommendTypeList() {
    const recommendTypeList = await getRecommendTypeList({})
    this.setState({
      recommendTypeList: recommendTypeList
    })
  }
  recommendTypeListChange = (value) => {
    const recommendTypeList = this.state.recommendTypeList
    const recommendTypeListValue = recommendTypeList[value].name
    const recommendType = recommendTypeList[value].id
    this.setState({
      recommendTypeListValue: recommendTypeListValue,
      recommendType: recommendType
    });
  };
  //设置推荐类型
  freezeBatch = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const {ids, recommendType,ifRecommend} = this.state
        if (!ids.length) {
          message.warning('请选择要推荐的类型');
          return;
        }
        this.setState({
          saveLoading: true
        })
       await updateRecommend({
          "ids": ids,
          "recommendType": recommendType,
          "ifRecommend": ifRecommend
        })

        message.success('推荐成功');
        this.props.form.resetFields("hotValue")//清空表单
        this.setState({
          visible: false,
          saveLoading: false,
        })
        this.props.refreshData()
      }
    })
  }

  render() {
    const {saveLoading,recommendTypeList} = this.state;
    const {visible} = this.props
    const formItemLayout = {
      labelCol: {span: 0},
      wrapperCol: {span: 24},
    };
    const {getFieldDecorator} = this.props.form;
    return (
      <Modal
        title='设置推荐类型'
        width={'660px'}
        wrapClassName='modal_box'
        visible={visible}
        closable={false}
        footer={
          <div className='modal_btn_j'>
            <Button onClick={() => this.checkCancel()}>取消</Button>
            <Button loading={saveLoading} onClick={() => this.freezeBatch()} type='primary'>保存</Button>
          </div>
        }
      >
        <Form {...formItemLayout}>
          <Form.Item>
            {
              getFieldDecorator('hotValue', {
                rules: [{required: true, message: '设置推荐类型'}]
              })(<Select placeholder="设置推荐类型" onChange={this.recommendTypeListChange} style={{width: '500px'}}>
                {recommendTypeList && recommendTypeList.length ? recommendTypeList.map((item, index) => {
                  return <Option key={index} value={index}>{item.name}</Option>
                }) : ""}
              </Select>)
            }

          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

SetRecommend = Form.create()(SetRecommend)
export default SetRecommend;
