import React, { Component } from 'react';
import { Select, Button, Input, Table,Tabs, Modal, message } from 'antd';
import { getManagementInstitutionsList,generalImport,downTemplate,checkImportManageOrg} from '@/api/platform-business/index.js'
import Batch from '@/components/import';
import './index.scss';
import moment from "moment";
const { Option } = Select;
const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

// const Demo = () => (
//     <Tabs defaultActiveKey="1" onChange={callback}>
//         <TabPane tab="全部" key="1">
//         </TabPane>
//         <TabPane tab="未处理" key="2">
//         </TabPane>
//         <TabPane tab="已处理" key="3">
//         </TabPane>
//     </Tabs>
// );


export default class index extends Component {

    state = {
        total:0,
        pageNum: 1,
        pageSize: 5,
        dataSource: [],
        enterpriseName: '',
        Batch:false,
        errorMessage: '',
        btnActive: false,
    }

    componentDidMount() {
        this.getManagementInstitutionsList();
    }
    //获取列表
    getManagementInstitutionsList = () => {
        let params = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            enterpriseName: this.state.enterpriseName,
        }
        getManagementInstitutionsList(params).then(res => {
            console.log("列表返回res：：",res);
            if (res) {
                this.setState({
                    total: res.total,
                    dataSource: res.data
                })
            }
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.getManagementInstitutionsList());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.getManagementInstitutionsList());
        window.elGoTop && window.elGoTop('body');
    }
    //检索
    search = (e) => {
        this.setState({ enterpriseName: e }, () => this.getManagementInstitutionsList());
    }
    toStudent(){
        this.props.history.push("/main/platform-business/add-member")
    }
    // 导入成员弹窗取消
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    sure = (e) => {
        this.setState({ btnActive: true, fileId: e });
    }
    getFormData=(formData)=>{
        console.log("1111111111111",formData.get("file"))
        formData.set("enterpriseId",this.state.enterpriseId)
        this.setState({
            batchRef: formData
        },()=>{
            console.log("batchRef",this.state.batchRef)
        })
    }
    //导入时得确定按钮
    commitBatch = () => {
        //从子组件调用导入接口，更改为点击确定后上传了
        generalImport(this.state.batchRef).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('上传成功');
                this.toTeacherresult(res)
            }
        }).catch(err => {
            console.log("err",err)
        })
    }
    async toTeacherresult(res){
        let transParam = {
            recordId: res,
            enterpriseId:this.state.enterpriseId
        }
        let param = JSON.stringify(transParam);
        this.props.history.push(`/main/platform-business/vir-mechanismresults?${encodeURI(param)}`)
    }
    //查看详情
    institutionsDetail = record => {
        console.log("record",record)
        this.props.history.push(`/main/platform-business/member-detail?${encodeURI(JSON.stringify(record.id))}`);
    }
    render() {
        let { dataSource,total,pageSize,batch,errorMessage,btnActive} = this.state;
        const {RenderButton} = React;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>},
            {
                title: 'logo',
                dataIndex: 'enterpriseLogo',
                key: 'enterpriseLogo',
                align: 'center',
                render: (text, item, index) => {
                    if (item.enterpriseLogo !== null){
                        //不为空展示logo
                        return <div className='not_opens'>
                            <div className="img_top">
                                <img  src={window.$$uploadRc(item.enterpriseLogo)} alt="" />
                            </div>
                        </div>
                    }else{
                        return <div className='not_opens'>
                            <div className="img_top">
                                <img src={require('@/assets/img/not_open.png')} alt="" />
                            </div>
                        </div>
                    }
                }
            },
            {title: '管理机构名称', dataIndex: 'enterpriseName',key:'3', },
            {title: '简称', dataIndex: 'shorterForm',key:'4', },
            {title: '机构识别码', dataIndex: 'enterpriseCode',key:'5', },
            {title: '所属范围', dataIndex: 'manageRangeName', key:'6',},
            {title: '加入时间', dataIndex: 'settledDate', key: 'settledDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '到期时间', dataIndex: 'settledEndDate', key: 'settledEndDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '成员人数', dataIndex: 'membersNum',key:'9',},
            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:'200',
                render:(text, record)=> {
                   return <div className="op_span">
                        <div className="main">
                            <RenderButton id='OBS-OM-01-09-a_btnDetail'>
                            <span className="but" onClick={() => this.institutionsDetail(record)}>查看详情</span>
                            </RenderButton>
                            <RenderButton id='OBS-OM-01-09-a_btnLoad'>
                            <span className="but"  onClick={() => this.setState({ batch: true ,enterpriseId:record.id})}>导入成员</span>
                            </RenderButton>
                        </div>
                    </div>
                }},
        ];

        return (
            <div className='managestu_box_lt'>
                <div className="twolist">
                    <div className="top_list">
                        <div className="leftlist">
                        </div>
                        <div className="right_list">
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入虚拟机构名称' />
                        </div>
                    </div>
                    <div className="table_list">
                        <Table rowKey={v => v['enterpriseId']} pagination={
                            {
                                total,
                                pageSize:pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['5', '10', '20', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns} dataSource={dataSource} />
                    </div>
                    <Modal className='batch' visible={batch} title='学校信息导入'
                           closable={false}
                           footer={
                               <div style={{ width: "100%" }}>
                                   <div className='pos-btn'>
                                       <Button onClick={this.closeBatch}>取消</Button>
                                       <Button disabled={btnActive ? false : true} className={btnActive ? "" : "gray"}
                                               onClick={this.commitBatch}>确定</Button>
                                   </div>
                                   {errorMessage ?
                                       <div className='error'>
                                           <span>请下载查看</span>
                                           <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                       </div>
                                       : null}
                               </div>
                           }
                    >
                        <Batch
                            ref={ref => this.batchRef = ref}
                            getFormData={this.getFormData}
                            sure={this.sure}
                            reset={() => this.setState({ errorMessage: '', btnActive: false })}
                            template={downTemplate(1)}
                            pullError={(val) => this.setState(val)}
                            titleType='管理机构成员'
                        />
                    </Modal>
                </div>

            </div>
        )
    }
}
