import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Radio, DatePicker} from 'antd';
import './index.scss';
import {importRecordDetail} from "../../../../api/platform-school";
import {
    getCitySel,
    getProvinceSel,
    importVirtualOrgTeachers
} from '../../../../api/platform-business/index'
import moment from "moment";
const { Option } = Select;

class index extends Component {
    state = {
        dataSource: [],
        facultyList: [], // 院系list
        majorList: [], // 专业list
        isChoose: false,//修改弹窗
        isModify: false,//修改弹窗
        isProject: false,//修改弹窗
        isAdd: false,//不导入弹窗
        recordId: "",
        createTime: '',
        importUserName: '',
        fileName: '',
        peopleTotalNum: '',
        isTeacher:false,
        isDelete: false,// 删除弹层
        isRepassword: false,// 重置密码
        propertyList: [], // 教师类型下拉列表
        professionalCallList: [], // 职称下拉列表
        professionalCall: '', // 职称
        property: '', // 教师类型
        teacherNameOrNum: '', // 教师工号/教师名称
        propertyDicType: 19, // 教师类型字典中的类型
        professionalCallDicType: 16, // 职称字典中的类型
        nameLength: 0, // 新增弹框姓名长度
        positionLength: 0, // 新增弹框职务长度
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        yuanxiaoSel:[],
        orgMember: {}, //机构成员json  有是修改没是新增
        orgMemberId:"", //删除时用
        highestDegreeOption:[],//学位下拉
        highestEducationOption:[],//学历下拉
        cardTypeOption:[{
            code:'0',
            name:'身份证'
        },{
            code:'1',
            name:'护照'
        }],//证件下拉
        Batch:false,
        btnActive: false,
        errorMessage:'',
        batchRef:{},//文件
        importStatus:null,
        enterpriseId:'',
    }

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log("jsonparam",jsonparam)
        this.setState({
            recordId: jsonparam.recordId,
            enterpriseId: jsonparam.enterpriseId,
        },()=> {
            this.importRecordDetail()
        })
    }

    async importRecordDetail(){
        const param = {
            recordId: this.state.recordId
        }
        const res = await importRecordDetail(param)
        console.log('99999999=====》》》', res)
        this.setState({
            dataSource: res.importErrVoList,
            facultyList: res.facultyList,
            majorList: res.majorList,
            createTime: res.createTime,
            importUserName: res.importUserName,
            fileName: res.fileName,
            peopleTotalNum: res.peopleTotalNum,
            errRecordId: '',
            importStatus:res.importStatus
        })
    }
    //确定导入
    sureImport = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        importVirtualOrgTeachers({recordId:recordId,enterpriseId:this.state.enterpriseId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('导入成功');
                this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
                    console.log("重新拉取列表")
                    this.importRecordDetail()
                });
            }
        }).catch(err => {
            console.log("err",err)
        })
    }
    // 姓名输入框长度
    nameLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                nameLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                nameLength:value.length
            })
        } else {
            callback();
        }
    }

    // 姓名输入框长度
    positionLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                positionLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                positionLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号')
        } else {
            callback();
        }
    }

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        this.setState({ provinceCode: e, citySel: [] }, () =>
            this.getCitySel()
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        this.setState({
            cityId: e
        });
    }
    //查看弹窗
    view = (record) => {
        console.log("查看得传值",record)
        this.setState({
            isTeacher: true,
        },()=>{
            this.props.form.setFieldsValue({
                orgMemberName: record.orgMemberName,
                jobNum: record.jobNum,
                sex:record.sex,
                telNum:record.telNum,
                birthday:moment(record.birthday),
                nativePlace:record.nativePlace,
                email:record.email,
                cardType: "1",
                idCard:record.idCard,
                highestEducation:record.highestEducation?Number(record.highestEducation):"",
                highestDegree:record.highestDegree?Number(record.highestDegree):"",
                inductionTime:record.inductionTime,
                professionalCall:record.professionalCall?Number(record.professionalCall):"",
                identityName:record.identityName,
                position:record.position,
                roleId:record.roleId,
                cityName:record.nativePlace?record.nativePlace.split("-")[1]:"",
                provinceName:record.nativePlace?record.nativePlace.split("-")[0]:"",
            });
        });
    }
    // 不导入
    async handImport(record) {
        Modal.confirm({
            title: '温馨提示',
            content: '您确定不导入吗',
            okText: '确定',
            cancelText: '取消',
            onOk() {
            },
            onCancel() {
            }
        });
    }
    goBack = () => {
        this.props.history.push(`/main/platform-business/vir-teamanage?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId}))}`);
    }
    render() {
        let { dataSource,importUserName,createTime,fileName,isTeacher, nameLength,provinceSel,citySel,positionLength,yuanxiaoSel,highestDegreeOption,highestEducationOption,cardTypeOption,propertyList,importStatus } = this.state;
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '教师姓名', dataIndex: 'errUserName',key:'errUserName',width:'50', },
            {title: '手机', dataIndex: 'errTelNum',key:'errTelNum',width:'50', },
            {title: '工号/学号', dataIndex: 'errJobNum',key:'errJobNum',width:'50', },
            {title: '教师类型', dataIndex: 'errTeacherType', key:'errTeacherType',width:'50',},
            {title: '教师职称', dataIndex: 'errProfessionalCall',key:'errProfessionalCall', width:'50',},
            {title: '异常原因', dataIndex: 'errReason', key:'errReason',width:'50',},
            {title: '状态', align: 'center', render: (text, item, index ) =>  <span className='serial'>
                    {
                        item.errStatus === 1 ? '校验失败' : item.importStatus === 2 ? '校验成功':
                            item.errStatus === 3 ?  '导入成功' : item.errStatus === 4 ?  '不再倒入' : ''
                    }</span>,},
            // {
            //     title: '操作',
            //     key: 'operate',
            //     align:'center',
            //     fixed:'right',
            //     width:'200',
            //     render:(record)=><div className="op-span">
            //         <span className="but" onClick={() => this.view(record)}>修改</span>
            //         <span className="but" onClick={() => this.handImport(record)}>不导入</span>
            //     </div>},
        ];
        return (
            <div className='result_box'>
                <div className="oneline">
                    <div className="one">
                        <p>导入人：{importUserName}</p><p>导入时间：{createTime}</p><p>导入文件名称：{fileName}</p>
                    </div>
                    <div className="two">
                        <p>导入结果：</p><p className="ok">导入成功</p>
                    </div>
                    <div className="five">
                        {importStatus ===0?<Button type="primary" onClick={this.sureImport}>确认导入</Button>:
                            <Button type="primary" onClick={this.goBack}>返回</Button>}
                        <p>导入校验结果：{dataSource.length>0? "校验失败"+dataSource.length+"条":"暂无失败校验"}</p>
                    </div>
                </div>
                <div className="twoline">
                    <div className="topline">
                        <div className="left">
                            {/*<Button className="buttonl" type="primary">以下结果再次校验</Button>*/}
                            {/*<Button className="buttonm">以下结果再导入</Button>*/}
                        </div>
                        <div className="right">共失败{dataSource.length}人</div>
                    </div>
                    <div className="tablelist">
                        <Table  columns={columns} dataSource={dataSource} />
                    </div>
                </div>
                <Modal
                    title='修改教师信息'
                    visible={isTeacher}
                    destroyOnClose={true}
                    closable={false}
                    className='binding_modal'
                    footer={
                        <div className='foot_botton'>
                            <Button className="addbutton" onClick={this.addOrganizatioMembers}>确认添加</Button>
                            <Button className="delete" onClick={() => this.setState({ isTeacher: false })}>取消</Button>
                        </div>
                    }
                >
                    <div className="modaltitle">
                        个人信息
                    </div>
                    <Form className='lt_form_wapper'>
                        <Form.Item label={`姓名`}>
                            {getFieldDecorator('orgMemberName', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入姓名`,
                                    },
                                    /*   {
                                           validator:this.nameLength
                                       }*/
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{nameLength}/20</span>
                        </Form.Item>
                        <Form.Item label={`手机`}>
                            {getFieldDecorator('telNum', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入手机号码`,
                                    },
                                    {
                                        validator:this.validatePhone
                                    }
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入手机号码" style={{ width: 294 , marginTop:"4px"}}/>)}
                        </Form.Item>
                        <Form.Item className="sex" label={`性别`}>
                            {
                                getFieldDecorator('sex', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择性别'
                                        }
                                    ]
                                })(
                                    <Radio.Group style={{ width: '100%' }}>
                                        <Radio value={0}>男</Radio>
                                        <Radio value={1}>女</Radio>
                                    </Radio.Group>
                                )
                            }
                        </Form.Item>
                        <Form.Item className='date_input' label={`出生日期`}>
                            {getFieldDecorator('birthday', {

                            })(<DatePicker   placeholder='请选择出生日期' style={{width: '100%'}} />)}
                        </Form.Item>
                        <Form.Item className='date_input' label={`籍贯`}>
                            <div className="all">
                                {/*<div className="totle">*/}
                                {/*    <span className="title">籍贯</span></div>*/}
                                {getFieldDecorator('provinceName', {
                                })(
                                    <Select placeholder='请选择省'
                                            onChange={this.changeProvince}
                                    >
                                        {/*<Option value={''}>全部</Option>*/}
                                        {
                                            provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                        }
                                    </Select>
                                )}
                                {getFieldDecorator('cityName', {
                                })(
                                    <Select placeholder='请选择市'
                                            onChange={this.changeCity}
                                    >
                                        {/*<Option value={''}>全部</Option>*/}
                                        {
                                            citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                        }
                                    </Select>)}
                            </div>
                        </Form.Item>
                        <Form.Item label={`最高学历`}>
                            {
                                getFieldDecorator('highestEducation', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '最高学历不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选则最高学历'>
                                    {
                                        highestEducationOption && highestEducationOption.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`最高学位`}>
                            {
                                getFieldDecorator('highestDegree', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '最高学位不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选则最高学位'>
                                    {
                                        highestDegreeOption && highestDegreeOption.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`证件类型`}>
                            {getFieldDecorator('cardType', {
                            })(<Select placeholder='请选择证件类型'>
                                {
                                    cardTypeOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                }
                            </Select>)}
                        </Form.Item>
                        <Form.Item label={`证件号码`}>
                            {getFieldDecorator('idCard', {
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入证件号码",
                                    },
                                    {
                                        validator:this.IsCard
                                    }
                                ]
                            })(<Input  className="input" maxLength={100}/>)}
                        </Form.Item>
                        <div className="modaltitle">
                            机构信息
                        </div>
                        <Form.Item label={`工号/帐号`}>
                            <div className="all">
                                {/*<div className="totle">*/}
                                {/*    <span className='red'>*</span><span className="title">工号/帐号</span></div>*/}

                                {getFieldDecorator('jobNum', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请填写工号/帐号",
                                        },
                                    ],
                                })(<Input placeholder="请输入工号" style={{ width: 330 , marginTop:"4px"}}/>)}
                            </div>
                        </Form.Item>
                        <Form.Item className='date_input' label={`入职日期`}>
                            <div className="all" style={{width: '330px'}}>
                                {/*<div className="totle">*/}
                                {/*    <span className="title">入职日期</span></div>*/}
                                {getFieldDecorator('inductionTime ', {

                                })(<DatePicker placeholder='请选择入职日期' style={{width: '100%'}} />)}
                            </div>
                        </Form.Item>
                        <Form.Item label={`教师职称`}>
                            {
                                getFieldDecorator('professionalCall', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '教师职称不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选择教师职称'>
                                    {
                                        propertyList && propertyList.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`教师类型`}>
                            {
                                getFieldDecorator('roleId', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '教师类型不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选择教师类型'>
                                    {
                                        yuanxiaoSel && yuanxiaoSel.map(v => {
                                            return <Option value={v.roleId} key={v.roleId}>{v.roleName}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`邮箱`}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入邮箱",
                                    },
                                    {
                                        validator:this.validateInvestmail
                                    }
                                ]
                            })(<Input  className="input" maxLength={100}/>)}
                        </Form.Item>
                        <Form.Item label={`职务`}>
                            {getFieldDecorator('position', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入职务`,
                                    },

                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入职务" style={   { width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{positionLength}/30</span>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

index = Form.create()(index);
export default index;
