import React, { useState, useEffect } from "react";
import { Divider } from 'antd';
import { get } from 'lodash';
import AppAccessInfo from '../../components/app_access_info';
import { _get_query } from '../../components/method_tools';
import { getVmOrgDetail } from '../../api/school_settlement';

import style from './index.module.scss';
const data = [1, 1, 2, 12, 12]
function VirtualOrganizationDetail() {
    const id = _get_query('id');
    const [detailInfo, setDetailInfo] = useState({});
    useEffect(() => {
        async function fn() {
            const res = await getVmOrgDetail({ id });
            setDetailInfo(res);
        }
        id && fn();
    }, [])
    return (
        <div className={style.VirtualOrganizationDetail}>
            <h1 className={style.basicln_title}>虚拟机构基本信息</h1>
            <div className={style.DeploymentDetails}>
                <div className={style.DetailsLeft}>
                    <div className={style.BasicInformationItem}>
                        <div className={style.BasicInformationLable}>虚拟机构名称</div>
                        <div className={style.BasicInformationValue}>{detailInfo.enterpriseName}</div>
                    </div>
                    <div className={style.BasicInformationItem}>
                        <div className={style.BasicInformationLable}>学校logo</div>
                        <div className={style.BasicInformationValueLogo}>
                            <img style={{ width: 160, height: 90 }} src={window.$$imgSrc(detailInfo.enterpriseLogo)} alt="" />
                        </div>
                    </div>
                </div>
                <div className={style.DetailsRight}>
                    <div className={style.BasicInformationItem}>
                        <div className={style.BasicInformationLable}>虚拟机构识别码</div>
                        <div className={style.BasicInformationValue}>{detailInfo.enterpriseCode}</div>
                    </div>
                    <div className={style.BasicInformationItem}>
                        <div className={style.BasicInformationLable}>所属范围</div>
                        <div className={style.BasicInformationValue}>
                            {get(detailInfo, 'orgRangeNames', []).map((item, index) => {
                                return <span style={{ whiteSpace: 'nowrap' }}>{item}{index < get(detailInfo, 'orgRangeNames', []).length - 1 && '/'}</span>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ paddingLeft: 50 }} className={style.BasicInformationItem}>
                <div className={style.BasicInformationLable}>机构标签</div>
                <div className={style.InstitutionalLabel}>
                    {get(detailInfo, 'labelNames', []).map((item, index) => {
                        return <span key={index}>{item}</span>
                    })}
                </div>
            </div>
            <div style={{ padding: '0 30px' }}>
                <Divider dashed />
                <AppAccessInfo 
                    id={id}
                    rightObj={{
                        date:'OBSMM-01-A03-c-btnExtendedUseTime',
                        record:'OBSMM-01-A03-c-btnViewActivationRecords',
                        stop:'OBSMM-01-A03-c-btnForcedDeactivation',
                        url:'OBSMM-01-A03-c-btnModifyAccessAddress',
                        formal:'OBSMM-01-A03-c-btnBecomeOfficial',
                        open:'OBSMM-01-A03-c-btnKaiTongAgain',
                    }}
                />
            </div>
        </div>
    )
}

export default VirtualOrganizationDetail;