import React, { Component } from 'react';
import './index.scss';
import { Form, Input, Radio, Select, Button, message } from 'antd';
import { getEnterprisList, roleSel, globalSel, resetItem, getUserDetail } from '@/api/member/index.js';
import { getSearch } from '@/until/libs';
import setName from 'classnames';
const { Option } = Select;


class index extends Component {
    state = {
        enterprisList: [],
        statusList: [],
        roleList: [],
        identityName: '',  //身份名称
        item: {},
        userNum: '',
        isUp: true,
        cyxm:0
    }
    init = () => {
        let data = getSearch(this.props.location.search);
        if (data) {
            let { id } = data;
            getUserDetail({ id }).then(res => {
                if (res) {
                    this.setState({
                        userNum: res.userName,
                        identityName:res.identityName,
                        cyxm:res.fullName&&res.fullName.length,
                        id
                    })
                    this.props.form.setFieldsValue(
                        {
                            fullName: res.fullName,
                            telNum: res.telNum,
                            sex: res.sex,
                            organizationId: res.organizationId,
                            identityId: Number(res.identityId),
                            roleId: res.roleId,
                        }
                    )
                }
            })
        }
    }
    async componentDidMount() {
        this.getEnterprisList();
        this.getRoleSel();
        await this.getStatusSel();
        await this.init();
    }
    //机构下拉
    getEnterprisList = () => {
        getEnterprisList().then(res => {
            if (res) {
                this.setState({ enterprisList: res });
            }
        })
    }
    //角色下拉
    getRoleSel = () => {
        roleSel().then(res => {
            if (res) {
                this.setState({ roleList: res });
            }
        })
    }
    //身份下拉
    getStatusSel = () => {
        globalSel({ type: 7 }).then(res => {
            if (res) {
                this.setState({ statusList: res })
            }
        })
    }
    change = code => {
        let value = this.state.statusList.find(v => v.code === code);
        if (value) {
            this.setState({ identityName: value.name });
        }
    }
    //保存
    save = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (!this.state.isUp) {
                    return;
                }
                this.setState({ isUp: false });
                setTimeout(() => this.setState({ isUp: true }), 2000)
                resetItem({
                    ...values,
                    platform: '123',
                    identityName: this.state.identityName,
                    id: this.state.id
                }).then(res => {
                    if (res) {
                        message.success('修改成功');
                        this.props.history.push('/main/capacity-auth/member')
                    }
                })
            }
        })
    }
    setLen=val=>this.setState(val)
    render() {
        const { getFieldDecorator } = this.props.form;
        let { enterprisList, statusList, roleList, userNum ,cyxm} = this.state;
        return (
            <div className='edit_member_box'>
                <h2 className='title'>编辑成员</h2>
                <div className="wrapper">
                    <Form>
                        <Form.Item>
                            <div className='form_item w_100_ipt'>
                                <span style={{ textAlign: 'right' }}><span className='red'>*</span>成员姓名</span>
                                {
                                    getFieldDecorator('fullName', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入成员姓名'
                                            }
                                        ]
                                    })(
                                        <Input 
                                        onChange={e => this.setLen({ cyxm: e.target.value.length })} 
                                        suffix={<span className='len'><span className={setName('current_len', cyxm === 0 ? 'zero' : '')}>{cyxm}</span>/20</span>}
                                        placeholder='请输入成员姓名'
                                         />
                                    )
                                }
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className='form_item'>
                                <span style={{ textAlign: 'right' }}><span className='red'>*</span>手机号码</span>
                                {
                                    getFieldDecorator('telNum', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入手机号码'
                                            },
                                            {
                                                pattern: /^1\d{10}$/,
                                                message: '请填写正确的手机号'
                                            }
                                        ]
                                    })(
                                        <Input placeholder='请输入手机号码' />
                                    )
                                }
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className='form_item'>
                                <span style={{ textAlign: 'right' }}><span className='red'>*</span>性别</span>
                                {
                                    getFieldDecorator('sex', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择性别'
                                            }
                                        ]
                                    })(
                                        <Radio.Group>
                                            <Radio value={0}>男</Radio>
                                            <Radio value={1}>女</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className='form_item'>
                                <span style={{ textAlign: 'right' }}><span className='red'>*</span>所属机构</span>
                                {
                                    getFieldDecorator('organizationId', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择所属机构'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择所属机构'>
                                            {
                                                enterprisList.map(v => {
                                                    return <Select.Option value={v.id} key={v.id}>{v.enterpriseName}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </div>
                        </Form.Item>
                        <div className='xian'></div>
                        <Form.Item>
                            <div className='form_item'>
                                <span><span className='red' style={{ opacity: 0 }}>*</span>成员账号</span>
                                <p style={{ display: 'inline-block' }}>{userNum}</p>
                            </div>
                        </Form.Item>
                        {/* <Form.Item>
                            <div className='form_item'>
                                <span><span className='red' style={{ opacity: 0 }}>*</span>账号密码</span>
                                <p style={{ marginRight: '22px', marginBottom: 0 }}>*******</p>
                                <p style={pStyle}><img src={require('@/assets/img/warning.png')} style={{ marginRight: '6px' }} alt='' />密码为用户手机尾号后6位</p>
                            </div>
                        </Form.Item> */}
                        <Form.Item>
                            <div className='form_item'>
                                <span><span className='red'>*</span>设置身份</span>
                                {
                                    getFieldDecorator('identityId', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择成员身份'
                                            }
                                        ]
                                    })(
                                        <Select onChange={this.change} placeholder='请选择成员身份'>
                                            {
                                                statusList.map(v => {
                                                    return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className='form_item'>
                                <span><span className='red'>*</span>设置角色</span>
                                {
                                    getFieldDecorator('roleId', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择成员角色'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择成员角色'>
                                            {
                                                roleList.map(v => {
                                                    return <Option value={v.id} key={v.id}>{v.roleName}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div className="fix_controll">
                    <Button className='add_btn_j' onClick={this.save} style={{ marginRight: '40px', width: '100px', height: '39px' }}>保存</Button>
                    <Button onClick={() => { this.props.history.push('/main/capacity-auth/member') }} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>
            </div>
        )
    }
}

index = Form.create()(index);
export default index;