import {Col, Form, Input} from "antd";
import React from "react";
class AddTags extends React.Component {
    state = {
        tagBqName:''
    }
    componentDidMount() {
        this.setState({
            tagBqName:this.props.tagBqName
        })
    }
    render() {
        const {getFieldDecorator} = this.props.form;

        return (
            <>
                <Form>
                    <Form.Item>
                        <div className='row_div'>
                            <Col span={4}><span style={{lineHeight: '40px'}}><span className='red'>*</span>标签名称</span></Col>
                            <Col span={15}>
                                <div className='row_div_right'>
                                    {
                                        getFieldDecorator('tagBqName', {
                                            initialValue: this.state.tagBqName,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '标签名称不能为空'
                                                },
                                                {
                                                    pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                                                    message: '禁止输入特殊字符',
                                                }
                                            ],
                                            getValueFromEvent: event => {
                                                return event.target.value.replace(/\s+/g, '')
                                            }
                                        })(
                                            <Input placeholder='请输入新闻标题'
                                                   maxLength={10}
                                                   suffix={<span>
                                                {this.props.form.getFieldValue(`tagBqName`) ? this.props.form.getFieldValue(`tagBqName`).length : 0}/10</span>}/>
                                        )
                                    }
                                </div>
                            </Col>
                        </div>
                    </Form.Item>
                </Form>
            </>
        )
    }
}


AddTags = Form.create()(AddTags);
export default AddTags;
