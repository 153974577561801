import { get, post } from "@/until/axios_instans";

export function getOssToken() {
    return get('/api/gongyong/api/v1/oss/ossToken');
}

export const uploadFile = data => {
    return post('/api/fujian/api/v1/fujian/uploadFile', data);
}

export function download(id,filename) {
    return get(`/api/fujian/api/v1/fujian/download/${id}/${filename}`);
}

// 公共图片的
export function getBaseCover(data) {
    return post("/api/gongyong/api/v1/gongyong/cover/getBaseCover", data);
}

