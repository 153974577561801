
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Button, Input, Col, Modal, Row, Form } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import kindName from 'classnames';
import { DndProvider } from 'react-dnd';
import MyTable from '@/components/Table';
import { set_visibily, moveFn, set_drab } from '@store/OBS/label/action';


const mapStateToProps = ({ LABEL }) => {
    const { dataSource, columns, visibily, isDrab } = LABEL;
    return {
        dataSource,
        columns,
        visibily,
        isDrab,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_visibily(data) {
            dispatch(set_visibily(data))
        },
        moveFn(data) {
            dispatch(moveFn(data));
        },
        set_drab(data) {
            dispatch(set_drab(data));
        }
    };
}


let Label = props => {

    const { columns, dataSource, visibily, isDrab } = props;
    const { moveFn, set_visibily, set_drab } = props;

    const saveSort = () => {
        set_drab(false);
    }
    //拖拽事件
    const moveRow = (dragIndex, hoverIndex) => {
        moveFn({ dragIndex, hoverIndex });
    };
    const save = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log(values);
                set_visibily(false);
            }
        })
    }
    const sp = <span>1/15</span>;
    const { getFieldDecorator } = props.form;
    return <div className='label_box bg_fff p20'>
        <div className="top" style={{ display: isDrab ? 'none' : 'flex' }}>
            <Col span={12}>
                <Button className='sure_sort' type='primary' onClick={() => set_visibily(true)}>添加标签</Button>
                <span className='sort' onClick={() => set_drab(true)}>🐕 标签排序</span>
            </Col>
        </div>
        <div className="top" style={{ display: isDrab ? 'flex' : 'none' }}>
            <Col span={12}>
                <Button className='sure_sort' type='primary' onClick={saveSort}>保存排序</Button>
                <Button className='cancel_sort' onClick={() => set_drab(false)}>取消排序</Button>
                {/* <span className='save'>🐕拖拽后请保存</span> */}
            </Col>
        </div>
        {/* <div className="alert" style={{ display: isActive ? 'flex' : 'none' }}>
        <span>
            🐕 查询到20个标签
        </span>
        <span onClick={() => setState({ isActive: false })}>
            X
        </span>
    </div> */}
        <div className="label_container">
            <DndProvider backend={HTML5Backend}>
                <MyTable
                    isDrab={true}
                    options={
                        {
                            columns,
                            dataSource,
                            onRow: (record, index) => ({
                                index,
                                moveRow,
                            }),
                            pagination: false
                        }
                    }
                />
            </DndProvider>
        </div>
        <Modal
            title='添加标签'
            wrapClassName='modal_box'
            visible={visibily}
            closable={false}
            footer={
                <div className='modal_btn_j'>
                    <Button onClick={() => set_visibily(false)}>取消</Button>
                    <Button onClick={save} type='primary'>保存</Button>
                </div>
            }
        >
            <Form>
                <Form.Item>
                    <Row>
                        <Col span={5}>
                            <span><span className='red'>*</span>标签名称</span>
                        </Col>
                        <Col span={15}>
                            {
                                getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入标签名称'
                                        }
                                    ]
                                })(<Input placeholder='请输入标签名称' suffix={sp} />)
                            }
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}

Label = connect(mapStateToProps, mapDispatchToProps)(Label);
Label = Form.create()(Label);
export default Label;