import React, { Component } from 'react';
import {Form, Radio, Select, Button, DatePicker, Input, Table, Modal, Tag, Col,Row } from 'antd';
import './index.scss';
import { getSchoolList, globalSel, getProvinceSel, getCitySel, appenter, downTemplate, selectFaculty, updateFaculty,importSchool, getSchoolDetail, insertAndUpdateOpenMessage} from '@/api/platform-business/index.js'
import Batch from '@/components/import';
import FormAlike from "../../../../components/FormAlike";
import ConsolextMoadl from "../../../../components/consolextMoadl";
import moment from "moment";
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
};

// 我应用接入定义数据
const { CheckableTag } = Tag;
const tagsData = [];

class index extends Component {
    state = {
        total:0,
        pageNum: 1,
        pageSize: 5,
        schoolRunMode: undefined,   //办学方式
        schoolRunLevel: undefined,   //办学层次
        schoolRunType: undefined,    //办学类型
        searchVal: '',
        dataSource: [],
        fangshiSel: [],
        cengciSel: [],
        leixingSel: [],
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        columns: [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '学校名称', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'center',},
            {title: '学校类型', dataIndex: 'schoolTypeName', key: 'schoolTypeName', align: 'center',},
            {title: '所在地', dataIndex: 'provinceName', key: 'provinceName', align: 'center',},
            {title: '办学层次', dataIndex: 'schoolRunLevelName', key: 'schoolRunLevelName', align: 'center',},
            {title: '加入日期', dataIndex: 'settledDate', key: 'settledDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '到期日期', dataIndex: 'settledEndDate', key: 'settledEndDate', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '状态', align: 'center', render: (text, item, index) =>  <span className='serial'>试用({item.onTrialNum})  正式({item.formalNum})</span>,},
            {
                title: '操作',  align: 'center', fixed: 'right',
                width: 220,
                render: (text, record) => {
                    const {RenderButton} = React;
                    return <p className='action vertical_j'>
                        {/*<span onClick={() => this.props.history.push(`/transResource/temporaryProject/${record.resourceId}`)}>查看</span>*/}
                        <div>
                            <RenderButton id='OBS-OM-01-02-a_btnDetail'>
                                <span onClick={() => this.lookDetil(record)}>进入详情</span>
                            </RenderButton>
                            <RenderButton id='OBS-OM-01-02-a_btnEdit'>
                            <span onClick={() => this.edit(record)}>编辑信息</span>
                            </RenderButton>
                        </div>
                        <div>
                            <RenderButton id='bOBS-OM-01-02-a_tnConfig'>
                            <span onClick={() => this.isApplication(record)}>配置应用</span>
                            </RenderButton>
                            {/*<span onClick={() => this.setState({ isSchool: true })}>院系管理</span>*/}
                            <RenderButton id='OBS-OM-01-02-a_btnDep'>
                            <span onClick={() => this.isSchool(record)}>院系管理</span>
                            </RenderButton>
                        </div>
                        <div>
                            <span onClick={() =>  this.isSynchro(record)}>同步到教管系统</span>
                        </div>
                    </p>
                }
            }
        ],
        batch: false,
        errorMessage: '',
        btnActive: false,
        fileName: '',
        isApplication: false, // 应用接入弹层
        selectedTags: [], // 接入应用选择数据
        isSchool: false, // 院系管理弹层
        is_input: true, // 院系管理是否可编辑
        applicationList: [], // 应用列表
        oftenFlag: true, // 应用接入连点控制
        facultyList: [],
        associated: [],
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        istbjg: false, // 教管系统弹层
        enterpriseInfo: '',
        synchroFlag: true, // 同步连点控制
    }
    componentDidMount() {
        this.getSchoolList();
        this.getFangshiSel();
        this.getCengciSel();
        this.getLeixingSel();
        this.getProvinceSel();
    }
    //打开应用接入弹窗
    isApplication = item => {
        this.setState({ isApplication: true, enterpriseId: item.id},()=>{
            // setTimeout(()=>{
            //     this.appForm.props.form.setFieldsValue({
            //         accessType:item.accessType
            //     })
            // })
        })
        sessionStorage.setItem('enterpriseId', item.id);
    }
    selectedTags = (e) => {
        this.setState({ selectedTags: e });
    }
    //同步到教育管理系统
    synchroEduSys = () => {
        let {enterpriseId} = this.state
        this.synchroForm.props.form.validateFields((err, values) => {
            if (!err) {
                let { synchroFlag,enterpriseInfo } = this.state;
                console.log('enterpriseInfo', enterpriseInfo)
                console.log('values',values)
                if (values.eduSysDate.isAfter(values.eduSysEndDate)) {
                    return this.mesWarning('开始日期不能小于结束日期')
                }
                if (values.eduSysDate.isBefore(moment(enterpriseInfo.settledDate)) || moment(enterpriseInfo.settledEndDate).isBefore(values.eduSysEndDate)) {
                    return this.mesWarning('教管系统开通周期不可超过学校开通周期范围')
                }
                if (synchroFlag) {
                    this.setState({ synchroFlag: false });
                    // 开始调用接口同步, 先保存同步时修改后的同步时间
                    insertAndUpdateOpenMessage(
                      {
                          ...values,
                          isSynchron: 1,
                          enterpriseId: enterpriseId
                      }
                    ).then(res => {

                    })
                    setTimeout(() => this.setState({ synchroFlag: true, istbjg: false }), 2500)
                }
            }
        })
    }
    //应用接入
    save = () => {
        console.log('appDelay', this)
        this.appForm.props.form.validateFields((err, values) => {
            if (!err) {
                let { oftenFlag,selectedTags,enterpriseId,userInfo } = this.state;
                if (selectedTags.length === 0) {
                    return this.mesWarning('请选择应用');
                }
                if (oftenFlag) {
                    this.setState({ oftenFlag: false });
                    let list = [];
                    selectedTags.forEach(function(item,index){
                        let app = {accessType: values.accessType,startDate: values.startDate,endDate: values.endDate,id: item.id};
                        list.push(app)
                    });
                    appenter(
                      {
                          enterpriseId: enterpriseId,
                          list: list,
                          createUserId: userInfo.id,
                          updateUserId: userInfo.id,
                      }
                    ).then(res => {
                        if (res) {
                            this.mesSuccess('配置成功');
                            this.getSchoolList()
                        }
                    })
                    setTimeout(() => this.setState({ oftenFlag: true, isApplication: false }), 2500)
                }
            }
        })
    }
    //打开院系弹窗和同步到教管系统
    isSchool = (item) => {
        this.setState({ isSchool: true, schoolName: item.enterpriseName, is_input: true})
        this.facultyList(item)
        // type = 0 打开院系弹窗, type =1打开同步到教管系统弹层

    }
    // 打开同步教育管理系统弹窗
    isSynchro = (item) => {
        if (item) {
            getSchoolDetail({ enterpriseId: item.id }).then(res => {
                console.log(res)
                if (res) {
                    this.setState({ enterpriseInfo: res, istbjg: true, enterpriseId: item.id})
                }
            })
        }
    }
    //院系列表
    facultyList = async (item) => {
      let res = await selectFaculty({enterpriseId: item.id});
      res && this.setState({ facultyList: res});
    }
    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        this.setState({ provinceCode: e, citySel: [] }, () => {
            this.getCitySel()
            this.getSchoolList()
        })
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        if(item.props.value === ''){
            this.setState({
                cityId: item.props.value,
            }, () => this.getSchoolList());
        } else {
            this.setState({
                cityId: e,
            }, () => this.getSchoolList());
        }
        // this.setState({ cityId: e}, () => this.getSchoolList());
    }
    //办学方式Sel
    getFangshiSel = () => {
        globalSel({ type: 2 }).then(res => {
            if (res) {
                this.setState({ fangshiSel: res });
            }
        })
    }
    //办学层次Sel
    getCengciSel = () => {
        globalSel({ type: 3 }).then(res => {
            if (res) {
                this.setState({ cengciSel: res });
            }
        })
    }
    //办学类型Sel
    getLeixingSel = () => {
        globalSel({ type: 4 }).then(res => {
            if (res) {
                this.setState({ leixingSel: res });
            }
        })
    }
    //获取列表
    getSchoolList = () => {
        let params = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            enterpriseName: this.state.enterpriseName,
            schoolType: this.state.schoolType,
            provinceId: this.state.provinceCode,
            cityId: this.state.cityId,
            schoolRunLevel: this.state.schoolRunLevel,
            accessType: this.state.accessType,
        }
        getSchoolList(params).then(res => {
            if (res) {
                this.setState({
                    total: res.total,
                    dataSource: res.data
                })
            }
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.getSchoolList());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.getSchoolList());
        window.elGoTop && window.elGoTop('body');
    }
    //检索
    search = (e) => {
        this.setState({ enterpriseName: e }, () => this.getSchoolList());
    }
    //新增
    add = () => {
        sessionStorage.setItem("school_type", 'add');
        sessionStorage.setItem('enterpriseId', '');
        this.props.history.push('/main/platform-business/add-school');
    }
    //编辑
    edit = item => {
        sessionStorage.setItem('enterpriseId', item.id);
        sessionStorage.setItem("school_type", 'edit');
        this.props.history.push('/main/platform-business/add-school');
    }
    //查看详情
    lookDetil = item => {
        sessionStorage.setItem('enterpriseId', item.id);
        // sessionStorage.setItem("school_type", 'edit');
        this.props.history.push('/main/platform-business/detil-school');
    }
    faculty(e, id) {
        let { facultyList } = this.state;
        let value = e.target.value;
        facultyList.forEach(e=>{
            if (e.id === id) {
                e.facultyName = value
            }
        })
        this.setState({
            facultyList
        })
    }
    facultyUpdate = () => {
        let { facultyList } = this.state;
        let facultyUpdateList = [];
        for(let i=0;i<facultyList.length;i++){
            facultyUpdateList.push({facultyId: facultyList[i].id, facultyName: facultyList[i].facultyName})
        }
        updateFaculty(
          {
              facultyUpdateList: facultyUpdateList
          }
        ).then(res => {
            if (res) {
                this.mesSuccess('修改成功');
                this.setState({ isSchool: false, is_input: true })
            }
        })
    }
    getFormData=(formData)=>{
        console.log("1111111111111",formData.get("file"))
        formData.set("id","")
        this.setState({
            batchRef: formData
        },()=>{
            console.log("batchRef",this.state.batchRef)
        })
    }
    sure = (e) => {
        this.setState({ btnActive: true, fileId: e });
    }
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    saveBatch = () => { // 管理机构信息导入
        let fileValue = this.state.batchRef;
        console.log("file",fileValue)
        importSchool(this.state.batchRef).then(res => {
            if (res) {
                this.mesSuccess('导入成功');
                this.getSchoolList()
            }
        }).catch(err => {
            console.log(err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    render() {
        let { columns, dataSource, leixingSel, facultyList, cengciSel,provinceSel, citySel, batch, btnActive, errorMessage,istbjg,isApplication,isSchool,is_input,schoolName,total,pageSize,showType,enterpriseInfo } = this.state;
        const {RenderButton} = React;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
          <div className='in_school_box is_in_school'>
              <div className="filter_controll">
                  <div className="item_wapper" style={{marginBottom: '20px'}}>
                      <div>
                          <span style={{ marginRight: '20px' }}>学校类型</span>
                          <Select placeholder='请选择学校类型' onChange={e => {
                              this.setState({ schoolType: e }, () => this.getSchoolList())
                          }} >
                              <Option value={''}>全部</Option>
                              {
                                  leixingSel && leixingSel.map(v => {
                                      return <Option value={v.code} key={v.code}>{v.name}</Option>
                                  })
                              }
                          </Select>
                      </div>
                      <div>
                          <span style={{ marginRight: '20px' }}>办学层次</span>
                          <Select placeholder='请选择办学层次' onChange={e => {
                              this.setState({ schoolRunLevel: e }, () => this.getSchoolList())
                          }} >
                              <Option value={''}>全部</Option>
                              {
                                  cengciSel && cengciSel.map(v => {
                                      return <Option value={v.code} key={v.code}>{v.name}</Option>
                                  })
                              }
                          </Select>
                      </div>
                      <div>
                          <span style={{ marginRight: '20px' }}>状态</span>
                          <Select placeholder='请选择状态' onChange={e => {
                              this.setState({ accessType: e }, () => this.getSchoolList())
                          }} >
                              <Option value={''}>全部</Option>
                              <Option value={0}>正式</Option>
                              <Option value={1}>试用</Option>
                          </Select>
                      </div>
                  </div>
                  <div>
                      <span style={{ marginRight: '20px',letterSpacing: '6px' }}>所在地</span>
                      <Select
                        style={{width: '260px',marginRight: '60px'}}
                        onChange={this.changeProvince}
                        placeholder='请选择省'>
                          <Option value={''}>全部</Option>
                          {
                              provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                          }
                      </Select>
                      <Select
                        style={{width: '260px',marginRight: '60px'}}
                        onChange={this.changeCity}
                        placeholder='请选择市'
                      >
                          <Option value={''}>全部</Option>
                          {
                              citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                          }
                      </Select>
                  </div>
              </div>
              <div className="in_school_content">
                  <div className='top'>
                      <div className="left">
                          <RenderButton id='OBS-OM-01-02-a_btnLoad'>
                          <Button className='add_btn_j' onClick={() => this.setState({ batch: true })} style={{ marginRight: '20px' }} >导入学校</Button>
                          </RenderButton>
                          <RenderButton id='OBS-OM-01-02-a_btnAdd'>
                              <Button className='add_btn_j no_color' onClick={this.add}>创建学校</Button>
                          </RenderButton>
                      </div>
                      <div className="right">
                          <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入学校名称' />
                      </div>
                  </div>
                  <div className="bottom">
                      <Table  columns={columns} dataSource={dataSource} pagination={
                          {
                              total,
                              pageSize:pageSize,
                              onChange: this.pageChange,
                              onShowSizeChange: this.onShowSizeChange,
                              pageSizeOptions: ['5', '10', '20', '40'],
                              showSizeChanger: true,
                              // position:'bottomRight'
                          }
                      }/>
                  </div>
              </div>
              <Modal className='batch' visible={batch} title='学校信息导入'
                     closable={false}
                     footer={
                         <div style={{ width: "100%" }}>
                             <div className='pos-btn'>
                                 <Button onClick={this.closeBatch}>取消</Button>
                                 <Button disabled={!btnActive} className={btnActive ? "" : "gray"}
                                         onClick={this.saveBatch}>确定</Button>
                             </div>
                             {errorMessage ?
                               <div className='error'>
                                   <span>请下载查看</span>
                                   <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                               </div>
                               : null}
                         </div>
                     }
              >
                  <Batch
                    ref={ref => this.batchRef = ref}
                    getFormData={this.getFormData}
                    sure={this.sure}
                    reset={() => this.setState({ errorMessage: '', btnActive: false })}
                    template={downTemplate(6)}
                    pullError={(val) => this.setState(val)}
                    titleType='院校'
                  />
              </Modal>
              {/*应用接入弹层*/}
              <Modal
                title='应用接入'
                visible={isApplication}
                destroyOnClose={true}
                closable={false}
                className='configuration_modals_gljg'
                width={660}
                footer={
                  <div className='modal_btn_j'>
                    <Button onClick={() => this.setState({ isApplication: false })}>取消</Button>
                    <Button onClick={()=>{this.save()}}>确认添加</Button>
                  </div>
                }
              >
                  <FormAlike wrappedComponentRef={form => {this.appForm = form}} selectedTags={this.selectedTags} formType='yyjr'/>
              </Modal>
              {/*同步到教管系统*/}
              <Modal
                  title='同步到教管系统'
                  visible={istbjg}
                  destroyOnClose={true}
                  closable={false}
                  className='configuration_modal_yx'
                  width={660}
                  footer={
                      <div className='modal_btn_j'>
                          <Button onClick={() => this.setState({ istbjg: false })}>取消</Button>
                          <Button onClick={()=>{this.synchroEduSys()}}>确认</Button>
                      </div>
                  }
              >
                  <ConsolextMoadl wrappedComponentRef={form => {this.synchroForm = form}} enterpriseInfo={enterpriseInfo}/>
              </Modal>
              {/*院系管理弹层*/}
              <Modal
                visible={isSchool}
                destroyOnClose={true}
                closable={false}
                className='configuration_modal_yx'
                width={660}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.setState({ isSchool: false })}>关闭</Button>
                        <Button onClick={()=>{this.facultyUpdate()}}>确定</Button>
                    </div>
                }
              >


                  <div className='add_school_box model_title'>
                      <div className="mini_title title">
                          {schoolName}<span className='little_title'>院系管理</span>
                      </div>
                  </div>
                  <Form {...formItemLayout}>
                      <Form.Item>
                          <div style={{width: '600px'}}>
                              <div className="school_con_top">
                                  <div className='app_top_titles'>共有院系<span className='title_number'>{facultyList.length}</span>个
                                      <div className='change_btn' onClick={() => this.setState({ is_input: false }) }>修改</div>
                                  </div>
                                  {
                                      is_input === true ?
                                          // 展示状态
                                          <div className='input_items'>
                                              {facultyList.map(tag => (
                                                  <div className='div_set'>{tag.facultyName}</div>
                                              ))}
                                          </div>
                                          :
                                          // 修改状态变为input输入框
                                          <div className='input_items'>
                                              {facultyList.map(tag => (
                                                  <Input defaultValue={tag.facultyName} className='input_set' onChange={(e) => this.faculty(e, tag.id)}/>
                                              ))}
                                          </div>
                                  }
                              </div>
                          </div>
                      </Form.Item>
                  </Form>
              </Modal>
          </div>
        )
    }
}

index = Form.create()(index);
export default index;
