import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Row, Col, Checkbox, Radio, DatePicker} from 'antd';
import './index.scss';
import {
    selectAcademyTeacher,
    saveVirtualOrgTeacher,
    selectVirtualOrgTeacher,
    deleteAcademyTeacher,
    seeVirtualOrgTeacher,
    seeVirtualOrgStudent,
    updateVirtualOrgTeacher, resetUserPassword, seeAcademyStudent,
} from '../../../../api/platform-school/index'
import {
    checkImportVirtualOrgTeachers, downTemplate,
    getCitySel,
    getProvinceSel,
    globalSel,
    selectFaculty
} from '../../../../api/platform-business/index'
import Batch from '@/components/import';
import moment from "moment";
import md5 from "js-md5"
const { Option } = Select;

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };
// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

const Demo = () => {
    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
}
const App = () => {
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>男</Radio>
            <Radio value={2}>女</Radio>
        </Radio.Group>
    );
};

class index extends Component {
    state = {
        dataSource: [],
        enterpriseId: '',
        pageNum: 1,
        pageSize: 10,
        total: 0,
        isCheck: false, // 查看
        isEdit: false, // 编辑
        isTeacher: false, // 新增教师弹层
        isDelete: false,// 删除弹层
        isRepassword: false,// 重置密码
        propertyList: [], // 教师类型下拉列表
        professionalCallList: [], // 职称下拉列表
        professionalCall: '', // 职称
        property: '', // 教师类型
        teacherNameOrNum: '', // 教师工号/教师名称
        propertyDicType: 19, // 教师类型字典中的类型
        professionalCallDicType: 16, // 职称字典中的类型
        nameLength: 0, // 新增弹框姓名长度
        positionLength: 0, // 新增弹框职务长度
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        teacherType: '',
        userId: '',
        isUpdateOrSee: '', // 1是编辑
        highestDegreeOption:[],//学位下拉
        highestEducationOption:[],//学历下拉
        facultyResOption:[],//院系下拉
        tableId: '',
        tableUserId: '',
        facultyName: '',
        cityName: '',
        provinceName: '',
        provinceCode: '',
        cityId: '',
    }

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({
            enterpriseId: jsonparam.enterpriseId
        },() => {
            this.selectVirtualOrgTeacher()
            this.getProvince()
            this.getProfessionalCall()
            this.getProvinceSel()
            this.getCitySel()
            this.getPolitical()
            this.getFaculty()
        })
    }

    async selectVirtualOrgTeacher(){
        const param = {
            enterpriseId: this.state.enterpriseId,
            // isManagement: this.state.isManagement,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            professionalCall: this.state.professionalCall,
            property: this.state.property,
            teacherNameOrNum: this.state.teacherNameOrNum,
            teacherType: this.state.teacherType
        }
        const teacherTable = await selectVirtualOrgTeacher(param)
        console.log('teacherTable ========>>>>', teacherTable)
        this.setState({
            total: teacherTable.total,
            dataSource: teacherTable.data
        })
    }
// 导入成员弹窗取消
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    getFormData=(formData)=>{
        console.log("1111111111111",formData.get("file"))
        formData.set("enterpriseId",this.state.enterpriseId)
        this.setState({
            batchRef: formData
        },()=>{
            console.log("batchRef",this.state.batchRef)
        })
    }
    //导入时得确定按钮
    commitBatch = () => {
        //从子组件调用导入接口，更改为点击确定后上传了
        console.log('this.state.batchRef)', this.state.batchRef)
        checkImportVirtualOrgTeachers(this.state.batchRef).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('上传成功');
                this.virTeacherresult(res)
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    sure = (e) => {
        this.setState({ btnActive: true, fileId: e });
    }
    async virTeacherresult(res){
        let transParam = {
            recordId: res,
            enterpriseId:this.state.enterpriseId
        }
        let param = JSON.stringify(transParam);
        this.props.history.push(`/main/platform-business/vir-teacherresult?${encodeURI(param)}`)
    }
    //检索
    search = (e) => {
        this.setState({ teacherNameOrNum: e }, () => this.selectVirtualOrgTeacher());
    }

    // 教师类型下拉
    getProvince = async () => {
        if (this.state.propertyDicType) {
            let provinceRes = await globalSel({ type: this.state.propertyDicType });
            provinceRes && this.setState({ propertyList: provinceRes });
        }
    }

    //教师类型下拉框change
    changeProperty = (e, item) => {
        this.setState({ property: e}, () => this.selectVirtualOrgTeacher());
    }

    // 职称下拉
    getProfessionalCall = async () => {
        if (this.state.professionalCallDicType) {
            let professionalCallRes = await globalSel({ type: this.state.professionalCallDicType });
            professionalCallRes && this.setState({ professionalCallList: professionalCallRes });
        }
    }

    // 职称下拉框change
    changeProfessionalCall = (e, item) => {
        this.setState({ professionalCall: e}, () => this.selectVirtualOrgTeacher());
    }

    async toTeacherresult(){
        this.props.history.push("/main/platform-business/vir-teacherresult")
    }
    async toTeacherrecord(){
        this.props.history.push(`/main/platform-business/teacher-record?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId,jumpType:4}))}`);
    }

    // 姓名输入框长度
    nameLength =  (rule, value, callback) => {
        if(value && value.length === 0){
            this.setState({
                nameLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                nameLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 职务输入框长度
    positionLength =  (rule, value, callback) => {
        if(value && value.length === 0){　
            this.setState({
                positionLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                positionLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号')
        } else {
            callback();
        }
    }

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        console.log('省下拉 res', res)
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        console.log('eeee', e)
        console.log('item', item)
        this.setState({
            provinceCode: e,
            citySel: [] ,
            provinceName: item.props.children
            }, () =>
            this.getCitySel()
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        this.setState({
            cityId: e,
            cityName: item.props.children
        });
    }

    saveTeacher=()=>{
        this.props.form.validateFields((err, value) => {
            if(!err){
                console.log('this.state.isUpdateOrSee', this.state.isUpdateOrSee)
                if(this.state.isUpdateOrSee !== 1){
                    let saveVirtualOrgTeacherQo = {
                        birthdays: new Date(value.birthday),
                        cardType: value.cardType,
                        enterpriseId: this.state.enterpriseId,
                        facultyId: value.facultyId,
                        facultyName: this.state.facultyName,
                        highestDegree: value.highestDegree,
                        highestEducation: value.highestEducation,
                        idCard: value.idCard,
                        inductionTime: moment(value.inductionTime).format('YYYY-MM-DD HH:MM:ss'),
                        jobNum: value.jobNum,
                        password: md5(value.password),
                        nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                        position: value.position,
                        professionalCall: value.professionalCall,
                        teacherType: value.teacherType,
                        sex: value.sex,
                        telNum: value.telNum,
                        userId: this.state.userId,
                        teacherName: value.teacherName,
                        workUnit: value.workUnit,
                        email: value.email,
                        positions: value.positions
                    }
                    console.log('saveVirtualOrgTeacherQo', saveVirtualOrgTeacherQo)
                    saveVirtualOrgTeacher(saveVirtualOrgTeacherQo).then (res => {
                        if (res) {
                            this.mesSuccess("新增成功")
                            this.setState({isTeacher: false})
                            this.selectVirtualOrgTeacher()
                        }
                    })
                } else {
                    let updateVirtualOrgTeacherQo = {
                        id: this.state.tableId,
                        adminUserId: this.state.tableUserId,
                        userId: this.state.userId,
                        birthday: new Date(value.birthday),
                        cardType: value.cardType,
                        enterpriseId: this.state.enterpriseId,
                        facultyId: value.facultyId,
                        facultyName: this.state.facultyName,
                        highestDegree: value.highestDegree,
                        highestEducation: value.highestEducation,
                        idCard: value.idCard,
                        inductionTime: moment(value.inductionTime).format('YYYY-MM-DD HH:MM:ss'),
                        jobNum: value.jobNum,
                        nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                        position: value.position,
                        professionalCall: value.professionalCall,
                        teacherType: value.teacherType,
                        sex: value.sex,
                        telNum: value.telNum,
                        userName: value.teacherName,
                        workUnit: value.workUnit,
                        email: value.email,
                        positions: value.positions
                    }
                    console.log('updateVirtualOrgTeacherQo', updateVirtualOrgTeacherQo)
                    updateVirtualOrgTeacher(updateVirtualOrgTeacherQo).then (res => {
                        if (res) {
                            this.mesSuccess("修改成功")
                            this.setState({isTeacher: false})
                            this.selectVirtualOrgTeacher()
                        }
                    })
                }

            }
        });
    }

    // 删除
    deleteAcademyTeachers = (record) => {
        this.setState({
            userId: record.userId
        })
        this.hint({'title': '温馨提示',
            'content': '确定要删除吗？删除后将无法恢复！',
            'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.deleteAcademyTeacher })
        // let _this = this;
        // Modal.confirm({
        //     title: "温馨提示",
        //     content: "确定要删除吗？删除后将无法恢复！",
        //     okText: "确定",
        //     cancelText: "关闭",
        //     centered:true,
        //     className: "ModalConfirm-pic",
        //     onOk() {
        //         deleteAcademyTeacher({userId: record.userId}).then((res) => {
        //             this.mesSuccess("删除成功");
        //             _this.setState({
        //                 isDelete: false
        //             },()=>{
        //                 _this.selectVirtualOrgTeacher();
        //             })
        //         });
        //     },
        //     onCancel() {},
        // });

    }
    // 删除
    deleteAcademyTeacher = async () => {
        const {userId} = this.state
        let data = {
            userId: userId
        }
        await deleteAcademyTeacher(data).then(r => {
            if(r){
                this.mesSuccess("删除成功");
                this.setState({
                    isDelete: false
                },()=>{
                    this.selectVirtualOrgTeacher();
                })
            }
        })
    }

    // 重置密码
    resetPassword = (record) => {
        this.setState({
            userId: record.userId
        })
        this.hint({'title': '温馨提示',
            'content': '确定要重置密码吗？',
            'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.resetUserPassword })
        // let _this = this;
        // Modal.confirm({
        //     title: "温馨提示",
        //     content: "确定要重置密码吗？",
        //     okText: "确定",
        //     cancelText: "关闭",
        //     centered:true,
        //     className: "ModalConfirm-pic",
        //     onOk() {
        //         resetUserPassword({userId: record.userId}).then((res) => {
        //             this.mesSuccess("重置成功");
        //             _this.setState({
        //                 isRepassword: false
        //             },()=>{
        //                 _this.selectVirtualOrgTeacher();
        //             })
        //         });
        //     },
        //     onCancel() {},
        // });
    }
    // 重置密码
    resetUserPassword = async () => {
        const {userId} = this.state
        let data = {
            userId: userId
        }
        await resetUserPassword(data).then(r => {
            if(r){
                this.mesSuccess("重置成功");
                this.setState({
                    isRepassword: false
                },()=>{
                    this.selectVirtualOrgTeacher();
                })
            }
        })
    }

    update = (record) => {
        this.setState({
            isTeacher: true,
            isUpdateOrSee: 1,
            tableId: record.id,
            tableUserId: record.userId
        },() => {
            this.seeVirtualOrgTeacher(record, 1)
        })
    }

    getPolitical = async () => {
        let highestEducationOption = await globalSel({ type: 17 });
        highestEducationOption && this.setState({ highestEducationOption: highestEducationOption });
        let highestDegreeOption = await globalSel({ type: 18 });
        highestDegreeOption && this.setState({ highestDegreeOption: highestDegreeOption });
    }

    // 院系下拉
    getFaculty = async () => {
        let facultyResOption = await selectFaculty({
            enterpriseId:this.state.enterpriseId,
            pageNum: 1,
            pageSize: '',
        });
        facultyResOption && this.setState({ facultyResOption });
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.selectVirtualOrgTeacher());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.selectVirtualOrgTeacher());
        window.elGoTop && window.elGoTop('body');
    }

    // 校验邮箱格式
    validateEmail =  (rule, value, callback) => {
        let emailReg =  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (value && !emailReg.test(value)){
            callback('请输入正确的电子邮箱')
        } else {
            callback();
        }
    };

    seeVirtualOrgTeacher = (record, type) => {
        this.setState({
            isTeacher: true,
            isUpdateOrSee: type ? type :2
        })
        const param = {
            enterpriseId: this.state.enterpriseId,
            userId: record.userId
        }
        console.log('param 333. ', param)
        seeVirtualOrgTeacher(param).then((res) => {
            console.log('seeVirtualOrgTeacher ===>>>>', res)
            if(res){
                if(res.province){
                    this.setState({
                        provinceCode: res.province
                    },() => {
                        this.getCitySel()
                    })
                }
                this.setState({
                    birthday: res.birthday,
                    inductionTime: res.inductionTime,
                    sex: res.sex,
                    nameLength: res.userName.length,
                    positionLength: res.positions ? res.positions.length : 0,
                    cityId: res.city ? res.city : ''
                })
                this.props.form.setFieldsValue({
                    jobNum: res.jobNum,
                    teacherName: res.userName,
                    highestEducation: res.highestEducation ? Number(res.highestEducation) : '',
                    highestDegree: res.highestDegree ? Number(res.highestDegree) : '',
                    facultyId: res.facultyId,
                    teacherType: res.teacherType ? Number(res.teacherType) : '',
                    professionalCall: res.professionalCall ? Number(res.professionalCall) : '',
                    telNum: res.telNum,
                    email: res.email,
                    sex: res.sex,
                    cardType: res.cardType ? res.cardType : '',
                    idCard: res.idCard,
                    positions: res.positions,
                    workUnit: res.workUnit,
                    province: res.province ? res.province : '',
                    city: res.city ? res.city : '',

                })
            }
        })
    }


    render() {
        let {birthday,facultyResOption,highestEducationOption,isUpdateOrSee,highestDegreeOption,total,pageSize,isTeacher,isEdit,
            isDelete,isCheck,isRepassword,dataSource,propertyList,professionalCallList,nameLength,provinceSel,
            citySel,positionLength,sex,inductionTime} = this.state;
        let {batch,btnActive,errorMessage} = this.state
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', width:80,render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '工号/帐号', dataIndex: 'jobNum',key:'jobNum',width:100, },
            {title: '姓名', dataIndex: 'teacherName',key:'teacherName',width:100, },
            {title: '性别', align: 'center', width:80,render: (text, item, index ) =>  <span className='serial'>{item.sex === 2 ? '女' : item.sex === 1 ? '男' : ''}</span>,},
            {title: '出生日期', dataIndex: 'birthday', key: 'birthday', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '籍贯', dataIndex: 'nativePlace', key:'nativePlace'},
            {title: '最高学位', dataIndex: 'highestDegree',key:'highestDegree'},
            {title: '职称', dataIndex: 'professionalCall', key:'professionalCall'},
            {title: '手机', dataIndex: 'telNum', key:'telNum'},
            {title: '院系', dataIndex: 'facultyName', key:'facultyName'},
            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width: 260,
                render:(text, record)=><div className="opspan">
                    <div className="main">
                        <span className="but" onClick={() => this.seeVirtualOrgTeacher(record)}>查看</span>
                        <span className="but" onClick={() => this.update(record)}>编辑</span></div>
                    <div className="main">
                        <span className="but" onClick={() => this.deleteAcademyTeachers(record)}>删除</span>
                        <span className="but" onClick={() => this.resetPassword(record)}>重置密码</span></div>
                </div>},
        ];
        return (
            <div className='managestu_box'>
                <div className="onelist">
                    <div className="one">
                        <div>
                            <span style={{marginRight: '20px'}}>教师类型</span>
                            <Select placeholder='全部'>
                                <Option value={''}>全部</Option>
                                {
                                    propertyList.map(property => <Option key={property.code} value={property.code}>{property.name}</Option>)
                                }
                            </Select>
                        </div>
                        <div>
                            <span style={{marginRight: '20px'}}>职称</span>
                            <Select placeholder='全部'>
                                <Option value={''}>全部</Option>
                                {
                                    professionalCallList.map(professionalCall => <Option key={professionalCall.code} value={professionalCall.code}>{professionalCall.name}</Option>)
                                }
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="twolist">
                    <div className="top_list">
                        <div className="leftlist">
                            <Button className="buttonl" type="primary" style={{marginRight: '20px'}} onClick={() => this.setState({ batch: true })}>导入教师</Button>
                            <Button className="buttonm" style={{marginRight: '20px'}} onClick={() => this.setState({ isTeacher: true, isUpdateOrSee: 3 })}>新增教师</Button>

                            <Modal
                                title={isUpdateOrSee === 1 ? '编辑教师信息': isUpdateOrSee === 2 ? '查看教师信息': '新增教师信息'}
                                visible={isTeacher}
                                destroyOnClose={true}
                                closable={false}
                                className='binding_modal binding_modal_add_look_ted'
                                footer={
                                    <div className='foot_botton'>
                                        {isUpdateOrSee === 1 ? <Button className="addbutton" onClick={() => this.saveTeacher()}>确认修改</Button> :
                                            isUpdateOrSee === 2 ? ''
                                            :<Button className="addbutton" onClick={() => this.saveTeacher()}>确认添加</Button>}

                                        <Button className="delete" onClick={() => this.setState({ isTeacher: false })}>取消</Button>
                                    </div>
                                }
                            >
                                <div className="modaltitle">
                                    个人信息
                                </div>
                                <Form className='lt_form_wapper'>
                                    <Form.Item label={`姓名`}>
                                        {getFieldDecorator('teacherName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入姓名`,
                                                },
                                               {
                                                   validator:this.nameLength
                                               }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} />)}
                                        <span className="limited">{nameLength}/20</span>
                                    </Form.Item>
                                    <Form.Item label={`电话`}>
                                        {getFieldDecorator('telNum', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入电话`,
                                                },
                                                {
                                                    validator:this.validatePhone
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入电话" style={{ width: 330 , marginTop:"4px"}}/>)}
                                    </Form.Item>
                                    {isUpdateOrSee === 1 || isUpdateOrSee === 2 ? '' :
                                        <Form.Item label={`密码`}>
                                            {getFieldDecorator('password', {
                                                initialValue: "zjy123456",
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请输入密码`,
                                                    },
                                                ],
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入密码" style={{ width: 330 , marginTop:"4px"}}/>)}
                                        </Form.Item>
                                    }
                                    <Form.Item className="sex" label={`性别`}>
                                        <div className="all">
                                            {/*<div className="totle"><span className="title">性别</span></div>*/}
                                            {getFieldDecorator('sex', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: `请选择性别`,
                                                    },
                                                ],
                                                initialValue: sex? sex : ""
                                            })(
                                                <Radio.Group value={sex? sex : ""} disabled={isUpdateOrSee === 2}>
                                                    <Radio value={1}>男</Radio>
                                                    <Radio value={2}>女</Radio>
                                                </Radio.Group>)}

                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`出生日期`}>
                                        {getFieldDecorator('birthday', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请选择出生日期`,
                                                },
                                            ],
                                            initialValue: birthday? moment(birthday, 'YYYY-MM-DD') : null
                                        })(<DatePicker disabled={isUpdateOrSee === 2} placeholder='请选择出生日期' style={{width: '100%'}} />)}
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`籍贯`}>

                                        <div className="all">
                                            {getFieldDecorator('province', {
                                            })(
                                                <Select placeholder='请选择省'
                                                        disabled={isUpdateOrSee === 2}
                                                        onChange={this.changeProvince}
                                                >
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                        provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                                    }
                                                </Select>
                                            )}
                                            {getFieldDecorator('city', {
                                            })(
                                                <Select placeholder='请选择市'
                                                        disabled={isUpdateOrSee === 2}
                                                        onChange={this.changeCity}
                                                >
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                        citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                                    }
                                                </Select>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`最高学历`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">最高学历</span></div>*/}
                                            {getFieldDecorator('highestEducation', {
                                            })(   <Select placeholder='请填写最高学历' disabled={isUpdateOrSee === 2}>
                                                {
                                                    highestEducationOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                                }
                                            </Select>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`最高学位`}>
                                        {getFieldDecorator('highestDegree', {
                                        })( <Select placeholder='请填写最高学位' disabled={isUpdateOrSee === 2}>
                                            {
                                                highestDegreeOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                            }
                                        </Select>)}
                                    </Form.Item>
                                    <Form.Item label={`证件类型`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">证件类型</span> </div>*/}
                                            {
                                                getFieldDecorator('cardType', {
                                                    initialValue: undefined,
                                                })(
                                                    <Select placeholder='请选择证件类型' disabled={isUpdateOrSee === 2}>
                                                        <Option value={0}>身份证</Option>
                                                        <Option value={1}>护照</Option>
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`证件号码`}>
                                        {/*<div className="totle">*/}
                                        {/*    <span className="title">证件号码</span></div>*/}
                                        {getFieldDecorator('idCard', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入证件号码`,
                                                },
                                            ],
                                        })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入证件号" style={{ width: 330 , marginTop:"4px"}}/>)}
                                    </Form.Item>
                                    <Form.Item label={`邮箱`}>
                                        {/*<div className="totle">*/}
                                        {/*    <span className="title">邮箱</span>*/}
                                        {/*</div>*/}
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: `请填写电子邮箱`,
                                                },
                                                {
                                                    validator:this.validateEmail
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input disabled={isUpdateOrSee === 2} placeholder="请填写电子邮箱" className="input" maxLength={100}/>)}
                                    </Form.Item>
                                    <div className="modaltitle">
                                        机构信息
                                    </div>
                                    <Form.Item label={`工号/账号`}>
                                        {/*<div className="totle">*/}
                                        {/*    <span className="title">工号/账号</span>*/}
                                        {/*</div>*/}
                                        {getFieldDecorator('jobNum', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: `请填写工号/账号`,
                                                },
                                            ],
                                        })(<Input disabled={isUpdateOrSee === 2} placeholder="请填写工号/账号" className="input"/>)}
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`入职日期`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">入职日期</span></div>*/}
                                            {getFieldDecorator('inductionTime', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择入职日期`,
                                                    },
                                                ],
                                                initialValue: inductionTime? moment(inductionTime, 'YYYY-MM-DD') : null
                                            })(<DatePicker disabled={isUpdateOrSee === 2} placeholder='请选择入职日期' style={{width: '100%'}} />)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`教师类型`}>
                                        <div className="all">
                                            {getFieldDecorator('teacherType', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择教师类型`,
                                                    },
                                                ],
                                            })(
                                                <Select disabled={isUpdateOrSee === 2} placeholder='请选择教师类型' style={{ width: 330 , marginTop:"4px"}}>
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                        propertyList.map(property => <Option key={property.code} value={property.code}>{property.name}</Option>)
                                                    }
                                                </Select>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`教师职称`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className='red'>*</span><span className="title">教师职称</span></div>*/}
                                            {getFieldDecorator('professionalCall', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择教师职称`,
                                                    },
                                                ],
                                            })(
                                                <Select disabled={isUpdateOrSee === 2} placeholder='请选择教师职称' style={{ width: 330 , marginTop:"4px"}}>
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                        professionalCallList.map(professionalCall => <Option key={professionalCall.code} value={professionalCall.code}>{professionalCall.name}</Option>)
                                                    }
                                                </Select>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`院系`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className='red'>*</span><span className="title">院系</span></div>*/}
                                            {
                                                getFieldDecorator('facultyId', {
                                                    initialValue: undefined,

                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择院系' style={{ width: 330 , marginTop:"4px"}}
                                                            onChange={(e,item) => {
                                                                this.setState({facultyName: item.props.children})
                                                            }}
                                                    >
                                                        {/*<Option value={''}>全部</Option>*/}
                                                        {
                                                            facultyResOption.map(v => <Option key={v.id} value={v.id}>{v.facultyName}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`工作单位`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">工作单位</span></div>*/}
                                            {getFieldDecorator('workUnit', {
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(
                                                <Input disabled={isUpdateOrSee === 2} placeholder="请输工作单位" style={{ width: 330 , marginTop:"4px"}} />
                                            )}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`职务`}>
                                        {getFieldDecorator('positions', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入职务`,
                                                },
                                                {
                                                    validator:this.positionLength
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入职务" style={{ width: 330 , marginTop:"4px"}} />)}
                                        <span className="limited">{positionLength}/30</span>
                                    </Form.Item>
                                </Form>
                            </Modal>

                            <Button className="buttonr" onClick={() =>{this.toTeacherrecord()}}><img src={require('@/assets/img/lead.png')} alt="" />导入记录</Button>
                        </div>
                        <div className="right_list">
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入教师工号/姓名' />
                        </div>
                    </div>
                    <div className="tablelist">
                        <Table rowKey={v => v['enterpriseId']} pagination={
                            {
                                total,
                                pageSize:pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['5', '10', '20', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns} dataSource={dataSource} scroll={{ x: 2000}} />
                    </div>
                </div>
                <Modal className='batch' visible={batch} title='学校信息导入'
                       closable={false}
                       footer={
                           <div style={{ width: "100%" }}>
                               <div className='pos-btn'>
                                   <Button onClick={this.closeBatch}>取消</Button>
                                   <Button disabled={btnActive ? false : true} className={btnActive ? "" : "gray"}
                                           onClick={this.commitBatch}>确定</Button>
                               </div>
                               {errorMessage ?
                                   <div className='error'>
                                       <span>请下载查看</span>
                                       <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                   </div>
                                   : null}
                           </div>
                       }
                >
                    <Batch
                        ref={ref => this.batchRef = ref}
                        getFormData={this.getFormData}
                        sure={this.sure}
                        reset={() => this.setState({ errorMessage: '', btnActive: false })}
                        template={downTemplate(2)}
                        pullError={(val) => this.setState(val)}
                        titleType='虚拟机构老师'
                    />
                </Modal>
            </div>
        )
    }
}

index = Form.create()(index);
export default index;
