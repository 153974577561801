import { post } from '@/until/axios_instans';

//资讯管理列表
export function getInformationList(data) {
  //return post('/api/zixun/api/v1/information/getInformationList', data);
  return post('/api/v1/information/getInformationList', data);
}
//资讯分类下拉框
export function getInfoList(id,data) {
  //return post('/api/v1/information/getInfoList?sysId=JT', data);    //官网
  //return post('/api/zixun/api/v1/information/getInfoList?sysId=ZJ', data);
  return post('/api/v1/information/getInfoList?sysId='+id, data);
}

//资讯管理添加资讯 (发布按钮接口)
export function addInformation(data) {
  //return post('/api/zixun/api/v1/JTInformation/addsInformation', data);
  return post('/api/v1/JTInformation/addsInformation', data);
}
//资讯管理删除资讯
export function delInformation(data) {
  //return post('/api/zixun/api/v1/information/delInformation?id=' + data);
  return post('/api/v1/information/delInformation?id=' + data);
}
//资讯管理设置/取消置顶
export function setInformationTop(data) {
  //return post('/api/zixun/api/v1/information/setInformationTop?id=' + data);
  return post('/api/v1/information/setInformationTop?id=' + data);
}
//获取资讯详情
export function getInformationDetail(data) {
  //return post('/api/zixun/api/v1/information/getInformationDetail?id=' + data);
  return post('/api/v1/information/getInformationDetail?id=' + data);
}
//附件列表
export function files(data) {
  return post('/api/zixun/api/v1/news/files',data);
}
//栏目列表
export function selectNewsCategory(data) {
  return post('/api/zixun/api/v1/news/category/selectNewsCategory', data);
}
//栏目列表保存排序
export function saveNewsCategorySort(data) {
  return post('/api/zixun/api/v1/news/category/saveNewsCategorySort', data);
}
//业务系统
export function getPlatformList(data) {
  return post('/api/zixun/api/v1/news/index/getPlatformList', data);
}
//业务系统名称和下属栏目数量
export function selectPlatformForCategory(data) {
  return post('/api/zixun/api/v1/news/category/selectPlatformForCategory', data);
}
//删除栏目
export function deleteNewsCategory(data) {
  return post('/api/zixun/api/v1/news/category/deleteNewsCategory', data);
}
//一级栏目
export function selectNewsParentCategory(data) {
  return post('/api/zixun/api/v1/news/category/selectNewsParentCategory', data);
}
//二级栏目
export function selectNewsTwoCategory(data) {
  return post('/api/zixun/api/v1/news/category/selectNewsTwoCategory', data);
}
//添加栏目
export function addNewsCategory(data) {
  return post('/api/zixun/api/v1/news/category/addNewsCategory', data);
}
//校验栏目数
export function checkColumnNum(data) {
  return post('/api/zixun/api/v1/news/category/checkColumnNum', data);
}
//校验栏目名称
export function checkColumnName(data) {
  return post('/api/zixun/api/v1/news/category/checkColumnName', data);
}
//修改栏目
export function updateNewsCategory(data) {
  return post('/api/zixun/api/v1/news/category/updateNewsCategory', data);
}
//修改新闻
export function updateNews(data) {
  return post('/api/zixun/api/v1/news/updateNews', data);
}
//查询新闻
export function selectNews(data) {
  return post('/api/zixun/api/v1/news/selectNews', data);
}
//发布/取消发布新闻
export function releaseNews(data) {
  return post('/api/zixun/api/v1/news/releaseNews', data);
}
//置顶/取消置顶新闻
export function topNews(data) {
  return post('/api/zixun/api/v1/news/topNews', data);
}
//校验栏目下置顶数量
export function checkTopNum(data) {
  return post('/api/zixun/api/v1/news/checkTopNum', data);
}
//删除新闻到回收站
export function deleteNewsToRecycle(data) {
  return post('/api/zixun/api/v1/news/deleteNewsToRecycle', data);
}
//添加新闻
export function addNews(data) {
  return post('/api/zixun/api/v1/news/addNews', data);
}
//根据id查询新闻
export function selectNewsById(data) {
  return post('/api/zixun/api/v1/news/selectNewsById/'+data);
}
//查询草稿新闻
export function selectNewsInDrafts(data) {
  return post('/api/zixun/api/v1/news/selectNewsInDrafts', data);
}
//查询回收站新闻
export function selectNewsInRecycle(data) {
  return post('/api/zixun/api/v1/news/selectNewsInRecycle', data);
}
//恢复回收站新闻
export function recoverNews(data) {
  return post('/api/zixun/api/v1/news/recoverNews', data);
}
//清空回收站新闻
export function clearRecycle(data) {
  return post('/api/zixun/api/v1/news/clearRecycle',data);
}
//删除回收站新闻
export function deleteNews(data) {
  return post('/api/zixun/api/v1/news/deleteNews',data);
}