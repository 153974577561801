import React, { Component } from 'react';
import { Input, Form, Button, Upload, Spin, DatePicker } from 'antd';
import ImgCrop from "antd-img-crop";
import { uploadFile, addManageMechanism, updataManageMechanismInfo, updataManageMechanism } from '../../api/manage_mechanism_manage';
import { uploadIcon } from '../../icon_type';
import MajorTypeSelect from "../../components/major_type_select";
import RangeCheckedSelect from '../../components/range_checked_select';
import ProvinceSelect from '../../components/province_select';
import CitySelect from '../../components/city_select';
import OrgTypeSelect from '../../components/org_type_select';
import Moment from 'moment';
import styles from './index.module.scss';
import '../../components/confirm';

import { disabledDate } from '../../components/method_tools';

export class ManageMechanismAdd extends Component {
	state = {
		loading: false,
        imgModalVisble:false,//预览图片弹窗
		cloneImg:'',//图片地址克隆
	};

	componentDidMount = async () => {
		const {location,form:{setFieldsValue}} = this.props;
		const urlId = this.getUrlType(location.search,'id');
		if(urlId){
			const {
				cityId,enterpriseCode,enterpriseLogo,enterpriseName,labelIds,
				manageOrgType,orgRangeCodes,provinceId,settledDate,settledEndDate
			} = await updataManageMechanismInfo({id:urlId});
			setFieldsValue({ 
				logo: enterpriseLogo,
				name: enterpriseName,
				cityId: cityId ? String(cityId) : undefined, 
				provinceId: provinceId ? String(provinceId) : undefined,
				sign: enterpriseCode,
				mechanismLabel: labelIds,
				mechanismType: manageOrgType ? String(manageOrgType) : undefined,
				range: orgRangeCodes,
				startDate: Moment(settledDate),
				endDate: Moment(settledEndDate),
				dataTime: [Moment(settledDate),Moment(settledEndDate)]
			});
		};
	};

	goBack = () => {
        this.props.history.goBack()
    };

	//上传图片
    changeLoad = async info => {
        this.setState({ loading: true});
        const fromData = new FormData()
        fromData.append('file', info)
        fromData.append('folder', 'cn')
        try {
            const uploadResult = await uploadFile(fromData);
            this.setState({ loading: false });
            this.props.form.setFieldsValue({ logo: uploadResult.fpName });
        } catch (error) {
            console.log(error);
        }
    };

	//提交表单
	onSubmit = async () => {
		const {form:{validateFields},history,location} = this.props;
		const urlId = this.getUrlType(location.search,'id');
		try {
			const res = await validateFields();
			const params = {
				enterpriseName:res.name,
				enterpriseCode:res.sign,
				settledDate:Moment(res.startDate).format('YYYY-MM-DD'),
				settledEndDate:Moment(res.endDate).format('YYYY-MM-DD'),
			};
			if(res.provinceId){
				params.provinceId = res.provinceId;
			};
			if(res.cityId){
				params.cityId = res.cityId;
			};
			if(res.logo){
				params.enterpriseLogo = res.logo;
			};
			if(res.mechanismType){
				params.manageOrgType = res.mechanismType;
			};
			if(Array.isArray(res.range) && res.range.length > 0){
				params.orgRangeCodes = res.range;
			};
			if(Array.isArray(res.mechanismLabel) && res.mechanismLabel.length > 0){
				params.labelIds = res.mechanismLabel;
			};
			if(urlId){
				await updataManageMechanism({adminOrgId:urlId,...params});
				this.mesSuccess('管理机构修改成功');
			}else{
				await addManageMechanism(params);
				this.mesSuccess('管理机构创建成功');
			}
			history.goBack();
		} catch (error) {
			console.log(error);
		}
	};

	//省市id变化
	provinceChange = () => {
		const {form:{getFieldValue,setFieldsValue}} = this.props;
		const city = getFieldValue('cityId');
		if(!city)return;
		setFieldsValue({cityId:undefined});
	};

	//表单的间距配置
	formLayout = {
		labelCol: {
			xs: { span: 3 },
			sm: { span: 3 },
		},
		wrapperCol: {
			xs: { span: 4 },
			sm: { span: 4 },
		},
		colon:false
	};

	render() {
		const { 
			loading, cloneImg,
		} = this.state;

		const {
			form: {
				getFieldDecorator,
				getFieldValue,
				setFieldsValue,
				validateFields,
			},location
		} = this.props;

		const props = {
            aspect: 160 / 88,
            resize: true, //裁剪是否可以调整大小
            resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
            modalTitle: "上传logo", //弹窗标题
            modalWidth: 600, //弹窗宽度
            rotate: false,
            width: 160,  //裁剪宽度
            height: 88, //裁剪高度
        };
        const urlId = this.getUrlType(location.search,'id');

		return (
			<div className={styles["manage_mechanism_add"]}>
				<Form {...this.formLayout}>
					<Form.Item label="管理机构名称" className="max_height">
						{getFieldDecorator('name', {
							rules: [
								{ required: true, message: '请输入管理机构名称' }
							],
							getValueFromEvent:e => {
								const val = e.target.value;
								if(typeof val !== 'string'){
									return val;
								};
								return val.replace(/\s+/g, "");
							}
						})(
							<Input 
								className="max_input"
								placeholder='请输入管理机构名称' 
								size="large" 
								maxLength={50} 
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("name") ? getFieldValue("name").length : 0}/
										<span style={{color: "#333"}}>50</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="管理机构标识" className="max_height">
						{getFieldDecorator('sign', {
							rules: [
								{ required: true, message: '请输入管理机构标识' }
							],
							getValueFromEvent:e => {
								const val = e.target.value;
								if(typeof val !== 'string'){
									return val;
								};
								return val.replace(/[^\w\.\/]/ig,"");
							}
						})(
							<Input 
								className="max_input"
								placeholder='请输入管理机构标识' 
								size="large" 
								maxLength={15} 
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("sign") ? getFieldValue("sign").length : 0}/
										<span style={{color: "#333"}}>15</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item className='upload_box' label={'管理机构Logo'} colon={false}>
                    {
                        getFieldDecorator('logo',{
                            getValueFromEvent:() => {
                                return cloneImg;
                            }
                        })(
                            <div>
                                <ImgCrop {...props}>
									<Upload
										accept='.jpg,.png,.jpeg,.bmp'
										listType="picture-card"
										showUploadList={false}
										beforeUpload={(file) => this.changeLoad(file)}
									>
										{
											loading 
											? <Spin />
											: (getFieldValue("logo")
											? <div className='resource_replace_img'>
												<div className="resource_replace_img_modal">
													<span style={{ cursor: 'pointer' }} onClick={()=>this.setState({cloneImg:getFieldValue("logo")})}>{uploadIcon}</span>
												</div>
												<div className="img_s">
													<img
														style={{ width: "100%"}}
														src={window.$$imgSrc(getFieldValue("logo"))} alt="avatar"
													/>
												</div>
											</div>
											: <div className='add_image_div'>
												<div 
													style={{
														display: 'flex',
														justifyContent: 'space-around',
														marginBottom:5
													}}
												>
													<img style={{ width: "19px", height: "19px", display: "block" }} src={window.$$img('cvec-portal-front-pingtai/Circl/add_icon.png')} alt="" />
												</div>
												<span style={{color:'#3E78ED'}}>上传Logo</span>
											</div>)
										}
									</Upload>
								</ImgCrop>
                            </div>
                        )
                    }
                    <div className="tishi_wrap">
						<p
							className='tishi'
						>
							支持jpg/gif/png/jpeg格式，<br />
							建议最佳尺寸200x50px，不超过5M
						</p>
					</div>
                </Form.Item>
					<Form.Item label="可管理机构类型" className="max_height">
						{getFieldDecorator('mechanismType')(
							<OrgTypeSelect
								style={{width:500}}
								placeholder="请选择可管理机构类型"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="所管范围">
						{getFieldDecorator('range')(
							<RangeCheckedSelect />
						)}
					</Form.Item>
					<Form.Item label="所在省市" className="sheng_shi_qu">
						<Form.Item label={undefined}>
							{getFieldDecorator('provinceId')(
								<ProvinceSelect
									showSearch
									style={{ width: 250 }}
									placeholder="请选择省"
									optionFilterProp="children"
									onChange={this.provinceChange}
									size='large'
								/>
							)}
						</Form.Item>
						<Form.Item label={undefined}>
							{getFieldDecorator('cityId')(
								<CitySelect
									provinceId={getFieldValue('provinceId')}
									showSearch
									style={{ width: 250, marginLeft: 20 }}
									placeholder="请选择市"
									optionFilterProp="children"
									size='large'
								/>
							)}
						</Form.Item>
					</Form.Item>
					<Form.Item label="平台使用日期" className="mmadd_data_time">
						{getFieldDecorator('dataTime',{
							rules: [
								{ 
									required: true,
									validator:(rule,val=[],callback) => {
										const [startDate,endDate] = val;
										const str = [];
										if(startDate && endDate){
											if(Moment(startDate).format('YYYY-MM-DD') === Moment(endDate).format('YYYY-MM-DD')){
												callback(Error('开通日期和到期日期不能相同'));
												return;
											}
											callback();
										}else{
											if(!startDate){
												str.push('开通日期');
											};
											if(!endDate){
												str.push('到期日期');
											};
											callback(Error('请选择' + str.join('和')));
										}
									}
								}
							],
						})(
							<>
								<Form.Item label={undefined}>
									{getFieldDecorator('startDate',{
										getValueFromEvent:(val) => {
											const endDate = getFieldValue('endDate');
											if(endDate && Moment(val) > Moment(endDate)){
												setFieldsValue({endDate:val});
												return endDate;
											};
											return val;
										}
									})(
										<DatePicker 
											style={{width:260}}
											placeholder="请选择开通日期"
											size='large'
											onChange={val => {
												setFieldsValue({dataTime:[val,getFieldValue('endDate')]});
												validateFields(['dataTime']);
											}}
											disabledDate={disabledDate}
											getCalendarContainer={triggerNode => triggerNode.parentElement}
										/>
									)}
								</Form.Item>
								<span style={{margin:'0 18px'}}>至</span>
								<Form.Item label={undefined}>
									{getFieldDecorator('endDate',{
										getValueFromEvent:(val) => {
											const startDate = getFieldValue('startDate');
											if(startDate && Moment(val) < Moment(startDate)){
												setFieldsValue({startDate:val});
												return startDate;
											};
											return val;
										}
									})(
										<DatePicker 
											style={{width:260}}
											placeholder="请选择到期日期"
											size='large'
											onChange={val => {
												setFieldsValue({dataTime:[getFieldValue('startDate'),val]});
												validateFields(['dataTime']);
											}}
											disabledDate={disabledDate}
											getCalendarContainer={triggerNode => triggerNode.parentElement}
										/>
									)}
								</Form.Item>
							</>
						)}
					</Form.Item>
					<Form.Item label="机构标签">
						{getFieldDecorator('mechanismLabel')(
							<MajorTypeSelect isEdit/>
						)}
					</Form.Item>
				</Form>
				<div className={styles["file_footer"]}>
					<Button className={styles["btn_yes"]} size="large" type="primary" onClick={this.onSubmit}>确定{urlId ? '修改' : '创建'}</Button>
					<Button className={styles["btn_no"]} size="large" type="primary" ghost onClick={this.goBack}>取消</Button>
				</div>
				<div style={{height:20}}></div>
			</div>
		)
	}
}

export default Form.create()(ManageMechanismAdd);
