import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Button, Checkbox, Col, Divider, Form, Input, message, Radio, Row, Select, Tree } from 'antd';
import url from "@/until/libs/url.js";
import { addRole, editRole, getMenuList, menuSysList, organizationList, roleDetail, roleStatus, relevanceAppRole } from '@/api/role'
import { powerdic } from '@/api/powerdic';
import { getAppButtonRole, getSelAppRole } from '@/api/configuration/index.js';

const { Option } = Select;

const mapStateToProps = ({ CHECK }) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}

class EditRole extends React.Component {
    state = {
        platform: "",
        treeMap: {},
        treeValue: [],
        menuOptions: [],
        menuList: [],
        organPlainOptions: [],//适用机构
        identityOptions: [],//适用身份
        isStatus: '',
        roleInputNum: 0,
        contentInputNum: 0,
        identificationInputNum: 0,
        checkList: [],
        statusList: [],
        detail: {
            id: '',
            applyOrganizationCode: '',
            applyOrganizationNmae: '',
            content: "",
            identification: "",
            identityId: '',
            identityName: '',
            roleMenuList: [],
            otherOrganizationIdList: [],
            roleName: "",
            showName: "",
            status: 1,
        },
        organizationList: [],  //其它
        associatedTerminal: undefined,
        appRoleList: [],
        appCheckList: []
    }
    relevanceAppRole = async (callBack) => {
        relevanceAppRole({
            "list": Array.from(new Set(this.state.appCheckList)),
            "roleId": url.getQueryString('id'),
            "sysId": this.state.platform
        }).then(res => {
            callBack && callBack();
        })
    }
    componentDidMount() {
        this.getDic()
        this.getRoleStatus()
        this.getMenuSysList()
        if (url.getQueryString('id')) {
            this.getInfo(url.getQueryString('id'))
        }
        this.getOrganizationList()
    }
    getSelAppRole = async () => {
        if (url.getQueryString('id')) {
            let id = url.getQueryString('id');
            let res = await getSelAppRole({
                id, platform: this.state.platform
            });
            res && this.setState({ appCheckList: Array.isArray(res) ? res : [] });
        }
    }
    //获取app全部按钮权限
    getAppButtonRole = async () => {
        if (!this.state.platform) {
            return;
        }
        let res = await getAppButtonRole({
            associatedTerminal: 1,
            platform: this.state.platform
        })
        res && this.setState({ appRoleList: Array.isArray(res) ? res : [] });
    }
    async getMenuSysList() {
        let menuOptions = await menuSysList();
        if (menuOptions) {
            this.setState({
                menuOptions
            })
        }
    }

    async getRoleStatus() {
        let statusList = await roleStatus();
        if (statusList) {
            this.setState({
                statusList
            })
        }
    }
    //适用机构
    async getOrganizationList() {
        let applyList = await organizationList();
        if (applyList) {
            this.setState({
                organizationList: applyList
            })
        }

    }
    async getDic() {
        let organPlainOptions = await this.getPowerdic(9)
        let identityOptions = await this.getPowerdic(7)
        this.setState({
            organPlainOptions,
            identityOptions
        })
    }

    async getPowerdic(num) {
        return await powerdic({ type: num });
    }

    async getInfo(id) {
        let info = await roleDetail({ roleId: id });
        if (info) {
            this.setState({
                detail: {
                    ...this.state.detail,
                    ...info,
                    identity: info.identityId ? info.identityId.split(',').map(e => parseInt(e)) : []
                },
                isStatus: info.applyOrganizationCode === 92,
                roleInputNum: info.roleName.length,
                contentInputNum: info.content.length,
                identificationInputNum: info.identification.length,
                treeMap: info.sysMenuMap
            })
        }
    }

    save = () => {
        let { organPlainOptions, identityOptions, treeMap, detail } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.state.associatedTerminal === 1) {
                    this.relevanceAppRole(() => {
                        message.success("修改成功")
                        this.props.form.resetFields();
                        this.props.history.goBack()
                    });
                    return;
                };
                //适用机构数据处理
                const applyOrganizationCode = values.applyOrganizationCode
                let applyOrganizationName = ''
                organPlainOptions.forEach(e => {
                    if (e.code === applyOrganizationCode) {
                        applyOrganizationName = e.name
                    }
                })
                //适用身份数据处理
                const identity = values.identity
                let identityName = []
                identityOptions.forEach(e => {
                    if (identity.includes(e.code)) {
                        identityName.push(e.name)
                    }
                })
                let data = []
                for (let key in treeMap) {
                    if (treeMap[key]) {
                        treeMap[key].forEach(e => {
                            data.push({...e, platform: key})
                        })
                    }
                }
                const params = {
                    ...detail,
                    ...values,
                    roleMenuList: data,
                    identityId: identity.join(','),//适用身份Id（多个用英文逗号隔开）
                    identityName: identityName.join(','),//适用身份名称（多个用英文逗号隔开）
                    applyOrganizationName: applyOrganizationName,//适用机构名称
                }
                if (params.id) {
                    editRole(params).then((res) => {
                        if (res && res.code == 0) {
                            message.success("修改成功")
                            this.props.form.resetFields();
                            this.props.history.goBack()
                        } else {
                            message.warning("修改失败")
                        }
                    })
                } else {
                    //添加
                    addRole(params).then((res) => {
                        if (res && res.code == 0) {
                            message.success("保存成功")
                            this.props.form.resetFields();
                            this.props.history.goBack()
                        } else {
                            message.warning("保存失败")
                        }
                    });
                }
            }
        })
    }

    changeTitle(e) {//角色value
        const value = e.target.value
        this.setState({
            roleInputNum: value.length
        })
    }
    changeContent(e) {//备注value
        const value = e.target.value
        this.setState({
            contentInputNum: value.length
        })
    }
    changeIdentification(e) {//字符标识value
        const value = e.target.value
        this.setState({
            identificationInputNum: value.length
        })
    }

    sysMenu = ({ target: { value } }) => {
        this.saveRole();
        this.setState({ platform: value }, () => {
            this.getList();
        });

    }
    saveRole = () => {
        const { associatedTerminal } = this.state;
        if (associatedTerminal !== 1) return;
        this.relevanceAppRole();
    }
    getList = () => {
        const { associatedTerminal, platform } = this.state;
        if (associatedTerminal === 0) {
            getMenuList({ platform }).then(res => {
                this.setState({
                    menuList: res,
                    treeValue: []
                }, () => {
                    this.setMenuValue();
                })
            })
        } else {
            this.getAppButtonRole();
            this.getSelAppRole();
        }
    }

    setMenuValue = () => {
        let { treeMap, platform } = this.state;
        let treeValue
        if (!treeMap[platform]) {
            treeValue = []
        } else {
            treeValue = treeMap[platform].filter(e=> e.half === 0).map(e => e.menuId)
        }
        this.setState({
            treeMap,
            treeValue
        })
    }

    renderTreeNodes = data => data.map((item) => {
        if (item.childList) {
            return (
                <Tree.TreeNode title={item.menuName} key={item.id} dataRef={item}>
                    {this.renderTreeNodes(item.childList)}
                </Tree.TreeNode>
            );
        }
        return <Tree.TreeNode key={item.id} title={item.menuName} {...item} dataRef={item} isLeaf={!item.isLeaf} />;
    });
    //切换终端
    changeType = e => {
        this.saveRole();
        this.setState({ associatedTerminal: e.target.value }, () => {
            this.getList();
        });
    }
    changeAppRole = e => {
        this.setState({ appCheckList: e });
    }
    render() {
        const { isStatus, detail, platform, statusList, menuList, treeMap,
            associatedTerminal, appRoleList, appCheckList,
            treeValue, menuOptions, roleInputNum, organPlainOptions, contentInputNum, identificationInputNum, identityOptions, organizationList } = this.state;
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='publicstyle edit_wrap'>
                <div className="wrapper padd">
                    <p className="edit_tit">{!detail.id ? "添加" : "编辑"}角色</p>
                    <Form {...formItemLayout} name="basic">
                        <Col span={16} className='flex-li'>
                            <Form.Item label="角色名称">
                                {getFieldDecorator('roleName', {
                                    initialValue: detail.roleName,
                                    rules: [
                                        { required: true, message: '请输入角色名称' },
                                        { max: 20, message: '角色名称不能超过20字' }
                                    ]
                                })
                                    (<Input type="text" placeholder='请输入角色名称' suffix={<span>{roleInputNum}/20</span>}
                                        onChange={this.changeTitle.bind(this)} />)}
                            </Form.Item>
                            <Form.Item label="显示名称">
                                {getFieldDecorator('showName', {
                                    initialValue: detail.showName,
                                    rules: [
                                        { required: true, message: '请输入显示名称' },
                                        { max: 20, message: '显示名称不能超过20字' }
                                    ]
                                })
                                    (<Input type="text" placeholder='请输入显示名称' />)}
                            </Form.Item>
                            <Form.Item label="适用身份" >
                                {getFieldDecorator('identity', {
                                    initialValue: detail.identity,
                                    rules: [
                                        { required: true, message: '请选择适用身份' },
                                    ]
                                })
                                    (<Checkbox.Group >
                                        {identityOptions ? identityOptions.map((item, index) => (
                                            <Checkbox disabled={index > 2} key={index} value={item.code}>{item.name}</Checkbox>
                                        )) : ''}
                                    </Checkbox.Group>)}
                            </Form.Item>
                            <Form.Item label="备注">
                                {getFieldDecorator('content', {
                                    initialValue: detail.content,
                                    rules: [
                                        { max: 20, message: '备注不能超过20字' }
                                    ]
                                })
                                    (<Input type="text" placeholder="请输入角色说明" className="input"
                                        onChange={this.changeContent.bind(this)} suffix={<span>{contentInputNum}/20</span>} />)}
                            </Form.Item>
                            <Form.Item label="字符标识">
                                {getFieldDecorator('identification', {
                                    initialValue: detail.identification,
                                    rules: [
                                        { required: true, message: '请输入字符标识' },
                                        { max: 10, message: '字符标识不能超过10字' }
                                    ]
                                })
                                    (<Input type="text" placeholder="请输入字符标识" className="input"
                                        onChange={this.changeIdentification.bind(this)} suffix={<span>{identificationInputNum}/10</span>} />)}
                            </Form.Item>
                            <Form.Item label="适用机构" rules={[{ required: true }]}>
                                {getFieldDecorator('otherOrganizationIdList', {
                                    initialValue: detail.otherOrganizationIdList,
                                    rules: [
                                        { required: true, message: '请选择适用机构' },
                                    ]
                                })
                                    (<Select mode="tags" placeholder="全部非运营机构（多选）" style={{ width: '100%' }}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}
                                        onChange={this.organizationNameChange}>
                                        {organizationList && organizationList.length ? organizationList.map((item, index) => {
                                            return <Option key={item.organizationId} value={item.organizationId}>{item.organizationName}</Option>
                                        }) : ""}
                                    </Select>
                                    )}
                            </Form.Item>
                            {/*<Form.Item label="适用机构" rules={[{required: true}]}>
                                        {getFieldDecorator('applyOrganizationCode', {
                                            initialValue: detail.applyOrganizationCode ?  detail.applyOrganizationCode : '',
                                            rules: [
                                                {required: true, message: '请选择适用机构'},
                                            ]
                                        })
                                        (<Radio.Group onChange={(e) => {
                                            this.setState({isStatus: e.target.value === 92})
                                        }} style={{width: '600px'}}>
                                            {organPlainOptions ? organPlainOptions.map((item, index) => (
                                                <Radio key={index} value={item.code}>{item.name}</Radio>
                                            )) : ''}
                                        </Radio.Group>)}
                                    </Form.Item>
                                {isStatus ?  <Form.Item label="" rules={[{required: true}]} className="Item-li apply-organization">
                                            {getFieldDecorator('otherOrganizationIdList', {
                                                initialValue: detail.otherOrganizationIdList,
                                                rules: [
                                                    {required: true, message: '请选择适用机构'},
                                                ]
                                            })
                                            (<Select mode="tags" placeholder="全部非运营机构（多选）" style={{width: '100%'}}
                                                     getPopupContainer={triggerNode => triggerNode.parentNode}
                                                     className='select-li'  onChange={this.organizationNameChange}>
                                                    {organizationList && organizationList.length ? organizationList.map((item, index) => {
                                                        return <Option key={item.organizationId} value={item.organizationId}>{item.organizationName}</Option>
                                                    }) : ""}
                                            </Select>
                                            )}
                                        </Form.Item> : ''}*/}
                            <Form.Item label="状 态">
                                {getFieldDecorator('status', {
                                    initialValue: parseInt(detail.status),
                                    rules: [
                                        { required: true, message: '请选择状态' },
                                    ]
                                })
                                    (<Radio.Group>
                                        {statusList && statusList.length ? statusList.map((item, index) => (
                                            <Radio value={item.code} key={index}>{item.msg}</Radio>
                                        )) : ''}
                                    </Radio.Group>)}
                            </Form.Item>
                            <Divider />
                            <Form.Item label="菜单权限" className="menu-limit">
                                <Radio.Group value={platform} onChange={this.sysMenu}>
                                    {menuOptions ? menuOptions.map((item, index) => (
                                        <Radio key={index} value={item.systemIdentification}>{item.systemName}</Radio>
                                    )) : ''}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label='关联终端' className="menu-limit">
                                <Radio.Group value={associatedTerminal} onChange={this.changeType}>
                                    <Radio value={0}>Web端</Radio>
                                    <Radio value={1}>App端</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {associatedTerminal === 0 && platform && <Form.Item label={''} className="checkbox-menu">
                                <Col offset={6}>
                                    <div className={`treeMenu`}>
                                        <Tree
                                            checkable
                                            checkedKeys={treeValue}
                                            onCheck={(checkedKeys, {halfCheckedKeys}) => {
                                                const checkedKeysObj = checkedKeys.map(id => {
                                                    return {menuId: id, half: 0}
                                                })
                                                const halfCheckedKeysObj = halfCheckedKeys.map(id => {
                                                    return {menuId: id, half: 1}
                                                })
                                                treeMap[platform] = checkedKeysObj.concat(halfCheckedKeysObj)
                                                this.setState({ treeMap: treeMap, treeValue: checkedKeys })
                                            }}
                                        >
                                            {menuList && menuList.length > 0 ? this.renderTreeNodes(menuList) : ''}
                                        </Tree>
                                    </div>
                                </Col>
                            </Form.Item>}
                            {
                                associatedTerminal === 1 && platform && <Form.Item>
                                    <Col offset={6}>
                                        <div className={`treeMenu`}>
                                            <Checkbox.Group value={appCheckList} onChange={this.changeAppRole}>

                                                {
                                                    appRoleList.map(v => {
                                                        return <Checkbox value={v.id} key={v.id}>{v.menuName}</Checkbox>
                                                    })
                                                }
                                            </Checkbox.Group>
                                        </div>
                                    </Col>
                                </Form.Item>
                            }
                        </Col>
                    </Form>
                </div>
                <div className="but-save">
                    <Button type="primary" className="but" onClick={() => this.save()}>{detail.id ? '修改' : '保存'}</Button>
                    <Button className="but" style={{ "marginRight": "10px" }}
                        onClick={() => this.props.history.goBack()}>取消</Button>
                </div>
            </div>
        )
    }
}

EditRole = connect(mapStateToProps, mapDispatchToProps)(EditRole);
EditRole = Form.create()(EditRole)
export default EditRole;
