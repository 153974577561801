import React, { Component } from 'react'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import PlayerVideo from './videoplayer'
import { Affix, Button } from 'antd'
import './index.scss'
import Audioplayer from './Audiopre'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default class FilePreview extends Component {
    state={
        full_flag:false,
        scalcnum: 1,
        currentpage:0,
        totalPage:0,
        resourceDetail:{},
        more:false
    };

    set_full_flag=(flag)=>{
        // let audio_wrap = document.getElementById('audio_wrap');
        // let canvas = document.getElementById('canvas');
        this.setState({
            full_flag:!flag
        },()=>{
            // canvas.setAttribute('width',audio_wrap.offsetWidth);
            // canvas.setAttribute('height',audio_wrap.offsetHeight);
        })
    };
    scalc_c=(type,n)=>{
        if(type==='-'&&n>1){
            this.setState({
                scalcnum:n-1
            })
        }else if(type==='+'&&n<2){
            this.setState({
                scalcnum:n*1+1
            })
        }else if(n===2){
            this.setState({
                full_flag:false
            })
        }
    };

    prev=(cur)=>{
        if(cur>=1){
            this.setState({
                currentpage:cur-1
            },()=>{
                // this.Document_wrap_cel
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }else{
            this.setState({
                currentpage:this.Document_wrap_cel.pages.map((v)=>v).length-2
            },()=>{
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }
    };

    next=(cur)=>{
        if(cur<this.Document_wrap_cel.pages.map((v)=>v).length-2){
            this.setState({
                currentpage:cur+1
            },()=>{
                // this.Document_wrap_cel
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }else{
            this.setState({
                currentpage:0
            },()=>{
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }

    };

    onDocumentLoadSuccess = (e) => {
        this.setState({
            totalPage: e.numPages
        })
    };

    render() {
        const {full_flag,totalPage,scalcnum,currentpage,more}=this.state
        //   resourceType    1,"文档",2,"视频",3,"音频" 4,"图片",
        const {resourceType, resourceSuffix, fileId, pageSize, startTime, transStatus} = this.props;
        const url = 'https://cvecdev.oss-cn-beijing.aliyuncs.com/';
        return (
            <div className="left_bottom" >
                <div className="main_preview">
                    {resourceType === 1 && ['pdf','doc','docx'].includes(resourceSuffix)?
                        /* pdf  doc docx  */
                        <div>
                            <Document
                                className='Document_wrap_c'
                                // file={filesrc}
                                ref={(el)=>this.Document_wrap_cel=el}
                                file={url+fileId}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                onLoadError={console.error}
                                renderMode="canvas"
                                loading="正在努力加载中"
                                externalLinkTarget="_blank"
                                renderTextLayer={true}
                            >
                                {!more?Array.from({ length: pageSize && pageSize > 0 ? pageSize : 2  }, (v, k) => k + 2).map((v, i) => {
                                    return <div key={i}>
                                        <Page pageNumber={v - 1} key={i} scale={scalcnum} />
                                        <div className='pagenum'>{i+1}</div>
                                    </div>
                                }):Array.from({ length: totalPage }, (v, k) => k + 2).map((v, i) => {
                                    return <div key={i}>
                                        <Page pageNumber={v - 1} key={i} scale={scalcnum} />
                                        <div className='pagenum'>{i+1}</div>
                                    </div>
                                })}
                            </Document>
                            {!more?<div className='more_wrap' ><span className='more' onClick={()=>[
                                this.setState({
                                    more:true
                                })
                            ]}>剩余{totalPage - (pageSize && pageSize > 0 ? pageSize : 2) > 0 ? totalPage - (pageSize && pageSize > 0 ? pageSize : 2): 0 }页未读，<span className='next_look'> 继续预览</span></span>
                            </div>:''}
                        </div>:
                        resourceType === 1 && !['pdf','doc','docx'].includes(resourceSuffix) ?
                            /* 文档类型 */
                            <div className="iframe_preview" >
                                <iframe width="100%" height="100%" src={url+fileId} frameBorder="0"/>
                            </div>:
                            resourceType === 2 ?
                                /* {视频} */
                                <div >
                                    <PlayerVideo startTime={startTime && startTime > 0 ? startTime : 0}
                                                 src={url+fileId}>
                                    </PlayerVideo>
                                </div> :
                                resourceType === 3 ?
                                    /* {音频} */
                                    <div className="c_a_dio">
                                        <Audioplayer set_full_flag={this.set_full_flag}
                                                     set_full_flag={full_flag}
                                                     src={url+fileId}>
                                        </Audioplayer>
                                    </div>:
                                    resourceType === 4 ?
                                        <div className="imgModal">
                                            <img src={`${url}${fileId}`} alt=""/>
                                        </div>: ''}
                        </div>
            </div>
        )
    }
}


