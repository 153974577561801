
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';

import { Layout } from 'antd';
import OBreadcrumb from '../../components/Breadcrumb'
import './index.scss'
import { Redirect } from 'react-router-dom';
import RouterView from '../../router/router_view';
import routerList from '@/router/router_config.js';
import Menu from '@/components/Menu';

const { Sider, Content } = Layout;

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {    
        menuList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
}




export default connect(mapStateToProps, mapDispatchToProps)(class PracticalTraining extends React.Component {
    render() {
        let path = this.props.location.pathname;
        let { menuList } = this.props;
        console.log('menuList培训',menuList)
        let propsMenuList = menuList.find((v) => v.menuName === "实训");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        console.log('propsMenuList',propsMenuList)
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        let two, three;
        propsMenuList && propsMenuList.forEach(v => {
            v.children && v.children.forEach(k => {
                if (k.menuUrl === path) {
                    two = v;
                    three = k;
                }
            })
        })
        let id = propsMenuList[0] && propsMenuList[0].id;
        let arr = [one, two, three];
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                  
                    {id ? <Menu {...this.props}  menuList={propsMenuList} /> : null}
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    {
                        arr[2]?<OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />:''
                    }
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout> 
        )
    }
})
