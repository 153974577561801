import { post } from "@/until/axios_instans";

// 省下拉列表
export const queryProvince = (data) => {
    return post("/api/gongyong/v1/cvecregion/province", data);
};

// 市下拉列表
export const queryCity = (data) => {
    return post("/api/gongyong/v1/cvecregion/city", data);
};

//文件上传
export const uploadFile = (data) => {
    return post("api/fujian/api/v1/fujian/uploadFile", data)
}

//获取专业列表
export const queryMajorList = () => {
    return post('/api/ucenter/api/v1/sharing/selectSchoolLabel');
};

//获取机构管理弹窗的表格数据
export const queryModalMechanismTableData = (data) => {
    return post('/api/ucenter/api/v1/enterprise/getSubSelectOrgSchoolPage',data);
};

//获取机构管理弹窗的右侧选中数据
export const queryModalMechanismSelectData = () => {
    return new Promise(resolve => {
        resolve([]);
    })
};

//获取国籍下拉 
export const queryNationalitySelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getNationality');
};

//获取最高学历下拉 
export const queryEducationSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getHighestEducation');
};

//获取最高学位下拉 
export const queryDegreeSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getHighestDegree');
};

//获取证件类型下拉 
export const queryDocumentTypeSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getCardType');
};

//获取民族下拉 
export const queryNationSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getNation');
};

//获取政治面貌下拉 
export const queryPoliticsSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getPolityFace');
};

//获取教师类型下拉 
export const queryTeacherTypeSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getTeacherType');
};

//获取教师职称下拉 
export const queryTeacherTitleSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getProfessionalCall');
};

//获取人员状态下拉 
export const queryPersonnelStatusSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getTeacherPositionStatus');
};

//获取专业技术职务下拉 
export const queryTechnicalPositionSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getTechnologyPost');
};

//获取教职工类别下拉 
export const queryFacultyTypeSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getFacultyCategory');
};

//获取现任岗位下拉 
export const queryCurrentPositionTypeSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/getPostCategory');
};

//获取学习层次下拉 
export const queryStudyLevelSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/selectLearningLevelType');
};

//获取学制下拉 
export const querySchoolSystemSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/selectEduSystem');
};

//获取院系下拉 
export const queryFacultySelect = (data) => {
    return post('/api/ucenter/api/v1/adminUser/selectFacultyByOrgId',data);
};

//获取专业下拉 
export const queryStadentMajorSelect = (data) => {
    return post('/api/ucenter/api/v1/adminUser/selectMajorByFacultyId',data);
};

//获取行政班级下拉 
export const queryStudentClassSelect = (data) => {
    return post('/api/ucenter/api/v1/adminUser/selectClassByMajorId',data);
};

//获取学生来源下拉 
export const queryStudentSourceSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/selectStudentSource');
};

//获取招生方式下拉 
export const queryEnrollmentMethodSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/selectEnrollStudentType');
};

//获取招生对象下拉 
export const queryEnrollmentObjectSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/selectEnrollStudentObj');
};

//获取户口类型下拉 
export const queryAccountTypeSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/selectStudentLiveType');
};

//获取在校状态下拉 
export const querySchoolStatusSelect = () => {
    return post('/api/ucenter/api/v1/adminUser/selectStudentStatus');
};

//获取在校状态下拉 
export const querySchoolRunningLevelSelect = () => {
    return post('/api/ucenter/api/v1/orgCommon/getSchoolLevel');
};

//获取省市范围
export const queryProvinceRange = (data) => {
    return post('/api/ucenter/api/v1/enterprise/getAdminOrgProvinceById',data);
};

//获取可管理机构类型
export const queryAdminOrgType = () => {
    return post("/api/ucenter/api/v1/orgCommon/getAdminOrgType")
}