import { post } from '@/until/axios_instans';

//应用列表
export function getUseList(data) {
    return post('/api/ucenter/api/v1/application/applications', data);
}
//应用添加
export function addUse(data) {
    return post('/api/ucenter/api/v1/application/application', data);
}
//应用编辑
export function editUse(data) {
    return post('/api/ucenter/api/v1/application/update', data);
}
//获取菜单详情
export function getMenuDetail(data) {
    return post('/api/ucenter/api/v1/application/appmenuDetail', data);
}
//应用接入列表
export function useCutList(data) {
    return post('/api/ucenter/api/v1/application/appAccess', data);
}
//设置学校列表
export function getsetCutList(data) {
    return post('/api/ucenter/api/v1/application/appAccessManage', data);
}
//获取开通记录列表
export function openRecordList(data) {
    return post('/api/ucenter/api/v1/application/appAccessManageLog', data);
}
//延期
export function postpone(data) {
    return post('/api/ucenter/api/v1/application/appAccessManageDelay', data);
}
//系统列表
export function getSystemList(data) {
    return post('/api/ucenter/api/v1/menu/menuList', data);
}
//权限树形菜单
export function jurisdictionList(data) {
    return post('/api/ucenter/api/v1/menu/upMenuList', data);
}
//分配菜单
export function allotMenu(data) {
    return post('/api/ucenter/api/v1/application/updateAppmenu', data);
}
//机构接入应用 全部应用
export function allFirm(data) {
    return post('/api/ucenter/api/v1/application/applist', data);
}
//设置应用
export function setFirm(data){
    return post('/api/ucenter/api/v1/application/appenter', data);
}

//获取全部app权限按钮
export function getAppButtonRole(data){
    return post('/api/ucenter/api/v1/menu/app/menu', data);
}

//获取已选中的app按钮权限
export function getSelAppRole(data){
    return post('/api/ucenter/api/v1/menu/app/chooseMenu', data);
}

//获取应用app权限
export function getAppRole(data){
    return post('/api/ucenter/api/v1/application/app/application/menu',data)
}