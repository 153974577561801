import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, DatePicker, Radio} from 'antd';
import './index.scss';
import { importRecordDetail,updateNotImport} from '../../../../api/platform-school/index'
import {importSchoolStudents} from "../../../../api/platform-business";
import {
    getCitySel,
    getProvinceSel,
} from '../../../../api/platform-business/index'
import moment from "moment";
const { Option } = Select;



class index extends Component {
    state = {
        dataSource: [], // 导入异常列表
        facultyList: [], // 院系list
        majorList: [], // 专业list
        isChoose: false,//修改弹窗
        isModify: false,//修改弹窗
        isProject: false,//修改弹窗
        isAdd: false,//不导入弹窗
        recordId: "",
        createTime: '',
        importUserName: '',
        fileName: '',
        peopleTotalNum: '',
        propertyList: [], // 教师类型下拉列表
        professionalCallList: [], // 职称下拉列表
        professionalCall: '', // 职称
        property: '', // 教师类型
        teacherNameOrNum: '', // 教师工号/教师名称
        propertyDicType: 19, // 教师类型字典中的类型
        professionalCallDicType: 16, // 职称字典中的类型
        nameLength: 0, // 新增弹框姓名长度
        positionLength: 0, // 新增弹框职务长度
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        yuanxiaoSel:[],
        orgMember: {}, //机构成员json  有是修改没是新增
        orgMemberId:"", //删除时用
        highestDegreeOption:[],//学位下拉
        highestEducationOption:[],//学历下拉
        cardTypeOption:[{
            code:'0',
            name:'身份证'
        },{
            code:'1',
            name:'护照'
        }],//证件下拉
        Batch:false,
        btnActive: false,
        errorMessage:'',
        batchRef:{},//文件
    }


    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log("jsonparam",jsonparam)
        this.setState({
            recordId: jsonparam.recordId,
            enterpriseId: jsonparam.enterpriseId,
        },()=> {
            this.importRecordDetail()
        })
    }

    async importRecordDetail(){
        const param = {
            recordId: this.state.recordId
        }
        const res = await importRecordDetail(param)
        this.setState({
            dataSource: res.importErrVoList,
            facultyList: res.facultyList,
            majorList: res.majorList,
            createTime: res.createTime,
            importUserName: res.importUserName,
            fileName: res.fileName,
            peopleTotalNum: res.peopleTotalNum,
            errRecordId: '',
            importStatus: res.importStatus
        })
    }

    async updateFacult(){
        this.setState({
            isChoose: true
        })
    }
//确定导入
    sureImport = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        importSchoolStudents({recordId:recordId,enterpriseId:this.state.enterpriseId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('导入成功');
                this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
                    console.log("重新拉取列表")
                    this.importRecordDetail()
                });
            }
        }).catch(err => {
            console.log("err",err)
        })
    }
    // 不导入
    async updateNotImport(){
        let _this = this;
        Modal.confirm({
            title: "温馨提示",
            content: "确定不导入吗？",
            okText: "确定",
            cancelText: "关闭",
            centered:true,
            className: "ModalConfirm-pic",
            onOk() {
                updateNotImport({errRecordId: this.state.errRecordId}).then((res) => {
                    this.mesSuccess("操作成功");
                    _this.setState({
                        isDelete: false
                    },()=>{
                        _this.importRecordDetail();
                    })
                });
            },
            onCancel() {},
        });

    }

// 姓名输入框长度
    nameLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                nameLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                nameLength:value.length
            })
        } else {
            callback();
        }
    }

    // 姓名输入框长度
    positionLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                positionLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                positionLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号')
        } else {
            callback();
        }
    }

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        this.setState({ provinceCode: e, citySel: [] }, () =>
            this.getCitySel()
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        this.setState({
            cityId: e
        });
    }
    //查看弹窗
    view = (record) => {
        console.log("查看得传值",record)
        this.setState({
            isTeacher: true,
        },()=>{
            this.props.form.setFieldsValue({
                orgMemberName: record.orgMemberName,
                jobNum: record.jobNum,
                sex:record.sex,
                telNum:record.telNum,
                birthday:moment(record.birthday),
                nativePlace:record.nativePlace,
                email:record.email,
                cardType: "1",
                idCard:record.idCard,
                highestEducation:record.highestEducation?Number(record.highestEducation):"",
                highestDegree:record.highestDegree?Number(record.highestDegree):"",
                inductionTime:record.inductionTime,
                professionalCall:record.professionalCall?Number(record.professionalCall):"",
                identityName:record.identityName,
                position:record.position,
                roleId:record.roleId,
                cityName:record.nativePlace?record.nativePlace.split("-")[1]:"",
                provinceName:record.nativePlace?record.nativePlace.split("-")[0]:"",
            });
        });
    }
    goBack = () => {
        this.props.history.push(`/main/platform-business/stu-manage?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId}))}`);
    }
    render() {
        const { dataSource,isModify,isAdd,createTime,importUserName,isChoose,isProject,fileName,facultyList,peopleTotalNum,majorList,nameLength,provinceSel,citySel,positionLength,yuanxiaoSel,highestDegreeOption,highestEducationOption,cardTypeOption,propertyList ,importStatus} = this.state;
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '学生姓名', dataIndex: 'errUserName',key:'errUserName',width:'50', },
            {title: '手机', dataIndex: 'errTelNum',key:'errTelNum',width:'50', },
            {title: '工号/学号', dataIndex: 'errJobNum',key:'errJobNum',width:'50', },
            {title: '所属院系', dataIndex: 'errFacultyName',key:'errFacultyName', width:'50',},
            {title: '所学专业', dataIndex: 'errMajorName', key:'errTeacherType',width:'50',},
            {title: '所在班级', dataIndex: 'errClassName',key:'errProfessionalCall', width:'50',},
            {title: '入学年份', dataIndex: 'errEnrollmentYear',key:'inductionTime', width:'50',},
            {title: '异常原因', dataIndex: 'errReason', key:'errReason',width:'50',},
            {title: '状态', align: 'center', render: (text, item, index ) =>  <span className='serial'>
                    {
                        item.errStatus === 1 ? '校验失败' : item.importStatus === 2 ? '校验成功':
                            item.errStatus === 3 ?  '导入成功' : item.errStatus === 4 ?  '不再倒入' : ''
                    }</span>,},
            // {
            //     title: '操作',
            //     key: 'operate',
            //     align:'center',
            //     fixed:'right',
            //     width:'200',
            //     render:()=><div className="op-span">
            //         <span className="but" onClick={() => this.setState({ isModify: true })}>修改</span>
            //         <span className="but" onClick={() => this.updateNotImport()}>不导入</span>
            //     </div>},
        ];
        return (
            <div className='result_box'>
                <Modal
                    title='修改学生信息'
                    visible={isModify}
                    destroyOnClose={true}
                    closable={false}
                    // className='configuration_modal'
                    className='binding_modal'
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({ isModify: false })}>取消</Button>
                            <Button onClick={() => this.setState({ isModify: false })}>确认删除</Button>
                        </div>
                    }
                >
                    <div className="modaltitle">
                        入学信息
                    </div>
                    <Form className='lt_form_wapper'>
                        <Form.Item label={`学号/工号`}>
                            <div className="all">
                                {/*<div className="totle">*/}
                                {/*    <span className='red'>*</span><span className="title">工号/帐号</span></div>*/}

                                {getFieldDecorator('jobNum', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请填写学号/工号",
                                        },
                                    ],
                                })(<Input placeholder="请输入工号" style={{ width: 330 , marginTop:"4px"}}/>)}
                            </div>
                        </Form.Item>
                        <Form.Item label={`姓名`}>
                            {getFieldDecorator('orgMemberName', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入姓名`,
                                    },
                                    /*   {
                                           validator:this.nameLength
                                       }*/
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{nameLength}/30</span>
                        </Form.Item>
                        <Form.Item label={`院系`}>
                            {getFieldDecorator('orgMemberName', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入院系`,
                                    },
                                    /*   {
                                           validator:this.nameLength
                                       }*/
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入院系" style={{ width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{nameLength}/50</span>
                        </Form.Item>
                        <Form.Item label={`专业`}>
                            {getFieldDecorator('orgMemberName', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入专业`,
                                    },
                                    /*   {
                                           validator:this.nameLength
                                       }*/
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入专业" style={{ width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{nameLength}/50</span>
                        </Form.Item>
                        <Form.Item label={`行政班级`}>
                            {getFieldDecorator('position', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入行政班级`,
                                    },

                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入行政班级" style={   { width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{positionLength}/50</span>
                        </Form.Item>
                        <Form.Item className='date_input' label={`入学年份`}>
                            <div className="all" style={{width: '330px'}}>
                                {/*<div className="totle">*/}
                                {/*    <span className="title">入职日期</span></div>*/}
                                {getFieldDecorator('inductionTime ', {
                                    rules: [
                                        {
                                            required: true,
                                            message: `请选择入学年份`,
                                        },

                                    ],
                                })(<DatePicker placeholder='请选择入学年份' style={{width: '100%'}} />)}
                            </div>
                        </Form.Item>
                        <div className="modaltitle">
                            个人信息
                        </div>
                        <Form.Item label={`手机号`}>
                            {getFieldDecorator('telNum', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入手机号码`,
                                    },
                                    {
                                        validator:this.validatePhone
                                    }
                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input placeholder="请输入手机号码" style={{ width: 294 , marginTop:"4px"}}/>)}
                        </Form.Item>
                        <Form.Item label={`邮箱`}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: false,
                                        message: "请输入邮箱",
                                    },
                                    {
                                        validator:this.validateInvestmail
                                    }
                                ]
                            })(<Input  className="input" maxLength={100}/>)}
                        </Form.Item>
                        <Form.Item className="sex" label={`性别`}>
                            {
                                getFieldDecorator('sex', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: false,
                                            message: '请选择性别'
                                        }
                                    ]
                                })(
                                    <Radio.Group style={{ width: '100%' }}>
                                        <Radio value={0}>男</Radio>
                                        <Radio value={1}>女</Radio>
                                    </Radio.Group>
                                )
                            }
                        </Form.Item>
                        <Form.Item label={`密码`}>
                            {getFieldDecorator('position', {
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入密码`,
                                    },

                                ],
                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<Input.Password placeholder="请输入密码" style={   { width: 330 , marginTop:"4px"}} />)}
                            <span className="limited">{positionLength}/16</span>
                        </Form.Item>
                        <Form.Item className='date_input' label={`出生日期`}>
                            {getFieldDecorator('birthday', {

                            })(<DatePicker   placeholder='请选择出生日期' style={{width: '100%'}} />)}
                        </Form.Item>
                        <Form.Item className='date_input' label={`籍贯`}>
                            <div className="all">
                                {/*<div className="totle">*/}
                                {/*    <span className="title">籍贯</span></div>*/}
                                {getFieldDecorator('provinceName', {
                                })(
                                    <Select placeholder='请选择省'
                                            onChange={this.changeProvince}
                                    >
                                        {/*<Option value={''}>全部</Option>*/}
                                        {
                                            provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                        }
                                    </Select>
                                )}
                                {getFieldDecorator('cityName', {
                                })(
                                    <Select placeholder='请选择市'
                                            onChange={this.changeCity}
                                    >
                                        {/*<Option value={''}>全部</Option>*/}
                                        {
                                            citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                        }
                                    </Select>)}
                            </div>
                        </Form.Item>
                        <Form.Item label={`政治面貌`}>
                            {
                                getFieldDecorator('highestEducation', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '政治面貌不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选择政治面貌'>
                                    {
                                        highestEducationOption && highestEducationOption.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`学习层次`}>
                            {
                                getFieldDecorator('highestDegree', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '学习层次不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选择学习层次'>
                                    {
                                        highestDegreeOption && highestDegreeOption.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                        <Form.Item label={`证件类型`}>
                            {getFieldDecorator('cardType', {
                            })(<Select placeholder='请选择证件类型'>
                                {
                                    cardTypeOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                }
                            </Select>)}
                        </Form.Item>
                        <Form.Item label={`证件号码`}>
                            {getFieldDecorator('idCard', {
                                rules: [
                                    {
                                        required: false,
                                        message: "请输入证件号码",
                                    },
                                    {
                                        validator:this.IsCard
                                    }
                                ]
                            })(<Input  className="input" maxLength={100}/>)}
                        </Form.Item>
                        <Form.Item label={`民族`}>
                            {
                                getFieldDecorator('highestDegree', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '民族不能为空'
                                        }
                                    ]
                                })(<Select placeholder='请选择民族'>
                                    {
                                        highestDegreeOption && highestDegreeOption.map(v => {
                                            return <Option value={v.code} key={v.code}>{v.name}</Option>
                                        })
                                    }
                                </Select>)
                            }
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title='修改院系'
                    visible={isChoose}
                    destroyOnClose={true}
                    closable={false}
                    className='configuration_modal'
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({ isChoose: false })}>取消</Button>
                            <Button onClick={() => this.setState({ isChoose: false })}>确认修改</Button>
                        </div>
                    }
                >
                    <div className="in_school_content">
                        <div className='top'>
                            <Input placeholder="请输入院系" style={{ width: 330 , marginTop:"4px"}}/>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title='修改专业'
                    visible={isProject}
                    destroyOnClose={true}
                    closable={false}
                    className='configuration_modal'
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({ isProject: false })}>取消</Button>
                            <Button onClick={() => this.setState({ isProject: false })}>确认修改</Button>
                        </div>
                    }
                >
                    <div className="in_school_content">
                        <div className='top'>
                            <Input placeholder="请输入专业" style={{ width: 330 , marginTop:"4px"}}/>
                        </div>
                    </div>
                </Modal>

                {/*<Modal*/}
                {/*    title='不导入'*/}
                {/*    visible={isAdd}*/}
                {/*    destroyOnClose={true}*/}
                {/*    closable={false}*/}
                {/*    className='configuration_modal'*/}
                {/*    footer={*/}
                {/*        <div className='modal_btn_j'>*/}
                {/*            <Button onClick={() => this.setState({ isAdd: false })}>取消</Button>*/}
                {/*            <Button onClick={() => this.setState({ isAdd: false })}>确认</Button>*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*>*/}
                {/*    <div className="in_school_content">*/}
                {/*        <div className='top'>*/}
                {/*            <span>撒</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Modal>*/}
                <div className="oneline">
                    <div className="one">
                        <p>导入人：{importUserName}</p><p>导入时间：{createTime}</p><p>导入文件名称：{fileName}</p>
                    </div>
                    <div className="two">
                        <p>导入结果：</p><p className="ok">校验成功</p>
                    </div>
                    <div className="three">
                        <p>新增院系（{facultyList.length}个）：</p>
                        {
                            facultyList && facultyList.map(v => {
                                return <span className="but">{v} </span>
                                    // <Button type="primary" onClick={() => this.setState({ isChoose: true })}>修改</Button>
                            })
                        }
                    </div>
                    <div className="three">
                        <p>新增专业（{majorList.length}个）：</p>
                        {
                            majorList && majorList.map(v => {
                                return <span className="but">{v}
                                {/*<Button type="primary" onClick={() => this.setState({ isProject: true })}>修改</Button>*/}
                                </span>
                            })
                        }
                    </div>
                    <div className="four">
                        <p>新增学生（{peopleTotalNum}人）</p><p className="attention">提示：导入角色为教师，如果需要配置管理员请导入后去机构管理-详情页面-绑定管理员账号</p>
                    </div>
                    <div className="five">
                        {
                            importStatus ===1?<Button type="primary" onClick={this.goBack}>返回</Button>:
                                <Button type="primary" onClick={this.sureImport}>确认导入</Button>
                        }<p className="failure">导入校验结果：{dataSource.length>0? "校验失败"+dataSource.length+"条":"暂无失败校验"}</p>
                    </div>
                </div>
                <div className="twoline">
                    <div className="topline">
                        <div className="left">
                            {/*<Button className="buttonl" type="primary">以下结果再次校验</Button>*/}
                            {/*<Button className="buttonm">以下结果再导入</Button>*/}
                        </div>
                        <div className="right">共失败{dataSource.length}人</div>
                    </div>
                    <div className="tablelist">
                        <Table  columns={columns} dataSource={dataSource} />
                    </div>
                </div>

            </div>
        )
    }
}
index = Form.create()(index);
export default index;
