import React, { Component } from 'react'
import { Table, message } from 'antd'
import './index.scss'
import { resourcedownloadrecordlist } from '../../../api/shixun2/index'
import TopTitle from "../../../components/BreadcrumbShixun";
// import { message } from '../../../components/global_components/confirm'
function _get_query(name) {
    const search = window.location.href
    if (!search) return null
    const item = search.split('&').find(v => {
        return v.includes(name)

    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}
export class index extends Component {
    state = {
        dataSource: [],
        dataSourceloging: true,//弹框loding
        resourceId: _get_query('resourceId'),
        pageNum: 1,
        pageSize: 10,
        total: 0,
    }

    componentDidMount() {

        const { resourceId,  } = this.state
      

        this.getData(resourceId)
         

    }
    getData = (resourceId) => {
        const {  pageNum, pageSize, } = this.state
 
        return resourcedownloadrecordlist({
            resourceId: resourceId,
            pageNum:pageNum,
            pageSize:pageSize
 
        }).then(res => {
          
            if (res) {
                this.setState(() => {
                    return {
                        dataSource: res.data,
                        pageNum:res.pageNum,//初始值时条用
                        pageSize:res.pageSize,
                        total:res.total

                    }

                })
            } else {
                message.info('没有数据')
            }


        })



    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize, ":sdd")
        const {resourceId}=this.state
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getData(resourceId);
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        console.log(current, pageSize, ":sdd")
        const {resourceId}=this.state
        this.setState({
            pageNum: current,
        }, () => {
        const {resourceId}=this.state
            this.getData(resourceId);
        }
        );
    };
    render() {
        const { dataSource } = this.state
        const columns = [
            {
                title: '序号',
                dataIndex: 'name',
                key: 'name',
                align: "center",
                render: (v, item, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '收件人名字',
                dataIndex: 'userName',

                align: "center",
            },
            {
                title: '收件人手机号',
                dataIndex: 'userPhone',

                align: "center",
            },
            {
                title: '所属机构',
                dataIndex: 'userOrg',
                render: (text, record, index) => <p>
             
                    {record.userOrg &&record.userOrg.length>10? record.userOrg.substring(0, 10) + '...':record.userOrg}
           

                </p>,
                align: "center",
            },
            {
                title: '提取进度',
                dataIndex: 'progressDesc',

                align: "center",
            },
            {
                title: '提取时间',
                dataIndex: 'createTime',

                align: "center",
            },
            {
                title: '发送方式',
                dataIndex: 'sendNode',
                align: "center",
                render: (sendNode) => {
                    return <div>
                        {sendNode}
                    </div>
                }

            },
            {
                title: '发送人',
                dataIndex: 'sendUserName',

                align: "center",
            },
        ];
        const topTitleData = {
            span_1: "实训",
            span_2: "实训资源管理",
            span_3: "仿真资源管理",
            span_4: "使用详情",
            span_3_fun: res => {
                this.props.history.go(-1)
            },
            span_1_c: "#333",
            span_2_c: "#333",
            span_3_c: "#333",
            container:"使用详情",
        };
        return (
            <div className={'installationXiang_cont'}>
                <TopTitle {...topTitleData} />
                <div className="installationXiang_management">
                    <Table dataSource={dataSource} columns={columns}
                        // loading={this.state.dataSourceloging}
                           scroll={{ y: "calc(100vh - 100px)", x: "calc(100% - 500px)" }}
                           pagination={
                               {
                                   defaultCurrent: 1,
                                   pageSizeOptions: ['10', '20', '30'],
                                   showSizeChanger: true,
                                   onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                   onChange: (a, b) => this.onPageNumChange(a, b),
                                   current: this.state.pageNum,
                                   pageSize: this.state.pageSize,
                                   total: this.state.total,
                               }
                           } />;
                </div>
            </div>
        )
    }
}

export default index
