import { post } from "../../../until/axios_instans";
//新增分类
export const addClassify = (data) =>
  post(
    "/api/huodongdasai/api/v1/activityMatch/operate/matchClassify/save",
    data
  );
//分类列表
export const listQuery = (data) =>
  post(
    "/api/huodongdasai/api/v1/activityMatch/operate/matchClassify/listQuery",
    data
  );
//删除分类
export const delClassify = (data) =>
  post(
    "/api/huodongdasai/api/v1/activityMatch/operate/matchClassify/delete",
    data
  );
  //启用分类
export const enableClassify = (data) =>
post(
  "/api/huodongdasai/api/v1/activityMatch/operate/matchClassify/enable",
  data
);

