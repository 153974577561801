// 资讯
import React from 'react';
import {Input, Form, Table, Row, Col, Checkbox, Button, Spin, Modal, message} from 'antd';
import './index.scss';
import DistributionCourceL from "../distribution_cource";
import {addSpecialColumn, getColumnNewsList, deleteSpecialColumn, updateSpecialColumn, deleteColumnNews, getSpecialColumnNum, getSpecialNewsNum} from "../../../../../../api/zixun/index"
import RenderButton from "../../../../../../components/renderButton";
// import DistributionCource from '../distribution_cource/index'
import { withRouter } from "react-router-dom";
const {Search} = Input
const {TextArea} = Input;

function compareSortFunction(propeties) {
    let compareSort = (rowa, rowb) => sortFunction(rowa[propeties], rowb[propeties]);
    return compareSort;
}

function sortFunction(a, b) {
    if (!a) {
        a = "";
    }
    if (!b) {
        b = "";
    }
    if ((typeof a) !== "number") {
        return a.localeCompare(b);
    }
}

@withRouter
class InformationNews extends React.Component {
    state = {
        pagination : {
            pageNum: 1,
            pageSize: 5,
            total: 0,
            current: 1,
        },
        columns: [
            {title: '资讯名称', dataIndex: 'newsTitle', key: 'newsTitle', align: 'center',ellipsis: true},
            {title: '资讯来源', dataIndex: 'articleSource', key: 'articleSource', align: 'center',render: (text,record) =>{
                    if(!text){
                        return '————'
                    }else{
                        return text
                    }
                }
            },
            {
                title: '资讯分类', align: 'center', render: (text, item, index) => <span>
                    {
                        item.newsType === 1 ? '原创' : '转载'
                    }</span>,
            },
            {
                title: '发布时间', dataIndex: 'createTime', key: 'createTime', align: 'center',
                sorter: compareSortFunction("createTime"),
                defaultSortOrder: 'descend',
            },
            {
                title: '操作',  align: 'center', fixed: 'right',
                width: 180,
                render: (text, record) => {
                    return <p className='action vertical_j'>
                        {/*<span onClick={()=> this.previewNews(record)}>预览</span>*/}
                        {/*<span onClick={()=> this.showDeleteColumnNewsConfirm(record)}>删除</span>*/}
                        <RenderButton id='NC_01_B11_c_btnpreview'>
                            <span onClick={()=> this.previewNews(record)}>预览</span>
                        </RenderButton>
                        <RenderButton id='NC_01_B11_c_btndelete'>
                            <span onClick={()=> this.showDeleteColumnNewsConfirm(record)}>删除</span>
                        </RenderButton>
                    </p>
                }
            }
        ],
        jhmc: 0, //
        userId : JSON.parse(localStorage.getItem("userInfo")).id,
        dataSource: [],
        tableLoading: false, // 是否显示加载中
        isDistribution: false, // 关联资讯弹层
        add_child_lm: false,
        columnInfo : {},
        operateType : 1,
        newsNum : 0,
        filteredInfo: null,
        sortedInfo: null,
        column:'创建子专栏'
    };

    componentDidMount () {
        this.props.onRef('InformationNews', this)

        console.log("this.props", this.props)
        this.setState({
            columnInfo : this.props.columnInfo ? this.props.columnInfo : {},
            dataSource : this.props.columnInfo ? this.props.columnInfo.columnNewsVoPageData.data : [],
            newsNum : this.props.columnInfo ? this.props.columnInfo.newsNum : 0
        })
        console.log("this.props.columnInfo", this.props.columnInfo)
    }

    showDeleteColumnNewsConfirm = (record) => { // 删除提示\
        if(this.props.releaseStatus === 1){
            let data = {
                id : this.props.specialId
            }
            getSpecialNewsNum(data).then(res =>{
                if(res > 1){
                    this.hint({
                        'title': '温馨提示',
                        'content': `您是否要删除【${record.newsTitle}】此资讯,删除后将不再列表进行展示,您仍可以选择关联资讯进行添加`,
                        'type': 'warning',
                        'cancelText': '取消',
                        'okText': '删除',
                        'isCancel': true,
                        'sure': () =>{this.deleteColumnNews(record)}
                    })
                }else {
                    // message.destroy();
                    this.mesWarning("专题已发布，至少有一条新闻资讯！")
                }
            })
        }else{
            this.hint({
                'title': '温馨提示',
                'content': `您是否要删除【${record.newsTitle}】此资讯,删除后将不再列表进行展示,您仍可以选择关联资讯进行添加`,
                'type': 'warning',
                'cancelText': '取消',
                'okText': '删除',
                'isCancel': true,
                'sure': () =>{this.deleteColumnNews(record)}
            })
        }
    }

    previewNews = (record) =>{
        this.props.history.push(`/main/group-column/lookNews?${decodeURI(JSON.stringify({ newsId: record.newsId }))}`)
    }

    deleteColumnNews = (record) =>{
        console.log("record", record)
        let data = {
            id : record.columnNewsId
        }
        deleteColumnNews(data).then(res =>{
            if(res){
                this.mesSuccess("删除成功")
                let pagination = this.state.pagination
                pagination.pageNum = 1
                pagination.pageSize = 5
                pagination.total = 0
                this.setState({
                    pagination: pagination
                }, () => this.getColumnNewsList())
            }
        })
    }

    changePage = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination},()=> this.getColumnNewsList())
    }

    getColumnNewsList = () =>{
        console.log("this.state.columnInfo.columnId", this.state.columnInfo.columnId)
        let pagination = this.state.pagination
        let data = {
            id : this.state.columnInfo.columnId,
            pageNum: this.state.pagination.pageNum,
            pageSize: this.state.pagination.pageSize,
        }
        getColumnNewsList(data).then(res =>{
            if(res){
                console.log("res:",res)
                pagination.total = res.total
                this.setState({
                    dataSource : res.data,
                    newsNum : res.total,
                    pagination : pagination
                })
            }
        })
    }

    cancelAddColumn = () =>{
        this.setState({
            add_child_lm : false
        }, () =>{
            this.props.specialColumnList()
        })
    }

    determineColumn = () =>{
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("values",values)
                let columnId = ''
                if(this.state.operateType === 1){
                    columnId = '';
                }else {
                    columnId = this.state.columnInfo.columnId
                }
                let data = {
                    columnName : values.columnName,
                    columnExplain : values.columnExplain,
                    createUserId : this.state.userId,
                    specialId : this.props.specialId,
                    columnId : columnId,
                }
                if(this.state.operateType === 2){
                    console.log("this.state.columnInfo.columnId", columnId)
                    updateSpecialColumn(data).then(res =>{
                        if(res){
                            this.mesSuccess("编辑成功")
                            this.cancelAddColumn()
                            let columnInfo = this.state.columnInfo
                            columnInfo.columnName = values.columnName
                            columnInfo.columnExplain = values.columnExplain
                            this.setState({
                                columnInfo : columnInfo
                            })
                        }
                    })
                }else {
                    console.log("this.state.columnInfo.columnId", columnId)
                    addSpecialColumn(data).then(res =>{
                        if(res){
                            this.mesSuccess("添加成功")
                            this.cancelAddColumn()
                        }
                    })
                }
            }
        })
    }

    addColumn = () =>{
        this.setState({
            add_child_lm:true,
            operateType : 1,
            column:'创建子专栏'
        })
    }

    updateColumn = () =>{
        this.setState({
            add_child_lm:true,
            operateType : 2,
            column:'编辑子专栏'
        })
    }

    showDeleteColumnConfirm = () => { // 删除专栏
        if(this.props.releaseStatus === 1){
            let data = {
                specialId : this.props.specialId,
                columnId : this.state.columnInfo.columnId
            }
            getSpecialColumnNum(data).then(res =>{
                if (res > 0){
                    this.hint({
                        'title': '温馨提示',
                        'content': `您是否要删除【${this.state.columnInfo.columnName}】此专栏，删除后不可找回！`,
                        'type': 'warning',
                        'cancelText': '取消',
                        'okText': '删除',
                        'isCancel': true,
                        'sure': () => {this.deleteColumn()}
                    })
                }else {
                    // message.destroy();
                    this.mesWarning("专题已发布，至少有一个子专栏！")
                }
            })
        }else {
            this.hint({
                'title': '温馨提示',
                'content': `您是否要删除【${this.state.columnInfo.columnName}】此专栏，删除后不可找回！`,
                'type': 'warning',
                'cancelText': '取消',
                'okText': '删除',
                'isCancel': true,
                'sure': () => {this.deleteColumn()}
            })
        }
    }

    deleteColumn = () =>{
        console.log("this.state.columnInfo.columnId", this.state.columnInfo.columnId)
        let data = {
            id : this.state.columnInfo.columnId
        }
        deleteSpecialColumn(data).then(res =>{
            if (res){
                this.mesSuccess("删除成功")
                this.cancelAddColumn()
            }
        })
    }

    relationNews = () =>{
        this.setState({
            isDistribution : true
        },() =>{
            setTimeout(() =>{
                this.DistributionCource.getNotRelationNewsList()
            },100)
        })
    }

    // 获取子组件
    onRef = (name, ref) => {
        switch (name) {
            case 'DistributionCource':
                this.DistributionCource = ref
                break
            default:
                break
        }
    }

    parentCallsChild = () =>{
        this.DistributionCource.determineRelationNews()
    }

    determineRelationNews = () =>{
        this.setState({
            isDistribution : false
        }, () =>{
            this.mesSuccess("添加成功")
            let pagination = this.state.pagination
            pagination.pageNum = 1
            pagination.pageSize = 5
            pagination.total = 0
            this.setState({
                pagination: pagination
            }, () => this.getColumnNewsList())
        })
    }

    addNews = () =>{
        this.props.history.push(`/main/group-column/addnews?${decodeURI(JSON.stringify({ columnId: this.state.columnInfo.columnId, platform : this.props.platform}))}`)
    }

    setLen = val => {
        this.setState(val);
    }

    render() {
        const {columns,dataSource,tableLoading,isDistribution, pagination,add_child_lm} = this.state
        const {getFieldDecorator} = this.props.form
        let { sortedInfo, filteredInfo,column } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        let {/*add_child_lm*/} = this.props
        return (
        <div className="InformationNews_cont">
            <div>
                {
                    this.props.columnInfo ? (
                        <div className='top'>
                            <div className="left">
                                <div className='left_title'>{this.state.columnInfo.columnName}（{this.state.newsNum}条新闻）</div>
                                <div className='left_content'>
                                    {this.state.columnInfo.columnExplain}
                                </div>
                            </div>
                            <div className="right">
                                {/*<Button type="primary" className='back_blue' onClick={this.relationNews}>关联资讯</Button>*/}
                                {/*/!*<Button type="primary" className='border_blue' onClick={this.addColumn}>创建子栏目</Button>*!/*/}
                                {/*<Button type="primary"  className='border_blue' onClick={this.updateColumn}>编辑专栏</Button>*/}
                                {/*<Button type="primary"  className='border_blue' onClick={()=>this.showDeleteColumnConfirm()}>删除专栏</Button>*/}
                                <RenderButton id='NC_01_B11_c_btnRelatedinformation'>
                                    <Button type="primary" className='back_blue' onClick={this.relationNews}>关联资讯</Button>
                                </RenderButton>
                                <RenderButton id='NC_01_B11_c_btnEditcolumn'>
                                    <Button type="primary"  className='border_blue' onClick={this.updateColumn}>编辑专栏</Button>
                                </RenderButton>
                                <RenderButton id='NC_01_B11_c_btnaddcolumn'>
                                    <Button type="primary"  className='border_blue' onClick={()=>this.showDeleteColumnConfirm()}>删除专栏</Button>
                                </RenderButton>
                                <Button type="primary"  className='border_blue' onClick={this.addNews}>新增新闻</Button>
                            </div>
                        </div>
                    ) : ''
                }
                <div className="bottomss">
                    {
                        tableLoading ? <Spin className="Spin-content" tip="加载中"/> : this.props.columnInfo ?  (
                            <div className='all_wapper'>
                                <Table
                                    className='organ-table organ-table-live'
                                    columns={columns}
                                    dataSource={dataSource}
                                    pagination = {
                                        {
                                            pageNum: pagination.pageNum,
                                            pageSize: pagination.pageSize,
                                            total: pagination.total,
                                            current: pagination.pageNum,
                                            onChange: this.changePage,
                                            // onShowSizeChange: this.onShowSizeChange,
                                            // showSizeChanger: true,
                                            // pageSizeOptions: ["10", "20", "30", "100"], //默认分页
                                        }
                                    }
                                    rowKey={v => v.newsId}
                                    locale={{
                                        emptyText: <>
                                            <div className="Empty-conet-zixun">
                                                <div className="img">
                                                    <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                                    <span>暂无数据</span>
                                                </div>
                                            </div>
                                        </>
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="Empty-conet-zixun">
                                <div className="img">
                                    <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            {/*创建修改子栏目*/}
            <Modal
                title={column}
                width={660}
                wrapClassName='modal_con_son1'
                visible={add_child_lm}
                destroyOnClose={true}
                closable={false}
                centered={true}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={this.cancelAddColumn}>取消</Button>
                        <Button type='primary' onClick={this.determineColumn}>确定</Button>
                    </div>
                }>
                <div>
                    <Form>
                        <Form.Item>
                            <Row>
                                <Col span={5}>
                                    <span><span className='red'>*</span>子栏目名称</span>
                                </Col>
                                <Col span={15}>
                                    {
                                        getFieldDecorator('columnName', {
                                            initialValue:this.state.operateType==1?"":this.state.columnInfo.columnName,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入子栏目名称'
                                                },
                                                {
                                                    max: 20,
                                                    message: "子栏目名称最多20个字"
                                                },
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '').replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
                                        })(
                                            <Input placeholder="请输入子栏目名称" maxLength={20} suffix={<span>
                                                {this.props.form.getFieldValue(`columnName`) ? this.props.form.getFieldValue(`columnName`).length : 0}/20</span>}/>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item style={{position: 'relative'}}>
                            <Row>
                                <Col span={5}><span><span className='red'></span>子专栏说明</span></Col>
                                <Col style={{position: 'relative'}} span={15}>
                                <span className='textarea_len'>
                                   {this.props.form.getFieldValue(`columnExplain`) ? this.props.form.getFieldValue(`columnExplain`).length : 0}/50
                                </span>
                                    {
                                        getFieldDecorator('columnExplain', {
                                            initialValue: this.state.operateType==1?"":this.state.columnInfo.columnExplain,
                                            rules: [
                                            ]
                                        })(
                                            <TextArea
                                                placeholder='请输入子专栏说明'
                                                onChange={e => this.setLen({jhmc: e.target.value.length})}
                                                maxLength={50}
                                                style={{resize: 'none'}} rows={5}/>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            {/*关联资讯*/}
            <Modal
                visible={isDistribution}
                wrapClassName='Modal-distribution'
                closable={false}
                width={1200}
                centered={true}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.setState({isDistribution: false})}>取 消</Button>
                        <Button onClick={this.parentCallsChild} type='primary'>确 定</Button>
                    </div>
                }
            >
                <div>
                    <DistributionCourceL columnInfo={this.state.columnInfo} onRef={this.onRef}
                                         determineRelationNews={this.determineRelationNews} platform={this.props.platform}/>
                </div>
            </Modal>
        </div>
        );
    }
}

InformationNews = Form.create()(InformationNews)
export default InformationNews;
