/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Form, Input, Button, Table, Pagination } from "antd";
import { queryTableList, batchAddSubOrg } from "../../api/manage_mechanism_manage";
import iconType from '../../icon_type';
import ConfigureApplicationModal from '../../components/config_application_modal';
import MechanismModal from '../../components/mechanism_modal';
import styles from './index.module.scss';
import '../../components/confirm';

class MaterialDataStatistics extends Component {
    state = {
        dataSource: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        searchValue: "",
        mechanismVisible:false,//机构管理弹窗
        selectItem:{},//选中的操作项
        configAppVisible:false,//配置应用弹窗
    };

    componentDidMount() {
        this.getDataSource();
    };

    getDataSource = async () => {
        const {pageNum,pageSize,searchValue} = this.state;
        const params = {pageNum,pageSize};
        if(searchValue){
            params.adminOrgName = searchValue;
        };
        try {
            const res = await queryTableList(params);
            this.setState({dataSource:res.data,total:res.total});
        } catch (error) {}
    }

    //分页
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => {
            this.getDataSource();
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({pageNum:1, pageSize },() => {
            this.getDataSource();
        })
    };

    //所属分类
    classifyChange = (value) => {
        this.setState({
            classifyValue: value
        })
    };

    //是否分类
    changeTabMenu = (key) => {
        this.setState({
            dateType: key,
            pageNum: 1,
        }, () => {
            this.getDataSource();
        })
    };

    //查询
    SearchChange = () => {
        this.getDataSource();
    };

    /*文件类型*/
    setFileType = fileType => {
        this.setState({
            fileType
        }, () => {
            this.getDataSource();
        });
    };

    /*搜索*/
    onSearch = e => {
        this.setState({ searchValue: e.target.value },);
    };

    //添加下级管理机构
    AddSubOrg = async res => {
        const {selectItem} = this.state;
        try {
            await batchAddSubOrg({adminOrgId:selectItem.id,subOrgIds:res.map(it=>it.adminOrgId)});
            this.mesSuccess('机构添加成功');
            this.getDataSource();
            this.setState({mechanismVisible:false});
        } catch (error) {
            console.warn(error);
        }
    }

    columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render:(_,__,index) => (index+1)
        },
        {
            title: '管理机构名称',
            dataIndex: 'enterpriseName',
            key: 'enterpriseName',
            align: 'center',
            width:'20%',
            ellipsis:true,
        },
        { 
            title: '所管范围', 
            dataIndex: 'adminOrgRangeName', 
            key: 'adminOrgRangeName', 
            align: 'center', 
            width:'20%',
        },
        { 
            title: '管理院校数', 
            dataIndex: 'schoolNum', 
            key: 'schoolNum', 
            align: 'center',
        },
        { 
            title: '加入日期', 
            dataIndex: 'settledDate', 
            key: 'settledDate', 
            align: 'center', 
        },
        { 
            title: '到期日期', 
            dataIndex: 'settledEndDate', 
            key: 'settledEndDate', 
            align: 'center', 
        },
        { 
            title: '操作', 
            dataIndex: 'option', 
            key: 'option', 
            align: 'center', 
            width:360,
            render: (_, record) => <div className="table_option">
                <React.RenderButton 
                    children={
                        <a onClick={() => {this.setState({selectItem:record});this.props.history.push(`/main/institutional-settlement/manage_mechanism_info?id=${record.id}&name=${record.enterpriseName}学校详情`)}}>进入详情</a>
                    }
                    id={'OBSMM-01-A02-b-btnGoInNeirong'}
                />
                <React.RenderButton 
                    children={
                        <a onClick={() => {this.setState({selectItem:record});this.props.history.push(`/main/institutional-settlement/manage_mechanism_updata?id=${record.id}`)}}>修改机构信息</a>
                    }
                    id={'OBSMM-01-A02-b-btnUpdataSchoolInformation'}
                />
                <React.RenderButton 
                    children={
                        <a onClick={() => this.setState({selectItem:record,configAppVisible:true})}>配置应用</a>
                    }
                    id={'OBSMM-01-A02-b-btnConfigureApplication'}
                />
                <React.RenderButton 
                    children={
                        <a onClick={() => this.setState({selectItem:record,mechanismVisible:true})}>机构管理</a>
                    }
                    id={'OBSMM-01-A02-b-btnJiGouGuanLi'}
                />
            </div>
        },
    ];
    
    render() {
        const { 
            dataSource, searchValue, 
            total, pageNum, pageSize,
            mechanismVisible, selectItem,
            configAppVisible,
        } = this.state;
        
        return (
            <div className={styles["manage_mechanism_manage"]}>
                <div className={styles['bottom_file_type_box']}>
                    <React.RenderButton 
                        children={
                            <Button type="primary" onClick={()=>this.props.history.push('/main/institutional-settlement/manage_mechanism_add')}>创建管理机构</Button>
                        }
                        id={'OBSMM-01-A02-b-btnAddSchool'}
                    />
                    <span />
                    <div className={styles['file_type_r']}>
                        <Input
                            placeholder="请输入文件名称或关键字"
                            style={{ width: 230 }}
                            value={searchValue}
                            onChange={this.onSearch.bind(this)}
                            prefix={iconType.magnifier}
                        />
                        <React.RenderButton 
                            children={
                                <Button onClick={() => this.SearchChange()} className={styles['resetbtn']}>查 询</Button>
                            }
                            id={'OBSMM-01-A02-b-btnSearch'}
                        />
                        <span style={{ color: "#888" ,marginLeft:20}}>
                            共{this.state.total > 99999 ? "99999+" : total}个管理机构入驻
                                </span>
                    </div>
                </div>
                <div className={styles['manage_mechanism_manage_table']}>
                    <Table
                        dataSource={dataSource}
                        columns={this.columns}
                        pagination={false}
                        rowKey="id"
                        scroll={{
                            y: true
                        }}
                        locale={{
                            emptyText:<img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                        }}
                        footer={() => {
                            return <Pagination
                                showSizeChanger
                                pageSize={pageSize}
                                current={pageNum}
                                total={total}
                                onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                onChange={p => this.pageChange(p)}
                            />
                        }} 
                    />
                </div>
                {
                    mechanismVisible && <MechanismModal 
                        item={selectItem}
                        onCancel={() => this.setState({mechanismVisible:false})}
                        onOk={this.AddSubOrg}
                    />
                }
                <ConfigureApplicationModal
                    configAppVisible={configAppVisible}
                    onCancel={()=>this.setState({configAppVisible:false})}
                    enterpriseId={selectItem.id}
                />
            </div>
        );
    }
}


const MaterialDataStatisticsForm = Form.create()(MaterialDataStatistics);
export default MaterialDataStatisticsForm;
