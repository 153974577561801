import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Row, Col, Checkbox, Radio, DatePicker} from 'antd';
import './index.scss';
import {
    deleteAcademyTeacher, resetUserPassword, selectAcademyStudent, addAcademyStudent,
    updateAcademyStudent, seeAcademyStudent, seeAcademyTeacher,getClassDetail
} from '../../../../api/platform-school/index'
import {
    checkImportSchoolStudents,
    downTemplate,
    getCitySel,
    getProvinceSel,
    globalSel,
    selectFaculty
} from '../../../../api/platform-business/index'
import Batch from '@/components/import';
import moment from "moment";
const { Option } = Select;

const Demo = () => {
    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
}
const App = () => {
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>男</Radio>
            <Radio value={0}>女</Radio>
        </Radio.Group>
    );
};

class index extends Component {
    state = {
        dataSource: [],
        isTeacher: false, // 新增成员弹层
        isEdit: false, // 编辑弹层
        isSee: false,  // 查看弹层
        isDelete: false,// 删除弹层
        isRepassword: false,// 重置密码
        isCheck: false,// 查看
        pageNum: 1,
        pageSize: 10,
        total: 0,
        studentNameOrNum: '',
        faculty: '',
        facultyList: [],
        facultyId: '',
        facultyName: '',
        userId: '',
        enterpriseId: '',
        enrollmentYear: '',
        birthday: '',
        jobNumLength: 0,
        politicalDicType: 15, // 政治面貌字典中的类型
        learningLevelDicType: 21, // 学习层次字典值
        learningLevelList: [],
        nationalsList: [],
        nationalsDicType: 20, // 民族字典值
        political: '',
        politicalList: [],
        provinceSel: [], // 省下拉列表
        citySel: [], // 市下拉列表
        politicalLandscape: '',
        classId: '',
        classList: [],
        className: '',
        majorId: '',
        majorName: '',
        isUpdateOrSee: '', // 1是编辑 2是查看
        userDetail: null,
        batch:false,
        sex: '',
        cityName: '',
        provinceName: '',
        studentId: '',
        adminUserId: '',
        provinceCode: '',
        cityId: '',
        scUserId: '' // 删除重置所需id
    }

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log('jsonparam.enterpriseId', jsonparam.enterpriseId)
        this.setState({
            enterpriseId: jsonparam.enterpriseId
        },() => {
            this.selectAcademyStudent()
            this.getFaculty()
            this.getClassDetail()
            this.getPolitical()
            this.getProvinceSel()
            this.getCitySel()
        })
    }

    // 查询
    async selectAcademyStudent(){
        console.log('this.state.enterpriseId', this.state.enterpriseId)
        const param = {
            enrollmentYear: this.state.enrollmentYear,
            enterpriseId: this.state.enterpriseId,
            facultyId: this.state.facultyId,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            studentNameOrNum: this.state.studentNameOrNum,
            userId: this.state.userId,
        }
        const academyStudentTable = await selectAcademyStudent(param)
        this.setState({
            total: academyStudentTable.total,
            dataSource: academyStudentTable.data
        })
    }

    // 新增
    async addAcademyStudent(){
        this.props.form.validateFields((err, values) => {
            if (!err){
                if(this.state.isUpdateOrSee === 1){
                    const updateAcademyStudentQo = {
                        id: this.state.studentId,
                        adminUserId: this.state.adminUserId,
                        birthdays: new Date(values.birthday),
                        cardType: values.cardType,
                        classId: values.classId,
                        email: values.email,
                        enrollmentYear: moment(values.enrollmentYear).format('YYYY-MM-DD HH:MM:ss'),
                        enterpriseId: this.state.enterpriseId,
                        facultyId: values.facultyId,
                        idCard: values.idCard,
                        jobNum: values.jobNum,
                        learningLevel: values.learningLevel,
                        majorId: values.majorId,
                        nationals: values.national,
                        nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                        password: values.password,
                        politicalLandscape: values.politicalLandscape,
                        sex: values.sex,
                        studentName: values.studentName,
                        telNum: values.telNum,
                        userId: this.state.userId,
                    }
                    console.log('addAcademyStudentQo', updateAcademyStudentQo)
                    updateAcademyStudent(updateAcademyStudentQo).then (res => {
                        if(res !== null){
                            this.setState({
                                isTeacher: false
                            })
                            this.mesSuccess('修改成功');
                            this.selectAcademyStudent()
                        }
                    })
                } else {
                    const addAcademyStudentQo = {
                        // birthdays: moment(values.birthday).format('YYYY-MM-DD'),
                        birthdays: new Date(values.birthday),
                        cardType: values.cardType,
                        classId: values.classId,
                        email: values.email,
                        enrollmentYear: moment(values.enrollmentYear).format('YYYY-MM-DD HH:MM:ss'),
                        enterpriseId: this.state.enterpriseId,
                        facultyId: values.facultyId,
                        idCard: values.idCard,
                        jobNum: values.jobNum,
                        learningLevel: values.learningLevel,
                        majorId: values.majorId,
                        nationals: values.national,
                        nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                        password: values.password,
                        politicalLandscape: values.politicalLandscape,
                        sex: values.sex,
                        studentName: values.studentName,
                        telNum: values.telNum,
                        userId: this.state.userId,
                    }
                    console.log('addAcademyStudentQo', addAcademyStudentQo)
                    addAcademyStudent(addAcademyStudentQo).then (res => {
                        if(res !== null){
                            this.setState({
                                isTeacher: false
                            })
                            this.mesSuccess('保存成功');
                            this.selectAcademyStudent()
                        }
                    })
                }
            }
        })
    }

    //检索
    search = (e) => {
        this.setState({ studentNameOrNum: e }, () => this.selectAcademyStudent());
    }


    async toStudentresult(){
        this.props.history.push("/main/platform-business/student-result")
    }
    async toStudentrecord(){
        this.props.history.push(`/main/platform-business/teacher-record?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId,jumpType:2}))}`);
    }

    // 院系下拉
    getFaculty = async () => {
            let facultyRes = await selectFaculty({
                enterpriseId: this.state.enterpriseId,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
            });
        facultyRes && this.setState({ facultyList: facultyRes });
    }

    // 院系下拉框change
    changeFaculty = (e, item) => {
        this.setState({
            facultyId: e
            }, () =>
                this.selectAcademyStudent()
        );
    }
    // 行政班级下拉
    getClassDetail = async () => {
            let classList = await getClassDetail({
                enterpriseId: this.state.enterpriseId,
                pageNum: 1,
                pageSize: '',
            });
        classList && this.setState({ classList: classList });
    }

    // 行政班级下拉框change
    changeClass = (e, item) => {
        this.setState({
            facultyId: e
            }, () =>
                this.selectAcademyStudent()
        );
    }

    // 重置密码
    resetPassword = (record) => {
        // let _this = this;
        // Modal.confirm({
        //     title: "温馨提示",
        //     content: "确定要重置密码吗？",
        //     okText: "确定",
        //     cancelText: "关闭",
        //     centered:true,
        //     className: "ModalConfirm-pic",
        //     onOk() {
        //         resetUserPassword({userId: record.userId}).then((res) => {
        //             this.mesSuccess("重置成功");
        //             _this.setState({
        //                 isRepassword: false
        //             },()=>{
        //                 _this.selectAcademyStudent();
        //             })
        //         });
        //     },
        //     onCancel() {},
        // });
        this.setState({
            scUserId: record.userId
        })
        this.hint({'title': '温馨提示',
            'content': '确定要重置密码吗?',
            'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.resetUserPassword})
    }

    //重置密码
    resetUserPassword = async () => {
        const {scUserId} = this.state
        let data = {
            userId: scUserId
        }
        await resetUserPassword(data).then(r => {
            if(r){
                this.mesSuccess("重置成功");
                this.setState({
                    isRepassword: false
                },()=>{
                    this.selectAcademyStudent();
                })
            }
        })
    }

    // 删除
    deleteAcademyTeachers = (record) =>{
        // let _this = this;
        // Modal.confirm({
        //     title: "温馨提示",
        //     content: "确定要删除吗？删除后将无法恢复！",
        //     okText: "确定",
        //     cancelText: "关闭",
        //     centered:true,
        //     className: "ModalConfirm-pic",
        //     onOk() {
        //         deleteAcademyTeacher({userId: record.userId}).then((res) => {
        //             this.mesSuccess("删除成功");
        //             _this.setState({
        //                 isDelete: false
        //             },()=>{
        //                 _this.selectAcademyStudent();
        //             })
        //         });
        //     },
        //     onCancel() {},
        // });
        this.setState({
            scUserId: record.userId
        })
        this.hint({'title': '温馨提示',
            'content': '确定要删除吗？删除后将无法恢复！',
            'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.deleteAcademyTeacher})

    }
    //删除
    deleteAcademyTeacher = async () => {
        const {scUserId} = this.state
        let data = {
            userId: scUserId
        }
        await deleteAcademyTeacher(data).then(r => {
            if(r){
                this.mesSuccess("删除成功");
                this.setState({
                    isDelete: false
                },()=>{
                    this.selectAcademyStudent();
                })
            }
        })
    }

    // 政治面貌下拉
    getPolitical = async () => {
        // 政治面貌
        if (this.state.politicalDicType) {
            let politicalRes = await globalSel({ type: this.state.politicalDicType });
            politicalRes && this.setState({ politicalList: politicalRes });
        }
        // 学习层次
        if (this.state.learningLevelDicType) {
            let learningLevelRes = await globalSel({ type: this.state.learningLevelDicType });
            learningLevelRes && this.setState({ learningLevelList: learningLevelRes });
        }
        // 民族
        if (this.state.nationalsDicType) {
            let nationalsRes = await globalSel({ type: this.state.nationalsDicType });
            nationalsRes && this.setState({ nationalsList: nationalsRes });
        }

    }

    // 政治面貌下拉框change
    changePolitical = (e, item) => {
        this.setState({ political: e}, () => this.selectAcademyStudent());
    }

    enrollmentYear = (e) => {
        let enrollmentYear = moment(e).format('YYYY-MM-DD')
        this.setState({
            enrollmentYear: enrollmentYear
        }, () => {
            this.selectAcademyStudent();
        })
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.selectAcademyStudent());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.selectAcademyStudent());
        window.elGoTop && window.elGoTop('body');
    }

    // 姓名输入框长度
    jobNumLength =  (rule, value, callback) => {
        if(value && value.length === 0){
            this.setState({
                jobNumLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                jobNumLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 表单院系
    faculty = (e, item) => {
        this.setState({
            facultyId: e,
            facultyName: item.props.children,
        })
    }
    // 政治面貌
    political = (e, item) => {
        this.setState({
            politicalLandscape: item.props.children,
        })
    }

    // 行政班级
    getClass = (e, item) => {
        this.setState({
            classId: e,
            className: item.props.children,
        })
    }
    // 专业
    getMajor = (e, item) => {
        this.setState({
            majorId: e,
            majorName: item.props.children,
        })
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号')
        } else {
            callback();
        }
    }
    // 校验邮箱格式
    validateEmail =  (rule, value, callback) => {
        let emailReg =  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (value && !emailReg.test(value)){
            callback('请输入正确的电子邮箱')
        } else {
            callback();
        }
    };

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        this.setState({ provinceCode: e, citySel: [],provinceName: item.props.children }, () =>
            this.getCitySel()
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        this.setState({
            cityId: e,
            cityName: item.props.children
        });
    }

    // 编辑按钮
    update = (record) => {
        this.setState({
            isTeacher: true,
            isUpdateOrSee: 1,
            studentId: record.id,
            adminUserId: record.userId
        },() => {
            this.seeAcademyStudent(record,1)
        })
    }
    // 导入成员弹窗取消
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    getFormData=(formData)=>{
        console.log("1111111111111",formData.get("file"))
        formData.set("enterpriseId",this.state.enterpriseId)
        this.setState({
            batchRef: formData
        },()=>{
            console.log("batchRef",this.state.batchRef)
        })
    }
    //导入时得确定按钮
    commitBatch = () => {
        //从子组件调用导入接口，更改为点击确定后上传了
        console.log('this.state.batchRef)', this.state.batchRef)
        checkImportSchoolStudents(this.state.batchRef).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('上传成功');
                this.studentResult(res)
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    sure = (e) => {
        this.setState({ btnActive: true, fileId: e });
    }
    async studentResult(res){
        let transParam = {
            recordId: res,
            enterpriseId:this.state.enterpriseId
        }
        let param = JSON.stringify(transParam);
        this.props.history.push(`/main/platform-business/student-result?${encodeURI(param)}`)
    }

    seeAcademyStudent =(record,type) => {
        console.log('record', record)
        this.setState({
            isTeacher: true,
            isUpdateOrSee: type ? type :2
        })
        let data = {
            userId: record.userId,
            enterpriseId: this.state.enterpriseId
        }
        seeAcademyStudent(data).then((res) => {
            console.log('res ===seeAcademyStudent===>>>> ', res)
            if(res.province){
                this.setState({
                    provinceCode: res.province
                },() => {
                    this.getCitySel()
                })
            }
            this.setState({
                birthday: res.birthday,
                // enrollmentYear: res.enrollmentYear,
                jobNumLength: res.jobNum ? res.jobNum.length : 0,
                sex: res.sex,
            })
            this.props.form.setFieldsValue({
                jobNum: res.jobNum,
                studentName: res.studentName,
                facultyId: res.facultyId,
                classId: res.classId,
                telNum: res.telNum,
                email: res.email,
                enrollmentYear: moment(res.enrollmentYear, 'YYYY-MM-DD'),
                password: res.passwords,
                politicalLandscape: res.politicalLandscape ? Number(res.politicalLandscape) : '',
                learningLevel: res.learningLevel ? Number(res.learningLevel) : '',
                cardType: res.cardType ? res.cardType : '',
                idCard: res.idCard,
                national: res.nationals ? Number(res.nationals) : '',
                province: res.province ? res.province : '',
                city: res.city ? res.city : '',
                sex: res.sex,
            })
        })
    }


    render() {
        let {sex,enrollmentYear,birthday,sexValue,isUpdateOrSee,provinceSel,citySel,isTeacher,isEdit,
            isRepassword,isDelete,isCheck,isSee,dataSource,facultyList,total,pageSize,jobNumLength,
            politicalList,classList,learningLevelList,nationalsList} = this.state;
        let {batch,btnActive,errorMessage} = this.state
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '工号/帐号', dataIndex: 'jobNum',key:'jobNum',width:'30', },
            {title: '姓名', dataIndex: 'studentName',key:'studentName',width:'40', },
            {title: '院系', dataIndex: 'facultyName',key:'facultyName',width:'50', },
            // {title: '专业', dataIndex: 'majorName',key:'majorName',width:'50', },
            {title: '行政班级', dataIndex: 'className',key:'className', width:'50',},
            {title: '入学年份', dataIndex: 'enrollmentYear', key: 'enrollmentYear', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '手机号', dataIndex: 'telNum',key:'telNum', width:'150',},
            {title: '性别', align: 'center', render: (text, item, index ) =>  <span className='serial'>{item.sex === 2 ? '女' : item.sex === 1 ? '男' : ''}</span>,},
            {title: '所属层次', dataIndex: 'learningLevel',key:'learningLevel', width:'50',},
            {title: '出生日期', dataIndex: 'birthday', key: 'birthday', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},


            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:'200',
                render:(text, record)=><div className="opspan">
                    <div className="main">
                        <span className="but" onClick={() => this.seeAcademyStudent(record)}>查看</span>
                        <span className="but" onClick={() => this.update(record)}>编辑</span></div>
                    <div className="main">
                        <span className="but" onClick={() => this.deleteAcademyTeachers(record)}>删除</span>
                        <span className="but" onClick={() => this.resetPassword(record)}>重置密码</span></div>
                </div>},
        ];
        return (
            <div className='managestu_box'>
                <div className="onelist">
                    <div className="one">
                        <div>
                            <span style={{marginRight: '20px'}}>院系</span>
                            <Select placeholder='全部'
                                    onChange={this.changeFaculty}>
                                <Option value={''}>全部</Option>
                                {
                                    facultyList.map(v => <Option key={v.id} value={v.id}>{v.facultyName}</Option>)
                                }

                            </Select>
                        </div>
                        <div>
                            <span style={{marginRight: '20px'}}>专业</span>
                            <Select placeholder='全部'>
                                <Option value={''}>全部</Option>
                            </Select>
                        </div>
                        <div className='add_school_time'>
                            <span style={{marginRight: '20px'}} className='add_school_time_tit'>入学年份</span>
                            <DatePicker onChange={this.enrollmentYear} placeholder='请选择入学年份' style={{width: '100%'}} />
                        </div>
                    </div>
                </div>
                <div className="twolist">
                    <div className="top_list">
                        <div className="leftlist">
                            <Button className="buttonl" type="primary" style={{marginRight: '20px'}} onClick={() => this.setState({ batch: true })}>导入学生</Button>
                            <Button className="buttonm" style={{marginRight: '20px'}} onClick={() => this.setState({ isTeacher: true,isUpdateOrSee: 3  })}>新增学生</Button>
                            <Modal
                                title={isUpdateOrSee === 1 ? '编辑学生信息': isUpdateOrSee === 2 ? '查看学生信息': '新增学生信息'}
                                visible={isTeacher}
                                destroyOnClose={true}
                                closable={false}
                                className='binding_modal binding_modal_add_edit_STU'
                                footer={
                                    <div className='foot_botton'>
                                        {
                                            isUpdateOrSee === 1 ? <Button className="addbutton" onClick={() => this.addAcademyStudent()}>确认修改</Button> :
                                                isUpdateOrSee === 2 ? '' :
                                                <Button className="addbutton" onClick={() => this.addAcademyStudent()}>确认添加</Button>
                                        }

                                        <Button className="delete" onClick={() => this.setState({ isTeacher: false })}>取消</Button>
                                    </div>
                                }
                            >
                                <div className="modaltitle">
                                    个人信息
                                </div>
                                <Form className='lt_form_wapper'>
                                    <Form.Item  label={`学号/工号`}>
                                        <div className="all">
                                            {getFieldDecorator('jobNum', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请输入学号/工号`,
                                                    },
                                                    {
                                                        validator:this.jobNumLength
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入学号/工号" style={{ width: 330 , marginTop:"4px"}} />)}
                                            <span className="limited">{jobNumLength}/20</span>
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`姓名`}>
                                        <div className="all">
                                            {getFieldDecorator('studentName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请输入姓名`,
                                                    },
                                                    // {
                                                    //     validator:this.studentNameLength
                                                    // }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} />)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`院系/部门`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*</div>*/}
                                            {
                                                getFieldDecorator('facultyId', {
                                                    initialValue: undefined,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择院系/部门`,
                                                        },
                                                    ],
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择院系/部门' style={{ width: 330 , marginTop:"4px"}}
                                                    >
                                                        {/*<Option value={''}>全部</Option>*/}
                                                        {
                                                            facultyList.map(v => <Option key={v.id} value={v.id}>{v.facultyName}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`行政班级`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*</div>*/}
                                            {
                                                getFieldDecorator('classId', {
                                                    initialValue: undefined,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择行政班级`,
                                                        },
                                                    ],
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择行政班级'>
                                                        {
                                                            classList.map(v => <Option key={v.id} value={v.id}>{v.className}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`入学年份`}>
                                        <div className="all">
                                            {getFieldDecorator('enrollmentYear', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择入学年份`,
                                                    },
                                                ],
                                                initialValue: enrollmentYear? moment(enrollmentYear, 'YYYY-MM-DD') : null
                                            })(<DatePicker disabled={isUpdateOrSee === 2} placeholder='请选择入学年份' style={{width: '63%'}} />)}
                                        </div>
                                    </Form.Item>
                                    <div className="modaltitle">
                                        机构信息
                                    </div>
                                    <Form.Item label={`电话`}>
                                        <div className="all">
                                            {getFieldDecorator('telNum', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请输入电话`,
                                                    },
                                                    {
                                                        validator:this.validatePhone
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入电话" style={{ width: 330 , marginTop:"4px"}} />)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`邮箱`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">邮箱:</span>*/}
                                            {/*</div>*/}
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    // {
                                                    //     required: true,
                                                    //     message: `请填写电子邮箱`,
                                                    // },
                                                    {
                                                        validator:this.validateEmail
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(<Input disabled={isUpdateOrSee === 2} style={{ width: 330 , marginTop:"4px"}} placeholder="请填写电子邮箱" className="input" maxLength={100}/>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item className="sex" label={`性别`}>
                                        <div className="all">
                                            {getFieldDecorator('sex', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择性别`,
                                                    },
                                                ],
                                                initialValue: sex? sex : ""
                                            })(
                                                <Radio.Group value={sex? sex : ""} disabled={isUpdateOrSee === 2}>
                                                    <Radio value={1}>男</Radio>
                                                    <Radio value={2}>女</Radio>
                                                </Radio.Group>)}

                                        </div>
                                    </Form.Item>
                                    {
                                        isUpdateOrSee !== 1 && isUpdateOrSee !== 2 ?
                                            <Form.Item label={`密码`}>
                                                <div className="all">
                                                    {getFieldDecorator('password', {
                                                        initialValue: "zjy123456",
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `请输入密码`,
                                                            },
                                                        ],
                                                    })( <Input disabled={isUpdateOrSee === 2} placeholder="请输入密码" style={{ width: 330 , marginTop:"4px"}}/>)}
                                                </div>
                                            </Form.Item> : ''
                                    }
                                    <Form.Item className='date_input' label={`出生日期`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">出生日期:</span>*/}
                                            {/*</div>*/}
                                            {getFieldDecorator('birthday', {
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                                initialValue: birthday? moment(birthday, 'YYYY-MM-DD') : null
                                            })(<DatePicker disabled={isUpdateOrSee === 2} placeholder='请选择出生日期' style={{width: '330px'}} />)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`籍贯`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">籍贯:</span></div>*/}
                                            {getFieldDecorator('province', {
                                            })(
                                                <Select placeholder='请选择省'
                                                        disabled={isUpdateOrSee === 2}
                                                        onChange={this.changeProvince}
                                                >
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                        provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                                    }
                                                </Select>
                                            )}
                                            {getFieldDecorator('city', {
                                            })(
                                                <Select placeholder='请选择市'
                                                        disabled={isUpdateOrSee === 2}
                                                        onChange={this.changeCity}
                                                >
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                        citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                                    }
                                                </Select>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`政治面貌`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">政治面貌:</span></div>*/}
                                            {
                                                getFieldDecorator('politicalLandscape', {
                                                    initialValue: undefined,
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择政治面貌' style={{ width: 330 , marginTop:"4px"}}
                                                            // onChange={this.political}
                                                    >
                                                        {
                                                            politicalList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`学习层次`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">学习层次:</span></div>*/}
                                            {
                                                getFieldDecorator('learningLevel', {
                                                    initialValue: undefined,
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择学习层次'>
                                                        {
                                                            learningLevelList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`证件类型`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">证件类型:</span></div>*/}

                                            {
                                                getFieldDecorator('cardType', {
                                                    initialValue: undefined,
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择证件类型'>
                                                        <Option value={"0"}>身份证</Option>
                                                        <Option value={"1"}>护照</Option>
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`证件号码`}>
                                        <div className="all">
                                            {getFieldDecorator('idCard', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请输入证件号码`,
                                                    },
                                                ],
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入证件号" style={{ width: 330 , marginTop:"4px"}}/>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`民族`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">民族:</span></div>*/}
                                            {
                                                getFieldDecorator('national', {
                                                    initialValue: undefined,
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择民族'>
                                                        {
                                                            nationalsList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                </Form>
                            </Modal>

                            <Button className="buttonr" onClick={() =>{this.toStudentrecord()}}><img src={require('@/assets/img/lead.png')} alt="" />导入记录</Button>
                        </div>
                        <div className="right_list">
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入教师工号/名字' />
                        </div>
                    </div>
                    <div className="table-list">
                        <Table rowKey={v => v['enterpriseId']} pagination={
                            {
                                total,
                                pageSize:pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['5', '10', '20', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns} dataSource={dataSource} />
                    </div>
                </div>
                <Modal className='batch' visible={batch} title='学校信息导入'
                       closable={false}
                       footer={
                           <div style={{ width: "100%" }}>
                               <div className='pos-btn'>
                                   <Button onClick={this.closeBatch}>取消</Button>
                                   <Button disabled={btnActive ? false : true} className={btnActive ? "" : "gray"}
                                           onClick={this.commitBatch}>确定</Button>
                               </div>
                               {errorMessage ?
                                   <div className='error'>
                                       <span>请下载查看</span>
                                       <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                   </div>
                                   : null}
                           </div>
                       }
                >
                    <Batch
                        ref={ref => this.batchRef = ref}
                        getFormData={this.getFormData}
                        sure={this.sure}
                        reset={() => this.setState({ errorMessage: '', btnActive: false })}
                        template={downTemplate(8)}
                        pullError={(val) => this.setState(val)}
                        titleType='院校学生'
                    />
                </Modal>
            </div>
        )
    }
}

index = Form.create()(index);
export default index;
