import React, { useState, useEffect } from 'react';
import { Menu, message } from 'antd';
import './index.scss';

const { SubMenu } = Menu;
const menuIconOpt = {
    "菜单管理": require('./svg/SvgIndex').default,
    "应用管理": require('./svg/SvgManagement').default,
    "角色管理": require('./svg/SvgPersonnel').default,
    "机构成员管理": require('./svg/SvgCategory').default,
    "机构入驻管理": require('./svg/SvgInstrument').default,
    "调研管理": require('./svg/SvgPortal').default,
    "反馈中心": require('./svg/SvgConfiguration').default,
    "帮助中心": require('./svg/SvgPracticaltraining').default,
    "实训硬件管理": require('./svg/SvgScenario').default,
    "实训资源管理": require('./svg/SvgCorporate').default,
    "分类标签": require('./svg/SvgApplication').default,
    "栏目管理": require('./svg/SvgProgram').default,
    "新闻管理": require('./svg/SvgPushthe').default,
    "专题管理": require('./svg/SvgSharingcenter').default,
    "推送管理": require('./svg/SvgPushthe').default,
    "平台用户管理": require('./svg/SvgSpatialallocation').default,
    "机构申请管理": require('./svg/SvgCampuscircle').default,

}
export default props => {
    let { menuList, openArr, onJump } = props;
    const curPath = props.location.pathname;
    // const [openKeys, setOpenKey] = useState(openArr ? openArr : menuList.map(v => v.id));
    const [openKeys, setOpenKey] = useState(menuList.map(v => v.id));
    // console.log("menuList+++++++",menuList)
    const [selectedKeys, setSelKey] = useState([]);
    //查找和路由匹配的项
    let find = (arr, parent = null) => {
        arr.find(v => {
            const copyV = {...v}
            copyV.parent = parent
            if (v.menuUrl === curPath) {
                setSelKey([v.id]);
                onJump && onJump({ ...copyV })
            } else if (v.children) {
                find(v.children, copyV);
            }
        })
    }
    // useEffect(() => {
    //     setOpenKey(openArr);
    // }, [openArr])
    useEffect(() => {
        !openArr && setOpenKey(menuList.map(v => v.id));
        find(menuList);
    }, [menuList]);
    useEffect(() => {
        find(menuList);
    }, [curPath])
    //路由跳转
    let jump = (path, route) => {
        if (path === curPath) {
            return;
        }
        if (path) {
            onJump && onJump({ ...route, menuUrl: path });
            props.history.push(path);
        } else {
            // return message.warning('该功能正在建设中,敬请期待...');
            this.mesWarning('该功能正在建设中，敬请期待...');
            return;
        }
        if (localStorage.getItem('previewNews') != null) {
            localStorage.removeItem('previewNews')
        }
        // props.history.push(path);
    }
    //展开收起
    let change = (openKeys) => {
        setOpenKey(openKeys);
    }
    return <Menu
        onOpenChange={change}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        mode='inline'>
        {
            menuList && menuList.map(((v, index) => {
                const Com = menuIconOpt[v.menuName];
                const Icon = typeof Com==='function' ? <Com /> :  Com ?
                    <img className='icon_j' src={menuIconOpt[v.menuName]} alt='icon' /> :
                    <img className='icon_j' src={window.$$setImgSrc(v.icon)} alt='icon' />;
                if (v.children&&v.children.length > 0) {
                    return <SubMenu key={v.id} title={<>
                        <span className="title_svg">{Icon}</span>
                        {v.menuName}  </>}>
                        {
                            v.children.map(k => {
                                if (!k.isHade) {
                                    return (k.menuName == '添加推荐资源' ? '' :
                                        <Menu.Item onClick={() => jump(k.menuUrl, v)} key={k.id}>
                                            {k.menuName}
                                        </Menu.Item>)
                                }
                            })
                        }
                    </SubMenu>
                } else {
                    return <Menu.Item onClick={() => jump(v.menuUrl, v)} key={v.id}>
                        <span className="title_svg">
                        {v.menuUrl === curPath ?
                            v.icon ?
                                Icon:Icon:Icon
                        }
                        </span>
                        {/*{v.iconActive && v.menuUrl === curPath ? <img className='icon_sidebar'*/}
                        {/*                                              src={window.$$img(v.iconActive)}*/}
                        {/*                                              alt=''/> : <img className='icon_sidebar'*/}
                        {/*                                                              src={window.$$img(v.icon)}*/}
                        {/*                                                              alt=''/>}*/}

                        <span>{v.menuName}</span>
                    </Menu.Item>
                }
            }))
        }
    </Menu>
}