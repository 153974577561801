
import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/check/action';
import './index.scss';
import {Button, Input, Col, Checkbox, Tag, Form, DatePicker, message, Select, Modal,} from 'antd';
import kindName from 'classnames';
import MyTable from '@/components/Table';
import { ExclamationCircleOutlined } from "@ant-design/icons";
// import {DndProvider} from "react-dnd";
// import {HTML5Backend} from "react-dnd-html5-backend";
import {changeUser,reportTypeList, showReportList, hasDealList,noDealList,create,dealwith,dealNoReson,reportTypeUserList,getTopicDetail,deleteReportTopic,joinBlack,  deleteComment,showCommentDeleted,} from '@/api/circle/index'

// import {wholeCircleList,circleList,closedCircle,hasAuditList,noAuditList,blackList,} from '@/api/circle/index'
// import {tupOff,tupOffWait,getLastProcessType,getTipOffType} from '@/api/report/report'
import moment from 'moment';
//import ReportReason from './components/index'
const {Option} = Select
const {RangePicker} = DatePicker;

const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


class CommentReport extends React.Component {
    state = {
        _businessType:'PL',     //评论举报
        _platform:'OC',         //平台圈子

        _startTime:'',      //开始时间
        _endTime:'',        //结束时间

        _noDealListData:[],    //待处理举报列表
        _noDealListNumber:[],
        _hasDealListData:[],   //已处理举报列表  
        _hasDealListNumber:[],
        
        btnDisabled:true,  //重置按钮
        daiNumber:'',  // 待处理长度
        yiNumber:'',   //已处理长度
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            // tipOffType: undefined, //举报类型
            // lastProcessType: undefined, //最后处理方式
            // searchValue: undefined,  //检索条件
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        },
        processLists:[],
        dateString:'',
        entity: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        reason:'',  //资源冻结原因
        reasonNum: 0,  //资源冻结原因字数
        ids: [],
        offLists:[],  //资源举报列表
        businessId:'',  //业务id
        dataSource: [],
        startColumns: [
            {title: '', width:0,dataIndex: '', key: ''},
            {title: '评论', dataIndex: 'extra', key: 'extra',align: 'center',
                render:(v,i)=>{
                    return <span>{eval('(' + v + ')').comment?eval('(' + v + ')').comment:''}</span>
                }
            },
            {title: '话题标题', dataIndex: 'extra', key: 'extra',align: 'center',
                render:(v,i)=>{
                    return <span>{eval('(' + v + ')').topicTitle?eval('(' + v + ')').topicTitle:''}</span>
                }
            },
            {title: '所在圈子名称', dataIndex: 'extra', key: 'extra', align: 'center',
                render:(v,i)=>{
                    return <span>{eval('(' + v + ')').circleName?eval('(' + v + ')').circleName:''}</span>
                }
            },
            {title: '所在院校', dataIndex: 'extra', key: 'extra', align: 'center',
                render:(v,i)=>{
                    return <span>{eval('(' + v + ')').orgName?eval('(' + v + ')').orgName:''}</span>
                }
            },

            {title: '话题作者', dataIndex: 'businessAuthor', key: 'businessAuthor', align: 'center'},
            {title: '发布日期', dataIndex: 'createTime', key: 'createTime', align: 'center'},
            {title: '举报原因', width:250,dataIndex: 'tipOffType', key: 'tipOffType', align: 'center'},
            {title: '举报人', dataIndex: 'tipOffUserName', key: 'tipOffUserName', align: 'center'},
            {title: '举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber', align: 'center'},
            
            // {title: '11', dataIndex: '', key: ''},

            // {title: '评论', dataIndex: 'circleName', key: 'circleName'},
            // {title: '话题标题', dataIndex: 'circleId', key: 'circleId'},
            // {title: '所在圈子名称', dataIndex: 'orgName', key: 'orgName', align: 'center',},
            // {title: '所在院校', dataIndex: 'createDate', key: 'createDate   ', align: 'center'},

            // {title: '话题作者', dataIndex: 'createTime', key: 'createTime   ', align: 'center'},
            // {title: '发布日期', dataIndex: 'openRange', key: 'openRange   ', align: 'center'},
            // {title: '举报原因', dataIndex: 'memberNum', key: 'memberNum   ', align: 'center'},
            // {title: '举报人', dataIndex: 'browseNum', key: 'browseNum   ', align: 'center'},
            

        ],
        endColumns: [
            // {title: '举报类型', dataIndex: 'tipOffType', key: 'tipOffType', align: 'center',
            //     render:(text, record) => this.state.reportTypeMap[text]
            // },
            // {title: '最后处理时间', dataIndex: 'lastProcessTime', key: 'lastProcessTime', align: 'center',},
            {
                title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                width: 240,
                render: (text, item) => <div className='action vertical_j'>
                    <p>
                        {/* <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
                        {this.state.activeIndex === 1 ? <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span> : ""}
                        {
                            this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
                              <span onClick={() => this.reasonShow(record)}>冻结</span> : ''
                        } */}
                        <span     onClick={(e)=>this.handleInfo(item.businessId)}>查看</span>
                        {/* <span >封禁圈子</span>
                        <span >解散圈子</span> */}
                    </p>
                </div>
            }
        ],
        endColumns02: [
            
            {
                title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                width: 280,
                render: (text, item) => <div className='action vertical_j'>
                {console.log(item,"--------------111")}
                    <p>
                        <span onClick={() => this.handledeleteTopic(item.businessId,item.tipOffType)}>删除该评论</span>
                        <span onClick={() => this.handlejoinBlackList(item.tipOffUserId,item.orgId)}>将评论者拉入黑名单</span>
                        
                    </p>
                </div>
            }
        ],
        
        columns: [],
        reportTypeData:[],
        reportTypeMap: {},
        processModeData:[],
        processModeMap: {},

        _showCommentDeletedData:[]
    }
    componentDidMount() {
        // this.getTipOffType()
        // this.getLastProcessType()
        // this.initColumns()
        this.noDealList();
        this.hasDealList();

        this.initColumns()
        this.getList();
        // this.tupOff()
    }



    //待处理举报列表
    async noDealList() {
        const noDealListData = await noDealList({
            "businessType": this.state._businessType,
            "platform": this.state._platform
        });    
        let noDealListMap = {}
        console.log(noDealListData,"--------------");
        
        if (noDealListData && noDealListData.data.length) {   
            this.setState({
                _noDealListData: noDealListData.data,
                _noDealListNumber:noDealListData.total,
                
            });
        }
        
    }

    //已处理举报列表
    async hasDealList() {
        const hasDealListData = await hasDealList({
            "businessType": this.state._businessType,
            "platform": this.state._platform
        });    
        let hasDealListMap = {}
        console.log(hasDealListData,"--------------");
        
        if (hasDealListData && hasDealListData.data.length) {   
            this.setState({
                _hasDealListData: hasDealListData.data,
                _hasDealListNumber:hasDealListData.total,
                
            });
        }
        
    }

    //列表查询
    async getResourceList() {
        const {  _startTime, _endTime, activeIndex, pagination, } = this.state

        if (activeIndex === 0) {

            const result = await noDealList();  //待处理
            if (result) {
                this.setState({
                    dataSource: result.data,
                })
            }

        } else {

            const result = await hasDealList();
            if (result) {
                this.setState({
                    dataSource: result.data,
                })
            }

        }
    }
    //检索查询
    queryBtn = () => {
        this.getResourceList()
    }


    initColumns() {
        const {activeIndex, startColumns, endColumns,endColumns02} = this.state
        let column = []
        if(activeIndex === 0){
            // column = [{title: '资源分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center'},
            // {title: '新举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber1', align: 'center'}]
            this.setState({
                columns: startColumns.concat(column).concat(endColumns02)
            })
        }else{
            // column = [{
            //     title: '最后处理方式', dataIndex: 'lastProcessType', key: 'lastProcessType', align: 'center',
            //     render: (text, record) => this.state.processModeMap[text]
            // },
            // {title: '处理时总举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber2', align: 'center'}]
            this.setState({
                columns: startColumns.concat(column).concat(endColumns)
            })
        }

    }
    async getList(){
        const {activeIndex, pagination} = this.state

        if(activeIndex === 0){
            const result = await noDealList({
                "businessType": this.state._businessType,
                "platform": this.state._platform,
                "pageNum": pagination.pageNum,
                "pageSize": pagination.pageSize,
            });
            if (result) {
                
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,    //这里需要传页码数据否则点击不跳页
                        pageSize: pagination.pageSize,
                        //processEndTime: undefined,
                        //processStartTime: undefined,
                        total: result.total
                    }
                })
            }
        }else{
            const result = await hasDealList({
                "businessType": this.state._businessType,
                "platform": this.state._platform,
                "pageNum": pagination.pageNum,
                "pageSize": pagination.pageSize,
            });
            if (result) {
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,
                        pageSize: pagination.pageSize,
                        //processEndTime: undefined,
                        //processStartTime: undefined,
                        total: result.total
                    }
                })
            }
            //console.log(this.state.dataSource,'\\\\\\\\\\\\\\');
        }
    }
    
    //分页
    pageChange = (pageNumber) => {
        let {pagination} = this.state;
        pagination.pageNum = pageNumber;
        // this.setState({pagination: pagination}, () => this.getList())
        this.setState({pagination: pagination})
    }
    //选择行
    // selectedRowChange = (selectedRowKeys, selectedRows) => {
    //     let alreadyFreeze = selectedRows.filter(row => row.lastProcessType === "1")
    //     if(alreadyFreeze.length > 0){
    //         return message.warning('不要选择已经冻结的数据');
    //     }
    //     let expandedRowKeys = selectedRows.map(v => v.resourceId)
    //     this.setState({selectedRowKeys, expandedRowKeys})
    // }
    //搜索选项改变
    reportChange = ({target: {value}}) => {
        let {pagination} = this.state;
        pagination.searchValue = value
        this.setState({
            pagination,
            btnDisabled: false
        });
    };
    //已完成  待完成切换
    changeTabMenu = (key) => {
        debugger;
        this.setState({
            selectedRowKeys: [],
            expandedRowKeys: [],
            activeIndex: key
        }, () => {
            this.initColumns()
            //this.resetSearch()
            this.getList()
        })
    };
    resetSearch() {
        let pagination = {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tipOffType: undefined, //举报类型
            processState: 0, //处理状态
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processTime: undefined,  //时间
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        };
        this.setState({
            pagination: pagination,
            btnDisabled: true
        }, () => this.getList())
    }

    

    checkCancel = () => {
        this.setState({
            visible: false
        })
    }
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        }, () => this.getList())
    }
    //举报类型改变
    reportTypeChange = (value) => {
        let {pagination} = this.state;
        pagination.tipOffType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //处理方式改变
    reportModeChange = (value) => {
        let {pagination} = this.state;
        pagination.lastProcessType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //日期查询
    reportTimeChange = (value, dateString)=> {
        let {pagination} = this.state;
        pagination.processTime = value
        pagination.processStartTime = dateString[0]
        pagination.processEndTime = dateString[1]
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList())
    }

    //查看已删除评论内容
    handleInfo = (discussId) => {   //key,value相等时 缩写{id:id}
        //debugger;
        console.log(discussId);
        showCommentDeleted({discussId}).then((res) => {
            console.log(res);
            this.setState({
                _showCommentDeletedData:res,   //查看已删除评论内容
                visible: true,
            });
        });

        // this.setState({
        //     visible: true,
        // });
         
    };



    //deleteTopic删除该评论弹层
    handledeleteTopic = (discussId,reason) => {
        // console.log(this.state.userInfo)
        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否删除该评论?',
            centered: true,
            className: "ModalConfirm-pic",
            okType: 'danger',
            okText: '确定',
            onOk: () => {
                 
                 deleteComment({discussId,reason}).then(r=>{
                    // debugger;
                    if(r.code===0){
                        message.success('删除该评论成功')
                        this.changeTabMenu(1);
                    }else{
                        message.error("删除该评论失败")
                    }
                })
            }
        })
    };

    //gotoBlackList 将作者拉入黑名单弹层
    handlejoinBlackList = (userId,orgId) => {
        // console.log(this.state.userInfo)
        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否将作者拉入黑名单?',
            centered: true,
            className: "ModalConfirm-pic",
            okType: 'danger',
            okText: '确定',
            onOk: () => {
                 
				joinBlack({userId,orgId}).then(r=>{
                    // debugger;
					if(r.code===0){
                        message.success('拉入黑名单成功');
                        this.changeTabMenu(1);
					}else{
                        message.error("拉入黑名单失败")
					}
				})
			}
        })
    };


    render() {
        const {
            _noDealListData,
            _hasDealListData,
            _noDealListNumber,
            _hasDealListNumber,
            _businessType,
            _platform,
            columns,

            _dddcommentReportListData, 
            selectedRowKeys, 
            activeIndex, 
            dataSource, 
            visible,
            reportTypeData,
            processModeData,

            pagination, 
            reason, 
            reasonNum,

            _showCommentDeletedData,
        } = this.state;
        const formItemLayout = {
            labelCol: {span: 0},
            wrapperCol: {span: 24},
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle check_box report'>
                 
                <div className="p20 pright40 bg_fff m20">
                    <div className="top_01 mbottom20">
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>圈子名称</span>
                            <Input style={{ width: '80%' }} placeholder='请输入圈子名称' />
                        </Col>

                        <Col span={8}>
                            <span style={{ marginRight: '20px' }}>举报人名称</span>
                            <Input style={{ width: '70%' }} placeholder='请输入举报人名称' />
                        </Col>

                        <Col span={8}>
                            <span style={{ marginRight: '20px' }}>评论者名称</span>
                            <Input style={{ width: '80%' }} placeholder='请输入话题作者名称' />
                        </Col>

                        

                    </div>

                    <div className="top_02  mbottom20">
                    <Col span={7}>
                            <span style={{ marginRight: '20px' }}>举报类型</span>
                            <Select placeholder='请选择所属机构' style={{ width: '80%' }} onChange={e => {
                                this.setState({ organizationId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                {
                                    // enterprisList.map(v => {
                                    //     return <Option value={v.id} key={v.id}>{v.enterpriseName}</Option>
                                    // })
                                }
                            </Select>
                        </Col>

                        <Col span={8}>

                            <span style={{ marginRight: '35px' }}>所属机构</span>
                            <Select placeholder='请选择身份类型' style={{ width: '70%' }} onChange={e => {
                                this.setState({ identityId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                {
                                    // statusList.map(v => {
                                    //     return <Option value={v.code} key={v.code}>{v.name}</Option>
                                    // })
                                }
                            </Select>
                        </Col>
                        <Col span={8}>
                            <span style={{ marginRight: '35px' }}>评论时间</span>
                            <RangePicker onChange={this.reportTimeChange} value={pagination.processTime}
                                style={{ width: '80%', }} />
                        </Col>

                        

                    </div>
                    <div className="mright20" style={{textAlign: 'right',paddingRight: '20px'}}>
                        <Button className='reset_btn' onClick={() => this.resetSearch()} disabled={this.state.btnDisabled}>重置</Button>
                        <Button type='primary' className='ant_blue' style={{ marginLeft: '12px' }} onClick={() => this.queryBtn()}>查询</Button>
                    </div>
                </div>
                <div className="wrapper padd">
                    <div className="top m20">
                        <Col className='top_l' span={12}>
                            <div className='tabs'>
                                <Tag onClick={() => this.changeTabMenu(0)}
                                     className={kindName({'active': activeIndex === 0})}>待处理 </Tag>
                                <Tag onClick={() => this.changeTabMenu(1)}
                                     className={kindName({'active': activeIndex === 1})}>已处理 </Tag>
                            </div>
                        </Col>
                        {/* <Col className='top_r' span={12}>
                            <Input placeholder='输入资源名称/上传人/举报人' value={pagination.searchValue} onChange={this.reportChange} onPressEnter={() => this.getList()} />
                            <Button className='reset_btn'>重置</Button>
                            <Button type='primary' className='search'>查询</Button>
                        </Col> */}
                    </div>
                    <div className="check_container" style={{position: 'relative'}}>
                        
                            <MyTable
                                options={
                                    {

                                        dataSource:this.state.dataSource,
                                        columns,
                                        // rowSelection: {
                                        //     selectedRowKeys,
                                        //     onChange: this.selectedRowChange,
                                        // },
                                        pagination: {
                                            onChange: this.pageChange, 
                                            current: pagination.pageNum, 
                                            pageSize: pagination.pageSize,
                                            total: pagination.total
                                        }
                                    }
                                }
                                //isDrab={false}
                                fixededit={true}
                                scroll={{ x: 1800,}}
                            />
                        
                        {/* <div className="Grounding_position">
                            {activeIndex==0?
                            <label>
                                <Checkbox
                                    checked={selectedRowKeys.length === dataSource.length ? true : false}
                                    onChange={this.checkedAll}
                                />
                                 <span className="Grounding_select">全选</span>
                            </label>
                            :""}
                            <Button className='ant_blue' onClick={() => this.reasonBatchShow()} type='primary'>批量冻结</Button>
                        </div> */}
                    </div>
                    <Modal
                        title="该评论已被删除"
                        visible={visible}
                        closable={false}
                        className="look-modal-01"
                        footer={
                            <div className='modal_btn_j'>
                                <Button onClick={() => this.checkCancel()}>关闭</Button>
                                {/* <Button onClick={() => this.lookCancel()} type='primary'>保存</Button> */}
                            </div>
                        }
                    >
                        <div className="sold-box">
                             
                           
                           {_showCommentDeletedData ?(
                           <div className="modal_comment">
                               <p className="modal_title_p"><span className=" modal_title">原因： </span><span className="warnReason">{_showCommentDeletedData.delReason}</span>    </p>
                               <p className="modal_title_p"><span className=" modal_title">评论者：</span>
                               {_showCommentDeletedData.authorAvatar?(
                                   <img width='40px' height='40px' style={{borderRadius:'50%',marginRight:'10px'}} src={_showCommentDeletedData.authorAvatar} alt=''/>
                               ):""}{_showCommentDeletedData.author}</p>
                               <p className="modal_title_p"><span className=" modal_title">评论内容：</span><span className="modal_content">{_showCommentDeletedData.content}</span></p>
                               <p className="modal_title_p"><span className=" modal_title">评论时间：</span><span className="modal_content">{_showCommentDeletedData.commentTime}</span></p>
                               <p className="modal_title_p"><span className=" modal_title">删除时间：</span><span className="modal_content">{_showCommentDeletedData.deleteTime}</span></p>
                           </div>
                           ):
                           <div className="modal_comment">
                           <p className="modal_title_p"> <span  >暂无数据</span></p>
                           </div>
                           }
                           
                           
                            
                        </div>
                    </Modal>
                </div>
 
            </div>
        )
    }
}


/*Check = connect(mapStateToProps, mapDispatchToProps)(Check);
export default Check;*/
CommentReport = connect(mapStateToProps, mapDispatchToProps)(CommentReport);
CommentReport = Form.create()(CommentReport)
export default CommentReport;
