import {message } from 'antd'
export default {
    MODULE_VISIBLE(state, action) {
        state.mvisible = false
        if (action.data === 'ok') {
        } else if (action.data === 'cancel') {
        } else if (action.data === 'build_module') {
            state.mvisible = true
        }
    },
    SET_TABLE_TITLE_MODULE(state) {
        state.table_title_showModal_visible = true
    },
    SET_ZHANKAI_SHOUQI(state, action) {
        const { _this, e } = action.data;
        console.log(e.target.parentNode.className )
        if (e.target.parentNode.className.indexOf('shouqi') < 0) {
            _this.input_wrap1.classList.remove('none')
            _this.input_wrap2.classList.remove('none')
            e.target.parentNode.classList.add('zhankai')
            e.target.parentNode.classList.remove('shouqi')
            e.target.innerHTML = '收起'
        } else {
            debugger
            _this.input_wrap1.classList.add('none')
            _this.input_wrap2.classList.add('none')
            e.target.parentNode.classList.remove('zhankai')
            e.target.parentNode.classList.add('shouqi')
            e.target.innerHTML = '展开'
        }
    },
    CHECKALL(state) {
        if (state.selectedRowKeys.length === state.data.length) {
            state.selectedRowKeys = []
            return;
        }
        state.selectedRowKeys = state.data.map(v => v.key)
    },
    BATCHSURE(state){
        if (!state.selectedRowKeys.length) {
            message.warning('请选择');
            return;
        }
        console.log(state.selectedRowKeys)
    },
    CONFIM_AND_CANCEL(state,action){
        console.log(1)
    },
    GETONSHEL(state,action){
        state.data = action.data.data || []
    }
}
