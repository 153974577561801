 import React from 'react';
import { Row,Col} from 'antd';
import {getHandledRecords} from '@/api/lives/live'

class Processing extends React.Component {
    state = {
        handledRecord:[]
    };

    componentWillMount() {
        // 查询已处理记录
        this.getHandledRecords()
    }

    getHandledRecords = () =>{
        getHandledRecords({taskId:this.props.taskId}).then(e=>{
            console.log("查询已处理记录",e);
            this.setState({handledRecord:e})
        })
    }

    render() {
        const { handledRecord} = this.state;
        return (
            <div className="records-content">
                <Row>
                    {
                        handledRecord.length?handledRecord.map((item,index)=>{
                            console.log(handledRecord)
                            return (
                                <Col span={24} className="col-li">
                                    <li>
                                        <span>结果：</span>
                                        <p>
                                            {item.processType === 0 ? "没有问题" : item.processType === 1 ? "禁止开播" :
                                             item.processType === 2 ? "暂停直播" : item.processType === 3 ? "恢复直播" :
                                             item.processType === 4 ? "强制结束" : item.processType === 5 ? "冻结" : ""}
                                        </p>
                                    </li>
                                    <li>
                                        <span>时间：</span>
                                        <p>
                                           {item.createTime}
                                        </p>
                                    </li>
                                    <li>
                                        <span>审核人：</span>
                                        <p>
                                            {item.adminName}
                                        </p>
                                    </li>
                                    <li>
                                        <span>原因：</span>
                                        <p>
                                            {item.processDesc}
                                        </p>
                                    </li>
                                </Col>
                            )
                        }):(
                            <div className="Empty-conet">
                                <div className="img">
                                    <img src={window.$$uploadRc("base/ae/image/080aa2d6d97a46569617f98725ad56d8.jpg")}/>
                                </div>
                            </div>
                        )
                    }
                </Row>
            </div>
        );
    }
}

export default Processing;
