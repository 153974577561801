// 新增专题
import React, { Component } from 'react'
import './index.scss';
import {Form, Select, Button, Row, Col, Radio, Input, Upload, Modal} from 'antd';
import moment from 'moment'
import OSS from 'ali-oss';
import { getOssToken } from '@/api/ali/index.js'
import {getPlatformList, saveOrUpdateNewsSpecial, getSpecialInfo} from "../../../../api/zixun/index"
import { getSearch } from '@/until/libs';
import UploadImg from '../../../../components/upload_img/index.js';
import RenderButton from "../../../../components/renderButton";
import { getBaseCover } from '../../../../api/ali/index'
import { PicModal } from 'gongyongmodal';
import TopTitle from "../../../../components/Breadzx";
const { Option } = Select;
const topTitleData = {
    span_1: "资讯中心",
    span_2: " 推送管理",
    span_3: "新增推送 ",
    span_1_c: "#333",
    span_2_c: "#333",
    container: "新增推送",
};

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
class AddThematic extends Component {
    state = {
        columnNames:'', // 栏目名称
        isSelectImg: false, // 弹层
        discussFileQueryVo: [],
        client: '',
        imageUrl: "",
        imageUrlID: "",
        imgList: [],
        imgValue: undefined, // 课程分类
        platformList :[],
        fileList: [],
        previewVisible: false,
        previewImage: '',
        radioValue : 1,
        platform : '',
        // userId : JSON.parse(localStorage.getItem("userInfo")).id,
        // userId :Cookies.get("info") ? JSON.parse(decodeURI(Cookies.get("info"))).userId : "",
        userId :"",
        specialId : '',
        specialInfo : {},
        fileId:'',   // 图片id
        mediaType : '',
        jump: false,
        visible: false,
        // defaultPlatform:''
    }
    async componentDidMount() {
        topTitleData.span_3 = '新增专题'
        topTitleData.container = '新增专题'
        let params = getSearch(this.props.location.search);
        if(params && params.specialId){
            topTitleData.span_3 = '编辑专题'
            topTitleData.container = '编辑专题'
            this.setState({
                specialId: params.specialId
            }, () => {
                this.specialInfo();
            })
        }else{
            this.getPlatformList();
        }
        if(params && params.specialInfo){
            console.log("params.specialInfo", params.specialInfo)
            this.setState({
                specialInfo : params.specialInfo
            }, () =>{
                this.props.form.setFieldsValue({
                    specialImg: params.specialInfo.specialImg,
                    specialName: params.specialInfo.specialName,
                    platform: params.specialInfo.platform,
                    // mediaType: params.specialInfo.mediaType,
                    specialDesc: params.specialInfo.specialDesc,
                })
                // let file = {
                //     uid: '-1',
                //     name: 'image.png',
                //     status: 'done',
                //     url: window.$$img(params.specialInfo.specialImg),
                // }
                // this.state.fileList.push(file)
                this.setState({
                    specialId : params.specialInfo.specialId,
                    platform: params.specialInfo.platform,
                    mediaType: params.specialInfo.mediaType,
                    // imageUrlID : params.specialInfo.specialImg,
                })
            })
        }

        let $$imgSrcLcl = window.$$imgSrcLcl
        //  公共图库
         await getBaseCover().then(res =>{
            res.forEach(element => {
                element.categoryBackgroundId = `${$$imgSrcLcl}${element.categoryBackgroundId}`
                element.coverInfos.forEach(v => {
                    v.fpNameurl = `${$$imgSrcLcl}${v.fpName}`
                })
            });
            this.setState(() => {
                return {
                    imgList: res
                }
            })
        })
    }

    specialInfo = () =>{
        let data = {
            id : this.state.specialId
        }
        getSpecialInfo(data).then(res =>{
            if(res){
                this.setState({
                    specialInfo : res
                })
                this.props.form.setFieldsValue({
                    specialImg: res.specialImg,
                    mediaType: res.mediaType,
                    specialName: res.specialName,
                    platform: res.platform,
                    specialDesc: res.specialDesc,
                })
                // let file = {
                //     uid: '-1',
                //     name: 'image.png',
                //     status: 'done',
                //     url: window.$$img(res.specialImg),
                // }
                // this.state.fileList.push(file)
                this.setState({
                    platform: res.platform,
                    mediaType: res.mediaType,
                    // imageUrlID : res.specialImg,
                },()=>{
                    this.getPlatformList();
                })
                // console.log("fileList", this.state.fileList)
            }
        })
    }
    //加载业务系统列表
     getPlatformList = async()=>{
        let platformData = {
            queryType : 1,
            organizationId :""
        }
        await getPlatformList(platformData).then(res =>{
            if(res&& res.length){
                var data = res.map((item,index)=>{
                    return{
                        platform: item.platform,
                        platformName: item.platformName,
                        disabled:false,
                    }
                })
                if(this.state.platform){
                    data.forEach((i)=>{
                        if(i.platform!==this.state.platform){
                            i.disabled = true
                        }
                    })
                }
                this.setState({
                    platformList : data,
                    //defaultPlatform: res[0].platform
                })
            }
        })
    }
    changePlatform = ({ target: { value } }) =>{
        console.log("value::", value)
        this.setState({
            platform : value,
        })
    }

    handleImgChange= (fileId, mediaType) =>{
        console.log("handleImgChange-fileId",fileId)
        console.log("handleImgChange-mediaType",mediaType)
        this.props.form.setFieldsValue({"specialImg":fileId})
        this.setState({
            fileId,
            mediaType
        })
    }

    // 公共图库确定按钮
    OK = (v) => {
        console.log(v, 'OK')
        this.props.form.setFieldsValue({"specialImg":v.fpName})
        this.setState(() => {
            return {
                isSelectImg: false,
                fileId: v.fpName, // 图片值
                mediaType: v.imgType // 图片类型
            }
        })
    }
    // 公共图库取消按钮
    cancel = (v) => {
        console.log(v, 'cancel')
        this.setState(() => {
            return {
                isSelectImg: false
            }
        })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        console.log("file", file)
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    openGalleryImg = () =>{
        this.setState({
            isSelectImg : true
        })
    }

    // async getOssToken() {
    //     let res = await getOssToken();
    //     // console.log("ossToken",res)
    //     if (res) {
    //         let client = new OSS({
    //             region: "oss-cn-beijing",
    //             accessKeyId: res.accessKeyId,
    //             accessKeySecret: res.keySecret,
    //             stsToken: res.token,
    //             bucket: res.bucket,
    //             secure: true
    //         });
    //         this.setState({ client })
    //     }
    // }
    //上传图片
    // changeLoad = async info => {
    //     try {
    //         let ymd = moment(new Date()).format('YYYY-MM-DD');
    //         ymd = ymd.replace(/-/g, '');
    //         let name = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
    //         let result = await this.state.client.put(name, info);
    //         console.log("result",result)
    //         if (result) {
    //             let { url, name } = result;
    //             name = name.substr(name.indexOf('/'))
    //             this.setState({
    //                 imageUrl: url,
    //                 imageUrlID: name,
    //                 loading: false,
    //             });
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };
    // handleChange = ({ fileList }) => {
    //     console.log("fileList",fileList)
    //     if(fileList.length === 0){
    //         this.setState({
    //             imageUrl: '',
    //             imageUrlID: '',
    //             imgValue : undefined,
    //         })
    //     }
    //     this.setState({ fileList })
    // }
    // onChangeGalleryImg = e => {
    //     this.setState({
    //         imgValue: e.target.value
    //     });
    // };
    // chooseGalleryImg = () =>{
    //     let fileList = this.state.fileList
    //     fileList = []
    //     this.setState({
    //         fileList
    //     })
    //     let imgUrl = this.state.imgValue
    //     let file = {
    //         uid: '-1',
    //         name: 'image.png',
    //         status: 'done',
    //         url: window.$$img(imgUrl),
    //     }
    //     fileList.push(file)
    //     this.setState({
    //         imageUrlID : imgUrl,
    //         fileList,
    //         isSelectImg : false
    //     })
    //     console.log("chooseGalleryImg", this.state.fileList)
    // }

    saveNewsSpecial = () =>{
        console.log("mediaType", this.state.mediaType)
        this.props.form.setFieldsValue({
            // specialImg: this.state.imageUrlID,
            platform: this.state.platform
        })
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("values",values)
                let data = {
                    specialId : this.state.specialId,
                    platform : values.platform,
                    specialName : values.specialName,
                    specialImg : values.specialImg,
                    mediaType : this.state.mediaType,
                    specialDesc : values.specialDesc,
                    specialTemplate : values.specialTemplate,
                    createUserId : this.state.userId,
                }
                saveOrUpdateNewsSpecial(data).then(res =>{
                    if(res){
                        if(this.state.specialId !== ''){
                            this.mesSuccess("编辑成功")
                        }else {
                            this.mesSuccess("添加成功")
                        }
                        this.setState({
                            specialId : res
                        },() =>{
                            this.cancelAdd()
                        })

                    }
                })
            }
        })
    }

    handPreview = () =>{
        console.log("mediaType", this.state.mediaType)
        this.props.form.setFieldsValue({
            // specialImg: this.state.imageUrlID,
            mediaType : this.state.mediaType,
            platform: this.state.platform
        })
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("values",values)
                let data = {
                    specialId : this.state.specialId,
                    platform : values.platform,
                    specialName : values.specialName,
                    specialImg : values.specialImg,
                    mediaType : this.state.mediaType,
                    specialDesc : values.specialDesc,
                }
                this.props.history.push(`/main/group-column/specialTopicPage?${decodeURI(JSON.stringify({ specialInfo: data}))}`)
            }
        })
        // this.props.history.push(`/main/group-column/specialTopic?${decodeURI(JSON.stringify({ specialId: record.specialId }))}`)
        // this.props.history.push(`/main/group-column/specialTopicPage`)
    }

    cancelAdd = () =>{
        if(this.state.specialId !== ''){
            this.props.history.push(`/main/group-column/thematicConsole?${decodeURI(JSON.stringify({ specialId: this.state.specialId }))}`);
        }else {
            this.props.history.push(`/main/group-column/thematicConfiguration`);
        }
    }

    // 公共图库确定按钮
    OK = (v) => {
        console.log(v, 'OK')
        this.props.form.setFieldsValue({"specialImg":v.fpName})
        this.setState(() => {
            return {
                isSelectImg: false,
                fileId: v.fpName, // 图片值
                mediaType: v.imgType // 图片类型
            }
        })
    }
    // 公共图库取消按钮
    cancel = (v) => {
        console.log(v, 'cancel')
        this.setState(() => {
            return {
                isSelectImg: false
            }
        })
    }

    render() {
        const {$$img} = window
        const {$$imgSrcLcl} = window.$$imgSrc
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let {isSelectImg,imgList,fileList} = this.state;
        const addDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/add_icon.png')} alt="" />
            </div>
            <span>上传封面</span>
        </div>
        const lodingDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/loading_icon.png')} alt="" />
            </div>
            <span>请稍后...</span>
        </div>
        return (
            <div style={{height: 'calc(100% - 100px)'}}>
                <TopTitle {...topTitleData} />
            <div className='add_thematic_box'>
                {/*<div className="title">*/}
                {/*    新增专题*/}
                {/*</div>*/}
                <Form className='lineHeightt'>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>归属业务系统</span></div>
                            <div className='row_div_right platformBox' >
                                {
                                    getFieldDecorator('platform', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '业务系统不能为空'
                                            }
                                        ]
                                    })(
                                        <Radio.Group onChange={this.changePlatform}  buttonStyle="solid" className='platform_radio' >
                                            { this.state.platformList.length ? this.state.platformList.map((item, index) => {
                                                return <Radio.Button key={index} value={item.platform} disabled={item.disabled}>{item.platformName}</Radio.Button>
                                            }):''}
                                        </Radio.Group>
                                        //<Select placeholder='请选择业务系统' onChange={e => this.changePlatform(e)}>
                                            //{
                                                //this.state.platformList.map(p => <Option key={p.platform} value={p.platform}>{p.platformName}</Option>)
                                            //}
                                       // </Select>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>专题名称</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('specialName', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '专题名称不能为空'
                                            }
                                        ],
                                        getValueFromEvent: (event) => {
                                            return event.target.value.replace(/\s+/g,"")
                                        }
                                    })(
                                        <Input placeholder='请输入专题名称' maxLength={100} className={'articleSourceinput'} suffix={<span>
                                                {this.props.form.getFieldValue(`specialName`) ? this.props.form.getFieldValue(`specialName`).length : 0}/100</span>}/>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="row_div">
                            <div className="row_div_left"><span><span className='red'>*</span>专题封面</span></div>
                            <div className="special-cover">
                                {
                                    getFieldDecorator('specialImg', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '专题封面不能为空'
                                            }
                                        ]
                                    })
                                    (

                                        <UploadImg
                                            handleImgChange={this.handleImgChange}
                                            html='上传封面'/>
                                    )
                                    // (
                                    //     <>
                                    //         { <Upload
                                    //             accept='.jpg,.png,.jpeg'
                                    //             listType="picture-card"
                                    //             /*  showUploadList={false}*/
                                    //             beforeUpload={(file) => this.changeLoad(file)}
                                    //             onPreview={this.handlePreview}
                                    //             onChange={this.handleChange}
                                    //             fileList={fileList}
                                    //         >
                                    //             {/*{imageUrl ? null : addDiv }*/}
                                    //             {this.state.fileList.length >= 1 ? null : addDiv}
                                    //         </Upload>}
                                    //         <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                                    //             <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                    //         </Modal>
                                    //     </>
                                    // )
                                }
                            </div>
                            <Col span={5}>
                                <div className='tishi'>
                                    <p>支持jpg/png/jpeg格式，</p>
                                    <p>建议最佳尺寸160x88px，不超过5M</p>
                                </div>
                                <div>
                                    <p className='select_img' onClick={this.openGalleryImg}>从图库里选择封面</p>
                                </div>
                            </Col>
                        </div>
                    </Form.Item>
                    <div >
                        <PicModal
                            visible={isSelectImg}
                            ImgList={this.state.imgList}
                            onCancel={(item) => {
                                this.cancel(item)
                            }}
                            onOK={(item) => {
                                this.OK(item)
                            }}/>
                    </div>
                    {/*<Modal*/}
                    {/*    width="750px"*/}
                    {/*    visible={isSelectImg}*/}
                    {/*    title="请选择封面"*/}
                    {/*    className="Modal-butmeinList"*/}
                    {/*    maskClosable={false}*/}
                    {/*    keyboard={false}*/}
                    {/*    closable={false}*/}
                    {/*    footer={*/}
                    {/*        <div className="modal_footer">*/}
                    {/*            <Button*/}
                    {/*                onClick={() => {*/}
                    {/*                    this.setState({*/}
                    {/*                        isSelectImg: false,*/}
                    {/*                    });*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                取消*/}
                    {/*            </Button>*/}
                    {/*            <Button type="primary" onClick={this.chooseGalleryImg}>确定</Button>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Radio.Group onChange={this.onChangeGalleryImg} value={this.state.imgValue}>*/}
                    {/*    </Radio.Group>*/}
                    {/*</Modal>*/}
                    {/*<Form.Item>*/}
                    {/*    <Row>*/}
                    {/*        <Col span={2}><span><span className='red'>*</span>专题介绍</span></Col>*/}
                    {/*        <Col span={10}>*/}
                    <Form.Item className='lineHeight'>
                        <div className="row_div">
                            <div className="row_div_left"><span><span className='red'>*</span>专题介绍</span></div>
                            <div className="row_div_right">
                                {
                                    getFieldDecorator('specialDesc', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '专题介绍不能为空'
                                            }
                                        ],
                                        getValueFromEvent: (event) => {
                                            return event.target.value.replace(/\s+/g,"")
                                        }
                                    })(
                                        <Input placeholder='请输入专题介绍' maxLength={150}  className={'articleSourceinput'} suffix={<span>
                                                {this.props.form.getFieldValue(`specialDesc`) ? this.props.form.getFieldValue(`specialDesc`).length : 0}/150</span>}/>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="row_div">
                            <div className="row_div_left"><span><span className='red'>*</span>专题模板</span></div>
                            <div className="row_div_right">
                                {
                                    getFieldDecorator('specialTemplate', {
                                        initialValue: "/cvec-manage-front-yunying/zixun/ztmb_1.png",
                                        rules: [
                                            {
                                                required: true,
                                                message: '专题模板不能为空'
                                            }
                                        ]
                                    })(
                                        <Radio.Group style={{ width: '100%' }}>
                                            <div className='mb_box'>
                                                <div className='radio-box'>
                                                    <img  src={window.$$img("/cvec-manage-front-yunying/zixun/ztmb_1.png")}/>
                                                    <Radio value={"/cvec-manage-front-yunying/zixun/ztmb_1.png"}  >专题模板</Radio>
                                                </div>
                                                {/*<div className='radio-box'>*/}
                                                {/*    <img  src={require('@/assets/img/zixun/Rectangle 1096.png')}/>*/}
                                                {/*    <Radio value={2}>专题模板2</Radio>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Radio.Group>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>


                </Form>
                <div className="fix_controll">
                    {/*<Button className='add_btn_j' style={{ marginRight: '40px', width: '100px', height: '39px' }} onClick={() =>this.handPreview()}>预览</Button>*/}
                    {/*<Button className='dr_btn_j' style={{ width: '90px', height: '39px', marginRight: '40px' }} onClick={() =>this.saveNewsSpecial()}>保存</Button>*/}
                    {/*<Button onClick={this.cancelAdd} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>*/}
                    <RenderButton id='NC_01_B09_c_btnpreview'>
                        <Button className='add_btn_j' style={{ marginRight: '40px', width: '100px', height: '39px' }} onClick={() =>this.handPreview()}>预览</Button>
                    </RenderButton>
                    <RenderButton id='NC_01_B09_c_btnsave'>
                        <Button className='dr_btn_j' style={{ width: '90px', height: '39px', marginRight: '40px' }} onClick={() =>this.saveNewsSpecial()}>保存</Button>
                    </RenderButton>
                    {/*<RenderButton id='NC_01_B09_c_btncancel'>*/}
                        <Button onClick={this.cancelAdd} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                    {/*</RenderButton>*/}
                </div>
            </div>
            </div>
        )
    }
}


AddThematic = Form.create()(AddThematic);
export default AddThematic;
