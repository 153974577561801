import React, { useState, useEffect } from 'react';
import { Table, Modal, Tooltip, Collapse } from "antd";
import { EditNoActive, YuanAdd, Yichang } from "../../../components/shixunsvg1.20/index";
import { message } from '../../../components/global_components/confirm'

import { resourcechapterremove, } from "../../../api/shixun2/index";
import Xiugai11 from './xiugai11';
const { Panel } = Collapse;
const EditdirModule = (props) => {
  const [___dataSourceTable, set___dataSourceTable] = useState([]);
  const [___dataSourceTableloading, set_____dataSourceTableloading] = useState(true);
  const [xiugai11visible, set_xiugai11visible] = useState(false);
  const [cur_xiugai_ITEM, set_cur_xiugai_ITEM] = useState(null);
  const [selectone, setselectone] = useState(0);//YI
  const [selecttwo, setselecttwo] = useState(0);//YI
  const [selectthree, setselectthree] = useState(0);//YI
  const customPanelStyle = { background: "#fff", border: 0, overflow: "hidden" }


  useEffect(() => {
    getLeft_getTableData({})
    console.log(1)
  }, [props.edit__leftLOOKdirList.join().length]);
  const xiugai11 = (v) => {
    set_xiugai11visible(true);
    set_cur_xiugai_ITEM({ ...v })

  }

  const deleteSiginDir = (v) => {
    const { get_edit__leftLOOKdirList } = props
    Modal.confirm({
      title: "删除提示",
      icon: null,
      content: (
        <div style={{ display: "flex" }}>
          <Yichang />
          &emsp;确定删除吗？
        </div>
      ),
      okText: "确认",
      cancelText: "取消",
      className: "deleteconfim",
      onCancel: () => { },
      onOk: () => {
        resourcechapterremove({
          resourceId: v.resourceId,
          resourceChapterId: v.resourceChapterId
        }).then((res) => {
          if (res?.code === 0) {
            message({
              type: "success", content: '删除成功'
            })


          } else {
            message({
              type: "error", content: '删除失败'
            })
          }
          getLeft_getTableData(v)
          set___dataSourceTable()
          // www
        });
      },
    });
  }
  const CurriculumclcolumnsTable = [
    {
      align: "center", title: "目录名称",dataIndex:'resourceChapterName',key:'resourceTypeDesc'
    

    },
    {
      align: "center", title: "目录代码", dataIndex: "resourceChapterCode", key: "resourceChapterCode",
      render: (a, b, c) => <div >

        <Tooltip title={b.resourceChapterCode} placement="bottom" overlayClassName="TableTooltip">
          <span>{b.resourceChapterCode && b.resourceChapterCode.length > 7 ? b.resourceChapterCode.substring(0, 7) + '...' : b.resourceChapterCode}</span>

        </Tooltip>
      </div>
    },
    {
      align: "center", title: "资源类型",dataIndex:'resourceTypeDesc',key:'resourceTypeDesc'
    

    },
  
    {
      align: "center",
      title: "操作",
      dataIndex: "addredsdsdsdsdsdsss",
      key: "addreewdddddddss",
      render: (a, b) => {

        return (
          <div className="action vertical_j" style={{ cursor: "pointer" }}>
            <span onClick={() => xiugai11(b)}>修改</span>
            <span onClick={() => deleteSiginDir(b)}>删除</span>
          </div>
        );
      },
    },
  ];
  const editDironCancel = () => {
    props.prop_toog_yesDIR()
  }
  const editDironOk = () => {
    props.prop_toog_yesDIR()
  }
  const depCloneTree = (tree) => {

    let arrs = [];
    let result = [];
    arrs = arrs.concat(JSON.parse(JSON.stringify(tree)));
    while (arrs.length) {
      let first = arrs.shift();
      if (first.children) {

        arrs = arrs.concat(first.children);
        delete first["children"];
      }
      result.push(first);
    }
    return result;

  }

  const setRightData = (v) => {
    const arr = depCloneTree(v)
    set_____dataSourceTableloading(false)
    set___dataSourceTable(arr)
  }

  const getLeft_getTableData = (action_item) => {

    props.get_edit__leftLOOKdirList().then(res => {
      //如果当前是编辑则表格显示当前编辑项，否则显示res[0]
      const index = res.findIndex(k => {
        return k.resourceChapterId === action_item.resourceChapterId
      })
      if (res?.length > 0) {
        const n = index !== -1 ? index : 0
        setRightData(res[n])
      }
    })
  }
  return (
    <>
      <Modal
        title={
          <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
            {props.isAdd ? '新建' : '编辑'}章节目录
          </div>
        }
        visible={props.visible}
        onCancel={editDironCancel}
        onOk={editDironOk}
        width={750}
        centered
        closable={false}
        wrapClassName="Curriculumclas"
        destroyOnClose
      >
        <div className="Curriculumclassifyid">
          <div className="Curriculumclassifyid_ll_left">
            <div className="Curriculumclassifyid_ll_left_first">
              <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
                {Array.isArray(props.edit__leftLOOKdirList) && props.edit__leftLOOKdirList.map((v, u) => (
                  <Panel
                    key={v.resourceChapterId}
                    style={customPanelStyle}
                    header={
                      <div
                        onClick={() => {
                          setRightData(v)

                          setselectone(u)
                        }}

                        style={{ display: "flex", alignItems: "center", padding: 6, background: u === selectone ? '#F6F7FB' : '', color: u === selectone ? '#3E78ED' : '' }}

                      >

                        <EditNoActive />
                        &emsp;<div>
                          {v.resourceChapterName && v.resourceChapterName.length > 7 ? v.resourceChapterName.substring(0, 7) + '...' : v.resourceChapterName}
                        </div>
                      </div>
                    }

                  >
                    {Array.isArray(v.children) && v.children.map((children, i) => {
                      return <Collapse expandIconPosition="right" key={children.resourceChapterId + i}>
                        <Panel style={customPanelStyle} header={
                          <div onClick={() => {
                            setRightData(children)
                            setselecttwo(i)
                          }}
                            style={{ display: "flex", alignItems: "center", padding: 4, background: i === selecttwo ? '#F6F7FB' : '', color: i === selecttwo ? '#3E78ED' : '' }}
                          >

                            {children.resourceChapterName && children.resourceChapterName.length > 6 ? children.resourceChapterName.substring(0, 6) + '...' : children.resourceChapterName}
                          </div>} key="1">

                          <div
                            // style={{ display: "flex", alignItems: "center", padding: 6, background: i === selecttwo ? '#F6F7FB' : '', color: i === selecttwo ? '#3E78ED' : '' }}
                            className={`ove item_overtext item_overtextActive
                      `}
                          >
                            {Array.isArray(children.children) && children.children.map((__chi, ip) => {
                              return <Tooltip placement="bottomRight" title={__chi.resourceChapterName}
                                key={__chi.resourceChapterId + __chi.resourceChapterName}
                              >

                                <div style={{
                                  lineHeight: '23px',
                                  cursor: 'pointer', color: ip === selectthree ? '#3E78ED' : ''
                                }} onClick={() => {
                                  setRightData(__chi)
                                  setselectthree(ip)

                                }}
                                >{__chi.resourceChapterName}</div>   </Tooltip>
                            })}

                          </div>
                        </Panel>
                      </Collapse>
                    })}


                  </Panel>
                ))}
              </Collapse>
            </div>
            <div
              className="Curriculumclassifyid_ll_left_sec"
              style={{
                height: 63,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: 20,
                cursor: "pointer",
              }}
              onClick={props.toog_addDIR_itemfn}
            >
              <YuanAdd />
              &emsp;
              <span style={{ color: "#3E78ED", fontSize: "16px" }}>
               目录名称
              </span>
            </div>
          </div>
          <div className="Curriculumclassifyid_ll_right">
            <Table
              rowKey='resourceChapterId'
              dataSource={___dataSourceTable}
              columns={CurriculumclcolumnsTable}
              pagination={false}
              // loading={___dataSourceTableloading}
              scroll={{ y: 360 }}
            />
          </div>
        </div>
      </Modal>


      <Xiugai11
        key={xiugai11visible}
        visible={xiugai11visible}
        resourceId={props.resourceId}
        cur_xiugai_ITEM={cur_xiugai_ITEM || {}}
        set_xiugai11visible={set_xiugai11visible}
        get_edit__leftLOOKdirList={getLeft_getTableData}
      ></Xiugai11>
    </>

  );
}

export default EditdirModule;
