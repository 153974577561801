/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,Component,useEffect} from 'react';
import { Table, Pagination, Tag } from 'antd';
import {
    stopNumIcon,triallNumIcon,formalNumIcon,allNumIcon
} from '../../icon_type';
import { 
    statisticApplication, queryConfigApplicationTable, appAccessManageDelay,
    editApplicationUrl, formalApplication, stopApplications, configAgainApplication
} from '../../api/manage_mechanism_manage';
import RecordModal from './components/record_modal';
import DelayModal from './components/delay_modal';
import ConfigModal from './components/config_modal';
import FormalModal from './components/formal_modal';
import OpenModal from './components/open_modal';
import styles from './index.module.scss';
import Moment from 'moment';
import '../confirm';

const that = new Component();

const AppAccessInfo = props => {
    const {rightObj={}} = props;
    const [type,setType] = useState(2);//筛选的表格类型
    const [info,setInfo] = useState({});//筛选的表格类型
    const [dataSource,setDataSource] = useState([]);//表格数据
    const [pageSize,setPageSize] = useState(20);
    const [pageNum,setPageNum] = useState(1);
    const [total,setTotal] = useState(0);
    const [selectItme,setSelectItem] = useState({});//选中的要操作的项
    const [recordVisible,setRecordVisible] = useState(false);//开通记录弹窗
    const [delayVisible,setDelayVisible] = useState(false);//延期设置弹窗
    const [configVisible,setConfigVisible] = useState(false);//配置应用弹窗
    const [formalVisible,setFormalVisible] = useState(false);//转为正式弹窗
    const [openVisible,setOpenVisible] = useState(false);//再次开通弹窗

    const getInfo = async () => {
        try {
            const res = await statisticApplication({enterpriseId:props.id});
            setInfo(res);
        } catch (error) {
            console.warn(error);
        }
    };

    const getTableData = async () => {
        const params = {
            enterpriseId:props.id,
            pageSize,pageNum,
        };
        if(type !== 2 && (type || type === 0)){
            params.accessType = type;
        };
        try {
            const {data,total} = await queryConfigApplicationTable(params);
            setDataSource(Array.isArray(data) ? data : []);
            setTotal(total || 0);
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(()=>{
        if(props.id){
            getInfo();
        };
    },[props.id]);

    useEffect(()=>{
        if(props.id){
            getTableData();
        };
    },[props.id,type,pageNum,pageSize]);

    //延期设置确定
    const onDelay = async (res) => {
        const time = Moment(res.time).format('YYYY-MM-DD');
        if(res.stopTime >= time){
            that.mesWarning('延期时间不能小于到期时间');
            return;
        };
        try {
            await appAccessManageDelay({appId:selectItme.appId,id:selectItme.id,enterpriseId:selectItme.enterpriseId,endDate:time});
            getInfo();
            getTableData();
            that.mesSuccess('应用延长使用成功');
            setDelayVisible(false);
        } catch (error) {
            console.warn(error);
        }
    };
    
    //配置应用确定
    const onConfig = async({url}) => {
        try {
            await editApplicationUrl({id:selectItme.id,appId:selectItme.appId,enterpriseId:selectItme.enterpriseId,appUrl:url});
            that.mesSuccess('访问地址修改成功');
            setConfigVisible(false);
        } catch (error) {
            console.warn(error);
        }
    };
    
    //转为正式确定
    const onFormal = async ({startDate,endDate,url:appUrl}) => {
        try {
            await formalApplication({
                id:selectItme.id,
                appId:selectItme.appId,
                enterpriseId:selectItme.enterpriseId,
                endDate,
                startDate,
                appUrl,
            });
            getInfo();
            getTableData();
            that.mesSuccess('应用转为正式使用成功');
            setFormalVisible(false);
        } catch (error) {
            console.warn(error);
        }
    };

    //再次开通确定
    const onOpen = async ({startDate,endDate,url:appUrl,accessType}) => {
        try {
            await configAgainApplication({
                id:selectItme.id,
                appId:selectItme.appId,
                enterpriseId:selectItme.enterpriseId,
                endDate,
                startDate,
                appUrl,
                accessType,
            });
            getInfo();
            getTableData();
            that.mesSuccess('应用再次开通成功');
            setOpenVisible(false);
        } catch (error) {
            console.warn(error);
        }
    };

    const appType = [
        {
            id:2,
            name:'全部'
        },
        {
            id:0,
            name:'正式接入'
        },
        {
            id:1,
            name:'试用中'
        },
        {
            id:3,
            name:'已停用'
        },
    ];

    const columns = [
        {
            title: '应用类型',
            dataIndex: 'appName',
            key: 'appName',
            align: 'center',
            width:'20%',
            render: (val,item) => {
                return <span className={styles["table_type"]}><img alt="" src={window.$$setImgSrc(item.icon)} /><span>{val}</span></span>
            }
        },
        { 
            title: '应用进度', 
            dataIndex: 'useDay', 
            key: 'useDay', 
            align: 'center', 
            render:val => `${val}天`
        },
        { 
            title: '应用状态', 
            dataIndex: 'useStatus', 
            key: 'useStatus', 
            align: 'center',
            render:text => {
                const obj = {
                    '1':{
                        color:'#27CDA2',
                        text:'使用中'
                    },
                    '0':{
                        color:'#E34949',
                        text:'已停用'
                    },
                }
                return <Tag color={obj[text]?.color || ''}>{obj[text]?.text}</Tag>
            }
        },
        { 
            title: '开始时间', 
            dataIndex: 'startDate', 
            key: 'startTime', 
            align: 'center', 
        },
        { 
            title: '结束时间', 
            dataIndex: 'endDate', 
            key: 'endTime', 
            align: 'center', 
        },
        { 
            title: '使用类型', 
            dataIndex: 'accessType', 
            key: 'accessType', 
            align: 'center', 
            render:text => {
                const obj = {
                    '1':{
                        color:'#27CDA2',
                        text:'试用'
                    },
                    '0':{
                        color:'#333',
                        text:'正式'
                    },
                    '3':{
                        color:'#E34949',
                        text:'停用'
                    },
                }
                return <Tag color={obj[text]?.color || ''}>{obj[text]?.text}</Tag>
            }
        },
        { 
            title: '操作', 
            dataIndex: 'option', 
            key: 'option', 
            align: 'center', 
            width:300,
            render: (_, record) => <div className="table_option">
                {
                    record.accessType !== 3 && <React.RenderButton 
                        children={
                            <a onClick={() => {setDelayVisible(true);setSelectItem(record)}}>延长使用</a>
                        }
                        id={rightObj.date}
                    />
                }
                <React.RenderButton 
                    children={
                        <a onClick={() => {setRecordVisible(true);setSelectItem(record)}}>开通记录</a>
                    }
                    id={rightObj.record}
                />
                {
                    record.useStatus === 1 && <React.RenderButton 
                        children={
                            <a onClick={() => {
                                that.hint({
                                    type:'warning',
                                    title:'温馨提示',
                                    content:`强制停用后，该机构下的用户将均失去【${record.appName}】的所有的操作功能。您确认是否要强制停用么？`,
                                    isCancel:true,
                                    sure:async () => {
                                        try {
                                            await stopApplications({id:record.id,appId:record.appId,enterpriseId:record.enterpriseId});
                                            getInfo();
                                            getTableData();
                                            that.mesSuccess('应用停止成功');
                                        } catch (error) {
                                            console.warn(error);
                                        }
                                    }
                                });
                            }}>强制停用</a>
                        }
                        id={rightObj.stop}
                    />
                }
                <React.RenderButton 
                    children={
                        record.useStatus !== 0 ? <a onClick={() => {setConfigVisible(true);setSelectItem(record)}}>修改访问地址</a> : null
                    }
                    id={rightObj.url}
                />
                {
                    record.accessType === 1 && <React.RenderButton 
                        children={
                            <a onClick={() => {setFormalVisible(true);setSelectItem(record)}}>转为正式</a>
                        }
                        id={rightObj.formal}
                    />  
                }
                {
                    record.accessType === 3 && <React.RenderButton 
                        children={
                            <a onClick={() => {setOpenVisible(true);setSelectItem(record)}}>重新开通</a>
                        }
                        id={rightObj.open}
                    />  
                }
            </div>
        },
    ];

    return (
        <div className={styles["app_access_info"]}>
            <h1>应用接入详情</h1>
            <div className={styles["info_top_num"]}>
                <div className={styles["num_item"]} style={{background:'rgba(62, 120, 237, 0.1)'}}>
                    {allNumIcon}
                    <p style={{color:'#3E78ED'}}>接入应用总数：{info.total || 0}</p>
                </div>
                <div className={styles["num_item"]} style={{background:'rgba(255, 128, 44, 0.1)'}}>
                    {formalNumIcon}
                    <p style={{color:'#FF802C'}}>正式接入数量：{info.accessFormal || 0}</p>
                </div>
                <div className={styles["num_item"]} style={{background:'rgba(79, 222, 179, 0.1)'}}>
                    {triallNumIcon}
                    <p style={{color:'#4FDEB3'}}>试用中数量：{info.accessInformal || 0}</p>
                </div>
                <div className={styles["num_item"]} style={{background:'rgba(136, 136, 136, 0.1)'}}>
                    {stopNumIcon}
                    <p style={{color:'#888888'}}>停用数量：{info.stop || 0}</p>
                </div>
            </div>
            <div className={styles["app_type"]}>
                {
                    appType.map(it => {
                        return <span className={it.id === type ? styles["app_type_active"] : ''} key={it.id} onClick={() => setType(it.id)}>
                            {it.name}
                        </span>
                    })
                }
            </div>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowKey="id"
                locale={{
                    emptyText:<img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                }}
                footer={() => {
                    return <Pagination
                        showSizeChanger
                        pageSize={pageSize}
                        current={pageNum}
                        total={total}
                        onShowSizeChange={(c, z) => {setPageSize(z);setPageNum(1);}}
                        onChange={p => setPageNum(p)}
                    />
                }} 
            />
            {recordVisible && <RecordModal item={selectItme} onOk={() => setRecordVisible(false)}/>}
            <DelayModal
                visible={delayVisible}
                item={selectItme}
                onOk={onDelay}
                onCancel={() => setDelayVisible(false)}
            />
            <ConfigModal 
                visible={configVisible}
                item={selectItme}
                onOk={onConfig}
                onCancel={() => setConfigVisible(false)}
            />
            <FormalModal 
                visible={formalVisible}
                onOk={onFormal}
                onCancel={() => setFormalVisible(false)}
            />
            <OpenModal 
                visible={openVisible}
                onOk={onOpen}
                onCancel={() => setOpenVisible(false)}
            />
        </div>
    )
};

export default AppAccessInfo;