import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Row, Col, Checkbox, Radio, DatePicker} from 'antd';
import './index.scss';
import {selectAcademyTeacher,addAcademyTeacher} from '../../../../api/platform-school/index'
import {getCitySel, getProvinceSel, globalSel} from '../../../../api/platform-business/index'
const { Option } = Select;

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };
// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

const Demo = () => {
    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
}
const App = () => {
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>男</Radio>
            <Radio value={2}>女</Radio>
        </Radio.Group>
    );
};

    class index extends Component {
        state = {
            dataSource: [],

            enterpriseId: '',
            pageNum: 1,
            pageSize: 10,
            total: '',
            isTeacher: false, // 新增教师弹层
            propertyList: [], // 教师类型下拉列表
            professionalCallList: [], // 职称下拉列表
            professionalCall: '', // 职称
            property: '', // 教师类型
            teacherNameOrNum: '', // 教师工号/教师名称
            propertyDicType: 19, // 教师类型字典中的类型
            professionalCallDicType: 16, // 职称字典中的类型
            nameLength: 0, // 新增弹框姓名长度
            positionLength: 0, // 新增弹框职务长度
            provinceSel: [], // 所在地省下拉列表
            citySel: [], // 所在地市
        }

        componentDidMount() {
            this.selectAcademyTeacher()
            this.getProvince()
            this.getProfessionalCall()
            this.getProvinceSel()
            this.getCitySel()
        }

        async selectAcademyTeacher(){
            const param = {
                enterpriseId: this.state.enterpriseId,
                // isManagement: this.state.isManagement,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
                professionalCall: this.state.professionalCall,
                property: this.state.property,
                teacherNameOrNum: this.state.teacherNameOrNum
            }
            console.log('param eeeee ', param)
            const teacherTable = await selectAcademyTeacher(param)
            // console.log('teacherTable.data eee', teacherTable.data)
            this.setState({
                total: teacherTable.total,
                dataSource: teacherTable.data
            })
        }

        //检索
        search = (e) => {
            this.setState({ teacherNameOrNum: e }, () => this.selectAcademyTeacher());
        }

        // 教师类型下拉
        getProvince = async () => {
            if (this.state.propertyDicType) {
                let provinceRes = await globalSel({ type: this.state.propertyDicType });
                provinceRes && this.setState({ propertyList: provinceRes });
            }
        }

        //教师类型下拉框change
        changeProperty = (e, item) => {
            this.setState({ property: e}, () => this.selectAcademyTeacher());
        }

        // 职称下拉
        getProfessionalCall = async () => {
            if (this.state.professionalCallDicType) {
                let professionalCallRes = await globalSel({ type: this.state.professionalCallDicType });
                professionalCallRes && this.setState({ professionalCallList: professionalCallRes });
            }
        }

        // 职称下拉框change
        changeProfessionalCall = (e, item) => {
            this.setState({ professionalCall: e}, () => this.selectAcademyTeacher());
        }

        async toTeacherresult(){
            this.props.history.push("/main/platform-business/teacher-result")
        }
        async toTeacherrecord(){
            this.props.history.push("/main/platform-business/teacher-record")
        }

        // 姓名输入框长度
        nameLength =  (rule, value, callback) => {
            if(value.length === 0){
                this.setState({
                    nameLength:0
                })
                callback();
            }else if (value && value.length!==0){
                this.setState({
                    nameLength:value.length
                })
            } else {
                callback();
            }
        }

        // 姓名输入框长度
        positionLength =  (rule, value, callback) => {
            if(value.length === 0){
                this.setState({
                    positionLength:0
                })
                callback();
            }else if (value && value.length!==0){
                this.setState({
                    positionLength:value.length
                })
                callback();
            } else {
                callback();
            }
        }

        // 校验手机号格式
        validatePhone =  (rule, value, callback) => {
            let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
            if (value && !pattern.test(value)){
                callback('请输入正确的手机号')
            } else {
                callback();
            }
        }

        //省下拉
        getProvinceSel = async () => {
            let res = await getProvinceSel();
            console.log('省下拉 res', res)
            res && this.setState({ provinceSel: res });
        }
        //省下拉框change
        changeProvince = (e, item) => {
            this.setState({ provinceCode: e, citySel: [] }, () =>
                this.getCitySel()
            );
        }

        //市下拉
        getCitySel = async () => {
            if (this.state.provinceCode) {
                let res = await getCitySel({ provinceCode: this.state.provinceCode });
                res && this.setState({ citySel: res });
            }
        }
        //市下拉框change
        changeCity = (e, item) => {
            this.setState({
                cityId: e
            });
        }

         saveTeacher=()=>{
            console.log('20200000000000000')
            console.log('this.props', this.props)
             this.props.form.validateFields((err, values) => {
                console.log('err 2020', err)
                if(!err){
                    // let saveAcademyTeacherQo = {
                    //     birthday: value.birthday,
                    //     cardType: value.cardType,
                    //     enterpriseId: this.state.enterpriseId,
                    //     facultyId: value.facultyId,
                    //     facultyName: value.facultyName,
                    //     highestDegree: value.highestDegree,
                    //     highestEducation: value.highestEducation,
                    //     idCard: value.idCard,
                    //     inductionTime: value.inductionTime,
                    //     jobNum: value.jobNum,
                    //     nativePlace: value.nativePlace,
                    //     position: value.position,
                    //     professionalCall: value.professionalCall,
                    //     property: value.property,
                    //     sex: value.sex,
                    //     telNum: value.telNum,
                    //     userId: value.userId,
                    //     userName: value.userName
                    // }
                    // console.log('saveAcademyTeacherQo 2020', saveAcademyTeacherQo)
                    // addAcademyTeacher(saveAcademyTeacherQo).then (res => {
                    //     if (res !== null) {
                    //         console.log('', res)
                    //     }
                    // })
                }
            });
        }

        render() {
            let {isTeacher,dataSource,propertyList,professionalCallList,nameLength,provinceSel,citySel,positionLength} = this.state;
            const { getFieldDecorator,getFieldValue } = this.props.form;
            const columns = [
                {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
                {title: '工号/帐号', dataIndex: 'jobNum',key:'jobNum',width:'50', },
                {title: '姓名', dataIndex: 'teacherName',key:'teacherName',width:'50', },
                {title: '性别', align: 'center', render: (text, item, index ) =>  <span className='serial'>{item.sex === 0 ? '女' : item.sex === 1 ? '男' : ''}</span>,},
                {title: '出生日期', dataIndex: 'birthday',key:'birthday', width:'50',},
                {title: '籍贯', dataIndex: 'nativePlace', key:'nativePlace',width:'50',},
                {title: '最高学位', dataIndex: 'highestDegree',key:'highestDegree', width:'50',},
                {title: '职称', dataIndex: 'professionalCall', key:'professionalCall',width:'50',},
                {title: '手机', dataIndex: 'telNum', key:'telNum',width:'50',},
                {title: '院系', dataIndex: 'facultyName', key:'facultyName',width:'50',},
                {
                    title: '操作',
                    key: 'operate',
                    align:'center',
                    fixed:'right',
                    width:'200',
                    render:()=><div className="opspan">
                        <div className="main"><span className="but">查看</span><span className="but">编辑</span></div>
                        <div className="main"><span className="but">删除</span><span className="but">重置密码</span></div>
                    </div>},
            ];
            return (
                <div className='managestu_box'>
                    <div className="onelist">
                        <div className="one">
                            <div>
                                <span style={{marginRight: '20px'}}>教师类型</span>
                                <Select placeholder='全部'>
                                    {/*<Option value={''}>全部</Option>*/}
                                    {
                                        propertyList.map(property => <Option key={property.code} value={property.code}>{property.name}</Option>)
                                    }
                                </Select>
                            </div>
                            <div>
                                <span style={{marginRight: '20px'}}>职称</span>
                                <Select placeholder='全部'>
                                    {/*<Option value={''}>全部</Option>*/}
                                    {
                                        professionalCallList.map(professionalCall => <Option key={professionalCall.code} value={professionalCall.code}>{professionalCall.name}</Option>)
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="twolist">
                        <div className="top_list">
                            <div className="leftlist">
                                <Button className="buttonl" type="primary" style={{marginRight: '20px'}} onClick={() =>{this.toTeacherresult()}}>导入教师</Button>
                                <Button className="buttonm" style={{marginRight: '20px'}} onClick={() => this.setState({ isTeacher: true })}>新增教师</Button>

                                <Modal
                                    title='新增教师信息'
                                    visible={isTeacher}
                                    destroyOnClose={true}
                                    closable={false}
                                    className='binding_modal binding_modal_add_tea_info'
                                    footer={
                                        <div className='foot_botton'>
                                            <Button className="addbutton" onClick={this.saveTeacher}>确认添加</Button>
                                            <Button className="delete" onClick={() => this.setState({ isTeacher: false })}>取消</Button>
                                        </div>
                                    }
                                >
                                    <div className="modaltitle">
                                        个人信息
                                    </div>
                                    <Form>
                                    <Form.Item label={`姓名`}>
                                        {getFieldDecorator('teacherName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入姓名`,
                                                },
                                             /*   {
                                                    validator:this.nameLength
                                                }*/
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} />)}
                                        <span className="limited">{nameLength}/20</span>
                                    </Form.Item>
                                    <Form.Item label={`电话`}>
                                        {getFieldDecorator('telNum', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入电话`,
                                                },
                                                {
                                                    validator:this.validatePhone
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入电话" style={{ width: 330 , marginTop:"4px"}}/>)}
                                    </Form.Item>
                                    <Form.Item className="sex">
                                        <div className="all">
                                        <div className="totle"><span className="title">性别</span></div><App />
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input'>
                                        <div className="all">
                                            <div className="totle">
                                                <span className="title">出生日期</span></div>
                                            <DatePicker placeholder='请选择出生日期' style={{width: '100%'}} />
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input'>
                                        <div className="all">
                                            <div className="totle">
                                                <span className="title">籍贯</span></div>
                                            <Select placeholder='请选择省'
                                                    onChange={this.changeProvince}
                                            >
                                                {/*<Option value={''}>全部</Option>*/}
                                                {
                                                    provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                                }
                                            </Select>
                                            <Select placeholder='请选择市'
                                                    onChange={this.changeCity}
                                            >
                                                {/*<Option value={''}>全部</Option>*/}
                                                {
                                                    citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                                }
                                            </Select>
                                            </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                        <span className="title">最高学历</span></div><Select placeholder='请选择学历' style={{ width: 330 , marginTop:"4px"}}>
                                        <Option value={''}>全部</Option>
                                       </Select>
                                        </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                        <span className="title">最高学位</span> </div>
                                    <Select placeholder='请选择学位' style={{ width: 330 , marginTop:"4px"}}>
                                        <Option value={''}>全部</Option>
                                    </Select>
                                        </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                        <span className="title">证件类型</span> </div>
                                        <Select placeholder='请选择证件类型' style={{ width: 330, marginTop:"4px" }}>
                                        <Option value={''}>全部</Option>
                                    </Select>
                                    </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                                <span className="title">证件号码</span></div><Input placeholder="请输入证件号" style={{ width: 330 , marginTop:"4px"}}/>
                                        </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                                <span className="title">邮箱</span></div><Input placeholder="请输入邮箱" style={{ width: 330, marginTop:"4px" }}/>
                                        </div>
                                    </Form.Item>
                                    <div className="modaltitle">
                                        机构信息
                                    </div>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                                <span className='red'>*</span><span className="title">工号/帐号</span></div><Input placeholder="请输入工号" style={{ width: 330 , marginTop:"4px"}}/>
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input'>
                                        <div className="all">
                                            <div className="totle">
                                                <span className="title">入职日期</span></div>
                                            <DatePicker placeholder='请选择入职日期' style={{width: '100%'}} />
                                        </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                                <span className='red'>*</span><span className="title">教师类型</span></div>
                                        <Select placeholder='请选择教师类型' style={{ width: 330 , marginTop:"4px"}}>
                                        <Option value={''}>全部</Option>
                                        </Select>
                                        </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                                <span className='red'>*</span><span className="title">教师职称</span></div>
                                        <Select placeholder='请选择教师职称' style={{ width: 330 , marginTop:"4px"}}>
                                            <Option value={''}>全部</Option>
                                        </Select>
                                        </div>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                                <span className='red'>*</span><span className="title">院系</span></div>
                                        <Select placeholder='请选择院系' style={{ width: 330 , marginTop:"4px"}}>
                                            <Option value={''}>全部</Option>
                                        </Select>
                                        </div>
                                    </Form.Item>
                                        <Form.Item label={`工作单位`}>
                                            <div className="all">
                                                {/*<div className="totle">*/}
                                                {/*    <span className="title">工作单位</span></div>*/}
                                                {getFieldDecorator('workUnit', {
                                                    getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                })(
                                                    <Input placeholder="请输工作单位" style={{ width: 330 , marginTop:"4px"}} />
                                                )}
                                            </div>
                                        </Form.Item>
                                    <Form.Item label={`职务`}>
                                        {getFieldDecorator('position', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入职务`,
                                                },

                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入职务" style={{ width: 330 , marginTop:"4px"}} />)}
                                        <span className="limited">{positionLength}/30</span>
                                    </Form.Item>
                                    </Form>
                                </Modal>
                                <Button className="buttonr" onClick={() =>{this.toTeacherrecord()}}><img src={require('@/assets/img/lead.png')} alt="" />导入记录</Button>
                            </div>
                            <div className="right_list">
                                <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入教师工号/姓名' />
                            </div>
                        </div>
                        <div className="tablelist">
                            <Table columns={columns} dataSource={dataSource} scroll={{ x: 2000}} />,
                        </div>
                    </div>

                </div>
            )
        }
}

index = Form.create()(index);
export default index;
