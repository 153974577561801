

import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/check/action';

import {Button,  Input, Col, Checkbox, Tag, Form, DatePicker, message, Select,Modal,Tooltip} from 'antd';
import kindName from 'classnames';
import MyTable from '@/components/Table';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {changeUser,reportTypeList, showReportList, hasDealList,noDealList,create,dealwith,dealNoReson,reportTypeUserList,    getTopicDetail,deleteReportTopic,dragBlack} from '@/api/circle/index'
// import {tupOff,tupOffWait,getLastProcessType,getTipOffType} from '@/api/report/report'
import moment from 'moment';
//import ReportReason from './components/index'
const {Option} = Select
const {RangePicker} = DatePicker;
// const { Column } = Table;

const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


class TopicReport extends React.Component {
    state = {
        _businessType:'HT',     //话题举报
        _platform:'OC',         //平台圈子
        
        _startTime:'',      //开始时间
        _endTime:'',        //结束时间

        _noDealListData:[],    //待处理举报列表
        _noDealListNumber:[],
        _hasDealListData:[],   //已处理举报列表  
        _hasDealListNumber:[],

        btnDisabled:true,  //重置按钮
        daiNumber:'',  // 待处理长度
        yiNumber:'',   //已处理长度
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            // tipOffType: undefined, //举报类型
            // lastProcessType: undefined, //最后处理方式
            // searchValue: undefined,  //检索条件
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        },
        processLists:[],
        dateString:'',
        entity: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        reason:'',  //资源冻结原因
        reasonNum: 0,  //资源冻结原因字数
        ids: [],
        offLists:[],  //资源举报列表
        businessId:'',  //业务id
        dataSource: [],
        startColumns: [
            // {title: '11', dataIndex: '', key: ''},
            // {title: '圈子封面', dataIndex: 'circlePhoto', key: 'circlePhoto'},
            // {title: '圈子名称', dataIndex: 'circleName', key: 'circleName'},
            // {title: '圈子ID', dataIndex: 'circleID', key: 'circleID'},
            // {title: '圈主', dataIndex: 'circleManager', key: 'circleManager', align: 'center',},
            // {title: '所在院校', dataIndex: 'circleCollege', key: 'circleCollege   ', align: 'center'},

            // {title: '创建日期', dataIndex: 'circleDate', key: 'circleDate   ', align: 'center'},
            // {title: '公开范围', dataIndex: 'circleScope', key: 'circleScope   ', align: 'center'},
            // {title: '成员量', dataIndex: 'chenyuanNumber', key: 'chenyuanNumber   ', align: 'center'},
            // {title: '浏览量', dataIndex: 'liulanNumber', key: 'liulanNumber   ', align: 'center'},
            // {title: '话题数', dataIndex: 'topicNumber', key: 'topicNumber   ', align: 'center'},
            // {title: '评论数', dataIndex: 'commentNumber', key: 'commentNumber   ', align: 'center'},

            {title: '', width:0,dataIndex: '', key: ''},

            {
                title: '话题名称', dataIndex: 'businessName', key: 'businessName',align: 'center',
                render:(text)=> <Tooltip placement="top" title={text}>
                 <div className="tabtext">{text}</div>
              </Tooltip>
               
        },
            {title: '所在圈子', dataIndex: 'extra', key: 'extra',align: 'center',
                render:(v,i)=>{
                    //console.log(eval('(' + v + ')'),'3333')
                    return <span>{eval('(' + v + ')')?.circleName?eval('(' + v + ')')?.circleName:''}</span>
                }
            },
            {title: '所在院校', dataIndex: 'extra', key: 'extra', align: 'center',
                render:(v,i)=>{
                    //console.log(eval('(' + v + ')'),'3333')
                    return <span>{eval('(' + v + ')')?.orgName?eval('(' + v + ')')?.orgName:''}</span>
                }
            },
            {title: '话题作者', dataIndex: 'businessAuthor', key: 'businessAuthor   ', align: 'center'},

            {title: '发布日期', dataIndex: 'createTime', key: 'createTime', align: 'center'},
            {title: '举报原因', dataIndex: 'tipOffType', key: 'tipOffType', align: 'center'},
            {title: '举报人', dataIndex: 'tipOffUserName', key: 'tipOffUserName', align: 'center'},
            {title: '举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber', align: 'center'},
            
            


        ],
        endColumns: [
            // {title: '举报类型', dataIndex: 'tipOffType', key: 'tipOffType', align: 'center',
            //     render:(text, record) => this.state.reportTypeMap[text]
            // },
            // {title: '最后处理时间', dataIndex: 'lastProcessTime', key: 'lastProcessTime', align: 'center',},
            {
                title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                width: 320,
                render: (text, item) => <div className='action vertical_j'>
                {/* {console.log(item,"--------------111")} */}
                    <p>
                        {/* <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
                        {this.state.activeIndex === 1 ? <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span> : ""}
                        {
                            this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
                              <span onClick={() => this.reasonShow(record)}>冻结</span> : ''
                        } */}
                        <span  onClick={(id) => this.props.history.push(`/main/circle-manage/topic-report-detail/${item.businessId}`)}>举报详情</span>
                        {/* <span  onClick={() => window.open('http://www.baidu.com')}>删除该话题</span>
                        <span  onClick={() => window.open('http://www.baidu.com')}>将作者拉入黑名单</span> */}
                        <span onClick={() => this.handledeleteTopic(item.businessId)}>删除该话题</span>
                        <span onClick={() => this.handlejoinBlackList(item.tipOffUserId)}>将作者拉入黑名单</span>	
                    </p>
                </div>
            }
        ],
        endColumns02: [
           
            {
                title: '操作', dataIndex: 'address02', key: 'address02', fixed: 'right', align: 'center',
                width: 240,
                render: (text, item) => <div className='action vertical_j'>
                    <p>
                        {/* <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
                        {this.state.activeIndex === 1 ? <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span> : ""}
                        {
                            this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
                              <span onClick={() => this.reasonShow(record)}>冻结</span> : ''
                        } */}
                        {/* <span  onClick={() => window.open('http://www.baidu.com')}>查看举报详情</span> */}
                        
                        {/* <span  onClick={() => this.props.history.push('/topic-report-detail')}>查看举报详情</span> */}
                        <span  onClick={(id) => this.props.history.push(`/main/circle-manage/topic-report-detail/${item.businessId}`)}>查看举报详情</span>
                    </p>
                </div>
            }
        ],
        // _dddcommentReportListData:[
        //     {
        //         "businessId": "1111111",
        //         "businessName": "fdsafdsaf",
        //         "businessAuthor": "fds1112112121212",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "4",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:40:37",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       },
        //       {
        //         "businessId": "1111118",
        //         "businessName": "速度速度8",
        //         "businessAuthor": "阿斯顿发8",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "5",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:43:17",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       },
        //       {
        //         "businessId": "1111117",
        //         "businessName": "速度速度7",
        //         "businessAuthor": "阿斯顿发7",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "10",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:43:07",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       },
        //       {
        //         "businessId": "1111116",
        //         "businessName": "速度速度6",
        //         "businessAuthor": "阿斯顿发6",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "8",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:42:53",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       },
        //       {
        //         "businessId": "1111115",
        //         "businessName": "速度速度5",
        //         "businessAuthor": "阿斯顿发5",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "3",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:42:43",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       },
        //       {
        //         "businessId": "1111114",
        //         "businessName": "速度速度4",
        //         "businessAuthor": "阿斯顿发4",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "1",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:42:36",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       },
        //       {
        //         "businessId": "1111113",
        //         "businessName": "速度速度1",
        //         "businessAuthor": "阿斯顿发2",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "11",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:42:27",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       },
        //       {
        //         "businessId": "1111112",
        //         "businessName": "速度速度",
        //         "businessAuthor": "阿斯顿发",
        //         "tipOffType": "骚扰,反动言论",
        //         "tipOffUserId": "7",
        //         "tipOffUserName": "范德萨发teest",
        //         "tipOffNumber": "1",
        //         "createTime": "2020-12-03 19:40:57",
        //         "extra": "{\"circleName\":\"清华圈子\"}"
        //       }
        // ], //本地数据调试专用可以删除
        columns: [],
        reportTypeData:[],
        reportTypeMap: {},
        processModeData:[],
        processModeMap: {}
    }
    componentDidMount() {
        //this.getTipOffType()
        //this.getLastProcessType()
        this.noDealList();
        this.hasDealList();

        this.initColumns();
        this.getList();
        
    }
    //delete
    // async getTipOffType() {
    //     const reportTypeData = await getTipOffType()
    //     let reportTypeMap = {}
    //     if (reportTypeData && reportTypeData.data.length) {
    //         reportTypeData.forEach(item => {
    //             reportTypeMap[item.tipOffType] = item.tipOffTypeName
    //         })
    //         this.setState({
    //             reportTypeData,
    //             reportTypeMap
    //         })
    //     }
        
    // }

    // async getLastProcessType() {
    //     const processModeData = await getLastProcessType()
    //     let processModeMap = {}
    //     if(processModeData && processModeData.data.length){
    //         processModeData.forEach(item => {
    //             processModeMap[item.id] = item.name
    //         })
    //         this.setState({
    //             processModeMap,
    //             processModeData
    //         })
    //     }
        
    // }

    //待处理举报列表
    async noDealList() {
        const noDealListData = await noDealList({
            "businessType": this.state._businessType,
            "platform": this.state._platform
        });    
        let noDealListMap = {}
        console.log(noDealListData,"--------------");
        
        if (noDealListData && noDealListData.data.length) {   
            this.setState({
                _noDealListData: noDealListData.data,
                _noDealListNumber:noDealListData.total,
                
            });
        }
        
    }

    //已处理举报列表
    async hasDealList() {
        const hasDealListData = await hasDealList({
            "businessType": this.state._businessType,
            "platform": this.state._platform
        });    
        let hasDealListMap = {}
        console.log(hasDealListData,"--------------");
        
        if (hasDealListData && hasDealListData.data.length) {   
            this.setState({
                _hasDealListData: hasDealListData.data,
                _hasDealListNumber:hasDealListData.total,
                
            });
        }
        
    }

    initColumns() {
        const {activeIndex, startColumns, endColumns, endColumns02,} = this.state
        let column = []
        if(activeIndex === 0){
                
                this.setState({
                    columns: startColumns.concat(column).concat(endColumns),
                })

        }else{
             
            this.setState({
                columns: startColumns.concat(column).concat(endColumns02),
            })
        }
        // this.setState({
        //     columns: startColumns.concat(column).concat(endColumns)
        // })
    }
    async getList(){
        const {activeIndex, pagination} = this.state
        if(activeIndex === 0){
            const result = await noDealList({
                "businessType": this.state._businessType,
                "platform": this.state._platform,
                "pageNum": pagination.pageNum,
                "pageSize": pagination.pageSize,
            }); 
            
            if(result ){
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,    //这里需要传页码数据否则点击不跳页
                        pageSize: pagination.pageSize,
                        //processEndTime: undefined,
                        //processStartTime: undefined,
                        total: result.total
                    }
                })
            }
            console.log(this.state.dataSource,'-------------')
        }else{
            const result = await hasDealList({
                "businessType": this.state._businessType,
                "platform": this.state._platform,
                "pageNum": pagination.pageNum,
                "pageSize": pagination.pageSize,
            }); 
            
            if(result ){
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,    //这里需要传页码数据否则点击不跳页
                        pageSize: pagination.pageSize,
                        //processEndTime: undefined,
                        //processStartTime: undefined,
                        total: result.total
                    }
                })
            }
            //console.log(this.state.dataSource,'\\\\\\\\\\\\\\');
        }
    }
    //资源举报列表-已处理
    // async tupOff() {
    //     let { pagination} = this.state
    //     const offLists = await tupOff({...pagination, processState: 1})
    //     if(offLists && offLists.length){
    //         pagination.total = offLists.total
    //         this.setState({
    //             pagination,
    //             yiNumber:offLists.total,
    //         })
    //         return offLists
    //     }
    //     pagination.total = offLists.total
    //     this.setState({
    //         pagination,
    //         yiNumber:offLists.total,
    //     })
    //     return offLists
    // }
    //资源举报列表-待处理
    // async tupOffWait() {
    //     let { pagination} = this.state
    //     const OffWaitLists = await tupOffWait({...pagination, processState: 0})
    //     pagination.total = OffWaitLists.total
    //     this.setState({
    //         pagination,
    //         daiNumber:OffWaitLists.total,
    //     })
    //     return OffWaitLists
    // }
    //分页
    pageChange = (pageNumber) => {
        let {pagination} = this.state;
        pagination.pageNum = pageNumber;
        //this.setState({pagination: pagination}, () => this.getList())
        this.setState({pagination: pagination})
    }
    //选择行
    // selectedRowChange = (selectedRowKeys, selectedRows) => {
    //     let alreadyFreeze = selectedRows.filter(row => row.lastProcessType === "1")
    //     if(alreadyFreeze.length > 0){
    //         return message.warning('不要选择已经冻结的数据');
    //     }
    //     let expandedRowKeys = selectedRows.map(v => v.resourceId)
    //     this.setState({selectedRowKeys, expandedRowKeys})
    // }
    //搜索选项改变
    reportChange = ({target: {value}}) => {
        let {pagination} = this.state;
        pagination.searchValue = value
        this.setState({
            pagination,
            btnDisabled: false
        });
    };
    //待处理  已处理切换
    changeTabMenu = (key) => {
        this.setState({
            //selectedRowKeys: [],
            expandedRowKeys: [],
            activeIndex: key
        }, () => {
            this.initColumns()
            //this.resetSearch()
            this.getList()
        })
    };
    resetSearch() {
        let pagination = {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tipOffType: undefined, //举报类型
            processState: 0, //处理状态
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processTime: undefined,  //时间
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        };
        this.setState({
            pagination: pagination,
            btnDisabled: true
        }, () => this.getList())
    }

    //全选反选切换
    // checkedAll = () => {
    //     let {selectedRowKeys, dataSource} = this.state;
    //     if (selectedRowKeys.length === dataSource.length) {
    //         this.setState({selectedRowKeys: [], expandedRowKeys: []});
    //         return;
    //     }
    //     const keys = Object.keys(dataSource);
    //     const index = [];
    //     keys.forEach(item => {
    //         index.push(Number(item));
    //     });
    //     this.setState({
    //         selectedRowKeys: index,
    //         expandedRowKeys: dataSource.map(v => v.resourceId)
    //     });
    // }
    //冻结原因
    // reasonShow(row) {
    //     this.setState({
    //         ids: [row.resourceId],
    //         visible: true
    //     })
    // }
    //批量冻结原因
    // reasonBatchShow = () => {
    //     const {expandedRowKeys} = this.state
    //     if (!expandedRowKeys.length) {
    //         return message.warning('请选择要冻结的资源');
    //     }
    //     this.setState({
    //         ids: expandedRowKeys,
    //         visible: true
    //     })
    // }

    checkCancel = () => {
        this.setState({
            visible: false
        })
    }
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        }, () => this.getList())
    }
    //举报类型改变
    reportTypeChange = (value) => {
        let {pagination} = this.state;
        pagination.tipOffType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //处理方式改变
    reportModeChange = (value) => {
        let {pagination} = this.state;
        pagination.lastProcessType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //日期查询
    reportTimeChange = (value, dateString)=> {
        let {pagination} = this.state;
        pagination.processTime = value
        pagination.processStartTime = dateString[0]
        pagination.processEndTime = dateString[1]
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList())
    }

    //deleteTopic删除该话题弹层
    handledeleteTopic = (topicId) => {
        // console.log(this.state.userInfo)
        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否删除该话题?',
            centered: true,
            className: "ModalConfirm-pic",
            okType: 'danger',
            okText: '确定',
            onOk: () => {
				deleteReportTopic({topicId}).then(r=>{
                    debugger;
					if(r.code===0){
						message.success('删除该话题成功')
					}else{
					alert("删除该话题失败")
					}
				})
			}
        })
    };

    //gotoBlackList 将作者拉入黑名单弹层
    handlejoinBlackList = (userId) => {
        // console.log(this.state.userInfo)
        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否将作者拉入黑名单?',
            centered: true,
            className: "ModalConfirm-pic",
            okType: 'danger',
            okText: '确定',
            onOk: () => {
				dragBlack({userId}).then(r=>{
                    debugger;
					if(r.code===0){
						message.success('拉入黑名单成功')
					}else{
					alert("拉入黑名单失败")
					}
				})
			}
        })
    };

    //deleteTopic   删除该话题 可以删
    deleteTopic = () => {
        console.log("删除该话题")

    }

    //gotoBlackList   将作者拉入黑名单 可以删
    gotoBlackList = () => {
        console.log("将作者拉入黑名单")
    }


    render() {
        const {
            _noDealListData,
            _hasDealListData,
            _noDealListNumber,
            _hasDealListNumber,
            _businessType,
            _platform,
            columns, 

            _dddcommentReportListData,
            selectedRowKeys, 
            activeIndex, 
            dataSource, 
            visible,
            reportTypeData,
            processModeData,

            pagination, 
            reason, 
            reasonNum
        } = this.state;
        const formItemLayout = {
            labelCol: {span: 0},
            wrapperCol: {span: 24},
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle check_box report'>
                {/* <div className="top p20 bg_fff m20">
                    
                        <div>
                            <span style={{ marginRight: '20px' }}>圈子名称</span>
                            <Input style={{width:'60%'}} placeholder='请输入圈子名称' />
                        </div>
                        <div>
                            <span style={{ marginRight: '20px' }}>所属机构</span>
                            <Select placeholder='请选择所属机构' onChange={e => {
                                this.setState({ organizationId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                {
                                    // enterprisList.map(v => {
                                    //     return <Option value={v.id} key={v.id}>{v.enterpriseName}</Option>
                                    // })
                                }
                            </Select>
                        </div>
                        <div>
                            <span style={{ marginRight: '20px' }}>公开范围</span>
                            <Select placeholder='请选择身份类型' onChange={e => {
                                this.setState({ identityId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                {
                                    // statusList.map(v => {
                                    //     return <Option value={v.code} key={v.code}>{v.name}</Option>
                                    // })
                                }
                            </Select>
                        </div>
                        <span style={{marginRight: '20px'}}>创建日期：</span>
                                <RangePicker onChange={this.reportTimeChange} value={pagination.processTime}
                                             style={{width: '25%', marginRight: '20px '}}/>
                    
                        <Button className='reset_btn' onClick={()=> this.resetSearch()} disabled={this.state.btnDisabled}>重置</Button>
                        <Button type='primary' className='ant_blue' style={{marginLeft: '12px'}} onClick={() => this.queryBtn()}>查询</Button>
                    
                </div> */}
                <div className="p20 pright40 bg_fff m20">
                    <div className="top_01 mbottom20">
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>圈子名称</span>
                            <Input style={{ width: '80%' }} placeholder='请输入圈子名称' />
                        </Col>

                        <Col span={8}>
                            <span style={{ marginRight: '20px' }}>举报人名称</span>
                            <Input style={{ width: '70%' }} placeholder='请输入举报人名称' />
                        </Col>

                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>话题作者</span>
                            <Input style={{ width: '80%' }} placeholder='请输入话题作者名称' />
                        </Col>

                        

                    </div>

                    <div className="top_02  mbottom20">
                    <Col span={7}>
                            <span style={{ marginRight: '20px' }}>举报类型</span>
                            <Select placeholder='请选择所属机构' style={{ width: '80%' }} onChange={e => {
                                this.setState({ organizationId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                {
                                    // enterprisList.map(v => {
                                    //     return <Option value={v.id} key={v.id}>{v.enterpriseName}</Option>
                                    // })
                                }
                            </Select>
                        </Col>

                        <Col span={8}>

                            <span style={{ marginRight: '35px' }}>所属机构</span>
                            <Select placeholder='请选择身份类型' style={{ width: '70%' }} onChange={e => {
                                this.setState({ identityId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                {
                                    // statusList.map(v => {
                                    //     return <Option value={v.code} key={v.code}>{v.name}</Option>
                                    // })
                                }
                            </Select>
                        </Col>
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>创建日期</span>
                            <RangePicker onChange={this.reportTimeChange} value={pagination.processTime}
                                style={{ width: '80%', }} />
                        </Col>

                        

                    </div >
                    <div className="mright20" style={{textAlign: 'right',paddingRight: '20px'}}>
                        <Button className='reset_btn' onClick={() => this.resetSearch()} disabled={this.state.btnDisabled}>重置</Button>
                        <Button type='primary' className='ant_blue' style={{ marginLeft: '12px' }} onClick={() => this.queryBtn()}>查询</Button>
                    </div>
                </div>

                <div className="wrapper padd">
                    <div className="top m20">
                        <Col className='top_l' span={12}>
                            <div className='tabs'>
                                <Tag onClick={() => this.changeTabMenu(0)}
                                     className={kindName({'active': activeIndex === 0})}>待处理 </Tag>
                                <Tag onClick={() => this.changeTabMenu(1)}
                                     className={kindName({'active': activeIndex === 1})}>已处理 </Tag>
                            </div>
                        </Col>
                        {/* <Col className='top_r' span={12}>
                            <Input placeholder='输入资源名称/上传人/举报人' value={pagination.searchValue} onChange={this.reportChange} onPressEnter={() => this.getList()} />
                            <Button className='reset_btn'>重置</Button>
                            <Button type='primary' className='search'>查询</Button>
                        </Col> */}
                    </div>
                    <div className="check_container" style={{position: 'relative'}}>
                        
                            <MyTable
                                options={
                                    
                                    {
                                        
                                        dataSource:this.state.dataSource,
                                        columns,
                                        // rowSelection: {
                                        //     selectedRowKeys,
                                        //     onChange: this.selectedRowChange,
                                        // },
                                        pagination: {
                                            onChange: this.pageChange, 
                                            current: pagination.pageNum, 
                                            pageSize: pagination.pageSize,
                                            total: pagination.total
                                        },
                                    }
                                }
                                // isDrab={false}
                                //fixededit={true}
                                scroll={{ x: 1800,}}
                            />
                        
                        {/* <div className="Grounding_position">
                            {activeIndex==0?
                            <label>
                                <Checkbox
                                    checked={selectedRowKeys.length === dataSource.length ? true : false}
                                    onChange={this.checkedAll}
                                />
                                 <span className="Grounding_select">全选</span>
                            </label>
                            :""}
                            <Button className='ant_blue' onClick={() => this.reasonBatchShow()} type='primary'>批量冻结</Button>
                        </div> */}
                    </div>
                </div>
                {/* <ReportReason
                  ids={this.state.ids}
                  visible={visible}
                  checkCancel={this.checkCancel.bind(this)}
                  refreshData={this.refreshData.bind(this)}
                  wrappedComponentRef={form => {
                      this.reportReasonForm = form;
                  }}
                ></ReportReason> */}
            </div>
        )
    }
}


/*Check = connect(mapStateToProps, mapDispatchToProps)(Check);
export default Check;*/
TopicReport = connect(mapStateToProps, mapDispatchToProps)(TopicReport);
TopicReport = Form.create()(TopicReport)
export default TopicReport;