


import React from 'react';


//推送管理
export default props => {
    return <svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5171 13.476H6.3233C5.9161 13.476 5.58594 13.1458 5.58594 12.7386V4.97174C5.58594 4.56454 5.9161 4.23438 6.3233 4.23438H10.3542C10.5526 4.23438 10.7427 4.31436 10.8814 4.45623L13.0444 6.66831C13.1791 6.80608 13.2545 6.99113 13.2545 7.18382V12.7386C13.2545 13.1458 12.9243 13.476 12.5171 13.476ZM7.06066 12.0013H11.7798V7.48448L10.0439 5.7091H7.06066V12.0013Z" fill="#6A7E9C"/>
        <path d="M10.5296 9.29767H8.47482C8.06762 9.29767 7.73746 8.96751 7.73746 8.56031C7.73746 8.15311 8.06762 7.82295 8.47482 7.82295H10.5296C10.9368 7.82295 11.2669 8.15311 11.2669 8.56031C11.2669 8.96751 10.9368 9.29767 10.5296 9.29767ZM10.1103 11.4114H8.47482C8.06762 11.4114 7.73746 11.0812 7.73746 10.674C7.73746 10.2668 8.06762 9.93664 8.47482 9.93664H10.1103C10.5175 9.93664 10.8476 10.2668 10.8476 10.674C10.8476 11.0812 10.5175 11.4114 10.1103 11.4114ZM8.00291 1.45517H7.22762C7.154 0.792487 6.59077 0.275391 5.9088 0.275391H4.37509C3.69306 0.275391 3.12984 0.792487 3.05622 1.45517H2.19251C1.298 1.45517 0.570312 2.18285 0.570312 3.07736V10.156C0.570312 11.0505 1.298 11.7782 2.19251 11.7782H4.72903V10.3035H2.19251C2.11258 10.3035 2.04503 10.2359 2.04503 10.156V3.07736C2.04503 2.99743 2.11258 2.92989 2.19251 2.92989H3.14647C3.34468 3.41383 3.8206 3.75573 4.37509 3.75573H5.9088C6.4633 3.75573 6.93916 3.41383 7.13742 2.92989H8.00291C8.08284 2.92989 8.15038 2.99743 8.15038 3.07736V3.55605H9.6251V3.07736C9.6251 2.18285 8.89735 1.45517 8.00291 1.45517ZM5.76133 2.28101H4.52256V1.75011H5.76133V2.28101Z" fill="#BABABA"/>
    </svg>


}