// 关联资讯
import React from 'react';
import {Form, Table,  Transfer,Row,Col,Input,Checkbox,Button} from 'antd';
import './index.scss';
import {connect} from "react-redux";
import {getNotRelationNewsList, columnRelationNews} from "../../../../../../api/zixun/index"
import difference from "lodash/difference";
import {withRouter} from "react-router-dom";
const { Search } = Input;

function callback(key) {
    console.log(key);
}
function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
}

@withRouter
class DistributionCource extends React.Component {
    state = {
        allClass: [],   // 全部班级
        assignedClasses: [],
        className: '',
        selectedRowKeys: [],// 选择的标签
        selectedRows: [],// 选择的标签
        disabled: false, // 是否可选择
        columns: [
            {title: '资讯名称', dataIndex: 'newsTitle', key: 'newsTitle', align: 'center',ellipsis: true},
            {title: '作者', dataIndex: 'authorName', key: 'authorName', align: 'center',ellipsis: true},
            {
                title: '资讯分类', align: 'center', render: (text, item, index) => <span>
                    {
                        item.newsType === 1 ? '原创' : '转载'
                    }</span>,
            },
            {title: '发布时间', width: 200, dataIndex: 'releaseTime', key: 'releaseTime', align: 'center'},
            {
                title: '操作',  align: 'center', fixed: 'right',
                // width: 220,
                render: (text, record) => {
                    return <p className='action vertical_j'>
                        <span onClick={() =>this.lookNewsDetail(record)}>查看</span>
                        <span onClick={() =>this.addNews(record)}>添加</span>
                    </p>
                }
            }
        ],
        mockData : [],
        searchNewsName : '',
        ids : [],
        userId : JSON.parse(localStorage.getItem("userInfo")).id,
        isSelectAll : false,
    };

    componentDidMount () {
        // 获取当前计算机的端口号
        this.setState({
            host: window.location.host.split(':')[1],
        });
        this.props.onRef('DistributionCource', this)
        // this.getNotRelationNewsList()
    }

    getNotRelationNewsList = () =>{
        let data = {
            columnId : this.props.columnInfo.columnId,
            newsName : this.state.searchNewsName,
            organizationId : '',
            platform : this.props.platform
        }
        getNotRelationNewsList(data).then(res =>{
            if(res){
                this.setState({
                    mockData : res,
                })
            }
        })
    }

    lookNewsDetail = (record) =>{
        const w = window.open('about:blank');
        w.location.href=`/#/main/group-column/lookRelationNews?${decodeURI(JSON.stringify({ newsId: record.newsId }))}`
        // this.props.history.push(`/main/group-column/lookNews?${decodeURI(JSON.stringify({ newsId: record.newsId }))}`)
    }

    addNews = (record) =>{
        let data = {
            newsId : record.newsId,
            newsTitle : record.newsTitle,
            authorName : record.authorName,
            newsType : record.newsType,
        }
        let ids = this.state.ids
        let result = ids.indexOf(record.newsId)
        if(result > -1){
            return
        }
        let assignedClasses = this.state.assignedClasses
        assignedClasses.push(data)
        ids.push(record.newsId)
        let selectedRowKeys = this.state.selectedRowKeys
        let selectedRows = this.state.selectedRows
        if(selectedRowKeys.length > 0){
            let index = selectedRowKeys.indexOf(record.newsId)
            selectedRowKeys.splice(index,1)
            let result = selectedRows.find(e =>e.newsId === record.newsId)
            selectedRows.splice(result,1)
        }
        this.setState({
            assignedClasses,
            ids,
            selectedRowKeys,
            selectedRows
        })
        console.log("selectedRowKeys", selectedRowKeys)
        console.log("selectedRows", selectedRows)
        if(ids.length >= this.state.mockData.length){
            this.setState({
                disabled : true,
                isSelectAll : false
            })
        }else {
            this.setState({
                disabled : false,
            })
        }
    }

    removeNews = (e) =>{
        console.log("e" ,  e)
        let assignedClasses = this.state.assignedClasses
        let ids = this.state.ids
        let index = assignedClasses.indexOf(e)
        let id = ids.indexOf(e.newsId)
        assignedClasses.splice(index, 1);
        ids.splice(id, 1);
        this.setState({
            assignedClasses,
            ids,
            disabled : false,
            isSelectAll : false
        })
    }

    determineRelationNews = () =>{
        if(this.state.ids.length === 0){
            this.mesWarning("请选择关联的资讯")
            return
        }
        let data = {
            createUserId : this.state.userId,
            columnId :  this.props.columnInfo.columnId,
            newsIds : this.state.ids
        }
        columnRelationNews(data).then(res =>{
            if(res){
                this.props.determineRelationNews()
                this.setState({
                    ids : [],
                    assignedClasses : [],
                    selectedRowKeys: [],
                    selectedRows: []
                })
            }
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        let isSelectAll = false
        let arr = []
        if(this.state.mockData.length > 0){
            this.state.mockData.forEach(item =>{
                let result = selectedRowKeys.find(e => e === item.newsId)
                if(result){
                    arr.push(item)
                }
            })
        }
        if(arr.length === (this.state.mockData.length - this.state.ids.length)){
            isSelectAll = true
        }
        this.setState({
            selectedRowKeys,
            selectedRows,
            isSelectAll
        });
    }

    batchAddNews = () =>{
        let assignedClasses = this.state.assignedClasses
        let ids = this.state.ids
        let selectedRowKeys = this.state.selectedRowKeys
        this.state.selectedRows.forEach(item =>{
            // let index = selectedRowKeys.indexOf(item.newsId)
            // selectedRowKeys.splice(index,1)
            ids.push(item.newsId)
            let data = {
                newsId : item.newsId,
                newsTitle : item.newsTitle,
                authorName : item.authorName,
                newsType : item.newsType,
            }
            assignedClasses.push(data)
        })
        this.setState({
            assignedClasses,
            ids,
            selectedRowKeys : [],
            selectedRows : []
        },() =>{
            console.log("selectedRowKeys", selectedRowKeys)
        })
        if(ids.length >= this.state.mockData.length){
            this.setState({
                disabled : true,
                isSelectAll : false
            })
        }else {
            this.setState({
                disabled : false,
            })
        }
    }

    onSelectALL = e => { // 点击全选
        if (e.target.checked) {
            let selectedRowKeys = this.state.selectedRowKeys
            let selectedRows = this.state.selectedRows
            this.state.mockData.forEach(item =>{
                let result = this.state.ids.indexOf(item.newsId);
                if(result < 0){
                    selectedRowKeys.push(item.newsId)
                    selectedRows.push(item)
                }
            })
            this.setState({
                selectedRowKeys,
                selectedRows,
                isSelectAll : true
            });
        } else {
            this.setState({
                selectedRowKeys: [],
                isSelectAll : false
            });
        }
    }

    setNewsName = ({target: {value}}) =>{
        console.log("this.searchNews", value)
        this.setState({
            searchNewsName :value
        })
    }

    searchNews = () =>{
        this.getNotRelationNewsList()
    }

    render() {
        const {allClass, assignedClasses, selectList,columns,dataSource,selectedRowKeys} = this.state
        const {getFieldDecorator} = this.props.form
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps : record => ({
                disabled: this.state.ids.includes(record.newsId)
            })
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div className="Distribution-cource">
                <Row type="flex" justify="space-between">
                    <Col span={15}>
                        <h4>关联资讯</h4>
                        <div className="bottom-cont">
                            <div className="sech-right searandTitle">
                                <b className='glname'>关联专栏</b>
                                <div className='glzyname'>{this.props.columnInfo.columnName}</div>
                                <Search
                                    placeholder="请输入资讯名称或作者搜索"
                                    maxLength={20}
                                    onChange={this.setNewsName.bind(this)}
                                    onSearch={this.searchNews}
                                />
                            </div>
                            <div className='all_wapper'>
                                <Table
                                    rowKey={(v) => v.newsId}
                                    className='organ-table organ-table-live'
                                    columns={columns}
                                    dataSource={this.state.mockData}
                                    pagination={false}
                                    rowSelection={rowSelection}
                                    // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
                                    locale={{
                                        emptyText: <>
                                            <div className="Empty-conet">
                                                <div className="img">
                                                    <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                                    <span>暂无数据</span>
                                                </div>
                                            </div>
                                        </>
                                    }}
                                />
                                <div className='check_box'>
                                    <Checkbox
                                        disabled={this.state.disabled}
                                        onChange={this.onSelectALL}
                                        checked={this.state.isSelectAll}
                                    >
                                        全选
                                    </Checkbox>
                                    <Button type="primary" disabled={!hasSelected} onClick={()=>this.batchAddNews()}>批量添加</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={9}>
                        <h4>已添加资讯</h4>
                        <ul className='li_box'>
                            {
                                assignedClasses.map((item, index) => {
                                    return (
                                        <li className='li_list' key={item.newsId}>
                                            <div className='li_name'>{item.newsTitle}</div>
                                            <div className='li_info'>
                                                <span style={{marginRight: '10px'}}>作者：{item.authorName}</span>
                                                <span>资讯类型：{item.newsType === 1 ? '原创' : '转载'}</span>
                                            </div>
                                            <img src={require("@/assets/img/zixun/icon-4.png")} onClick={() => this.removeNews(item)}/>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="flex-h4">已选<i>{this.state.assignedClasses.length}</i>条资讯</div>
                    </Col>
                </Row>
            </div>
        );
    }
}

DistributionCource = Form.create()(DistributionCource)
export default DistributionCource;
