import React, {Component} from 'react';
import './index.scss'
import {Button, Col, Tooltip, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import {ExclamationCircleFilled} from "@ant-design/icons";
import moment from "moment";
import {practTrainOssImgUrl} from '@/config/secret.js';
import OSS from "ali-oss";
import {getOssToken} from '@/api/ali/index.js'
import SetLimitsModel from "./components/set-limits";
import AddFilesModel from "./components/add-installation-files";
import ReplaceModel from "./components/replace-files";
import {installlist, getValidTimeList} from '@/api/practTrain/index.js'

class HardFile extends Component {
    state = {
        replaceVisible: false,
        addFilesVisible: false,
        limitsVisible: false,  //设置使用限制
        isDrab: false,
        softwareImgUrl: '',
        softList: [], //安装文件管理列表
        softwareId: "", //安装软件id
    }

    async componentDidMount() {
        await this.getOssToken();
        this.softList()
    }

    //安装文件管理列表
    async softList() {
        const softList = await installlist({})
        if (softList) {
            this.setState({
                softList: softList,
            })
        }
    }

    //调整排序
    sort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
        })
    }
    //取消排序
    cancelSort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
        })
    }

    //get oss token
    async getOssToken() {
        let res = await getOssToken();
        if (res) {
            let client = new OSS({
                region: "oss-cn-beijing",
                accessKeyId: res.accessKeyId,
                accessKeySecret: res.keySecret,
                stsToken: res.token,
                bucket: res.bucket,
                secure: true
            });
            this.setState({client})
        }
    }

    //上传图片
    changeLoad = async (info, type) => {
        try {
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            let name = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
            name = `/cvec-front-img/${practTrainOssImgUrl}/${ymd}/${name}`
            let result = await this.state.client.put(name, info);
            console.log('图片上传的地址哈哈哈', result)
            let {url} = result
            this.setState({
                softwareImgUrl: url,
                loading: false
            });
        } catch (e) {
            console.log(e);
        }
    };
    //设置使用限制弹窗
    addressModel = (softwareId) => {
        const {softList} = this.state
        let index = softList.findIndex((v) => {
            return v.softwareId === softwareId;
        });
        this.setState({
            limitsVisible: !this.state.limitsVisible,
            softwareId: softList[index].softwareId,
        })
    }
    //取消弹框
    checkCancel = () => {
        this.setState({
            addFilesVisible: false,
            replaceVisible: false,
        })
    }
    limitsCancel = () => {
        this.setState({
            limitsVisible: !this.state.limitsVisible,
        })
    }
    //刷新设置使用限制弹窗
    oneRefreshData = () => {
        this.setState({
            limitsVisible: false,
        })
    }
    //增加安装文件弹窗
    addFiles = () => {
        this.setState({
            addFilesVisible: !this.state.addFilesVisible,
        })
    }
    //更换文件弹窗
    replaceFiles = () => {
        this.setState({
            replaceVisible: !this.state.replaceVisible,
        })
    }

    addDiv = (data) => {
        const styles = {
            display: 'flex',
            justifyContent: 'space-around'
        }
        return <div className='add_image_div'>
            <div style={{...styles}}>
                <img style={{width: "40px", height: "40px", display: "block"}}
                     src={window.$$img('cvec-manage-front-yunying/practTrain/add_icon.png')} alt=""/>
            </div>
            <span style={{
                color: "#3E78ED",
                fontSize: "12px",
                marginTop: "8px",
                display: 'inline-block'
            }}>Landesk的安装包</span>
        </div>;
    }

    render() {
        const {$$img} = window;
        const {isDrab, softwareImgUrl, softwareId, limitsVisible, addFilesVisible, replaceVisible, softList} = this.state;
        const props = {
            //aspect:160/88,
            resize: true, //裁剪是否可以调整大小
            resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
            modalTitle: "上传图片", //弹窗标题
            modalWidth: 600, //弹窗宽度
            rotate: true,
            width: 160,  //裁剪宽度
            height: 88, //裁剪高度
        };
        const {RenderButton} = React;
        return (
            <div className="hardware-wrap">
                <h2 className={'training-tit'}>安装文件管理</h2>
                <div className="publicBox">
                    <div className="hardware-top">
                        <div className="top" style={{display: isDrab ? 'none' : 'flex'}}>
                            <Col span={12} className="sort_boxs">
                                <div className="sort-cont">
                                    <React.RenderButton text='增加安装文件' id="btn_AddInstallFiles01">
                                        <Button className='dis-btn' type='primary' disabled={true}>增加安装文件</Button>
                                    </React.RenderButton>
                                    <React.RenderButton text='专题排序' id="btn_FilesSort02">
                                        <div className="sort-cur">
                                            <img src={require("@/assets/img/sort.png")} className="sort_img"/>
                                            <span className='sort'>专题排序</span>
                                        </div>
                                    </React.RenderButton>
                                </div>
                            </Col>
                        </div>
                        <div className="top" style={{display: isDrab ? 'flex' : 'none'}}>
                            <Col span={12}>
                                <Button className='sure_sort' type='primary'
                                        onClick={() => this.saveSort()}>保存排序</Button>
                                <Button className='cancel_sort' onClick={() => this.cancelSort()}>取消排序</Button>
                                <ExclamationCircleFilled style={{marginRight: '10px'}} className="sort1_img"/>
                                <span className='save_hyn'>拖拽排序后请保存</span>
                            </Col>
                        </div>
                    </div>
                    <div className="hardware-cont">
                        {softList && softList.length ? softList.map((item, index) => {
                            
                            return (
                                <div className="hardware-item" key={index}>
                                    <div className="item-left">
                                        {item.softwareImgUrl ?
                                            <img src={window.$$img(item.softwareImgUrl)} onError={(e) => {
                                                e.target.src = window.$$uploadRc(item.mediaType?item.softwareImgUrl+'_m.'+item.mediaType:item.softwareImgUrl)
                                            }}></img> :
                                            <img src={$$img('cvec-space-front-guanli/zhihuishixun/empty.png')} alt=""/>}
                                        {/*<ImgCrop {...props}>*/}
                                        {/*    <Upload*/}
                                        {/*        accept='.jpg,.png,.jpeg,.bmp'*/}
                                        {/*        listType="picture-card"*/}
                                        {/*        showUploadList={false}*/}
                                        {/*        beforeUpload={(file) => this.changeLoad(file, 1)}*/}
                                        {/*    >*/}
                                        {/*        {*/}

                                        {/*            softwareImgUrl ? <div className='replace_img'>*/}
                                        {/*                    <img*/}
                                        {/*                        style={{width: "100%", height: '100%'}}*/}
                                        {/*                        src={item.softwareImgUrl} alt="图片"/>*/}
                                        {/*                </div>*/}
                                        {/*                :*/}
                                        {/*                this.addDiv()*/}
                                        {/*        }*/}
                                        {/*    </Upload>*/}
                                        {/*</ImgCrop>*/}
                                        {/* <React.RenderButton text='更换文件' id="btn_FilesChange03"><div className="replace-box" onClick={() => this.replaceFiles()}>*/}
                                        {/*         alt="图片" className="replace-img"/>*/}
                                        {/*    <p className="replace-tit">更换文件</p> </React.RenderButton>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="item-right">
                                        <h3 className="installation-tit">{item.softwareName}</h3>
                                        <p className="installation-info">
                                            <span>版本：{item.softwareVersion}</span>
                                            <span>文件类型：{item.softwareType === 1 ? '.exe' : ''}</span>
                                            <span>文件大小：{item.softwareSize}</span>
                                            <span>更新者：{item.updateUserName}</span>
                                            <span>更新时间：{item.updateTime.substring(0, 10)}</span>
                                        </p>
                                        <p className="installation-common">文件供应商：{item.softwareSupplier}</p>
                                        <p className="installation-common">适用场景：
                                            <Tooltip placement="topLeft" title={item.softwareRange}>
                                                {item.softwareRange}
                                            </Tooltip>
                                        </p>
                                        <p className="installation-common ellipsis">备注说明：{item.softwareExplain}</p>
                                        <div className="btn-box">
                                            <React.RenderButton text='生成获取地址' id="btn_GenerateGetAddress06">
                                                <Button className='sure_sort' type='primary'
                                                        onClick={() => this.addressModel(item.softwareId)}>生成获取地址</Button>
                                            </React.RenderButton>
                                            <React.RenderButton text='编辑信息' id="btn_FilesUpdateInformation05">
                                                <Button className='dis-btn' disabled={true}>编辑信息</Button>
                                            </React.RenderButton>
                                            <React.RenderButton text='删除文件' id="btn_FilesDel04">
                                                <Button className='dis-btn' disabled={true}>删除文件</Button>
                                            </React.RenderButton>

                                        </div>

                                    </div>
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>
                {/*设置使用限制弹窗 start*/}
                <SetLimitsModel
                    limitsVisible={limitsVisible}
                    limitsCancel={this.limitsCancel.bind(this)}
                    oneRefreshData={this.oneRefreshData.bind(this)}
                    softwareId={softwareId}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*设置使用限制弹窗 end*/}
                {/*增加安装文件弹窗 start*/}
                <AddFilesModel
                    addFilesVisible={addFilesVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    oneRefreshData={this.oneRefreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*增加安装文件弹窗 end*/}
                {/*更换文件弹窗 start*/}
                <ReplaceModel
                    replaceVisible={replaceVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    oneRefreshData={this.oneRefreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*更换文件弹窗 end*/}
            </div>
        );
    }
}

export default HardFile;
