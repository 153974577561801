import React from 'react';


//培训管理
export default props => {
    return<svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3105 13.705H2.67007C1.35504 13.705 0.285156 12.6352 0.285156 11.3201V2.67978C0.285156 1.36474 1.35504 0.294922 2.67007 0.294922H11.3105C12.6256 0.294922 13.6954 1.36474 13.6954 2.67978V11.3201C13.6953 12.6352 12.6254 13.705 11.3105 13.705ZM2.67007 1.76964C2.1682 1.76964 1.75988 2.17791 1.75988 2.67978V11.3201C1.75988 11.822 2.1682 12.2303 2.67007 12.2303H11.3105C11.8123 12.2303 12.2207 11.822 12.2207 11.3201L12.2207 2.67978C12.2207 2.17791 11.8124 1.76964 11.3105 1.76964H2.67007Z" fill="#6A7E9C"/>
        <path d="M3.21875 4.24781L4.4269 5.45602L6.62359 3.25928" fill="white"/>
        <path d="M4.42599 6.19339C4.32915 6.19339 4.23326 6.17431 4.1438 6.13725C4.05433 6.10019 3.97305 6.04586 3.90459 5.97737L2.69643 4.76922C2.40845 4.4813 2.40851 4.0144 2.69643 3.72647C2.98436 3.43855 3.45126 3.43855 3.73918 3.72647L4.42593 4.41328L6.10121 2.73794C6.38914 2.45001 6.85604 2.44995 7.14396 2.73794C7.43194 3.02586 7.43194 3.49276 7.14402 3.78074L4.94733 5.97749C4.80905 6.11574 4.62152 6.1934 4.42599 6.19339ZM4.66991 11.2733C3.52145 11.2733 2.58707 10.3389 2.58707 9.19038C2.58707 8.04193 3.52139 7.10754 4.66991 7.10754C5.81842 7.10754 6.75275 8.04193 6.75275 9.19038C6.75275 10.3389 5.81836 11.2733 4.66991 11.2733ZM4.66991 8.58232C4.33461 8.58232 4.06179 8.85509 4.06179 9.19044C4.06179 9.52579 4.33461 9.79861 4.66991 9.79861C5.00526 9.79861 5.27802 9.52579 5.27802 9.19044C5.27802 8.85509 5.0052 8.58232 4.66991 8.58232Z" fill="#BABABA"/>
        <path d="M10.6868 5.9381H8.3272C7.92 5.9381 7.58984 5.60794 7.58984 5.20074C7.58984 4.79354 7.92 4.46338 8.3272 4.46338H10.6868C11.094 4.46338 11.4241 4.79354 11.4241 5.20074C11.4241 5.60794 11.094 5.9381 10.6868 5.9381ZM10.1559 9.92775H8.3272C7.92 9.92775 7.58984 9.59759 7.58984 9.19039C7.58984 8.78319 7.92 8.45303 8.3272 8.45303H10.1559C10.5631 8.45303 10.8932 8.78319 10.8932 9.19039C10.8932 9.59759 10.5631 9.92775 10.1559 9.92775Z" fill="#6A7E9C"/>
    </svg>

}
