import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import {freeze} from '@/api/report/report'
import{ getSubjectList,updateTempHotResources} from '@/api/temporaryProject/temporaryProject'
const {TextArea} = Input;
const {Option} = Select


class SetHot extends React.Component {
  state = {
    saveLoading: false,
    visible: false,
    subJectList:[],
    subJectListValue: '',//资源分类默认值
    seniorId:'', //热门方向id
    ids: [],
  }
  componentDidMount() {
    this.getSubjectList()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.visible){
      this.setState({
        ids: nextProps.ids,
        visible: nextProps.visible
      })
    }
  }

  checkCancel = () => {
    this.props.form.resetFields("hotValue")//清空表单
    this.props.checkCancel()
  }
  //资源分类（热门方向）
  async getSubjectList() {
    const subJectList = await getSubjectList({
      "status": '1',
      "validFlag": '1',
    })
    console.log('subJectList', subJectList)
    this.setState({
      subJectList: subJectList
    })
  }
  //资源分类选择
  subJectListChange = (value) => {
    const subJectList = this.state.subJectList
    const subJectListValue = subJectList[value].name
    const seniorId = subJectList[value].id
    this.setState({
      subJectListValue: subJectListValue,
      seniorId: seniorId
    });
  };
  //设置热门方向
  freezeBatch = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const {ids, seniorId, subJectListValue} = this.state
        if (!ids.length) {
          message.warning('请选择要批量设置学习的方向');
          return;
        }
        this.setState({
          saveLoading: true
        })
        await updateTempHotResources({
          "ids": ids,
          "seniorId": seniorId,
          "seniorName": subJectListValue,
        })
        message.success('设置成功');
        this.props.form.resetFields("hotValue")//清空表单
        this.setState({
          visible: false,
          saveLoading: false,
        })
        this.props.refreshData()
      }
    })
  }

  render() {
    const {saveLoading,subJectList} = this.state;
    const {visible} = this.props
    const formItemLayout = {
      labelCol: {span: 0},
      wrapperCol: {span: 24},
    };
    const {getFieldDecorator} = this.props.form;
    return (
      <Modal
        title='设置热门方向'
        width={'660px'}
        wrapClassName='modal_box'
        visible={visible}
        closable={false}
        footer={
          <div className='modal_btn_j'>
            <Button onClick={() => this.checkCancel()}>取消</Button>
            <Button loading={saveLoading} onClick={() => this.freezeBatch()} type='primary'>保存</Button>
          </div>
        }
      >
        <Form {...formItemLayout}>
          <Form.Item>
            {
              getFieldDecorator('hotValue', {
                rules: [{required: true, message: '请选择学习方向'}]
              })(<Select placeholder="请选择学习方向"  onChange={this.subJectListChange} style={{width: '500px'}}>
                {subJectList && subJectList.length ? subJectList.map((item, index) => {
                  return <Option key={index} value={index}>{item.name}</Option>
                }) : ""}

              </Select>)
            }

          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

SetHot = Form.create()(SetHot)
export default SetHot;
