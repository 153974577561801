
import update from 'immutability-helper';
import { SET_list } from './action_type';



export default {
    //拖拽
    MOVE(state, { data }) {
        let { dragIndex, hoverIndex } = data;
        const dragRow = state.dataSource[dragIndex];
        let res = update(state, {
            dataSource: {
                $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]]
            }
        });
        state.dataSource = res.dataSource;
    },
    SET_active(state, { data }) {
        state.isActive = data;
    },
    //添加专题单矿
    SET_visibily(state, { data }) {
        state.visibily = data;
    },
    SET_drab(state, { data }) {
        state.isDrab = data;
    },
    //全部折叠，全部展开
    CHANGE_all(state, { data }) {
        if (data === 2) {
            state.expandedRowKeys = [];
        } else if (data === 1) {
            state.expandedRowKeys = state.dataSource.map(v => v.id);
        }
    },
    //单选展开收起
    OPEN(state, { data }) {
        const { id: key } = data;
        const { expandedRowKeys } = state;
        if (expandedRowKeys.includes(key)) {
            expandedRowKeys.splice(expandedRowKeys.findIndex(element => element === key), 1);
        } else {
            expandedRowKeys.push(key);
        }
    },
    //列表赋值
    SET_list(state, { data }) {
        state['dataSource'] = data;
    },
    //设置查询专题个数
    set_Count(state, { data }) {
        state['specialCount'] = data;
    }
}