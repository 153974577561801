import React, { Component } from 'react';
import './index.scss';
import { Layout } from 'antd';
import RouterView from '@/router/router_view';
import Menu from '@/components/Menu';
import OBreadcrumb from '@/components/Breadcrumb';
import { connect } from 'react-redux';
const { Sider, Content } = Layout;

const menuList = [
    {
        menuName: '配置管理',
        id: 'sub1',
        icon: 'yingyong_icon.svg',
        children: [
            {
                menuName: '配置首页板块',
                id: '11',
                menuUrl: '/main/craftsman-app/config-manage'
            },
        ]
    },
    {
        menuName: '分类管理',
        id: 'sub2',
        icon: 'yingyong_icon.svg',
        children: [
            {
                menuName: '课程分类',
                id: '21',
                menuUrl: '/main/craftsman-app/classify-manage'
            },
        ]
    },
    {
        menuName: 'APP首页管理',
        id: 'sub3',
        icon: 'yingyong_icon.svg',
        children: [
            {
                menuName: '首页课程管理',
                id: '31',
                menuUrl: '/main/craftsman-app/course-manage'
            },
        ]
    },
    {
        menuName: 'APP发现页管理',
        id: 'sub4',
        icon: 'yingyong_icon.svg',
        children: [
            {
                menuName: '发现首页课程管理',
                id: '41',
                menuUrl: '/main/craftsman-app/discover-manage'
            },
            // {
            //     menuName: '工作室入驻管理',
            //     id: '42',
            //     menuUrl: '/main/craftsman-app/occupancy-manage',
            // },
            // {
            //     menuName: '工作室入驻管理详情',
            //     id: '42',
            //     menuUrl: '/main/craftsman-app/occupancy-manage-detail',
            // }
        ]
    },
]

class index extends Component {
    render() {
        // const { menuList } = this.props;
        let path = this.props.location.pathname;
        let arr = [];
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    <Menu {...this.props} openArr={[]} menuList={menuList} />
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}
index = connect(mapStateToProps)(index);
export default index;