/*eslint eqeqeq: ["off", "smart"]*/
import React, { Component } from "react";
import { getDefaultConfSubjectImg } from "@/api/cover_gallery";
import "./index.scss";

class CoverGallery extends Component {
  state = {
    fileListImg: [],
    fileIdDefult: "",
    fileId: "",
    checkVal: undefined,
    checkId: undefined,
  };
  componentDidMount() {
    this.getDefaultConfSubjectImg();
  }
  //获取课程默认封面
  async getDefaultConfSubjectImg() {
    let fileList = await getDefaultConfSubjectImg();
    if (fileList) {
      this.setState({
        fileListImg: fileList,
      });
    }
  }
  render() {
    let { fileListImg, checkVal } = this.state;
    return (
      <div className="cover_gallery">
        {fileListImg && fileListImg.length
          ? fileListImg.map((item, index) => {
              return (
                <div
                  key={index}
                  className={checkVal == index ? "" : "cover_hover"}
                >
                  <img
                    src={`/api/media/api/v1/media/showThumbnail/${item}`}
                    alt=""
                    key={index}
                    onClick={() => {
                      this.setState(
                        {
                          checkVal: index,
                          checkId: item,
                        },
                        () => {
                          this.props.checkImgId(item);
                        }
                      );
                    }}
                  />
                  {checkVal == index ? (
                    <div>
                      <div>
                        <img
                          key={index}
                          alt=""
                          src={require("@/assets/img/logo.png")}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          : ""}
        <img />
      </div>
    );
  }
}

export default CoverGallery;
