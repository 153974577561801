import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Row, Col, Checkbox, Radio, DatePicker} from 'antd';
import './index.scss';
import {
    deleteAcademyTeacher,
    resetUserPassword,
    selectVirtualOrgStudent,
    seeVirtualOrgStudent,
    saveVirtualOrgStudent,
    updateVirtualOrgStudent, seeAcademyStudent, getClassDetail
} from '../../../../api/platform-school/index'
import {
    checkImportVirtualOrgStudents,
    downTemplate,
    getCitySel,
    getProvinceSel,
    globalSel,
    selectFaculty
} from "../../../../api/platform-business";
import Batch from '@/components/import';
import moment from "moment";
import md5 from "js-md5"
const { Option } = Select;

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };
// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

const Demo = () => {
    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
}
const App = () => {
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>男</Radio>
            <Radio value={2}>女</Radio>
        </Radio.Group>
    );
};

class index extends Component {
    state = {
        dataSource: [],
        isCheck: false, // 查看
        isEdit: false, // 编辑
        isStudent: false, // 新增学生弹层
        isDelete: false,// 删除弹层
        isRepassword: false,// 重置密码
        pageNum: 1,
        pageSize: 10,
        total: '',
        studentNameOrNum: '',
        faculty: '',
        facultyList: [],
        facultyId: '',
        enrollmentYear: '',
        enrollmentYears: '', // 表单回显使用-入学年份
        birthday: '',
        enterpriseId: "",
        isUpdateOrSee: '',
        jobNumLength: 0,
        provinceSel: [], // 省下拉列表
        citySel: [], // 市下拉列表
        politicalDicType: 15, // 政治面貌字典中的类型
        learningLevelDicType: 21, // 学习层次字典值
        learningLevelList: [],
        nationalsList: [],
        nationalsDicType: 20, // 民族字典值
        political: '',
        politicalList: [],
        tableId: '',
        tableUserId: '',
        sex: '',
        userId: '',
        provinceCode: '',
        cityId: '',
        classList: [],
    }

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({
            enterpriseId: jsonparam.enterpriseId
        },() => {
            this.selectVirtualOrgStudent()
            this.getFaculty()
            this.getProvinceSel()
            this.getCitySel()
            this.getPolitical()
            this.getClassDetail()
        })
    }

    async selectVirtualOrgStudent(){
        const param = {
            enrollmentYear: this.state.enrollmentYear,
            enterpriseId: this.state.enterpriseId,
            facultyId: this.state.facultyId,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            studentNameOrNum: this.state.studentNameOrNum
        }
        const virtualOrgStudentTable = await selectVirtualOrgStudent(param)
        console.log('virtualOrgStudentTable', virtualOrgStudentTable)
        this.setState({
            total: virtualOrgStudentTable.total,
            dataSource: virtualOrgStudentTable.data
        })
    }

    //检索
    search = (e) => {
        this.setState({ studentNameOrNum: e }, () => this.selectAcademyStudent());
    }

    // 院系下拉
    getFaculty = async () => {
        let facultyRes = await selectFaculty({
            enterpriseId: this.state.enterpriseId,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        });
        console.log('schoolTypeRes', facultyRes)
        facultyRes && this.setState({ facultyList: facultyRes });
    }
    // 院系下拉框change
    changeFaculty = (e, item) => {
        this.setState({
                facultyId: e
            }, () =>
                this.selectAcademyStudent()
        );
    }

    // 重置密码
    async resetPassword(record){
        let _this = this;
        Modal.confirm({
            title: "温馨提示",
            content: "确定要重置密码吗？",
            okText: "确定",
            cancelText: "关闭",
            centered:true,
            className: "ModalConfirm-pic",
            onOk() {
                resetUserPassword({userId: record.userId}).then((res) => {
                    this.mesSuccess("重置成功");
                    _this.setState({
                        isRepassword: false
                    },()=>{
                        _this.selectVirtualOrgStudent();
                    })
                });
            },
            onCancel() {},
        });
    }

    // 删除
    async deleteAcademyTeacher(record){
        let _this = this;
        Modal.confirm({
            title: "温馨提示",
            content: "确定要删除吗？删除后将无法恢复！",
            okText: "确定",
            cancelText: "关闭",
            centered:true,
            className: "ModalConfirm-pic",
            onOk() {
                deleteAcademyTeacher({userId: record.userId}).then((res) => {
                    this.mesSuccess("删除成功");
                    _this.setState({
                        isDelete: false
                    },()=>{
                        _this.selectVirtualOrgStudent();
                    })
                });
            },
            onCancel() {},
        });
    }

    // 行政班级下拉
    getClassDetail = async () => {
        let classList = await getClassDetail({
            enterpriseId: this.state.enterpriseId,
            pageNum: 1,
            pageSize: '',
        });
        classList && this.setState({ classList: classList });
    }

    // 行政班级下拉框change
    changeClass = (e, item) => {
        this.setState({
                facultyId: e
            }, () =>
                this.selectVirtualOrgStudent()
        );
    }


    enrollmentYear = (e) => {
        let enrollmentYear = moment(e).format('YYYY-MM-DD')
        this.setState({
            enrollmentYear: enrollmentYear
        }, () => {
            this.selectVirtualOrgStudent();
        })
    }


    async toStudentresult(){
        this.props.history.push("/main/platform-business/vir-studentresult")
    }
    async toStudentrecord(){
        this.props.history.push(`/main/platform-business/teacher-record?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId,jumpType:5}))}`);
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.selectAcademyStudent());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.selectAcademyStudent());
        window.elGoTop && window.elGoTop('body');
    }
// 导入成员弹窗取消
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    getFormData=(formData)=>{
        console.log("1111111111111",formData.get("file"))
        formData.set("enterpriseId",this.state.enterpriseId)
        this.setState({
            batchRef: formData
        },()=>{
            console.log("batchRef",this.state.batchRef)
        })
    }
    //导入时得确定按钮
    commitBatch = () => {
        //从子组件调用导入接口，更改为点击确定后上传了
        console.log('this.state.batchRef)', this.state.batchRef)
        checkImportVirtualOrgStudents(this.state.batchRef).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('上传成功');
                this.virTeacherresult(res)
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    sure = (e) => {
        this.setState({ btnActive: true, fileId: e });
    }
    async virTeacherresult(res){
        console.log("11111",res)
        let transParam = {
            recordId: res,
            enterpriseId:this.state.enterpriseId
        }
        let param = JSON.stringify(transParam);
        this.props.history.push(`/main/platform-business/vir-studentresult?${encodeURI(param)}`)
    }
    saveStudent = () => {
        this.props.form.validateFields((err, value) => {
            if(!err){
                console.log("1111111111111111111111111111111111")
                if (this.state.isUpdateOrSee !== 1){
                    let addVirtualOrgStudentQo = {
                        birthdays: new Date(value.birthday),
                        cardType: value.cardType,
                        classId: value.classId,
                        className: value.className,
                        email: value.email,
                        enrollmentYears: moment(value.enrollmentYear).format('YYYY-MM-DD HH:MM:ss'),
                        enterpriseId: this.state.enterpriseId,
                        nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                        facultyId: value.facultyId,
                        facultyName: value.facultyName,
                        idCard: value.idCard,
                        jobNum: value.jobNum,
                        learningLevel: value.learningLevel,
                        majorId: value.majorId,
                        nationals: value.nationals,
                        password: md5(value.password),
                        politicalLandscape: value.politicalLandscape,
                        sex: value.sex,
                        studentName: value.studentName,
                        telNum: value.telNum,
                        userId: this.state.userId
                    }
                    saveVirtualOrgStudent(addVirtualOrgStudentQo).then (res => {
                        if (res) {
                            this.setState({isStudent: false})
                            this.mesSuccess("新增成功")
                            this.selectVirtualOrgStudent()
                        }
                    })
                } else {
                    let addVirtualOrgStudentQo = {
                        id: this.state.tableId,
                        birthdays: new Date(value.birthday),
                        cardType: value.cardType,
                        classId: value.classId,
                        className: value.className,
                        email: value.email,
                        enrollmentYears: moment(value.enrollmentYear).format('YYYY-MM-DD HH:MM:ss'),
                        enterpriseId: this.state.enterpriseId,
                        facultyId: value.facultyId,
                        facultyName: value.facultyName,
                        idCard: value.idCard,
                        jobNum: value.jobNum,
                        learningLevel: value.learningLevel,
                        majorId: value.majorId,
                        nationals: value.nationals,
                        nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                        password: md5(value.password),
                        politicalLandscape: value.politicalLandscape,
                        sex: value.sex,
                        studentName: value.studentName,
                        telNum: value.telNum,
                        adminUserId: this.state.tableUserId, // 当前行，人的信息的用户Id
                        userId: this.state.userId
                    }
                    updateVirtualOrgStudent(addVirtualOrgStudentQo).then (res => {
                        if (res) {
                            this.setState({isStudent: false})
                            this.mesSuccess("修改成功")
                            this.selectVirtualOrgStudent()
                        }
                    })
                }
            } else {
                console.log('错误信息：：：', err)
            }
        });

    }
    // 编辑按钮
     update =(record)=>{
        console.log('列表的值：：：：：：', record)
         this.setState({
             isStudent: true,
             isUpdateOrSee: 1,
             tableId: record.id,
             tableUserId: record.userId
         }, () => {
            this.seeVirtualOrgStudent(record, 1)
         })
     }

     seeVirtualOrgStudent = (record,type) => {
         this.setState({
             isStudent: true,
             isUpdateOrSee: type ? type :2
         })
        const param = {
            enterpriseId: this.state.enterpriseId,
            userId: record.userId
        }
         seeVirtualOrgStudent(param).then((res) => {
             console.log('res ===>>>>', res)
             if(res.province){
                 this.setState({
                     provinceCode: res.province
                 },() => {
                     this.getCitySel()
                 })
             }
             if(res){
                 this.setState({
                     birthday: res.birthday,
                     enrollmentYears: res.enrollmentYear,
                     sex: res.sex,
                     jobNumLength: res.jobNum.length
                 })
                 this.props.form.setFieldsValue({
                     jobNum: res.jobNum,
                     studentName: res.studentName,
                     facultyId: res.facultyId ? res.facultyId : '',
                     classId: res.classId ? res.classId : '',
                     telNum: res.telNum,
                     email: res.email,
                     sex: res.sex,
                     // password: res.passwords,
                     politicalLandscape: res.politicalLandscape ? Number(res.politicalLandscape) : '',
                     learningLevel: res.learningLevel ? Number(res.learningLevel) : '',
                     cardType: res.cardType ?res.cardType : '',
                     idCard: res.idCard,
                     nationals: res.nationals ? Number(res.nationals) : '',
                     province: res.province ? res.province : '',
                     city: res.city ? res.city : '',
                 })
             }
         })
     }

    // 工号输入框长度
    jobNumValueLength =  (rule, value, callback) => {
        if(value&&value.length === 0){
            this.setState({
                jobNumLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                jobNumLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号')
        } else {
            callback();
        }
    }

    // 校验邮箱格式
    validateEmail =  (rule, value, callback) => {
        let emailReg =  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (value && !emailReg.test(value)){
            callback('请输入正确的电子邮箱')
        } else {
            callback();
        }
    };

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        this.setState({ provinceCode: e, citySel: [] }, () =>
            this.getCitySel()
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        this.setState({
            cityId: e
        });
    }

    // 政治面貌下拉
    getPolitical = async () => {
        if (this.state.politicalDicType) {
            let politicalRes = await globalSel({ type: this.state.politicalDicType });
            politicalRes && this.setState({ politicalList: politicalRes });
        }
        // 学习层次
        if (this.state.learningLevelDicType) {
            let learningLevelRes = await globalSel({ type: this.state.learningLevelDicType });
            learningLevelRes && this.setState({ learningLevelList: learningLevelRes });
        }
        // 民族
        if (this.state.nationalsDicType) {
            let nationalsRes = await globalSel({ type: this.state.nationalsDicType });
            nationalsRes && this.setState({ nationalsList: nationalsRes });
        }
    }

    // 政治面貌下拉框change
    changePolitical = (e, item) => {
        this.setState({ political: e}, () => this.selectAcademyStudent());
    }


    render() {
        let {facultyResOption,jobNumLength,isUpdateOrSee,total,pageSize,isStudent,isCheck,isEdit,isDelete,isRepassword,
            dataSource,facultyList,enrollmentYears,birthday,provinceSel,citySel,politicalList,sex} = this.state;
        let {batch,btnActive,errorMessage,classList,learningLevelList,nationalsList} = this.state
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center',width: 80, render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '工号/帐号', dataIndex: 'jobNum',key:'jobNum', },
            {title: '姓名', dataIndex: 'studentName',key:'studentName',width:100, },
            {title: '院系', dataIndex: 'facultyName',key:'facultyName'},
            // {title: '专业', dataIndex: 'majorName',key:'majorName'},
            {title: '行政班级', dataIndex: 'className',key:'className'},
            {title: '入学年份', dataIndex: 'enrollmentYear', key: 'enrollmentYear', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
            {title: '手机号', dataIndex: 'telNum',key:'telNum'},
            {title: '性别', align: 'center',width: 80, render: (text, item, index ) =>  <span className='serial'>{item.sex === 2 ? '女' : item.sex === 1 ? '男' : ''}</span>,},
            {title: '所属层次', dataIndex: 'learningLevel',key:'learningLevel'},
            {title: '出生日期', dataIndex: 'birthday', key: 'birthday', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},

            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:260,
                render:(text, record)=><div className="opspan">
                    <div className="main">
                        <span className="but" onClick={() => this.seeVirtualOrgStudent(record)}>查看</span>
                        <span className="but" onClick={() => this.update(record)}>编辑</span></div>
                    <div className="main">
                        <span className="but" onClick={() => this.deleteAcademyTeacher(record)}>删除</span>
                        <span className="but" onClick={() => this.resetPassword(record)}>重置密码</span></div>
                </div>},
        ];
        return (
            <div className='managestu_box_five'>
                <div className="onelist">
                    <div className="one">
                        <div>
                            <span style={{marginRight: '20px'}}>院系</span>
                            <Select placeholder='全部'
                                    onChange={this.changeFaculty}>
                                <Option value={''}>全部</Option>
                                {
                                    facultyList.map(v => <Option key={v.id} value={v.id}>{v.facultyName}</Option>)
                                }
                            </Select>
                        </div>
                        {/*<div>*/}
                        {/*    <span style={{marginRight: '20px'}}>专业</span>*/}
                        {/*    <Select placeholder='全部'>*/}
                        {/*        <Option value={''}>全部</Option>*/}
                        {/*    </Select>*/}
                        {/*</div>*/}
                        <div className='inschool_time'>
                            <span className='add_school_time_title'>入学年份</span>
                            <DatePicker onChange={this.enrollmentYear} placeholder='请选择入学年份' style={{width: '100%'}} />
                        </div>
                    </div>
                </div>
                <div className="twolist">
                    <div className="top_list">
                        <div className="leftlist">
                            <Button className="buttonl" type="primary" style={{marginRight: '20px'}} onClick={() => this.setState({ batch: true })}>导入学生</Button>
                            <Button className="buttonm" style={{marginRight: '20px'}} onClick={() => this.setState({ isStudent: true,isUpdateOrSee: 3 })}>新增学生</Button>

                            <Modal
                                title={isUpdateOrSee === 1 ? '编辑学生信息':
                                    isUpdateOrSee === 2 ? '查看学生信息'
                                    : '新增学生信息'}
                                visible={isStudent}
                                destroyOnClose={true}
                                closable={false}
                                className='binding_modal binding_modal_stu'
                                footer={
                                    <div className='foot_botton'>
                                        {isUpdateOrSee === 1 ? <Button className="addbutton" onClick={() => this.saveStudent()}>确认修改</Button> :
                                            isUpdateOrSee === 2 ? '':
                                            <Button className="addbutton" onClick={() => this.saveStudent()}>确认添加</Button>}
                                        <Button className="delete" onClick={() => this.setState({ isStudent: false })}>取消</Button>
                                    </div>
                                }
                            >
                                <div className="modaltitle">
                                    个人信息
                                </div>
                                <Form className='lt_form_wapper'>
                                    <Form.Item label={`学号/工号`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">学号/工号</span>*/}
                                            {/*</div>*/}
                                            {getFieldDecorator('jobNum', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请填写学号/工号`,
                                                    },
                                                    {
                                                        max: 20,
                                                        message: "文本过长，最多20字"
                                                    },
                                                    {
                                                        validator:this.jobNumValueLength
                                                    }
                                                ],
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请填写学号/工号"/>)}
                                            <span className="limited">{jobNumLength}/20</span>
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={'姓名'}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className='red'>*</span><span className="title">姓名</span>*/}
                                            {/*</div>*/}
                                            {getFieldDecorator('studentName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请输入姓名`,
                                                    },
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} />)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`院系`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className='red'>*</span><span className="title">院系</span></div>*/}
                                            {
                                                getFieldDecorator('facultyId', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择院系`,
                                                        },
                                                    ],
                                                    initialValue: undefined,
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择院系' style={{ width: 330 , marginTop:"4px"}}
                                                    >
                                                        {/*<Option value={''}>全部</Option>*/}
                                                        {
                                                            facultyList.map(v => <Option key={v.id} value={v.id}>{v.facultyName}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`行政班级`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className='red'>*</span><span className="title">行政班级</span></div>*/}
                                            {
                                                getFieldDecorator('classId', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择行政班级`,
                                                        },
                                                    ],
                                                    initialValue: undefined,
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择行政班级'>
                                                        {
                                                            classList.map(v => <Option key={v.id} value={v.id}>{v.className}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`入学年份`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">入学年份</span></div>*/}
                                            {getFieldDecorator('enrollmentYear', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择入学年份`,
                                                    },
                                                ],
                                                initialValue: enrollmentYears? moment(enrollmentYears, 'YYYY-MM-DD') : null
                                            })(<DatePicker disabled={isUpdateOrSee === 2} placeholder='请选择入学年份' style={{width: '100%'}} />)}
                                        </div>
                                    </Form.Item>
                                    <div className="modaltitle">
                                        机构信息
                                    </div>
                                    <Form.Item label={`电话`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className='red'>*</span><span className="title">电话</span>*/}
                                            {/*</div>*/}
                                            {getFieldDecorator('telNum', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请输入电话`,
                                                    },
                                                    {
                                                        validator:this.validatePhone
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入电话" style={{ width: 330 , marginTop:"4px"}} />)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`邮箱`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">邮箱</span>*/}
                                            {/*</div>*/}
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: `请填写电子邮箱`,
                                                    },
                                                    {
                                                        validator:this.validateEmail
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                            })(<Input disabled={isUpdateOrSee === 2} placeholder="请填写电子邮箱" className="input" maxLength={100}/>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item className="sex" label={`性别`}>
                                        <div className="all">
                                            {/*<div className="totle"><span className="title">性别</span></div>*/}
                                            {getFieldDecorator('sex', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: `请选择性别`,
                                                    },
                                                ],
                                                initialValue: sex? sex : ""
                                            })(
                                                <Radio.Group value={sex? sex : ""} disabled={isUpdateOrSee === 2}>
                                                <Radio value={1}>男</Radio>
                                                <Radio value={2}>女</Radio>
                                            </Radio.Group>)}

                                    </div>
                                </Form.Item>
                                    {
                                        isUpdateOrSee === 3 ?
                                            <Form.Item>
                                                <div className="all">
                                                    <div className="totle">
                                                        <span className='red'>*</span><span className="title">密码</span>
                                                    </div>
                                                    {getFieldDecorator('password', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `请输入密码`,
                                                            },
                                                        ],
                                                    })( <Input disabled={isUpdateOrSee === 2} placeholder="请输入密码" style={{ width: 330 , marginTop:"4px"}}/>)}
                                                </div>
                                            </Form.Item>
                                            : ''
                                    }
                                <Form.Item className='date_input'>
                                    <div className="all">
                                        <div className="totle">
                                            <span className="title">出生日期</span>
                                        </div>
                                        {getFieldDecorator('birthday', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请选择出生日期`,
                                                },
                                            ],
                                            initialValue: birthday? moment(birthday, 'YYYY-MM-DD') : null
                                        })(<DatePicker disabled={isUpdateOrSee === 2} placeholder='请选择出生日期' style={{width: '100%'}} />)}
                                    </div>
                                </Form.Item>
                                <Form.Item className='date_input'>
                                    <div className="all">
                                        <div className="totle">
                                            <span className="title">籍贯</span></div>
                                        {getFieldDecorator('province', {
                                        })(
                                            <Select placeholder='请选择省'
                                                    disabled={isUpdateOrSee === 2}
                                                    onChange={this.changeProvince}
                                            >
                                                {/*<Option value={''}>全部</Option>*/}
                                                {
                                                    provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                                }
                                            </Select>
                                        )}
                                        {getFieldDecorator('city', {
                                        })(
                                            <Select placeholder='请选择市'
                                                    disabled={isUpdateOrSee === 2}
                                                    onChange={this.changeCity}
                                            >
                                                {/*<Option value={''}>全部</Option>*/}
                                                {
                                                    citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                                }
                                            </Select>)}
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <div className="all">
                                        <div className="totle">
                                            <span className="title">政治面貌</span></div>
                                        {
                                            getFieldDecorator('politicalLandscape', {
                                                initialValue: undefined,
                                            })(
                                                <Select placeholder='请选择政治面貌' style={{ width: 330 , marginTop:"4px"}}
                                                        disabled={isUpdateOrSee === 2}
                                                    // onChange={this.political}
                                                >
                                                    {
                                                        politicalList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                                    }
                                                </Select>
                                            )
                                        }
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <div className="all">
                                        <div className="totle">
                                            <span className="title">学习层次</span></div>
                                        {
                                            getFieldDecorator('learningLevel', {
                                                initialValue: undefined,
                                            })(
                                                <Select placeholder='请选择学习层次' disabled={isUpdateOrSee === 2}>
                                                    {
                                                        learningLevelList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                                    }
                                                </Select>
                                            )
                                        }
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <div className="all">
                                        <div className="totle">
                                            <span className="title">证件类型</span> </div>
                                        {
                                            getFieldDecorator('cardType', {
                                                initialValue: undefined,
                                            })(
                                                <Select placeholder='请选择证件类型' disabled={isUpdateOrSee === 2}>
                                                    <Option value={0}>身份证</Option>
                                                    <Option value={1}>护照</Option>
                                                </Select>
                                            )
                                        }
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <div className="totle">
                                        <span className="title">证件号码</span></div>
                                    {getFieldDecorator('idCard', {
                                        rules: [
                                            {
                                                required: true,
                                                message: `请输入证件号码`,
                                            },
                                        ],
                                    })(<Input disabled={isUpdateOrSee === 2} placeholder="请输入证件号" style={{ width: 330 , marginTop:"4px"}}/>)}
                                </Form.Item>
                                <Form.Item>
                                    <div className="all">
                                        <div className="totle">
                                            <span className="title">民族</span></div>
                                        {
                                            getFieldDecorator('nationals', {
                                                initialValue: undefined,
                                            })(
                                                <Select placeholder='请选择民族' disabled={isUpdateOrSee === 2}>
                                                    {
                                                        nationalsList.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                                    }
                                                </Select>
                                            )
                                        }
                                    </div>
                                </Form.Item>
                                </Form>
                            </Modal>

                            <Button className="buttonr" onClick={() =>{this.toStudentrecord()}}><img src={require('@/assets/img/lead.png')} alt="" />导入记录</Button>
                        </div>
                        <div className="right_list">
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入教师工号/名字' />
                        </div>
                    </div>
                    <div className="tablelist">
                        <Table rowKey={v => v['enterpriseId']} pagination={
                            {
                                total,
                                pageSize:pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['5', '10', '20', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns} dataSource={dataSource} scroll={{ x: 2000}} />
                    </div>
                </div>
                <Modal className='batch' visible={batch} title='学校信息导入'
                       closable={false}
                       footer={
                           <div style={{ width: "100%" }}>
                               <div className='pos-btn'>
                                   <Button onClick={this.closeBatch}>取消</Button>
                                   <Button disabled={btnActive ? false : true} className={btnActive ? "" : "gray"}
                                           onClick={this.commitBatch}>确定</Button>
                               </div>
                               {errorMessage ?
                                   <div className='error'>
                                       <span>请下载查看</span>
                                       <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                   </div>
                                   : null}
                           </div>
                       }
                >
                    <Batch
                        ref={ref => this.batchRef = ref}
                        getFormData={this.getFormData}
                        sure={this.sure}
                        reset={() => this.setState({ errorMessage: '', btnActive: false })}
                        template={downTemplate(3)}
                        pullError={(val) => this.setState(val)}
                        titleType='虚拟机构学生'
                    />
                </Modal>
            </div>
        )

    }
}

index = Form.create()(index);
export default index;
