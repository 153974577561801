
import reducers from './reducers';
let defaultState = {
    dataSource: [],
    visibily: false,
    isActive: true,
    expandedRowKeys: [],
    isDrab: false,
    specialCount: 0,
    pageNum:1,
    pageSize:5
}
const Special = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action);
    return Newstate
}

export default Special
