import React from 'react';
import { Button, Form, Input, message, Modal, Radio, Select, TreeSelect, Checkbox } from "antd";
import { powerdic } from '@/api/powerdic';
import '../Menu.scss';
import { addMenu, upMenuList, jumpWay, getMenuDetail, editMenu } from '@/api/menu'
const { SHOW_PARENT, TreeNode } = TreeSelect;
const { Option } = Select;
class NewAdd extends React.Component {
  state = {
    platform: {},
    type: "",
    jumpWayData: [],//跳转方式
    visible: false,
    treeSelectStatus: false,
    statusList: [],  //字典表 菜单类型
    jumpWayTypeValue: '',
    superiorValue: "",  //上级菜单选中值
    addMenuItem: {
      correspond: '',
      id: '',
      jumpWay: '',
      menuCode: '',
      menuCodeName: '',
      menuName: '',
      parentId: undefined,
      path: '',
      platform: '',
      platformName: '',
      addMenuItem: undefined
    },
    treeData: []
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { platform, addMenuItem } = this.state
    
    if ((nextProps.platform && platform.id !== nextProps.platform.id) || (nextProps.platform && addMenuItem.menuCode === '')) {
      // console.log('nextProps.platform===>',nextProps.platform)
      let menuCode = ''
      if (nextProps.type === 'new') {
        menuCode = nextProps.platform.menuCodeName === '系统' ? 80 : nextProps.platform.menuCodeName === '模块' ? 81 : nextProps.platform.menuCodeName === '页面' ? 82 : ''
      }
      console.log(nextProps.platform)
      let addMenuItem_new = {
        ...addMenuItem,
        parentId: nextProps.platform.parentId === '0' ? undefined : nextProps.platform.parentId,
        menuCode: menuCode,
        platform: nextProps.platform.platform,
        platformName: nextProps.platform.platformName,
        associatedTerminal:[0]
      }
      // console.log(addMenuItem_new,'addMenuItem_new--------------')
      this.setState({
        platform: nextProps.platform,
        treeSelectStatus: nextProps.type === 'edit',
        addMenuItem: addMenuItem_new,
        type: nextProps.type,
        visible: nextProps.visible
      }, () => this.getUpMenuList(),
      )
      if (nextProps.type === 'edit') {
        // console.log('000000000000000')
        this.getInfo(nextProps.platform.id)
      }
    }
  }
  componentDidMount() {
    this.getPowerdic()
    this.jumpWay()

  }
  // 字典表查询 接口类型：1-院校入驻方式，2-办学方式，3-办学层次，4-办学类型，5-办学类型,6-管理部门，7-权限身份，8-菜单类型
  getPowerdic() {
    powerdic({ type: 8 }).then(res => {
      if (res) {
        let addMenuItem = this.state.addMenuItem
        addMenuItem.menuCode = res[0].code;
        addMenuItem.associatedTerminal = [0];
        this.setState({
          statusList: res,
          addMenuItem: addMenuItem
        })
      }
    })
  }

  //菜单类型
  onChangeMenuCodeName = (e) => {
    // console.log('radio checked', e.target.value);
    let { addMenuItem } = this.state
    if(e.target.value===82){
      addMenuItem.associatedTerminal = [];
    }else{
      addMenuItem.associatedTerminal = [0];
    }
    let addMenuItem_Check = {
      ...addMenuItem,
      correspond: '',
      jumpWay: '',
      menuName: '',
      path: '',
      menuCode: e.target.value,
    }
    console.log(addMenuItem_Check)
    this.setState({
      addMenuItem: addMenuItem_Check
    })
  }
  //编辑详情
  async getInfo(id) {
    let info = await getMenuDetail({ menuId: id });
    if (info) {
      this.setState({
        addMenuItem: {
          ...this.state.addMenuItem, ...info,
          associatedTerminal:[info.associatedTerminal],
          parentId: info.parentId === '0' ? undefined : info.parentId,

        },
      },()=>{
        console.log(this.state.addMenuItem);
      })
    }
  }
  //取消
  checkCancel = () => {
    this.setState({
      treeSelectStatus: false
    },()=>{
      this.props.checkCancel()
      this.props.form.resetFields();
      let addMenuItem = {
        correspond: '',
        jumpWay: '',
        menuCode: '',
        menuCodeName: '',
        menuName: '',
        path: '',
        platform: '',
        platformName: '',
        associatedTerminal:[0]
      }
      this.setState({addMenuItem})
    })
  }
  onChangeCorrespond = (rule, value, callback) => {
    let pattern = /^[0-9a-zA-Z_|-]{1,}$/;
    if (value && !pattern.test(value)) {
      callback('请输入正确对应字符')
    } else {
      callback();
    }
  }
  //保存
  menuConfirm = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (values.id) {
        } else {
          const menuCode = values.menuCode
          const statusList = this.state.statusList
          let menuCodeName = ''
          statusList.forEach(e => {
            if (e.code === menuCode) {
              menuCodeName = e.name
            }
          })

          //编辑修改
          if (this.state.type === 'edit') {
            const params = {
              ...values,
              parentId: this.state.addMenuItem.parentId === undefined ? '0' : this.state.addMenuItem.parentId,
              id: this.state.addMenuItem.id,
              menuCodeName: menuCodeName,
              platform: this.state.addMenuItem.platform,
              platformName: this.state.addMenuItem.platformName,
            }
            // console.log('--1---',params)
            editMenu(params).then((res) => {
              if (res && res.code === 0) {
                message.success("修改成功")
                this.props.systemList()
                this.checkCancel();
              }
            })
          } else {
            //添加
            const params = {
              ...values,
              parentId: this.state.addMenuItem.parentId === undefined ? '0' : this.state.addMenuItem.parentId,
              menuCodeName: menuCodeName,
              platform: this.state.addMenuItem.platform,
              platformName: this.state.addMenuItem.platformName
            }
            addMenu(params).then((res) => {
              // console.log('reswwww',res)
              if (res && res.code === 0) {
                message.success("保存成功")
                this.checkCancel()
                this.props.systemList()
              }
            });
          }

        }
      }
    })
  }
  //上级菜单
  onTreeChange = value => {
    let { addMenuItem } = this.state
    addMenuItem = {
      ...addMenuItem,
      parentId: value
    }
    this.setState({
      addMenuItem: addMenuItem
    })
  }
  //上级菜单列表
  async getUpMenuList() {
    let getUpMenuListData = await upMenuList({ platform: this.state.addMenuItem.platform });
    if (getUpMenuListData) {
      this.setState({
        treeData: getUpMenuListData
      })
    }
  }
  //跳转方式
  async jumpWay() {
    let jumpWayData = await jumpWay();
    this.setState({
      jumpWayData: jumpWayData
    })

  }
  //按钮跳转方式
  onChangeonChangeJumpWay = (e) => {
    this.setState({
      jumpWayTypeValue: e.target.value,
    })
  }
  //递归方法遍历树形控件
  renderTree = (data, idx) => {
    return data.map((item, idx) => {
      if (!item.childList) {
        return (
          <TreeNode title={item.menuName} value={item.id} key={item.id} />
        )
      } else {
        return (
          <TreeNode title={item.menuName} value={item.id} key={item.id}>
            {this.renderTree(item.childList)}
          </TreeNode>
        )
      }
    })
  };
  render() {

    const { jumpWayTypeValue, jumpWayData, treeSelectStatus, addMenuItem, statusList, treeData } = this.state;
    const { visibly } = this.props
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
    }
    const { getFieldDecorator } = this.props.form;
    let flag = addMenuItem.menuCode === 80 || addMenuItem.menuCode === 81;
    return (
      <Modal
        title={this.state.type == 'edit' ? "编辑菜单" : "新增菜单"}
        wrapClassName='modal_box'
        visible={visibly}
        closable={false}
        className="add_menu"
        footer={
          <div className='modal_btn_j'>
            <Button onClick={() => this.checkCancel()}>取消</Button>
            <Button onClick={() => this.menuConfirm()} type='primary'>确定</Button>
          </div>
        }
      >

        <Form
          {...formItemLayout}
        >
          {addMenuItem.parentId === undefined ?
            <Form.Item label="上级菜单">
              {
                getFieldDecorator('parentId', {
                  initialValue: addMenuItem.platformName,
                })(
                  <Select disabled >
                    <Option value={addMenuItem.platformName}>{addMenuItem.platformName}</Option>
                  </Select>)
              }
            </Form.Item>
            : <Form.Item label="上级菜单">
              {
                getFieldDecorator('parentId', {
                  initialValue: addMenuItem.parentId,
                  rules: [
                    {
                      required: true,
                      message: '请选择上级菜单'
                    }
                  ]
                })(
                  <TreeSelect
                    bordered={true}
                    disabled={treeSelectStatus}
                    placeholder='请选择菜单'
                    onChange={e => this.onTreeChange(e)}
                    showCheckedStrategy={SHOW_PARENT}
                    treeDefaultExpandAll
                    style={{ width: '100%' }}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                  >
                    {this.renderTree(treeData)}
                  </TreeSelect>

                )
              }
            </Form.Item>}
          <Form.Item label="菜单类型">
            {
              getFieldDecorator('menuCode', {
                initialValue: addMenuItem.menuCode,
                rules: [
                  {
                    required: true,
                    message: '请选择菜单类型'
                  }
                ]
              })(<Radio.Group onChange={this.onChangeMenuCodeName}>
                {
                  statusList.map(v => {
                    return <Radio value={v.code} key={v.code}>{v.name}</Radio>
                  })
                }
              </Radio.Group>)
            }
          </Form.Item>
          {addMenuItem.menuCode === 82 ? <Form.Item label="按钮跳转方式">
            {getFieldDecorator('jumpWay', {
              initialValue: addMenuItem.jumpWay,
              rules: [
                { required: true, message: '请选择跳转方式' }
              ]
            })
              (<Radio.Group onChange={this.onChangeonChangeJumpWay}>
                {jumpWayData && jumpWayData.length ? jumpWayData.map((item, index) =>
                  <Radio value={item.code} key={index}>{item.msg}</Radio>
                ) : ""}

              </Radio.Group>)}
          </Form.Item> : ""}
          <Form.Item label="菜单名称">
            {
              getFieldDecorator('menuName', {
                initialValue: addMenuItem.menuName,
                rules: [
                  {
                    required: true,
                    message: '请输入菜单名称'
                  }
                ]
              })(<Input
                type="text"
                placeholder="请输入菜单名称"
                maxLength={20}
                className="input"
              />)
            }
          </Form.Item>
          <Form.Item label={
            <>
              {addMenuItem.menuCode === 80 ? "模块地址" : addMenuItem.menuCode === 81 ? "页面地址" : addMenuItem.menuCode === 82 ? "按钮地址" : ""}
            </>
          }>
            {
              getFieldDecorator('path', {
                initialValue: addMenuItem.path,
                rules: [
                  jumpWayTypeValue !== 0 ? {
                    required: true,
                    message: <span>{`请输入${addMenuItem.menuCode === 80 ? "模块" : ""}${addMenuItem.menuCode === 81 ? "页面" : ""}${addMenuItem.menuCode === 82 ? "按钮" : ""}地址`}</span>
                  } : ''
                ]
              })(<Input
                type="text"
                placeholder={`请输入${addMenuItem.menuCode === 80 ? "模块" : ""}${addMenuItem.menuCode === 81 ? "页面" : ""}${addMenuItem.menuCode === 82 ? "按钮" : ""}地址`}
                maxLength={200}
                className="input"
              />)
            }
          </Form.Item>
          <Form.Item label="对应字符">
            {
              getFieldDecorator('correspond', {
                initialValue: addMenuItem.correspond,
                rules: [
                  {
                    required: true,
                    message: '请输入对应字符'
                  }, {
                    validator: this.onChangeCorrespond
                  }
                ]
              })(<Input
                type="text"
                placeholder="请输入对应字符"
                maxLength={30}
                className="input"
              />)
            }
          </Form.Item>
          <Form.Item label="管理终端">
            {
              getFieldDecorator('associatedTerminal', {
                initialValue: addMenuItem.associatedTerminal,
                rules: [
                  {
                    required: true,
                    message: '请选择终端'
                  }
                ]
              })(
                <Checkbox.Group>
                  <Checkbox disabled={flag} value={0}>Web端</Checkbox>
                  {
                    flag ? null : <Checkbox value={1}>App端</Checkbox>
                  }
                </Checkbox.Group>
              )
            }
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

NewAdd = Form.create()(NewAdd)
export default NewAdd;
