import { result } from './result'

export default [
    {
        name: '机构入驻',
        path: '/main/platform-business',
        component: result['Platform-business'],
        children: [
            {
                path: '/main/platform-business',
                redirect: '/main/platform-business/in-school'
            },
            {
                name: '机构成员管理',
                path: '/main/platform-business/in-school',
                component: result['InSchool']
            },
            {
                name: '添加院校入驻',
                path: '/main/platform-business/add-school',
                component: result['AddSchool']
            },
            {
                name: '学校详情',
                path: '/main/platform-business/detil-school',
                component: result['DetilSchool']
            },
            {
                name: '管理机构管理',
                path: '/main/platform-business/management_organization',
                component: result['ManagementOrganization']
            },
            {
                name: '创建管理机构',
                path: '/main/platform-business/add-management',
                component: result['AddManagement']
            },
            {
                name: '机构管理详情',
                path: '/main/platform-business/detil-management',
                component: result['DetilManagement']
            },
            {
                name: '企业入驻管理',
                path: '/main/platform-business/enterprise_settlement',
                component: result['EnterpriseSettlement']
            },
            {
                name: '创建虚拟机构',
                path: '/main/platform-business/add-enterprise',
                component: result['AddEnterprise']
            },
            {
                name: '虚拟机构详情',
                path: '/main/platform-business/detil-enterprise',
                component: result['DetilEnterprise']
            },
            {
                name: '教师管理',
                path: '/main/platform-business/tea-manage',
                component: result['TeaManage']
            },
            {
                name: '学生管理',
                path: '/main/platform-business/stu-manage',
                component: result['StuManage']
            },
            {
                name: '虚拟机构教师管理',
                path: '/main/platform-business/vir-teamanage',
                component: result['VirTeamanage']
            },
            {
                name: '虚拟机构学生管理',
                path: '/main/platform-business/vir-stumanage',
                component: result['VirStumanage']
            },
            {
                name: '学校成员管理',
                path: '/main/platform-business/student-list',
                component: result['StudentList']
            },
            {
                name: '虚拟机构成员管理',
                path: '/main/platform-business/virtual-organization',
                component: result['VirtualOrganization']
            },
            {
                name: '管理机构成员管理',
                path: '/main/platform-business/management-institutions',
                component: result['ManagementInstitutions']
            },
            {
                name: '教师导入记录',
                path: '/main/platform-business/teacher-record',
                component: result['TeacherRecord']
            },
            {
                name: '教师导入结果',
                path: '/main/platform-business/teacher-result',
                component: result['TeacherResult']
            },
            {
                name: '学生导入记录',
                path: '/main/platform-business/student-record',
                component: result['StudentRecord']
            },
            {
                name: '学生导入结果',
                path: '/main/platform-business/student-result',
                component: result['StudentResult']
            },
            {
                name: '虚拟教师导入记录',
                path: '/main/platform-business/vir-teacherrecord',
                component: result['VirTeacherrecord']
            },
            {
                name: '虚拟教师导入结果',
                path: '/main/platform-business/vir-teacherresult',
                component: result['VirTeacherresult']
            },
            {
                name: '虚拟学生导入记录',
                path: '/main/platform-business/vir-studentecord',
                component: result['VirStudentecord']
            },
            {
                name: '虚拟学生导入结果',
                path: '/main/platform-business/vir-studentresult',
                component: result['VirStudentresult']
            },
            {
                name: '查看详情',
                path: '/main/platform-business/check-detail',
                component: result['CheckDetail']
            },
            {
                name: '导入成员',
                path: '/main/platform-business/add-member',
                component: result['AddMember']
            },
            {
                name: '机构成员查看详情',
                path: '/main/platform-business/member-detail',
                component: result['MemberDetail']
            },
            {
                name: '机构导入结果',
                path: '/main/platform-business/vir-mechanismresults',
                component: result['VirMechanismresults']
            }
        ]
    },

]