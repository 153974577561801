import React, { Component } from 'react'
import { Input, Button, Table, Modal, Divider, Pagination, Tooltip, Switch, Form } from 'antd';
import { getFeedbackTypePage, addFeedback, feedbackRemove, updateTypeStatus, updateFeedback } from '../../../api/feedbackCenter/index';
import './index.scss';

export default class retroalimentacionTipo extends Component {
    state = {
        feedTypeList: [],
        id: null,
        total: 0,
        pageSize: 10,
        pageNum: 1,
        visible: false,
        titleName: ''
    }
    componentDidMount() {
        this.getDataList()
    }
    switchControl = (e, item) => {
        updateTypeStatus({
            displayStatus: e ? 1 : 0,
            id: item.id
        }).then(res => {
            if (res === '状态变更成功') {
                this.getDataList();
            } else {
                this.mesWarning('状态改变失败');
            }
        })
    }
    // 删除
    delete = (item) => {
        this.hint({
            'title': '温馨提示',
            'content': '删除类型后，类型下的全部反馈记录也会一并删除。是否确认继续该操作？',
            'type': 'warning',
            'isCancel': true,
            'sure': () => {
                feedbackRemove({ id: item.id }).then(res => {
                    if (res === '删除成功') {
                        this.mesSuccess('删除成功');
                        this.getDataList();
                    } else {
                        this.mesWarning('删除失败');
                    }
                })
            }
        })
    }
    //编辑
    edit = (record) => {
        this.setState({
            visible: true,
            titleName: '编辑问题分类',
            id: record.id
        })
        let timer = setTimeout(() => {
            this.contenidosAddAndEdit && this.contenidosAddAndEdit.setFieldsValue({
                typeName: record.typeName
            })
            clearTimeout(timer)
        })

    }
    //确定
    onOk = () => {
        this.contenidosAddAndEdit.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.titleName === '新增问题分类') {
                    addFeedback({ platform: 'uc', ...values }).then(res => {
                        if (res === '保存成功') {
                            this.mesSuccess(res);
                            this.setState({
                                visible: false
                            })
                            this.getDataList();
                        } else {
                            this.mesWarning(res);
                        }
                    })
                } else {
                    updateFeedback({
                        id: this.state.id,
                        ...values
                    }).then(res => {
                        if (res === '修改成功') {
                            this.mesSuccess(res);
                            this.setState({
                                visible: false
                            })
                            this.getDataList();
                        } else {
                            this.mesWarning(res);
                        }
                    })

                }
            }
        });
    }
    //  列表
    getDataList = () => {
        getFeedbackTypePage({
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum,
            platform: 'uc'
        }).then(res => {
            if (res) {
                this.setState({
                    feedTypeList: res.data,
                    total: res.total
                })
            }
        })

    }
    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };
    render() {
        const columns = [
            {
                title: '编号',
                dataIndex: 'id',
                width: 180,
                key: 'id',
                align: 'center'
            },
            {
                title: '反馈类型',
                dataIndex: 'typeName',
                key: 'typeName',
                align: 'center'
            },
            {
                title: '已反馈记录',
                dataIndex: 'count',
                key: 'count',
                align: 'center',
            },
            {
                title: '创建人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                align: 'center',
            },
            {
                title: '展示状态',
                dataIndex: 'displayStatus',
                key: 'showState',
                align: 'center',
                render: (text, record) => {
                    return <span>
                        {text === 1 ? "启用" : "停用"}
                    </span>
                }
            },
            {
                title: '展示状态',
                dataIndex: 'displayStatus',
                key: 'showSwitch',
                align: 'center',
                render: (text, record) => {
                    return <span>
                        <Tooltip placement="top" title={text ? "启用" : "停用"} >
                            <Switch onChange={(e) => this.switchControl(e, record)}
                                checkedChildren="开" unCheckedChildren="关"
                                checked={text === 1 ? true : false}
                            />
                        </Tooltip>
                    </span>
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: 160,
                render: (text, record) => (
                    <div className="contenidosList_action" >
                        <span onClick={() => this.edit(record)}>编辑</span>
                        <Divider type="vertical" />
                        <span onClick={() => this.delete(record)}>删除</span>
                    </div>
                )
            },
        ];
        const { feedTypeList, total } = this.state
        return (
            <div className="retroalimentacionTipo">
                <div className="fdb_header">
                    <Button type="primary" onClick={() => this.setState({ visible: true, titleName: "新增问题分类" })}>新增反馈类型</Button>
                    <span>共有{total}个反馈类型</span>
                </div>
                <div className="q_list">
                    <div className="q_list_show">
                        <Table
                            rowKey="id"
                            className="retroalimentacion_table"
                            dataSource={feedTypeList.length > 0 && feedTypeList}
                            columns={columns}
                            pagination={
                                {
                                    showSizeChanger: true,
                                    onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                    onChange: (a, b) => this.onPageNumChange(a, b),
                                    total: this.state.total
                                }
                            }
                        />
                    </div>

                </div>
                <Modal
                    destroyOnClose={true}
                    closable={false}
                    className="contenidos_clasificacion_Modal"
                    width={660}
                    title={this.state.titleName}
                    visible={this.state.visible}
                    footer={
                        <div className='contenidos_clasificacion_btn'>
                            <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                            <Button type='primary' onClick={() => this.onOk()}>确定</Button>
                        </div>
                    }
                >
                    <ContenidosAddAndEdit ref={ref => this.contenidosAddAndEdit = ref} />
                </Modal>
            </div>
        )
    }
}



class ContenidosAddAndEdit extends Component {
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <Form className="contenidos_retroalimentacionTipo_Modal" colon={false}>
                <Form.Item label="问题分类" style={{ display: 'flex' }}>
                    {getFieldDecorator('typeName', {
                        rules: [
                            {
                                required: false,
                                message: '',
                            },
                        ],
                    })(<Input
                        placeholder='请输入类型名称'
                        maxLength={8}
                        style={{ width: '330px', marginLeft: 10 }}
                        suffix={<span>{getFieldValue('typeName') && getFieldValue('typeName').length || 0}/8</span>}
                    />)}
                </Form.Item>
            </Form>
        )
    }
}
ContenidosAddAndEdit = Form.create()(ContenidosAddAndEdit);