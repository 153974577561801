import { post } from '@/until/axios_instans';


//添加菜单
export function addMenu(data) {
    return post('/api/ucenter/api/v1/menu/addMenu', data)
}
//删除菜单
export function delMenu(data) {
    return post('/api/ucenter/api/v1/menu/delMenu', data)
}
//修改菜单
export function editMenu(data) {
    return post('/api/ucenter/api/v1/menu/editMenu', data)
}
//菜单详情
export function getMenuDetail(data) {
    return post('/api/ucenter/api/v1/menu/getMenuDetail', data)
}
//菜单列表
export function menuList(data) {
    return post('/api/ucenter/api/v1/menu/menuList', data)
}
//菜单树形查询（添加菜单）
export function upMenuList(data) {
    return post('/api/ucenter/api/v1/menu/upMenuList', data)
}
//跳转方式（检索）
export function jumpWay(data) {
    return post('/api/ucenter/api/v1/menu/jumpWay', data)
}

//导出菜单
export const exportMenu = '/api/ucenter/api/v1/menu/exportMenu';

