import React, { Component, useState, useEffect } from 'react';
import { Button, Form, Input, Select, Upload, DatePicker, Spin } from 'antd';
import ImgCrop from "antd-img-crop";
import moment from 'moment';

import RangeCheckedSelect from '../../components/range_checked_select';
import SelectLabelModal from '../../components/major_type_select';
import { uploadFile } from '../../api/manage_mechanism_manage';
import { enterVmOrgInfo, getVmOrgInfoEcho, updateVmOrgInfo } from '../../api/school_settlement';
import { uploadIcon } from '../../icon_type';
import { _get_query, disabledDate } from '../../components/method_tools';
import style from './index.module.scss';

const format = 'YYYY-MM-DD'
const that = new Component();
const formItemLayout = {
    labelCol: {
        xxl: { span: 2 },
        xl: { span: 3 },
        lg: { span: 3 },
        md: { span: 3 },
    },
    wrapperCol: {
        xxl: { span: 15 },
        xl: { span: 15 },
        lg: { span: 18 },
        md: { span: 15 },
        span: 12
    },
};
const ImgCropProps = {
    aspect: 160 / 88,
    resize: true, //裁剪是否可以调整大小
    resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
    modalTitle: "上传logo", //弹窗标题
    modalWidth: 600, //弹窗宽度
    rotate: false,
    width: 160,  //裁剪宽度
    height: 88, //裁剪高度
    beforeCrop: (f) => {
        if (!f.type.includes('image')) {
            that.mesWarning('上传格式不正确');
            return false;
        }
        return true;
    }
};
let cloneImg = undefined;//图片选择的克隆地址
function CollegeInformationEntry(props) {
    const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = props.form;
    const id = _get_query('id');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fn() {
            const res = await getVmOrgInfoEcho({ id });
            setFieldsValue({
                ...res,
                settledDate: moment(res.settledDate),
                settledEndDate: moment(res.settledEndDate),
            })
        }
        id && fn();
    }, [])
    //上传图片
    const changeLoad = async info => {
        setLoading(true);
        const fromData = new FormData()
        fromData.append('file', info)
        fromData.append('folder', 'cn')
        try {
            const uploadResult = await uploadFile(fromData);
            setLoading(false);
            setFieldsValue({ enterpriseLogo: uploadResult.fpName });
        } catch (error) {
            console.log(error);
        }
    };
    const handleSubmit = () => {
        validateFields(async (err, values) => {
            if (!err) {
                const { settledDate, settledEndDate } = values;
                values.settledDate = settledDate.format(format);
                values.settledEndDate = settledEndDate.format(format);
                if (id) {
                    values.id = id;
                    await updateVmOrgInfo(values);
                    that.mesSuccess("修改成功");
                } else {
                    await enterVmOrgInfo(values);
                    that.mesSuccess("创建成功");
                }
                props.history.push('/main/institutional-settlement/virtual_organization_manage')
            }
        })
    }
    return (
        <div className={style.CollegeInformationEntry}>
            <Form {...formItemLayout} colon={false}>
                <Form.Item label="虚拟机构名称">
                    {getFieldDecorator("enterpriseName", {
                        initialValue: '',
                        rules: [{ required: true, message: '虚拟机构名称不能为空' }],
                        getValueFromEvent: e => {
                            const val = e.target.value;
                            if (typeof val !== 'string') {
                                return val;
                            };
                            return val.replace(/\s+/g, "");
                        }
                    })(
                        <Input
                            className="max_input"
                            placeholder='请输入虚拟机构名称'
                            size="large"
                            maxLength={30}
                            style={{ width: 500 }}
                            suffix={
                                <span style={{ color: "#888", fontSize: 12 }}>
                                    {getFieldValue("enterpriseName") ? getFieldValue("enterpriseName").length : 0}/
                                    <span style={{ color: "#333" }}>30</span>
                                </span>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item label="虚拟机构识别码">
                    {getFieldDecorator("enterpriseCode", {
                        initialValue: '',
                        rules: [{ required: true, message: '虚拟机构识别码不能为空' }],
                        getValueFromEvent: e => {
                            const val = e.target.value;
                            if (typeof val !== 'string') {
                                return val;
                            };
                            return val.replace(/[\u4e00-\u9fa5\s]/g, "");
                        }
                    })(
                        <Input
                            className="max_input"
                            placeholder='请输入虚拟机构识别码'
                            size="large"
                            maxLength={15}
                            style={{ width: 500 }}
                            suffix={
                                <span style={{ color: "#888", fontSize: 12 }}>
                                    {getFieldValue("enterpriseCode") ? getFieldValue("enterpriseCode").length : 0}/
                                    <span style={{ color: "#333" }}>15</span>
                                </span>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item className='upload_box' label={'虚拟机构logo'}>
                    {getFieldDecorator('enterpriseLogo', {
                        getValueFromEvent: () => {
                            return cloneImg;
                        }
                    })(
                        <div>
                            <ImgCrop {...ImgCropProps}>
                                <Upload
                                    accept='.jpg,.png,.jpeg'
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={changeLoad}
                                >
                                    {
                                        loading
                                            ? <Spin />
                                            : (getFieldValue("enterpriseLogo")
                                                ? <div className='resource_replace_img'>
                                                    <div className="resource_replace_img_modal">
                                                        <span style={{ cursor: 'pointer' }} onClick={() => { cloneImg = getFieldValue("enterpriseLogo") }}>{uploadIcon}</span>
                                                    </div>
                                                    <div className="img_s">
                                                        <img
                                                            style={{ width: "100%" }}
                                                            src={window.$$imgSrc(getFieldValue("enterpriseLogo"))} alt="avatar"
                                                        />
                                                    </div>
                                                </div>
                                                : <div className='add_image_div'>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around',
                                                            marginBottom: 5
                                                        }}
                                                    >
                                                        <img style={{ width: "19px", height: "19px", display: "block" }} src={window.$$img('cvec-portal-front-pingtai/Circl/add_icon.png')} alt="" />
                                                    </div>
                                                    <span style={{ color: '#3E78ED' }}>上传Logo</span>
                                                </div>)
                                    }
                                </Upload>
                            </ImgCrop>
                        </div>
                    )
                    }
                    <div className="tishi_wrap">
                        <p
                            className='tishi'
                        >
                            支持jpg/png/jpeg格式，<br />
                            建议最佳尺寸200x50px，不超过5M
                        </p>
                    </div>
                </Form.Item>
                <Form.Item label="所属范围">
                    {getFieldDecorator('orgRangeCodes', {

                    })(
                        <RangeCheckedSelect />
                    )}
                </Form.Item>
                <Form.Item label="平台使用日期" required style={{ marginBottom: 0 }} className={style.shijian}>
                    <Form.Item label={undefined} style={{ display: 'inline-block', width: 260 }}>
                        {getFieldDecorator('settledDate', {
                            rules: [
                                { required: true, message: '开通日期不能为空' },
                                (rule, val, callback) => {
                                    // getFieldValue
                                    const endTime = getFieldValue('settledEndDate');
                                    if (
                                        val && endTime &&
                                        moment(endTime.format(format)).diff(moment(val.format(format)), 'days') < 0
                                    ) {
                                        callback("开始时间不能大于结束时间")
                                    } else {
                                        if (endTime) validateFields(['settledEndDate']);
                                        callback();
                                    }
                                }
                            ]
                        })(
                            <DatePicker
                                placeholder="请选择开通日期"
                                style={{ width: 260 }}
                                disabledDate={disabledDate}
                                getCalendarContainer={triggerNode => triggerNode.parentElement}
                            />
                        )}
                    </Form.Item>
                    <span style={{ display: 'inline-block', width: '55px', textAlign: 'center' }}>至</span>
                    <Form.Item label={undefined} style={{ display: 'inline-block', width: 260 }}>
                        {getFieldDecorator('settledEndDate', {
                            rules: [
                                { required: true, message: '到期日期不能为空' },
                                (rule, val, callback) => {
                                    // getFieldValue
                                    const startTime = getFieldValue('settledDate');
                                    if (
                                        val && startTime &&
                                        moment(val.format(format)).diff(moment(startTime.format(format)), 'days') < 0
                                    ) {
                                        callback("结束时间不能小于开始时间")
                                    } else {
                                        if (startTime) validateFields(['settledDate']);
                                        callback();
                                    }
                                }
                            ]
                        })(
                            <DatePicker
                                placeholder="请选择到期日期"
                                style={{ width: 260 }}
                                disabledDate={disabledDate}
                                getCalendarContainer={triggerNode => triggerNode.parentElement}
                            />
                        )}
                    </Form.Item>
                </Form.Item>
                <Form.Item label="机构标签">
                    {getFieldDecorator('labelIds', {

                    })(
                        <SelectLabelModal
                            isEdit
                        />
                    )}
                </Form.Item>
            </Form>

            <div className={style.fixed_footer}>
                <Button onClick={handleSubmit} type="primary" style={{ marginRight: 30 }}>{id ? '确定修改' : '确定创建'}</Button>
                <Button onClick={() => {
                    props.history.push('/main/institutional-settlement/virtual_organization_manage')
                }}>取 消</Button>
            </div>
        </div>
    )
}
export default Form.create()(CollegeInformationEntry);