import React,{useState,useEffect,forwardRef} from 'react';
import { queryCity } from '../../api/components';
import { Select } from 'antd';

const {Option} = Select;

const CitySelect = (props,ref) => {
    const {provinceId} = props;
    const [list,setList] = useState([]);

    useEffect(()=>{
        if(!provinceId)return;
        const fn = async () => {
            try {
                const res = await queryCity({provinceCode:provinceId});
                setList(res);
            } catch (error) {
                console.log(error);
            }
        };
        fn();
    },[provinceId]);

    return (
        <Select
            {...props}
            ref={ref}
            getPopupContainer={triggerNode => triggerNode.parentElement}
        >
            {
                Array.isArray(list) && list.map((item) => {
                    return <Option key={item.cityCode} value={item.cityCode}>
                        {item.name}
                    </Option>
                })

            }
        </Select>
    )
}

export default forwardRef(CitySelect);