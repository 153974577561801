import React, { Component } from 'react';
import { Select, Button, Input, Table, Divider, Pagination, Modal, message, Tooltip } from 'antd'
import { commonQuestionList, deleteQuestion } from '@/api/questionLibrary'
import moment from 'moment'
import './index.scss'

const { Column } = Table;


const GanTanHao = () => {

    return <div style={{ marginRight: 10 }}>
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11.5L0 11.5C9.59018e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5L0 11.5Z" fill="#F3B714" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6502 6.32502V13.225C12.6502 13.8602 12.1353 14.375 11.5002 14.375C10.865 14.375 10.3502 13.8602 10.3502 13.225V6.32502C10.3502 5.68989 10.865 5.17502 11.5002 5.17502C12.1353 5.17502 12.6502 5.68989 12.6502 6.32502ZM11.5001 17.825C12.1352 17.825 12.6501 17.3102 12.6501 16.675C12.6501 16.0399 12.1352 15.525 11.5001 15.525C10.865 15.525 10.3501 16.0399 10.3501 16.675C10.3501 17.3102 10.865 17.825 11.5001 17.825Z" fill="white" />
        </svg>
    </div>
}
const _id_create = () => {
    return 'xxxxxx-xxx-4xxx-yxx-xxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const questionGroup = [
    { questionType: 0, value: "单选题" },
    { questionType: 1, value: "多选题" },
    { questionType: 2, value: "简答题" },
    { questionType: 3, value: "五星评价题" },
    { questionType: 4, value: "填空题" },
]
const categoryGroup = [
    { categoryType: 0, name: "人口属性" },
    { categoryType: 1, name: "联系方式" },
    { categoryType: 2, name: "用户行为" },
    { categoryType: 3, name: "用户反馈" },
    { categoryType: 4, name: "满意度评价" },
]
class index extends Component {
    state = {
        pageSize: 10,
        pageNum: 1,
        // 题型分类
        questionType: '',
        //题目分类
        categoryType: '',
        //搜索框内容
        Inputbox: '',
        total: 100
    }
    componentDidMount() {
        this.getDataList({
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum
        })
    }
    // 点击添加题目跳转
    addQuestion = (v) => {
        // console.log(v.questionType);
        this.props.history.push({ pathname: '/editQuestion', state: { questionType: v.questionType } })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };
    //  列表
    getDataList = (par) => {
        return commonQuestionList(par).then(res => {
            if (res) {
                this.setState(() => {
                    return {
                        dataList: res.data,
                        total: res.total
                    }
                })
            } else {
                message.info("数据错误")
            }
        })
    }
    //删除问题库
    deleteQuestion = (v) => {
        Modal.confirm({
            className: "hh_modal_1",
            title: <><div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: 16,
                background: '#F8F8F8',
                width: '100%'
            }}>删除提示</div>
                <div style={{
                    marginTop: 19,
                    display: 'flex'
                }}><GanTanHao /><div>
                        删除{v.remark}后，用户将无法使用该例题</div></div></>,
            onOk: (close) => {
                deleteQuestion({ questionId: v.questionId }).then((res) => {
                    if (res) {
                        message.success("删除成功", 0.8)
                        this.getDataList({
                            pageSize: this.state.pageSize,
                            pageNum: this.state.pageNum
                        })
                    } else {
                        message.error("数据错误")
                    }
                })
                close()
            },
            onCancel(close) {
                close()
            },
        })
    }
    //编辑问题库
    editQuestion = (v) => {
        this.props.history.push({
            pathname: '/editQuestion', state: {
                questionType: v.questionType,
                questionId: v.questionId
            }
        })
    }
    // 题目类型下拉框
    changeQuestionType = (item) => {
        this.setState(() => ({
            questionType: item
        }))
    }
    //题库分类下拉框
    changeCategoryType = (item) => {
        this.setState(() => ({
            categoryType: item
        }))
    }
    // 重置按钮
    reset = () => {
        this.setState(() => ({
            categoryType: '',
            questionType: '',
            Inputbox: ''
        }), () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        })
    }
    // 搜索按钮
    search = () => {
        const { Inputbox, categoryType, questionType, pageSize, pageNum } = this.state
        const data = { questionTitle: Inputbox, questionType, categoryType, pageSize, pageNum }
        // console.log(data,"要发送的数据");
        this.getDataList(data)
    }
    render() {
        const { dataList } = this.state
        return (
            <div className="question_body">
                <div className="q_search">
                    <div className="change_sl">
                        <span>题型分类</span>
                        <span>
                            <Select
                                style={{ width: 230 }}
                                placeholder="请选择题型分类"
                                onChange={this.changeQuestionType}
                                value={this.state.questionType ? this.state.questionType : undefined}
                                allowClear={true}
                            >
                                {
                                    questionGroup.map((item, index) => {
                                        return <Select.Option key={item.questionType} >
                                            {item.value}  </Select.Option>
                                    })
                                }

                            </Select>
                        </span>
                    </div>
                    <div className="change_sl">
                        <span>题库分类</span>
                        <span>
                            <Select
                                // showSearch
                                style={{ width: 230 }}
                                placeholder="请选择题库分类"
                                value={this.state.categoryType ? this.state.categoryType : undefined}
                                onChange={this.changeCategoryType}
                                allowClear={true}
                            >
                                {
                                    categoryGroup.map((item, index) => {
                                        return <Select.Option key={item.categoryType} >
                                            {item.name}  </Select.Option>
                                    })
                                }

                            </Select>
                        </span>
                    </div>
                    <div className="change_sl">
                        <img src={window.$$img('cvec-gonggong-front-wenjuan/icon/icon_select.png')}></img>
                        <Input
                            className="in"
                            placeholder="请输入题目名称"
                            style={{ width: 230 }}
                            value={this.state.Inputbox}
                            onChange={value => {
                                this.setState({
                                    Inputbox: value.target.value
                                })
                            }}
                            onPressEnter={v => {
                                this.onInputbox()
                            }}
                        />

                    </div>
                    <div className="r_btn">
                        <Button onClick={() => this.reset()}>重置</Button>
                        <Button type="primary" onClick={() => this.search()}>查询</Button>
                    </div>
                </div>
                <div className="add_question">
                    {questionGroup.map((v, i) => {
                        return <Button key={i} onClick={() => this.addQuestion(v)}>新增{v.value}</Button>
                    })}
                </div>
                <div className="q_list">
                    <div className="q_list_show">

                        <Table dataSource={dataList} style={{ width: "100%" }} pagination={
                            {
                                dataSource: { dataList }, defaultCurrent: 1,
                                pageSizeOptions: ['10', '20', '30'],
                                onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                onChange: (a, b) => this.onPageNumChange(a, b),
                                showSizeChanger: true,
                                current: this.state.pageNum,
                                pageSize: this.state.pageSize,
                                total: this.state.total
                            }
                        }>
                            <Column title="题目" width={300} ellipsis={true} render={(text, record) => {
                                return <Tooltip placement="bottom" title={record.questionTitle}>{record.questionTitle}</Tooltip>
                            }} dataIndex="questionTitle" align="center" />
                            <Column title="题型" align="center" render={(text, record) => {
                                return <span>{record.questionType === 0 ? "单选题" : record.questionType === 1 ? "多选题" : record.questionType === 2 ? "简答题" : record.questionType === 3 ? "五星评价题" : "填空题"}</span>
                            }} />
                            <Column
                                title="题库分类"
                                align="center"
                                render={(text, record) => {
                                    return <span>{record.categoryType === 0 ? "人口属性" : record.categoryType === 1 ? "联系方式" : record.categoryType === 2 ? "用户行为" : record.categoryType === 3 ? "用户反馈" : "满意度评价"}</span>
                                }}
                            />

                            <Column
                                title="备注"
                                dataIndex="remark"
                                key="remark"
                                align="center"
                            />
                            <Column
                                title="创建时间"
                                align="center"
                                render={(text, record) => {
                                    return <span>{moment(record.createTime).format('YYYY-MM-DD hh:mm:ss')}</span>
                                }}
                            />

                            <Column
                                title="操作"
                                dataIndex="action"
                                align="center"
                                render={(text, record) => (
                                    <span>
                                        <a onClick={() => this.editQuestion(record)}>编辑</a>
                                        <Divider type="vertical" />
                                        <a onClick={() => this.deleteQuestion(record)}>删除</a>
                                    </span>
                                )}
                            />
                        </Table>

                    </div>
                </div>
            </div>
        );
    }
}

export default index;