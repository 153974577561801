import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Row, Col, Checkbox, Radio, DatePicker} from 'antd';
import './index.scss';
import { selectRoleList } from '@/api/role/index.js';
import {getCitySel, getProvinceSel, globalSel,getOrganizatioMembers,addOrganizatioMembers,updateOrganizatioMembers,deleteOrganizatioMembers,formattingPassword,downTemplate,generalImport} from '../../../../api/platform-business/index'
import moment from "moment";
import Batch from '@/components/import';
import {deleteAcademyTeacher, resetUserPassword} from "../../../../api/platform-school";
import fs from "fs";
// const fs = require("fs")
const { Option } = Select;


const App = () => {
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>男</Radio>
            <Radio value={0}>女</Radio>
        </Radio.Group>
    );
};

class index extends Component {
    state = {
        dataSource: [],
        enterpriseId: '',
        roleName: '',
        userName: '',
        pageNum: 1,
        pageSize: 10,
        total: '',
        isTeacher: false, // 新增成员弹层
        isDelete: false,// 删除弹层
        isRepassword: false,// 重置密码
        propertyList: [], // 教师类型下拉列表
        professionalCallList: [], // 职称下拉列表
        professionalCall: '', // 职称
        property: '', // 教师类型
        teacherNameOrNum: '', // 教师工号/教师名称
        propertyDicType: 19, // 教师类型字典中的类型
        professionalCallDicType: 16, // 职称字典中的类型
        nameLength: 0, // 新增弹框姓名长度
        positionLength: 0, // 新增弹框职务长度
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        yuanxiaoSel:[],
        orgMember: {}, //机构成员json  有是修改没是新增
        orgMemberId:"", //删除时用
        highestDegreeOption:[],//学位下拉
        highestEducationOption:[],//学历下拉
        cardTypeOption:[{
            code:'0',
            name:'身份证'
        },{
            code:'1',
            name:'护照'
        }],//证件下拉
        Batch:false,
        btnActive: false,
        errorMessage:'',
        batchRef:{},//文件
        isDisable:true,
        isUpdateOrSee: '',
        inductionTime: '',
    }

    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log("jsonparam",jsonparam)
        this.setState({
            enterpriseId:jsonparam
        }, () =>{
            this.getOrganizatioMembers()
        })
        this.getRoleList()
        this.getProvince()
        this.getProfessionalCall()
        this.getPolitical()//学历学位下拉数据
        this.getProvinceSel()
        this.getCitySel()
    }
    async getOrganizatioMembers(){
        const param = {
            enterpriseId: this.state.enterpriseId,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            jobNum: this.state.jobNum,
        }
        const teacherTable = await getOrganizatioMembers(param)
        this.setState({
            total: teacherTable.total,
            dataSource: teacherTable.data
        })
    }
//角色下拉列表
    getRoleList = async () => {
        let res = await selectRoleList({identityId: "71"});
        res && this.setState({
            yuanxiaoSel: res
        });
    }
    getPolitical = async () => {
        let highestEducationOption = await globalSel({ type: 17 });
        highestEducationOption && this.setState({ highestEducationOption: highestEducationOption });
        let highestDegreeOption = await globalSel({ type: 18 });
        highestDegreeOption && this.setState({ highestDegreeOption: highestDegreeOption });
    }
    addOrganizatioMembers = () => {
        this.props.form.validateFields((err, values) => {
            console.log('values.inductionTime', values.inductionTime)
            if (!err) {
                let { orgMember } = this.state;
                console.log("values",values)
                if (JSON.stringify(orgMember) === "{}") {
                    console.log("values1111",moment(values.birthday).format('YYYY-MM-DD'))
                    let record= {
                        enterpriseId: this.state.enterpriseId,
                        orgMemberName: values.orgMemberName,
                        jobNum: values.jobNum,
                        sex:values.sex,
                        telNum:values.telNum,
                        birthday:moment(values.birthday).format('YYYY-MM-DD'),
                        nativePlace:values.provinceName+"-"+values.cityName,
                        email:values.email,
                        cardType: 1,
                        idCard:values.idCard,
                        highestEducation:values.highestEducation,
                        highestDegree:values.highestDegree,
                        inductionTime:moment(values.inductionTime).format('YYYY-MM-DD HH:MM:ss'),
                        professionalCall:values.professionalCall,
                        identityName:values.identityName,
                        position:values.position,
                        identityId:values.roleId,
                    }
                    console.log("record",record)
                    console.log('typeof record.inductionTime', typeof record.inductionTime)
                    console.log('record.inductionTime', record.inductionTime)
                    addOrganizatioMembers(record).then(res => {
                        if (res) {
                            this.mesSuccess('保存成功');
                            this.setState({
                                isTeacher: false,
                                orgMember:{}
                            },()=>{
                                this.getOrganizatioMembers()
                            })
                        }
                    })
                }else {
                    let record= {
                        id:orgMember.id,
                        enterpriseId: this.state.enterpriseId,
                        orgMemberName: values.orgMemberName,
                        jobNum: values.jobNum,
                        sex:values.sex,
                        telNum:values.telNum,
                        birthday:moment(values.birthday).format('YYYY-MM-DD'),
                        nativePlace:values.provinceName+"-"+values.cityName,
                        email:values.email,
                        cardType: 1,
                        idCard:values.idCard,
                        highestEducation:values.highestEducation,
                        highestDegree:values.highestDegree,
                        inductionTime:values.inductionTime,
                        professionalCall:values.professionalCall,
                        identityName:values.identityName,
                        position:values.position,
                        identityId:values.roleId,
                    }
                    updateOrganizatioMembers(record).then(res => {
                        if (res) {
                            this.mesSuccess('保存成功');
                            this.setState({
                                isTeacher: false,
                                orgMember:{}
                            },()=>{
                                this.getOrganizatioMembers()
                            })
                        }
                    })
                }
            }
        })

    }
    //检索
    search = (e) => {
        this.setState({ jobNum: e }, () => this.getOrganizatioMembers());
    }

    // 教师类型下拉
    getProvince = async () => {
        if (this.state.propertyDicType) {
            let provinceRes = await globalSel({ type: this.state.propertyDicType });
            provinceRes && this.setState({ propertyList: provinceRes });
        }
    }

    //教师类型下拉框change
    changeProperty = (e, item) => {
        this.setState({ property: e}, () => this.selectAcademyTeacher());
    }

    // 职称下拉
    getProfessionalCall = async () => {
        if (this.state.professionalCallDicType) {
            let professionalCallRes = await globalSel({ type: this.state.professionalCallDicType });
            professionalCallRes && this.setState({ professionalCallList: professionalCallRes });
        }
    }

    // 职称下拉框change
    changeProfessionalCall = (e, item) => {
        this.setState({ professionalCall: e}, () => this.selectAcademyTeacher());
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => this.getOrganizatioMembers());
        window.elGoTop && window.elGoTop('body');
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize }, () => this.getOrganizatioMembers());
        window.elGoTop && window.elGoTop('body');
    }
    // 姓名输入框长度
    nameLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                nameLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                nameLength:value.length
            })
        } else {
            callback();
        }
    }

    // 姓名输入框长度
    positionLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                positionLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                positionLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号')
        } else {
            callback();
        }
    }

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        this.setState({ provinceCode: e, citySel: [] }, () =>
            this.getCitySel()
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        this.setState({
            cityId: e
        });
    }
    //编辑弹窗
    isUpdate = (record) => {
        console.log("编辑弹窗 === >>>>",record)
        this.setState({
            isUpdateOrSee: 1,
            isTeacher: true,
            orgMember: record,
            provinceCode:record.nativePlace?record.nativePlace.split("-")[0]:"",
            inductionTime: record.inductionTime
        },()=>{
            this.getCitySel()
        });
        this.props.form.setFieldsValue({
            orgMemberName: record.orgMemberName,
            jobNum: record.jobNum,
            sex:record.sex,
            telNum:record.telNum,
            birthday:moment(record.birthday),
            nativePlace:record.nativePlace,
            email:record.email,
            cardType: "1",
            idCard:record.idCard,
            highestEducation:record.highestEducation?Number(record.highestEducation):"",
            highestDegree:record.highestDegree?Number(record.highestDegree):"",
            // inductionTime: moment(record.inductionTime) ,
            professionalCall:record.professionalCall?Number(record.professionalCall):"",
            identityName:record.identityName,
            position:record.position,
            roleId:record.roleId,
            cityName:record.nativePlace?record.nativePlace.split("-")[1]:"",
            provinceName:record.nativePlace?record.nativePlace.split("-")[0]:"",
        });
    }
    //查看弹窗
    view = (record) => {
        console.log("查看得传值",record)
        this.setState({
            isUpdateOrSee: 2,
            isDisable:false,
            isTeacher: true,
            provinceCode:record.nativePlace?record.nativePlace.split("-")[0]:"",
            inductionTime:record.inductionTime,
        },()=>{
            this.getCitySel()
        });
        this.props.form.setFieldsValue({
            orgMemberName: record.orgMemberName,
            jobNum: record.jobNum,
            sex:record.sex,
            telNum:record.telNum,
            birthday:moment(record.birthday),
            nativePlace:record.nativePlace,
            email:record.email,
            cardType: "1",
            idCard:record.idCard,
            highestEducation:record.highestEducation?Number(record.highestEducation):"",
            highestDegree:record.highestDegree?Number(record.highestDegree):"",
            professionalCall:record.professionalCall?Number(record.professionalCall):"",
            identityName:record.identityName,
            position:record.position,
            roleId:record.roleId,
            cityName:record.nativePlace?record.nativePlace.split("-")[1]:"",
            provinceName:record.nativePlace?record.nativePlace.split("-")[0]:"",
        });
    }
    //删除弹窗
    idDelete = (id) => {
        this.setState({
            orgMemberId: id,
            isDelete: true,
        })
        this.hint({'title': '温馨提示',
            'content': '确定要删除吗？删除后将无法恢复！',
            'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.deleteOrganizatioMembers })
        // let _this = this;
        // _this.setState({
        //     isDelete: true,
        //     orgMemberId:orgMemberId
        // });
        // Modal.confirm({
        //     title: "温馨提示",
        //     content: "确定要删除吗？删除后将无法恢复！",
        //     okText: "确定",
        //     cancelText: "关闭",
        //     centered:true,
        //     className: "ModalConfirm-pic",
        //     onOk() {
        //         deleteOrganizatioMembers({id: id}).then((res) => {
        //             this.mesSuccess("删除成功");
        //             _this.setState({
        //                 isDelete: false,
        //                 orgMemberId:''
        //             },()=>{
        //                 _this.getOrganizatioMembers();
        //             })
        //         });
        //     },
        //     onCancel() {},
        // });
    }

    //删除弹窗
    deleteOrganizatioMembers = async () => {
        const {orgMemberId} = this.state
        let data = {
            id: orgMemberId
        }
        await deleteOrganizatioMembers(data).then(r => {
            if(r){
                this.mesSuccess("删除成功");
                this.setState({
                    isDelete: false,
                    orgMemberId:''
                },()=>{
                    this.getOrganizatioMembers();
                })
            }
        })
    }
    //删除弹窗
    // deleteMember = () => {
    //     deleteOrganizatioMembers({ id: this.state.orgMemberId }).then(res => {
    //             this.mesSuccess('删除成功');
    //             this.setState({
    //                 isDelete: false,
    //                 orgMemberId:''
    //             },()=>{
    //                 this.getOrganizatioMembers()
    //             })
    //     })
    // }
    //重置密码弹窗
    repassword = (id) => {
        this.setState({
            isRepassword: true,
            orgMemberId:id
        })
        this.hint({'title': '温馨提示',
            'content': '确定要重置密码吗？',
            'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.formattingPassword })
        // let _this = this;
        // _this.setState({
        //     isRepassword: true,
        //     orgMemberId:id
        // });
        // Modal.confirm({
        //     title: "温馨提示",
        //     content: "确定要重置密码吗？",
        //     okText: "确定",
        //     cancelText: "关闭",
        //     centered:true,
        //     className: "ModalConfirm-pic",
        //     onOk() {
        //         formattingPassword({id: id}).then((res) => {
        //             this.mesSuccess("重置成功");
        //             _this.setState({
        //                 isRepassword: false
        //             },()=>{
        //                 _this.getOrganizatioMembers();
        //             })
        //         });
        //     },
        //     onCancel() {},
        // });
    }
    //重置密码方法
    formattingPassword = async () => {
        const {orgMemberId} = this.state
        let data = {
            id: orgMemberId
        }
        await formattingPassword(data).then(r => {
            if(r){
                this.mesSuccess("重置成功");
                this.setState({
                    isRepassword: false,
                },()=>{
                    this.getOrganizatioMembers();
                })
            }
        })
    }
    // resetPassword = () => {
    //     console.log("id",this.state.orgMemberId)
    //     formattingPassword({id: this.state.orgMemberId} ).then(res => {
    //         this.mesSuccess('重置密码成功');
    //         this.setState({
    //             isRepassword: false,
    //             orgMemberId:''
    //         },()=>{
    //             this.getOrganizatioMembers()
    //         })
    //     })
    // }
    // 导入成员弹窗取消
    closeBatch = () => {
        this.setState({ batch: false, errorMessage: '', btnActive: false });
    }
    getFormData=(formData)=>{
        console.log("1111111111111",formData.get("file"))
        formData.set("enterpriseId",this.state.enterpriseId)
        this.setState({
            batchRef: formData
        },()=>{
            console.log("batchRef",this.state.batchRef)
        })
    }

    //导入时得确定按钮
    commitBatch = () => {
        //从子组件调用导入接口，更改为点击确定后上传了
        generalImport(this.state.batchRef).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('上传成功');
                this.toTeacherresult(res)
                // if (Object.prototype.toString.call(res.message) === '[object String]' && res.message !== 'success') {
                //     this.setState({ errorMessage: res.message, fileName: this.batchRef.state.batchTitle });
                // }
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
            console.log("重新拉取列表")
            this.getOrganizatioMembers()
        });
    }
    async toTeacherresult(res){
        let transParam = {
            recordId: res,
            enterpriseId:this.state.enterpriseId
        }
        let param = JSON.stringify(transParam);
        this.props.history.push(`/main/platform-business/vir-mechanismresults?${encodeURI(param)}`)
    }
    async toTeacherrecord(){
        this.props.history.push(`/main/platform-business/teacher-record?${encodeURI(JSON.stringify({enterpriseId: this.state.enterpriseId,jumpType:3}))}`);
    }
    //校验电子邮箱格式
    validateInvestmail =  (rule, value, callback) => {
        let regex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.(com|cn|net|clup|com.cn))$/;
        console.log("正常打印",regex.test(value));
        if (value && !regex.test(value)){
            callback('请填写正确电子邮箱')
        }else{
            callback();
        }
    };
    //身份证验证
    IsCard=(rule, value, callback)=> {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (value && !reg.test(value)){
            callback('请填写正确证件号')
        }else{
            callback();
        }

    }
    sure = (e) => {
        this.setState({ btnActive: true, fileId: e });
    }
    shutDown = () => {
        this.setState({ isTeacher: false ,isDisable:true},()=>{
            this.getOrganizatioMembers()
        })
    }
    render() {
        let {isTeacher,dataSource,propertyList,professionalCallList,nameLength,provinceSel,citySel,positionLength,total,pageSize,isDelete,isRepassword,yuanxiaoSel,batch,btnActive,} = this.state;
        let {errorMessage,highestDegreeOption,highestEducationOption,cardTypeOption,isDisable,isUpdateOrSee,inductionTime} = this.state
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '工号/帐号', dataIndex: 'jobNum',key:'jobNum'},
            {title: '姓名', dataIndex: 'orgMemberName',key:'teacherName' },
            {title: '性别', align: 'sex', render: (text, item, index ) =>  <span className='serial'>{item.sex === 0 ? '女' : item.sex === 1 ? '男' : ''}</span>,},
            {title: '出生日期', dataIndex: 'birthday',key:'birthday'},
            {title: '籍贯', dataIndex: 'nativePlaceName', key:'nativePlace'},
            {title: '最高学位', dataIndex: 'highestDegreeName',key:'highestDegree'},
            {title: '角色', dataIndex: 'roleName', key:'roleName'},
            {title: '手机', dataIndex: 'telNum', key:'telNum'},
            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:260,
                render:(text, record)=><div className="opspan">
                    <div className="main">
                        <span className="but" onClick={() => this.view(record)}>查看</span>
                        <span className="but" onClick={() => this.isUpdate(record)}>编辑</span></div>
                    <div className="main">
                        <span className="but" onClick={() => this.idDelete(record.id)}>删除</span>
                        <span className="but" onClick={() => this.repassword(record.id)}>重置密码</span></div>
                </div>},
        ];
        return (
            <div className='managestu_boxssss'>
                <div className="twolist">
                    <div className="top_list">
                        <div className="leftlist">
                            <Button className="buttonl" type="primary" style={{marginRight: '20px'}} onClick={() => this.setState({ batch: true })}>导入成员</Button>
                            <Button className="buttonm" style={{marginRight: '20px'}} onClick={() => this.setState({ isTeacher: true })}>新增成员</Button>

                            <Modal
                                title= {isUpdateOrSee === 1 ? '修改成员信息' :
                                    isUpdateOrSee === 2 ?  '查看成员信息' : '新增成员信息'}
                                visible={isTeacher}
                                destroyOnClose={true}
                                closable={false}
                                className='binding_modal_sdd_xy'
                                footer={
                                    <div className='foot_botton'>
                                        {isUpdateOrSee === 1 ? <Button className="addbutton" onClick={this.addOrganizatioMembers}>确认修改</Button> :
                                        isUpdateOrSee === 2 ? '':<Button className="addbutton" onClick={this.addOrganizatioMembers}>确认添加</Button>}
                                        <Button className="delete" onClick={this.shutDown}>取消</Button>
                                    </div>
                                }
                            >
                                <div className="modaltitle">
                                    个人信息
                                </div>
                                <Form className='lt_form_wapper'>
                                    <Form.Item label={`姓名`}>
                                        {getFieldDecorator('orgMemberName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入姓名`,
                                                },
                                                {
                                                    max:30,
                                                    message: "姓名最多30字"
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} disabled={isDisable === false}/>)}
                                        <span className="limited">{this.props.form.getFieldValue(`orgMemberName`) ? this.props.form.getFieldValue(`orgMemberName`).length : 0}/30</span>
                                    </Form.Item>
                                    <Form.Item label={`电话`}>
                                        {getFieldDecorator('telNum', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入电话`,
                                                },
                                                {
                                                    max:11,
                                                    message: "电话最多11字"
                                                },
                                                {
                                                    validator:this.validatePhone
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入电话" style={{ width: 294 , marginTop:"4px"}} disabled={isDisable === false}/>)}
                                        <span className="limited">{this.props.form.getFieldValue(`telNum`) ? this.props.form.getFieldValue(`telNum`).length : 0}/11</span>
                                    </Form.Item>
                                    <Form.Item className="sex" label={`性别`}>
                                        {
                                            getFieldDecorator('sex', {
                                                initialValue: '',
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: '请选择性别'
                                                    }
                                                ]
                                            })(
                                                <Radio.Group style={{ width: '100%' }} disabled={isDisable=== false}>
                                                    <Radio value={1}>男</Radio>
                                                    <Radio value={0}>女</Radio>
                                                </Radio.Group>
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`出生日期`}>
                                        {getFieldDecorator('birthday', {

                                        })(<DatePicker   placeholder='请选择出生日期' style={{width: '100%'}} disabled={isDisable=== false}/>)}
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`籍贯`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">籍贯</span></div>*/}
                                            {getFieldDecorator('provinceName', {
                                            })(
                                                <Select placeholder='请选择省'
                                                        onChange={this.changeProvince}
                                                        disabled={isDisable=== false}
                                                >
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                        provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                                    }
                                                </Select>
                                            )}
                                            {getFieldDecorator('cityName', {
                                            })(
                                                <Select placeholder='请选择市'
                                                        onChange={this.changeCity}
                                                        disabled={isDisable=== false}
                                                >
                                                    {/*<Option value={''}>全部</Option>*/}
                                                    {
                                                            citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                                    }
                                                </Select>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`最高学历`}>
                                        {
                                            getFieldDecorator('highestEducation', {
                                                initialValue: undefined,
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: '最高学历不能为空'
                                                    }
                                                ]
                                            })(<Select placeholder='请选则最高学历' disabled={isDisable=== false}>
                                                {
                                                    highestEducationOption && highestEducationOption.map(v => {
                                                        return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                    })
                                                }
                                            </Select>)
                                        }
                                    </Form.Item>
                                    <Form.Item label={`最高学位`}>
                                        {
                                            getFieldDecorator('highestDegree', {
                                                initialValue: undefined,
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: '最高学位不能为空'
                                                    }
                                                ]
                                            })(<Select placeholder='请选则最高学位' disabled={isDisable=== false}>
                                                {
                                                    highestDegreeOption && highestDegreeOption.map(v => {
                                                        return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                    })
                                                }
                                            </Select>)
                                        }
                                    </Form.Item>
                                    <Form.Item label={`证件类型`}>
                                        {getFieldDecorator('cardType', {
                                        })(<Select placeholder='请选择证件类型' disabled={isDisable=== false}>
                                            {
                                                cardTypeOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                            }
                                        </Select>)}
                                    </Form.Item>
                                    <Form.Item label={`证件号码`}>
                                        {getFieldDecorator('idCard', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "请输入证件号码",
                                                },
                                                {
                                                    max:18,
                                                    message: "证件号码最多18位"
                                                },
                                                {
                                                    validator:this.IsCard
                                                }
                                            ]
                                        })(<Input  className="input" maxLength={100} disabled={isDisable=== false}/>)}
                                        <span className="limited">{this.props.form.getFieldValue(`idCard`) ? this.props.form.getFieldValue(`idCard`).length : 0}/18</span>
                                    </Form.Item>
                                    <div className="modaltitle">
                                        机构信息
                                    </div>
                                    <Form.Item label={`工号/帐号`}>
                                        <div className="all">
                                            {/*<div className="totle">*/}
                                            {/*    <span className='red'>*</span><span className="title">工号/帐号</span></div>*/}

                                            {getFieldDecorator('jobNum', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写工号/帐号",
                                                    },
                                                    {
                                                        max:15,
                                                        message: "工号/帐号最多15位"
                                                    },
                                                ],
                                            })(<Input placeholder="请输入工号" style={{ width: 330 , marginTop:"4px"}} disabled={isDisable=== false}/>)}
                                            <span className="limited">{this.props.form.getFieldValue(`jobNum`) ? this.props.form.getFieldValue(`jobNum`).length : 0}/15</span>
                                        </div>
                                    </Form.Item>
                                    <Form.Item className='date_input' label={`入职日期`}>
                                        <div className="all" style={{width: '330px'}}>
                                            {/*<div className="totle">*/}
                                            {/*    <span className="title">入职日期</span></div>*/}
                                                {getFieldDecorator('inductionTime ', {
                                                    initialValue: inductionTime? moment(inductionTime, 'YYYY-MM-DD') : null
                                                })(<DatePicker placeholder='请选择入职日期' style={{width: '100%'}} disabled={isDisable=== false}/>)}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={`职称`}>
                                        {
                                            getFieldDecorator('professionalCall', {
                                                initialValue: undefined,
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: '职称不能为空'
                                                    }
                                                ]
                                            })(<Select placeholder='请选择职称' disabled={isDisable=== false}>
                                                {
                                                    professionalCallList && professionalCallList.map(v => {
                                                        return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                    })
                                                }
                                            </Select>)
                                        }
                                    </Form.Item>
                                    <Form.Item label={`负责角色`}>
                                        {
                                            getFieldDecorator('roleId', {
                                                initialValue: undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '角色名称不能为空'
                                                    }
                                                ]
                                            })(<Select placeholder='请选择角色名称' disabled={isDisable=== false}>
                                                {
                                                    yuanxiaoSel && yuanxiaoSel.map(v => {
                                                        return <Option value={v.roleId} key={v.roleId}>{v.roleName}</Option>
                                                    })
                                                }
                                            </Select>)
                                        }
                                    </Form.Item>
                                    <Form.Item label={`邮箱`}>
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "请输入邮箱",
                                                },
                                                {
                                                    max:50,
                                                    message: "邮箱最多50位"
                                                },
                                                {
                                                    validator:this.validateInvestmail
                                                }
                                            ]
                                        })(<Input  className="input" maxLength={100} disabled={isDisable=== false}/>)}
                                        <span className="limited">{this.props.form.getFieldValue(`email`) ? this.props.form.getFieldValue(`email`).length : 0}/50</span>
                                    </Form.Item>
                                    <Form.Item label={`职务`}>
                                        {getFieldDecorator('position', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: `请输入职务`,
                                                },
                                                {
                                                    max:30,
                                                    message: "职务最多30位"
                                                },
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入职务" style={   { width: 330 , marginTop:"4px"}} disabled={isDisable=== false}/>)}
                                        <span className="limited">{this.props.form.getFieldValue(`position`) ? this.props.form.getFieldValue(`position`).length : 0}/30</span>
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <Button className="buttonr" onClick={() =>{this.toTeacherrecord()}}><img src={require('@/assets/img/lead.png')} alt="" />导入记录</Button>
                        </div>
                        <div className="right_list">
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入成员工号/名字' />
                        </div>
                    </div>
                    <div className="tablelist">
                        <Table rowKey={v => v['enterpriseId']} pagination={
                            {
                                total,
                                pageSize:pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['5', '10', '20', '40'],
                                showSizeChanger: true,
                                // position:'bottomRight'
                            }
                        } columns={columns} dataSource={dataSource} />
                        {/*<Table columns={columns} dataSource={dataSource} scroll={{ x: 2000}} />,*/}
                    </div>
                </div>
                <Modal className='batch' visible={batch} title='学校信息导入'
                       closable={false}
                       footer={
                           <div style={{ width: "100%" }}>
                               <div className='pos-btn'>
                                   <Button onClick={this.closeBatch}>取消</Button>
                                   <Button disabled={btnActive ? false : true} className={btnActive ? "" : "gray"}
                                           onClick={this.commitBatch}>确定</Button>
                               </div>
                               {errorMessage ?
                                   <div className='error'>
                                       <span>请下载查看</span>
                                       <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                   </div>
                                   : null}
                           </div>
                       }
                >
                    <Batch
                        ref={ref => this.batchRef = ref}
                        getFormData={this.getFormData}
                        sure={this.sure}
                        reset={() => this.setState({ errorMessage: '', btnActive: false })}
                        template={downTemplate(1)}
                        action={generalImport}
                        pullError={(val) => this.setState(val)}
                        titleType='管理机构成员'
                    />
                </Modal>
            </div>
        )
    }
}

index = Form.create()(index);
export default index;
