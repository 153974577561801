import React, { Component } from 'react'
import { Input, Table, Pagination, Button, Select, Modal } from 'antd';
import iconType from './icon'
import S from "gongyongicon"
import { getPlatformUserPage, deleteStudent, resetUserPassword, setIdentity, delTeacher, selectEnterpriseType, selectIdentityType } from '../../api/organization_member_management';
import RenderButton from "../../../../components/renderButton";
import styles from "./index.module.scss"
import FilterTableData from '@/page/authorityManagement/components/FilterTableData/FilterTableData.js';
import ViewStudentInformation from '../../components/view_student_information';
import ViewTeacherInformation from '../../components/view_teacher_information';

export default class index extends Component {
    state = {
        dataSource: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        isZhanKai: 'false',
        visible: false,
        optionList: [], //机构类型
        userIdentity: [],    //用户身份
        searchField: {
            enterpriseName: '',
            enterpriseType: undefined,
            identityId: undefined,
            key: ''
        },
        selectStatus: [ //选择身份
            {
                name: "管理员",
                btnText: '选择',
                img: 'OBS_woman.png',
                isShow: 0,
                id: 71
            },
            {
                name: "门户管理员",
                btnText: '选择',
                img: 'OBS_man.png',
                isShow: 0,
                id: 75
            }
        ],
        selectStatusArr: [], //身份id
        userId: null, //用戶id
        enterpriseId: '',
        studentVisible: false,
        teacherVisible: false,
    }
    componentDidMount() {
        this.getPlatformUserPage();
        this.selectEnterpriseType();
        this.selectIdentityType();
    }
    getPlatformUserPage = async () => {
        const { pageNum, pageSize, searchField } = this.state;
        let params = {
            pageNum: pageNum,
            pageSize: pageSize,
            ...searchField
        }
        try {
            let res = await getPlatformUserPage(params);
            this.setState({
                dataSource: res.data,
                total: res.total
            });
        } catch (error) { }
    };

    //机构类型
    selectEnterpriseType = async () => {
        try {
            let res = await selectEnterpriseType();
            let newOption = [];
            if (res.length > 0) {
                res.forEach(i => {
                    newOption.push({ code: i.code, desc: i.msg })
                });
                this.setState({
                    optionList: newOption
                })
            }

        } catch (error) { }
    }
    //用户身份
    selectIdentityType = async () => {
        try {
            let res = await selectIdentityType();
            let userIdentity = [];
            if (res.length > 0) {
                res.forEach(i => {
                    userIdentity.push({ code: i.code, desc: i.msg })
                });
                this.setState({
                    userIdentity: userIdentity
                })
            }

        } catch (error) { }
    }
    //分页
    pageChange = (pageNum, pageSize) => {
        this.setState({ pageNum, pageSize }, () => {
            this.getPlatformUserPage();
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({ pageNum: 1, pageSize }, () => {
            this.getPlatformUserPage();
        })
    };

    //删除
    delete = (item) => {
        this.hint({
            type: 'warning',
            title: '温馨提示',
            content: '您确定要删除该用户吗？',
            isCancel: true,
            sure: async () => {
                if (item.identityId === '73') {
                    try {
                        const res = await deleteStudent({ organization: item.organization, id: item.id })
                        if (res) {
                            this.getPlatformUserPage();
                            this.mesSuccess('删除成功');
                        }
                    } catch (error) {
                        this.mesWarning('删除失败');
                    }
                } else {
                    try {
                        const res = await delTeacher({ enterpriseId: item.organization, userId: item.id, })
                        if (res) {
                            this.getPlatformUserPage();
                            this.mesSuccess('删除成功');
                        }
                    } catch (error) {
                        this.mesWarning('删除失败');
                    }
                }
            }
        });
    }

    //重置密码
    resetpassword = (item) => {
        this.hint({
            type: 'warning',
            title: '温馨提示',
            content: '您确定要将此用户密码重置吗？重置后密码为Admin123456',
            isCancel: true,
            sure: async () => {
                try {
                    const res = await resetUserPassword({ enterpriseId: item.organization, userId: item.id })
                    if (res) {
                        this.mesSuccess('重置成功');
                    }
                } catch (error) {
                    this.mesWarning('重置失败');
                }
            }
        })
    };

    // 选择身份
    setStatus = (item) => {
        const { selectStatus, selectStatusArr } = this.state;
        if (!item.isShow) {
            item.isShow = 1;
            selectStatusArr.push(item.id)
            this.setState({
                selectStatus,
                selectStatusArr
            });
        } else {
            item.isShow = 0;
            if (selectStatusArr.length > 0 && selectStatusArr.includes(item.id)) {
                this.setState({
                    selectStatus,
                    selectStatusArr: selectStatusArr.filter(i => i !== item.id)
                });
            }

        }
    }
    //设置身份弹框
    identity = (item) => {
        this.setState({
            visible: true,
            userId: item.id
        });
    };
    // 清空身份状态
    deleteStatus = () => {
        const { selectStatusArr, userId, selectStatus } = this.state;
        selectStatus.forEach(i => {
            i.isShow = 0;
        })
        this.setState({
            visible: false,
            selectStatus,
            selectStatusArr: [],
            userId: null
        });
    }
    //确认身份
    handleOk = e => {
        const { selectStatusArr, userId, selectStatus } = this.state;
        if (selectStatusArr.length > 0) {
            selectStatusArr.unshift(72);
            this.setState({
                selectStatusArr
            }, async () => {
                const params = {
                    identityIds: selectStatusArr.join(','),
                    id: userId
                };
                try {
                    let res = await setIdentity(params);
                    if (res) {
                        this.deleteStatus();
                        this.getPlatformUserPage();
                    }
                } catch (error) { }
            })
        } else {
            this.mesWarning('请选择身份');
        }


    };
    classifyChange = (value) => {
        this.setState({
            classifyValue: value
        })
    };
    //展开收起
    _iszhankaiFn = (isZhanKai) => {
        this.setState(() => {
            return {
                isZhanKai: !isZhanKai
            }
        })
    }
    columns = [
        {
            title: '姓名',
            dataIndex: 'fullName',
            key: 'fullName',
            align: 'center',
        },
        {
            title: '工号/学号',
            dataIndex: 'jobNum',
            key: 'jobNum',
            align: 'center',
            render: (text, item) => text || '--',
        },

        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            align: 'center',
            render: (text, item) => text === 0 ? "女" : "男",
        },

        {
            title: '手机号',
            dataIndex: 'telNum',
            key: 'telNum',
            align: 'center',
            render: (text, item) => text || '--',
        },
        {
            title: '用户身份',
            dataIndex: 'identityName',
            key: 'identityName',
            align: 'center',

        },
        {
            title: '所属机构',
            dataIndex: 'enterpriseName',
            key: 'enterpriseName',
            align: 'center',
        },
        {
            title: '机构类型',
            dataIndex: 'enterpriseTypeName',
            key: 'enterpriseTypeName',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '320px',
            render: (text, item, index) => {
                const { organization, id } = item;
                return <div style={{ textAlign: "left" }}>
                    <RenderButton id='OBSMM-01-A04-b-btnShowData'>
                        <span className="but" onClick={() => {
                            if (item.identityId === '73') {
                                this.setState({
                                    userId: id,
                                    enterpriseId: organization,
                                    studentVisible: true,
                                })
                            } else {
                                this.setState({
                                    userId: id,
                                    enterpriseId: organization,
                                    teacherVisible: true,
                                })
                            }
                        }}>查看</span>
                    </RenderButton>
                    <RenderButton id='OBSMM-01-A04-b-btnUpdataUserInformation'>
                        <span className="but" onClick={() => {
                            if (item.identityId === '73') {
                                this.props.history.push(`/main/institutional-settlement/platform_user_student_updata?id=${organization}&userId=${id}`)
                            } else {
                                this.props.history.push(`/main/institutional-settlement/platform_user_teacher_updata?id=${organization}&userId=${id}`)
                            }
                        }}>修改</span>
                    </RenderButton>
                    <RenderButton id='OBSMM-01-A04-b-btnDelete'>
                        <span className="but" onClick={() => this.delete(item)}>删除</span>
                    </RenderButton>
                    <RenderButton id='OBSMM-01-A04-b-btnResetPassword'>
                        <span className="but" onClick={() => this.resetpassword(item)}>重置密码</span>
                    </RenderButton>
                    {item.identityId === '73' ? null : <span className="but" onClick={() => this.identity(item)} >设置身份</span>}
                </div>
            }
        },
    ];
    // 查询
    search = () => {
        this.getPlatformUserPage();
    }
    // 重置

    reset = () => {
        let { searchField } = this.state;
        this.setState({
            searchField: {
                enterpriseName: '',
                enterpriseType: undefined,
                identityId: undefined,
                key: ''
            },
        }, () => {
            this.getPlatformUserPage();
        })
    }
    render() {
        const {
            dataSource, pageSize, pageNum, total, isZhanKai, selectStatus, searchField, optionList, userIdentity,
            enterpriseId, studentVisible, teacherVisible, userId,
        } = this.state;
        return (
            <div className={styles["student_list"]}>
                <div className="material_screen">
                    <FilterTableData
                        className='mmmm'
                        style={{ border: 'none', padding: 0 }}
                        search={this.search}
                        reset={this.reset}
                        topFilterList={
                            [
                                {
                                    label: '机构类型',
                                    type: 'select',
                                    optionList: optionList && optionList,
                                    props: {
                                        placeholder: '请选择机构类型',
                                        value: searchField.enterpriseType,
                                        onChange: (e) => {
                                            searchField.enterpriseType = e
                                            this.setState({
                                                searchField
                                            })
                                        }
                                    }
                                },
                                {
                                    label: '用户身份',
                                    type: 'select',
                                    optionList: optionList && userIdentity,
                                    props: {
                                        placeholder: '请选择用户身份',
                                        value: searchField.identityId,
                                        onChange: (e) => {
                                            searchField.identityId = e
                                            this.setState({
                                                searchField
                                            })
                                        }
                                    }
                                },
                                {
                                    label: '所属机构',
                                    type: 'input',
                                    optionList: [],
                                    props: {
                                        placeholder: '请输入所属机构',
                                        value: searchField.enterpriseName,
                                        onChange: (e) => {
                                            searchField.enterpriseName = e.target.value
                                            this.setState({
                                                searchField
                                            })
                                        }
                                    }
                                }
                            ]
                        }
                        bottomFilterList={
                            [
                                {
                                    label: '搜索用户',
                                    type: 'input',
                                    optionList: [],
                                    props: {
                                        placeholder: '请输入文件名称或关键字',
                                        value: searchField.key,
                                        onChange: (e) => {
                                            searchField.key = e.target.value
                                            this.setState({
                                                searchField
                                            })
                                        }
                                    }
                                }
                            ]
                        }
                    />
                </div>
                <div className='Shared_bottom'>
                    <div className='filetypebox'>
                        <span style={{ color: "#888" }}>
                            共{total}人
                        </span>
                    </div>
                    <div className='student_list'>
                        <Table
                            dataSource={dataSource}
                            columns={this.columns}
                            pagination={false}
                            locale={{
                                emptyText: <img src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                            }}
                            footer={() => {
                                return <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                    onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                    onChange={(p, n) => this.pageChange(p, n)}
                                />
                            }}
                        />
                    </div>

                </div>
                <ViewTeacherInformation
                    enterpriseId={enterpriseId}
                    userId={userId}
                    visible={teacherVisible}
                    onCancel={() => this.setState({ teacherVisible: false })}
                />
                <ViewStudentInformation
                    enterpriseId={enterpriseId}
                    userId={userId}
                    visible={studentVisible}
                    onCancel={() => this.setState({ studentVisible: false })}
                />
                <Modal
                    title="选择身份"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.deleteStatus}
                    closable={false}
                    width={880}
                    className='setidentity'
                    getContainer={false}
                >
                    <div className='student_setidentity'>
                        {
                            selectStatus.map((item, key) => {
                                return <div
                                    key={key}
                                    className={[`${item.isShow === 1 ? 'student_box' : ''} student_hover`]}
                                >
                                    <img src={window.$$setImgSrc(item.img)} alt="" />
                                    <span>{item.name}</span>
                                    <Button
                                        onClick={() => this.setStatus(item)}
                                    >
                                        {item.isShow === 1 ? '已选择' : item.btnText}
                                    </Button>
                                </div>
                            })
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}
