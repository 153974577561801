// 新增推送
import React, { Component } from 'react'
import './index.scss';
import {Form, Select, Button, Row, Col, Input, Modal, Table, Transfer,Checkbox, message, Tag, Tooltip} from 'antd';
import {getPlatformList, getChoosePushNewsList, getPushOrganizationList, addSystemPushNews} from "../../../../api/zixun";
import RenderButton from "../../../../components/renderButton";
import difference from 'lodash/difference';
import  RenderImg from '@/components/renderImg';
import S from 'gongyongicon'
import TopTitle from "../../../../components/Breadzx";

const { Option } = Select;
const { Search } = Input;
const topTitleData = {
    span_1: "资讯中心",
    span_2: "推送管理",
    span_3: " 新增推送",
    span_1_c: "#333",
    span_2_c: "#333",
    container: "新增推送",
};

function handleChange(value) {
    console.log(`selected ${value}`);
}
function callback(key) {
    console.log(key);
}
function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
}

class AddPush extends Component {
    state = {
        pagination : {
            pageNum: 1,
            pageSize: 5,
            total: 0,
            current: 1,
        },
        userId : JSON.parse(localStorage.getItem("userInfo")).id,
        userName : JSON.parse(localStorage.getItem("userInfo")).fullName,
        idNews:false,
        objectModal:false,
        platformList :[],
        newsTitle : '',
        platform : '',
        pushOrganType : '',
        enterpriseName : '',
        pushNewsList : [],
        pushNewsNum : 0,
        pushNews : null,
        radioNews : null,
        objList : [],
        columns: [
            {title: '新闻名称', dataIndex: 'newsTitle', key: 'newsTitle', align: 'center', ellipsis: true,},
            {title: '作者', dataIndex: 'authorName', key: 'authorName', align: 'center',},
            {title: '发布时间', dataIndex: 'releaseTime', key: 'releaseTime', align: 'center',},
            {title: '操作', align: 'center', width: 220,
                render: (text, record) => {
                    return <p className='action vertical_j'>
                        <RenderButton id='NC_01_B12_c_btnlook'>
                            <span onClick={() =>this.lookNewsDetail(record)}>查看</span>
                        </RenderButton>
                    </p>
                }
            }
        ],
        list: [
            {title: '机构名称', dataIndex: 'enterpriseName', key: 'enterpriseName', align: 'left',ellipsis: true},
        ],
        mockData: [],
        assignedClasses: [],   // 已分配班级
        selectedRowKeys: [],// 选择的标签
        selectedRows: [],// 选择的标签
        disabled: false, // 是否可选择
        ids : [],
        isSelectAll : false,
    }

    componentDidMount() {
        let platformData = {
            queryType : 2,
            organizationId : ''
        }
        getPlatformList(platformData).then(res =>{
            if(res){
                this.setState({
                    platformList : res
                })
            }
        })
    }

    lookNewsDetail = (record) =>{
        const w = window.open('about:blank');
        w.location.href=`/#/main/group-column/lookRelationNews?${decodeURI(JSON.stringify({ newsId: record.newsId }))}`
        // this.props.history.push(`/main/group-column/lookNews?${decodeURI(JSON.stringify({ newsId: record.newsId }))}`)
    }

    changePlatform = (e) =>{
        console.log("e::", e)
        this.setState({
            platform : e,
            assignedClasses : [],
            objList : [],
            ids : []
        })
    }

    changePushOrganType = (e) =>{
        console.log("e::", e)
        this.setState({
            pushOrganType : e,
            assignedClasses : [],
            objList : [],
            ids : []
        })
    }

    handNews = () =>{
        this.setState({
            idNews:true,
        }, () =>{
            this.getPushNewsList();
        })
    }

    getPushNewsList = () =>{
        let pagination = this.state.pagination
        let data = {
            newsTitle: this.state.newsTitle,
            pageNum: this.state.pagination.pageNum,
            pageSize: this.state.pagination.pageSize
        }
        getChoosePushNewsList(data).then(res =>{
            console.log("pushNewsList",res)
            if(res){
                pagination.total = res.total
                this.setState({
                    pushNewsList : res.data,
                    pushNewsNum : res.total,
                    pagination : pagination
                })
            }
        })
    }

    handObject = () =>{
        if(this.state.platform === ''){
            // message.destroy()
            this.mesWarning("请选择归属业务系统")
            return
        }
        if(this.state.pushOrganType === ''){
            // message.destroy()
            this.mesWarning("请选择推送对象类型")
            return
        }
        this.setState({
            objectModal:true,
        }, () =>{
            this.getOrganizationList()
        })
    }

    getOrganizationList = () =>{
        let data = {
            platform : this.state.platform,
            enterpriseType : this.state.pushOrganType,
            enterpriseName : this.state.enterpriseName,
        }
        getPushOrganizationList(data).then(res =>{
            console.log("mockData",res)
            if(res){
                this.setState({
                    mockData : res
                })
            }
        })
    }

    handClear = () =>{
        this.setState({
            idNews:false,
            objectModal:false
        })
    }

    deleteEnterpriseName = (v,index) =>{
        console.log("deleteEnterpriseName", v)
        let objList = this.state.objList
        let assignedClasses = this.state.assignedClasses
        let ids = this.state.ids
        ids.splice(v.id,1)
        objList.splice(index,1)
        assignedClasses.splice(v,1)
        this.setState({
            objList,
            assignedClasses,
            ids
        })
    }

    onSearchNews = (newsTitle) => {
        console.log("newsTitle", newsTitle)
        let pagination = this.state.pagination
        pagination.pageNum = 1
        pagination.pageSize = 5
        pagination.total = 0
        this.setState({
            newsTitle : newsTitle,
            pagination: pagination
        }, () => this.getPushNewsList())
    }

    handChooseNews = () =>{
        let radioNews =  this.state.radioNews
        console.log("radioNews", this.state.radioNews)
        this.setState({
            idNews:false,
            pushNews : radioNews
        },() =>{
            console.log("pushNews", this.state.pushNews)
        })
    }

    clearPushNews = () =>{
        this.setState({
            // radioNews: null,
            pushNews : null
        },() =>{
            console.log("pushNews", this.state.pushNews)
            console.log("radioNews", this.state.radioNews)
        })
    }

    changePage = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination},()=> this.getPushNewsList())
    }

    showDeleteConfirm = () => { // 确认推送提示
        if(this.state.platform === ''){
            // message.destroy()
            this.mesWarning("请选择归属业务系统")
            return
        }
        if(this.state.pushOrganType === ''){
            // message.destroy()
            this.mesWarning("请选择推送对象类型")
            return
        }
        if( this.state.pushNews === null){
            // message.destroy()
            this.mesWarning("请选择推送新闻")
            return
        }
        if( this.state.objList.length === 0){
            // message.destroy()
            this.mesWarning("请选择推送对象")
            return
        }
        this.hint({
            'title': '温馨提示',
            'content': `请确认是否推送【${this.state.pushNews.newsTitle}】这篇新闻`,
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': this.addSystemPushNews
        })
    }

    addSystemPushNews = () =>{
        let organList = []
        this.state.objList.forEach(item =>{
            let organ = {
                id : item.id,
                enterpriseName : item.enterpriseName
            }
            organList.push(organ)
        })
        let data = {
            pushOrganType : this.state.pushOrganType,
            newsId : this.state.pushNews.newsId,
            pushUserId : this.state.userId,
            pushUserName : this.state.userName,
            organList : organList,
        }
        addSystemPushNews(data).then(res =>{
            if(res){
                this.props.history.goBack()
            }
        })
    }

    newsRadioChange = (v, e) => {
        console.log("newsRadioChange", v)
        console.log("newsRadioChange", e)
        this.setState({
            radioNews: e[0]
        })
    }

    addOrgan = () =>{
        let assignedClasses = this.state.assignedClasses
        let ids = this.state.ids
        let selectedRowKeys = this.state.selectedRowKeys
        this.state.selectedRows.forEach(item =>{
            let index = selectedRowKeys.indexOf(item.id)
            selectedRowKeys.splice(index,1)
            let resultId = ids.find(e =>e === item.id)
            if(!resultId){
                ids.push(item.id)
            }
            let data = {
                id : item.id,
                enterpriseName : item.enterpriseName,
            }
            let result = assignedClasses.find(e =>e.id === item.id)
            if(!result){
                assignedClasses.push(data)
            }
            this.setState({
                assignedClasses,
                ids,
                selectedRowKeys
            },() =>{
                console.log("selectedRowKeys", selectedRowKeys)
            })
            if(ids.length >= this.state.mockData.length){
                this.setState({
                    disabled : true,
                    isSelectAll : false
                })
            }else {
                this.setState({
                    disabled : false,
                })
            }
        })
    }

    removeOrgan = (e) =>{
        console.log("e" ,  e)
        let assignedClasses = this.state.assignedClasses
        let ids = this.state.ids
        let index = assignedClasses.indexOf(e)
        let id = ids.indexOf(e.id)
        assignedClasses.splice(index, 1);
        ids.splice(id, 1);
        this.setState({
            assignedClasses,
            ids,
            disabled : false,
            isSelectAll : false
        })
    }

    handChooseEnterprise = () =>{
        if(this.state.assignedClasses.length === 0){
            this.mesWarning("请选择推送的机构")
            return
        }
        let objList = []
        if(this.state.assignedClasses.length > 0){
            this.state.assignedClasses.forEach(item =>{
                objList.push(item)
            })
        }
        this.setState({
            objectModal : false,
            objList : objList,
        })
    }

    setOrganName = ({target: {value}}) =>{
        console.log("this.searchNews", value)
        this.setState({
            enterpriseName :value
        })
    }

    searchNews = () =>{
        this.getOrganizationList()
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        let isSelectAll = false
        let arr = []
        if(this.state.mockData.length > 0){
            this.state.mockData.forEach(item =>{
                let result = selectedRowKeys.find(e => e === item.id)
                if(result){
                    arr.push(item)
                }
            })
        }
        if(arr.length === (this.state.mockData.length - this.state.ids.length)){
            isSelectAll = true
        }
        this.setState({
            selectedRowKeys,
            selectedRows,
            isSelectAll
        });
    }

    onSelectALL = e => { // 点击全选
        console.log("this.state.ids", this.state.ids)
        if (e.target.checked) {
            let selectedRowKeys = this.state.selectedRowKeys
            let selectedRows = this.state.selectedRows
            this.state.mockData.forEach(item =>{
                let result = this.state.ids.find(e =>e === item.id)
                if(!result){
                    selectedRowKeys.push(item.id)
                    selectedRows.push(item)
                }
            })
            this.setState({
                selectedRowKeys,
                selectedRows,
                isSelectAll : true
            });
        } else {
            this.setState({
                selectedRowKeys: [],
                isSelectAll : false
            });
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let {idNews,columns,objectModal, pagination,list,assignedClasses,selectedRowKeys,mockData} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps : record => ({
                disabled: this.state.ids.includes(record.id)
            })
        };
        const hasSelected = selectedRowKeys.length > 0;
        console.log(this.state.objList,'this.state.objList')
        return (
            <div style={{height: 'calc(100% - 100px)'}}>
                <TopTitle {...topTitleData} />
            <div className='add_push_box'>
                {/*<div className="title">*/}
                {/*    新增推送*/}
                {/*</div>*/}

                <Form>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>归属业务模块</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('platform', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '业务模块不能为空'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择业务模块' onChange={e => this.changePlatform(e)}>
                                            {
                                                this.state.platformList.map(p => <Option key={p.platform} value={p.platform}>{p.platformName}</Option>)
                                            }
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>推送对象类型</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('pushOrganType', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '对象类型不能为空'
                                            }
                                        ]
                                    })(
                                        //推送机构类型：1院校，2企业，3教育局，4、联盟，5、管理机构，6、虚拟机构
                                        <Select placeholder='请选择对象类型' onChange={e => this.changePushOrganType(e)}>
                                            <Option value="1">职业院校</Option>
                                            <Option value="2">普通企业</Option>
                                            {/*<Option value="3">教育局</Option>*/}
                                            {/*<Option value="4">联盟</Option>*/}
                                            {/*<Option value="5">管理机构</Option>*/}
                                            <Option value="6">虚拟机构</Option>
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>推送新闻</span></Col>
                            <Col span={10}>
                                <div className="push_news">
                                    <RenderButton id='NC_01_B13_c_btnselectbews'>
                                        <Button onClick={this.handNews}>
                                            {/*<img src={require('../../../../assets/img/zixun/ts_icon.png')}/>*/}
                                            <S.Choose_topic/>
                                            选择新闻
                                        </Button>
                                    </RenderButton>
                                    {/*<Button onClick={this.handNews}><img src={require('../../../../assets/img/zixun/ts_icon.png')}/>选择新闻</Button>*/}
                                </div>
                                {
                                    this.state.pushNews !== null ?
                                        <div className="push_ul">
                                            <div className="li_box">
                                                <div className="left-img">
                                                    {/*<img src={window.$$img(this.state.pushNews.newsImg)}/>*/}
                                                    {/*<RenderImg size='m' id={this.state.pushNews.newsImg}/>*/}
                                                    {
                                                        this.state.pushNews.newsImg?
                                                            <RenderImg size='m' id={this.state.pushNews.newsImg}/>
                                                            :<img
                                                                src={require("../../../../assets/img/images/default-img.png")}
                                                                alt=""
                                                            />
                                                    }
                                                </div>
                                                <div className="right-text">
                                                    <h3>{this.state.pushNews.newsTitle}</h3>
                                                    <div className="dov">
                                                        {this.state.pushNews.newsContent}
                                                    </div>
                                                    <div className="link">
                                                        <h5><i>{this.state.pushNews.newsType === 1 ? "原创" : "转载"}
                                                        </i>来源：{this.state.pushNews.newsType === 1 ? this.state.pushNews.authorName : this.state.pushNews.articleSource}</h5>
                                                        <span>{this.state.pushNews.releaseTime}</span>
                                                    </div>
                                                </div>
                                                <b className='icon'><img src={require('../../../../assets/img/zixun/ch_icon.png')} onClick={this.clearPushNews}/></b>
                                            </div>
                                        </div> : ''
                                }

                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>推送对象名称</span></Col>
                            <Col span={10}>
                                <div className="push_news">
                                    <RenderButton id='NC_01_B13_c_btnSelectobject'>
                                        <Button onClick={this.handObject}>
                                            {/*<img src={require('../../../../assets/img/zixun/ts_icon.png')}/>*/}
                                            <S.Choose_topic/>
                                            选择推送对象</Button>
                                    </RenderButton>
                                    {/*<Button onClick={this.handObject}><img src={require('../../../../assets/img/zixun/ts_icon.png')}/>选择推送对象</Button>*/}
                                </div>
                                <div className="pushtitle_ul">
                                    {
                                        this.state.objList.length > 0 ?
                                            (
                                                this.state.objList.map((v, index) => (
                                                    <div key={v.id} className="li_push">{v.enterpriseName}
                                                        <img src={require('../../../../assets/img/zixun/ch_icon1.png')} onClick={() => this.deleteEnterpriseName(v,index)}/>
                                                    </div>
                                                ))
                                            )
                                            : ''
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Modal
                        width="800px"
                        visible={idNews}
                        title="推送新闻"
                        className="Modal-Updatenews"
                        maskClosable={false}
                        keyboard={false}
                        closable={false}
                        footer={
                            <div className="modal_footer">
                                <Button onClick={this.handClear}>取消</Button>
                                <Button type="primary" onClick={this.handChooseNews}>确定</Button>
                            </div>
                        }
                    >
                        <div className="updatenews-cont">
                            <div className="sech_top">
                                <Search className="lcl_left" onSearch={this.onSearchNews} placeholder='请输入新闻标题或作者名称' />
                                <span className='all_total'>共{this.state.pushNewsNum}条新闻</span>
                            </div>
                            <div className="update_table">
                                {
                                    columns?(
                                        <Table
                                            className='organ-table'
                                            columns={columns}
                                            dataSource={this.state.pushNewsList}
                                            pagination = {
                                                {
                                                    pageNum: pagination.pageNum,
                                                    pageSize: pagination.pageSize,
                                                    total: pagination.total,
                                                    current: pagination.pageNum,
                                                    onChange: this.changePage,
                                                }
                                            }
                                            rowSelection={{type: "radio",onChange : this.newsRadioChange}}
                                            rowKey={(v) => v.newsId}
                                        />
                                    ):(
                                        <div className="Empty-conet">
                                            <div className="img">
                                                <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                                <span>暂无数据</span>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </Modal>
                    <Modal
                        width="1000px"
                        visible={objectModal}
                        className="Modal-handObject"
                        maskClosable={false}
                        keyboard={false}
                        closable={false}
                        footer={
                            <div className="modal_footer">
                                <Button onClick={this.handClear}>取消</Button>
                                <Button type="primary" onClick={this.handChooseEnterprise}>确定</Button>
                            </div>
                        }
                    >
                        <div className="updateObject-cont">
                            <Row type="flex" justify="space-between">
                                <Col span={15}>
                                    <h4>推送对象名称</h4>
                                    <div className="bottom-cont">
                                        <div className="sech-right searandTitle">
                                            <b className='glname'>搜索机构</b>
                                            <Search
                                                placeholder="请输入机构名称"
                                                maxLength={20}
                                                onChange={this.setOrganName.bind(this)}
                                                onSearch={this.searchNews}
                                            />
                                        </div>
                                        <div className='all_wapper'>
                                            <Table
                                                rowKey={(v) => v.id}
                                                className='organ-table'
                                                columns={list}
                                                dataSource={mockData}
                                                rowSelection={rowSelection}
                                                pagination={false}
                                                // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
                                                locale={{
                                                    emptyText: <>
                                                        <div className="Empty-conet">
                                                            <div className="img">
                                                                <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                                                <span>暂无数据</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                }}
                                            />
                                            <div className='check_box'>
                                                <Checkbox
                                                    disabled={this.state.disabled}
                                                    onChange={this.onSelectALL}
                                                    checked={this.state.isSelectAll}
                                                >
                                                    全选
                                                </Checkbox>
                                                <Button type="primary" disabled={!hasSelected} onClick={()=>this.addOrgan()}>批量添加</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={9}>
                                    <h4>已添加机构</h4>
                                    <ul className='li_box'>
                                        {
                                            assignedClasses.map((item, index) => {
                                                return (
                                                    <li className='li_list' key={item.id}>
                                                        <div className='li_name'>{item.enterpriseName}</div>
                                                        <img src={require("@/assets/img/zixun/icon-4.png")} onClick={() => this.removeOrgan(item)}/>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className="flex-h4">已选<i>{this.state.assignedClasses.length}</i>个机构</div>
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                </Form>
                <div className="fix_controll">
                    <RenderButton id='NC_01_B13_c_btnpush'>
                        <Button type="primary" onClick={this.showDeleteConfirm} style={{ width: '90px', height: '39px', marginRight: '40px' }} >确认推送</Button>
                    </RenderButton>
                    {/*<Button type="primary" onClick={this.showDeleteConfirm} style={{ width: '90px', height: '39px', marginRight: '40px' }} >确认推送</Button>*/}
                    <Button onClick={() => this.props.history.goBack()} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>
            </div>
            </div>
        )
    }
}


AddPush = Form.create()(AddPush);
export default AddPush;
