// 添加新闻
import React, { Component } from 'react'
import './index.scss';
import {Form, Select, Button, Row, Col, Radio, Input, DatePicker, Tag, Modal, message, Upload, Icon} from 'antd';
import setName from 'classnames';
import {HighEditor} from '../../../../components/global_components/editor/index'
import RenderButton from "../../../../components/renderButton";
import {getPlatformList, selectNewsParentCategory,selectNewsTwoCategory,addNews,selectNewsById,updateNews} from "../../../../api/information";
import {files} from '../../../../api/zixun'
import moment from "moment";
import UploadImg from "../../../../components/upload_img";
import AddTags from "../../../../components/addTags/addTags";
import TopTitle from "../../../../components/Breadzx";
import {uploadFile} from "../../../../api/upload";
import validate from "../../../../config/validate";
import S from 'gongyongicon'
const { Option } = Select;
const { CheckableTag } = Tag;
const {TextArea} = Input;
const topTitleData = {
    span_1: "资讯中心",
    span_2: " 新闻管理",
    span_3: "新增新闻",
    span_1_c: "#333",
    span_2_c: "#333",
    container: "新增新闻",
};


class AddNews extends Component {
    state = {
        tagsData: [],
        tagsDataTemp: [],
        selectedTags: [], // 标签选择数据
        deleteTags: [], // 标签删除数据
        addTagModal: false, // 添加便签弹层
        editTagModal: false, // 编辑标签弹层
        tagBqName: '', // 便签名称
        organizationId: null,
        platformList: [],
        useType: 1,
        parentCategoryList:[],
        twoCategoryList:[],
        form:{},
        newsCon:null,
        id:'',
        columnId:'',
        createUserId: JSON.parse(localStorage.getItem("userInfo")).id,
        platformDisabled:false,
        tagUpdate: true,
        fileId : '',
        mediaType : '',
        isMust:false,
        fileListArr:[],
        fileData:[]
    }
    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        if (param) {
            const codeParam = param.includes("%") ? decodeURI(param) : param;

            const jsonParam = JSON.parse(codeParam);
            console.log('param',jsonParam)
            if(jsonParam.columnId !== undefined){
                this.setState({
                    columnId:jsonParam.columnId
                })
            }
            if(jsonParam.platform !== undefined){
                this.props.form.setFieldsValue({
                    platform:jsonParam.platform
                })
                let form =this.state.form;
                form.platform = jsonParam.platform;
                this.setState({
                    platformDisabled:true,
                    form:form
                },()=>{
                    this.selectNewsParentCategory()
                })
            }
            console.log(this.state.form,'form');
            if(localStorage.getItem('previewNews')){
                if(jsonParam.id !== '' && jsonParam.id !==undefined){
                    this.setState({
                        id:jsonParam.id
                    })
                }
                this.props.form.setFieldsValue({
                    newsContent:JSON.parse(localStorage.getItem('previewNews')).newsContent,
                    newsLabel:JSON.parse(localStorage.getItem('previewNews')).newsLabel === ''?[]:JSON.parse(localStorage.getItem('previewNews')).newsLabel,
                    newsImg:JSON.parse(localStorage.getItem('previewNews')).newsImg,
                    newsAbstract : JSON.parse(localStorage.getItem('previewNews')).newsAbstract,
                },()=>{
                    this.setState(
                        {
                            form:JSON.parse(localStorage.getItem('previewNews')),
                            newsCon:JSON.parse(localStorage.getItem('previewNews')).newsContent,
                            tagsData: JSON.parse(localStorage.getItem('previewNews')).newsLabel.length <1?[]:JSON.parse(localStorage.getItem('previewNews')).newsLabel,
                            tagsDataTemp: JSON.parse(localStorage.getItem('previewNews')).newsLabel.length <1?[]:JSON.parse(localStorage.getItem('previewNews')).newsLabel,
                            selectedTags: JSON.parse(localStorage.getItem('previewNews')).newsLabel.length <1?[]:JSON.parse(localStorage.getItem('previewNews')).newsLabel,
                            fileId:JSON.parse(localStorage.getItem('previewNews')).newsImg,
                            tagUpdate: JSON.parse(localStorage.getItem('previewNews')).newsLabel.length <1?true:false,
                            fileListArr:JSON.parse(localStorage.getItem('previewNews')).fileList?JSON.parse(localStorage.getItem('previewNews')).fileList:[],
                            fileData:JSON.parse(localStorage.getItem('previewNews')).fileList?JSON.parse(localStorage.getItem('previewNews')).fileList:[]
                        },
                        () => {
                            this.getPlatformList()
                            this.selectNewsParentCategory()
                            this.selectNewsTwoCategory()
                        }
                    );
                })
            }else if(jsonParam.id !== '' && jsonParam.id !==undefined){
                selectNewsById(jsonParam.id).then(e => {
                    console.log('detail---------',e.newsLabel)
                    this.props.form.setFieldsValue({
                        newsContent:e.newsContent?e.newsContent:'',
                        newsAbstract:e.newsAbstract,
                        newsLabel:JSON.parse(e.newsLabel?e.newsLabel:"[]"),
                        timerReleaseTime:e.timerReleaseTime,
                        newsImg:e.newsImg,
                        articleLink:e.articleLink,
                    })
                    this.setState(
                        {
                            form:e,
                            id:e.id,
                            newsCon:e.newsContent,
                            tagUpdate: JSON.parse(e.newsLabel?e.newsLabel:"[]").length===0 ?true:false,
                            tagsData: JSON.parse(e.newsLabel?e.newsLabel:"[]"),
                            tagsDataTemp: JSON.parse(e.newsLabel?e.newsLabel:"[]"),
                            selectedTags: JSON.parse(e.newsLabel?e.newsLabel:"[]"),
                            fileId:e.newsImg,
                            mediaType:e.mediaType
                        },
                        () => {
                            this.getPlatformList()
                            this.selectNewsParentCategory()
                            this.selectNewsTwoCategory()
                            this.getFiles(jsonParam.id)
                        }
                    );
                })
                topTitleData.span_3 = '编辑新闻'
                topTitleData.container = '编辑新闻'
            }else {
                this.getPlatformList()
                topTitleData.span_3 = '新增新闻'
                topTitleData.container = '新增新闻'
            }
        }
    }
    //查询文件列表
    getFiles = (id) => {
        files({id: id}).then(e => {
            if (e) {
                let fileArr = []
                for (let i = 0; i < e.length; ++i) {
                    let file = e[i]
                    file.fileUploadStatus = 'success'
                    fileArr.push(file)
                }
                this.setState({
                    fileData: e,
                    fileListArr: fileArr
                })
            }
        })
    }
    //查询一级栏目
    selectNewsParentCategory = async () =>{
        let data={
            organizationId: this.state.organizationId,
            useType:this.state.useType,
            platform: this.state.form.platform
        }
        await selectNewsParentCategory(data).then(e => {
            this.setState({parentCategoryList: e})
        })
    }
    //查询二级栏目
    selectNewsTwoCategory = async () =>{
        let data={
            organizationId: this.state.organizationId,
            useType:this.state.useType,
            parentId: this.state.form.oneCategoryId
        }
        await selectNewsTwoCategory(data).then(e => {
            if(!e.length){
                this.props.form.setFieldsValue({twoCategoryId:null})
            }
            this.setState({twoCategoryList: e})
        })
    }
    //加载业务系统列表
    getPlatformList = async()=>{
        let data={
            organizationId: this.state.organizationId,
            queryType: this.state.useType
        }
        await getPlatformList(data).then(e => {
            var data = e.map((item,index)=>{
                return{
                    platform: item.platform,
                    platformName: item.platformName,
                    disabled:false,
                }
            })
            if(this.state.form.platform){
                data.forEach((i)=>{
                    if(i.platform!==this.state.form.platform){
                        i.disabled = true
                    }
                })
            }
            this.setState({platformList: data})
        })
    }
    // 我的标签--选择
    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        if (nextSelectedTags.length === 0) {
            this.props.form.setFieldsValue({newsLabel: ''})
        } else {
            this.props.form.setFieldsValue({newsLabel: nextSelectedTags})
        }
        this.setState({ selectedTags: nextSelectedTags });
    }

    //业务系统change事件
    platformChange = ({ target: { value } }) =>{
        let temp = this.state.form;
        temp.platform = value;
        temp.oneCategoryId = null;
        temp.twoCategoryId = null;
        this.props.form.setFieldsValue({oneCategoryId:null,twoCategoryId:null})
        this.setState({
            form: temp
        },()=>{
            this.selectNewsParentCategory()
        })
    }
    //一级栏目change事件
    oneCategoryChange = e =>{
        let temp = this.state.form;
        temp.oneCategoryId = e;
        temp.twoCategoryId = null;
        this.props.form.setFieldsValue({twoCategoryId:null})
        console.log(temp,'temp');
        this.setState({
            form: temp
        },()=>{
            this.selectNewsTwoCategory()
        })
    }
    onFocusParentCategory =()=>{
        if(this.props.form.getFieldValue('platform') === undefined){
            message.warning("请选择所属业务系统！");
            return;
        }
    }
    //二级栏目change事件
    twoCategoryChange = e =>{
        let temp = this.state.form;
        temp.twoCategoryId = e;
        this.setState({
            form: temp
        })
    }
    onFocusTwoCategory =()=>{
        if(this.props.form.getFieldValue('platform') === undefined){
            message.warning("请选择所属业务系统！");
            return;
        }
        if(this.props.form.getFieldValue('oneCategoryId'  ) === undefined){
            message.warning("请选择一级栏目！");
            return;
        }
    }
    addTag =()=>{
        this.tagForm.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    tagBqName: values.tagBqName
                })
                let arr = this.state.tagsData;
                if(arr.indexOf(values.tagBqName)!= -1){
                    message.warning("标签已存在！");
                    return;
                }
                arr.push(values.tagBqName)
                let selectedTags = this.state.selectedTags;
                selectedTags.push(values.tagBqName)
                this.setState({
                    tagsData:arr,
                    tagsDataTemp:JSON.parse(JSON.stringify(arr)),
                    selectedTags: JSON.parse(JSON.stringify(selectedTags)),
                    addTagModal: false,
                    tagBqName: '',
                    tagUpdate: false
                },()=>{
                    this.props.form.setFieldsValue({newsLabel: this.state.selectedTags})
                })
            }
        })
    }
    deleteTag =(tag)=>{ // 编辑里的删除操作
        let arrDelete = this.state.tagsDataTemp;
        arrDelete.splice(arrDelete.indexOf(tag),1);
        this.setState({
            tagsDataTemp: arrDelete
        })
    }
    save =(releaseStatus)=>{
        this.setState({
            isMust:true
        },()=>{
            this.props.form.validateFields(['newsContent'], { force: true })
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let data = values;
                    if(!values.newsLabel || values.newsLabel.length===0 || (values.newsLabel.length===1 && !values.newsLabel[0])){
                        data.newsLabel = []
                    }
                    if (this.state.fileId) {
                        data.newsImg = this.state.fileId;
                        data.mediaType = this.state.mediaType
                    }
                    // else {
                    //     data.newsImg = 'base/cbs/image/48016f42c7eb4ac290c40a0e9bf57111.jpg'
                    //     data.mediaType = 'jpg'
                    // }
                    data.id=this.state.id;
                    data.releaseStatus = releaseStatus;
                    data.columnId = this.state.columnId
                    data.createUserId = this.state.createUserId
                    data.organizationId = this.state.organizationId;
                    data.fileList = this.state.fileData
                    if(values.releaseType ===1){
                        let time = new Date(values.timerReleaseTime);
                        let datetime= time.getFullYear() + '-'+((time.getMonth() + 1)<10?0:'')+ (time.getMonth() + 1) + '-' +(time.getDate()<10?0:'')+ time.getDate()+ ' '+(time.getHours()<10?0:'')+time.getHours()+':'+(time.getMinutes()<10?0:'')+time.getMinutes()+':00';
                        data.timerReleaseTime = datetime;
                    }else{
                        data.timerReleaseTime =null
                    }
                    this.setState({
                        form:data
                    },()=>{
                        console.log('data--------',data)
                        if(this.state.id ===''){
                            addNews(data).then(e=>{
                                message.destroy()
                                message.success("保存成功")
                                if(localStorage.getItem('previewNews') != null){
                                    localStorage.removeItem('previewNews')
                                }
                                this.props.history.goBack()
                            })
                        }else{
                            updateNews(data).then(e=>{
                                message.destroy()
                                message.success("修改成功")
                                if(localStorage.getItem('previewNews') != null){
                                    localStorage.removeItem('previewNews')
                                }
                                this.props.history.goBack()
                            })
                        }
                    })
                }
            })
        })
    }
    preview =()=>{
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data = values;
                let date = new Date();
                let year = date.getFullYear();
                let month = date.getMonth()+1;
                let day = date.getDate();
                let hour = date.getHours();
                let minute = date.getMinutes();
                let second = date.getSeconds();
                data.releaseTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
                data.fileList = this.state.fileData;
                data.fileList.forEach((e)=>{
                    e.fileUploadStatus ='success'
                });
                localStorage.setItem('previewNews',JSON.stringify(data));
                this.props.history.push(`/main/group-column/lookNews?${decodeURI(JSON.stringify({ newsId: '' }))}`)
            }
        })
    }
    onChangeReleaseType =e => {
        let formBefore = this.state.form
        formBefore.releaseType= e.target.value
        this.setState({
            form:formBefore
        })
    }

    cancel = ()=>{
        if(localStorage.getItem('previewNews') != null){
            localStorage.removeItem('previewNews')
        }
        this.props.history.goBack()
    }

    saveTags = ()=>{
        this.props.form.setFieldsValue({newsLabel: this.state.tagsDataTemp})
        this.setState({
            editTagModal: false,
            tagsData:JSON.parse(JSON.stringify(this.state.tagsDataTemp)),
            selectedTags:JSON.parse(JSON.stringify(this.state.tagsDataTemp)),
            tagUpdate: this.state.tagsDataTemp.length===0?true:false
        })
    }
    disabledDate(current) {
        return current && current <moment().subtract(1, "days").endOf('day');
    }
    range = (start, end) => {
        let result = [];
        for (let i = start; i <= end; i++) {
            result.push(i);
        }
        return result;
    };
    disabledTime = (dates,partial) => {
        let hours = moment().hours();//0~23
        let minutes = moment().minutes();//0~59
        //当日只能选择当前时间之后的时间点
        if (dates._d.getDate() === moment().date()) {
            if (dates._d.getDate() === moment().date()&&dates._d.getHours()===hours) {
                if(minutes===59){
                    return {
                        disabledHours: () => this.range(0,hours),
                    }
                }else{
                    return {
                        disabledHours: () => this.range(0,hours-1),
                        disabledMinutes: () => this.range(0,minutes)
                    }
                }
            }
            return {
                disabledHours: () => this.range(0,hours-1),
            };
        }
    }

    handleImgChange= (fileId, mediaType) =>{
        console.log("handleImgChange-fileId",fileId)
        console.log("handleImgChange-mediaType",mediaType)
        this.props.form.setFieldsValue({"newsImg":fileId})
        this.setState({
            fileId,
            mediaType
        })
    }
    validatLength = (rule, value, callback) => {
        let valueNew = value.replace(/(<\/?[^>]*>)/g, "").replace(/&nbsp;/g, '')
        if (value && value.length > 70000) {
            callback('不得超过70000字');
        } else {
            callback();
        }

    }
    //上传方法
    multipartUpload = async (options) => {
        let {file} = options
        let {fileListArr} = this.state;
        let tableList = fileListArr
        if ((fileListArr.length + 1) > 3) {
            message.error("附件数量不能超过3")
            return;
        }
        let thisFileName = file.name.substring(0, file.name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        const fileNameIndex = file.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
        const fileNameLen = file.name.length; // 取到文件名长度
        const fileType = file.name.substring(fileNameIndex + 1, fileNameLen); // 截
        if (!this.checkFileType(fileType)) {
            message.error("上传的资源格式不符")
            return;
        }
        if (file.size / 1048576 > 30) {
            this.mesWarning('资源大小不能超过30MB')
            return;
        }
        let ymd = moment(new Date()).format('YYYY-MM-DD');
        ymd = ymd.replace(/-/g, '');
        let localFileId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
        localFileId = `${ymd}/${localFileId}`
        file["localFileId"] = localFileId
        file.fileUploadStatus = 'loading'
        file.fileName = file.name
        tableList.push(file)
        this.setState({
            fileListArr: tableList,
        });
        this.uploadFile(file, localFileId);
    }
    //上传文件
    uploadFile = async (file, localFileId) => {
        const fromData = new FormData()
        fromData.append('file', file)
        fromData.append('folder', undefined)
        uploadFile(fromData).then(res => {
            if (res) {
                this.getResult(res, localFileId);
            }
        })
    }
    //上传完成获取返回结果过
    getResult = (val, localFileId) => {
        let {fileListArr, fileData} = this.state;
        let data = fileData
        let fileList = fileListArr
        for (let i = 0; i < fileList.length; ++i) {
            if (fileList[i].localFileId === localFileId) {
                fileList[i].fileUploadStatus = 'success'
                this.setState({
                    fileListArr: fileList
                })
                break
            }
        }
        data.push({
            localFileId: localFileId,
            fileId: val.fpName,
            fileName: val.originFileName,
            fileSize: (val.fileSize / 1024).toFixed(2),
            fileSuffix: val.mediaType,
        })

        this.setState({fileData: data})
    }
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "doc、docx、pdf、txt"
        return checkType.includes(type)
    }
    //删除资源
    deleteFile = (index) => {
        const {fileListArr,fileData} = this.state
        const arrList = []
        const arrData = []
        fileListArr.forEach((item,i)=>{
            if(index !==i){
                arrList.push(item)
            }
        })
        fileData.forEach((item,i)=>{
            if(index !==i){
                arrData.push(item)
            }
        })
        this.setState({
            fileData: arrData,
            fileListArr: arrList
        })
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let {tagsData,selectedTags,addTagModal,tagBqName,editTagModal,platformList, parentCategoryList, twoCategoryList,tagsDataTemp,form,fileListArr } = this.state;
        return (
            <div style={{height: 'calc(100% - 100px)'}}>
                <TopTitle {...topTitleData} />
            <div className='add_news_box'>
                {/*<div className="title">*/}
                {/*    新增新闻*/}
                {/*</div>*/}
                <Form>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>归属业务板块</span></div>
                            <div className='row_div_right platformBox'>
                                {
                                    getFieldDecorator('platform', {
                                        initialValue: form.platform,
                                        rules: [
                                            {
                                                required: true,
                                                message: '归属业务板块不能为空'
                                            }
                                        ]
                                    })(
                                        <Radio.Group onChange={this.platformChange}  buttonStyle="solid" className='platform_radio'>
                                            { platformList.length ? platformList.map((item, index) => {
                                                 return <Radio.Button key={index} value={item.platform} disabled={item.disabled}>{item.platformName}</Radio.Button>
                                              }):''}
                                        </Radio.Group>
                                        //<Select placeholder='请选择业务系统' onChange={(e) => this.platformChange(e)} disabled={this.state.platformDisabled}>
                                            //{platformList.length ? platformList.map((item, index) => {
                                                //return <Option key={item.platform} value={item.platform}>{item.platformName}</Option>
                                            //}) : ""}
                                        //</Select>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>所属一级栏目</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('oneCategoryId', {
                                        initialValue: form.oneCategoryId,
                                        rules: [
                                            {
                                                required: true,
                                                message: '所属一级栏目不能为空'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择一级栏目' onChange={(e) => this.oneCategoryChange(e)} onFocus={this.onFocusParentCategory}
                                                getPopupContainer={triggerNode => triggerNode.parentNode || document.body}>
                                            {parentCategoryList.length ? parentCategoryList.map((item, index) => {
                                                return <Option key={item.id} value={item.id}>{item.categoryName}</Option>
                                            }) : ""}
                                        </Select>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'></span>所属二级栏目</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('twoCategoryId', {
                                        initialValue: form.twoCategoryId,
                                        rules: [
                                        ]
                                    })(
                                        <Select placeholder='请选择二级栏目' onChange={(e) => this.twoCategoryChange(e)} onFocus={this.onFocusTwoCategory}
                                        getPopupContainer={triggerNode => triggerNode.parentNode || document.body}>
                                            {twoCategoryList.length ? twoCategoryList.map((item, index) => {
                                                return <Option key={item.id} value={item.id}>{item.categoryName}</Option>
                                            }) : ""}
                                        </Select>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>新闻标题</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('newsTitle', {
                                        initialValue: form.newsTitle,
                                        rules: [
                                            {
                                                required: true,
                                                message: '新闻标题不能为空'
                                            },
                                            {
                                                pattern: /([A-Za-z0-9]|[\u4E00-\u9FA5])+/,
                                                message: '必须包含汉字、字母、数字',
                                            }
                                        ],
                                        getValueFromEvent: event => {
                                            return event.target.value.replace(/\s+/g, '')
                                        }
                                    })(
                                        <Input placeholder='请输入新闻标题' maxLength={100} className={'articleSourceinput'}
                                               suffix={<span>
                                                {this.props.form.getFieldValue(`newsTitle`) ? this.props.form.getFieldValue(`newsTitle`).length : 0}/100</span>}/>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>新闻分类</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('newsType', {
                                        initialValue: form.newsType,
                                        rules: [
                                            {
                                                required: true,
                                                message: '新闻分类不能为空'
                                            }
                                        ]
                                    })(
                                        <Radio.Group style={{ width: '100%' }}>
                                            <Radio value={1}>原创</Radio>
                                            <Radio value={2}>转载</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    {
                        this.props.form.getFieldValue(`newsType`) == 2?
                            <Form.Item>
                                <div className='row_div'>
                                    <div className='row_div_left'><span><span className='red'>*</span>文章来源</span></div>
                                    <div className='row_div_right'>
                                        {
                                            getFieldDecorator('articleSource', {
                                                initialValue: form.articleSource,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '文章来源不能为空'
                                                    },
                                                    {
                                                        pattern: /([A-Za-z0-9]|[\u4E00-\u9FA5])+/,
                                                        message: '必须包含汉字、字母、数字',
                                                    }
                                                ],
                                                getValueFromEvent: event => {
                                                    return event.target.value.replace(/\s+/g, '')
                                                }
                                            })(
                                                <Input placeholder='请输入文章来源' maxLength={50} suffix={<span>
                                                {this.props.form.getFieldValue(`articleSource`) ? this.props.form.getFieldValue(`articleSource`).length : 0}/50</span>}/>
                                            )
                                        }
                                    </div>
                                </div>
                            </Form.Item>:null
                    }

                    {
                        this.props.form.getFieldValue(`newsType`) == 2?
                            <Form.Item>
                                <div className='row_div'>
                                    <div className='row_div_left'><span><span className='red'></span>链接地址</span></div>
                                    <div className='row_div_right'>
                                        {
                                            getFieldDecorator('articleLink', {
                                                initialValue: form.articleLink,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                    {
                                                        pattern: /^((ht|f)tps?):\/\/([\w-]+(\.[\w-]+)*\/?)+(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?$/,
                                                        message: '链接地址格式不正确',
                                                    }
                                                ],

                                            })(
                                                <Input placeholder='请输入文章来源地址' maxLength={200}  className={'articleSourceinput'}
                                                       suffix={<span>{this.props.form.getFieldValue(`articleLink`) ? this.props.form.getFieldValue(`articleLink`).length : 0}/200</span>}/>
                                            )
                                        }
                                    </div>
                                </div>
                            </Form.Item>:null
                    }

                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>作者姓名</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('authorName', {
                                        initialValue: form.authorName,
                                        rules: [
                                            {
                                                required: true,
                                                message: '作者姓名不能为空'
                                            },
                                            {
                                                pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                                                message: '禁止输入特殊字符',
                                            }
                                        ],
                                        getValueFromEvent: event => {
                                            return event.target.value.replace(/\s+/g, '')
                                        }
                                    })(
                                        <Input placeholder='请输入作者姓名' maxLength={20} suffix={<span>
                                                {this.props.form.getFieldValue(`authorName`) ? this.props.form.getFieldValue(`authorName`).length : 0}/20</span>}/>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        {/*<Row>*/}
                        {/*    <Col span={3}><span><span className='red'>*</span>文章标签</span></Col>*/}
                        {/*    <Col span={10}>*/}
                        <div className='row_div'>
                            <div className='row_div_left'><span>文章标签</span></div>
                            <div className='row_div_right'>
                                <span className='tab_btn' hidden={this.state.tagUpdate}  onClick={()=>this.setState({editTagModal: true})}>编辑</span>
                                <span className='tab_btn' onClick={()=>this.state.tagsData.length>=4?message.warning('最多添加4个标签'):this.setState({addTagModal: true})}>添加标签</span>
                                <span className='tabTip'>提示：您最多添加4个标签</span>
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item style={{marginTop: '-20px',marginLeft: '124px'}}>
                        <Row>
                            {/*<Col span={3}><span><span className='red'></span></span></Col>*/}
                            <Col span={10} className='tabItem'>
                                {
                                    getFieldDecorator('newsLabel', {
                                        initialValue: '',
                                        // rules: [
                                        //     {
                                        //         required: true,
                                        //         message: '文章标签不能为空'
                                        //     }
                                        // ]
                                    })(
                                        <>
                                            {tagsData && tagsData.length > 0 ? tagsData.map(tag => (
                                                <CheckableTag
                                                    key={tag}
                                                    checked={selectedTags.find(e => e === tag)}
                                                    onChange={checked => this.handleChange(tag, checked)}
                                                >
                                                    {tag}
                                                </CheckableTag>
                                            )) : ''}
                                        </>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item className="row_div_textarea">
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>摘要</span></div>
                            {
                                <div className='row_div_right'>
                                    <div className="sold-box">
                                        {
                                            getFieldDecorator('newsAbstract', {
                                                initialValue: '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '摘要内容不能为空'
                                                    },
                                                    {
                                                        pattern: /([A-Za-z0-9]|[\u4E00-\u9FA5])+/,
                                                        message: '必须包含汉字、字母、数字',
                                                    }
                                                ],
                                                getValueFromEvent: event => {
                                                    return event.target.value.replace(/\s+/g, '')
                                                }
                                            })(
                                                <TextArea placeholder='请输入摘要内容' autoSize={{minRows: 3, maxRows: 5}} maxLength={100}/>
                                            )
                                        }
                                    </div>
                                    <span className='textarea_len'>
                                       {this.props.form.getFieldValue(`newsAbstract`) ? this.props.form.getFieldValue(`newsAbstract`).length : 0}/100
                                    </span>
                                </div>
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="row_div">
                            <div className="row_div_left"><span>封面</span></div>
                            <div className="special-cover">
                                {
                                    getFieldDecorator('newsImg', {
                                        initialValue: '',
                                        // rules: [
                                        //     {
                                        //         required: true,
                                        //         message: '封面不能为空'
                                        //     }
                                        // ]
                                    })
                                    (
                                        <UploadImg
                                            handleImgChange={this.handleImgChange}
                                            html='上传封面图'/>
                                    )
                                }
                            </div>
                            <Col span={5}>
                                <div className='tishi'>
                                    <p>支持jpg/png/jpeg格式，</p>
                                    <p>建议最佳尺寸160x88px，不超过5M</p>
                                </div>
                            </Col>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>新闻内容</span></div>
                            {
                                <div className='row_div_right'>
                                    {
                                        getFieldDecorator('newsContent', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: this.state.isMust,
                                                    message: '新闻内容不能为空'
                                                },{validator: this.validatLength}
                                            ],
                                        })(
                                            <HighEditor height={200} html={this.state.newsCon} zIndex={1} maxCount={70000} montedNode='newsConEditId'/>)
                                    }
                                    <div className={'text_length'}>{this.props.form.getFieldValue(`newsContent`) ? this.props.form.getFieldValue(`newsContent`).length : 0}/70000</div>
                                </div>
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span>添加附件</span></div>
                            {
                                <div className='row_div_right'>
                                    {
                                        getFieldDecorator('newsEnclosure', {
                                            rules: [],
                                        })(
                                            <div className='enclosure'>
                                                <Upload
                                                    customRequest={this.multipartUpload}
                                                    showUploadList={false}
                                                    accept=".doc, .docx, .pdf, .txt"
                                                >
                                                    <Button>
                                                        <Icon type="upload"/> 上传文件
                                                    </Button>
                                                </Upload>
                                                <div className='tip'><S.SvgQuestionMark/>文档：支持doc、docx、pdf、txt文件格式，文件大小≤30M</div>
                                                <div className='uploadFileList'>
                                                    {
                                                        fileListArr.map((item,index)=>{
                                                            return (
                                                                <div className='fileItem'>
                                                                    {
                                                                        item.fileUploadStatus==='success'?<span><S.ChengGongicon/></span>:<span><Icon type="loading" /></span>
                                                                    }

                                                                    <span>{item.fileName}</span>
                                                                    <span><S.Shanchuicon onClick={()=>this.deleteFile(index)}/></span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            )
                                    }
                                </div>
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>发布位置</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('releaseTerminal', {
                                        initialValue: form.releaseTerminal,
                                        rules: [
                                            {
                                                required: true,
                                                message: '发布位置不能为空'
                                            }
                                        ]
                                    })(
                                        <Radio.Group style={{ width: '100%' }}>
                                            <Radio value={3}>Web和APP</Radio>
                                            <Radio value={1}>只发布Web</Radio>
                                            <Radio value={2}>只发布APP</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='row_div'>
                            <div className='row_div_left'><span><span className='red'>*</span>发布设置</span></div>
                            <div className='row_div_right'>
                                {
                                    getFieldDecorator('releaseType', {
                                        initialValue: form.releaseType,
                                        rules: [
                                            {
                                                required: true,
                                                message: '发布设置不能为空'
                                            }
                                        ]
                                    })(
                                        <Radio.Group style={{ width: '100%' }} onChange={this.onChangeReleaseType}>
                                            <RenderButton id='NC_01_B04_c_btnTime interval'>
                                                <Radio value={1}>定时发布</Radio>
                                            </RenderButton>

                                            <Radio value={2}>手动开始</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </div>
                        </div>
                    </Form.Item>
                    {
                        form.releaseType == 1?
                            <Form.Item>
                                <Row>
                                    {/*<Col span={1}>*/}
                                        {/*<span><span className='red'>*</span>发布时间</span>*/}
                                    {/*</Col>*/}
                                    <Col span={10} style={{width: '260px' ,marginLeft: '124px'}}>
                                        {
                                            getFieldDecorator('timerReleaseTime', {
                                                initialValue: form.timerReleaseTime?moment(form.timerReleaseTime):null,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '发布时间不能为空'
                                                    }
                                                ]
                                            })(
                                                <DatePicker
                                                    disabledDate={this.disabledDate}
                                                    disabledTime={this.disabledTime}
                                                    showTime={{ format: "HH:mm" }}
                                                    format="YYYY-MM-DD HH:mm"
                                                    placeholder='请选择开始时间'/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item>:null
                    }
                </Form>
                <div className="fix_controll">
                    <RenderButton id='NC_01_B04_c_btnpreview'>
                        <Button className='add_btn_j' onClick={()=> this.preview()} style={{ marginRight: '40px', width: '100px', height: '39px',borderRadius: '5px' }}>预览</Button>
                    </RenderButton>
                    <RenderButton id='NC_01_B04_c_btnSave drafts'>
                        <Button className='dr_btn_j' style={{ marginRight: '40px', width: '90px', height: '39px',borderRadius: '5px'  }} onClick={()=>this.save(1)}>保存草稿</Button>
                    </RenderButton>
                    <Button className='dr_btn_j' onClick={()=> this.cancel()} style={{ marginRight: '40px', width: '90px', height: '39px',borderRadius: '5px'  }} >取消</Button>
                    <RenderButton id='NC_01_B04_c_btnConfirmrelease'>
                        <Button className='dr_btn_j' style={{ width: '90px', height: '39px',borderRadius: '5px'  }}  onClick={()=>this.save(3)}>确认发布</Button>
                    </RenderButton>
                </div>
                <Modal
                    title={'添加标签'}
                    width={660}
                    visible={addTagModal}
                    destroyOnClose={true}
                    wrapClassName='modal_con_addtag'
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={()=> this.setState({addTagModal: false,selectedTags:this.state.tagsData,tagBqName:''})}>取消</Button>
                            <Button onClick={() => this.addTag()} type='primary'>确定</Button>
                        </div>
                    }>
                    <div>
                        <div className='add-wapper'>
                            <AddTags tagBqName={tagBqName}  wrappedComponentRef={form => {
                                this.tagForm = form}
                            }/>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title={'删除标签'}
                    width={660}
                    visible={editTagModal}
                    destroyOnClose={true}
                    wrapClassName='modal_con_addtag'
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={()=> this.setState({editTagModal: false,tagsDataTemp:JSON.parse(JSON.stringify(this.state.tagsData))})}>取消</Button>
                            <Button type='primary' onClick={()=> this.saveTags()}>保存</Button>
                        </div>
                    }>
                    <div>
                        <div className='add-wapper'>
                            <div className='delete-wapper-box'>
                                {tagsDataTemp && tagsDataTemp.length > 0? tagsDataTemp.map(tag => (
                                    <div className='delete-wapper' key={tag}>
                                        <span>{tag}</span>
                                        <img src={require('@/assets/img/zixun/delete.png')} onClick={()=>this.deleteTag(tag)}/>
                                    </div>
                                )) : ''}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            </div>
        )
    }
}


AddNews = Form.create()(AddNews);
export default AddNews;
