
import reducers from './reducers'
let defaultState = {
    value: 'test',
    selectedRowKeys: [],
    activeIndex: 0,
    dataSource: [
        { id: 1, name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.' },
        { id: 2, name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.' },
        { id: 3, name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.' }
    ],
    columns: [
        { title: '资源名称', dataIndex: 'name', key: 'name' },
        { title: '上传人', dataIndex: 'age', key: 'age' },
        { title: '资源类型', dataIndex: 'age', key: 'age' },
        { title: '最后处理方式', dataIndex: 'age', key: 'age' },
        { title: '处理时举报次数', dataIndex: 'age', key: 'age' },
        { title: '举报类型', dataIndex: 'age', key: 'age' },
        { title: '最后处理时间', dataIndex: 'age', key: 'age' }
    ]
}
const Report = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Report
