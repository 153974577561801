import React from 'react';
import {Button, Form, Input, Modal, message, Select} from "antd";
import '../index.scss';
import AddRegModal from "./add-registration";
import {getProvinceList, getCityList, getAreaList, getOrganizationList} from '@/api/practTrain/index.js'

const {Option} = Select;

class AddUserInfoModel extends React.Component {
    state = {
        oneClassifyDetail: {},
        timeList: [],
        organizationList: [],
        provinceList: [],
        cityList: [],
        areaList: [],
        addressVisible: false,
        schoolList: [],
        regVisible: false,
        detail: {},
        ipAddr: window.returnCitySN['cip']
    }

    componentDidMount() {
        this.getProvinceList()
        this.getOrganizationList()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
    }

    async getOrganizationList() {
        const organizationList = await getOrganizationList({
            "enterpriseName": "",
            "enterpriseType": 1
        })
        this.setState({
            organizationList
        })
    }

    async getProvinceList() {
        const provinceList = await getProvinceList()
        this.setState({
            provinceList
        })
    }

    async getCityList(code) {
        const cityList = await getCityList({provinceCode: code})
        this.setState({
            cityList
        })
    }

    async getAreaList(code) {
        const areaList = await getAreaList({cityCode: code})
        this.setState({
            areaList
        })
    }

    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
    }
    checkSave = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
        this.props.getList()
    }
    checkSaveContinue = () => {
        this.setState({
            detail: {},
            detailNew: {}
        }, () => this.props.form.resetFields())
        this.props.getList()
    }
    // zSpace 设备注册弹窗
    addRegistration = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                let form = this.props.form.getFieldsValue()
                const {detail, provinceList, cityList, areaList, organizationList} = this.state
                const province = provinceList.find(province => province.provinceCode === form.provinceId)
                const city = cityList.find(city => city.cityCode === form.cityId)
                // const area = areaList.find(area => area.areaCode === form.areaId)
                const organization = organizationList.find(organization => organization.id === form.orgId)
                let detailNew = {
                    ...detail,
                    ...form,
                    provinceName: province.name,
                    cityName: city.name,
                    // areaName: area.name,
                    orgName: organization.enterpriseName
                }
                this.setState({
                    detailNew,
                    regVisible: !this.state.regVisible,
                })
            }
        })

    }
    regCancel = () => {
        this.setState({
            regVisible: false,
        })
    }

    provinceChange = code => {
        this.props.form.resetFields(["cityId", "areaId"])
        this.setState({
            cityList: [],
            areaList: []
        }, () => {
            this.getCityList(code)
        })
    }
    cityChange = code => {
        this.props.form.resetFields(["areaId"])
        this.setState({
            areaList: []
        }, () => {
            this.getAreaList(code)
        })
    }


    render() {
        const {saveLoading, provinceList, cityList, areaList, detail, organizationList, regVisible, ipAddr} = this.state;
        const {addUserInfoVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 16}
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <div>
                <Modal
                    width='660px'
                    title="设备所属用户信息"
                    wrapClassName='practTrain_modal_box'
                    visible={addUserInfoVisible}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.checkCancel()}>取消</Button>
                            <Button loading={saveLoading} onClick={() => this.addRegistration()}
                                    type='primary'>确定</Button>
                        </div>
                    }
                >
                    <Form {...formItemLayout} className="practTrain_form">
                        <div className="address-stock">
                            <div className="address-stock-top">
                                <Form.Item label='出库地址' colon={false}>
                                    {
                                        getFieldDecorator('provinceId', {
                                            initialValue: detail.provinceId,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择省'
                                                },
                                            ]
                                        })(
                                            <Select placeholder="请选择省" onChange={this.provinceChange}
                                                    getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                {provinceList && provinceList.length ? provinceList.map((item, index) => {
                                                    return <Option key={index}
                                                                   value={item.provinceCode}>{item.name}</Option>
                                                }) : ""}
                                            </Select>
                                        )
                                    }

                                </Form.Item>

                                <Form.Item label='' colon={false}>
                                    {
                                        getFieldDecorator('cityId', {
                                            initialValue: detail.cityId,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择市'
                                                },
                                            ]
                                        })(
                                            <Select placeholder="请选择市" onChange={this.cityChange}
                                                    getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                {cityList && cityList.length ? cityList.map((item, index) => {
                                                    return <Option key={index}
                                                                   value={item.cityCode}>{item.name}</Option>
                                                }) : ""}
                                            </Select>
                                        )
                                    }

                                </Form.Item>
                            </div>
                            {/*<Form.Item label='区' colon={false}>*/}
                            {/*  {*/}
                            {/*    getFieldDecorator('areaId', {*/}
                            {/*      initialValue: detail.areaId,*/}
                            {/*      rules: [*/}
                            {/*        {*/}
                            {/*          required: true,*/}
                            {/*          message: '请选择区'*/}
                            {/*        },*/}
                            {/*      ]*/}
                            {/*    })(*/}
                            {/*      <Select placeholder="请选择区" onChange={this.areaChange}*/}
                            {/*              getPopupContainer={triggerNode => triggerNode.parentNode}>*/}
                            {/*        {areaList && areaList.length ? areaList.map((item, index) => {*/}
                            {/*          return <Option key={index} value={item.areaCode}>{item.name}</Option>*/}
                            {/*        }) : ""}*/}
                            {/*      </Select>*/}
                            {/*    )*/}
                            {/*  }*/}

                            {/*</Form.Item>*/}

                            <Form.Item label='' colon={false} className="distance-box">
                                {
                                    getFieldDecorator('detailAddress', {
                                        initialValue: detail.detailAddress,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入具体出库地址'
                                            },
                                        ]
                                    })(
                                        <Input placeholder='请输入具体出库地址' className="sold-box"
                                               suffix={<span
                                                   className="suffixStyle">{getFieldValue('detailAddress') ? getFieldValue('detailAddress').length : "0"}/50</span>}
                                        />
                                    )
                                }

                            </Form.Item>
                        </div>
                        <Form.Item label='所属学校' colon={false}>
                            {
                                getFieldDecorator('orgId', {
                                    initialValue: detail.orgId,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择学校'
                                        },
                                    ]
                                })(
                                    <Select placeholder="请选择学校" onChange={this.organizationChange}
                                            getPopupContainer={triggerNode => triggerNode.parentNode}>
                                        {organizationList && organizationList.length ? organizationList.map((item, index) => {
                                            return <Option key={index} value={item.id}>{item.enterpriseName}</Option>
                                        }) : ""}
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item label='所属院系' colon={false}>
                            {
                                getFieldDecorator('faculty', {
                                    initialValue: detail.faculty,
                                    rules: [
                                        {
                                            required: false,
                                            message: '请输入院系名称'
                                        },
                                    ]
                                })(<Input placeholder='请输入院系名称' className="sold-box"
                                          suffix={<span
                                              className="suffixStyle">{getFieldValue('faculty') ? getFieldValue('faculty').length : "0"}/50</span>}/>)
                            }
                        </Form.Item>
                        <Form.Item label='注册所在IP' colon={false}>
                            {
                                getFieldDecorator('ipAdress', {
                                    initialValue: detail.ipAdress || ipAddr,
                                    rules: [
                                        {
                                            required: false,
                                            message: ''
                                        },
                                    ]
                                })(<Input placeholder='注册所在IP' className="sold-box" disabled={true}/>)
                            }
                        </Form.Item>
                    </Form>
                </Modal>
                {/* zSpace 设备注册 start*/}
                <AddRegModal
                    regVisible={regVisible}
                    detailNew={this.state.detailNew}
                    detail={detail}
                    regCancel={this.regCancel.bind(this)}
                    checkSave={this.checkSave.bind(this)}
                    checkSaveContinue={this.checkSaveContinue.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/* zSpace 设备注册 end*/}
            </div>

        )
    }
}

AddUserInfoModel = Form.create()(AddUserInfoModel)
export default AddUserInfoModel;

