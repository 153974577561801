import React, { Component } from "react";
import './index.scss';
import { Input, Button, Tabs, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
const { Search } = Input;
const { TabPane } = Tabs;
export default class index extends Component {
  state = {
    count: 0,
    comment_data: [
      {
        head_p:
          "gr.png",
        user_name: "董珊珊",
        timer: "2020/8/7 下午3:24:51",
        organ_name: "金融数据统计",
        referral:
          "整理了自己用过的photo shop 安装包 教程和素材还有一些ps pr ae视频剪辑类的，删了挺可惜的，没事的时候可以get新技能，赠给高考结束即将步入大学的小可爱们～～～需要的小伙伴扣111分享啦 ​​​！！！",
        browse: 9659,
        trans: 555,
        like: 1658,
        review: 439,
        counts: 0,
        is_sub: false,
        is_like: false,
        com_list: []
      },
      {
        head_p:
          "gr.png",
        user_name: "刘晓",
        timer: "2020/10/7 下午4:24:41",
        organ_name: "商务数据分析与应用",
        referral: "大数据和人工智能火了这么久，为什么还没有能根据描述病情反馈病症的程序出现，是有什么不为人知的难点吗？IBM watson已经成为很多重要诊断的备用“专家”。",
        browse: 363,
        trans: 235,
        like: 95,
        review: 356,
        counts: 0,
        is_sub: false,
        is_like: false,
        com_list: []
      },
      {
        head_p:
          "gr.png",
        user_name: '卢静珊',
        timer: "2020/10/1 下午2:14:41",
        organ_name: "财务分析与业绩评价",
        referral: "人工智能的发展很多时候超出了我们的想象，在热门影视作品中也常常出现人与人工智能的感情纠葛题材，那么想问问大家：1人工智能会产生真正的感情吗？2你能接受人工智能产生情感吗？",
        browse: 96578,
        trans: 6531,
        like: 58654,
        review: 456896,
        counts: 0,
        is_sub: false,
        is_like: false,
        com_list: [
          {
            head_sub:
              "gr.png",
            content:
              `我觉得人工智能并不会产生真正的感情。再厉害再像人的人工智能也只是一行行代码组成的一个程序。人工智能只会在程序员的代码约束下，按班就部的执行，不能跳出代码的约束，也就是不能产生自己独立的想法。
              回复二：  感情是个复杂的东西，人工智能被人创造后可以模仿，真正的感情不见得。
              回复三： 人工智能也是一个机器学习的过程，所以既然都是通过学习获得情感反射。那么我认为人工智能可以产生真正的情感。我能接受人工智能情感，但是一定要给其正确的情感导向。`,
            sub_name: "韩梅梅",
            time_r: "今天：07：30",
            like_r: 626,
          }
        ],
      },
      {
        head_p:
          "gr.png",
        user_name: "王辉",
        timer: "2020/9/30 上午9:34:22",
        organ_name: "财务分析与业绩评价",
        referral: "本人工作多年，想考中级会计证，自我约束力有点小差，有没有一起学习的，共同监督的那种！",
        browse: 234,
        trans: 232,
        like: 45,
        review: 18,
        counts: 0,
        is_sub: false,
        is_like: false,
        com_list: []
      },
      {
        head_p:
          "gr.png",
        user_name: "卢珊珊",
        timer: "2020/10/6 下午1:04:31",
        organ_name: "企业财务会计",
        referral: "孤独是精神和灵魂层面缺少同频的伙伴，有可能是精神层次特别高，也可能精神层次追求特别独特，所以就会有一种难以承受的，无法被满足的，对关注和好意的渴求，渴求被倾听，被碰触，和被看见。正是因为精神的独特性，一个人似乎也无法通过共情与他人分享孤独。",
        browse: 4564,
        trans: 546,
        like: 5968,
        review: 5869,
        counts: 0,
        is_sub: false,
        is_like: false,
        com_list: [
          {
            head_sub:
              "gr.png",
            content:
              `
              孤独和无聊，这完全是两个不同的方向。
              回复二： 孤独的形成是人们为了避免在纷繁复杂的世界里沟通付出努力，或者受到伤害而退缩到互联网后面产生的人际的隔离。
              回复三：实中与人沟通是要付出努力的，要付出真心的，在对方的回应过程中，是会有受到伤害的风险的，所以，很多人就不愿意在现实中沟通，从而转移到网络上，他想要的是掌握沟通的主动性以及收放自如的安全感，他要确保别人没法看透他，最终达到的效果就是别理解我，别看透我，别分析我，别我太近，我可以随时在线也可以随时离线，我比现实世界更能掌控节奏。              
              `,
            sub_name: "李志强",
            time_r: "昨天：20：30",
            like_r: 876,
          }
        ],
      },
    ],
    portraitId:"",
    // portraitId:
    //   localStorage.getItem("userInfo") &&
    //     JSON.parse(localStorage.getItem("userInfo"))
    //     ? JSON.parse(localStorage.getItem("userInfo")).user.portraitId
    //     : "",
    idea: "",
    com_d: "",
  };
  render() {
    let { count, comment_data, portraitId, counts } = this.state;
    const communityDetailData = sessionStorage.communityDetail ? JSON.parse(sessionStorage.communityDetail) : {};
    return (
      <div className="topic_wrap">
        <div className="hseader_wrap">
          <div className="head">
            <div className="head_l">
              <span onClick={() => this.props.history.push('/community')}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.258 5.0247H2.17301L7.01502 1.14361C7.08697 1.09578 7.14821 1.03353 7.19485 0.960793C7.2415 0.88806 7.27252 0.806432 7.28597 0.721081C7.29942 0.63573 7.29499 0.548517 7.27297 0.464966C7.25096 0.381415 7.21183 0.303347 7.15806 0.235708C7.1043 0.16807 7.03707 0.112334 6.96064 0.0720329C6.88421 0.0317315 6.80025 0.00774268 6.71406 0.00158606C6.62788 -0.00457055 6.54136 0.00723925 6.45998 0.0362673C6.37859 0.0652954 6.30413 0.110909 6.2413 0.170219L0.00159455 5.16198V5.38661C-0.000531515 5.41569 -0.000531515 5.44488 0.00159455 5.47396V5.53636C-0.000531515 5.56544 -0.000531515 5.59464 0.00159455 5.62372V5.84835L6.2413 10.8401C6.30521 10.8917 6.37866 10.9303 6.45747 10.9535C6.53627 10.9767 6.61888 10.9842 6.70058 10.9755C6.78228 10.9668 6.86146 10.9421 6.93361 10.9028C7.00576 10.8635 7.06947 10.8104 7.12109 10.7465C7.17272 10.6826 7.21124 10.6091 7.23448 10.5303C7.25771 10.4515 7.2652 10.3689 7.25651 10.2872C7.24782 10.2055 7.22312 10.1263 7.18383 10.0542C7.14453 9.98204 7.09141 9.91833 7.0275 9.86671L2.17301 6.27264H11.258C15.2389 6.27264 18.4836 9.06803 18.4836 12.5123C18.4836 15.9567 15.2389 18.752 11.258 18.752H1.6364C1.47091 18.752 1.3122 18.8178 1.19518 18.9348C1.07817 19.0518 1.01243 19.2105 1.01243 19.376C1.01243 19.5415 1.07817 19.7002 1.19518 19.8172C1.3122 19.9342 1.47091 20 1.6364 20H11.258C15.9877 20 19.7315 16.643 19.7315 12.5123C19.7315 8.38166 15.9877 5.0247 11.258 5.0247Z"
                    fill="#333333"
                  />
                </svg>

                <span className="back_text">返回圈子广场</span>
              </span>
            </div>
            <div>
              <Search
                placeholder="请输入您要查询的内容"
                onSearch={(value) => console.log(value)}
                style={{ width: 360 }}
              />
              <Button>搜话题</Button>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="c_left">
            <div className="tabs">
              <Tabs defaultActiveKey="1">
                <TabPane tab="发想法" key="1"></TabPane>
                <TabPane tab="发文章" key="2"></TabPane>
                <TabPane tab="提问题" key="3"></TabPane>
              </Tabs>
            </div>
            <div className="entering">
              <TextArea
                style={{ resize: "none" }}
                placeholder="写下这一刻你的想法"
                maxLength={600}
                value={this.state.idea}
                onChange={(e) => {
                  if (e.target.value.length <= 600) {
                    this.setState({
                      count: e.target.value.length,
                      idea: e.target.value,
                    });
                  }
                }}
              />
              <span>
                <span>{count}/</span>600
              </span>
            </div>
            <div className="opera">
              <div>
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 18.3333C5.405 18.3333 1.66667 14.595 1.66667 10C1.66667 5.405 5.405 1.66667 10 1.66667C14.595 1.66667 18.3333 5.405 18.3333 10C18.3333 14.595 14.595 18.3333 10 18.3333ZM10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM14.9125 11.7783C14.7212 11.6678 14.4938 11.6377 14.2804 11.6947C14.0669 11.7518 13.8848 11.8912 13.7742 12.0825C13.4072 12.7152 12.8807 13.2406 12.2473 13.6064C11.6139 13.9721 10.8956 14.1653 10.1642 14.1667C9.43294 14.1653 8.71487 13.9722 8.08158 13.6066C7.44829 13.2411 6.92192 12.7158 6.555 12.0833C6.44438 11.8919 6.26226 11.7523 6.04871 11.6952C5.83515 11.6381 5.60765 11.6681 5.41625 11.7788C5.22485 11.8894 5.08524 12.0715 5.02811 12.285C4.97099 12.4986 5.00105 12.7261 5.11167 12.9175C5.62551 13.8028 6.3624 14.538 7.24891 15.0497C8.13541 15.5615 9.14056 15.8319 10.1642 15.8342C11.1878 15.8323 12.1931 15.5619 13.0797 15.0501C13.9662 14.5384 14.7031 13.803 15.2167 12.9175C15.2715 12.8227 15.3072 12.7181 15.3216 12.6095C15.336 12.501 15.3289 12.3907 15.3007 12.2849C15.2724 12.1791 15.2236 12.0799 15.157 11.993C15.0904 11.9061 15.0073 11.8331 14.9125 11.7783ZM6.66667 10C6.88768 10 7.09964 9.9122 7.25592 9.75592C7.4122 9.59964 7.5 9.38768 7.5 9.16667V6.66667C7.5 6.44565 7.4122 6.23369 7.25592 6.07741C7.09964 5.92113 6.88768 5.83333 6.66667 5.83333C6.44565 5.83333 6.23369 5.92113 6.07741 6.07741C5.92113 6.23369 5.83333 6.44565 5.83333 6.66667V9.16667C5.83333 9.38768 5.92113 9.59964 6.07741 9.75592C6.23369 9.9122 6.44565 10 6.66667 10ZM13.3333 10C13.5543 10 13.7663 9.9122 13.9226 9.75592C14.0789 9.59964 14.1667 9.38768 14.1667 9.16667V6.66667C14.1667 6.44565 14.0789 6.23369 13.9226 6.07741C13.7663 5.92113 13.5543 5.83333 13.3333 5.83333C13.1123 5.83333 12.9004 5.92113 12.7441 6.07741C12.5878 6.23369 12.5 6.44565 12.5 6.66667V9.16667C12.5 9.38768 12.5878 9.59964 12.7441 9.75592C12.9004 9.9122 13.1123 10 13.3333 10Z"
                      fill="#F08034"
                    />
                  </svg>
                  表情
                </span>
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.6234 16.333H19.3129C19.6944 16.333 20.0025 16.6333 20 16.9995C20 17.3682 19.6918 17.666 19.3104 17.666H18.6208V19.3335C18.6208 19.7021 18.3127 20 17.9313 20C17.5499 20 17.2417 19.7021 17.2417 19.3335V17.666H2.0687C1.68729 17.666 1.37914 17.3682 1.37914 16.9995V3.66699H0.689568C0.308159 3.66699 0 3.36914 0 3.00049C0 2.63184 0.308159 2.33398 0.689568 2.33398H1.37914V0.666504C1.37914 0.297852 1.68729 0 2.0687 0C2.45011 0 2.75827 0.297852 2.75827 0.666504V2.33398H17.9338C18.3152 2.33398 18.6234 2.63184 18.6234 3.00049V16.333ZM2.75827 3.66705V16.3331H17.2417V3.66705H2.75827Z"
                      fill="#46B034"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.55217 8.33252C5.78935 8.33252 5.17303 7.73682 5.17303 6.99951C5.17303 6.26465 5.79188 5.6665 6.55217 5.6665C7.31246 5.6665 7.9313 6.26221 7.9313 6.99951C7.9313 7.73682 7.31499 8.33252 6.55217 8.33252ZM14.1399 8.8989L16.1884 14.187C16.2314 14.2993 16.2162 14.414 16.1404 14.5141C16.0672 14.6142 15.9586 14.6679 15.8298 14.6679H4.1652C4.02375 14.6679 3.89998 14.5996 3.83178 14.4824C3.76359 14.3628 3.76864 14.2309 3.84441 14.1162L6.20107 10.6396C6.30463 10.4858 6.51428 10.4297 6.68856 10.5127L10.6567 12.3755C10.8335 12.4585 11.0407 12.4048 11.1467 12.2485L13.463 8.83054C13.5438 8.71336 13.6777 8.65232 13.8242 8.66697C13.9732 8.68162 14.0894 8.76707 14.1399 8.8989Z"
                      fill="#46B034"
                    />
                  </svg>
                  图片
                </span>
                <Checkbox onChange={() => { }}>匿名发话题</Checkbox>
              </div>
              <div>
                <span>
                  公开
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3505 0.155371L5.55724 5.54156L0.763933 0.155371C0.674058 0.0517903 0.569205 0 0.449372 0C0.32954 0 0.224686 0.0517903 0.134812 0.155371C0.0449372 0.258951 0 0.379795 0 0.517903C0 0.65601 0.0399442 0.7711 0.119833 0.863171L5.2277 6.59463C5.31757 6.69821 5.42742 6.75 5.55724 6.75C5.68705 6.75 5.7969 6.69821 5.88678 6.59463L10.9946 0.880435C11.0745 0.776854 11.1145 0.653133 11.1145 0.509271C11.1145 0.365409 11.0695 0.244565 10.9797 0.146739C10.8898 0.048913 10.7849 0 10.6651 0C10.5453 0 10.4404 0.0517903 10.3505 0.155371Z"
                      fill="#333333"
                    />
                  </svg>
                </span>
                <Button
                  disabled={this.state.idea != "" ? false : true}
                  onClick={() => {
                    let comment_data = this.state.comment_data;
                    if (this.state.idea != "") {
                      comment_data.unshift({
                        head_p: this.state.portraitId,
                        user_name:
                          localStorage.getItem("userInfo") &&
                            JSON.parse(localStorage.getItem("userInfo"))
                            ? JSON.parse(localStorage.getItem("userInfo")).user
                              .humanName
                            : "",
                        organ_name:
                          localStorage.getItem("userInfo") &&
                            JSON.parse(localStorage.getItem("userInfo"))
                            ? JSON.parse(localStorage.getItem("userInfo")).user
                              .unitName
                            : "",
                        timer: new Date().toLocaleString(),
                        referral: this.state.idea,
                        like: 0,
                        browse: 0,
                        review: 0,
                        trans: 0,
                        com_list: [],
                        counts: 0,
                      });
                      this.setState(comment_data);
                      this.setState({
                        idea: "",
                        count: 0,
                      });
                    }
                  }}
                >
                  发布
                </Button>
              </div>
            </div>
            <div className="comment">
              <h2 className="title">
                用户评论<span>(共{comment_data.length}条)</span>
              </h2>
              {comment_data.map((item, index) => {
                return (
                  <>
                    <dl className="com_list" key={index}>
                      <dt>
                        <img src={item.head_p === 'gr.png' ? window.$$uploadRc('gr.png') : item.head_p ? "/api/media/api/v1/media/showThumbnail/" + item.head_p : window.$$uploadRc('gr.png')} alt="" />
                      </dt>
                      <dd>
                        <h2>
                          <span>{item.user_name}</span>
                          <svg
                            width="16"
                            height="4"
                            viewBox="0 0 16 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 1.60008L0 1.60008C1.33435e-07 2.48377 0.716379 3.20015 1.60008 3.20015C2.48377 3.20015 3.20015 2.48377 3.20015 1.60007C3.20015 0.716377 2.48377 0 1.60008 0C0.716379 0 1.85999e-06 0.716379 1.85999e-06 1.60007L0 1.60008Z"
                              fill="#333333"
                            />
                            <path
                              d="M6.39993 1.60008L6.39993 1.60008C6.39993 2.48377 7.11631 3.20015 8.00001 3.20015C8.88371 3.20015 9.60008 2.48377 9.60008 1.60007C9.60008 0.716377 8.8837 0 8.00001 0C7.11631 0 6.39993 0.716379 6.39993 1.60007L6.39993 1.60008Z"
                              fill="#333333"
                            />
                            <path
                              d="M12.7999 1.60008L12.7999 1.60008C12.7999 2.48377 13.5162 3.20015 14.3999 3.20015C15.2836 3.20015 16 2.48377 16 1.60007C16 0.716377 15.2836 0 14.3999 0C13.5162 0 12.7999 0.716379 12.7999 1.60007L12.7999 1.60008Z"
                              fill="#333333"
                            />
                          </svg>
                        </h2>
                        <p>
                          <span>{item.organ_name}</span>
                          <span>{item.timer}</span>
                        </p>
                        <div>{item.referral}</div>
                        <div>
                          <div>
                            <svg
                              width="16"
                              height="12"
                              viewBox="0 0 16 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.9294 3.85333C13.1882 1.49394 10.633 0 7.77907 0C4.92513 0 2.38029 1.49394 0.62877 3.85333C-0.143957 4.89394 -0.143957 6.43939 0.62877 7.48C2.38029 9.83939 4.93544 11.3333 7.77907 11.3333C10.633 11.3333 13.1779 9.83939 14.9294 7.48C15.7021 6.43939 15.7021 4.89394 14.9294 3.85333ZM14.1566 6.62485C12.5391 8.96364 10.2106 10.303 7.76877 10.303C5.32695 10.303 2.99847 8.96364 1.38089 6.62485C0.989376 6.07879 0.989376 5.26485 1.38089 4.70849C2.99847 2.3697 5.32695 1.0303 7.76877 1.0303C10.2106 1.0303 12.5391 2.3697 14.1566 4.70849C14.5379 5.26485 14.5379 6.07879 14.1566 6.62485ZM7.78938 2.06061C5.80089 2.06061 4.18332 3.67818 4.18332 5.66667C4.18332 7.65515 5.80089 9.27273 7.78938 9.27273C9.77786 9.27273 11.3954 7.65515 11.3954 5.66667C11.3954 3.67818 9.77786 2.06061 7.78938 2.06061ZM7.78938 8.24242C6.36756 8.24242 5.21362 7.08849 5.21362 5.66667C5.21362 4.24485 6.36756 3.09091 7.78938 3.09091C9.21119 3.09091 10.3651 4.24485 10.3651 5.66667C10.3651 7.08849 9.21119 8.24242 7.78938 8.24242Z"
                                fill="#3E78ED"
                              />
                            </svg>
                            浏览{item.browse}
                          </div>
                          <div>
                            <span>
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.25 4.25C1.9125 4.25 0 6.1625 0 8.5C0 10.8375 1.9125 12.75 4.25 12.75C6.5875 12.75 8.5 10.8375 8.5 8.5C8.5 6.1625 6.5875 4.25 4.25 4.25ZM4.25 11.6875C2.44375 11.6875 1.0625 10.3063 1.0625 8.5C1.0625 6.69375 2.44375 5.3125 4.25 5.3125C6.05625 5.3125 7.4375 6.69375 7.4375 8.5C7.4375 10.3063 6.05625 11.6875 4.25 11.6875ZM14.3438 0C12.8563 0 11.6875 1.16875 11.6875 2.65625C11.6875 4.14375 12.8563 5.3125 14.3438 5.3125C15.8313 5.3125 17 4.14375 17 2.65625C17 1.16875 15.8313 0 14.3438 0ZM14.3438 4.25C13.4938 4.25 12.75 3.50625 12.75 2.65625C12.75 1.80625 13.4938 1.0625 14.3438 1.0625C15.1938 1.0625 15.9375 1.80625 15.9375 2.65625C15.9375 3.50625 15.1938 4.25 14.3438 4.25ZM14.3438 11.6875C12.8563 11.6875 11.6875 12.8563 11.6875 14.3438C11.6875 15.8313 12.8563 17 14.3438 17C15.8313 17 17 15.8313 17 14.3438C17 12.8563 15.8313 11.6875 14.3438 11.6875ZM14.3438 15.9375C13.4938 15.9375 12.75 15.1938 12.75 14.3438C12.75 13.4938 13.4938 12.75 14.3438 12.75C15.1938 12.75 15.9375 13.4938 15.9375 14.3438C15.9375 15.1938 15.1938 15.9375 14.3438 15.9375Z"
                                  fill="#333333"
                                />
                                <path
                                  d="M7.38644 9.73672L12.9072 12.9242L12.3759 13.8443L6.85519 10.6568L7.38644 9.73672ZM12.9359 4.13521L7.41513 7.32272L6.88388 6.40259L12.4046 3.21509L12.9359 4.13521Z"
                                  fill="#333333"
                                />
                              </svg>
                              转发{item.trans}
                            </span>
                            <span>
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.58643 0C8.66702 0 7.86537 0.628331 7.69675 1.51688C7.69675 1.51688 7.42344 2.90859 7.28049 3.40904C7.04546 4.23389 6.22332 5.14565 5.62832 5.43673C5.17898 5.65893 3.50878 5.6614 3.40657 5.6614H1.13396C0.508343 5.6614 1.90953e-08 6.16703 0 6.79536V15.866C0 16.4917 0.505627 17 1.13396 17H11.5988C13.2816 17 14.7118 15.7693 14.9622 14.1042L15.8381 8.26657C16.045 6.89535 14.9827 5.66436 13.5959 5.66436H11.0167C11.0167 5.66436 11.3998 4.39511 11.5939 2.25261C11.6909 1.16729 10.9223 0.133073 9.79357 0.015554C9.72444 0.00518465 9.65556 1.90953e-08 9.58643 0ZM4.53287 15.8633V6.75956C5.27601 6.71117 5.81225 6.60649 6.12876 6.44799C6.96374 6.0342 8.03128 4.90049 8.36828 3.71567C8.51913 3.18708 8.77688 1.87956 8.8075 1.72624C8.87391 1.38406 9.20079 1.13124 9.58372 1.13124C9.61433 1.13124 9.64494 1.13371 9.67581 1.13643C10.2145 1.19272 10.5031 1.70353 10.4649 2.1425C10.2861 4.12674 9.93356 5.32193 9.92862 5.32958L9.48694 6.79017H13.5959C13.9304 6.79017 14.2368 6.93065 14.4566 7.18347C14.6763 7.43628 14.7681 7.76069 14.717 8.09276L13.8435 13.9302C13.6749 15.0486 12.7325 15.8606 11.6013 15.8606H4.53287V15.8633ZM1.13396 15.8633V6.79264H3.39916V15.8633H1.13396Z"
                                  fill="#333333"
                                />
                              </svg>

                              {item.is_like ? (
                                <span
                                  onClick={() => {
                                    this.state.comment_data[index].like =
                                      this.state.comment_data[index].like - 1;
                                    this.state.comment_data[
                                      index
                                    ].is_like = false;
                                    this.setState({});
                                  }}
                                >
                                  取消赞{item.like}
                                </span>
                              ) : (
                                  <span
                                    onClick={() => {
                                      this.state.comment_data[index].like =
                                        this.state.comment_data[index].like + 1;
                                      this.state.comment_data[
                                        index
                                      ].is_like = true;
                                      this.setState({});
                                    }}
                                  >
                                    点赞{item.like}
                                  </span>
                                )}
                            </span>
                            <span
                              onClick={() => {
                                this.state.comment_data.forEach(v => v.is_sub = false);
                                this.state.comment_data[index].is_sub =
                                  this.state.comment_data[index].is_sub === true
                                    ? false
                                    : true;
                                this.setState({});
                              }}
                            >
                              <svg
                                width="18"
                                height="16"
                                viewBox="0 0 18 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.34946 5.25252C3.76298 5.25252 3.28681 5.75921 3.28681 6.38482C3.28681 7.01003 3.76298 7.5167 4.34946 7.5167C4.93679 7.5167 5.41211 7.01003 5.41211 6.38482C5.41211 5.75921 4.93679 5.25252 4.34946 5.25252ZM8.60008 5.25252C8.01284 5.25252 7.53742 5.75921 7.53742 6.38482C7.53742 7.01003 8.01284 7.5167 8.60008 7.5167C9.18655 7.5167 9.66194 7.01003 9.66194 6.38482C9.66194 5.75921 9.18655 5.25252 8.60008 5.25252ZM12.8499 5.25252C12.2635 5.25252 11.7872 5.75921 11.7872 6.38482C11.7872 7.01003 12.2635 7.5167 12.8499 7.5167C13.4363 7.5167 13.9125 7.01003 13.9125 6.38482C13.9125 5.75921 13.4363 5.25252 12.8499 5.25252ZM14.9752 0.133301H2.22496C1.0512 0.133301 0.0997009 1.1473 0.0997009 2.39793V9.8059C0.0997009 11.0561 1.07208 12.2087 2.2726 12.2087H5.51154C6.07549 12.8265 8.41209 15.05 8.41209 15.05C8.51576 15.1611 8.68365 15.1611 8.78721 15.05C8.78721 15.05 10.4981 13.3189 11.6126 12.2087H14.9267C16.1273 12.2087 17.0997 11.0561 17.0997 9.8059V2.39793C17.0997 1.1473 16.1481 0.133301 14.9752 0.133301ZM16.037 9.8059C16.037 10.4309 15.5266 11.0871 14.9268 11.0871H11.6677C11.2726 11.0871 10.9176 11.5036 10.9176 11.5036L8.62934 13.6813L6.34113 11.5036C6.34113 11.5036 5.90588 11.0871 5.53246 11.0871H2.2726C1.67275 11.0871 1.16231 10.4309 1.16231 9.8059V2.39793C1.16231 1.77233 1.63768 1.26575 2.22496 1.26575H14.9752C15.5617 1.26575 16.037 1.77233 16.037 2.39793V9.8059Z"
                                  fill="black"
                                />
                              </svg>
                              评论{item.review}
                            </span>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    {item.is_sub ? (
                      <div className="reply">
                        <dl className="my_self">
                          <dt>
                            <img
                              src={
                                portraitId
                                  ? `/api/media/api/v1/media/showThumbnail/${portraitId}`
                                  : window.$$uploadRc('gr.png')
                              }
                              alt=""
                            />
                          </dt>
                          <dd>
                            <TextArea
                              style={{ resize: "none" }}
                              placeholder="写下这一刻你的想法"
                              maxLength={200}
                              value={this.state.com_d}
                              onChange={(e) => {
                                if (e.target.value.length <= 200) {
                                  this.state.comment_data[index].counts =
                                    e.target.value.length;
                                  this.setState({ com_d: e.target.value });
                                }
                              }}
                            />
                            <span>
                              <span>{item.counts}/</span>200
                            </span>
                            <div>
                              <span>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 18.3333C5.405 18.3333 1.66667 14.595 1.66667 10C1.66667 5.405 5.405 1.66667 10 1.66667C14.595 1.66667 18.3333 5.405 18.3333 10C18.3333 14.595 14.595 18.3333 10 18.3333ZM10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM14.9125 11.7783C14.7212 11.6678 14.4938 11.6377 14.2804 11.6947C14.0669 11.7518 13.8848 11.8912 13.7742 12.0825C13.4072 12.7152 12.8807 13.2406 12.2473 13.6064C11.6139 13.9721 10.8956 14.1653 10.1642 14.1667C9.43294 14.1653 8.71487 13.9722 8.08158 13.6066C7.44829 13.2411 6.92192 12.7158 6.555 12.0833C6.44438 11.8919 6.26226 11.7523 6.04871 11.6952C5.83515 11.6381 5.60765 11.6681 5.41625 11.7788C5.22485 11.8894 5.08524 12.0715 5.02811 12.285C4.97099 12.4986 5.00105 12.7261 5.11167 12.9175C5.62551 13.8028 6.3624 14.538 7.24891 15.0497C8.13541 15.5615 9.14056 15.8319 10.1642 15.8342C11.1878 15.8323 12.1931 15.5619 13.0797 15.0501C13.9662 14.5384 14.7031 13.803 15.2167 12.9175C15.2715 12.8227 15.3072 12.7181 15.3216 12.6095C15.336 12.501 15.3289 12.3907 15.3007 12.2849C15.2724 12.1791 15.2236 12.0799 15.157 11.993C15.0904 11.9061 15.0073 11.8331 14.9125 11.7783ZM6.66667 10C6.88768 10 7.09964 9.9122 7.25592 9.75592C7.4122 9.59964 7.5 9.38768 7.5 9.16667V6.66667C7.5 6.44565 7.4122 6.23369 7.25592 6.07741C7.09964 5.92113 6.88768 5.83333 6.66667 5.83333C6.44565 5.83333 6.23369 5.92113 6.07741 6.07741C5.92113 6.23369 5.83333 6.44565 5.83333 6.66667V9.16667C5.83333 9.38768 5.92113 9.59964 6.07741 9.75592C6.23369 9.9122 6.44565 10 6.66667 10ZM13.3333 10C13.5543 10 13.7663 9.9122 13.9226 9.75592C14.0789 9.59964 14.1667 9.38768 14.1667 9.16667V6.66667C14.1667 6.44565 14.0789 6.23369 13.9226 6.07741C13.7663 5.92113 13.5543 5.83333 13.3333 5.83333C13.1123 5.83333 12.9004 5.92113 12.7441 6.07741C12.5878 6.23369 12.5 6.44565 12.5 6.66667V9.16667C12.5 9.38768 12.5878 9.59964 12.7441 9.75592C12.9004 9.9122 13.1123 10 13.3333 10Z"
                                    fill="#F08034"
                                  />
                                </svg>
                                表情
                              </span>
                              <Button
                                disabled={this.state.com_d != "" ? false : true}
                                onClick={() => {
                                  let newData = this.state.comment_data;
                                  if (this.state.com_d != "") {
                                    newData[index].com_list.unshift({
                                      head_sub: this.state.portraitId,
                                      content: this.state.com_d,
                                      sub_name:
                                        localStorage.getItem("userInfo") &&
                                          JSON.parse(
                                            localStorage.getItem("userInfo")
                                          )
                                          ? JSON.parse(
                                            localStorage.getItem("userInfo")
                                          ).user.humanName
                                          : "",
                                      time_r: new Date().toLocaleString(),
                                      like_r: 0,
                                      is_liker: false,
                                    });
                                    this.state.comment_data[index].review++;
                                    this.state.comment_data[index].counts = 0;
                                    this.setState({
                                      com_d: "",
                                    });
                                  }
                                }}
                              >
                                回复
                              </Button>
                            </div>
                          </dd>
                        </dl>
                        {item.com_list
                          ? item.com_list.map((item1, index1) => {
                            return (
                              <dl key={index1} className="com_sub">
                                <dt>
                                  <img src={item1.head_sub === 'gr.png' ? window.$$uploadRc('gr.png') : item1.head_sub ? "/api/media/api/v1/media/showThumbnail/" + item1.head_sub : window.$$uploadRc('gr.png')} alt="" />
                                </dt>
                                <dd>
                                  <h2>
                                    {item1.sub_name}：{item1.content}
                                  </h2>
                                  <div>
                                    <span>{item1.time_r}</span>
                                    <div>
                                      <span>回复</span>
                                      <span>
                                        <svg
                                          width="12"
                                          height="12"
                                          viewBox="0 0 12 12"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.76689 0C6.1179 0 5.55203 0.443528 5.433 1.07074C5.433 1.07074 5.24008 2.05312 5.13917 2.40638C4.97326 2.98863 4.39293 3.63222 3.97293 3.83769C3.65575 3.99454 2.47678 3.99628 2.40464 3.99628H0.800441C0.358831 3.99628 1.3479e-08 4.3532 0 4.79672V11.1996C0 11.6412 0.356914 12 0.800441 12H8.1874C9.37525 12 10.3848 11.1312 10.5615 9.95594L11.1799 5.83522C11.3259 4.8673 10.576 3.99837 9.59711 3.99837H7.77646C7.77646 3.99837 8.04694 3.10243 8.18392 1.59008C8.25241 0.823968 7.70989 0.0939338 6.91311 0.0109793C6.86431 0.00365975 6.81569 1.3479e-08 6.76689 0ZM3.19967 11.1976V4.77145C3.72424 4.7373 4.10276 4.6634 4.32618 4.55152C4.91558 4.25944 5.66914 3.45917 5.90702 2.62283C6.01351 2.24971 6.19545 1.32675 6.21706 1.21853C6.26394 0.976981 6.49468 0.798524 6.76498 0.798524C6.78659 0.798524 6.8082 0.800267 6.82998 0.802184C7.21025 0.841919 7.41397 1.20249 7.38696 1.51235C7.26079 2.91299 7.01192 3.75665 7.00844 3.76206L6.69666 4.79306H9.59711C9.83325 4.79306 10.0495 4.89223 10.2046 5.07068C10.3597 5.24914 10.4246 5.47814 10.3885 5.71253L9.7719 9.83307C9.65287 10.6225 8.98767 11.1957 8.18915 11.1957H3.19967V11.1976ZM0.800441 11.1976V4.79481H2.39941V11.1976H0.800441Z"
                                            fill="#333333"
                                          />
                                        </svg>
                                        {item1.is_liker ? (
                                          <span
                                            onClick={() => {
                                              this.state.comment_data[
                                                index
                                              ].com_list[index1].like_r =
                                                this.state.comment_data[index]
                                                  .com_list[index1].like_r -
                                                1;
                                              this.state.comment_data[
                                                index
                                              ].com_list[
                                                index1
                                              ].is_liker = false;
                                              this.setState({});
                                            }}
                                          >
                                            取消赞{item1.like_r}
                                          </span>
                                        ) : (
                                            <span
                                              onClick={() => {
                                                this.state.comment_data[
                                                  index
                                                ].com_list[index1].like_r =
                                                  this.state.comment_data[index]
                                                    .com_list[index1].like_r +
                                                  1;
                                                this.state.comment_data[
                                                  index
                                                ].com_list[
                                                  index1
                                                ].is_liker = true;
                                                this.setState({});
                                              }}
                                            >
                                              点赞{item1.like_r}
                                            </span>
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            );
                          })
                          : ""}
                        <p className="more">
                          <span>查看更多话题 &gt;</span>
                        </p>
                      </div>
                    ) : (
                        ""
                      )}
                  </>
                );
              })}
            </div>
          </div>
          <div className="c_right">
            <div className="r_top">
              <img
                alt=""
                src={require("@/assets/img/images/topic_r.png")}
              />
              <div className="logo">
                <svg
                  width="50"
                  height="14"
                  viewBox="0 0 50 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.00302866 4.00844L0 9.89616L0.416441 10.3126V3.59351L0.00302866 4.00844ZM10.1294 5.91952L3.95392 0.054516L3.67983 0.33921L3.68286 13.5836L4.0993 14V8.08199L10.1809 13.9743L10.5488 13.5942L4.0993 7.34754V6.64792L10.932 13.2262L11.2833 12.8627L4.0993 5.91801V5.19719L11.6694 12.4887L12.0238 12.1222L4.0993 4.46425V3.76614L12.3948 11.7603L12.7673 11.3999L4.0993 3.03472V2.2927L13.1565 11.0001L13.5078 10.6367L4.0993 1.57339V0.773824L13.9258 10.2399L14.2044 9.95976L14.2014 4.06144L13.8168 3.68588L13.8395 9.47669L13.4533 9.12991V3.3285L13.0853 2.95901L13.1005 8.78464L12.737 8.4106L12.7446 2.61677L12.366 2.24273L12.3615 8.07139L11.9981 7.69735L12.0041 1.87323L11.6301 1.50373L11.624 7.35814L11.2606 7.00681L11.2666 1.14484L10.8502 0.728394L10.8532 6.62975L10.5216 6.29205L10.5231 0.392212L10.1248 0L10.1294 5.91952ZM2.91963 1.09183L2.91812 12.8188L3.33456 13.2353V0.693564L2.91963 1.09183ZM2.18215 1.83083L2.18064 12.0798L2.59708 12.4963L2.59556 1.42347L2.18215 1.83083ZM1.44013 2.56831V11.3378L1.85657 11.7542L1.85203 2.16247L1.44013 2.56831ZM0.699621 3.30427L0.70265 10.5973L1.11758 11.0137V2.8954L0.699621 3.30427Z"
                    fill="#E71B18"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.0116 4.32407H20.5094V4.19584L20.5883 4.0413L19.2073 3.80785L19.2106 9.68033L20.5094 9.42715V9.08519H23.0116V11.6203H24.6557V9.08519H27.0888V9.63101L28.4599 9.42715V4.19255L28.5619 4.01171L27.0888 3.80785V4.32078H24.6557L24.6524 3.07461L24.7839 2.93651L23.0083 2.36768V4.32407H23.0116ZM20.5094 8.57225V4.80413H23.0116V8.57225H20.5094ZM24.6557 8.57225V4.83701H27.0888V8.57225H24.6557Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M31.2352 3.46895L30.2258 3.47224L30.3112 3.87996H31.2352V5.28396L29.8213 5.28725L29.9331 5.69497L31.5213 5.71798L29.8147 7.29297V8.06237L31.2714 6.75701H32.6063L31.9257 7.44093H31.5443V7.88482H31.9257V8.59175L29.8147 9.53543L29.8115 10.4857L31.9191 9.56173V11.1038L30.4 11.1137L30.9228 11.6168L32.5603 11.6233L33.2771 10.9098L33.2968 8.92056L34.6581 8.31555L34.6383 7.37188L33.3001 7.97031L33.2968 7.61849L34.224 6.87538L33.7308 6.37231H31.6758L32.3663 5.70483L34.6778 5.71141L35.0362 5.20833V8.47009C35.1184 8.59175 35.9108 9.4598 35.9108 9.47295L33.7506 11.6233L35.2664 11.6266L36.621 10.2983L37.7751 11.6233L39.3271 11.6266L37.4562 9.4861L38.4624 8.47009V4.98475L38.561 4.85322L37.2326 4.18904L37.226 8.12485L36.7756 8.64107L36.2791 8.12485L36.2725 5.45823L36.3711 5.32671L35.2466 4.84007L35.7168 4.15615L39.143 4.14629L38.8438 3.40319L38.1927 3.74515H35.9207L36.4205 2.99547L36.575 2.93628L35.4998 2.41677L33.7538 5.27081H32.8496L34.2142 4.01477L33.9709 3.46895H32.5734V3.04808L32.6721 2.83435L31.2385 2.37402V3.46895H31.2352ZM32.5734 3.87667H33.5303L32.5734 4.75787V3.87667Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M48.7139 5.5925L40.5759 5.59578L40.7305 6.0035H43.2984L41.0363 10.2188L42.0983 11.4518H49.5359L48.4048 7.33846L46.5405 7.3549L46.7706 7.58506L47.6518 10.9718H42.4764L45.1496 6.0035L49.8647 5.99364L49.4866 5.06641L48.7139 5.5925Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M41.8021 2.97168H47.6023L48.3651 2.56396L48.6676 3.3531H41.9698L41.8021 2.97168Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="r_bottom">
              <h2>{communityDetailData.title}</h2>
              <h4>{communityDetailData.time} </h4>
              <p title={communityDetailData.master}>圈主：{communityDetailData.master}</p>
              <div title={communityDetailData.content}>
                {communityDetailData.content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
