import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import '../index.scss';
import {homePageModuleList} from '@/api/craftsman-app/index'
const {Option} = Select;
class relevanceModel extends React.Component {
    state = {
        data:{},
        plateList:{},  //首页板块列表
        moduleId:'',  //板块id
        courseList:[],  //课程列表
        record:{},
        moduleName:''
    }
    componentDidMount() {
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.relevanceVisible) {
            this.setState({
                record: nextProps.record,
                relevanceVisible: nextProps.relevanceVisible,
                courseList:nextProps.courseList,
            },()=>{
                this.homePageModuleList()
            })
        }
    }
//所在版块列表
    async homePageModuleList() {
        const plateList = await homePageModuleList({})
        this.setState({
            plateList: plateList
        })
    }
    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
    }
    selectModelChange = (value) =>{
        let module = this.state.plateList.find(item => item.moduleId === value);
        this.setState({
            moduleName:module.moduleName,
            moduleId:value,
        })
    }
    //添加
    courseSave = () =>{
        const {courseList,record} = this.state;
        let newCourse =
            {
                'courseCode' : record.courseCode,
                'moduleId': this.state.moduleId,
                'courseName':record.courseName,
                'originName':record.originName,
                'courseTeacher': record.courseTeacher,
                'moduleName' : this.state.moduleName,
            }
        let id=courseList.findIndex(item => item.moduleId === newCourse.moduleId && item.courseCode === newCourse.courseCode)
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    if(id>=0){
                        message.error("课程已经添加过该板块，请不要重复添加")
                        return
                    }else {
                        courseList.push(newCourse)
                    const params = values
                    params.courseList = courseList
                    this.setState({
                        saveLoading: false,
                        courseList:courseList,
                        aveLoading: false,
                    },()=>{
                        this.props.checkCancel()
                    })
                }
                }
            })



    }
    render() {
        const {saveLoading,data,plateList} = this.state;
        const {relevanceVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 14}
        };
        const {getFieldDecorator,getFieldValue} = this.props.form;
        return (
            <Modal
                width='660px'
                title="关联版块"
                wrapClassName='training_modal_box'
                visible={relevanceVisible}
                destroyOnClose={true}
                closable={false}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.checkCancel()}>取消</Button>
                        <Button loading={saveLoading} onClick={() => this.courseSave()} type='primary'>保存</Button>
                    </div>
                }
            >
                <Form {...formItemLayout}>
                    <Form.Item label='课程版块'>
                        {
                            getFieldDecorator('moduleId', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择版块'
                                    },
                                ]
                            })(
                                <Select placeholder="请选择版块"   onChange={(value) => this.selectModelChange(value)} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    {plateList && plateList.length ? plateList.map((item, index) => {
                                        return <Option key={index}
                                                       value={item.moduleId}>{item.moduleName}</Option>
                                    }) : ""}

                                </Select>)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

relevanceModel = Form.create()(relevanceModel)
export default relevanceModel;
