import React from 'react';
import {Button, Form, Input, Modal, message} from "antd";
import '../index.scss';
import UserSetModel from "./user-settings";
import {getAddressPassword} from '@/api/practTrain/index.js'

class GenerateAddressModel extends React.Component {
    state = {
        getAddress: {},
        userVisible: false,
        addressVisible: false,
        timeValue: '',//有效时间
        softwareId: '',  //安装软件id
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.addressVisible) {
            this.setState({
                addressVisible: nextProps.addressVisible,
                timeValue: nextProps.timeValue,
                softwareId: nextProps.softwareId,
            }, () => {
                this.addressPassword()
            })
        }

    }

    //获取下载页地址和密码
    async addressPassword() {
        const getAddress = await getAddressPassword({
            address: 'https://obs.cveducloud.com/'
        })
        if (getAddress) {
            this.setState({
                getAddress: getAddress,
            })
        }
    }

    //发送链接级密码弹窗
    userSetting = () => {
        this.setState({
            userVisible: !this.state.userVisible,
            timeValue: this.state.timeValue,
            getAddress: this.state.getAddress,
            softwareId: this.state.softwareId,
        }, () => {
            this.props.generateAddressConfirm()
        })
    }
    userCancel = () => {
        this.setState({
            userVisible: false,
        })
    }
    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.generateAddressCancel()
    }

    render() {
        const {saveLoading, userVisible, getAddress, timeValue, softwareId} = this.state;
        const {addressVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16}
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <div>
                <Modal
                    width='660px'
                    title="地址生成成功"
                    wrapClassName='practTrain_modal_box'
                    visible={addressVisible}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.checkCancel()}>关闭本页</Button>
                            <Button loading={saveLoading} onClick={() => this.userSetting()}
                                    type='primary'>发送链接级密码</Button>
                        </div>
                    }
                >
                    <Form {...formItemLayout} style={{width:'507px'}}>
                        <div className="hint-box">
                            <img src={window.$$img('cvec-manage-front-yunying/practTrain/hint-icon.png')}
                                 alt="图片" className="hint-img"/>
                            <span> 获取地址32天有效，请及时获取安装文件</span>
                        </div>
                        <Form.Item label='Landesk安装包获取地址' colon={false}>
                            {
                                getFieldDecorator('address', {
                                    initialValue: getAddress.address ? getAddress.address : undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '请输入地址信息不可更改'
                                        },
                                    ]
                                })(<Input placeholder='地址信息不可更改' className="sold-box"/>)
                            }
                        </Form.Item>
                        <Form.Item label='提取密码' colon={false}>
                            {
                                getFieldDecorator('password', {
                                    initialValue: getAddress.password ? getAddress.password : undefined,
                                    rules: [
                                        {
                                            required: false,
                                            message: '请输入提取密码'
                                        },
                                    ]
                                })(<Input placeholder='提取密码' className="sold-box"/>)
                            }
                        </Form.Item>
                    </Form>
                </Modal>
                {/*安装包使用者设置弹窗 start*/}
                <UserSetModel
                    userVisible={userVisible}
                    checkCancel={this.userCancel.bind(this)}
                    timeValue={timeValue}
                    getAddress={getAddress}
                    softwareId={softwareId}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*安装包使用者设置弹窗 end*/}
            </div>

        )
    }
}

GenerateAddressModel = Form.create()(GenerateAddressModel)
export default GenerateAddressModel;

