import { post, get } from '../../until/axios_instans';


// 反馈中心消息列表
export function getFeedbackPage(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedback/manage/getFeedbackPage', data);
}

// 反馈详情
export function getFeedbackInfo(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedback/getFeedbackInfo', data);
}
// 反馈类型列表（表格展示）
export function getFeedbackTypePage(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedbacktype/manage/getFeedbackTypePage', data);
}
//反馈类型列表（纯类型）
export function getFeedbackTypeList(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedbacktype/getFeedbackTypeList', data);
}
// 反馈删除
export function feedbackRemove(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedbacktype/manage/remove', data);
}


//新增反馈类型
export function addFeedback(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedbacktype/manage/save', data);
}

//反馈类型状态变更
export function updateTypeStatus(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedbacktype/manage/updateTypeStatus', data);
}

//编辑反馈类型
export function updateFeedback(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedbacktype/manage/update', data);
}

//反馈回复保存
export function saveReplyInfo(data) {
    return post(window.ningxiaApiPrefix + '/api/v1/feedback/manage/saveReplyInfo', data);
}
