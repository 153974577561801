/*eslint eqeqeq: ["off", "smart"]*/
/*eslint-disable*/
import React from "react";
import {
    Button,
    Tabs,
    Select,
    Input,
    Form,
    Modal,
    message, Row, Col, Slider,Checkbox
} from "antd";
import LiveCanvasBarrage from '@/components/utils/LiveCanvasBarrage';
import "./index.scss";
import "./emoji-sprite.scss";
import Swiper from "swiper"
import 'swiper/dist/css/swiper.css'
import {withRouter} from "react-router-dom";
import {
    getGroup,
    saveChatMessage,
    getSdkAppId,
    getChatMsg,
    getGroupInfo,
    deleteChatRecords,
    getVoteList,
    connectEnd,
    getGroupInfoPage,
    getStudentLiveDetail,
    pauseLive,
    continueLive,
    frozenAndForceEndLive,
    removeUser
} from "@/api/lives/live"
import emoji from '@/components/utils/emoji';
import data from '@/components/emoji-data/emoji-data';
import Banned from '@/components/banned';
import TRTC from 'trtc-js-sdk';
import TIM from 'tim-js-sdk';
import moment from "moment";
const {TabPane} = Tabs;
const {Search} = Input;
export default Form.create()(
    withRouter(
        class Studntliveroom extends React.Component {
            state = {
                barrageList: [
                    "Ceshi 1",
                    "Ceshi 3",
                    "Ceshi 2",
                    "Ceshi 5",
                    "Ceshi 8"
                ], //弹幕组件初始化的弹幕列表，可以为空，但必须有list
                value:'', //投票选项值
                studentsPortrait: [],//在线学生人数
                pageNum: 1,
                pageSize: 10,
                total: 0,
                audience:0,  //是否是观众，用于判断当前连麦学生是否自己，0不是观众，是自己连麦，1是观众，不能操作连麦只能观看
                needResume: 0, //由于某些报错，可能需要恢复连接的flag判断 ，0不需要，1需要
                eleVideo: null, //弹幕组件需要绑定的视频标签
                startBarrage: 0, //当前是否开启了弹幕，0否1是
                breadcrumbData: [
                    {
                        name: "个人中心",
                        path: "/",
                    },
                    {
                        name: "我的直播",
                        path: "/",
                    },
                    {
                        name: "学生直播间",
                        path: "/",
                    },
                ], //面包屑
                userId: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "", //当前进入直播间的用户id
                teacherId: "109", //当前直播的教师id
                teacherName: '2', //当前直播的教师名称
                sdkAppId: 1400421185,
                userSig: '', //签名（创建im和trtc需要使用）
                mm: 0, //连麦计时使用的参数
                ss: 0,//连麦计时使用的参数
                hh: 0,//连麦计时使用的参数
                liveStatusFlag: 0, //当前是否开播的状态 0否1是
                isCamera: false, //远程摄像头是否开启了
                emojiData: data,  //表情数组
                pannels: ['表情'], //表情
                isVoice: false, //远程声音是否kaiqi
                isBanSpeech: 0, //直播间是否开启了全体禁言 0否1是
                isConnect: 0,   //当前是否在连麦中 0否1是
                videoId: '',    //录像id
                isRecord: 0,    //当前直播是否开启了录像
                activeKey: "1", //当前激活的操作栏 1讨论区2讲解区3成员列表
                groupPageSize: 40,           //在线学生人数
                groupPageNum: 1,             //在线学生页数
                groupTotal: 0,               //在线学生总人数
                groupTotalPages:1,           //在线学生总页数
                teacherPortraitId: '', //教师头像
                isMyVoice: true,     //连麦中我的声音是否开启
                isMyCamera: true,    //连麦中我的摄像头是否开启
                emojiContainer: false, //表情选择框是否展示
                discussPageNum: 1,    //讨论区分页参数
                explainPageNum: 1,    //讲解区分页参数
                showVoteList: 0,     //投票列表，是否已经展示更多 0否1是
                activeIndex: 0,     //选择表情时激活的panel 的index
                connecting: 0,      //当前在连麦时是否接听了，用来控制显示什么按钮
                message: "",        //发送的消息文本
                messageList: [],    //当前直播间收到的消息列表（对象形式保存，展示时解析对象）
                searchMessageList: [],    //当前直播间收到的消息列表（对象形式保存，展示时解析对象）
                voteList: [],       //当前投票列表
                voteStat: [],       //当前选择的投票状态信息
                taskId: '',         //直播任务id
                roomId: '714887168', //房间id
                remoteStream: null, //远程摄像头流
                localStream: null,  //本地流
                remoteScreenStream: null, //远程屏幕共享流
                tim: null, //im对象
                studentName: '', //学生名称
                chatMsg: [], // 聊天记录
                time: 0, //录制的时间
                client: null, //trtc对象
                memberList: [], //成员列表
                userMark: 3, //当前用户的身份类型
                deadline: null, //直播任务开始时间，用于计算倒计时（已经弃用）
                imSdkReady: false, //当前im是否准备就绪
                taskName: '汽车机械加工课 第三讲',//课程名称
                audioDisabled: true, //音量控制字段
                audioLevel: 30, //音量大小
                messageRedo: 1, //消息发送间隔的控制字段
                lianMaiTime: '00:00:00', //连麦的时间显示
                canvasBarrage: null, //弹幕组件对象
                userName: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).fullName : '默认名字',
                userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : "",
                GroupInfoVo: null, //连麦学生信息
                groupInfo: null, //群组信息
                reportFlag: false, //举报弹窗
                type: 1,   //没用
                liveDetail: {}, //直播任务详情
                isStudent: 1, //传给header组件的参数，代表是学生
                isPause:0,//管理员暂停直播的flag，1为暂停，直播正常为0
                members:[],
                member:[],
                banFlag:false,
                reportType:0,
                isReport:0,
                pauseTime:0,
                objectStr:'',  //聊天记录搜索内容
                messageSearchFlag:0,//用来区分当前获取消息时是搜索还是全部 0否1是
                discussSearchPageNum:1,
                explainSearchPageNum:1,
                discussSearchTotalMsg:0,
                explainSearchTotalMsg:0,
                kickOut:true,
                contentHeight:0,
                contentHeighta:0,
                waitStart:0,
                playError : 0,
                inviteMemberVisible: false,//邀请成员弹框显隐
                nextBtnDisable: false,//下一页翻页按钮状态
                preBtnDisable: false,//上一页翻页按钮状态
                prevStemp: 0,//记录前一页的页码步骤
                isHandNum:0,
            };

            componentDidMount = async () => {
                TRTC.checkSystemRequirements().then((result) => {
                    if(!result) {
                        alert('Your browser is not compatible with TRTC');
                        Modal.info({
                            content:"您当前浏览器不支持当前直播功能，请下载最新版Chrome浏览器使用",
                            onOk:() => {
                                // this.props.history.push("studentlive")
                                this.props.history.goBack()
                            }
                        })
                    }
                });
                //聊天框滚动事件监听
                this.scroll();
                //聊天回车事件
                // document.addEventListener("keydown", this.onKeyDown);
                //video标签全屏事件监听
                this.webkitfullscreenchange();

                //地址栏截取
                const data = this.props.location.search;
                const param = data.split("?")[1];
                const codeParam = param.includes("%") ? decodeURI(param) : param;
                const jsonparam = JSON.parse(codeParam);
                if (jsonparam.taskId) {
                    this.setState({
                        taskId: jsonparam.taskId,
                        taskName: jsonparam.taskName,
                        isReport:jsonparam.isReport
                    }, async () => {
                        //任务详情
                        this.getLiveDetail();
                        //获取直播间信息并收看直播
                        await this.getLiveInfo();
                        //获取聊天记录（这里需要单独维护两个分页参数，一个discussPageNum用作讨论，一个explainPageNum用作讲解）
                        await this.messageLog();

                    })
                } else {
                    //否则表示传参出错，提示错误，需要返回详情页重新进入
                }
            };

            getLiveInfo = async () => {
                await getGroup({
                    taskId: this.state.taskId,
                    userType: 3
                }).then(res => {
                    console.log("getGroup:::", res);
                    if (res) {
                        this.setState({
                            roomId: res.groupId,
                            teacherName: res.teacherName,
                            teacherId: res.teacherId,
                            teacherPortraitId: res.teacherPortraitId,
                            groupInfo: res,
                            isBanSpeech: res.isBanSpeech ? res.isBanSpeech : 0,
                            isJoinIn: res.isJoinIn ? res.isJoinIn : 0,
                            isHandsUp: res.isHandsUp ? res.isHandsUp : 0,
                            pauseTime:res.suspendTime
                        }, () => {
                            if (res.taskStatus == 2){
                                this.setState({
                                    liveStatusFlag:1
                                })
                            }
                            if (this.state.pauseTime != 0){
                                this.setState({
                                    isPause:1
                                });
                                this.livePauseTimer()
                            }
                            //成员列表
                            this.groupMemberInfo(true,true);//添加参数，限制页面高度重新计算
                            //后台计算userSig
                            getSdkAppId().then(res => {
                                console.log("aappId::::", res);
                                if (res) {
                                    //todo 这里测试一下签名错误报错情况
                                    this.setState({
                                        userSig: res.genSig,
                                        sdkAppId: res.sdkappid
                                    }, () => {
                                        //创建IM对象
                                        let tim = TIM.create({SDKAppID: this.state.sdkAppId});
                                        //TRTC客户端
                                        let client = TRTC.createClient({
                                            mode: 'live',
                                            sdkAppId: this.state.sdkAppId,
                                            userId: this.state.userId,
                                            userSig: this.state.userSig
                                        });
                                        this.setState({
                                            tim: tim,
                                            client: client
                                        }, async () => {

                                            //注册IM监听事件，登录IM（登录时处理了加入群组）
                                            this.getImEvent(tim);
                                            this.imLogin(tim, this.state.userId, this.state.userSig);
                                            //TRTC监听事件
                                            console.log("TRTC:::", client);
                                            this.handleEvents(client);
                                            this.joinTRTCRoom(this.state.roomId);
                                            if (this.state.groupInfo.connectGroupInfo ? this.state.groupInfo.connectGroupInfo.userId : '0' === this.state.userId) {
                                                this.setState({
                                                    audience: 1
                                                })
                                            }
                                        })
                                    })
                                } else {
                                    //后台获取签名超时
                                    Modal.info({
                                        content: "加载出错，请刷新重试",
                                        onOk: () => window.location.reload()
                                    })
                                }
                            });
                        });
                    } else {
                        //getGroup出错，返回结果错误
                        Modal.info({
                            content: "加载出错，请刷新重试",
                            onOk: () => window.location.reload()
                        })
                    }
                });
            }

            webkitfullscreenchange = () => {
                document.addEventListener("webkitfullscreenchange", (e) => {
                    console.log("全屏事件触发了！！！！！！！1", e.target.tagName);
                    let div = document.getElementById('local_stream').getElementsByTagName('div')[0];
                    let video = document.getElementById('local_stream').getElementsByTagName('video')[0];
                    if (e.target.tagName === 'VIDEO') {
                        //如果是video标签的操作则不可以全屏
                        document.webkitCancelFullScreen();
                        // div.webkitCancelFullScreen();
                        // video.webkitCancelFullScreen();
                    }
                });
            }

            scroll = () => {
                //聊天框滚动事件
                window.addEventListener(
                    "scroll",
                    () => {
                        console.log("触发了滚动:::")
                        let Ele = document.querySelector("#ChatTop")
                        let eleExplain = document.querySelector("#eleExplain")
                        let content = document.querySelector("#content")
                        let contenta = document.querySelector("#contenta")
                        if (Ele) {
                            let ElescrollTop = Ele.scrollTop;
                            if (ElescrollTop == 0) {
                                let contentHeight=content.offsetHeight
                                console.log("contentHeight",contentHeight)
                                if (this.state.discussTotalMsg > this.state.discussPageNum) {
                                    this.setState({
                                        discussPageNum: this.state.discussPageNum + 1,
                                        contentHeight:contentHeight
                                    }, () => {
                                        this.messageLogForScroll()
                                    })
                                }
                            }
                        }
                        if (eleExplain) {
                            let ElescrollTop = eleExplain.scrollTop;
                            if (ElescrollTop == 0) {
                                let contentHeighta=contenta.offsetHeight
                                if (this.state.explainTotalMsg > this.state.explainPageNum) {
                                    this.setState({
                                        explainPageNum: this.state.explainPageNum + 1,
                                        contentHeighta:contentHeighta
                                    }, () => {
                                        this.messageLogForScroll()
                                    })
                                }
                            }
                        }
                    },
                    true
                );
            }

            getDiscussMessageForScroll = () => {
                getChatMsg({taskId: this.state.taskId,chatType:1,pageNum:this.state.discussPageNum}).then(res => {
                    // console.log("聊天记录：：：", res);
                    if (res){
                        this.setState({
                            discussTotalMsg: res.pages
                        })
                        //这里res.data是数组
                        res.data.map((item, index) => {
                            console.log("聊天记录item：：：", item);
                            let fromUser = {
                                id: item.userId,
                                humanName: item.userName,
                                portraitId: item.portraitId,
                                userMark: item.userMark,
                                portraitIdName: item.interceptName
                            };
                            let message = {
                                action: item.chatType === 1 ? "TEXT_DISCUSS" : "TEXT_EXPLAIN",
                                body: {
                                    text: item.chatMessage,
                                    data: {
                                        id:item.id,
                                        sendTime: item.sendTime
                                    },
                                    fromUser
                                }
                            };
                            this.state.messageList.unshift(message);
                            console.log("聊天记录messageList：：：", this.state.messageList);
                        });
                        setTimeout(() => {
                            this.setState({
                                chatMsg: res.data,
                            },()=>{
                                console.log("调用了底部滚动");
                                this.scrollBottoms();
                            })
                        },500);

                    } else {
                        console.log("获取讨论消息数据未返回，可能是接口超时导致")
                    }
                    document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
                    document.getElementById('contenta') !== null && (document.getElementById('contenta').scrollTop = document.getElementById('enda').offsetTop);

                }).catch(error => {
                    console.log("获取讨论消息出错")
                });
            };

            getExplainMessageForScroll = () => {
                getChatMsg({taskId: this.state.taskId, chatType: 2, pageNum: this.state.explainPageNum}).then(res => {
                    console.log("聊天记录：：：", res);
                    if (res) {
                        this.setState({
                            explainTotalMsg: res.pages
                        })
                        //这里res.data是数组
                        res.data.map((item, index) => {
                            console.log("聊天记录item：：：", item);
                            let fromUser = {
                                id: item.userId,
                                humanName: item.userName,
                                portraitId: item.portraitId,
                                userMark: item.userMark,
                                portraitIdName: item.interceptName
                            };
                            let message = {
                                action: item.chatType === 1 ? "TEXT_DISCUSS" : "TEXT_EXPLAIN",
                                body: {
                                    text: item.chatMessage,
                                    data: {
                                        sendTime: item.sendTime
                                    },
                                    fromUser
                                }
                            };
                            this.state.messageList.unshift(message)
                            console.log("聊天记录messageList：：：", this.state.messageList);
                        });
                        this.setState({
                            chatMsg: res.data
                        },() => {
                            this.scrollBottoms()
                        })
                    } else {
                        console.log("获取讲解消息数据未返回，可能是接口超时导致")
                    }
                    document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
                    document.getElementById('contenta') !== null && (document.getElementById('contenta').scrollTop = document.getElementById('enda').offsetTop);
                }).catch(error => {
                    console.log("获取讲解消息出错")
                });
            };

            scrollBottoms = () => {
                const {contentHeight}=this.state
                let Ele = document.getElementById("content");
                let Elea = document.getElementById("contenta");
                let ChatTop = document.getElementById("ChatTop");
                let eleExplain = document.getElementById("eleExplain");
                if(Ele){
                    let EleHeight=Ele.offsetHeight;
                    let topHeight=EleHeight-contentHeight+100;
                    if (ChatTop) {
                        ChatTop.scrollTo(0,topHeight)
                    }
                }
                if(Elea){
                    let EleHeighta=Elea.offsetHeight;
                    let topHeighta=EleHeighta-contentHeight+100;
                    if (eleExplain) {
                        eleExplain.scrollTo(0, topHeighta)
                    }
                }
            }

            messageLogForScroll = (type) => {
                //讨论区
                if (type == 1){
                    //获取讨论消息
                    this.getDiscussMessageForScroll();
                }else if(type == 2){
                    //获取讲解消息
                    this.getExplainMessageForScroll();
                }else{
                    this.getDiscussMessageForScroll();
                    this.getExplainMessageForScroll();
                }
            };

            async getLiveDetail() {
                let data = {
                    taskId: this.state.taskId
                }
                let detailTable = await getStudentLiveDetail(data)
                console.log("111111", detailTable)
                if(detailTable.connectInfoVo?.studentId){
                    if(detailTable.connectInfoVo.studentId===this.state.userId){
                        this.setState({
                            audience:0,
                        })
                    }else{
                        this.setState({
                            audience:1,
                            isConnect:1
                        })
                    }
                }
                this.setState({
                    liveDetail: detailTable ? detailTable : {},
                }, () => {
                })
            }
            componentWillReceiveProps(nextProps, nextContext) {
                document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
                document.getElementById('contenta') !== null && (document.getElementById('contenta').scrollTop = document.getElementById('enda').offsetTop);
            }

            componentDidUpdate(prevProps, prevState, snapshot) {
                document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
                document.getElementById('contenta') !== null && (document.getElementById('contenta').scrollTop = document.getElementById('enda').offsetTop);
            }

            quitGroup = () => {
                if (this.state.tim){
                    this.state.tim.quitGroup(this.state.roomId).then((imResponse) => {
                        console.log(imResponse.data.groupID); // 退出成功的群 ID
                        this.state.tim.logout().then((imResponse) => {
                            console.log(imResponse.data); // 登出成功
                        }).catch((imError) => {
                            console.warn('logout error:', imError);
                        });
                    }).catch((imError) => {
                        console.warn('quitGroup error:', imError); // 退出群组失败的相关信息
                    });
                }
            }

            componentWillUnmount() {
                //组件销毁时进行登出（后端监听登出事件进行逻辑处理）
                clearInterval(this.lianMaiTimer);
                console.log("销毁组件")
                //退出im
                this.quitGroup();
                //离开TRTC房间
                this.leaveRoom();
            }

            getImEvent(tim) {
                // 监听事件
                tim.on(TIM.EVENT.SDK_READY, (event) => {
                    console.log("IM.EVENT.SDK_READY");
                    // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
                    // event.name - TIM.EVENT.SDK_READY
                    if (event.name === TIM.EVENT.SDK_READY) {
                        this.setState({
                            imSdkReady: true
                        })
                    }
                });
                tim.on(TIM.EVENT.MESSAGE_RECEIVED, (event) => {
                    let messages = event.data;
                    console.log("123123123", event);
                    messages.forEach(
                        (message) => {
                            //后续把if合起来
                            if (message.conversationType === "GROUP") {
                                //im房间匹配
                                if (message.to === this.state.roomId) {
                                    //文本类型
                                    if (message.type === "TIMTextElem") {
                                        console.log(message.payload);
                                        const mes = JSON.parse(message.payload.text);
                                        if (!mes.body){
                                            return;
                                        }
                                        this.setState({
                                            fromUser: mes.body.fromUser,
                                            toUser: mes.body.toUser
                                        }, async() => {
                                            switch (mes.action) {
                                                case 'JOIN_ROOM':
                                                    //显示进入房间消息
                                                    //①、XXX进入直播间
                                                    //更新成员列表
                                                    await this.groupMemberInfo(true,false);
                                                    this.state.messageList.push(mes);
                                                    this.setState({
                                                        deadline: 1
                                                    },()=>{
                                                        this.scrollBottom()
                                                    });
                                                    break;
                                                case 'QUIT_ROOM':
                                                    //
                                                    // ②、XXX退出直播间
                                                    this.state.messageList.push(mes);
                                                    //更新成员列表
                                                    if (this.state.fromUser.id === this.state.teacherId) {
                                                        //如果老师退出，关闭视频流
                                                        if (this.state.remoteStream) {
                                                            this.state.remoteStream.stop();
                                                        }
                                                    }
                                                    await this.groupMemberInfo(true,false);
                                                    this.setState({
                                                        deadline: 1
                                                    },()=>{
                                                        this.scrollBottom()
                                                    });
                                                    break;
                                                case 'LIVE_BEGIN':
                                                    console.log("开播");
                                                    // this.joinTRTCRoom('111122222');
                                                    this.setState({
                                                        liveStatusFlag:1,
                                                        waitStart:0
                                                    })
                                                    break;
                                                case 'LIVE_END':
                                                    this.liveEnd()
                                                    break;
                                                case 'FORCE_CLOSE':
                                                    this.forceEnd()
                                                    break;
                                                case 'SHARE_BEGIN':
                                                    console.log("屏幕分享开始");
                                                    this.setState({
                                                        tinyWindowFlag:1
                                                    },()=>{
                                                        this.reCalcRightHeiFun()
                                                    });
                                                    break;
                                                case 'SHARE_END':
                                                    console.log("屏幕分享结束");
                                                    if (this.state.remoteScreenStream) {
                                                        this.state.remoteScreenStream.stop();
                                                        //这里把右上角摄像头流切换到大屏幕的代码
                                                        let myCamera = document.getElementById('myCamera');
                                                        if (myCamera.innerHTML !== "") {
                                                            this.state.remoteStream.stop();
                                                            this.state.remoteStream.play("local_stream");
                                                            this.initBarrage(this.state.remoteStream);
                                                            this.setState({
                                                                deadline: 1,
                                                                remoteScreenStream:null
                                                            },async()=>{
                                                                await this.initRightHeiFun()
                                                                await this.scrollBottom()
                                                            })
                                                        }
                                                    }
                                                    break;
                                                case 'TEXT_DISCUSS':
                                                    console.log("讨论消息");
                                                    this.state.messageList.push(mes);
                                                    this.state.barrageList.push(mes.body.text);
                                                    if (this.state.eleVideo) {
                                                        console.log("进行add的弹幕对象：：：", this.state.canvasBarrage);
                                                        this.state.canvasBarrage.add({
                                                            value: mes.body.text,
                                                            time: this.state.eleVideo.currentTime
                                                            // 其它如color, fontSize, opacity等可选
                                                        })
                                                    }
                                                    setTimeout(() => {
                                                        console.log("add结束开始render");
                                                        this.setState({
                                                            deadline: 1
                                                        },()=>{
                                                            this.scrollBottom()
                                                        });
                                                    }, 1);
                                                    console.log("this.state.messageList:::", this.state.messageList);
                                                    break;
                                                case 'TEXT_EXPLAIN':
                                                    console.log("讲解消息");
                                                    this.state.messageList.push(mes);
                                                    this.setState({
                                                        deadline: 1
                                                    },()=>{
                                                        this.scrollBottom()
                                                    });
                                                    console.log("this.state.messageList:::", this.state.messageList);
                                                    break;
                                                case 'CONNECT_BEGIN':
                                                    //连麦
                                                {
                                                    console.log("连麦开始");
                                                    this.setState({
                                                        isConnect: 1,
                                                        audience:1,
                                                        connecting:0,
                                                    });
                                                    // }
                                                    this.groupMemberInfo(false,false);
                                                    //这里就可以开启主播模式发起直播进行连麦了
                                                }
                                                    break;
                                                case 'CONNECT_END':
                                                    clearInterval(this.lianMaiTimer);
                                                    this.setState({
                                                        lianMaiTime: '00:00:00',
                                                        isConnect: 0,
                                                        connecting:0,
                                                    });
                                                    this.groupMemberInfo(false,false);
                                                    break;
                                                case 'CANCEL_CONNECT':
                                                    //发消息通知连麦结束
                                                    this.setState({
                                                        isConnect: 0,
                                                        connecting: 0,
                                                    });
                                                    this.groupMemberInfo(false,false);
                                                    break;
                                                case 'CONNECT_REFUSE':
                                                    //发消息通知连麦结束
                                                    console.log("CONNECT_REFUSE")
                                                    this.setState({
                                                        isConnect: 0,
                                                        connecting:0,
                                                    });
                                                    break;
                                                case 'RAISE_HAND':
                                                    //收到某人举手消息，标记举手状态
                                                    //这里通过调取成员列表的接口直接刷新状态，在此之前需要记录一个当前页，用来维持列表页数不变
                                                    this.groupMemberInfo(false,false);
                                                    break;
                                                case 'CANCEL_HAND':
                                                    //收到某人取消举手的消息，删除举手状态
                                                    this.groupMemberInfo(false,false);
                                                    break;
                                                case 'HAND_EMPTY':
                                                    //收到清空举手的消息，删除举手状态
                                                    this.groupMemberInfo(false,false);
                                                    this.setState({
                                                        isHand:0
                                                    });
                                                    break;
                                                case 'HAND_BANNED':
                                                    //收到某人取消举手的消息，删除举手状态
                                                    this.groupMemberInfo(false,false);
                                                    this.setState({
                                                        isHand:0
                                                    });
                                                    break;
                                                case 'VOTE_OPEN':
                                                    //发起投票
                                                    console.log("发起投票");
                                                    getVoteList({
                                                        taskId: this.state.taskId,
                                                        userId: this.state.userId
                                                    }).then(res => {
                                                        console.log("投票列表：：：", res);
                                                        this.setState({
                                                            voteList: res.voteList,
                                                            voteStat: res.voteStat,
                                                            activityId: res.voteList[res.voteList.length - 1].activityId,
                                                            activityName: res.voteList[res.voteList.length - 1].activityName
                                                        })
                                                    })
                                                    break;
                                                case 'VOTE_OVER':
                                                    //这里接收其他人完成投票的消息
                                                    //需要投票id以及所投的选项，用来更新投票的统计图
                                                    console.log("投票完成");
                                                    break;
                                                case 'BANNED':
                                                    if (this.state.toUser.id === this.state.userId){
                                                        //被禁言
                                                        this.setState({
                                                            isBanSpeech: 1
                                                        });
                                                        this.groupMemberInfo(false,false);
                                                    }
                                                    break;
                                                case 'ALL_BANNED':
                                                    //被禁言
                                                    this.setState({
                                                        isBanSpeech: 1
                                                    });
                                                    this.groupMemberInfo(false,false);
                                                    break;
                                                case 'BANNED_END':
                                                    //解除禁言
                                                    if (this.state.toUser.id === this.state.userId){
                                                        //被禁言
                                                        this.setState({
                                                            isBanSpeech: 0
                                                        });
                                                        this.groupMemberInfo(false,false);
                                                    }
                                                    break;
                                                case 'ALL_BANNED_END':
                                                    //解除禁言
                                                    this.setState({
                                                        isBanSpeech: 0
                                                    });
                                                    this.groupMemberInfo(false,false);
                                                    break;
                                                case 'START_RECORDING':
                                                    //开始录制
                                                    this.setState({
                                                        videoId: mes.body.data.playbackId,
                                                        isRecord: 1
                                                    }, () => {
                                                        console.log("开始录制后的状态", this.state.videoId, this.state.isRecord)
                                                        setInterval(() => {
                                                            this.setState({
                                                                time: this.state.time + 1
                                                            });
                                                        }, 1000)
                                                    });
                                                    break;
                                                case 'STOP_RECORDING':
                                                    //结束录制
                                                    this.setState({
                                                        videoId: '',
                                                        isRecord: 0,
                                                        time: 0
                                                    });
                                                    break;
                                                case 'FORCE_LEAVE':
                                                    //老师踢人
                                                    if (this.state.toUser.id === this.state.userId) {
                                                        // this.props.history.push("/studentlive");
                                                        this.props.history.goBack()
                                                    }
                                                    break;
                                                case 'RANDOM_SELECT':
                                                    //随机选人
                                                    if (this.state.toUser.id === this.state.userId) {
                                                        console.log("我被随机选人选中了！！！！！！")
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                        });
                                        console.log("转换后的消息对象", mes)
                                    }
                                }
                            }
                        });
                    console.log("IM接收到的messages:::", event)
                });

                tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, (event) => {
                    console.log("IM.EVENT.CONVERSATION_LIST_UPDATED,收到会话列表更新通知", event)
                    // 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
                    // event.name - TIM.EVENT.CONVERSATION_LIST_UPDATED
                    // event.data - 存储 Conversation 对象的数组 - [Conversation]
                });


                tim.on(TIM.EVENT.GROUP_LIST_UPDATED, (event) => {
                    console.log("IM.EVENT.GROUP_LIST_UPDATED,收到群组列表更新通知", event)
                });
                tim.on(TIM.EVENT.ERROR, async (event) => {
                    console.log("TIM.EVENT.ERROR");
                    // 收到 SDK 发生错误通知，可以获取错误码和错误信息
                    // event.name - TIM.EVENT.ERROR
                    // event.data.code - 错误码
                    // event.data.message - 错误信息
                    console.log("错误码", event.data.code);
                    console.log("错误信息", event.data.message);
                });

                tim.on(TIM.EVENT.SDK_NOT_READY, (event) => {
                    console.log("TIM.EVENT.SDK_NOT_READY", event);
                    // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
                    // event.name - TIM.EVENT.SDK_NOT_READY
                });

                tim.on(TIM.EVENT.KICKED_OUT, function (event) {
                    console.log("TIM.EVENT.KICKED_OUT", event);
                    if (this.state?.kickOut){
                        Modal.info({
                            content: '当前账号在其他地方登录，请重新进入直播间',
                            onOk:() => {
                                this.setState({
                                    kickOut:false
                                },()=>{
                                    this.props.history.push('login')
                                })
                            }
                        })
                    }
                    // 收到被踢下线通知
                    // event.name - TIM.EVENT.KICKED_OUT
                    // event.data.type - 被踢下线的原因，例如 :
                    //   - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
                    //   - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
                    //   - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢（v2.4.0起支持）。
                });

                tim.on(TIM.EVENT.NET_STATE_CHANGE, async (event) => {
                    console.log("TIM.EVENT.NET_STATE_CHANGE", event);
                    // 网络状态发生改变（v2.5.0 起支持）。
                    // event.name - TIM.EVENT.NET_STATE_CHANGE
                    // event.data.state 当前网络状态，枚举值及说明如下：
                    if (event.data.state === TIM.TYPES.NET_STATE_CONNECTING) {
                        message.destroy();
                        message.warning("当前网络状态不佳,正在重连")
                    } else if (event.data.state === TIM.TYPES.NET_STATE_DISCONNECTED) {
                        message.destroy();
                        message.warning("网络断开，请查看网络")
                    } else if (event.data.state === TIM.TYPES.NET_STATE_CONNECTED) {
                        //重新进入trtc房间接收直播流
                        message.warning("网络恢复，正在尝试重连")
                        if (this.state.client) {
                            setTimeout(() => {
                                this.state.client.leave().catch(error => {
                                    console.error('退房失败 ' + error);
                                    message.warning("重连失败，请手动刷新重试")
                                    // 错误不可恢复，需要刷新页面。
                                }).then(() => {
                                    console.log("退出直播房间成功！！！");
                                    this.joinTRTCRoom(this.state.roomId);
                                    // 退房成功，可再次调用client.join重新进房开启新的通话。
                                });
                            },5000)
                        }
                    }
                    //   - TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
                    //   - TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
                    //   - TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
                });
            }

            imLogin = (tim, userId, userSig) => {
                // 开始登录IM
                if (tim) {
                    tim.login({
                        userID: userId,
                        userSig: userSig
                    })
                        .then((imResponse) => {
                            console.log(imResponse.data); // 登录成功
                            if (imResponse.data.repeatLogin === true) {
                                // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                                console.log(imResponse.data.errorInfo);
                            }
                            //登录成功后加入群组
                            this.joinGroup(tim, this.state.roomId, TIM.TYPES.GRP_AVCHATROOM);
                        }).catch((imError) => {
                        console.warn('login error:', imError);// 登录失败的相关信息
                    });
                } else {
                    //tim不存在，刷新重试
                    Modal.info({
                        content: "聊天室加载出错，请刷新重试",
                        onOk: () => window.location.reload()
                    })
                }
            };

            joinGroup = (tim, groupId, type) => {
                let joinGroup = tim.joinGroup({groupID: groupId, type: type});
                joinGroup.then((imResponse) => {
                    console.log("加群后返回的消息", imResponse);
                    switch (imResponse.data.status) {
                        case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                            break;
                        case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                            console.log(imResponse.data.group);
                            //进入房间时，显示一条自己进入房间的消息
                            let fromUser = {
                                id: this.state.userId,
                                humanName: this.state.userName,
                                portraitId: "头像地址",
                                userMark: 3,
                            };
                            const messageText = {
                                action: "JOIN_ROOM",
                                body: {
                                    text: `${this.state.userName}加入了直播间`,
                                    data: {},
                                    fromUser,
                                    toUser: {},
                                }
                            };
                            this.state.messageList.push(messageText);
                            this.setState({
                                deadline: 1
                            },()=>{
                                this.scrollBottom()
                            });
                            break;
                        case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                            break;
                        default:
                            break;
                    }
                }).catch(function (imError) {
                    console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
                });
            };


            quitLive = () => {
                Modal.confirm({
                    className:"Modal-Createpolla",
                    title:"提示",
                    content: "是否确定退出直播间？",
                    okText: "确定",
                    centered:true,
                    closable:true,
                    onOk: () => {
                        // this.props.history.push('studentlive');
                        this.props.history.goBack()
                    },
                })
            }

            liveEnd = () => {
                message.destroy();
                message.info("此次直播任务已结束");
                this.setState({
                    liveStatusFlag:0
                })
                this.props.history.goBack()
            }
            forceEnd = () => {
                this.setState({
                    liveStatusFlag:0
                })
                message.destroy();
                message.info("此次直播任务已强制结束");
                this.props.history.goBack()
            }

            groupMemberInfo = (calcFalg,initSwiper) => {
                const {groupPageSize, groupPageNum} = this.state
                //群组成员列表
                getGroupInfo({taskId: this.state.taskId}).then(res => {
                    console.log(res);
                    if (res) {
                        let isHandNum = 0;
                        for (let i = 0; i < res.length; i++) {
                            if (res[i].isHand === 1) {
                                isHandNum = isHandNum + 1
                            }
                        }
                        this.setState({
                            memberList: res,
                            isHandNum
                        })
                    } else {
                        message.destroy();
                        message.warning("获取成员数据出错")
                    }
                });
                //获取群组分页
                getGroupInfoPage({
                    taskId: this.state.taskId,
                    pageNum: groupPageNum,
                    pageSize: groupPageSize
                }).then(e => {
                    console.log(e.data,'e.data');
                    this.setState({
                        studentsPortrait:e.data,
                        groupTotal:e.total,
                        groupTotalPages:e.pages
                    }, async () => {
                        if(calcFalg) {
                            await this.reCalcLeftFun();//计算直播及评论区高度
                            if (this.state.remoteScreenStream) {
                                await this.reCalcRightHeiFun()
                            } else {
                                await this.initRightHeiFun()
                            }
                        }
                        if(initSwiper){
                            var swiper = new Swiper('.swiper-container', {
                                slidesPerView: 20,
                                slidesPerColumn: 2,
                                // spaceBetween:10,
                                slidesPerColumnFill: 'row',
                                // pagination: '.swiper-pagination',
                                // paginationClickable: true,
                                // nextButton: '.swiper-button-next',//绑定下一页的控制按钮
                                // prevButton: '.swiper-button-prev'//绑定上一页的控制按钮
                            })
                        }
                    })
                })
            };

            //下一页
            nextFun= ()=>{
                let {groupTotalPages} = this.state;
                let swiperWidth = document.querySelector('.swiper-container').clientWidth;
                let stemp = this.state.groupPageNum;
                if(stemp<groupTotalPages){
                    let translateDistance ="-" + (swiperWidth * stemp) + 'px';
                    this.setState({
                        groupPageNum:this.state.groupPageNum + 1,
                        prevStemp:0
                    },()=>{
                        this.groupMemberInfo(false,false);
                        // document.querySelector('.swiper-wrapper').style.transform = "translate3d("+translateDistance+", 0px, 0px)";
                        document.querySelector('.swiper-wrapper').style.transform = "translate3d(0px, 0px, 0px)";
                    });
                }else{
                    this.setState({
                        nextBtnDisable:true
                    })
                }
            }
            //上一页
            prevFun=()=>{
                let {groupPageNum,prevStemp} = this.state;
                let swiperWidth = document.querySelector('.swiper-container').clientWidth;
                this.setState({
                    prevStemp:prevStemp + 1
                },()=>{
                    if(groupPageNum>1){
                        let translateDistance =(swiperWidth * prevStemp) + 'px';
                        this.setState({
                            groupPageNum:groupPageNum - 1
                        },()=>{
                            this.groupMemberInfo(false,false);
                            // document.querySelector('.swiper-wrapper').style.transform = "translate3d("+translateDistance+", 0px, 0px)";
                            document.querySelector('.swiper-wrapper').style.transform = "translate3d(0px, 0px, 0px)";
                        });
                    }else{
                        this.setState({
                            preBtnDisable:true
                        })
                    }
                })
            }
            //左侧高度计算
            reCalcLeftFun(){
                //计算直播区域高度
                let bodyHeight = document.body.clientHeight;
                let onlineTopDom = document.querySelector('.teacher-online-top');
                let onlineTopDomHeiht = onlineTopDom.clientHeight;
                let videoCountDom = document.querySelector('.video-cont');
                videoCountDom.style.height = (bodyHeight - 78 - onlineTopDomHeiht -12 - 18 - 96 - 40) +'px';   //直播区域高度 = 屏幕内容高度 - header高度- 连线高度 - marginTop高度 - 底部操作栏高度 - 最外层padding-bottom
            }
            //初始化右侧高度计算
            initRightHeiFun(){
                //根据左侧，同步计算右侧
                let previewBoxDom = document.querySelector('.preview-box');
                let previewBoxDomHeight = previewBoxDom.clientHeight;
                let ChatLeftTopDom = document.getElementById('ChatTop');
                let eleExplainDom = document.getElementById('eleExplain');
                let ChatRightDom = document.getElementById('member');
                let ChatLeftBottomDom = document.querySelector('.Chat-left-bottom');
                if(ChatLeftTopDom){
                    ChatLeftTopDom.style.height = (previewBoxDomHeight - 50 - 20 - 20 - 140) + 'px';
                    ChatLeftBottomDom.style.height = "140px";
                }
                if(eleExplainDom){
                    eleExplainDom.style.height = (previewBoxDomHeight - 50 - 40) + 'px';
                }
                if(ChatRightDom){
                    ChatRightDom.style.height = (previewBoxDomHeight - 50 - 62 - 64 ) + 'px';
                }
            }
            joinTRTCRoom = async (roomId) => {
                //加入直播
                console.log("room:::加入房间");
                if (this.state.client) {
                    this.state.client
                        .join({roomId: parseInt(roomId), role: 'audience'})
                        .then(() => {
                            console.log('room:::进房成功');
                        })
                        .catch(error => {
                            console.log(typeof error);
                            console.log(error);
                            console.error('进房失败 ' + error);
                        });
                }
            };

            leaveRoom = () => {
                if (this.state.client) {
                    this.state.client.leave().catch(error => {
                        console
                            .error(error => {
                                console.error('退房失败 ' + error);
                                // 错误不可恢复，需要刷新页面。
                            })
                            .then(() => {
                                console.log("退出直播房间成功！！！")
                                // 退房成功，可再次调用client.join重新进房开启新的通话。
                            });
                    });
                }
            };

            //注册TRTC的监听事件
            handleEvents = (client) => {
                // 处理 client 错误事件，错误均为不可恢复错误，建议提示用户后刷新页面
                client.on('error', err => {
                    console.error("trtc发生错误 err:::", err);
                    // message.destroy();
                    // message.error('发生错误：' + err);
                    // Modal.info({
                    //     content:'发生错误：' + err + "即将刷新浏览器",
                    //     onOk:() =>  window.location.reload()
                    // })
                    // this.props.history.push(`/my-live`)
                });

                // 处理用户被踢事件，通常是因为房间内有同名用户引起，这种问题一般是应用层逻辑错误引起的
                // 应用层请尽量使用不同用户ID进房
                client.on('client-banned', err => {
                    console.log('client has been banned for ' + err);
                    // message.error('用户被踢出房间！');
                    // window.location.reload();
                });

                // 远端用户进房通知 - 仅限主动推流用户
                client.on('peer-join', evt => {
                    const userId = evt.userId;
                    console.log('peer-join ' + userId);
                    // message.info('收到远端用户推流------userId为：：：：： ' + userId);
                });
                // 远端用户退房通知 - 仅限主动推流用户
                client.on('peer-leave', evt => {
                    const userId = evt.userId;
                    console.log('peer-leave ' + userId);
                    if (userId === this.state. teacherId) {
                        if (this.state.remoteScreenStream) {
                            //这里把右上角摄像头流切换到大屏幕的代码
                            console.log("这里把右上角摄像头流切换到大屏幕的代码");
                            this.setState({
                                deadline: 1,
                                tinyWindowFlag: 0,
                                remoteScreenStream: null
                            }, async () => {
                                console.log("在remove事件中将remoteScreenStream置空", this.state.remoteScreenStream)
                                await this.initRightHeiFun()
                                await this.scrollBottom()
                            });
                            let myCamera = document.getElementById('myCamera');
                            if (myCamera?.innerHTML !== "" && this.state.remoteStream) {
                                this.state.remoteStream.stop();
                                this.state.remoteStream.play("local_stream");
                                this.initBarrage(this.state.remoteStream);
                            } else {
                                this.initBarrage(this.state.remoteStream);
                            }
                        }
                    }
                });

                //订阅远端音视频流me
                client.on('stream-added', event => {
                    const remoteStream = event.stream;
                    //userId+roomId屏幕分享
                    // const remoteStreamId = remoteStream.getId();
                    // const userId = remoteStream.getUserId();
                    // message.info('远端流增加 ------- ' + userId);
                    //订阅远端流
                    client.subscribe(remoteStream);
                });

                // 远端流订阅成功事件me
                client.on('stream-subscribed', async event => {
                    const remoteStream = event.stream;
                    const remoteStreamId = remoteStream.getId();
                    const remoteStreamUserId = remoteStream.getUserId();
                    if (this.state.localStream && this.state.localStream.getId() === remoteStreamId) {
                        console.log("本队流不play");
                        return;
                    }
                    console.log('流类型：：', remoteStream.getType(), remoteStreamUserId, this.state.teacherId, this.state.roomId, remoteStreamUserId.substring(remoteStreamUserId.length - 9, remoteStreamUserId.length));
                    console.log('远端流订阅成功：' + remoteStreamId);
                    console.log('remoteStreamUserId：' + remoteStreamUserId);
                    console.log('this.state.teacherId：' + this.state.teacherId);
                    //视频流是userID.屏幕分享流是userid_roomId
                    let shareUserIdArray = remoteStreamUserId.split("_")
                    console.log("shareUserId", shareUserIdArray)
                    if (shareUserIdArray.length > 1 && shareUserIdArray[1] === this.state.roomId) {
                        //为屏幕分享流
                        // 初始化弹幕方法
                        console.log("?????????????????????????????????");
                        if (this.state.remoteScreenStream) {
                            console.log("?????????????????????????????????弹幕停止");
                            this.state.remoteScreenStream.stop();
                            this.closeBarrage1();
                        }
                        this.setState({
                            remoteScreenStream: remoteStream,
                            liveStatusFlag: 1,
                            tinyWindowFlag:1
                        }, async() => {
                            await this.reCalcRightHeiFun();
                            await this.scrollBottom();
                            //把摄像头流切换到小屏
                            let screen = document.getElementById('local_stream');
                            console.log("?????????????????????????????????123", this.state.remoteStream);
                            if (this.state.remoteStream) {
                                console.log("?????????????????????????????????本地流存在");
                                this.state.remoteStream.stop();
                                this.state.remoteStream.play('myCamera');
                                remoteStream.play('local_stream').then(e => {
                                    console.log("屏幕分享流play成功！！！", e);
                                    this.initBarrage(remoteStream);
                                }).catch(error => {
                                    console.log("屏幕分享流play失败！！！", error);
                                    this.initBarrage(remoteStream);
                                });
                            } else {
                                //这个表示摄流不存在，直接播放在大屏完事
                                remoteStream.play('local_stream').then(e => {
                                    console.log("屏幕分享流play成功！！！", e)
                                    this.initBarrage(remoteStream);
                                }).catch(error => {
                                    console.log("屏幕分享流play失败！！！", error)
                                    this.initBarrage(remoteStream);
                                });
                            }
                        })
                    } else if (shareUserIdArray.length <= 1 && remoteStreamUserId === this.state.teacherId) {
                        if (this.state.remoteStream) {
                            this.state.remoteStream.stop();
                            this.closeBarrage1();
                        }
                        this.setState({
                            isVoice: true,
                            isCamera: true,
                            audioDisabled: false,
                            liveStatusFlag: 1,
                            remoteStream: remoteStream
                        }, () => {
                            if (this.state.remoteScreenStream) {
                                let that = this
                                //直接播放到右上角
                                remoteStream.play('myCamera').then(e => {
                                    this.initBarrage(remoteStream);
                                })
                                remoteStream.on('error', error => {
                                    console.log("play---error1111", error)
                                    const errorCode = error.getCode();
                                    if (errorCode === 0x4043) {
                                        // PLAY_NOT_ALLOWED,引导用户手势操作并调用 stream.resume 恢复音视频播放
                                        if (this.state.playError === 0) {
                                            Modal.success({
                                                title: '温馨提示',
                                                content: '主讲教师正在直播中，点击观看直播...',
                                                okText: 'OK',
                                                onOk() {
                                                    remoteStream.resume()
                                                    that.initBarrage(remoteStream);
                                                }
                                            });
                                            this.setState({
                                                playError: 1
                                            })
                                        }
                                    }
                                })
                            } else {
                                let that = this
                                remoteStream.play('local_stream').then(e => {
                                    console.log("play成功");
                                    this.initBarrage(remoteStream);
                                })
                                remoteStream.on('error', error => {
                                    console.log("play---error222", error)
                                    const errorCode = error.getCode();
                                    if (errorCode === 0x4043) {
                                        // PLAY_NOT_ALLOWED,引导用户手势操作并调用 stream.resume 恢复音视频播放
                                        if(this.state.playError === 0){
                                            Modal.success({
                                                title: '温馨提示',
                                                content: '主讲教师正在直播中，点击观看直播...',
                                                okText: 'OK',
                                                onOk() {
                                                    remoteStream.resume()
                                                    that.initBarrage(remoteStream);
                                                }
                                            });
                                            this.setState({
                                                playError : 1
                                            })
                                        }
                                    }
                                })
                            }
                        })
                        // else if (remoteStreamUserId.indexOf('student') !== -1) {
                    }  else {
                        //连麦流
                        this.setState({
                            isConnect: 1,
                            connecting:1,
                        }, () => {
                            remoteStream.play('connect').then(e => {
                                console.log("连麦成功")
                                this.lianMaiTime();
                            }).catch(error => {
                                console.log("连麦失败", error)
                                this.setState({
                                    connecting:0,
                                })
                            })
                        })
                    }
                    console.log("remoteStream", remoteStream);
                    // 播放远端流
                });

                // 处理远端流被删除事件
                client.on('stream-removed', evt => {
                    const remoteStream = evt.stream;
                    const remoteStreamId = remoteStream.getId();
                    const remoteStreamUserId = remoteStream.getUserId();
                    // 关闭远端流内部的音视频播放器
                    console.log("remove的远程流", remoteStream);
                    let shareUserIdArray = remoteStreamUserId.split("_")
                    if (shareUserIdArray.length > 1 && shareUserIdArray[1] === this.state.roomId) {
                        //为屏幕分享流
                        remoteStream.stop();
                        if (this.state.remoteScreenStream) {
                            //这里把右上角摄像头流切换到大屏幕的代码
                            console.log("这里把右上角摄像头流切换到大屏幕的代码");
                            this.setState({
                                deadline: 1,
                                tinyWindowFlag:0,
                                remoteScreenStream:null
                            },async()=>{
                                console.log("在remove事件中将remoteScreenStream置空",this.state.remoteScreenStream)
                                await this.initRightHeiFun()
                                await this.scrollBottom()
                                let myCamera = document.getElementById('myCamera');
                                if (myCamera?.innerHTML !== "" && this.state.remoteStream) {
                                    this.state.remoteStream.stop();
                                    this.state.remoteStream.play("local_stream");
                                    this.initBarrage(this.state.remoteStream);
                                }else{
                                    this.initBarrage(this.state.remoteStream);
                                }
                            });
                        }
                    } else {
                        //只处理老师的删除事件
                        if (remoteStreamUserId === this.state.teacherId) {
                            this.setState({
                                isVoice: false,
                                isCamera: false,
                                audioDisabled: true,
                            }, () => {
                                remoteStream.stop();
                            })
                        }
                    }
                    if (this.state.remoteStream === null && this.state.remoteScreenStream === null){
                        this.setState({
                            liveStatusFlag:0,
                            waitStart:1
                        })
                    }
                    console.log(`stream-removed ID: ${remoteStreamId}  type: ${remoteStream.getType()}`);
                });

                // 处理远端流更新事件，在音视频通话过程中，远端流音频或视频可能会有更新
                client.on('stream-updated', evt => {
                    const remoteStream = evt.stream;
                    // const remoteStreamId = remoteStream.getId();
                    // const remoteStreamUserId = remoteStream.getUserId();
                    console.log(
                        'type: ' +
                        remoteStream.getType() +
                        ' stream-updated hasAudio: ' +
                        remoteStream.hasAudio() +
                        ' hasVideo: ' +
                        remoteStream.hasVideo()
                    );
                    console.log("remote流的id：：：" + remoteStream.getId())
                    this.initBarrage(remoteStream);
                });

                // 远端流音频或视频mute状态通知
                client.on('mute-audio', evt => {
                    console.log(evt.userId + ' mute audio');
                });
                client.on('unmute-audio', evt => {
                    console.log(evt.userId + ' unmute audio');
                });
                client.on('mute-video', evt => {
                    console.log(evt.userId + ' mute video');
                });
                client.on('unmute-video', evt => {
                    console.log(evt.userId + ' unmute video');
                });

                // 信令通道连接状态通知
                client.on('connectHion-state-changed', evt => {
                    console.log(`RtcClient state changed to ${evt.state} from ${evt.prevState}`);
                });
            };

            //发消息
            createMessage = async (action) => {
                let mes = this.state.message;
                if (this.state.message.trim() === "") {
                    message.destroy();
                    message.warning("消息内容不能为空");
                    return;
                }
                // this.setState({
                //     message: ''
                // });
                if (this.state.tim && (action === "TEXT_DISCUSS" || action === "TEXT_EXPLAIN")) {
                    //聊天消息需要保存聊天记录，其他直接发送im即可
                    let param = {
                        chatMessage: mes,
                        chatType: 1,
                        isDelete: 0,
                        taskId: this.state.taskId,
                        userId: this.state.userId,
                        userMark: 3,
                        videoTime: this.state.time,
                        userName: this.state.userName
                    };
                    //如果正在录像中，需要把录像id一起保存到聊天记录中，录像回放时需要
                    if (this.state.isRecord === 1) {
                        console.log("传参了");
                        param.videoId = this.state.videoId
                    } else {
                        param.videoId = ''
                    }
                    //保存聊天记录，并且收到res后进行im，弹幕等处理
                    await saveChatMessage(param).then(res => {
                        if (res) {
                            console.log("保存聊天消息", res);
                            let current = new Date();
                            let time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
                            const toUser = {
                                id: this.state.teacherId
                            };
                            const fromUser = {
                                id: this.state.userId,
                                humanName: this.state.userName,
                                portraitId: res.portraitId ? res.portraitId : '',
                                userMark: 3,
                                portraitIdName: res.interceptName
                            };
                            const messageText = {
                                action: action,
                                body: {
                                    text: res.chatMessage,
                                    data: {
                                        id: res.id,
                                        sendTime: res.sendTime
                                    },
                                    fromUser
                                }
                            };
                            console.log("发送消息111：：：",messageText,this.state.imSdkReady);
                            //im发送消息
                            this.sendImMessage(res.chatMessage,messageText);
                            //添加一条弹幕，并重新渲染
                            if (this.state.eleVideo && this.state.startBarrage === 1) {
                                console.log("进行add的弹幕对象：：：", this.state.canvasBarrage);
                                this.state.canvasBarrage.add({
                                    value: res.chatMessage,
                                    time: this.state.eleVideo.currentTime
                                    // 其它如color, fontSize, opacity等可选
                                });
                            }
                            this.setState({
                                message: ''
                            });
                        } else {
                            //接口调用失败

                        }

                    });

                } else {
                    const toUser = {
                        id: this.state.teacherId
                    };
                    let fromUser = {
                        id: this.state.userId,
                        humanName: this.state.userName,
                        portraitId: '',
                        userMark: 3,
                    };
                    //这里进行发送非聊天的消息
                    const messageText = {
                        action: action,
                        body: {
                            text: mes,
                            fromUser,
                            toUser
                        }
                    };
                    console.log("发送消息111：：：",messageText,this.state.imSdkReady);
                    //im发送消息
                    this.sendImMessage(mes,messageText);
                }

                document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
            };

            sendImMessage = (message,messageJsonText) => {
                if (this.state.imSdkReady === true){
                    const message1 = this.state.tim.createTextMessage({
                        to: this.state.roomId,
                        conversationType: TIM.TYPES.CONV_GROUP,
                        payload: {
                            text: JSON.stringify(messageJsonText)
                        }
                    });
                    this.state.tim.sendMessage(message1).then(e => {
                        console.log("发送聊天消息", e);
                        this.state.messageList.push(messageJsonText);
                        this.state.barrageList.push(message);
                        this.setState({
                            message: ''
                        },() => {
                            this.scrollBottom()
                        });
                    });
                }
            }

            //聊天框滚动条下滑到底部
            scrollBottom=()=>{
                let Ele=document.getElementById("content");
                let Elea=document.getElementById("contenta");
                let ChatTop=document.getElementById("ChatTop");
                let eleExplain=document.getElementById("eleExplain");
                if(Ele && ChatTop){
                    ChatTop.scrollTo(0, Ele.offsetHeight)
                }
                if(Elea && eleExplain){
                    eleExplain.scrollTo(0, Elea.offsetHeight)
                }
            }

            // 连麦时长
            lianMaiTime = () => {
                let {mm, lianMaiTime, ss, hh} = this.state;
                this.lianMaiTimer = setInterval(() => {
                    lianMaiTime = "";
                    if (++ss === 60) {
                        if (++mm === 60) {
                            if (++hh === 24) {
                                hh = 0;
                            }
                            mm = 0;
                        }
                        ss = 0;
                    }
                    lianMaiTime += hh < 10 ? "0" + hh : hh;
                    lianMaiTime += ":";
                    lianMaiTime += mm < 10 ? "0" + mm : mm;
                    lianMaiTime += ":";
                    lianMaiTime += ss < 10 ? "0" + ss : ss;
                    this.setState({lianMaiTime});
                }, 1000);
            };

            initBarrage = (remoteStream) => {
                // const eleCanvas = document.getElementById('canvasBarrage');
                if (remoteStream) {
                    const eleVideo = document.getElementById(`video_${remoteStream.getId()}`);
                    // let eleVideo = document.getElementById('local_stream').getElementsByTagName('video')[0];
                    console.log(`这是组装出的video的id：：：video_${remoteStream.getId()}`);
                    console.log("这个是video标签1：：：：", eleVideo);
                    setTimeout(() => {
                        if (eleVideo) {
                            eleVideo.removeAttribute("controls");
                            let htmlCanvasElement = document.createElement('canvas');
                            console.log("手动创建的canvas节点", htmlCanvasElement);
                            if (htmlCanvasElement) {
                                htmlCanvasElement.style.width = '100%';
                                htmlCanvasElement.style.height = '100%';
                                htmlCanvasElement.className = 'canvasBarrage';
                                htmlCanvasElement.id = 'canvasBarrage';
                                let video = document.getElementById('local_stream').getElementsByTagName('div')[0];
                                setTimeout(() => {
                                    video?.appendChild(htmlCanvasElement);
                                    let demoBarrage = new LiveCanvasBarrage(htmlCanvasElement, eleVideo, {
                                        data: this.state.barrageList
                                    });
                                    this.setState({
                                        canvasBarrage: demoBarrage,
                                        eleVideo: eleVideo
                                    }, () => {
                                        console.log("这是保存的弹幕对象：：：111", this.state.canvasBarrage);
                                        console.log("这是保存的弹幕对象：：：111", demoBarrage);
                                        demoBarrage.render();
                                        demoBarrage.add({
                                            value: '测试一下弹幕对象是否初始化',
                                            time: this.state.eleVideo.currentTime
                                            // 其它如color, fontSize, opacity等可选
                                        });
                                        if (this.state.startBarrage === 1) {
                                            this.state.canvasBarrage.reset();
                                            this.state.canvasBarrage.isPause = false;
                                            this.state.canvasBarrage.render();
                                            message.destroy();
                                            message.info("弹幕已开启")
                                        }
                                    })
                                }, 100)
                            }
                        }
                    }, 100)
                } else {
                    this.setState({
                        startBarrage: 0
                    })
                }
            };

            goBack = () => {
                // this.props.history.goBack(-1);
                // window.open(liveContentUrl + `?${encodeURI(JSON.stringify({taskId:this.state.taskId,isReport:0}))}`)
                // this.props.history.push(`/livecontent?${encodeURI(JSON.stringify({taskId:this.state.taskId,isReport:0}))}`);
                this.props.history.goBack();
            };

            //切换聊天tab页
            callback = async (key) => {
                // if (key === '3') {
                //     this.groupMemberInfo();
                // }
                // if (key === '1') {
                //     this.messageLog(1)
                // }
                // if (key === '2') {
                //     this.messageLog(2)
                // }
                setTimeout(()=>{
                    this.setState({activeKey: key},async() => {
                        if(this.state.remoteScreenStream){
                            await this.reCalcRightHeiFun()
                        }else{
                            await this.initRightHeiFun()
                        }
                        await this.scrollBottom()
                    })
                },100)

                document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
                document.getElementById('contenta') !== null && (document.getElementById('contenta').scrollTop = document.getElementById('enda').offsetTop);

            };
            //切换小屏幕，重新计算右侧讨论区高度
            reCalcRightHeiFun(){
                let previewBoxDom = document.querySelector('.preview-box');
                let previewBoxDomHeight = previewBoxDom.clientHeight;

                let ChatLeftTopDom = document.getElementById('ChatTop');
                let eleExplainDom = document.getElementById('eleExplain');
                let ChatRightDom = document.getElementById('member');
                let ChatLeftBottomDom = document.querySelector('.Chat-left-bottom');
                if(ChatLeftTopDom){
                    ChatLeftTopDom.style.height = (previewBoxDomHeight - 50 - 40- 140 - 245 -15) + 'px';
                    ChatLeftBottomDom.style.height = "140px";
                }
                if(eleExplainDom){
                    eleExplainDom.style.height = (previewBoxDomHeight - 50 - 40 - 245 -15) + 'px';
                }
                if(ChatRightDom){
                    ChatRightDom.style.height = (previewBoxDomHeight - 50 - 62 - 64 - 245 - 15) + 'px';
                }
            }
            //消息变化时回调
            messageChange = (e) => {
                this.setState({message: e.target.value});
                if (e.target.value.length > 0) {
                    document.getElementById('send-btn').classList.add("addNewClass");
                } else {
                    document.getElementById('send-btn').classList.remove ("addNewClass");
                }
            };


            getDiscussMessage = () => {
                getChatMsg({taskId: this.state.taskId,chatType:1,pageNum:this.state.discussPageNum,objectStr:this.state.objectStr}).then(res => {
                    // console.log("聊天记录：：：", res);
                    if (res){
                        this.setState({
                            discussTotalMsg: res.pages
                        })
                        //这里res.data是数组
                        res.data.map((item, index) => {
                            console.log("聊天记录item：：：", item);
                            let fromUser = {
                                id: item.userId,
                                humanName: item.userName,
                                portraitId: item.portraitId,
                                userMark: item.userMark,
                                portraitIdName: item.interceptName
                            };
                            let message = {
                                action: item.chatType === 1 ? "TEXT_DISCUSS" : "TEXT_EXPLAIN",
                                body: {
                                    text: item.chatMessage,
                                    data: {
                                        id:item.id,
                                        sendTime:item.sendTime
                                    },
                                    fromUser
                                }
                            };
                            this.state.messageList.unshift(message);
                            console.log("聊天记录messageList：：：", this.state.messageList);
                        });
                        setTimeout(() => {
                            this.setState({
                                chatMsg: res.data,
                            },()=>{
                                console.log("调用了底部滚动")
                                // this.scrollBottom()
                            })
                        },500);

                    } else {
                        console.log("获取讨论消息数据未返回，可能是接口超时导致")
                    }
                    document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
                    document.getElementById('contenta') !== null && (document.getElementById('contenta').scrollTop = document.getElementById('enda').offsetTop);

                }).catch(error => {
                    console.log("获取讨论消息出错")
                });
            };

            getExplainMessage = () => {
                getChatMsg({taskId: this.state.taskId, chatType: 2, pageNum: this.state.explainPageNum}).then(res => {
                    console.log("聊天记录：：：", res);
                    if (res) {
                        this.setState({
                            explainTotalMsg: res.pages
                        })
                        //这里res.data是数组
                        res.data.map((item, index) => {
                            console.log("聊天记录item：：：", item);
                            let fromUser = {
                                id: item.userId,
                                humanName: item.userName,
                                portraitId: item.portraitId,
                                userMark: item.userMark,
                                portraitIdName: item.interceptName
                            };
                            let message = {
                                action: item.chatType === 1 ? "TEXT_DISCUSS" : "TEXT_EXPLAIN",
                                body: {
                                    text: item.chatMessage,
                                    data: {
                                        sendTime: item.sendTime
                                    },
                                    fromUser
                                }
                            };
                            this.state.messageList.unshift(message)
                            console.log("聊天记录messageList：：：", this.state.messageList);
                        });
                        this.setState({
                            chatMsg: res.data
                        })
                    } else {
                        console.log("获取讲解消息数据未返回，可能是接口超时导致")
                    }
                    document.getElementById('content') !== null && (document.getElementById('content').scrollTop = document.getElementById('end').offsetTop);
                    document.getElementById('contenta') !== null && (document.getElementById('contenta').scrollTop = document.getElementById('enda').offsetTop);
                }).catch(error => {
                    console.log("获取讲解消息出错")
                });
            };

            messageLog = (type) => {
                //讨论区
                if (type == 1){
                    //获取讨论消息
                    this.getDiscussMessage();
                }else if(type == 2){
                    //获取讲解消息
                    this.getExplainMessage();
                }else{
                    this.getDiscussMessage();
                    this.getExplainMessage();
                }
            };

            refreshScreen = () => {
                let filter = this.state.messageList.filter(item => {
                    return item.action === "TEXT_EXPLAIN"
                });
                this.setState({
                    messageList: filter
                })
            }



            onSearch = (e) => {
                //群组成员列表
                getGroupInfo({taskId: this.state.taskId, humanName: e}).then(res => {
                    console.log(res);
                    if (res) {
                        this.setState({
                            memberList: res
                        })
                    } else {
                        message.destroy();
                        message.warning("获取成员数据出错")
                    }
                });
            }

            searchChange = (e) => {
                console.log("aaa", e);
                this.setState({
                    studentName: e.target.value
                }, () => {
                    getGroupInfo({taskId: this.state.taskId, humanName: this.state.studentName}).then(res => {
                        console.log(res);
                        if (res) {
                            this.setState({
                                memberList: res
                            })
                        } else {
                            message.destroy();
                            message.warning("获取成员数据出错")
                        }
                    });
                })
            }

            iconSearch = (e) =>{
                this.onSearch(e.currentTarget.parentNode.previousElementSibling.value);
            }

            messageSearch = (e) =>{
                this.onMessageSearch(e.currentTarget.parentNode.previousElementSibling.value);
            }



            onSliderChange = (e) => {
                //控制音量条
                if (this.state.remoteStream === null) {
                    return;
                }
                this.setState({
                    audioLevel: e
                });
                let voice = e / 100;
                this.state.remoteStream.setAudioVolume(voice)
            };

            onAfterSliderChange = (e) => {
                //控制音量条
                if (this.state.remoteStream === null) {
                    return;
                }
                this.setState({
                    audioLevel: e
                });
                let voice = e / 100;
                this.state.remoteStream.setAudioVolume(voice)
            };

            // 解析时间
            resolutionTime = (timestamp) => {
                timestamp = Date.parse(timestamp) / 1000;
                let curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
                let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

                let curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
                let tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

                let Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
                let H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();

                if (timestampDiff < 60) { // 一分钟以内
                    console.log("刚刚");
                    return "刚刚";
                } else if (timestampDiff < 300) { // 一小时前之内
                    console.log(Math.floor(timestampDiff / 60) + "分钟前");
                    return Math.floor(timestampDiff / 60) + "分钟前";
                } else {
                    let newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
                    // console.log(Y + '年' + this.zeroize(m) + '月' + this.zeroize(d) + '日 ' + this.zeroize(H) + ':' + this.zeroize(i));
                    return Y + '年' + this.zeroize(m) + '月' + this.zeroize(d) + '日 ' + this.zeroize(H) + ':' + this.zeroize(i);
                }
            };

            zeroize = (num) => {
                return (String(num).length === 1 ? '0' : '') + num;
            };



            //处理表情字符串
            getPureName(name) {
                return name.replace(/:/g, '')
            }

            //选择表情回调, 表情消息不显示在弹幕
            selectEmoji(code) {
                console.log("这里选择了一个表情");
                // 这里code是一个key传过来
                if (this.state.message.length<100){
                    this.setState({
                        emojiContainer: false,
                        message: this.state.message + code
                    })
                }

            }

            //切换屏幕
            changeVideo = async () => {
                let event = new Event('changeVideo');
                document.dispatchEvent(event);
                this.state.canvasBarrage = null;
                //app不切换屏幕
                if (this.state.initiator === 'APP') {
                    return;
                }
                if (this.state.remoteStream === null && this.state.remoteScreenStream === null) {
                    return;
                }
                let myCamera = document.getElementById('myCamera');
                if (myCamera.innerHTML !== "") {
                    var myCameraId = this.getDiv('myCamera');
                } else {
                    //myCamera的子div为空，说明只有一个流且显示在screen下，此时不可以切换位置
                    // message.info("只有一个视频切换什么")
                    // let screen = document.getElementById('local_stream');
                    // if (screen.innerHTML !== "") {
                    //     var myCameraId = this.getDiv('local_stream');
                    //     await this.state.remoteStream.stop();
                    //     this.state.remoteStream.play('myCamera');
                    // }
                    return;
                }

                if (this.state.remoteStream !== null) {
                    await this.state.remoteStream.stop();
                }
                if (this.state.remoteScreenStream !== null) {
                    await this.state.remoteScreenStream.stop();
                }
                if (this.state.remoteStream.id_ === myCameraId.substring(7)) {
                    //这个判断表明：摄像头还是显示在右上角，所以：
                    if (this.state.remoteStream !== null) {
                        let screen = this.getDiv('local_stream');
                        if (screen) {
                            screen.innerHTML = '';
                        }
                        this.state.remoteStream.play('local_stream');
                        setTimeout(() => {
                            // const eleCanvas = document.getElementById('canvasBarrage');
                            const eleVideo = document.getElementById(`video_${this.state.remoteStream.getId()}`);
                            // let eleVideo = document.getElementById('local_stream').getElementsByTagName('video')[0];
                            console.log(`这是组装出的video的id：：：video_${this.state.remoteStream.getId()}`);
                            setTimeout(() => {
                                console.log("这个是video标签2：：：：", eleVideo);
                                if (eleVideo) {
                                    eleVideo.removeAttribute("controls");
                                    let htmlCanvasElement = document.createElement('canvas');
                                    console.log("手动创建的canvas节点", htmlCanvasElement);
                                    if (htmlCanvasElement) {
                                        htmlCanvasElement.style.width = '100%';
                                        htmlCanvasElement.style.height = '100%';
                                        htmlCanvasElement.className = 'canvasBarrage';
                                        htmlCanvasElement.id = 'canvasBarrage';
                                        let video = document.getElementById('local_stream').getElementsByTagName('div')[0];
                                        console.log("插入的div“：：：：", video);
                                        setTimeout(() => {
                                            video.appendChild(htmlCanvasElement);
                                            let demoBarrage = new LiveCanvasBarrage(htmlCanvasElement, eleVideo, {
                                                data: this.state.barrageList
                                            });
                                            this.setState({
                                                canvasBarrage: demoBarrage,
                                                eleVideo: eleVideo
                                            }, () => {
                                                console.log("这是保存的弹幕对象：：：111", this.state.canvasBarrage);
                                                console.log("这是保存的弹幕对象：：：111", demoBarrage);
                                                demoBarrage.render();
                                                demoBarrage.add({
                                                    value: '测试一下弹幕对象是否初始化',
                                                    time: this.state.eleVideo.currentTime
                                                    // 其它如color, fontSize, opacity等可选
                                                });
                                                if (this.state.startBarrage === 1) {
                                                    this.state.canvasBarrage.reset();
                                                    this.state.canvasBarrage.isPause = false;
                                                    this.state.canvasBarrage.render();
                                                    message.destroy();
                                                    message.info("弹幕已开启")
                                                }
                                            })
                                        }, 100)
                                    }
                                }
                            }, 100)
                        }, 100)
                    }
                    if (this.state.remoteScreenStream !== null) {
                        let myCamera = this.getDiv('myCamera');
                        if (myCamera) {
                            myCamera.innerHTML = '';
                        }
                        this.state.remoteScreenStream.play('myCamera');
                    }
                    //把他play到大屏区域，然后屏幕分享去到小屏区域
                } else {
                    //否则回归原位
                    if (this.state.remoteScreenStream !== null) {
                        this.state.remoteScreenStream.play('local_stream');
                        setTimeout(() => {
                            // const eleCanvas = document.getElementById('canvasBarrage');
                            const eleVideo = document.getElementById(`video_${this.state.remoteScreenStream.getId()}`);
                            // let eleVideo = document.getElementById('local_stream').getElementsByTagName('video')[0];
                            console.log(`这是组装出的video的id：：：video_${this.state.remoteScreenStream.getId()}`);
                            setTimeout(() => {
                                console.log("这个是video标签1：：：：", eleVideo);
                                if (eleVideo) {
                                    eleVideo.removeAttribute("controls");
                                    let htmlCanvasElement = document.createElement('canvas');
                                    console.log("手动创建的canvas节点", htmlCanvasElement);
                                    if (htmlCanvasElement) {
                                        htmlCanvasElement.style.width = '100%';
                                        htmlCanvasElement.style.height = '100%';
                                        htmlCanvasElement.className = 'canvasBarrage';
                                        htmlCanvasElement.id = 'canvasBarrage';
                                        let video = document.getElementById('local_stream').getElementsByTagName('div')[0];
                                        console.log("插入的div“：：：：", video);
                                        setTimeout(() => {
                                            video.appendChild(htmlCanvasElement);
                                            let demoBarrage = new LiveCanvasBarrage(htmlCanvasElement, eleVideo, {
                                                data: this.state.barrageList
                                            });
                                            this.setState({
                                                canvasBarrage: demoBarrage,
                                                eleVideo: eleVideo
                                            }, () => {
                                                console.log("这是保存的弹幕对象：：：111", this.state.canvasBarrage);
                                                console.log("这是保存的弹幕对象：：：111", demoBarrage);
                                                demoBarrage.render();
                                                demoBarrage.add({
                                                    value: '测试一下弹幕对象是否初始化',
                                                    time: this.state.eleVideo.currentTime
                                                    // 其它如color, fontSize, opacity等可选
                                                });
                                                if (this.state.startBarrage === 1) {
                                                    this.state.canvasBarrage.reset();
                                                    this.state.canvasBarrage.isPause = false;
                                                    this.state.canvasBarrage.render();
                                                    message.destroy();
                                                    message.info("弹幕已开启")
                                                }
                                            })
                                        }, 100)
                                    }
                                }
                            }, 100)
                        }, 100)
                    }
                    if (this.state.remoteStream !== null) {
                        this.state.remoteStream.play('myCamera');
                    }
                }
            };

            getDiv = (id) => {
                var parentDiv = document.getElementById(id);
                if (parentDiv) {
                    var childs = parentDiv.getElementsByTagName('div');
                } else {
                    message.destroy();
                    message.warning("获取视频流流异常");
                    window.location.reload();
                }
                return childs[0] ? childs[0].id : '';
            };



            //表情模块渲染
            getContainer = () => {
                let map = this.state.pannels.map(item => {
                    return Object.keys(this.state.emojiData[item])
                });
                if (this.state.emojiContainer === true) {
                    return (
                        <div className="emoji">
                            <ul className="emoji-container">
                                {
                                    map.map((item, index) => {
                                        if (index === this.state.activeIndex) {
                                            return (
                                                <li
                                                    style={{'padding': '0'}}
                                                    key={index}
                                                >
                                                    {
                                                        item.map((emoji, index) => {
                                                            let pureName = this.getPureName(emoji);
                                                            let title = pureName;
                                                            pureName = `sprite-${pureName}`;
                                                            return (
                                                                <a href="javascript:" key={index}
                                                                   style={{'font-color': 'red'}}
                                                                   onClick={() => this.selectEmoji(emoji)}>
                                                                    <span className="emoji-item" title={title}
                                                                          className={pureName}/>
                                                                </a>
                                                            )
                                                        })
                                                    }
                                                </li>
                                            )
                                        }
                                    })
                                }

                            </ul>
                        </div>
                    )
                }
            };
            getPannel = () => {

            }

            // faceMessage = () => {
            //     this.state.tim.createFaceMessage({
            //         to: 'user1',
            //         // conversationType: TIM.TYPES.CONV_C2C,
            //         // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
            //         // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
            //         // priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
            //         payload: {
            //             index: 1, // Number 表情索引，用户自定义
            //             data: 'tt00' // String 额外数据
            //         }
            //     });
            // }

            danmu = () => {
                console.log("进入123123123123");
                //开启弹幕只能调用一次，然后必须reset后重新render
                //这里如果切换屏幕时没有把canvas对象置空，判断就不生效了
                console.log(this.state.canvasBarrage);
                if (this.state.canvasBarrage) {
                    this.setState({
                        startBarrage: 1
                    }, () => {
                        this.state.canvasBarrage.reset();
                        this.state.canvasBarrage.isPause = false;
                        this.state.canvasBarrage.render();
                        message.destroy();
                        message.info("弹幕已开启")
                    })

                    // let event = new Event('play');
                    // document.dispatchEvent(event);
                } else {
                    message.destroy();
                    message.info("当前无视频信号，不可以开启弹幕")
                }
            };

            closeBarrage1 = () => {
                if (this.state.canvasBarrage) {
                    this.setState({
                        startBarrage: 0
                    }, () => {
                        this.state.canvasBarrage.reset();
                        this.state.canvasBarrage.isPause = true;
                        // this.state.canvasBarrage.render();
                        // message.destroy();
                        // message.info("弹幕已关闭")
                    })

                    // let event = new Event('play');
                    // document.dispatchEvent(event);
                }
            }

            closeBarrage2 = () => {
                if (this.state.canvasBarrage) {
                    this.setState({
                        startBarrage: 0
                    }, () => {
                        this.state.canvasBarrage.reset();
                        this.state.canvasBarrage.isPause = true;
                        // this.state.canvasBarrage.render();
                        message.destroy();
                        message.info("弹幕已关闭")
                    })

                    // let event = new Event('play');
                    // document.dispatchEvent(event);
                }
            }

            //暂停直播
            pauseLive = () => {
                let param = {
                    taskId:this.state.taskId,
                    createUserId:this.state.userId
                };
                pauseLive(param).then(res => {
                    if (res){
                        this.setState({
                            pauseTime:600,
                            pauseFlag:1
                        },() => {
                            this.livePauseTimer()
                        })
                    }
                })
            };

            livePauseTimer = () => {
                this.suspendTimer = setInterval(() => {
                    let pauseTime = this.state.pauseTime - 1;
                    this.setState({pauseTime:pauseTime},()=>{
                        if(this.state.pauseTime === 0){
                            clearInterval(this.suspendTimer);
                            message.destroy();
                            message.info("直播任务已恢复");
                            this.resumeLive()
                        }
                    });
                }, 1000);
            }

            resumeLive = () => {
                let param = {
                    taskId:this.state.taskId,
                    createUserId:this.state.userId
                };
                continueLive(param).then(res => {
                    if (res){
                        this.setState({
                            isPause:0,
                            pauseTime:0
                        })
                    }
                })
            };

            //强制结束
            forceCloseLive = () => {
                let param = {
                    taskId:this.state.taskId,
                    userId:this.state.userId,
                    lastProcessType:3,
                    isReport:0,
                };
                frozenAndForceEndLive(param).then(res => {
                    if (res){
                        console.log(res);
                        this.props.history.goBack(-1);
                    }
                })
            };

            //冻结直播
            freezeLive = () => {
                let param = {
                    taskId:this.state.taskId,
                    userId:this.state.userId,
                    lastProcessType:1,
                    isReport:0,
                };
                frozenAndForceEndLive(param).then(res => {
                    if (res){
                        console.log(res)
                        this.props.history.push("/reportlive");
                        //如果冻结成功，返回列表
                    }
                })
            };

            //冻结成员
            freezeStudent = () => {
                if(this.state.members.length <= 0){
                    this.mesWarning("请选择要冻结的成员")
                    return
                }
                Modal.confirm({
                    className:'冻结成员',
                    content:"確定要冻结该成员吗？",
                    okText:"确定",
                    cancelText:"取消",
                    onOk: () => {
                        let param = {
                            taskId:this.state.taskId,
                            roomId:this.state.roomId,
                            students:this.state.members,
                            kickUserType:3,
                            kickUserId:this.state.userId
                        };
                        removeUser(param).then(res => {
                            if (res){
                                console.log(res);
                                this.groupMemberInfo(false,false)
                            }
                        })
                    },
                    onCancel: () => {
                    }
                });

            };

            //踢出成员
            removeStudent = () => {
                if(this.state.members.length <= 0){
                    this.mesWarning("请选择要踢出的成员")
                    return
                }
                Modal.confirm({
                    className:'踢出成员',
                    content:"確定要踢出这些成员吗？",
                    okText:"确定",
                    cancelText:"取消",
                    onOk: () => {
                        let param = {
                            taskId:this.state.taskId,
                            roomId:this.state.roomId,
                            students:this.state.members,
                            kickUserType:2,
                            kickUserId:this.state.userId
                        };
                        removeUser(param).then(res => {
                            if (res){
                                console.log(res);
                                this.groupMemberInfo(true,false)
                            }
                        })
                    },
                    onCancel: () => {
                    }
                });
            };

            //踢出当前成员
            removeThisStudent = (studentId) => {
                Modal.confirm({
                    className:'踢出成员',
                    content:"確定要踢出该成员吗？",
                    okText:"确定",
                    cancelText:"取消",
                    onOk: () => {
                        let list = Array.of(studentId);
                        this.setState({
                            member:list
                        },() => {
                            let param = {
                                taskId:this.state.taskId,
                                roomId:this.state.roomId,
                                students:list,
                                kickUserType:2,
                                kickUserId:this.state.userId
                            };
                            removeUser(param).then(res => {
                                if (res){
                                    console.log(res);
                                    this.groupMemberInfo(true,false)
                                }
                            })
                        })
                    },
                    onCancel: () => {
                    }
                });
            };

            banCancel = () => {
                clearInterval(this.suspendTimer);
                this.setState({ banFlag:false,isPause:0,pauseTime:0});
            };

            pauseCancel = () => {
                clearInterval(this.suspendTimer);
                this.setState({ banFlag:false,pauseTime:0});
            };

            Banned = (e) =>{
                console.log("暂停直播：：：",e);
                if (e == 3){
                    console.log("暂停直播：：：123123",e);
                    this.setState({ banFlag:true,reportType:e,taskId:this.state.taskId,isPause:1});
                }else{
                    this.setState({ banFlag:true,reportType:e,taskId:this.state.taskId});
                }
            };

            returnMessage = () =>{
                const {reportType} = this.state;
                if(reportType===3){
                    return "暂停开播"
                }else if(reportType===2){
                    return "禁止开播"
                }else if(reportType===1){
                    return "冻结直播"
                }else if(reportType===4){
                    return "强制结束"
                }
            };

            memberChange = (value) => {
                console.log("本次选中的value",value)
                this.setState({
                    members:value
                });
            };

            overflowEllipsis(str, num) {
                if (str.length <= num) {
                    return str;
                }
                return str.substr(0, num) + '...';
            }


            messageSearchChange = (e) => {

            };

            onMessageSearch = (e) => {
                console.log("搜索回调的值：：：",e);
                this.setState({
                    objectStr:e,
                    messageSearchFlag:1,
                    messageList:[],
                    discussPageNum:1
                },() => {
                    this.messageLog();
                })
            };
            expression = () => {
                console.log("emojiContainer", this.state.emojiContainer)
                this.setState({
                    emojiContainer:!this.state.emojiContainer
                })
            }
            render() {
                const { switchScreen,liveDetail,isConnect, liveStatusFlag, activeKey, messageList, remoteScreenStream,studentsPortrait, audioDisabled, groupInfo,
                    groupTotal,nextBtnDisable,preBtnDisable,groupPageNum,groupTotalPages} = this.state;
                let screenHeight = document.documentElement.clientHeight - 80;
                console.log("++++",screenHeight)
                let campusResource = <div className="Chat-left">
                    {/*828*/}
                    {/* ?isConnect === 1 ?{maxHeight:"390px"}:{maxHeight: '384px'} :*/}
                    <div className="Chat-left-top" id="ChatTop">
                        {/*<Search*/}
                        {/*    className="inputa"*/}
                        {/*    placeholder="搜索聊天记录"*/}
                        {/*    maxLength={20}*/}
                        {/*    onSearch={(e) => this.onMessageSearch(e)}*/}
                        {/*    onChange={(e) => this.messageSearchChange(e)}*/}
                        {/*    suffix={<img src={require('@/assets/img/images/scearch-icon.png')} onClick={(e)=>this.messageSearch(e)}/>}*/}
                        {/*/>*/}
                        {/*<div className="jgts">*/}
                        {/*    <span>安全提示</span>*/}
                        {/*    <p>欢迎来到直播间！直播间秉承着和谐有爱的精神，请勿发表不当言论，严禁涉政，涉恐，涉黄，暴力，谩骂，违法乱纪行为等内容。</p>*/}
                        {/*</div>*/}
                        {/*<span className="time">{this.state.nowTime}</span>*/}
                        {/*724*/}
                        <Row
                            id="content">
                            {/*<span className="time">20:18</span>*/}
                            <Row>
                                {messageList.map(item => {
                                    if (item.action === 'TEXT_DISCUSS') {
                                        if (item.body.fromUser.id === this.state.userId) {
                                            return (
                                                <Col span={24} className="right-pic">
                                                    <div className="text-right">
                                                        <span className="pic-img">

                                                            <span
                                                                className="time">{this.resolutionTime(item.body.data.sendTime)}</span>{this.overflowEllipsis(item.body.fromUser.humanName, 4)}
                                                        </span>
                                                        <div className="text" id="test">
                                                            {/*<img src="/public/emoji/earth_asia.png" />*/}
                                                            <p dangerouslySetInnerHTML={{__html: emoji(item.body.text)}}/>
                                                        </div>
                                                    </div>
                                                    <span className="span-gr"><div
                                                        className={item.isHand === 1 ? "opacity-bg-red" : "span-name"}>
                                                        <span>{item.body.fromUser.portraitIdName}</span>
                                                        </div></span>

                                                </Col>
                                            )
                                        } else {
                                            return (
                                                <Col span={24} className="left-pic">
                                                    <span className="span-gr"><div
                                                        className={item.isHand === 1 ? "opacity-bg-red" : "span-name"}>
                                                            <span>{item.body.fromUser.portraitIdName}</span>
                                                        </div></span>
                                                    <div className="text-right">
                                                        <span className="pic-img">
                                                            {this.overflowEllipsis(item.body.fromUser.humanName, 4)}<span
                                                            className="time">{this.resolutionTime(item.body.data.sendTime)}</span>
                                                        </span>
                                                        <div className="right-dic">
                                                            <span className="text">
                                                                <p dangerouslySetInnerHTML={{__html: emoji(item.body.text)}}/>
                                                            </span>
                                                            {/*<span className="dete-icon" onClick={() => this.deleteMessage(item)}></span>*/}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    } else if (item.action === 'JOIN_ROOM' || item.action === 'QUIT_ROOM' || item.action === 'PRESENT') {
                                        return (
                                            <Col span={24} className="left-pic">
                                                <div className="text-right">
                                                    <span className="pic-img">
                                                        系统提示：
                                                    </span>
                                                    <span className="text">
                                                        <p>{item.body.text}</p>
                                                    </span>
                                                </div>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                            <Col span={24} id="end"></Col>
                        </Row>
                    </div>
                    <div className="Chat-left-bottom">
                        <div className="left-div">
                            {
                                this.state.isBanSpeech === 0 ?
                                    <span className="span-bq" onClick={() => this.expression()}>
                                        <img src={require('@/assets/img/images/icon-21.png')}/>
                                    </span>:
                                    <span className="span-bq on" >
                                        <img src={require('@/assets/img/images/icon-21.png')}/>
                                    </span>

                            }

                            <span className="span-qk" onClick={this.refreshScreen}>
                                <img src={require('@/assets/img/images/icon-22.png')}/>
                            </span>
                            {this.getContainer()}
                        </div>
                        <div className="input-box">
                            <Input.TextArea placeholder="输入讨论" rows={4}
                                      height='100%'
                                      onChange={(e) => this.messageChange(e)}
                                      onFocus={this.doFocus} onBlur={this.doBlur}
                                      value={this.state.message}
                                      maxLength={100}
                                      onPressEnter={() => {
                                          this.createMessage('TEXT_DISCUSS')
                                      }}
                            >
                            </Input.TextArea>
                            <div className="box-button">
                                <Button className="send livepageroomsend" id="send-btn"
                                        onClick={() => {
                                            if (this.state.messageRedo === 1) {
                                                this.setState({
                                                    messageRedo: 0
                                                }, () => {
                                                    this.createMessage('TEXT_DISCUSS');
                                                    setTimeout(() => {
                                                        this.setState({
                                                            messageRedo: 1
                                                        })
                                                    }, 5000)
                                                })
                                            } else {
                                                message.destroy();
                                                message.info("两次消息间隔5秒")
                                            }
                                        }}>
                                    发送
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                let platformResource = <div className="Chat-left">
                    <div className="Chat-left-top" id="eleExplain">
                        <Row id="contenta">
                            <Row>
                                {messageList && messageList.length > 0 ? messageList.map(item => {
                                    if (item.action === 'TEXT_EXPLAIN') {
                                        return (
                                            <Col span={24} className="left-pic">
                                                <span className="span-gr"><div
                                                    className={item.isHand === 1 ? "opacity-bg-red" : "span-name"}>
                                                            <span>{item.body.fromUser.portraitIdName}</span>
                                                        </div></span>

                                                <div className="text-right">
                                                    <span className="pic-img">
                                                        {this.overflowEllipsis(item.body.fromUser.humanName, 4)}<span
                                                        className="time">{this.resolutionTime(item.body.data.sendTime)}</span>
                                                    </span>
                                                    <span className="text">
                                                        <p dangerouslySetInnerHTML={{__html: emoji(item.body.text)}}/>
                                                    </span>
                                                </div>
                                            </Col>
                                        )
                                    }
                                }): <div className="Empty-conet">
                                    <div className="img">
                                        <img src={window.$$setImgSrc("zhibo_list_Empty.jpg")}/>
                                        <span>暂无数据</span>
                                    </div>
                                </div>}
                            </Row>
                            <Col span={24} id="enda"></Col>
                        </Row>
                    </div>
                    <div className="Chat-left-bottom" style={{display: "none"}}>
                        <div className="left-div">
                            <span className="span-bq" onClick={() => {
                                this.setState({
                                    emojiContainer: true
                                })
                            }}><img src={require('@/assets/img/images/icon-21.png')}/></span>
                            <span className="span-qk" onClick={this.gift}>
                                <img src={require('@/assets/img/images/icon-22.png')}/>
                            </span>
                            {this.getContainer()}
                            {this.getPannel()}
                        </div>
                        {this.state.isBanSpeech === 0 ? <div className="input-box">
                            <Input.TextArea placeholder="输入讨论" rows={4}
                                      height='100%'
                                      onChange={(e) => this.messageChange(e)}
                                      onFocus={this.doFocus} onBlur={this.doBlur}
                                      value={this.state.message}
                                      maxLength={100}

                                      onPressEnter={() => {
                                          this.createMessage('TEXT_DISCUSS')
                                      }}
                            >
                            </Input.TextArea>
                            <div className="box-button">
                                <Button className="send" id="send-btn"
                                        onClick={() => {
                                            this.createMessage('TEXT_DISCUSS')
                                        }}>
                                    发送
                                </Button>
                            </div>
                        </div> : <span>你被禁言了！</span>}
                    </div>
                </div>
                // 1010
                let member = <div className="Chat-right">
                    <Search
                        className="inputa"
                        placeholder="输入学生姓名"
                        maxLength={20}
                        onSearch={(e) => this.onSearch(e)}
                        onChange={(e) => this.searchChange(e)}
                        suffix={<img src={require('@/assets/img/images/scearch-icon.png')} onClick={(e)=>this.iconSearch(e)}/>}
                    />
                    {/*948*/}
                    <div className="Chat-right-con" id="member">

                        <Checkbox.Group style={{ width: '100%' }} onChange={(value) => this.memberChange(value)}>
                            {
                                this.state.memberList.map(item => {
                                    return (
                                        <li>
                                            <Checkbox value={item.userId}/>
                                            <div className="left-antd">
                                             <span className="pic-img">
                                                 <span className="text">{item.portraitIdName}</span>

                                             </span>
                                                {item.humanName ? this.overflowEllipsis(item.humanName, 10) : "路人"}
                                            </div>
                                            <div className="antd-an">
                                                <span className="span-2" onClick={(studentId) => this.removeThisStudent(item.userId)}><i></i>踢出</span>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </Checkbox.Group>
                    </div>
                    <div className="Chat-right-bottom">
                        <ul>
                            <li onClick={() => this.removeStudent()}><img src={require("@/assets/img/images/live-1.png")}/>踢出成员</li>
                            <li onClick={() => this.freezeStudent()}><img src={require("@/assets/img/images/live-2.png")}/>冻结成员</li>
                        </ul>
                    </div>
                </div>
                return (
                    <div className="resource_center_wrap studntliveroom_listing">
                        <div className="header bg_fff">
                            <div className="back" onClick={()=>this.goBack()}>
                                <img alt="" src={require("@/assets/img/grounding/back.png")} className="back-icon"/>
                                返回
                            </div>
                            <div className='title'>
                                <h2>{this.state.taskName}</h2>
                            </div>
                        </div>
                        {/* 内容start */}
                        <div className="content_wrap">
                            <div className="right">
                                <div className="live-cont">
                                    <div className="wrap-center">
                                        <div className="preview-box clearfix">
                                            <div className="teacher-online-top">
                                                <dl className="online-top">
                                                    <dt className="dt-first">
                                                        <img src={window.$$uploadRc('gr.png')} className="img-teacher"/>
                                                        <span className="span-text">主讲老师</span>
                                                    </dt>
                                                    <dt>
                                                        {groupInfo && groupInfo.teacherName ? this.overflowEllipsis(groupInfo.teacherName, 4) : ''}
                                                    </dt>
                                                    <dt>
                                                        在线人数：{this.state.memberList ? this.state.memberList.length : 0}
                                                    </dt>
                                                    {
                                                        this.state.isConnect===1?<dd className="dd-red"><p className="p-0">连线中...</p></dd>:
                                                        this.state.isHandNum===1?<dd className="dd-red"><p className="p-0">当前有{this.state.isHandNum}人正在举手</p></dd>:""
                                                    }
                                                </dl>
                                                {
                                                    studentsPortrait.length > 0 ?
                                                <div className="students-portrait">
                                                    <div className="swiper-container" style={{width: groupTotal > 40 ? "calc(100% - 60px)" : "100%"}}>
                                                        <div className="swiper-wrapper">
                                                            {
                                                                studentsPortrait?.map((item, index) => {
                                                                    console.log("成员详情", item);
                                                                    return (
                                                                        <div className="swiper-slide" key={index}>
                                                                            <div
                                                                                className={item.isHand === 1 ? "opacity-bg-red" : "span-name"}>
                                                                                <span>{item.portraitIdName}</span>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        groupTotal > 40 ?
                                                            <div className={`swiper-button-next ${nextBtnDisable || groupPageNum === groupTotalPages?"swiper-button-disabled":""}`} onClick={this.nextFun}></div> : ""
                                                    }
                                                    {
                                                        groupTotal > 40 ?
                                                            <div className={`swiper-button-prev ${preBtnDisable || groupPageNum===1?"swiper-button-disabled":""}`} onClick={this.prevFun}></div> : ""
                                                    }
                                                </div>:""}
                                                {/*连线开始*/}
                                                {this.state.isConnect === 1 ? (this.state.audience === 0? <div className="connecting-line-box">
                                                        <div className="connecting-line">
                                                            <dl>
                                                                <dt>
                                                                    <img src={window.$$uploadRc('gr.png')} className="img-teacher"/>
                                                                    <h4>{this.overflowEllipsis(this.state.teacherName, 4)}</h4>
                                                                    <p>{this.state.lianMaiTime}</p>
                                                                    <div className="flex-button">
                                                                        <Button className="refuse" onClick={() => {
                                                                            clearInterval(this.lianMaiTimer);
                                                                            if (this.state.connecting === 0) {
                                                                                this.setState({
                                                                                    message: "拒绝连麦",
                                                                                    isConnect: 0,
                                                                                }, () => {
                                                                                    this.createMessage("CONNECT_REFUSE")
                                                                                })
                                                                            } else {
                                                                                this.setState({
                                                                                    message: "连麦结束"
                                                                                }, () => {
                                                                                    this.createMessage("CONNECT_END");
                                                                                    connectEnd({
                                                                                        studentId: this.state.userId,
                                                                                        taskId: this.state.taskId
                                                                                    }).then(res => {
                                                                                        console.log("连麦结束", res)
                                                                                        this.setState({
                                                                                            isConnect: 0,
                                                                                            connecting: 0
                                                                                        })
                                                                                    });
                                                                                    this.state.client.unpublish(this.state.localStream).then(() => {
                                                                                        // 取消发布本地流成功
                                                                                        console.log("清楚了计时器：：：")
                                                                                        clearInterval(this.lianMaiTimer);
                                                                                        this.setState({
                                                                                            lianMaiTime: '00:00:00'
                                                                                        });
                                                                                    });
                                                                                    this.state.localStream.close()
                                                                                })
                                                                            }
                                                                        }}/>
                                                                    </div>
                                                                </dt>
                                                                {
                                                                    this.state.connecting?<dd><div id="connect"></div></dd>:
                                                                        <dd>
                                                                            <div className="call-icon">
                                                                                <div className="call-icon-1"></div>
                                                                                <div className="call-icon-2"></div>
                                                                                <div className="call-icon-3"></div>
                                                                            </div>
                                                                            <div>呼叫中</div>
                                                                        </dd>
                                                                }
                                                            </dl>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="connecting-line-box">
                                                        <div className="connecting-line">
                                                            <dl>
                                                                <dt>
                                                                    <img src={window.$$uploadRc('gr.png')} className="img-teacher"/>
                                                                    {/*<img*/}
                                                                    {/*    src={`/api/media/api/v1/media/showThumbnail/${this.state.teacherPortraitId}`}*/}
                                                                    {/*    alt=""/>*/}

                                                                    <h4>{this.overflowEllipsis(this.state.teacherName, 4)}</h4>
                                                                    <p>{this.state.lianMaiTime}</p>
                                                                </dt>
                                                                {
                                                                    this.state.connecting?<dd><div id="connect"></div></dd>:
                                                                        <dd>
                                                                            <div className="call-icon">
                                                                                <div className="call-icon-1"></div>
                                                                                <div className="call-icon-2"></div>
                                                                                <div className="call-icon-3"></div>
                                                                            </div>
                                                                            <div>呼叫中</div>
                                                                        </dd>
                                                                }
                                                            </dl>
                                                        </div>
                                                    </div>  )
                                                    : ''
                                                }
                                                {/*  连线结束*/}
                                            </div>
                                            {/*)*/}
                                            {/*}*/}
                                            <div className="left clearfix">
                                                <div className="left-tabs">
                                                    <div className="video-cont" style={studentsPortrait?.length > 0?{minHeight:"610px"}:{minHeight:"660px"}}>
                                                                     {
                                                                         liveStatusFlag === 1 ?
                                                                         <div className="img" id='local_stream' style={studentsPortrait?.length > 0?{minHeight:"610px"}:{minHeight:"660px"}}>
                                                                         </div> : liveStatusFlag === 0 ?
                                                                         <div className="img" style={studentsPortrait?.length > 0?{minHeight:"610px"}:{minHeight:"660px"}}>
                                                                    <img
                                                                        src={window.$$setImgSrc(window.$$logo)}
                                                                        alt=""/>
                                                                </div> : <div className="img on" style={studentsPortrait?.length > 0?{minHeight:"610px"}:{minHeight:"660px"}}>
                                                                    <span>直播已结束</span>
                                                                </div>
                                                        }
                                                        {
                                                            liveStatusFlag === 1 ? "" : liveStatusFlag === 0 ? this.state.waitStart === 1?
                                                                    <Button
                                                                        className="start-live">等待开始</Button>:
                                                                (
                                                                    liveDetail.estimateStartTime ?    <Button
                                                                            className="start-live">预计开始：{moment(liveDetail?liveDetail.estimateStartTime : '').format("YYYY-MM-DD HH:mm")}</Button>
                                                                        :
                                                                        <Button
                                                                            className="start-live">预计开始：</Button>
                                                                )
                                                                :
                                                                <Button className="start-live on">观看回放</Button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="video-box">
                                                    <div className="left-but">
                                                        {
                                                            liveStatusFlag === 0 ?
                                                                <div className="span-sy on">
                                                                <span className="span">
                                                                    <img src={require('@/assets/img/images/icon-12a.png')}/>
                                                                    <Slider defaultValue={this.state.audioLevel} disabled={audioDisabled} onChange={this.onSliderChange} onAfterChange={this.onAfterSliderChange}/>
                                                                </span>
                                                                </div> : <div className="span-sy">
                                                                <span className="span">
                                                                    <img
                                                                        src={require('@/assets/img/images/icon-12.png')}/>
                                                                    <Slider defaultValue={this.state.audioLevel}
                                                                            disabled={audioDisabled}
                                                                            onAfterChange={this.onAfterSliderChange}/>
                                                                </span>
                                                                </div>
                                                        }

                                                    </div>
                                                    <div className="right-but zhibi-btn-list">
                                                        <ul className="ul-left">
                                                            {
                                                                liveStatusFlag === 0 ?
                                                                    <li className="on dmli"><img
                                                                        src={require('@/assets/img/images/icon-17b.png')}/>关闭弹幕
                                                                    </li> :
                                                                    this.state.startBarrage === 1 ?
                                                                        <li className="span-ht dmli"
                                                                            onClick={() => this.closeBarrage2()}><img
                                                                            src={require('@/assets/img/images/icon-17a.png')}/>关闭弹幕
                                                                        </li> :
                                                                        <li className={'dmli'} onClick={() => this.danmu()}><img
                                                                            src={require('@/assets/img/images/icon-17.png')}/>开启弹幕
                                                                        </li>
                                                            }
                                                            <ul className={'rightul'}>
                                                            {liveStatusFlag === 0 ?

                                                                (this.state.isPause === 0 ?
                                                                    <li className="li-span on">
                                                                        <img src={require('@/assets/img/images/icon-73.png')}/>暂停直播
                                                                    </li>
                                                                    :
                                                                    <li className="li-span on" >
                                                                        <img src={require('@/assets/img/images/icon-73a.png')}/>恢复直播
                                                                    </li>)
                                                                :
                                                                (
                                                                    this.state.isPause === 0 ?
                                                                        <li className="li-span" onClick={() => this.Banned(3)}>
                                                                            <img src={require('@/assets/img/images/icon-73.png')}/>暂停直播
                                                                        </li>
                                                                        :
                                                                        <li className="li-span" onClick={() => this.resumeLive()}>
                                                                            <img src={require('@/assets/img/images/icon-73a.png')}/>恢复直播
                                                                        </li>
                                                                )
                                                            }
                                                            {
                                                                liveStatusFlag === 0 ?   <li className="li-span on">
                                                                        <img src={require('@/assets/img/images/icon-74.png')}/>强制结束
                                                                    </li>
                                                                    :
                                                                    <li className="li-span" onClick={() => this.Banned(4)}>
                                                                        <img src={require('@/assets/img/images/icon-74.png')}/>强制结束
                                                                    </li>
                                                            }

                                                            <li className="li-span" onClick={() => this.Banned(1)}>
                                                                <img src={require('@/assets/img/images/icon-75.png')}/>冻结直播
                                                            </li>
                                                            </ul>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right clearfix">
                                                {
                                                    this.state.remoteScreenStream ? <Row className="Camera">
                                                        <Col span={24} className="Camera-bottom"
                                                             style={{height: "100%"}}>
                                                            {/*摄像头视频流*/}
                                                            <div className="img" id="myCamera">
                                                            </div>
                                                            <div className="bottom-text">
                                                                <span className="icon-hc"
                                                                      onClick={() => this.changeVideo()}><img
                                                                    src={require('@/assets/img/images/icon-hc.png')}/></span>
                                                            </div>
                                                        </Col>
                                                    </Row> : ''
                                                }

                                                <Row className="Chat">
                                                    <Col span={24}>
                                                        <Tabs activeKey={activeKey} onChange={this.callback.bind(this)}>
                                                            <TabPane tab="讨论区" key="1">
                                                            </TabPane>
                                                            <TabPane tab="讲解区" key="2">
                                                            </TabPane>
                                                            <TabPane tab="成员" key="3">
                                                            </TabPane>
                                                        </Tabs>
                                                        {
                                                            activeKey === "1" ? campusResource : (activeKey === "2" ? platformResource : member)
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal title={this.returnMessage()} className='Modal-Banned' visible={this.state.banFlag} destroyOnClose={true} maskClosable={false}
                                           onCancel={() => this.banCancel() }
                                           centered={true}
                                           footer={
                                               <div className='pos-btn' style={{display:"none"}}>
                                                   <Button className="but" onClick={() => this.banCancel()}>取消</Button>
                                                   <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                                               </div>
                                           }
                                    >
                                        <Banned reportType={this.state.reportType} taskId={this.state.taskId} getList={()=>{
                                            if (this.state.reportType == 3){
                                                this.setState({
                                                    pauseTime:600
                                                },() => {
                                                    this.livePauseTimer();
                                                })
                                            }else{
                                                this.props.history.goBack(-1);
                                            }
                                        }} isReport={this.state.isReport}  page="room"
                                                pauseCancel={this.pauseCancel}
                                                cancel={()=>{if (this.state.reportType == 3 && this.state.isPause == 1){
                                                    this.setState({banFlag:false,isPause:0})
                                                }else{
                                                    this.setState({banFlag:false})
                                                }}}/>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
