import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import '../index.scss';
import {classifyFirstList, classifySave, classifyUpdate, classifyFirstSelect} from '@/api/craftsman-app/index'

const {Option} = Select;

class twoClassifyModel extends React.Component {
    state = {
        data: {},
        twoClassifyDetail: {},
        oneClassifyList: {},  //一级分类列表
        oneClassifyId: '',   //一级分类id
        categoryName: '', //分类名称
        twoClassifyOne: {},
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.twoVisible) {
            this.setState({
                twoClassifyDetail: nextProps.twoClassifyDetail,
                twoVisible: nextProps.twoVisible,
                twoClassifyOne: nextProps.twoClassifyOne,
            }, () => {
                this.classifyFirstList()
            })
        }
    }

    //课程分类一级分类列表
    async classifyFirstList() {
        const oneClassifyList = await classifyFirstSelect({categoryId: ''})
        if (oneClassifyList) {
            this.setState({
                oneClassifyList: oneClassifyList
            })
        }
    }

    //一级分类查询
    oneClassifyChange = (value) => {
        this.setState({
            oneClassifyId: value,
        })
    };
    //二级分类保存
    twoClassifySave = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    saveLoading: true
                })
                let successBack = () => {
                    this.props.twoRefreshData()
                    this.props.form.resetFields()//清空表单
                    this.checkCancel()
                    this.setState({
                        twoVisible: false,
                        saveLoading: false,
                    });
                }
                if (this.state.twoClassifyDetail.categoryId) {
                    let params =
                        {
                            "categoryLevel": 2,
                            "categoryName": values.categoryName,
                            "parentId": this.state.twoClassifyOne.categoryId,
                            "categoryId": this.state.twoClassifyDetail.categoryId
                        }
                    classifyUpdate(params).then(res => {
                        if (res) {
                            message.success('编辑成功');
                            successBack();
                        }
                    })
                } else {
                    let params =
                        {
                            "categoryLevel": 2,
                            "categoryName": values.categoryName,
                            "parentId": this.state.oneClassifyId,
                        }
                    classifySave(params).then(res => {
                        if (res) {
                            message.success('添加成功');
                            successBack();
                            this.checkCancel()
                        }
                    })
                }
            }
        })
    }
    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
    }

    render() {
        const {saveLoading, twoClassifyDetail, oneClassifyList, twoClassifyOne,} = this.state;
        const {twoVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 14}
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <Modal
                destroyOnClose={true}
                width='660px'
                title={this.state.twoClassifyDetail.categoryId ? "编辑二级分类" : "新增二级分类"}
                wrapClassName='training_modal_box'
                visible={twoVisible}
                closable={false}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.checkCancel()}>取消</Button>
                        <Button loading={saveLoading} onClick={() => this.twoClassifySave()} type='primary'>保存</Button>
                    </div>
                }
            >
                <Form {...formItemLayout}>
                    <Form.Item label='所属类别'>
                        {
                            getFieldDecorator('categoryId', {
                                initialValue: twoClassifyOne.categoryName ? twoClassifyOne.categoryName : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择一级分类'
                                    },
                                ]
                            })(
                                        <Select placeholder="请选择一级分类" onChange={(value) => this.oneClassifyChange(value)}
                                                disabled ={this.state.twoClassifyDetail.categoryId ? true : false}
                                                getPopupContainer={triggerNode => triggerNode.parentNode}>
                                            {oneClassifyList && oneClassifyList.length ? oneClassifyList.map((item, index) => {
                                                return <Option key={index}
                                                               value={item.categoryId}>{item.categoryName}</Option>
                                            }) : ""}
                                        </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item label='子分类名称'>
                        {
                            getFieldDecorator('categoryName', {
                                initialValue: twoClassifyDetail.categoryName ? twoClassifyDetail.categoryName : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入子分类名称'
                                    },
                                    {
                                        max: 20,
                                        message: "子分类名称不能超过20个字"
                                    },
                                ]
                            })(<Input placeholder='请输入子分类名称' className="sold-box"
                                      suffix={<span
                                          className="suffixStyle">{getFieldValue('categoryName') ? getFieldValue('categoryName').length : "0"}/20</span>}/>)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

twoClassifyModel = Form.create()(twoClassifyModel)
export default twoClassifyModel;
