import React, { Component } from 'react'

import './index.scss'

import { DatePicker, Input, Select, Button, Table, Modal, Drawer, Form, Radio, message } from 'antd';

import { Shou, Zhan, Jia, Zheng, Jiag, Shanc } from '../../../components/shixunsvg1.20/index'

import MaxLengthInput from '../../../components/hhinput'
import { pageListEquipmentManager, optionlist, listByLevel, listByParentId, details, delEquipment, getOrganizationList, cvecequipmenttypeoptionlist, regoptionList, cvecorgplaceoptionList, cvecorgclassroomoptionList, cvecregisterequipmentregister, equipmentBandOrg, bandInfo } from '../../../api/shixun2/index'
import moment from 'moment'

const Yichang = () => {
    return (
        <div>
            <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 11.5L0 11.5C9.59018e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5L0 11.5Z"
                    fill="#F3B714"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.6516 6.32578V13.2258C12.6516 13.8609 12.1367 14.3758 11.5016 14.3758C10.8665 14.3758 10.3516 13.8609 10.3516 13.2258V6.32578C10.3516 5.69065 10.8665 5.17578 11.5016 5.17578C12.1367 5.17578 12.6516 5.69065 12.6516 6.32578ZM11.5016 17.8258C12.1367 17.8258 12.6516 17.3109 12.6516 16.6758C12.6516 16.0407 12.1367 15.5258 11.5016 15.5258C10.8664 15.5258 10.3516 16.0407 10.3516 16.6758C10.3516 17.3109 10.8664 17.8258 11.5016 17.8258Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
const { Option } = Select;


const Bangding = [{
    id: '0', name: '未绑定'
}, {
    id: '1', name: '已绑定'
}]
const JiHuo = [{
    id: '0', name: '未激活'
}, {
    id: '1', name: '已激活'
}]
export class index extends Component {

    state = {

        startValue: null,
        endValue: null,
        endOpen: false,
        delete: false,//删除,

        visible: false,//查看抽屉

        dataSource: [],
        isZhanKai: 'false',
        visibleEcho: false,//绑定消息'

        She_beivisible: false,//设备注册
        dataSourceloding: true,//表格loding

        value: undefined,//所在校区  有 无 
        // formlistArray: [`$$$${Math.random().toString(36).substr(3)}`],// 设备主板号的数组
        formlistArray: [{
            equipmentMainboard0: null,
            mainboardSmbios0: null
        }],// 设备主板号的数组
        _zhubanhao: "",//搜索主板号
        _op_tionlist_select: [],//供应商下拉框筛选
        _gongyingshang: undefined,//初始值供应商
        _listByLevel: [],//设备大类
        _dalei: undefined,//设备大类初始值
        _listByParentId: [],//设备类别
        _shebeileibie: undefined,//设备类别初始值
        _Sou_shu_selct: [],//所属机构
        _sscj: undefined,//所属机构初始值
        _jigou: undefined,//机构状态
        _jihuo: undefined,//激活状态
        _shebei_xinghao: undefined,//设备型号
        mainBoard: undefined,//主板 smBIOS
        _Bangding: undefined,//绑定状态
        xinagqing: [],//详情
        Shanchuid: '',//删除id
        Shangong: '',//删除第二参数
        BangDing: [],//绑定信息
        Tan_suo: [],//弹框所属跨机构
        Xiao_qu: [],//校区管理
        Chang_di: [],//场地管理
        Shi_xun: [],//实训教师
        pageNum: 1,
        pageSize: 10,
        total: 0,
        equipmentMainboard: '', euqipmentId: ''
        ,
        __bangdingxinxi_orgId: null,//所在机构
        __suozai_xiao_qu_id: null,//所在小区orgId
        _souzaichangdi_id: null,//所在场地

        ___selectedRowKeys: [],//表格选中的数据
        Suo_shu_Ji_Gou: [],
        Bang_suo: [],//所属机构
        ShebeiXinghao: [],//设备型号
        _Shebeixinghao: [],//搜索 设备型号

        ___tan_bangdingxinxi_orgId: null,//所在机构
        ___tan_suozai_xiao_qu_id: null,//所在小区orgId
        ___tan_souzaichangdi_id: null,//所在场地
        _tan_Changdi: [],//设备注册弹框  场地
        _tan_Suo_xun: [],
        isshow: false,
        bangdinfxinxi: false,//绑定信息
        // zhubandelete:{},//主板smBIOS



    };

    componentDidMount() {

        this.getData({
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
        this.getoptionlist()//供应商
        this.getlistByLevel()//设备大类
        this.getSuoselect()
        this.cvecequipmenttypeoptionlist_p()//设备型号  下拉

    }
    // 搜索
    headerSearch = (target) => {
        const { name, value } = target

        this.setState(() => {
            return {
                [name]: value
            }
        })
    }
    // 查询
    chaxun = () => {
        const { _zhubanhao, _gongyingshang, _dalei, _shebeileibie, _jigou, _jihuo, _sscj, startValue, endValue, pageSize, pageNum, mainBoard, _shebei_xinghao, _Bangding } = this.state

        this.getData({
            equipmentMainboard: _zhubanhao,//设置主板号
            // supplierId: _gongyingshang,//供应商
            // categoryParentId: _dalei,//设备大类
            // categoryId: _shebeileibie,//设置类别
            // euqipmentBindType: _jigou,//机构绑定
            activationState: _jihuo,//激活状态
            orgId: _sscj,//机构id
            createBeginTime: startValue ? moment(startValue).format('YYYY-MM-DD hh:mm:ss') : '',//开始时间

            createEndTime: endValue ? moment(endValue).format('YYYY-MM-DD hh:mm:ss') : '',//结束时间
            mainboardSmbios: mainBoard,//主板 smBIOS
            pageNum: pageNum,
            pageSize: pageSize,
            equipmentTypeId: _shebei_xinghao,//设备型号
            euqipmentBindType: _Bangding,
        })

        // euqipmentBindType:_Bangding,//绑定状态
        // 设备型号  equipmentTypeId
    }
    // 重置 
    gongzhi = () => {

        this.setState(() => {
            return {
                _zhubanhao: undefined,
                _gongyingshang: undefined,
                _dalei: undefined,
                _shebeileibie: undefined,
                _jigou: undefined,
                _jihuo: undefined,
                _sscj: undefined,
                startValue: undefined,
                endValue: undefined,
                mainboardSmbios: undefined,
                _shebei_xinghao: undefined,
                _Bangding: undefined,
                mainBoard: undefined




            }
        }, () => {
            this.getData()
        })
    }
    // 获取大表戈数据
    getData = (par) => {

        return pageListEquipmentManager(par).then(res => {

            if (res) {
                this.setState(() => {
                    return {
                        dataSource: res.data,
                        dataSourceloding: false,
                        pageSize: res.pageSize,
                        pageNum: res.pageNum,
                        total: res.total,
                    }
                })
            } else {
                message.info('数据错误')
            }
        })
    }
    // 所属机构下拉框
    getSuoselect = async (pp) => {
        const _suo_selct = await getOrganizationList(pp)
        this.setState(() => {
            return {
                _Sou_shu_selct: _suo_selct

            }
        })

    }
    //设备型号  下拉框
    cvecequipmenttypeoptionlist_p = async (pp) => {
        const cvecequipmenttypeoptionlist_data = await cvecequipmenttypeoptionlist(pp)
        this.setState(() => {
            return {
                _Shebeixinghao: cvecequipmenttypeoptionlist_data
            }
        })
    }
    // 供应商下卡框
    getoptionlist = async (pp) => {
        const _op_tion = await optionlist(pp)
        this.setState(() => {
            return {
                _op_tionlist_select: _op_tion

            }
        })
    }
    // 设备大类
    getlistByLevel = async (p) => {
        const ip_by = await listByLevel({
            categoryLevel: '1'
        })

        this.setState(() => {
            return {
                _listByLevel: ip_by
            }
        })
    }
    // 所在校区

    Radio_onChange = e => {
        this.setState({

            value: e.target.value,
            isshow: true
        });
    };

    // 绑定消息

    visibleEchoTWOModal = async (euqipmentId) => {


        this.setState({

            visibleEcho: true,

        });
        if (this.state.bangdinfxinxi) {
            //  单个绑定信息
            await bandInfo({ euqipmentId }).then(async res => {
                if (res) {
                    const Xiaoqu = await regoptionList({ orgId: res.orgId })//校区管理
                    const Chang_di_m = await cvecorgplaceoptionList({
                        orgId: res.orgId,
                        campusId: res.campusId,
                        placeId: res.placeId
                    })//场地管理
                    const suo = await cvecorgclassroomoptionList({
                        placeId: res.placeId,
                        orgId: res.orgId,
                        campusId: res.campusId,
                    })
                    // 教师管理
                    this.setState(() => {
                        return {
                            Xiao_qu: Xiaoqu, Chang_di: Chang_di_m,
                            Shi_xun: suo
                        }
                    })
                    this.props.form.setFieldsValue({
                        orgId: res.orgId,
                        equipmentTypeId: res.equipmentTypeId,
                        campusId: Xiaoqu.length && res.campusId || undefined,

                        placeId: Chang_di_m.length && res.placeId || undefined,
                        classroomId: suo.length && res.classroomId || undefined
                    })

                }

            })
        }


    };



    visibleEchoTWOOk = e => {
        const { formlistArray, ___selectedRowKeys } = this.state
        e.preventDefault();
        let b = ___selectedRowKeys.map((i) => {
            return i.euqipmentId
        })
        console.log(b, 'll')

        this.props.form.validateFields(["euqipmentMainboardArray", "orgId", "equipmentTypeId", "campusId", "placeId", 'classroomId'], async (err, values) => {

            if (!err) {
                let a = await equipmentBandOrg({
                    euqipmentIdArray: b,
                    orgId: values.orgId,
                    equipmentTypeId: values.equipmentTypeId,
                    campusId: values.campusId,
                    placeId: values.placeId,
                    classroomId: values.classroomId,

                })
                if (a.code === 0) {
                    this.mesSuccess('绑定成功')
                    this.getData()
                    this.setState({
                        ___selectedRowKeys: []
                    })
                } else {
                    this.mesWarning('绑定失败')
                }

                this.setState(() => {

                    return {

                        visibleEcho: false

                    }

                });

            }

        })

    };



    visibleEchoTWOCancel = e => {

        this.setState({

            visibleEcho: false,
            ___selectedRowKeys: []


        });



    };

    // 设备注册

    She_beivisibleModal = () => {

        this.setState({

            She_beivisible: true,
            formlistArray: [{
                equipmentMainboard0: null,
                mainboardSmbios0: null
            }],// 设备主板号的数组

        });

    };



    She_beivisibleOk = e => {
        e.preventDefault();
        const { formlistArray } = this.state
        let ArrList = [];
        const cvecMainbordIndexQo = formlistArray.map((item, index) => {
            const equipmentMainboardKey = `equipmentMainboard${index}`;

            const mainboardSmbiosKey = `mainboardSmbios${index}`;
            console.log(equipmentMainboardKey, mainboardSmbiosKey)
            ArrList = ArrList.concat([equipmentMainboardKey, mainboardSmbiosKey]);
            return {
                equipmentMainboard: item[equipmentMainboardKey],
                mainboardSmbios: item[mainboardSmbiosKey]
            }
        })
        console.log(ArrList, cvecMainbordIndexQo)
        this.props.form.validateFields(["orgId1", "equipmentTypeId", "isBanding1", "campusId1", "placeId1 ", "classroomId", ...ArrList], async (err, values) => {
            if (!err) {
                let b = await cvecregisterequipmentregister({
                    campusId: values.campusId1,
                    orgId: values.orgId1,
                    equipmentTypeId: values.equipmentTypeId,
                    isBanding: values.isBanding1,//0 1

                    placeId: values.placeId1,
                    classroomId: values.classroomId,
                    cvecMainbordIndexQo,
                })
                debugger

                if (b.result === null) {
                    this.mesSuccess('添加成功')
                    this.getData()
                } else {
                    this.mesWarning('添加重复')
                }

                this.setState(() => {

                    return {

                        She_beivisible: false,
                        isshow: false,

                    }

                });


            }

        })



    };



    She_beivisibleCancel = e => {

        this.setState({

            She_beivisible: false,
            isshow: false

        });



    };





    // 查看抽屉



    showDrawer = async (id) => {

        const _xiang = await details({
            euqipmentId: id
        })

        this.setState({

            visible: true,
            xinagqing: _xiang

        });

    };



    onClose = () => {

        this.setState({

            visible: false,

        });

    };

    disabledStartDate = startValue => {
        const { endValue } = this.state;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };

    disabledEndDate = endValue => {
        const { startValue } = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    __onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    onStartChange = (item) => {
        this.setState(() => {
            return {
                startValue: item
            }
        })
        this.__onChange('startValue', item);
    };

    onEndChange = (item) => {
        this.setState(() => {
            return {
                endValue: item
            }
        })
        this.__onChange('endValue', item);
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };

    // 机构绑定
    onChange = (item) => {
        this.setState(() => {
            return {
                _jigou: item
            }
        })
        console.log(item, 'oo');
    }
    // 所属机构
    Sou_onChange = (item) => {
        this.setState(() => {
            return {
                _sscj: item
            }
        })
    }
    // 激活状态
    Ji_onChange = (item) => {
        this.setState(() => {
            return {
                _jihuo: item
            }
        })
    }
    // 设备型号下拉框
    Shebei_xinghao = (item) => {
        this.setState(() => {
            return {
                _shebei_xinghao: item
            }
        })
    }
    // 绑定状态
    Bangding_onChange = (item) => {
        this.setState(() => {
            return {
                _Bangding: item
            }
        })
    }

    // 供应商

    Gong_onChange = (item) => {
        this.setState(() => {
            return {
                _gongyingshang: item
            }
        })
    }

    // 设备大类
    She_onChange = async (item) => {
        await this.getlistByParentId(item)
        this.setState(() => {
            return {
                _dalei: item
            }
        })
    }

    // 设备类别
    SheBei_onChange = async (item) => {
        this.setState(() => {
            return {
                _shebeileibie: item
            }
        })
    }

    getlistByParentId = async (parentId) => {
        const ip_by = await listByParentId({
            parentId
        })
        this.setState(() => {
            return {
                _listByParentId: ip_by
            }
        })
    }

    // 删除Model
    deleteModal = (d) => {
        this.setState({
            delete: true,
            euqipmentId: d
        });
    };

    deleteOk = async v => {
        const { euqipmentId } = this.state
        delEquipment({ euqipmentId: euqipmentId }).then(res => {
            if (res.code === 0) {
                this.getData()
                this.mesSuccess('删除成功')
            } else {
                this.mesWarning('删除失败')
            }
        })
        this.setState({
            delete: false,
        });
    };

    deleteCancel = e => {
        console.log(e);
        this.setState({
            delete: false,
        });
    };

    //展开收起
    _iszhankaiFn = (isZhanKai) => {
        this.setState(() => {
            return {
                isZhanKai: !isZhanKai
            }
        })
    }
    // 设备注册-所属机构
    suo_shu_onChange = async (value) => {
        debugger
        const Suo_shu_Ji_Gou = await regoptionList({ orgId: value })//
        // console.log(Suo_shu_Ji_Gou,'suo_shu')
        this.setState(() => {
            return {
                Suo_shu_Ji_Gou: Suo_shu_Ji_Gou,
                ___tan_bangdingxinxi_orgId: value
            }
        })
    }
    // 绑定信息_所属机构
    bang_suo_onChange = async (value) => {

        const Xiaoqu = await regoptionList({ orgId: value })//校区管理
        // )))))))))))))))))))))))))))))))
        this.props.form.setFieldsValue({ placeId: undefined, classroomId: undefined, campusId: undefined, })
        this.setState(() => {
            return {
                Xiao_qu: Xiaoqu,
                __bangdingxinxi_orgId: value
            }
        })
        console.log(`selected ${value}`);
    }

    // 绑定信息_设备信息

    she_bei_onChange = (value) => {
        console.log(`selected ${value}`);
    }

    // 设备注册——所在校区
    tan_suo_zai_onChange = async (value) => {
        const { ___tan_bangdingxinxi_orgId, _tan_Changdi, _tan_Suo_xun } = this.state
        this.props.form.setFieldsValue({ placeId: undefined, classroomId: undefined })
        const Shebeixiaoqwu = await cvecorgplaceoptionList({
            orgId: ___tan_bangdingxinxi_orgId,
            campusId: value

        })

        this.setState(() => {
            return {
                _tan_Changdi: Shebeixiaoqwu,
                ___tan_suozai_xiao_qu_id: value,
            }
        })
    }

    // 绑定信息_所在校区
    suo_zai_onChange = async (value) => {
        const { __bangdingxinxi_orgId } = this.state
        this.props.form.setFieldsValue({ placeId: undefined, classroomId: undefined })
        const Chang_di_m = await cvecorgplaceoptionList({
            orgId: __bangdingxinxi_orgId,
            campusId: value,
        })//场地管理
        this.setState(() => {
            return {
                Chang_di: Chang_di_m,
                __suozai_xiao_qu_id: value
            }
        })
        console.log(`selected ${value}`);
    }
    // 设备注册——所在场地
    tan_suo_chang_onChange = async (value) => {
        const { ___tan_bangdingxinxi_orgId, ___tan_suozai_xiao_qu_id, } = this.state
        const b = await cvecorgclassroomoptionList({
            placeId: value,
            orgId: ___tan_bangdingxinxi_orgId,
            campusId: ___tan_suozai_xiao_qu_id
        })
        this.setState(() => {
            return {
                _tan_Suo_xun: b,
                ___tan_souzaichangdi_id: value,
            }
        })
    }

    // 绑定信息_所在场地
    suo_chang_onChange = async (value) => {

        const { __bangdingxinxi_orgId, __suozai_xiao_qu_id } = this.state

        const suo = await cvecorgclassroomoptionList({
            placeId: value,
            orgId: __bangdingxinxi_orgId,
            campusId: __suozai_xiao_qu_id
        })
        this.setState(() => {
            return {
                Shi_xun: suo,
                _souzaichangdi_id: value
            }
        })

        console.log(`selected ${value}`);


    }

    // 绑定信息_实训教室

    shi_xun_onChange = (value) => {
        console.log(`selected ${value}`);

    }
    remove = k => {
        const { formlistArray } = this.state
        this.setState(() => {
            return {
                formlistArray: formlistArray.filter(key => key !== k)
            }
        })
    };

    add = () => {
        const { formlistArray } = this.state
        if (formlistArray.length !== 10) {
            this.setState(() => {
                const len = formlistArray.length;
             
                return {
                    formlistArray: [...formlistArray,
                    {
                        [`equipmentMainboard${len}`]: null,
                        [`mainboardSmbios${len}`]: null
                    }
                    ]
                    
                }
                
            })
          
        } else {
            this.mesWarning('最多十个')
        }
    };


    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize, ":sdd")
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getData({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        console.log(current, pageSize, ":sddpageNum变化")
        this.setState({
            pageNum: current,
        }, () => {
            this.getData({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );

    };
    render() {

        const { startValue, endValue, endOpen, dataSource, isZhanKai, formlistArray, xinagqing } = this.state;

        const { getFieldDecorator } = this.props.form;
        const { RenderButton } = React;
        const rowSelection = {

            onChange: (selectedRowKeys, selectedRows) => {
                this.setState(() => {
                    return {
                        ___selectedRowKeys: selectedRows
                    }
                })

            },

            selectedRowKeys: this.state.___selectedRowKeys.map(i => {
                return i.equipmentMainboard
            })


        };
        const formItemLayoutWithOutLabel = {
            labelCol: {
                xs: { span: 4 }
                , sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 6 },
                sm: { span: 11 },
            },
        };

        const formItemLayout_aaaa = {
            labelCol: {
                xs: { span: 3 }
                , sm: { span: 5 }

            }
            , wrapperCol: {
                xs: { span: 6 }
                , sm: { span: 12 }

            }
        }



        const formItems = formlistArray.map((k, index) => (
            <Form.Item

                {...formItemLayout_aaaa}
                label={'设备主板号'}
                required={true}
                className={`form_ItemLayoutWithOutLabel ${index && 'not_first_childed'}`}
                key={k}
            >
                <div className="form_ItemLayoutWithOutLabel_flex">
                    <Form.Item>
                        {getFieldDecorator(`equipmentMainboard${index}`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请选择设备主板号' }],
                        })(
                            <MaxLengthInput
                                placeholder='请输入设备主板号'
                                style={{ height: 40, paddingRight: 50 }}
                                maxLength={30}
                                onChange={e => {
                                    this.setState(state => {
                                        state.formlistArray[index][`equipmentMainboard${index}`] = e;
                                        return {
                                            formlistArray
                                        }
                                    }, () => {
                                        console.log(this.state.formlistArray[index][`equipmentMainboard${index}`], 'iiiiii')
                                    })
                                }}
                            ></MaxLengthInput>
                        )}
                    </Form.Item>
                    <Form.Item>  {getFieldDecorator(`mainboardSmbios${index}`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请选择主板smBIOS' }],
                    })(
                        <MaxLengthInput
                            placeholder='请输入主板smBIOS'
                            style={{ height: 40, paddingRight: 50 }}
                            maxLength={30}  
                            onChange={e => {
                                this.setState(state => {
                                    state.formlistArray[index][`mainboardSmbios${index}`] = e;
                                    return {
                                        formlistArray
                                    }
                                },()=>{
                                    console.log(this.state.formlistArray[index][`mainboardSmbios${index}`])
                                })
                            }}
                        ></MaxLengthInput>
                    )}  </Form.Item>




                </div>


                {formlistArray.length > 1 ? (
                    <span
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.remove(k)}
                        style={{
                            color: '#3E78ED',
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        &nbsp; <Shanc />&nbsp;
                        删除
                    </span>


                ) : null}
            </Form.Item>
        ));


        const columns = [

            {
                title: '设备主板号',
                dataIndex: 'equipmentMainboard',

            },
            {
                title: '主板smBIOS',
                dataIndex: 'mainboardSmbios',
            },
            {
                title: '注册时间',
                dataIndex: 'registrationTime',
            },
            {
                title: '扫描时间',
                dataIndex: 'scanTime',
            },
            {
                title: '设备型号',
                dataIndex: 'equipmentTypeName',
            },

            {
                title: '激活状态',
                dataIndex: 'activeState',
                render: (v, b, value) => <div className=' vertical_j' style={{ paddingLeft: "10px" }}>
                    <span
                    >
                        {b.activeState === "0" ? (
                            <span>未激活</span>
                        ) : <span>已激活</span>}
                    </span>
                </div>
            },
            {
                title: '绑定状态',
                dataIndex: 'euqipmentBindType',
                render: (v, b, value) => <div className=' vertical_j' style={{ paddingLeft: "10px" }}>
                    <span
                    >
                        {b.euqipmentBindType === 0 ? (
                            <span>未绑定</span>
                        ) : <span>已绑定</span>}
                    </span>
                </div>
            },

            {
                title: '所属机构',
                dataIndex: 'orgName',

            },
            {
                title: '操作',

                key: 'operation',

                fixed: 'right',

                align: 'center',

                width: 200,

                render: (v, b) => {

                    return <div className='action vertical_j' style={{ cursor: 'pointer' }}>

                        <RenderButton id="ABB-01-A05-b_btnBulkBinding">
                            <span onClick={async () => {
                                this.setState(() => {
                                    return {
                                        ___selectedRowKeys: [{ ...b }]
                                    }
                                })
                                const BangDing = await cvecequipmenttypeoptionlist()//设备型号
                                const Suo_SHu = await getOrganizationList()//所属机构下拉


                                this.setState(() => {
                                    return {
                                        BangDing: BangDing,
                                        Tan_suo: Suo_SHu,
                                        bangdinfxinxi: true

                                    }
                                }, () => {
                                    this.visibleEchoTWOModal(b.euqipmentId)
                                })
                            }}>绑定信息</span>
                        </RenderButton>

                        <RenderButton id="ABB-01-A05-b_btnEquipmentInformation">
                            <span onClick={() => {
                                debugger
                                this.showDrawer(b.euqipmentId)
                            }} >查看</span>
                        </RenderButton>

                        <RenderButton id="ABB-01-A05-b_btnDel">
                            <span onClick={() => {
                                this.deleteModal(v.euqipmentId)

                            }}>删除</span>
                        </RenderButton>
                    </div>



                },



            },

        ];

        columns.forEach(v => { v.width = 200; v.align = 'center' })



        const HeaderButton = () => {

            return <> <Button style={{ maxWidth: 69, marginRight: 20 }}
                onClick={() => {
                    this.gongzhi()
                }}
            >重置</Button>

                <Button type="primary" style={{ maxWidth: 69, marginRight: 20 }}
                    onClick={() => {
                        this.chaxun()
                    }}
                >查询</Button></>

        }

        return (

            <div className="registration_processing">
                <div className="registration_processing_top">
                    <div className='registration_processing_top_f1'>
                        <span >
                            <>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    value={startValue}
                                    placeholder="开始注册时间"
                                    onChange={this.onStartChange}
                                    onOpenChange={this.handleStartOpenChange}
                                />
                                <span style={{ width: 40, textAlign: 'center', lineHeight: '30px' }}>至</span>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    value={endValue}
                                    placeholder="开始结束时间"
                                    onChange={this.onEndChange}
                                    open={endOpen}
                                    onOpenChange={this.handleEndOpenChange}
                                />
                            </>

                        </span>

                        <span><Input placeholder="设备主板号"
                            name='_zhubanhao' onChange={(e) => this.headerSearch(e.target)} value={this.state._zhubanhao}
                        /> </span>

                        <span style={{ display: isZhanKai ? 'none' : '', justifyContent: 'flex-end' }}>

                            <HeaderButton />

                            <span style={{ color: '#3E78ED', maxWidth: 69, marginRight: 20, marginTop: 6, cursor: "pointer" }} onClick={() => {

                                this._iszhankaiFn(isZhanKai)

                            }}>展开&nbsp;<Zhan></Zhan></span>
                        </span>

                        <span style={{ display: !isZhanKai ? 'none' : '' }}>
                            <Input placeholder="主板smbios"
                                name='mainBoard' onChange={(e) => this.headerSearch(e.target)} value={this.state.mainBoard}
                            />
                            {/* <Select
                                placeholder="机构绑定"
                                optionFilterProp="children"
                                value={this.state._jigou}
                                onChange={this.onChange}
                            >
                                {
                                    Jigou.map((item, index) => {

                                        return <Option key={item.id}>
                                            {item.name}
                                        </Option>
                                    })

                                }
                            </Select> */}

                            {/* 主板smBIOS */}

                        </span>

                    </div>
                    <div className='registration_processing_top_f2' style={{ display: !isZhanKai ? 'none' : '' }} >
                        <span v>

                            <span>

                                <Select
                                    placeholder="所属机构"
                                    optionFilterProp="children"
                                    value={this.state._sscj}
                                    onChange={this.Sou_onChange}
                                >
                                    {
                                        Array.isArray(this.state._Sou_shu_selct) && this.state._Sou_shu_selct.map((item, index) => {

                                            return <Option key={item.id}>
                                                {item.enterpriseName}
                                            </Option>
                                        })

                                    }
                                </Select>

                            </span>
                            <span style={{ width: 40 }}>&emsp; </span>
                            <span>
                                <Select
                                    placeholder="绑定状态"
                                    optionFilterProp="children"
                                    value={this.state._Bangding}
                                    onChange={this.Bangding_onChange}
                                >

                                    {
                                        Bangding.map((item, index) => {

                                            return <Option key={item.id}>
                                                {item.name}
                                            </Option>
                                        })
                                    }

                                </Select>

                            </span>

                        </span>





                        <span>
                            <Select
                                placeholder="激活状态"
                                optionFilterProp="children"
                                value={this.state._jihuo}
                                onChange={this.Ji_onChange}
                            >

                                {
                                    JiHuo.map((item, index) => {

                                        return <Option key={item.id}>
                                            {item.name}
                                        </Option>
                                    })
                                }

                            </Select>

                        </span>



                        <span>
                            <Select
                                style={{ flex: 1 }}

                                placeholder="设备型号"

                                optionFilterProp="children"
                                value={this.state._shebei_xinghao}
                                onChange={this.Shebei_xinghao}
                            >

                                {
                                    this.state._Shebeixinghao.map((item, index) => {
                                        return <Option key={item.equipmentTypeId}>
                                            {item.equipmentName}
                                        </Option>
                                    })
                                }

                            </Select>
                        </span>



                    </div>
                    <div className='registration_processing_top_f3' >
                        <span style={{ display: !isZhanKai ? 'none' : '' }}>
                            <span>

                            </span>
                            <span style={{ width: 40, maxWidth: 40 }}></span>
                            <span></span>
                        </span>

                        <span></span>


                        <span style={{ display: !isZhanKai ? 'none' : '' }}>
                            <HeaderButton />

                            <span onClick={() => this._iszhankaiFn(isZhanKai)} style={{ color: '#3E78ED', marginRight: 20, cursor: "pointer" }}>收起&nbsp;<Shou></Shou></span>

                        </span>

                    </div>



                </div>

                <div className="registration_processing_main">
                    <RenderButton id="ABB-01-A05-b_btnRegistrationEquipment">
                        <Button type="primary" style={{ marginRight: 20 }} onClick={async () => {


                            const Bang_suo = await getOrganizationList()//所属机构下拉
                            const ShebeiXinghao = await cvecequipmenttypeoptionlist()
                            this.setState(() => {
                                return {
                                    Bang_suo: Bang_suo,
                                    ShebeiXinghao: ShebeiXinghao,
                                }
                            }, () => {
                                this.She_beivisibleModal()
                            })
                        }}>设备注册</Button></RenderButton>
                    <RenderButton id="ABB-01-A05-b_btnRegistrationInformation">
                        <Button style={{ marginRight: 20 }} onClick={async () => {
                            debugger
                            const { ___selectedRowKeys } = this.state
                            if (___selectedRowKeys.length < 1) {
                                this.mesWarning('请至少选择一条')
                                return
                            }
                            const BangDing = await cvecequipmenttypeoptionlist()//设备型号
                            const Suo_SHu = await getOrganizationList()//所属机构下拉
                            // const bandInfo1=await bandInfo()
                            this.setState(() => {
                                return {
                                    BangDing: BangDing,
                                    Tan_suo: Suo_SHu,
                                    bangdinfxinxi: false


                                }
                            }, () => {
                                this.visibleEchoTWOModal()
                            })
                        }}>绑定信息</Button>
                    </RenderButton>
                </div>

                <div className="registration_processing_footer">

                    <Table
                        rowKey='equipmentMainboard'
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={dataSource}
                        // scroll={{ x: 1500, y: 'calc(100vh - 520px)' }    }
                        scroll={{ y: "calc(100vh - 460px)", x: "calc(100% - 700px)" }}
                        loading={this.state.dataSourceloding}
                        pagination={
                            {
                                defaultCurrent: 1,
                                pageSizeOptions: ['10', '20', '30'],
                                showSizeChanger: true,
                                onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                onChange: (a, b) => this.onPageNumChange(a, b),
                                current: this.state.pageNum,
                                pageSize: this.state.pageSize,
                                total: this.state.total,
                            }
                        }
                    />

                </div>



                {/* 删除 */}



                <Modal

                    title={<div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}>温馨提示</div>}

                    visible={this.state.delete}

                    onOk={this.deleteOk}

                    onCancel={this.deleteCancel}

                    bodyStyle={{ height: 130 }}

                    width={450}

                    centered

                    closable={false}

                    wrapClassName='details_delete_reg'

                >

                    <div>



                        <p><span><Yichang></Yichang></span><span>是否确定删除此条设备信息？</span></p>

                    </div>

                </Modal>

                {/* c抽屉 */}

                <div>

                    <Drawer

                        title="设备信息"

                        placement="right"

                        closable={false}

                        visible={this.state.visible}

                        width={600}

                        className="red_information_tion"

                    >

                        <div>

                            <div><span><Zheng /></span><span>扫描信息</span></div>

                            <p><span>设备主板号:</span><span>{xinagqing?.equipmentBoard}</span></p>



                            <p><span>磁盘存储量:</span><span>{xinagqing?.storageTotalFormatted}</span></p>



                            <p><span>内存:</span><span>{xinagqing?.bytesTotalFormatted}</span></p>



                            <p><span>操作系统版本:</span><span>{xinagqing?.operatingSystemVersion}</span></p>

                            <p><span>操作系统:</span><span>{xinagqing?.operatingSystem}</span></p>



                            <p><span>可用存储量:</span><span>{xinagqing?.storageAvailFormatted}</span></p>

                            <p><span>可用内存:</span><span>{xinagqing?.bytesAvailFormatted}</span></p>



                            <p><span>设备IP:</span><span>{xinagqing?.outsideAddress}</span></p>

                            <p><span>扫描时间:</span><span>{xinagqing?.scanTime}</span></p>
                            <p><span>主板smBIOS:</span><span>{xinagqing?.mainboardSmbios}</span></p>

                        </div>



                        <div>

                            <div><span><Zheng /></span><span>绑定信息</span></div>

                            <p><span>所属机构:</span><span>{xinagqing?.orgName}</span></p>

                            <p><span>设备名称:</span><span>{xinagqing?.equipmentName}</span></p>

                            <p><span>设备分类:</span><span>{xinagqing?.equipmentCategoryName}</span></p>



                            <p><span>设备供应商:</span><span>{xinagqing?.equipmentSupplier}</span></p>



                            <p><span>登记位置:</span><span>{xinagqing?.registerAddress}</span></p>

                            <p><span>绑定时间:</span><span>{xinagqing?.createTime}</span></p>


                        </div>



                        <div



                            style={{



                                position: 'absolute',



                                bottom: 0,



                                width: '100%',

                                borderTop: '1px solid #e8e8e8',

                                padding: '10px 16px',

                                textAlign: 'right',

                                left: 0,

                                background: '#fff',

                                borderRadius: '0 0 4px 4px',

                            }}

                        >

                            <Button onClick={this.onClose} type="primary" style={{ width: 90, height: 40, position: 'relative', right: 400, background: '#fff', color: '#3E78ED' }}>



                                确定

</Button>

                        </div>



                    </Drawer>

                </div>

                {/* 绑定消息 */}

                <Modal

                    title={<div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}>绑定信息</div>}

                    visible={this.state.visibleEcho}

                    onOk={this.visibleEchoTWOOk}

                    onCancel={this.visibleEchoTWOCancel}

                    bodyStyle={{ height: 450 }}

                    width={660}
                    destroyOnClose
                    centered

                    wrapClassName='details_bang'

                    closable={false}
                    key={this.state.visibleEcho}

                >
                    <div>
                        <div>
                            <span style={{ color: '#333', fontSize: 16 }}>设备主板号：</span>
                            {
                                this.state.___selectedRowKeys.map((ii, iiindex) => {

                                    return <code >{ii.equipmentMainboard}&emsp;{iiindex < this.state.___selectedRowKeys?.length - 1 ? <>{'/'}&emsp;</> : null}</code>
                                })
                            }

                        </div>
                        <div>


                            <span style={{ color: '#333', fontSize: 16 }}>主板smBIOS：</span>
                            {
                                this.state.___selectedRowKeys.map((ii, iiindex) => {

                                    return <code >{ii.mainboardSmbios}&emsp;{iiindex < this.state.___selectedRowKeys?.length - 1 ? <>{'/'}&emsp;</> : null}</code>
                                })
                            }

                        </div>
                    </div>


                    <Form {...formItemLayout_aaaa} >



                        <Form.Item label="所属机构">

                            {getFieldDecorator('orgId', {

                                rules: [{ required: true, message: '请选择所属机构' }],

                            })(

                                <Select

                                    style={{ width: 330 }}
                                    placeholder="请选择所属机构"
                                    onChange={this.bang_suo_onChange}
                                >

                                    {
                                        this.state.Tan_suo.map((item, index) => {

                                            return <Option key={item.id}>
                                                {item.enterpriseName}
                                            </Option>
                                        })
                                    }
                                </Select>
                            )}

                        </Form.Item>

                        <Form.Item label="设备型号">
                            {getFieldDecorator('equipmentTypeId', {

                                rules: [{ required: true, message: '请选择设备型号' }],

                            })(

                                <Select
                                    style={{ width: 330 }}
                                    placeholder="请选择设备型号"
                                    onChange={this.she_bei_onChange}
                                >
                                    {
                                        this.state.BangDing.map((item, index) => {
                                            return <Option key={item.equipmentTypeId}>
                                                {item.equipmentName}
                                            </Option>
                                        })
                                    }

                                </Select>

                            )}



                        </Form.Item>


                        <Form.Item label="所在校区">

                            {getFieldDecorator('campusId', {

                                rules: [{ message: '请选择所在校区' }],

                            })(

                                <Select
                                    style={{ width: 330 }}
                                    placeholder="请选择所在校区"
                                    onChange={this.suo_zai_onChange}

                                >
                                    {
                                        this.state.Xiao_qu.map((item, index) => {
                                            return <Option key={item.campusId}>
                                                {item.campusName}
                                            </Option>
                                        })
                                    }
                                </Select>,
                            )}

                        </Form.Item>

                        <Form.Item label="所在场地">

                            {getFieldDecorator('placeId', {
                                rules: [{ required: false, message: '请选择所在场地' }],
                            })(

                                <Select
                                    style={{ width: 330 }}
                                    placeholder="请选择所在场地"
                                    onChange={this.suo_chang_onChange}

                                >

                                    {
                                        this.state.Chang_di.map((item, index) => {
                                            return <Option key={item.placeId}>
                                                {item.placeName}
                                            </Option>
                                        })
                                    }
                                </Select>,

                            )}



                        </Form.Item>

                        <Form.Item label="实训教室">
                            {getFieldDecorator('classroomId', {
                                rules: [{ required: false, message: '请选择实训教室' }],

                            })(

                                <Select
                                    style={{ width: 330 }}
                                    placeholder="请选择实训教室"
                                    onChange={this.shi_xun_onChange}

                                >
                                    {
                                        this.state.Shi_xun.map((item, index) => {
                                            return <Option key={item.classroomId}>
                                                {item.classroomName}
                                            </Option>
                                        })
                                    }
                                </Select>,

                            )}


                        </Form.Item>

                    </Form>

                    {/* f */}

                </Modal>

                <Modal
                    title={<div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}>设备绑定</div>}
                    visible={this.state.She_beivisible}
                    onOk={this.She_beivisibleOk}
                    onCancel={this.She_beivisibleCancel}
                    bodyStyle={{ height: 'auto' }}
                    width={758}
                    destroyOnClose
                    centered
                    wrapClassName='details_ce'
                    closable={false}
                    key={this.state.She_beivisible}
                >

                    <Form {...formItemLayout_aaaa}>

                        <Form.Item label="所属机构">

                            {getFieldDecorator('orgId1', {
                                rules: [{ required: true, message: '请选择所属机构' }],
                            })(
                                <Select
                                    style={{ width: 330 }}

                                    placeholder="请选择所属机构"

                                    onChange={this.suo_shu_onChange}

                                >
                                    {
                                        this.state.Bang_suo.map((item, index) => {
                                            return <Option key={item.id}>
                                                {item.enterpriseName}
                                            </Option>
                                        })
                                    }
                                </Select>,

                            )}

                        </Form.Item>


                        <Form.Item label="设备型号">

                            {getFieldDecorator('equipmentTypeId', {

                                rules: [{ required: true, message: '请选择设备型号' }],



                            })(

                                <Select
                                    style={{ width: 330 }}
                                    placeholder="请选择设备型号"
                                // onChange={this.she_bei_onChange}
                                >

                                    {
                                        this.state.ShebeiXinghao.map((item, index) => {
                                            return <Option key={item.equipmentTypeId}>
                                                {item.equipmentName}
                                            </Option>
                                        })
                                    }

                                </Select>,

                            )}

                        </Form.Item>

                        <Form.Item label="所在校区">
                            {getFieldDecorator('isBanding1', {
                                rules: [{ required: false, message: '请选择所在校区' }],

                            })(

                                <Radio.Group onChange={this.Radio_onChange} value={this.state.value}
                                >

                                    <Radio value={0}>无</Radio>

                                    <Radio value={1}>有</Radio>



                                </Radio.Group>





                            )}

                        </Form.Item>
                        {
                            this.state.isshow && <>
                                {



                                    this.props.form.getFieldValue('isBanding1') !== 0 ? <Form.Item label="所在校区">

                                        {getFieldDecorator('campusId1', {

                                            rules: [{ message: '请选择所在校区' }],

                                        })(

                                            <Select
                                                style={{ width: 330, }}
                                                placeholder="请选择所在校区"
                                                onChange={this.tan_suo_zai_onChange}

                                            >

                                                {
                                                    Array.isArray(this.state.Suo_shu_Ji_Gou) && this.state.Suo_shu_Ji_Gou.map((item, index) => {


                                                        return <Option key={item.campusId}>
                                                            {item.campusName}

                                                        </Option>
                                                    })
                                                }

                                            </Select>,

                                        )}



                                    </Form.Item>

                                        : null



                                }

                                {

                                    this.props.form.getFieldValue('isBanding1') !== 0 ? <Form.Item label="所在场地">



                                        {getFieldDecorator('placeId1', {



                                            rules: [{ required: false, message: '请选择所在场地' }],

                                        })(



                                            <Select
                                                style={{ width: 330 }}
                                                placeholder="请选择所在场地"
                                                onChange={this.tan_suo_chang_onChange}

                                            >
                                                {
                                                    this.state._tan_Changdi.map((item, index) => {
                                                        console.log(item, index)
                                                        return <Option key={item.placeId}>
                                                            {item.placeName}
                                                        </Option>
                                                    })
                                                }

                                            </Select>,

                                        )}

                                    </Form.Item> : null

                                }



                                {



                                    this.props.form.getFieldValue('isBanding1') !== 0 ? <Form.Item label="实训教室">

                                        {getFieldDecorator('classroomId', {

                                            rules: [{ required: false, message: '请选择实训教室' }],

                                        })(

                                            <Select

                                                style={{ width: 330 }}

                                                placeholder="请选择实训教室"

                                                onChange={this._tan_shi_xun_onChange}

                                            >


                                                {
                                                    this.state._tan_Suo_xun.map((item, index) => {
                                                        return <Option key={item.classroomId}>
                                                            {item.classroomName}
                                                        </Option>
                                                    })
                                                }

                                            </Select>,



                                        )}

                                    </Form.Item> : null



                                }


                            </>
                        }
                        {formItems}

                        <Form.Item className='notafter' label={<span style={{ color: 'transparent' }}></span>} {...formItemLayoutWithOutLabel}>

                            <div onClick={this.add} style={{ display: 'flex', cursor: 'pointer' }}>

                                <span style={{ lineHeight: '44px' }}><Jia /></span>

                                <span style={{ marginLeft: 10, color: '#3E78ED' }}>添加设备号</span>

                                <span style={{ marginLeft: 10, color: '#888888' }}>可添加1-10个设备号</span>

                            </div>



                        </Form.Item>



                    </Form>

                </Modal>





            </div>
        )
    }
}

export default Form.create()(index);
