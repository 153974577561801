import { result } from './result'

export default [
    {
        name: '圈子管理',
        path: '/main/circle-manage',
        component: result['Circle-manage'],
        children: [
            {
                path: '/main/circle-manage',
                redirect: '/main/circle-manage/manage',

            },
            {
                name: '圈子管理',
                path: '/main/circle-manage/manage',
                component: result['Manage']
            },
            {
                name: '话题审核管理',
                path: '/main/circle-manage/topic-audit',
                component: result['Topic-audit']
            },
            {
                name: '话题举报管理',
                path: '/main/circle-manage/topic-report',
                component: result['Topic-report']
            },

            {
                name: '评论举报管理',
                path: '/main/circle-manage/comment-report',
                component: result['Comment-report']
            },
            {
                name: '人员黑名单',
                path: '/main/circle-manage/blacklist',
                component: result['Blacklist']
            },


            {
                name: "评论详情页test",
                path: "/main/circle-manage/test-detail",
                component: result['Test-detail'],
            },
            {
                name: "话题举报详情",
                path: "/main/circle-manage/topic-report-detail/:id",
                component: result['Topic-report-detail'],
            },
            {
                name: "话题审核详情",
                path: "/main/circle-manage/topic-audit-detail/:id",
                component: result['Topic-audit-detail'],
            },



            // {
            //     name: "评论详情页test",
            //     path: "/main/circle-manage/test-detail",
            //     component: result['Test-detail'],
            // },
            // {
            //     name: "话题举报详情",
            //     path: "/main/circle-manage/topic-report-detail/:id",
            //     component: result['Topic-report-detail'],
            // },
            // {
            //     name: "话题审核详情",
            //     path: "/main/circle-manage/topic-audit-detail/:id",
            //     component: result['Topic-audit-detail'],
            // },

        ]
    },

]