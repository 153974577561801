import React, { Component } from 'react'
import './index.scss';
import { Radio, Tree, Button, message } from 'antd';
import { bindRole, getUserDetail, jurisdictionList, roleMenuId, roleSel, getSystemList } from '@/api/member/index.js';
let { TreeNode } = Tree;



export default class index extends Component {
    state = {
        data: sessionStorage['roleDistribution'] ? JSON.parse(sessionStorage['roleDistribution']) : {},
        item: {},
        treeList: [],
        platform: '',
        checkArr: null,
        roleSelList: [],
        systemList: [],
        roleId: ''
    }
    getRoleSel = () => {
        roleSel().then(res => {
            console.log(res);
            if (res) {
                this.setState({ 
                    roleSelList: res,
                    roleId:res&&res.length&&res[0].id
                })
            }
        })
    }
    componentDidMount() {
        this.getRoleSel();
        this.getSystemList();
    }
    //获取系统列表
    getSystemList = () => {
        getSystemList().then(res => {
            if (res) {
                this.setState({
                    systemList: res,
                    platform: res[0] ? res[0].platform : ''
                }, () => {
                    this.getUserDetail();
                });
            }
        })
    }
    //菜单
    jurisdictionList = () => {
        let { platform } = this.state;
        if (!platform) {
            return;
        }
        jurisdictionList({
            platform
        }).then(res => {
            if (res) {
                this.setState({ treeList: res }, () => {
                    this.roleMenuId(platform)
                });
            }
        })
    }
    //详情
    getUserDetail = () => {
        getUserDetail({
            id: this.state.data.id
        }).then(res => {
            if (res) {
                this.setState({ item: res, roleId: res.roleId }, () => {
                    this.jurisdictionList();
                });
            }
        })
    }
    //递归方法遍历树形控件
    renderTree = (data, idx) => {
        return data.map(item => {
            if (!item.childList) {
                return (
                    <TreeNode title={item.menuName} key={item.id} />
                )
            } else {
                return (
                    <TreeNode title={item.menuName} key={item.id}>
                        {this.renderTree(item.childList)}
                    </TreeNode>
                )
            }
        })
    };
    save = () => {
        bindRole(
            {
                roleId: this.state.roleId,
                userName: this.state.item.userName
            }
        ).then(res => {
            if (res) {
                message.success('保存成功');
                this.props.history.push('/main/capacity-auth/member');
            }
        })
    }
    roleMenuId = (key) => {
        roleMenuId({
            roleId: this.state.item.roleId
        }).then(res => {
            if (res[key]) {
                this.setState({ checkArr: res[key] });
            } else {
                this.setState({ checkArr: [] });
            }
        })
    }
    onCheck = arr => {
        this.setState({ jgSel: arr });
    }
    groupChange = e => {
        this.setState({
            platform: e.target.value,
            checkArr: null
        }, () => {
            this.jurisdictionList();
        })
    }
    changeRole = e => {
        this.setState({ roleId: e.target.value });
    }
    render() {
        let { roleSelList, item, checkArr, treeList, platform, roleId, systemList } = this.state;
        return (
            <div className='roleDistribution_box'>
                <h2 className='title'>角色分配</h2>
                <div className="top">
                    <ul>
                        <li>
                            <span>角色姓名</span><span>{item.fullName}</span>
                        </li>
                        <li>
                            <span>角色账号</span><span>{item.userName}</span>
                        </li>
                        <li>
                            <span>手  机 号</span><span>{item.telNum}</span>
                        </li>
                    </ul>
                    <p>
                        <span style={{ marginRight: '22px' }}>角色权限</span>
                        <Radio.Group value={roleId} onChange={this.changeRole}>
                            {
                                roleSelList && roleSelList.map(v => {
                                    return <Radio value={v.id}>{v.roleName}</Radio>
                                })
                            }
                        </Radio.Group>
                    </p>
                    <ul>
                        <li>
                            <span>角色名称</span><span>{item.identityName}</span>
                        </li>
                        <li>
                            <span>角色说明</span><span>角色管理备注信息，备注信息20字以内</span>
                        </li>
                    </ul>
                </div>
                <div className="bottom">
                    <div className="title min_title" style={{ fontSize: '16px', fontWeight: 'normal' }}>已开通权限</div>
                    <div style={{ marginBottom: '25px' }}>
                        <Radio.Group onChange={this.groupChange} value={platform}>
                            {
                                systemList && systemList.map(v => <Radio key={v.id} value={v.platform}>{v.platformName}</Radio>)
                            }
                        </Radio.Group>
                    </div>
                    <div className="tree_box" style={{maxHeight:'180px',overflow:'auto'}}>
                        {
                            checkArr ?
                                <Tree
                                    checkable
                                    defaultExpandAll={true}
                                    onCheck={this.onCheck}
                                    defaultCheckedKeys={checkArr}
                                >
                                    {this.renderTree(treeList)}
                                </Tree> : null
                        }
                    </div>
                </div>
                <div className="fix_controll">
                    <Button className='add_btn_j' onClick={this.save} style={{ marginRight: '40px', width: '100px', height: '39px' }}>保存</Button>
                    <Button onClick={() => { this.props.history.goBack() }} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>
            </div>
        )
    }
}
