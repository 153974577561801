/*eslint eqeqeq: ["off", "smart"]*/
import axios from "axios";
import { getToken, setToken, removeToken } from "./token";
import { createHashHistory } from "history";
import md5 from "js-md5";
import { isEmpty } from "./isEmpty";
import messageObj from "./err_code";
import { baseURL } from "../config/index.js";
import { message, mesWarning,mesError } from "../components/global_components/confirm";
const history = createHashHistory();

let requestFlag = true;
const Http = axios.create({
    timeout: 15000005555,
    withCredentials: false,
    baseURL,
});
Http.interceptors.request.use(
    (config) => {
        let now = new Date().getTime();
        let token = getToken();
        config.headers = {
            ...config.headers,
            "z-ts": now,
            "z-sign": md5(now + ""),
        };
        token && (config.headers.token = token);
        return config;
    },
    (error) => {
        Promise.reject(error).catch((e) => { });
    }
);

Http.interceptors.response.use(
    (response) => {
        const res = response.data;
        let token = response.headers["token"];
        // mesWarning("您的登录已过期，请重新登录");
        if (!isEmpty(token)) {
            setToken(token);
        }
        if (
            res.code === 551005 ||
            res.code === 551004 ||
            res.code === 551002 ||
            res.code === 551003
        ) {
            if (requestFlag) {
                requestFlag = false;
                mesWarning("您的登录已过期，请重新登录");
                setTimeout(() => {
                    history.replace('/login')
                    removeToken()
                }, 1000);
            }
        }

        if (
            res.code === 0 ||
            res.code === 303004 ||
            (response.config && response.config.responseType === "blob")
        ) {
            if (res.result) {
                return res.result;
            } else {
                return res;
            }
        } else {

            return Promise.reject(res);
        }
    },
    (err) => {
        mesError('哎呀，有意外了，攻城狮正火速来援')
        // message({
        //     type: 'error',
        //     content: '哎呀，有意外了，攻城狮正火速来援'
        // })
    }
);
export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        Http.get(url, { params: params })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        mesWarning(messageObj[err.code])
                    } else if (
                        err.code === 551005 ||
                        err.code === 551004 ||
                        err.code === 551002 ||
                        err.code === 551003
                    ) {
                        mesError(err.message)
                        // message({
                        //     type: 'error',
                        //     content: err.message
                        // })
                        history.replace("/login");
                    }
                }
                reject(err)
            });
    });
};
export const post = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        Http.post(url, params)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        mesWarning(messageObj[err.code]);
                        return;
                    } else if (
                        err.code === 551005 ||
                        err.code === 551004 ||
                        err.code === 551002 ||
                        err.code === 551003
                    ) {
                        mesWarning(err.message);
                        history.replace("/login");
                        return;
                    }
                    mesWarning(err.message);
                }
            });
    });
};


export default Http;
