
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { MODULE_VISIBLEfn, SET_TABLE_TITLE_MODULEfn, SET_ZHANKAI_SHOUQIfn, CHECKALLfn, BATCHSUREfn, CONFIM_AND_CANCELfn } from '@store/OBS/grounding/action';
import { Button, Input, Col, Select, Modal, Dropdown, message, Menu, Form, Popconfirm, Table } from 'antd';
import { Checkbox } from 'antd';
const { Option } = Select;
const mapStateToProps = ({ GROUNDING }) => {
    return {
        mvisible: GROUNDING.mvisible,
        data: GROUNDING.data,
        selectedRowKeys: GROUNDING.selectedRowKeys
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        okAndCancel: (data) => {
            dispatch(MODULE_VISIBLEfn(data))
        },
        table_title_showModal: () => {
            dispatch(SET_TABLE_TITLE_MODULEfn())
        },
        zhankai_shouqi(data) {
            dispatch(SET_ZHANKAI_SHOUQIfn(data))
        },
        setCheckAll() {
            dispatch(CHECKALLfn())
        },
        batchSure() {
            dispatch(BATCHSUREfn())
        },
        confirmAndCancel(data) {
            dispatch(CONFIM_AND_CANCELfn(data))
        },
        addUpload(data) {
            data._this.props.history.push('/main/uploadingmodule')

        }
    };
}

class Special extends React.Component {
    render() {
        const { RenderButton } = React;
        const menu = (
            <Menu>
                <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="#">
                        共享到校本资源库
                </a>
                </Menu.Item>
                <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="#">
                        共享到平台资源库
                </a>
                </Menu.Item>

            </Menu>
        );
        const columns = [
            {
                title: '专题名称',
                dataIndex: 'name',
                key: 'name',
                render: () => {
                    return <div className='nth_ch1'>
                        <div className="left">
                            <img width="95px" style={{ display: "block" }} height="90px" src={require('../../assets/img/logo.png')} alt="" />
                        </div>
                        <div className="right">
                            <div className="item_h">
                                北京召开疫情防控第110场例行新闻发布会
                           </div>
                            <div className="item_btn">
                                <span>大幅度的</span>  <span>大幅度的</span>  <span>大幅度的</span>
                            </div>
                            <div className="item_desc">
                                <span>大幅度的：</span> 北京召开疫情防控第110场例行新闻发布会
                           </div>
                            <div className="item_mo">
                                <div>
                                    <span>资源类型：</span> 北京召开
                              </div>
                                <div>
                                    <span>作者：</span> 北京召
                              </div>
                                <div>
                                    <span>共享时间：</span> 1111-23333--3444
                              </div>
                            </div>
                        </div>
                    </div>
                }

            },
            {
                title: () => {
                    return <div id='action_btn' className="action_btn" style={{ position: "relative" }} >
                        <Popconfirm icon='' placement="bottom" title={
                            <>
                                <Checkbox >Checkbox</Checkbox>
                                <Checkbox >Checkbox</Checkbox>
                                <Checkbox >Checkbox</Checkbox>
                            </>
                        }
                            onCancel={() => this.props.confirmAndCancel('cancel')} onConfirm={() => this.props.confirmAndCancel('ok')} okText="确定" cancelText="重置">
                            <span onClick={() => this.props.table_title_showModal()} style={{ color: "#3E78ED", width: '160px' }}>审核状态 ^</span>
                        </Popconfirm>
                    </div>
                },
                dataIndex: 'age',
                key: 'age',
                width: 160,
                render: () => {
                    return <div>
                        <span onClick={() => this.props.okAndCancel('build_module')}>· 审批未通过 > </span>
                        {/* <span>·待审核  > </span>
                        <span>·未共享 </span> */}
                    </div>
                }

            }, {
                title: '',
                dataIndex: '',
                width: 100
            },
            {
                title: '操作',
                dataIndex: 'address',
                width: 200,
                render: () => {
                    return <div className="action_btn">
                        <RenderButton
                            text='查看'
                            id="OBS-RC-01-12-b_btnRecord"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                        >
                            <span>查看</span>
                        </RenderButton>
                        <RenderButton
                            text='编辑'
                            id="OBS-RC-01-12-b_btnEdit"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                        >
                            <span>编辑</span>
                        </RenderButton>
                        <RenderButton
                            text='删除'
                            id="OBS-RC-01-12-b_btnDel"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                        >
                            <span>删除</span>
                        </RenderButton>
                        <Dropdown overlay={menu}>
                            <RenderButton
                                text='共享^'
                                id="OBS-RC-01-12-b_btnShare"
                            // type='table_btn'
                            // event={{
                            //     onClick: () => {
                            //         console.log('click')
                            //     },
                            //     onMouseOver:()=>{
                            //         console.log(222)
                            //     }
                            // }}
                            >
                                <a className="ant-dropdown-link" style={{ color: "#3E78ED" }} onClick={e => e.preventDefault()}>
                                    共享^
                                </a>
                            </RenderButton>
                        </Dropdown></div>
                }
            },
        ];

        return (
            <div className='grounding'>
                <div className='bg_fff m20 p20' style={{ position: 'relative' }}>
                    <div className="navtop bg_fff" >
                        <div className='left' >
                            资源分类
                        </div>
                        <div className='left_border'> | </div>
                        <div className="center">
                            <span>啊哈哈哈</span>  <span>资源啊哈哈哈分类</span> <span>啊哈哈哈</span> <span>啊哈哈哈</span> <span>啊哈哈哈</span> <span>啊哈哈哈</span> <span>啊哈哈哈</span> <span>资啊哈哈哈源分类</span>  <span>啊哈哈哈</span> <span>资啊哈哈哈源分类</span>  <span>啊哈哈哈</span> <span>资啊哈哈哈源分类</span>  <span>啊哈哈哈</span> <span>啊哈哈哈</span> <span>啊哈哈哈</span> <span>啊哈哈哈</span>
                        </div>
                        <div className='right'>
                            <Button type='primary' className='search'>查询</Button>
                            <Button className='reset_btn'>重置</Button>
                            <span style={{ display: 'inline-block', width: "50px" }} onClick={(e) => {
                                this.props.zhankai_shouqi({ _this: this, e })
                            }}>展开 ★</span>
                        </div>
                    </div>
                    <div className="input_wrap1 none" ref={(e) => this.input_wrap1 = e} >
                        <div className='input_wrap1_left' >
                            其他分类
                        </div>
                        <div className="input_wrap1_center">
                            <Select className='select_c' defaultValue={2}>
                                <Option value={2}>请选择分类方式（高职/专业）</Option>
                            </Select>
                            <Select className='select_c' defaultValue={2}>
                                <Option value={2}>请选择专业大类</Option>
                            </Select>
                            <Select className='select_c' defaultValue={2}>
                                <Option value={2}>请选择专业</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="input_wrap2 none" ref={(e) => this.input_wrap2 = e} >
                        <div className='input_wrap2_left' >
                            资源类型
                        </div>
                        <div className="input_wrap2_center">
                            <Select className='select_c' defaultValue={2}>
                                <Option value={2}>教育资讯</Option>
                            </Select>
                            <div className="text_c">
                                <span style={{ paddingRight: "10px" }}>资源格式</span>
                                <Select className='select_c' defaultValue={2}>
                                    <Option value={2}>请选择资源格式</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='special_box bg_fff p20'>

                    <div className="top" >
                        <Col span={12}>
                            <RenderButton
                                text='上传资源'
                                id="OBS-RC-01-12-b_btnUpload"
                            // type='table_btn'
                            // event={{
                            //     onClick: () => {
                            //         console.log('click')
                            //     },
                            //     onMouseOver:()=>{
                            //         console.log(222)
                            //     }
                            // }}
                            >
                                <Button className='sure_sort' type='primary' onClick={() => this.props.addUpload({ _this: this })}>上传资源</Button>
                            </RenderButton>
                            {/*<Button className='sure_sort' type='primary' onClick={() => this.props.addUpload({ _this: this })}>上传资源</Button>*/}
                            <Button className='sure_sort' type='primary'>批量操作</Button>
                        </Col>
                        <Col className='top_r' span={12}>
                            <Input placeholder='请输入资源名称/上传人' />

                        </Col>
                    </div>
                    <div className="special_container">
                        <div style={{ position: 'relative' }}>
                            <Table rowSelection={{
                                selectedRowKeys: this.props.selectedRowKeys,
                            }}
                                columns={columns} dataSource={this.props.data} />
                            <div className="batch_box">
                                <span>
                                    <Checkbox
                                        checked={this.props.selectedRowKeys.length === this.props.data.length ? true : false}
                                        onChange={() => this.props.setCheckAll()}
                                    />
                                    <span>全选</span>
                                </span>
                                <Button className='ant_blue' onClick={this.props.batchSure} type='primary'>批量共享到资源中心</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    title="审核详情"
                    visible={this.props.mvisible}

                    onCancel={() => this.props.okAndCancel('cancel')}
                    footer={[
                        <Button key="back" onClick={() => this.props.okAndCancel('cancel')}>
                            关闭
                        </Button>,
                    ]}
                >
                    <div className="res">
                        <div className='label'>结果：</div><div className="content">未通过</div>
                    </div>
                    <div className="res">
                        <div className='label'>时间：</div><div className="content">2020-07-23 16:24</div>
                    </div>
                    <div className="res">
                        <div className='label'>审核人：</div><div className="content">zhang'san</div>
                    </div>
                    <div className="res">
                        <div className='label'>原因：</div><div className="content">现在这场疫情危机给整个世界都带来了非常沉重的影响，现在已经有超过72万的人因为新冠病毒而死亡。只不过现在就算是很多已经暂时控制住疫情的国家，也都是苦不堪言。毕竟在现在的国际环境之下，只要有一个国家没有完全消灭病毒，那么这场病毒战役就难以称得上胜利。所以现在很多国家就将疫苗当作了救命稻草，不过世卫在这个时候给人们泼了一盆凉水。表示在现在的疫情局势下，虽然疫苗的研发不断传来好消息，但实际上还是困难重重。</div>
                    </div>

                </Modal>
            </div>
        )
    }
}
Special = connect(mapStateToProps, mapDispatchToProps)(Special);
Special = Form.create()(Special)
export default Special;