import React, { Component } from 'react';

class Contentlist extends Component {
    render() {
        return (
            <div>
               Content-listContent-listContent-listContent-listContent-listContent-listContent-listContent-listContent-list
            </div>
        );
    }
}

export default Contentlist;
