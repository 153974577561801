import React, { Component, useEffect, useState } from "react";
import { Button, Input, Modal, DatePicker, Checkbox } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import { configApplicationDetail, configApplication } from '../../api/school_settlement';

import style from './index.module.scss';

const that = new Component();
const format = 'YYYY-MM-DD'
function nextBtnDis(data) {
    let flag = true;
    data.forEach(item => {
        if (item.checked) {
            if (!item.startTime || !item.endTime) {
                flag = false;
                return;
            }
        }
    })
    if (data.every(item => !item.checked)) {
        flag = false;
    }
    return flag;
}

function ConfigureApplicationModal(props) {
    const { configAppVisible, onCancel, enterpriseId } = props;
    const [notConnectedList, setNotConnectedList] = useState([]); //未接入应用列表
    const [connectedList, setConnectedList] = useState([]); //yi接入应用列表
    const [configSettingVisible, setConfigSettingVisible] = useState(false);

    const getConfigApplicationDetail = async () => {
        const res = await configApplicationDetail({ enterpriseId });
        const notConnectedList = get(res, 'noAccessApplicationVos', []).map(item => ({
            appId: item.applicationId,
            name: item.applicationName,
            startTime: undefined,
            endTime: undefined,
            checked: false,
            appUrl: '',
        }))
        setNotConnectedList(notConnectedList);
        setConnectedList(get(res, 'accessApplicationVos', []));
    }
    useEffect(() => {
        configAppVisible && getConfigApplicationDetail();
    }, [configAppVisible, enterpriseId])
    const handleConfigApplication = async (accessType) => {
        let configApplicationInfoQos = notConnectedList.filter(item => item.checked);
        let pattern = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
        if (!configApplicationInfoQos.every(item => item.appUrl && pattern.test(item.appUrl))) {
            that.mesWarning("请输入正确链接地址");
            return;
        }
        configApplicationInfoQos = configApplicationInfoQos.map(({ appId, appUrl, startTime, endTime }) => ({
            appId,
            appUrl,
            startDate: moment(startTime).format('YYYY-MM-DD'),
            endDate: moment(endTime).format('YYYY-MM-DD'),
        }))
        await configApplication({
            accessType,
            configApplicationInfoQos,
            enterpriseId,
        })
        setConfigSettingVisible(false);
        that.mesSuccess("配置成功");
    }
    const handleChange = (value, key, index) => {
        const curValue = notConnectedList[index];
        if (key === 'startTime' && curValue.endTime) {
            if (moment(curValue.endTime.format(format)).diff(moment(value.format(format)), 'days') < 0) {
                that.mesWarning("开始时间不能大于结束时间");
                return;
            }
        }
        if (key === 'endTime' && curValue.startTime) {
            if (moment(value.format(format)).diff(moment(curValue.startTime.format(format)), 'days') < 0) {
                that.mesWarning("结束时间不能小于开始时间");
                return;
            }
        }
        const newData = notConnectedList.map((item, i) => {
            if (index === i) {
                item[key] = value
            }
            return item;
        })
        setNotConnectedList(newData)
    }
    const modalFooter = (
        <div>
            <Button size="large" onClick={onCancel}>取 消</Button>
            <Button
                size="large"
                style={{ marginLeft: 30, marginRight: 14 }}
                type={nextBtnDis(notConnectedList) ? "primary" : null}
                disabled={!nextBtnDis(notConnectedList)}
                onClick={() => {
                    onCancel();
                    setConfigSettingVisible(true);
                }}
            >下一步</Button>
        </div>
    )
    const configModalFooter = (
        <div>
            <Button size="large" onClick={() => setConfigSettingVisible(false)}>取 消</Button>
            <Button
                size="large"
                style={{ marginLeft: 30, borderColor: '#3E78ED', color: '#3E78ED' }}
                onClick={() => handleConfigApplication(1)}
            >试用接入</Button>
            <Button
                size="large"
                type="primary"
                style={{ marginLeft: 30, marginRight: 4 }}
                onClick={() => handleConfigApplication(0)}
            >正式接入</Button>
        </div>
    )
    return (
        <>
            <Modal
                className={style.configure_application}
                title={`配置设置`}
                visible={configAppVisible}
                width={880}
                closable={false}
                getContainer={false}
                footer={modalFooter}
                bodyStyle={{
                    height: 490,
                    overflow: 'auto'
                }}
            >
                <div className={style.connected_applications} style={{ marginBottom: 20 }}>
                    <div className={style.connected_lable}>已接入的应用</div>
                    <div className={style.connected_app}>
                        {connectedList.map((item, i) => (
                            <div key={i}>{item.applicationName}
                                {item.accessType === 1 ? (
                                    <span><svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="14" rx="2" fill="#27CDA2" />
                                        <path d="M11.36 4.14C11.15 3.76 10.67 3.18 10.25 2.76L9.74 3.07C10.16 3.51 10.62 4.11 10.82 4.51L11.36 4.14ZM5.19 4.25C4.88 3.86 4.24 3.25 3.72 2.84L3.23 3.28C3.74 3.72 4.37 4.35 4.67 4.76L5.19 4.25ZM4.62 10.09V5.76H2.54V6.47H3.91V10.04C3.91 10.47 3.62 10.74 3.43 10.86C3.57 11.01 3.75 11.32 3.81 11.51C3.97 11.32 4.23 11.15 5.92 10C5.85 9.85 5.76 9.57 5.72 9.38L4.62 10.09ZM7.52 7.55H8.45V6.86H5.79V7.55H6.83V10.05C6.37 10.17 5.95 10.28 5.61 10.36L5.81 11.06C6.64 10.81 7.74 10.5 8.78 10.19L8.68 9.53C8.29 9.64 7.89 9.75 7.52 9.85V7.55ZM11.56 5.42V4.71H9.49C9.46 4.06 9.45 3.38 9.44 2.68H8.7C8.71 3.39 8.73 4.06 8.76 4.71H5.47V5.42H8.79C8.97 9.16 9.44 11.71 10.67 11.74C11.05 11.74 11.43 11.32 11.63 9.65C11.5 9.58 11.18 9.4 11.04 9.25C10.98 10.22 10.86 10.78 10.69 10.77C10.07 10.74 9.68 8.48 9.52 5.42H11.56ZM17.42 8.01V6.35H20.11V8.01H17.42ZM14.25 8.01C14.28 7.64 14.29 7.27 14.29 6.94V6.35H16.68V8.01H14.25ZM16.68 4.05V5.65H14.29V4.05H16.68ZM20.11 5.65H17.42V4.05H20.11V5.65ZM20.84 3.34H13.56V6.94C13.56 8.33 13.46 10.09 12.36 11.33C12.52 11.42 12.82 11.67 12.93 11.82C13.69 10.97 14.03 9.83 14.18 8.72H16.68V11.67H17.42V8.72H20.11V10.75C20.11 10.94 20.04 11 19.84 11.01C19.66 11.01 18.98 11.02 18.28 10.99C18.38 11.19 18.5 11.51 18.53 11.71C19.47 11.72 20.05 11.71 20.39 11.59C20.71 11.46 20.84 11.24 20.84 10.76V3.34Z" fill="white" />
                                    </svg>
                                    </span>
                                ) : null}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={style.connected_applications}>
                    <div className={style.connected_lable}>未接入的应用</div>
                    <div className={style.notconnected_app}>
                        {notConnectedList.map((item, index) => (
                            <div key={index} className={style.notconnected_app_item}>
                                <Checkbox
                                    checked={item.checked}
                                    onChange={(e) => handleChange(e.target.checked, 'checked', index)}
                                    style={{ display: 'flex', alignItems: 'center', marginRight: 30, paddingLeft: 12 }}
                                >
                                    <span className={style.checkbox_text} style={item.checked ? { color: '#3E78ED' } : {}}>{item.name}</span>
                                </Checkbox>
                                <DatePicker
                                    disabled={!item.checked}
                                    placeholder="请选择开始时间"
                                    value={item.startTime}
                                    onChange={(e) => handleChange(e, 'startTime', index)}
                                    style={{ width: 230 }}
                                    getCalendarContainer={triggerNode => triggerNode.parentElement}
                                />
                                <span style={{ padding: '0 20px' }}>至</span>
                                <DatePicker
                                    disabled={!item.checked}
                                    placeholder="请选择结束时间"
                                    value={item.endTime}
                                    onChange={(e) => handleChange(e, 'endTime', index)}
                                    style={{ width: 230 }}
                                    getCalendarContainer={triggerNode => triggerNode.parentElement}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                title={`配置设置`}
                visible={configSettingVisible}
                width={660}
                closable={false}
                getContainer={false}
                footer={configModalFooter}
            >
                <h1 style={{ fontSize: 14, marginBottom: 20 }}>设置应用访问链接地址</h1>
                {
                    notConnectedList.map((item, index) => {
                        if (!item.checked) return null;
                        return (
                            <div key={index} style={{ marginBottom: 20 }}>
                                <span
                                    style={{ marginRight: 20, width: 100, textAlign: 'right', display: 'inline-block' }}
                                ><span style={{ color: '#FF3000', marginRight: 3 }}>*</span>{item.name}</span>
                                <Input
                                    style={{ width: 330 }}
                                    size="large"
                                    placeholder="请输入链接地址"
                                    value={item.appUrl}
                                    onChange={(e) => handleChange(e.target.value, 'appUrl', index)}
                                />
                            </div>
                        )
                    })
                }
            </Modal>
        </>
    )
}
export default ConfigureApplicationModal