import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { AAA } from '@store/OBS/check/action';
import './index.scss';
import { Button, Table, Input, Col, Checkbox, Tag, Form, DatePicker, message, Select, Modal, } from 'antd';
import kindName from 'classnames';
import MyTable from '@/components/Table';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { 
    wholeCircleList, 
    circleList,
    banCircle,
    unsealCircle,
    deleteCircle, 
    closedCircle, 
    hasAuditList, 
    noAuditList,
    showTopicDetail,
    auditPass,
    auditRefuse, 
    blackList, 
    orgList, 
} from '@/api/circle/index'

import moment from 'moment';
import ReportReason from './components/index'
const { Option } = Select
const { RangePicker } = DatePicker;
const { Column } = Table;

const mapStateToProps = ({ CHECK }) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


class Manage extends React.Component {
    state = {
        _circleName: '',     //圈子名称
        _orgid: '',          //所属机构
        _startTime: '',      //开始时间
        _endTime: '',        //结束时间
        btnDisabled: true,  //重置按钮

        _wholeCircleNumber: '',  // 全部圈子
        _circleNumber: '',
        _closeCircleNumber: '',   //封禁圈子 
        informationList: [],        //表格列表
        _wholeCircleListData: [],
        _orgList: [],    //机构列表

        _circleListData: [],         //全部圈子列表
        _closedCircleData: [],       //被封禁的圈子列表
        _closedCircleNumber: '',
        _openRange: '',              //圈子公开范围:0平台公开，1机构公开

        _coverUrl: '',               //图片地址

        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            //tipOffType: undefined, //举报类型
            //lastProcessType: undefined, //最后处理方式
            //searchValue: undefined,  //检索条件
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        },
        processLists: [],
        dateString: '',
        entity: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        reason: '',  //资源冻结原因
        reasonNum: 0,  //资源冻结原因字数
        ids: [],
        offLists: [],  //资源举报列表
        businessId: '',  //业务id
        dataSource: [],
        startColumns: [
            
            {title: '', width:0,dataIndex: '', key: ''},
            {
                title: '圈子封面', dataIndex: 'coverUrl', key: 'coverUrl',

                render: (src) => {
                    // { console.log(src, ':::::;') }
                    return (

                        <img
                            alt=""
                            width='46px'
                            height='46px'
                            style={{ borderRadius: '50%' }}
                            src={src}

                        />
                    );
                }
            },
            { title: '圈子名称', dataIndex: 'circleName', key: 'circleName', align: 'center', },
            { title: '圈子ID', dataIndex: 'circleId', key: 'circleId', align: 'center', width: 240, },
            { title: '圈主', dataIndex: 'circleOwner', key: 'circleOwner', align: 'center', },
            { title: '所在院校', dataIndex: 'orgId', key: 'orgId', align: 'center' },

            { title: '创建日期', dataIndex: 'createTime', key: 'createTime', align: 'center' },
            { title: '公开范围', dataIndex: 'openRange', key: 'openRange', align: 'center' },
            { title: '成员量', dataIndex: 'memberNum', key: 'memberNum', align: 'center' },
            { title: '浏览量', dataIndex: 'browseNum', key: 'browseNum', align: 'center' },
            { title: '话题数', dataIndex: 'topicNum', key: 'topicNum', align: 'center' },
            { title: '评论数', dataIndex: 'discussNum', key: 'discussNum', align: 'center' },

        ],
        endColumns: [

            {
                title: '操作', dataIndex: 'id', key: 'id', fixed: 'right', align: 'center',
                width: 240,
                render: (text, record) => <div className='action vertical_j'>
                    <p>
                        {/* <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
                        {this.state.activeIndex === 1 ? <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span> : ""}
                        {
                            this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
                              <span onClick={() => this.reasonShow(record)}>冻结</span> : ''
                        } */}
                        
                        {/* <span onClick={() => window.open('http://www.baidu.com')}>进入圈子</span> */}
                        <span>进入圈子</span>

                        <span onClick={()=>{this.handlebanCircle(record)}}>封禁圈子</span>
                        <span onClick={()=>{this.handledeleteCircle(record)}}>解散圈子</span>
                    </p>
                </div>
            }
        ],
        endColumns02: [

            {
                title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                width: 240,
                render: (text, record) => <div className='action vertical_j'>
                    <p>
                        {/* <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
                        {this.state.activeIndex === 1 ? <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span> : ""}
                        {
                            this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
                              <span onClick={() => this.reasonShow(record)}>冻结</span> : ''
                        } */}
                        {/* <span onClick={() => window.open('http://www.baidu.com')}>进入圈子</span> */}
                        <span >进入圈子</span>
                        <span onClick={()=>{this.handleunsealCircle(record)}}>解封圈子</span>
                        <span onClick={()=>{this.handledeleteCircle(record)}}>解散圈子</span>
                    </p>
                </div>
            }
        ],
        columns: [],
        reportTypeData: [],
        reportTypeMap: {},
        processModeData: [],
        processModeMap: {}
    }
    componentDidMount() {
        //this.wholeCircleList();     //全部圈子列表01
        this.circleList();          //全部圈子列表
        this.closedCircle();

        //this.getTipOffType()
        //this.getLastProcessType()
        this.initColumns()
        this.getList()
        this.orgList()
    }

    //机构列表
    async orgList() {
        const orgListData = await orgList();
        let orgListMap = {};

        // console.log(orgListData, "--------------");
        // //
        if (orgListData) {   //&& orgListData.result.length 判断有效防止页面报错 没有数据的时候不渲染
            this.setState({
                _orgList: orgListData,
            });
        }

    }

    //全部的圈子列表01
    async wholeCircleList() {
        const wholeCircleListData = await wholeCircleList();
        let wholeCircleListMap = {};
        ////
        // console.log(wholeCircleListData, "--------------");
        // wholeCircleListData.forEach(item => {
        //     wholeCircleListMap[item.tipOffType] = item.tipOffTypeName
        // })
        // this.setState({
        //     reportTypeData,
        //     reportTypeMap
        // })
        if (wholeCircleListData && wholeCircleListData.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            this.setState({
                informationList: wholeCircleListData.data,
                _wholeCircleListData: wholeCircleListData.data,
                _wholeCircleNumber: wholeCircleListData.total,

                //total: wholeCircleListData.total,
                //pageSize: wholeCircleListData.pageSize,
                //pageNum: wholeCircleListData.pageNum,
            });
        }

    }

    //平台全部的圈子列表
    async circleList() {
        const circleListData = await circleList();
        let circleListMap = {}
        

        if (circleListData && circleListData.data.length) {
            this.setState({
                _circleListData: circleListData.data,
                _circleNumber: circleListData.total,

            });
        }

    }

    //被封禁的圈子列表
    async closedCircle() {
        const closedCircleData = await closedCircle();
        let closedCircleMap = {}
        

        if (closedCircleData && closedCircleData.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            this.setState({
                _closedCircleData: closedCircleData.data,
                _closedCircleNumber: closedCircleData.total == null ? '' : closedCircleData.total,
            });
        }

    }

     

    //查询start

    //搜索圈子名称
    circleNameChange = ({ target: { value } }) => {
        this.setState({

            _circleName: value
        });
    };

    //检索重置
    resetBtn = () => {
        this.setState({
            btnDisabled: true,
            name: '',
            // subList: [],
            // subHotSpecialList: [],
            // reportTime: [],
            // resouceCategorySeniorId: undefined,
            // resouceCategorySubId: undefined,
        }, () => this.initColumns());
    }
    //列表查询
    async getResourceList() {
        const { _circleName, _orgid, _openRange, _startTime, _endTime, activeIndex, pagination, } = this.state

        if (activeIndex === 0) {

            const result = await circleList({

                "searchName": _circleName,  //circleName改为searchName
                "orgid": _orgid,
                "openRange": _openRange,
                "startTime": _startTime,
                "endTime": _endTime,
                //"pageNum": pagination.current,
                //"pageSize": pagination.pageSize,

            });
            if (result) {
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,
                        pageSize: pagination.pageSize,
                        total: result.total
                    }
                })
            }

        } else {

            const result = await closedCircle({

                "searchName": _circleName,
                "orgid": _orgid,
                "openRange": _openRange,
                "startTime": _startTime,
                "endTime": _endTime,
               // "pageNum": pagination.current,
                //"pageSize": pagination.pageSize,

            });
            if (result) {
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,
                        pageSize: pagination.pageSize,
                        total: result.total
                    }
                })
            }

        }

        // pagination.total = result.total
        // this.setState({
        //     dataSource: result.data,
        //     pagination: pagination
        // })
    }
    //检索查询
    queryBtn = () => {
        this.getResourceList()
    }

    //日期查询
    reportTimeChange = (value, dateString) => {
        let { pagination } = this.state;
        pagination.processTime = value
        pagination.processStartTime = dateString[0]
        pagination.processEndTime = dateString[1]
        this.setState({
            pagination,
            btnDisabled: false
        })
    }
    //查询end

    initColumns() {
        const { activeIndex, startColumns, endColumns,endColumns02 } = this.state
        let column = []
        if (activeIndex === 0) {
            //column = [{ title: '资源分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center' },
            //{ title: '新举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber1', align: 'center' }]
            this.setState({
                columns: startColumns.concat(column).concat(endColumns)
            })
        } else {
            // column = [{
            //     title: '最后处理方式', dataIndex: 'lastProcessType', key: 'lastProcessType', align: 'center',
            //     render: (text, record) => this.state.processModeMap[text]
            // },
            // { title: '处理时总举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber2', align: 'center' }]
            this.setState({
                columns: startColumns.concat(column).concat(endColumns02)
            })
        }

    }
    //切换获取数据
    async getList() {
        const { activeIndex, pagination } = this.state;
        if (activeIndex === 0) {
            const result = await circleList({
                "pageNum": pagination.pageNum,
                "pageSize": pagination.pageSize,
            });
            if (result) {
                
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,    //这里需要传页码数据否则点击不跳页
                        pageSize: pagination.pageSize,
                        //processEndTime: undefined,
                        //processStartTime: undefined,
                        total: result.total
                    }
                })
            }
        } else {

            const result = await closedCircle({
                "pageNum": pagination.pageNum,
                "pageSize": pagination.pageSize,
            });
            if (result) {
                this.setState({
                    dataSource: result.data,
                    pagination: {
                        pageNum: pagination.pageNum,
                        pageSize: pagination.pageSize,
                        //processEndTime: undefined,
                        //processStartTime: undefined,
                        total: result.total
                    }
                })
            }
            //console.log(this.state.dataSource,'\\\\\\\\\\\\\\');

        }
    }

    //分页
    pageChange = (pageNumber) => {
        //
        let { pagination } = this.state;
        pagination.pageNum = pageNumber;
        console.log(pagination.pageNum,"===========")
        //this.setState({pagination: pagination}, () => this.getList())
        this.setState({ pagination: this.state.pagination },() => this.getList())
        console.log(pagination);
    }
    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let alreadyFreeze = selectedRows.filter(row => row.lastProcessType === "1")
        if (alreadyFreeze.length > 0) {
            return message.warning('不要选择已经冻结的数据');
        }
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({ selectedRowKeys, expandedRowKeys })
    }
    //搜索选项改变
    reportChange = ({ target: { value } }) => {
        let { pagination } = this.state;
        pagination.searchValue = value
        this.setState({
            pagination,
            btnDisabled: false
        });
    };
    //已完成  待完成切换
    changeTabMenu = (key) => {
        this.setState({
            selectedRowKeys: [],
            expandedRowKeys: [],
            activeIndex: key
        }, () => {
            this.initColumns()
            this.resetSearch()
            this.getList()
        })
    };

    //查询条件
    resetSearch() {
        // let {_circleName, _orgid,_openRange,_startTime,_endTime,btnDisabled} = this.state;
        let pagination = {

            // pageNum: 1,
            // pageSize: 10,
            // total: 0,
            // tipOffType: undefined, //举报类型
            // processState: 0, //处理状态
            // lastProcessType: undefined, //最后处理方式
            // searchValue: undefined,  //检索条件
            // processTime: undefined,  //时间
            // processStartTime: undefined,//推荐开始时间
            // processEndTime: undefined,//推荐结束时间

            //_circleName: '',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tipOffType: undefined, //举报类型
            processState: 0, //处理状态
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processTime: undefined,  //时间
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        };
       
        // this.setState({
        //     pagination: pagination,
        //     btnDisabled: true
        // }, () => this.getList())

        this.setState({
            _circleName: '',
            _orgid: undefined,
            _openRange: undefined,
            _startTime: undefined,
            _endTime: undefined,
            //pagination: pagination,
            btnDisabled: true
        })
        // let { pagination } = this.state;
        // pagination.pageNum = 1;
        // this.setState(() => {
        //     return {
        //         _circleName: '',
        //         _orgid: undefined,
        //         _openRange: undefined,
        //         _startTime: undefined,
        //         _endTime: undefined,
        //         btnDisabled: true,

        //     }
        // }, () => {
        //     circleList({
        //         pagination,
                
        //     })
        // })
    }

     

    checkCancel = () => {
        this.setState({
            visible: false
        })
    }
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        }, () => this.getList())
    }
    //举报类型改变
    reportTypeChange = (value) => {
        let { pagination } = this.state;
        pagination.tipOffType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //处理方式改变
    reportModeChange = (value) => {
        let { pagination } = this.state;
        pagination.lastProcessType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };


    //封禁圈子弹层
    handlebanCircle = (id) => {
        
        console.log(id)
        let that = this;
        // console.log(id.circleId)
        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '被封禁后该圈子内的所有成员将不能再发表话题，圈子内所有话题将会全部隐藏。',
            centered: true,
            okText: "确定",
            cancelText: "取消",
            className: "ModalConfirm-pic",
            okType: 'danger',
            
            onOk() {
                banCircle({"circleId":id.circleId}).then((res) => {
                    if (res.code == 0) {
                        message.success("删除成功");
                        that.circleList();
                    }
                });
            },
            onCancel() { },

        })
    };

    //解散圈子弹层
    handledeleteCircle = (id) => {

        console.log(id.circleId)
        let that = this;

        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '解散圈子后，所有数据都将不存在 ，您确定 要解散圈子吗？',
            centered: true,
            okText: '狠心解散',
            cancelText: "取消",
            className: "ModalConfirm-pic",
            okType: 'danger',
            
            //onOk: () => this.deleteCircle()
            onOk() {
                deleteCircle({"circleId":id.circleId}).then((res) => {
                    if (res.code == 0) {
                        message.success("删除成功");
                        that.circleList();
                    }
                });
            },
            onCancel() { },
        })
    };

    //解封圈子弹层
    handleunsealCircle = (id) => {

        console.log(id.circleId)
        let that = this;

        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '解封圈子后，该圈子内所有成员可以发表话题，同时恢复显示圈子内所有话题。',
            centered: true,
            okText: '确认',
            cancelText: "取消",
            className: "ModalConfirm-pic",
            okType: 'danger',
            
            //onOk: () => this.deleteCircle()
            onOk() {
                unsealCircle({"circleId":id.circleId}).then((res) => {
                    if (res.code == 0) {
                        message.success("删除成功");
                        that.circleList();
                    }
                });
            },
            onCancel() { },
        })
    };

    // //封禁圈子
    // banCircle = () => {
    //     console.log("封禁圈子");

    // }

    // //解散圈子
    // deleteCircle = () => {
    //     console.log("解散圈子")
    // }

    render() {
        const {
            _circleName,
            _orgid,
            _openRange,
            _startTime,
            _endTime,
            btnDisabled,
            _wholeCircleListData,
            _circleListData,
            _closedCircleData,
            _closedCircleNumber,

            informationList,
            _wholeCircleNumber,
            _circleNumber,
            _closeCircleNumber,
            _orgList,

            columns,
            selectedRowKeys,
            activeIndex,
            dataSource,
            visible,
            reportTypeData,
            processModeData,
            pagination,
            reason,
            reasonNum
        } = this.state;
        const formItemLayout = {
            labelCol: { span: 0 },
            wrapperCol: { span: 24 },
        };
        const { getFieldDecorator } = this.props.form;
        // console.log(this.state, 111111111111111111)
        return (
            <div className='publicstyle check_box report'>

                {/* <div className="go_detail_warp">
                    <img src={require('@/assets/img/icon_warn.svg')} alt="" />
                    <span className="mleft10  dlabel">您有需要尽快处理的举报</span>
                    
                    
                    <span class="go_detail dname"> 查看详情 &gt;</span>
                </div> */}
                <div className="p20 pright40 bg_fff m20">
                    <div className="top_01 mbottom20">
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>圈子名称</span>
                            <Input style={{ width: '80%' }} placeholder='请输入圈子名称' value={_circleName}

                                onChange={({ target: { value } }) => {
                                    this.setState({
                                        _circleName: value,
                                        btnDisabled: false
                                    });
                                }} />
                        </Col>

                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>所属机构</span>
                            <Select placeholder='请选择所属机构' style={{ width: '80%' }}

                                // onChange={({target: {value}}) => {
                                //     console.log(_orgid,'123456789-------------')
                                //     this.setState({ _orgid: e })
                                // }} 

                                onChange={(id) => {
                                    // console.log(id, '-------------')
                                    this.setState({
                                        _orgid: id,
                                        btnDisabled: false
                                    });
                                }}
                            >

                                {/* <Option value={''}>全部</Option>
                                <Option value="">机构1</Option>
                                <Option value="">机构2</Option> */}
                                {
                                    _orgList.map(v => {
                                        return <Option value={v.orgId} key={v.orgId}>{v.orgName}</Option>
                                    })
                                }
                            </Select>
                        </Col>

                        <Col span={7}>

                            <span style={{ marginRight: '20px' }}>公开范围</span>
                            <Select placeholder='请选择身份类型' style={{ width: '80%' }}
                                onChange={(o) => {
                                    // console.log(e)
                                    this.setState({
                                        _openRange: o,
                                        btnDisabled: false
                                    });
                                }}
                            // onChange= {({target: {value}}) => {
                            //     this.setState({
                            //         _openRange: value
                            //     });
                            // }}
                            >
                                <Option value={''}>全部公开</Option>
                                <Option value="0">平台公开</Option>
                                <Option value="1">机构公开</Option>
                                {
                                    // statusList.map(v => {
                                    //     return <Option value={v.code} key={v.code}>{v.name}</Option>
                                    // })
                                }
                            </Select>
                        </Col>

                    </div>

                    <div className="top_02 ">
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>创建日期</span>
                            <RangePicker
                                onChange={(e, i) => {
                                    // console.log(i[0], i[1])
                                    this.setState({
                                        _startTime: i[0],
                                        _endTime: i[1],
                                        btnDisabled: false,
                                    });
                                }}
                                //value={pagination.processTime} 
                                format="YYYY-MM-DD"
                                style={{ width: '80%', }} />
                        </Col>

                        <div className="mright20">
                            <Button className='reset_btn' onClick={() => this.resetSearch()} disabled={this.state.btnDisabled}>重置</Button>
                            <Button type='primary' className='ant_blue' style={{ marginLeft: '12px' }} onClick={() => this.queryBtn()}>查询</Button>
                        </div>

                    </div>
                </div>

                <div className="wrapper padd">
                    <div className="top m20">
                        <Col className='top_l' span={12}>
                            <div className='tabs'>
                                <Tag onClick={() => this.changeTabMenu(0)}
                                    className={kindName({ 'active': activeIndex === 0 })}>全部圈子 ({this.state._circleNumber}条)</Tag>
                                <Tag onClick={() => this.changeTabMenu(1)}
                                    className={kindName({ 'active': activeIndex === 1 })}>被封禁的圈子 ({this.state._closedCircleNumber}条)</Tag>
                            </div>
                        </Col>

                    </div>
                    <div className="check_container" style={{ position: 'relative' }}>

                        <MyTable
                            options={

                                {

                                    //dataSource:_wholeCircleListData,
                                    dataSource: this.state.dataSource,
                                    columns,
                                    // pagination: {onChange: this.pageChange, current: this.state.dataSource.pageNum, pageSize: this.state.dataSource.pageSize,total: this.state.dataSource.total},
                                    pagination: {
                                        onChange: this.pageChange,  
                                        current: pagination.pageNum, 
                                        pageSize: pagination.pageSize, 
                                        total: pagination.total 
                                    },

                                }

                            }

                            fixededit={true}
                            scroll={{ x: 1800, }}
                        />

                         
                    </div>
                     
                </div>
                <ReportReason
                    ids={this.state.ids}
                    visible={visible}
                    checkCancel={this.checkCancel.bind(this)}
                    refreshData={this.refreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                ></ReportReason>
            </div>
        )
    }
}


/*Check = connect(mapStateToProps, mapDispatchToProps)(Check);
export default Check;*/
Manage = connect(mapStateToProps, mapDispatchToProps)(Manage);
Manage = Form.create()(Manage)
export default Manage;

