import { result } from './result'
export default [
    {
        name: "资源",
        path: "/main/resource",
        component: result.Resource,
        children: [
            {
                path: '/main/resource',
                redirect: '/main/resource/special'
            },
            {
                name: '资源热门方向管理',
                path: '/main/resource/special',
                component: result.Special
            },
            {
                name: '临时资源热门方向管理',
                path: '/main/resource/temporaryProject',
                component: result.TemporaryProject
            },
            {
                name: '审核管理',
                path: '/main/resource/check',
                component: result.Check
            },
            {
                name: '推荐管理',
                path: '/main/resource/recommend',
                component: result.Recommend
            },
            {
                name: '上下架资源管理',
                path: '/main/resource/grounding',
                component: result.Grounding
            },
            {
                name: '下架资源',
                path: '/main/resource/undercarriage',
                component: result.Undercarriage
            },
            {
                name: '举报管理',
                path: '/main/resource/report',
                component: result.Report
            },
            {
                name: '添加推荐资源',
                path: '/main/resource/temporaryClassify',
                component: result.TemporaryClassify
            },
            /*   {
                   name: '资源标签管理',
                   path: '/main/resource/label',
                   component: result.Label
               },
               {
                   name: '审核设置',
                   path: '/main/resource/audit',
                   component: result.Audit
               },
               {
                   name: '上传管理',
                   path: '/main/resource/uploading',
                   component: result.Uploading
               },*/
        ]

    },
    {
        name: '上传资源',
        path: '/main/uploadingmodule',
        component: result.Uploadingmodule
    },
]
