


import React from 'react';


//共享中心
export default props => {
    return <svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1941 10.998H4.31629C3.63568 10.998 3.08203 10.4444 3.08203 9.76374V4.70198C3.08203 4.02142 3.63573 3.46777 4.31629 3.46777H12.1941C12.8747 3.46777 13.4284 4.02147 13.4284 4.70198V9.76374C13.4284 10.4444 12.8747 10.998 12.1941 10.998ZM4.42819 9.65184H12.0822V4.81393H4.42819V9.65184Z" fill="#6A7E9C"/>
        <path d="M2.4498 8.82734H1.6484C0.870866 8.82734 0.238281 8.19476 0.238281 7.41722V1.53518C0.238281 0.757638 0.870866 0.125 1.6484 0.125H9.80933C10.5869 0.125 11.2195 0.757638 11.2195 1.53518V2.61156H9.8733V1.53518C9.8733 1.50168 9.84282 1.47115 9.80933 1.47115H1.6484C1.61491 1.47115 1.58444 1.50168 1.58444 1.53518V7.41722C1.58444 7.45066 1.61491 7.48119 1.6484 7.48119H2.4498V8.82734Z" fill="#BABABA"/>
        <path d="M12.8345 13.1386H3.75511C3.38341 13.1386 3.08203 12.8373 3.08203 12.4656C3.08203 12.0939 3.38341 11.7925 3.75511 11.7925H12.8346C13.2063 11.7925 13.5077 12.0939 13.5077 12.4656C13.5077 12.8373 13.2063 13.1386 12.8345 13.1386Z" fill="#6A7E9C"/>
    </svg>

}