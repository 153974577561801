import React from "react";
import { Form, Row, Col, Radio, Select,Input } from "antd";
import "./index.scss";
import { withRouter } from "react-router-dom";
const FormItem = Form.Item;
const { Option } = Select;
export default Form.create()(
  withRouter(
    class BasicProperty extends React.Component {
      state = {
        isUnfold: false,
        unfoldStyle: { transform: "rotate(-90deg)" },
        majorOrSpecial: "",
      };
      componentDidMount() {
        // console.log(this.props, "`````````````````");
      }

      //显示专业或者专题
      majorOrSpecial(e) {
        if (e.target.value === 1) {
          this.setState({
            majorOrSpecial: "major",
          });
        } else if (e.target.value === 2) {
          this.setState({
            majorOrSpecial: "special",
          });
        } else {
          this.setState({
            majorOrSpecial: "",
          });
        }
      }
      render() {
        const { getFieldDecorator } = this.props.form;
        const { majorOrSpecial } = this.state;

        return (
          <div className="classification_wrap">
            <Form
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              className="upd_resource_form"
            >

              <Row className='show'>
                <Col span={2}>
                  <span className="label">
                    <span className="red">&nbsp;</span>&nbsp;
                  </span>
                </Col>
                <Col className="more_class">
                  <Radio.Group
                    onChange={(e) => {
                      this.majorOrSpecial(e);
                    }}
                  >
                    <Radio value={1}>图书</Radio>
                    <Radio value={2}>期刊</Radio>
                    <Radio value={3}>教学素材</Radio>
                    <Radio value={4}>实训案例</Radio>
                    <Radio value={5}>试卷</Radio>
                    <Radio value={6}>其他</Radio>
                  </Radio.Group>
                  <FormItem>
                    {getFieldDecorator("aaa", {
                      initialValue: "",
                      rules: [],
                    })(
                      <div className="select_wrap">
                        {majorOrSpecial === "major" ? (
                          <>
                            <Input></Input>
                            <Input></Input>
                            <Input></Input>
                          </>
                        ) : majorOrSpecial === "special" ? (
                          <>
                            <Input></Input>
                            <Input></Input>
                            <Input></Input>
                          </>
                        ) : (
                              ""
                            )}
                      </div>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
