import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import './index.scss';
import {Button, Input, Col, Checkbox, Modal, Form, message} from 'antd';
import MyTable from '@/components/Table';
import {getList, pass} from '@/api/check/index'
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
const {TextArea} = Input
const mapStateToProps = ({CHECK}) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {};
}


class Check extends React.Component {
  state = {
    saveLoading: false,
    searchValue: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "30", "100"]
    },
    formData: {},
    examineReason: null,
    examineReasonNum: 0,
    visible: false,
    opType: null, //不通过操作类型：1点击行内不通过 2批量不通过
    selectedRowKeys: [],
    activeIndex: 0,
    dataSource: [],
    columns: [
      {title: '资源名称', dataIndex: 'resourceName', key: 'resourceName'},
      {title: '作者', dataIndex: 'authorName', key: 'authorName', align: 'authorName',},
      {title: '资源类型', dataIndex: 'resourceFormName', key: 'resourceFormName', align: 'center',},
      {title: '热门分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center',},
      {
        title: '其他分类方式', dataIndex: 'classify', key: 'classify', align: 'center',
        render: (text, row, index) => {
          return row.stageCategory?row.stageName + "/" + row.medMajorName + "/" + row.bigMajorName:""
        }
      },
      {
        title: '资源格式', dataIndex: 'resourceType', key: 'resourceType', align: 'center',
        render: (text, row, index) => {
          const param = {
            1: "文档",
            2: "视频",
            3: "音频",
            4: "图片"
          }
          return param[text]
        }
      },
      {title: '上传人', dataIndex: 'createName', key: 'createName', align: 'center',},
      {title: '上传时间', dataIndex: 'createTime', key: 'createTime', align: 'center',},
      {
        title: '操作', dataIndex: 'address', key: '8', fixed: 'right', align: 'center',
        width: 220,
        render: (text, row, index) => <p className='action vertical_j'>
          <React.RenderButton
              text='查看'
              id="OBS-RC-01-03-b_btnView"
              // type='table_btn'
              // event={{
              //     onClick: () => {
              //         console.log('click')
              //     },
              //     onMouseOver:()=>{
              //         console.log(222)
              //     }
              // }}
          >
            <span onClick={() => this.props.history.push(`/transResource/check/${row.id}`)}>查看</span>
          </React.RenderButton>
          <React.RenderButton
              text='通过'
              id="OBS-RC-01-03-b_btnPass"
              // type='table_btn'
              // event={{
              //     onClick: () => {
              //         console.log('click')
              //     },
              //     onMouseOver:()=>{
              //         console.log(222)
              //     }
              // }}
          >
            <span onClick={() => this.checkPass(row)}>通过</span>
          </React.RenderButton>
          <React.RenderButton
              text='不通过'
              id="OBS-RC-01-03-b_btnNotPass"
              // type='table_btn'
              // event={{
              //     onClick: () => {
              //         console.log('click')
              //     },
              //     onMouseOver:()=>{
              //         console.log(222)
              //     }
              // }}
          >
            <span onClick={() => this.examineReasonShow(row)}>不通过</span>
          </React.RenderButton>
        </p>
      }
    ]
  }

  componentDidMount() {
    this.getCheckList()
  }

  //检索查询
  queryBtn = () => {
    let  pagination=this.state.pagination;
    pagination.current=1
    this.setState({
      pagination:pagination
    }, () => this.getCheckList());
  }

  async getCheckList() {
    const {pagination, searchValue} = this.state
    const res = await getList({
      "searchValue": searchValue,
      "pageNum": pagination.current,
      "pageSize": pagination.pageSize,
    })
    pagination.total = res.total
    this.setState({
      dataSource: res.data,
      pagination
    },()=>{
      window.elGoTop && window.elGoTop('body');
    })
  }

  //分页
  pageChange = (pageNumber) => {
    const {pagination} = this.state;
    pagination.current = pageNumber;
    this.getCheckList()
    this.setState({pagination})
  }
  //pageSize变化
  onShowSizeChange = (current, pageSize) => {
    const {pagination} = this.state;
    pagination.current = 1;
    pagination.pageSize = pageSize;
    this.setState({pagination}, () => this.getCheckList())
  };
  //选择行
  selectedRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({selectedRowKeys})
  }
  //全选 反选
  checkedAll = () => {
    let {dataSource, selectedRowKeys} = this.state;

    if (selectedRowKeys.length === dataSource.length) {

      this.setState({selectedRowKeys: []});
      return;
    }
    this.setState({selectedRowKeys: dataSource.map(v => v.id)}, () => {
    });
  }
  //批量通过
  checkPassBatch = async () => {
    let {selectedRowKeys} = this.state;
    if (!selectedRowKeys.length) {
      message.warning('请选择资源');
      return;
    }
    const param = {
      ids: selectedRowKeys,
      examineStatus: 1
    }
    await pass(param)
    message.success('审核成功');
    this.setState({
      selectedRowKeys: []
    })
    this.getCheckList()
  }
  //批量不通过
  checkNotPassBatch = async () => {
    const {selectedRowKeys, examineReason} = this.state;
    if (!selectedRowKeys.length) {
      message.warning('请选择资源');
      return;
    }
    const param = {
      ids: selectedRowKeys,
      examineStatus: 2,
      examineReason: examineReason
    }
    await pass(param)
    message.success('审核成功');
    this.setState({
      visible: false,
      saveLoading: false,
      examineReason: null,
      selectedRowKeys: []
    })
    this.getCheckList()
  }

  async checkPass(row) {
    const param = {
      ids: [row.id],
      examineStatus: 1
    }
    await pass(param)
    message.success('审核成功');
    this.getCheckList()
  }

  examineReasonChange(e) {
    const value = e.target.value
    this.setState({
      examineReason: value,
      examineReasonNum: value.length
    })
  }

  examineReasonShow = (row) => {
    this.setState({
      opType: 1,
      formData: row,
      visible: !this.state.visible
    })
  }
  examineReasonBatchShow = () => {
    if (!this.state.selectedRowKeys.length) {
      message.warning('请选择资源');
      return;
    }
    this.setState({
      opType: 2,
      formData: {},
      visible: !this.state.visible
    })
  }
  checkNotPass = async () => {
    const {formData, examineReason} = this.state
    const param = {
      ids: [formData.id],
      examineStatus: 2,
      examineReason: examineReason
    }
    await pass(param)
    message.success('审核成功');
    this.setState({
      visible: false,
      saveLoading: false,
      examineReason: null
    })
    this.getCheckList()
  }
  checkCancel = () => {
    this.setState({
      examineReason: '',
      visible: false
    })
  }
  selectType() {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          saveLoading: true
        })
        const {opType} = this.state
        if(opType === 1){
          this.checkNotPass()
        }else{
          this.checkNotPassBatch()
        }
      }
    })
  }
  searchValueChange(e) {
    this.setState({
      searchValue: e.target.value
    })
  }
  emptySearchValue(){
    this.setState({
      searchValue: null
    }, () => this.getCheckList())
  }

  render() {
    const {columns, selectedRowKeys, pagination, dataSource, visible,searchValue, examineReason, examineReasonNum, saveLoading} = this.state;
    const formItemLayout = {
      labelCol: {span: 0},
      wrapperCol: {span: 24},
    };
    const {getFieldDecorator} = this.props.form;
    const sp = <span>0/15</span>;
    return (
      <div className='publicstyle check_box'>
        <div className="wrapper padd">
          {/* <div className='tabs'>
                        <Tag onClick={() => this.callBack(0)} className={kindName({ 'active': activeIndex == 0 })}>专题资源（20）</Tag>
                        <Tag onClick={() => this.callBack(1)} className={kindName({ 'active': activeIndex == 1 })}>专业资源（20）</Tag>
                    </div> */}
          <div className="top m20">
            <Col className='top_l btn_box' span={12}>
              <Input placeholder='输入资源名称/上传人' value={searchValue} onChange={this.searchValueChange.bind(this)} onPressEnter={() => this.queryBtn()}/>
              <Button className='reset_btn' onClick={() => this.emptySearchValue()} disabled={!searchValue}>重置</Button>
              {/*<Button type='primary' className='search' onClick={() => this.getCheckList()}>查询</Button>*/}
            </Col>
          </div>
          <div className="check_container" style={{position: 'relative'}}>
            <DndProvider backend={HTML5Backend}>
              <MyTable
                options={
                  {
                    dataSource,
                    columns,
                    rowSelection: {
                      selectedRowKeys,
                      // ...rowSelection
                      onChange: this.selectedRowChange,
                    },
                    pagination: {onChange: this.pageChange, ...pagination,onShowSizeChange: this.onShowSizeChange}
                  }
                }
                isDrab={false}
                fixededit={true}
              />
            </DndProvider>
            <div className="Grounding_position">
                            <label>
                                <Checkbox
                                  checked={selectedRowKeys.length === dataSource.length ? true : false}
                                  onChange={this.checkedAll}
                                />
                                <span className="Grounding_select">全选</span>
                            </label>
              <Button className='ant_blue' onClick={this.checkPassBatch} type='primary'>批量通过</Button>
              <Button className='ant_share' type='primary' onClick={() => this.examineReasonBatchShow()}>批量不通过</Button>
            </div>
          </div>
        </div>
        <Modal
          title='不通过理由'
          width={'660px'}
          wrapClassName='modal_box'
          visible={visible}
          closable={false}
          footer={
            <div className='modal_btn_j'>
              <Button onClick={() => this.checkCancel()}>取消</Button>
              <Button loading={saveLoading} onClick={() => this.selectType()} type='primary'>保存</Button>
            </div>
          }
        >
          <Form {...formItemLayout}>
            <Form.Item>
              {
                getFieldDecorator('examineReason', {
                  rules: [{required: true, message: '请输入不通过理由'},
                    {max: 100, message: '不通过理由不能超过100字'}]
                })(<div className="sold-box">
                  <TextArea value={examineReason} onChange={this.examineReasonChange.bind(this)} placeholder='请输入不通过理由'
                            autoSize={{minRows: 3, maxRows: 5}}/>
                  <span className="suffixStyle">{examineReasonNum}/100</span>
                </div>)
              }
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}


/*Check = connect(mapStateToProps, mapDispatchToProps)(Check);
export default Check;*/
Check = connect(mapStateToProps, mapDispatchToProps)(Check);
Check = Form.create()(Check)
export default Check;
