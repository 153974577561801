/* eslint-disable no-useless-constructor */
import React, { Component } from 'react'
import './index.scss';
import { Steps, Button, message } from 'antd';
import AddMessage from './addMessage';
import Create from './create';
import { addItem } from '@/api/member/index.js';
const { Step } = Steps;


class index extends Component {
    state = {
        active: '0',
        one: {},
        isUp:true
    }
    nextFn = () => {
        this.mesRef.validateFields((err, values) => {
            if (!err) {
                this.setState({ active: '1', one: values });
            }
        })
    }
    save = () => {
        this.twoRef.validateFields((err, values) => {
            if (!err) {
                if(!this.state.isUp){
                    return;
                }
                this.setState({isUp:false});
                let { one } = this.state;
                let params = {
                    ...values,
                    platform: '123',
                    ...one,
                    identityName: this.getStateRef.state.identityName,
                    userName: this.getStateRef.state.userNum,
                    password: one.telNum.slice(-6)
                }
                setTimeout(()=>this.setState({isUp:true}),2000)
                addItem(params).then(res => {
                    if (res) {
                        message.success('添加成功');
                        this.props.history.push('/main/capacity-auth/member');
                    }
                })
            }
        })
    }
    render() {
        let { active } = this.state;
        return (
            <div className='add_member_box'>
                <h2 className='title'>添加成员</h2>
                <div className={active === '0' ? 'buzhou_1' : 'buzhou_2'}>
                    <Steps current={active}>
                        <Step title="填写成员信息" />
                        <Step title="生成账号" />
                    </Steps>
                </div>
                {
                    active === '0' ? <AddMessage ref={e => this.mesRef = e} /> : active === '1' ? <Create wrappedComponentRef={e => this.getStateRef = e} ref={e => this.twoRef = e} /> : ''
                }
                <div className="fix_controll">
                    {
                        active === '0' ?
                            <>
                                <Button onClick={this.nextFn} className='add_btn_j' style={{ marginRight: '40px', width: '100px', height: '39px' }}>下一步</Button>
                                <Button onClick={() => this.props.history.push('/main/capacity-auth/member')} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                            </> :
                            active === '1' ?
                                <>
                                    <Button className='add_btn_j' onClick={this.save} style={{ marginRight: '40px', width: '100px', height: '39px' }}>保存</Button>
                                    <Button onClick={() => {
                                        this.props.history.push('/main/capacity-auth/member');
                                        // this.setState({
                                        //     active: '0'
                                        // })
                                    }} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                                </> : ''
                    }
                </div>
            </div>
        )
    }
}


export default index;