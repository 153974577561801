import React, { Component } from 'react';
import { Select, Input, Button, Table, Divider, Tooltip } from 'antd';
import { questionPage, getTypeSelectList, deleteQuestion } from '../../../api/helpCenter/index';
import './index.scss';
const { Option } = Select;
export default class index extends Component {
    state = {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        keyWord: '',
        total: 0,
        data: [],
        selectList: []
    }
    componentDidMount() {
        this.questionPage();
        this.getTypeSelectList();
    }
    //分类下拉框
    getTypeSelectList = () => {
        getTypeSelectList({}).then(res => {
            if (res) {
                this.setState({
                    selectList: [{ id: '', typeName: '全部' }, ...res]
                })
            }
        })
    }
    //问题列表
    questionPage = () => {
        questionPage({
            keyWord: this.state.keyWord,
            id: this.state.id || '',
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }).then(res => {
            if (res) {
                this.setState({
                    data: res.data,
                    total: res.total
                })
            }
        })
    }
    // 删除
    remove = (record) => {
        this.hint({
            'title': '温馨提示',
            'content': '您确定要删除该内容吗？删除后数据将无法恢复。',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': () => {
                deleteQuestion({ id: record.id }).then(res => {
                    console.log(res, "的卡卡打开");
                    if (res === 'success') {
                        this.mesSuccess('删除成功');
                        this.questionPage();
                    } else {
                        this.mesWarning('删除失败');
                    }
                })
            }
        })
    }
    render() {
        const columns = [
            {
                title: '编号',
                dataIndex: 'id',
                key: 'id',
                align: 'center'
            },
            {
                title: '问题分类',
                dataIndex: 'typeName',
                key: 'typeName',
                align: 'center'
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                align: 'center',
                render: (text) => <Tooltip placement="bottom" title={text}>
                    <span className="title">{text}</span>
                </Tooltip>

            },
            {
                title: '创建人',
                dataIndex: 'createUsername',
                key: 'createUsername',
                align: 'center'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                align: 'center'
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (red, record) => {
                    return <div className="contenidosList_action">
                        <span onClick={() => this.props.history.push(`/main/Generalmanagement/problemaDetail?id=${record.id}`)}>查看</span>
                        <Divider type="vertical" />
                        <span onClick={() => this.props.history.push(`/main/Generalmanagement/editProblema?id=${record.id}`)}>编辑</span>
                        <Divider type="vertical" />
                        <span onClick={() => this.remove(record)}>删除</span>
                    </div>
                }
            },
        ];
        return (
            <div className='contenidosList'>
                <div className="contenidosList_header">
                    <span>
                        问题分类
                        <Select
                            style={{ width: 230, marginLeft: '20px' }}
                            placeholder="请选择问题分类"
                            value={this.state.id}
                            onChange={(e) => {
                                this.setState({
                                    id: e
                                })
                            }}
                        >
                            {
                                this.state.selectList.map((item, key) => {
                                    return <Option key={key} value={item.id}>{item.typeName}</Option>
                                })
                            }
                        </Select>
                        <Input
                            prefix={<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0991 11.6364L9.70955 9.11115C11.4932 6.59057 11.0046 3.02002 8.62098 1.13508C6.2374 -0.749865 2.85732 -0.233507 1.07372 2.2855C0.377214 3.27113 0 4.46707 0 5.69754C0 8.84434 2.41328 11.3963 5.39238 11.3963C6.55669 11.3963 7.68834 10.9977 8.62098 10.2616L11.009 12.7853C11.309 13.1039 11.7976 13.1039 12.0991 12.7869C12.3991 12.4698 12.3991 11.9535 12.0991 11.6364ZM5.3909 9.76876C3.26424 9.80015 1.51628 8.00467 1.4851 5.75718C1.45539 3.50969 3.15434 1.66242 5.281 1.62946H5.38941C7.51607 1.62946 9.24026 3.45162 9.24026 5.69911C9.24026 7.9466 7.51904 9.76876 5.3909 9.76876Z" fill="#BEBEBE" />
                            </svg>
                            } style={{ width: 230, marginLeft: '30px' }}
                            placeholder="请输入搜索内容"
                            value={this.state.keyWord}
                            onChange={(e) => {
                                this.setState({
                                    keyWord: e.target.value
                                })
                            }}
                        />
                    </span>
                    <span>
                        <Button onClick={() => {
                            this.setState({
                                pageNum: 1,
                                pageSize: 10,
                                id: undefined,
                                keyWord: '',
                            }, () => {
                                this.questionPage();
                            })
                        }}>重置</Button>
                        <Button type="primary" onClick={() => { this.questionPage() }}>查询</Button>
                    </span>
                </div>
                <div className="contenidosList_top">
                    <span><Button type="primary" onClick={() => this.props.history.push('/main/Generalmanagement/addProblema')}>新增问题</Button></span>
                    <span>共有{this.state.total}条内容</span>
                </div>
                <div className="q_list">
                    <div className="q_list_show">
                        <Table
                            className="contenidosList_table"
                            dataSource={this.state.data}
                            columns={columns}
                            pagination={
                                {
                                    showSizeChanger: true,
                                    onShowSizeChange: (pageNum, pageSize) => {
                                        this.setState({
                                            pageNum: pageNum,
                                            pageSize: pageSize
                                        }, () => {
                                            this.questionPage();
                                        })
                                    },
                                    onChange: (pageNum, pageSize) => {
                                        this.setState({
                                            pageNum: pageNum,
                                            pageSize: pageSize
                                        }, () => {
                                            this.questionPage();
                                        })
                                    },
                                    total: this.state.total
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }
}
