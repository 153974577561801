import React, { Component } from 'react'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import PlayerVideo from './videoplayer'
import { resource_preview } from '@/config/secret.js';
import { Affix, Button } from 'antd'
import './index.scss'
import Audioplayer from './Audiopre'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default class FilePreview extends Component {
    state={
        full_flag:true,
        scalcnum: 1,
        currentpage:0,
        totalPage:0,
        resourceDetail:{},
        more:false,
        FileType:'',
    };

    componentDidMount(){
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        console.log("nextProps",nextProps.fileId,this.props.fileId)
        // if(nextProps.fileId!=this.props.fileId){
        this.setState({
            FileType:null
        })
        setTimeout(()=>{
            this.setState({
                FileType :nextProps.resourceType
            })
        },0)
        // }

    }
    set_full_flag=(flag)=>{
        // let audio_wrap = document.getElementById('audio_wrap');
        // let canvas = document.getElementById('canvas');
        console.log("tttt--",flag)
        this.setState({
            full_flag:!flag
        },()=>{
            this.props.setFullType(!flag)
            // canvas.setAttribute('width',audio_wrap.offsetWidth);
            // canvas.setAttribute('height',audio_wrap.offsetHeight);
        })
    };

    set_full_flag_child=(flag)=>{
        // let audio_wrap = document.getElementById('audio_wrap');
        // let canvas = document.getElementById('canvas');
        // console.log("tttt--",flag)
        this.setState({
            full_flag:!flag
        })
    };

    scalc_c=(type,n)=>{
        if(type==='-'&&n>1){
            this.setState({
                scalcnum:n-1
            })
        }else if(type==='+'&&n<2){
            this.setState({
                scalcnum:n*1+1
            })
        }else if(n===2){
            this.setState({
                full_flag:false
            })
        }
    };

    prev=(cur)=>{
        if(cur>=1){
            this.setState({
                currentpage:cur-1
            },()=>{
                // this.Document_wrap_cel
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }else{
            this.setState({
                currentpage:this.Document_wrap_cel.pages.map((v)=>v).length-2
            },()=>{
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }
    };

    next=(cur)=>{
        // if(cur<this.Document_wrap_cel.pages.map((v)=>v).length-2){
        if(cur<this.Document_wrap_cel.pages.map((v)=>v).length-1){
            this.setState({
                currentpage:cur+1
            },()=>{
                // this.Document_wrap_cel
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }else{
            this.setState({
                currentpage:0
            },()=>{
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }

    };

    onDocumentLoadSuccess = (e) => {
        console.log(this.Document_wrap_cel, 'this.Document_wrap_cel.pages')
        this.setState({
            totalPage: e.numPages
        })
    };

    render() {
        const {full_flag,totalPage,scalcnum,currentpage,more,FileType}=this.state
        //   resourceType    1,"文档",2,"视频",3,"音频" 4,"图片",
        const {resourceType, resourceSuffix, transFileSuffix, fileId, pageSize, startTime, resourceName,resourceContent} = this.props;
        console.log('wwwww1',fileId)
        console.log('wwwww2',resourceSuffix)
        console.log('wwwww3',resourceType)
        console.log('transFileSuffix',transFileSuffix)
        const url = resource_preview;
        return (
            <div className="left_bottom left-con">
                <div style={{height:"60px"}}></div>
                <div className='c_affix'>
                    <div className="c_bl">
                        <div className="img_w" >
                            {/*<img src={require('../../assets/resource_preview/wps.png')} alt="" />*/}
                            {/*<div className="text">*/}
                            {/*    {resourceName ? resourceName : ''}*/}
                            {/*</div>*/}
                        </div>
                        <div className="c_br">
                            {resourceType == 1 && resourceSuffix !== "txt"?
                                <div>
                        <span onClick={()=>this.prev(currentpage)}>
                            <img src={require('@/assets/resource_preview/prev.png')}/>
                        </span>
                                    <span>{currentpage+1}</span>
                                    <span>/</span>
                                    <span>{totalPage}</span>
                                    <span onClick={()=>this.next(currentpage)}>
                            <img src={require('@/assets/resource_preview/next.png')}/>
                        </span>
                                    <span onClick={()=>this.scalc_c('-',scalcnum)}>
                            <img src={require('@/assets/resource_preview/reduce.png')}/>
                        </span>
                                    <span onClick={()=>this.scalc_c('+',scalcnum)}>
                            <img src={require('@/assets/resource_preview/add.png')}/>
                        </span>
                                </div>:''}
                            <span onClick={()=>this.set_full_flag(full_flag)}>
                    <img className='suofang' src={require(full_flag?'@/assets/resource_preview/fangda.png':"@/assets/resource_preview/suoxiao.png")}/>
                </span>
                        </div>
                    </div>
                </div>
                <div className="main_preview">
                    { resourceType == 1 && resourceSuffix !== "txt"?
                        /* pdf  doc docx
                        * xlsx   ca99843af6ed4a229852809517151d32
                        * ppt  f9433bb7be5b419496bf12e9ecf8422f
                        *   */
                        <div>
                            <iframe src={`${window.$$webOfficeDownload(fileId)}/${resourceName}.${transFileSuffix}`} style={{width:'100%'}} height={500}>

                            </iframe>
                            {/*<Document*/}
                            {/*    className='Document_wrap_c'*/}
                            {/*    // file={filesrc}*/}
                            {/*    ref={(el)=>this.Document_wrap_cel=el}*/}
                            {/*    file={url+fileId}*/}
                            {/*    onLoadSuccess={this.onDocumentLoadSuccess}*/}
                            {/*    onLoadError={console.error}*/}
                            {/*    renderMode="canvas"*/}
                            {/*    loading="正在努力加载中"*/}
                            {/*    externalLinkTarget="_blank"*/}
                            {/*    renderTextLayer={true}*/}
                            {/*>*/}
                            {/*    {!more?Array.from({ length: pageSize && pageSize > 0 ? totalPage > 0 && totalPage > pageSize ? pageSize : totalPage : 2 }, (v, k) => k + 2).map((v, i) => {*/}
                            {/*        return <div key={i}><Page pageNumber={v - 1} key={i}*/}
                            {/*                                  scale={scalcnum} />*/}
                            {/*            <div className='pagenum'>{i+1}</div>*/}
                            {/*        </div>*/}
                            {/*    }):Array.from({ length: totalPage }, (v, k) => k + 2).map((v, i) => {*/}
                            {/*        return <div key={i}><Page pageNumber={v - 1} key={i}*/}
                            {/*                                  scale={scalcnum} />*/}
                            {/*            <div className='pagenum'>{i+1}</div>*/}
                            {/*        </div>*/}
                            {/*    })}*/}
                            {/*</Document>*/}
                            {/*{!more && totalPage > (pageSize && pageSize > 0 ? totalPage < pageSize ? totalPage : pageSize : 2) ?<div className='more_wrap' ><span className='more' onClick={()=>[*/}
                            {/*    this.setState({*/}
                            {/*        more:true*/}
                            {/*    })*/}
                            {/*]}>剩余{totalPage - (pageSize && pageSize > 0 ? pageSize : 2) > 0 ? totalPage - (pageSize && pageSize > 0 ? pageSize : 2): 0 }页未读，<span className='next_look'> 继续预览</span></span>*/}
                            {/*</div>:''}*/}
                        </div>:
                        resourceType == 2 ?
                            /* {视频} */
                            <div >
                                <PlayerVideo startTime={startTime && startTime > 0 ? startTime : 0}
                                             src={url+fileId}>
                                    params={(e)=>{
                                    console.log(e)
                                }}
                                </PlayerVideo>
                            </div> :
                            resourceType == 3 ?
                                /* {音频} */
                                <div className="c_a_dio">
                                    <Audioplayer set_full_flag={this.set_full_flag}
                                                 set_full_flag={full_flag}
                                                 src={url+fileId}>
                                    </Audioplayer>
                                </div>:
                                resourceType == 4 ?
                                    <div className="imgModal">
                                        {/*<img src={`${url}${fileId}`} alt=""/>*/}
                                        <img src={window.$$img(fileId)} alt=""/>
                                    </div>
                                    :
                                    <div className="iframe_preview" >
                                        {/*<iframe width="100%" height="75%" src={url+fileId} frameBorder="0"></iframe>*/}
                                        <iframe src={`${window.$$webOfficeDownload(fileId)}`} style={{width:'100%'}}  height={500}></iframe>
                                    </div>
                    }
                </div>
                <div style={{height:"20px"}}></div>
            </div>
        )
    }
}


