
export default {
    CHANGE_tab(state, { data }) {
        state.activeIndex = data;
    },
    CHANGE_checkedAll(state) {
        console.log(2222)
        let { selectedRowKeys, dataSource } = state;
        if (selectedRowKeys.length === dataSource.length) {
            state.selectedRowKeys = [];
        } else {
            state.selectedRowKeys = dataSource.map(v => v.id);
        }
    },
    CHANGE(state, { data }) {
        state.selectedRowKeys = data;
    },
    BATCH(state){
        console.log(state.selectedRowKeys);
    }
}