import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import MyTable from '@/components/Table';
import {GETONSHELfn} from '@store/OBS/grounding/action';
import {Button, Input, Col, Select, message, Form, Radio, DatePicker} from 'antd';
import {
    getOnShel,
    getOffShel,
    shareResource,
    resourceShelfOperate,
    pressType,
    getSubjectList,
    getSpecialList,
    getBaseSchoolMajorList
} from '@/api/grounding';
import {Checkbox} from 'antd';
import setName from 'classnames';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import OffShelf from "../../components/OffShelfModal";
const {Option} = Select;
const {RangePicker} = DatePicker;
const mapStateToProps = ({GROUNDING}) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {}
}

class Grounding extends React.Component {
    state = {
        columns: [],
        params: {
            mediaType: "",
            pageNum: 1,
            pageSize: 10,
            pressType: "",
            resourceCategorySeniorId: "",
            resourceCategorySubId: "",
            resourceCategorySubId1: "",
            searchValue: "",
            shelfDateEnd: "",
            shelfDateStart: "",
            resourceHotSenior: ""
        },
        dataSource: [],
        type: 1,
        total: 0,
        ids: [],
        shelvesReason: "",
        subjectList: [],//分类
        pressTypeList: [],//资源类型
        specialList: [],//专业大类 或 专题1级
        specialTowList: [],//专业 或 专题2级
        resourceTypeList: [{id: 1, name: "文档"}, {id: 2, name: "视频"}, {id: 3, name: "音频"}, {id: 4, name: "图片"}],//资源格式
        majorValue: '',
        bigMajorValue: '',
        isBlock: false,
        lookVisible: false,  //下架弹窗
        btnDisabled: true,  //重置按钮
    }

    componentDidMount() {
        this.switchTab(1)
        this.getPressType()
        this.getSubjectList()
    }

    //资源分类
    async getPressType() {
        let pressTypeList = await pressType();
        this.setState({
            pressTypeList: pressTypeList
        })
    };

    //资源分类
    async getSubjectList() {
        let subjectList = await getSubjectList({validFlag: 1, status: 1});
        this.setState({
            subjectList: subjectList
        })
    };

    //资源专题
    async getSpecialList() {
        let specialList = await getSpecialList();
        this.setState({
            specialList: specialList
        })
    };

    //资源专业
    async getBaseSchoolMajorList() {
        const {params} = this.state;
        let schoolMajorList = await getBaseSchoolMajorList({stageId:params.resourceCategorySeniorId});
        if(schoolMajorList){
            this.setState({
                specialList: schoolMajorList
            })
        }
    };
    //检索查询
    queryBtn = () => {
        let  pagination=this.state.params;
        pagination.pageNum = 1
        this.setState({
            params:pagination
        }, () => this.switchTab(this.state.type));
    }
    async switchTab(switchType) {
        let {params} = this.state;
        if (switchType !== this.state.type) {
            params = {
                mediaType: "",
                pageNum: 1,
                pageSize: 10,
                pressType: "",
                resourceCategorySeniorId: "",
                resourceCategorySubId: "",
                resourceCategorySubId1: "",
                searchValue: "",
                shelfDateEnd: "",
                resourceHotSenior: "",
                shelfDateStart: ""
            };
        }
        let columns;
        let dataSource;
        let total;
        //type  0 下架列表  1 上架列表
        if (switchType === 0) {
            columns = [
                {title: '资源名称', dataIndex: 'resourceName', key: 'resourceName'},
                {title: '上传人', dataIndex: 'createName', key: 'createName', align: 'center',},
                {title: '资源类型', dataIndex: 'resourceFormName', key: 'resourceForm', align: 'center',},
                {title: '资源分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center'},
                {title: '作者', dataIndex: 'authorName', key: 'authorName', align: 'center',},
                {
                    title: '资源格式', dataIndex: 'resourceType', key: 'resourceType', align: 'center',
                    render: (data) => {
                        let params = {1: "文档", 2: "视频", 3: "音频", 4: "图片"}
                        return params[data];
                    }
                },
                {
                    title: '共享状态', dataIndex: 'unitType', key: 'unitType', align: 'center', render: (data) => {
                        return <div>{data === 2 ? '机构' : '平台'}</div>
                    }
                },
                {title: '下架原因', dataIndex: 'shelvesReason', key: 'shelvesReason', align: 'center', ellipsis:"true", },
                {title: '下架时间', dataIndex: 'shelvesTime', key: 'operationTime', align: 'center',},
                {
                    title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                    width: 240,
                    render: (text, data) => {
                        return <div className="action vertical_j">
                            <span
                                onClick={() => this.props.history.push(`/resourceDetail/${data.id}/${switchType}`)}>查看</span>
                            <React.RenderButton
                                text='上架'
                                id="OBS-RC-01-06-b_btnPutOn"
                                // type='table_btn'
                                // event={{
                                //     onClick: () => {
                                //         console.log('click')
                                //     },
                                //     onMouseOver:()=>{
                                //         console.log(222)
                                //     }
                                // }}
                            >
                                <span onClick={() => this.lookModal(data, 1)} className="textStyle">上架</span>
                            </React.RenderButton>
                        </div>
                    }
                }
            ]
            let offShelList = await getOffShel(params);
            if(offShelList){
                dataSource = offShelList.data || []
                total = offShelList.total || 0
            }
        } else {
            columns = [
                {title: '资源名称', dataIndex: 'resourceName', key: '0'},
                {title: '上传人', dataIndex: 'createName', key: '1', align: 'center'},
                {title: '资源类型', dataIndex: 'resourceFormName', key: '2', align: 'center'},
                {title: '资源分类', dataIndex: 'resourceHotSeniorName', key: '3', align: 'center'},
                {title: '作者', dataIndex: 'authorName', key: '4', align: 'center'},
                {
                    title: '资源格式', dataIndex: 'resourceType', key: '5', align: 'center',
                    render: (data) => {
                        let params = {1: "文档", 2: "视频", 3: "音频", 4: "图片"}
                        return params[data];
                    }
                },
                {
                    title: '共享状态', dataIndex: 'unitType', key: '6', align: 'center', render: (data) => {
                        return data === 2 ? '机构' : '平台'
                    }
                },
                {title: '上架时间', dataIndex: 'shelvesTime', key: '7', align: 'center'},
                {title: '上传时间', dataIndex: 'createTime', key: '8', align: 'center'},
                {
                    title: '操作', dataIndex: 'address', key: '9', fixed: 'right', align: 'center', width: 240,
                    render: (text, data) => {
                        return <div className="action vertical_j">
                            <span
                                onClick={() => this.props.history.push(`/resourceDetail/${data.id}/${switchType}`)}>查看</span>
                            <React.RenderButton
                                text='下架'
                                id="OBS-RC-01-06-b_btnPutOff"
                                // type='table_btn'
                                // event={{
                                //     onClick: () => {
                                //         console.log('click')
                                //     },
                                //     onMouseOver:()=>{
                                //         console.log(222)
                                //     }
                                // }}
                            >
                                <span onClick={() => this.lookModal(data, 0)} className="textStyle">下架</span>
                            </React.RenderButton>
                            {/*<span className="textStyle" onClick={() => this.shareResource(data)}>向上共享</span>*/}
                        </div>
                    }
                }
            ]
            let onShelList = await getOnShel(params);
            if(onShelList){
                dataSource = onShelList.data || []
                total = onShelList.total || 0
            }
        }
        this.setState({
            dataSource,
            total,
            params,
            ids: [],
            columns,
            type: switchType
        },()=>{
            window.elGoTop && window.elGoTop('body');
        })
    };

    clearAll = () => {
        let {params, type} = this.state;
        let data = {
            ...params,
            mediaType: "",
            pressType: "",
            resourceCategorySeniorId: "",
            resourceCategorySubId: "",
            resourceCategorySubId1: "",
            searchValue: "",
            shelfDateEnd: "",
            resourceHotSenior: "",
            shelfDateStart: ""
        }
        this.setState({
            params: data,
            btnDisabled: true,
            majorValue: '',
            bigMajorValue: ''
        }, () => {
            this.switchTab(type)
        })
    };

    classChange = (value) => {
        const {params, type} = this.state;
        params.resourceCategorySeniorId = value
        params.resourceCategorySubId = ''
        params.resourceCategorySubId1 = ''
        this.setState({
            specialList: [],
            specialTwoList: [],
            bigMajorValue: '',
            majorValue: '',
            specialTowList: [],
            btnDisabled: false,
            params
        }, () => {
            if(value !== ''){
                value === 10202 ? this.getSpecialList() : this.getBaseSchoolMajorList()
            }
            this.switchTab(type)
        })
    };

    bigMajorChange = (index) => {
        const {specialList, params, type} = this.state;
        let data;
        let list = [];
        if (index !== '') {
            data = specialList[index];
            params.resourceCategorySubId = data.id;
            params.resourceCategorySubId1 = '';
            if (data && data.subList) {
                list = data.subList;
            } else {
                list = data.subHotSpecial
            }
        }
        this.setState({
            bigMajorValue: index,
            specialTowList: list,
            majorValue: '',
            btnDisabled: false,
            params
        }, () => {
            this.switchTab(type)
        })
    };

    majorChange = (id) => {
        const {params, type} = this.state;
        params.resourceCategorySubId1 = id
        this.setState({
            majorValue: id,
            btnDisabled: false,
            params
        }, () => {
            this.switchTab(type)
        })
    };

    mediaTypeChange = (value) => {
        const {params, type} = this.state;
        params.mediaType = value
        this.setState({
            btnDisabled: false,
            params
        }, () => {
            this.switchTab(type)
        })
    };

    pressTypeChange = (value) => {
        const {params, type} = this.state;
        params.pressType = value
        this.setState({
            btnDisabled: false,
            params
        }, () => {
            this.switchTab(type)
        })
    };

    subjectChange = ({target: {value}}) => {
        const {params, type} = this.state;
        params.resourceHotSenior = value
        this.setState({btnDisabled: false, params}, () => {
            this.switchTab(type)
        })
    };

    searchChange = ({target: {value}}) => {
        let {params} = this.state;
        params.searchValue = value
        let btnDisabled = true
        if (value !== '' && value !== null) {
            btnDisabled = false;
        }
        this.setState({btnDisabled, params})
    };

    //分页
    pageChange = (pageNumber) => {
        const {params, type} = this.state;
        params.pageNum = pageNumber;
        this.setState({params}, () => {
            this.switchTab(type)
        })
    };
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const {params, type} = this.state;
        params.pageNum = 1;
        params.pageSize = pageSize;
        this.setState({params}, () => this.switchTab(type))
    };
    lookModal = (data, status) => {
        //如果是上架不需要填写原因
        if (status === 1) {
            let params = {
                ids: [data.id],
                shelvesType: 1
            };
            resourceShelfOperate(params).then(res => {
                this.setState({
                    lookVisible: false,
                    ids: [],
                }, () => {
                    if(res.code == 0){
                        message.success('上架成功');
                        this.switchTab(this.state.type)
                    }
                });
            })
        } else {
            this.setState({
                ids: [data.id],
                lookVisible: true,
            });
        }
    };

    lookCancel = () => {
        this.setState({
            lookVisible: false,
            ids: []
        }, () =>  this.switchTab(this.state.type))
    };

    lookClose = () => {
        this.setState({lookVisible: false})
    };

    checkedChange = (selectedRowKeys, selectedRows) => {
        this.setState({ids: selectedRows.map(v => v.id)})
    };

    //全选 反选
    checkedAll = () => {
        let {ids, dataSource} = this.state;
        if (ids.length === dataSource.length) {
            this.setState({ids: []});
            return;
        }
        this.setState({
            ids: dataSource.map(v => v.id)
        });
    };

    //批量下架
    batchSure = () => {
        let {ids, type} = this.state;
        if (!ids.length) {
            message.warning('请选择想要下架的资源！');
            return;
        }
        if (type === 0) {
            let params = {
                ids: ids,
                shelvesType: 1
            };
            resourceShelfOperate(params).then(res => {
                this.setState({
                    lookVisible: false,
                    ids: [],
                }, () => {

                    this.switchTab(this.state.type)
                });
            })
        } else {
            this.setState({lookVisible: true});
        }
    };

    //向上共享
    shareResource = (data) => {
        shareResource([data.id]).then(res => {
            return message.warning("已成功共享，请等待审核结果！")
        })
    };

    //批量共享
    noBatchSure = () => {
        let {ids} = this.state;
        if (!ids.length) {
            message.warning('请选择想要共享的数据!');
            return;
        }
        shareResource(ids).then(res => {
            return message.warning("已成功共享，请等待审核结果！")
        })
    };

    isShowForm = () => {
        this.setState({
            isBlock: !this.state.isBlock
        })
    }

    render() {
        const {columns, dataSource, total, params, pressTypeList, specialList, btnDisabled, specialTowList, majorValue, bigMajorValue, ids, subjectList, resourceTypeList, type, isBlock} = this.state;
        return (
            <div className='grounding'>
                <div className='bg_fff m20 p20' style={{position: 'relative'}}>
                    <div className="navtop bg_fff">
                        <div className='left'>
                            资源分类
                        </div>
                        <div className='left_border'> |</div>
                        <div className="classity">
                            <Radio.Group onChange={this.subjectChange} value={params.resourceHotSenior}
                                         buttonStyle="solid">
                                {subjectList.map((item, index) => {
                                    return <Radio.Button key={index} value={item.id}>{item.name}</Radio.Button>
                                })
                                }
                            </Radio.Group>
                        </div>
                        <div className={setName(['right btn_box', isBlock ? 'zhankai' : 'shouqi'])}>
                            {/*<Button type='primary' className='search' onClick={()=>{this.getOnShel()}}>查询</Button>*/}
                            <Button className='reset_btn' onClick={() => {
                                this.clearAll()
                            }} disabled={btnDisabled}>重置</Button>
                            <div className="pack-unfold" onClick={() => this.isShowForm()}>
                                {isBlock ? <span className="pack">收起</span> : <span className="unfold">展开</span>}
                            </div>
                        </div>
                    </div>
                    <div className="hot-box" style={{display: isBlock ? 'flex' : 'none'}}
                         ref={(e) => this.input_wrap1 = e}>
                        <div className="hot-direction">
                            <div className='hot-tit'>
                                其他分类
                            </div>
                            <Select style={{width: "28%", marginRight: "20px"}} value={params.resourceCategorySeniorId}
                                    onChange={this.classChange}>
                                <Option value="">请选择分类方式(高职/中职)</Option>
                                <Option value={1}>中职</Option>
                                <Option value={2}>高职</Option>
                                <Option value={10202}>专题</Option>
                            </Select>
                            <Select style={{width: "28%", marginRight: "20px"}} value={bigMajorValue}
                                    onChange={this.bigMajorChange}>
                                <Option
                                    value="">{params.resourceCategorySeniorId === 10202 ? "请选择一级专题" : "请选择专业大类"}</Option>
                                {specialList && specialList.map((item, index) => (
                                    <Option key={index} value={index}>{item.name ? item.name : item.typeName}</Option>
                                ))}
                            </Select>
                            <Select style={{width: "28%", marginRight: "20px"}} value={majorValue}
                                    onChange={this.majorChange}>
                                <Option value="">{params.resourceCategorySeniorId === 10202 ? "请选择二级专题" : "请选择专业"}</Option>
                                {specialTowList && specialTowList.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name ? item.name : item.typeName}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="hot-box" style={{display: isBlock ? 'flex' : 'none'}}>
                        <div className='hot-tit'>
                            资源类型
                        </div>
                        <Select style={{width: "28%", marginRight: "20px"}} value={params.pressType}
                                onChange={this.pressTypeChange}>
                            <Option value="">请选择资源类型</Option>
                            {pressTypeList.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                        <div className='hot-tit'>
                            资源格式
                        </div>
                        <Select style={{width: "28%", marginRight: "20px"}} value={params.mediaType}
                                onChange={this.mediaTypeChange}>
                            <Option value="">请选择资源格式</Option>
                            {resourceTypeList.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </div>
                    {/*<div className="input_wrap2" style={{display: isBlock ? 'flex' : 'none'}}*/}
                    {/*     ref={(e) => this.input_wrap2 = e}>*/}
                    {/*    <div className='input_wrap2_left'>*/}
                    {/*        推荐日期*/}
                    {/*    </div>*/}
                    {/*    <div className="input_wrap2_center">*/}
                    {/*        <RangePicker/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className='Grounding_box bg_fff p20'>
                    <div className="Grounding_top">
                        <Col span={12}>
                            <p onClick={() => {
                                this.switchTab(1)
                            }} className={type === 1 ? "manage manage-active" : "manage"}>上架资源</p>
                            <p onClick={() => {
                                this.switchTab(0)
                            }} className={type === 0 ? "manage manage-active" : "manage"}>下架资源</p>
                        </Col>
                        <Col className='Grounding_search' span={12}>
                            <Input value={params.searchValue} onChange={this.searchChange} onPressEnter={() => {
                                this.queryBtn()
                            }} placeholder='请输入资源名称/上传人/作者'/>
                        </Col>
                    </div>
                    <div className="check_container" style={{position: 'relative'}}>
                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                options={{
                                    dataSource,
                                    columns,
                                    rowSelection: {
                                        onChange: this.checkedChange,
                                        selectedRowKeys: ids
                                    },
                                    pagination: {
                                        onChange: this.pageChange,
                                        current: params.pageNum,
                                        pageSize: params.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ["10", "20", "30", "100"],
                                        total: total,
                                        onShowSizeChange: this.onShowSizeChange
                                    }
                                }}
                                isDrab={false}
                                fixededit={true}
                            />
                        </DndProvider>
                        <div className="Grounding_position">
                            <label>
                                <Checkbox
                                    checked={ids.length === dataSource.length}
                                    onChange={this.checkedAll}
                                />
                                <span className="Grounding_select">全选</span>
                            </label>
                            <Button type='primary' onClick={() => {
                                this.batchSure()
                            }}>{type === 1 ? '批量下架' : '批量上架'}</Button>
                            {/*{type === 1 ? <Button className='ant_share' type='primary'*/}
                            {/*                      onClick={this.noBatchSure}>批量共享到平台资源库</Button> : ''}*/}
                        </div>
                    </div>
                </div>
                {/*操作下架*/}
                <OffShelf
                    ids={ids}
                    visible={this.state.lookVisible}
                    checkCancel={()=>this.lookClose()}
                    refreshData={()=>this.lookCancel()}
                    wrappedComponentRef={form => {
                        this.offShelfForm = form;
                    }}
                />
            </div>
        )
    }
}

Grounding = connect(mapStateToProps, mapDispatchToProps)(Grounding);
Grounding = Form.create()(Grounding)
export default Grounding;
