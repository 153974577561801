import React, { Component } from 'react';
import { Select, Button, Input, Table, Divider, Pagination, Tooltip } from 'antd';
import { getFeedbackPage, getFeedbackTypeList } from '../../../api/feedbackCenter/index';
import './index.scss';

const replayStatusList = [
    { replayStatus: 0, value: "待回复" },
    { replayStatus: 1, value: "已回复" }
]
export default class Retroalimentacion extends Component {
    state = {
        pageSize: 10,
        pageNum: 1,
        // 状态
        replayStatus: '',
        //反馈类型
        typeName: '',
        //搜索框内容
        nameOrPhone: '',
        // 反馈类型列表
        fdbTypeList: [],
        total: 0,
        // 数据列表
        dataList: []
    }
    componentDidMount() {
        this.getDataList()
        this.getFdbTypeList()
    }
    getFdbTypeList = async () => {
        let res = await getFeedbackTypeList({ platform: "uc" })
        if (res) {
            this.setState({
                fdbTypeList: res
            })
        } else {
            this.mesError("数据错误")
        }

    }
    // 状态下拉框
    changeStatus = (item) => {
        this.setState(() => ({
            replayStatus: item
        }));
    }
    //反馈类型下拉框
    changeFdbType = (item) => {
        this.setState(() => ({
            typeName: item
        }))
    }
    // 重置按钮
    reset = () => {
        this.setState(() => ({
            replayStatus: '',
            typeName: '',
            nameOrPhone: ''
        }), () => {
            this.getDataList();
        })
    }
    //  列表
    getDataList = () => {
        getFeedbackPage({
            replayStatus: this.state.replayStatus,
            typeName: this.state.typeName,
            nameOrPhone: this.state.nameOrPhone,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }).then(res => {
            if (res) {
                this.setState({
                    dataList: res.data,
                    total: res.total
                })
            }
        })

        // return commonQuestionList(par).then(res => {
        //     if (res) {
        //         this.setState(() => {
        //             return {
        //                 dataList: res.data,
        //                 total: res.total
        //             }
        //         })
        //     }
        // })
    }
    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
        }, () => {
            this.getDataList();
        }
        );
    };
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getDataList();
        }
        );
    };
    // 查看
    look = (item) => {
        this.props.history.push(`/main/Generalmanagement/feedBackDetail?type=0&id=${item.id}`)
    }
    // 回复
    response = (item) => {
        this.props.history.push(`/main/Generalmanagement/feedBackDetail?type=1&id=${item.id}`)

    }
    render() {
        const columns = [
            {
                title: '编号',
                dataIndex: 'id',
                width: 200,
                key: 'id',
                align: 'center',
            },
            {
                title: '问题分类',
                dataIndex: 'typeName',
                key: 'typeName',
                align: 'center'
            },
            {
                title: '标题',
                dataIndex: 'feedbackTitle',
                key: 'feedbackTitle',
                width: 140,
                ellipsis: true,
                align: 'center',
                render: (text, record) => {
                    return <Tooltip placement="bottom" title={text}>{text}</Tooltip>
                }
            },
            {
                title: '描述',
                dataIndex: 'feedbackInfo',
                width: 200,
                ellipsis: true,
                key: 'feedbackInfo',
                align: 'center',
                render: (text, record) => {
                    return <Tooltip placement="bottom" title={text}>{text}</Tooltip>
                }
            },
            {
                title: '用户',
                dataIndex: 'userName',
                key: 'userName',
                align: 'center'
            },
            {
                title: '手机号',
                dataIndex: 'userPhone',
                key: 'userPhone',
                align: 'center'
            },
            {
                title: '反馈时间',
                dataIndex: 'feedbackTime',
                key: 'feedbackTime',
                align: 'center'
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: 160,
                fixed: 'right',
                render: (text, record) => (
                    <div className="contenidosList_action">
                        <span onClick={() => this.look(record)}>查看</span>
                        <Divider type="vertical" />
                        <span onClick={() => this.response(record)}>回复</span>
                    </div>
                )
            },
        ];
        const { dataList } = this.state
        return (
            <div className='retroalimentacion'>
                <div className='retroalimentacion_top'>
                    <div className="select">
                        <div className="change_sl">
                            <span>状态</span>
                            <span>
                                <Select
                                    style={{ width: 230 }}
                                    placeholder="请选择状态"
                                    onChange={this.changeStatus}
                                    value={this.state.replayStatus ? this.state.replayStatus : undefined}
                                    allowClear={true}
                                >
                                    {
                                        replayStatusList.map((item, index) => {
                                            return <Select.Option key={item.replayStatus} >
                                                {item.value}  </Select.Option>
                                        })
                                    }

                                </Select>
                            </span>
                        </div>
                        <div className="change_sl">
                            <span>反馈类型</span>
                            <span>
                                <Select
                                    // showSearch
                                    style={{ width: 230 }}
                                    placeholder="请选择反馈类型"
                                    onChange={this.changeFdbType}
                                    value={this.state.typeName ? this.state.typeName : undefined}
                                    allowClear={true}
                                >
                                    {
                                        this.state.fdbTypeList.length > 0 && this.state.fdbTypeList.map((item, index) => {
                                            return <Select.Option key={item} >
                                                {item}  </Select.Option>
                                        })
                                    }

                                </Select>
                            </span>
                        </div>
                        <div className="change_sl">
                            <img src={window.$$img('cvec-gonggong-front-wenjuan/icon/icon_select.png')} alt=''></img>
                            <Input
                                className="in"
                                placeholder="请输入姓名/手机号码"
                                style={{ width: 230 }}
                                value={this.state.nameOrPhone}
                                onChange={value => {
                                    this.setState({
                                        nameOrPhone: value.target.value
                                    })
                                }}
                            />

                        </div>
                    </div>
                    <div className="r_btn">
                        <Button onClick={() => this.reset()}>重置</Button>
                        <Button type="primary" onClick={() => this.getDataList()}>查询</Button>
                        <div className="total">共计{this.state.total}条反馈记录</div>
                    </div>

                </div>
                <div className="q_list">
                    <div className="q_list_show">
                        <Table
                            className="retroalimentacion_table"
                            dataSource={dataList.length > 0 && dataList}
                            columns={columns}
                            scroll={{ x: 1200 }}
                            pagination={
                                {
                                    dataSource: { dataList }, defaultCurrent: 1,
                                    pageSizeOptions: ['10', '20', '30'],
                                    onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                    onChange: (a, b) => this.onPageNumChange(a, b),
                                    showSizeChanger: true,
                                    current: this.state.pageNum,
                                    pageSize: this.state.pageSize,
                                    total: this.state.total
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }
}
