
import {
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import reducers from './reducers'
let defaultState = {
    collapsed: false,
    menuList: [
        {
            title: '资源属性管理',
            key: 'sub1',
            icon: 'icon_sidebar.svg',
            children: [
                {
                    title: '资源热门方向管理',
                    key: '0',
                    path: '/main/resource/special'
                },
                {
                    title: '临时资源热门方向管理',
                    key: '1',
                    path: '/main/resource/temporaryProject'
                }
            ]
        },
        {
            title: '审核管理',
            key: 'sub2',
            icon: 'icon_sidebar1.svg',
            children: [
                {
                    title: '审核管理',
                    key: '3',
                    path: '/main/resource/check'
                }

            ]
        },
        {
            title: '资源管理',
            key: 'sub3',
            icon: 'icon_sidebar2.svg',
            children: [
                {
                    title: '推荐管理',
                    key: '9',
                    path: '/main/resource/recommend',
                },
                {
                    title: '上下架资源管理',
                    key: '4',
                    path: '/main/resource/grounding'
                },
                // {
                //     title: '下架资源',
                //     key: '5',
                //     path: '/main/resource/undercarriage'
                // },
                {
                    title: '举报管理',
                    key: '6',
                    path: '/main/resource/report'
                },
                {
                    title: '添加推荐资源',
                    key: '11',
                    path: '/main/resource/temporaryClassify'
                }
               /* {
                    title: '审核管理',
                    key: '7',
                    path: '/main/resource/check'
                },
                {
                    title: '上传管理',
                    key: '8',
                    path: '/main/resource/uploading'
                },

                {
                    title: '临时专题管理',
                    key: '10',
                    path: '/main/resource/temporaryProject'
                },
                {
                    title: '临时分类管理',
                    key: '11',
                    path: '/main/resource/temporaryClassify'
                },*/

            ]
        }
    ]
}
const Resource = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Resource
