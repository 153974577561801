import React, { Component } from 'react';
import { Table } from 'antd';
import './index.scss';
import MyImage from '../../../../components/MyImage';
import { fetchAppAccess } from '../../../authorityManagement/api/application'
import TableEmpty from '@/page/authorityManagement/components/TableEmpty/TableEmpty'

const formatTableValue = value => {
    return value ?? '- -';
}

class index extends Component {
    state = {
        columns: [
            {
                title: '序号',
                dataIndex: 'xuhao',
                key: 'xuhao',
                align: 'center',
                width: 80,
                render: (text, item, index) => <span className='serial'>{index + 1}</span>,
            },
            {
                title: '应用图标',
                dataIndex: 'terminalLogo',
                key: 'terminalLogo',
                align: 'center',
                width: 100,
                render: ($, item) => {
                    return <img  className={'img_icon'} src={window.$$setImgSrc(item.icon)}/>
                }
            },
            {
                title: '应用名称',
                dataIndex: 'appName',
                key: 'appName',
                align: 'center',
                width: 100,
                render: (text) => text ?? '- -'
            },
            {
                title: '应用标识码',
                dataIndex: 'appCode',
                key: 'appCode',
                align: 'center',
                width: 100,
                render: formatTableValue
            },
            {
                title: '添加时间',
                dataIndex: 'createTime',
                key: 'createTime',
                align: 'center',
                width: 150,
                render: (text) => text ?? '- -'
            },
            {
                title: '接入机构数（正式）',
                dataIndex: 'formalNum',
                key: 'formalNum',
                align: 'center',
                width: 140,
                render: (text) => text ?? '- -'
            },
            {
                title: '接入机构数（试用）',
                dataIndex: 'informalNum',
                key: 'informalNum',
                align: 'center',
                width: 140,
                render: (text) => text ?? '- -'
            },
            {
                title: '版本号',
                dataIndex: 'appVersion',
                key: 'appVersion',
                align: 'center',
                textWrap: 'word-break',
                ellipsis: true,
                width: 100,
                render: (text) => text ?? '- -'
            },
            {
                title: '操作',
                dataIndex: 'handle',
                key: 'handle',
                align: 'center',
                width: 130,
                render: ($, item, index) => (
                    <p className='group_btn'>
                        <span onClick={() => this.cut(item)}>接入管理</span>
                    </p>
                )
            }
        ],
        dataSource: [],
        total: 0,
        pageSize: 20,
        pageNum: 1
    }
    //接入管理
    cut = item => {
        this.props.history.push(`/main/capacity-auth/cut-setSchool/${item.id}/${item.appName}`)
    }
    //切换页码
    pageChange = (page, pageSize) => {
        this.setState({ pageNum: page }, () => {
            this.useCutList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    //切换分页返回条数
    onShowSizeChange = (current, size) => {
        this.setState({ pageSize: size, pageNum: 1 }, () => {
            this.useCutList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    //列表
    useCutList = () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }
        fetchAppAccess(data).then(res => {
            if (res) {
                this.setState({
                    total: res.total,
                    dataSource: res.data
                })
            }
        })
    }

    componentDidMount() {
        this.useCutList();
    }


    render() {
        let { columns, dataSource, total, pageSize, pageNum } = this.state;
        return (
            <div className='access_box'>

                <div className='access_info'>
                    <p>共{total}个应用</p>
                </div>
                <div className='container ui_table'>
                    <Table
                        locale={{
                            emptyText: <TableEmpty/>
                        }}
                        rowKey={v => v.id}
                        columns={columns}
                        pagination={{
                            total,
                            pageSize,
                            pageNum,
                            onChange: this.pageChange,
                            onShowSizeChange: this.onShowSizeChange,
                            pageSizeOptions: ['20', '40', '60', '80'],
                            showSizeChanger: true
                        }}
                        dataSource={dataSource}/>
                </div>
            </div>
        )
    }
}


export default index;