const str = '{"表情":{":蝴蝶结:":"bowtie.png",":开心:":"smile.png",":大笑:":"laughing.png",":可爱:":"blush.png",":笑脸:":"smiley.png",":放松:":"relaxed.png",":假笑:":"smirk.png",' +
    '":喜欢:":"heart_eyes.png",":飞吻:":"kissing_heart.png",":亲吻:":"kissing_closed_eyes.png",":脸红:":"flushed.png",":放心:":"relieved.png",":满意:":"satisfied.png",":龇牙笑:":"grin.png",' +
    '":眨眼:":"wink.png",":鬼脸:":"stuck_out_tongue_winking_eye.png",":调皮:":"stuck_out_tongue_closed_eyes.png",":咧嘴笑:":"grinning.png",":亲亲:":"kissing.png",' +
    '":吐舌:":"stuck_out_tongue.png",":睡觉:":"sleeping.png",":担心:":"worried.png",' +
    '":皱眉:":"frowning.png",":痛苦:":"anguished.png",":张嘴:":"open_mouth.png",":扮鬼脸:":"grimacing.png",":撇嘴:":"confused.png",' +
    '":安静:":"hushed.png",":无表情:":"expressionless.png",":不开心:":"unamused.png",":破涕为笑:":"sweat_smile.png",":流汗:":"sweat.png",' +
    '":如释重负:":"disappointed_relieved.png",":疲倦:":"weary.png",":沉思:":"pensive.png",":失望:":"disappointed.png",":困惑:":"confounded.png",' +
    '":可怕:":"fearful.png",":冷汗:":"cold_sweat.png",":难受:":"persevere.png",":哭泣:":"cry.png",":大哭:":"sob.png",":哭笑不得:":"joy.png",' +
    '":尖叫:":"scream.png",":累:":"tired_face.png",":生气:":"angry.png",":愤怒:":"rage.png",":气愤:":"triumph.png",":打瞌睡:":"sleepy.png",' +
    '":美味:":"yum.png",":生病:":"mask.png",":太阳镜:":"sunglasses.png",":头晕:":"dizzy_face.png",":恶魔:":"imp.png",":恶魔微笑:":"smiling_imp.png",":闭嘴:":"neutral_face.png",' +
    '":捂嘴:":"no_mouth.png",":无辜:":"innocent.png",":外星人:":"alien.png",":黄心:":"yellow_heart.png",":蓝心:":"blue_heart.png",":紫心:":"purple_heart.png",' +
    '":红心:":"heart.png",":绿心:":"green_heart.png",":伤心:":"broken_heart.png",":心动:":"heartbeat.png",":爱心:":"heartpulse.png",":心心相印:":"two_hearts.png",' +
    '":心心相绕:":"revolving_hearts.png",":一见钟情:":"cupid.png",":闪闪发光的心:":"sparkling_heart.png",":星星:":"sparkles.png",":星星:":"star.png",":闪亮的星星:":"star2.png",' +
    '":流星:":"dizzy.png",":爆炸:":"boom.png",":怒:":"anger.png",":叹号:":"exclamation.png",":问号:":"question.png",":灰叹号:":"grey_exclamation.png",' +
    '":灰问号:":"grey_question.png",":睡着了:":"zzz.png",":快跑:":"dash.png",":汗水:":"sweat_drops.png",":音符:":"notes.png",":音乐:":"musical_note.png",":火:":"fire.png",' +
    '":屎:":"shit.png",":棒:":"thumbsup.png",":鄙视:":"thumbsdown.png",":ok:":"ok_hand.png",":拳头:":"punch.png",":加油:":"fist.png",' +
    '":耶:":"v.png",":挥手:":"wave.png",":手掌:":"hand.png",":打开手掌:":"open_hands.png",":上面:":"point_up.png",":下面:":"point_down.png",' +
    '":左面:":"point_left.png",":右面:":"point_right.png",":举双手:":"raised_hands.png",":祈祷:":"pray.png",":食指:":"point_up_2.png",":鼓掌:":"clap.png",' +
    '":努力:":"muscle.png",":摇滚:":"metal.png",":中指:":"fu.png",":跑步:":"runner.png",":情侣:":"couple.png",":家庭:":"family.png",' +
    '":兄弟:":"two_men_holding_hands.png",":姐妹:":"two_women_holding_hands.png",":跳舞:":"dancer.png",":兔女郎:":"dancers.png",' +
    '":好的:":"ok_woman.png",":不行:":"no_good.png",":服务员:":"information_desk_person.png",":举手:":"raising_hand.png",":婚纱:":"bride_with_veil.png",' +
    '":撅嘴:":"person_with_pouting_face.png",":皱眉:":"person_frowning.png",":低头:":"bow.png",":热吻:":"couplekiss.png",' +
    '":夫妻:":"couple_with_heart.png",":按摩:":"massage.png",":理发:":"haircut.png",":美甲:":"nail_care.png",":男孩:":"boy.png",":女孩:":"girl.png",' +
    '":女人:":"woman.png",":男人:":"man.png",":婴儿:":"baby.png",":老奶奶:":"older_woman.png",":老爷爷:":"older_man.png",":金发碧眼:":"person_with_blond_hair.png",' +
    '":瓜皮帽:":"man_with_gua_pi_mao.png",":包头巾:":"man_with_turban.png",":建筑工人:":"construction_worker.png",":警察:":"cop.png",":天使:":"angel.png",' +
    '":公主:":"princess.png",":食人魔:":"japanese_ogre.png",":妖精:":"japanese_goblin.png",":看不见:":"see_no_evil.png",":听不见:":"hear_no_evil.png",":不说话:":"speak_no_evil.png",' +
    '":士兵:":"guardsman.png",":骷髅:":"skull.png",":脚:":"feet.png",":唇:":"lips.png",":唇印:":"kiss.png",":水滴:":"droplet.png",":耳朵:":"ear.png",":眼睛:":"eyes.png",' +
    '":鼻子:":"nose.png",":舌头:":"tongue.png",":情书:":"love_letter.png"}}'

export default JSON.parse(str)