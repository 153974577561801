import React, { Component } from 'react';
import { Input, Form, Button, DatePicker, Radio } from 'antd';
import { pick } from 'lodash';
import ProvinceSelect from '../../components/province_select';
import CitySelect from '../../components/city_select';
import NationalitySelect from '../../components/nationality_select';
import EducationSelect from '../../components/education_select';
import DegreeSelect from '../../components/degree_select';
import DocumentTypeSelect from '../../components/document_type_select';
import NationSelect from '../../components/nation_select';
import PoliticsSelect from '../../components/politics_select';
import TeacherTypeSelect from '../../components/teacher_type_select';
import TeacherTitleSelect from '../../components/teacher_title_select';
import PersonnelStatusSelect from '../../components/personnel_status_select';
import TechnicalPositionSelect from '../../components/technical_position_select';
import FacultyTypeSelect from '../../components/faculty_type_select';
import CurrentPositionTypeSelect from '../../components/current_position_type_select';
import { addTeacher, getTeacherInfo, updataTeacher } from '../../api/school_settlement';
import Moment from 'moment';
import styles from './index.module.scss';

export class TeacherAdd extends Component {
	urlId = this.getUrlType(this.props.location.search,'id');
	userId = this.getUrlType(this.props.location.search,'userId');
	keyId = undefined;

	async componentDidMount() {
		this.urlId && this.userId && this.getTeacherInfo();
    };

	getTeacherInfo = async () => {
		const { setFieldsValue } = this.props.form;
		try {
			const res = await getTeacherInfo({enterpriseId:this.urlId,userId:this.userId});
			const values = pick(res, [
				'teacherName','telNum','sex','nationality','birthday','age','highestEducation','highestDegree','cardType',
				'idCard','nation','provinceId','cityId','polityFace','email','introduction','jobNum','joinTime','teacherType',
				'professionalCall','positions','ifOffice','schoolRunLevel','positionStatus','ifMainTeacher','technologyPost',
				'facultyCategory','postCategory',
			]);
			console.log(values,'vs');
			const keys = Object.keys(values);
			keys.forEach(it=>{
				if(values[it] === null){
					values[it] = undefined;
				};
			});
			values.joinTime = Moment(values.joinTime);
			values.birthday = Moment(values.birthday);
			values.cityId = String(values.cityId?values.cityId:'');
			values.provinceId = String(values.provinceId?values.provinceId:'');
			setFieldsValue({...values,native:[values.provinceId,values.cityId]});
			this.keyId = res.id;
		} catch (error) {
			console.warn(error);
		}
	};

	goBack = () => {
        this.props.history.goBack()
    };

	//提交表单
	onSubmit = async (flag) => {
		const {form:{validateFields,resetFields}} = this.props;
		try {
			const values = await validateFields();
			const { joinTime, birthday } = values || {};
			joinTime && (values.joinTime = joinTime.format('YYYY-MM-DD'))
			birthday && (values.birthday = birthday.format('YYYY-MM-DD'))
			values.enterpriseId = this.urlId;
			console.log(values,this.urlId);
			if(this.userId){
				await updataTeacher({...values,userId:this.userId,id:this.keyId});
				this.mesSuccess('教师信息修改成功');
				this.goBack();
			}else{
				console.log('走这了吗', values)
				await addTeacher(values);
				this.mesSuccess('教师信息新增成功');
				if(flag){
					this.goBack();
				}else{
					resetFields();
				}
			};
		} catch (error) {
			console.warn(error);
		}
	};

	//省市id变化
	provinceChange = (val) => {
		const {form:{getFieldValue,setFieldsValue,validateFields}} = this.props;
		const city = getFieldValue('cityId');
		if(!city)return;
		setFieldsValue({native:[val,undefined],cityId:undefined});
		validateFields(['native']);
	};

	//表单的间距配置
	formLayout = {
		labelCol: {
			xs: { span: 3 },
			sm: { span: 3 },
		},
		wrapperCol: {
			xs: { span: 4 },
			sm: { span: 4 },
		},
		colon:false
	};

	removeNbsp = e => {
		const val = e.target.value;
		if(typeof val !== 'string'){
			return val;
		};
		return val.replace(/\s+/g, "");
	}

	render() {

		const {
			form: {
				getFieldDecorator,
				getFieldValue,
				validateFields,
				setFieldsValue,
			}
		} = this.props;

		return (
			<div className={styles["teacher_add"]}>
				<Form {...this.formLayout}>
					<h1 style={{marginTop:0}}>教师信息</h1>
					<Form.Item label="教师姓名" className="max_height">
						{getFieldDecorator('teacherName', {
							rules: [
								{ required: true, message: '请输入教师姓名' }
							],
							getValueFromEvent:this.removeNbsp
						})(
							<Input
								className="max_input"
								placeholder='请输入教师姓名'
								size="large"
								maxLength={10}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("teacherName") ? getFieldValue("teacherName").length : 0}/
										<span style={{color: "#333"}}>10</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="手机号" className="max_height">
						{getFieldDecorator('telNum', {
							rules: [
								{
									required: true,
									validator:(_,val,callback) => {
										if(isNaN(val)){
											callback(Error('请输入正确的手机号'));
											return;
										};
										callback();
									},
								}
							],
							getValueFromEvent:this.removeNbsp
						})(
							<Input
								className="max_input"
								placeholder='请输入手机号'
								size="large"
								maxLength={11}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("telNum") ? getFieldValue("telNum").length : 0}/
										<span style={{color: "#333"}}>11</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="性别">
						{getFieldDecorator('sex',{
							rules: [
								{ required: true, message: '请选择性别' }
							],
						})(
							<Radio.Group>
								<Radio value={1}>男</Radio>
								<Radio value={0}>女</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					<Form.Item label="国籍" className="max_height">
						{getFieldDecorator('nationality',{
							rules: [
								{ required: false, message: '请选择国籍' }
							],
						})(
							<NationalitySelect
								style={{width:500}}
								placeholder="请选择国籍"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="出生日期">
						{getFieldDecorator('birthday')(
							<DatePicker
								style={{width:260}}
								placeholder="请选择出生日期"
								size='large'
								getCalendarContainer={triggerNode => triggerNode.parentElement}
							/>
						)}
					</Form.Item>
					<Form.Item label="年龄" className="max_height">
						{getFieldDecorator('age',{
							getValueFromEvent:this.removeNbsp,
							rules:[
								{
									validator:(_,val,callback) => {
										if(val && isNaN(val)){
											callback(Error('请输入正确的年龄'));
											return;
										};
										callback();
									},
								}
							]
						})(
							<Input
								className="max_input"
								placeholder='请输入年龄'
								size="large"
								maxLength={3}
								style={{width:500}}
							/>,
						)}
					</Form.Item>
					<Form.Item label="最高学历" className="max_height">
						{getFieldDecorator('highestEducation',{
							rules: [
								{ required: true, message: '请选择最高学历' }
							],
						})(
							<EducationSelect
								style={{width:500}}
								placeholder="请选择最高学历"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="最高学位" className="max_height">
						{getFieldDecorator('highestDegree',{
							rules: [
								{ required: true, message: '请选择最高学位' }
							],
						})(
							<DegreeSelect
								style={{width:500}}
								placeholder="请选择最高学位"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="证件类型" className="max_height">
						{getFieldDecorator('cardType',{
							rules: [
								{ required: true, message: '请选择证件类型' }
							],
						})(
							<DocumentTypeSelect
								style={{width:500}}
								placeholder="请选择证件类型"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="证件号码" className="max_height">
						{getFieldDecorator('idCard', {
							rules: [
								{
									required: true,
									validator:(_,val,callback) => {
										console.log(val);
										if(!val){
											callback(Error('请输入证件号码'));
											return;
										};
										if(getFieldValue("cardType") === 1){
											const _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
											const _IDre15 =  /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
											// 校验身份证：
											if(!(_IDRe18.test( val ) || _IDre15.test( val ))) {
												callback(Error('请输入正确的证件号码'));
												return;
											}
										};
										callback();
									},
								}
							],
							getValueFromEvent:this.removeNbsp
						})(
							<Input
								className="max_input"
								placeholder='请输入证件号码'
								size="large"
								maxLength={18}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("idCard") ? getFieldValue("idCard").length : 0}/
										<span style={{color: "#333"}}>18</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="民族" className="max_height">
						{getFieldDecorator('nation')(
							<NationSelect
								style={{width:500}}
								placeholder="请选择民族"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="籍贯" className="sheng_shi_qu">
						{getFieldDecorator('native',{
							rules: [
								{
									required: false,
									// validator:(rule,val=[],callback) => {
									// 	const [provinceId,cityId] = val;
									// 	const str = [];
									// 	if(provinceId && cityId){
									// 		callback();
									// 	}else{
									// 		if(!provinceId){
									// 			str.push('省份');
									// 		};
									// 		if(!cityId){
									// 			str.push('市区');
									// 		};
									// 		callback(Error('请选择' + str.join('和')));
									// 	}
									// }
								}
							],
						})(
							<>
								<Form.Item label={undefined}>
									{getFieldDecorator('provinceId')(
										<ProvinceSelect
											showSearch
											style={{ width: 250 }}
											placeholder="请选择省"
											optionFilterProp="children"
											onChange={this.provinceChange}
											size='large'
										/>
									)}
								</Form.Item>
								<Form.Item label={undefined} style={{marginLeft:20}}>
									{getFieldDecorator('cityId')(
										<CitySelect
											provinceId={getFieldValue('provinceId')}
											showSearch
											style={{ width: 250 }}
											placeholder="请选择市"
											optionFilterProp="children"
											size='large'
											onChange={val => {
												setFieldsValue({native:[getFieldValue('provinceId'),val]});
												validateFields(['native']);
											}}
										/>
									)}
								</Form.Item>
							</>
						)}
					</Form.Item>
					<Form.Item label="政治面貌" className="max_height">
						{getFieldDecorator('polityFace', {
							rules: [
								{ required: false, message: '请选择政治面貌' }
							],
						})(
							<PoliticsSelect
								style={{width:500}}
								placeholder="请选择政治面貌"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="邮箱" className="max_height">
						{getFieldDecorator('email',{
							getValueFromEvent:this.removeNbsp
						})(
							<Input
								className="max_input"
								placeholder='请输入邮箱'
								size="large"
								style={{width:500}}
								type="email"
							/>,
						)}
					</Form.Item>
					<Form.Item label="个人简介" className="max_text">
						{getFieldDecorator('introduction')(
							<Input.TextArea
								placeholder='请输入个人简介'
								size="large"
								maxLength={200}
								style={{height:90}}
							/>,
						)}
						<span className="max_length">
							{getFieldValue("introduction") ? getFieldValue("introduction").length : 0}/
							<span>200</span>
						</span>
					</Form.Item>
					<p className={styles["br"]}/>
					<h1>教师院校信息</h1>
					<Form.Item label="工号" className="max_height">
						{getFieldDecorator('jobNum', {
							rules: [
								{
									required: true,
									validator:(_,val,callback) => {
										if(!val){
											callback(Error('请输入工号'));
											return;
										};
										// if(!(/^(?=^.*?[a-zA-Z])(?=^.*?\d).{5,15}$/.test( val ))) {
										// 	callback(Error('工号需要数字5-15之间'));
										// 	return;
										// }
										callback();
									},
								}
							],
							getValueFromEvent:this.removeNbsp,
						})(
							<Input
								className="max_input"
								placeholder='请输入工号'
								size="large"
								maxLength={15}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("jobNum") ? getFieldValue("jobNum").length : 0}/
										<span style={{color: "#333"}}>15</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="入职日期">
						{getFieldDecorator('joinTime')(
							<DatePicker
								style={{width:260}}
								placeholder="请选择入职日期"
								size='large'
								getCalendarContainer={triggerNode => triggerNode.parentElement}
							/>
						)}
					</Form.Item>
					<Form.Item label="教师类型" className="max_height">
						{getFieldDecorator('teacherType',{
							rules: [
								{ required: true, message: '请选择教师类型' }
							],
						})(
							<TeacherTypeSelect
								style={{width:500}}
								placeholder="请选择教师类型"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="教师职称" className="max_height">
						{getFieldDecorator('professionalCall')(
							<TeacherTitleSelect
								style={{width:500}}
								placeholder="请选择教师职称"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="教师职务" className="max_height">
						{getFieldDecorator('positions',{
							getValueFromEvent:this.removeNbsp
						})(
							<Input
								className="max_input"
								placeholder='请输入教师职务'
								size="large"
								style={{width:500}}
							/>,
						)}
					</Form.Item>
					<Form.Item label="是否在编">
						{getFieldDecorator('ifOffice',{
							rules: [
								{ required: false, message: '请选择是否在编' }
							],
						})(
							<Radio.Group>
								<Radio value={1}>是</Radio>
								<Radio value={0}>否</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					<Form.Item label="所在办学层次">
						{getFieldDecorator('schoolRunLevel',{
							rules: [
								{ required: false, message: '请选择所在办学层次' }
							],
						})(
							<Radio.Group style={{width:300}}>
								<Radio value={3}>本科</Radio>
								<Radio value={2}>高职</Radio>
								<Radio value={1}>中职</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					<Form.Item label="人员状态" className="max_height">
						{getFieldDecorator('positionStatus',{
							rules: [
								{ required: false, message: '请选择人员状态' }
							],
						})(
							<PersonnelStatusSelect
								style={{width:500}}
								placeholder="请选择人员状态"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="是否为班主任" className="max_height">
						{getFieldDecorator('ifMainTeacher',{
							rules: [
								{ required: false, message: '请选择是否为班主任' }
							],
						})(
							<Radio.Group>
								<Radio value={1}>是</Radio>
								<Radio value={0}>否</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					<Form.Item label="专业技术职务" className="max_height">
						{getFieldDecorator('technologyPost')(
							<TechnicalPositionSelect
								style={{width:500}}
								placeholder="请选择专业技术职务"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="教职工类别" className="max_height">
						{getFieldDecorator('facultyCategory')(
							<FacultyTypeSelect
								style={{width:500}}
								placeholder="请选择教职工类别"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="现任岗位类别" className="max_height">
						{getFieldDecorator('postCategory')(
							<CurrentPositionTypeSelect
								style={{width:500}}
								placeholder="请选择现任岗位类别"
								size='large'
							/>
						)}
					</Form.Item>
				</Form>
				<div className={styles["file_footer"]}>
					<Button className={styles["btn_yes"]} size="large" type="primary" onClick={()=>this.onSubmit(true)}>确定{this.userId ? '修改' : '创建'}</Button>
					{!this.userId && <Button className={styles["btn_no"]} size="large" type="primary" ghost onClick={()=>this.onSubmit()}>保存并继续添加</Button>}
					<Button className={styles["btn_no"]} size="large" type="primary" ghost onClick={this.goBack}>取消</Button>
				</div>
				<div style={{height:60}}></div>
			</div>
		)
	}
}

export default Form.create()(TeacherAdd);
