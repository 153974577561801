import React, {Component} from 'react';
import {Col, Button, Radio, Input, Row, Select} from 'antd';
import MyTable from '@/components/Table';
import './index.scss';
import AddUserInfo from "./components/add-userinfo";
import {equipmentRegisterList, getProvinceList, getCityList, getOrganizationList} from '@/api/practTrain/index.js'
const {Option} = Select;

class HardwareResource extends Component {
    state = {
        addUserInfoVisible:false,
        organizationList: [],
        provinceList: [],
        cityList: [],
        progressVisible: false,
        dataSource: [],
        searchParams: {
            provinceId: '',
            cityId: '',
            orgId: '',
            keyword: ''
        },
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            pageSizeOptions: ["10", "20", "50", "100"]
        },
    }

    componentDidMount() {
        this.getProvinceList()
        this.getOrganizationList()
        this.getList()
    }

    async getProvinceList(){
        const provinceList = await getProvinceList()
        this.setState({
            provinceList
        })
    }
    async getOrganizationList(){
        const organizationList = await getOrganizationList({
            "enterpriseName": "",
            "enterpriseType": 1
        })
        this.setState({
            organizationList
        })
    }
    async getCityList(code){
        const cityList = await getCityList({provinceCode: code})
        this.setState({
            cityList
        })
    }
    async getList() {
        let {pagination, searchParams} = this.state
        const result = await equipmentRegisterList({
            pageNum: pagination.pageNum,
            pageSize: pagination.pageSize,
            ...searchParams
        })
        pagination.total = result.total
        this.setState({
            pagination: pagination,
            dataSource: result.data
        },()=>{
            window.elGoTop && window.elGoTop('body')
        })
    }
    //搜索
    searchValueChange(e) {
        let {searchParams}= this.state
        searchParams.keyword = e.target.value
        this.setState({
            searchParams
        })
    }
    provinceChange = code => {
        let searchParams = this.state.searchParams
        searchParams.provinceId = code
        searchParams.cityId = ""
        this.setState({
            cityList: [],
            searchParams
        }, () => {
            this.getList()
            if(code){
                this.getCityList(code)
            }
        })
    }
    cityChange = code => {
        let searchParams = this.state.searchParams
        searchParams.cityId = code
        this.setState({
            searchParams
        }, () => {
            this.getList()
        })
    }

    organizationChange = id => {
        let searchParams = this.state.searchParams
        searchParams.orgId = id
        this.setState({
            searchParams
        }, () => {
            this.getList()
        })
    }

    //取消弹框
    checkCancel = () => {
        this.setState({
            progressVisible: false,
            addUserInfoVisible:false
        })
    }
    //处理进度弹窗
    progress = () => {
        this.setState({
            progressVisible: !this.state.progressVisible,
        })
    }
    //新增设备注册弹窗
    addRegistration = () => {
        this.setState({
            addUserInfoVisible: !this.state.addUserInfoVisible,
        })
    }
    //分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination}, () => this.getList())
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const {pagination} = this.state;
        pagination.pageNum = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.getList())
    };

    render() {
        const { dataSource, searchParams, pagination, organizationList,provinceList,cityList,addUserInfoVisible} = this.state
        const columns = [
            {
                title: '序号', dataIndex: 'content', key: 'content', align: "center",
                render: (text, record, index) => <p>{index+1}</p>
            },
            {title: '设备主板号', dataIndex: 'equipmentBoard', key: 'equipmentBoard', align: "center",},
            {title: '设备名称', dataIndex: 'equipmentName', key: 'equipmentName', align: "center",},
            {title: ' 出库地址', dataIndex: 'address', key: 'address', align: "center",
                render: (text, record, index) => record.provinceName + record.cityName + record.detailAddress
            },
            {title: '所属学校', dataIndex: 'orgName', key: 'orgName', align: "center",},
            {title: '所属院系', dataIndex: 'faculty', key: 'faculty', align: "center",},
            {title: '注册设备时间', dataIndex: 'createTime', key: 'createTime', align: "center",},
            {title: '注册时所在IP', dataIndex: 'ipAdress', key: 'ipAdress', align: "center",
            render:(text, record, index) => <p>{record.ipAdress ? record.ipAdress :'------'}</p>
            },
            {
                title: '操作', dataIndex: 'operation', key: 'operation', align: "center", width: "200px", fixed: 'right',
                render: (text, record, index) => <p className='action vertical_j'>
                    <React.RenderButton text='查看详情' id="btn_Show03">
                        <span className="btn-disabled">查看详情</span>
                    </React.RenderButton>
                    <React.RenderButton text='修改' id="btn_Updata04">
                        <span className="btn-disabled">修改</span>
                    </React.RenderButton>
                </p>
            }
        ]
        const {$$img} = window
        const { RenderButton } = React;
        return (
            <div className={'applayMg training-warp'}>
                <h2 className={'training-tit'}>zSpace设备到平台注册</h2>
                <div className="applayMg-header">
                    <Row className="navtop">
                        <Col span={20} className="navtop-flex">
                            <div>
                                <span className="w100">所在省市</span>
                                <Select placeholder="请选择所在省市" className="w260" value={searchParams.provinceId}
                                        onChange={this.provinceChange}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    <Option value="">全部</Option>
                                    {provinceList && provinceList.length ? provinceList.map((item, index) => {
                                        return <Option key={index} value={item.provinceCode}>{item.name}</Option>
                                    }) : ""}
                                </Select>
                                <Select placeholder="请选择市" className="w260" value={searchParams.cityId}
                                        onChange={this.cityChange}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    <Option value="">全部</Option>
                                    {cityList && cityList.length ? cityList.map((item, index) => {
                                        return <Option key={index} value={item.cityCode}>{item.name}</Option>
                                    }) : ""}
                                </Select>
                            </div>
                            <div>
                                <span className="w100">所在学校</span>
                                <Select placeholder="请选择所在学校" className="w260" onChange={this.organizationChange}
                                        value={searchParams.orgId}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    <Option value=''>不限</Option>
                                    {organizationList && organizationList.length ? organizationList.map((item, index) => {
                                        return <Option key={index} value={item.id}>{item.enterpriseName}</Option>
                                    }) : ""}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="training-top">
                    <Col span={6}>
                        <React.RenderButton text='新增设备注册' id="btn_zSpaceRegister01">
                            <Button type='primary' onClick={()=>this.addRegistration()}>新增设备注册</Button>
                        </React.RenderButton>
                    </Col>
                    <Col className='training-top-r' span={18}>
                        <React.RenderButton text='搜索' id="btn_zSpaceSearch02">
                        <Input style={{width: "360px", marginRight: "12px"}}
                               prefix={<img src={$$img('cvec-space-front-guanli/zhihuishixun/search.svg')} alt=""/>}
                               placeholder='请输入学校名称或者主板号'
                               value={searchParams.keyword} onChange={this.searchValueChange.bind(this)}
                               onPressEnter={() => this.getList()}/>
                        </React.RenderButton>
                        <label>共查询到{pagination.total}台设备注册</label>
                        {/*<Button type='primary' onClick={() => getData()} className='search'>查询</Button>*/}
                    </Col>
                </div>
                <div className={'check_container training-table '} style={{position: 'relative'}}>
                    <MyTable
                        options={
                            {
                                dataSource,
                                columns,
                                pagination: {onChange: this.pageChange, ...pagination,onShowSizeChange: this.onShowSizeChange, showSizeChanger: true,}
                            }
                        }
                        isDrab={false}
                        fixededit={true}
                    />
                </div>
                {/*新增设备注册弹窗 start*/}
                <AddUserInfo
                    addUserInfoVisible={addUserInfoVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    getList={this.getList.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*新增设备注册弹窗 end*/}
            </div>
        );
    }
}

export default HardwareResource;
