



import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/check/action';
import './index.scss';
import {Button, Input, Col, Checkbox, Tag, Form, DatePicker, message, Select,Table,} from 'antd';
import kindName from 'classnames';
import MyTable from '@/components/Table';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import { wholeCircleList, circleList,banCircle,unsealCircle,deleteCircle, closedCircle, hasAuditList, noAuditList,showTopicDetail,auditPass,auditRefuse, blackList, orgList, } from '@/api/circle/index'

import {tupOff,tupOffWait,getLastProcessType,getTipOffType} from '@/api/report/report'
import moment from 'moment';
//import ReportReason from './components/index'
const {Option} = Select
const {RangePicker} = DatePicker;
const { Column } = Table;

const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


class Manage extends React.Component {
    state = {
        btnDisabled:true,  //重置按钮
        daiNumber:'',  // 待处理长度
        yiNumber:'',   //已处理长度
        _tableColumns:[],
        _tabledata:[],
        _noAuditListData:[], 
        _noAuditListNumber:'',      //分页
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tipOffType: undefined, //举报类型
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        },
        processLists:[],
        dateString:'',
        entity: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        reason:'',  //资源冻结原因
        reasonNum: 0,  //资源冻结原因字数
        ids: [],
        offLists:[],  //资源举报列表
        businessId:'',  //业务id
        dataSource: [], //表格数据
        startColumns: [
            {title: '圈子封面', dataIndex: 'circlePhoto', key: 'circlePhoto'},
            {title: '圈子名称', dataIndex: 'circleName', key: 'circleName'},
            {title: '圈子ID', dataIndex: 'circleID', key: 'circleID'},
            {title: '圈主', dataIndex: 'circleManager', key: 'circleManager', align: 'center',},
            {title: '所在院校', dataIndex: 'circleCollege', key: 'circleCollege   ', align: 'center'},

            {title: '创建日期', dataIndex: 'circleDate', key: 'circleDate   ', align: 'center'},
            {title: '公开范围', dataIndex: 'circleScope', key: 'circleScope   ', align: 'center'},
            {title: '成员量', dataIndex: 'chenyuanNumber', key: 'chenyuanNumber   ', align: 'center'},
            {title: '浏览量', dataIndex: 'liulanNumber', key: 'liulanNumber   ', align: 'center'},
            {title: '话题数', dataIndex: 'topicNumber', key: 'topicNumber   ', align: 'center'},
            {title: '评论数', dataIndex: 'commentNumber', key: 'commentNumber   ', align: 'center'},

        ],
        endColumns: [
            // {title: '举报类型', dataIndex: 'tipOffType', key: 'tipOffType', align: 'center',
            //     render:(text, record) => this.state.reportTypeMap[text]
            // },
            // {title: '最后处理时间', dataIndex: 'lastProcessTime', key: 'lastProcessTime', align: 'center',},
            {
                title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                width: 240,
                render: (text, record) => <div className='action vertical_j'>
                    <p>
                        {/* <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
                        {this.state.activeIndex === 1 ? <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span> : ""}
                        {
                            this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
                              <span onClick={() => this.reasonShow(record)}>冻结</span> : ''
                        } */}
                        <span >进入圈子</span>
                        <span >封禁圈子</span>
                        <span >解散圈子</span>
                    </p>
                </div>
            }
        ],
        columns: [],
        _tableColumns:[
            {title: '话题标题', dataIndex: 'topicTitle', key: 'topicTitle'},
            {title: '所在圈子名称', dataIndex: 'circleName', key: 'circleName'},
            {title: '所在院校', dataIndex: 'anonyName', key: 'anonyName'},
            {title: '话题作者', dataIndex: 'orgId', key: 'orgId', align: 'center',},
            {title: '发布日期', dataIndex: 'createTime', key: 'createTime   ', align: 'center'},

            {
                title: '操作', dataIndex: 'address', key: 'address', align: 'center',  width:"18%",
                render: (text, record) => <p className='action vertical_j'>
                    <span >新增</span>
                    <span >编辑</span>
                    <span >删除</span>
                </p>
            }
            // {
            //     title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
            //     width: 240,
            //     render: (text, record) => <div className='action vertical_j'>
            //         <p>
            //             {/* <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
            //             {this.state.activeIndex === 1 ? <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span> : ""}
            //             {
            //                 this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
            //                   <span onClick={() => this.reasonShow(record)}>冻结</span> : ''
            //             } */}
            //             <span >查看话题详情</span>
            //             <span >审核通过</span>
            //             <span >拒绝</span>
            //         </p>
            //     </div>
            // }
        ],
        _tabledata:[ ],
        reportTypeData:[],
        reportTypeMap: {},
        processModeData:[],
        processModeMap: {}
    }
    componentDidMount() {
         
        this.noAuditList()
         
    }


        //全部的圈子列表01
        async noAuditList() {
            let { pagination} = this.state;
            const res = await noAuditList({...pagination});    
            let noAuditListMap = {}
            //
            console.log(res,"--------------");
            
            if (res && res.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
                pagination.total = res.total;
                pagination.pageSize = res.pageSize;
                pagination.pageNum = res.pageNum;
                this.setState({
                    _noAuditListData:res.data,
                    _noAuditListNumber:res.total,
                    pagination,

                    
                });
            }
            
        }

    

    initColumns() {
        //
        const {activeIndex, startColumns, endColumns,_noAuditListData} = this.state
        let column = []
         
        this.setState({
            //columns: startColumns.concat(column).concat(endColumns)
            columns: _noAuditListData.concat(column)
        })
    }
    async getList(){
        const {activeIndex, pagination} = this.state
        if(activeIndex === 0){
            const result = await this.tupOffWait()
            this.setState({
                //dataSource: result.data,
            })
        }else{
            const result = await this.tupOff()
            this.setState({
                //dataSource: result.data,
            })
        }
    }
    

    //话题审核通过
    handleauditPass = (id) => {
        //debugger
        console.log(id)
        let that = this;
        // console.log(id)
        auditPass({"topicId":id}).then((res) => {
            console.log(res)
            if (res.code == 0) {
                message.success("删除成功");
                that.noAuditList();
            }
            else{
                console.log(res)
                //message.success("删除成功");
            }
        });
    };
    
    
    //话题审核拒绝
    handleauditRefuse = (id) => {
        //debugger
        console.log(id)
        let that = this;
        // console.log(id)
        auditRefuse({"topicId":id}).then((res) => {
            console.log(res)
            if (res.code == 0) {
                message.success("删除成功");
                that.noAuditList();
            }
            else{
                console.log(res)
                //message.success("删除成功");
            }
        });
    };
    

    //分页
    pageChange = (pageNumber) => {
        let {pagination} = this.state;
        pagination.pageNum = pageNumber;
        
        console.log(pageNumber);
        //this.noAuditList()
        this.setState({pagination: pagination}, () => this.noAuditList({
            pageNum:pageNumber,
        }))
    }
    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let alreadyFreeze = selectedRows.filter(row => row.lastProcessType === "1")
        if(alreadyFreeze.length > 0){
            return message.warning('不要选择已经冻结的数据');
        }
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({selectedRowKeys, expandedRowKeys})
    }
    //搜索选项改变
    reportChange = ({target: {value}}) => {
        let {pagination} = this.state;
        pagination.searchValue = value
        this.setState({
            pagination,
            btnDisabled: false
        });
    };
    //已完成  待完成切换
    changeTabMenu = (key) => {
        this.setState({
            selectedRowKeys: [],
            expandedRowKeys: [],
            activeIndex: key
        }, () => {
            this.initColumns()
            this.resetSearch()
        })
    };
    resetSearch() {
        let pagination = {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tipOffType: undefined, //举报类型
            processState: 0, //处理状态
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processTime: undefined,  //时间
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        };
        this.setState({
            pagination: pagination,
            btnDisabled: true
        }, () => this.getList())
    }

    //全选反选切换
    checkedAll = () => {
        let {selectedRowKeys, dataSource} = this.state;
        if (selectedRowKeys.length === dataSource.length) {
            this.setState({selectedRowKeys: [], expandedRowKeys: []});
            return;
        }
        const keys = Object.keys(dataSource);
        const index = [];
        keys.forEach(item => {
            index.push(Number(item));
        });
        this.setState({
            selectedRowKeys: index,
            expandedRowKeys: dataSource.map(v => v.resourceId)
        });
    }
    //冻结原因
    reasonShow(row) {
        this.setState({
            ids: [row.resourceId],
            visible: true
        })
    }
    //批量冻结原因
    reasonBatchShow = () => {
        const {expandedRowKeys} = this.state
        if (!expandedRowKeys.length) {
            return message.warning('请选择要冻结的资源');
        }
        this.setState({
            ids: expandedRowKeys,
            visible: true
        })
    }

    checkCancel = () => {
        this.setState({
            visible: false
        })
    }
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        }, () => this.getList())
    }
    //举报类型改变
    reportTypeChange = (value) => {
        let {pagination} = this.state;
        pagination.tipOffType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //处理方式改变
    reportModeChange = (value) => {
        let {pagination} = this.state;
        pagination.lastProcessType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //日期查询
    reportTimeChange = (value, dateString)=> {
        let {pagination} = this.state;
        pagination.processTime = value
        pagination.processStartTime = dateString[0]
        pagination.processEndTime = dateString[1]
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList())
    }
    render() {
        const {columns, selectedRowKeys, activeIndex, dataSource, visible,reportTypeData,processModeData,pagination, reason, reasonNum,_tableColumns, _tabledata,_noAuditListData,_noAuditListNumber,} = this.state;
        const formItemLayout = {
            labelCol: {span: 0},
            wrapperCol: {span: 24},
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle check_box report'>
                
                <div className="p20 pright40 bg_fff m20">
                    <div className="top_01 mbottom20">
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>圈子名称</span>
                            <Input style={{ width: '80%' }} placeholder='请输入圈子名称' />
                        </Col>

                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>话题作者</span>
                            <Input style={{ width: '80%' }} placeholder='请输入话题作者名称' />
                        </Col>

                        <Col span={7}>

                            <span style={{ marginRight: '20px' }}>所属机构</span>
                            <Select placeholder='请选择身份类型' style={{ width: '80%' }} onChange={e => {
                                this.setState({ identityId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                <Option value="">全网公开</Option>
                                <Option value="">机构公开</Option>
                                {
                                    // statusList.map(v => {
                                    //     return <Option value={v.code} key={v.code}>{v.name}</Option>
                                    // })
                                }
                            </Select>
                        </Col>

                    </div>

                    <div className="top_02 ">
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>创建日期</span>
                            <RangePicker onChange={this.reportTimeChange} value={pagination.processTime}
                                style={{ width: '80%', }} />
                        </Col>

                        <div className="mright20">
                            <Button className='reset_btn' onClick={() => this.resetSearch()} disabled={this.state.btnDisabled}>重置</Button>
                            <Button type='primary' className='ant_blue' style={{ marginLeft: '12px' }} onClick={() => this.queryBtn()}>查询</Button>
                        </div>

                    </div>
                </div>
                

                <div className="wrapper padd">
                     
                     

                    <div className="">
                        <Table className="circle" dataSource={_noAuditListData} 
                        //pagination= { this.state.pagination}
                        pagination={{
                            pageNum: 1,
                            pageSize: 10,
                            total: this.state.pagination.total,
                            onChange:this.pageChange,
                            //onChange: this.affiliatedTableTableChangePage,
                        }}
                            >
                            <Column title="话题标题" dataIndex="topicTitle" key="topicTitle" />
                            <Column title="所在圈子名称" dataIndex="circleName" key="circleName" />
                            <Column title="所在院校" dataIndex="orgId" key="orgId" />
                            <Column title="话题作者" dataIndex="userName" key="userName" />
                            <Column title="发布日期" dataIndex="createTime" key="createTime" />
                            <Column
                                title="操作"
                                dataIndex="address"
                                key="6"
                                align='center'
                                render={(e, item) => {
                                    //console.log(item.topicId,'789456');
                                    return (
                                        <p className='action vertical_j'>
                                            <span  onClick={(id) => this.props.history.push(`/topic-audit-detail/${item.topicId}`)}>查看话题详情</span>
                                            <span onClick={(id) => this.handleauditPass(item.topicId)} >审核通过</span>
                                            <span onClick={(id) => this.handleauditRefuse(item.topicId)} >拒绝</span>
                                        </p>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                </div>
                 
            </div>
        )
    }
}


/*Check = connect(mapStateToProps, mapDispatchToProps)(Check);
export default Check;*/
Manage = connect(mapStateToProps, mapDispatchToProps)(Manage);
Manage = Form.create()(Manage)
export default Manage;



