import LoadableComponent from '@/until/LoadableComponent';

const AuthorityManagement = LoadableComponent(() => import('./pages/AuthorityManagement/AuthorityManagement'));  //活动
const Menu = LoadableComponent(import('./pages/Menu/Menu.js'));
const RoleJoin = LoadableComponent(import('./pages/RoleJoin/RoleJoin.js'));
const UserAssignment = LoadableComponent(import('./pages/UserAssignment/UserAssignment.js'));
const UserList = LoadableComponent(import('./pages/UsreList/UserList'));
const ConfiguringApplication = LoadableComponent(import('./pages/ConfiguringApplication/ConfiguringApplication'))
const ApplicationConfigManagement = LoadableComponent(import('./pages/ApplicationConfigManagement/ApplicationConfigManagement'))
const RoleEdit = LoadableComponent(import('./pages/RoleEdit/RoleEdit'))

export const AuthorityManagementRouter = [
    {
        name: '权限管理',
        path: '/main/AuthorityManagement/test',
        component: AuthorityManagement,
        children: []
    },
    {
        name: '菜单管理',
        path: '/main/capacity-auth/menu',
        component: Menu,
        children: []
    },
    {
        name: '角色接入管理',
        path: '/main/capacity-auth/role-join/:roleId',
        component: RoleJoin
    },
    {
        name: '用户分配',
        path: '/main/capacity-auth/user-assignment/:roleId/:enterpriseId/:organizationName',
        component: UserAssignment
    },
    {
        name: '用户列表',
        path: '/main/capacity-auth/user-list/:roleId/:enterpriseId/:roleName/:organizationName',
        component: UserList
    },
    {
        name: '菜单详情',
        path: '/main/capacity-auth/menu-config/:id/:appName/:readonly',
        component: ConfiguringApplication,
    },
    {
        name: '菜单管理',
        path: '/main/capacity-auth/menu-config/:id/:appName',
        component: ConfiguringApplication,
    },
    {
        name: '配置管理',
        path: '/main/capacity-auth/configuration',
        component: ApplicationConfigManagement
    },
    {
        name: '查看角色',
        path: '/main/capacity-auth/edit-role/:id/:readonly',
        component: RoleEdit
    },
    {
        name: '编辑角色',
        path: '/main/capacity-auth/edit-role/:id',
        component: RoleEdit
    },
    {
        name: '创建角色',
        path: '/main/capacity-auth/edit-role',
        component: RoleEdit
    },

]

