
import { SET_MODULE, SET_SELECT } from './action_type'
export const SET_MODULEDfn = (data) => ({
    type: SET_MODULE,
    data: data
});

export const SET_SELECTfn = (data) => ({
    type: SET_SELECT,
    data: data
});            