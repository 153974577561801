/*专题配置*/
import React, { Component } from 'react';
import {Form, Select, Button, Table, Spin} from 'antd';
import './index.scss';
import {getNewsSpecialList, getPlatformList, deleteNewsSpecial, getSpecialDetail} from "../../../../api/zixun/index"
import RenderButton from "../../../../components/renderButton";
import RenderImg from '../../../../components/renderImg/index'
const { Option } = Select;

class ThematicConfiguration extends Component {
    state = {
        pagination : {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            current: 1,
        },
        columns: [
            {
                title: '封面',
                dataIndex: "specialImg",
                align: 'center',
                key: "specialImg",
                width: 150,
                render: (text, record) => {
                    // console.log("record.specialImg11",record.specialImg)
                    return (
                        <RenderImg type={record.mediaType} size='s' id={record.specialImg}/>
                    )
                },
            },
            // {title: '封面', dataIndex: 'specialImg', key: 'specialImg', align: 'center'},
            {title: '专题名称', dataIndex: 'specialName', key: 'specialName', align: 'center',ellipsis: true},
            {title: '所属业务系统', dataIndex: 'platformName', key: 'platformName', align: 'center',},
            {title: '新闻数量', dataIndex: 'newsNum', key: 'newsNum', align: 'center',},
            {title: '创建时间', dataIndex: 'createTime', key: 'createTime', align: 'center'},
            {
                title: '操作',  align: 'center',
                width: 220,
                render: (text, record) => {
                    return <p className='action vertical_j'>
                        {/*<span onClick={() => this.showDeleteSpecialConfirm(record)}>删除</span>*/}
                        {/*<span onClick={() => this.lookSpecialDetail(record)}>查看</span>*/}
                        {/*<span onClick={() => this.manageSpecialColumn(record)}>管理</span>*/}
                        <RenderButton id='NC_01_B09_c_btndelete'>
                            <span onClick={() => this.showDeleteSpecialConfirm(record)}>删除</span>
                        </RenderButton>
                        <RenderButton id='NC_01_B08_c_btnlook'>
                            <span onClick={() => this.lookSpecialDetail(record)}>查看</span>
                        </RenderButton>
                        <RenderButton id='NC_01_B08_c_btnmanagement'>
                            {/*<span onClick={(id*/}
                            {/*)=>this.props.history.push(`/main/group-column/thematicConsole?${decodeURI(JSON.stringify({ specialId: record.specialId }))}`)}>管理</span>*/}
                            <span onClick={() => this.manageSpecialColumn(record)}>管理</span>
                        </RenderButton>
                        {/*<span onClick={() => this.showDeleteSpecialConfirm(record)}>删除</span>*/}
                        {/*<span>查看</span>*/}
                        {/*<span>管理</span>*/}
                    </p>
                }
            }
        ],
        dataSource: [],
        tableLoading: false, // 是否显示加载中
        platformList :[],
        platform : '',
        specialNum : 0,
    }

    componentDidMount() {
        let platformData = {
            queryType : 1,
            organizationId : ''
        }
        getPlatformList(platformData).then(res =>{
            if(res){
                this.setState({
                    platformList : res
                })
            }
        })

        this.specialList()
    }


    specialList = () =>{
        let pagination = this.state.pagination
        let data = {
            platform : this.state.platform,
            organizationId : '',
            pageNum: this.state.pagination.pageNum,
            pageSize: this.state.pagination.pageSize,
        }
        getNewsSpecialList(data).then(res =>{
            if(res){
                console.log("res:",res)
                pagination.total = res.total
                this.setState({
                    dataSource : res.data,
                    specialNum : res.total,
                    pagination : pagination
                })
            }
        })
    }

    lookSpecialDetail = (record) =>{
        this.props.history.push(`/main/group-column/specialTopic?${decodeURI(JSON.stringify({ specialId: record.specialId }))}`)
    }

    manageSpecialColumn = (record) =>{
        this.props.history.push(`/main/group-column/thematicConsole?${decodeURI(JSON.stringify({ specialId: record.specialId }))}`)
    }

    changePlatform = (e) =>{
        console.log("e::", e)
        let pagination = this.state.pagination
        pagination.pageNum = 1
        pagination.pageSize = 10
        pagination.total = 0
        this.setState({
            platform : e,
            pagination: pagination
        }, () => this.specialList())
    }

    showDeleteSpecialConfirm = (record) => { // 删除提示
        this.hint({
            'title': '温馨提示',
            'content': '您是否要删除此专题？删除后不可找回',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '删除',
            'isCancel': true,
            'sure': () => {this.deleteSpecial(record)}
        })
    }

    deleteSpecial = (record) => {
        let data = {
            id: record.specialId
        }
        deleteNewsSpecial(data).then(res => {
            if (res && res > 0) {
                this.mesSuccess("删除成功")
                let pagination = this.state.pagination
                pagination.pageNum = 1
                pagination.pageSize = 10
                pagination.total = 0
                this.setState({
                    pagination: pagination
                }, () => this.specialList())
            }
        })
    }
    // 分页
    changePage = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination},()=> this.specialList())
    }

    showSizeChange = ( pageNum,size,) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            console.log("pageSize",this.state.pageSize)
            this.specialList()
        })
    }

    render() {
        let { columns,total,pageSize,dataSource,pageNum,tableLoading } = this.state;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
            onChange: this.changePage,
            onShowSizeChange: this.showSizeChange
        }
        return (
          <div className='thematicConfiguration'>
              <div className="filter_controll">
                  <div className="item_wapper" style={{marginBottom: '20px'}}>
                      <div>
                          <span style={{ marginRight: '20px' }}>请选择业务系统</span>
                          <Select placeholder='请选择业务系统' onChange={e => this.changePlatform(e)}
                          getPopupContainer={triggerNode => triggerNode.parentNode || document.body}>
                              <Option value={''}>全部业务系统</Option>
                              {
                                  this.state.platformList.map(p => <Option key={p.platform} value={p.platform}>{p.platformName}</Option>)
                              }
                          </Select>
                      </div>
                  </div>
              </div>
              <div className="in_school_content">
                  <div className='top'>
                      <div className="left">
                          {/*<Button type="primary" onClick={()=> this.props.history.push('/main/group-column/addThematic')}>新增专题</Button>*/}
                          <RenderButton id='NC_01_B08_c_btnConfirmrelease'>
                              <Button type="primary" onClick={()=> this.props.history.push('/main/group-column/addThematic')}>新增专题</Button>
                          </RenderButton>
                      </div>
                      <div className="right">
                          <span className='all_total'>共{this.state.specialNum}条专题资讯</span>
                      </div>
                  </div>
                  <div className="bottomss">
                      {
                        tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                            <div className='all_wapper'>
                              <Table
                                  className='organ-table organ-table-live'
                                  columns={columns}
                                  dataSource={dataSource}
                                  pagination ={pagination}
                                  rowKey={(v) => v.specialId}
                                  locale={{
                                      emptyText: <>
                                          <div className="Empty-conet-zixun">
                                              <div className="img">
                                                  <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                                  <span>暂无数据</span>
                                              </div>
                                          </div>
                                      </>
                                  }}
                                 />
                            </div>
                        )
                      }
                  </div>
              </div>
          </div>
        )
    }
}

ThematicConfiguration = Form.create()(ThematicConfiguration);
export default ThematicConfiguration;
