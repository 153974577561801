import React, { Component } from 'react'
import { Button, Form, Input } from 'antd';
import { Sloved, UnSloved } from '../../../components/svgConfig/index';
import { HighEditor } from '../../../components/global_components/editor/index'
import {
    getFeedbackInfo,
    saveReplyInfo
} from '../../../api/feedbackCenter/index'
import './index.scss'
import TopTitle from "../../../components/Breadcrumbtongyong";


export default class feedBackDetail extends Component {
    state = {
        isSloved: true,
        visible: false,
        preVisible: false,
        responseShow: false,
        details: '',
        info: {}
    }
    componentDidMount() {
        this.getResponseShow();
        this.getFeedbackInfo();
    }
    //详情信息
    getFeedbackInfo = () => {
        getFeedbackInfo({ id: this._get_query().id }).then(res => {
            if (res) {
                this.setState({
                    info: res
                })
            }
        })
    }
    // 获取地址栏参数
    _get_query() {
        let o = window.location.href.split("?")[1].split("&")
        let obj = {}
        o.map(v => {
            obj[v.split("=")[0]] = v.split("=")[1]
        })
        return obj;
    }
    // 判断回复框显示
    getResponseShow = () => {
        if (this._get_query() && this._get_query().type === "1") {
            this.setState({ responseShow: true })
        } else {
            this.setState({ responseShow: false })
        }
    }
    //回复
    release = () => {
        const { info, details } = this.state;
        saveReplyInfo({
            id: info.id,
            feedbackInfo: details,
            feedbackOrReply: 1
        }).then(res => {
            if (res && res === 'success') {
                this.setState({ details: '' }, () => {
                    this.mesSuccess('回复成功');
                    this.getFeedbackInfo()
                    this.footEdit.clear()
                })
            } else {
                this.mesWarning('回复失败');
            }
        })
    }
    render() {
        const { info } = this.state;
        const topTitleData = {
            span_1: "通用管理",
            span_2: "反馈中心",
            span_3: "反馈消息列表",
            span_4: "反馈详情",
            span_3_fun: res => {
                this.props.history.go(-1)
            },
            span_1_c: "#333",
            span_2_c: "#333",
            span_3_c: "#333",
            container:"反馈详情",
        };
        return (
            <div className={'feedBackDetail_con'}>
                <TopTitle {...topTitleData} />
                <div className="feedBackDetail">
                    <div className='return_wapper' onClick={() => this.props.history.goBack()}>
                        <img src={require('@/assets/img/zixun/return.png')}/>
                        <span>返回</span>
                    </div>
                    <div className="fbd_content">
                        {
                            info.solveStatus === 1 ?
                                <div className="slove">
                                    <Sloved />
                                </div>: info.solveStatus === 2 ?
                                    <div className="slove">
                                        <UnSloved />
                                    </div>: null
                        }
                        <div className="question_type">
                            <span>反馈类型：{info.typeName || '--'}</span>
                            <span>|</span>
                            <span>编号：{info.id || '--'}</span>
                            <span>|</span>
                            <span>反馈时间：{info.createTime || '--'}</span>
                        </div>
                        <div className="fdb_info">
                            <h2 className="q_title">
                                {info.feedbackTitle || '--'}
                                {info.isReply === 1 && <span className="q_status">已回复</span>}
                                {info.isReply === 0 && <span className="q_status" style={{ background: "#FCEDED", color: "#E34949" }}>待回复</span>}
                            </h2>
                            <p className="q_content"> {info.feedbackInfo || '--'}</p>
                            {info.feedbackImg && JSON.parse(info.feedbackImg).length > 0 && <div className="q_img">
                                {
                                    info.feedbackImg && JSON.parse(info.feedbackImg).map((item, key) => {
                                        return <img key={key} src={window.$$imgSrc(item.fpName || "base/us/image/4e0f19d3ed3044fc82c55c74b32ad78f.jpg")} alt="" />
                                    })
                                }
                            </div>}
                        </div>
                        <div className="fbd_record">
                            <h3 className="revord_title">反馈记录</h3>
                            {
                                info.subFeedbackVos && info.subFeedbackVos.slice(1).length > 0 ? info.subFeedbackVos.slice(1).map((item, key) => {
                                    return <div key={key} className="user">
                                        <div className="userinfo">
                                            <div className="header_img">
                                                <img src={item.portraitId ? window.$$imgSrc(item.portraitId) : window.$$setImgSrc('AE_default_head.jpg')} alt="" />
                                            </div>
                                            <div className="uname">
                                                <span>{item.createUsername || '--'}</span>
                                                <span>{item.createTime || '--'}</span>
                                            </div>
                                        </div>
                                        <div className="border">
                                            <div className="describe" dangerouslySetInnerHTML={{ __html: item.feedbackInfo }} style={{ whiteSpace: 'break-spaces' }}></div>
                                            {JSON.parse(item.feedbackImg) && JSON.parse(item.feedbackImg).length > 0 && <div className="imginfo">
                                                {
                                                    JSON.parse(item.feedbackImg) && JSON.parse(item.feedbackImg).map((i, k) => {
                                                        return <img key={k} src={window.$$imgSrc(i.fpName || "base/cbs/image/70d8a2a7a67c4a95ba3462356f3ede08.jpg")} alt="" />
                                                    })
                                                }
                                            </div>}
                                        </div>
                                    </div>
                                }) : <div className="noFB">
                                    <img src={window.$$setImgSrc("CS_nodataImg.png")} alt="" />
                                </div>
                            }
                        </div>
                        {this.state.responseShow && <div className="editor">
                            <div className="footEdit">
                                <img src={info.portraitId ? window.$$imgSrc(info.portraitId) : window.$$setImgSrc('AE_default_head.jpg')} alt="" />
                                <HighEditor montedNode={'footEdit'}
                                            ref={ref => this.footEdit = ref}
                                            zIndex={1000}
                                            placeholder={"请输入内容"}
                                            height="200"
                                            isFocus={true}
                                            onChange={(e) => {
                                                this.setState({
                                                    details: e
                                                })
                                            }}
                                />
                            </div>
                            <div className="footBtn">
                                <Button type="primary" onClick={() => this.release()}>发布</Button>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}
