import React from 'react';
import './index.scss';
import MyTable from '@/components/Table';
import {connect} from 'react-redux';
import {Button, Input, Col, Select, Modal, message, Form, Radio, DatePicker} from 'antd';
import {Checkbox} from 'antd';
import setName from 'classnames';
import {Link} from "react-router-dom";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {getOffShel,shareResource,resourceShelfOperate} from '@/api/grounding'
const {Option} = Select;
const {RangePicker} = DatePicker;
const {TextArea} = Input;
const mapStateToProps = ({UPLOADING}) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {};
}

class Undercarriage extends React.Component {
    state = {
        params: {
            mediaType: "",
            pageNum: 1,
            pageSize: 10,
            pressType: "",
            resouceCategorySeniorId: "",
            resouceCategorySubId: "",
            searchValue: "",
            shelfDateEnd: "",
            shelfDateStart: ""
        },
        btnDisabled:true,  //重置按钮
        dataSource: [],
        ids: [],
        classifyData: [
            {typeName:'国际货运代理实务'},
            {typeName:'国际货运代理实务'},
            {typeName:'国际货运代理实务'}
        ],//分类
        isBlock: false,
        lookVisible: false,  //下架弹窗
        visibily: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        columns: [
            {title: '资源名称', dataIndex: 'resourceName', key: '0'},
            {title: '发布人', dataIndex: 'createName', key: '1', align: 'center',},
            {title: '资源类型', dataIndex: 'formDetail', key: '2', align: 'center',},
            {title: '资源分类', dataIndex: 'resourceHotSeniorName', key: '3', align: 'center',},
            {title: '作者', dataIndex: 'authorName', key: '4', align: 'center',},
            {title: '资源格式', dataIndex: 'resourceType', key: '5', align: 'center',},
            {title: '共享状态', dataIndex: 'unitType', key: '6', align: 'center', render: (data) => {return <div>{data === 2 ?'机构':'平台' }</div>}},
            {title: '下架原因', dataIndex: 'shelvesReason', key: '7', align: 'center',},
            {title: '下架时间', dataIndex: 'operationTime', key: '8', align: 'center',},
            {title: '操作', dataIndex: 'address', key: '9', fixed: 'right', align: 'center',
                width: 240,
                render: () => {
                    return <div className="action vertical_j">
                        <span onClick={() => this.props.history.push('/transResource/undercarriage')}>查看</span>
                        <span onClick={this.lookModal} className="textStyle">上架</span>
                    </div>
                }
            }
        ]
    }

    componentDidMount(){
        this.getOffShel()
    }
    async getOffShel(){
        let {params} = this.state;
        let offShelList = await getOffShel(params);
        this.setState({
            dataSource: offShelList.data || [],
            total: offShelList.total || 0,
        })
    }
    lookModal = () => {
        this.setState({
            lookVisible: true,
        });
    };

    lookCancel = e => {
        this.setState({
            lookVisible: false,
        });
    };

    checkedChange = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys, ids: selectedRows.map(v => v.id)})
    };

    //全选 反选
    checkedAll = () => {
        let {selectedRowKeys, dataSource} = this.state;
        if (selectedRowKeys.length === dataSource.length) {
            this.setState({selectedRowKeys: [], ids: []});
            return;
        }
        const keys = Object.keys(dataSource);
        const index = [];
        keys.forEach(item => {
            index.push(Number(item));
        });
        this.setState({
            selectedRowKeys: index,
            ids: dataSource.map(v => v.id)
        });
    };
    //批量下架
    batchSure = () => {
        let {selectedRowKeys} = this.state;
        if (!selectedRowKeys.length) {
            message.warning('请选择');
            return;
        }
        console.log(selectedRowKeys)
    }
    //批量共享
    noBatchSure = () => {
        let {selectedRowKeys} = this.state;
        if (!selectedRowKeys.length) {
            message.warning('请选择');
            return;
        }
        console.log(selectedRowKeys)
    }
    isShowForm = () => {
        this.setState({
            isBlock: !this.state.isBlock
        })
    }

    render() {

        const {columns, selectedRowKeys, dataSource, isBlock} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.change,
        };
        return (
            <div className='grounding'>
                <div className='bg_fff m20 p20' style={{position: 'relative'}}>
                    <div className="navtop bg_fff btn_box">
                        <div className='left'>
                            资源分类
                        </div>
                        <div className='left_border'> |</div>
                        <div className="classity">
                            <Radio.Group defaultValue="index" buttonStyle="solid">
                                {
                                    this.state.classifyData.map((item,index)=>{
                                        return  <Radio.Button value={index} key={index}>{item.typeName}</Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </div>
                        <div className={setName(['right', isBlock ? 'zhankai' : 'shouqi'])}>
                            {/*<Button type='primary' className='search'>查询</Button>*/}
                            <Button className='reset_btn' disabled={this.state.btnDisabled ? true : false}>重置</Button>
                            <div className="pack-unfold" onClick={() => this.isShowForm()}>
                                {isBlock ? <span className="pack">收起</span> : <span className="unfold">展开</span>}
                            </div>
                        </div>
                    </div>
                    <div className="hot-box" style={{display: isBlock ? 'flex' : 'none'}}
                         ref={(e) => this.input_wrap1 = e}>
                        <div className="hot-direction">
                            <div className='hot-tit'>
                                热门方向
                            </div>
                            <Select defaultValue={2}>
                                <Option value={2}>请选择热门方向</Option>
                            </Select>
                        </div>

                        <Select className='ml78' defaultValue={2}>
                            <Option value={2}>请选择资源管理分类</Option>
                        </Select>
                    </div>
                    <div className="input_wrap2" style={{display: isBlock ? 'flex' : 'none'}}
                         ref={(e) => this.input_wrap2 = e}>
                        <div className='input_wrap2_left'>
                            推荐日期
                        </div>
                        <div className="input_wrap2_center">
                            <RangePicker/>
                        </div>
                    </div>
                </div>

                <div className='Grounding_box bg_fff p20'>
                    <div className="Grounding_top">
                        <Col span={12}>
                            <Link to={"/main/resource/grounding"} className="manage">上架资源 </Link>
                            <p className="manage manage-active">下架资源</p>
                        </Col>
                        <Col className='Grounding_search' span={12}>
                            <Input placeholder='请输入资源名称/上传人'/>

                        </Col>
                    </div>
                    <div className="check_container" style={{position: 'relative'}}>
                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                options={
                                    {
                                        dataSource,
                                        columns,
                                        rowSelection: {
                                            onChange: this.checkedChange,
                                            selectedRowKeys
                                        },
                                        pagination: true,
                                    }
                                }

                                isDrab={false}
                                fixededit={true}
                            />
                        </DndProvider>
                        <div className="Grounding_position">
                            <span>
                                <Checkbox
                                    checked={selectedRowKeys.length === dataSource.length ? true : false}
                                    onChange={this.checkedAll}
                                />
                                 <span className="Grounding_select">全选</span>
                            </span>
                            <Button type='primary' onClick={this.batchSure}>批量下架</Button>
                            <Button className='ant_share' type='primary' onClick={this.noBatchSure}>批量共享到平台资源库</Button>
                        </div>
                    </div>
                </div>
                {/*操作下架*/}
                <Modal
                    title="下架原因"
                    visible={this.state.lookVisible}
                    onOk={this.lookCancel}
                    closeIcon={false}
                    onCancel={this.lookCancel}
                    className="look-modal"
                >
                    <div className="sold-box">
                        <TextArea rows={4} placeholder="请输入下架原因"/>
                        <span className="suffixStyle">0/100</span>
                    </div>
                </Modal>
            </div>
        )
    }
}

Undercarriage = connect(mapStateToProps, mapDispatchToProps)(Undercarriage);
Undercarriage = Form.create()(Undercarriage)
export default Undercarriage;
