import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import '../index.scss';
import {classifyFirstList, classifyTransfer} from '@/api/craftsman-app/index'

const {Option} = Select;

class transferClassifyModel extends React.Component {
    state = {
        data: {},
        transferTwoDetail: {}, //二级分类列表
        transferOneDetail: {}, //一级分类列表
        oneClassifyList: {},   //原一级分类列表
        categoryId: '',   //一级分类Id
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.transferVisible) {
            this.setState({
                transferTwoDetail: nextProps.transferTwoDetail,
                transferOneDetail: nextProps.transferOneDetail,
            }, () => {
                this.classifyFirstList()
            })
        }
    }

    //课程分类一级分类列表
    async classifyFirstList() {
        const oneClassifyList = await classifyFirstList({})
        if (oneClassifyList) {
            this.setState({
                oneClassifyList: oneClassifyList
            })
        }
    }

    //一级分类查询
    oneClassifyChange = (value) => {
        this.setState({
            oneClassifyValue: value,
        })
    };
    //分类保存
    transferSave = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    saveLoading: true
                })
                let successBack = () => {
                    this.props.TransferRefreshData()
                    this.props.form.resetFields()//清空表单
                    this.checkCancel()
                    this.setState({
                        oneVisible: false,
                        saveLoading: false,
                    });
                }
                let params =
                    {
                        "categoryId": this.state.transferTwoDetail.categoryId,
                        "categoryName": this.state.transferTwoDetail.categoryName,
                        "parentId": values.categoryId,
                    }
                classifyTransfer(params).then(res => {
                    if (res) {
                        message.success('添加成功');
                        successBack();
                    }
                })
            }
        })
    }
    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
    }

    render() {
        const {saveLoading, data, transferTwoDetail, transferOneDetail, oneClassifyList} = this.state;
        const {transferVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 14}
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <Modal
                title='分类转移'
                width='660px'
                wrapClassName='training_modal_box'
                visible={transferVisible}
                closable={false}
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.checkCancel()}>取消</Button>
                        <Button loading={saveLoading} onClick={() => this.transferSave()} type='primary'>保存</Button>
                    </div>
                }
            >
                <Form {...formItemLayout}>
                    <p className={'training_modal_info'}>您即将将 &lt; {transferOneDetail.categoryName}分类下的 {transferTwoDetail.categoryName}分类 &gt; 中的内容转移到：</p>
                    <Form.Item label='一级分类'>
                        {
                            getFieldDecorator('categoryId', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择分类所属类别'
                                    },
                                ]
                            })(
                                <Select placeholder="请选择分类所属类别" onChange={(value) => this.oneClassifyChange(value)}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}>
                                    {oneClassifyList && oneClassifyList.length ? oneClassifyList.map((item, index) => {
                                        return <Option key={index}
                                                       value={item.categoryId}>{item.categoryName}</Option>
                                    }) : ""}
                                </Select>)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

transferClassifyModel = Form.create()(transferClassifyModel)
export default transferClassifyModel;
