import React, { Component } from 'react'
import { Button, Modal, Form, Input, Statistic } from 'antd';
import {
    getAccountNumInfo,         //获取账户信息
    savePassword,          //修改密码
    sendTelNum,             //发送验证码
    verificationTelNum,     //验证验证码
    encryptionPassword      //sm3密码加密
} from '../../../api/password'
import imgVer from '../../../components/imageSliderCodePlus'
import { quitLogin } from '@/api/login/index.js';
import Md5 from 'js-md5'
import './index.scss'
const { Countdown } = Statistic;



const OperationSuccess = () => {
    return <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <rect width="80" height="80" fill="white" />
            <path d="M56.6962 25.8008H52.5087C51.5979 25.8008 50.7319 26.2383 50.1962 26.9883L36.1605 46.4526L29.8033 37.6311C29.2676 36.8901 28.4104 36.4436 27.4908 36.4436H23.3033C22.723 36.4436 22.3837 37.1044 22.723 37.5776L33.848 53.0061C34.1108 53.373 34.4572 53.6718 34.8586 53.878C35.26 54.0842 35.7047 54.1918 36.156 54.1918C36.6072 54.1918 37.052 54.0842 37.4534 53.878C37.8548 53.6718 38.2012 53.373 38.464 53.0061L57.2676 26.9347C57.6158 26.4615 57.2765 25.8008 56.6962 25.8008Z" fill="#3E78ED" />
            <path d="M40 0C17.9107 0 0 17.9107 0 40C0 62.0893 17.9107 80 40 80C62.0893 80 80 62.0893 80 40C80 17.9107 62.0893 0 40 0ZM40 73.2143C21.6607 73.2143 6.78571 58.3393 6.78571 40C6.78571 21.6607 21.6607 6.78571 40 6.78571C58.3393 6.78571 73.2143 21.6607 73.2143 40C73.2143 58.3393 58.3393 73.2143 40 73.2143Z" fill="#3E78ED" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="80" height="80" fill="white" />
            </clipPath>
        </defs>
    </svg>
}
const Cancal = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C4.47716 0 0 4.47716 0 10C0 15.5229 4.47716 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0ZM13.9711 12.9798C14.2448 13.2536 14.2448 13.6975 13.9711 13.971C13.6971 14.2448 13.2534 14.2448 12.9793 13.971L10.0049 10.9965L7.03024 13.971C6.75639 14.2448 6.31267 14.2448 6.03862 13.971C5.76496 13.6975 5.76496 13.2536 6.03862 12.9798L9.01345 10.0045L6.03862 7.03004C5.76496 6.75659 5.76496 6.31229 6.03862 6.03845C6.31265 5.765 6.75637 5.765 7.03024 6.03845L10.0049 9.01329L12.9793 6.03845C13.2534 5.765 13.6971 5.765 13.9711 6.03845C14.2448 6.31229 14.2448 6.75664 13.9711 7.03004L10.9963 10.0045L13.9711 12.9798H13.9711Z" fill="#E34949" />
    </svg>
}
const Layout = {
    labelCol: {
        xs: { span: 4 }
        , sm: { span: 9 }
    },
    wrapperCol: {
        xs: { span: 6 },
        sm: { span: 10 },
    },
};

class Validation extends Component {
    state = {
        passwordShow: false,
        isShow: false,
        url: '',
        codeStep: 0,
        errorPassShow: false,
        errorConfigShow: false
    }
    componentDidMount() {
    }
    // 比较两次密码输入是否一致
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;

        if (value && value !== form.getFieldValue('password')) {
            this.setState({ errorConfigShow: true })
            callback('两次密码输入不一致');
        } else {
            this.setState({ errorConfigShow: false })
            callback();
        }
    };
    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { passwordShow, isShow, codeStep, errorConfigShow, errorPassShow } = this.state
        return <Form>
            <Form {...Layout}>
                <Form.Item className="userAccount" help="不小于8位，且至少包含字母数字及特殊字符中的两种" label="新的密码">
                    {
                        getFieldDecorator('password', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: <span>不小于8位，且至少包含字母数字及特殊字符中的两种<br />(特殊字符：!@#￥%……&*)</span>
                                },
                                {
                                    pattern: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,}$/,
                                    message: "不小于8位，且至少包含字母数字及特殊字符中的两种",
                                },
                                {
                                    whiteSpace: true,
                                    message: <span>不小于8位，且至少包含字母数字及特殊字符中的两种<br />(特殊字符：!@#￥%……&*)</span>
                                }
                            ]
                        })(<Input
                            type={passwordShow ? "text" : "password"}
                            maxLength={20}
                            whiteSpace={true}
                            placeholder='请输入密码'
                            suffix={
                                getFieldValue('password') && getFieldValue('password').length > 0 && errorPassShow ?
                                    <span className="cancelInput" onClick={() => { setFieldsValue({ password: '', errorPassShow: false }) }}><Cancal /></span> : <span></span>
                            }
                        />)
                    }
                </Form.Item>
                <Form.Item label="重复密码" colon={false}>
                    {
                        getFieldDecorator('passwordCopy', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '不小于8位，且至少包含字母数字及特殊字符中的两种'
                                },
                                {
                                    validator: this.compareToFirstPassword,
                                },
                            ]
                        })(<Input
                            type={passwordShow ? "text" : "password"}
                            maxLength={20}
                            placeholder='请输入密码'
                            whiteSpace={true}
                            suffix={
                                getFieldValue('passwordCopy') && getFieldValue('passwordCopy').length > 0 && errorConfigShow ?
                                    <span className="cancelInput" onClick={() => { setFieldsValue({ passwordCopy: '', errorConfigShow: false }) }}><Cancal /></span> : <span></span>
                            }
                        />)
                    }
                </Form.Item>
                <Form.Item className="checkUser">
                    <div className="space"></div>
                    <Button type="primary" htmlType="submit" onClick={() => this.props.validation()}>
                        确定
                    </Button>
                </Form.Item>
            </Form>
        </Form>
    }
}
class CheckPhone extends Component {
    state = {
        passwordShow: false,
        isShow: false,
        url: '',
        codeStep: 0,
        time: null
    }
    componentDidMount() {
        this.getImg()
    }
    getImg = () => {
        const imgList = ["base/us/image/07b5706edc1046e8aabcf096240ab140.jpg", "base/us/image/4e0f19d3ed3044fc82c55c74b32ad78f.jpg", "base/us/image/7409ba84e5d449c996b986ebbe40de28.jpg"]
        const index = Math.floor(Math.random() * imgList.length)
        this.setState({ url: window.loadSrc + imgList[index] })
    }
    // 获取验证码
    getCode = () => {
        this.props.form.validateFields(['telNum'], (errors, values) => {
            if (!errors) {
                // this.setState({ isShow: true })
                this.setState({ isShow: true }, () => {
                    var timer = setTimeout(() => {
                        imgVer({
                            el: '#imgVer',
                            width: '270',
                            height: '170',
                            img: [
                                window.$$setImgSrc + "UC_code1.jpg",
                                window.$$setImgSrc + "UC_code2.jpg",
                                window.$$setImgSrc + "UC_code3.jpg",
                            ],
                            success: () => {
                                this.onMatch();
                            },
                            error: function () {
                                console.log('错误执行=======')
                            }
                        });
                        clearTimeout(timer);
                    })
                })
            }
        })
    }
    // 图片验证码成功
    onMatch = () => {
        this.setState({ isShow: false }, async () => {
            const { accountInfo } = this.props
            const data = { telNum: accountInfo.telNum, type: 2 }
            let res = await sendTelNum(data)
            if (res) {
                this.hint({
                    title: '发送成功',
                    content: '验证码已发送至您的手机,请注意查收',
                    type: 'success',
                    isCancel: false,
                    okText: '知道了',
                    sure: () => {
                        this.setState({ codeStep: 1, time: Date.now() })
                    }
                })
            } else {
                this.mesError("数据错误");
            }
        })
    }
    // 图片验证码失败
    onError = () => {
        this.mesError("验证失败")
        this.getImg()
    }
    // 倒计时结束
    timerFinish = () => {
        this.setState({ codeStep: 2 })
    }
    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { passwordShow, isShow, codeStep } = this.state
        const { accountInfo } = this.props
        return <Form {...Layout}>
            <Form.Item label="当前号码" colon={false}>
                {
                    getFieldDecorator('oldPhone', {})(<span className="oldphone">{JSON.stringify(accountInfo) !== "{}" ? accountInfo.telNum.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2") : '--'}</span>)
                }
            </Form.Item>
            <div style={{ width: "100%", height: 20 }}></div>
            <Form.Item label="短信验证" colon={false}>
                {
                    getFieldDecorator('validNum', {
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: '请输入验证码'
                            },
                        ]
                    })(<Input
                        maxLength={10}
                        placeholder='请输入验证码'
                        suffix={<div className="code_style"><i></i>{codeStep === 0 ? <span onClick={() => this.getCode()}>获取验证码</span> :
                            (codeStep === 1 ? <span style={{ color: "#888888" }}><Countdown onFinish={() => this.timerFinish()} value={this.state.time + 1000 * 60} format='ss' /><em>s后重新发送</em></span> : <span onClick={() => this.getCode()}>重新发送</span>)}</div>}
                    />)
                }
            </Form.Item>
            <Form.Item className="checkUser">
                <div className="space"></div>
                <Button type="primary" htmlType="submit" onClick={() => this.props.checkPhone()}>
                    身份验证
                </Button>
            </Form.Item>
            <Modal
                title='请完成安全验证'
                visible={this.state.isShow}
                destroyOnClose={true}
                closable={false}
                width={660}
                footer={<div><Button type="primary" onClick={() => this.setState({ isShow: false })}>关闭</Button></div>}
            >
                <div id="imgVer" className="imgVer sliderBox" style={{ width: '270px' }}></div>
            </Modal>
        </Form>
    }
}
Validation = Form.create()(Validation);
CheckPhone = Form.create()(CheckPhone);

class Password extends Component {
    state = {
        editPasswordStep: 1,
        accountInfo: {}
    }
    componentDidMount() {
        this.getAccountNumInfo()
    }
    //获取账户信息
    getAccountNumInfo = async () => {
        let res = await getAccountNumInfo()
        if (res) {
            this.setState(() => {
                return {
                    accountInfo: res
                }
            })
        } else {
            this.mesError("数据错误")
        }
    }
    // 修改密码
    validationUser = () => {
        this.validation.validateFields(async (err, values) => {
            if (!err) {
                const { password } = values
                const smStr = Md5(password).toUpperCase()
                let r = await encryptionPassword({ smStr })
                if (r) {
                    const data = { password: r }
                    let res = await savePassword(data)
                    if (res) {
                        this.setState({ editPasswordStep: 2 }, () => {
                            localStorage.setItem("free", 1)
                        })
                    } else {
                        this.mesError("数据错误")
                    }
                }else{
                    this.mesError("trans接口报错")
                }
            }
        })
    }
    // 身份验证按钮
    checkPhone = () => {
        this.editTel.validateFields(async (err, values) => {
            if (!err) {
                const { accountInfo } = this.state
                const { validNum } = values
                const data = { telNum: accountInfo.telNum, type: 2, validNum }
                let res = await verificationTelNum(data)
                if (res) {
                    this.mesSuccess("验证成功", 1000)
                    this.setState({ editPasswordStep: 1 })
                } else {
                    this.hint({
                        title: '验证失败',
                        content: '短信验证码验证失败，请重新输入',
                        type: 'error',
                        isCancel: false,
                        'okText': '知道了',
                    })
                }
            }

        })
    }
    loginTimerFinish = async () => {
        quitLogin({ type: 'PC' }).then(res => {
            console.log(res)
            if (res.code === 0) {
                localStorage.clear();
                this.props.history.replace('/login');
            }
        })
    }
    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { editPasswordStep } = this.state
        return (
            <div className="editPassword">
                <div className="ep_content">
                    <div className="head_title">
                        <div></div>
                        <div>修改密码<span><em></em>修改密码需要先对您的身份进行验证</span></div>
                    </div>
                    {editPasswordStep === 0 && <div className="ep_steps">
                        <div className="ep_step">
                            <div className="orderstep">
                                <span style={{ borderColor: "#888888", background: "#FFFFFF", color: "#888888" }}>1</span>
                                <span style={{ color: "#888888" }}>修改密码</span>
                            </div>
                            <div className="stepline"></div>
                            <div className="orderstep">
                                <span style={{ borderColor: "#888888", background: "#FFFFFF", color: "#888888" }}>2</span>
                                <span style={{ color: "#888888" }}>完成</span>
                            </div>
                        </div>
                        <div className="user_form">
                            <CheckPhone ref={e => this.editTel = e} accountInfo={this.state.accountInfo} checkPhone={this.checkPhone} />
                        </div>
                    </div>}
                    {editPasswordStep === 1 && <div className="ep_steps">
                        <div className="ep_step">
                            <div className="orderstep">
                                <span>1</span>
                                <span>修改密码</span>
                            </div>
                            <div className="stepline"></div>
                            <div className="orderstep">
                                <span style={{ borderColor: "#888888", background: "#FFFFFF", color: "#888888" }}>2</span>
                                <span style={{ color: "#888888" }}>完成</span>
                            </div>
                        </div>
                        <div className="user_form">
                            <Validation ref={e => this.validation = e} validation={this.validationUser} />
                        </div>
                    </div>}
                    {editPasswordStep === 2 && <div className="ep_steps">
                        <div className="ep_step">
                            <div className="orderstep">
                                <span>1</span>
                                <span>修改密码</span>
                            </div>
                            <div className="stepline" style={{ background: "#3E78ED" }}></div>
                            <div className="orderstep">
                                <span>2</span>
                                <span>完成</span>
                            </div>
                        </div>
                        <div className="checksuccess">
                            <OperationSuccess />
                            <h2>操作成功</h2>
                            <p>密码修改成功！为了您的账号安全，需要您重新登录</p>
                            <p>
                                <span className="skipLogin" onClick={() => this.loginTimerFinish()}>返回页面</span><span>重新登录，</span><Countdown onFinish={() => this.loginTimerFinish()} value={Date.now() + 1000 * 5} format='s' /><em>s</em>
                            </p>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}
export default Form.create()(Password);
