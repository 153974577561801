import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import './index.scss';
import {
    Button,
    Input,
    Col,
    Checkbox,
    Modal,
    Row,
    Form,
    Icon,
    message,
    Select,
    Divider,
    Radio,
    Menu,
    Dropdown, Empty, Table, Tabs,
} from 'antd';
import {
    roleList,
    deleteRole,
    existUserList,
    userList,
    assigningUserSave,
    roleStatus,
    updateRoleStatus,
    organizationList,
} from '@/api/role'
import { powerdic } from '@/api/powerdic'
import { BreadcrumbFCLine } from '../../../../components/Breadcrumb/BreadcrumbFC'
import TableEmpty from '../../../authorityManagement/components/TableEmpty/TableEmpty'

const { Option } = Select;
const { Search } = Input;
const { confirm } = Modal;
const mapStateToProps = ({ CHECK }) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}

class RoleManager extends React.Component {
    state = {
        fullName: '',
        organPlainOptions: [],//适用机构
        identityOptions: [],//适用身份
        roleName: '',//角色名称
        roleStatusType: [],//角色状态（检索）
        applyOrganizationNameValue: null,//所属机构选中值
        identityNameValue: '',//适用身份选中值
        status: '',//状态（0 禁用 1 正常  空全部）
        identityName: '',//适用身份名称（多个用英文逗号隔开）
        classityActive: '',
        detail: {},
        expandedRowKeys: [],
        selectedRowKeys: [],
        existingMembers: [],
        existUserListDate: [],//现有用户列表（分配用户）
        visibly: false,
        existUserValue: [],
        dataSource: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ['20', '40', '60', '80']
        },
    }

    componentDidMount() {
        this.roleList()
        this.roleStatus()
        this.getDic()
        this.getOrganizationList()
    }

    // 字典表查询 接口类型：1-院校入驻方式，2-办学方式，3-办学层次，4-办学类型，5-办学类型,6-管理部门，7-权限身份，8-菜单类型
    async getDic() {
        // let organPlainOptions = await this.getPowerdic(9)   //所属机构
        let identityOptions = await this.getPowerdic(7)     //身份下拉类型
        if (!identityOptions) return;
        this.setState({
            // organPlainOptions,
            identityOptions: identityOptions.length >= 3 ? identityOptions.splice(0, 3) : identityOptions
        })
    }

    //适用机构
    async getOrganizationList() {
        let applyList = await organizationList();
        if (applyList) {
            this.setState({
                organPlainOptions: applyList
            })
        }

    }

    async getPowerdic(num) {
        let data = await powerdic({ type: num });
        return data;
    }

    //角色状态（检索）
    async roleStatus() {
        const roleStatusType = await roleStatus()
        console.log('roleStatusType', roleStatusType)
        this.setState({
            roleStatusType: roleStatusType
        })

    }

    roleStatusChange = (status) => {
        this.setState({
            pagination: { ...this.state.pagination, current: 1 },
            status: status
        }, () => this.roleList())
    }

    //角色列表
    roleList = async () => {
        const { status, roleName, identityNameValue, pagination, applyOrganizationNameValue } = this.state
        const roleListDate = await roleList({
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            status: status,
            roleName: roleName,
            organizationIdList: applyOrganizationNameValue ? [applyOrganizationNameValue] : [],//适用机构code
            identityId: identityNameValue,//适用身份Id
        })
        pagination.total = roleListDate ? roleListDate.total : 0
        this.setState({
            dataSource: roleListDate ? roleListDate.data : '',
            pagination,
        }, () => window.elGoTop && window.elGoTop('body'))

    }

    //删除角色
    async deleteRole(id) {
        let that = this
        confirm({
            title: '警告',
            content: '您确定要删除该条信息，删除后不可恢复，是否继续？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                deleteRole({ roleId: id }).then(res => {
                    if (res && res.code == 0) {
                        message.success('删除成功')
                        that.roleList()
                    }
                })
            },
            onCancel() {

            }
        });
    }


    //启用禁用角色
    async updateRoleStatus(record) {
        const statusType = record.status == 0 ? '启用' : '禁用'
        let that = this
        confirm({
            title: '你确定要' + statusType,
            onOk() {
                updateRoleStatus({ roleId: record.id }).then(res => {
                    console.log(res, 'res')
                    if (res && res.code == 0) {
                        message.success('已' + statusType)
                        that.roleList()
                    }
                })
            },
            onCancel() {

            }
        });
    }

    //分页
    pageChange = (pageNumber) => {
        const { pagination } = this.state;
        pagination.current = pageNumber;
        this.setState({ pagination }, () => this.roleList())
    }

    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({ selectedRowKeys, expandedRowKeys })
    }

    ////现有用户列表（分配用户）
    existUser = (item) => {
        // this.setState({
        //     fullName: "",
        //     visibly: !this.state.visibly,
        //     roleId: item.id,
        //     detail: {
        //         applyOrganizationName: item.applyOrganizationName,
        //         showName: item.showName,
        //         identityName: item.identityName,
        //     }
        // },()=>{
        //     this.getExistUserList()
        //     this.onSearchValue()
        // })
        const roleName = decodeURIComponent(item.roleName);
        this.props.history.push(`/main/capacity-auth/role-join/${item.id}?roleName=${roleName}`);
    }
    handleViewDetails = (item) => {
        this.props.history.push(`/main/capacity-auth/edit-role/${item.id}/readonly`);
    }

    //角现有用户列表（分配用户）
    async getExistUserList() {
        let { roleId } = this.state;
        let existingMembers = await existUserList({ roleId: roleId });
        console.log(existingMembers, 'existUserList')
        if (existingMembers) {
            this.setState({
                existingMembers: existingMembers
            })
        }
    }

    onSearchChange = (fullName) => {
        console.log('fullName', fullName)
        this.setState({
            fullName: fullName
        }, () => this.onSearchValue())
    }

    async onSearchValue() {
        const existUserListDate = await userList({
            roleId: this.state.roleId,
            fullName: this.state.fullName,
            selectNum: this.state.fullName ? '' : 10
        });
        if (existUserListDate) {
            this.setState({
                existUserListDate: existUserListDate
            })
        }
    }

    save() {
        let { existingMembers, existUserValue, roleId, visibly } = this.state;
        let data = {
            roleId: roleId,
            userIdList: [...existingMembers.map(item => item.id), ...existUserValue]
        }
        assigningUserSave(data).then((res) => {
            if (res.code == 0) {
                this.setState({
                    existUserValue: [],
                    visibly: !visibly
                }, () => message.success('权限分配成功！'))
            }

        })
    }

    //搜索查询
    onHotChange = ({ target: { value } }) => {
        this.setState({
            pagination: { ...this.state.pagination, current: 1 },
            roleName: value
        });
    };
    delMember = (index) => {
        const { existingMembers } = this.state
        existingMembers.splice(index, 1);
        this.setState({
            existingMembers: existingMembers
        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const { pagination } = this.state;
        pagination.current = 1;
        pagination.pageSize = pageSize;
        this.setState({ pagination }, () => this.roleList())
    };

    handleStatusChange = () => {

    }


    render() {
        const {
            detail,
            existingMembers,
            pagination,
            isDrab,
            identityNameValue,
            status,
            isActive,
            visibly,
            dataSource,
            roleStatusType,
            roleName,
            applyOrganizationNameValue,
            identityOptions,
            existUserListDate,
            organPlainOptions
        } = this.state;
        const menu = (<Menu onClick={this.roleStatusChange.bind(this)} className='downmenu' selectedKeys={[status]}
            style={{ width: '100px', textAlign: 'center' }}>
            <Menu.Item key={'2'}>
                <a>全部</a>
            </Menu.Item>
            {roleStatusType ? roleStatusType.map((item, index) =>
                <Menu.Item key={item.code}>
                    <a>{item.msg}</a>
                </Menu.Item>
            ) : ''}

        </Menu>)
        const columns = [
            { title: '角色名称', dataIndex: 'roleName', key: 'roleName', align: 'center',render: (text) => text ?? '- -' },
            { title: '角色更新时间', dataIndex: 'createTime', key: 'createTime', align: 'center',render: (text) => text ?? '- -' },
            { title: '使用院校数量', dataIndex: 'organizationTotal', key: 'organizationTotal', align: 'center',render: (text) => text ?? '- -' },
            {
                title: '状态', dataIndex: 'status', key: 'status', align: 'center', width: 80,
                render: (text, record) => <span>{record.status == 0 ? '禁用 ' : '正常'}</span>
            },
            {
                title: '操作', dataIndex: 'address', key: 'address', align: 'center', width: '260px',
                render: (text, record) => (
                    <p className='action vertical_j'>
                        <span onClick={() => this.existUser(record)}>角色接入管理</span>
                        <span onClick={() => this.updateRoleStatus(record)}>{record.status !== 0 ? '禁用' : '启用'}</span>
                        <span
                            onClick={() => this.props.history.push(`/main/capacity-auth/edit-role/${record.id}`)}>编辑</span>
                        <span onClick={() => this.handleViewDetails(record)}>详情</span>
                    </p>
                )
            }
        ]
        return (
            <div className='roleManager_box'>
                <div className='roleManager_tabs'>
                    <Tabs defaultActiveKey={''} onChange={this.roleStatusChange}>
                        <Tabs.TabPane key={''} tab={'全部'} />
                        <Tabs.TabPane key={'1'} tab={'正常'} />
                        <Tabs.TabPane key={'0'} tab={'禁用'} />
                    </Tabs>
                </div>
                <div className='top' style={{ display: isDrab ? 'none' : 'flex' }}>
                    <Col span={12} className='sort_boxs'>
                        <Button onClick={() => this.props.history.push(`/main/capacity-auth/edit-role`)}
                            className='sure_sort' type='primary'>添加角色</Button>
                    </Col>
                    <Col className='top_r role_btn_box' span={12}>
                        <div className={'role_auth_search'}>
                            <Input.Search
                                placeholder={'请输入角色名称'}
                                value={roleName}
                                onChange={this.onHotChange}
                                onPressEnter={this.roleList}
                            />
                            <Button onClick={this.roleList}>查询</Button>
                            <p>共{pagination.total ?? 0}个角色</p>
                        </div>
                    </Col>
                </div>
                <div className='alert' style={{ display: isActive ? 'flex' : 'none' }}>
                </div>
                <div className={`role_container ui_table`} style={{ position: 'relative' }}>
                    <Table
                        rowKey={(record, i) => i}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            onChange: this.pageChange,
                            ...pagination,
                            onShowSizeChange: this.onShowSizeChange
                        }}
                        locale={{
                            emptyText: <TableEmpty />
                        }}
                    />
                </div>
                <Modal
                    title=''
                    width='800px'
                    wrapClassName='modal_box roleModal'
                    visible={visibly}
                    destroyOnClose
                    onCancel={() => { this.setState({ visibly: !visibly }) }}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => {
                                this.setState({ visibly: !visibly })
                            }}>取消</Button>
                            <Button onClick={() => this.save()} type='primary'>保存</Button>
                        </div>
                    }
                >
                    <Row className='roleModalcont'>
                        <Col span={14}>
                            <h2 className='roleModalName'>分配用户</h2>
                            <div className='role-l'>
                                <div className='l_item'><label>适用机构</label><span>{detail.applyOrganizationName}</span>
                                </div>
                                <div className='l_item'><label>适用身份</label><span>{detail.identityName}</span></div>
                                <div className='l_item'><label>角色显示</label><span>{detail.showName}</span></div>
                                <Divider />
                                <div className='l_item'>
                                    <label>成员搜索</label>
                                    <Search className='formsearch'
                                        placeholder='请输入成员姓名查询'
                                        allowClear
                                        onSearch={this.onSearchChange}
                                        style={{ width: '70%' }}
                                        prefix={<Icon type='search' style={{ color: '#BEBEBE' }} />}
                                    />
                                </div>
                                <Row>
                                    <Col span={24}>
                                        <Checkbox.Group className='searchList' onChange={(e) => {
                                            this.setState({ existUserValue: e })
                                        }}>
                                            {existUserListDate && existUserListDate.length ? existUserListDate.map((item, index) => {
                                                return <Checkbox key={index}
                                                    value={item.id}>{item.fullName}&nbsp;&nbsp;&nbsp;&nbsp;{item.organizationName ? '(' + item.organizationName + ')' : ''}</Checkbox>
                                            }) : ''}
                                        </Checkbox.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={10}>
                            <h2 className='roleModalName'>现有成员</h2>
                            <div className='memberbox'>
                                <ul className='memberList'>
                                    {existingMembers && existingMembers.length ? existingMembers.map((item, index) =>
                                        <li key={index}>
                                            <span>{item.fullName}</span>&nbsp;&nbsp;
                                            <span
                                                className='membername'> {item.organizationName ? '(' + item.organizationName + ')' : ''}</span>
                                            {/*（<span
                                            className='membername'>{item.organizationName}</span>）*/}
                                            <img className='icondel' onClick={() => this.delMember(index)}
                                                src={require('../../../../assets/capacity-auth/icon-del.png')} />
                                        </li>
                                    ) :
                                        <div className='data_empty'>
                                            <Empty image={require('@/assets/img/data_empty.png')} description='暂无数据范围' />
                                        </div>
                                    }
                                </ul>
                                <p className='memberNum'>现有成员<span>{existingMembers.length}</span>人</p>
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}


RoleManager = connect(mapStateToProps, mapDispatchToProps)(RoleManager);
RoleManager = Form.create()(RoleManager);
export default RoleManager;
