import { post } from '@/until/axios_instans'

// 学校入住-学校入驻管理分页列表接口
export const getSchoolIntoManagePage = (data) => {
    return post("/api/ucenter/api/v1/enterprise/getSchoolIntoManagePage", data);
};

// 公用接口-获取学校类型接口
export const getSchoolType = (data) => {
    return post("/api/ucenter/api/v1/orgCommon/getSchoolType", data);
};

// 公用接口-获取办学层次接口
export const getSchoolLevel = (data) => {
    return post("/api/ucenter/api/v1/orgCommon/getSchoolLevel", data);
};

// 公用接口-获取办学方式接口
export const getSchoolMode = (data) => {
    return post("/api/ucenter/api/v1/orgCommon/getSchoolMode", data);
};

// ygx-机构配置应用详情
export const configApplicationDetail = (data) => {
    return post("/api/ucenter/api/v1/application/configApplicationDetail", data);
};

// ygx-机构配置应用
export const configApplication = (data) => {
    return post("/api/ucenter/api/v1/application/configApplication", data);
};

// 学校入住-院校信息录入接口
export const enterSchoolInfo = (data) => {
    return post("/api/ucenter/api/v1/enterprise/enterSchoolInfo", data);
};

// 学校入住-学校信息回显接口-用于修改学校信息时使用
export const getSchoolInfoDetail = (data) => {
    return post("/api/ucenter/api/v1/enterprise/updateSchoolInfo", data);
};

// 学校入住-院校信息修改接口
export const schoolInfoUpdate = (data) => {
    return post("/api/ucenter/api/v1/enterprise/schoolInfoUpdate", data);
};

// 学校入住-获取院校详情信息接口
export const getSchoolDetail = (data) => {
    return post("/api/ucenter/api/v1/enterprise/getSchoolDetail", data);
};

// 虚拟机构-虚拟机构管理分页列表接口
export const getVmOrgManagePage = (data) => {
    return post("/api/ucenter/api/v1/enterprise/getVmOrgManagePage", data);
};

// 虚拟机构-虚拟机构信息录入接口
export const enterVmOrgInfo = (data) => {
    return post("/api/ucenter/api/v1/enterprise/enterVmOrgInfo", data);
};

// 虚拟机构-获取虚拟机构信息接口--用于修改回显数据使用
export const getVmOrgInfoEcho = (data) => {
    return post("/api/ucenter/api/v1/enterprise/getVmOrgInfoEcho", data);
};

// 虚拟机构-修改虚拟机构信息接口
export const updateVmOrgInfo = (data) => {
    return post("/api/ucenter/api/v1/enterprise/updateVmOrgInfo", data);
};

// 虚拟机构-获取虚拟机构详情信息接口
export const getVmOrgDetail = (data) => {
    return post("/api/ucenter/api/v1/enterprise/getVmOrgDetail", data);
};

// 获取教师类型接口
export const getTeacherType = (data) => {
    return post("/api/ucenter/api/v1/adminUser/getTeacherType", data);
};

// 获取最高学历接口
export const getHighestEducation = (data) => {
    return post("/api/ucenter/api/v1/adminUser/getHighestEducation", data);
};

// 根据机构ID分页查询教师列表接口
export const getTeacherPage = (data) => {
    return post("/api/ucenter/api/v1/adminUser/teacherPage", data);
};

// 根据组织Id分页查询学生列表
export const getStudentPage = (data) => {
    return post("/api/ucenter/api/v1/adminUser/getStudentPage", data);
};

// 根据组织id查询院系信息
export const selectFacultyByOrgId = (data) => {
    return post("/api/ucenter/api/v1/adminUser/selectFacultyByOrgId", data);
};

// 根据院系id查询专业信息
export const selectMajorByFacultyId = (data) => {
    return post("/api/ucenter/api/v1/adminUser/selectMajorByFacultyId", data);
};

// 根据专业id查询班级信息
export const selectClassByMajorId = (data) => {
    return post("/api/ucenter/api/v1/adminUser/selectClassByMajorId", data);
};

// 根据userId删除教师接口
export const delTeacher = (data) => {
    return post("/api/ucenter/api/v1/adminUser/delTeacher", data);
};

// 根据userId重置密码接口
export const resetUserPassword = (data) => {
    return post("/api/ucenter/api/v1/adminUser/resetUserPassword", data);
};

// 删除学生信息
export const deleteStudent = (data) => {
    return post("/api/ucenter/api/v1/adminUser/deleteStudent", data);
};

// 新增教师接口
export const addTeacher = (data) => {
    return post("/api/ucenter/api/v1/adminUser/addTeacher", data);
};

// 根据userId查看教师接口
export const getTeacherInfo = (data) => {
    return post("/api/ucenter/api/v1/adminUser/getTeacherInfo", data);
};

//修改教师接口
export const updataTeacher = (data) => {
    return post("/api/ucenter/api/v1/adminUser/updateTeacher",data);
};

// 新增学生接口
export const addStudent = (data) => {
    return post("/api/ucenter/api/v1/adminUser/insertStudent", data);
};

// 根据userId查看学生接口
export const getStudentInfo = (data) => {
    return post("/api/ucenter/api/v1/adminUser/selectStudentById", data);
};

//修改学生接口
export const updataStudent = (data) => {
    return post("/api/ucenter/api/v1/adminUser/updateStudent",data);
};

//根据机构id查询默认密码
export const getDefaultPasswordByEnterpriseId = (data) => {
    return post("/api/ucenter/api/v1/new/management/getDefaultPasswordByEnterpriseId",data);
};
