import React, { Component } from 'react'
import { pdfjs } from 'react-pdf';
import './index.scss'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default class PreviewLiveInfo extends Component {
    state={
        full_flag:true,
        scalcnum: 1,
        currentpage:0,
        totalPage:0,
        resourceDetail:{},
        more:false,
        FileType:'',
    };

    render() {
        const {full_flag,totalPage,scalcnum,currentpage,more,FileType}=this.state
        //   resourceType    1,"文档",2,"视频",3,"音频" 4,"图片",
        const {type, suffix, id, pageSize, startTime, name,resourceContent} = this.props;
        const {$$img}=window
        const {$$uploadRc}=window
        const {$$uploadDoc}=window
        const {$$webOfficeDownload}=window
        return (
            <div className="left_bottom left-con">
                <div style={{height:"60px"}}></div>
                <div className="main_preview">
                    { type == 1?
                        <div>
                            <iframe style={{width:"100%",marginTop:"20px",height:"500px"}}
                                    src={`${$$webOfficeDownload(id)}`}></iframe>
                        </div>:
                            <div className="imgModal">
                                <img src={$$uploadRc(id)} alt=""/>
                            </div>
                    }
                </div>
                <div style={{height:"20px"}}></div>
            </div>
        )
    }
}
