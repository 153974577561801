


import React from 'react';


//应用大厅管理
export default props => {
    return<svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.77792 8.36054C5.68806 8.36054 5.59909 8.34285 5.51608 8.30847C5.43306 8.27408 5.35763 8.22368 5.2941 8.16015C5.23057 8.09661 5.18018 8.02118 5.1458 7.93816C5.11142 7.85515 5.09374 7.76617 5.09375 7.67632V4.14756C5.09376 4.02346 5.12751 3.9017 5.19141 3.79532C5.2553 3.68893 5.34693 3.60192 5.45648 3.54361C5.56602 3.4853 5.68936 3.45788 5.8133 3.46429C5.93723 3.4707 6.05709 3.51069 6.16004 3.57999L8.78515 5.34724C8.87823 5.4099 8.95447 5.49449 9.00716 5.59356C9.05985 5.69262 9.08736 5.80313 9.08728 5.91533C9.0872 6.02754 9.05953 6.138 9.00669 6.237C8.95386 6.33599 8.8775 6.42046 8.78432 6.48299L6.15922 8.24449C6.04646 8.32018 5.91372 8.36058 5.77792 8.36054ZM6.46219 5.43301V6.39322L7.17652 5.91388L6.46219 5.43301Z" fill="#BABABA"/>
        <path d="M12.8355 1.38037H1.12172C0.743811 1.38037 0.4375 1.68668 0.4375 2.06459C0.4375 2.4425 0.743811 2.74881 1.12172 2.74881H1.56969V9.68695C1.56969 10.0648 1.87606 10.3712 2.25391 10.3712H6.3549V12.5556C6.3549 12.9335 6.66126 13.2398 7.03912 13.2398C7.41697 13.2398 7.72333 12.9335 7.72333 12.5556V10.3712H11.8244C12.2022 10.3712 12.5086 10.0648 12.5086 9.68695V2.74881H12.8355C13.2134 2.74881 13.5198 2.4425 13.5198 2.06459C13.5198 1.68668 13.2134 1.38037 12.8355 1.38037ZM11.1402 9.00273H2.93813V2.74881H11.1402V9.00273Z" fill="#6A7E9C"/>
    </svg>

}