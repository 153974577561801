import LoadableComponent from "../until/LoadableComponent";
import { AuthorityManagementRouter } from '../page/authorityManagement/router.config'
import shixun_router_config from './shixun_router_config';
import shixun_router_config_nouse from './shixun_router_config_nouse';
import quanzi_router_config from './quanzi_router_config';
import peixun_config from "./peixun_config";
import zixun_router_config from "./zixun_router_config";
import huodongdasai_router_config from './huodongdasai_router_config'
import tongyong from "./tongyong_config";

import { liveStreaming, liveStreamingConten, liveStreamingRoom } from './live_streaming_router_config';
import tongyong_config from "./tongyong_config";

import ziyuan_router_config from './ziyuan_router_config'
import Institutions_router_config from './Institutions_router_config'
import app_router_config from "./app_router_config";

import { institutional_settlement_router, institutional_settlement_global_router } from './institutional_settlement_router';
let moduleFile = require.context("../page", true, /\index.js$/);
let result = moduleFile.keys().reduce((prev, item) => {
    let str = item.split("/")[item.split("/").length - 2];
    let name = str[0].toLocaleUpperCase() + str.slice(1);
    let aa = moduleFile(item).default;
    prev = Object.

        assign({}, prev, {
            [name]: LoadableComponent(import("../page" + item.slice(1))),
        });
    // prev = Object.assign({}, prev, { [name]: aa })
    return prev;
}, {});

export default [
    // {
    //     name: "首页",
    //     path: "/",
    //     redirect: '/main',
    // },
    {
        name: "首页",
        path: "/main",
        component: result.Main,
        //资源
        children: [
            ...ziyuan_router_config,

            //职业能力权限管理
            {
                name: '职业能力权限管理',
                path: '/main/capacity-auth',
                component: result['Capacity-auth'],
                children: [
                    // {
                    //     path: '/main/capacity-auth',
                    //     redirect: '/main/capacity-auth/member',
                    //     exact:true
                    // },
                    {
                        name: '成员管理',
                        path: '/main/capacity-auth/member',
                        component: result['Member']
                    },
                    // {
                    //     name: '菜单管理',
                    //     path: '/main/capacity-auth/menu',
                    //     component: result['Menu']
                    // },
                    {
                        name: '角色权限管理',
                        path: '/main/capacity-auth/role-auth',
                        component: result['Role-auth']
                    },
                    {
                        name: '添加成员',
                        path: '/main/capacity-auth/add-member',
                        component: result['Add-member']
                    },
                    {
                        name: '编辑成员',
                        path: '/main/capacity-auth/edit-member',
                        component: result['Edit-member']
                    },
                    {
                        name: '角色分配',
                        path: '/main/capacity-auth/roleDistribution',
                        component: result.RoleDistribution
                    },
                    {
                        name: '接入管理',
                        path: '/main/capacity-auth/access',
                        component: result.Access
                    },
                    {
                        name: '应用接入学校',
                        path: '/main/capacity-auth/cut-setSchool/:id/:appName',
                        component: result['Cut-setSchool']
                    },
                    ...AuthorityManagementRouter
                ]
            },
            //机构入驻
            ...Institutions_router_config,
            //资讯管理
            ...zixun_router_config,
            //圈子管理
            ...quanzi_router_config,
            // {
            //     name: '圈子管理',
            //     path: '/main/circle-manage',
            //     component: result['Circle-manage'],
            //     children: [
            //         {
            //             path: '/main/circle-manage',
            //             redirect: '/main/circle-manage/manage',

            //         },
            //         {
            //             name: '圈子管理',
            //             path: '/main/circle-manage/manage',
            //             component: result['Manage']
            //         },
            //         {
            //             name: '话题审核管理',
            //             path: '/main/circle-manage/topic-audit',
            //             component: result['Topic-audit']
            //         },
            //         {
            //             name: '话题举报管理',
            //             path: '/main/circle-manage/topic-report',
            //             component: result['Topic-report']
            //         },

            //         {
            //             name: '评论举报管理',
            //             path: '/main/circle-manage/comment-report',
            //             component: result['Comment-report']
            //         },
            //         {
            //             name: '人员黑名单',
            //             path: '/main/circle-manage/blacklist',
            //             component: result['Blacklist']
            //         },


            //         {
            //             name: "评论详情页test",
            //             path: "/main/circle-manage/test-detail",
            //             component: result['Test-detail'],
            //         },
            //         {
            //             name: "话题举报详情",
            //             path: "/main/circle-manage/topic-report-detail/:id",
            //             component: result['Topic-report-detail'],
            //         },
            //         {
            //             name: "话题审核详情",
            //             path: "/main/circle-manage/topic-audit-detail/:id",
            //             component: result['Topic-audit-detail'],
            //         },



            //         // {
            //         //     name: "评论详情页test",
            //         //     path: "/main/circle-manage/test-detail",
            //         //     component: result['Test-detail'],
            //         // },
            //         // {
            //         //     name: "话题举报详情",
            //         //     path: "/main/circle-manage/topic-report-detail/:id",
            //         //     component: result['Topic-report-detail'],
            //         // },
            //         // {
            //         //     name: "话题审核详情",
            //         //     path: "/main/circle-manage/topic-audit-detail/:id",
            //         //     component: result['Topic-audit-detail'],
            //         // },

            //     ]
            // },
            //APP内容管理
            ...app_router_config,
           ...huodongdasai_router_config,
            // 实训，接下来可能要删除的
            ...shixun_router_config_nouse,
            // 实训1.20
            ...shixun_router_config,
            //机构入驻管理
            ...institutional_settlement_router,
            // {
            //     name: '实训',
            //     path: '/main/PracticalTraining',
            //     component: result.PracticalTraining,
            //     children: [

            //         {
            //             path: '/main/PracticalTraining',
            //             redirect: '/main/PracticalTraining',
            //         },
            //         {
            //             name: '供应商管理',
            //             path: '/main/PracticalTraining/Supplier',
            //             component: result['Supplier']

            //         },
            //         {
            //             name: '设备分类管理',
            //             path: '/main/PracticalTraining/classified',
            //             component: result['Classified']

            //         },  
            //         {
            //             name: '设备型号管理',
            //             path: '/main/PracticalTraining/Equipment',
            //             component: result['Equipment']

            //         },
            //         {
            //             name: '初始安装管理',
            //             path: '/main/PracticalTraining/installation',
            //             component: result['Installation']

            //         },
            //         {
            //             name: '初始安装管理详情页',
            //             path: '/main/PracticalTraining/installationXiang',
            //             component: result['InstallationXiang'],
            //             namehide: true,
            //         },
            //         {
            //             name: '资源授权',
            //             path: '/main/PracticalTraining/Authorization',
            //             component: result['Authorization'],
            //             namehide: true,
            //         },
            //         {
            //             name: '硬件设备注册',
            //             path: '/main/PracticalTraining/registration',
            //             component: result['Registration']

            //         },
            //         {
            //             name: '提供商管理',
            //             path: '/main/PracticalTraining/Trainingresource',
            //             component: result['Trainingresource']

            //         }, {
            //             name: '课程资源管理',
            //             path: '/main/PracticalTraining/Curriculum',
            //             component: result['Curriculum']

            //         },
            //         {
            //             name: '课程资源管理详情页',
            //             path: '/main/PracticalTraining/CurriculumXiang',
            //             component: result['CurriculumXiang'],
            //             namehide: true,
            //         },

            //         {
            //             name: '机构申请处理',
            //             path: '/main/PracticalTraining/Organizationing',
            //             component: result['Organizationing']

            //         },

            //     ]
            // },





            // 培训中心
            ...peixun_config,
            //通用管理
            ...tongyong_config,

            ...liveStreaming,
            {
                name: '日志',
                path: '/main/journal',
                component: result.Journal
            },
            // 修改密码
            {
                name: '修改密码',
                path: '/main/editPassword-list',
                component: result['EditPassword-list'],
                children: [
                    {
                        path: '/main/editPassword-list',
                        redirect: '/main/editPassword-list/password',
                    },
                    {
                        name: '修改密码',
                        path: '/main/editPassword-list/password',
                        component: result['Password']
                    },
                ]
            },
        ]
    },

    ...liveStreamingConten,
    ...liveStreamingRoom,
    {
        name: '举报详情',
        path: '/reportDetail/:resourceId/:lastProcessType/:processState/:sourceState',
        component: result.ReportDetail
    },
    {
        name: '编辑问题',
        path: "/editQuestion",
        component: result.EditQuestion
    },
    {
        name: "登录",
        path: "/login",
        component: result.Login,
        exact: true
    },
    {
        name: "404",
        path: "/404",
        component: result.Notfound,
    },
    // {
    //     name: "401",
    //     path: "/reporterrorFour",
    //     component: result.ReporterrorFour,
    // },
    {
        name: '资源详情',
        path: '/resourceDetail/:id/:status',
        component: result.ResourceDetail
    },
    {
        name: '资源浏览通用',
        path: '/transResource/:type/:resourceId',
        component: result.TransResource
    },

    {
        name: '推荐详情',
        path: '/recommendDetail/:resourceId',
        component: result.RecommendDetail
    },
    {
        name: "编辑列表",
        path: "/editContent",
        component: result.EditContent,
    },
    {
        name: "编辑列表预览",
        path: "/previewQuestion",
        component: result.PreviewQuestion,
    },
    ...institutional_settlement_global_router,
];
