/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Button, Row, Col, message, Form, Tabs, Popover, Modal,Table,Tag} from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import './index.scss';
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {CloseCircleOutlined} from "@ant-design/icons";
import Sharecon from "../../components/shareit/index";
const { TabPane } = Tabs;
@withRouter

class Studntliveroom extends Component {
    state = {
        liveStatusFlage:1,
        isCamera:false,
        isVoice:false,
        activeKey:"1",
        coverFlag:false,
        columns: [
            {
                title: '名称',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '格式',
                dataIndex: 'format',
                key: 'format'
            },
            {
                title: '大小',
                dataIndex: 'size',
                key: 'size'
            },{
                title: '添加时间',
                dataIndex: 'creatTime',
                key: 'creatTime'
            },
            {
                title: '操作',
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => {
                    return <div className='oper'>
                        <span>预览</span>
                    </div>
                }
            }],
        dataSource:[
            {
                title:"分类名称",
                format:"doc",
                size:"115.6KB",
                creatTime:"2020-07-23 18:30",
            },
            {
                title:"分类名称",
                format:"doc",
                size:"115.6KB",
                creatTime:"2020-07-23 18:30",
            },
            {
                title:"分类名称",
                format:"doc",
                size:"115.6KB",
                creatTime:"2020-07-23 18:30",
            }
        ],
        data: [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '录制时长',
                dataIndex: 'creatTime',
                key: 'creatTime'
            },
            {
                title: '操作',
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => {
                    return <div className='oper'>
                        <span>回放</span>
                    </div>
                }
            }],
        dataSources:[
            {
                title:"石油的冶炼渔加工1",
                creatTime:"00:00:00",
            },
            {
                title:"石油的冶炼渔加工1",
                creatTime:"00:00:00",
            },
            {
                title:"石油的冶炼渔加工1",
                creatTime:"00:00:00",
            }
        ]

    };
    componentDidMount() {}

    cancel = e => {
        this.setState({ coverFlag:false });
    }
    Share = (e) => {
        this.setState({
            coverFlag: true,
        })
    }
    render() {
        const {columns,coverFlag,dataSource,data,dataSources} = this.state;
        let screenHeight = document.documentElement.clientHeight-80
        return (
            <div className="live-contpage">
                <div className="live-contmain-top">
                    <div className="wrap-center">
                        <Row>
                            <Col span={3}>
                                <span>学生人数</span>
                            </Col>
                            <Col span={18} className="col14">
                                <span>直播名称直播名称直播名称直播名称直播名称直播名称</span>
                            </Col>
                            <Col span={3}>
                                <span onClick={this.Share}>分享</span>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="wrap-center">
                    <div className="page-con">
                        <Row>
                            <Col span={24} className="li">
                                <div className="top-title">
                                    <h4 className="h4-title">直播信息</h4>
                                </div>
                                <div className="li-pic">
                                    <div className="left">
                                        <p>直播名称： 石油经过加工提炼石油经过加工提炼</p>
                                        <p>主讲教师： 杨老师</p>
                                        <p>预计开始时间： 年月日时分</p>
                                        <p>直播时长： 7小时45分钟</p>
                                        <p>直播范围： 校内公开</p>
                                        <p>直播简介： 一次加工过程，是将原油用蒸馏的方法分离成轻重不同馏分的过程，常称为原油蒸馏，它包括原油预处理、常压蒸馏和减压蒸馏。一次加工产品可以粗略地分为   两行展示100字符</p>
                                    </div>
                                    <div className="right">
                                    <span className="img">

                                    </span>
                                        <Button>报名</Button>
                                        <Button><Link
                                            to="/main/sanshi/studntliveroom">进入直播间</Link></Button>
                                    </div>
                                </div>
                            </Col>
                            <Col span={24} className="li">
                                <div className="top-title">
                                    <h4 className="h4-title">直播资料<span>注：直播结束后方可查看资料</span></h4>
                                </div>
                                <div className="bottom-table">
                                    {/*<DndProvider backend={HTML5Backend}>*/}
                                        <Table className="main-box-header table-maina" cellspacing={20}  columns={columns} dataSource={dataSource} pagination={false} rowKey={row => row.id}
                                               components={this.components}
                                               onRow={(record, index) => ({
                                                   index,
                                                   moveRow: this.moveRow,
                                               })}
                                        />
                                    {/*</DndProvider>*/}
                                </div>
                            </Col>
                            <Col span={24} className="li">
                                <div className="top-title">
                                    <h4 className="h4-title">直播回放</h4>
                                </div>
                                <div className="bottom-table">
                                    {/*<DndProvider backend={HTML5Backend}>*/}
                                        <Table className="main-box-header table-maina" cellspacing={20}  columns={data} dataSource={dataSources} pagination={false} rowKey={row => row.id}
                                               components={this.components}
                                               onRow={(record, index) => ({
                                                   index,
                                                   moveRow: this.moveRow,
                                               })}
                                        />
                                    {/*</DndProvider>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Modal className='Modal-butApplyuse' visible={coverFlag} destroyOnClose={true} maskClosable={false} title="直播名称"
                       onCancel={this.cancel}
                       centered={true}
                       closeIcon={<CloseCircleOutlined />}
                       footer={
                           <div className='pos-btn'>
                               <Button className="but" onClick={this.cancel}>返回</Button>
                               <Button className="but" type="primary" onClick={this.sureChangeCover}>一键分享</Button>
                           </div>
                       }
                >
                    <Sharecon bannerDetail ={this.state.bannerDetail}
                              wrappedComponentRef={form => {
                                  this.AddCover = form;}}/>
                </Modal>
            </div>
        );
    }
}

export default Studntliveroom;
