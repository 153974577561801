import { result } from './result'

export default [
    {
        name: '实训',
        path: '/main/practTrain',
        component: result.PractTrain,
        children: [
            //管理者
            {
                path: '/main/practTrain',
                redirect: '/main/practTrain/hard_file',
            },
            {
                name: '实训配置管理',
                path: '/main/practTrain/configPage',
                component: result.ConfigPage
            },
            //硬件
            {
                name: '硬件分类管理',
                path: '/main/practTrain/hardware_class',
                component: result.Hardware_class
            },
            {
                name: '硬件设备管理',
                path: '/main/practTrain/hard_facility',
                component: result['Hard_facility']
            },
            {
                name: '安装文件管理',
                path: '/main/practTrain/hard_file',
                component: result['Hard_file']
            },
            {
                name: '追踪记录',
                path: '/main/practTrain/hard_record',
                component: result['Hard_record']
            },


            //实训资源管理

            {
                name: '资源分类管理',
                path: '/main/practTrain/course_class',
                component: result['Course_class']
            },
            {
                name: '课程资源管理',
                path: '/main/practTrain/course-mg',
                component: result['Resource-mg']
            },
            {
                name: '追踪日记记录',
                path: '/main/practTrain/course-log',
                component: result['Course-log']
            },
            //实训资源管理
            {
                name: '申请资源管理',
                path: '/main/practTrain/applay-mg',
                component: result['Applay-mg']
            },
            {
                name: '资源下发申请',
                path: '/main/practTrain/applay-mg-detail',
                component: result['Applay-mg-detail']
            },
            //销售
            // 实训硬件资源
            {
                name: '硬件设备清单',
                path: '/main/practTrain/hardware-resource',
                component: result['Hardware-resource']
            },

            {
                name: '设备到平台注册',
                path: '/main/practTrain/hardware-regisrtry',
                component: result['Hardware-regisrtry']
            },
            // 实训内容资源
            {
                name: '虚拟仿真资源清单',
                path: '/main/practTrain/content-list',
                component: result['Content-list']
            }, {
                name: '通用在线课程清单',
                path: '/main/practTrain/content-resource',
                component: result['Content-resource']
            },
        ]
    }

]