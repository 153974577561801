import React, { useEffect, useReducer } from 'react';
import { HashRouter } from 'react-router-dom'
import RouterView from './router/router_view'
import routers from './router/router_config'
import './reset_style/public.scss';
import resetAntd from './config/antd_global';
import 'antd/es/modal/style';
import { getRoles, getMenus } from '@/store/OBS/main/action.js';
import { connect } from 'react-redux';
import { noRequestList } from '@/config/index.js';
import { GetImageBaseUrl } from 'gongyongpicpreview'
import { Context, store as modalContextValue, reducer } from './store/ModalReducer/ModalReducer';
window.elGoTop = function (n) {
  document.querySelector(n) && document.querySelector(n).scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

function App(props) {
  const [value, dispatch] = useReducer(reducer, modalContextValue);
  useEffect(() => {
    let pathname = window.location.href.split('/#/')[1];
    if (!noRequestList.includes(pathname)) {
      props.getRoles();
      props.getMenus();
    }
  }, [])
  return (
      <HashRouter>
          <Context.Provider value={{ store: value, dispatch }}>
            <RouterView routers={routers}></RouterView>
          </Context.Provider>
      </HashRouter>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getRoles(data) {
      dispatch(getRoles(data));
    },
    getMenus(data) {
      dispatch(getMenus(data));
    }
  }
}
App = connect(null, mapDispatchToProps)(App);
App = resetAntd(App);

export default App
