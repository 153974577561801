
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import OBreadcrumb from '../../components/Breadcrumb'
import './index.scss'
import { Redirect } from 'react-router-dom';
import RouterView from '../../router/router_view';
import routerList from '@/router/router_config.js';
import Menu from '@/components/Menu';

const { Sider, Content } = Layout;

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(class LiveBroadcast extends React.Component {
    render() {
        let { menuList } = this.props;


        let path = this.props.location.pathname;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        let three;
        menuList[0] && menuList[0].children.forEach(v => {
            v.children && v.children.forEach(k => {
                if (k.menuUrl === path) {
                    // two = v;
                    three = k;
                }
            })
        })
        let arr = [one, three];
        let propsMenuList = menuList.find(v => v.menuName === "直播");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        let id = propsMenuList[0] && propsMenuList[0].id;
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    {/* <SiderLogo></SiderLogo> */}
                    {id ? <Menu {...this.props}  menuList={propsMenuList} /> : null}
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    <Content className="warpheight" style={{ margin: '15px 10px', minHeight:' calc(100vh - 141px)', minWidth:'920px' }}>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
})
