
import reducers from './reducers'
let defaultState = {
    mvisible: false,
    selectedRowKeys:[],
    table_title_showModal_visible:false,
    data: []

}
const Grounding = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Grounding
