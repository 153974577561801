import React from "react";
import { connect } from "react-redux";
import { Form, Button, Layout } from "antd";
import RouterView from "../../router/router_view";
import "./index.scss";
import { Redirect } from "react-router-dom";
// const mapStateToProps = ({ ACTIVITYSERIES }) => {
//   const { menuList } = ACTIVITYSERIES;
//   return {
//     menuList,
//   };
// };
export default connect()(
  class ActivitySeries extends React.Component {
    render() {
      return (
        <div className="activitySeries">
          {localStorage.getItem("userInfo") ? (
            <div>
              {/* <Button
                onClick={() => {
                  this.props.history.push("/main/huodongdasai/addClasses");
                }}
              >
                赛事类别管理
              </Button> */}
              <RouterView routers={this.props.routers} />
            </div>
          ) : (
            <Redirect to="/login" />
          )}
        </div>
      );
    }
  }
);
