import React, { Component, Fragment } from 'react';
import {
    Player,
    ControlBar,
    PlayToggle, //  播放/暂停按钮
    ReplayControl, // 后退按钮
    ForwardControl,  // 前进按钮
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,  // 倍速播放选项
    VolumeMenuButton,
    BigPlayButton,
    LoadingSpinner  //loding
} from 'video-react';
import "video-react/dist/video-react.css";
import './videoplayer.scss'

class PlayerVideo extends Component {
    componentDidMount() {
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    handleStateChange(state, prevState) {
        // console.log(state)
        // console.log(state.currentTime);
    }

    render() {
        // startTime 开始播放的位置
        const {startTime}=this.props
        return (
            <Fragment>
                <div style={{ width: '100%', height: 'auto' }} className='video_c'>
                    <Player
                        ref={c => {
                            this.player = c;
                        }}
                        poster="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=2724409193,2018760642&fm=26&gp=0.jpg"
                        autoPlay={true}
                        startTime={startTime}
                    >
                        <source
                            src={this.props.src}
                            type="video/mp4"
                        />
                        <ControlBar autoHide={false} disableDefaultControls={false} className="c_bar" >
                            {/* <ReplayControl seconds={10} order={1.1} /> */}
                            {/* <ForwardControl seconds={30} order={1.2} /> */}
                            <BigPlayButton position="center" />
                            <PlayToggle />
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            {/*<LoadingSpinner />*/}
                            <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
                            <VolumeMenuButton />
                        </ControlBar>
                    </Player>
                </div>
            </Fragment>
        )
    }
}


export default PlayerVideo;
