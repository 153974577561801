import React from 'react';
import './index.scss';
import { Layout } from 'antd';
import OBreadcrumb from '../../components/Breadcrumb'
import './index.scss'
import RouterView from '../../router/router_view';
import routerList from '@/router/router_config.js';
import Menu from '@/components/Menu';
import { connect } from "react-redux";
const { Sider, Content } = Layout;
const mapStateToProps = ({ MAIN }) => {
    const { activeKey, headerMenuList, buttonRole, menuList } = MAIN;
    return {
        activeKey, headerMenuList, buttonRole, menuList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

    };
}
const menuList = [
    {
        title: '机构管理',
        key: 'sub1',
        icon: 'icon_sidebar.svg',
        children: [
            {
                title: '院校入驻管理',
                key: '0',
                path: '/main/platform-business/in-school'
            },
            {
                title: '创建学校',
                key: '1',
                path: '/main/platform-business/add-school',
                isHade: true
            },
            {
                title: '学校详情',
                key: '2',
                path: '/main/platform-business/detil-school',
                isHade: true
            },
            {
                title: '管理机构入驻管理',
                key: '3',
                path: '/main/platform-business/management_organization'
            },
            {
                title: '创建管理机构',
                key: '4',
                path: '/main/platform-business/add-management',
                isHade: true
            },
            {
                title: '机构管理详情',
                key: '5',
                path: '/main/platform-business/detil-management',
                isHade: true
            }, {
                title: '虚拟机构入驻管理',
                key: '6',
                path: '/main/platform-business/enterprise_settlement'
            },
            {
                title: '创建虚拟机构',
                key: '7',
                path: '/main/platform-business/add-enterprise',
                isHade: true
            },
            {
                title: '虚拟机构详情',
                key: '8',
                path: '/main/platform-business/detil-enterprise',
                isHade: true
            },
        ]
    }, {
        title: '机构成员管理',
        key: 'sub2',
        icon: 'icon_sidebar2.svg',
        children: [
            {
                title: '学校成员管理',
                key: '9',
                path: '/main/platform-business/student-list'
            },
            {
                title: '管理机构成员管理',
                key: '10',
                path: '/main/platform-business/management-institutions'
            },
            {
                title: '虚拟机构成员管理',
                key: '11',
                path: '/main/platform-business/virtual-organization'
            },
        ]
    },
]


export default connect(mapStateToProps, mapDispatchToProps)(class Resource extends React.Component {
    render() {
        let { menuList } = this.props;
        let path = this.props.location.pathname;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        let two, three;
        menuList[0] && menuList[0].children.forEach(v => {
            v.children && v.children.forEach(k => {
                if (k.menuUrl === path) {
                    two = v;
                    three = k;
                }
            })
        })
        let arr = [one, two, three];
        let propsMenuList = menuList.find(v => v.menuName === "机构入驻");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        let id = propsMenuList[0]&&propsMenuList[0].id;
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    {/* <SiderLogo></SiderLogo> */}
                   {id?<Menu {...this.props} openArr={[id]}  menuList={propsMenuList} />:null} 
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}
)
