import LoadableComponent from '../until/LoadableComponent';
let moduleFile = require.context('../page', true, /\index.(jsx|js)$/);
let result = moduleFile.keys().reduce((prev, item) => {
    let str = item.split('/')[item.split('/').length - 2];
    let name = str[0].toLocaleUpperCase() + str.slice(1);
    prev = Object.assign({}, prev, {
        [name]: LoadableComponent(import('../page' + item.slice(1))),
    });
    return prev;
}, {});
const liveStreaming = [
    {
        name: '直播',
        path: '/main/livebroadcast',
        component: result['Livebroadcast'],
        children: [
            {
                path: '/main/livebroadcast',
                redirect: '/main/livebroadcast/adminlive'
            },
            {
                name: "直播任务列表",
                path: "/main/livebroadcast/adminlive",
                component: result['Adminlive'],
            },
            {
                name: "举报管理",
                path: "/main/livebroadcast/reportlive",
                component: result['Reportlive'],
            },
            {
                name: "处理完毕",
                path: "/main/livebroadcast/processedlive",
                component: result['Processedlive'],
            },
            {
                name: "直播数据统计",
                path: "/main/livebroadcast/statistics",
                component: result['Statistics'],
            }
        ]
    },
]

const liveStreamingConten = [
    {
        name: "直播详情",
        path: "/livecontent",
        component: result['Livecontent'],
        exact: true
    },
]
const liveStreamingRoom = [
    {
        name: "直播间",
        path: "/livepageroom",
        component: result['Livepageroom'],
        exact: true
    },
]
export {
    liveStreaming,
    liveStreamingConten,
    liveStreamingRoom,
}