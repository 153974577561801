import React, { Component } from 'react';
import AppAccessInfo from '../../components/app_access_info';
import {infoManageMechanism} from '../../api/manage_mechanism_manage';
import styles from './index.module.scss';

export class ManageMechanismInfo extends Component {
	state = {
		info: {}
	};

	componentDidMount = async () => {
		const {location} = this.props;
		const urlId = this.getUrlType(location.search,'id');
		if(urlId){
			try {
				const info = await infoManageMechanism({adminOrgId:urlId});
				this.setState({info})
			} catch (error) {
				console.warn(error);
			}
		}
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		const {
			info
		} = this.state;
		const {location} = this.props;
		const urlId = this.getUrlType(location.search,'id');

		return (
			<div className={styles["manage_mechanism_info"]}>
				<div className={styles["info_basics"]}>
					<h1>管理机构基本信息</h1>
					<div className={styles["info_form"]}>
						<div className={styles["info_item"]}>
							<div className={styles["item_label"]}>管理机构名称</div>
							<div className={styles["item_content"]}>
								<div className={styles["content_input"]}>{info.enterpriseName}</div>
							</div>
						</div>
						<div className={styles["info_item"]}>
							<div className={styles["item_label"]}>管理机构识别码</div>
							<div className={styles["item_content"]}>
								<div className={styles["content_input"]}>{info.enterpriseCode}</div>
							</div>
						</div>
						
						<div className={styles["info_item"]}>
							<div className={styles["item_label"]}>管理范围</div>
							<div className={styles["item_content"]}>
								<div className={`${styles["content_input"]} ${styles["content_range"]}`}>
									{
										Array.isArray(info.orgRangeNames) && info.orgRangeNames.map(
											(it,inx)=><span key={it}>{it}{inx === info.orgRangeNames.length-1 ? '' : ' / '}</span>
										)
									}
								</div>
							</div>
						</div>
						<div className={styles["info_item"]}>
							<div className={styles["item_label"]}>可管理机构类型</div>
							<div className={styles["item_content"]}>
								<div className={styles["content_input"]}>{info.manageOrgType}</div>
							</div>
						</div>
						<div className={styles["info_item"]}>
							<div className={styles["item_label"]}>管理机构logo</div>
							<div className={styles["item_content"]}>
								{
									info.enterpriseLogo 
									? <img alt='' className={styles["content_img"]} src={window.$$imgSrc(info.enterpriseLogo)} />
									: <div className={styles["content_img"]}/>
								}
								
							</div>
						</div>
						<div className={styles["info_item"]}>
							<div className={styles["item_label"]}>所在地</div>
							<div className={styles["item_content"]}>
								<div className={styles["content_input"]}>{info.provinceName}&ensp;/&ensp;{info.cityName}</div>
							</div>
						</div>
						<div className={styles["info_item"]}>
							<div className={styles["item_label"]}>机构标签</div>
							<div className={styles["item_content"]}>
								<div className={styles["content_type"]}>
									{
										Array.isArray(info.labelNames) && info.labelNames.map(it=>{
											return <span key={it}>{it}</span>
										})
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<AppAccessInfo 
					id={urlId}
					rightObj={{
                        date:'OBSMM-01-A02-c-btnExtendedUseTime',
                        record:'OBSMM-01-A02-c-btnViewActivationRecords',
                        stop:'OBSMM-01-A02-c-btnForcedDeactivation',
                        url:'OBSMM-01-A02-c-btnModifyAccessAddress',
                        formal:'OBSMM-01-A02-c-btnBecomeOfficial',
                        open:'OBSMM-01-A02-c-btnKaiTongAgain',
                    }}
				/>
			</div>
		)
	}
}

export default ManageMechanismInfo;
