import React, { Component } from "react";
import {
  Input,
  Select,
  Button,
  Table,
  Modal,
  Form,
  Radio,
  DatePicker,
  InputNumber,
  message,
} from "antd";
import locale from 'antd/es/date-picker/locale/zh_CN';

import TopTitle from "../../../components/BreadcrumbShixun";
import {
  Yichang,
  Word,
  Xiazai,
  YuanAdd,
  EditNoActive,
} from "../../../components/shixunsvg1.20/index";
import {
  queryProvince,
  queryArea,
  queryCity,
  resourceorgTablelist,
  resourceorgRevocation,
  resourceorgAddRevocation,
  resourceorgOptionAuthTypeList,
  resourceorgAuthList,
  pageListdara
} from "../../../api/shixun2/index";
import MaxLengthInput from "../../../components/hhinput";
import "./index.scss";
import moment from 'moment'
const { Option } = Select;
export class index extends Component {
  state = {
    dataSource: [],
    dataSourceloging: true, //大表戈 弹框loding

    dataSourceTable: [], //操作记录
    dataSourceTableloging: true, //操作记录loding
    pageSizeLogin: 10,
    pageNumLogin: 1,
    totalLogin: 0,

    operation: false, //操作记录弹框
    authorization: false, //授权记录
    dataSourceTableuthor: [], //授权记录
    dataSourceTablelogingthor: true, //授权记录loding
    visible: false, ///添加
    value: 1, //Radio
    startValue: null, //生效日期
    endValue: null, //结束日期
    endOpen: false, //
    provinceData: [],
    cityData: [],
    areaData: [],
    queryCondition: {
      pageNum: 1,
      pageSize: 10,
      resourceId: sessionStorage.getItem("resourceId"),
    },
    authorizationMethod: [],
    pageNum: 1,
    pageSize: 10,
    detailTotal: 0,
  };

  // 省
  abc = (value, field, name) => {
    console.log(value, field);
    const params = this.state.queryCondition;
    if (value == undefined) {
      params[field] = undefined;
    } else {
      params[field] = value.target ? value.target.value : value;
    }

    if (name == "province") {
      params["cityId"] = undefined;
      params["areaId"] = undefined;
      queryCity({ provinceCode: value }).then((res) => {
        console.log(res);
        this.setState({
          cityData: res,
        });
      });
    } else if (name == "city") {
      params["areaId"] = undefined;
      if (value == undefined) {
        params[field] = undefined;
      } else {
        params[field] = value.target ? value.target.value : value;
        queryArea({ cityCode: value }).then((res) => {
          this.setState({
            areaData: res,
          });
        });
      }
    }
    this.setState({
      queryCondition: params,
    });
  };

  Radio_onChange = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  Shu_onChange(value) {
    console.log("changed", value);
  }
  //   *生效日期 和结束日期
  disabledStartDate = (startValue) => {

    return startValue && startValue < moment(Date.now() - 86400000).endOf('day');
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;

    return endValue < moment() || startValue > endValue;
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  componentDidMount() {
    this.getData(); //大表格

    // this.Getauthorization(); //授权记录
    console.log(this.props.location.query);
    console.log(sessionStorage.getItem("resourceId"));
    queryProvince().then((res) => {
      console.log(res);
      this.setState({
        provinceData: res,
      });
    });
    resourceorgOptionAuthTypeList().then((res) => {
      console.log(res);
      this.setState({
        authorizationMethod: res,
      });
    });
  }
  resetBtn = () => {
    const params = this.state.queryCondition;
    for (var key in params) {
      params[key] = undefined;
    }
    params["pageNum"] = 1;
    params["resourceId"] = sessionStorage.getItem("resourceId");
    params["pageSize"] = 10;
    this.setState(
      {
        queryCondition: params,
      },
      () => {
        this.getData();
      }
    );
  };
  getData = () => {
    const { queryCondition } = this.state;
    console.log(queryCondition);
    this.setState({
      dataSourceloging: true,
    });
    resourceorgTablelist(queryCondition).then(
      (res) => {
        console.log(res);
        if (res) {
          this.setState({
            dataSource: res.data,
            total: res.total,
            dataSourceloging: false,
          });
        }
      },
      () => {
        this.setState({
          dataSource: [],
          total: 0,
          dataSourceloging: false,
        });
      }
    );
  };
  // 操作记录  
  getDataList = (pp) => {
    return pageListdara({
      operateType: 7,
      pageSize: pp.pageSize,
      pageNum: pp.pageNum
    }).then(res => {

      if (res && res.data) {

        this.setState(() => {
          return {
            dataSourceTable: res.data,
            totalLogin: res.total,
            pageSizeLogin: res.pageSize,
            pageNumLogin: res.pageNum,
            dataSourceTableloging: false

          }
        })
      } else {
        message.info('数据错误')
      }

    })
  }
  //pageSize变化 操作记录
  onShowSizeChangeLogin = (current, pageSizeLogin) => {
    // console.log(current, pageSize, ":sdd")
    this.setState({
      pageNumLogin: current,
      pageSizeLogin: pageSizeLogin,
    }, () => {
      this.getDataList({
        pageSize: this.state.pageSizeLogin,
        pageNum: this.state.pageNumLogin
      });
    }
    );
  };

  //pageNum变化 操做
  onPageNumChangeLogin = (current, pageSizeLogin) => {
    // console.log(current, pageSize, ":sdd")
    this.setState({
      pageNumLogin: current,
    }, () => {
      this.getDataList({
        pageSize: this.state.pageSizeLogin,
        pageNum: this.state.pageNumLogin
      });
    }
    );
  }
  Getauthorization = () => {
    const { dataSourceTableuthor } = this.state;
    for (var i = 0; i < 100; i++) {
      dataSourceTableuthor.push({ key: i + "", age: i, address: "啥黄瓜" + i });
    }

    setTimeout(() => {
      this.setState(() => {
        return {
          dataSourceTableuthor: [...dataSourceTableuthor],
          dataSourceTablelogingthor: false,
        };
      });
    }, 2000);
  };

  // 添加
  showModal = (record) => {
    this.setState({
      visible: true,
      addParams: {
        resourceOrgId: record.resourceOrgId,
        orgId: record.orgId,
      },
    });
  };
  getTimer(startValue) {
    var date = new Date(startValue);
    console.log(date);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;

    var w = date.getDay();

    var h = date.getHours();
    h = h < 10 ? "0" + h : h;
    var min = date.getMinutes();
    min = min < 10 ? "0" + min : min;
    var s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
  }
  handleOk = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      if (!err) {
        const param = {
          ...this.state.addParams,
          beginTime: this.getTimer(values.beginTime),
          endTime: this.getTimer(values.endTime),
          authorizationNum: values.authorizationNum,
          authorizationType: values.authorizationType,
          resourceId: sessionStorage.getItem("resourceId"),
        }
        values.authorizationType === 3 && delete param.endTime
        resourceorgAddRevocation(param).then(() => {
          message.success("授权成功");
          this.getData();
          this.setState(() => {
            return {
              visible: false,
            };
          });
        });
        console.log(this.state.addParams, values);
        console.log(this.getTimer(values.beginTime));
      }
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  // 撤销
  deleteSiginDir = (record) => {
    Modal.confirm({
      title: "取消授权提示",
      icon: null,
      content: (
        <div style={{ display: "flex" }}>
          <Yichang />
          &emsp;是否确认撤销授权？
        </div>
      ),
      okText: "确定",
      cancelText: "取消",
      className: " deleteconfim_che",
      onCancel: this.ModalconfirmonCancel,
      onOk: () => {
        this.ModalconfirmononOk(record);
      },
    });
  };
  ModalconfirmonCancel = () => { };
  ModalconfirmononOk = (record) => {
    console.log(record);
    if (record.resourceOrgId) {
      resourceorgRevocation({ resourceOrgId: record.resourceOrgId }).then(
        () => {
          message.success("撤销成功");
          this.getData();
        }
      );
    } else {
      message.error("请先授权再撤销授权！");
    }
  };
  // 操作
  operationModal = () => {
    this.getDataList({
      pageSize: this.state.pageSizeLogin,
      pageNum: this.state.pageNumLogin
    }); //操作记录
    this.setState({
      operation: true,
    });
  };

  operationleOk = (e) => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };

  operationCancel = (e) => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };
  // 授权记录
  authorizationModal = (record) => {
    this.setState(
      {
        authorization: true,
        authList: record,
      },
      () => {
        this.queryAuthList();
      }
    );
  };

  authorizationOk = (e) => {
    console.log(e);
    this.setState({
      authorization: false,
    });
  };
  queryAuthList = () => {
    this.setState({
      dataSourceTablelogingthor: true,
    });
    resourceorgAuthList({
      orgId: this.state.authList.orgId,
      resourceId: sessionStorage.getItem("resourceId"),
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
    }).then((res) => {
      this.setState({
        dataSourceTableuthor: res.data,
        dataSourceTablelogingthor: false,
        detailTotal: res.total,
      });
    });
  };
  authorizationCancel = (e) => {
    console.log(e);
    this.setState({
      authorization: false,
    });
  };
  render() {
    const { RenderButton } = React;

    const {
      dataSource,
      dataSourceTable,
      dataSourceTableuthor,
      startValue,
      endValue,
      endOpen,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout_aaaa = {
      labelCol: {
        xs: { span: 22 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 14 },
      },
      // klgfjkfdljg
    };
    const columns = [
      {
        title: "机构名称",
        dataIndex: "enterpriseName",
        key: "enterpriseName",
        align: "center",
      },
      {
        title: "机构类型",
        dataIndex: "enterpriseTypeName",
        key: "enterpriseTypeName",
        align: "center",
      },
      {
        title: "所在区域",
        dataIndex: "site",
        key: "site",
        align: "center",
      },
      {
        title: "授权状态",
        dataIndex: "authorizationStatusDesc",
        key: "authorizationStatusDesc",
        align: "center",
      },
      {
        title: "授权节点",
        dataIndex: "authorizationNum",
        key: "authorizationNum",
        align: "center",
      },
      {
        title: "生效日期",
        dataIndex: "beginTime",
        key: "beginTime",
        align: "center",
      },
      {
        title: "结束日期",
        dataIndex: "endTime",
        key: "endTime",
        align: "center",
      },
      {
        title: "操作",
        align: "center",
        width:300,
        render: (v, b, value) => {
          // console.log(v, b, 'vvvbbb')

          return (
            <div className="action vertical_j" style={{ cursor: "pointer" }}>
              <RenderButton id="ABB-01-B03-b_btnAuthorization">
                <span
                  onClick={() => {
                    this.showModal(b);
                  }}
                >
                  授权
              </span>
              </RenderButton>
              <RenderButton id="ABB-01-B03-b_btnRevocation">
                <span
                  onClick={() => {
                    this.deleteSiginDir(b);
                  }}
                >
                  撤销
              </span>
              </RenderButton>
              <RenderButton id="ABB-01-B03-b_btnAuthorizationRecord">
                <span
                  onClick={() => {
                    this.authorizationModal(b);
                  }}
                >
                  授权记录
              </span>
              </RenderButton>
            </div>
          );
        },
      },
    ];
    const columnsTable = [
      {
        title: "操作人",
        dataIndex: "account",
        align: "center",
      },

      {
        title: "课程资源管理",
        dataIndex: "operationDesc",
        align: "center",
      },
      {
        title: "操作时间",
        dataIndex: "createTime",

        align: "center",
      },
      {
        title: "动作",
        dataIndex: "actionDscp",

        align: "center",
      },
    ];
    const columnsTableanth = [
      {
        title: "授权添加时间",
        dataIndex: "createTime",
        key: "createTime",
        align: "center",
      },

      {
        title: "授权方式",
        dataIndex: "authorizationType",
        key: "authorizationType",
        align: "center",
        render: (text) => {
          return (
            <span>{text == 1 ? "购买" : text == 2 ? "试用" : "无限期"}</span>
          );
        },
      },
      {
        title: "授权节点",
        dataIndex: "authorizationNum",
        key: "authorizationNum",
        align: "center",
      },
      {
        title: "生效日期",
        dataIndex: "beginTime",
        key: "beginTime",
        align: "center",
      },
      {
        title: "结束日期",
        dataIndex: "endTime",
        key: "endTime",
        align: "center",
      },
      {
        title: "授权状态",
        dataIndex: "authorizationStatusDesc",
        key: "authorizationStatusDesc",
        align: "center",
      },
      {
        title: "操作人",
        dataIndex: "createUserName",
        key: "createUserName",
        align: "center",
      },
    ];

    const {
      provinceData,
      cityData,
      areaData,
      queryCondition,
      total,
      authorizationMethod,
    } = this.state;

    const paginationProps = {
      showSizeChanger: true, //设置每页显示数据条数
      showQuickJumper: false,
      pageSize: queryCondition.pageSize,
      total: total, //数据的总的条数
      current: queryCondition.pageNum,
      onChange: (page) => {
        const params = this.state.queryCondition;
        params["pageNum"] = page;
        this.setState(
          {
            queryCondition: params,
          },
          () => {
            this.getData();
          }
        );

        console.log(page);
      }, //点击当前页码
      onShowSizeChange: (current, pageSize) => {
        //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
        const params = this.state.queryCondition;
        params["pageSize"] = pageSize;
        this.setState(
          {
            queryCondition: params,
          },
          () => {
            this.getData();
          }
        );

        // this.onShowSizeChange(current, pageSize);
      },
    };
    const paginationPropsDetail = {   
      showSizeChanger: true, //设置每页显示数据条数
      showQuickJumper: false,
      pageSize: this.state.pageSize,
      total: this.state.detailTotal, //数据的总的条数
      current: this.state.pageNum,
      onChange: (page) => {
        this.setState(
          {
            pageNum: page,
          },
          () => {
            this.queryAuthList();
          }
        );

        console.log(page);
      }, //点击当前页码
      onShowSizeChange: (current, pageSize) => {
        //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数

        this.setState(
          {
            pageSize: pageSize,
          },
          () => {
            this.queryAuthList();
          }
        );

        // this.onShowSizeChange(current, pageSize);
      },
    };
    const topTitleData = {
      span_1: "实训",
      span_2: "实训资源管理",
      span_3: "课程资源管理",
      span_4: "资源授权",
      span_3_fun: res => {
        this.props.history.go(-1)
      },
      span_1_c: "#333",
      span_2_c: "#333",
      span_3_c: "#333",
      container:"资源授权",
    };
    return (
      <div className={'Authrization_warp'}>
        <TopTitle {...topTitleData} />
        <div className="Authrization_ement">
          <div className="Authrization_ement_top">
            <div>
              <Input
                  value={queryCondition.enterpriseName}
                  placeholder="请输入机构名称"
                  style={{ width: 230 }}
                  onChange={(value) => {
                    this.abc(value, "enterpriseName");
                  }}
              />
              <Select
                  //   allowClear
                  showSearch
                  value={queryCondition.provinceId}
                  style={{ width: 230, marginLeft: 30 }}
                  placeholder="请选择省"
                  optionFilterProp="children"
                  onChange={(value) => {
                    this.abc(value, "provinceId", "province");
                  }}
              >
                {provinceData
                    ? provinceData.map((item) => {
                      return (
                          <Option value={item.provinceCode}>{item.name}</Option>
                      );
                    })
                    : null}
              </Select>
              <Select
                  //   allowClear
                  showSearch
                  value={queryCondition.cityId}
                  style={{ width: 230, marginLeft: 30 }}
                  placeholder="请选择市"
                  optionFilterProp="children"
                  onChange={(value) => {
                    this.abc(value, "cityId", "city");
                  }}
              >
                {cityData
                    ? cityData.map((item) => {
                      return <Option value={item.cityCode}>{item.name}</Option>;
                    })
                    : null}
              </Select>
              <Select
                  //   allowClear
                  value={queryCondition.areaId}
                  showSearch
                  style={{ width: 230, marginLeft: 30 }}
                  placeholder="请选择区"
                  optionFilterProp="children"
                  onChange={(value) => {
                    this.abc(value, "areaId");
                  }}
              >
                {areaData
                    ? areaData.map((item) => {
                      return <Option value={item.areaCode}>{item.name}</Option>;
                    })
                    : null}
              </Select>
            </div>
            <div>
              <Button style={{ marginRight: 20 }} onClick={this.resetBtn}>
                重置
              </Button>
              <Button type="primary" onClick={this.getData}>
                查询
              </Button>
            </div>
          </div>
          <div className="Authrization_ement_main">
            <RenderButton id="ABB-01-B03-b_btnOperatingRecord">
              <Button type="primary" onClick={this.operationModal}>
                操作记录
              </Button></RenderButton>
          </div>
          <div className="Authrization_ement_button">
            <Table
                dataSource={dataSource}
                columns={columns}
                loading={this.state.dataSourceloging}
                pagination={paginationProps}
                scroll={{ y: "calc(100vh - 490px)", x: "calc(100% - 700px)" }}
            />
            ;
          </div>
          <Modal
              title={
                <div style={{ fontWeight: 'bold', color: "#333", fontSize: 18 }}>
                  操作记录
                </div>
              }
              visible={this.state.operation}
              // onOk={this.operationOk}
              onCancel={this.operationleOk}
              // bodyStyle={{ height: 400 }}
              wrapClassName="details_record_anth"
              width={1000}
              centered
              closable={false}
              footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={this.operationleOk}
                    style={{ width: 90, height: 38 }}
                >
                  我知道了
                </Button>,
              ]}
          >
            <div>
              <Table
                  dataSource={dataSourceTable}
                  columns={columnsTable}
                  loading={this.state.dataSourceTableloging}
                  className={'columnsTable_warp'}
                  pagination={
                    {
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30'],
                      showSizeChanger: true,
                      onShowSizeChange: (a, b) => this.onShowSizeChangeLogin(a, b),
                      onChange: (a, b) => this.onPageNumChangeLogin(a, b),
                      current: this.state.pageNumLogin,
                      pageSize: this.state.pageSizeLogin,
                      total: this.state.totalLogin,
                    }
                  }
                  scroll={{  x: "calc(100% - 700px)",y:300 }}
              />
            </div>
          </Modal>

          <Modal
              title={
                <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
                  授权记录
                </div>
              }
              visible={this.state.authorization}
              // onOk={this.operationOk}
              onCancel={this.authorizationOk}
              // bodyStyle={{ height: 400 }}
              // wrapClassName="details_record_anth"
              width={1000}

              centered
              closable={false}
              footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={this.authorizationCancel}
                    style={{ width: 90, height: 38 }}
                >
                  我知道了
                </Button>,
              ]}
          >

            <Table
                dataSource={dataSourceTableuthor}
                columns={columnsTableanth}
                loading={this.state.dataSourceTablelogingthor}
                pagination={paginationPropsDetail}
                scroll={{  x: "calc(100% - 700px)" }}
            />

          </Modal>


          <Modal
              title={
                <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
                  添加授权
                </div>
              }
              key={this.state.visible}
              destroyOnClose
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              bodyStyle={{ height: 350 }}
              width={660}
              centered
              closable={false}
              wrapClassName="Authorization_add"
          >
            <Form {...formItemLayout_aaaa}>
              <Form.Item label="授权方式">
                {getFieldDecorator("authorizationType", {
                  rules: [{ required: true, message: "请选择授权方式" }],
                })(
                    <Radio.Group
                        onChange={this.Radio_onChange}
                        value={this.state.value}
                    >
                      {authorizationMethod
                          ? authorizationMethod.map((item) => {
                            return <Radio value={item.type}>{item.desc}</Radio>;
                          })
                          : null}
                    </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="生效日期">
                {getFieldDecorator("beginTime", {
                  rules: [{ required: true, message: "请选择生效日期" }],
                })(
                    <DatePicker

                        disabledDate={this.disabledStartDate}
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        value={startValue}
                        placeholder="请选择生效日期"
                        onChange={this.onStartChange}
                        onOpenChange={this.handleStartOpenChange}
                    />
                )}
              </Form.Item>
              {this.state.value == 3 ? null : (
                  <Form.Item label="结束日期">
                    {getFieldDecorator("endTime", {
                      rules: [{ required: true, message: "请选择结束日期" }],
                    })(
                        <DatePicker
                            disabledDate={this.disabledEndDate}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            value={endValue}
                            placeholder="请选择结束日期"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                        />
                    )}
                  </Form.Item>
              )}

              <Form.Item className="xxxx_before" label="授权节点">
                {getFieldDecorator("authorizationNum", {
                  rules: [{ required: false, message: "请选择授权节点" }],
                })(
                    <InputNumber
                        min={1}
                        max={200}
                        defaultValue={3}
                        onChange={this.Shu_onChange}
                    />
                )}
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Form.create()(index);
