import React from 'react';
import {Button, Form, Input, message, Modal, Select, DatePicker,Radio} from "antd";
import '../index.scss';
import {save,selectList,updateById} from '@/api/craftsman-app/index'
const {TextArea} = Input;
const {Option} = Select;
class addModel extends React.Component {
    state = {
        data:{},
        moduleListData:{},  //表单数据
    }

    componentDidMount() {
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.moduleListData) {
            this.setState({
                moduleListData: nextProps.moduleListData,
                visible: nextProps.visible,
            })
        }
    }
    selectModelChange = (value) =>{
        console.log('选择',value)
        //let module = this.state.moduleListData.find(item => item.moduleId === value);
        this.setState({
            moduleId:value,
        })
    }
    //保存
    equipmentSave = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    saveLoading: true
                })
                let successBack = () => {
                    this.props.refreshData()
                    this.props.form.resetFields()//清空表单
                    this.checkCancel()
                    this.setState({
                        visible: false,
                        saveLoading: false,
                        moduleListData:{}
                    });
                }
                if (this.state.moduleListData.moduleId) { //有moduleId代表编辑
                    updateById({...values}).then(res => {
                        if (res) {
                            message.success('编辑成功');
                            successBack();
                        }
                    })
                } else {//没有moduleId代表添加
                    save({ ...values}).then(res => {
                        if (res) {
                            message.success('添加成功');
                            successBack();
                        }
                    })
                }



            }
        })
    }
    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
        this.setState({
            moduleId: "",
            moduleListData: {}
        })
    }
    render() {
        const {saveLoading,moduleListData} = this.state;
        const {visible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 14}
        };
        const {getFieldDecorator,getFieldValue} = this.props.form;
        return (
            <Modal
                title={this.state.moduleListData.moduleId  ? "编辑板块" : "新增板块" }
                wrapClassName='training_modal_box'
                visible={visible}
                closable={false}
                destroyOnClose={true}
                width="660px"
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.checkCancel()}>取消</Button>
                        <Button loading={saveLoading} onClick={() => this.equipmentSave()} type='primary'>保存</Button>
                    </div>
                }
            >
                <Form {...formItemLayout}>
                    <Form.Item label='板块名称'>
                        {
                            getFieldDecorator('moduleName', {
                                initialValue: moduleListData.moduleName,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入板块名称'
                                    },
                                    {
                                        max: 15,
                                        message: "文本过长，最多15字"
                                    },
                                ]
                            })(<Input placeholder='请输入板块名称' className="sold-box"
                                      suffix={<span className="suffixStyle">{getFieldValue('moduleName') ? getFieldValue('moduleName').length : "0"}/15</span>} />)
                        }
                    </Form.Item>
                    <Form.Item label='板块类别'>
                        {
                            getFieldDecorator('moduleType', {
                                initialValue: moduleListData.moduleType,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入板块类别'
                                    },
                                ]
                            })(
                                <Select placeholder="请选择板块类别"   onChange={(value) => this.selectModelChange(value)} getPopupContainer={triggerNode => triggerNode.parentNode}
                               className="m18"
                                >
                                    <Option value={1}>课程</Option>
                                    <Option value={2}>资讯</Option>
                                    <Option value={3}>读书</Option>
                                </Select>)
                        }
                    </Form.Item>
                    <Form.Item label='板块说明'>
                        {
                            getFieldDecorator('moduleExplain', {
                                initialValue: moduleListData.moduleExplain,
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入板块说明",
                                    },
                                    {
                                        max: 100,
                                        message: "文本过长，最多100字"
                                    },
                                    {
                                        min: 2,
                                        message: "文本过短，最少2字"
                                    }
                                ]
                            })(
                                <TextArea   autoSize={{minRows: 3, maxRows: 5}} placeholder="请输入备注" className="ml22"/>
                            )
                        }
                    </Form.Item >
                    <Form.Item label='展现状态'>
                        {  //是否隐藏 0否 1是
                            getFieldDecorator('isHide', {
                                initialValue: moduleListData.isHide,
                                rules: [
                                    {
                                        required: true,
                                        message: "请选择展现状态",
                                    },
                                ]
                            })(
                                <Radio.Group onChange={this.onChange}>
                                    <Radio value={1}>显示</Radio>
                                    <Radio value={0}>隐藏</Radio>
                                </Radio.Group>
                                      )
                        }
                    </Form.Item>
                    <Form.Item label='id' style={{display:'none'}}>
                        {
                            getFieldDecorator('moduleId', {
                                initialValue: moduleListData.moduleId,
                            })(
                                <></>
                            )
                        }
                    </Form.Item>
                    <Form.Item label='id' style={{display:'none'}}>
                        {
                            getFieldDecorator('moduleSort', {
                                initialValue: moduleListData.moduleSort,
                            })(
                                <></>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

addModel = Form.create()(addModel)
export default addModel;
