import React,{ useState , useEffect } from 'react'
import {Button} from 'antd';
import S from 'gongyongicon';
import {queryMajorList} from '../../api/components';
import './index.scss'

const MajorTypeSelect = (props,ref) => {
    const {value,onChange,isEdit,majorVosSave} = props;
    const [datas,setData] = useState([]);// 数据处理
    const [checkData,setCheckData] = useState([]);//选中的数据
    const [cloneCheckData,setCloneCheckData] = useState([]);//新选中的数据
    const [visible,setVisible] = useState(false);//弹框显示

    useEffect(()=>{
        const fn = async () => {
            const res = await queryMajorList();
            setData(res);
        };
        fn();
    },[]);

    useEffect(()=>{
        if(Array.isArray(value)){
            setCheckData(value);
            setCloneCheckData(value);
        }
    },[value]);

    const onChecked = (id,falg) => {
        if(cloneCheckData.length === 5 && !falg) return;
        const arr = cloneCheckData.filter(it=>(it !== id));
        if(arr.length === cloneCheckData.length && !falg){
            arr.push(id);
            setCloneCheckData(arr);
            return;
        };
        if(arr.length < cloneCheckData.length && falg){
            setCloneCheckData(arr);
        }
    };

    return (
        <div className="major_type" ref={ref}>
            {isEdit && <span className="major_type_edit"><span onClick={()=>setVisible(true)}><S.Edit_icon/>编辑</span></span>}
            <div className="major_type_list">
                {
                    Array.isArray(datas) && datas.map(it=>{
                        if(Array.isArray(checkData) && checkData.includes(it.id)){
                            return <span key={it.id} className="list_item">{it.labelName}</span>
                        };
                        return null;
                    })
                }
            </div>
            {
                visible && <div className="major_type_modal">
                    <div className="modal_box">
                        <div className="modal_box_header">
                            <div className="header_left">添加标签</div>
                            <div className="header_right"></div>
                        </div>
                        <div className="modal_box_content">
                            <div className="content_left">
                                <div className="left_list">
                                    {
                                        Array.isArray(datas) && datas.map(it=>{
                                            return <span 
                                                key={it.id} 
                                                className={`left_list_item ${(Array.isArray(cloneCheckData) && cloneCheckData.includes(it.id)) ? 'active' : ''}`}
                                                onClick={()=>onChecked(it.id,false)}
                                            >{it.labelName}</span>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="content_right">
                                <div className="right_title">
                                    已选<span>{cloneCheckData.length}</span>个标签，还可以添加<span>{5-cloneCheckData.length}</span>个
                                </div>
                                <div className="right_list">
                                    {
                                        Array.isArray(datas) && datas.map(it=>{
                                            if(Array.isArray(cloneCheckData) && cloneCheckData.includes(it.id)){
                                                return <span key={it.id} className="right_list_item">
                                                    <p>{it.labelName}</p>
                                                    <S.Yuanclose_icon onClick={()=>onChecked(it.id,true)}/>
                                                </span>
                                            };
                                            return null;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal_box_footer">
                            <Button 
                                size="large" 
                                onClick={()=>{
                                    setCloneCheckData(checkData);
                                    setVisible(false);
                                }}
                            >
                                取消
                            </Button>
                            <Button 
                                size="large" 
                                type="primary"
                                onClick={async ()=>{
                                    if(typeof majorVosSave === 'function'){
                                        try {
                                            await majorVosSave(cloneCheckData);
                                            setCheckData(cloneCheckData);
                                            onChange(cloneCheckData);
                                            setVisible(false);
                                        } catch (error) {
                                            console.log(error);
                                        }
                                        return;
                                    }
                                    setCheckData(cloneCheckData);
                                    onChange(cloneCheckData);
                                    setVisible(false);
                                }}
                            >
                                确定
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default React.forwardRef(MajorTypeSelect);