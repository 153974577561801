import React ,{useState}from 'react'
import {Modal,Collapse,Tooltip,Button} from 'antd'
import { EditNoActive, YuanAdd, Yichang } from "../../../components/shixunsvg1.20/index";

const { Panel } = Collapse;
export default function LookDIr(props) {

  const customPanelStyle = { background: "#fff", border: 0, overflow: "hidden" }
  const [selectone, setselectone] = useState(0);//YI
  const [selecttwo, setselecttwo] = useState(0);//YI
  const [selectthree, setselectthree] = useState(0);//YI
  return (
    <div>
      <Modal
        visible={props.visible}
        className="detetlxi"
        closable={false}
      
        title="章节目录"
        footer={[


          <Button key="submit" type="primary" onClick={props.onOk} style={{ width: 90, height: 38 }}>
              我知道了
          </Button>,]}
        >
      <Collapse expandIconPosition="left" defaultActiveKey={["1"]}>
                {Array.isArray(props.data) && props.data.map((v, u) => (
                  <Panel
                    key={v.resourceChapterId}
                    style={customPanelStyle}
                    header={
                      <div
                        

                        style={{ display: "flex"}}

                      >
                      
                        {/* <EditNoActive /> */}
                        &emsp;<div>
                          {v.resourceChapterName && v.resourceChapterName.length > 7 ? v.resourceChapterName.substring(0, 7) + '...' : v.resourceChapterName}
                        </div>
                      </div>
                    }

                  >
                    {Array.isArray(v.children) && v.children.map((children, i) => {
                      return <Collapse expandIconPosition="left" key={children.resourceChapterId + i}>
                        <Panel style={customPanelStyle} header={
                        <div 
                          style={{ display: "flex",marginLeft:10 }}
                        >

                      {children.resourceChapterName && children.resourceChapterName.length > 6 ? children.resourceChapterName.substring(0, 6) + '...' : children.resourceChapterName}
                         
                        </div>} key="1">

                          <div
                           
                            className={`ove item_overtext item_overtextActive
                      `}
                          >
                            {Array.isArray(children.children) && children.children.map((__chi,ip) => {
                              return <Tooltip placement="bottomRight" title={__chi.resourceChapterName}
                                key={__chi.resourceChapterId + __chi.resourceChapterName}
                              >

                                <ul style={{
                                  lineHeight: '23px',
                                  cursor: 'pointer',
                                  marginLeft:36
                                }}
                                >
                                 <li>{__chi.resourceChapterName}</li> 
                                </ul>   </Tooltip>
                            })}

                          </div>
                        </Panel>
                      </Collapse>
                    })}


                  </Panel>
                ))}
              </Collapse>
          
      </Modal>
    </div>
  )
}
