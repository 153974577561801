import React from 'react';
import { Form,Input,Button} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

class Shareit extends React.Component {
    state = {
        loading: false,
        fileList: [],
        acceptType: ".jpeg,.png,.gif,.jpg",
        imageUrl: '',
        isJump: '',
        jumpType: '',
        jumpUrl: '',
        bannerImg: '',
        title: '',
        id: '',
        previewVisible:false,
        titleLength: ''
    };

    componentWillMount() {

    }

    // 输入框剩余字数联动
    titleLength = (e) =>{
        this.setState({titleLength:e.target.value.length})
    }
    onChangeUrl =  (rule, value, callback) => {
        let pattern =  /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
        if (value && !pattern.test(value)){
            callback('请输入正确链接地址')
        } else {
            callback();
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { title} = this.state;
        return (
            <Form className="form-imga">
                <Form.Item label="分享链接" className="Item-li">
                    {
                        getFieldDecorator('title', {
                            initialValue: title,
                            rules: [
                                {
                                    required: true,
                                    message: "请输入链接地址",
                                },
                                {
                                    validator:this.onChangeUrl
                                }
                            ]
                        })(
                            <Input placeholder='请输入分享链接' className="input" />
                        )
                    }
                    <Button>复制链接</Button>
                </Form.Item>
                <div className="Item-li">
                    <span className="ant-col ant-form-item-label">生成二维码</span>
                    <span className="img" style={{marginRight:"10px",marginLeft:"10px;"}}>
                        <img src={require("../../assets/img/images/icon-code.png")} style={{width:"100px"}} alt="" />
                    </span>
                </div>
            </Form>
        );
    }
}

Shareit = Form.create()(Shareit);
export default Shareit;