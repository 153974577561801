/*任务管理*/
import React, { Component } from "react";
import {
    Row,
    Input,
    Table,

    Col,

    DatePicker, Modal, Button, Spin, Select,
} from "antd";
import "./index.scss";
import {Link} from "react-router-dom";
import {getHandledTaskList} from '@/api/lives/live'
import { withRouter } from "react-router";
// import {Link} from "react-router-dom";
import moment from "moment";
import Processing from '@/components/processing';
import Banned from '@/components/banned';
import { baseURL } from '@/config/index.js';
import RenderButton from "@/components/renderButton";
import OBreadcrumb from '@/components/Breadcrumb'
import routerList from '@/router/router_config.js';
import {connect} from "react-redux";
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    console.log(menuList)
    return {
        menuList
    }
}
@withRouter
class Processedlive extends Component {
    state = {
        userId: "",
        startTime: "",
        endTime: "",
        objectName: "",
        taskId: "",
        isReport: 0,
        visible: false,
        confirmLoading: false,
        total: 0,
        pageNum: 1,
        current:1,
        pageSize: 12,
        homeworkNum: 0,
        coverFlag:false,
        coverFlaga:false,
        tableLoading:true,
        loading:false,
        list : [],
        reportType: '',
        resultType: '',
        liveLecturer:"",
        searchValue:"",
        columns: [
            {
                title: '直播名称',
                width: 160,
                dataIndex: 'taskName',
                key: 'taskName',
                // fixed: 'left',
            },
            {
                title: '所在学校',
                width: 160,
                dataIndex: 'schoolName',
                key: 'schoolName',
                // fixed: 'left',
            },
            {
                title: '讲解教师',
                dataIndex: 'liveLecturer',
                key: 'liveLecturer',
                width: 150,
            },
            {
                title: '举报次数',
                dataIndex: 'reportNum',
                key: 'reportNum',
                width: 100,
                render: (text, record) => {
                    if (record.reportNum === null || record.reportNum === '~') {
                        return (
                            <span>--</span>
                        )
                    }else{
                        return (
                            record.reportNum
                        )
                    }
                }
            },
            {
                title: '举报类型',
                dataIndex: 'reportType',
                key: 'reportType',
                width: 150,
                render: (text, record) => {
                    if (record.reportType === null || record.reportType === '~') {
                        return (
                            <span>--</span>
                        )
                    }else{
                        return (
                            record.reportType
                        )
                    }
                }
            },
            {
                title: '最后处理时间',
                dataIndex: 'lastHandledTime',
                key: 'lastHandledTime',
                width: 150,
            },
            {
                title: '最后处理结果',
                dataIndex: 'lastHandledResult',
                key: 'lastHandledResult',
                width: 150,
                render: (text, record) => {
                    if (text === "1") {
                        return (
                            <span className="span-a">禁止开播</span>
                        )
                    } else if (text === "4") {
                        return (
                            <span className="span-a">强制结束</span>
                        )
                    } else if (text === "5") {
                        return (
                            <span className="span-a">冻结直播</span>
                        )
                    }
                }
            },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 270,
                render: (text, record) => {
                    if (record.lastHandledResult === "5") {
                        return (
                            <div className="cont">
                                <RenderButton id="LS_02_G03_b_btnExportChatRecord">
                                    <span className="but" onClick={() => this.exportChatMsg(record)}>导出聊天</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G03_b_btnShowZbDetails">
                                    <span className="but" onClick={() => this.getDetail(record)}>查看详情</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G03_b_btnViewRecords">
                                    <span className="but" onClick={() => this.getRecord(record)}>查看记录</span>
                                </RenderButton>
                            </div>
                        )
                    } else {
                        return (
                            <div className="cont">
                                <RenderButton id="LS_02_G03_c_btnFrozenZB">
                                    <span className="but" onClick={() => this.frozen(record)}>冻结直播</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G03_b_btnExportChatRecord">
                                    <span className="but" onClick={() => this.exportChatMsg(record)}>导出聊天</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G03_b_btnShowZbDetails">
                                    <span className="but" onClick={() => this.getDetail(record)}>查看详情</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G03_b_btnViewRecords">
                                    <span className="but" onClick={() => this.getRecord(record)}>查看记录</span>
                                </RenderButton>
                            </div>
                        )
                    }
                },
            },
        ],
    };
    componentDidMount = async () => {
        this.onLoad()
    };
    onLoad = () =>{
        this.setState({
            loading : true
        })
        let param = {
            liveLecturer:this.state.liveLecturer,
            objectName:this.state.objectName,
            startTime:this.state.startTime,
            endTime:this.state.endTime,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            // taskName:this.state.objectName,
            reportType: this.state.reportType,
            resultType: this.state.resultType
        };
        getHandledTaskList(param).then(res => {
            if (res){
                this.setState({
                    list:res.data,
                    total:res.total,
                    tableLoading:false,
                    loading:false
                })
            }
        })
    }
    onChange = (dates, dateStrings) => {
        console.log("dates1",dates)
        console.log("dates2",dateStrings)
        this.setState({
            startTime:dateStrings[0],
            endTime:dateStrings[1]
        })
    }
    pageChange = (page, pageSize) => {
        this.setState({
                pageNum: page,
                current:page
            },() => {
                this.onLoad();
            }
        );
    };
    onShowSizeChange = (current, size) => {
        this.setState({
                pageNum: 1,
                pageSize: size
            },() => {
                this.onLoad();
            }
        );
    };
    cancel = e => {
        this.setState({ coverFlag:false,coverFlaga:false });
    }
    searchTask = (e) =>{
        console.log("searchTask", e)
        this.setState({
                pageNum: 1,
                objectName: e.target.value,
            }
        );
    }
    frozen = (record) => {
        if(record.isReport === 0){
            this.setState({
                coverFlaga: true,
                taskId: record.taskId,
                isReport : 0
            })
        }else {
            this.setState({
                coverFlaga: true,
                taskId: record.taskId,
                isReport : 1
            })
        }
    }
    exportChatMsg = (record) =>{
        // if (record.lastHandledResult === "0") {
        //     message.warning("本次直播内容违规，已被管理员禁止开播，无法导出数据")
        //     return
        // }
        // if (record.liveStatus === 1 || record.liveStatus === 2) {
        //     message.warning("直播任务未结束，无法导出数据")
        //     return
        // }
        let taskId = record.taskId;
        let chatType = 0;
        window.location.href = `${baseURL}/api/zhibo/api/v1/live/chat/exportChatMessage?taskId=${taskId}&chatType=${chatType}`;
        // window.location.href = 'http://192.168.1.117:18600/api/interaction/api/v1/live/chat/exportChatMessage?taskId=' + taskId + '&chatType=' + chatType;
    }
    getDetail = (record) =>{
        //beforePage 0:直播任务列表 1:直播举报管理 2：举报处理记录
        console.log("record::",record)
        this.props.history.push(`/livecontent?${encodeURI(JSON.stringify({taskId: record.taskId, isReport:record.isReport,beforePage:2}))}`);
    }
    getRecord = (record) =>{
        this.setState({
            coverFlag : true,
            taskId : record.taskId
        })
    }

    typeChange =(e)=>{
        this.setState({
            reportType:e
        })
    }

    resultChange =(e)=>{
        this.setState({
            resultType:e
        })
    }
    //教师查询
    tearchChange=(e)=>{
        this.setState({liveLecturer:e.target.value})
    }
    //查询
    searchButton=()=>{
        this.setState({
            current:1,
            pageNum:1
        },()=>{
            this.onLoad()
        })
    }
    //查询院校
    searchChange = (e) => {
        console.log(e.target.value);
        this.setState({
            searchValue:e.target.value
        })
    };
    //重置
    resetClick=()=>{
        this.setState({
            startTime:"",
            endTime:"",
            resultType:"",
            searchValue:"",
            objectName:"",
            reportType:"",
            liveLecturer:"",
            current:1,
            pageNum:1
        },()=>{
            this.onLoad()
        })
    }
    render() {
        const {coverFlag,coverFlaga,tableLoading,loading} = this.state;
        let path = this.props.location.pathname;
        let { menuList } = this.props;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        let two, three;
        menuList.forEach(v => {
            v.children && v.children.forEach(k => {
                k.children.forEach(i=>{
                    if(i.menuUrl===path){
                        two = k;
                        three = i;
                    }
                })
            })
        })
        let arr = [{ ...one, title: one.name }, two, three];
        const dateFormat = 'YYYY-MM-DD';
        return (
            <div className="Reportlive-content">
                {/*顶部star*/}
                <div className="o-breadcrumb">
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    {/*<div className="at-present-router">*/}
                    {/*    <div className="router-title">*/}
                    {/*        <div className="line"></div>*/}
                    {/*        <div className="router-text">举报处理记录</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*顶部end*/}
                <div className="wrap-center">
                    {/*<div className="top-title">*/}
                    {/*    <span>当前有<i>20</i>条举报记录请注意查收</span>*/}
                    {/*    <Link to="/main/sanshi/reportlive">查看详情</Link>*/}
                    {/*</div>*/}
                    <div className="student-mylive-pic">
                        <div className="teching-mylive-main">
                            {/*<Row className="paper-common">*/}
                            {/*    <Col className="paper-year">*/}
                            {/*        直播状态*/}
                            {/*    </Col>*/}
                            {/*    <Col span={16}>*/}
                            {/*        <Select defaultValue="a" onChange={handleChange}>*/}
                            {/*            <Option value="a">全部状态</Option>*/}
                            {/*            <Option value="b">未开始</Option>*/}
                            {/*            <Option value="c">直播中</Option>*/}
                            {/*            <Option value="d">已结束</Option>*/}
                            {/*        </Select>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row className="paper-common min-box">
                                <Col span={24}>
                                    <RangePicker
                                        value={this.state.startTime?[moment(this.state.startTime, dateFormat), moment(this.state.endTime, dateFormat)]:null}
                                        format={dateFormat}
                                        ranges={{
                                            今天: [moment(), moment()],
                                            '本周': [moment().startOf('Week'), moment().endOf('Week')],
                                            '本月': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        onChange={this.onChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="paper-common min-box-1">
                                <Col span={24}>
                                    <Select value={this.state.reportType?this.state.reportType:undefined}
                                            onChange={(value)=>this.typeChange(value)}
                                            placeholder={"请选择举报类型"}
                                    >
                                        {/*<Option value="">全部类型</Option>*/}
                                        <Option value="0">色情 </Option>
                                        <Option value="1">骚扰</Option>
                                        <Option value="2">反动言论</Option>
                                        <Option value="3">暴力</Option>
                                        <Option value="4">广告或垃圾信息</Option>
                                        <Option value="5">抄袭或未授权</Option>
                                        <Option value="6">其他</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="paper-common min-box-1">
                                <Col span={24}>
                                    <Select value={this.state.resultType?this.state.resultType:undefined}
                                            onChange={(value)=>this.resultChange(value)}
                                            placeholder={"请选择处理结果"}
                                    >
                                        <Option value="">全部处理结果</Option>
                                        {/*<Option value="0">没有问题 </Option>*/}
                                        <Option value="1">禁止开播</Option>
                                        {/*<Option value="2">暂停直播</Option>*/}
                                        {/*<Option value="3">恢复直播</Option>*/}
                                        <Option value="4">强制结束</Option>
                                        <Option value="5">冻结</Option>
                                    </Select>
                                </Col>
                            </Row>
                            {/*<Row className="paper-common">*/}
                            {/*    <Col span={24} className="live-right">*/}
                            {/*        <Input*/}
                            {/*            value={this.state.searchValue}*/}
                            {/*            placeholder="请输入您要查询的院校"*/}
                            {/*            maxLength={20}*/}
                            {/*            onChange={(e)=>this.searchChange(e)}*/}
                            {/*        />*/}

                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row className="paper-common min-box">
                                <Col span={24} className="live-right">
                                    <Input
                                        value={this.state.objectName}
                                        placeholder="请输入您要查询的直播名称"
                                        maxLength={20}
                                        onChange={(e)=>this.searchTask(e)}
                                    />

                                </Col>
                            </Row>
                            <Row className="paper-common" >
                                <Col span={24} className="live-right min-box">
                                    <Input
                                        value={this.state.liveLecturer}
                                        placeholder="请输入您要查询的直播名称"
                                        maxLength={20}
                                        onChange={(e)=>this.tearchChange(e)}
                                    />

                                </Col>
                            </Row>
                            <div className="search-button">
                                <Button className="reset" onClick={this.resetClick}>重置</Button>
                                <Button type="primary" onClick={this.searchButton}>查询</Button>
                            </div>
                        </div>
                        <div className="teching-mylive-content">
                            <div className="text-left">共查询到{this.state.total}个直播任务</div>
                            <div className="teching-mylive-bottom">
                                <div className="mylive-list">
                                    {
                                        tableLoading ? <Spin className="Spin-content" tip="加载中"/>:(
                                            <Table columns={this.state.columns} dataSource={this.state.list} loading={loading} className={'processedlivetable'}
                                                   pagination={{onChange:this.pageChange,pageSize:this.state.pageSize,total:this.state.total,current:this.state.current}}
                                                   scroll={{x: 1400}} sticky rowKey={row => row.taskId}
                                                   locale={{emptyText:<><div className="Empty-conet">
                                                           <div className="img">
                                                               <img src={window.$$setImgSrc("zhibo_list_Empty.jpg")}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="处理记录" className='Modal-processing' visible={coverFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       centered={true}
                       footer={
                           <div className='pos-btn'>
                               <Button className="but" onClick={this.cancel}>关闭</Button>
                               {/*<Button className="but" type="primary" style={{display:"none"}} onClick={this.sureChangeCover}>提交</Button>*/}
                           </div>
                       }
                >
                    <Processing taskId={this.state.taskId}/>
                </Modal>
                <Modal title="冻结直播" className='Modal-Banned' visible={coverFlaga} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       centered={true}
                       footer={
                           <div className='pos-btn' style={{display:"none"}}>
                               <Button className="but" onClick={this.cancel}>取消</Button>
                               <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                           </div>
                       }
                >
                    <Banned taskId={this.state.taskId} reportType={1} isReport={this.state.isReport} cancel={()=>{this.setState({coverFlaga:false})}} getList={() => this.onLoad()}/>
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Processedlive);