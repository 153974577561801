export const apiPrefix = () => {
    return "/api/rcenter"
    // return ""
}

export const peixunApiPrefix = () => {
    return "/api/peixun"
    // return ""
}
//圈子的请求接口
export const CircleapiPrefix = () => {
    return "/api/circle"

}
//共用服务的请求接口
export const GongyongapiPrefix = () => {
    return "/api/gongyong"
}
//活动大赛
export const hdApiPrefix = () => {
    return "/api/huodongdasai"
}
//智慧实训
export const zhsxPrefix = () => {
    return "/api/training"
}
// Ucenter
export const UcenterapiPrefix = () => {
    return "/api/ucenter"
}
// 实训
export const TrainingapiPrefix = () => {
    return "/api/training"
}
//新闻资讯
export const zixunApiPrefix = () => {
    return "/api/zixun"
}

//宁夏个人中心
export const ningxiaApiPrefix = () => {
    return "/api/gongyong"
}
