// 一个专题
import React, { Component } from 'react'
import './index.scss';
import { Form } from 'antd';
import {getSpecialDetail} from "../../../../api/zixun/index"
import { getSearch } from '@/until/libs';
import moment from 'moment';
import RenderImg from "../../../../components/renderImg";

class SpecialTopic extends Component {

    state = {
        specialDetail : {}
    }

    componentDidMount() {
        let params = getSearch(this.props.location.search);
        let data = {
            id : params.specialId
        }
        getSpecialDetail(data).then(res =>{
            if (res){
                console.log("res", res)
                this.setState({
                    specialDetail : res
                })
            }
        })
    }

    lookNewsDetail = (e) =>{
        this.props.history.push(`/main/group-column/lookNews?${decodeURI(JSON.stringify({ newsId: e }))}`)
    }

    render() {
        return (
            <div className='specialTopic_box'>
                <div className='top_title'>
                    <div className='return_wapper' onClick={() => this.props.history.goBack()}>
                        <img src={require('@/assets/img/zixun/return.png')}/>
                        <span>返回</span>
                    </div>
                    <span>{this.state.specialDetail.specialName}</span>
                </div>
                {
                    this.state.specialDetail.specialColumnList ?(
                        <div className='zt_top'>
                            <div className='zt_top_box'>
                                <div className='zp_top_title'>
                                    {this.state.specialDetail.specialName}
                                </div>
                                <div className='cont_box'>
                                    <div className='img_box'>
                                        {/*<img src={require("@/assets/img/zixun/Rectangle 2285.png")}/>*/}
                                        {
                                            <RenderImg type={this.state.specialDetail.mediaType} size='m' id={this.state.specialDetail.specialImg}/>
                                        }
                                    </div>
                                    <div className='cont_box_wapper'>
                                        <div className='cont_title'><span>内容介绍</span></div>
                                        <div className='cont'>
                                            {this.state.specialDetail.specialDesc}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className='zt_top' style={{minHeight:'800px'}}>
                            <div className='zt_top_box'>
                                <div className='zp_top_title'>
                                    {this.state.specialDetail.specialName}
                                </div>
                                <div className='cont_box'>
                                    <div className='img_box'>
                                        {/*<img src={require("@/assets/img/zixun/Rectangle 2285.png")}/>*/}
                                        {
                                            this.state.specialDetail.specialImg?<img src={window.$$img(this.state.specialDetail.specialImg)}/>:<img src={window.$$img('cvec-front-img/cvec-web-global/global_placeholder_1.png')}/>
                                        }
                                    </div>
                                    <div className='cont_box_wapper'>
                                        <div className='cont_title'><span>摘要</span></div>
                                        <div className='cont'>
                                            {this.state.specialDetail.specialDesc}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    this.state.specialDetail.specialColumnList ?
                        (
                            <div className='back_white_box'>
                                <div className='back_white_center'>
                                    {
                                        this.state.specialDetail.specialColumnList.map(specialColumn => (
                                            <ul className='list_wapper_white'>
                                                <li className='cont_title'><span>{specialColumn.columnName}</span></li>
                                                {
                                                    specialColumn.columnNewsList ?
                                                        (
                                                            specialColumn.columnNewsList.map(columnNews => (
                                                                <li className='item_wapper'>
                                                                    <div>
                                                                        <img className='item_img'
                                                                             src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                                                    </div>
                                                                    <div className='center' onClick={() =>this.lookNewsDetail(columnNews.newsId)}>
                                                                        {columnNews.newsTitle}
                                                                    </div>
                                                                    <div className='time'>
                                                                        [{moment(columnNews.createTime).format('YYYY-MM-DD')}]
                                                                    </div>
                                                                </li>
                                                            ))
                                                        )
                                                        : (
                                                            <div className="Empty-conet">
                                                                <div className="img">
                                                                    <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                                                    <span>暂无数据</span>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            </ul>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="Empty-conet">
                                <div className="img">
                                    <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        )
                }
            </div>
        )
    }
}


SpecialTopic = Form.create()(SpecialTopic);
export default SpecialTopic;
