/*任务管理*/
import React, { Component } from "react";
import {
    Row,
    Input,
    Table,

    Col,

    Select,
    DatePicker, Modal, Button, Spin,
} from "antd";
import "./index.scss";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";
import moment from "moment";
import Processing from '@/components/processing';
import Banned from '@/components/banned';
import { liveUrl, baseURL } from '@/config/index.js';
import {adminJoinLiveRoom, continueLive, getLiveReportPage, getEnterpriseList} from '@/api/lives/live'
import RenderButton from "@/components/renderButton";
import OBreadcrumb from '@/components/Breadcrumb'
import routerList from '@/router/router_config.js';
import {connect} from "react-redux";
const { Search } = Input;
const { RangePicker } = DatePicker;

const { Option } = Select;
const data = [];
const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    console.log(menuList)
    return {
        menuList
    }
}
for (let i = 0; i < 5; i++) {
    data.push({
        taskId:"1",
        key: i,
        name: `机械加工与制造`,
        school: `东北师范大学`,
        number:`8`,
        report:`色情、反动言论`,
        time:`2020-1-2 10:05`,
        teacher:`李明明 ${i}`,
    });
}
// function handleChange(value) {
//     console.log(`selected ${value}`);
// }
@withRouter
class Reportlive extends Component {
    state = {
        liveLecturer:"",
        userId: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "",
        visible: false,
        confirmLoading: false,
        total: 0,
        pageNum:1,
        pageSize: 10,
        current:1,
        homeworkNum: 0,
        startTime:'',
        endTime:'',
        columns : [
            {
                title: '直播名称',
                width: 160,
                dataIndex: 'taskName',
                key: 'taskName',
                // fixed: 'left',
            },
            {
                title: '所在学校',
                width: 160,
                dataIndex: 'schoolName',
                key: 'schoolName',
                // fixed: 'left',
            },
            {
                title: '讲解教师',
                dataIndex: 'liveLecturer',
                key: 'liveLecturer',
                width: 150,
            },
            {
                title: '举报次数',
                dataIndex: 'tipOffNumber',
                key: 'tipOffNumber',
                width: 100,
            },
            {
                title: '举报类型',
                dataIndex: 'tipOffTypeName',
                key: 'tipOffTypeName',
                width: 150,
                // render: (text,record) =>
                // {
                //     if (text === 0){
                //         return (
                //             <span>色情</span>
                //         )
                //     }else  if (text === 1){
                //         return (
                //             <span>骚扰</span>
                //         )
                //     }else  if (text === 2){
                //         return (
                //             <span>反动言论</span>
                //         )
                //     }else  if (text === 3){
                //         return (
                //             <span>暴力</span>
                //         )
                //     }else  if (text === 4){
                //         return (
                //             <span>广告或垃圾信息</span>
                //         )
                //     }else  if (text === 5){
                //         return (
                //             <span>抄袭或未授权</span>
                //         )
                //     } else {
                //         return (
                //           <span>其他</span>
                //         )
                //     }
                // }
            },
            {
                title: '目前直播状态',
                dataIndex: 'taskStatus',
                key: 'taskStatus',
                width: 150,
                render: (text,record) =>
                {
                    if (text === 0){
                        return (
                            <div className="statusTxt"><span className='span-a'></span>未开始</div>
                        )
                    }else if (text === 1){
                        return (
                            <div className="statusTxt"><span></span>直播中</div>
                        )
                    }else  if (text === 2){
                        return (
                            <div className="statusTxt"><span className="span-b"></span>已结束</div>
                        )
                    }
                }

            },
            {
                title: '最后举报时间',
                dataIndex: 'lastTipOffTime',
                key: 'lastTipOffTime',
                width: 150,
            },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 270,
                render: (text,record) =>
                {
                    console.log("record:::",record)
                    if (record.taskStatus === 0) {
                        return (
                            <div className="cont">
                                <RenderButton id="LS_02_G02_b_btnyinShenEnterZBRoom">
                                    <span className="but" onClick={(item) => this.Sliplive(record)}>隐身进入</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnNOBeginZB">
                                    <span className="but" onClick={() => this.Banned(record,2)}>禁止开播</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnFrozenZB">
                                    <span className="but" onClick={() => this.Banned(record, 1)}>冻结直播</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnShowZbDetails">
                                    <span className="but" onClick={()=>this.Checkpage(record,0)}>查看详情</span>
                                </RenderButton>
                                {/*<span className="but">*/}
                                {/*    <Link*/}
                                {/*        target = "_blank"*/}
                                {/*        to={`/livecontent/?${encodeURI(JSON.stringify(*/}

                                {/*            {taskId:record.id,isReport:1}*/}
                                {/*        ))}`}>*/}
                                {/*        查看详情*/}
                                {/*    </Link>*/}
                                {/*</span>*/}
                            </div>
                        )
                    }else if (record.taskStatus === 1){
                        return (
                            <div className="cont">
                                <RenderButton id="LS_02_G02_b_btnyinShenEnterZBRoom">
                                    <span className="but" onClick={(item) => this.Sliplive(record)}>隐身进入</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnSuspendZB">
                                    <span className="but" onClick={() => this.Banned(record,3)}>暂停直播</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnForcedEndZB">
                                    <span className="but" onClick={() => this.Banned(record,4)}>强制结束</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnFrozenZB">
                                    <span className="but" onClick={() => this.Banned(record, 1)}>冻结直播</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnShowZbDetails">
                                    <span className="but" onClick={()=>this.Checkpage(record,1)}>查看详情</span>
                                </RenderButton>
                            </div>
                        )
                    }else if (record.taskStatus === 2){
                        return (
                            <div className="cont">
                                <RenderButton id="LS_02_G02_b_btnFrozenZB">
                                    <span className="but" onClick={() => this.Banned(record, 1)}>冻结直播</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnExportChatRecord">
                                    <span className="but" onClick={() => this.exportChatMsg(record)}>导出聊天</span>
                                </RenderButton>
                                <RenderButton id="LS_02_G02_b_btnShowZbDetails">
                                    <span className="but" onClick={()=>this.Checkpage(record,2)}>查看详情</span>
                                </RenderButton>
                            </div>
                        )
                    }
                },
            },
        ],
        coverFlag:false,
        coverFlaga:false,
        searchValue:'',
        statusCondition:'',
        reportType:'',
        isPause:0,
        tableLoading:true,
        loading : false,
        enterpriseList: [],
    };
    componentDidMount = async () => {
        // getLiveReportPage().then(res => {
        //     if (res){
        //         this.setState({
        //             data:res.data,
        //             tableLoading:false,
        //             total:res.total
        //         },() => {
        //             console.log("data:::",data)
        //         })
        //     }
        // })
        this.getList();
        getEnterpriseList().then(e=>{
            this.setState({enterpriseList:e})
        })
    };

    exportChatMsg = (record) =>{
        // if (record.lastHandledResult === "0") {
        //     message.warning("本次直播内容违规，已被管理员禁止开播，无法导出数据")
        //     return
        // }
        // if (record.liveStatus === 1 || record.liveStatus === 2) {
        //     message.warning("直播任务未结束，无法导出数据")
        //     return
        // }
        let taskId = record.id;
        let chatType = 0;
        window.location.href = `${baseURL}/api/zhibo/api/v1/live/chat/exportChatMessage?taskId=${taskId}&chatType=${chatType}`;
        // window.location.href = 'http://192.168.1.117:18600/api/interaction/api/v1/live/chat/exportChatMessage?taskId=' + taskId + '&chatType=' + chatType;
    }

    resumeLive = () => {
        let param = {
            taskId:this.state.taskId,
            createUserId:this.state.userId
        };
        continueLive(param).then(res => {
            if (res){
                this.setState({
                    isPause:0
                },() => {
                    this.getList();
                })
            }
        })
    };

    Checkpage = (item,status) => {
        //beforePage 0:直播任务列表 2:直播举报管理 1：举报处理记录
        this.props.history.push(`/livecontent?${encodeURI(JSON.stringify({taskId: item.id,isReport:1,beforePage:status}))}`)
    };
    Sliplive = (item) => {
        console.log("item:::",item)
        adminJoinLiveRoom({taskId:item.id,studentId:this.state.userId}).then(res => {
            if (res){
                this.props.history.push(`/livepageroom?${encodeURI(JSON.stringify({taskId: item.id,taskName: item.taskName,isReport:1,beforePage:1}))}`);
                // window.open(liveUrl + `?${encodeURI(JSON.stringify({taskId: item.taskId,taskName: item.taskName,isReport:1}))}`)
            }
        })
    };
    onChange = (dates, dateStrings) => {
        console.log("dates1",dates)
        console.log("dates2",dateStrings)
        this.setState({
            startTime:dateStrings[0],
            endTime:dateStrings[1]
        },()=>{
            this.getList()
        })
    };
    pageChange = (page, pageSize) => {
        console.log(page,pageSize);
        this.setState({
            pageNum:page,
            current:page
        },() => {
            this.getList()
        })
        // this.state.postParam.pageNum = page;
        // sessionStorage.setItem("live-pageNum", page);
        // this.state.postParam.pageSize = pageSize;
        // this.liveList(this.state.postParam);
    };

    onShowSizeChange = (current, size) => {
        this.state.postParam.pageNum = current;
        this.state.postParam.pageSize = size;
        this.liveList(this.state.postParam);
    };
    cancel = e => {
        this.setState({ coverFlag:false,coverFlaga:false });
    };
    Viewrecords = e => {
        this.setState({ coverFlag:true});
    };
    Banned = (record,e) =>{
        if (e == 3){
            this.setState({ coverFlaga:true,reportType:e,taskId:record.id,isPause:1});
        }else{
            this.setState({ coverFlaga:true,reportType:e,taskId:record.id});
        }
    };

    getList = () => {
        this.setState({
            loading : true
        })
        let param = {
            liveLecturer:this.state.liveLecturer,
            reportType: this.state.reportType,
            school: this.state.enterpriseId,
            taskStatus:this.state.statusCondition,
            objectName:this.state.searchValue,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            startTime:this.state.startTime,
            endTime:this.state.endTime
        };
        getLiveReportPage(param).then(res => {
            if (res){
                this.setState({
                    data:res.data,
                    total:res.total,
                    tableLoading:false,
                    loading : false
                },() => {
                    console.log("data:::",data)
                })
            }
        })
    };

    statusChange = (value) => {
        console.log("value：：：",value)
        this.setState({
            statusCondition:value
        })

    };
    searchChange = (e) => {
        console.log(e.target.value);
        this.setState({
            searchValue:e.target.value
        })
    };
    search = () => {
        this.getList()
    };

    returnMessage = () =>{
        const {reportType} = this.state;
        if(reportType===3){
            return "暂停开播"
        }else if(reportType===2){
            return "禁止开播"
        }else if(reportType===1){
            return "冻结直播"
        }else if(reportType===4){
            return "强制结束"
        }
    };

    enterpriseChange =(e)=>{
        this.setState({enterpriseId:e})
    }

    typeChange =(e)=>{
        this.setState({
            reportType:e
        })
    }
    //教师查询
    tearchChange=(e)=>{
        this.setState({liveLecturer:e.target.value})
    }
    //查询
    searchButton=()=>{
        this.setState({
            current:1,
            pageNum:1
        },()=>{
            this.getList()
        })
    }
    //重置
    resetClick=()=>{
        this.setState({
            startTime:"",
            endTime:"",
            statusCondition:"",
            searchValue:"",
            enterpriseId:"",
            reportType:"",
            liveLecturer:"",
            current:1,
            pageNum:1
        },()=>{
            this.getList()
        })
    }
    render() {
        const { pageNum, total,columns,coverFlag,coverFlaga,tableLoading,loading,enterpriseList} = this.state; //pageSize, visible,
        let path = this.props.location.pathname;
        let { menuList } = this.props;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        let two, three;
        menuList.forEach(v => {
            v.children && v.children.forEach(k => {
                k.children.forEach(i=>{
                    if(i.menuUrl===path){
                        two = k;
                        three = i;
                    }
                })
            })
        })
        let arr = [{ ...one, title: one.name }, two, three];
        const dateFormat = 'YYYY-MM-DD';
        return (
            <div className="Reportlive-content">
                {/*顶部star*/}
                <div className="o-breadcrumb">
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    {/*<div className="at-present-router">*/}
                    {/*    <div className="router-title">*/}
                    {/*        <div className="line"></div>*/}
                    {/*        <div className="router-text">直播举报管理</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*顶部end*/}
                <div className="wrap-center">
                    <div className="student-mylive-pic">
                        <div className="teching-mylive-main">
                            <Row className="paper-common min-box">
                                <Col span={24}>
                                    <RangePicker
                                        value={this.state.startTime?[moment(this.state.startTime, dateFormat), moment(this.state.endTime, dateFormat)]:null}
                                        format={dateFormat}
                                        ranges={{
                                            今天: [moment(), moment()],
                                            '本周': [moment().startOf('Week'), moment().endOf('Week')],
                                            '本月': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        onChange={this.onChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="paper-common min-box">
                                <Col span={24}>
                                    <Select placeholder={'请选择所在院校'} value={this.state.enterpriseId?this.state.enterpriseId:undefined} onChange={(e) => this.enterpriseChange(e)}>
                                        {enterpriseList.length ? enterpriseList.map((item, index) => {
                                            return <Option key={item.enterpriseId} value={item.enterpriseId}>{item.enterpriseName}</Option>
                                        }) : ""}
                                    </Select>
                                </Col>
                            </Row>
                            {/*<Row className="paper-common">*/}
                            {/*    <Col span={24}>*/}
                            {/*        <Select value={this.state.statusCondition?this.state.statusCondition:undefined}*/}
                            {/*                onChange={(value) => this.statusChange(value)}*/}
                            {/*                placeholder={"请选择直播状态"}>*/}
                            {/*            <Option value="">全部状态</Option>*/}
                            {/*            <Option value="1">未开始</Option>*/}
                            {/*            <Option value="2">直播中</Option>*/}
                            {/*            <Option value="3">已结束</Option>*/}
                            {/*        </Select>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            <Row className="paper-common min-box">
                                <Col span={24}>
                                    <Select value={this.state.reportType?this.state.reportType:undefined}
                                            onChange={(value)=>this.typeChange(value)}
                                            placeholder={"请选择举报类型"}
                                    >
                                        {/*<Option value="">全部类型</Option>*/}
                                        <Option value="0">色情 </Option>
                                        <Option value="1">骚扰</Option>
                                        <Option value="2">反动言论</Option>
                                        <Option value="3">暴力</Option>
                                        <Option value="4">广告或垃圾信息</Option>
                                        <Option value="5">抄袭或未授权</Option>
                                        <Option value="6">其他</Option>
                                    </Select>
                                </Col>
                            </Row>
                            {/*<Row className="paper-common">*/}
                            {/*    <Col span={24} className="live-right">*/}
                            {/*        <Input*/}
                            {/*            value={this.state.searchValue}*/}
                            {/*            placeholder="请输入您要查询的院校"*/}
                            {/*            maxLength={20}*/}
                            {/*            onChange={(e) => this.searchChange(e)}*/}
                            {/*            onSearch={() => this.search()}*/}
                            {/*        />*/}

                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row className="paper-common">*/}
                            {/*    <Col span={24} className="live-right">*/}
                            {/*        <Input*/}
                            {/*            value={this.state.searchValue}*/}
                            {/*            placeholder="请输入您要查询的直播名称"*/}
                            {/*            maxLength={20}*/}
                            {/*            onChange={(e) => this.searchChange(e)}*/}
                            {/*            onSearch={() => this.search()}*/}
                            {/*        />*/}

                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row className="paper-common">*/}
                            {/*    <Col span={24} className="live-right">*/}
                            {/*        <Input*/}
                            {/*            value={this.state.liveLecturer}*/}
                            {/*            placeholder="请输入您要查询的讲师名称"*/}
                            {/*            maxLength={20}*/}
                            {/*            onChange={(e) => this.tearchChange(e)}*/}
                            {/*        />*/}

                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <div className="search-button">
                                <Button className="reset" onClick={this.resetClick}>重置</Button>
                                <Button type="primary" onClick={this.searchButton}>查询</Button>
                            </div>
                        </div>
                        <div className="teching-mylive-content">
                            <div className="text-left">共查询到{this.state.total}个直播任务</div>
                            <div className="teching-mylive-bottom">
                                <div className="mylive-list">
                                    {
                                        tableLoading ? <Spin className="Spin-content" tip="加载中"/>:(
                                            <Table columns={columns} dataSource={this.state.data} scroll={{ x: 1400}} loading={loading} className={'reportlivetable'}
                                                   pagination={{onChange:this.pageChange,pageSize:this.state.pageSize,total:this.state.total,current:this.state.current}}
                                                   locale={{emptyText:<><div className="Empty-conet">
                                                           <div className="img">
                                                               <img src={window.$$setImgSrc("zhibo_list_Empty.jpg")}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="处理记录" className='Modal-processing' visible={coverFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       centered={true}
                       footer={
                           <div className='pos-btn'>
                               <Button className="but" onClick={this.cancel}>关闭</Button>
                               <Button className="but" type="primary" style={{display:"none"}} onClick={this.sureChangeCover}>提交</Button>
                           </div>
                       }
                >
                    <Processing/>
                </Modal>
                <Modal title={this.returnMessage()} className='Modal-Banned' visible={coverFlaga} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       centered={true}
                       footer={
                           <div className='pos-btn' style={{display:"none"}}>
                               <Button className="but" onClick={this.cancel}>取消11</Button>
                               <Button className="but" type="primary" onClick={this.sureChangeCover}>确定</Button>
                           </div>
                       }
                >
                    <Banned reportType={this.state.reportType} taskId={this.state.taskId} getList={() => this.getList()} isReport={1}
                            cancel={()=>{if (this.state.reportType == 3){
                                this.setState({coverFlaga:false,isPause:0})
                            }else{
                                this.setState({coverFlaga:false})
                            }}}/>
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Reportlive);
