import React from 'react';
import { Empty } from 'antd';
const styles = {
    padding: '50px 0',
    minHeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
}

const TableEmpty = ({ text = '暂无内容', img = 'zhjx-empty.png' }) => {
    return <Empty
        style={styles}
        image={window.$$setImgSrc(img)}
        description={text}
    />
}


export default TableEmpty;