import React, { Component } from 'react';
import { Select, Button, Input, Table,Tabs } from 'antd';
import './index.scss';
import {selectImportRecord} from "../../../../api/platform-school";
const { Option } = Select;
const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

const Demo = () => (
    <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="全部" key="1">
        </TabPane>
        <TabPane tab="未处理" key="2">
        </TabPane>
        <TabPane tab="已处理" key="3">
        </TabPane>
    </Tabs>
);


export default class index extends Component {
    state = {
        dataSource: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        importUserName: '',
        enterpriseId: '',
        importType: '',
        importStatus: ''
    }

    componentDidMount() {
        this.selectImportRecord()
    }

    async selectImportRecord() {
        const param = {
            enterpriseId: this.state.enterpriseId,
            importStatus: this.state.importStatus,
            importType: this.state.importType,
            importUserName: this.state.importUserName,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }
        const res = await selectImportRecord(param);
        this.setState({
            dataSource: res.data,
            total: res.total,
        })
    }

    // 院校学生导入详情
    async toStudentResultDetail(){
        this.props.history.push("/main/platform-business/student-result")
    }

    render() {

        const { dataSource,total } = this.state
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '导入文件名称', dataIndex: 'fileName',key:'fileName', },
            {title: '导入时间', dataIndex: 'createTime',key:'createTime', },
            {title: '导入人', dataIndex: 'importUserName',key:'importUserName', },
            {title: '总条数', dataIndex: 'totalCount',key:'totalCount', },
            {title: '成功条数', dataIndex: 'successCount', key:'successCount',},
            {title: '状态', align: 'center', render: (text, item, index ) =>  <span className='serial'>{item.importStatus === "0" ? '未处理' : item.importStatus === "1" ? '已处理' : ''}</span>,},
            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:'200',
                render:()=><div className="opspan">
                    <div className="main">
                        <span className="but" onClick={() => this.toStudentResultDetail()}>进入详情</span>
                        <span className="but">下载源文件</span>
                    </div>
                </div>},
        ]
        return (
            <div className='managestu_box'>
                <div className="twolist">
                    <div className="toplist">
                        <div className="leftlist">
                            <Demo />
                        </div>
                        <div className="right_list">
                            <span className="number">共{total}条记录</span>
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入导入人姓名' />
                        </div>
                    </div>
                    <div className="tablelist">
                        <Table columns={columns} dataSource={dataSource} />,
                    </div>
                </div>

            </div>
        )
    }
}
