import { post } from '../../until/axios_instans';
// 安装文件管理接口
export function installlist(data) {
    return post(
        window.TrainingapiPrefix + "/v1/cvecsoftwareinfo/list",data
    );
}
// 获取有效时间列表
export function getValidTimeList(data) {
    return post(
        window.TrainingapiPrefix + "/v1/cvecdownloadpage/getValidTimeList",data
    );
}
//保存下载页地址相关信息
export function saveDownloadPage(data) {
    return post(
        window.TrainingapiPrefix + "/v1/cvecdownloadpage/saveDownloadPage",data
    );
}
//获取下载页地址和密码
export function getAddressPassword(data) {
    return post(
        window.TrainingapiPrefix + "/v1/cvecdownloadpage/getAddressPassword",data
    );
}
//获取发送用户列表
export function getUserInfo(data) {
    return post(
        window.TrainingapiPrefix + "/v1/cvecdownloadpage/getUserInfo",data
    );
}

// 资源下发申请查询列表
export function applyPageList(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecresourceapply/pageList", data);
}

// 获取申请状态枚举
export function getResourceApplyStatusList(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecresourceapply/getResourceApplyStatusList", data);
}

// 时间枚举查询接口
export function getRegisterTimes(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecequipmentregister/registerTimes", data);
}

// 提交处理结果
export function processingResults(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecresourceapply/updateById", data);
}

// 资源下发申请清单详情-设备列表
export function getEquipment(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecresourceapply/detail/equipment", data);
}

// 资源下发申请清单详情-详细信息
export function getDetail(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecresourceapply/detail", data);
}

//查询设备注册列表
export function equipmentRegisterList(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecequipmentregister/pageList", data);
}

//查询省列表
export function getProvinceList() {
    return post(window.TrainingapiPrefix + "/v1/cvecregion/province");
}
//查询市列表
export function getCityList(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecregion/city", data);
}
//查询区列表
export function getAreaList(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecregion/area", data);
}
//查询机构列表
export function getOrganizationList(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecequipmentregister/getOrganizationList", data);
}
//设备名称枚举查询
export function getEquipmentList(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecequipmentregister/equipment/name/enums", data);
}
//新设备注册接口
export function equipmentRegisterSave(data) {
    return post(window.TrainingapiPrefix + "/v1/cvecequipmentregister/save", data);
}
