/**
 * 举报管理
 */

//import {  post, get } from "@/until/http";
import { post } from '../../until/axios_instans';
//获取举报人列表
export function tupOff(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOff/tupOff", data);
}
//资源冻结
export function freeze(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOff/freeze", data);
}
//资源举报列表-未处理
export function tupOffWait(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOff/tupOffWait", data);
}

//举报浏览
export function getReportOne(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOff/getReportOne", data);
}
//获取举报人列表
export function getReportersBytype(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOffUser/getReportersByType", data);
}

//获取举报条数
export function getReportNumbers(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOffUser/getReportNumbers", data);
}

//举报类型
export function getTipOffType(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/common/tipOff/getTipOffType", data);
}

//举报处理类型
export function getLastProcessType(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/common/tipOff/getLastProcessType", data);
}

//举报处理(无问题)
export function setProcess(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOff/process", data);
}

//举报原因
export function getRecord(data) {
    return post(window.apiPrefix + "/api/v1/rcenter/manage/tipOff/getRecord", data);
}

