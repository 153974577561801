
import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import RouterView from '../../router/router_view';
import Menu from '@/components/Menu';
import TopTitle from './components/top_title';
import fourRouter from './four_router_config';
import style from './index.module.scss';

const { Sider, Content } = Layout;

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

//根据路径和type处理url参数
React.Component.prototype.getUrlType = (url,type) => {
    const typeArr = url.substr(1).split('&');
    const typeObj = {};
    typeArr.forEach(it=>{
        const urls = it.split('=');
        typeObj[urls[0]] = urls[1];
    })
    return typeObj[type] || '';
};

export default connect(mapStateToProps, mapDispatchToProps)(class PracticalTraining extends React.Component {

    goPath = path => {
        this.props.history.push(`${path}${this.props.location.search}`);
    }

    render() {
        const { location, menuList } = this.props;
        const newMenuList = menuList.filter(it => it.menuUrl === '/main/institutional-settlement')[0]?.children || [];
        const pathText = window.decodeURI(this.getUrlType(location.search,'name'));
        const path = location.pathname;
        let one = {};
        let two = {};
        let three = {};
        let four = {};
        const fourRouterObj = fourRouter[path];
        if (fourRouterObj) {
            one.menuName = fourRouterObj.oneName;
            one.path = fourRouterObj.onePath;
            two.menuName = fourRouterObj.twoName;
            two.path = fourRouterObj.twoPath;
            three.menuName = fourRouterObj.threeName;
            three.path = fourRouterObj.threePath;
            four.menuName = fourRouterObj.fourName;
            four.path = fourRouterObj.fourPath;
        } else {
            newMenuList.forEach(it => {
                if (it.menuUrl) {
                    one = it;
                    return;
                };
                it.children.forEach(its => {
                    if (its.menuUrl === path) {
                        one = it;
                        two = its;
                    }
                })
            });
        };
        // console.log(fourRouterObj,newMenuList,one,two,three);

        return (
            <Layout className={`resource_box ${style['institutional_settlement_box']}`}>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    <Menu {...this.props} menuList={Array.isArray(newMenuList) ? newMenuList : []} />
                </Sider>
                <Layout>
                    <TopTitle
                        span_1="机构入驻管理"
                        span_2={one.menuName}
                        span_3={two.menuName}
                        span_4={!four.menuName ? (pathText || three.menuName) : three.menuName}
                        span_5={four.menuName ? (pathText || four.menuName) : four.menuName}
                        container={pathText || four.menuName || three.menuName || two.menuName || one.menuName}
                        span_2_fun={() => { one.path && this.goPath(one.path) }}
                        span_3_fun={() => { two.path && this.goPath(two.path) }}
                        span_4_fun={() => { three.path && this.goPath(three.path) }}
                        span_5_fun={() => { four.path && this.goPath(four.path) }}
                        span_3_c={(three.menuName || four.menuName) ? '#333' : undefined}
                        span_4_c={four.menuName ? '#333' : undefined}
                        con={null}
                    />
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
})
