import React from 'react';
import { Row, Col, Button, Upload, message } from 'antd';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { uploadFile } from '../../api/ali/index';
// import { getToken } from '@/utils/token';
import axios from 'axios';
import md5 from "js-md5";
import OSS from "ali-oss";
import {FolderEnum} from "../../api/uploadFile";


export default class Batch extends React.Component {
    state = {
        batchTitle: '上传文件',
        del: false,
        file:{}
    }
    componentDidMount() {

    }

    //上传图片
    beforeUpload =async (info) => {
        console.log("info.file",info)
            if (info.type !== 'application/vnd.ms-excel' && info.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                message.destroy();
                message.warning('文件格式有误，请重新上传');
                return;
            }
        this.setState({ loading: true });
        const formDataOne = new FormData()
        formDataOne.append('file', info)
        formDataOne.append('folder', FolderEnum.OBS)
        let result = await uploadFile(formDataOne);
        if (result) {
            let { url, name } = result;
            this.props.pullError({ errorMessage: '', fileName: '' })
            let formData = new FormData();
            formData.append('file', info);
            formData.append("fileId",result.id);
            this.props.getFormData(formData, result.id)
            this.setState({
                file:formData
            })
            this.props.sure(name);
        }
    };
    //批量上传
    customRequest = (option) => {
        if (option.file.type !== 'application/vnd.ms-excel') {
            message.warning('文件格式有误，请重新上传');
            return;
        }
        this.props.pullError({ errorMessage: '', fileName: '' })
        let formData = new FormData();
        // formData.set("uname", "韦灵通");
        // console.log("fo啊手动阀手动阀rmData： ",formData)
        let file = option.file;
        console.log("file: ",file)
        formData.append('file', file);
        console.log("formData1111： ",formData)
        console.log("formData-get-file1111 ： ",formData.get("file"))
        this.props.getFormData(formData)
        this.setState({
            file:formData
        })
        this.props.sure();
    }
    getItemsValue = () => {
        return this.state.file;
    };
    //清除
    clear = (e) => {
        e && e.stopPropagation();
        this.setState({ batchTitle: '上传文件', del: false });
        this.props.reset();
    }
    //下载文件
    dowLoadFile = () => {
        const { template, titleType } = this.props;
        let now = new Date().getTime();
        axios.get(template, {
            headers: {
                'content-type': 'application/json; charset=utf-8',
                // token: getToken(),
                "z-ts": now,
                "z-sign": md5(now + "")
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${titleType}.xls`);
            link.click();
        })
    }
    removeTitle = () => this.setState({ batchTitle: '上传文件', del: false })
    render() {
        const { titleType } = this.props;
        // console.log('titleType 88888888=== > ', titleType)
        // console.log('titleType 88888888=type== > ', typeof titleType)
        const { batchTitle, del } = this.state;
        return (
            <Row className='batch-wrapper'>
                <Col className="batch-wrapper-first">
                    <div>1</div>
                    <ul>
                        <li>下载 <span className='download' style={{ color: '#3E78ED', cursor: 'pointer' }} onClick={this.dowLoadFile}>模板</span>，批量导入{titleType}</li>
                        <li>注：请根据模板指示添加{titleType}信息，将示例删除后并上传</li>
                    </ul>
                </Col>
                <Col className="batch-wrapper-second">
                    <div>2</div>
                    <ul>
                        <li>上传填写好的{titleType}表</li>
                        <li>
                            <Upload
                                // customRequest={this.customRequest}
                                // onChange={this.onChange}
                                beforeUpload={ this.beforeUpload}
                            >
                                <Button>
                                    <UploadOutlined /> {batchTitle}
                                </Button>
                                {/* {del ? <CloseOutlined className='close-icon' onClick={e => this.clear(e)} /> : null} */}
                            </Upload>
                        </li>
                        <li>一次最多上传1000个{titleType}，请分批上传</li>
                    </ul>
                </Col>
            </Row >
        )
    }
}
