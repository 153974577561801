import GongYongSelectIcon from 'gongyongmoduleicon'
import React, { Component } from 'react';
import { Button, Table, Modal, Form, Input, Upload, message, Tabs, Radio, Select, Empty } from 'antd';
import './index.scss';
import { getOssToken } from '@/api/ali/index.js';
import OSS from 'ali-oss';
import { getUseList, addUse, editUse } from '@/api/configuration/index.js';
import { FolderEnum, uploadFile } from '../../../../api/uploadFile';
import { MaxLengthInputFC } from '../../../../components/MaxLengthInput'
import MyImage from '../../../../components/MyImage';
import TableEmpty from '../../../authorityManagement/components/TableEmpty/TableEmpty'

const { TabPane } = Tabs;

/*
* */
class index extends Component {
    state = {
        columns: [
            {
                title: '应用名称',
                dataIndex: 'appName',
                key: 'appName',
                align: 'center'
            },
            {
                title: '应用图标',
                dataIndex: 'terminalLogo',
                key: 'terminalLogo',
                align: 'center',
                width: 100,
                render: ($, item) => {
                    // 暂时未给 suffix
                    return <MyImage id={item.webLogo} suffix={item.webLogoSuffix || ' '} type={'s'}
                                    className={'img_icon'}/>
                }
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                width: 200,
                key: 'updateTime',
                align: 'center'
            },
            {
                title: '版本号',
                dataIndex: 'appVersion',
                key: 'appVersion',
                align: 'center'
            },
            {
                title: '所属业务系统',
                dataIndex: 'enterpriseName',
                key: 'enterpriseName',
                align: 'center'
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 90,
                render: (text, item, index) => {
                    return <span>{item.enterpriseName ? '使用中' : '未使用'}</span>
                }
            },
            {
                title: '操作',
                dataIndex: 'handle',
                key: 'handle',
                align: 'center',
                width: 240,
                render: ($, item, index) => {
                    return (
                        <p className='group_btn'>
                            <span onClick={() => this.setMenu(item)}>查看详情</span>
                            <span onClick={() => this.editUse(item)}>编辑</span>
                            <span onClick={() => this.setMenu(item)}>配置应用能力</span>
                        </p>
                    )
                }
            }
        ],
        selectIconVisible: false,
        dataSource: [],
        total: 0,
        pageSize: 20,
        pageNum: 1,
        loading: false,
        isBlock: false,
        activeKey: '0',
        associatedTerminal: 0,
        id: null,
        iconImageType: ''
    }
    //取菜单管理
    setMenu = item => {
        sessionStorage.setItem('menu_config_type', this.state.associatedTerminal);
        this.props.history.push(`/main/capacity-auth/menu-config/${item.id}`);
    }
    //编辑应用
    editUse = item => {
        this.setState({ isBlock: true, id: item.id }, () => {
            this.setState({ imageUrl: item.webLogo })
            this.props.form.setFieldsValue({
                applicationName: item.appName,
                webLogo: item.webLogo,
                appCode: item.appCode,
                appVersion: item.appVersion,
                associatedTerminal: item.associatedTerminal
            })
        });
    }
    //切换页面
    pageChange = (page, pageSize) => {
        this.setState({ pageNum: page }, () => {
            this.getUseList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    //切换分页返回条数
    onShowSizeChange = (current, size) => {
        this.setState({ pageSize: size }, () => {
            this.getUseList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    //添加应用
    add = () => {
        this.setState({ isBlock: true, id: null, imageUrl: null });
    }
    //取消
    closeModal = () => {
        this.setState({ isBlock: false });
    }
    //保存
    save = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { id } = this.state;
                let successBack = () => {
                    this.getUseList();
                    this.setState({ isBlock: false });
                }
                values.webLogoSuffix = this.state.iconImageType
                if (id) {                          //存在id就是编辑
                    values.id = id;
                    console.log(values)
                    editUse(values).then(res => {
                        if (res) {
                            message.success('编辑成功');
                            successBack();
                        }
                    })
                } else {                           //不存在是添加
                    addUse(values).then(res => {
                        if (res) {
                            message.success('添加成功');
                            successBack();
                        }
                    })
                }
            }
        })
    }
    //列表
    getUseList = () => {
        let { associatedTerminal, pageNum, pageSize } = this.state;
        let data = {
            pageNum,
            pageSize,
        }
        getUseList(data).then(res => {
            if (res) {
                console.log('getUseList res', res)
                this.setState({
                    total: res.total,
                    dataSource: res.data
                })
            }
        })
    }
    //上传图片
    handleUploadIcon = async file => {
        let uploadType = /\.(png|jpg|jpeg)$/i;
        if (!uploadType.test(file.name)) {
            message.warning('图片格式有误，请重新上传');
        } else {

            this.setState({ loading: true });
            const formData = new FormData()
            formData.append('file', file)
            formData.append('folder', FolderEnum.OBS)
            const res = await uploadFile(formData)

            if (res instanceof Object) {
                const { fpName: name, mediaType } = res
                console.log('res', res)

                this.props.form.setFieldsValue({ webLogo: name })
                this.setState({
                    imageUrl: name,
                    loading: false,
                    iconImageType: mediaType
                })
            }
        }
        return Promise.reject()
    };

    //get oss token
    async getOssToken() {
        let res = await getOssToken();
        if (res) {
            let client = new OSS({
                region: res.region,
                accessKeyId: res.accessKeyId,
                accessKeySecret: res.keySecret,
                stsToken: res.token,
                bucket: res.bucket,
                // endpoint:'oss-cn-beijing.aliyuncs.com',
                secure: true
            });
            this.client = client;
        }
    }

    componentDidMount() {
        this.getOssToken();      //获取oss token信息
        this.getUseList();
    }

    //切换web和app
    changeTab = e => {
        this.setState({
            activeKey: e,
            associatedTerminal: e
        }, () => {
            this.getUseList();
        });
    }

    render() {
        let {
            columns,
            dataSource,
            total,
            pageSize,
            loading,
            imageUrl,
            isBlock,
            activeKey,
            id,
            iconImageType,
            selectIconVisible
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const addDiv = <div className='add_image_div'>
            <div style={{ marginBottom: 8 }}>
                <img src={require('@/assets/img/add_icon.png')} alt=''/>
            </div>
            <span style={{ color: '#3E78ED' }}>上传icon</span>
        </div>
        const lodingDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/loading_icon.png')} alt=''/>
            </div>
            <span>请稍后...</span>
        </div>
        return (
            <div className='configuration_box'>
                <div className={'configuration_search'}>
                    <div>
                        <span>应用名称</span>
                        <Input placeholder={'请输入应用名称'}/>
                        <span>所属业务系统</span>
                        <Select defaultValue='lucy'>
                            <Select.Option value='jack'>Jack</Select.Option>
                            <Select.Option value='lucy'>Lucy</Select.Option>
                            <Select.Option value='disabled' disabled>
                                Disabled
                            </Select.Option>
                            <Select.Option value='Yiminghe'>yiminghe</Select.Option>
                        </Select>
                    </div>
                    <div>
                        <Button>重置</Button>
                        <Button type={'primary'}>查询</Button>
                    </div>
                </div>
                <div className='controll'>
                    <Button onClick={this.add}>添加应用</Button>
                    <p>共120个应用</p>
                </div>

                <div className='container ui_table'>
                    <Table
                        rowKey={v => v.id}
                        columns={columns}
                        pagination={{
                            total: total,
                            pageSize: pageSize,
                            onChange: this.pageChange,
                            onShowSizeChange: this.onShowSizeChange,
                            pageSizeOptions: ['20', '40', '60', '80'],
                            showSizeChanger: true
                            // position:'bottomRight'
                        }}
                        locale={{
                            emptyText:<TableEmpty/>
                        }}
                        dataSource={dataSource}
                    />
                </div>
                <Modal
                    title={id ? '编辑应用' : '添加应用'}
                    visible={isBlock}
                    destroyOnClose={true}
                    closable={false}
                    className='configuration_modal'
                    centered={true}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={this.closeModal}>取消</Button>
                            <Button onClick={this.save}>保存</Button>
                        </div>
                    }
                >
                    <Form className={'configuration_modal_form'}>
                        <Form.Item label='应用名称'>
                            {getFieldDecorator('applicationName', {
                                initialValue: '',
                                rules: [{
                                    required: true,
                                    message: '请输入页面名称'
                                }]
                            })(
                                <MaxLengthInputFC
                                    placeholder='请输入页面名称'
                                    maxLength={8}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label='所属业务系统'>
                            {getFieldDecorator('platform', {
                                initialValue: 'lucy',
                                rules: [{
                                    required: true,
                                    message: '请输入页面地址'
                                }]
                            })(
                                <Select style={{ width: 330 }}>
                                    <Select.Option value='jack'>Jack</Select.Option>
                                    <Select.Option value='lucy'>Lucy</Select.Option>
                                    <Select.Option value='Yiminghe'>yiminghe</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item className='upload_box' label='页面icon'>
                            {
                                getFieldDecorator('icon', {
                                    initialValue: '',
                                })(<Upload
                                    accept='.jpg,gif,.png,.jpeg'
                                    listType='picture-card'
                                    showUploadList={false}
                                    fileList={[]}
                                    beforeUpload={this.handleUploadIcon}
                                >
                                    {loading
                                        ? lodingDiv
                                        : imageUrl
                                            ? <div className='replace_img'>
                                                <MyImage type={'s'} id={imageUrl} suffix={iconImageType} alt='avatar'/>
                                                <div className='mask'>
                                                    <div className='add_image_div'>
                                                        <div>
                                                            <img src={require('@/assets/img/replace_icon.png')} alt=''/>
                                                        </div>
                                                        <span style={{ color: '#fff' }}>更新icon</span>
                                                    </div>
                                                </div>
                                            </div>
                                            : addDiv
                                    }
                                </Upload>)
                            }
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                paddingTop: '10px'
                            }}>
                                <p style={{ width: '100%', lineHeight: '20px' }}>支持jpg/gif/png/jpeg格式，</p>
                                <p style={{ width: '100%', lineHeight: '20px' }}>建议最佳尺寸60x60px，不超过5M</p>
                                <span
                                    style={{ textDecorationLine: 'underline', color: '#3E78ED', cursor: 'pointer' }}
                                    onClick={() => this.setState({ selectIconVisible: true })}
                                >
                                    从icon库里选择icon
                                </span>
                            </div>
                        </Form.Item>
                        <Form.Item label='版本号'>
                            {getFieldDecorator('appVersion', {
                                initialValue: '',
                            })(
                                <MaxLengthInputFC
                                    maxLength={10}
                                    placeholder='请输入版本号'
                                />
                            )}
                        </Form.Item>
                        <Form.Item label='应用描述'>
                            {getFieldDecorator('appVersion', {
                                initialValue: '',
                            })(
                                <MaxLengthInputFC
                                    maxLength={500}
                                    type={'textarea'}
                                    placeholder='请输入描述内容'
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
                <GongYongSelectIcon
                    visible={selectIconVisible}
                    onCancel={() => this.setState({ selectIconVisible: false })}
                    onOk={() => this.setState({ selectIconVisible: false })}
                />
            </div>
        )
    }
}

index = Form.create()(index);
export default index;