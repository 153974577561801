import React from 'react';
import {Button, Form, Input, Modal, message, Select} from "antd";
import '../index.scss';
import GenerateAddressModel from "./generate-address";
import {getValidTimeList} from '@/api/practTrain/index.js'

const {Option} = Select;

class SetLimitsModel extends React.Component {
    state = {
        timeList: [], //有效时间列表
        addressVisible: false,
        softwareDownloadUrl: '', //软件下载地址
        limitsVisible: false,
        timeValue: '',
        softwareId: '',  //安装软件id
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.limitsVisible) {
            this.setState({
                limitsVisible: nextProps.limitsVisible,
                softwareId: nextProps.softwareId,
            })
        }
        this.timeList()
    }

    //获取有效时间列表
    async timeList() {
        const timeList = await getValidTimeList({})
        if (timeList) {
            this.setState({
                timeList: timeList,
            })
        }
    }

    //有效时间
    timeChange = (value) => {
        this.setState({
            timeValue: value,
        })
    };
    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.limitsCancel()
    }
    //生成获取地址弹窗
    generateAddress = () => {
        const {timeValue, softwareId} = this.state;
        if (timeValue) {
            this.setState({
                addressVisible: !this.state.addressVisible,
                timeValue: timeValue,
                softwareId: softwareId,
                limitsVisible: false,
            }, () => {
                this.checkCancel()
            })
        } else {
            this.mesWarning("请先选择有效期限")
        }


    }
    generateAddressCancel = () => {
        this.setState({
            addressVisible: false,
        }, () => {
            this.props.limitsCancel()
        })
    }
    generateAddressConfirm = () => {
        this.setState({
            addressVisible: false,
        })
    }

    render() {
        const {saveLoading, timeList, addressVisible, timeValue, softwareId} = this.state;
        const {limitsVisible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 20}
        };
        const {getFieldDecorator, getFieldValue} = this.props.form;
        return (
            <div>
                <Modal
                    width='660px'
                    title="设置使用限制"
                    wrapClassName='practTrain_modal_box set-limits'
                    visible={limitsVisible}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.checkCancel()}>关闭本页</Button>
                            <Button loading={saveLoading} onClick={() => this.generateAddress()}
                                    type='primary'>创建地址链接</Button>
                        </div>
                    }
                >
                    <Form {...formItemLayout}  style={{width:'507px'}}>
                        <div className="hint-box">
                            <img src={window.$$img('cvec-manage-front-yunying/practTrain/hint-icon.png')}
                                 alt="图片" className="hint-img"/>
                            <span> 地址使用限制：为了保障安全，创建的地址链接需要输入密码后才能查看、下载</span>
                        </div>
                        <Form.Item label='有效期限' colon={false}>
                            {
                                getFieldDecorator('dayName', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择有效期限'
                                        },
                                    ]
                                })(
                                    <Select placeholder="请选择有效期限" onChange={(value) => this.timeChange(value)}
                                            getPopupContainer={triggerNode => triggerNode.parentNode}>
                                        {timeList && timeList.length ? timeList.map((item, index) => {
                                            return <Option key={index}
                                                           value={item.dayNum}>{item.dayName}</Option>
                                        }) : ""}
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Modal>
                {/*生成获取地址弹窗 start*/}
                <GenerateAddressModel
                    addressVisible={addressVisible}
                    timeValue={timeValue}
                    softwareId={softwareId}
                    generateAddressCancel={this.generateAddressCancel.bind(this)}
                    generateAddressConfirm={this.generateAddressConfirm.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*生成获取地址弹窗 end*/}
            </div>

        )
    }
}

SetLimitsModel = Form.create()(SetLimitsModel)
export default SetLimitsModel;

