// 应用接入
import React from 'react';
import { Form, Input, Button, Radio, message, DatePicker, Tag, Select, Modal } from 'antd';
import moment from "moment";
import { applist } from '@/api/platform-business/index.js';
import { selectRoleList } from '@/api/role/index.js';
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
};

// 我应用接入定义数据
const { CheckableTag } = Tag;
const tagsData = ['教育资讯', '标签名称', 'Music', 'Sports'];
const { Option } = Select;
class adminForm extends React.Component {
    state = {
        applicationList: [],
        selectedTags: [],
        enterpriseId: '',
        yuanxiaoSel: [], // 配置弹层下拉选
        associated: [],
        appList: [],   //app应用列表
        webList: []    //web应用列表
    };

    async componentWillMount() {
        console.log("formType", this.props.formType)
        this.getApplicationList()
        this.getRoleList()
    }
    //角色下拉列表
    getRoleList = async () => {
        let res = await selectRoleList({ identityId: "71" });
        res && this.setState({
            yuanxiaoSel: res
        });
    }
    //应用列表
    getApplicationList = async () => {
        // let res = await applist({enterpriseId: sessionStorage['enterpriseId'], associatedTerminal: 0});
        // if (res != null && res.length > 0) {
        //     this.setState({
        //         applicationList: res,
        //         selectedTags: res.filter(e => e.flag === true),
        //         associated: res.filter(e => e.flag === true),
        //         enterpriseId: sessionStorage['enterpriseId']
        //     });
        //     this.props.form.setFieldsValue({
        //         accessType: res[0].accessType,
        //         startDate: res[0].startDate ? moment(res[0].startDate, 'YYYY-MM-DD') : null,
        //         endDate: res[0].endDate ? moment(res[0].endDate, 'YYYY-MM-DD') : null,
        //     });
        // }
        let appD = {
            enterpriseId: sessionStorage['enterpriseId'],
            associatedTerminal: 0
        }
        let webD = {
            enterpriseId: sessionStorage['enterpriseId'],
            associatedTerminal: 1
        }
        let res = await Promise.all([applist(appD), applist(webD)]);
        if (res) {
            let concatArr = [...res[0], ...res[1]];
            this.setState({
                webList: res[0],
                appList: res[1],
                selectedTags: concatArr.filter(e => e.flag === true),
                // associated: concatArr.filter(e => e.flag === true),
                enterpriseId: sessionStorage['enterpriseId']
            },()=>{
                this.props.selectedTags(this.state.selectedTags);
            });
        }

    }
    // 应用接入选择--选择
    handleChange(tag, checked) {
        let { selectedTags, associated } = this.state;
        let index = selectedTags.findIndex(v=>v.id===tag.id);
        if(index>-1){
            selectedTags.splice(index,1);
        }else{
            selectedTags.push(tag);
        }
        console.log(selectedTags)
        // let nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        // let nextSelectedTags = [];
        // console.log(checked)
        // if (checked) {
        //     nextSelectedTags = [...selectedTags, tag]
        // } else {
        //     if (!associated.find(e => e.id === tag.id)) {
        //         nextSelectedTags = selectedTags.filter(t => t !== tag)
        //     } else {
        //         nextSelectedTags = selectedTags
        //     }
        // }
        // console.log('You are interested in: ', nextSelectedTags);
        this.setState({ selectedTags });
        this.props.selectedTags(selectedTags);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { formType } = this.props
        const { applicationList, selectedTags, yuanxiaoSel, appList, webList } = this.state;

        return (
            <Form {...formItemLayout} style={{ marginTop: '20px' }}>
                {formType === 'pzgl' && <div>
                    <Form.Item label='账号'>
                        {
                            getFieldDecorator('userName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '账号不能为空'
                                    }
                                ]
                            })(<Input placeholder='请输入账号' />)
                        }
                    </Form.Item>
                    <Form.Item label='角色名称'>
                        {
                            getFieldDecorator('roleId', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '角色名称不能为空'
                                    }
                                ]
                            })(<Select placeholder='请选择角色名称'>
                                {
                                    yuanxiaoSel && yuanxiaoSel.map(v => {
                                        return <Option value={v.roleId} key={v.roleId}>{v.roleName}</Option>
                                    })
                                }
                            </Select>)
                        }
                    </Form.Item>
                    <Form.Item label='选择到期日期'>
                        {
                            getFieldDecorator('endDate ', {
                                defaultPickerValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '到期日期不能为空'
                                    }
                                ]
                            })(<DatePicker placeholder='请选择到期日期' style={{ width: '100%' }} />)
                        }
                    </Form.Item>
                    <Form.Item label='状态'>
                        {
                            getFieldDecorator('managementStatus', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '状态不能为空'
                                    }
                                ]
                            })(<Radio.Group style={{ width: '100%' }}>
                                <Radio value={1}>启用</Radio>
                                <Radio value={2}>不启用</Radio>
                            </Radio.Group>)
                        }
                    </Form.Item>
                </div>}
                {formType === 'yyjr' && <div>
                    <Form.Item>
                        <div className="top_wapper top_wappers">
                            <div className='app_top_title'>请选择接入数据(App)</div>
                            <div className='tabItem'>
                                {appList.map(tag => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={selectedTags.find(e => e.id === tag.id)}
                                        onChange={checked => this.handleChange(tag, checked)}
                                    >
                                        {tag.appName}
                                    </CheckableTag>
                                ))}
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="top_wapper top_wappers">
                            <div className='app_top_title'>请选择接入数据(Web)</div>
                            <div className='tabItem'>
                                {webList.map(tag => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={selectedTags.find(e => e.id === tag.id)}
                                        onChange={checked => this.handleChange(tag, checked)}
                                    >
                                        {tag.appName}
                                    </CheckableTag>
                                ))}
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item label='接入方式'>
                        {
                            getFieldDecorator('accessType', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '接入方式不能为空'
                                    }
                                ]
                            })(<Radio.Group style={{ width: '100%' }}>
                                <Radio value={0}>正式使用</Radio>
                                <Radio value={1}>试用</Radio>
                            </Radio.Group>)
                        }
                    </Form.Item>
                    <Form.Item label='开始日期'>
                        {
                            getFieldDecorator('startDate', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '开始日期不能为空'
                                    }
                                ]
                            })(<DatePicker placeholder='请选择开始日期' style={{ width: '100%' }} />)
                        }
                    </Form.Item>
                    <Form.Item label='结束日期'>
                        {
                            getFieldDecorator('endDate', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '结束日期不能为空'
                                    }
                                ]
                            })(<DatePicker placeholder='请选择开始日期' style={{ width: '100%' }} />)
                        }
                    </Form.Item>
                </div>}
                {formType === 'yqsz' && <div>
                    <Form.Item label='延期方式'>
                        {
                            getFieldDecorator('accessType', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '延期方式不能为空'
                                    }
                                ]
                            })(<Radio.Group style={{ width: '100%' }}>
                                <Radio value={0}>正式</Radio>
                                <Radio value={1}>试用</Radio>
                            </Radio.Group>)
                        }
                    </Form.Item>
                    <Form.Item label='选择延期时间'>
                        {
                            getFieldDecorator('endDate', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '延期时间不能为空'
                                    }
                                ]
                            })(<DatePicker placeholder='请选择延期时间' style={{ width: '100%' }} />)
                        }
                    </Form.Item>
                </div>}
            </Form>

        );
    }
}

adminForm = Form.create()(adminForm);
export default adminForm;
