import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import "./index.scss";

export default class OBreadcrumb extends Component {
    jump = (path) => {
        if (path === this.props.location.pathname) {
            return;
        }
        this.props.history.push(path);
    }
    render() {
        const { list=[] } = this.props;
        console.log("list",list[0].children)

        return (
            <div style={{ padding: '20px 30px 10px 30px',background:"#fff" }}>
                <Breadcrumb separator="/" >
                    {
                        list.length && list.map((v, i) => {
                            if (v) {
                                return <Breadcrumb.Item key={i} className={i==list.length-1 ? 'default' : 'pointer'} onClick={() => this.jump(v.menuUrl)}>
                                    <span className="menuName">{v.menuName}</span>
                                </Breadcrumb.Item>
                            }
                        })
                    }
                </Breadcrumb>
                <div className="bottom">
                    <div className="line"></div>
                   {/* {list[list.length-1]?list[list.length-1].menuName:list[1].menuName}*/}
                    {list[0].children?list[0].children.map((item,index)=> {
                        if(item.path===this.props.location.pathname){
                            return <span>{item.name}</span>
                        }

                    } ):''}
                </div>
            </div>
        )
    }
}