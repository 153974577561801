import React, { Component } from 'react'

export default class Hardware_class extends Component {
    render() {
        return (
            <div>
                Hardware_classHardware_classHardware_classHardware_classHardware_class
            </div>
        )
    }
}
