
import reducers from './reducers'
let defaultState = {
    options: {
        temporaryProject: {
            btnArr: ['设置热门方向'],
            btnFn: ['AAA']
        },
        grounding: {
            btnArr: ['向上共享','下架资源'],
            btnFn: ['BBB']
        },
        undercarriage:{
            btnArr: ['上架'],
            btnFn: ['CCC']
        },
        recommend:{
            btnArr: ['推荐'],
            btnFn: ['CCC']
        },
        check:{
            btnArr: [],
            btnFn: ['DDD']
        },
    }
}
const homeReducer = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default homeReducer