export default {
    '/main/institutional-settlement/manage_mechanism_add':{
        'oneName':'机构管理',
        'twoName':'管理机构管理',
        'threeName':'管理机构信息录入',
        'twoPath':'/main/institutional-settlement/manage_mechanism_manage',
        'threePath':'/main/institutional-settlement/manage_mechanism_add',
    },
    '/main/institutional-settlement/manage_mechanism_updata':{
        'oneName':'机构管理',
        'twoName':'管理机构管理',
        'threeName':'管理机构信息修改',
        'twoPath':'/main/institutional-settlement/manage_mechanism_manage',
        'threePath':'/main/institutional-settlement/manage_mechanism_updata',
    },
    '/main/institutional-settlement/manage_mechanism_info':{
        'oneName':'机构管理',
        'twoName':'管理机构管理',
        'threeName':'管理机构信息详情',
        'twoPath':'/main/institutional-settlement/manage_mechanism_manage',
        'threePath':'/main/institutional-settlement/manage_mechanism_info',
    },
    "/main/institutional-settlement/college_information_entry": {
        'oneName':'机构管理',
        'twoName':'学校入驻管理',
        'threeName':'院校信息录入',
        'twoPath':'/main/institutional-settlement/school-settlement-manage',
    },
    "/main/institutional-settlement/school_settlement_detail": {
        'oneName':'机构管理',
        'twoName':'学校入驻管理',
        'threeName':'学校详情',
        'twoPath':'/main/institutional-settlement/school-settlement-manage',
    },
    "/main/institutional-settlement/create_virtual_organization": {
        'oneName':'机构管理',
        'twoName':'虚拟机构管理',
        'threeName':'创建虚拟机构',
        'twoPath':'/main/institutional-settlement/virtual_organization_manage',
    },
    "/main/institutional-settlement/updata_virtual_organization": {
        'oneName':'机构管理',
        'twoName':'虚拟机构管理',
        'threeName':'修改虚拟机构',
        'twoPath':'/main/institutional-settlement/virtual_organization_manage',
    },
    "/main/institutional-settlement/virtual_organization_detail": {
        'oneName':'机构管理',
        'twoName':'虚拟机构管理',
        'threeName':'虚拟机构详情',
        'twoPath':'/main/institutional-settlement/virtual_organization_manage',
    },
    "/main/institutional-settlement/member_list":{
        'oneName':'机构成员管理',
        'twoName':'管理机构成员管理',
        'threeName':'成员列表',
        'twoPath':'/main/institutional-settlement/organization_member_management',
    },
    '/main/institutional-settlement/member_list_teacher_add':{
        'oneName':'机构成员管理',
        'twoName':'管理机构成员管理',
        'threeName':'成员列表',
        'fourName':'新增成员',
        'twoPath':'/main/institutional-settlement/organization_member_management',
        'threePath':'/main/institutional-settlement/member_list',
        'fourPath':'/main/institutional-settlement/member_list_teacher_add',
    },
    '/main/institutional-settlement/member_list_teacher_updata':{
        'oneName':'机构成员管理',
        'twoName':'管理机构成员管理',
        'threeName':'成员列表',
        'fourName':'修改成员',
        'twoPath':'/main/institutional-settlement/organization_member_management',
        'threePath':'/main/institutional-settlement/member_list',
        'fourPath':'/main/institutional-settlement/member_list_teacher_updata',
    },
    "/main/institutional-settlement/teacher_management":{
        'oneName':'机构成员管理',
        'twoName':'虚拟机构成员管理',
        'threeName':'教师管理',
        'twoPath':'/main/institutional-settlement/virtual_organization_members',
    },
    '/main/institutional-settlement/virtual_teacher_add':{
        'oneName':'机构成员管理',
        'twoName':'虚拟机构成员管理',
        'threeName':'教师管理',
        'fourName':'新增教师',
        'twoPath':'/main/institutional-settlement/virtual_organization_members',
        'threePath':'/main/institutional-settlement/teacher_management',
        'fourPath':'/main/institutional-settlement/virtual_teacher_add',
    },
    '/main/institutional-settlement/virtual_teacher_updata':{
        'oneName':'机构成员管理',
        'twoName':'虚拟机构成员管理',
        'threeName':'教师管理',
        'fourName':'修改教师',
        'twoPath':'/main/institutional-settlement/virtual_organization_members',
        'threePath':'/main/institutional-settlement/teacher_management',
        'fourPath':'/main/institutional-settlement/virtual_teacher_updata',
    },
    "/main/institutional-settlement/student_management":{
        'oneName':'机构成员管理',
        'twoName':'虚拟机构成员管理',
        'threeName':'学生管理',
        'twoPath':'/main/institutional-settlement/virtual_organization_members',
    },
    "/main/institutional-settlement/member_list_teacher_management":{
        'oneName':'机构成员管理',
        'twoName':'学校成员管理',
        'threeName':'教师信息管理',
        'twoPath':'/main/institutional-settlement/school-member-manage',
    },
    "/main/institutional-settlement/member_list_student_management":{
        'oneName':'机构成员管理',
        'twoName':'学校成员管理',
        'threeName':'学生信息管理',
        'twoPath':'/main/institutional-settlement/school-member-manage',
    },
    '/main/institutional-settlement/school_teacher_add':{
        'oneName':'机构成员管理',
        'twoName':'学校成员管理',
        'threeName':'教师信息管理',
        'fourName':'新增教师',
        'twoPath':'/main/institutional-settlement/school-member-manage',
        'threePath':'/main/institutional-settlement/member_list_teacher_management',
        'fourPath':'/main/institutional-settlement/school_teacher_add',
    },
    '/main/institutional-settlement/school_teacher_updata': {
        'oneName':'机构成员管理',
        'twoName':'学校成员管理',
        'threeName':'教师信息管理',
        'fourName':'修改教师',
        'twoPath':'/main/institutional-settlement/school-member-manage',
        'threePath':'/main/institutional-settlement/member_list_teacher_management',
        'fourPath':'/main/institutional-settlement/school_teacher_add',
    },
    '/main/institutional-settlement/manage_teacher_add':{
        'oneName':'机构成员管理',
        'twoName':'管理机构成员管理',
        'threeName':'成员列表',
        'fourName':'新增教师',
        'twoPath':'/main/institutional-settlement/organization_member_management',
        'threePath':'/main/institutional-settlement/member_list',
        'fourPath':'/main/institutional-settlement/manage_teacher_add',
    },
    '/main/institutional-settlement/school_student_add':{
        'oneName':'机构成员管理',
        'twoName':'学校成员管理',
        'threeName':'学生信息管理',
        'fourName':'新增学生',
        'twoPath':'/main/institutional-settlement/school-member-manage',
        'threePath':'/main/institutional-settlement/member_list_student_management',
        'fourPath':'/main/institutional-settlement/school_student_add',
    },
    '/main/institutional-settlement/school_student_updata':{
        'oneName':'机构成员管理',
        'twoName':'学校成员管理',
        'threeName':'学生信息管理',
        'fourName':'修改学生',
        'twoPath':'/main/institutional-settlement/school-member-manage',
        'threePath':'/main/institutional-settlement/member_list_student_management',
        'fourPath':'/main/institutional-settlement/school_student_updata',
    },
    '/main/institutional-settlement/virtual_student_add':{
        'oneName':'机构成员管理',
        'twoName':'虚拟机构成员管理',
        'threeName':'学生管理',
        'fourName':'新增学生',
        'twoPath':'/main/institutional-settlement/virtual_organization_members',
        'threePath':'/main/institutional-settlement/student_management',
        'fourPath':'/main/institutional-settlement/virtual_student_add',
    },
    '/main/institutional-settlement/virtual_student_updata':{
        'oneName':'机构成员管理',
        'twoName':'虚拟机构成员管理',
        'threeName':'学生管理',
        'fourName':'修改学生',
        'twoPath':'/main/institutional-settlement/virtual_organization_members',
        'threePath':'/main/institutional-settlement/student_management',
        'fourPath':'/main/institutional-settlement/virtual_student_updata',
    },
}
