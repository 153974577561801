/*任务管理*/
import React, { Component } from "react";
import {
    Row,
    Input,
    Table,

    Col,

    Select,
    DatePicker,Modal,Button,message,Spin
} from "antd";
import "./index.scss";
import {Link} from "react-router-dom";
import {getLiveTaskList, continueLive, adminJoinLiveRoom, getEnterpriseList} from '@/api/lives/live'
import { withRouter } from "react-router";
// import {Link} from "react-router-dom";
import moment from "moment";
import Processing from '@/components/processing';
import Banned from '@/components/banned';
import { baseURL } from '@/config/index.js';
import Cookies from "js-cookie";
import RenderButton from "@/components/renderButton";
import OBreadcrumb from '@/components/Breadcrumb'
import routerList from '@/router/router_config.js';
import {connect} from "react-redux";
import validate from "../../../../config/validate";
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    console.log(menuList)
    return {
        menuList
    }
}
@withRouter
class Adminlive extends Component {
    state = {
        liveLecturer:"",
        data:[],
        userId: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "",
        visible: false,
        confirmLoading: false,
        total: 0,
        reportType:0, // 暂停3  禁止2  冻结1
        homeworkNum: 0,
        pageNum:1,
        pageSize: 12,
        current:1,
        selectStatus:"",
        userInfo : Cookies.get("info") ? JSON.parse(decodeURI(Cookies.get("info"))) : "",
        taskName:"",
        school: '',
        columns : [
            {
                title: '直播名称',
                width: 100,
                dataIndex: 'taskName',
                key: 'name',
                // fixed: 'left',
            },
            {
                title: '所在学校',
                width: 100,
                dataIndex: 'unitName',
                key: 'school',
                // fixed: 'left',
            },
            {
                title: '讲解教师',
                dataIndex: 'liveLecturer',
                key: 'teacher',
                width: 150,
            },
            {
                title: '目前直播状态',
                dataIndex: 'taskStatus',
                key: 'status',
                width: 150,
                render: (e) => e === 0?<div className="statusTxt"><span className='span-a'></span>未开始</div>:e ===1?<div className="statusTxt"><span></span>直播中</div>:e===2?<div className="statusTxt"><span className="span-b"></span>已结束</div>
                    :e===3?<div className="statusTxt"><span className='span-b'></span>已结束</div>: e===4?<div className="statusTxt"><span className="span-d"></span>禁止开播</div>:e===5?<div className="statusTxt"><span className="span-e"></span>已冻结</div>:""
            },
            {
                title: '操作',
                key: 'operation',
                // fixed: 'right',
                width: 100,
                render: (pro,data) => {
                    return(
                        <div className="cont">
                            {
                                data.taskStatus === 0?
                                    <>
                                        <RenderButton id="LS_02_G01_b_btnyinShenEnterZBRoom">
                                            <span className="but" onClick={()=>this.invisible(data)}>隐身进入</span>
                                        </RenderButton>
                                        <RenderButton id="LS_02_G01_b_btnNOBeginZB">
                                            <span className="but" onClick={()=>this.report(2,data.id)}>禁止开播</span>
                                        </RenderButton>
                                        <RenderButton id="LS_02_G01_b_btnFrozenZB">
                                            <span className="but" onClick={()=>this.report(1,data.id)}>冻结直播</span>
                                        </RenderButton>
                                        <RenderButton id="LS_02_G01_b_btnShowZbDetails">
                                            <span className="but" onClick={()=>this.Checkpage(data.id)}>查看详情</span>
                                        </RenderButton>
                                        {/*<RenderButton id="LS_02_G01_b_btnViewRecords">*/}
                                        {/*    <span className="but" onClick={()=>this.Viewrecords(data.id)}>查看记录</span>*/}
                                        {/*</RenderButton>*/}
                                    </>
                                    :data.taskStatus === 1?
                                    <>
                                        <RenderButton id="LS_02_G01_b_btnyinShenEnterZBRoom">
                                            <span className="but" onClick={()=>this.invisible(data)}>隐身进入</span>
                                        </RenderButton>
                                        <RenderButton id="LS_02_G01_a_btnForcedEndZB">
                                            <span className="but" onClick={()=>this.report(4,data.id)}>强制结束</span>
                                        </RenderButton>
                                        <RenderButton id="LS_02_G01_c_btnFrozenZB">
                                            <span className="but" onClick={()=>this.report(1,data.id)}>冻结直播</span>
                                        </RenderButton>
                                        <RenderButton id="LS_02_G01_b_btnShowZbDetails">
                                            <span className="but" onClick={()=>this.Checkpage(data.id)}>查看详情</span>
                                        </RenderButton>
                                        {/*<RenderButton id="LS_02_G01_b_btnViewRecords">*/}
                                        {/*    <span className="but" onClick={()=>this.Viewrecords(data.id)}>查看记录</span>*/}
                                        {/*</RenderButton>*/}
                                    </>
                                    :data.taskStatus === 2||data.taskStatus === 3?
                                        <>
                                            <RenderButton id="LS_02_G01_b_btnExportChatRecord">
                                                <span className="but" onClick={() => this.exportChatMsg(data.id)}>导出聊天</span>
                                            </RenderButton>
                                            <RenderButton id="LS_02_G01_c_btnFrozenZB">
                                                <span className="but" onClick={()=>this.report(1,data.id)}>冻结直播</span>
                                            </RenderButton>
                                            <RenderButton id="LS_02_G01_b_btnShowZbDetails">
                                                <span className="but" onClick={()=>this.Checkpage(data.id)}>查看详情</span>
                                            </RenderButton>
                                            {/*<RenderButton id="LS_02_G01_b_btnViewRecords">*/}
                                            {/*    <span className="but" onClick={()=>this.Viewrecords(data.id)}>查看记录</span>*/}
                                            {/*</RenderButton>*/}
                                        </>:""
                            }


                            {/*{*/}
                            {/*    data.taskStatus === 2 || data.taskStatus === 1 ?*/}
                            {/*        // <RenderButton id = "LS_02_B01_b_btnyinShenEnterZBRoom">*/}
                            {/*            <span className="but" onClick={()=>this.invisible(data)}>隐身进入</span>*/}
                            {/*        // </RenderButton>*/}
                            {/*        : ""*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    data.taskStatus === 2 ? data.suspendTime === 0 ?*/}
                            {/*        // <RenderButton id="LS_02_B01_b_btnSuspendZB">*/}
                            {/*            <span className="but" onClick={()=>this.report(3,data.id)}>暂停直播</span>*/}
                            {/*        // </RenderButton>*/}
                            {/*        :<span className="but" onClick={()=>this.continueLive(data.id)}>恢复直播</span> : ""*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    data.taskStatus === 2 ?*/}
                            {/*        // <RenderButton id="LS_02_B01_b_btnForcedEndZB">*/}
                            {/*            <span className="but" onClick={()=>this.report(4,data.id)}>强制结束</span>*/}
                            {/*        // </RenderButton>*/}
                            {/*        : ""*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    data.taskStatus === 0 ?*/}
                            {/*        // <RenderButton id="LS_02_B01_b_btnNOBeginZB">*/}
                            {/*            <span className="but" onClick={()=>this.report(2,data.id)}>禁止开播</span>*/}
                            {/*        // </RenderButton>*/}
                            {/*        : ""*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    data.taskStatus === 1 ?*/}
                            {/*        // <RenderButton id="LS_02_B01_b_btnNOBeginZB">*/}
                            {/*        <span className="but" onClick={()=>this.report(4,data.id)}>强制结束</span>*/}
                            {/*        // </RenderButton>*/}
                            {/*        : ""*/}
                            {/*}*/}

                            {/*{*/}
                            {/*    data.taskStatus === 3 ||  data.taskStatus === 4 ?*/}
                            {/*        // <RenderButton id="LS_02_B01_b_btnExportChatRecord">*/}
                            {/*            <span className="but" onClick={()=>this.exportChatMsg(data.id)}>导出聊天</span>*/}
                            {/*        // </RenderButton>*/}
                            {/*        : ""*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    data.taskStatus === 5 ? <span className="but">已冻结</span> :*/}
                            {/*        // <RenderButton id="LS_02_B01_b_btnFrozenZB">*/}
                            {/*            <span className="but" onClick={()=>this.report(1,data.id)}>冻结直播</span>*/}
                            {/*        // </RenderButton>*/}
                            {/*}*/}
                            {/*<RenderButton id="LS_02_B01_b_btnShowZbDetails">*/}
                            {/*    <span className="but" onClick={()=>this.Checkpage(data.id)}>查看详情</span>*/}
                            {/*<span className="but"><Link*/}
                            {/*    target = "_blank"*/}
                            {/*    to={`/livecontent/?${encodeURI(JSON.stringify(*/}

                            {/*        {taskId:data.id,isReport:0}*/}
                            {/*    ))}`}>*/}
                            {/*    查看详情*/}
                            {/*</Link></span>*/}
                            {/*</RenderButton>*/}
                            {/*<RenderButton id="LS_02_B01_b_btnViewRecords">*/}
                            {/*    <span className="but" onClick={()=>this.Viewrecords(data.id)}>查看记录</span>*/}
                            {/*</RenderButton>*/}
                        </div>
                    )
                }
            },
        ],
        coverFlag:false,
        coverFlaga:false,
        startTime:"",
        endTime:"",
        status: 0,
        tableLoading:true,
        loading:false,
        enterpriseList:[]
    };

    componentDidMount = async () => {
        this.getLiveTaskList()
        this.getEnterpriseList()
    };

    // 查询列表数据
    getLiveTaskList = () =>{
        this.setState({
            loading : true
        })
        const {pageNum,liveLecturer, pageSize,selectStatus,taskName,startTime,endTime,school} = this.state
        getLiveTaskList({pageNum,liveLecturer,pageSize,taskStatus:selectStatus,taskName,startTime,endTime,school}).then(e=>{
            console.log("数据：：：",e)
            this.setState({data:e.data,total:e.total,tableLoading:false,loading:false})
        })
    }
    getEnterpriseList = ()=>{
        getEnterpriseList().then(e=>{
            this.setState({enterpriseList:e})
        })
    }
    Checkpage = (taskId) => {
        //beforePage 0:直播任务列表 1:直播举报管理 2：举报处理记录
        this.props.history.push(`/livecontent?${encodeURI(JSON.stringify({taskId,isReport:0,beforePage:0}))}`)
    };

    // 隐身进入
    invisible = (data) => {
        adminJoinLiveRoom({taskId:data.id,studentId:this.state.userId}).then(res => {
            if (res){
                this.props.history.push(`/livepageroom?${encodeURI(JSON.stringify({taskId: data.id,taskName: data.taskName,isReport:1,beforePage:0}))}`);
                // window.open(liveUrl + `?${encodeURI(JSON.stringify({taskId: data.id,taskName: data.taskName,isReport:1}))}`)
            }
        })
    };

    pageChange = (page, pageSize) => {
        this.setState({
            pageNum:page,
            current:page
        },() => {
            this.getLiveTaskList()
        })
    };

    onChange = (dates, dateStrings) => {
        this.setState({
            startTime:dateStrings[0],
            endTime:dateStrings[1]
        })
    }

    onShowSizeChange = (current, size) => {
        this.state.postParam.pageNum = current;
        this.state.postParam.pageSize = size;
        this.liveList(this.state.postParam);
    };

    cancel = e => {
        this.setState({ coverFlag:false,coverFlaga:false });
    }

    Viewrecords = (taskId) => {
        this.setState({ coverFlag:true,taskId});
    }

    report = (reportType,taskId) =>{
        this.setState({ coverFlaga:true,reportType,taskId});
    }

    // 继续直播
    continueLive = (taskId) =>{
        continueLive({taskId}).then(e=>{
            message.destroy()
            message.success("操作成功")
            this.getLiveTaskList()
        })
    }

    returnMessage = () =>{
        const {reportType} = this.state;
        if(reportType===3){
            return "暂停开播"
        }else if(reportType===2){
            return "禁止开播"
        }else if(reportType===1){
            return "冻结直播"
        }else if(reportType===4){
            return "强制结束"
        }
    }

    handleChange =(vlaue) =>{
        console.log(vlaue)
        this.setState({selectStatus:vlaue})
    }

    change =(e) => {
        this.setState({taskName:e.target.value})
    }

    search =() => {
        this.getLiveTaskList()
    }
    enterpriseChange =(e)=>{
        this.setState({school:e})
    }

    exportChatMsg = (id) =>{
        // if (record.lastHandledResult === "0") {
        //     message.warning("本次直播内容违规，已被管理员禁止开播，无法导出数据")
        //     return
        // }
        // if (record.liveStatus === 1 || record.liveStatus === 2) {
        //     message.warning("直播任务未结束，无法导出数据")
        //     return
        // }
        let taskId = id;
        let chatType = 0;
        // window.location.href = `http://${window.location.host}/api/zhibo/api/v1/live/chat/exportChatMessage?taskId=${taskId}&chatType=${chatType}`;
        window.location.href = `${baseURL}/api/zhibo/api/v1/live/chat/exportChatMessage?taskId=${taskId}&chatType=${chatType}`;
        // window.location.href = 'http://192.168.1.117:18600/api/interaction/api/v1/live/chat/exportChatMessage?taskId=' + taskId + '&chatType=' + chatType;
    }
    //教师查询
    tearchChange=(e)=>{
        this.setState({liveLecturer:e.target.value})
    }
    //查询
    searchButton=()=>{
        this.setState({
            current:1,
            pageNum:1
        },()=>{
            this.getLiveTaskList()
        })
    }
    //重置
    resetClick=()=>{
        this.setState({
            startTime:"",
            endTime:"",
            selectStatus:"",
            taskName:"",
            liveLecturer:"",
            school:"",
            current:1,
            pageNum:1
        },()=>{
            this.getLiveTaskList()
        })
    }
    render() {
        const { pageSize, total,columns,coverFlag,coverFlaga,data,reportType,taskId,status,tableLoading,loading,enterpriseList} = this.state; //pageSize, visible,
        let path = this.props.location.pathname;
        let { menuList } = this.props;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        let two, three;
        menuList.forEach(v => {
            v.children && v.children.forEach(k => {
                k.children.forEach(i=>{
                    if(i.menuUrl===path){
                        two = k;
                        three = i;
                    }
                })
            })
        })
        let arr = [{ ...one, title: one.name }, two, three];
        const dateFormat = 'YYYY-MM-DD';
        return (
            <div className="home-live-student">
                {/*顶部star*/}
                <div className="o-breadcrumb">
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    {/*<div className="at-present-router">*/}
                    {/*    <div className="router-title">*/}
                    {/*        <div className="line"></div>*/}
                    {/*        <div className="router-text">直播任务列表</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*顶部end*/}
                <div className="wrap-center">
                    <div className="student-mylive-pic">
                        <div className="teching-mylive-main">
                            <Row className="paper-common ">
                                <Col span={16}>
                                    <Select value={this.state.selectStatus?this.state.selectStatus:undefined} onChange={(value)=>this.handleChange(value)} placeholder={"请选择直播状态"}>
                                        {/*<Option value="">全部状态</Option>*/}
                                        <Option value="0">未开始</Option>
                                        <Option value="1">直播中</Option>
                                        <Option value="2">正常结束</Option>
                                        {/*<Option value="4">强制结束</Option>*/}
                                        {/*<Option value="5">禁止开播</Option>*/}
                                        {/*<Option value="6">已冻结</Option>*/}
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="paper-common">
                                <Col span={16}>
                                    <Select placeholder='请选择所在院校' value={this.state.school?this.state.school:undefined} onChange={(e) => this.enterpriseChange(e)}>
                                        <Option value="">全部院校</Option>
                                        {enterpriseList.length ? enterpriseList.map((item, index) => {
                                            return <Option key={item.enterpriseId} value={item.enterpriseId}>{item.enterpriseName}</Option>
                                        }) : ""}
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="paper-common min-box">
                                <Col span={16}>
                                    <RangePicker
                                        value={this.state.startTime?[moment(this.state.startTime, dateFormat), moment(this.state.endTime, dateFormat)]:null}
                                        format={dateFormat}
                                        ranges={{
                                            今天: [moment(), moment()],
                                            '本周': [moment().startOf('Week'), moment().endOf('Week')],
                                            '本月': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        onChange={this.onChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="paper-common min-box">
                                <Col span={18} className="live-right">
                                    <Input
                                        value={this.state.taskName}
                                        placeholder="请输入您要查询的直播名称"
                                        maxLength={20}
                                        onChange={(e)=>this.change(e)}
                                    />

                                </Col>
                            </Row>
                            {/*<Row className="paper-common">*/}
                            {/*    <Col span={18} className="live-right">*/}
                            {/*        <Input*/}
                            {/*            value={this.state.liveLecturer}*/}
                            {/*            placeholder="请输入您要查询的讲师名称"*/}
                            {/*            maxLength={20}*/}
                            {/*            onChange={(e)=>this.tearchChange(e)}*/}
                            {/*            onSearch={()=>this.search()}*/}
                            {/*        />*/}

                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <div className="search-button">
                                <Button className="reset" onClick={this.resetClick}>重置</Button>
                                <Button type="primary" onClick={this.searchButton}>查询</Button>
                            </div>
                        </div>
                        <div className="teching-mylive-content">
                            <Row>
                                <Col span={6}>
                                </Col>

                            </Row>
                            <div className="teching-mylive-bottom">
                                <div className='text-left'>共查询到{this.state.total}个直播任务</div>
                                <div className="mylive-list">
                                    {
                                        tableLoading ? <Spin className="Spin-content" tip="加载中"/>:(
                                            <Table columns={columns} dataSource={data} sticky loading={loading}
                                                   className={'adminlivetable'}
                                                   pagination={{onChange:this.pageChange,
                                                       pageSize:this.state.pageSize,
                                                       total:this.state.total,
                                                       current:this.state.current}}
                                                   locale={{emptyText:<><div className="Empty-conet">
                                                           <div className="img">
                                                               <img src={window.$$setImgSrc("zhibo_list_Empty.jpg")}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div></>}}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="处理记录" className='Modal-processing' visible={coverFlag} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       centered={true}
                       footer={
                           <div className='pos-btn'>
                               <Button className="but" onClick={this.cancel}>关闭</Button>
                               <Button className="but" type="primary" style={{display:"none"}} onClick={this.sureChangeCover}>提交</Button>
                           </div>
                       }
                >
                    <Processing taskId={taskId}/>
                </Modal>
                <Modal title={this.returnMessage()} className='Modal-Banned dongjiezbmodal' visible={coverFlaga} destroyOnClose={true} maskClosable={false}
                       onCancel={this.cancel}
                       closable={false}
                       centered={true}
                       footer={
                           <div className='pos-btn' style={{display:"none"}}>
                               <Button className="but" onClick={this.cancel}>取消</Button>
                               <Button className="but" type="primary">确定</Button>
                           </div>
                       }
                >
                    <Banned reportType={reportType} taskId={taskId} cancel={()=>{this.setState({coverFlaga:false })}} getList={() => this.getLiveTaskList()} isReport={0}/>
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Adminlive);
