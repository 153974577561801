

export default {
    SET_MODULE(state, action) {
        if(action.data==='show'){
            state.visible=true
        }else if(action.data=='ok'){
            state.visible=false
        }else if(action.data=='cancel'){
            state.visible=false
        }
        
    },
    SET_SELECT(state,action){

    }
}