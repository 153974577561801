import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Row, Col, Checkbox, Radio, DatePicker} from 'antd';
import './index.scss';
import {selectAcademyTeacher,addAcademyTeacher,resetUserPassword,deleteAcademyTeacher,seeAcademyTeacher,
        updateAcademyTeacher} from '../../../../api/platform-school/index'
import {
    downTemplate, checkImportSchoolTeachers,
    getCitySel,
    getProvinceSel,
    globalSel,
    selectFaculty
} from '../../../../api/platform-business/index'
import moment from "moment";
import Batch from '@/components/import';
const { Option } = Select;

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };
// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

const Demo = () => {
    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
}
const App = () => {
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>男</Radio>
            <Radio value={2}>女</Radio>
        </Radio.Group>
    );
};

    class index extends Component {
        state = {
            dataSource: [],
            userId: '',
            enterpriseId: '',
            pageNum: 1,
            pageSize: 10,
            total: '',
            isTeacher: false, // 新增教师弹层
            isDelete: false,// 删除弹层
            isRepassword: false,// 重置密码
            propertyList: [], // 教师类型下拉列表
            professionalCallList: [], // 职称下拉列表
            professionalCall: '', // 职称
            property: '', // 教师类型
            teacherNameOrNum: '', // 教师工号/教师名称
            propertyDicType: 19, // 教师类型字典中的类型
            professionalCallDicType: 16, // 职称字典中的类型
            nameLength: 0, // 新增弹框姓名长度
            positionLength: 0, // 新增弹框职务长度
            provinceSel: [], // 所在地省下拉列表
            provinceVal:'',//省
            cityVal:'',//市
            citySel: [], // 所在地市
            highestDegreeOption:[],//学位下拉
            highestEducationOption:[],//学历下拉
            facultyResOption:[],//院系下拉
            cardTypeOption:[{
                code:'0',
                name:'身份证'
            },{
                code:'1',
                name:'护照'
            }],//证件下拉
            isUpdateOrSee: '', // 1是编辑 2是查看
            birthday: '',
            batch: false,
            inductionTime: '',
            sex: '',
            teacherId: '',
            adminUserId: '',
            cityName: '',
            provinceName: '',
            provinceCode: '',
            cityId: '',
            scUserId: '', // 删除时的id
        }

        componentDidMount() {
            const data = this.props.location.search; //地址栏截取
            const param = data.split("?")[1];
            const codeParam = param.includes("%") ? decodeURI(param) : param;
            const jsonparam = JSON.parse(codeParam);
            this.setState({
                enterpriseId: jsonparam.enterpriseId
            },() => {
                this.selectAcademyTeacher()
                this.getProvince()
                this.getProfessionalCall()
                this.getProvinceSel()
                this.getCitySel()
                this.getPolitical()//学历学位下拉数据
                this.getFaculty()//院系下拉数据
            })
        }
        async selectAcademyTeacher(){
            console.log('this.state.enterpriseId', this.state.enterpriseId)
            const param = {
                enterpriseId: this.state.enterpriseId,
                // isManagement: this.state.isManagement,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
                professionalCall: this.state.professionalCall,
                property: this.state.property,
                teacherNameOrNum: this.state.teacherNameOrNum
            }
            const teacherTable = await selectAcademyTeacher(param)
            // console.log('teacherTable.data eee', teacherTable.data)
            this.setState({
                total: teacherTable.total,
                dataSource: teacherTable.data
            })
        }

        //检索
        search = (e) => {
            this.setState({ teacherNameOrNum: e }, () => this.selectAcademyTeacher());
        }

        // 教师类型下拉
        getProvince = async () => {
            if (this.state.propertyDicType) {
                let provinceRes = await globalSel({ type: this.state.propertyDicType });
                provinceRes && this.setState({ propertyList: provinceRes });
            }
        }

        //教师类型下拉框change
        changeProperty = (e, item) => {
            this.setState({ property: e}, () => this.selectAcademyTeacher());
        }

        // 职称下拉
        getProfessionalCall = async () => {
            if (this.state.professionalCallDicType) {
                let professionalCallRes = await globalSel({ type: this.state.professionalCallDicType });
                professionalCallRes && this.setState({ professionalCallList: professionalCallRes });
            }
        }

        // 职称下拉框change
        changeProfessionalCall = (e, item) => {
            this.setState({ professionalCall: e}, () => this.selectAcademyTeacher());
        }

        async toTeacherrecord(){
            this.props.history.push(`/main/platform-business/teacher-record?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId,jumpType:1}))}`);
        }

        // 姓名输入框长度
        nameLength =  (rule, value, callback) => {
            if(value && value.length === 0){
                this.setState({
                    nameLength:0
                })
                callback();
            }else if (value && value.length!==0){
                this.setState({
                    nameLength:value.length
                })
                callback();
            } else {
                callback();
            }
        }

        // 姓名输入框长度
        positionLength =  (rule, value, callback) => {
            if(value && value.length === 0){
                this.setState({
                    positionLength:0
                })
                callback();
            }else if (value && value.length!==0){
                this.setState({
                    positionLength:value.length
                })
                callback();
            } else {
                callback();
            }
        }

        // 校验手机号格式
        validatePhone =  (rule, value, callback) => {
            let pattern = /^((\+|00)86)?1[3-9]\d{9}$/g;
            if (value && !pattern.test(value)){
                callback('请输入正确的手机号')
            } else {
                callback();
            }
        }

        //省下拉
        getProvinceSel = async () => {
            let res = await getProvinceSel();
            console.log('省下拉 res', res)
            res && this.setState({ provinceSel: res });
        }
        //省下拉框change
        changeProvince = (e, item) => {
            console.log("省",e)
            this.setState({ provinceCode: e, citySel: [],provinceName: item.props.children }, () =>
                this.getCitySel()
            );
        }

        //市下拉
        getCitySel = async () => {
            if (this.state.provinceCode) {
                let res = await getCitySel({ provinceCode: this.state.provinceCode });
                res && this.setState({ citySel: res });
            }
        }
        //市下拉框change
        changeCity = (e, item) => {
            this.setState({
                cityId: e,
                cityName: item.props.children
            });
        }
        getPolitical = async () => {
                let highestEducationOption = await globalSel({ type: 17 });
                highestEducationOption && this.setState({ highestEducationOption: highestEducationOption });
                let highestDegreeOption = await globalSel({ type: 18 });
                highestDegreeOption && this.setState({ highestDegreeOption: highestDegreeOption });
        }

        // 政治面貌下拉框change
        changePolitical = (e, item) => {
            this.setState({ political: e}, () => this.selectAcademyStudent());
        }
        // 院系下拉
        getFaculty = async () => {
            let facultyResOption = await selectFaculty({
                enterpriseId:this.state.enterpriseId,
                pageNum: 1,
                pageSize: '',
            });
            console.log("院系下拉",facultyResOption)
            facultyResOption && this.setState({ facultyResOption });
        }

         saveTeacher=()=>{
             this.props.form.validateFields((err, value) => {
                if(!err){
                    if(this.state.isUpdateOrSee === 1){
                        console.log('籍贯地址：：：：', this.state.provinceCode + "-" + this.state.cityId)
                        const {userId,enterpriseId}=this.state
                        let updateAcademyTeacherQo = {
                            id: this.state.teacherId,
                            adminUserId: this.state.adminUserId,
                            birthdays:  new Date(value.birthday),
                            cardType: value.cardType,//证件类型
                            enterpriseId: enterpriseId,
                            facultyId: value.facultyId,//	院系Id
                            highestDegree: value.highestDegree,
                            highestEducation: value.highestEducation,//最高学历
                            idCard: value.idCard,//证件号码
                            inductionTime:  moment(value.inductionTime).format('YYYY-MM-DD HH:MM:ss'),//入职时间
                            jobNum: value.jobNum,//工号
                            nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                            // nativePlace:`${ value.province},${value.city}`,//籍贯
                            positions: value.positions,//职务
                            professionalCall:value.professionalCall,//职称
                            teacherType: value.teacherType,//教师类型
                            sex: value.sex,
                            telNum: value.telNum,//手机号
                            userId:userId,
                            userName: value.userName,//姓名
                            workUnit: value.workUnit,//工作单位
                            email: value.email,//邮箱
                        }
                        updateAcademyTeacher(updateAcademyTeacherQo).then(res => {
                            this.setState({
                                isTeacher: false
                            })
                            this.mesSuccess('修改成功');
                            this.selectAcademyTeacher()
                        })
                    } else {
                        const {userId,enterpriseId}=this.state
                        let saveAcademyTeacherQo = {
                            birthdays:  new Date(value.birthday),
                            cardType: value.cardType,//证件类型
                            enterpriseId: enterpriseId,
                            facultyId: value.facultyId,//	院系Id
                            highestDegree: value.highestDegree,
                            highestEducation: value.highestEducation,//最高学历
                            idCard: value.idCard,//证件号码
                            inductionTime:  moment(value.inductionTime).format('YYYY-MM-DD HH:MM:ss'),//入职时间
                            jobNum: value.jobNum,//工号
                            password: value.password,
                            nativePlace: this.state.provinceCode + "-" + this.state.cityId,
                            // nativePlace:`${ value.province},${value.city}`,//籍贯
                            positions: value.positions,//职务
                            professionalCall:value.professionalCall,//职称
                            teacherType: value.teacherType,//教师类型
                            sex: value.sex,
                            telNum: value.telNum,//手机号
                            userId:userId,
                            userName: value.userName,//姓名
                            workUnit: value.workUnit,//工作单位
                            email: value.email,//邮箱
                        }
                        console.log('saveAcademyTeacherQo 2020', saveAcademyTeacherQo)
                        addAcademyTeacher(saveAcademyTeacherQo).then (res => {
                            if (res !== null) {
                                this.setState({
                                    isTeacher: false
                                })
                                this.mesSuccess('新增成功');
                                this.selectAcademyTeacher()
                            }
                        })
                    }
                }
            });
        }

        // 重置密码
        resetPasswords = (record) => {
            this.setState({
                scUserId: record.userId
            })
            this.hint({'title': '温馨提示',
                'content': '确定要重置密码吗?',
                'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.resetUserPassword})
            // let _this = this;
            // Modal.confirm({
            //     title: "温馨提示",
            //     content: "确定要重置密码吗？",
            //     okText: "确定",
            //     cancelText: "关闭",
            //     centered:true,
            //     className: "ModalConfirm-pic",
            //     onOk() {
            //         resetUserPassword({userId: record.userId}).then((res) => {
            //             this.mesSuccess("重置成功");
            //             _this.setState({
            //                 isRepassword: false
            //             },()=>{
            //                 _this.selectAcademyTeacher();
            //             })
            //         });
            //     },
            //     onCancel() {},
            // });
        }
        //重置密码
        resetUserPassword = async () => {
            const {scUserId} = this.state
            let data = {
                userId: scUserId
            }
            await resetUserPassword(data).then(r => {
                if(r){
                    this.mesSuccess("重置成功");
                    this.setState({
                        isRepassword: false
                    },()=>{
                        this.selectAcademyTeacher();
                    })
                }
            })
        }

        // 删除
        deleteAcademyTeachers = (record) => {
            // console.log('record.userId', record.userId)
            // let _this = this;
            // Modal.confirm({
            //     title: "温馨提示",
            //     content: "确定要删除吗？删除后将无法恢复！",
            //     okText: "确定",
            //     cancelText: "关闭",
            //     centered:true,
            //     className: "ModalConfirm-pic",
            //     onOk() {
            //         deleteAcademyTeacher({userId: record.userId}).then((res) => {
            //             this.mesSuccess("删除成功");
            //             _this.setState({
            //                 isDelete: false
            //             },()=>{
            //                 _this.selectAcademyTeacher();
            //             })
            //         });
            //     },
            //     onCancel() {},
            // });
            this.setState({
                scUserId: record.userId
            })
            this.hint({'title': '温馨提示',
                'content': '确定要删除吗？删除后将无法恢复！',
                'type': 'warning','cancelText': '关闭', 'okText': '确定','isCancel': true,'sure': this.deleteAcademyTeacher})

        }
        //删除
        deleteAcademyTeacher = async () => {
            const {scUserId} = this.state
            let data = {
                userId: scUserId
            }
            await deleteAcademyTeacher(data).then(r => {
                if(r){
                    this.mesSuccess("删除成功");
                    this.setState({
                        isDelete: false
                    },()=>{
                        this.selectAcademyTeacher();
                    })
                }
            })
        }

        //分页切换
        pageChange = (pageNum) => {
            this.setState({ pageNum }, () => this.selectAcademyTeacher());
            window.elGoTop && window.elGoTop('body');
        }
        //分页条数切换
        onShowSizeChange = (pageNum, pageSize) => {
            this.setState({ pageSize }, () => this.selectAcademyTeacher());
            window.elGoTop && window.elGoTop('body');
        }
        //校验电子邮箱格式
        validateInvestmail =  (rule, value, callback) => {
            let regex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.(com|cn|net|clup|com.cn))$/;
            console.log("正常打印",regex.test(value));
            if (value && !regex.test(value)){
                callback('请填写正确电子邮箱')
            }else{
                callback();
            }
        };
        //身份证验证
        IsCard=(rule, value, callback)=> {
            var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (value && !reg.test(value)){
                callback('请填写正确证件号')
            }else{
                callback();
            }
        }

        update =(record)=> {
            console.log('update record ', record)
            this.setState({
                isTeacher: true,
                isUpdateOrSee: 1,
                teacherId: record.id,
                adminUserId: record.userId
            },() => {
                this.seeAcademyTeacher(record, 1)
            })
        }

        seeAcademyTeacher =(record, type) => {
            console.log('record 0000.......', record)
            this.setState({
                isTeacher: true,
                isUpdateOrSee: type ? type : 2
            })
            const param = {
                userId: record.userId,
                enterpriseId: this.state.enterpriseId
            }
            console.log('this.state.param', param)
            seeAcademyTeacher(param).then((res) => {
                console.log('this.state..res', res)
                if(res.province){
                    this.setState({
                        provinceCode: res.province
                    },() => {
                        this.getCitySel()
                    })
                }
                this.setState({
                    birthday: res.birthday,
                    inductionTime: res.inductionTime,
                    nameLength: res.userName ? res.userName.length : 0,
                    positionLength: res.positions ? res.positions.length : 0,
                    cityId: res.city ? res.city : '',
                })
                this.props.form.setFieldsValue({
                    userName: res.userName,
                    telNum: res.telNum,
                    highestEducation: res.highestEducation ? Number(res.highestEducation) : '',
                    highestDegree: res.highestDegree? Number(res.highestDegree) : '',
                    cardType: res.cardType,
                    idCard: res.idCard,
                    email: res.email,
                    jobNum: res.jobNum,
                    teacherType: res.teacherType ? Number(res.teacherType) : '',
                    professionalCall: res.professionalCall ? Number(res.professionalCall) : '',
                    workUnit: res.workUnit,
                    positions: res.positions,
                    facultyId: res.facultyId,
                    sex: res.sex,
                    province: res.province ? res.province : '',
                    city: res.city ? res.city : '',
                })
            })

        }
        // 导入成员弹窗取消
        closeBatch = () => {
            this.setState({ batch: false, errorMessage: '', btnActive: false });
        }
        getFormData=(formData)=>{
            formData.set("enterpriseId",this.state.enterpriseId)
            this.setState({
                batchRef: formData
            },()=>{
                console.log("batchRef",this.state.batchRef)
            })
        }

        //导入时得确定按钮
        commitBatch = () => {
            //从子组件调用导入接口，更改为点击确定后上传了
            console.log('this.state.batchRef)', this.state.batchRef)
            checkImportSchoolTeachers(this.state.batchRef).then(res => {
                if (res) {
                    console.log("res", res)
                    message.destroy();
                    this.mesSuccess('上传成功');
                    this.toTeacherresult(res)
                }
            }).catch(err => {
                console.log("err",err)
            })
            this.setState({ batch: false, errorMessage: '', btnActive: false });
        }
        async toTeacherresult(res){
            let transParam = {
                recordId: res,
                enterpriseId:this.state.enterpriseId
            }
            let param = JSON.stringify(transParam);
            this.props.history.push(`/main/platform-business/teacher-result?${encodeURI(param)}`)
        }
        sure = (e) => {
            this.setState({ btnActive: true, fileId: e });
        }
        render() {
            let {birthday,inductionTime,isUpdateOrSee,total,pageSize,isTeacher,isRepassword,isDelete,
                dataSource,propertyList,professionalCallList,nameLength,provinceSel,citySel,positionLength,
                highestEducationOption,highestDegreeOption,facultyResOption,cardTypeOption,sex,batch,btnActive,errorMessage} = this.state;
            const { getFieldDecorator,getFieldValue } = this.props.form;
            const columns = [
                {title: '序号', dataIndex: 'source', key: 'source', align: 'center',width: 80,render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
                {title: '工号/帐号', dataIndex: 'jobNum',key:'jobNum'},
                {title: '姓名', dataIndex: 'teacherName',key:'teacherName', width: 100},
                {title: '性别', align: 'center',width: 80, render: (text, item, index ) =>  <span className='serial'>{item.sex === 2 ? '女' : item.sex === 1 ? '男' : ''}</span>,},
                {title: '出生日期', dataIndex: 'birthday', key: 'birthday', align: 'center',render: (text) => text?moment(text).format('YYYY-MM-DD'): null,},
                {title: '籍贯', dataIndex: 'nativePlace', key:'nativePlace'},
                {title: '最高学位', dataIndex: 'highestDegree',key:'highestDegree'},
                {title: '职称', dataIndex: 'professionalCall', key:'professionalCall'},
                {title: '手机', dataIndex: 'telNum', key:'telNum'},
                {title: '教师类型', dataIndex: 'teacherTypeName', key:'teacherTypeName'},
                {
                    title: '操作',
                    key: 'operate',
                    align:'center',
                    fixed:'right',
                    width: 220,
                    render:(text, record)=><div className="opspan">
                        <div className="main">
                            <span className="but" onClick={() => this.seeAcademyTeacher(record)}>查看</span>
                            <span className="but" onClick={() => this.update(record)}>编辑</span></div>
                        <div className="main">
                            <span className="but" onClick={() => this.deleteAcademyTeachers(record)}>删除</span>
                            <span className="but" onClick={() => this.resetPasswords(record)}>重置密码</span></div>
                    </div>},
            ];
            return (
                <div className='managestu_boxs'>
                    <div className="onelist">
                        <div className="one">
                            <div>
                                <span style={{marginRight: '20px'}}>教师类型</span>
                                <Select placeholder='全部'
                                        onChange={this.changeProperty}
                                >
                                    <Option value={''}>全部</Option>
                                    {
                                        propertyList.map(property => <Option key={property.code} value={property.code}>{property.name}</Option>)
                                    }
                                </Select>
                            </div>
                            <div>
                                <span style={{marginRight: '20px'}}>职称</span>
                                <Select placeholder='全部'
                                        onChange={this.changeProfessionalCall}>
                                    <Option value={''}>全部</Option>
                                    {
                                        professionalCallList.map(professionalCall => <Option key={professionalCall.code} value={professionalCall.code}>{professionalCall.name}</Option>)
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="twolist">
                        <div className="top_list">
                            <div className="leftlist">
                                <Button className="buttonl" type="primary" style={{marginRight: '20px'}} onClick={() => this.setState({ batch: true })}>导入教师</Button>
                                <Button className="buttonm" style={{marginRight: '20px'}} onClick={() => this.setState({ isTeacher: true,isUpdateOrSee: 3 })}>新增教师</Button>

                                <Modal
                                    title= {isUpdateOrSee === 1 ? '编辑教师信息':isUpdateOrSee === 2 ? '查看教师信息' : '新增教师信息'}
                                    visible={isTeacher}
                                    destroyOnClose={true}
                                    closable={false}
                                    className='binding_modal binding_modal_ad_look_tea'
                                    footer={
                                        <div className='foot_botton'>
                                            {isUpdateOrSee === 1 ? <Button className="addbutton" onClick={() => this.saveTeacher()}>确认修改</Button> :
                                                isUpdateOrSee === 2 ? '' :
                                                <Button className="addbutton" onClick={() => this.saveTeacher()}>确认添加</Button>}
                                            <Button className="delete" onClick={() => this.setState({ isTeacher: false })}>取消</Button>
                                        </div>
                                    }
                                >
                                    <div className="modaltitle">
                                        个人信息
                                    </div>
                                    <Form className='lt_form_wapper'>
                                    <Form.Item label={`姓名`}>
                                        {getFieldDecorator('userName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入姓名`,
                                                },
                                                {
                                                    validator:this.nameLength
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入姓名" style={{ width: 330 , marginTop:"4px"}} disabled={isUpdateOrSee === 2}/>)}
                                        <span className="limited">{nameLength}/20</span>
                                    </Form.Item>
                                    <Form.Item label={`电话`}>
                                        {getFieldDecorator('telNum', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请输入电话`,
                                                },
                                                {
                                                    validator:this.validatePhone
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入电话" disabled={isUpdateOrSee === 2} style={{ width: 330 , marginTop:"4px"}}/>)}
                                    </Form.Item>
                                        <Form.Item className="sex" label={`性别`}>
                                            <div className="all">
                                                {/*<div className="totle">*/}
                                                {/*    <span className="title">性别</span>*/}
                                                {/*</div>*/}
                                                {getFieldDecorator('sex', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择性别`,
                                                        },
                                                    ],
                                                    initialValue: sex? sex : ""
                                                })(
                                                    <Radio.Group value={sex? sex : ""} disabled={isUpdateOrSee === 2} defaultValue= "1">
                                                        <Radio value={1}>男</Radio>
                                                        <Radio value={2}>女</Radio>
                                                    </Radio.Group>)}

                                            </div>
                                        </Form.Item>
                                    <Form.Item className='date_input' label={`出生日期`}>
                                        {getFieldDecorator('birthday', {
                                            rules: [
                                                // {
                                                //     required: true,
                                                //     message: `请选择出生日期`,
                                                // },
                                            ],
                                            initialValue: birthday? moment(birthday, 'YYYY-MM-DD') : null
                                        })(<DatePicker   disabled={isUpdateOrSee === 2} placeholder='请选择出生日期' style={{width: '100%'}} />)}
                                    </Form.Item>
                                    <Form.Item className='date_input'>
                                        <div className="all">
                                            <div className="totle">
                                                <span className="title">籍贯</span></div>
                                            {getFieldDecorator('province', {
                                            })(
                                            <Select placeholder='请选择省'
                                                    disabled={isUpdateOrSee === 2}
                                                    onChange={this.changeProvince}
                                            >
                                                {/*<Option value={''}>全部</Option>*/}
                                                {
                                                    provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                                }
                                            </Select>
                                            )}
                                            {getFieldDecorator('city', {
                                            })(
                                            <Select placeholder='请选择市'
                                                    disabled={isUpdateOrSee === 2}
                                                    onChange={this.changeCity}
                                            >
                                                {/*<Option value={''}>全部</Option>*/}
                                                {
                                                    citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                                }
                                            </Select>)}
                                            </div>
                                    </Form.Item>
                                    <Form.Item label={`最高学历`}>
                                        {getFieldDecorator('highestEducation', {
                                        })(   <Select placeholder='请填写最高学历' disabled={isUpdateOrSee === 2}>
                                            {
                                                highestEducationOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                            }
                                        </Select>)}
                                    </Form.Item>
                                    <Form.Item label={`最高学位`}>
                                        {getFieldDecorator('highestDegree', {
                                        })( <Select placeholder='请填写最高学位' disabled={isUpdateOrSee === 2}>
                                            {
                                                highestDegreeOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                            }
                                        </Select>)}
                                    </Form.Item>
                                    <Form.Item label={`证件类型`}>
                                        {getFieldDecorator('cardType', {
                                        })(<Select placeholder='请填写证件类型' disabled={isUpdateOrSee === 2}>
                                            {
                                                cardTypeOption.map(v => <Option key={v.code} value={v.code}>{v.name}</Option>)
                                            }
                                        </Select>)}
                                    </Form.Item>
                                    <Form.Item label={`证件号码`}>
                                        {getFieldDecorator('idCard', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入证件号码",
                                                },
                                                {
                                                    validator:this.IsCard
                                                }
                                            ]
                                        })(<Input  className="input" disabled={isUpdateOrSee === 2}/>)}
                                        {/*<div className="all">*/}
                                        {/*    <div className="totle">*/}
                                        {/*        <span className="title">证件号码</span></div><Input placeholder="请输入证件号" style={{ width: 330 , marginTop:"4px"}}/>*/}
                                        {/*</div>*/}
                                    </Form.Item>
                                    <Form.Item label={`邮箱`}>
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入邮箱",
                                                },
                                                {
                                                    validator:this.validateInvestmail
                                                }
                                            ]
                                        })(<Input  className="input" maxLength={100} disabled={isUpdateOrSee === 2}/>)}
                                        {/*<div className="all">*/}
                                        {/*    <div className="totle">*/}
                                        {/*        <span className="title">邮箱</span></div><Input placeholder="请输入邮箱" style={{ width: 330, marginTop:"4px" }}/>*/}
                                        {/*</div>*/}
                                    </Form.Item>
                                    <div className="modaltitle">
                                        机构信息
                                    </div>
                                    <Form.Item label={`工号/帐号`}>
                                        {getFieldDecorator('jobNum', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请填写工号/帐号`,
                                                },
                                            ],
                                        })(<Input  className="input" maxLength={100} disabled={isUpdateOrSee === 2}/>)}
                                        {/*<div className="all">*/}
                                        {/*    <div className="totle">*/}
                                        {/*        <span className='red'>*</span><span className="title">工号/帐号</span></div><Input placeholder="请输入工号" style={{ width: 330 , marginTop:"4px"}}/>*/}
                                        {/*</div>*/}
                                    </Form.Item>
                                    {isUpdateOrSee === 1 || isUpdateOrSee === 2 ? '':
                                        <Form.Item label={`密码`}>
                                            {getFieldDecorator('password', {
                                                initialValue: "zjy123456",
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请填写密码`,
                                                    },
                                                ],
                                            })(<Input  className="input" maxLength={100} disabled={isUpdateOrSee === 2}/>)}
                                        </Form.Item>
                                    }
                                        <Form.Item className='date_input' label={`入职日期`}>
                                            {getFieldDecorator('inductionTime', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择入职日期`,
                                                    },
                                                ],
                                                initialValue: inductionTime? moment(inductionTime, 'YYYY-MM-DD') : null
                                            })(<DatePicker  disabled={isUpdateOrSee === 2} placeholder='请选择入职日期' style={{width: '100%'}} />)}
                                        </Form.Item>
                                        <Form.Item label={`教师类型`}>
                                            <div className="all">
                                                {getFieldDecorator('teacherType', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择教师类型`,
                                                        },
                                                    ],
                                                })(
                                                    <Select placeholder='请选择教师类型' style={{ width: 330 , marginTop:"4px"}} disabled={isUpdateOrSee === 2}>
                                                        {/*<Option value={''}>全部</Option>*/}
                                                        {
                                                            propertyList.map(property => <Option key={property.code} value={property.code}>{property.name}</Option>)
                                                        }
                                                    </Select>)}
                                            </div>
                                        </Form.Item>
                                        <Form.Item label={`教师职称`}>
                                            <div className="all">
                                                {/*<div className="totle">*/}
                                                {/*    <span className='red'>*</span><span className="title">教师职称</span></div>*/}
                                                {getFieldDecorator('professionalCall', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择教师职称`,
                                                        },
                                                    ],
                                                })(
                                                    <Select disabled={isUpdateOrSee === 2} placeholder='请选择教师职称' style={{ width: 330 , marginTop:"4px"}}>
                                                        {/*<Option value={''}>全部</Option>*/}
                                                        {
                                                            professionalCallList.map(professionalCall => <Option key={professionalCall.code} value={professionalCall.code}>{professionalCall.name}</Option>)
                                                        }
                                                    </Select>)}
                                            </div>
                                        </Form.Item>
                                    <Form.Item>
                                        <div className="all">
                                            <div className="totle">
                                                <span className='red'>*</span><span className="title">院系</span></div>
                                            {
                                                getFieldDecorator('facultyId', {
                                                    initialValue: undefined,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `请选择院系`,
                                                        },
                                                    ],
                                                })(
                                                    <Select placeholder='请选择院系' style={{ width: 330 , marginTop:"4px"} } disabled={isUpdateOrSee === 2}
                                                    >
                                                        {/*<Option value={''}>全部</Option>*/}
                                                        {
                                                            facultyResOption.map(v => <Option key={v.id} value={v.id}>{v.facultyName}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </Form.Item>
                                        <Form.Item label={`工作单位`}>
                                            <div className="all">
                                                {/*<div className="totle">*/}
                                                {/*    <span className="title">工作单位</span></div>*/}
                                                {getFieldDecorator('workUnit', {
                                                    getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                })(
                                                    <Input placeholder="请输工作单位" disabled={isUpdateOrSee === 2} style={{ width: 330 , marginTop:"4px"}} />
                                                )}
                                            </div>
                                        </Form.Item>
                                    <Form.Item label={`职务`}>
                                        {getFieldDecorator('positions', {
                                            rules: [
                                                // {
                                                //     required: true,
                                                //     message: `请选择教师职称`,
                                                // },
                                                {
                                                    validator:this.positionLength
                                                }
                                            ],

                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input placeholder="请输入职务" disabled={isUpdateOrSee === 2} style={{ width: 330 , marginTop:"4px"}} />)}
                                        <span className="limited">{positionLength}/30</span>
                                    </Form.Item>
                                    </Form>
                                </Modal>
                                <Button className="buttonr" onClick={() =>{this.toTeacherrecord()}}><img src={require('@/assets/img/lead.png')} alt="" />导入记录</Button>
                            </div>
                            <div className="right_list">
                                <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入教师工号/姓名' />
                            </div>
                        </div>
                        <div className="tablelist">
                            <Table rowKey={v => v['enterpriseId']} pagination={
                                {
                                    total,
                                    pageSize:pageSize,
                                    onChange: this.pageChange,
                                    onShowSizeChange: this.onShowSizeChange,
                                    pageSizeOptions: ['5', '10', '20', '40'],
                                    showSizeChanger: true,
                                    // position:'bottomRight'
                                }
                            } columns={columns} dataSource={dataSource} />
                        </div>
                    </div>
                    <Modal className='batch' visible={batch} title='学校信息导入'
                           closable={false}
                           footer={
                               <div style={{ width: "100%" }}>
                                   <div className='pos-btn'>
                                       <Button onClick={this.closeBatch}>取消</Button>
                                       <Button disabled={btnActive ? false : true} className={btnActive ? "" : "gray"}
                                               onClick={this.commitBatch}>确定</Button>
                                   </div>
                                   {errorMessage ?
                                     <div className='error'>
                                         <span>请下载查看</span>
                                         <span>
                                        <img src={require('@/assets/img/err_down_icon.png')} alt="" />
                                        下载
                                    </span>
                                     </div>
                                     : null}
                               </div>
                           }
                    >
                        <Batch
                          ref={ref => this.batchRef = ref}
                          getFormData={this.getFormData}
                          sure={this.sure}
                          reset={() => this.setState({ errorMessage: '', btnActive: false })}
                          template={downTemplate(7)}
                          pullError={(val) => this.setState(val)}
                          titleType='院校老师'
                        />
                    </Modal>
                </div>
            )
        }
}

index = Form.create()(index);
export default index;
