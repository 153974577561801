


import React from 'react';


//实训门户管理
export default props => {
    return <svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7701 10.9627H2.02995C1.39311 10.9627 0.875 10.4192 0.875 9.75115V2.60949C0.875 1.94142 1.39311 1.39795 2.02995 1.39795H11.7701C12.4068 1.39795 12.925 1.94142 12.925 2.60949V9.75115C12.925 10.4192 12.4068 10.9627 11.7701 10.9627ZM2.22115 9.61654H11.5788V2.7441H2.22115V9.61654Z" fill="#6A7E9C"/>
        <path d="M13.0996 13.0869H0.89964C0.52794 13.0869 0.226563 12.7855 0.226563 12.4138C0.226562 12.0421 0.52794 11.7407 0.89964 11.7407H13.0996C13.4713 11.7407 13.7727 12.0421 13.7727 12.4138C13.7727 12.7855 13.4713 13.0869 13.0996 13.0869Z" fill="#BABABA"/>
    </svg>

}