import React from 'react';
import {Button, Form, Input, message, Modal} from "antd";
import {freeze} from '@/api/report/report'
const {TextArea} = Input;



class ReportReason extends React.Component {
  state = {
    saveLoading: false,
    visible: false,
    reason:'',  //资源冻结原因
    reasonNum: 0,  //资源冻结原因字数
    ids: [],
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.visible){
      this.setState({
        ids: nextProps.ids,
        visible: nextProps.visible
      })
    }
  }

  checkCancel = () => {
    this.props.checkCancel()
  }

  //冻结原因change
  frozenReasonsChange = ({ target: { value } }) => {
    this.setState({
      reason: value,
      reasonNum: value.length
    })
  };

  //批量冻结
  freezeBatch = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const {ids, reason} = this.state;
        if (!ids.length) {
          message.warning('请选择要冻结的资源');
          return;
        }
        this.setState({
          saveLoading: true
        })
        await freeze({
          ids: ids,
          type: 1,
          reason: reason
        })
        message.success('冻结成功');
        this.setState({
          visible: false,
          saveLoading: false,
          reason: null
        })
        this.props.refreshData()
      }
    })
  }

  render() {
    const {reason, reasonNum, saveLoading} = this.state;
    const {visible} = this.props
    const formItemLayout = {
      labelCol: {span: 0},
      wrapperCol: {span: 24},
    };
    const {getFieldDecorator} = this.props.form;
    return (
      <Modal
        title='冻结原因'
        wrapClassName='modal_box'
        visible={visible}
        closable={false}
        footer={
          <div className='modal_btn_j'>
            <Button onClick={() => this.checkCancel()}>取消</Button>
            <Button loading={saveLoading} onClick={() => this.freezeBatch()} type='primary'>保存</Button>
          </div>
        }
      >
        <Form {...formItemLayout}>
          <Form.Item>
            {
              getFieldDecorator('examineReason', {
                rules: [{required: true, message: '请输入冻结原因'},
                  {max: 100, message: '冻结原因不能超过100字'}]
              })(<div className="sold-box">
                <TextArea value={reason} placeholder='请输入冻结原因' onChange={this.frozenReasonsChange.bind(this)} autoSize={{minRows: 3, maxRows: 5}}/>
                <span className="suffixStyle">{reasonNum}/100</span>
              </div>)
            }
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

ReportReason = Form.create()(ReportReason)
export default ReportReason;