import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import BreadcrumbGeneral from '../../components/BreadcrumbGeneral'
import { Redirect } from 'react-router-dom';
import RouterView from '../../router/router_view';
import routerList from '@/router/router_config.js';
import Menu from '@/components/Menu';
import './index.scss';
import OBreadcrumb from "../../components/Breadcrumb";
const { Sider, Content } = Layout;
const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(class Resource extends React.Component {
    render() {
        let { menuList } = this.props;
        let path = this.props.location.pathname;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        
        let two, three;
        let amenu = menuList.filter(v => v.menuName === one.name);
        amenu[0] && amenu[0].children.forEach(i => {
            i.children && i.children.forEach(k => {
                if (k.menuUrl === path) {
                    two = i;
                }
                if (k.menuUrl !== path) {
                    one.children && one.children.map((item) => {
                        if (item.path === path) {
                            three = item;
                        }
                    })
                }

            })
        })
        // menuList[0] && menuList[0].children.forEach(v => {
        //     console.log(v)
        //     v.children && v.children.forEach(k => {

        //     })
        // })
        console.log(two)
        let arr = [one, two, three];
        let propsMenuList = menuList.find(v => v.menuName === "通用管理");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        let id = propsMenuList[0] && propsMenuList[0].id;
        return (
            <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    {/* <SiderLogo></SiderLogo> */}
                    {id ? <Menu {...this.props} openArr={[id]} menuList={propsMenuList} /> : null}
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    {
                        arr[1]?.menuName?
                            <div className="obreadcrumb_title">
                                <BreadcrumbGeneral list={arr} ref={e => this.crumb = e} {...this.props} />
                                <p>
                                    <i>
                                        <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="4" height="18" rx="2" fill="url(#paint0_linear)" />
                                            <defs>
                                                <linearGradient id="paint0_linear" x1="0.285714" y1="-4.71312e-07" x2="10.6256" y2="3.80682" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#6EA8FF" />
                                                    <stop offset="1" stopColor="#485AFE" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </i>
                                    <span style={{color:'#333'}}>
                                        {arr[2] ?.menuName || arr[2] ?.name}
                                    </span>
                                </p>
                            </div>:''
                    }

                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
})
