import React, { Component } from 'react'

import { Button, Table, Modal, Form, Collapse, Select, Tooltip, message } from 'antd'
import MaxLengthInput from '../../../components/hhinput'
import { Yichang, YuanAdd, EditNoActive, } from '../../../components/shixunsvg1.20/index'
import { cvecequipmentcategory, listLevel, cvecequipmentcategorysave, listByLevel, cvecequipmentcategoryedit, cvecequipmentcategoryremove, pageListdara } from '../../../api/shixun2'
import './index.scss'
const { Panel } = Collapse;
const { Option } = Select;
export class index extends Component {
    state = {
        delete: false,//删除,
        __delete_categoryId: null,//当前删除的id
        addvisible: false,//添加

        operation: false,//操作记录
        isshow: 'add',//新增 修改
        dataSource: [],//表格左侧数据
        __dataSource: [],//表格数据
        dataSourceloading: true,//表格数据oding
        dataSourceTable: [],   // 操作记录Table
        dataSourceTableloading: true,//操作记录loding，
        __listLevel: [],//设备分类管理-分类级别下拉
        __listByLevel: [],//设备分类管理-查询下拉分类
        __curClickId: null,//左侧默认展开的id
        __disable_parentId: "",//添加的分类级别选取一级 就禁用上级分类 
        __curXiuGaiId: null,//表格里的修改的当前id
        // 操作记录
        pageNum: 1,
        pageSize: 10,
        total: 0,

    }
    async componentDidMount() {
        const data = await this.getData()
        this.setState(() => {
            return {
                dataSource: data,
                __curClickId: data[0]?.children[0]?.categoryId,
                __dataSource: this.depCloneTree(data[0]),
                dataSourceloading: false
            }
        })


    }

    getData = async (params) => {
        const __cvecequipmentcategory = await cvecequipmentcategory(params)

        return __cvecequipmentcategory
    }

    getDataList = (pp) => {
        return pageListdara({
            operateType: 2,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }).then(res => {
            if (res && res.data) {
                this.setState(() => {
                    return {
                        dataSourceTable: res.data,
                        total: res.total,
                        pageNum: res.pageNum,
                        pageSize: res.pageSize,
                        dataSourceTableloading: false
                    }
                })
            } else {
                message.info('数据错误')
            }
        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize, ":sdd")
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        console.log(current, pageSize, ":sdd")
        this.setState({
            pageNum: current,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    }

    // 操作记录
    operationModal = () => {
        this.getDataList({
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
        this.setState({
            operation: true,
        });
    };

    operationOk = e => {
        console.log(e);
        this.setState({
            operation: false,
        });
    };

    operationCancel = e => {
        console.log(e);
        this.setState({
            operation: false,
        });
    };



    // 添加
    addvisibleModal = async (v) => {
        debugger
        if (this.state.isshow === 'add') {

            this.setState(() => {
                return {
                    addvisible: true,

                }
            })
        } else if (this.state.isshow === 'edit') {

            this.setState(() => {


                return {
                    addvisible: true,

                }
            }, () => {
                const o = {
                    categoryLevel: v.categoryLevel,
                    categoryName: v.categoryName,
                    categoryStatus: v.categoryStatus,
                    categoryExplain: v.categoryExplain,
                    parentId: v.parentId
                }
                this.props.form.setFieldsValue(o)
            })
        }

    };

    addvisibleOk = e => {
        if (this.state.isshow === "add") {
            e.preventDefault();

            this.props.form.validateFields(["categoryStatus", "parentId", "categoryName", "categoryExplain", "categoryLevel"], async (err, values) => {
                values.parentId = values.parentId ? values.parentId : '0'
                if (!err) {
                    const { code } = await cvecequipmentcategorysave({ ...values })
                    if (code === 0) {
                        this.mesSuccess('添加成功')
                    } else {
                        this.mesWarning('添加失败')
                    }
                    const data = await this.getData()
                    this.setState(() => {
                        return {

                            dataSource: data,
                            __curClickId: data[0]?.children[0]?.categoryId,


                            dataSourceloading: false,
                            addvisible: false,
                            __disable_parentId: ''

                        }
                    })

                }

            })
            // 编辑
        } else {
            this.props.form.validateFields(async (err, values) => {

                if (!err) {
                    const { __curXiuGaiId,__curClickId } = this.state
                    values.parentId = values.parentId ? values.parentId : '0'
                    const { code } = await cvecequipmentcategoryedit({ ...values, categoryId: __curXiuGaiId })

                    if (code === 0) {
                        this.mesSuccess('修改成功')
                    } else {
                        this.mesWarning('修改失败')
                    }
                    const data = await this.getData()

                    function findid(d) {
                        const childindex = d.find((v) => {
                            
                            if (v.categoryId === __curClickId) {
                                return v.categoryId
                            } else {
                                if (v.children && v.children.length > 0) {
                                    return findid(v.children)
                                }
                            }
                        })
                        return childindex
                    }
                 
                    this.setState(() => {
                        const item = findid(data)//z这里的应该是二级才对
                        const i=item?.children.findIndex(v=>v.categoryId===__curXiuGaiId)
                        // debugger
                        return {

                            dataSource: [...data],
                            // __curClickId: item?.categoryId,
                            __dataSource:i===-1? this.depCloneTree(item):this.depCloneTree(item?.children[i]),
                            dataSourceloading: false,
                            addvisible: false,


                            __curXiuGaiId: null
                        }
                    })

                }
            })
        }


    };

    addvisibleCancel = e => {
        console.log(e);
        this.setState({
            addvisible: false,
        });
    };
    // 删除Model

    deleteModal = ({ categoryId }) => {


        this.setState(() => {
            return {
                delete: true,
                __delete_categoryId: categoryId
            }
        });
    };

    deleteOk = async e => {

        const { __delete_categoryId } = this.state
        const { code } = await cvecequipmentcategoryremove({
            categoryId: __delete_categoryId
        })

        if (code === 0) {
            this.mesSuccess('删除成功')
        } else {
            this.mesWarning('删除失败')
        }
        const data = await this.getData()
        this.setState(() => {
            return {

                dataSource: data,
                __dataSource: this.depCloneTree(data[0].children),
                __curClickId: data[0]?.children[0]?.categoryId,
                dataSourceloading: false,
                delete: false,

            }
        })
    };

    deleteCancel = e => {
        console.log(e);
        this.setState({
            delete: false,
            __delete_categoryId: null
        });
    };
    depCloneTree = (tree = []) => {

        let arrs = [];
        let result = [];
        arrs = arrs.concat(JSON.parse(JSON.stringify(tree)));
        while (arrs.length) {
            let first = arrs.shift();
            if (first.children) {

                arrs = arrs.concat(first.children);
                delete first["children"];
            }
            result.push(first);
        }
        return result;

    }
    //打开的是哪个Panel
    openPanelFn = (v) => {

        const arr = this.depCloneTree(v)
        this.setState(() => {
            return {
                __dataSource: arr
            }
        })

    }
    //添加的分类级别选取一级 就禁用上级分类 
    disable_parentId = (val) => {
debugger
        this.setState(() => {
            return {
                __disable_parentId: val
            }
        })

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { dataSource, dataSourceTable, __dataSource, __curClickId } = this.state;
        const { RenderButton } = React;
        const formItemLayout_aaaa = {
            labelCol: {
                xs: { span: 2 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 2 },
                sm: { span: 12 },
            },
            // klgfjkfdljg
        };


        const columnsTable = [
            {
                title: '操作人',
                dataIndex: 'account',
                align: 'center'
            },
            {
                title: '设备分类管理',
                dataIndex: 'operationDesc',
                align: 'center'
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                align: 'center'
            },
            {
                title: '动作',
                dataIndex: 'actionDscp',
                align: 'center'
            },

        ];
        // Table
        const columns = [
            {
                title: '创建人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                align: "center",
                width: 230,
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                align: "center",
                width: 230

            },
            {
                title: '状态',
                dataIndex: 'categoryStatus',
                key: 'categoryStatus',
                align: "center",
                width: 230,

                render: (v, b) => {


                    return <div>


                        {b.categoryStatus === "0" ? '未启用' : b.categoryStatus === "1" ? '启用' : ''}
                    </div>

                }
            },
            {
                title: '备注',
                dataIndex: 'categoryExplain',
                key: 'categoryExplain',
                align: "center",
                width: 230
                ,
                render: (a, b, c) => {
                    // debugger
                    return <div >

                        <span>{b.categoryExplain && b.categoryExplain.length > 16 ? b.categoryExplain.substring(0, 16) + '...' : b.categoryExplain}</span>


                    </div>
                }

            },

            {
                title: '操作',
                align: "center",
                fixed: 'right',
                width: 130,
                render: (v, b, value) => {
                   
                    return <div className='action vertical_j' style={{ cursor: 'pointer' }}>
                        <RenderButton id="ABB-01-A02-b_btnEdit">
                            <span onClick={() => {
                                console.log(v)
                                this.setState(() => {
                                    return {
                                        isshow: 'edit'

                                    }
                                }, async () => {
                                    const __listLevel = await listLevel()

                                    const __listByLevel = await listByLevel()


                                    this.setState(() => {
                                        return {
                                            __listLevel,
                                            __listByLevel,
                                            __curXiuGaiId: v.categoryId,
                                            __disable_parentId: v.categoryLevel === '1' ? '1' : ''

                                        }
                                    }, () => {

                                        this.addvisibleModal(v)

                                    })

                                }

                                )
                            }}>修改</span>
                        </RenderButton>
                        <RenderButton id="ABB-01-A02-b_btnDel"> <span onClick={() => this.deleteModal(v)}>删除</span></RenderButton>



                    </div>
                },

            },
        ]
        const customPanelStyle = {
            background: '#fff',

            border: 0,
            overflow: 'hidden',

        };

        return (
            <div className="classified_management">

                <div className="classified_flex_wrappers">
                    <div className="classifyid_ll_left">
                        <div style={{ height: 73, display: "flex", alignItems: 'center', justifyContent: 'flex-start', padding: 20, borderBottom: '1px solid #e8e8e8', cursor: 'pointer' }} onClick={() => {
                            this.setState(() => {
                                return {
                                    isshow: 'add'
                                }
                            }, async () => {
                                const __listLevel = await listLevel()
                                const __listByLevel = await listByLevel()
                                this.setState(() => {
                                    return {
                                        __listLevel,
                                        __listByLevel
                                    }
                                }, () => {
                                    this.addvisibleModal()
                                })

                            })
                        }}>
                            <YuanAdd />&emsp;
                            <RenderButton id="ABB-01-A02-b_btnAdd"><span style={{ color: '#3E78ED', fontSize: "16px" }}>添加分类</span></RenderButton>

                        </div>

                        <Collapse defaultActiveKey={dataSource.map(fi => fi.categoryId)} expandIconPosition='right'  >
                            {dataSource.map(v => (<Panel
                                key={v.categoryId}
                                style={customPanelStyle}
                                header={
                                    <div
                                        onClick={() => {
                                            this.openPanelFn(v)
                                        }}
                                        style={{ display: 'flex', alignItems: 'center' }}>
                                        <EditNoActive />&emsp;
                                <div>
                                            <Tooltip title={v.categoryName} placement="bottom" overlayClassName="classTableTooltip">
                                                {v.categoryName && v.categoryName.length > 7 ? v.categoryName.substring(0, 7) + '...' : v.categoryName}
                                            </Tooltip>
                                        </div>
                                    </div>
                                } >
                                {
                                    v.children.map(c => {
                                        // debugger
                                        return <Tooltip key={c.categoryId} placement="bottom" title={c.categoryName}>

                                            <div onClick={() => {
                                                this.setState(() => {
                                                    return {
                                                        __curClickId: c.categoryId
                                                    }
                                                }, () => {
                                                    this.openPanelFn(c)
                                                })

                                            }} className={`ove item_overtext ${__curClickId === c.categoryId && 'item_overtextActive'}`}>{c.categoryName}</div>
                                        </Tooltip>
                                    })
                                }
                            </Panel>))}

                        </Collapse>
                    </div>
                    <div className="classifyid_rr_right">
                        <div className="classified_management_top">
                            <div>
                                <RenderButton id="ABB-01-A02-b_btnOperatingRecord"> <Button type='primary' onClick={this.operationModal} style={{ marginRight: 12 }}>操作记录</Button></RenderButton>

                            </div>


                        </div>

                        <div className="installation_management_buttom">
                            <Table rowKey='categoryId'
                                loading={this.state.dataSourceloading}
                                dataSource={__dataSource}
                                columns={columns}
                                pagination={false}
                                scroll={{ y: 'calc(100vh - 290px)', x: 'calc(100% - 700px)' }} />
                        </div>
                        {/* 删除 */}
                        <Modal
                            title={<div style={{ fontWeight: 500, color: '#333', fontSize: 18 }}>删除提示</div>}
                            visible={this.state.delete}
                            onOk={this.deleteOk}
                            onCancel={this.deleteCancel}
                            bodyStyle={{ height: 80 }}
                            width={450}
                            centered
                            closable={false}
                            destroyOnClose
                            wrapClassName='details_delete_class'

                        >
                            <div>
                                <p><span><Yichang></Yichang></span><span>是否删除改设备?</span></p>

                            </div>

                        </Modal>
                        <Modal
                            //   key={this.state.addvisible}
                            key={this.state.addvisible}
                            title={<div style={{ fontSize: 18, color: '#333', fontWeight: 500 }}>{this.state.isshow === "add" ? "添加设备分类" : '修改设备分类'}</div>}

                            visible={this.state.addvisible}

                            onOk={this.addvisibleOk}
                            onCancel={this.addvisibleCancel}

                            bodyStyle={{ height: 300,overflow:'auto' }}
                            width={660}
                            centered={true}

                            destroyOnClose
                            wrapClassName='class_Device_information'

                        >


                            <Form {...formItemLayout_aaaa}>

                                <Form.Item label="分类级别" >
                                    {getFieldDecorator('categoryLevel', {
                                        rules: [{ required: true, message: '请选择分类级别' },],
                                    })(
                                        <Select
                                            disabled={this.state.isshow !== "add"}
                                            placeholder="请选择分类级别"
                                            onChange={this.disable_parentId}
                                            getPopupContainer={triggerNode => triggerNode.parentElement}
                                        >


                                            {(this.state.__listLevel || []).map(l => { return <Option key={l.type} value={l.type + ''}>{l.desc}</Option> })}
                                        </Select>,

                                    )}

                                </Form.Item>

                                <Form.Item label="上级分类">

                                    {getFieldDecorator('parentId', {

                                        rules: [{ required: this.state.__disable_parentId !== '1', message: '请选择上级分类' }],

                                    })(

                                        <Select

                                            // disabled={}
                                            disabled={this.state.isshow !== "add"||this.state.__disable_parentId === '1'}
                                            placeholder="请选择上级分类"
                                            getPopupContainer={triggerNode => triggerNode.parentElement}

                                        >
                                            {this.state.__listByLevel.map(li => {
                                                return <Option key={li.categoryId} value={li.categoryId}>{li.categoryName}</Option>
                                            })}



                                        </Select>,

                                    )}

                                </Form.Item>



                                <Form.Item label="分类名称">

                                    {getFieldDecorator('categoryName', {

                                        rules: [{ required: true, message: '请输入分类名称' }],

                                    })(

                                        <MaxLengthInput placeholder='请输入分类名称' style={{ height: 40, paddingRight: 50 }} maxLength={30}></MaxLengthInput>,

                                    )}

                                </Form.Item>


                                <Form.Item label="备注">

                                    {getFieldDecorator('categoryExplain', {
                                        rules: [{ required: true, message: '请输入备注信息' }],

                                    })(
                                        <MaxLengthInput placeholder='请输入备注信息' type='textarea' isTextarea={true} style={{

                                            height: 100, paddingRight: 50, resize: 'none'

                                        }} maxLength={100}></MaxLengthInput>



                                    )}

                                </Form.Item>

                                <Form.Item label="状态">
                                    {getFieldDecorator('categoryStatus', {
                                        rules: [{ required: true, message: '请选择状态' }],
                                    })(
                                        <Select
                                            placeholder="请选择状态"

                                        >

                                            <Option value={'0'}>停用</Option>

                                            <Option value={'1'}>可用</Option>



                                        </Select>,

                                    )}

                                </Form.Item>


                            </Form>



                        </Modal>




                        {/* 操作记录 */}
                        <Modal
                            title={<div style={{ fontWeight: "bold", color: '#333', fontSize: 18 }}>操作记录</div>}
                            visible={this.state.operation}
                            destroyOnClose
                            onCancel={this.operationCancel}
                            bodyStyle={{ height: 300, overflow:'auto' }}
                            width={1000}
                            centered
                            closable={false}
                            wrapClassName='details_record'
                            footer={[


                                <Button key="submit" type="primary" onClick={this.operationOk}>
                                    我知道了
            </Button>,]}
                        >
                            <div>
                                <Table
                                    loading={this.state.dataSourceTableloading}
                                    dataSource={dataSourceTable} columns={columnsTable}

                                    scroll={{ y: 350 }}
                                    pagination={
                                        {
                                            defaultCurrent: 1,
                                            pageSizeOptions: ['10', '20', '30'],
                                            showSizeChanger: true,
                                            onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                            onChange: (a, b) => this.onPageNumChange(a, b),
                                            current: this.state.pageNum,
                                            pageSize: this.state.pageSize,
                                            total: this.state.total,
                                        }
                                    } />;
                    </div>

                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create()(index);



