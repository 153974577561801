/*专题配置管理*/
import React, { Component } from 'react';
import {Form, Button, message} from 'antd';
import InformationNews from "./compents/information_news/index"; // 表格资讯
import './index.scss';
import { getSearch } from '@/until/libs';
import {getSpecialColumnList, getSpecialInfo, releaseSpecial, cancelReleaseSpecial, getSpecialNewsNum} from "../../../../api/zixun/index"
import RenderButton from "../../../../components/renderButton";
import RenderImg from "../../../../components/renderImg";
import TopTitle from "../../../../components/Breadzx";
const topTitleData = {
    span_1: "资讯中心",
    span_2: " 专题管理",
    span_3: "专题管理 ",
    span_1_c: "#333",
    span_2_c: "#333",
    container: "专题配置",
};


class ThematicConsole extends Component {
    state = {
        specialId : '',
        tableLoading: false, // 是否显示加载中
        add_child_lm: false, // 新增创建子栏目弹层
        specialInfo : {},
        columnList : [],
        releaseStatus : 0,
    }

    componentDidMount() {
        let params = getSearch(this.props.location.search);
        console.log("params", params)
        this.setState({
            specialId: params.specialId
        }, () => {
            this.specialInfo();
            this.specialColumnList()
        })
    }

    specialInfo = () =>{
        let data = {
            id : this.state.specialId
        }
        getSpecialInfo(data).then(res =>{
            if(res){
                this.setState({
                    specialInfo : res,
                    releaseStatus : res.releaseStatus
                })
                console.log("specialInfo", this.state.specialInfo)
            }
        })
    }

    specialColumnList = () =>{
        let data = {
            id : this.state.specialId
        }
        getSpecialColumnList(data).then(res =>{
            if(res){
                this.setState({
                    columnList : res
                })
                console.log("columnList", this.state.columnList)
            }
        })
    }

    // 获取子组件
    onRef = (name, ref) => {
        switch (name) {
            case 'InformationNews':
                this.InformationNews = ref
                break
            default:
                break
        }
    }

    addSpecialColumn = () =>{
        this.InformationNews.addColumn()
    }

    releaseSpecial = () =>{
        let data = {
            id : this.state.specialId
        }
        getSpecialNewsNum(data).then(res =>{
            if(res){
                if(res > 0){
                    releaseSpecial(data).then(res =>{
                        if(res){
                            this.mesSuccess("发布成功")
                            this.specialInfo();
                        }
                    })
                }else {
                    // message.destroy();
                    this.mesWarning("至少需要有一条资讯才可发布，请先添加资讯")
                }
            }
        })
    }

    cancelReleaseSpecial = () =>{
        let data = {
            id : this.state.specialId
        }
        cancelReleaseSpecial(data).then(res =>{
            if(res){
                this.mesSuccess("取消发布成功")
                this.specialInfo();
            }
        })
    }

    cancel = () =>{
        this.props.history.push(`/main/group-column/thematicConfiguration`)
    }

    render() {
        let { specialInfo } = this.state;
        return (
            <div style={{height: 'calc(100% - 100px)'}}>
                <TopTitle {...topTitleData} />
          <div className='thematicConsole'>
              <div className="filter_controll">
                  <div className="item_wapper">
                      <div className="top-img">
                          {
                              <RenderImg  type={specialInfo.mediaType} size='s' id={specialInfo.specialImg}/>
                          }
                      </div>
                      <div className='center_box'>
                          <div className='center_title'>
                              {specialInfo.specialName}
                          </div>
                          <div className='center_center'>
                              <span>所属业务系统：{specialInfo.platformName}</span>
                              <span style={{marginLeft:'30px'}}>创建时间：{specialInfo.createTime}  </span>
                          </div>
                          <div className='center_bottom'>
                              {specialInfo.specialDesc}
                          </div>
                      </div>
                      <div className='btn_box'>
                          {/*<Button type="primary" className='back_blue' onClick={this.addSpecialColumn}>创建子栏目</Button>*/}
                          {/*<Button type="primary" className='border_blue' onClick={()=> this.props.history.push(`/main/group-column/addThematic?${decodeURI(JSON.stringify({ specialId: this.state.specialId }))}`)}>编辑</Button>*/}
                          <RenderButton id='NC_01_B11_c_btnNewSubcolumn'>
                              <Button type="primary" className='back_blue' onClick={this.addSpecialColumn}>创建子栏目</Button>
                          </RenderButton>
                          <RenderButton id='NC_01_B11_c_btneditor'>
                              {/*<Button type="primary" className='border_blue' onClick={()=> this.props.history.push(`/main/group-column/addThematic?${decodeURI(JSON.stringify({ specialId: this.state.specialId }))}`)}>编辑</Button>*/}
                              <Button type="primary" className='border_blue' onClick={()=> this.props.history.push(`/main/group-column/addThematic?${decodeURI(JSON.stringify({ specialId: this.state.specialId }))}`)}>编辑</Button>
                          </RenderButton>
                      </div>
                  </div>
                  <div className='bgImg-ztpz' ></div>
              </div>
              <div className="in_school_content">
                  <div className="in_school_main">
                      {
                          this.state.columnList.length > 0 ? (
                              this.state.columnList.map(value => (
                                  <InformationNews key={value.columnId} columnInfo ={value} onRef={this.onRef} releaseStatus={this.state.releaseStatus}
                                                   specialId={this.state.specialId} specialColumnList={this.specialColumnList} platform={this.state.specialInfo.platform}/>
                              ))
                          ) :  <InformationNews onRef={this.onRef} specialId={this.state.specialId} platform={this.state.specialInfo.platform} specialColumnList={this.specialColumnList}/>
                      }
                  </div>
                  <div className="fix_controll">
                      {
                          this.state.specialInfo.releaseStatus === 0 ?
                              (<Button className='add_btn_j' style={{ marginRight: '40px', width: '100px', height: '39px' }} onClick={this.releaseSpecial}>发布</Button>)
                              :
                              (<Button className='dr_btn_j' style={{ marginRight: '40px', width: '100px', height: '39px' }} onClick={this.cancelReleaseSpecial}>取消发布</Button>)
                      }
                      <Button className='dr_btn_j' style={{ marginRight: '40px', width: '100px', height: '39px' }} onClick={this.cancel}>取消</Button>
                  </div>
              </div>

          </div>
            </div>
        )
    }
}

ThematicConsole = Form.create()(ThematicConsole);
export default ThematicConsole;
