import React,{useEffect,forwardRef} from 'react';
import { Modal, Form, Input } from 'antd';
import styles from './index.module.scss';

const FormItem = Form.Item;

const ConfigModal = (props,ref) => {
    const {
        visible,onOk,onCancel,item,
        form:{
            getFieldDecorator,
            validateFields,
            resetFields,
        }
    } = props;

    useEffect(()=>{
        if(!visible){
            resetFields();
        };
    },[resetFields, visible]);

    const Ok = async () => {
        try {
            const res = await validateFields();
            onOk(res);
        } catch (error) {}
    };

    const Cancel = () => {
        onCancel();
    };

    return (
        <Modal
            visible={visible}
            title="配置应用"
            className={styles["config_modal"] + " global_modal"}
            onOk={Ok}
            ref={ref}
            onCancel={Cancel}
            style={{
                width:660,
            }}
            getContainer={false}
            okText="确定"
            okButtonProps={{size:'large'}}
            cancelButtonProps={{size:'large'}}
            bodyStyle={{
                height:180
            }}
        >
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 8 }}
                colon={false}
            >
                <h2>设置应用访问链接地址</h2>
                <FormItem label={item.appName || '链接地址'}>
                    {getFieldDecorator("url", {
                        rules: [
                            { 
                                required: true, 
                                message: "请输入链接地址！" 
                            },
                        ],
                    })(
                        <Input style={{width:330}} size="large" placeholder="请输入链接地址" maxLength={50}/>
                    )}
                </FormItem>
            </Form>
        </Modal>
    )
}

export default Form.create()(forwardRef(ConfigModal))