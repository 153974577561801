import React, { Component } from 'react';
import { Input, Table, Pagination, Button, Select, Modal } from 'antd';
import iconType from '../../icon_type';
import { virtualOrgMembersPage } from '../../api/organization_member_management';

import styles from './index.module.scss';

class index extends Component {
    state = {
        dataSource: [],
        pageNum: 1,
        pageSize: 20,
        isZhanKai: 'false',
        visible: false,
        searchValue: "",  //机构名称

    }
    componentDidMount() {
        this.virtualOrgMembersPage()
    }
    //列表
    virtualOrgMembersPage = async () => {
        const { pageNum, pageSize, searchValue } = this.state;
        const params = {
            pageNum: pageNum,
            pageSize: pageSize,
            virtualEnterpriseName: searchValue
        };
        try {
            let res = await virtualOrgMembersPage(params);
            this.setState({
                dataSource: res.data,
                total: res.total
            });
        } catch (error) { }

    };
    //分页
    pageChange = (pageNum, pageSize) => {
        this.setState({ pageNum, pageSize }, () => {
            this.virtualOrgMembersPage()
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({ pageNum: 1, pageSize }, () => {
            this.virtualOrgMembersPage()
        })
    };

    /*搜索*/
    onSearch = e => {
        this.virtualOrgMembersPage();
    };

    columns = [
        {
            title: '序号',
            dataIndex: '',
            key: '',
            align: 'center',
            render: (a, b, c) => <span>{c + 1}</span>,
        },
        {
            title: '虚拟机构名称',
            dataIndex: 'enterpriseName',
            key: 'enterpriseName',
            align: 'center',
            render: (text, item) => text || '--',
        },

        {
            title: '机构识别码',
            dataIndex: 'enterpriseCode',
            key: 'enterpriseCode',
            align: 'center',
            render: (text, item) => text || '--',

        },

        {
            title: '所属范围',
            dataIndex: 'manageRange',
            key: 'manageRange',
            align: 'center',
            render: (text, item) => text || '--',
        },
        {
            title: '教师人数',
            dataIndex: 'teacherNum',
            key: 'teacherNum',
            align: 'center',
        },
        {
            title: '学生人数',
            dataIndex: 'studentNum',
            key: 'studentNum',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '20%',
            render: (text, item, r) => {
                const {enterpriseName} = r;
                return <div>
                    <React.RenderButton id="OBSMM-01-B03-b-btnTeacherInformation">
                    <span className="but" onClick={() => this.props.history.push(`/main/institutional-settlement/teacher_management?id=${item.id}&name=${item.enterpriseName}-教师管理`)}>教师管理</span>
                    </React.RenderButton>
                    <React.RenderButton id="OBSMM-01-B03-b-btnStudentInformation">
                    <span className="but" onClick={() => this.props.history.push(`/main/institutional-settlement/student_management?id=${item.id}&name=${item.enterpriseName}-学生管理`)}>学生管理</span>
                    </React.RenderButton>
                </div>
            }
        },
    ];
    render() {
        const { dataSource, pageSize, pageNum, total, isZhanKai, searchValue } = this.state;

        return (
            <div className={styles["virtual_organization_members"]}>
                <div className='Shared_bottom'>
                    <div className='filetypebox'>
                        <Input
                            placeholder="请输入虚拟机构名称"
                            style={{ width: 230 }}
                            prefix={iconType.magnifier}
                            value={searchValue}
                            onChange={(e) => {
                                this.setState({
                                    searchValue: e.target.value
                                })
                            }}
                        />
                        <React.RenderButton id="OBSMM-01-B03-b-btnSearch">
                        <Button className='resetbtn' onClick={() => this.onSearch()}>查 询</Button>
                        </React.RenderButton>
                        <span style={{ color: "#888", marginRight: 30 }}>
                            共{total}个虚拟机构数据
                        </span>
                    </div>
                    <div className='list_of_institutions'>
                        <Table
                            dataSource={dataSource}
                            columns={this.columns}
                            pagination={false}
                            rowKey="id"
                            scroll={{
                                y: true
                            }}
                            locale={{
                                emptyText: <img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                            }}
                            footer={() => {
                                return <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                    onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                    onChange={(p, n) => this.pageChange(p, n)}
                                />
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default index;