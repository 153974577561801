/*推送管理*/
import React, { Component } from 'react';
import {Form, Select, Button, Input, Table,Spin,Checkbox, Row, Col} from 'antd';
import './index.scss';
import {getSystemPushNewsList, getPlatformList, deletePushNews, systemAgainPushNews} from "../../../../api/zixun/index"
import RenderButton from "../../../../components/renderButton";
import {withRouter} from "react-router-dom";
const { Option } = Select;

function compareSortFunction(propeties) {
    let compareSort = (rowa, rowb) => sortFunction(rowa[propeties], rowb[propeties]);
    return compareSort;
}

function sortFunction(a, b) {
    if (!a) {
        a = "";
    }
    if (!b) {
        b = "";
    }
    if ((typeof a) !== "number") {
        return a.localeCompare(b);
    }
}

@withRouter
class PushManagement extends Component {
    state = {
        pagination : {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            current: 1,
        },
        columns: [
            {title: '新闻标题', dataIndex: 'newsTitle', key: 'newsTitle', align: 'center',ellipsis: true},
            {title: '推送对象', dataIndex: 'pushOrganName', key: 'pushOrganName', align: 'center',},
            {
                title: '推送时间', dataIndex: 'pushTime', key: 'pushTime', align: 'center',
                sorter: compareSortFunction("pushTime"),
                defaultSortOrder: 'descend',
            },
            {title: '推送人', dataIndex: 'pushUserName', key: 'pushUserName', align: 'center',},
            {
                title: '操作',  align: 'center',
                width: 220,
                render: (text, record) => {
                    return <p className='action vertical_j'>
                        {/*<span onClick={()=> this.showAgainReleaseConfirm(record)}>再次推送</span>*/}
                        {/*<span onClick={()=> this.lookNewsDetail(record)}>查看</span>*/}
                        {/*<span onClick={()=> this.showDeletePushNewsConfirm(1,record)}>删除</span>*/}
                        <RenderButton id='NC_01_B12_c_btnagain'>
                            <span onClick={()=> this.showAgainReleaseConfirm(record)}>再次推送</span>
                        </RenderButton>
                        <RenderButton id='NC_01_B12_c_btnlook'>
                            <span onClick={()=> this.lookNewsDetail(record)}>查看</span>
                        </RenderButton>
                        <RenderButton id='NC_01_B12_c_btndelete'>
                            <span onClick={()=> this.showDeletePushNewsConfirm(1,record)}>删除</span>
                        </RenderButton>
                    </p>
                }
            }
        ],
        dataSource: [],
        tableLoading: false, // 是否显示加载中
        selectedRowKeys: [],// 选择的标签
        disabled: false, // 是否可选择
        platformList :[],
        platform : '',
        newsTitle : '',
        newsNum : 0,
        isSelectAll : false,
    }

    componentDidMount() {
        let platformData = {
            queryType : 1,
            organizationId : ''
        }
        getPlatformList(platformData).then(res =>{
            if(res){
                this.setState({
                    platformList : res
                })
            }
        })

        this.systemPushNewsList()
    }

    lookNewsDetail = (record) =>{
        this.props.history.push(`/main/group-column/lookNews?${decodeURI(JSON.stringify({ newsId: record.newsId }))}`)
    }

    systemPushNewsList = () =>{
        let data = {
            platform : this.state.platform,
            newsTitle : this.state.newsTitle,
            pageNum: this.state.pagination.pageNum,
            pageSize: this.state.pagination.pageSize,
        }
        getSystemPushNewsList(data).then(res =>{
            if(res){
                let pagination = this.state.pagination
                pagination.total = res.total
                this.setState({
                    dataSource : res.data,
                    newsNum : res.total,
                    pagination : pagination,
                })
            }
        })
    }

    onSearchChange = (newsTitle) =>{
        let pagination = this.state.pagination
        pagination.pageNum = 1
        pagination.pageSize = 10
        pagination.total = 0
        this.setState({
            newsTitle : newsTitle,
            pagination: pagination
        }, () => this.systemPushNewsList())
    }

    changePlatform = (e) =>{
        let pagination = this.state.pagination
        pagination.pageNum = 1
        pagination.pageSize = 10
        pagination.total = 0
        this.setState({
            platform : e,
            pagination: pagination
        }, () => this.systemPushNewsList())
    }

    changePage = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({
            pagination
        }, () =>{
            let data = {
                platform : this.state.platform,
                newsTitle : this.state.newsTitle,
                pageNum: pageNumber,
                pageSize: pagination.pageSize,
            }
            getSystemPushNewsList(data).then(res =>{
                if(res){
                    pagination.total = res.total
                    this.setState({
                        dataSource : res.data,
                        newsNum : res.total,
                        pagination : pagination,
                    }, () =>{
                        let selectedRowKeys = this.state.selectedRowKeys
                        let isSelectAll = false
                        let arr = []
                        if(this.state.dataSource.length > 0){
                            this.state.dataSource.forEach(item =>{
                                let result = selectedRowKeys.find(e => e === item.pushId)
                                if(result){
                                    arr.push(item)
                                }
                            })
                        }
                        if(arr.length === this.state.dataSource.length){
                            isSelectAll = true
                        }
                        this.setState({
                            isSelectAll
                        })
                    })
                }
            })
        })
    }

    selectAll = e => { // 点击全选
        if (e.target.checked) {
            let selectedRowKeys = this.state.selectedRowKeys
            this.state.dataSource.forEach(item =>{
                let result = selectedRowKeys.find(e => e === item.pushId)
                if(!result){
                    selectedRowKeys.push(item.pushId)
                }
            })
            this.setState({
                selectedRowKeys,
                isSelectAll : true
            });
        } else {
            this.setState({
                selectedRowKeys: [],
                isSelectAll : false
            });
        }
    }

    multipleChoice = selectedRowKeys => { // 多选
        let isSelectAll = false
        let arr = []
        if(this.state.dataSource.length > 0){
            this.state.dataSource.forEach(item =>{
                let result = selectedRowKeys.find(e => e === item.pushId)
                if(result){
                    arr.push(item)
                }
            })
        }
        if(arr.length === this.state.dataSource.length){
            isSelectAll = true
        }
        this.setState({
            selectedRowKeys,
            isSelectAll
        });
    }

    showDeletePushNewsConfirm = (type,record) => { // 删除提示
        let pushIds = []
        if(type === 1){
            pushIds.push(record.pushId)
        }else {
            let selectedRowKeys = this.state.selectedRowKeys
            selectedRowKeys.forEach(item =>{
                pushIds.push(item)
            })
        }
        this.hint({
            'title': '温馨提示',
            'content': '您是否要删除所选数据？删除后不可找回',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '删除',
            'isCancel': true,
            'sure': () => {this.deletePushNews(pushIds)}
        })
    }

    deletePushNews = (pushIds) =>{
        let data = {
            deleteType : 1,
            pushIds : pushIds
        }
        deletePushNews(data).then(res =>{
            if(res && res > 0){
                this.mesSuccess("删除成功")
                let pagination = this.state.pagination
                pagination.pageNum = 1
                pagination.pageSize = 10
                pagination.total = 0
                this.setState({
                    pagination: pagination,
                    selectedRowKeys : [],
                    isSelectAll : false
                }, () => this.systemPushNewsList())
            }
        })
    }

    showAgainReleaseConfirm = (record) => { // 再次推送提示
        let content = `请确认是否再次推送${record.newsTitle}这篇新闻`
        this.hint({
            'title': '温馨提示',
            'content': content,
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': () => {this.againRelease(record)}
        })
    }

    againRelease = (record) => { // 再次推送
        let data = {
            id : record.pushId
        }
        systemAgainPushNews(data).then(res =>{
            if(res && res > 0){
                this.mesSuccess("推送成功")
                this.systemPushNewsList()
            }
        })
    }
    // 分页
    showSizeChange = (current, size) => {
        const data = this.state.pagination
        data.pageNum = 1
        data.pageSize = size
        this.setState({pagination: data}, () => {
            this.systemPushNewsList()
        })
    }

    render() {
        let {columns, dataSource, tableLoading, selectedRowKeys, pagination,pageNum,pageSize,total,} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.multipleChoice,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
          <div className='pushManagement'>
              <div className="filter_controll">
                  <div className="item_wapper" style={{marginBottom: '20px'}}>
                      <div>
                          <span style={{ marginRight: '20px' }}>请选择业务系统</span>
                          <Select placeholder='请选择业务系统' onChange={e => this.changePlatform(e)}
                          getPopupContainer={triggerNode => triggerNode.parentNode || document.body}>
                              <Option value={''}>全部业务系统</Option>
                              {
                                  this.state.platformList.map(p => <Option key={p.platform} value={p.platform}>{p.platformName}</Option>)
                              }
                          </Select>
                      </div>
                  </div>
              </div>
              <div className="in_school_content">
                  <div className='top'>
                      <div className="left">
                          <RenderButton id='NC_01_B12_c_btnnewpush'>
                              <Button type="primary" onClick={()=> this.props.history.push('/main/group-column/addPush')}>新增推送</Button>
                          </RenderButton>
                          {/*<Button type="primary" onClick={()=> this.props.history.push('/main/group-column/addPush')}>新增推送</Button>*/}
                          {/*<Button type="primary" disabled={!hasSelected} onClick={()=>this.showDeletePushNewsConfirm(2)} style={{marginLeft:'30px'}}>批量删除</Button>*/}
                      </div>
                      <div className="right">
                          <RenderButton id='NC_01_B12_c_btnConfirmrelease'>
                              <Input.Search className="lcl_left" onSearch={this.onSearchChange} placeholder='请输入新闻标题或推送对象' />
                          </RenderButton>
                          <span className='all_total'>共{this.state.newsNum}条新闻资讯</span>
                      </div>
                  </div>
                  <div className="bottomss">
                      {
                        tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                            <div className='all_wapper'>
                              <Table
                                  className='organ-table organ-table-live'
                                  columns={columns}
                                  dataSource={dataSource}
                                  rowSelection={rowSelection}
                                  pagination = {
                                      {
                                          pageNum: pagination.pageNum,
                                          pageSize:  pagination.pageSize,
                                          total: pagination.total,
                                          current:  pagination.pageNum,
                                          showSizeChanger: true,
                                          pageSizeOptions: ['10', '20', '30', '40'],
                                          onChange: this.changePage,
                                          onShowSizeChange: this.showSizeChange
                                      }
                                  }
                                  rowKey={(v) => v.pushId}
                                  locale={{
                                      emptyText: <>
                                          <div className="Empty-conet-zixun">
                                              <div className="img">
                                                  <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                                  <span>暂无数据</span>
                                              </div>
                                          </div>
                                      </>
                                  }}
                                 />
                                 <div className='check_box'>
                                     <Checkbox
                                         disabled={this.state.disabled}
                                         onChange={this.selectAll}
                                         checked={this.state.isSelectAll}
                                     >
                                         全选
                                     </Checkbox>
                                     <Button type="primary" disabled={!hasSelected} onClick={()=>this.showDeletePushNewsConfirm(2)}>批量删除</Button>
                                 </div>
                            </div>
                        )
                      }
                  </div>
              </div>
          </div>
        )
    }
}

PushManagement = Form.create()(PushManagement);
export default PushManagement;
