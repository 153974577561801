import { result } from './result'

export default [
    {
        name: '通用管理',
        path: "/main/Generalmanagement",
        component: result['Generalmanagement'],
        children: [{
            path: '/main/Generalmanagement',
            redirect: '/main/Generalmanagement/questionLibrary',
        }, {
            name: "常用问题库",
            path: '/main/Generalmanagement/questionLibrary',
            component: result['QuestionLibrary']
        }, {
            name: "常用模板库",
            path: '/main/Generalmanagement/templateLibrary',
            component: result['TemplateLibrary'],

        }, {
            name: "反馈消息列表",
            path: '/main/Generalmanagement/retroalimentacion',
            component: result['Retroalimentacion'],
        },
        {
            name: "反馈类型",
            path: '/main/Generalmanagement/retroalimentacionTipo',
            component: result['RetroalimentacionTipo'],
        },
        {
            name: "内容列表",
            path: '/main/Generalmanagement/contenidosList',
            component: result['ContenidosList'],
        },
        {
            name: "新增问题",
            path: '/main/Generalmanagement/addProblema',
            component: result['AddProblema'],
        },
        {
            name: "编辑问题",
            path: '/main/Generalmanagement/editProblema',
            component: result['EditProblema'],
        },
        {
            name: "问题详情",
            path: '/main/Generalmanagement/problemaDetail',
            component: result['ProblemaDetail'],
        },
        {
            name: "内容分类",
            path: '/main/Generalmanagement/contenidosClasificacion',
            component: result['ContenidosClasificacion'],
        },
        {
            name: "反馈详情",
            path: '/main/Generalmanagement/feedBackDetail',
            component: result['FeedBackDetail'],
        },
        ]
    },
]