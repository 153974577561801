import { post} from '@/until/axios_instans';

//平台全部的圈子列表01  
export function wholeCircleList(data) {
 //return post('/api/v1/circle/console/wholeCircleList', data);
 //return post('/api/v1/circle/platform/circleList', data);
  return post(window.apiPrefixCicle+"/api/v1/circle/platform/circleList", data);
}

//平台全部的圈子列表  
export function circleList(data) {
  // return post('/api/v1/circle/platform/circleList', data);
  return post(window.apiPrefixCicle+"/api/v1/circle/platform/circleList", data);
}

//封禁圈子  
export function banCircle(data) {
  // return post('/api/v1/circle/console/banCircle', data);
  return post(window.apiPrefixCicle+"/api/v1/circle/console/banCircle", data);
}

//解封圈子 
export function unsealCircle(data) {
  // return post('/api/v1/circle/console/unsealCircle', data);
  return post(window.apiPrefixCicle+"/api/v1/circle/console/unsealCircle", data);
}

//解散圈子
export function deleteCircle(data) {
  // return post('/api/v1/circle/deleteCircle', data);
  return post(window.apiPrefixCicle+"/api/v1/circle/deleteCircle", data);
}


//被封禁的圈子列表
export function closedCircle(data) {
  // return post('/api/v1/circle/platform/closedCircle', data);
  return post(window.apiPrefixCicle+"/api/v1/circle/platform/closedCircle", data);
}

//已审核的话题列表
export function hasAuditList(data) {
  // return post('/api/v1/topic/platform/hasAuditList', data);
  return post(window.apiPrefixCicle+"/api/v1/topic/platform/hasAuditList", data);
}

//待审核的话题列表
export function noAuditList() {
  // return post('/api/v1/topic/platform/noAuditList'); 
  return post(window.apiPrefixCicle+"/api/v1/topic/platform/noAuditList" );
}

//查看话题详情
export function showTopicDetail(data) {
  // return post('/api/v1/circle/console/showTopicDetail', data); 
  return post(window.apiPrefixCicle+"/api/v1/circle/console/showTopicDetail", data);
}

//话题审核通过
export function auditPass(data) {
  // return post('/api/v1/circle/console/auditPass', data); 
  return post(window.apiPrefixCicle+"/api/v1/circle/console/auditPass", data);
}
//话题审核拒绝
export function auditRefuse(data) {
  // return post('/api/v1/circle/console/auditRefuse', data); 
  return post(window.apiPrefixCicle+"/api/v1/circle/console/auditRefuse", data);
}

//机构列表
export function orgList() {
  // return post('/api/v1/circle/console/getCircleOrgList');
  return post(window.apiPrefixCicle+"/api/v1/circle/console/getCircleOrgList" );
  
}

//举报管理 start 带公用的就不用加api/cicle路径

//举报人员换一批
export function changeUser() {
  // return post('/api/v1/gongyong/report/changeUser');
  return post("/api/v1/gongyong/report/changeUser" );
}

//举报类型列表
export function reportTypeList() {
  // return post('/api/v1/gongyong/report/reportTypeList');
  return post("/api/v1/gongyong/report/reportTypeList" );
}

//举报记录列表
export function showReportList(data) {
  // return post('/api/v1/gongyong/report/showReportList');
  return post( window.GongyongapiPrefix+"/api/v1/gongyong/report/showReportList", data );
}

//mediaType: 评论：PL、话题：HT
//已处理举报列表
export function hasDealList(data) {
  // return post('/api/v1/gongyong/report/hasDealList',data);
  
  return post("/api/gongyong/api/v1/gongyong/report/hasDealList", data);
}

//mediaType: 评论：PL、话题：HT
//待处理举报列表
export function noDealList(data) {
  // return post('/api/v1/gongyong/report/noDealList',data);
  
  return post("/api/gongyong/api/v1/gongyong/report/noDealList", data);
}

//投诉举报
export function create() {
  // return post('/api/v1/gongyong/report/create');
  return post("/api/v1/gongyong/report/create" );
}

//投诉处理-有问题
export function dealwith() {
  // return post('/api/v1/gongyong/report/dealwith');
  return post("/api/v1/gongyong/report/dealwith" );
}

//投诉处理-没问题
export function dealNoReson(data) {
  // return post('/api/v1/gongyong/report/dealNoReson');
  return post(window.GongyongapiPrefix+"/api/v1/gongyong/report/dealNoReson",data );
}

//投诉处理-没问题
export function reportTypeUserList() {
  // return post('/api/v1/gongyong/report/reportTypeUserList');
  return post("/api/v1/gongyong/report/reportTypeUserList" );
}

//举报管理 end

//黑名单 灰名单 白名单 不用传参

//平台黑名单列表
export function blackList() {
  // return post('/api/v1/member/platform/blackList');
  return post(window.apiPrefixCicle+"/api/v1/member/platform/blackList" );
}

//平台灰名单 
export function GrayList() {
  // return post('/api/v1/member/platform/WhiteList');
  return post(window.apiPrefixCicle+"/api/v1/member/platform/GrayList" );
}

//平台白名单 已处理人员名单
export function WhiteList() {
  // return post('/api/v1/member/platform/GrayList');
  return post(window.apiPrefixCicle+"/api/v1/member/platform/WhiteList" );
}

//-----------------------
//灰名单的查看
export function showGrayUserComment(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/showGrayUserComment" , data);
}

//黑名单的查看
export function showBlackUserComment(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/showBlackUserComment", data );
}

//白名单的查看
export function showWhiteUserComment(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/showWhiteUserComment", data );
}

//黑名单移入灰名单
export function moveGrayList(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/moveGrayList", data );
}

//黑名单移出黑名单(加入白名单)
export function removeBlackList(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/removeBlackList", data );
}

//灰名单：移出灰名单(加入白名单)
export function removeGrayList(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/removeGrayList", data );
}

//灰名单：移入黑名单
export function moveBlackList(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/moveBlackList", data );
}

//白名单：移入灰名单
export function whiteMoveGrayList(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/whiteMoveGrayList" , data);
}

//白名单：移入黑名单
export function whiteMoveBlackList(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/whiteMoveBlackList" , data);
}

//话题举报管理
//话题详情(举报详情)
export function getTopicDetail(data) {
  return post(window.apiPrefixCicle+"/api/v1/topic/getTopicDetail" , data);
}

//删除举报话题
export function deleteReportTopic(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/deleteReportTopic 	" , data);
}

//将作者拉入黑名单
export function dragBlack(data) {
  return post(window.apiPrefixCicle+"/api/v1/member/platform/dragBlack" , data);
}


//评论举报管理
//删除举报评论 (待处理 )
export function deleteComment(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/deleteComment" , data);
}


//将评论者拉入黑名单 (待处理 )  
export function joinBlack(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/joinBlack" , data);
}


//查看已删除评论内容 (已处理  )
export function showCommentDeleted(data) {
  return post(window.apiPrefixCicle+"/api/v1/circle/console/showCommentDeleted" , data);
}

//补充
// 话题评论列表
export function topicCommentList(data) {
  return post(window.apiPrefixCicle+"/api/v1/comment/topicCommentList", data);
}

// 评论回复列表
export function replyCommentList(data) {
  return post(window.apiPrefixCicle+"/api/v1/comment/replyCommentList", data);
}





