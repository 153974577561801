import React, { useState } from 'react';
import { Select, Modal, Form, Radio } from "antd";
import './index.scss'
import MaxLengthInput from "../../../components/hhinput";
import { resourcechaptersave, resourcechapteroptionList } from "../../../api/shixun2/index";
import { message } from '../../../components/global_components/confirm'
const { Option } = Select;
const AddZhangJie = (props) => {
  const [__add_Shang_ji_list, set___add_Shang_ji_list] = useState([]);
  const formItemLayout_xxx = {
    labelCol: {
      xs: { span: 1 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 2 },
      sm: { span: 12 },
    },
  };
  const { getFieldDecorator } = props.form
  const formItemLayout_xxx_Ok = () => {
    props.form.validateFields(
      ["resourceChapterName", "resourceChapterLevel", "resourceChapterCode", "parentId","isReport"],
      async (err, values) => {
        console.log("aaaaaaa")
        const { resourceId, get_edit__leftLOOKdirList,resourceType} = props
        if (!err) {
          console.log("bbbbbbb")
          const _data = await resourcechaptersave({
            resourceId,
            resourceType,
            ...values
          })
          console.log("增加章节参数：",JSON.stringify(_data));
          if (_data?.resourceChapterId) {
            message({
              type: "success", content: '添加成功'
            })
          } else {
            message({
              type: "warning", content: '添加失败'
            })

          }
          get_edit__leftLOOKdirList()
          props.get_edit__leftLOOKdirList({}) //添加之右边展示左侧的[0]
          props.toog_addDIR_itemfn()
        }
      }
    );
  }
  const formItemLayout_xxx_Cancel = () => {
    props.toog_addDIR_itemfn()
  }
  const Equipmentadd_onChange = async (value) => {


    if (value === '1') {
      return props.form.setFieldsValue({ 'parentId': '0' })

    } else {
      props.form.setFieldsValue({ 'parentId': undefined })
    }
    const _data = await resourcechapteroptionList({
      resourceChapterLevel: value,
      resourceId: props.resourceId
    })
    set___add_Shang_ji_list(_data)

  }
  const categoryadd_onChange = () => {

  }
  return (
    <Modal
      title={
        <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
          添加章节目录
        </div>
      }
      visible={props.visible}
      onOk={formItemLayout_xxx_Ok}
      onCancel={formItemLayout_xxx_Cancel}
      width={660}
      centered
      destroyOnClose
      closable={false}
      wrapClassName="Curriculumclas Curriculumclas_zindex"
    >
      <Form {...formItemLayout_xxx} style={{marginBottom: '20px'}}>
        <Form.Item label="目录层级">
          {getFieldDecorator("resourceChapterLevel", {
            rules: [{ required: true, message: "请选择目录层级" }],
          })(
            <Select
              dropdownClassName="Curriculumclas_select"
              placeholder="请选择目录层级"
              onChange={Equipmentadd_onChange}
            >
              <Option value="1">一级</Option>
              <Option value="2">二级</Option>
              <Option value="3">三级</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="上级目录">
          {getFieldDecorator("parentId", {
            rules: [{ required: true, message: "请选择上级目录" }],
          })(
            <Select
              dropdownClassName="Curriculumclas_select"
              disabled={props.form.getFieldValue('resourceChapterLevel') === '1'}
              placeholder="请选择上级目录"
              onChange={categoryadd_onChange}
            >
              {props.form.getFieldValue('resourceChapterLevel') === '1' && <Option value="0">无</Option>}


              {Array.isArray(__add_Shang_ji_list) && __add_Shang_ji_list.map(v => {
                return <Option value={v.resourceChapterId}>{v.resourceChapterName}</Option>

              })}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="目录名称">
          {getFieldDecorator("resourceChapterName", {
            rules: [{ required: true, message: "请输入目录名称" }],
          })(
            <MaxLengthInput
              placeholder="请输入目录名称"
              style={{ height: 40, paddingRight: 60 }}
              maxLength={50}
            ></MaxLengthInput>
          )}
        </Form.Item>
        <Form.Item label="目录代码">
          {getFieldDecorator("resourceChapterCode", {
            rules: [{ required: true, message: "请输入目录代码" }],
          })(
            <MaxLengthInput
              placeholder="请输入目录代码"
              style={{ height: 40, paddingRight: 60 }}
              maxLength={100}
            ></MaxLengthInput>
          )}
        </Form.Item>

        <Form.Item label="实训报告">
          {getFieldDecorator("isReport", {
            rules: [{ required: true, message: "请选择实训报告" }],
            initialValue: 0
          })(
              <Radio.Group //value={this.state.value}
              >
                <Radio value={0}>无</Radio>
                <Radio value={1}>有</Radio>
              </Radio.Group>
          )}
        </Form.Item>

        {/*<Form.Item label="资源类型">*/}
        {/*  {getFieldDecorator("resourceType", {*/}
        {/*    rules: [{ required: false, message: "请选择资源类型" }],*/}
        {/*  })(*/}
        {/*    <Select*/}
        {/*      dropdownClassName="Curriculumclas_select"*/}
        {/*      placeholder="请选择资源类型"*/}
        {/*      allowClear={true}*/}

        {/*    >*/}
        {/*      <Option value={1}>VR实操资源</Option>*/}
        {/*      <Option value={2}>仿真实训资源</Option>*/}
        {/*      <Option value={3}>仿真模型资源</Option>*/}
        {/*    </Select>*/}
        {/*  )}*/}
        {/*</Form.Item>*/}
      </Form>
    </Modal>

  );
}

export default Form.create()(AddZhangJie);
