import { post } from '../../until/axios_instans';

// 常用问题库列表
export function commonQuestionList(data) {
    return post('/api/wenjuan/api/v1/platform/commonQuestionList', data);
}

//保存编辑调研(创建和修改问题)
export function saveCommonQuestion(data) {
    return post('/api/wenjuan/api/v1/platform/saveCommonQuestion', data);
}

//删除常用问题库
export function deleteQuestion(data){
    return post('/api/wenjuan/api/v1/platform/deleteQuestion', data);
}

//编辑调研-数据回显
export function editQuestion(data){
    return post('/api/wenjuan/api/v1/platform/editQuestion', data);
}

