import React from "react";
import { Form, Row, Col, Radio, Select } from "antd";
import "./index.scss";
import { withRouter } from "react-router-dom";
const FormItem = Form.Item;
const { Option } = Select;
export default Form.create()(
  withRouter(
    class BasicProperty extends React.Component {
      state = {
        isUnfold: false,
        unfoldStyle: { transform: "rotate(-90deg)" },
        majorOrSpecial: "",
      };
      recoverType() {
        // console.log(this.props, "`````````````````");
      }
      // 展开/收起更多分类
      showOrHide() {
        if (this.state.isUnfold) {
          this.setState({
            unfoldStyle: { transform: "rotate(-90deg)" },
            isUnfold: false,
          });
        } else {
          this.setState({
            unfoldStyle: { transform: "rotate(0deg)" },
            isUnfold: true,
          });
        }
      }
      //显示专业或者专题
      majorOrSpecial(e) {
        if (e.target.value === 1) {
          this.setState({
            majorOrSpecial: "major",
          });
        } else if (e.target.value === 2) {
          this.setState({
            majorOrSpecial: "special",
          });
        } else {
          this.setState({
            majorOrSpecial: "",
          });
        }
      }
      render() {
        const { getFieldDecorator } = this.props.form;
        const { unfoldStyle, majorOrSpecial, isUnfold } = this.state;
        let list = [
          { id: 1, label: "热门学习方向分类" },
          { id: 2, label: "热门学习" },
          { id: 3, label: "热门学习" },
          { id: 4, label: "热门学习" },
          { id: 5, label: "热门学习" },
          { id: 6, label: "热门学习" },
          { id: 7, label: "热门学习" },
          { id: 8, label: "热门学习" },
          { id: 9, label: "热门学习" },
          { id: 10, label: "热门学习" },
          { id: 11, label: "热门学习" },
          { id: 12, label: "热门学习" },
          { id: 13, label: "热门学习" },
        ];
        return (
          <div className="classification_wrap">
            <Form
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              className="upd_resource_form"
            >
              <Row>
                <Col span={2}>
                  <span className="label">
                    <span className="red">*&nbsp;</span>资源分类
                  </span>
                </Col>
                <Col>
                  <FormItem className="class_tag">
                    {getFieldDecorator("author", {
                      initialValue: "",
                      rules: [],
                    })(
                      <Radio.Group
                        onChange={(e) => {
                          console.log(e);
                        }}
                      >
                        {list.map((item, index) => {
                          return (
                            <Radio.Button key={index} value={item.id}>
                              {item.label}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <p className="cut_class" onClick={this.showOrHide.bind(this)}>
                <span style={unfoldStyle}>▼</span>&nbsp;展开更多分类
              </p>
              <Row className={isUnfold ? "show" : "hide"}>
                <Col span={2}>
                  <span className="label">
                    <span className="red">&nbsp;</span>&nbsp;
                  </span>
                </Col>
                <Col className="more_class">
                  <Radio.Group
                    onChange={(e) => {
                      this.majorOrSpecial(e);
                    }}
                  >
                    <Radio value={1}>院校专业分类</Radio>
                    <Radio value={2}>专题资源</Radio>
                  </Radio.Group>
                  <FormItem>
                    {getFieldDecorator("aaa", {
                      initialValue: "",
                      rules: [],
                    })(
                      <div className="select_wrap">
                        {majorOrSpecial === "major" ? (
                          <>
                            <Select defaultValue="1">
                              <Option value="1">全部</Option>
                            </Select>
                            <Select defaultValue="1">
                              <Option value="1">全部</Option>
                            </Select>
                            <Select defaultValue="1">
                              <Option value="1">全部</Option>
                            </Select>
                          </>
                        ) : majorOrSpecial === "special" ? (
                          <>
                            <Select defaultValue="1">
                              <Option value="1">全部</Option>
                            </Select>
                            <Select defaultValue="1">
                              <Option value="1">全部</Option>
                            </Select>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }
    }
  )
);
