import React,{useState,useEffect} from 'react';
import { Button, Table, Tag } from 'antd';
import {applicationRecord} from '../../../../api/manage_mechanism_manage';
import styles from './index.module.scss';

const RecordModal = (props) => {
    const {onOk,item={}} = props;
    const [dataSource,setDataSource] = useState([]);

    useEffect(()=>{
        const fn = async () => {
            try {
                const res = await applicationRecord({appId:item.appId,enterpriseId:item.enterpriseId});
                setDataSource(res);
            } catch (error) {
                console.warn(error);
            }
        };
        if(item.appId && item.enterpriseId){
            fn();
        };
    },[item.appId,item.enterpriseId]);

    const columns = [
        { 
            title: '序 号', 
            dataIndex: 'id', 
            key: 'id', 
            align: 'center',
            render:(_,__,inx) => inx+1
        },
        { 
            title: '服务开始时间', 
            dataIndex: 'startDate', 
            key: 'startDate', 
            align: 'center', 
        },
        { 
            title: '服务结束时间', 
            dataIndex: 'endDate', 
            key: 'endDate', 
            align: 'center', 
        },
        { 
            title: '操作时间', 
            dataIndex: 'createTime', 
            key: 'createTime', 
            align: 'center', 
        },
        { 
            title: '操作人', 
            dataIndex: 'fullName', 
            key: 'fullName', 
            align: 'center',
        },
        { 
            title: '状态', 
            dataIndex: 'accessType', 
            key: 'accessType', 
            align: 'center', 
            render:text => {
                const obj = {
                    '1':{
                        color:'#27CDA2',
                        text:'试用'
                    },
                    '0':{
                        color:'#333',
                        text:'正式'
                    },
                }
                return <Tag color={obj[text]?.color || ''}>{obj[text]?.text}</Tag>
            }
        },
    ];

    return (
        <div className={styles["record_modal"]}>
            <div className={styles["modal_box"]}>
                <div className={styles["modal_header"]}>开通记录</div>
                <div className={styles["modal_content"]}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        rowKey="id"
                        scroll={{
                            y:370,
                        }}
                        locale={{
                            emptyText:<img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                        }}
                    />
                </div>
                <div className={styles["modal_footer"]}><Button type="primary" size="large" style={{width:90}} onClick={onOk}>确定</Button></div>
            </div>
        </div>
    )
}

export default RecordModal