import React, { Component } from 'react';
import { Select, Button, Input, Table,Tabs, Modal, message } from 'antd';
import { selectImportRecord } from '../../../../api/platform-school/index'
import { download } from '../../../../api/ali/index';
import './index.scss';
const { Option } = Select;
const { TabPane } = Tabs;
// function callback(key) {
//     console.log(key);
// }

// const Demo = () => (
//     <Tabs defaultActiveKey="1" onChange={callback}>
//         <TabPane tab="全部" key="1">
//         </TabPane>
//         <TabPane tab="未处理" key="2">
//         </TabPane>
//         <TabPane tab="已处理" key="3">
//         </TabPane>
//     </Tabs>
// );


export default class index extends Component {
    state = {
        dataSource: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        importUserName: '',
        enterpriseId: '',
        importType: '',
        importStatus: '',
        jumpType:null
    }


    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log("jsonparam",jsonparam)
        this.setState({
            enterpriseId:jsonparam.enterpriseId,
            jumpType:jsonparam.jumpType
        }, () =>{
            this.selectImportRecord()
        })
    }

    async selectImportRecord() {
        const param = {
            enterpriseId: this.state.enterpriseId,
            importStatus: this.state.importStatus,
            importType: this.state.importType,
            importUserName: this.state.importUserName,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }
        const res = await selectImportRecord(param);
        console.log('000203', res)
        this.setState({
            dataSource: res.data,
            total: res.total,
        })
    }

    //检索
    search = (e) => {
        this.setState({ importUserName: e }, () => this.selectImportRecord());
    }
    //检索
    callbacks = (e) => {
        console.log("e",e)
        this.setState({ importStatus: e }, () => this.selectImportRecord());
    }

    //导入查看详情 1.院校老师2.院校学生3.机构成员4.虚拟机构老师5.虚拟机构学生
    async toTeacherResultDetail(id){
        const {jumpType} = this.state
        console.log("id::",id)
        let transParam = {
            jumpType:this.state.jumpType,
            recordId: id,
            enterpriseId:this.state.enterpriseId
        }
        let param = JSON.stringify(transParam);
        if (jumpType === 1){
            this.props.history.push(`/main/platform-business/teacher-result?${encodeURI(param)}`)
        } else if (jumpType === 2){
            this.props.history.push(`/main/platform-business/student-result?${encodeURI(param)}`)
        }else if (jumpType === 3){
            this.props.history.push(`/main/platform-business/vir-mechanismresults?${encodeURI(param)}`)
        }else if (jumpType === 4){
            this.props.history.push(`/main/platform-business/vir-teacherresult?${encodeURI(param)}`)
        }else if (jumpType === 5){
            this.props.history.push(`/main/platform-business/vir-studentresult?${encodeURI(param)}`)
        }
    }
    //下载源文件
    async downloadFile(fileName, fileId){
        download(fileId, fileName)
    }


    render() {
        const { dataSource,total } = this.state
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '导入文件名称', dataIndex: 'fileName',key:'fileName', },
            {title: '导入时间', dataIndex: 'createTime',key:'createTime', },
            {title: '导入人', dataIndex: 'importUserName',key:'importUserName', },
            {title: '总条数', dataIndex: 'totalCount',key:'totalCount', },
            {title: '成功条数', dataIndex: 'successCount', key:'successCount',},
            {title: '状态', align: 'center', render: (text, item, index ) =>  <span className='serial'>{item.importStatus === "0" ? '未处理' : item.importStatus === "1" ? '已处理' : ''}</span>,},
            {
                title: '操作',
                key: 'operate',
                align:'center',
                fixed:'right',
                width:'200',
                render:(text, item, index)=><div className="opspan">
                    <div className="main">
                        <span className="but" onClick={() => this.toTeacherResultDetail(item.id)}>进入详情</span>
                        <span className="but" onClick={() => this.downloadFile(item.fileName,item.fileId)}>下载源文件</span>
                    </div>
                </div>},
        ]
        return (
            <div className='managestu_box'>
                <div className="twolist">
                    <div className="toplist">
                        <div className="leftlist">
                            <Tabs defaultActiveKey={null} onChange={(e) => {
                                this.callbacks(e);
                            }}>
                                <TabPane tab="全部" key={null}>
                                </TabPane>
                                <TabPane tab="未处理" key={0}>
                                </TabPane>
                                <TabPane tab="已处理" key={1}>
                                </TabPane>
                            </Tabs>
                        </div>
                        <div className="right_list">
                            <span className="number">共{total}条记录</span>
                            <Input.Search className="lcl_left" onSearch={this.search} placeholder='请输入导入人姓名' />
                        </div>
                    </div>
                    <div className="tablelist">
                        <Table columns={columns} dataSource={dataSource}/>,
                    </div>
                </div>

            </div>
        )
    }
}
