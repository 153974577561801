// 同步教管系统
import React from 'react';
import {Form, Radio, Select, Button, DatePicker, Input, Table, Modal, Tag, Col,Row } from 'antd';
import moment from "moment";

class ConsolextMoadl extends React.Component {
    state = {

    };


    render() {
        const {getFieldDecorator} = this.props.form;
        let {enterpriseInfo} = this.props;
        console.log('enterpriseInfo', enterpriseInfo)
        return (
            <Form>
                <Form.Item className='tip-box'>
                    如果要更改教育管理系统开通周期，请在详情页面重新选择
                </Form.Item>
                <div className='time-range'>
                    <Form.Item className='is-data_wapper'>
                        <Row>
                            <Col span={6}><span><span className='red'></span>开始日期</span></Col>
                            <Col span={16}>
                                {
                                    getFieldDecorator('eduSysDate', {
                                        initialValue: enterpriseInfo.eduSysDate ? moment(enterpriseInfo.eduSysDate, 'YYYY-MM-DD') : null,

                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item className='is-data_wapper'>
                        <Row>
                            <Col span={6}><span><span className='red'></span>结束日期</span></Col>
                            <Col span={16}>
                                {
                                    getFieldDecorator('eduSysEndDate', {
                                        initialValue: enterpriseInfo.eduSysEndDate ? moment(enterpriseInfo.eduSysEndDate, 'YYYY-MM-DD') : null,

                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Col>

                        </Row>
                    </Form.Item>
                </div>
                <div className='tip_bottom'>
                    点击确定后，机构基本信息和教育管理系统开通周期将会同步到平台接入的教育管理系统中，您是否确认此操作!
                </div>
            </Form>
        );
    }
}

ConsolextMoadl = Form.create()(ConsolextMoadl);
export default ConsolextMoadl;
