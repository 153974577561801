import { post } from '../../until/axios_instans';

//首页课程管理
//板块课程列表
export function moduleCourseList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/moduleCourseList', data);
}
//板块课程排序
export function moduleCourseSort(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/moduleCourseSort', data);
}
//添加首页课程
export function addCourse(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/addCourse', data);
}
//添加首页课程列表
export function addCourseList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/addCourseList', data);
}
//移除首页课程展示
export function deleteHomePageCourse(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/deleteHomePageCourse', data);
}
//课程分类列表
export function courseCategoryList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/courseCategoryList', data);
}
//首页课程列表
export function homePageCourseList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/homePageCourseList', data);
}
//首页板块列表
export function homePageModuleList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvechomepagecourse/homePageModuleList', data);
}
// 课程分类一级分类下拉列表
export function firstSelect(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/firstSelect', data);
}
// 课程分类二级分类下拉列表
export function secondSelect(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/secondSelect', data);
}

//配置首页版块
//首页板块下拉列表
export function selectList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/selectList', data);
}
//首页板块保存
export function save(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/save', data);
}
//首页板块信息
export function getInfoById(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/getInfoById', data);
}
//首页板块修改
export function updateById(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/updateById', data);
}
//首页板块列表
export function moduleList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/moduleList', data);
}
//首页板块删除
export function deleteModule(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/deleteModule', data);
}
//首页板块排序
export function moduleSort(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/moduleSort', data);
}
//首页板块隐藏/显示板块
export function hideModule(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecmodule/hideModule', data);
}


//课程分类
//课程分类一二级分类保存
export function classifySave(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/save', data);
}
//课程分类一二级分类信息
export function classifyGetInfoById(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/getInfoById', data);
}
//课程分类一二级分类修改
export function classifyUpdate(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/update', data);
}
//课程分类一二级分类删除
export function classifyDelete(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/delete', data);
}
//课程分类一级分类下拉列表
export function classifyFirstSelect(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/firstSelect', data);
}
//课程分类一级分类列表
export function classifyFirstList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/firstList', data);
}
//课程分类二级分类下拉列表
export function classifySecondSelect(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/secondSelect', data);
}
//课程分类二级分类列表
export function classifySecondList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/secondList', data);
}
//课程分类二级分类转移
export function classifyTransfer(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cveccoursecategory/transfer', data);
}


//发现页课程首页列表
export function managerPageList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/managerPageList', data);
}
//发现页课程添加前列表列表
export function courseList(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/courseList', data);
}

//添加发现页课程
export function addDiscoverCourse(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/add', data);
}

//调整发现页课程分类
export function updateCategory(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/updateCategory', data);
}

//发现页课程首页设置
export function showSet(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/showSet', data);
}

//发现页课程取消首页设置
export function showCancel(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/showCancel', data);
}


//发现页课程置顶设置
export function setTop(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/setTop', data);
}


//发现页app下架
export function delById(data) {
  return post(window.apiPrefixPlatformApp +'/v1/cvecdiscovercourse/delById', data);
}




