import React, { Component } from 'react';
import { Button, Divider, Table, Switch, Form, Modal, Input } from 'antd';
import { getTypePage, addTypeInfo, updateTypeName, deleteType, updateStatus } from '../../../api/helpCenter/index';
import './index.scss';
export default class index extends Component {
    state = {
        visible: false,
        titleName: '',
        pageNum: 1,
        pageSize: 10,
        total: 0,
        data: []
    }
    componentDidMount() {
        this.getTypePage();
    }
    getTypePage = () => {
        getTypePage({
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }).then(res => {
            if (res) {
                this.setState({
                    data: res.data,
                    total: res.total
                })
            }
        })
    }
    // 删除
    remove = (record) => {
        this.hint({
            'title': '温馨提示',
            'content': '您确定要删除该内容吗？删除后数据将无法恢复。',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': () => {
                deleteType({ id: record.id }).then(res => {
                    if (res === 'success') {
                        this.mesSuccess('删除成功');
                        this.getTypePage();
                    } else {
                        this.mesWarning('删除失败');
                    }
                })
            }
        })
    }
    //确定
    onOk = () => {
        this.contenidosAddAndEdit.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.titleName === '新增问题分类') {
                    addTypeInfo({ ...values }).then(res => {
                        if (res === 'success') {
                            this.mesSuccess('保存成功');
                            this.getTypePage();
                            this.setState({
                                visible: false
                            })
                        } else {
                            this.mesWarning('保存失败');
                        }
                    })
                } else {
                    updateTypeName({
                        status: this.state.status,
                        id: this.state.id,
                        ...values
                    }).then(res => {
                        if (res === 'success') {
                            this.mesSuccess('保存成功');
                            this.getTypePage();
                            this.setState({
                                visible: false
                            })
                        } else {
                            this.mesWarning('保存失败');
                        }
                    })
                }
            }
        });
    }
    //编辑
    edit = (record) => {
        this.setState({
            visible: true,
            titleName: '编辑问题分类',
            status: record.status,
            id: record.id
        })
        setTimeout(() => {
            this.contenidosAddAndEdit && this.contenidosAddAndEdit.setFieldsValue({
                typeName: record.typeName
            })
        })

    }
    render() {
        const columns = [
            {
                title: '编号',
                dataIndex: 'id',
                key: 'id',
                align: 'center'
            },
            {
                title: '问题分类',
                dataIndex: 'typeName',
                key: 'typeName',
                align: 'center'
            },
            {
                title: '内容数量',
                dataIndex: 'count',
                key: 'count',
                align: 'center'
            },
            {
                title: '创建人',
                dataIndex: 'createUsername',
                key: 'createUsername',
                align: 'center'
            },
            {
                title: '展示状态',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text) => <span>{text === 1 ? '启用' : '禁用'}</span>
            },
            {
                title: '展示状态',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text, record) => <Switch
                    onChange={(e) => {
                        updateStatus({ id: record.id, status: e ? 1 : 0 }).then(res => {
                            if (res === 'success') {
                                this.mesSuccess('更改成功');
                                this.getTypePage();
                            } else {
                                this.mesWarning('更改失败');
                            }
                        })
                    }}
                    checkedChildren="开"
                    unCheckedChildren="关"
                    defaultChecked={text === 1 ? true : false}
                />
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (red, record) => {
                    return <div className="contenidosList_action">
                        <span onClick={() => this.edit(record)}>编辑</span>
                        <Divider type="vertical" />
                        <span onClick={() => this.remove(record)}>删除</span>
                    </div>
                }
            },
        ];
        return (
            <div className="contenidos_clasificacion">
                <div className='contenidos_clasificacion_top'>
                    <span><Button type="primary" onClick={() => this.setState({ visible: true, titleName: "新增问题分类" })}>新增问题分类</Button></span>
                    <span>共有{this.state.total}条分类</span>
                </div>

                <div className="q_list">
                    <div className="q_list_show">
                        <Table
                            className="clasificacion_table"
                            dataSource={this.state.data}
                            columns={columns}
                            pagination={
                                {
                                    showSizeChanger: true,
                                    onShowSizeChange: (pageNum, pageSize) => {
                                        this.setState({
                                            pageNum: pageNum,
                                            pageSize: pageSize
                                        }, () => {
                                            this.getTypePage();
                                        })
                                    },
                                    onChange: (pageNum, pageSize) => {
                                        this.setState({
                                            pageNum: pageNum,
                                            pageSize: pageSize
                                        }, () => {
                                            this.getTypePage();
                                        })
                                    },
                                    total: this.state.total
                                }
                            }
                        />
                    </div>
                </div>
                {/* //新增编辑 */}
                <Modal
                    destroyOnClose={true}
                    closable={false}
                    className="contenidos_clasificacion_Modal"
                    width={660}
                    title={this.state.titleName}
                    visible={this.state.visible}
                    footer={
                        <div className='contenidos_clasificacion_btn'>
                            <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                            <Button type='primary' onClick={() => this.onOk()}>确定</Button>
                        </div>
                    }
                >
                    <ContenidosAddAndEdit ref={ref => this.contenidosAddAndEdit = ref} />
                </Modal>
            </div>
        )
    }
}


//新增编辑
class ContenidosAddAndEdit extends Component {
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <Form className="contenidos_clasificacion_form">
                <Form.Item label="问题分类" style={{ display: 'flex' }}>
                    {getFieldDecorator('typeName', {
                        rules: [
                            {
                                required: false,
                                message: '',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ],
                    })(<Input
                        placeholder='请输入类型名称'
                        maxLength={8}
                        style={{ width: '330px' }}
                        suffix={<span>{getFieldValue('typeName')?.length || 0}/8</span>}
                    />)}
                </Form.Item>
            </Form>
        )
    }
}
ContenidosAddAndEdit = Form.create()(ContenidosAddAndEdit);