import React, { Component } from 'react'
import './index.scss';
import { Form, Select, Input, Radio, Row, Col, Upload, Button, DatePicker, Tag , Modal} from 'antd';
import { getProvinceSel, getCitySel, insertVirtualOrg, globalSel ,updateVirtualOrg,virtualOrgDetail} from '@/api/platform-business/index.js';
import { LinkedinOutlined, DeleteOutlined} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import UploadImg from '../../../../components/enterprise_upload_img/index.js';
import setName from 'classnames';
import AddLable from "../../../../components/addLable";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const { CheckableTag } = Tag;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
};

class index extends Component {
    state = {
        selectedTags: ['Books'], // 我的标签选择数据
        isTab: false, // 添加机构弹层
        deletTag: false, // 标签是否可编辑
        imageUrl: '',
        loading: false,
        fangshiSel: [],
        cengciSel: [],
        yuanxiaoSel: [],
        bumenSel: [],
        provinceSel: [],
        citySel: [],
        districtSel: [],
        ruzhuRad: [],
        tagsData:[],
        fanweiRad: [],
        provinceCode: '',
        cityCode: '',
        provinceName: null,
        cityName: null,
        areaName: null,
        labelName:'',
        oftenFlag: true,
        yxmc: 0,
        yxjc: 0,
        ywmc: 0,
        ywjc: 0,
        xx: 0,
        xxdz: 0,
        lxr: 0,
        lxsj: 0,
        yxjj: 0
    }
    componentDidMount() {
        this.getProvinceSel();
        this.init();          //初始化
    }
    //信息回显
    init = () => {
        let enterpriseId = sessionStorage['enterpriseId'];
        if (enterpriseId) {
            virtualOrgDetail({ enterpriseId }).then(res => {
                console.log(res)
                if (res) {
                    this.setState({
                        tagsData: res.labelList,
                        isOpenDomain: res.isOpenDomain,
                    })
                    this.setState({
                        yxmc: res.enterpriseName && res.enterpriseName.length,
                        yxjc: res.shorterForm && res.shorterForm.length,
                        ywmc: res.enName && res.enName.length,
                        ywjc: res.enShorterForm && res.enShorterForm.length,
                        yxjj: res.simpleDesc && res.simpleDesc.length
                    })
                    this.props.form.setFieldsValue({
                        settledDate: res.settledDate ? moment(res.settledDate, 'YYYY-MM-DD') : null,
                        settledEndDate:res.settledEndDate ? moment(res.settledEndDate, 'YYYY-MM-DD') : null,
                        enterpriseName:res.enterpriseName,
                        enterpriseCode:res.enterpriseCode,
                        manageRange: res.manageRange ? res.manageRange.split(',') : undefined,
                        enterpriseDomain:res.enterpriseDomain,
                        isOpenDomain:res.isOpenDomain,
                        shorterForm:res.shorterForm,
                        simpleDesc:res.simpleDesc,
                        enterpriseLogo: res.enterpriseLogo
                    });
                }
            })
        }
    }
    //所属范围下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res.unshift({provinceCode: '0', name: '全国'})
        res && this.setState({ provinceSel: res });
    }
    //上传图片
    onChange = async info => {
        this.setState({ loading: true });
        console.log('info', info)
        try {
            let key = Math.floor(Math.random()).toString(8) + new Date().getTime()
            let result = await this.client.put(key, info.file.originFileObj);
            if (result) {
                console.log('result', result)
                let { url, name } = result;
                this.props.form.setFieldsValue({
                    enterpriseLogo: name
                })
                this.setState({
                    imageUrl: url,
                    loading: false
                });
            }
        } catch (e) {
            console.log(e);
        }
    };
    addLableList = () => {
        let { tagsData } = this.state;
        this.AddLableForm.props.form.validateFields((err, values) => {
            if (!err) {
                tagsData.push({id: '', labelName: values.shorterForm})
            }
            this.setState({
                isTab:false
            })
        })
    }
    deleteLabel = (tag) => {
        let { tagsData } = this.state;
        const nextSelectedTags = tagsData.filter(t => t.labelName !== tag.labelName);
        this.setState({ tagsData: nextSelectedTags });
    }
    //保存
    save = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("表单数据：：：",values);
                let { oftenFlag, tagsData } = this.state;
                if (values.settledDate.isAfter(values.settledEndDate)) {
                    return this.mesWarning('开始日期不能小于结束日期')
                }
                let type = sessionStorage['school_type'];
                if (type === 'add') {                               //add代表新增院校
                    if (oftenFlag) {
                        this.setState({ oftenFlag: false });
                        insertVirtualOrg(
                            {
                                ...values,
                                labels:tagsData.map(e => e.labelName)
                            }
                        ).then(res => {
                            if (res) {
                                this.mesSuccess('保存成功');
                                this.props.history.push('/main/platform-business/enterprise_settlement');
                            }
                        })
                        setTimeout(() => this.setState({ oftenFlag: true }), 2500)
                    }
                } else if (type === 'edit') {                           //edit代表编辑院校
                    updateVirtualOrg({
                        ...values,
                        id: sessionStorage['enterpriseId'],
                        labels:tagsData
                    }).then(res => {
                        if (res) {
                            this.mesSuccess('保存成功');
                            this.props.history.push('/main/platform-business/enterprise_settlement');
                        }
                    })
                }
            }
        })
    }
    //
    selectChange = (value) => {
        console.log("所属范围选择：",value);
    }

    setLen = val => {
        this.setState(val);
    }

    saveLabels = () => {
        const { tagsData , labelName } = this.state;
        let fieldValue = this.props.form.getFieldValue('label');
        if (labelName){
            tagsData.push(labelName);
        }
        this.setState({
            isTab:false
        });
        console.log("标签值：：：",labelName);
    }
    // 我的标签--选择
    handleChange(tag, checked) {
        console.log("check",checked)
        console.log("check11",tag)
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        this.setState({ selectedTags: nextSelectedTags });
    }

    addLabelList = () => {
        console.log("11111111111111111111",this.props)
        const { tagsData } = this.state;
        this.AddLableForm.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("values",values)
                tagsData.push(values.shorterForm)
            }
            this.setState({
                isTab:false
            })
            console.log("tagsData",tagsData)
        })
    }

    updateLable = () => {
        if (this.state.deletTag === true){
            this.setState({
                deletTag: false
            })
        } else {
            this.setState({
                deletTag: true
            })
        }
    }
    handleImgChange= (fileId, mediaType) =>{
        this.props.form.setFieldsValue({"enterpriseLogo":fileId})
        this.setState({
            fileId,
            mediaType
        })
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let { imageUrl, loading, yuanxiaoSel, fangshiSel, cengciSel, isOpenDomain, provinceSel, citySel, isTab,tagsData } = this.state;
        const props = {
            //aspect:160/88,
            resize: true, //裁剪是否可以调整大小
            resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
            modalTitle: "上传图片", //弹窗标题
            modalWidth: 600, //弹窗宽度
            rotate: true,
            width: 166,  //裁剪宽度
            height: 88, //裁剪高度
        };
        const addDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/add_icon.png')} alt="" />
            </div>
            <span>上传logo</span>
        </div>
        const lodingDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/loading_icon.png')} alt="" />
            </div>
            <span>请稍后...</span>
        </div>
        let { yxmc, yxjc, ywmc, ywjc, xx, xxdz, lxr, lxsj, yxjj, selectedTags, deletTag} = this.state;
        return (
            <div className='add_school_box'>
                <div className="title">
                    创建虚拟机构
                </div>
                <Form>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>虚拟机构名称</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseName', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '虚拟机构名称不能为空'
                                            }
                                        ]
                                    })(
                                        <Input placeholder='请输入虚拟机构名称'
                                            onChange={e => this.setLen({ yxmc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxmc === 0 ? 'zero' : '')}>{yxmc}</span>/50</span>} maxLength={50} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>虚拟机构识别码</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseCode', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '虚拟机构识别码不能为空'
                                            }
                                        ]
                                    })(
                                        <Input
                                            placeholder='请输入虚拟机构识别码标识'
                                            onChange={e => this.setLen({ yxjc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxjc === 0 ? 'zero' : '')}>{yxjc}</span>/15</span>}
                                            maxLength={15} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>虚拟机构简称</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('shorterForm', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '虚拟机构简称不能为空'
                                            }
                                        ]
                                    })(
                                        <Input
                                            placeholder='请输入虚拟机构简称'
                                            onChange={e => this.setLen({ yxjc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxjc === 0 ? 'zero' : '')}>{yxjc}</span>/20</span>}
                                            maxLength={20} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>虚拟机构logo</span></Col>
                            <Col span={3} style={{ marginRight: '30px' }}>
                                {
                                    getFieldDecorator('enterpriseLogo', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'logo不能为空'
                                            }
                                        ]
                                    })
                                    (

                                        <UploadImg
                                            handleImgChange={this.handleImgChange}
                                            html='上传封面'/>
                                    )}
                            </Col>
                            <Col span={5}>
                                <p className='tishi'>支持jpg/png/jpeg格式，</p>
                                <p className='tishi'>建议最佳尺寸160x88px，不超过5M</p>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>所属范围</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('manageRange', {
                                        initialValue: undefined,
                                    })(
                                        <Select  mode="multiple"
                                                 placeholder='请选择所属范围'>
                                            {
                                                provinceSel && provinceSel.map(v => {
                                                    return <Option value={v.provinceCode} key={v.provinceCode}>{v.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>虚拟机构简介</span></Col>
                            <Col style={{ position: 'relative' }} span={10}>
                                <span className='textarea_len'>
                                    <span className={setName('current_len', yxjj === 0 ? 'zero' : '')}>{yxjj}</span>/200
                                </span>
                                {
                                    getFieldDecorator('simpleDesc', {
                                        initialValue: ''
                                    })(
                                        <TextArea
                                            placeholder='请输入虚拟机构简介'
                                            onChange={e => this.setLen({ yxjj: e.target.value.length })}
                                            maxLength={200}
                                            style={{ resize: 'none' }} rows={5} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>二级域名</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('isOpenDomain', {
                                        initialValue: ''
                                    })(
                                        <Radio.Group style={{ width: '100%' }} onChange={e => this.setState({ isOpenDomain: e.target.value })}>
                                            <Radio value={0}>不启动</Radio>
                                            <Radio value={1}>启动</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    {isOpenDomain === 1?<Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'/></span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseDomain', {
                                        initialValue: '',
                                    })(
                                      <Input addonBefore="https://" addonAfter=".cveducloud.com"/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>: null}
                    {/*<Form.Item>*/}
                    {/*    <Row>*/}
                    {/*        <Col span={2}><span><span className='red'>*</span>到期日期</span></Col>*/}
                    {/*        <Col span={10}>*/}
                    {/*            {*/}
                    {/*                getFieldDecorator('settledEndDate', {*/}
                    {/*                    initialValue: '',*/}
                    {/*                    rules: [*/}
                    {/*                        {*/}
                    {/*                            required: true,*/}
                    {/*                            message: '请选择到期日期'*/}
                    {/*                        }*/}
                    {/*                    ]*/}

                    {/*                })(*/}
                    {/*                    <DatePicker placeholder='请选择到期日期' style={{width: '100%'}} />*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item>*/}
                    {/*    <Row>*/}
                    {/*        <Col span={2}><span><span className='red'>*</span>开通信息</span></Col>*/}
                    {/*        <Col span={8}>*/}
                    {/*            {*/}
                    {/*                getFieldDecorator('ktxx', {*/}
                    {/*                    initialValue: ''*/}

                    {/*                })(*/}
                    {/*                    <RangePicker/>*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Form.Item>*/}
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>开通信息</span></Col>
                            <Col span={5}>
                                <Form.Item>
                                    <Row className='is-data_wapper'>
                                        <Col span={5}><span><span className='red'>*</span>开始日期</span></Col>
                                        <Col span={17}>
                                            {
                                                getFieldDecorator('settledDate', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择到期日期'
                                                        }
                                                    ]
                                                })(
                                                  <DatePicker />
                                                )
                                            }
                                        </Col>

                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item>
                                    <Row className='is-data_wapper'>
                                        <Col span={5}><span><span className='red'>*</span>结束日期</span></Col>
                                        <Col span={17}>
                                            {
                                                getFieldDecorator('settledEndDate', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择到期日期'
                                                        }
                                                    ]
                                                })(
                                                  <DatePicker />
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                    <div className="mini_title title">
                        <span>我的标签</span>
                        <span className='tabButton' onClick={() => this.updateLable()}>编辑</span>
                        <span className='tabButton' onClick={() => this.setState({isTab: true})}>添加标签</span>
                        <span className='tabButton tabTip'>提示：您最多添加4个标签</span>
                    </div>
                    <Form.Item>
                        {/*<Row>*/}
                        {/*    <Col span={12} className='tabItem'>*/}
                        {/*        {tagsData.map(tag => (*/}
                        {/*          <CheckableTag*/}
                        {/*            key={tag.id}*/}
                        {/*            checked={selectedTags.find(e => e === tag)}*/}
                        {/*            onChange={checked => this.handleChange(tag, checked)}*/}
                        {/*          >*/}
                        {/*              {tag.labelName? tag.labelName :tag}*/}
                        {/*          </CheckableTag>*/}
                        {/*        ))}*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col span={12} className='jigou_tab_wapper'>
                                {tagsData.map(tag => (
                                    <Tag color="#3E78ED" className='tag_item'
                                         key={tag.id}

                                    >
                                        {tag.labelName? tag.labelName :tag}
                                        {deletTag === true ? <DeleteOutlined className="tag_icon" onClick={() => this.deleteLabel(tag)}/> : null}
                                    </Tag>
                                ))}
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
                    {/*添加标签弹层*/}
                    <Modal
                        title='添加机构标签'
                        visible={isTab}
                        destroyOnClose={true}
                        closable={false}
                        className='configuration_modal'
                        footer={
                            <div className='modal_btn_j'>
                                <Button onClick={() => this.setState({isTab: false})}>取消</Button>
                                <Button onClick={this.addLableList}>确定</Button>
                            </div>
                        }
                    >
                        <AddLable wrappedComponentRef={form => {this.AddLableForm = form;}}/>
                    </Modal>
                <div className="fix_controll">
                    <Button className='add_btn_j' onClick={this.save} style={{ marginRight: '40px', width: '100px', height: '39px' }}>保存</Button>
                    <Button onClick={() => this.props.history.goBack()} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>
            </div>
        )
    }
}


index = Form.create()(index);
export default index;
