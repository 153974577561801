import React, { Component } from 'react'
export default class Audiopre extends Component {
    state = {
        rateList: [1.0, 1.25, 1.5, 2.0],
        playRate: 1.0,
        isPlay: false,
        isMuted: false,
        volume: 100,
        allTime: 0,
        currentTime: 0,
    };
    componentDidMount() {
        console.log(111)

        setTimeout(() => {
            this.audio.play();
            this.onloadAudio(this.canvas);

        })
    }
    formatSecond(time) {
        const second = Math.floor(time % 60);
        let minite = Math.floor(time / 60);
        return `${minite}:${second >= 10 ? second : `0${second}`}`;
    }

    onCanPlay = () => {

        this.setState({
            allTime: this.audio.duration,
        });
    };

    playAudio = () => {

        this.audio.play();
        this.setState({
            isPlay: true,
        });
    };

    pauseAudio = () => {

        this.audio.pause();
        this.setState({
            isPlay: false,
        });
    };

    onMuteAudio = () => {
        const { id } = this.props;
        const audio = document.getElementById(`audio${id}`);
        this.setState({
            isMuted: !audio.muted,
        });
        audio.muted = !audio.muted;
    };

    changeTime = (e) => {
        const { value } = e.target;
        this.setState({
            currentTime: value,
        });
        this.audio.currentTime = value;
        if (value === this.audio.duration) {
            this.setState({
                isPlay: false,
            });
        }
    };

    // 当前播放位置改变时执行
    onTimeUpdate = () => {


        this.setState({
            currentTime: this.audio.currentTime,
        });
        if (this.audio.currentTime === this.audio.duration) {
            this.setState({
                isPlay: false,
            });
        }
    };

    changeVolume = (e) => {
        const { value } = e.target;

        this.audio.volume = value / 100;

        this.setState({
            volume: value,
            isMuted: !value,
        });
    };

    // 倍速播放
    changePlayRate = (num) => {
        this.audio.playbackRate = num;
        this.setState({
            playRate: num,
        });
    };
    onloadAudio = (el) => {

        let that = this;
        el.width = that.audio_wrap.offsetWidth / 2;
        el.height = that.audio_wrap.offsetHeight / 1.5;
        let ctx = el.getContext('2d');
        let WIDTH = el.width;
        let HEIGHT = el.height;
        let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        let analyser = audioCtx.createAnalyser();
        analyser.fftSize = 512;
        let source = audioCtx.createMediaElementSource(that.audio);
        source.connect(analyser);
        analyser.connect(audioCtx.destination);
        let bufferLength = analyser.frequencyBinCount;
        let dataArray = new Uint8Array(bufferLength);
        let barWidth = WIDTH / bufferLength * 1.5;
        let barHeight;
        function renderFrame() {
            requestAnimationFrame(function () {

                ctx.clearRect(0, 0, WIDTH, HEIGHT);
                analyser.getByteFrequencyData(dataArray);
                for (let i = 0, x = 0; i < bufferLength; i++) {
                    barHeight = dataArray[i] / 3;
                    let r = barHeight + 25 * (i / bufferLength);
                    let g = 250 * (i / bufferLength);
                    let b = 50;
                    // ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
                    ctx.fillStyle = 'pink'
                    ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
                    x += barWidth + 2;
                }
                renderFrame();
            })
        }
        renderFrame();
    }

    render() {
        const {
            isPlay,
            isMuted,
            volume,
            allTime,
            currentTime,
            rateList,
            playRate,
        } = this.state
        return (
            <div ref={(el) => this.audio_wrap = el} className='audio_wrap' id='audio_wrap'>
                <canvas ref={(el) => this.canvas = el} width='100%' height='100%' className="canvas"> </canvas>
                <audio
                    ref={(el) => this.audio = el} crossOrigin='' id='audio' src={this.props.src}
                    className='audio'
                    src={this.props.src}
                    ref={(el) => this.audio = el}
                    preload={"auto"}
                    onCanPlay={this.onCanPlay}
                    onTimeUpdate={this.onTimeUpdate}
                >
                    <track src={this.props.src} kind="captions" />
                </audio>

                <div className="contrus">
                    <div className='con'>
                        {
                            <img onClick={() => isPlay ? this.pauseAudio() : this.playAudio()} src={isPlay ? require('@/assets/resource_preview/play.png') : require('@/assets/resource_preview/nextplay.png')}></img>
                        }

                        <div>
                            <span>
                                {this.formatSecond(currentTime) + "/" + this.formatSecond(allTime)}
                            </span>
                            <input
                                type="range"
                                step="0.01"
                                max={allTime}
                                value={currentTime}
                                onChange={this.changeTime}
                            />
                        </div>

                        <div onClick={this.onMuteAudio}>静音</div>

                        <div>
                            <span>音量调节：</span>
                            <input
                                type="range"
                                onChange={this.changeVolume}
                                value={isMuted ? 0 : volume}
                            />
                        </div>

                        {/* <div>
                            <span>倍速播放：</span>
                            {rateList &&
                                rateList.length > 0 &&
                                rateList.map((item) => (
                                    <button
                                        key={item}
                                        style={
                                            playRate === item
                                                ? {
                                                    border: "1px solid #188eff",
                                                    color: "#188eff",
                                                }
                                                : null
                                        }
                                        onClick={() => this.changePlayRate(item)}
                                    >
                                        {item}
                                    </button>
                                ))}
                        </div> */}
                        <div className="img_full" onClick={()=>{
                            console.log(this.props)
                            // this.props.set_full_flag(this.props.full_flag)
                        }}>
                           111
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
