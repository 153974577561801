/**
 *  获取 url 后面通过?传参的参数
 * @param {String} name
 */
function getQueryString(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const url = window.location.href
  const search = url.substring(url.lastIndexOf('?') + 1)
  const r = search.match(reg)
  if (r != null) return r[2]
  return null
}

function getSearch(str) {
  if (!str) {
    return null;
  }
  let param,
    jsonparam,
    codeParam;
  param = str.split("?")[1];
  codeParam = param.includes("%") ? decodeURI(param) : param;
  jsonparam = JSON.parse(codeParam);
  return jsonparam;
}

export default {
  getQueryString,
  getSearch
}