
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { SET_MODULEDfn, SET_SELECTfn } from '@store/OBS/uploadingmodule/action'
import { Button, Modal, Select, Radio ,Empty,ConfigProvider  } from 'antd';
import MyTable from '@/components/Table';
import BasicProperty from '../../components/BasicProperty'
import CoverGallery from '../../components/CoverGallery'
import ClassProperty from '../../components/ClassProperty'
import ClassProperty_jigou from '../../components/ClassProperty_jigou'
import Resource_class from '../../components/resource_calss'
const { Option } = Select;
const mapStateToProps = ({ UPLOADINGMODULE }) => {
    return {

        dataSource: UPLOADINGMODULE.dataSource,
        visible: UPLOADINGMODULE.visible
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showModule(data) {
            dispatch(SET_MODULEDfn(data))
        },
        selectChange(data) {
            dispatch(SET_SELECTfn(data))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(class Uploadingmodule extends React.Component {

    render() {
        const columns = [
            {
                title: '资源名称', dataIndex: 'name', key: 'name',
                render: () => {
                    return <div>资源名称名称，30个字不带格式名称，多余的截取…<span onClick={() => this.props.showModule('show')}>🐎编辑</span>
                        <span>重试</span>
                        <span>★</span>
                    </div>
                }
            },
            { title: '格式', dataIndex: '2121', key: '211212' },
            { title: '大小', dataIndex: '2121', key: '1212' },
            { title: '操作时间', dataIndex: '1212', key: '2121212' },
            {
                title: '操作', dataIndex: 'address', key: 'address',
                render: () => {
                    return <div>
                        <Select defaultValue="浏览设置" style={{ width: 120 }} onChange={() => this.props.selectChange()}>
                            <Option value="jack">试看1分钟</Option>
                            <Option value="lucy">试看2分钟</Option>
                            <Option value="Yiminghe">试看3分钟</Option>
                        </Select>
                        <Button>删除</Button>
                        <Button>继续</Button>
                        <Button>暂停</Button></div>


                }
            }
        ]
        const customizeRenderEmpty = () => (
            //这里面就是我们自己定义的空状态
            <div style={{ textAlign: 'center' ,minHeight:"200px",marginTop:"150px"}}>

              <div>
                  <div><img src={require('../../assets/img/logo.png')} alt=""/></div>
              暂无的文件
              </div>
            </div>
        )
        return (
            <div className='Uploadingmodule'>
                <div className="lab_left">
                   <i className='before_i'></i> &emsp;文件格式类型
                  </div>
                <div className="class_resource">
                    {[1, 2, 34, 5].map(((v, i) => {
                        return <div key={i} className="class_resource_item">
                            fdfd
                          </div>
                    }))}
                </div>
                <div className="lab_left">
                    文件列表
                  </div>
                <div className="lie_biao">
                    <div className="left">
                        <Button type='primary'>本地文件上传到网盘</Button>
                    </div>
                    <div className="right">
                        <div className="right_top">
                            提示
                         </div> <div className="right_bottom">
                            支持doc/docx、xls/xlsx、ppt/pptx、pdf、txt格式的文档，本地上传时文件大小不超过1GB
                         </div>
                         </div>

                    </div>
                    <div className="lab_left">
                        文件目录（10）
                  </div>
                    <div className="resouurce_table">
                    <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <MyTable

                            options={{
                                columns,
                                dataSource: this.props.dataSource,
                                pagination: false
                            }}
                        /></ConfigProvider>
                    </div>
                    <div className="lab_left">
                        基本属性
                  </div>
                    <BasicProperty></BasicProperty>
                    <ClassProperty></ClassProperty>
                    <Resource_class></Resource_class>
                    <ClassProperty_jigou></ClassProperty_jigou>
                    <div className="footer_upload" style={{ position: 'fixed', bottom: '0', display: "flex" }}>
                        <div className="left" style={{ textAlign: 'left' }}>
                            <Radio></Radio><span>同意</span><span>《资源上传规范》</span>
                        </div>
                        <div className="right" style={{ textAlign: 'right' }}>
                            <Button type='primary' > 保存</Button>
                            <Button type="primary" > 保存并继续上传</Button>
                            <Button >取消</Button>
                        </div>
                    </div>

                <Modal
                    title="Basic Modal"
                    visible={this.props.visible}
                    onOk={() => this.props.showModule('ok')}
                    onCancel={() => this.props.showModule('cancel')}
                >
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                </Modal>
                <CoverGallery></CoverGallery>
            </div>
        )
    }
})
