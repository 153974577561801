import React from 'react';
import NotFoundCom from '@/components/notFoundCom/index.js';
import './index.scss';
import img from '@/assets/img/img_four.png';

export default () => {
       return <div className='not_confour'>
              <NotFoundCom imgBoxClass='not_found_img' src={img}/>
       </div>
}
