import React from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Upload,
  Modal,
  Button,
  message,
  Spin,
} from "antd";
import "./index.scss";
import { withRouter } from "react-router-dom";
// import Editor from "@/components/Editor"; //富文本框组件
import CoverGallery from "@/components/CoverGallery"; //公共导航组件
const FormItem = Form.Item;
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default Form.create()(
  withRouter(
    class BasicProperty extends React.Component {
      state = {
        selectCover: false,
        acceptType: ".jpeg,.png,.gif,.jpg",
        imageUrl: "",
        fileId: "",
        fileStatus: "loading",
        loading: false,
      };
    
      //   上传封面
      beforefn = (file) => {
        const { acceptType } = this.state;
        const acceptTypeList = acceptType.split(",");
        const fileName = file.file.name;
        const suffix = fileName.substring(
          fileName.lastIndexOf("."),
          fileName.length
        );
        const fileTypeStatus = acceptTypeList.includes(suffix);
        if (!fileTypeStatus) {
          message.warning("不支持的文件格式");
          return;
        }
        const isLt2M = file.file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.warning("文件不能大于5M");
          return false;
        } else {
          this.setState({ loading: true }, () => {
            this.fileChange(file);
          });
        }
      };

      //   封面remove
      uploadRemove = (file) => {
        this.state.fileTitle = "";
        this.setState({
          result: {},
        });
      };
      //   封面change
      fileChange = (info) => {
        if (info) {
          let fileList = [...info.fileList];
          fileList = fileList.slice(-1);
          fileList = fileList.map((file) => {
            if (file.response) {
              file.url = file.response.url;
            }
            return file;
          });

          this.setState({ fileList });

          if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) => {
              this.setState({
                imageUrl,
                loading: false,
              });
            });
            if (info.file.response.code === 0) {
              const result = info.file.response.result;
              this.setState({
                result: result,
                fileTitle: "",
                fileId: result.id,
                fileStatus: "success",
              });
              message.success(`${info.file.name} 上传成功`);
            } else {
              this.setState({
                result: {},
                fileList: [],
                fileTitle: "",
              });
              message.warning(info.file.response.message);
            }
          } else if (info.file.status === "error") {
            this.setState({
              result: {},
              fileList: [],
              fileStatus: "error",
            });
            this.props.form.setFieldsValue({ fileObj: undefined });
            message.warning(`${info.file.name} 上传失败`);
          } else if (info.file.status === "uploading") {
            this.setState({
              fileStatus: "loading",
            });
          }
        }
      };
      render() {
        let { selectCover, imageUrl, fileId, loading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const props = {
          action: "/api/media/api/v1/media/addMedia",
          onChange: this.beforefn,
          onRemove: this.uploadRemove,
        };
        return (
          <Form
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 10 }}
            className="upd_resource_form"
          >
            <Row className='fen_mian'>
              <Col span={2}>
                <span className="">
                  <span className="red">*&nbsp;</span>资源封面
                </span>
              </Col>
              <Col>
                <FormItem className="upload_wrap">
                  {getFieldDecorator("fileId", {
                    initialValue: "",
                    rules: [{ required: true, message: "课程封面不能为空!" }],
                  })(
                    < >
                      <Upload
                        showUploadList={false}
                        name="file"
                        listType="picture-card"
                        className='fenmian_right'
                        {...props}
                        className={
                          fileId
                            ? "avatar-uploader avatar-uploader_l"
                            : "avatar-uploader"
                        }
                        // fileList={fileList}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: "100%",height:78 }}
                          />
                        ) : (
                          <div style={{ color: "#3E78ED" }}>
                            {loading ? (
                              <Spin />
                            ) : (
                              <>
                                <span style={{ fontSize: 24 }}>+</span>{" "}
                                <div style={{ color: "#3E78ED" }}>上传图片</div>
                              </>
                            )}
                          </div>
                        )}
                        {/* <div id="update-shade-l">
                          <img
                            alt=""
                            src={require("../../assets/img/logo.png")}
                          />
                          <p>更新封面</p>
                        </div> */}
                      </Upload>
                      <div className="cover_info_l">
                        <div>支持jpg/gif/png/jpeg格式，</div>
                        <div>建议最佳尺寸750x422px，不超过5M</div>
                        <span style={{'textDecorationLine': 'underline',color: '#3E78ED'}}
                          onClick={() => {
                            this.setState({
                              selectCover: true,
                            });
                          }}
                        >
                          从图库里选择封面
                        </span>
                      </div>
                    </>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={2}>
                <span>
                  <span className="red">*&nbsp;</span>资讯作者
                </span>
              </Col>
              <Col>
                <FormItem>
                  {getFieldDecorator("author", {
                    initialValue: "",
                    rules: [
                      {
                        max: 10,
                        message: "资讯作者不能超过10字",
                      },
                    ],
                  })(
                    <Input
                      type="text"
                      placeholder="请输入资讯作者"
                      maxLength={10}
                      suffix={<span></span>}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={2}>
                <span className="">
                  <span className="red">*&nbsp;</span>一句话介绍
                </span>
              </Col>
              <Col>
                <FormItem>
                  {getFieldDecorator("infoTitle", {
                    initialValue: "",
                    rules: [
                      { required: true, message: "一句话介绍不能为空!" },
                      {
                        max: 50,
                        message: "一句话介绍不能超过50字！",
                      },
                    ],
                  })(
                    <Input
                      type="text"
                      placeholder="不超过50个字，将会在列表页展示给用户"
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/^\s/, "");
                        let val = e.target.value.replace(/^\s/, "");
                        this.setState({
                          infoTitle: val,
                          infoTitleLength: val.length,
                        });
                      }}
                      maxLength={50}
                      suffix={<span></span>}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={2}>
                <span className="">
                  <span className="red"></span>适用对象
                </span>
              </Col>
              <Col>
                <FormItem>
                  {getFieldDecorator("suitableObject", {
                    initialValue: "",
                    rules: [
                      {
                        max: 30,
                        message: "不超过30字",
                      },
                    ],
                  })(
                    <Input
                      type="text"
                      placeholder="不超过30字"
                      maxLength={30}
                      suffix={<span></span>}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            {/* 封面图库弹窗start */}
            <Modal
              width="750px"
              visible={selectCover}
              title="请选择封面"
              maskClosable={false}
              keyboard={false}
              closable={false}
              footer={
                <div className="modal_footer">
                  <Button
                    onClick={() => {
                      this.setState({
                        selectCover: false,
                      });
                    }}
                  >
                    取消
                  </Button>
                  <Button>确定</Button>
                </div>
              }
            >
              <CoverGallery />
            </Modal>
            {/* 封面图库弹窗end */}
          </Form>
        );
      }
    }
  )
);
