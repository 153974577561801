import React, { Component } from 'react'
import './index.scss';
import { Form, Modal, Input, Row, Col, Button, Tag, Table, DatePicker } from 'antd';
import { selectApplications, selectManagement, stopApplications, bindingManagement, stopAndEnableManagement, deleteManagement, insertManagement,
    manageOrgDetail, appAccessManageDelay, appenter,applicationRecord, getOrganizatioMembers,insertAndUpdateOpenMessage } from '@/api/platform-business/index.js';
import FormAlike from "../../../../components/FormAlike/index";
import RoleAbility from "../../../../components/RoleAbility/index";
import moment from "moment";
import {hint} from '../../../../components/global_components/confirm/index'
const { RangePicker } = DatePicker;
class index extends Component {
    state = {
        total:0,
        pageNum: 1,
        pageSize: 5,
        managementTotal: 0,
        expandForm: false, // 基本信息是否显示全部
      columns: [ // 管理者账号表格参数
        {title: '账号', dataIndex: 'userName', key: 'userName', align: 'center',},
        {title: '绑定用户姓名', dataIndex: 'fullName', key: 'fullName', align: 'center',},
        {title: '绑定用户手机号', dataIndex: 'telNum', key: 'telNum', align: 'center',},
        {title: '角色说明', dataIndex: 'roleName', key: 'roleName', align: 'center',},
        {title: '角色能力', align: 'center',
          render: (text, record) => <p className='action vertical_j'>
            <span onClick={() => this.addAbility(record)}>能力列表</span>
          </p>
        },
        {title: '添加日期', dataIndex: 'createTime', key: 'createTime', align: 'center',},
        {
          title: '操作', dataIndex: 'address', key: 'address', align: 'center', fixed: 'right',
          width: 220,
          render: (text, record) =>
            <p className='action vertical_j'>
              {record.isBinding === 1? <span onClick={() => this.addBingding(record, 0)} >解绑</span> :
                <span onClick={() => this.addBingding(record, 1)} >绑定</span>}
              {record.managementStatus === 1? <span onClick={() => this.outOfService(record, 3)} >停用</span> :
                <span onClick={() => this.outOfService(record, 1)} >启用</span>}
              <span onClick={() => this.magDelete(record)}>删除</span>
            </p>
        }
      ],
        dataSource: [ // 管理者账号表格数据
        ],
        memberColumns: [ // 管理者账号表格参数
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '工号/账号', dataIndex: 'jobNum', key: 'jobNum', align: 'center',},
            {title: '姓名', dataIndex: 'orgMemberName', key: 'orgMemberName', align: 'center',},
            {title: '性别', dataIndex: 'sex', key: 'sex', align: 'center',
              render: (text, record) => {
              console.log('text', text)
                if (text === 0) {
                  return '女'
                } else {
                  return '男'
                }
              }
             },
            {title: '出生日期', dataIndex: 'birthday', key: 'birthday', align: 'center',},
            {title: '籍贯', dataIndex: 'nativePlace', key: 'nativePlace', align: 'center',},
            {title: '最高学位', dataIndex: 'highestDegreeName', key: 'highestDegreeName', align: 'center',},
            {title: '职称', dataIndex: 'professionalCall', key: 'professionalCall', align: 'center',},
            {title: '电话', dataIndex: 'telNum', key: 'telNum', align: 'center',},
        ],
        memberDataSource: [],
        adminSource: [ // 管理者账号表格数据
        ],
        adminColumns: [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '应用图标', dataIndex: 'icon', key: 'icon', align: 'center',
                render: (text, record) => {
                    return <div className='not_open'>
                        <div className="img_top">
                            <img  src={window.$$uploadRc(record.webLogo)} alt="" />
                        </div>
                    </div>
                }
            },
            {title: '应用名称', dataIndex: 'appName', key: 'appName', align: 'center',},
            {title: '应用识别码', dataIndex: 'appCode', key: 'appCode', align: 'center'},
            {title: '添加日期', dataIndex: 'startDate', key: 'startDate', align: 'center',},
            {title: '到期日期', dataIndex: 'endDate', key: 'endDate', align: 'center'},
            {title: '状态', dataIndex: 'accessType', key: 'accessType', align: 'center', render: (text, record) => {
                    if (record.accessType === 0) {
                        return '正式'
                    } else if (record.accessType === 1) {
                        return '试用'
                    } else if (record.accessType === 2) {
                        return '未开通'
                    } else if (record.accessType === 3) {
                        return '已停用'
                    }
                }},
            {
                title: '操作', dataIndex: 'address', key: 'address', align: 'center', fixed: 'right',
                width: 260,
                render: (text, record) => <p className='action vertical_j'>
                    <span onClick={() => this.stopApp(record)}>强制停用</span>
                    <span onClick={() => this.addDelay(record)}>延期设置</span>
                    <span onClick={() => this.addRecord(record)}>开通记录</span>
                </p>
            }
        ],
        isBlock: false, // 添加配置管理账号
        isBinding: false, // 绑定成员弹层
        isAbility: false, // 权限能力列表弹层
        isApplication: false, // 应用接入弹层
        selectedTags: ['Books'], // 接入应用选择数据
        isDelay: false, // 延期设置弹层
        isRecord: false, // 开通记录弹层
      columnsRecord: [ // 开通记录表格参数
        {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,width: 80,},
        {title: '服务开始时间', dataIndex: 'startDate', key: 'startDate', align: 'center',width: 150},
        {title: '服务结束时间', dataIndex: 'endDate', key: 'endDate', align: 'center',width: 150},
        {title: '操作时间', dataIndex: 'updateTime', key: 'createTime', align: 'center',width: 180},
        {title: '操作人', dataIndex: 'updateUserId', key: 'createUserName', align: 'center',width: 150},
        {title: '状态', dataIndex: 'accessType', width:80, key: 'accessType', align: 'center', render: (text, record) => {
            if (record.accessType === 0) {
              return '正式'
            } else if (record.accessType === 1) {
              return '试用'
            } else if (record.accessType === 2) {
              return '未开通'
            } else if (record.accessType === 3) {
              return '已停用'
            }
          }},
      ],
        dataRecord: [ // 开通记录表格数据
        ],
        imageUrl: '',
        loading: false,
        fangshiSel: [],
        cengciSel: [],
        bumenSel: [],
        provinceSel: [],
        citySel: [],
        districtSel: [],
        ruzhuRad: [],
        fanweiRad: [],
        provinceCode: '',
        cityCode: '',
        provinceName: null,
        cityName: null,
        areaName: null,
        oftenFlag: true,
        manageOrgDetail: null,
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        roleList: [],//权限能力列表
        roleListArr: [],//权限能力处理过数据
        roleNum:0,//权限能力tab
        roleDetails: {},
        ability: {},
        memberSelection:'',
        tyId: '', // 停用id
        isStop: '',
        enterpriseType: '',
        managementId: '',// 删除管理账号id
        qztId: '', // 强制停用id
      openFlag: true

    }
    componentDidMount() {
        this.getManagement();
        this.init();//初始化
        this.getApplications();      //获取应用接入详情
    }
    async getManagement() {
        let res = await selectManagement({enterpriseId: sessionStorage['enterpriseId']});
        if (res) {
            this.setState({
                managementTotal: res.length,
                dataSource: res,
            });
        }
    }
    addBingding = (record, isBinding) => { // 绑定弹层
        console.log('recordrecord', record)
        if (isBinding === 0) {
            Modal.confirm({
                className: 'content-warp',
                title: <div>消息提醒</div>,
                centered: true,
                content: "确定要解绑该用户吗？",
                onOk: async () => {
                    bindingManagement({ id: record.id, objectId: record.objectId, isBinding: isBinding, enterpriseType: 5 }).then(res => {
                        if (res) {
                            this.mesSuccess("解绑成功")
                            this.getManagement()
                        }
                    })
                }
            })
        } else {
            this.selectOrganizatioMembers();
          this.setState({ isBinding: true, management: record});
        }
    }
  saveBingding = () => { // 绑定弹层
    let {management, memberSelection} = this.state
    bindingManagement({ id: management.id, objectId: memberSelection, isBinding: 1, enterpriseType: 5  }).then(res => {
      if (res) {
        this.mesSuccess("绑定成功")
        this.getManagement()
        this.setState({isBinding: false})
      }
    })
  }
    async selectOrganizatioMembers(){
        const param = {
            enterpriseId: sessionStorage['enterpriseId'],
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            teacherNameOrNum: this.state.teacherNameOrNum
        }
        const teacherTable = await getOrganizatioMembers(param)
        this.setState({
            memberDataSource: teacherTable.data
        })
    }
    // 我的标签--选择
    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        this.setState({ selectedTags: nextSelectedTags });
    }
    //添加配置管理账号
    addAccount = () => {
        this.setState({ isBlock: true});
    }
    //取消配置管理账号
    closeModal = () => {
        this.setState({ isBlock: false });
    }
    outOfService = (record, isStop) => { // 停用管理者账号
        let contentss = '确定要停用该账号吗?';
        if (isStop === 1) {
            contentss = '确定要启用该账号吗?';
        } else {
            contentss = '确定要停用该账号吗?';
        }
        this.setState({
            tyId: record.id,
            isStop: isStop,
            enterpriseType: 5
        })
        this.hint({'title': '温馨提示',
            'content': contentss,
            'type': 'warning','cancelText': '取消', 'okText': '确定','isCancel': true,'sure': this.stopAndEnableManagement })
        // let content = '确定要停用该账号吗?';
        // if (isStop === 1) {
        //     content = '确定要启用该账号吗?';
        // }
        // Modal.confirm({
        //     className: 'content-warp',
        //     title: <div>消息提醒</div>,
        //     centered: true,
        //     content: content,
        //     onOk: async () => {
        //         stopAndEnableManagement({ managementId: record.id, managementStatus: isStop, enterpriseType: 5 }).then(res => {
        //             if (res) {
        //                 this.getManagement()
        //             }
        //         })
        //     }
        // })
        // this.setState({isApplication: false});
    }
    //停用启用
    stopAndEnableManagement = async () => {
        const {tyId,isStop,enterpriseType} = this.state
        let data = {
            managementId: tyId,
            managementStatus: isStop,
            enterpriseType: enterpriseType
        }
        await stopAndEnableManagement(data).then(r => {
            if(r){
                this.setState({isApplication: false})
                this.getManagement()
            }
        })
    }

    magDelete = (record) => { // 删除管理者账号
        this.setState({
            managementId: record.id,
            enterpriseType: 5
        })
        this.hint({'title': '温馨提示',
            'content': '确定要删除该账号吗?',
            'type': 'warning','cancelText': '取消', 'okText': '确定','isCancel': true,'sure': this.deleteManagement })
        // Modal.confirm({
        //     className: 'content-warp',
        //     title: <div>消息提醒</div>,
        //     centered: true,
        //     content: "确定要删除该账号吗?",
        //     onOk: async () => {
        //         deleteManagement({ managementId: record.id, enterpriseType: 5 }).then(res => {
        //             if (res) {
        //                 this.getManagement()
        //             }
        //         })
        //     }
        // })
    }
    //删除管理账号
    deleteManagement = async () => {
        const {managementId,enterpriseType} = this.state
        let data = {
            managementId: managementId,
            enterpriseType: enterpriseType
        }
        await deleteManagement(data).then(r => {
            if(r){
                this.setState({isApplication: false})
                this.getManagement()
            }
        })
    }
    stopApp = (item) => {
        this.setState({
            qztId: item.id
        })
        this.hint({'title': '强制停用',
            'content':`强制停用后，该机构下的用户将均失去${item.appName}的所有的操作功能。您确认是否要强制停用么？`,
            'type': 'warning','cancelText': '取消', 'okText': '确定','isCancel': true,'sure': this.stopApplications })
        // Modal.confirm({
        // Modal.confirm({
        //   className: 'content-warp',
        //   title: <div>强制停用</div>,
        //   centered: true,
        //   content: '强制停用后，该机构下的用户将均失去'+ item.appName +'的所有的操作功能。您确认是否要强制停用么？',
        //     onOk: async () => {
        //         stopApplications({ id: item.id, userId: '' }).then(res => {
        //             if (res) {
        //                 this.getApplications()
        //             }
        //         })
        //     }
        // })
    }
    // 强制停用
    stopApplications = async () => {
        const {qztId} = this.state
        let data = {
            id: qztId,
            userId: ''
        }
        await stopApplications(data).then(r => {
            if(r){
                // this.setState({isApplication: false})
                this.getApplications()
            }
        })
    }
    //开通记录
    appAccessRecord = async (item) => {
        let res = await applicationRecord({enterpriseId: sessionStorage['enterpriseId'], appId: item.appId});
        res && this.setState({ dataRecord: res});
    }
    selectedTags = (e) => {
        this.setState({ selectedTags: e });
    }
    closeBingding = () => { // 取消绑定弹层
        this.setState({ isBinding: false});
    }
  addAbility = (record) => { // 权限能力列表弹层
    this.setState({isAbility: true, ability: record});
  }
    closeAbility = () => { // 取消权限能力列表弹层
        this.setState({ isAbility: false});
    }
    addApplication = () => { // 应用接入弹层
        this.setState({ isApplication: true});
    }
    closeApplication = () => { // 取消应用接入弹层
        this.setState({isApplication: false});
    }
    addDelay = (item) => { // 延期设置弹层
        this.setState({ isDelay: true, appId: item.appId});
    }
    closeDelay = () => { // 取消延期设置弹层
        this.setState({isDelay: false});
    }
    addRecord = (item) => { // 开通记录弹层
      this.setState({ isRecord: true});
      this.appAccessRecord(item)
    }
    closeRecord = () => { // 取消开通记录弹层
        this.setState({isRecord: false});
    }
    //信息回显
    init = () => {
        let enterpriseId = sessionStorage['enterpriseId'];
        if (enterpriseId) {
            manageOrgDetail({ enterpriseId }).then(res => {
                if (res) {
                    this.setState({
                        manageOrgDetail: res
                    })
                  this.props.form.setFieldsValue({
                    settledDate: res.settledDate ? moment(res.settledDate, 'YYYY-MM-DD') : null,
                    settledEndDate: res.settledEndDate ? moment(res.settledEndDate, 'YYYY-MM-DD') : null
                  });
                }
            })
        }
    }
    async getApplications() {
        let res = await selectApplications({enterpriseId: sessionStorage['enterpriseId']});
        if (res) {
            this.setState({
                adminSource: res,
                enterpriseId: sessionStorage['enterpriseId']
            });
        }
    }
    //应用接入
    save = () => {
        this.appForm.props.form.validateFields((err, values) => {
            if (!err) {
                let { oftenFlag,selectedTags,enterpriseId,userInfo } = this.state;
                if (selectedTags.length === 0) {
                    return this.mesWarning('请选择应用');
                }
                if (oftenFlag) {
                    this.setState({ oftenFlag: false });
                    let list = [];
                    selectedTags.forEach(function(item,index){
                        let app = {accessType: values.accessType,startDate: values.startDate,endDate: values.endDate,id: item.id};
                        list.push(app)
                    });
                    appenter(
                      {
                          enterpriseId: enterpriseId,
                          list: list,
                        createUserId: userInfo.id,
                        updateUserId: userInfo.id,
                      }
                    ).then(res => {
                        if (res) {
                            this.mesSuccess('配置成功');
                            this.getApplications();
                        }
                    })
                    setTimeout(() => this.setState({ oftenFlag: true, isApplication: false }), 2500)
                }
            }
        })
    }
  //应用延期
  appDelay = () => {
    console.log('appDelay', this)
    this.yanqiForm.props.form.validateFields((err, values) => {
      if (!err) {
        let { oftenFlag,enterpriseId,appId } = this.state;
        if (oftenFlag) {
          this.setState({ oftenFlag: false });
          appAccessManageDelay(
            {
              enterpriseId: enterpriseId,
              appId: appId,
              accessType: values.accessType,
              endDate: moment(values.endDate).format('YYYY-MM-DD')
            }
          ).then(res => {
            if (res) {
              this.mesSuccess('延期成功');
              this.getApplications();
            }
          })
          setTimeout(() => this.setState({ oftenFlag: true, isDelay: false }), 2500)
        }
      }
    })
  }
    //配置管理账号
    adminSave = () => {
      this.adminForm.props.form.validateFields((err, values) => {
        if (!err) {
          let { oftenFlag,enterpriseId } = this.state;
          if (oftenFlag) {
            this.setState({ oftenFlag: false });
            insertManagement(
              {
                enterpriseId: enterpriseId,
                userName: values.userName,
                roleId: values.roleId,
                managementStatus: values.managementStatus,
                platform: 'OBS',
                endDate: moment(values.endDate).format('YYYY-MM-DD')
              }
            ).then(res => {
              if (res) {
                this.mesSuccess('配置成功');
                this.getManagement();
              }
            })
            setTimeout(() => this.setState({ oftenFlag: true, isBlock: false }), 2500)
          }
        }
      })
    }
  // 开通信息修改
  saveOpenMessage = () => {
    this.props.form.validateFields((err, values) => {
      console.log('values', values)
      if (!err) {
        let { openFlag,enterpriseId } = this.state;
        if (openFlag) {
          this.setState({ oftenFlag: false });
          insertAndUpdateOpenMessage(
            {
              ...values,
              enterpriseId: enterpriseId
            }
          ).then(res => {
            if (res) {
              this.mesSuccess('保存成功');
            }
          })
          setTimeout(() => this.setState({ openFlag: true }), 1000)
        }
      }
    })
  }
  //table 行实践
  handleTableClick=(e,row,id)=>{
      console.log('eeeeeeeeeeeeeeeeeeee', e)
    this.setState({
      memberSelection:e.id
    })
  }
    render() {
        let {columns, dataSource, adminColumns, adminSource, columnsRecord, dataRecord, manageOrgDetail,managementTotal,
            pageSize, total, memberColumns, memberDataSource, isBlock, isBinding,isAbility,isApplication, isDelay, isRecord, ability,memberSelection} = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <div className='add_school_box detil_management'>
                {/*学校基本信息*/}
                <div className="title">
                    机构管理基本信息
                </div>
                {manageOrgDetail? <ul>
                    <li className='infoItem'>
                        <div className='infoFlex'>
                            <Col span={6}>管理机构名称</Col>
                            <Col span={8}>
                                <div className='infoCon'>{manageOrgDetail.enterpriseName}</div>
                            </Col>
                        </div>
                        <div className='infoFlex'>
                            <Col span={6}>管理机构识别码</Col>
                            <Col span={8}>
                                <div className='infoCon'>{manageOrgDetail.enterpriseCode}</div>
                            </Col>
                        </div>
                    </li>
                    <li className='infoItem'>
                        <div className='infoFlex'>
                            <Col span={6}>管理机构简称</Col>
                            <Col span={8}>
                                <div className='infoCon'>{manageOrgDetail.shorterForm}</div>
                            </Col>
                        </div>
                        <div className='infoFlex'>
                            <Col span={6}>管理范围</Col>
                            <Col span={8}>
                                <div className='infoCon'>{manageOrgDetail.manageRangeName? manageOrgDetail.manageRangeName.join(",") : null}</div>
                            </Col>
                        </div>
                    </li>
                    <li className='infoItem'>
                        <div className='imgWaper'>
                            <Row style={{marginBottom: '20px'}}>
                                <Col span={6} style={{lineHeight: '100px'}}>二级域名</Col>
                                <Col span={8} style={{marginTop: '30px',lineHeight: '40px'}}>
                                    <div className='infoCon'>{manageOrgDetail.enterpriseDomain}</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='imgWaper' style={{lineHeight: '100px'}}>
                            <Col span={6}>机构logo</Col>
                            <Col span={8}>
                                <img  src={window.$$uploadRc(manageOrgDetail.enterpriseLogo)} alt="" />
                            </Col>
                        </div>
                    </li>
                    <li className='infoItem'>
                        <div className='jj_title'>机构简介</div>
                        <div className='jj_text'>{manageOrgDetail.simpleDesc}</div>
                    </li>
                    <li className='infoItem'>
                        <div className='jj_title'>机构标签</div>
                        <div className='tabText'>
                            {manageOrgDetail ? manageOrgDetail.labelList.map(tag => (
                              <Tag>
                                  {tag.labelName}
                              </Tag>
                            )): ''}
                        </div>
                    </li>
                </ul> : null}

                <p className='xian'/>
                <div>
                    <div className='tableWapper'>
                        <div className="mini_title title">
                            开通信息
                        </div>
                    </div>
                    <Form className='kt_school_info'>
                      <Form.Item>
                        <Row>
                          <Col span={4}><span><span className='red'>*</span>学校开通周期</span></Col>
                          <Col span={5}>
                            <Form.Item>
                              <Row className='is-data_wapper'>
                                <Col span={5}><span><span className='red'>*</span>开始日期</span></Col>
                                <Col span={17}>
                                  {
                                    getFieldDecorator('settledDate', {
                                      initialValue: ''

                                    })(
                                      <DatePicker />
                                    )
                                  }
                                </Col>

                              </Row>
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item>
                              <Row className='is-data_wapper'>
                                <Col span={5}><span><span className='red'>*</span>结束日期</span></Col>
                                <Col span={17}>
                                  {
                                    getFieldDecorator('settledEndDate', {
                                      initialValue: ''

                                    })(
                                      <DatePicker />
                                    )
                                  }
                                </Col>

                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                        <Form.Item style={{textAlign: 'center'}}>
                            <Button className='add_btn_j' style={{width: '90px'}} onClick={()=>{this.saveOpenMessage()}}>保存</Button>
                        </Form.Item>
                    </Form>
                </div>


                {/*管理者账号表格部分*/}
                <p className='xian'/>
                <div className='tableWapper'>
                    <div className="mini_title title">
                        管理者账号详情（{managementTotal}）
                    </div>
                    <div className='tableButton'>
                        <div className="right">
                            <div className='sllogg'><img src={require(`@/assets/img/sanjiao_l.png`)} /><span>展开</span></div>
                            <Button className='add_btn_j' onClick={this.addAccount}>配置管理账号</Button>
                        </div>
                    </div>
                </div>

                {/*添加配置管理账号弹层*/}
                <Modal
                  title='配置管理账号'
                  visible={isBlock}
                  destroyOnClose={true}
                  closable={false}
                  className='configuration_modal_add'
                  footer={
                      <div className='modal_btn_j'>
                          <Button onClick={this.closeModal}>取消</Button>
                          <Button onClick={()=>{this.adminSave()}}>确认添加</Button>
                      </div>
                  }
                >
                    <FormAlike wrappedComponentRef={form => {this.adminForm = form}} selectedTags={this.selectedTags} formType='pzgl'/>
                </Modal>
                <div className="bottom">
                    <Table columns={columns} dataSource={dataSource} pagination={ false }/>
                </div>
                {/*绑定成员弹层*/}
                <Modal
                  title='绑定成员'
                  visible={isBinding}
                  destroyOnClose={true}
                  closable={false}
                  className='binding_modal_kait'
                  width={1000}
                  footer={
                      <div className='modal_btn_j'>
                          <Button onClick={this.closeBingding}>取消</Button>
                          <Button onClick={()=>{this.saveBingding()}}>确认</Button>
                      </div>
                  }
                >
                    <div className="in_school_content">
                        <div className="right" style={{textAlign: 'right'}}>
                            <Input.Search className="lcl_left" style={{width: '360px', marginRight: '20px'}} placeholder='请输入人员姓名或工号查询' />
                        </div>
                        <div className="bottom">
                            <Table rowClassName={(record,index)=>{return  record.id===memberSelection?'td-class':''}} rowKey={v => v['enterpriseId']} pagination={
                                {
                                    total,
                                    pageSize:pageSize,
                                    pageSizeOptions: ['5', '10', '20', '40'],
                                    showSizeChanger: true,
                                    // position:'bottomRight'
                                }
                            } columns={memberColumns} dataSource={memberDataSource}
                                   onRow={(record,rowkey)=>{
                                     return{
                                       onClick : this.handleTableClick.bind(this,record,rowkey)    //点击行 record 指的本行的数据内容，rowkey指的是本行的索引
                                     }}}
                            />
                        </div>
                    </div>
                </Modal>
                {/*权限能力列表弹层*/}
              <Modal
                title='权限能力列表'
                visible={isAbility}
                destroyOnClose={true}
                closable={false}
                className='configuration_modal'
                footer={
                  <div className='modal_btn_j'>
                    <Button onClick={this.closeAbility}>返回</Button>
                  </div>
                }
              >
                <RoleAbility ability={ability}/>
              </Modal>

                {/*应用接入详情表格部分*/}
                <p className='xian'/>
                <div className='tableWapper'>
                    <div className="mini_title title">
                        应用接入详情
                    </div>
                    <div className='tableButton'>
                        <div className="right">
                            <Button className='add_btn_j' onClick={this.addApplication}>增加应用接入</Button>
                        </div>
                    </div>
                </div>
                {/*应用接入弹层*/}
                <Modal
                  title='应用接入'
                  visible={isApplication}
                  destroyOnClose={true}
                  closable={false}
                  className='configuration_modal_add'
                  width={660}
                  footer={
                      <div className='modal_btn_j'>
                          <Button onClick={this.closeApplication}>取消</Button>
                          <Button onClick={()=>{this.save()}}>确认添加</Button>
                      </div>
                  }
                >
                    <FormAlike wrappedComponentRef={form => {this.appForm = form}} selectedTags={this.selectedTags} formType='yyjr'/>
                </Modal>
                <div className="bottom hasImgTable">
                    <Table columns={adminColumns} dataSource={adminSource} pagination={ false }/>
                </div>
                {/*延期设置弹层*/}
              <Modal
                title='延期设置'
                visible={isDelay}
                destroyOnClose={true}
                closable={false}
                className='configuration_modal_add'
                footer={
                  <div className='modal_btn_j'>
                    <Button onClick={this.closeDelay}>取消</Button>
                    <Button onClick={()=>{this.appDelay()}}>确认延期</Button>
                  </div>
                }
              >
                <FormAlike wrappedComponentRef={form => {this.yanqiForm = form}} selectedTags={this.selectedTags} formType='yqsz'/>
                </Modal>
                {/*绑定成员弹层*/}
                <Modal
                  title='开通记录'
                  visible={isRecord}
                  destroyOnClose={true}
                  closable={false}
                  className='binding_modal_kait'
                  width={1000}
                  footer={
                      <div className='modal_btn_j'>
                          <Button onClick={this.closeRecord}>关闭</Button>
                      </div>
                  }
                >
                    <div className="in_school_content">
                        <div className="bottom">
                            <Table  columns={columnsRecord} dataSource={dataRecord} pagination={ false }/>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}


index = Form.create()(index);
export default index;
