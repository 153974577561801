import React, { Component } from 'react';
import './index.scss';
import { Layout } from 'antd';
import RouterView from '@/router/router_view';
import { connect } from 'react-redux';


class index extends Component {
    render() {
        return (
            <Layout className='editPassword_box'>
                <RouterView routers={this.props.routers}></RouterView>
            </Layout>
        )
    }
}

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}
index = connect(mapStateToProps)(index);
export default index;
