


import React from 'react';


//实训教学配置
export default props => {
    return <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.50511 4.51687C3.13341 4.51687 2.83203 4.2155 2.83203 3.8438V1.12327C2.83203 0.751572 3.13341 0.450195 3.50511 0.450195C3.87681 0.450195 4.17819 0.751572 4.17819 1.12327V3.8438C4.17819 4.21555 3.87681 4.51687 3.50511 4.51687Z" fill="#BABABA"/>
        <path d="M6.50654 10.0321V1.12327C6.50654 0.751518 6.20516 0.450195 5.83346 0.450195C5.46176 0.450195 5.16039 0.751518 5.16039 1.12327V4.58445C5.06438 4.74857 4.64104 5.07289 3.50957 5.07289C2.39 5.07289 2.00958 4.76241 1.9399 4.61622V1.12327C1.9399 0.751518 1.63858 0.450195 1.26683 0.450195C0.895073 0.450195 0.59375 0.751518 0.59375 1.12327V12.0218C0.59375 12.0391 0.594396 12.0564 0.595742 12.0737C0.608181 12.2349 0.795888 13.649 3.50957 13.649C4.56108 13.649 5.36382 13.4414 5.89549 13.0322C6.37887 12.6601 6.47881 12.2474 6.49787 12.1296C6.50363 12.094 6.50659 12.0579 6.50659 12.0218V10.0322L6.50654 10.0321ZM3.50957 6.41904C4.21124 6.41904 4.74863 6.3184 5.16039 6.16704V9.73484C4.82159 10.0072 4.41257 10.1453 3.56164 10.1453C2.61341 10.1453 2.13854 9.90111 1.9399 9.75594V6.18917C2.37272 6.34177 2.89702 6.41904 3.50957 6.41904ZM3.50957 12.3028C2.44056 12.3028 2.02993 12.0409 1.9399 11.9175V11.2495C2.40481 11.41 2.94747 11.4914 3.56164 11.4914C4.20343 11.4914 4.71739 11.4207 5.16039 11.2597V11.8865C5.04645 12.0315 4.60674 12.3028 3.50957 12.3028Z" fill="#6A7E9C"/>
        <path d="M10.2942 4.51687C9.92247 4.51687 9.62109 4.2155 9.62109 3.8438V1.12327C9.62109 0.751572 9.92247 0.450195 10.2942 0.450195C10.6659 0.450195 10.9672 0.751572 10.9672 1.12327V3.8438C10.9672 4.21555 10.6659 4.51687 10.2942 4.51687Z" fill="#BABABA"/>
        <path d="M12.5835 0.450195C12.2118 0.450195 11.9104 0.751518 11.9104 1.12327V4.58445C11.8144 4.74857 11.391 5.07289 10.2596 5.07289C9.13995 5.07289 8.75953 4.76241 8.68991 4.61622V1.12327C8.68991 0.751518 8.38853 0.450195 8.01683 0.450195C7.64513 0.450195 7.34375 0.751518 7.34375 1.12327V12.0218C7.34375 12.0391 7.34445 12.0564 7.34574 12.0737C7.35818 12.2349 7.54589 13.649 10.2596 13.649C11.3111 13.649 12.1138 13.4414 12.6455 13.0322C13.1289 12.6601 13.2288 12.2474 13.2479 12.1296C13.2537 12.094 13.2566 12.0579 13.2566 12.0218V1.12327C13.2566 0.751518 12.9552 0.450195 12.5835 0.450195ZM10.2596 6.41904C10.9612 6.41904 11.4986 6.31835 11.9104 6.16699V9.73484C11.5715 10.0072 11.1626 10.1453 10.3118 10.1453C9.36347 10.1453 8.88854 9.90111 8.68991 9.75594V6.18917C9.12272 6.34177 9.64702 6.41904 10.2596 6.41904ZM10.2596 12.3028C9.19057 12.3028 8.77994 12.0409 8.68991 11.9175V11.2495C9.15481 11.41 9.69758 11.4914 10.3118 11.4914C10.9535 11.4914 11.4675 11.4207 11.9104 11.2597V11.8865C11.7965 12.0315 11.3567 12.3028 10.2596 12.3028Z" fill="#6A7E9C"/>
    </svg>

}