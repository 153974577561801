


import React from 'react'
import { Input } from 'antd';
const { TextArea } = Input

export default class MaxLengthInput extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            length: 0, // 字符串展示长度
        }
    }

    onChange = (e) => {
        const val = e.target.value
        const { onChange ,maxLength} = this.props;
         const len=this.getLen(val)  
         if(len<=maxLength){
              onChange(val);
              this.setState(()=>{
                  return{
                      length:len
                  }
              })
         }
       
    }
    getLen(val, len = 0) {
        if(!val){
            return 0;
        }
        for (let i = 0; i < val.length; i++) {
            let a = val.charAt(i);
            if (a.match(/[^\x00-\xff]/ig) != null) {
                len += 1;
            } else {
                len += 0.5;
            }
        }
        
        return len;
    }

    render() {

        let { type = 'input', maxLength = 0,  style = {}, placeholder = '', isTextarea, isEdit } = this.props
        const { length } = this.state
        const isMaxLength = maxLength !== 0 // 是否限制最大长度
        const { value = '' } = this.props;
     
        return (
            <div style={{ display: 'flex', position: "relative" }}>
                {
                    type === 'input' ?
                        <Input disabled={isEdit} value={value} onChange={this.onChange} placeholder={placeholder} style={style} />
                        :
                        <TextArea disabled={isEdit} value={value} onChange={this.onChange} style={style} placeholder={placeholder} />

                }
                {
                    isMaxLength && <div style={{ top: isTextarea ? 65 : null }} className="lengthNum">
                        <span>{Math.ceil(this.getLen(value))}/</span><span>{maxLength}</span>
                    </div>
                }
            </div>
        )
    }

}