import React from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import { resourceShelfOperate} from '@/api/grounding'

const {TextArea} = Input;


class OffShelf extends React.Component {
    state = {
        saveLoading: false,
        visible: false,
        shelvesReason: '',
        ids: [],
    }
    componentDidMount() {}
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.visible){
            this.setState({
                ids: nextProps.ids,
                shelvesReason: '',
                visible: nextProps.visible
            })
        }
    }

    checkCancel = () => {
        this.props.checkCancel()
    }

    inputChange = (e) => {
        if (e.target.value.length > 100) {
            return message.warning("下架原因不能超过100字！")
        }
        this.setState({shelvesReason: e.target.value})
    };

    //设置推荐类型
    lookCancel = () => {
        let {ids, shelvesReason} = this.state;
        if (shelvesReason === '' || shelvesReason === null) {
            return message.error("请输入下架原因！");
        }
        let params = {
            ids: ids,
            shelvesReason: shelvesReason,
            shelvesType: 0
        }
        resourceShelfOperate(params).then(res => {
            this.setState({
                lookVisible: false,
                ids: [],
            }, () => {
                if(res.code == 0){
                    this.props.refreshData()
                    message.success('下架成功');
                }
            });
        })
    };

    render() {
        const {shelvesReason} = this.state;
        const {visible} = this.props
        return (
            <Modal
                title="下架原因"
                visible={visible}
                width={'660px'}
                closable={false}
                className="look-modal"
                footer={
                    <div className='modal_btn_j'>
                        <Button onClick={() => this.checkCancel()}>取消</Button>
                        <Button onClick={() => this.lookCancel()} type='primary'>保存</Button>
                    </div>
                }
            >
                <div className="sold-box">
                    <TextArea value={shelvesReason} onChange={this.inputChange} rows={4} placeholder="请输入下架原因"/>
                    <span className="suffixStyle">{shelvesReason.length}/100</span>
                </div>
            </Modal>
        )
    }
}

OffShelf = Form.create()(OffShelf)
export default OffShelf;
