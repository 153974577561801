import React, { useState } from "react";
import { get } from 'lodash';


import style from './index.module.scss';
const data = [1, 1, 2, 12, 12, 21, 21, 12, 21, 12, 12, 12]
function SchoolBasicInformation(props) {
    const [isExpand, setIsExpand] = useState(true);
    const { detailInfo = {} } = props;
    return (
        <div>
            <h1 className={style.basicln_title}>学校基本信息</h1>
            <div className={style.BasicInformation}>
                <div className={style.BasicInformationItem}>
                    <div className={style.BasicInformationLable}>学校名称</div>
                    <div className={style.BasicInformationValue}>{detailInfo.enterpriseName}</div>
                </div>
                <div className={style.BasicInformationItem}>
                    <div className={style.BasicInformationLable}>所属省/市</div>
                    <div className={style.BasicInformationValue}>{detailInfo.ascriptionProvinceName}</div>
                </div>
                <div className={style.BasicInformationItem}>
                    <div className={style.BasicInformationLable}>学校标识</div>
                    <div className={style.BasicInformationValue}>{detailInfo.enterpriseCode}</div>
                </div>
                <div className={style.BasicInformationItem}>
                    <div className={style.BasicInformationLable}>所在地</div>
                    <div className={style.BasicInformationValue}>{detailInfo.provinceName}/{detailInfo.cityName}</div>
                </div>
            </div>
            {isExpand && (<>
                <div className={style.DeploymentDetails}>
                    <div className={style.DetailsLeft}>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>学校logo</div>
                            <div className={style.BasicInformationValueLogo}>
                                <img style={{ width: 160, height: 90 }} src={window.$$imgSrc(detailInfo.enterpriseLogo)} />
                            </div>
                        </div>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>学校简称</div>
                            <div className={style.BasicInformationValue}>{detailInfo.simpleDesc}</div>
                        </div>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>英文名称</div>
                            <div className={style.BasicInformationValue}>{detailInfo.enName}</div>
                        </div>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>主管部门</div>
                            <div className={style.BasicInformationValue}>{detailInfo.departmentName}</div>
                        </div>
                    </div>
                    <div className={style.DetailsRight}>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>办学层次</div>
                            <div className={style.BasicInformationValue}>
                                {get(detailInfo, 'schoolRunLevelNames', []).map((item, index) => {
                                    return <span key={index} style={{ whiteSpace: 'nowrap' }}>{item}{index < get(detailInfo, 'schoolRunLevelNames', []).length - 1 && '/'}</span>
                                })}
                            </div>
                        </div>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>办学方式</div>
                            <div className={style.BasicInformationValue}>{detailInfo.schoolRunModeName}</div>
                        </div>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>学校网址</div>
                            <div className={style.BasicInformationValue}>{detailInfo.websiteAdd}</div>
                        </div>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>学校类型</div>
                            <div className={style.BasicInformationValue}>
                                {get(detailInfo, 'schoolTypeNames', []).map((item, index) => {
                                    return <span key={index} style={{ whiteSpace: 'nowrap' }}>{item}{index < get(detailInfo, 'schoolTypeNames', []).length - 1 && '/'}</span>
                                })}
                            </div>
                        </div>
                        <div className={style.BasicInformationItem}>
                            <div className={style.BasicInformationLable}>创办时间</div>
                            <div className={style.BasicInformationValue}>{detailInfo.foundedTime}</div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingLeft: 50 }} className={style.BasicInformationItem}>
                    <div className={style.BasicInformationLable}>机构标签</div>
                    <div className={style.InstitutionalLabel}>
                        {get(detailInfo, 'labelNames', []).map((item, index) => {
                            return <span key={index}>{item}</span>
                        })}
                    </div>
                </div>
            </>)}
            <div className={style.expandBtn} onClick={() => setIsExpand(!isExpand)}>
                <span style={{ paddingRight: 9 }}><svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 11.0052L0 1.00134C0 0.44773 0.512856 0 1.15197 0C1.44907 0 1.7178 0.0987892 1.92138 0.257374L9.54156 5.2045C10.0462 5.53601 10.1522 6.16017 9.76951 6.60091C9.70439 6.67847 9.62691 6.74549 9.54145 6.79837L1.84005 11.8021C1.33527 12.13 0.610862 12.0419 0.232178 11.6012C0.0733686 11.4212 0.000115018 11.2132 0.000115018 11.0052H0Z" fill="#3E78ED" />
                </svg></span>
                {isExpand ? "收起详情" : "展开详情"}
            </div>
        </div>
    )
}

export default SchoolBasicInformation;