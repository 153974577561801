import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/check/action';
import './index.scss';
import {Button, Input, Col, Checkbox, Tag, Form, DatePicker, message, Select} from 'antd';
import kindName from 'classnames';
import MyTable from '@/components/Table';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {tupOff,tupOffWait,getLastProcessType,getTipOffType} from '@/api/report/report'
import moment from 'moment';
import ReportReason from './components/index'
const {Option} = Select
const {RangePicker} = DatePicker;
const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


class Report extends React.Component {
    state = {
        btnDisabled:true,  //重置按钮
        daiNumber:'',  // 待处理长度
        yiNumber:'',   //已处理长度
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "100"],
            tipOffType: undefined, //举报类型
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        },
        processLists:[],
        dateString:'',
        entity: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        reason:'',  //资源冻结原因
        reasonNum: 0,  //资源冻结原因字数
        ids: [],
        offLists:[],  //资源举报列表
        businessId:'',  //业务id
        dataSource: [],
        startColumns: [
            {title: '资源名称', dataIndex: 'resourceName', key: 'resourceName'},
            {title: '上传人', dataIndex: 'createName', key: 'createName', align: 'center',},
            {title: '资源类型', dataIndex: 'resourceFormName', key: 'resourceFormName', align: 'center'}
        ],
        endColumns: [
            {title: '举报类型', dataIndex: 'tipOffType', key: 'tipOffType', align: 'center',
                render:(text, record) => this.state.reportTypeMap[text]
            },
            {title: '最后处理时间', dataIndex: 'lastProcessTime', key: 'lastProcessTime', align: 'center',},
            {
                title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                width: 240,
                render: (text, record) => <div className='action vertical_j'>
                    <p>
                        <React.RenderButton
                            text='浏览资源'
                            id="OBS-RC-01-10-b_btnView"
                            // type='table_btn'
                            // event={{
                            //     onClick: () => {
                            //         console.log('click')
                            //     },
                            //     onMouseOver:()=>{
                            //         console.log(222)
                            //     }
                            // }}
                        >
                            <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${record.lastProcessType}/${this.state.activeIndex}/0`)}>浏览资源</span>
                        </React.RenderButton>

                        {this.state.activeIndex === 1 ?
                            <React.RenderButton
                            text='查看记录'
                            id="OBS-RC-01-10-b_btnRecord"
                            // type='table_btn'
                            // event={{
                            //     onClick: () => {
                            //         console.log('click')
                            //     },
                            //     onMouseOver:()=>{
                            //         console.log(222)
                            //     }
                            // }}
                            >
                                <span onClick={() => this.props.history.push(`/reportDetail/${record.resourceId}/${this.state.activeIndex}/${record.processState}/1`)}>查看记录</span>
                            </React.RenderButton>
                            : ""}
                        {
                            this.state.activeIndex === 0 || record.lastProcessType !== '1' ?
                                <React.RenderButton
                                    text='冻结'
                                    id="OBS-RC-01-10-b_btnFreeze"
                                    // type='table_btn'
                                    // event={{
                                    //     onClick: () => {
                                    //         console.log('click')
                                    //     },
                                    //     onMouseOver:()=>{
                                    //         console.log(222)
                                    //     }
                                    // }}
                                >
                                    <span onClick={() => this.reasonShow(record)}>冻结</span>
                                </React.RenderButton>
                                : ''
                        }
                    </p>
                </div>
            }
        ],
        columns: [],
        reportTypeData:[],
        reportTypeMap: {},
        processModeData:[],
        processModeMap: {}
    }
    componentDidMount() {
        this.getTipOffType()
        this.getLastProcessType()
        this.initColumns()
        this.tupOff().then(()=>{
            this.tupOffWait()
        })
    }
    async getTipOffType() {
        const reportTypeData = await getTipOffType()
        let reportTypeMap = {}
        reportTypeData.forEach(item => {
            reportTypeMap[item.tipOffType] = item.tipOffTypeName
        })
        this.setState({
            reportTypeData,
            reportTypeMap
        })
    }
    async getLastProcessType() {
        const processModeData = await getLastProcessType()
        let processModeMap = {}
        processModeData.forEach(item => {
            processModeMap[item.id] = item.name
        })
        this.setState({
            processModeMap,
            processModeData
        })
    }
    initColumns() {
        const {activeIndex, startColumns, endColumns} = this.state
        let column = []
        if(activeIndex === 0){
            column = [{title: '资源分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center'},
                {title: '新举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber1', align: 'center'}]
        }else{
            column = [{
                title: '最后处理方式', dataIndex: 'lastProcessType', key: 'lastProcessType', align: 'center',
                render: (text, record) => this.state.processModeMap[text]
            },
                {title: '处理时总举报次数', dataIndex: 'tipOffNumber', key: 'tipOffNumber2', align: 'center'}]
        }
        this.setState({
            columns: startColumns.concat(column).concat(endColumns)
        })
    }
    async getList(){
        const {activeIndex, pagination} = this.state
        if(activeIndex === 0){
            this.tupOffWait()
        }else{
            this.tupOff()
        }
    }
    //资源举报列表-已处理
    async tupOff() {
        const { pagination} = this.state
        const offLists = await tupOff({...pagination, processState: 1})
        pagination.total = offLists.total
        this.setState({
            dataSource: offLists.data,
            yiNumber:offLists.total,
        },()=>{
            window.elGoTop && window.elGoTop('body');
        })
    }
    //资源举报列表-待处理
    async tupOffWait() {
        const { pagination} = this.state
        const OffWaitLists = await tupOffWait({...pagination, processState: 0})
        pagination.total = OffWaitLists.total
        this.setState({
            dataSource: OffWaitLists.data,
            daiNumber:OffWaitLists.total,
        },()=>{
            window.elGoTop && window.elGoTop('body');
        })
    }
    //分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination}, () => this.getList())
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const {pagination} = this.state;
        pagination.pageNum = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.getList())
    };
    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let alreadyFreeze = selectedRows.filter(row => row.lastProcessType === "1")
        if(alreadyFreeze.length > 0){
            return message.warning('不要选择已经冻结的数据');
        }
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({selectedRowKeys, expandedRowKeys})
    }
    //搜索选项改变
    reportChange = ({target: {value}}) => {
        let {pagination} = this.state;
        pagination.searchValue = value
        this.setState({
            pagination,
            btnDisabled: false
        });
    };
    //已完成  待完成切换
    changeTabMenu = (key) => {
        this.setState({
            selectedRowKeys: [],
            expandedRowKeys: [],
            activeIndex: key
        }, () => {
            this.initColumns()
            this.resetSearch()
        })
    };
    resetSearch() {
        let pagination = {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tipOffType: undefined, //举报类型
            processState: 0, //处理状态
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processTime: undefined,  //时间
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        };
        this.setState({
            pagination: pagination,
            btnDisabled: true
        }, () => this.getList())
    }

    //全选反选切换
    checkedAll = () => {
        let {selectedRowKeys, dataSource} = this.state;
        if (selectedRowKeys.length === dataSource.length) {
            this.setState({selectedRowKeys: [], expandedRowKeys: []});
            return;
        }
        const keys = Object.keys(dataSource);
        const index = [];
        keys.forEach(item => {
            index.push(Number(item));
        });
        this.setState({
            selectedRowKeys: index,
            expandedRowKeys: dataSource.map(v => v.resourceId)
        });
    }
    //冻结原因
    reasonShow(row) {
        console.log('ssss', row)
        this.setState({
            ids: [row.resourceId],
            visible: true
        })
    }
    //批量冻结原因
    reasonBatchShow = () => {
        const {expandedRowKeys} = this.state
        if (!expandedRowKeys.length) {
            return message.warning('请选择要冻结的资源');
        }
        this.setState({
            ids: expandedRowKeys,
            visible: true
        })
    }

    checkCancel = () => {
        this.setState({
            visible: false
        })
    }
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        }, () => this.getList())
    }
    //举报类型改变
    reportTypeChange = (value) => {
        let {pagination} = this.state;
        pagination.tipOffType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //处理方式改变
    reportModeChange = (value) => {
        let {pagination} = this.state;
        pagination.lastProcessType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //日期查询
    reportTimeChange = (value, dateString)=> {
        let {pagination} = this.state;
        pagination.processTime = value
        pagination.processStartTime = dateString[0]
        pagination.processEndTime = dateString[1]
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList())
    }
    render() {
        const {columns, selectedRowKeys, activeIndex, dataSource, visible,reportTypeData,processModeData,pagination, reason, reasonNum} = this.state;
        const formItemLayout = {
            labelCol: {span: 0},
            wrapperCol: {span: 24},
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle check_box report'>
                <div className="top p20 bg_fff m20">
                    <Col span={22}>
                        <Select style={{marginRight: '20px',width:"25%"}} placeholder="请选择举报类型" onChange={this.reportTypeChange} value={pagination.tipOffType}>
                            {reportTypeData && reportTypeData.length ? reportTypeData.map((item, index) => {
                                return <Option key={index} value={item.tipOffType} >{item.tipOffTypeName}</Option>
                            }) : ""}
                        </Select>
                        {this.state.activeIndex === 1 ?<Select style={{marginRight: '20px',width:"25%"}} placeholder="请选择处理方式" onChange={this.reportModeChange} value={pagination.lastProcessType}>
                            {
                                processModeData && processModeData.length ? processModeData.map((item, index) => {
                                    return <Option key={index} value={item.id}>{item.name}</Option>
                                }) : ""}
                        </Select> : ""}
                        <span style={{marginRight: '20px'}}>举报日期：</span>
                                <RangePicker onChange={this.reportTimeChange} value={pagination.processTime}
                                             style={{width: '25%', marginRight: '20px '}}/>
                    </Col>
                    <Col className='top_r btn_box' span={2}>
                        <Button className='reset_btn' onClick={()=> this.resetSearch()} disabled={this.state.btnDisabled}>重置</Button>
                        {/*<Button type='primary' className='ant_blue' style={{marginLeft: '12px'}} onClick={() => this.queryBtn()}>查询</Button>*/}
                    </Col>
                </div>
                <div className="wrapper padd">
                    <div className="top m20">
                        <Col className='top_l' span={12}>
                            <div className='tabs'>
                                <Tag onClick={() => this.changeTabMenu(0)}
                                     className={kindName({'active': activeIndex === 0})}>待处理 ({this.state.daiNumber})</Tag>
                                <Tag onClick={() => this.changeTabMenu(1)}
                                     className={kindName({'active': activeIndex === 1})}>已处理 ({this.state.yiNumber})</Tag>
                            </div>
                        </Col>
                        <Col className='top_r' span={12}>
                            <Input placeholder='输入资源名称/上传人/举报人' value={pagination.searchValue} onChange={this.reportChange} onPressEnter={() => this.getList()} />
                            {/* <Button className='reset_btn'>重置</Button>
                            <Button type='primary' className='search'>查询</Button>*/}
                        </Col>
                    </div>
                    <div className="check_container" style={{position: 'relative'}}>
                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                options={
                                    {

                                        dataSource,
                                        columns,
                                        rowSelection: {
                                            selectedRowKeys,
                                            onChange: this.selectedRowChange,
                                        },
                                        pagination: {onChange: this.pageChange, ...pagination,onShowSizeChange: this.onShowSizeChange}
                                    }
                                }
                                isDrab={false}
                                fixededit={true}
                            />
                        </DndProvider>
                        <div className="Grounding_position">
                            {activeIndex==0?
                            <label>
                                <Checkbox
                                    checked={selectedRowKeys.length === dataSource.length ? true : false}
                                    onChange={this.checkedAll}
                                />
                                 <span className="Grounding_select">全选</span>
                            </label>
                            :""}
                            <Button className='ant_blue' onClick={() => this.reasonBatchShow()} type='primary'>批量冻结</Button>
                        </div>
                    </div>
                </div>
                <ReportReason
                  ids={this.state.ids}
                  visible={visible}
                  checkCancel={this.checkCancel.bind(this)}
                  refreshData={this.refreshData.bind(this)}
                  wrappedComponentRef={form => {
                      this.reportReasonForm = form;
                  }}
                ></ReportReason>
            </div>
        )
    }
}


/*Check = connect(mapStateToProps, mapDispatchToProps)(Check);
export default Check;*/
Report = connect(mapStateToProps, mapDispatchToProps)(Report);
Report = Form.create()(Report)
export default Report;
