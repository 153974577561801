import React from "react";
import { Popover } from 'antd';

function TextOverflowEllipsis(props) {
    const { text = '', maxNum = 10 } = props;
    if (text.length > maxNum) {
        return <Popover placement="bottom" content={<div>{text}</div>} trigger="hover">
            <span>{text.substr(0, maxNum)}...</span>
        </Popover>
    }
    return <span>{text}</span>
}

export default TextOverflowEllipsis;