import React, { Component } from 'react';
import { Select, Table, Modal, Button, Form, Radio, DatePicker, message, Input } from 'antd';
import './index.scss';
import moment from 'moment';
import TableEmpty from '@/page/authorityManagement/components/TableEmpty/TableEmpty'
import { getsetCutList, openRecordList, postpone, allFirm, setFirm } from '@/api/configuration/index.js';
import { BreadcrumbFCLine } from '../../../../components/Breadcrumb/BreadcrumbFC'
const { Option } = Select;
const { RangePicker } = DatePicker;


class index extends Component {
    state = {
        columns: [
            {
                title: '序号',
                dataIndex: 'xuhao',
                key: 'xuhao',
                align: 'center',
                render: (text, item, index) => {
                    return <span className='serial'>{index + 1}</span>
                }
            },
            {
                title: '学校名称',
                dataIndex: 'enterpriseName',
                key: 'enterpriseName',
                align: 'center',
                render: (text) => text ?? '- -'
            },
            {
                title: '学校标识码',
                dataIndex: 'enterpriseCode',
                key: 'enterpriseCode',
                align: 'center',
                render: (text) => text ?? '- -'
            },

            {
                title: '添加时间',
                dataIndex: 'createTime',
                key: 'createTime',
                align: 'center',
                render: (text) => text ?? '- -'
            },
            {
                title: '到期时间',
                dataIndex: 'endDate',
                key: 'endDate',
                align: 'center',
                render: (text) => text ?? '- -'
            },
            {
                title: '状态',
                dataIndex: 'useType',
                key: 'useType',
                align: 'center',
                render: ($, item) => {
                    return <span>{item.useType === 0 ? '使用中' : '停用'}</span>
                }
            },
            {
                title: '操作',
                dataIndex: 'handle',
                key: 'handle',
                align: 'center',
                render: ($, item, index) => (
                    <p className='group_btn'>
                        <span onClick={() => this.postpone(item)}>延期设置</span>
                        <span onClick={() => this.getOpenRecord(item.id, () => this.setState({ recordFlag: true }))}>开通记录</span>
                    </p>
                )
            }
        ],
        sonColumns: [
            {
                title: '序号',
                dataIndex: 'xuhao',
                key: 'xuhao',
                align: 'center',
                width: 130,
                render: (text, item, index) => <span>{index + 1}</span>,
            },
            {
                title: '服务开始时间',
                dataIndex: 'createTime',
                key: 'createTime',
                align: 'center'
            },
            {
                title: '服务结束时间',
                dataIndex: 'endDate',
                key: 'endDate',
                align: 'center'
            },
            {
                title: '操作时间',
                dataIndex: 'startDate',
                key: 'startDate',
                align: 'center'
            },
            {
                title: '操作人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                align: 'center',
                render: text => text ?? '- -'
            },
            {
                title: '状态',
                dataIndex: 'accessType',
                key: 'accessType',
                align: 'center',
                width: 130,
                render: ($, item) => {
                    let isOfficial = item.accessType === 0;
                    return <span style={{color:isOfficial?'#333333':'#27CDA2'}}>{isOfficial ? '正式' : '试用'}</span>
                }
            },
        ],
        params: this.props.match.params,
        dataSource: [],
        total: 0,
        pageSize: 20,
        pageNum: 1,
        recordFlag: false,
        postponeFlag: false,
        firmFlag: false,
        accessType: '',
        useType: '',
        id: null,
        startDate: '',
        endDate: '',
        enterpriseList: [],     //test
        isFlag: false,     //test
        testSel: '',            //test
        testDate: '',  //test
        index: -1,
        searchValue: '',
        testId: null,  //test
        openLogPageParams: {
            pageNum: 1,
            total: 0,
            pageSize: 20,
            dataSource: []
        }

    }
    //机构接入应用  test
    firm = item => {
        this.setState({ id: item.id });
        allFirm({
            enterpriseId: item.id
        }).then(res => {
            if (res) {
                this.setState({
                    enterpriseList: res,
                    firmFlag: true
                });
            }
        })
    }
    //延期设置
    postpone = item => {
        this.setState({
            id: item.id,
            startDate: item.startDate,
            endDate: item.endDate,
            postponeFlag: true
        }, () => {
            this.props.form.setFieldsValue({
                accessType: item.accessType,
                currentEndDate: moment(item.endDate).format('YYYY-MM-DD')
            })
        });
    }
    getOpenRecord = (id, callBack) => {
        id && (this.openlogId = id);
        openRecordList({
            enterpriseId: id ?? this.openlogId,
            pageNum: this.state.openLogPageParams.pageNum,
            pageSize: this.state.openLogPageParams.pageSize
        }).then(res => {
            if (res) {
                this.setState(state => {
                    return {
                        openLogPageParams: {
                            ...state.openLogPageParams,
                            dataSource: res.data,
                            total: res.total
                        }
                    }
                }, () => {
                    callBack && callBack();
                })
            }
        })
    }
    //切换页码
    pageChange = (page, pageSize) => {
        this.setState({ pageNum: page }, () => {
            this.getsetCutList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    //切换分页返回条数
    onShowSizeChange = (current, size) => {
        this.setState({ pageSize: size, pageNum: 1 }, () => {
            this.getsetCutList();
            window.elGoTop && window.elGoTop('body');
        })
    }
    //列表
    getsetCutList = () => {
        let data = {
            id: this.state.params.id,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            accessType: this.state.accessType,
            useType: this.state.useType,
            searchValue: this.state.searchValue
        }
        getsetCutList(data).then(res => {
            if (res) {
                this.setState({
                    total: res.total,
                    dataSource: res.data
                })
            }
        })
    }
    //ok  test
    ok = () => {
        let { enterpriseList } = this.state;
        let list = enterpriseList.filter(v => v.flag);
        setFirm({
            list,
            enterpriseId: this.state.id
        }).then(res => {
            if (res) {
                this.setState({ firmFlag: false, testDate: null, testSel: null, isFlag: false })
            }
        })
    }
    componentDidMount() {
        this.getsetCutList();
    }
    changeFirm = item => {
        this.setState({
            isFlag: true,
            testSel: item.accessType,
            testDate: item.startDate && item.endDate ? [moment(item.startDate), moment(item.endDate)] : null
        })
        let { enterpriseList } = this.state;
        let val = enterpriseList.find(v => v.id === item.id);
        if (val) {
            val.flag = !val.flag;
        }
        this.setState({ enterpriseList, testId: item.id });
    }
    //延期设置保存
    save = () => {
        const { params, id, startDate } = this.state;
        if (!id || !params.id) {
            return;
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (new Date(values.endDate) < new Date(startDate)) {
                    message.warning('到期时间不能小于开始时间');
                    return;
                }
                values.endDate = moment(values.endDate).format('YYYY-MM-DD');
                postpone(
                    {
                        ...values,
                        enterpriseId: id,
                        appId: params.id
                    }
                ).then(res => {
                    if (res) {
                        message.success('延期成功');
                        this.setState({ postponeFlag: false, id: null });
                        this.getsetCutList();
                    }
                })
            }
        })
    }
    render() {
        let { openLogPageParams, columns, dataSource, total, pageSize, recordFlag, sonColumns, postponeFlag, isFlag, endDate, firmFlag, enterpriseList, testSel, testDate } = this.state;
        function disabledDate(current) {
            return current && current < moment();
        }
        const {match} = this.props
        const {appName} = match.params
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='cut_setSchool_box'>
                <BreadcrumbFCLine list={[
                    { name: '应用接入管理',title:`接入管理-${appName}`},
                ]}/>
                <div className={'cut_setSchool_search'}>
                    <Input.Search
                        onChange={e => this.setState({ searchValue: e.target.value })}
                        placeholder={'请输入院校名称'}
                        onSearch={this.getsetCutList} />
                    <Button onClick={this.getsetCutList}>查询</Button>
                    <p>共{total}个院校数据</p>
                </div>
                <div className="container ui_table">
                    <Table
                        locale={{
                            emptyText: <TableEmpty />
                        }}
                        rowKey={v => v.id}
                        columns={columns}
                        pagination={
                            {
                                total: total,
                                pageSize: pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['20', '40', '60', '80'],
                                showSizeChanger: true
                            }
                        }
                        dataSource={dataSource} />
                </div>
                <Modal
                    title='开通记录'
                    closable={false}
                    className='son_table_modal ui_table'
                    centered={true}
                    visible={recordFlag}
                    footer={
                        <Button type={'primary'} onClick={() => this.setState({ recordFlag: false })}>确定</Button>
                    }
                >
                    <Table
                        rowKey={v => v.id}
                        columns={sonColumns}
                        dataSource={openLogPageParams.dataSource}
                        locale={{
                            emptyText: <TableEmpty />
                        }}
                        pagination={{
                            ...openLogPageParams,
                            onChange: pageNum => this.setState(state => {
                                return { openLogPageParams: { ...state.openLogPageParams, pageNum } }
                            }, this.getOpenRecord),
                            onShowSizeChange: (pageNum, pageSize) => this.setState(state => {
                                return { openLogPageParams: { ...state.openLogPageParams, pageNum: 1, pageSize } }
                            }, this.getOpenRecord),
                            pageSizeOptions: ['20', '40', '60', '80'],
                            showSizeChanger: true
                        }}
                    />
                </Modal>
                <Modal
                    title='延期设置'
                    visible={postponeFlag}
                    destroyOnClose={true}
                    closable={false}
                    centered={true}
                    className='postpone_modal'
                    width={660}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.setState({ postponeFlag: false })}>取消</Button>
                            <Button onClick={this.save}>确定延期</Button>
                        </div>
                    }
                >
                    <Form>
                        <Form.Item label='菜单类型'>
                            {getFieldDecorator('accessType', {
                                initialValue: 0,
                            })(
                                <Radio.Group>
                                    <Radio value={0} style={{ marginRight: '28px' }}>正式接入</Radio>
                                    <Radio value={1}>试用期</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label='到期时间'>
                            {getFieldDecorator('currentEndDate', {
                                initialValue: ''
                            })(
                                <Input disabled={true} />
                            )}
                        </Form.Item>
                        <Form.Item label='延期日期'>
                            {getFieldDecorator('endDate',)(
                                <DatePicker
                                    disabledDate={disabledDate}
                                    format='YYYY-MM-DD'
                                    placeholder='请选择应用延期到期日'
                                />
                            )}
                        </Form.Item>

                    </Form>
                </Modal>
                <Modal visible={firmFlag} onOk={this.ok} onCancel={() => this.setState({ firmFlag: false })}>
                    <ul>
                        {
                            Array.isArray(enterpriseList) && enterpriseList.map(v => <li key={v.id} onClick={() => this.changeFirm(v)} style={{ color: v.flag ? 'red' : '#333333', cursor: 'pointer' }}>{v.appName}</li>)
                        }
                    </ul>

                    {
                        isFlag ? <div>
                            <div>
                                <span>账户类型</span>
                                <Select
                                    value={testSel}
                                    onChange={e => {
                                        const { enterpriseList, testId } = this.state;
                                        let item = enterpriseList.find(v => v.id === testId);
                                        if (item) {
                                            item.accessType = e;
                                        }
                                        this.setState({
                                            testSel: e
                                        })
                                    }}
                                    style={{ minWidth: '250px' }}
                                >
                                    <Option value=''>全部账户</Option>
                                    <Option value={0}>正式</Option>
                                    <Option value={1}>非正式</Option>
                                </Select>
                            </div>
                            <div>
                                <span>开始结束时间</span>
                                <RangePicker
                                    onChange={e => {
                                        const { enterpriseList, testId } = this.state;
                                        let item = enterpriseList.find(v => v.id === testId);
                                        if (item) {
                                            item.startDate = moment(e[0]).format('YYYY-MM-DD');
                                            item.endDate = moment(e[1]).format('YYYY-MM-DD');
                                        }
                                        this.setState({
                                            testDate: e
                                        })
                                    }}
                                    value={testDate}
                                    format='YYYY-MM-DD'
                                />
                            </div>
                        </div> : null
                    }
                </Modal>
            </div>
        )
    }
}


index = Form.create()(index);
export default index;