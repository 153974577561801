import data from '../emoji-data/emoji-data'

let emojiData = {}
Object.values(data).forEach(item => {
    emojiData = { ...emojiData, ...item }
})


/**
 *
 *
 * @export
 * @param {string} value
 * @returns {string}
 */

export default function emoji (value) {
    if (!value) return
    Object.keys(emojiData).forEach(item => {
        value = value.replace(new RegExp(item, 'g'), createIcon(item))
    })
    return value
}

function createIcon (item) {
    const name = item.replaceAll(":","")
    const className = 'sprite-' +name
    const value = emojiData[item]
    const path = '/static/emoji/'
    const classValue = value.split('.')
    return `<img class=${className} width="16px" height="16px">`
}