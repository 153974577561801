import React, { memo } from 'react';
import { Input, Select, Button } from 'antd';
import styles from './FilterTableData.module.scss';
import SvgDown from '../../svg/SvgDown';
import { useSetState } from 'ahooks';

// [
//     {
//         lable: '',

//         type: 'select',
//         optionList: [],
//         props:{
//             placeholder: '请选择教师类型',
//             onChange:()=>{}
//         }
//     }
// ]


function renderItem(item, index) {
    if (item.type === 'select') {
        return <div key={index}>
            <span>{item.label}</span>
            <Select {...item.props}>
                {
                    item?.optionList.map((sonItem) => {
                        return <Select.Option value={sonItem.code} key={sonItem.code}>{sonItem.desc}</Select.Option>
                    })
                }
            </Select>
        </div>
    } else if (item.type === 'input') {
        return <div key={index}>
            <span>{item.label}</span>
            <Input {...item.props} />
        </div>
    }
}


const FilterTableData = ({
    topFilterList,
    bottomFilterList,
    reset,
    hidden = true,
    search,
    className,
    style={}
}) => {
    const [state, setState] = useSetState({
        hidden
    })
    const Search = <>
        <Button onClick={reset}>重置</Button>
        <Button onClick={search}>查询</Button>
    </>;
    return <div className={`${styles['filter_box']} ${className}`} style={style}>
        <div>
            <div className={styles['l_filter']}>
                {
                    topFilterList.map((item, index) => (renderItem(item, index)))
                }
            </div>
            <div className={styles['r_filter']} hidden={!state.hidden}>
                {Search}
                <span onClick={() => setState({ hidden: false })}>
                    <i>展开</i>
                    <SvgDown />
                </span>
            </div>
        </div>
        <div hidden={state.hidden}>
            <div className={styles['l_filter']}>
                {
                    bottomFilterList.map((item, index) => (renderItem(item, index)))
                }
            </div>
            <div className={styles['r_filter']}>
                {Search}
                <span onClick={() => setState({ hidden: true })}>
                    <i>收起</i>
                    <SvgDown style={{ transform: 'rotate(180deg)' }} />
                </span>
            </div>
        </div>
    </div>
}

export default memo(FilterTableData);

