import React, { Component } from 'react';
import { Button, Radio, Tree, message, Checkbox } from 'antd';
import './index.scss';
import { getMenuDetail, getSystemList, jurisdictionList, allotMenu, getAppButtonRole, getAppRole } from '@/api/configuration/index.js';
let { TreeNode } = Tree;


class index extends Component {
    state = {
        params: this.props.match.params,
        systemList: [],
        halfMenu: [],
        platform: '',
        checkArr: null,
        sysId: '',
        treeList: null,
        associatedTerminal: sessionStorage['menu_config_type'] ? sessionStorage['menu_config_type'] * 1 : 0,
        appRoleList: [],
        appCheckList: []
    }
    componentWillUnmount(){
        sessionStorage.removeItem('menu_config_type');
    }
    sure = (isBack) => {
        const { params, checkArr, halfMenu, sysId, associatedTerminal, appCheckList } = this.state;
        let data = { "appId": params.id, "sysId": sysId, associatedTerminal }
        if (associatedTerminal === 0) {
            data["menus"] = checkArr
            data["halfMenus"] = halfMenu
        } else if (associatedTerminal === 1) {
            data['appMenu'] = appCheckList;
        }
        allotMenu(data).then(res => {
            if (res) {
                isBack && message.success('设置成功');
                isBack && this.props.history.goBack();
            }
        })
    }
    cancel = () => {
        this.props.history.goBack();
    }
    //获取回显id数组
    getDetail = () => {
        getMenuDetail({
            appId: this.state.params.id,
            sysId: this.state.sysId
        }).then(res => {
            if (res) {
                const { halfCheck, allCheck } = res;
                this.setState({
                    checkArr: allCheck ? allCheck : [],
                    halfMenu: halfCheck
                });
            }
        })
    }
    //获取系统列表
    getSystemList = () => {
        getSystemList().then(res => {
            if (res) {
                this.setState({
                    systemList: res,
                    platform: res[0] ? res[0].platform : '',
                    sysId: res[0] ? res[0].id : ''
                }, () => {
                    // this.getDetail();
                    // this.jurisdictionList();
                    // this.getAppButtonRole();
                    this.changeGetList();
                });
            }
        })
    }
    //获取树形菜单
    jurisdictionList = () => {
        let { platform } = this.state;
        if (!platform) {
            return;
        }
        jurisdictionList({
            platform
        }).then(res => {
            if (res) {
                this.setState({ treeList: res });
            }
        })
    }
    //递归方法遍历树形控件
    renderTree = (data, idx) => {
        return data.map(item => {
            if (!item.childList) {
                return (
                    <TreeNode title={item.menuName} key={item.id} />
                )
            } else {
                return (
                    <TreeNode title={item.menuName} key={item.id}>
                        {this.renderTree(item.childList)}
                    </TreeNode>
                )
            }
        })
    };
    changeGetList = () => {
        if (this.state.associatedTerminal === 0) {
            this.getDetail();
            this.jurisdictionList();
        } else {
            this.getAppButtonRole();
            this.getAppRole();
        }
    }
    change = e => {
        const { systemList } = this.state;
        this.sure();
        this.setState({
            platform: e.target.value,
            treeList: null,
            checkArr: null,
            sysId: systemList && systemList.find(v => v.platform === e.target.value).id
        }, () => {
            this.changeGetList();
        });
    }
    componentDidMount() {
        this.getSystemList();
    }
    onCheck = (arr, l) => {
        const { halfCheckedKeys } = l;
        this.setState({
            checkArr: arr,
            halfMenu: halfCheckedKeys
        });
    }
    changeType = e => {
        const value = e.target.value;
        this.sure();
        this.setState({ associatedTerminal: value }, () => {
            this.changeGetList();
        });
    }
    //获取全部app端按钮权限
    getAppButtonRole = () => {
        getAppButtonRole({
            associatedTerminal: 1,
            platform: this.state.platform
        }).then(res => {
            this.setState({
                appRoleList: Array.isArray(res) ? res : []
            });
        })
    }
    //获取app已选中的按钮权限
    getAppRole = () => {
        getAppRole({
            platform: this.state.platform,
            id: this.state.params.id
        }).then(res => {
            res && this.setState({ appCheckList: res });
        })
    }
    changeAppRole = e => {
        this.setState({ appCheckList: e });
    }
    render() {
        const { systemList, checkArr, treeList, platform, associatedTerminal, appRoleList, appCheckList } = this.state;
        const webBox = <div className="container" style={{ maxHeight: '350px' }}>
            {
                checkArr && treeList ?
                    <Tree
                        checkable
                        selectable={false}
                        defaultExpandAll={true}
                        onCheck={this.onCheck}
                        defaultCheckedKeys={checkArr}
                    >
                        {this.renderTree(treeList)}
                    </Tree> : null
            }
        </div>;
        const appBox = <div className='app_role_box' style={{ maxHeight: '350px' }}>
            <Checkbox.Group value={appCheckList} onChange={this.changeAppRole}>
                {
                    appRoleList.map(v => {
                        return <Checkbox value={v.id} key={v.id}>{v.menuName}</Checkbox>
                    })
                }
            </Checkbox.Group>
        </div>
        return (
            <div className='menu_config_box'>
                <h2 className='title'>角色分配</h2>
                <div className="container_controll">
                    <div className='name_box'>
                        <span className='red'>*</span>
                                空间/系统
                            </div>
                    <div className="system_list">
                        <Radio.Group value={platform} onChange={this.change}>
                            {
                                systemList && systemList.map(v => {
                                    return <Radio key={v.id} value={v.platform}>{v.menuName}</Radio>
                                })
                            }
                        </Radio.Group>
                    </div>
                </div>
                <div className='type_controll'>
                    <div className='name_box'>
                        <span className='red'>*</span>
                                关联终端
                            </div>
                    <div className="system_list">
                        <Radio.Group disabled value={associatedTerminal} onChange={this.changeType}>
                            <Radio value={0}>Web端</Radio>
                            <Radio value={1}>App端</Radio>
                        </Radio.Group>
                    </div>
                </div>
                {
                    associatedTerminal === 0 ? webBox : appBox
                }
                <div className="fix_controll">
                    <Button onClick={() => this.sure(true)} className='add_btn_j' style={{ marginRight: '40px', width: '100px', height: '39px' }}>确定</Button>
                    <Button onClick={this.cancel} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>
            </div>
        );
    }
}

export default index;