import React, { Component } from "react";
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/check/action';
import TextArea from "antd/lib/input/TextArea";
import {Button,  Input, Col, Checkbox,Table, Tag, Form, DatePicker, message, Select,Modal,Tabs,} from 'antd';
import kindName from 'classnames';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
 
  reportTypeList, 
  hasDealList,
  noDealList,
  create,
  dealwith,
  reportTypeUserList,    
  
  getTopicDetail, 
  showReportList, 
  topicCommentList, 
  replyCommentList, 
  dealNoReson, 
  deleteReportTopic, 
  changeUser 
} from '@/api/circle/index'

import moment from 'moment';
import { Fragment } from 'react';
const { Search } = Input;
const { TabPane } = Tabs;

const { Option } = Select
const { RangePicker } = DatePicker;
const { Column } = Table;

export default class index extends Component {
  state = {
    count: 0,
    _index: "",     //tabs切换变量
    
    comment_data: [ ],
    comment_data_02: [ ],
    portraitId:"",
    
    idea: "",
    com_d: "",

    dataSource: [],
    _getTopicDetailData: [],  

    ReportList: [],       //话题举报详情得举报详情
    _showReportList: [],  //举报记录列表
    CommentList: [],      // 话题评论列表
    
    pageNum: 1, //话题的分页
    pageSize: 12,
    _businessId:"",   //话题id
  };

  componentDidMount() {
   let _tid  = this.props.match.params.id; 
   this.setState({
    _businessId: _tid,
    });

   this.getTopicDetail();
   this.showReportList();
   this.topicCommentList();
}

 

    //话题举报详情
    async getTopicDetail() {
      const _tid = this.props.match.params.id; 
      console.log(_tid,'0000')
      let params = {
        "topicId": _tid,
      };
      const res = await getTopicDetail(params);  
      console.log(res)
      if (res ) {
          this.setState({
              _getTopicDetailData: res  || {},
              dataSource: res || {},

          });
      }
        console.log(res,"111111")
    }

    //举报记录列表
    async showReportList() {
      const _tid = this.props.match.params.id; 
      let params = {
        "platform": "OC",
        "businessType": "HT",
        "businessId": "565ffe60ce284a47b8060fb99e1bc8e2"
      };
      const res = await showReportList(params);  
  
      if (res ) {
        res.forEach(v=>{
            v.pageSize=12
            v.pageNum=1
        })
      }

      this.setState({
        ReportList: res  ,
        _showReportList: res,
      });
      console.log(this.state._showReportList,"_showReportList---");
    }

    // 话题评论列表
    async topicCommentList() {
      const _tid = this.props.match.params.id; 
      let params = {
        topicId: "565ffe60ce284a47b8060fb99e1bc8e2",
        circleId: "87906629",
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      };
      const res = await topicCommentList(params);  
 
      if (res ) {
          this.setState({
            CommentList: res.data  || {},
          });
      }
    }



  onChange = e => {
    this.setState({ _index: e.target.value });
  };

      //下一批
    // 举报类型(0:色情  1:骚扰 2:反动言论 3:暴力 4:广告或垃圾信息 5:抄袭或未授权 6:其他)
    updata = (v, i) => {
    debugger
      const { ReportList } = this.state
    
      changeUser({
          businessId: '565ffe60ce284a47b8060fb99e1bc8e2',
          platform: "OC",              //类型：String  必有字段  备注：系统标识
          tipOffType: v.tipOffVal,                //类型：String  必有字段  备注：举报类型
          pageNum: v.pageNum,                //类型：String  必有字段  备注：无
          pageSize: v.pageSize               //类型：String  必有字段  备注：无
      }).then(res => {
          if (res && res.data) {
              ReportList[i]['reportTypeList'] = res.data
              console.log(ReportList[i]['pageNum'])
              ReportList[i]['pageNum']=  ReportList[i]['pageNum']+1
              console.log(ReportList, res.data)
              this.setState(() => {
                  return {
                      ReportList
                  }
              }, () => {
                  message.info('更新成功')
              })
          } else {
              message.info('更新失败')
          }

      })
  }

  //设置
  setUp = (obj) => {
    const { businessId, platform } = obj
    dealNoReson({
        businessId,
        platform
    }).then(r => {
        if (r && r.code === 0) {
            message.success('操作成功')

        } else {
            message.error('操作失败')
        }
    })

  }
  //删除话题
  deletetopc = () => {
      deleteReportTopic({
          topicId: this.state._businessId

      }).then(r => {
          if (r && r.code === 0) {
              message.success('操作成功')

          } else {
              message.error('操作失败')
          }
      })
  }

  render() {
    let { count, comment_data, comment_data_02, portraitId, counts, dataSource,_getTopicDetailData,ReportList,_showReportList,CommentList, _businessId,} = this.state;
    const communityDetailData = sessionStorage.communityDetail ? JSON.parse(sessionStorage.communityDetail) : {};
    return (
      <div className="topic_wrap_new">
        <div className="hseader_wrap">
          <div className="head_new">
            <div className="head_l">
              <span onClick={() => this.props.history.push('/main/circle-manage/topic-report')}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.258 5.0247H2.17301L7.01502 1.14361C7.08697 1.09578 7.14821 1.03353 7.19485 0.960793C7.2415 0.88806 7.27252 0.806432 7.28597 0.721081C7.29942 0.63573 7.29499 0.548517 7.27297 0.464966C7.25096 0.381415 7.21183 0.303347 7.15806 0.235708C7.1043 0.16807 7.03707 0.112334 6.96064 0.0720329C6.88421 0.0317315 6.80025 0.00774268 6.71406 0.00158606C6.62788 -0.00457055 6.54136 0.00723925 6.45998 0.0362673C6.37859 0.0652954 6.30413 0.110909 6.2413 0.170219L0.00159455 5.16198V5.38661C-0.000531515 5.41569 -0.000531515 5.44488 0.00159455 5.47396V5.53636C-0.000531515 5.56544 -0.000531515 5.59464 0.00159455 5.62372V5.84835L6.2413 10.8401C6.30521 10.8917 6.37866 10.9303 6.45747 10.9535C6.53627 10.9767 6.61888 10.9842 6.70058 10.9755C6.78228 10.9668 6.86146 10.9421 6.93361 10.9028C7.00576 10.8635 7.06947 10.8104 7.12109 10.7465C7.17272 10.6826 7.21124 10.6091 7.23448 10.5303C7.25771 10.4515 7.2652 10.3689 7.25651 10.2872C7.24782 10.2055 7.22312 10.1263 7.18383 10.0542C7.14453 9.98204 7.09141 9.91833 7.0275 9.86671L2.17301 6.27264H11.258C15.2389 6.27264 18.4836 9.06803 18.4836 12.5123C18.4836 15.9567 15.2389 18.752 11.258 18.752H1.6364C1.47091 18.752 1.3122 18.8178 1.19518 18.9348C1.07817 19.0518 1.01243 19.2105 1.01243 19.376C1.01243 19.5415 1.07817 19.7002 1.19518 19.8172C1.3122 19.9342 1.47091 20 1.6364 20H11.258C15.9877 20 19.7315 16.643 19.7315 12.5123C19.7315 8.38166 15.9877 5.0247 11.258 5.0247Z"
                    fill="#333333"
                  />
                </svg>

                <span className="back_text">返回</span>
              </span>
            </div>
            <div className="detail_title">
              {/* <Search
                placeholder="请输入您要查询的内容"
                onSearch={(value) => console.log(value)}
                style={{ width: 360 }}
              />
              <Button>搜话题</Button> */}
              <span>话题举报详情</span>
            </div>
          </div>
        </div>
        <div className="breadcrumb">
              <span className="first_bread">话题举报管理 </span>&nbsp;>&nbsp;
              <span className="second_bread"> 话题举报详情</span>
        </div>
        <div className="content">

          <div className="c_left">
          {/* console.log(this.state._getTopicDetailData,"_getTopicDetailData----")*/}
            <div className="cicle_main_warp">
                <div className="cicle_main_name_warp">
                    <div className="cicle_user_name">
                        <img src={ _getTopicDetailData.coverUrl} alt="" />
                    </div>
                    <div className="cicle_main_name">
                        <p className="cname"> {  _getTopicDetailData.circleName}</p>
                        <p className="cinfo"><span className="mright10">圈主：{  _getTopicDetailData.circleName} </span> <span className="mleft10">所在机构：{ _getTopicDetailData.orgName} </span></p>
                    </div>
                    <div className="go_cicle">
                        <span className="go_link">进入圈子 ></span>
                    
                    </div>
                </div>
            </div>
            
            <div className="cicle_main_content">
                  <div className="content_title">
                   { _getTopicDetailData.topicTitle}
                  </div>
                  <div className="content_main">
                  { _getTopicDetailData.content}
                  </div>
                  <div className="content_img">
                  {/* <img src={require("@/assets/img/images/gr.png")} alt=""/>
                  <img src={require("@/assets/img/images/gr.png")} alt=""/>
                  <img src={require("@/assets/img/images/gr.png")} alt=""/> */}
                  {_getTopicDetailData.fjType === 0 ? _getTopicDetailData.fjUrl && _getTopicDetailData.fjUrl.map((v, i) => {
                                return <img src={v} key={i} alt="" />
                            }) :
                            _getTopicDetailData.fjType === 1 ?
                                    '视频'
                                    : _getTopicDetailData.fjType === 2 ? "音频" 
                                  : ""}
                  </div>
            </div>

            <div className="cicle_mian_tag_wrap">
                <div className="cicle_mian_tag_main">
                    {/* <img className="mright10" src={require("@/assets/img/images/gr.png")} alt=""/> */}
                    <img className="mright10" src={_getTopicDetailData.imgurl } alt=""/>
                    <span className="tag_name font16 mright10">{_getTopicDetailData.userName}</span>
                    <span  className="tag_time font14">发布时间：{_getTopicDetailData.pubTime}</span>
                </div>
                <div className="cicle_mian_tag_content">
                      <Tag className="reset_lll color_array_02" >
                        浏览量 {_getTopicDetailData.browseNum}
                      </Tag>
                      <Tag className="reset_lll color_array_02" >
                      点赞量 {_getTopicDetailData.praiseNum}
                      </Tag>
                      <Tag className="reset_lll color_array_02" >
                      评论量 {_getTopicDetailData.discussNum} 
                      </Tag>
                </div>
            </div>

            <div className="comment">
              {/* <h2 className="title">
                用户评论<span>(共{comment_data.length}条)</span>
              </h2> */}

            <div className="tabs_new">

                <Tabs defaultActiveKey="1" size={this.state._index} style={{ marginBottom: 32 }}>
                <TabPane tab="举报详情" key="1">
                <div className="" data-index="1">
                  
                  

              {ReportList && ReportList.map((item, index) => {
                return (
                  <>
                  <div className="typeinfo_title_wrap">
                      <span className="typeinfo_title">举报类型：</span>
                      <span className="typeinfo_title mright10">{item.tipOffType}</span>/
                      <img className="mleft10 typeinfo_img" src={require("@/assets/img/icon_avator_s.svg")} alt="" />
                      <span className="typeinfo_title_number mleft5 ">{item.tipOffTypeNum}人举报</span>
                      <span className="btn " style={{float: 'right'}}><Button >下一批</Button></span>
                       
                  </div>

                    {item.reportTypeList && item.reportTypeList.map((v, index) => {
                      return (
                        <dl className="com_list" key={index}>
                          <dt>
                          <img src={v.avatarUrl} alt="" />
                          </dt>
                          <dd>
                            <h2>
                              <span>{v.tipOffUserName}</span>

                            </h2>
                            <p>
                              <span>{v.createTime}</span>
                              {/* <span>{item.timer}</span> */}
                            </p>
                            <div>{v.tipOffDescribe}</div>

                            <div className="list_img">
                            {v.tipOffImages.map((m, i ) => {
                              return (
                                <img className="" src={m} alt="" />
                              )
 
                              })}
                              
                            </div>
                          </dd>
                        </dl>
                      )
                    })}
 
                  </>
                );
              })}
            </div>
                </TabPane>
                <TabPane tab="话题评论" key="2">
                  <div className="" data-index="2">

                      {CommentList.map((item, index) => {
                        console.log(item,'CommentList----');
                        return (
                          <>
                            <dl className="com_list" style={{width:'100%'}} key={index}>
                            
                              <dt>
                                <img src={item.avatarUrl} alt="" />
                              </dt>
                              <dd>
                                <h2 style={{height:'24px'}}>
                                  <span  style={{float:'left'}}>{item.nickName} 用户昵称</span>
                                  <span className="com_timer color_array_02" style={{float:'right'}}>{item.commentTime}</span> 
                                </h2>
                                <p>
                                  <span>  资源所属机构名称</span>
                                  
                                </p>
                                <div>{item.commentMsg}内容</div>
                                
                                <div  className=""><img className="mright5" src={require("@/assets/img/images/icon_replay.svg")}  alt=""/>查看回复 <span>{item.replyNum}</span>  </div>
                                
                              </dd>
                              
                            </dl>
                            
                              {  item.replyDataList.replyList.map((v, index) => {
                                // console.log(item,'replyList---')
                                return (
                                  <div className="reply_list_wrap" >
                                    <dl className="reply_list" key={index}>
                                      <dt>
                                          <img src={v.userAvatar} alt="" />
                                      </dt>
                                      <dd>
                                        <h2>
                                          <span>{v.fromUser}<span className="color_array_02 mleft10 mright10">回复</span>{v.toUser}</span>
                                          <span className="reply_timer color_array_02" style={{ float: 'right' }}>{v.time}</span>
                                        </h2>
                                        
                                        <div>{v.content}内容</div>
                                      </dd>
                                    </dl>

                                  </div>
                                )
                              })
                            }
                              
                          </>
                        );
                      })}
 
                    </div>
                </TabPane>
                </Tabs>
            </div>
 
            </div>
          </div>
 
        </div>
        <div className="tfooter_wrap">
            <div className="head_new">
              <Button type="primary" style={{marginRight:'20px'}} onClick={() => this.setUp({ businessId: this.state._businessId, platform: "OC"})}>没有问题</Button>
              <Button type="default" onClick={() => this.deletetopc()}>删除此话题</Button>
            </div>
        
        </div>
      </div>
    );
  }
}
