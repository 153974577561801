import { get } from 'lodash';
import moment from 'moment';

export function _get_query(name) {
    const search = window.location.href
    if (!search) return null
    const item = get(search.split('?'), '1', '').split('&').find(v => {
        return v.includes(name)
    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        // console.log(`${name} is not in search`)
        return null
    }
}

export const format = 'YYYY-MM-DD'
export function disabledDate(current) {
    // Can not select days before today and today
    // return current && current < moment().endOf('day');
    return current && current < moment().subtract(1, 'days').endOf('day')
}

export function afterDisabledDate(current) {
    return current && current > moment().endOf('day');
}