import React, {Component} from 'react';
import {Col, Button,} from 'antd';
import MyTable from '@/components/Table';
import url from "@/until/libs/url.js";
import {getEquipment, getDetail} from '@/api/practTrain/index.js'
import './index.scss';

class Applaymg extends Component {
    state = {
        applyId: '',
        dataSource: [],
        detail: {},
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            pageSizeOptions: ["10", "20", "50", "100"]
        },
    }

    componentDidMount() {
        if (url.getQueryString("applyId")){
            this.setState({
                applyId: url.getQueryString("applyId")
            },()=>{
                this.getDetail()
                this.getEquipment()
            })
        }
    }

    async getDetail(){
        let {applyId} = this.state;
        let detail = await getDetail({applyId:applyId});
        if(detail){
            this.setState({
                detail
            })
        }
    }

    async getEquipment(){
        let {applyId,pagination} = this.state;
        let equipment = await getEquipment({applyId:applyId});
        if(equipment){
            pagination.total = equipment.total;
            this.setState({
                dataSource: equipment.data,
                pagination
            })
        }
    }
    onShowSizeChange = (pageNum, pageSize)=>{
        let {pagination} = this.state;
        pagination.current = pageNum;
        pagination.pageSize = pageSize;
        this.setState({
            pagination
        },()=>{
            this.getEquipment()
        })
    }

    pageChange = (pageNum) =>{
        let {pagination} = this.state;
        pagination.current = pageNum;
        this.setState({
            pagination
        },()=>{
            this.getEquipment()
        })
    }

    render() {
        const { dataSource, pagination, detail} = this.state;
        const columns = [
            {
                title: '序号', dataIndex: 'index', key: 'index', align: "center",width:"100px",
                render: (text,record,  index) => <p>{index+1}</p>
            },
            {title: '设备主板号', dataIndex: 'equipmentBoard', key: 'equipmentBoard', align: "center",},
            {title: '设备名称', dataIndex: 'equipmentName', key: 'equipmentName', align: "center",},
            {title: '设备分类', dataIndex: 'equipmentCategoryName', key: 'equipmentCategoryName', align: "center",},
            {title: '设备供应商', dataIndex: 'equipmentSupplier', key: 'equipmentSupplier', align: "center",},
            {title: '设备IP地址', dataIndex: 'ipAdress', key: 'ipAdress', align: "center",},
            {title: '上次扫描时间', dataIndex: 'vaLastScanDate', key: 'vaLastScanDate', align: "center",},
            {
                title: '操作', dataIndex: 'address', key: 'operation', align: "center", width: "200px", fixed: 'right',
                render: (text, record, index) => <p className='action vertical_j'>
                    <span className="btn-disabled">查看详情</span>
                </p>
            }
        ];
        return (
            <div className={'applayMgDetail training-warp'}>
                <h2 className={'training-tit'}>{detail.resourceName}资源下发申请单</h2>
                <div className="applayMg-header">
                        <p><span>申请学校名称：</span>{detail.orgName}</p>
                        <p><span>申请资源：</span>{detail.resourceName}</p>
                        <p><span>资 源 供 应 商：</span>{detail.resourceSupplier}</p>
                        <p><span>适配范围：</span>{detail.resourceRange}</p>
                        <p><span>申 请 者：</span>{detail.applyUserName}</p>
                        <p><span>申请时间：</span>{detail.createTime}</p>
                        <p><span>有效使用时间：</span>{detail.resourceBeginTime}{detail.resourceEndTime ? "～"+detail.resourceEndTime: ''}</p>
                        <p><span>设备所在位置：</span>辽宁省沈阳市沈北新区111楼1928室</p>
                </div>
                <div className="training-top">
                    <Col span={6}>
                        <Button type='primary'  className="btn-disabled" disabled={true}>导出</Button>
                    </Col>
                    <Col className='training-top-r' span={18}>
                        <label>该教室共有{pagination.total}硬件设备可以适配</label>
                    </Col>
                </div>
                <div className={'check_container training-table '} style={{position: 'relative'}}>
                    <MyTable
                        options={
                            {
                                dataSource,
                                columns,
                                pagination: {
                                    ...pagination,
                                    showSizeChanger: true,
                                    onChange: this.pageChange,
                                    onShowSizeChange: this.onShowSizeChange
                                }
                            }
                        }
                        isDrab={false}
                        fixededit={true}
                    />
                </div>
            </div>
        );
    }
}

export default Applaymg;
