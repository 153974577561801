import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import './index.scss';
import {Button, Col, Modal, Row, Form, message, Tooltip, Switch} from 'antd';
import MyTable from '@/components/Table';
import update from "immutability-helper";
import {ExclamationCircleFilled} from '@ant-design/icons';
import AddModel from "./components/index";
import {moduleList,deleteModule,hideModule,moduleSort} from '@/api/craftsman-app/index'

const {confirm} = Modal;
const mapStateToProps = ({CHECK}) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {};
}

class ConfigManage extends React.Component {
    state = {
        checkde:1,
        name: "",//方向名称 全模糊
        keyName: "",
        visible: false,
        isDrab: false,
        expandedRowKeys: [],
        selectedRowKeys: [],
        isHide:0,
        dataSource: [],
        moduleListData:{},  //表单数据
    }

    componentDidMount() {
        this.moduleList()
    }
    //首页板块列表
    async moduleList() {
        const res = await moduleList({})
        this.setState({
            dataSource: res ? res : '',
        }, () => {
            window.elGoTop && window.elGoTop('body')
        })
    }
    //删除版块
    delModule(id) {
        let that = this;
        Modal.confirm({
            title: '温馨提醒',
            content: '确认删除这条资源吗？',
            type: 'warning',
            isCancel: true,
            onOk: () => {
                deleteModule({moduleId: id}).then((res) => {
                    if (res.code === 0) {
                        message.success("删除成功");
                        that.moduleList();
                    }
                });
            },
        });
    }
    //刷新数据
    refreshData = () => {
        this.setState({
            visible: false,
        }, () =>this.moduleList())
    }
    //首页板块隐藏/显示板块
    isHideChange = (record)=>{
        hideModule({
            moduleId:record.moduleId,
            isHide:record.isHide===1?0:1
        }).then(res=>{
            this.moduleList()
        })
    }
    sort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
        })
    }
    //保存新排序
    async sortItem (){
        const data=this.state.dataSource
        for(var i=0;i<data.length;i++){
            data[i].moduleSort=i+1
        }
        let res = await moduleSort(data)
        if (res && res.code == 0) {
            message.success("排序成功")
        }
    }
    saveSort = () => {
        this.sortItem().then(()=>{
            this.setState({
                isDrab: !this.state.isDrab,
            })
            this.props.form.resetFields();
        });
    }
    cancelSort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
        })
    }
    //拖拽事件
    moveRow = (dragIndex, hoverIndex) => {
        const {dataSource} = this.state;
        // console.log("dataSource---",dataSource)
        let oldDataSource =JSON.parse(JSON.stringify(dataSource));
        const dragRow = dataSource[dragIndex];
        this.setState(
            update(this.state, {
                dataSource: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            }),()=>{
                //重新排序
                let newTempDataSource=JSON.parse(JSON.stringify(this.state.dataSource))
                for (var i=0;i<newTempDataSource.length;i++){
                    newTempDataSource[i].moduleSort=dataSource[i].moduleSort;
                }
                this.setState({dataSource:newTempDataSource})
            });
        this.setState({
            hasChanged: true,
        });
    };
    //新增板块
    addModel = (record) => {
        const {dataSource} = this.state
        if(dataSource.length >= 8 && !record.moduleId ){
           message.warning('最多可以为App的首页配置8个板块');
        }else {
            this.setState({
                visible: !this.state.visible,
                moduleListData:record,
            })
        }

    }
    //取消弹框
    checkCancel = () => {
        this.setState({
            visible: false,
            moduleListData:{}
        })
    }

    render() {
        const {pagination, isDrab, visible, dataSource} = this.state;
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 16},
        };
        const {getFieldDecorator} = this.props.form;
        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => <p>{index + 1}</p>
            },
            {title: '板块名称', dataIndex: 'moduleName', key: 'moduleName', align: 'center',width:'200px'},
            {title: '板块类别', dataIndex: 'moduleType', key: 'moduleType', align: 'center',
                render: (text, record) =>
                { //模块类别 1.课程
                    return (
                        <span>{(text===1 && "课程") || (text===2 && "资讯") || (text===3 && "读书")}</span>
                    )
                }
                },
            {
                title: '板块说明',
                dataIndex: 'moduleExplain',
                key: 'moduleExplain',
                align: 'center',
                width: '300px',
                ellipsis: {showTitle: false,},
                render: moduleExplain => (
                    <Tooltip placement="topLeft" title={moduleExplain}>
                        {moduleExplain}
                    </Tooltip>
                ),
            },
            {title: '状态', dataIndex: 'state', key: 'state', align: 'center',
                render: (text, record, index) =>
                {//是否隐藏 0否 1是
                    return(
                        <span>{(record.isHide===0 && "隐藏") || (record.isHide===1 && "显示") }</span>
                    )
                }
            },
            {title: '内容数量', dataIndex: 'moduleNum', key: 'moduleNum', align: 'center'},
            {
                title: '展现状态', dataIndex: 'isHide', key: 'isHide', align: 'center',
                render: (text, record, index) =>
                {//是否隐藏 0否 1是
                    return(
                        <Switch onChange={()=>this.isHideChange(record)}  checkedChildren="开启" unCheckedChildren="关闭" checked={record.isHide===1}/>
                        )
                }
            },
            {
                title: '操作', dataIndex: 'moduleId', key: 'moduleId', fixed: 'right', align: 'center',
                width: 245,
                render: (text, record, index) => <p className='action vertical_j'>
                   <span onClick={() => {this.delModule(record.moduleId)}}>删除</span>
                    <span onClick={() => this.addModel(record)}>编辑</span>
                </p>
            }
        ]
        return (
            <div className='publicstyle set_index'>
                <div className="padd">
                    <div className="top" style={{display: isDrab ? 'none' : 'flex'}}>
                        <Col span={12} className="sort_boxs">
                            <Button className='sure_sort' type='primary' onClick={() => this.addModel('')}>新增板块</Button>
                            <Button className='cancel_sort' onClick={() => this.sort()}>调整排序</Button>
                            <ExclamationCircleFilled style={{marginRight: '10px'}} className="sort1_img"/>
                            <span className='save_hyn'>说明：最多可以为App的首页配置8个板块</span>
                        </Col>
                    </div>
                    <div className="top" style={{display: isDrab ? 'flex' : 'none'}}>
                        <Col span={12}>
                            <Button className='sure_sort' type='primary' onClick={() => this.saveSort()}>保存排序</Button>
                            <Button className='cancel_sort' onClick={() => this.cancelSort()}>取消排序</Button>
                            <ExclamationCircleFilled style={{marginRight: '10px'}} className="sort1_img"/>
                            <span className='save_hyn'>拖拽排序后请保存</span>
                        </Col>
                    </div>
                    <div className="check_container"
                         style={{position: 'relative'}}>
                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                enumeration='moduleId'
                                className="index_block"
                                options={
                                    {
                                        dataSource,
                                        columns,
                                        pagination:false,
                                        onRow: (record, index) => ({
                                            index,
                                            moveRow: this.moveRow,
                                        })
                                    }
                                }

                                isDrab={isDrab}
                                fixededit={true}
                            />
                        </DndProvider>
                    </div>
                </div>
                <AddModel
                    visible={visible}
                    moduleListData={this.state.moduleListData}
                    checkCancel={this.checkCancel.bind(this)}
                    refreshData={this.refreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                ></AddModel>
            </div>
        )
    }
}


ConfigManage = connect(mapStateToProps, mapDispatchToProps)(ConfigManage);
ConfigManage = Form.create()(ConfigManage)
export default ConfigManage;
