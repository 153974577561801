import React,{useEffect,forwardRef} from 'react';
import { Modal, Form, Input, DatePicker, Radio } from 'antd';
import Moment from 'moment';

const FormItem = Form.Item;

const OpenModal = (props,ref) => {
    const {
        visible,onOk,onCancel,
        form:{
            getFieldDecorator,
            validateFields,
            resetFields,
            setFieldsValue,
            getFieldValue,
        }
    } = props;

    useEffect(()=>{
        if(!visible){
            resetFields();
        };
    },[resetFields, visible]);

    const Ok = async () => {
        try {
            const res = await validateFields();
            onOk(res);
        } catch (error) {}
    };

    const Cancel = () => {
        onCancel();
    };

    return (
        <Modal
            visible={visible}
            title="再次开通"
            className="global_modal"
            onOk={Ok}
            ref={ref}
            onCancel={Cancel}
            getContainer={false}
            okText="确认"
            okButtonProps={{size:'large'}}
            cancelButtonProps={{size:'large'}}
            bodyStyle={{
                height:260
            }}
        >
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                colon={false}
            >
                <FormItem label="开始时间">
                    {getFieldDecorator('startDate',{
                        rules: [
                            { 
                                required: true, 
                                message: "请选择开始时间！" 
                            },
                        ],
                        getValueFromEvent:(val) => {
                            const endDate = getFieldValue('endDate');
                            if(endDate && Moment(val) > Moment(endDate)){
                                setFieldsValue({endDate:val});
                                return endDate;
                            };
                            return val;
                        }
                    })(
                        <DatePicker 
                            style={{width:260}}
                            placeholder="请选择开始时间"
                            size='large'
                        />
                    )}
                </FormItem>
                <FormItem label="结束时间">
                    {getFieldDecorator('endDate',{
                        rules: [
                            { 
                                required: true, 
                                message: "请选择结束时间！" 
                            },
                        ],
                        getValueFromEvent:(val) => {
                            const startDate = getFieldValue('startDate');
                            if(startDate && Moment(val) < Moment(startDate)){
                                setFieldsValue({startDate:val});
                                return startDate;
                            };
                            return val;
                        }
                    })(
                        <DatePicker 
                            style={{width:260}}
                            placeholder="请选择结束时间"
                            size='large'
                        />
                    )}
                </FormItem>
                <FormItem label='接入方式'>
                    {getFieldDecorator("accessType", {
                        initialValue:0,
                        rules: [
                            { 
                                required: true, 
                                message: "请选择接入方式！" 
                            },
                        ],
                    })(
                        <Radio.Group>
                            <Radio value={0}>正式</Radio>
                            <Radio value={1}>试用</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem label='访问地址'>
                    {getFieldDecorator("url", {
                        rules: [
                            { 
                                required: true, 
                                message: "请输入链接地址！" 
                            },
                        ],
                    })(
                        <Input style={{width:330}} size="large" placeholder="请输入链接地址" maxLength={50}/>
                    )}
                </FormItem>
            </Form>
        </Modal>
    )
}

export default Form.create()(forwardRef(OpenModal))