import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { changeIndex } from '@store/OBS/main/action'
import { Dropdown, Menu } from 'antd';
import RouterView from '../../router/router_view';
// import KindName from 'classnames';
import { getRoles, getMenus } from '@/store/OBS/main/action.js'
import { quitLogin,getMenu,routersAuth } from '@/api/login/index.js';
import { schoolInfo } from '@/api/role';




// const { SubMenu } = Menu;
const mapStateToProps = ({ MAIN }) => {
    const { activeKey, buttonRole, menuList, headerMenuList } = MAIN;
    return {
        activeKey, buttonRole, menuList, headerMenuList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeIndex(data) {
            dispatch(changeIndex(data));
        },
        getRoles(data) {
            dispatch(getRoles(data));
        },
        getMenus(data) {
            dispatch(getMenus(data));
        }
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(class Main extends React.Component {userInfo
    state = {
        navList: [
            // {
            //     path: '/main/teaching/class',
            //     name: 'APP内容管理'
            // },
        ],
        thisPath: this.props.match.path,
        logoUrl:'',
        menuUrlList: []
    };

    componentDidMount(){
        this.schoolInfo()
        getMenu({ platform: "OBS" }).then(res => {
            if (res && this.props.location.pathname === '/main') {
                let o = res[0];
                if (!o) return;
                let url = o.menuUrl;
                this.props.history.push(url);
            }
            routersAuth({ platform: "OBS" }).then(data => {
                this.setState({
                    menuUrlList: data
                })
            })
        })
    }


    getMenuUrlList(tree) {
        let menuList = [];
        tree.forEach(e => {
            fn(e);
            function fn(obj) {
                if (obj.menuUrl) {
                    menuList.push(obj.menuUrl);
                }
                if (obj.children && obj.children.length > 0) {
                    obj.children.forEach(c => {
                        fn(c);
                    })
                }
            }
        })
        return menuList;
    }
    getRouter(hash) {
        if (hash.indexOf("?") < 0) {
            return hash.substring(1);
        } else {
            return hash.substring(1, hash.indexOf("?"));
        }
    }
    componentDidUpdate(prevProps,prevState,snapshot) {
        let hash = this.getRouter(window.location.hash);
        let {menuUrlList} = this.state;
        // if (menuUrlList.length > 0) {
        //     let result = menuUrlList.find(item => {
        //         return hash.includes(item);
        //     });
        //     if (!result) {
        //         console.log("跳转到无权限访问页  无权限路由：" + hash)
        //         this.props.history.push("/reporterrorFour");
        //     }
        // }
    }
    getQueryString(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
    }
    //get school info
    schoolInfo = () => {
        let info = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
        schoolInfo(info?.mainOrgId).then(res=>{
            if(res){
                this.setState({
                    logoUrl: res.icon && window.$$imgSrc(JSON.parse(res.logo).imgValue)
                })
            }
        })
    }

    change = (item) => {
        // this.props.changeIndex(item.key);
        if(item.children[0]?.children[0]?.menuUrl){
            return  this.props.history.push(item.children[0].children[0].menuUrl);

        }else if(item.children[0]?.menuUrl){
            return  this.props.history.push(item.children[0].menuUrl);

        }else {
            this.props.history.push(item.menuUrl);

        }
    }
    change1 = (item) => {
        this.props.history.push(`/main/craftsman-app`)
    }
    loginOut = () => {
        quitLogin({ type: 'PC' }).then(res => {
            console.log(res)
            if (res.code === 0) {
                localStorage.clear();
                this.props.history.replace('/login');
            }
        })
    }
    render() {
        const { activeKey, menuList, headerMenuList, } = this.props;


        const menu = (
            <Menu className='menudown'>
                <Menu.Item key="1" onClick={() => this.props.history.push('/main/editPassword-list/password')}>
                    修改密码
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={this.loginOut} key="3">退出登录</Menu.Item>
            </Menu>
        );
        const pathname = this.props.location.pathname;
        return (
            <div className='main_container'>
                <div className='header_menu'>
                    <div className='menu_left'>
                    <img src={this.state.logoUrl} alt="" />

                        {menuList.map((v, i) => {
                            return <div key={i} className={pathname.includes(v.menuUrl) ? 'active' : ''} onClick={() => this.change(v)}>{v.menuName}</div>
                        })}
                        {this.state.navList.map((v, i) => {
                            return <div key={i} className={pathname.includes(v.path) ? 'active' : ''} onClick={() => this.change1(v)}>{v.name}</div>
                        })}
                    </div>
               
                    <Dropdown overlay={menu} className='menu_right'>
                        <div onClick={e => e.preventDefault()}>
                            <img className='usericon' src={require('@/assets/img/icon-user.png')} />
                            <div className="user-right">
                                <h2 className="username">管理员</h2>
                                <p className="userinfo">admin</p>
                            </div>
                        </div>
                    </Dropdown>
                </div>
                <div className='container'>
                    <RouterView routers={this.props.routers} />
                </div>
            </div>
        )
    }
})
