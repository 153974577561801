import { post } from '@/until/axios_instans'

//查询用到的所属分类下拉列表接口
export const queryTableList = (data) => {
    return post('/api/ucenter/api/v1/enterprise/getAdminOrgPage',data);
};

// 省下拉列表
export const queryProvince = (data) => {
    return post("/api/gongyong/v1/cvecregion/province", data);
};

// 市下拉列表
export const queryCity = (data) => {
    return post("/api/gongyong/v1/cvecregion/city", data);
};

//文件上传
export const uploadFile = (data) => {
    return post("api/fujian/api/v1/fujian/uploadFile",data)
};

//批量添加下级机构
export const batchAddSubOrg = (data) => {
    return post("/api/ucenter/api/v1/enterprise/batchAddSubOrg",data)
};

//管理机构信息保存
export const updataManageMechanism = (data) => {
    return post("/api/ucenter/api/v1/enterprise/updateAdminOrgInfo",data)
};

//管理机构信息录入
export const addManageMechanism = (data) => {
    return post("/api/ucenter/api/v1/enterprise/enterAdminOrg",data)
};

//管理机构信息回显
export const updataManageMechanismInfo = (data) => {
    return post("/api/ucenter/api/v1/enterprise/getAdminOrgInfoEcho",data)
};

//管理机构信息详情
export const infoManageMechanism = (data) => {
    return post("/api/ucenter/api/v1/enterprise/getAdminOrgDetail",data)
};

//机构配置应用列表分页
export const queryConfigApplicationTable = (data) => {
    return post("/api/ucenter/api/v1/enterpriseApplication/selectApplications",data)
};

//修改应用连接
export const editApplicationUrl = (data) => {
    return post("/api/ucenter/api/v1/application/editApplicationUrl",data)
};

//转为正式应用
export const formalApplication = (data) => {
    return post("/api/ucenter/api/v1/application/formalApplication",data)
};

//统计机构配置应用
export const statisticApplication = (data) => {
    return post("/api/ucenter/api/v1/application/statisticApplication",data)
};

//机构或学校停用应用
export const stopApplications = (data) => {
    return post("/api/ucenter/api/v1/enterpriseApplication/stopApplications",data)
};

//机构应用开通记录
export const applicationRecord = (data) => {
    return post("/api/ucenter/api/v1/enterpriseApplication/applicationRecord",data)
};

//机构应用延期
export const appAccessManageDelay = (data) => {
    return post("/api/ucenter/api/v1/application/appAccessManageDelay",data)
};

//机构应用再次开通
export const configAgainApplication = (data) => {
    return post("/api/ucenter/api/v1/application/configAgainApplication",data)
};