import React, { Component } from 'react'
import './index.scss';
import { Form, Select, Input, Radio, Row, Col, Upload, Button, message, DatePicker, Space, Tag  } from 'antd';
import { getProvinceSel, getCitySel, selectSchoolLabel, addItem, globalSel, getSchoolDetail, editItem } from '@/api/platform-business/index.js';
import ImgCrop from 'antd-img-crop';
import setName from 'classnames';
import moment from "moment";
import UploadImg from '../../../../components/enterprise_upload_img/index.js';
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

// 我的标签定义数据
const { CheckableTag } = Tag;
class index extends Component {
    state = {
        selectedTags: [], // 我的标签选择数据
        imageUrl: '',
        loading: false,
        tagsData: [],
        fangshiSel: [],
        cengciSel: [],
        yuanxiaoSel: [],
        bumenSel: [],
        provinceSel: [],
        citySel: [],
        districtSel: [],
        ruzhuRad: [],
        fanweiRad: [],
        provinceCode: '',
        cityCode: '',
        provinceName: null,
        cityName: null,
        areaName: null,
        oftenFlag: true,
        yxmc: 0,
        yxjc: 0,
        ywmc: 0,
        ywjc: 0,
        zgbm: 0,
        xx: 0,
        xxdz: 0,
        lxr: 0,
        lxsj: 0,
        yxjj: 0,
        xxwz: 0,
    }
    componentDidMount() {
        this.getFangshiSel();    //办学方式下拉框
        this.getCengciSel();    //办学层次下拉框
        this.getyuanxiaoLeixingSel(); //院校类型下拉框
        this.getbumenSel();     //管理部门下拉框
        this.getProvinceSel();  //省下拉框
        this.schoolLabel();  //省下拉框
        this.init();
    }
    // 我的标签--选择
    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t.id !== tag.id);
        this.setState({ selectedTags: nextSelectedTags });
    }
    //信息回显
    init = () => {
        let enterpriseId = sessionStorage['enterpriseId'];
        if (enterpriseId) {
            getSchoolDetail({ enterpriseId }).then(res => {
                console.log(res)
                if (res) {
                    this.setState({
                        provinceCode: res.provinceId,
                        cityId: res.cityId,
                        selectedTags: res.labelList,
                        isOpenDomain: res.isOpenDomain,
                    }, () => {
                        this.getCitySel();
                    })
                    this.setState({
                        yxmc: res.enterpriseName && res.enterpriseName.length,
                        yxjc: res.enterpriseCode && res.enterpriseCode.length,
                        ywmc: res.enName && res.enName.length,
                        ywjc: res.enShorterForm && res.enShorterForm.length,
                        zgbm: res.departmentName && res.departmentName.length,
                        xx: res.schoolMotto && res.schoolMotto.length,
                        xxdz: res.detailAddress && res.detailAddress.length,
                        xxwz: res.websiteAdd && res.websiteAdd.length,
                        yxjj: res.simpleDesc && res.simpleDesc.length
                    })
                    this.props.form.setFieldsValue({
                        enterpriseName: res.enterpriseName,
                        enterpriseCode: res.enterpriseCode,
                        shorterForm: res.shorterForm,
                        enName: res.enName,
                        detailAddress: res.detailAddress,
                        schoolMotto: res.schoolMotto,
                        websiteAdd: res.websiteAdd,
                        departmentName: res.departmentName,
                        enterpriseDomain: res.enterpriseDomain,
                        foundedTime: res.foundedTime ? moment(res.foundedTime, 'YYYY-MM-DD') : null,
                        settledDate: res.settledDate ? moment(res.settledDate, 'YYYY-MM-DD') : null,
                        settledEndDate: res.settledEndDate ? moment(res.settledEndDate, 'YYYY-MM-DD') : null,
                        simpleDesc: res.simpleDesc,
                        labelList: res.labelList,
                        schoolType: Number(res.schoolType),
                        manageRange: res.manageRange,
                        schoolRunMode: res.schoolRunMode,
                        schoolRunLevel: res.schoolRunLevel,
                        isOpenDomain: res.isOpenDomain,
                        cityId: res.cityId ? res.cityId : undefined,
                        provinceId: res.provinceId ? res.provinceId : undefined,
                        enterpriseLogo: res.enterpriseLogo
                    });
                }
            })
        }
    }

    //可选标签列表
    schoolLabel = async () => {
        let res = await selectSchoolLabel();
        res && this.setState({ tagsData: res });
    }
    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //管理部门Sel
    getbumenSel = async () => {
        let res = await globalSel({ type: 6 });
        res && this.setState({ bumenSel: res });
    }
    //办学方式Sel
    getFangshiSel = async () => {
        let res = await globalSel({ type: 2 });
        res && this.setState({ fangshiSel: res });
    }
    //办学层次Sel
    getCengciSel = async () => {
        let res = await globalSel({ type: 3 });
        res && this.setState({ cengciSel: res });
    }
    //院校类型Sel
    getyuanxiaoLeixingSel = async () => {
        let res = await globalSel({ type: 4 });
        res && this.setState({ yuanxiaoSel: res });
    }
    //入驻类型radio
    getRuzhuRad = async () => {
        let res = await globalSel({ type: 1 })
        res && this.setState({ ruzhuRad: res });
    }
    //数据范围radio
    getfanweiRad = async () => {
        let res = await globalSel({ type: 10 })
        res && this.setState({ fanweiRad: res });
    }
    handleImgChange= (fileId, mediaType) =>{
        console.log("handleImgChange-fileId",fileId)
        console.log("handleImgChange-mediaType",mediaType)
        this.props.form.setFieldsValue({"enterpriseLogo":fileId})
        this.setState({
            fileId,
            mediaType
        })
    }
    //保存
    save = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { provinceName, cityName, areaName, oftenFlag,schoolRunModeName,schoolRunLevelName,schoolTypeName,selectedTags } = this.state;
                let type = sessionStorage['school_type'];
                console.log('values', values)
                if (values.settledDate.isAfter(values.settledEndDate)) {
                    return this.mesWarning('开始日期不能小于结束日期')
                }
                if (type === 'add') {                               //add代表新增院校
                    if (oftenFlag) {
                        this.setState({ oftenFlag: false });
                        addItem(
                            {
                                ...values,
                                provinceName,
                                cityName,
                                areaName,
                                schoolRunModeName,
                                schoolRunLevelName,
                                schoolTypeName,
                                labelList: selectedTags
                            }
                        ).then(res => {
                            if (res) {
                                this.mesSuccess('保存成功');
                                this.props.history.push('/main/platform-business/in-school');
                            }
                        })
                        setTimeout(() => this.setState({ oftenFlag: true }), 2500)
                    }
                } else if (type === 'edit') {                           //edit代表编辑院校
                    editItem({
                        ...values,
                        provinceName,
                        cityName,
                        areaName,
                        schoolRunModeName,
                        schoolRunLevelName,
                        schoolTypeName,
                        id: sessionStorage['enterpriseId'],
                        labelList: selectedTags
                    }).then(res => {
                        if (res) {
                            this.mesSuccess('保存成功');
                            this.props.history.push('/main/platform-business/in-school');
                        }
                    })
                }
            }
        })
    }
    //省下拉框change
    changeProvince = (e, item) => {
        let { props: { children } } = item;
        this.props.form.setFieldsValue({ cityId: undefined, areaId: undefined, areaRange: '' });
        this.setState({ provinceCode: e, cityName: '', areaName: '', provinceName: children, citySel: [], districtSel: [] }, () => this.getCitySel());
    }
    //所属省市下拉框change
    changeManageRange = (e) => {
        this.setState({ manageRange: e });
    }
    //市下拉框change
    changeCity = (e, item) => {
        let { props: { children } } = item;
        this.props.form.setFieldsValue({ areaId: undefined, areaRange: '' });
        this.setState({ cityCode: e, cityName: children, areaName: '', districtSel: [] });
    }
    setLen = val => {
        this.setState(val);
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        let { imageUrl, loading, yuanxiaoSel, fangshiSel, cengciSel, tagsData, provinceSel, citySel, isOpenDomain } = this.state;
        const props = {
            //aspect:160/88,
            resize: true, //裁剪是否可以调整大小
            resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
            modalTitle: "上传图片", //弹窗标题
            modalWidth: 600, //弹窗宽度
            rotate: true,
            width: 160,  //裁剪宽度
            height: 88, //裁剪高度
        };
        const addDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/add_icon.png')} alt="" />
            </div>
            <span>上传logo</span>
        </div>
        const lodingDiv = <div className='add_image_div'>
            <div>
                <img src={require('@/assets/img/loading_icon.png')} alt="" />
            </div>
            <span>请稍后...</span>
        </div>
        let { yxmc, yxjc, ywmc, zgbm, xx, xxdz, xxwz, yxjj, selectedTags } = this.state;
        return (
            <div className='add_school_box'>
                <div className="title">
                    院校基本信息
                </div>
                <Form>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>学校名称</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseName', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '院校名称不能为空'
                                            }
                                        ]
                                    })(
                                        <Input placeholder='请输入院校名称'
                                            onChange={e => this.setLen({ yxmc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxmc === 0 ? 'zero' : '')}>{yxmc}</span>/50</span>} maxLength={50} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>学校标识</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseCode', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '学校标识不能为空'
                                            }
                                        ]
                                    })(
                                        <Input
                                            placeholder='请输入学校标识'
                                            onChange={e => this.setLen({ yxjc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxjc === 0 ? 'zero' : '')}>{yxjc}</span>/15</span>}
                                            maxLength={15} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>院校简称</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('shorterForm', {
                                        initialValue: '',
                                    })(
                                        <Input
                                            placeholder='请输入院校简称'
                                            onChange={e => this.setLen({ yxjc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', yxjc === 0 ? 'zero' : '')}>{yxjc}</span>/20</span>}
                                            maxLength={20} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>英文名称</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enName', {
                                        initialValue: ''

                                    })(
                                        <Input
                                            placeholder='请输入英文名称'
                                            onChange={e => this.setLen({ ywmc: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', ywmc === 0 ? 'zero' : '')}>{ywmc}</span>/30</span>}
                                            maxLength={30} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>学校logo</span></Col>
                            <Col span={3} style={{ marginRight: '30px' }}>
                                {
                                    getFieldDecorator('enterpriseLogo', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '院校徽章不能为空'
                                            }
                                        ]
                                    })(
                                      <UploadImg
                                        handleImgChange={this.handleImgChange}
                                        html='上传封面'/>
                                    )
                                }
                            </Col>
                            <Col span={5}>
                                <p className='tishi'>支持jpg/png/jpeg格式，</p>
                                <p className='tishi'>建议最佳尺寸160x88px，不超过5M</p>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>主管部门</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('departmentName', {
                                        initialValue: ''

                                    })(
                                        <Input
                                            placeholder='请输入主管部门'
                                            onChange={e => this.setLen({ zgbm: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', zgbm === 0 ? 'zero' : '')}>{zgbm}</span>/50</span>}
                                            maxLength={50} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>创办时间</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('foundedTime', {
                                        initialValue: ''

                                    })(
                                        <DatePicker placeholder='请选择学校创办时间' picker="year"  style={{width: '100%'}} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>学校类型</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('schoolType', {
                                        initialValue: undefined,
                                        // rules: [
                                        //     {
                                        //         required: true,
                                        //         message: '院校类型不能为空'
                                        //     }
                                        // ]
                                    })(
                                        <Select placeholder='请选择院校类型' onChange={(e,item) => {
                                            this.setState({schoolTypeName: item.props.children})
                                        }}>
                                            {
                                                yuanxiaoSel && yuanxiaoSel.map(v => {
                                                    return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>所属省/市</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('manageRange', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '所属省/市不能为空'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择所属省/市'
                                                onChange={this.changeManageRange}>
                                            {
                                                provinceSel && provinceSel.map(v => {
                                                    return <Option value={v.provinceCode} key={v.provinceCode}>{v.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Row>
                        <Col span={2}>
                            <span style={{ position: 'relative', top: '10px' }}><span className='red'>*</span>所在地</span>
                        </Col>
                        <Col className='location_box' span={16}>
                            <Form.Item>
                                {
                                    getFieldDecorator('provinceId', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '所在省不能为空'
                                            }
                                        ]
                                    })(
                                        <Select
                                            onChange={this.changeProvince}
                                            placeholder='请选择省'>
                                            {
                                                provinceSel.map(v => <Option key={v.provinceCode} value={v.provinceCode}>{v.name}</Option>)
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                {
                                    getFieldDecorator('cityId', {
                                        initialValue: undefined
                                    })(
                                        <Select
                                            onChange={this.changeCity}
                                            placeholder='请选择市'
                                        >
                                            {
                                                citySel.map(v => <Option key={v.cityCode} value={v.cityCode}>{v.name}</Option>)
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>办学层次</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('schoolRunLevel', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '办学层次不能为空'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择办学层次' onChange={(e,item) => {
                                            this.setState({schoolRunLevelName: item.props.children})
                                        }}>
                                            {
                                                cengciSel && cengciSel.map(v => {
                                                    return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>办学方式</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('schoolRunMode', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '办学方式不能为空'
                                            }
                                        ]
                                    })(
                                        <Select placeholder='请选择办学方式' onChange={(e,item) => {
                                            this.setState({schoolRunModeName: item.props.children})
                                        }}>
                                            {
                                                fangshiSel && fangshiSel.map(v => {
                                                    return <Option value={v.code} key={v.code}>{v.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>学校地址</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('detailAddress', {
                                        initialValue: ''
                                    })(
                                        <Input
                                            placeholder='请输入学校地址'
                                            onChange={e => this.setLen({ xxdz: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', xxdz === 0 ? 'zero' : '')}>{xxdz}</span>/50</span>}
                                            maxLength={50} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>校训</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('schoolMotto', {
                                        initialValue: ''

                                    })(
                                        <Input
                                            placeholder='请输入院校校训'
                                            onChange={e => this.setLen({ xx: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', xx === 0 ? 'zero' : '')}>{xx}</span>/30</span>}
                                            maxLength={30} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>院校简介</span></Col>
                            <Col style={{ position: 'relative' }} span={10}>
                                <span className='textarea_len'>
                                    <span className={setName('current_len', yxjj === 0 ? 'zero' : '')}>{yxjj}</span>/200
                                </span>
                                {
                                    getFieldDecorator('simpleDesc', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '院校简介不能为空'
                                            }
                                        ]
                                    })(
                                        <TextArea
                                            placeholder='请输入院校简介'
                                            onChange={e => this.setLen({ yxjj: e.target.value.length })}
                                            maxLength={200}
                                            style={{ resize: 'none' }} rows={5} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>学校网址</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('websiteAdd', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                pattern: /(?:(https?|ftp|file):)?\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/,
                                                message: '请输入合法的地址'
                                            }
                                        ]
                                    })(
                                        <Input
                                            placeholder='请输入官网URL地址'
                                            onChange={e => this.setLen({ xxwz: e.target.value.length })}
                                            suffix={<span className='len'><span className={setName('current_len', xx === 0 ? 'zero' : '')}>{xxwz}</span>/50</span>}
                                            maxLength={50}
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'></span>二级域名</span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('isOpenDomain', {
                                        initialValue: ''
                                    })(
                                        <Radio.Group style={{ width: '100%' }} onChange={e => this.setState({ isOpenDomain: e.target.value })}>
                                            <Radio value={0}>不启动</Radio>
                                            <Radio value={1}>启动</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    {isOpenDomain === 1?<Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'/></span></Col>
                            <Col span={10}>
                                {
                                    getFieldDecorator('enterpriseDomain', {
                                        initialValue: '',
                                    })(
                                      <Input addonBefore="https://" addonAfter=".cveducloud.com"/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>: null}
                    {/*<Form.Item>*/}
                    {/*    <Row>*/}
                    {/*        <Col span={2}><span><span className='red'>*</span>到期日期</span></Col>*/}
                    {/*        <Col span={10}>*/}
                    {/*            {*/}
                    {/*                getFieldDecorator('settledEndDate', {*/}
                    {/*                    initialValue: '',*/}
                    {/*                    rules: [*/}
                    {/*                        {*/}
                    {/*                            required: true,*/}
                    {/*                            message: '请选择到期日期'*/}
                    {/*                        }*/}
                    {/*                    ]*/}

                    {/*                })(*/}
                    {/*                    <DatePicker placeholder='请选择到期日期' style={{width: '100%'}} />*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Form.Item>*/}
                    <Form.Item>
                        <Row>
                            <Col span={2}><span><span className='red'>*</span>开通信息</span></Col>
                            <Col span={5}>
                              <Form.Item>
                                <Row className='is-data_wapper'>
                                    <Col span={5}><span><span className='red'>*</span>开始日期</span></Col>
                                    <Col span={17}>
                                        {
                                            getFieldDecorator('settledDate', {
                                                initialValue: '',
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: '请选择到期日期'
                                                  }
                                              ]
                                            })(
                                                <DatePicker />
                                            )
                                        }
                                    </Col>

                                </Row>
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item>
                                <Row className='is-data_wapper'>
                                    <Col span={5}><span><span className='red'>*</span>结束日期</span></Col>
                                    <Col span={17}>
                                        {
                                            getFieldDecorator('settledEndDate', {
                                                initialValue: '',
                                              rules: [
                                                {
                                                  required: true,
                                                  message: '请选择到期日期'
                                                }
                                              ]
                                            })(
                                                <DatePicker />
                                            )
                                        }
                                    </Col>
                                </Row>
                              </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                    <div className="mini_title title">
                        <span>我的标签</span>
                        <span className='tabButton tabTip'>提示：您最多添加4个标签</span>
                    </div>
                    <Form.Item>
                        <Row>
                            <Col span={12} className='tabItem'>
                                {tagsData.map(tag => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={selectedTags.find(e => e.id === tag.id)}
                                        onChange={checked => this.handleChange(tag, checked)}
                                    >
                                        {tag.labelName}
                                    </CheckableTag>
                                ))}
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
                <div className="fix_controll">
                    <Button className='add_btn_j' onClick={this.save} style={{ marginRight: '40px', width: '100px', height: '39px' }}>保存</Button>
                    <Button onClick={() => this.props.history.goBack()} className='dr_btn_j' style={{ width: '90px', height: '39px' }} >取消</Button>
                </div>
            </div>
        )
    }
}


index = Form.create()(index);
export default index;
