/**
 * 我的直播
 */

import {  post, get } from "@/until/axios_instans";

//获取签名
export function getUserSign(data) {
    return get("/api/zhibo/api/v1/live/liveRoom/getGenSig", data);
}
//进入聊天室
export function getGroup(data) {
    return post("/api/zhibo/api/v1/live/newTask/getGroupId", data);
}
//获取群组成员
export function getGroupInfo(data) {
    return post("/api/zhibo/api/v1/live/newTask/getGroupInfo", data);
}
//获取群组成员（分页）
export function getGroupInfoPage(data) {
    return post("/api/zhibo/api/v1/live/newTask/getGroupInfoPage", data);
}

//保存聊天记录
export function saveChatMessage(data) {
    return post("/api/zhibo/api/v1/live/chat/saveChatMessage", data);
}

//删除聊天记录
export function deleteChatRecords(data) {
    return post("/api/zhibo/api/v1/live/chat/deleteChatRecords", data);
}

//获取appid
export function getSdkAppId(data) {
    return post("/api/zhibo/api/v1/live/newTask/getSdkAppId", data);
}

//进入直播间
export function joinLiveRoom(data) {
    return post("/api/zhibo/api/v1/live/activity/joinLiveRoom", data);
}

//举手接口
export function handsUp(data) {
    return post("/api/zhibo/api/v1/live/activity/handsUp", data);
}

//取消举手接口
export function cancelhandsUp(data) {
    return post("/api/zhibo/api/v1/live/activity/cancelHandsUp", data);
}
//查询直播资源
export function selectLiveResource(data) {
    return post("/api/zhibo/api/v1/live/resource/selectLiveResource", data);
}

//结束连麦接口
export function connectEnd(data) {
    return post("/api/zhibo/api/v1/live/activity/endLianMai", data);
}
//结束活动
export function closeActivity(data) {
    return post("/api/zhibo/api/v1/live/activity/closeActivity", data);
}



export function report(data) {
    return post("/api/v1/sdk/gongyong/tip/tipOff", data);
}

//连麦接口
export function connect(data) {
    return post("/api/zhibo/api/v1/live/activity/lianMai", data);
}

export function getVoteInfo(data) {
    return post("/api/zhibo/api/v1/live/activity/getVoteInfo",data);
}

//获取签名
export function getGenSig(data) {
    return post("/api/zhibo/api/v1/live/newTask/getGenSig", data);
}
//参加投票接口
export function attendVote(data) {
    return post("/api/zhibo/api/v1/live/activity/attendVote", data);
}

//查询直播聊天记录
export function getChatMsgForLive(data) {
    return post("/api/zhibo/api/v1/live/chat/getChatMsgForLive", data);
}
//创建直播活动并开启
export function createActivity(data) {
    return post("/api/zhibo/api/v1/live/activity/createActivity", data);
}

//获取举报条数
export function getReportNumbers(data) {
    return post("/api/gongyong/api/v1/sdk/gongyong/tip/tipOffUser/getReportNumbersForLive", data);
}


//暂停直播
export function pauseLive(data) {
    return post("/api/zhibo/api/v1/live/newTask/pauseLive", data);
}

//恢复直播
export function continueLive(data) {
    return post("/api/zhibo/api/v1/live/newTask/continueLive", data);
}

export function getTipOffPage(data) {
    return post("/api/gongyong/api/v1/sdk/gongyong/tip/tipOff/getTipOffPage", data);
}

//强制结束，冻结直播
export function frozenAndForceEndLive(data) {
    return post("/api/zhibo/api/v1/live/manage/currency/frozenAndForceEndLive", data);
}
//查询常用语
export function selectCommonWords(data) {
    return post("/api/zhibo/api/v1/live/manage/currency/selectCommonWords", data);
}
//删除常用语
export function deleteCommonWords(data) {
    return post("/api/zhibo/api/v1/live/manage/currency/deleteCommonWords", data);
}
//添加常用语
export function addCommonWords(data) {
    return post("/api/zhibo/api/v1/live/manage/currency/addCommonWords", data);
}

//举报记录
export function getReportersByType(data) {
    return post("/api/gongyong/api/v1/sdk/gongyong/tip/tipOffUser/getReportersByType", data);
}

//获取投票列表
export function getVoteList(data){
    return post('/api/zhibo/api/v1/live/activity/getVoteList',data)
}

//获取投票统计
export function getVoteStatDetail(data){
    return post('/api/zhibo/api/v1/live/activity/getVoteStatDetail',data)
}

//获取聊天记录
export function getChatMsg(data){
    return post('/api/zhibo/api/v1/live/chat/getChatMsgForLive',data)
}

//开始连麦
export function connectMicrophone(data) {
    return post("/api/zhibo/api/v1/live/newTask/connectMicrophone", data);
}
//开启直播(WEB)
export function newStartCloseLives(data) {
    return post("/api/zhibo/api/v1/live/newTask/newStartCloseLives", data);
}
// 结束直播任务
export function endLiveTask(data) {
    return post("/api/zhibo/api/v1/live/newTask/endLiveTask", data);
}

//管理员隐身进入直播间
export function adminJoinLiveRoom(data) {
    return post("/api/zhibo/api/v1/live/manage/currency/adminJoinLiveRoom", data);
}

//举报管理列表

export function getLiveReportPage(data) {
    return post("/api/zhibo/api/v1/live/manage/report/getLiveReportPage", data);
}


//禁言
export function studentBanSpeech(data) {
    return post("/api/zhibo/api/v1/live/newTask/studentBanSpeech", data);
}
//踢出直播间
export function removeUser(data) {
    return post("/api/zhibo/api/v1/live/newTask/removeUser", data);
}
//禁止进入直播间
export function studentIsJoinIn(data) {
    return post("/api/zhibo/api/v1/live/newTask/studentIsJoinIn", data);
}
//启动云端混流
export function startMCUMixTranscode(data) {
    return post("/api/zhibo/api/v1/live/newTask/startMCUMixTranscode", data);
}
//关闭云端混流
export function stopMCUMixTranscode(data) {
    return post("/api/zhibo/api/v1/live/newTask/stopMCUMixTranscode", data);
}
//退出群
export function deleteGroupMember(data) {
    return get("/api/zhibo/api/v1/live/liveRoom/deleteGroupMember", data);
}
//获取直播回放列表
export function getVideoList(data) {
    return get("/api/zhibo/api/v1/live/liveRoom/getVideoList", data);
}
//校验直播任务是否存在
export function verificationLiveStatus(data) {
    return get("/api/zhibo/api/v1/live/liveRoom/webVerificationLive", data);
}

//直播间互动
export function interaction(data) {
    return post("/api/zhibo/api/v1/live/activity/interaction", data);
}
//结束连麦
export function endLianMai(data) {
    return post("/api/zhibo/api/v1/live/activity/endLianMai", data);
}
//连麦
export function lianMai(data) {
    return post("/api/zhibo/api/v1/live/activity/lianMai", data);
}
//禁止（开启）举手
export function forbidHandsUp(data) {
    return post("/api/zhibo/api/v1/live/activity/forbidHandsUp", data);
}
//清空举手
export function emptyHandsUp(data) {
    return post("/api/zhibo/api/v1/live/activity/emptyHandsUp", data);
}

//直播任务列表(教师)
export function getTeacherLiveTaskList(data) {
    return post("/api/zhibo/api/v1/live/newTask/getTeacherLiveTaskList", data);
}

//直播任务列表(学生)
export function getStudentLiveTaskList(data) {
    return post("/api/zhibo/api/v1/live/newTask/getStudentLiveTaskList", data);
}

//学生报名
export function studentEnroll(data) {
    return post("/api/zhibo/api/v1/live/newTask/studentEnroll", data);
}

//excel导出消息记录
export function exportChatMessage(data) {
    return get("/api/zhibo/api/v1/live/chat/exportChatMessage", data);
}

//删除直播任务
export function deleteLiveTask(data) {
    return post("/api/zhibo/api/v1/live/newTask/deleteLiveTask", data);
}

//新增直播任务
export function addLiveTask(data) {
    return post("/api/zhibo/api/v1/live/newTask/addLiveTask", data);
}

//修改直播详情
export function updateLiveDetail(data) {
    return post("/api/zhibo/api/v1/live/newTask/updateLiveDetail", data);
}

//获取当前任务的直播统计数据
export function getDataSet(data){
    return post("/api/zhibo/api/v1/live/stat/getDataStat", data);
}

//获取直播详情(教师)
export function getLiveDetail(data) {
    return post("/api/zhibo/api/v1/live/newTask/getLiveDetail", data);
}
//直播任务详情（学生）
export function getStudentLiveDetail(data) {
    return post("/api/zhibo/api/v1/live/newTask/getStudentLiveDetail", data);
}
// 查询回放聊天记录
export function getChatMsgForDanmu(data) {
    return post("/api/zhibo/api/v1/live/chat/getChatMsgForDanmu", data);
}
export function showThumbnail(id) {
    return get(`/api/media/api/v1/media/showImage1/${id}`);
}

export function downloadFile(id) {
    return get(`/api/media/api/v1/media/download/${id}`);
}

//管理直播任务列表
export function getLiveTaskList(data) {
    return post("/api/zhibo/api/v1/live/manage/report/getLiveTaskList", data);
}
//查询已处理记录
export function getHandledRecords(data) {
    return post("/api/zhibo/api/v1/live/manage/handled/getHandledRecords", data);
}

//查询已处理举报任务列表
export function getHandledTaskList(data) {
    return post("/api/zhibo/api/v1/live/manage/handled/getHandledTaskList", data);
}

// 直播详情-教师
export function getTeacherLiveDetail(data) {
    return post("/api/zhibo/api/v1/live/newTask/getLiveDetail", data);
}

// 上传直播资源
export function uploadLiveResource(data) {
    return post("/api/zhibo/api/v1/live/resource/uploadLiveResource", data);
}

// 修改直播资源
export function updateLiveResource(data) {
    return post("/api/zhibo/api/v1/live/resource/updateLiveResource", data);
}

// 删除直播资源
export function deleteLiveResource(data) {
    return post("/api/zhibo/api/v1/live/resource/deleteLiveResource", data);
}
// 根据id修改学生是否能观看直播回放
export function updateIsStudentLook(data) {
    return post("/api/zhibo/api/v1/live/liveplayback/updateIsStudentLook", data);
}
//获取院校
export function getEnterpriseList() {
    return post("/api/ucenter/api/v1/enterprise/findSchools");
}
