import React, { Fragment } from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/check/action';
import './index.scss';
import {Button, Table, Input, Col, Checkbox, Tag, Form, DatePicker, message, Select, Modal,} from 'antd';
import kindName from 'classnames';
import MyTable from '@/components/Table';
import {ExclamationCircleOutlined} from "@ant-design/icons";

// import {wholeCircleList,circleList,closedCircle,hasAuditList,noAuditList,blackList,orgList,} from '@/api/circle/index'
import {
    blackList,
    GrayList, 
    WhiteList,

    showGrayUserComment, 
    showBlackUserComment, 
    showWhiteUserComment,
    moveGrayList,
    removeBlackList,
    removeGrayList,
    moveBlackList,
    whiteMoveGrayList,
    whiteMoveBlackList, 
    
} from '@/api/circle/index'

import moment from 'moment';

const {Option} = Select
// const {RangePicker} = DatePicker;
const { Column } = Table;

const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}


class Blacklist extends React.Component {
    state = {
        btnDisabled:true,  //重置按钮
        _listType:0,		//名单类型 0黑(默认)，1灰，2白
        _blackListName:'', 	//人员名称
        _orgId:'', 	//所属机构
        _blackListData:[],  //名单列表数据
        _blackListNumber:'',    //总页数
        //_wholeCircleNumber:'',  // 全部圈子
        //_closeCircleNumber:'',   //封禁圈子
        informationList: [],        //表格列表
        pageSize:'',
        pageNum:'',
        //_circleListData:[],         //全部圈子列表
        //_closedCircleData:[],       //被封禁的圈子列表
        _openRange:'',              //圈子公开范围:0平台公开，1机构公开
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            //tipOffType: undefined, //举报类型
            //lastProcessType: undefined, //最后处理方式
            //searchValue: undefined,  //检索条件
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        },
        processLists:[],
        dateString:'',
        entity: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        reason:'',  //资源冻结原因
        reasonNum: 0,  //资源冻结原因字数
        ids: [],
        offLists:[],  //资源举报列表
        businessId:'',  //业务id

        dataSource: [],

        startColumns: [
            {title: '11', dataIndex: '', key: ''},
            {title: '圈子封面', dataIndex: 'coverUrl', key: 'coverUrl',
            
            render:(e) => {
                {console.log(e,':::::;')}
                return (
                
                <img
                    alt=""
                    width='46px'
                    height='46px'
                    style={{borderRadius:'50%'}}
                    src={require('@/assets/img/icon-user.png')}
                    
                />
                );
            }
            },
            {title: '圈子名称', dataIndex: 'circleName', key: 'circleName'},
            {title: '圈子ID', dataIndex: 'circleId', key: 'circleId'},
            {title: '圈主', dataIndex: 'orgName', key: 'orgName', align: 'center',},
            {title: '所在院校', dataIndex: 'createDate', key: 'createDate   ', align: 'center'},

            {title: '创建日期', dataIndex: 'createTime', key: 'createTime   ', align: 'center'},
            {title: '公开范围', dataIndex: 'openRange', key: 'openRange   ', align: 'center'},
            {title: '成员量', dataIndex: 'memberNum', key: 'memberNum   ', align: 'center'},
            {title: '浏览量', dataIndex: 'browseNum', key: 'browseNum   ', align: 'center'},
            {title: '话题数', dataIndex: 'topicNum', key: 'topicNum   ', align: 'center'},
            {title: '评论数', dataIndex: 'discussNum', key: 'discussNum   ', align: 'center'},

        ],
        endColumns: [

            {
                title: '操作', dataIndex: 'address', key: 'address', fixed: 'right', align: 'center',
                width: 240,
                render: (text, record) => <div className='action vertical_j'>
                    <p>
                         
                        {/* <span onClick={() => window.open('http://www.baidu.com')}>进入圈子</span> */}
                        <span >进入圈子</span>
                        <span  onClick={this.handlecloseCircle}>封禁圈子</span>
                        <span  onClick={this.handledissolveCircle}>解散圈子</span>
                    </p>
                </div>
            }
        ],
        columns:[],
        // columns02: [
        //     {title: '菜单名称', dataIndex: 'menuName', key: 'menuName',width:"17%"},
        //     {title: '类 型', dataIndex: 'menuCodeName', key: 'menuCodeName', align: 'center',width:"10%"},
        //     {title: '对应字符', dataIndex: 'correspond',key: 'correspond', align: 'center', width:"15%"},
        //     {title: '跳转方式', dataIndex: 'jumpWay',key: 'jumpWay', align: 'center', width:"10%",
        //         render:(text, record)=>
        //             <span>{record.jumpWay==0?"不跳转":""}{record.jumpWay==1?"栈内":""}{record.jumpWay==2?"栈外":""}</span>
        //     },
        //     {title: '地 址', dataIndex: 'path',key: 'path', align: 'center', width:"15%"},
        //     {title: '更新时间', dataIndex: 'createTime',key: 'createTime', align: 'center', width:"15%"},
        //     {
        //         title: '操作', dataIndex: 'address', key: 'address', align: 'center',  width:"18%",
        //         render: (text, record) => <p className='action vertical_j'>
        //             <span  onClick={() =>this.updateRoleStatus("new")}>新增</span>
        //             <span onClick={() => this.updateRoleStatus("modify")}>编辑</span>
        //             <span onClick={() => this.delMenu(record)}>删除</span>
        //         </p>
        //     }
        // ],
        _tableColumns:[
            {title: '人员姓名', dataIndex: 'topicTitle', key: 'topicTitle'},
            {title: '性别', dataIndex: 'circleName', key: 'circleName'},
            {title: '所在院校', dataIndex: 'anonyName', key: 'anonyName'},
            {title: '账号', dataIndex: 'orgId', key: 'orgId', align: 'center',},
            {title: '手机号', dataIndex: 'createTime', key: 'createTime   ', align: 'center'},

            {
                title: '操作', dataIndex: 'address', key: 'address', align: 'center',  width:"18%",
                render: (text, item) => <p className='action vertical_j'>
                    <span   onClick={(e)=>this.handleInfo(item)} >查看</span>
                    <span >移入灰名单</span>
                    <span >移出黑名单</span>
                </p>
            }

        ],
         
        reportTypeData:[],
        reportTypeMap: {},
        processModeData:[],
        processModeMap: {},

        _listvalue:"0",     //名单类型值 备注：黑/灰/白明单类型: 0黑，1灰，2白
        _showBlackUserCommentData:[],   //黑名单查看详情
        _showGrayUserCommentData:[],   //灰名单的查看
        _showWhiteUserCommentData:[],   //白名单的查看
        _showResultData:[], //名单数据详情


        
        
        
        
    }
    componentDidMount() {
        //this.wholeCircleList();     //全部圈子列表01
        //this.circleList();          //全部圈子列表
        //this.closedCircle();

        //this.getTipOffType()
        //this.getLastProcessType()
        this.initColumns()
        //this.getList()
        this.blackList()
        //this.GrayList()
        //this.WhiteList()
    }

    //平台黑名单列表
    async blackList() {
        let { pagination} = this.state;
        const res = await blackList();    
        let blackListMap = {}
        //
        console.log(res,"--------------");
        
        if (res ) {   //判断有效防止页面报错 没有数据的时候不渲染&& res.data.length
            this.setState({
                dataSource: res.data,
                //_blackListData:res.data,
                //_blackListNumber:res.total,
                
                //total: wholeCircleListData.total,
                //pageSize: res.pageSize,
                //pageNum: res.pageNum,
                pagination: {
                    pageNum:res.pageNum,
                    pageSize: res.pageSize,
                    total: res.total
                }
            });
        }
        
    }


    

    //平台灰名单 
    async GrayList() {
        let { pagination} = this.state;
        const res = await GrayList();    
        let blackListMap = {}
        //
        
        
        if (res ) {   //判断有效防止页面报错 没有数据的时候不渲染 && res.data.length
            this.setState({
                dataSource: res.data,

                pagination: {
                    pageNum: res.pageNum,
                    pageSize: res.pageSize,
                    total: res.total
                }
            });
        }
        
    }

    //平台白名单 已处理人员名单
    async WhiteList() {
        let { pagination} = this.state;
        const res = await WhiteList();    
        let blackListMap = {}
         ;
        
        
        if (res ) {   //判断有效防止页面报错 没有数据的时候不渲染
            this.setState({
                dataSource: res.data,

                pagination: {
                    pageNum: res.pageNum,
                    pageSize: res.pageSize,
                    total: res.total
                }
            });
        }
        
    }

    //黑名单查看详情
    async showBlackUserComment() {
        let params = {

        }
        const res = await showBlackUserComment();    
        
        
        
        if (res && res.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            if (res.code == 0) {
                this.setState({
                    _showBlackUserCommentData:res.result,   //黑名单查看详情
                     
                });
                
            }else{
                message.error(res.message);
            }
        }
    }

    //灰名单查看详情
    async showGrayUserComment() {
        const res = await showGrayUserComment();    
        if (res && res.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            if (res.code == 0) {
                this.setState({
                    _showGrayUserCommentData:res.result,   //灰名单的查看
                });
            }else{
                message.error(res.message);
            }
        }
    }

    //白名单的查看
    async showWhiteUserComment() {
        const res = await showWhiteUserComment();    
        if (res && res.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            if (res.code == 0) {
                this.setState({
                    _showGrayUserCommentData:res.result,   //灰名单的查看
                });
            }else{
                message.error(res.message);
            }
        }
    }

    //移入移出名单 start		
    //黑名单
    //移入灰名单
    lookModal = (blackId) => {
        Modal.confirm({
            title: '温馨提示',
            content: '您确定要将 韩梅梅 移入灰名单吗？',
            okText: '确 定',
            cancelText: '取 消',
            onOk: () => {
                moveGrayList({ blackId }).then(r => {
                    if (r.code === 0) {
                        blackList({
                            pageNum: this.state.pageNum,
                            pageSize: this.state.pageSize
                        }).then(res => {
                            if (res) {
                                this.setState(() => {
                                    return {
                                        dataSource: res.data || [],
                                        total: res.total,
                                    }
                                })
                            }

                        })
                    }else{
                        message.wraning('出错了')
                    }
                })

            }
        });
    };

    //移出黑名单
    sharblackId = (blackId) => {
        Modal.confirm({
            title: '温馨提示',
            content: '您确定要将 韩梅梅 移入黑名单吗？',
            okText: '确 定',
            cancelText: '取 消',
            onOk: () => {
                removeBlackList({blackId}).then(r=>{
                    if(r.code===0){
                        message.success('移出黑名单成功')
                        blackList({
                            pageNum: this.state.pageNum,
                            pageSize: this.state.pageSize
                        }).then(res => {
                            if (res) {
                                this.setState(() => {
                                    return {
                                        dataSource: res.data || [],
                                        total: res.total,
                                    }
                                })
                            }
                
                        })

                    }else{
                    alert("移出黑名单失败")
                    }
                })
            }
        });
    }			
                
    //灰名单
    //移出灰名单(加入白名单)
    lookModalout = (grayId) => {
    Modal.confirm({
        title: '温馨提示',
        content: '您确定要将 韩梅梅 移出灰名单吗？',
        okText: '确 定',
        cancelText: '取 消',
        onOk: () => {
            removeGrayList({grayId}).then(r=>{
                if(r.code===0){
                    message.success('移出灰名单成功')
                    GrayList({
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize
                    }).then(res => {
                        if (res) {
                            this.setState(() => {
                                return {
                                    dataSource: res.data || [],
                                    total: res.total,
                                }
                            })
                        }
                    })


                }else{
                alert("一处失败")
                }
            })
        
        }
    });
    };

    // 移入黑名单
    shareResource = (grayId) => {
    Modal.confirm({
        title: '温馨提示',
        content: '您确定要将 韩梅梅 移入黑名单吗？',
        okText: '确 定',
        cancelText: '取 消',
        onOk: () => {
            moveBlackList({grayId}).then(r=>{
                if(r.code===0){
                    message.success('移入黑名单成功')
                }else{
                alert("移入黑名单失败")
                }
            })
        }
    });
    }

    //白名单
    //移入灰名单
    whitelookModal=(whiteId)=>{
    Modal.confirm({
        title: '温馨提示',
        content: '您确定要将 韩梅梅 移入灰名单吗？',
        okText: '确 定',
        cancelText: '取 消',
        onOk: () => {
            whiteMoveGrayList({whiteId}).then(r=>{
                if(r.code===0){
                    message.success('移入灰名单成功')
                    WhiteList({
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize
                    }).then(res => {
                        if (res) {
                            this.setState(() => {
                                return {
                                    dataSource: res.data || [],
                                    total: res.total,
                                }
                            })
                        }
            
                    })
                }else{
                alert("移入灰名单失败")
                }
            })
        }
    });
    }

    //移入黑名单
    whiteshareResource=(whiteId)=>{
    Modal.confirm({
        title: '温馨提示',
        content: '您确定要将 韩梅梅 移入黑名单吗？',
        okText: '确 定',
        cancelText: '取 消',
        onOk: () => {
            whiteMoveBlackList({whiteId}).then(r=>{
                if(r.code===0){
                    message.success('移入黑名单成功')
                }else{
                alert("移入黑名单失败")
                }
            })
        }
    });
    }	

    //end



    
    changeList=(value)=>{
       ;
        let v=value;
        if(v==="0"){
            console.log(0);
            this.blackList()

            // blackList().then(res => {

            //     console.log(res,"--------------");
                
            //     if (res && res.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            //         this.setState({
            //             dataSource: res.data,

            //             pagination: {
            //                 pageNum: res.pageNum,
            //                 pageSize: res.pageSize,
            //                 total: res.total
            //             }
            //         });
            //     }
            // })
            
        }
        else if(v==="1"){
            console.log(1);
            this.GrayList()
            // GrayList().then(res => {

            //     /////
            //     console.log(res,"--------------");
                
            //     if (res && res.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            //         this.setState({
            //             dataSource: res.data,

            //             pagination: {
            //                 pageNum: res.pageNum,
            //                 pageSize: res.pageSize,
            //                 total: res.total
            //             }
            //         });
            //     }
            // })
            
        }
        else if(v==="2"){
            console.log(2);
            this.WhiteList()
            // WhiteList().then(res => {

            //     ////
            //     console.log(res,"--------------");
                
            //     if (res && res.data.length) {   //判断有效防止页面报错 没有数据的时候不渲染
            //         this.setState({
            //             dataSource: res.data,

            //             pagination: {
            //                 pageNum: res.pageNum,
            //                 pageSize: res.pageSize,
            //                 total: res.total
            //             }
            //         });
            //     }
            // })
        }
    }
    //切换列表
    changeOption(e) {
        ////
        const value = e 
        
        // console.log(e)
        this.setState({
            _listvalue: value 
        },()=>this.changeList(e))

        // console.log(this.state._listvalue,'123456')
    }

    initColumns() {
        const {activeIndex, startColumns, endColumns,_blackListData, dataSource,} = this.state
        let column = []
        // if (activeIndex === 0) {
            
        //     this.setState({
        //         columns: startColumns
        //     })
        // } else {
            
        //     this.setState({
        //         columns: startColumns.concat(column).concat(endColumns)
        //     })
        // }
        this.setState({
            //columns: startColumns.concat(column).concat(endColumns)
            //columns: _blackListData.concat(column)
            columns: dataSource.concat(column)

        })

        
    }
    async getList(){
        const {activeIndex, pagination} = this.state
        if(activeIndex === 0){
            const result = await this.wholeCircleList();
            if(result  ){
                this.setState({
                    dataSource: result.data,
                })
            }
            
        }else{
            const result = await this.closedCircle(); 
            if(result  ){
                this.setState({
                    dataSource: result.data,
                })
            }
            
        }
    }



    //分页
    pageChange = (pageNumber) => {
        let {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination: pagination}, () => this.getList())
    }
    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let alreadyFreeze = selectedRows.filter(row => row.lastProcessType === "1")
        if(alreadyFreeze.length > 0){
            return message.warning('不要选择已经冻结的数据');
        }
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({selectedRowKeys, expandedRowKeys})
    }
    //搜索选项改变
    reportChange = ({target: {value}}) => {
        let {pagination} = this.state;
        pagination.searchValue = value
        this.setState({
            pagination,
            btnDisabled: false
        });
    };
    //已完成  待完成切换
    changeTabMenu = (key) => {
        this.setState({
            selectedRowKeys: [],
            expandedRowKeys: [],
            activeIndex: key
        }, () => {
            this.initColumns()
            this.resetSearch()
        })
    };
    resetSearch() {
        let pagination = {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tipOffType: undefined, //举报类型
            processState: 0, //处理状态
            lastProcessType: undefined, //最后处理方式
            searchValue: undefined,  //检索条件
            processTime: undefined,  //时间
            processStartTime: undefined,//推荐开始时间
            processEndTime: undefined,//推荐结束时间
        };
        // this.setState({
        //     pagination: pagination,
        //     btnDisabled: true
        // }, () => this.getList())
    }

    //全选反选切换
    checkedAll = () => {
        let {selectedRowKeys, dataSource} = this.state;
        // if (selectedRowKeys.length === dataSource.length) {
        //     this.setState({selectedRowKeys: [], expandedRowKeys: []});
        //     return;
        // }
        const keys = Object.keys(dataSource);
        const index = [];
        keys.forEach(item => {
            index.push(Number(item));
        });
        // this.setState({
        //     selectedRowKeys: index,
        //     expandedRowKeys: dataSource.map(v => v.resourceId)
        // });
    }
 
 

    checkCancel = () => {
        this.setState({
            visible: false
        })
    }
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        }, () => this.getList())
    }
    //举报类型改变
    reportTypeChange = (value) => {
        let {pagination} = this.state;
        pagination.tipOffType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //处理方式改变
    reportModeChange = (value) => {
        let {pagination} = this.state;
        pagination.lastProcessType = value
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList());
    };
    //日期查询
    reportTimeChange = (value, dateString)=> {
        let {pagination} = this.state;
        pagination.processTime = value
        pagination.processStartTime = dateString[0]
        pagination.processEndTime = dateString[1]
        this.setState({
            pagination,
            btnDisabled: false
        }, () => this.getList())
    }

    //closeCircle 封禁圈子弹层
    handlecloseCircle = () => {
        // console.log(this.state.userInfo)
        Modal.confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '被封禁后该圈子内的所有成员将不能再发表 话题，圈子内所有话题将会全部隐藏。',
            centered:true,
            className: "ModalConfirm-pic",
            okType: 'danger',
            onOk:() => this.closeCircle()
        })
    };

    //dissolveCircle 解散圈子弹层
    handledissolveCircle = () => {
        // console.log(this.state.userInfo)
        Modal.confirm({
            title: '系统提示',
            icon: <ExclamationCircleOutlined />,
            content: '解散圈子后，所有数据都将不存在 ，您确定 要解散圈子吗？',
            centered:true,
            className: "ModalConfirm-pic",
            okType: 'danger',
            okText:'狠心解散',
            onOk:() => this.dissolveCircle()
        })
    };

    //弹出窗口
    handleInfo = (e) => {

        if(this.state._listvalue==="0"){
            ////
            //this.showBlackUserComment();
            showBlackUserComment({"blackId":e}).then((res) => {
                
                // console.log(res)
                // if (res.code == 0) {
                     
                //     this.setState({
                //         _showBlackUserCommentData:res.result,   //黑名单查看详情
                         
                //     });
                // }
                // else{
                //     console.log(res)
                //     //message.success("删除成功");
                // }
                // console.log(res)
                this.setState({
                    _showResultData:res,   //黑名单查看详情
                    visible: true,
                });

                
                
            });
        }
        else if(this.state._listvalue==="1"){
            //this.showGrayUserComment()
            showGrayUserComment({"grayId":e}).then((res) => {
                this.setState({
                    _showResultData:res,   //灰名单查看详情
                    visible: true,
                });
            });
        }
        else if(this.state._listvalue==="2"){
            //his.showWhiteUserComment()
            showWhiteUserComment({"whiteId":e}).then((res) => {
                this.setState({
                    _showResultData:res,   //白名单查看详情
                    visible: true,
                });
            });
        }
       
    };

    //closeCircle   封禁圈子
    closeCircle=()=>{
        console.log("封禁圈子")
    }

    //closeCircle   解散圈子
    dissolveCircle=()=>{
        console.log("解散圈子")
    }

    render() {
        const {_listvalue,  _showResultData,  _blackListData,_blackListNumber,informationList,columns, selectedRowKeys, activeIndex, dataSource, visible,reportTypeData,processModeData,pagination, reason, reasonNum,_tableColumns,_tabledata,} = this.state;
        const formItemLayout = {
            labelCol: {span: 0},
            wrapperCol: {span: 24},
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle check_box report'>
             
                <div className="p20 pright40 bg_fff m20" style={{paddingRight:'0'}}>
                    <div className="top_01 ">
                        

                        <Col span={7}>
                            {/* <span style={{ marginRight: '20px' }}>人员灰名单</span> */}
                            {/* <Select placeholder='人员黑名单' style={{ width: '95%' }} onChange={e => {
                                this.setState({ organizationId: e }, () => this.getList())
                            }} > */}
                            <Select placeholder='人员黑名单' defaultValue="0" style={{ width: '95%' }} onChange={(e)=>this.changeOption(e)} >
                                <Option value="0">人员黑名单</Option>
                                <Option value="1">人员灰名单</Option>
                                <Option value="2">已处理人员名单</Option>
                                {
                                    // enterprisList.map(v => {
                                    //     return <Option value={v.id} key={v.id}>{v.enterpriseName}</Option>
                                    // })

                                }
                            </Select>
                        </Col>
                        <Col span={7}>
                            <span style={{ marginRight: '20px' }}>人员名称</span>
                            <Input style={{ width: '75%' }} placeholder='请输入人员名称' />
                        </Col>
                        <Col span={7}>

                            <span style={{ marginRight: '20px' }}>所属机构</span>
                            <Select placeholder='请选择所属机构' style={{ width: '75%' }} onChange={e => {
                                this.setState({ identityId: e }, () => this.getList())
                            }} >
                                <Option value={''}>全部</Option>
                                <Option value="">全网公开</Option>
                                <Option value="">机构公开</Option>
                                {
                                    // statusList.map(v => {
                                    //     return <Option value={v.code} key={v.code}>{v.name}</Option>
                                    // })
                                }
                            </Select>
                        </Col>
                        <div style={{textAlgin:'right'}}>
                            <Button className='reset_btn' onClick={() => this.resetSearch()} disabled={this.state.btnDisabled}>重置</Button>
                            <Button type='primary' className='ant_blue' style={{ marginLeft: '12px' }} onClick={() => this.queryBtn()}>查询</Button>
                        </div>
                    </div>

                    
                </div>

                <div className="wrapper padd" style={{paddingTop:'0'}}>

                    <div className="top m20">

                    <div className="listMenu" id={this.state._listvalue}><span className="list_icon">{ (this.state._listvalue==="0")? "人员黑名单": ( (this.state._listvalue==="1")? "人员灰名单":( (this.state._listvalue==="2")?"人员白名单":"人员黑名单") ) }</span></div>
                    </div>
                    <div className="">
                        <Table className="circle" dataSource={dataSource}  
 
                        pagination={{
                            pageNum: 1,
                            pageSize: 10,
                            total: this.state.pagination.total,
                            //onChange:this.pageChange,
                            //onChange: this.affiliatedTableTableChangePage,
                        }}
                        
                        >
                        <Column title="人员姓名" dataIndex="memberName" key="memberName" />
                            <Column title="性别" dataIndex="sex" key="sex"
                            render={(v,i)=>{
                                return (<span>{i.sex==1?'男':'女'}</span>)
                            }}
                            />
                            <Column title="账号" dataIndex="accountNo" key="accountNo" />
                            <Column title="手机号" dataIndex="mobile" key="mobile" />
                            
                            <Column
                                title="操作"
                                dataIndex="address"
                                key="6"
                                align='center'
                                render={(e, item) => {
                                    console.log(this.state._listvalue,'---------')
                                    return <p className='action vertical_j'>

                                        {   this.state._listvalue==="0" ?
                                            <Fragment>
                                            {/*黑名单*/}
                                                <span onClick={(id)=>this.handleInfo( item.blackId)}>查看</span>
                                                <span onClick={() => this.lookModal(item.blackId)}>移入灰名单</span>
                                                <span onClick={() => this.sharblackId(item.blackId)}>移出黑名单</span>
                                            </Fragment>:
                                            this.state._listvalue==="1" ?
                                            <Fragment> 
                                            {/*灰名单*/}
                                                <span onClick={(id)=>this.handleInfo( item.grayId)}>查看</span>
                                                <span onClick={() => this.lookModalout(item.grayId)}>移出灰名单 </span>
                                                <span onClick={() => this.shareResource(item.grayId)}>移入黑名单</span>
                                            </Fragment>:
                                            this.state._listvalue==="2" ?
                                            <Fragment>
                                            {/*白名单*/}
                                                <span onClick={(id)=>this.handleInfo( item.whiteId)}>查看</span>
                                                <span onClick={() => this. whitelookModal(item.whiteId)}>移入灰名单</span>
                                                <span onClick={() => this. whiteshareResource(item.whiteId)}>移入黑名单</span>
                                            </Fragment>
                                            : 
                                            <Fragment>
                                            {/*黑名单*/}
                                                <span onClick={(id)=>this.handleInfo( item.blackId)}>查看</span>
                                                <span onClick={() => this.lookModal(item.blackId)}>移入灰名单</span>
                                                <span onClick={() => this.sharblackId(item.blackId)}>移出黑名单</span>
                                            </Fragment>
                                            }
                                        </p>
                                }}
                            />
                        </Table>
                    </div>
                    <Modal
                        title="系统提示"
                        visible={visible}
                        closable={false}
                        className="look-modal-01"
                        footer={
                            <div className='modal_btn_j'>
                                <Button onClick={() => this.checkCancel()}>关闭</Button>
                                {/* <Button onClick={() => this.lookCancel()} type='primary'>保存</Button> */}
                            </div>
                        }
                    >
                        <div className="sold-box">
                            
                            {_showResultData ?
                            (
                                <p style={{padding:'40px'}}>{_showResultData.memberName}  在{_showResultData.circleCreateTime}，在圈子{_showResultData.circleName}中发表了话题{_showResultData.topicTitle}涉及【<span style={{color:'red'}}>{_showResultData.reportType}</span>】的言论，已被{_showResultData.adminUser}，在{_showResultData.topicDiscussTime}拉入{(this.state._listvalue==="0" && "黑名单")|| (this.state._listvalue==="1" && "灰名单") ||  (this.state._listvalue==="2" && "白名单")}</p>
                            ):
                            <p style={{padding:'40px'}}>暂无数据</p>
                            }
                            
                        </div>
                    </Modal>
                </div>
                
            </div>
        )
    }
}

Blacklist = connect(mapStateToProps, mapDispatchToProps)(Blacklist);
Blacklist = Form.create()(Blacklist)
export default Blacklist;


