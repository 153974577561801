// 预览一个专题
import React, { Component } from 'react'
import '../specialTopic/index.scss';
import { Form, Select,Button,Row ,Col,Radio,Input } from 'antd';
import setName from 'classnames';
import { getSearch } from '@/until/libs';
import {withRouter} from "react-router-dom";
import RenderImg from "../../../../components/renderImg";
const { Option } = Select;

@withRouter
class specialTopicPage extends Component {
    state = {
        specialInfo : {}
    }

    componentDidMount() {
        let params = getSearch(this.props.location.search);
        console.log("params", params)
        this.setState({
            specialInfo : params.specialInfo
        })
    }

    gobackPage = () =>{
        this.props.history.push(`/main/group-column/addThematic?${decodeURI(JSON.stringify({ specialInfo: this.state.specialInfo}))}`)
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <div className='specialTopic_box'>
                <div className='top_title'>
                    <div className='return_wapper' onClick={this.gobackPage}>
                        <img src={require('@/assets/img/zixun/return.png')}/>
                        <span>返回</span>
                    </div>
                    <span>{this.state.specialInfo.specialName}</span>
                </div>
                <div className='zt_top'>
                    <div className='zt_top_box'>
                        <div className='zp_top_title'>
                            {this.state.specialInfo.specialName}
                        </div>
                        <div className='cont_box'>
                            <div className='img_box'>
                                <RenderImg type={this.state.specialInfo.mediaType} size='m' id={this.state.specialInfo.specialImg}/>
                                {/*<img src={window.$$img(this.state.specialInfo.specialImg)}/>*/}
                            </div>
                            <div className='cont_box_wapper'>
                                <div className='cont_title'><span>内容介绍</span></div>
                                <div className='cont'>
                                    {this.state.specialInfo.specialDesc}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='back_white_box'>
                    <div className='back_white_center'>
                        <ul className='list_wapper_white'>
                            <li className='cont_title'><span>政策文件</span></li>
                            <li className='item_wapper'>
                                <div>
                                    <img className='item_img' src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                </div>
                                <div className='center'>
                                    直属机关党委部门党支部办公室党小组召开“厉行勤俭节约...
                                </div>
                                <div className='time'>
                                    [2020-09-12]
                                </div>
                            </li>
                            <li className='item_wapper'>
                                <div>
                                    <img className='item_img' src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                </div>
                                <div className='center'>
                                    直属机关党委部门党支部办公室党小组召开“厉行勤俭节约...
                                </div>
                                <div className='time'>
                                    [2020-09-12]
                                </div>
                            </li>
                            <li className='item_wapper'>
                                <div>
                                    <img className='item_img' src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                </div>
                                <div className='center'>
                                    直属机关党委部门党支部办公室党小组召开“厉行勤俭节约...
                                </div>
                                <div className='time'>
                                    [2020-09-12]
                                </div>
                            </li>
                        </ul>
                        <ul className='list_wapper_white'>
                            <li className='cont_title'><span>专家解读</span></li>
                            <li className='item_wapper'>
                                <div>
                                    <img className='item_img' src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                </div>
                                <div className='center'>
                                    直属机关党委部门党支部办公室党小组召开“厉行勤俭节约...
                                </div>
                                <div className='time'>
                                    [2020-09-12]
                                </div>
                            </li>
                            <li className='item_wapper'>
                                <div>
                                    <img className='item_img' src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                </div>
                                <div className='center'>
                                    直属机关党委部门党支部办公室党小组召开“厉行勤俭节约...
                                </div>
                                <div className='time'>
                                    [2020-09-12]
                                </div>
                            </li>
                            <li className='item_wapper'>
                                <div>
                                    <img className='item_img' src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                </div>
                                <div className='center'>
                                    直属机关党委部门党支部办公室党小组召开“厉行勤俭节约...
                                </div>
                                <div className='time'>
                                    [2020-09-12]
                                </div>
                            </li>
                        </ul>
                        <ul className='list_wapper_white'>
                            <li className='cont_title'><span>媒体报道</span></li>
                            <li className='item_wapper item_mt_bd'>
                                <div>
                                    <img className='item_img' src={require('@/assets/img/zixun/Rectangle 2290.png')}/>
                                </div>
                                <div className='center'>
                                    <div>直属机关党委部门党支部办公室党小组召开“厉行勤俭节约...</div>
                                    <div className='mt_bd_title'>摘自人民日报</div>
                                </div>
                                <div className='time'>
                                    [2020-09-12]
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        )
    }
}


specialTopicPage = Form.create()(specialTopicPage);
export default specialTopicPage;
