import React, { Component } from 'react'
import { Form } from 'antd';

class statistics extends Component {
    state = {
    }
    componentDidMount() {

    }

    render() {
        return (
            <div>该功能暂未开放</div>
        )
    }
}


export default Form.create()(statistics);