


import React from 'react';


//校内圈子管理
export default props => {
    return <svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2989 3.8899L7.25262 0.431736C7.14072 0.36776 7.01401 0.334226 6.88511 0.334474C6.75621 0.334722 6.62963 0.368745 6.51797 0.433152L0.525173 3.89131C0.172479 4.09488 0.0515517 4.54579 0.255063 4.89855C0.439286 5.21774 0.825899 5.34621 1.15919 5.21703V12.8106C1.15919 13.2178 1.48929 13.548 1.89655 13.548H5.37087C5.77807 13.548 6.10823 13.2178 6.10823 12.8106V10.1254H8.15804V12.8106C8.15804 13.2178 8.4882 13.548 8.8954 13.548H12.0427C12.4499 13.548 12.7801 13.2178 12.7801 12.8106V5.25012C12.8305 5.26086 12.8815 5.26746 12.9322 5.26746C13.1881 5.26746 13.4368 5.13409 13.5729 4.89607C13.7751 4.54255 13.6524 4.09211 13.2989 3.8899ZM9.6327 12.0732V9.38801C9.6327 8.98081 9.30254 8.65065 8.89534 8.65065H5.37087C4.96367 8.65065 4.63351 8.98081 4.63351 9.38801V12.0732H2.63391V4.37715L6.88819 1.92221L11.3053 4.44858V12.0732H9.6327Z" fill="#6A7E9C"/>
        <path d="M8.64248 7.61944H5.60845C5.20125 7.61944 4.87109 7.28928 4.87109 6.88208V4.52984C4.87109 4.12264 5.20125 3.79248 5.60845 3.79248H8.64248C9.04968 3.79248 9.37984 4.12264 9.37984 4.52984V6.88202C9.37984 7.28928 9.04968 7.61944 8.64248 7.61944ZM6.34581 6.14466H7.90512V5.2672H6.34581V6.14466Z" fill="#BABABA"/>
    </svg>

}