import React, { Component } from 'react'
import "./index.scss"
import { Select, Button, Input, Pagination, Menu, Dropdown, Modal, Popconfirm, message, Breadcrumb, Form } from 'antd';
import { customLibList, closeProject, publish, previewProject, publishQuestion, changeCollection } from "../../../api/researchtemplate/index"
import { getQuestionnaireList, deleteList } from "../../../api/templateLibrary/index"
import { DownOutlined } from '@ant-design/icons';
import { createTemplate } from "@/api/save_question";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
const GanTanHao = () => {

    return <div style={{ marginRight: 10 }}>
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11.5L0 11.5C9.59018e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5L0 11.5Z" fill="#F3B714" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6502 6.32502V13.225C12.6502 13.8602 12.1353 14.375 11.5002 14.375C10.865 14.375 10.3502 13.8602 10.3502 13.225V6.32502C10.3502 5.68989 10.865 5.17502 11.5002 5.17502C12.1353 5.17502 12.6502 5.68989 12.6502 6.32502ZM11.5001 17.825C12.1352 17.825 12.6501 17.3102 12.6501 16.675C12.6501 16.0399 12.1352 15.525 11.5001 15.525C10.865 15.525 10.3501 16.0399 10.3501 16.675C10.3501 17.3102 10.865 17.825 11.5001 17.825Z" fill="white" />
        </svg>
    </div>

}
const { Option } = Select;
const arrData = [
    {
        text: '全部', id: ''
    },
    {
        text: '问卷', id: '0'
    }, {
        text: '反馈', id: '1'
    }, {
        text: '投票', id: '2'
    },
]
const Dystatus = [{
    ztstatus: "全部状态", id: ''
}, {
    ztstatus: "未发布", id: 0,
}, {
    ztstatus: "采集中", id: 1,
}, {
    ztstatus: "暂停中", id: 2,
}, {
    ztstatus: "已结束", id: 3,

}]
class index extends Component {
    state = {
        visible: false,
        modalInputLength: 0,
        curbuttonid: '',
        // 下拉菜单  
        //列表
        dataList: [],
        total: 0, //列表总数
        pageNum: 1,  //第几页
        pageSize: 10, //每页几条
        questionTitle: ""
    }
    //类型
    componentDidMount() {
        this.list()
    }
    //  列表
    list = () => {
        getQuestionnaireList({
            type: this.state.curbuttonid,
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum,
            questionTitle: this.state.questionTitle
        }).then(res => {
            this.setState({
                dataList: res.data,
                total: res.total
            })
        })
    }
    getselect = (item, key) => {
        if (item.text === '全部') {
            this.setState({
                curbuttonid: '',
            }, () => {
                this.list();
            })
            return;

        }
        this.setState({
            curbuttonid:item.id,
        }, () => {
            this.list()
        })

    }
    getDataList = (par) => {
        return customLibList(par).then(res => {
            if (res.code === 0) {
                this.setState(() => {
                    return {
                        dataList: res.result.data,
                        total: res.result.total
                    }
                })

            } else {
                message.info("数据错误")
            }


        })
    }

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize
        }, () => {
            this.list();
        }
        );
    };
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize
        }, () => {
            this.list();
        })
    }
    //删除
    deleteItem = (v) => {
        console.log(v)
        Modal.confirm({
            className: "hh_modal_1",
            title: <><div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: 16,
                background: '#F8F8F8',
                width: '100%'
            }}>温馨提示</div>
                <div style={{
                    marginTop: 19,
                    display: 'flex'
                }}>{GanTanHao()}<div>
                        确定要删除#《{v.questionTitle}》吗？删除后项目将移入回收站，可进行恢复。</div></div></>,
            onOk: (close) => {
                deleteList({
                    templateId: v.templateId
                }).then(res => {
                    if (res.code === 0) {
                        let tip = res.message === 'success' ? "删除成功" : ''
                        message.success(tip);
                        this.list();
                        close()
                        return;
                    }
                    message.error(res.message);
                })

            },
            onCancel(close) {
                close()
            },
        })
    }


    //新建空白调研弹框的取消
    handlerCancel = () => {
        this.setState(() => {
            return {
                visible: false
            }
        })
    }

    //新建空白调研弹框的确认
    handlerOk = async (item) => {
        try {
            const values = await this.props.form.validateFields();
            console.log(values)
            const ars = await createTemplate({
                type: values.type,
                questionTitle: values.questionTitle,
                sysFlag: '55'
            })
            if (!ars) return;
            this.setState(() => {
                return {
                    visible: false
                }
            }, () => {
                sessionStorage.setItem('router', JSON.stringify({ result: ars.result, questionTitle: values.questionTitle }))
                this.props.history.push(`/editContent?templateId=${ars}`);
            })

        } catch (errorInfo) {
            console.log('校验失败', errorInfo);
        }


    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 14,
            },
        }
        const { dataList, total } = this.state
        return (

            <div className="template">
                
                <div className="template_main">

                    <div className="m_buttom">
                        <div>
                            <div>
                                调研类型
                            </div>
                            {
                                arrData.map((item, index) => {
                                    return <span key={index} onClick={() => {
                                        this.getselect(item, index)
                                    }}
                                        className={this.state.curbuttonid === item.id ? 'blue_btn' : ""} >{item.text}</span>
                                })
                            }
                            <button className="template_btn" onClick={() => this.setState({ visible: true })} >新建调研</button>
                        </div>
                    </div>
                    <div className="ipt"
                    >
                        <div>
                            <div id="c_input">
                                <Input
                                    className="in"
                                    prefix={<img src={window.$$img('cvec-gonggong-front-wenjuan/icon/icon_select.png')} />}
                                    placeholder="请输入调研名称"
                                    style={{ width: 360 }}
                                    value={this.state.questionTitle}
                                    onChange={value => {
                                        this.setState({
                                            questionTitle: value.target.value
                                        })
                                        console.log(value, 'vvv')
                                    }}
                                    onPressEnter={v => {
                                        this.list()
                                    }}
                                />
                                <span className="c_span">共{total}个调研</span>
                            </div>
                        </div>
                    </div>
                    <div className="main_border">
                        <div className="h_border">
                            {
                                dataList.length>0?dataList.map((v, i) => {
                                    return <div className='h_item' key={i}>
                                        <div>
                                            {
                                                v.type === '0' ? <img src={window.$$img('cvec-gonggong-front-wenjuan/icon/inco5_blue.png')}></img> :
                                                    v.type === '1' ? <img src={window.$$img('cvec-gonggong-front-wenjuan/icon/inco6_origin.png')}></img> :
                                                        v.type === '2' ? <img src={window.$$img('cvec-gonggong-front-wenjuan/icon/inco_7green.png')}></img> : ''
                                            }
                                            <span
                                            >{v.type === '0' ? "问卷" : v.type === '1' ? "反馈" : v.type === '2' ? "投票" : ''}</span>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <h3>{v.questionTitle ? v.questionTitle.length > 15 ? `${v.questionTitle.substr(0, 15)}...` : v.questionTitle : ""}</h3>
                                                    <div
                                                        className="backgrounf_image"

                                                        style={{
                                                            backgroundColor: v.status === 0
                                                                ?
                                                                "rgba(231,1,1,0.1)" : v.status === 1
                                                                    ?
                                                                    "rgba(66,207,157,0.1)" : v.status === 2
                                                                        ?
                                                                        "rgba(231,83,1,0.1)" : v.status === 3
                                                                            ?
                                                                            "rgba(136,136,136,0.1)" : ''
                                                        }}
                                                    >

                                                        <span
                                                            className="background_lei"
                                                            style={{
                                                                color: v.status === 0
                                                                    ?
                                                                    "#F22E2E" : v.status === 1
                                                                        ?
                                                                        "#42CF9D" : v.status === 2
                                                                            ?
                                                                            "#E75301" : v.status === 3
                                                                                ?
                                                                                "#888888" : ''
                                                            }}
                                                        >
                                                            {v.status === 0 ?
                                                                "未发布" : v.status === 1 ?
                                                                    "采集中" : v.status === 2 ?
                                                                        "暂停中" : v.status === 3 ?
                                                                            "已结束" : ''}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            {/*  */}
                                            <div>
                                                {/* 问卷导语： */}
                                                {v.introduction ? v.introduction.length > 60 ? `${v.introduction.substr(0, 60)}...` : v.introduction : <>暂无导语 <br /></>}
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className="bottom_foo">
                                            <div className="pp" >
                                                <span>{v.questionNum || 0}道问题</span> <span>|</span>
                                                <span>模板被使用了{v.isUseNum || 0}次</span>
                                            </div>
                                            <div className="c_fonts">
                                                <span className="template_foo">
                                                    <em>

                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.0909 14.0307C15.2355 14.0307 15.3743 14.0882 15.4766 14.1904C15.5789 14.2927 15.6363 14.4315 15.6363 14.5761C15.6363 14.7208 15.5789 14.8595 15.4766 14.9618C15.3743 15.0641 15.2355 15.1216 15.0909 15.1216H1.27269C1.12802 15.1216 0.989286 15.0641 0.886994 14.9618C0.784701 14.8595 0.727234 14.7208 0.727234 14.5761C0.727234 14.4315 0.784701 14.2927 0.886994 14.1904C0.989286 14.0882 1.12802 14.0307 1.27269 14.0307H15.0909ZM15.0847 4.13796L6.42832 12.7947L3.05378 13.1394L3.39887 9.76487L10.9258 2.2376V2.23724L12.0552 1.10814C12.5629 0.600508 13.448 0.600508 13.9556 1.10814L15.0847 2.2376C15.2099 2.36208 15.3092 2.51015 15.3768 2.67325C15.4444 2.83635 15.4789 3.01123 15.4785 3.18778C15.4785 3.54669 15.3385 3.88414 15.0847 4.13796ZM4.27523 11.918L5.93123 11.7489L12.7981 4.8816L11.3116 3.39469L4.44432 10.2616L4.27523 11.918ZM13.0054 1.8056C12.9722 1.80549 12.9392 1.812 12.9085 1.82473C12.8778 1.83747 12.8499 1.85618 12.8265 1.87978L12.0829 2.62305L13.5698 4.11033L14.3131 3.36669C14.3605 3.31923 14.3871 3.25488 14.3871 3.18778C14.3871 3.12068 14.3605 3.05633 14.3131 3.00887L13.1843 1.87978C13.1609 1.85621 13.133 1.83752 13.1023 1.82479C13.0716 1.81205 13.0387 1.80553 13.0054 1.8056Z" fill="#888888" />
                                                        </svg>

                                                    </em>
                                                    <span style={{ margin: "0 2px" }} onClick={() => {
                                                        this.props.history.push(`/editContent?templateId=${v.templateId}`);
                                                        //  this.props.history.push({ pathname: '/editContent', state: { questionType: v.questionType } })
                                                    }}>编辑</span>
                                                </span>
                                                <span className="template_foot" >
                                                    <em>


                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0)">
                                                                <path d="M5.11951 2.97051H5.16781L5.16948 2.92223C5.22451 1.32629 6.51498 0.0552635 8.10603 0.0500163H8.11592C9.71154 0.0500163 11.0096 1.323 11.0572 2.92712L11.0587 2.97564H11.1072H14.6642C15.0591 2.97564 15.3786 3.29899 15.3786 3.69486C15.3786 4.09308 15.0568 4.41411 14.6642 4.41411H13.8998H13.8498V4.46411V13.0641C13.8498 14.6687 12.7733 15.95 11.4665 15.95H4.61248C3.30321 15.95 2.22916 14.6612 2.22916 13.0641V4.45898V4.40898H2.17916H1.33579C0.94086 4.40898 0.621411 4.08561 0.621411 3.68973C0.621411 3.29152 0.943185 2.97051 1.33578 2.97051H5.11951ZM6.50158 2.91715L6.49799 2.97051H6.55147H9.67781H9.73081L9.72773 2.9176C9.6778 2.06083 8.98109 1.39104 8.11846 1.39104C7.25581 1.39104 6.55936 2.05821 6.50158 2.91715ZM3.60857 4.41411H3.55857V4.46411V13.0641C3.55857 13.5149 3.69268 13.8997 3.89004 14.1729C4.07391 14.4274 4.31666 14.5897 4.55993 14.6095V14.6192H4.60993H11.4639C11.7237 14.6192 11.9874 14.4518 12.1838 14.1796C12.3812 13.9061 12.5153 13.5213 12.5153 13.0718V4.46411V4.41411H12.4653H3.60857Z" fill="#888888" stroke="white" strokeWidth="0.1" />
                                                                <path d="M6.25811 11.7753C6.25811 12.181 5.99335 12.4855 5.69307 12.4855C5.3858 12.4855 5.128 12.179 5.128 11.7753V7.43795C5.128 7.03229 5.39275 6.72773 5.69307 6.72773C5.99571 6.72773 6.25811 7.03215 6.25811 7.43795V11.7753ZM8.48461 11.7753C8.48461 12.181 8.21987 12.4855 7.91957 12.4855C7.61935 12.4855 7.3545 12.1786 7.3545 11.7753V7.43795C7.3545 7.0323 7.61926 6.72773 7.91957 6.72773C8.22221 6.72773 8.48461 7.03215 8.48461 7.43795V11.7753ZM10.8268 11.7753C10.8268 12.181 10.562 12.4855 10.2617 12.4855C9.95209 12.4855 9.69664 12.1792 9.69664 11.7753V7.43795C9.69664 7.03229 9.96139 6.72773 10.2617 6.72773C10.5643 6.72773 10.8268 7.03216 10.8268 7.43795V11.7753Z" fill="#888888" stroke="white" strokeWidth="0.1" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0">
                                                                    <rect width="16" height="16" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>


                                                    </em>
                                                    <span style={{ margin: "0 2px" }} onClick={() => this.deleteItem(v)}>删除</span>
                                                </span>

                                            </div>
                                        </div>

                                    </div>
                                }):<div className="noFB">
                                    <img src={window.$$setImgSrc("CS_nodataImg.png")} alt="" />
                                </div>
                            }
                            {
                                this.state.total?
                                    <div style={{
                                        display: 'flex', justifyContent: "flex-end", width: "100%",paddingRight:'13px'
                                    }}>
                                        <Pagination
                                            dataSource={this.state.dataList} defaultCurrent={1}
                                            pageSizeOptions={['10', '20', '30']}
                                            onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)}
                                            onChange={(a, b) => this.onPageNumChange(a, b)}
                                            showSizeChanger={true}
                                            current={this.state.pageNum}
                                            pageSize={this.state.pageSize}
                                            total={this.state.total}
                                        />
                                    </div>:''
                            }

                        </div>
                    </div>
                </div>
                <Modal
                    title='选择调研类型'
                    closable={false}
                    centered={true}
                    maskClosable={false}
                    destroyOnClose={true}
                    wrapClassName='cc_modal_wrap1'
                    visible={this.state.visible}
                    onOk={this.handlerOk}
                    onCancel={this.handlerCancel}
                >
                    <Form {...layout} name="control-ref" >

                        <Form.Item
                            label="调研类型"
                        >
                            {
                                getFieldDecorator('type', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择调研类型'
                                        }
                                    ]
                                })(
                                    <Select
                                        placeholder="请选择调研类型"
                                        onChange={this.onGenderChange}
                                    >

                                        {
                                            arrData.map((item, index) => {
                                                if(item.text==='全部'){
                                                    return null;
                                                }
                                                return <Option value={index-1} key={index}>
                                                    {/* {console.log(item.id, 'idiidiiddd')} */}
                                                    {item.text}
                                                </Option>
                                            })

                                        }
                                    </Select>
                                )
                            }

                        </Form.Item>
                        <Form.Item
                            name="questionTitle"
                            className="question_Input"
                            colon={false}
                            placeholder='请输入调研标题'
                            label="调研标题"
                            type='string'
                            
                        >
                            {
                                getFieldDecorator('questionTitle', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入调研标题'
                                        },
                                    ]
                                })(
                                    <Input autoComplete='off' maxLength={50} onChange={(e) => {
                                        this.setState({ modalInputLength: e.target.value.length })
                                    }}
                                        suffix={
                                            <span>
                                                {this.state.modalInputLength}/50
                                            </span>
                                        } />
                                )
                            }
                        </Form.Item>

                    </Form>

                </Modal>
            </div>
        )
    }
}
export default Form.create()(index)

