import React, { Component } from 'react'

export default class Hard_record extends Component {
    render() {
        return (
            <div>
              hard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_recordhard_record
            </div>
        )
    }
}
