import React, { Component } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { HighEditor } from '../../../components/global_components/editor/index';
import { getTypeSelectList, addQuestionInfo } from '../../../api/helpCenter/index';
import './index.scss';
const { Option } = Select;
class index extends Component {
    state = {
        selectList: [],
        istip: false,
        tip: '',
        details: ''
    }
    componentDidMount() {
        this.getTypeSelectList();
    }
    //保存
    submit = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.istip) {
                    addQuestionInfo({ ...values, details: this.state.details }).then(res => {
                        if (res === 'success') {
                            this.mesSuccess('保存成功');
                            this.props.history.push('/main/Generalmanagement/contenidosList');
                        } else {
                            this.mesWarning('保存失败');
                        }
                    })
                }
                return;

            }
        });
    }
    //分类下拉框
    getTypeSelectList = () => {
        getTypeSelectList({}).then(res => {
            if (res) {
                this.setState({
                    selectList: [...res]
                })
            }
        })
    }

    getText = (str) => {
        return str.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "");
    }
    isNull = (str) => {
        if (str == "") return true;
        var regu = "^[ ]+$";
        var re = new RegExp(regu);
        return re.test(str);
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <div className="addProblema">
                <Form className="addProblema_form">
                    <Form.Item label="问题分类">
                        {getFieldDecorator('typeId', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择问题分类',
                                },
                                // /^[^\s]*$/
                            ],
                        })(
                            <Select
                                style={{ width: '500px' }}
                                placeholder='请选择问题分类'
                            >
                                {
                                    this.state.selectList.map((item, key) => {
                                        return <Option key={key} value={item.id}>{item.typeName}</Option>
                                    })
                                }
                            </Select>
                        )
                        }
                    </Form.Item>
                    <Form.Item label="标题" >
                        {getFieldDecorator('title', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入标题',
                                },
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ],
                        })(
                            <Input
                                maxLength={30}
                                style={{ width: '500px' }}
                                suffix={<span>{getFieldValue('title')?.length || 0}/30</span>}
                                placeholder="请输入标题"
                            />
                        )
                        }
                    </Form.Item>
                    <Form.Item label="内容" validateStatus="error">
                        {getFieldDecorator('details', {
                            // rules: [
                            //     {
                            //         required: true,
                            //         message: '请输入内容',
                            //     },
                            //     {
                            //         whitespace:true,
                            //         message: '不能输入空格',
                            //     }
                            // ],
                        })(
                            <>
                                <HighEditor
                                    ref={ref => this.onEdit = ref}
                                    montedNode={"addProblema_Editor"}
                                    zIndex={1}
                                    placeholder={"请输入内容"}
                                    height="200"
                                    width="500"
                                    onChange={(e) => {
                                        this.setState({
                                            details: e
                                        })
                                        let aa = this.getText(e);
                                        if (this.isNull(aa)) {
                                            this.setState({
                                                tip: "请输入内容",
                                                istip: false
                                            })
                                        } else {
                                            this.setState({
                                                tip: "",
                                                istip: true
                                            })
                                        }

                                    }}
                                // isStat={true}
                                // html={'<p>111</p>'}
                                />
                                <p style={{ color: 'red' }}>{this.state.tip}</p>
                            </>
                        )
                        }
                    </Form.Item>

                </Form>
                <div className="addProblema_footer">
                    <span>
                        <Button type="primary" onClick={() => this.submit()}>保存</Button>
                        <Button onClick={()=>this.props.history.goBack()}>取消</Button>
                    </span>
                </div>
            </div>
        )
    }
}
export default Form.create()(index);