import React,{useEffect,forwardRef} from 'react';
import { Modal, Form, Input, DatePicker } from 'antd';

const FormItem = Form.Item;

const DelayModal = (props,ref) => {
    const {
        visible,onOk,onCancel,item={},
        form:{
            getFieldDecorator,
            validateFields,
            resetFields,
        }
    } = props;

    useEffect(()=>{
        if(!visible){
            resetFields();
        };
    },[resetFields, visible]);

    const Ok = async () => {
        try {
            const res = await validateFields();
            onOk(res);
        } catch (error) {}
    };

    const Cancel = () => {
        onCancel();
    };

    return (
        <Modal
            visible={visible}
            title="延期设置"
            className="global_modal"
            onOk={Ok}
            ref={ref}
            onCancel={Cancel}
            getContainer={false}
            okText="确认延期"
            okButtonProps={{size:'large'}}
            cancelButtonProps={{size:'large'}}
            bodyStyle={{
                height:180
            }}
        >
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                colon={false}
            >
                <FormItem label='到期方式'>
                    {getFieldDecorator("stopTime",{
                        initialValue:item.endDate
                    })(
                        <Input
                            type="text"
                            style={{
                                width:260,
                            }}
                            size="large"
                            disabled
                        />
                    )}
                </FormItem>
                <FormItem label='延期时间'>
                    {getFieldDecorator("time", {
                        rules: [
                            { 
                                required: true, 
                                message: "请选择延期时间!" 
                            },
                        ],
                    })(
                        <DatePicker style={{width:260}} size="large"/>
                    )}
                </FormItem>
            </Form>
        </Modal>
    )
}

export default Form.create()(forwardRef(DelayModal))