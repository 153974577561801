import { post } from '@/until/axios_instans';

//新闻专题管理列表
export function getNewsSpecialList(data) {
    return post('/api/zixun/api/v1/news/special/getNewsSpecialList', data);
}

//查询系统集合
export function getPlatformList(data) {
    return post('/api/zixun/api/v1/news/index/getPlatformList', data);
}

//删除新闻专题
export function deleteNewsSpecial(data) {
    return post('/api/zixun/api/v1/news/special/deleteNewsSpecial', data);
}

//平台运营推送新闻列表
export function getSystemPushNewsList(data) {
    return post('/api/zixun/api/v1/news/push/getSystemPushNewsList', data);
}

//删除推送新闻
export function deletePushNews(data) {
    return post('/api/zixun/api/v1/news/push/deletePushNews', data);
}

//平台再次推送新闻
export function systemAgainPushNews(data) {
    return post('/api/zixun/api/v1/news/push/systemAgainPushNews', data);
}

//保存或修改新闻专题
export function saveOrUpdateNewsSpecial(data) {
    return post('/api/zixun/api/v1/news/special/saveOrUpdateNewsSpecial', data);
}

//查询专题子专栏列表
export function getSpecialColumnList(data) {
    return post('/api/zixun/api/v1/news/special/getSpecialColumnList', data);
}

//查询专题信息
export function getSpecialInfo(data) {
    return post('/api/zixun/api/v1/news/special/getSpecialInfo', data);
}

//平台查询选择推送新闻列表
export function getChoosePushNewsList(data) {
    return post('/api/zixun/api/v1/news/push/getChoosePushNewsList', data);
}

//查询推送机构列表
export function getPushOrganizationList(data) {
    return post('/api/zixun/api/v1/news/push/getPushOrganizationList', data);
}

//平台新增推送新闻
export function addSystemPushNews(data) {
    return post('/api/zixun/api/v1/news/push/addSystemPushNews', data);
}

//新增专题子专栏
export function addSpecialColumn(data) {
    return post('/api/zixun/api/v1/news/special/addSpecialColumn', data);
}

//编辑专题子专栏
export function updateSpecialColumn(data) {
    return post('/api/zixun/api/v1/news/special/updateSpecialColumn', data);
}

//查询子专栏关联新闻列表
export function getColumnNewsList(data) {
    return post('/api/zixun/api/v1/news/special/getColumnNewsList', data);
}

//删除专题子专栏
export function deleteSpecialColumn(data) {
    return post('/api/zixun/api/v1/news/special/deleteSpecialColumn', data);
}

//删除子专栏关联新闻
export function deleteColumnNews(data) {
    return post('/api/zixun/api/v1/news/special/deleteColumnNews', data);
}

//查询未关联新闻
export function getNotRelationNewsList(data) {
    return post('/api/zixun/api/v1/news/special/getNotRelationNewsList', data);
}

//子专栏关联新闻
export function columnRelationNews(data) {
    return post('/api/zixun/api/v1/news/special/columnRelationNews', data);
}

//查询专题详情
export function getSpecialDetail(data) {
    return post('/api/zixun/api/v1/news/special/getSpecialDetail', data);
}

//发布专题
export function releaseSpecial(data) {
    return post('/api/zixun/api/v1/news/special/releaseSpecial', data);
}

//取消发布专题
export function cancelReleaseSpecial(data) {
    return post('/api/zixun/api/v1/news/special/cancelReleaseSpecial', data);
}

//查询专题子专栏数量
export function getSpecialColumnNum(data) {
    return post('/api/zixun/api/v1/news/special/getSpecialColumnNum', data);
}

//查询专题新闻数量
export function getSpecialNewsNum(data) {
    return post('/api/zixun/api/v1/news/special/getSpecialNewsNum', data);
}
//附件列表
export function files(data) {
    return post('/api/zixun/api/v1/news/files',data);
}
