import React, { Component } from 'react';
import { Input, Form, Button, DatePicker, Radio } from 'antd';
import { pick } from 'lodash';
import ProvinceSelect from '../../components/province_select';
import CitySelect from '../../components/city_select';
import NationalitySelect from '../../components/nationality_select';
import DocumentTypeSelect from '../../components/document_type_select';
import NationSelect from '../../components/nation_select';
import PoliticsSelect from '../../components/politics_select';
import StudyLevelSelect from '../../components/study_level_select';
import SchoolSystemSelect from '../../components/school_system_select';
import FacultySelect from '../../components/faculty_select';
import StadentMajorSelect from '../../components/stadent_major_select';
import StudentClassSelect from '../../components/student_class_select';
import StudentSourceSelect from '../../components/student_source_select';
import EnrollmentMethodSelect from '../../components/enrollment_method_select';
import EnrollmentObjectSelect from '../../components/enrollment_object_select';
import AccountTypeSelect from '../../components/account_type_select';
import SchoolStatusSelect from '../../components/school_status_select';
import { addStudent, getStudentInfo, updataStudent } from '../../api/school_settlement';
import Moment from 'moment';
import styles from './index.module.scss';

export class StudentAdd extends Component {
	urlId = this.getUrlType(this.props.location.search,'id');
	userId = this.getUrlType(this.props.location.search,'userId');
	keyId = undefined;

	async componentDidMount() {
		this.urlId && this.userId && this.getTeacherInfo();
    };

	getTeacherInfo = async () => {
		const { setFieldsValue } = this.props.form;
		try {
			const res = await getStudentInfo({organization:this.urlId,id:this.userId});
			const values = pick(res, [
				'age','birthday','cardType','cityId','classId','eduSystem','email','enrollStudentObj','enrollStudentType',
				'facultyId','fullName','guardianPhone','idCard','introduction','jobNum','learningLevel','majorId','nationality','nationals',
				'organization','politicalOutlook','provinceId','sex','studentLiveType','studentSource','studentStatus',
				'telNum','postCategory',
			]);
			const keys = Object.keys(values);
			keys.forEach(it=>{
				if(values[it] === null){
					values[it] = undefined;
				};
			});
			values.birthday = Moment(values.birthday);
			values.cityId = String(values.cityId?values.cityId:'');
			values.provinceId = String(values.provinceId?values.provinceId:'');
			setFieldsValue({...values,native:[values.provinceId,values.cityId]});
			this.keyId = res.id;
		} catch (error) {
			console.warn(error);
		}
	};

	goBack = () => {
        this.props.history.goBack()
    };

	//提交表单
	onSubmit = async (flag) => {
		const {form:{validateFields,resetFields}} = this.props;
		try {
			const values = await validateFields();
			const { birthday } = values || {};
			birthday && (values.birthday = birthday.format('YYYY-MM-DD'))
			values.organization = this.urlId;
			delete values.native;
			if(this.userId){
				await updataStudent({...values,id:this.userId});
				this.mesSuccess('学生信息修改成功');
				this.goBack();
			}else{
				await addStudent(values);
				this.mesSuccess('学生信息新增成功');
				if(flag){
					this.goBack();
				}else{
					resetFields();
				}
			};
		} catch (error) {
			console.warn(error);
		}
	};

	//省市id变化
	provinceChange = (val) => {
		const {form:{getFieldValue,setFieldsValue,validateFields}} = this.props;
		const city = getFieldValue('cityId');
		if(!city)return;
		setFieldsValue({native:[val,undefined],cityId:undefined});
		validateFields(['native']);
	};

	//表单的间距配置
	formLayout = {
		labelCol: {
			xs: { span: 3 },
			sm: { span: 3 },
		},
		wrapperCol: {
			xs: { span: 4 },
			sm: { span: 4 },
		},
		colon:false
	};

	render() {

		const {
			form: {
				getFieldDecorator,
				getFieldValue,
				validateFields,
				setFieldsValue,
			}
		} = this.props;

		return (
			<div className={styles["student_add"]}>
				<Form {...this.formLayout}>
					<h1 style={{marginTop:0}}>学生信息</h1>
					<Form.Item label="学生姓名" className="max_height">
						{getFieldDecorator('fullName', {
							rules: [
								{ required: true, message: '请输入学生姓名' }
							],
						})(
							<Input
								className="max_input"
								placeholder='请输入学生姓名'
								size="large"
								maxLength={10}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("fullName") ? getFieldValue("fullName").length : 0}/
										<span style={{color: "#333"}}>10</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="手机号" className="max_height">
						{getFieldDecorator('telNum', {
							rules: [
								{ required: true, message: '请输入手机号' }
							],
						})(
							<Input
								className="max_input"
								placeholder='请输入手机号'
								size="large"
								maxLength={11}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("telNum") ? getFieldValue("telNum").length : 0}/
										<span style={{color: "#333"}}>11</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="性别">
						{getFieldDecorator('sex',{
							rules: [
								{ required: true, message: '请选择性别' }
							],
							initialValue:1,
						})(
							<Radio.Group>
								<Radio value={1}>男</Radio>
								<Radio value={0}>女</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					<Form.Item label="国籍" className="max_height">
						{getFieldDecorator('nationality',{
							rules: [
								{ required: false, message: '请选择国籍' }
							],
						})(
							<NationalitySelect
								style={{width:500}}
								placeholder="请选择国籍"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="出生日期">
						{getFieldDecorator('birthday')(
							<DatePicker
								style={{width:260}}
								placeholder="请选择出生日期"
								size='large'
								getCalendarContainer={triggerNode => triggerNode.parentElement}
							/>
						)}
					</Form.Item>
					<Form.Item label="年龄" className="max_height">
						{getFieldDecorator('age', {
							rules: [
								{
									validator:(_,val,callback) => {
										if(val && isNaN(val)){
											callback(Error('请输入正确的年龄'));
											return;
										};
										callback();
									},
								}
							],
						})(
							<Input
								className="max_input"
								placeholder='请输入年龄'
								size="large"
								maxLength={3}
								style={{width:500}}
							/>,
						)}
					</Form.Item>
					<Form.Item label="证件类型" className="max_height">
						{getFieldDecorator('cardType',{
							rules: [
								{ required: true, message: '请选择证件类型' }
							],
						})(
							<DocumentTypeSelect
								style={{width:500}}
								placeholder="请选择证件类型"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="证件号码" className="max_height">
						{getFieldDecorator('idCard', {
							rules: [
								{ required: true, message: '请输入证件号码' }
							],
						})(
							<Input
								className="max_input"
								placeholder='请输入证件号码'
								size="large"
								maxLength={18}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("idCard") ? getFieldValue("idCard").length : 0}/
										<span style={{color: "#333"}}>18</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="学习层次" className="max_height">
						{getFieldDecorator('learningLevel',{
							rules: [
								{ required: false, message: '请选择学习层次' }
							],
						})(
							<StudyLevelSelect
								style={{width:500}}
								placeholder="请选择学习层次"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="民族" className="max_height">
						{getFieldDecorator('nationals')(
							<NationSelect
								style={{width:500}}
								placeholder="请选择民族"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="籍贯" className="sheng_shi_qu">
						{getFieldDecorator('native',{
							rules: [
								{
									required: false,
									// validator:(rule,val=[],callback) => {
									// 	const [provinceId,cityId] = val;
									// 	const str = [];
									// 	if(provinceId && cityId){
									// 		callback();
									// 	}else{
									// 		if(!provinceId){
									// 			str.push('省份');
									// 		};
									// 		if(!cityId){
									// 			str.push('市区');
									// 		};
									// 		callback(Error('请选择' + str.join('和')));
									// 	}
									// }
								}
							],
						})(
							<>
								<Form.Item label={undefined}>
									{getFieldDecorator('provinceId')(
										<ProvinceSelect
											showSearch
											style={{ width: 250 }}
											placeholder="请选择省"
											optionFilterProp="children"
											onChange={this.provinceChange}
											size='large'
										/>
									)}
								</Form.Item>
								<Form.Item label={undefined} style={{marginLeft:20}}>
									{getFieldDecorator('cityId')(
										<CitySelect
											provinceId={getFieldValue('provinceId')}
											showSearch
											style={{ width: 250 }}
											placeholder="请选择市"
											optionFilterProp="children"
											size='large'
											onChange={val => {
												setFieldsValue({native:[getFieldValue('provinceId'),val]});
												validateFields(['native']);
											}}
										/>
									)}
								</Form.Item>
							</>
						)}
					</Form.Item>
					<Form.Item label="政治面貌" className="max_height">
						{getFieldDecorator('politicalOutlook')(
							<PoliticsSelect
								style={{width:500}}
								placeholder="请选择政治面貌"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="邮箱" className="max_height">
						{getFieldDecorator('email')(
							<Input
								className="max_input"
								placeholder='请输入邮箱'
								size="large"
								style={{width:500}}
								type="email"
							/>,
						)}
					</Form.Item>
					<Form.Item label="个人简介" className="max_text">
						{getFieldDecorator('introduction')(
							<Input.TextArea
								placeholder='请输入个人简介'
								size="large"
								maxLength={200}
								style={{height:90}}
							/>,
						)}
						<span className="max_length">
							{getFieldValue("introduction") ? getFieldValue("introduction").length : 0}/
							<span>200</span>
						</span>
					</Form.Item>
					<p className={styles["br"]}/>
					<h1>学生院校信息</h1>
					<Form.Item label="学号" className="max_height">
						{getFieldDecorator('jobNum', {
							rules: [
								{
									required: true,
									validator:(_,val,callback) => {
										if(!val){
											callback(Error('请输入学号'));
											return;
										};
										// if(!(/^(?=^.*?[a-zA-Z])(?=^.*?\d).{6,15}$/.test( val ))) {
										// 	callback(Error('学号需要数字+字母长度为6-15之间'));
										// 	return;
										// }
										callback();
									},
								}
							],
						})(
							<Input
								className="max_input"
								placeholder='请输入学号'
								size="large"
								maxLength={15}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("jobNum") ? getFieldValue("jobNum").length : 0}/
										<span style={{color: "#333"}}>15</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="学制" className="max_height">
						{getFieldDecorator('eduSystem',{
							rules: [
								{ required: false, message: '请选择学制' }
							],
						})(
							<SchoolSystemSelect
								style={{width:500}}
								placeholder="请选择学制"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="院系" className="max_height">
						{getFieldDecorator('facultyId',{
							rules: [
								{ required: true, message: '请选择院系' }
							],
						})(
							<FacultySelect
								ids={this.urlId}
								style={{width:500}}
								placeholder="请选择院系"
								size='large'
								onChange={()=>{
									setFieldsValue({majorId:undefined});
									setFieldsValue({classId:undefined});
								}}
							/>
						)}
					</Form.Item>
					<Form.Item label="专业" className="max_height">
						{getFieldDecorator('majorId',{
							rules: [
								{ required: true, message: '请选择专业' }
							],
						})(
							<StadentMajorSelect
								ids={getFieldValue('facultyId')}
								style={{width:500}}
								placeholder="请选择专业"
								size='large'
								onChange={()=>{
									setFieldsValue({classId:undefined});
								}}
							/>
						)}
					</Form.Item>
					<Form.Item label="行政班级" className="max_height">
						{getFieldDecorator('classId',{
							rules: [
								{ required: true, message: '请选择行政班级' }
							],
						})(
							<StudentClassSelect
								ids={getFieldValue('majorId')}
								style={{width:500}}
								placeholder="请选择行政班级"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="学生来源" className="max_height">
						{getFieldDecorator('studentSource')(
							<StudentSourceSelect
								style={{width:500}}
								placeholder="请选择学生来源"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="招生方式" className="max_height">
						{getFieldDecorator('enrollStudentType')(
							<EnrollmentMethodSelect
								style={{width:500}}
								placeholder="请选择招生方式"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="招生对象" className="max_height">
						{getFieldDecorator('enrollStudentObj')(
							<EnrollmentObjectSelect
								style={{width:500}}
								placeholder="请选择招生对象"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="监护人电话" className="max_height">
						{getFieldDecorator('guardianPhone')(
							<Input
								className="max_input"
								placeholder='请输入监护人电话'
								size="large"
								maxLength={11}
								style={{width:500}}
								suffix={
									<span style={{color: "#888",fontSize:12}}>
										{getFieldValue("guardianPhone") ? getFieldValue("guardianPhone").length : 0}/
										<span style={{color: "#333"}}>11</span>
									</span>
								}
							/>,
						)}
					</Form.Item>
					<Form.Item label="户口类型" className="max_height">
						{getFieldDecorator('studentLiveType')(
							<AccountTypeSelect
								style={{width:500}}
								placeholder="请选择户口类型"
								size='large'
							/>
						)}
					</Form.Item>
					<Form.Item label="在校状态" className="max_height">
						{getFieldDecorator('studentStatus')(
							<SchoolStatusSelect
								style={{width:500}}
								placeholder="请选择在校状态"
								size='large'
							/>
						)}
					</Form.Item>
				</Form>
				<div className={styles["file_footer"]}>
					<Button className={styles["btn_yes"]} size="large" type="primary" onClick={()=>this.onSubmit(true)}>确定{this.userId ? '修改' : '创建'}</Button>
					{!this.userId && <Button className={styles["btn_no"]} size="large" type="primary" ghost onClick={()=>this.onSubmit()}>保存并继续添加</Button>}
					<Button className={styles["btn_no"]} size="large" type="primary" ghost onClick={this.goBack}>取消</Button>
				</div>
				<div style={{height:60}}></div>
			</div>
		)
	}
}

export default Form.create()(StudentAdd);
