/**
 * 临时热门资源
 */

//import {  post, get } from "@/until/http";
import { post } from '../../until/axios_instans';
//临时热门方向资源管理查询
export function getTempHotLists(data) {
    return post(window.apiPrefix+"/api/v1/rcenter/operate/hot/getTempHotLists", data);
}
//设置热门方向
export function updateTempHotResources(data) {
    return post(window.apiPrefix+"/api/v1/rcenter/operate/hot/updateTempHotResources", data);
}
//资源分类（热门方向）
export function getSubjectList(data) {
    return post(window.apiPrefix+"/api/v1/rcenter/common/subject/getSubjectList", data);
}







