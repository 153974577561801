import LoadableComponent from '../until/LoadableComponent';
let moduleFile = require.context('../page', true, /\index.(jsx|js)$/);
let result = moduleFile.keys().reduce((prev, item) => {
    let str = item.split('/')[item.split('/').length - 2];
    let name = str[0].toLocaleUpperCase() + str.slice(1);
    prev = Object.assign({}, prev, {
        [name]: LoadableComponent(import('../page' + item.slice(1))),
    });
    return prev;
}, {});


export default [
    {
        name: '培训中心',
        path: '/main/training-center',
        component: result['Training-center'],
        children: [
            {
                path: '/main/training-center',
                redirect: '/main/training-center/classification',

            },
            {
                name: '分类管理',
                path: '/main/training-center/classification',
                component: result['Classification']
            },
            {
                name: '分类查看',
                path: '/main/training-center/classificationLook',
                component: result['ClassificationLook']
            },
            {
                name: '机构标签使用情况',
                path: '/main/training-center/useAgency',
                component: result['UseAgency']
            },
            {
                name: '标签管理',
                path: '/main/training-center/relevance',
                component: result['Relevance']
            }
        ]
    },
]

