import { MODULE_VISIBLE, SET_TABLE_TITLE_MODULE, SET_ZHANKAI_SHOUQI,CHECKALL,BATCHSURE,CONFIM_AND_CANCEL,GETONSHEL } from './action_type'
import {getOnShel} from '@/api/grounding'
import {GETSHANGJIA_LIST} from "../uploading/action_type";

export const MODULE_VISIBLEfn = (data) => ({
    type: MODULE_VISIBLE,
    data: data
});
export const SET_TABLE_TITLE_MODULEfn = (data) => ({
    type: SET_TABLE_TITLE_MODULE,
    data: data
});
export const SET_ZHANKAI_SHOUQIfn = (data) => ({
    type: SET_ZHANKAI_SHOUQI,
    data: data
});
export const CHECKALLfn = (data) => ({
    type: CHECKALL,
    data: data
});
export const BATCHSUREfn = (data) => ({
    type: BATCHSURE,
    data: data
});

export const CONFIM_AND_CANCELfn = (data) => ({
    type: CONFIM_AND_CANCEL,
    data: data
});

//上架列表
export const GETONSHELfn=(data)=>{
    return async (dispatch)=>{
        let r=await getOnShel(data)
        dispatch({
            type:GETONSHEL,
            data:r||[]
        })
    }
}
