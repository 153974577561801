import { createContext } from 'react';

//终端
const terObj = {
    WEB: 0,
    APP: 1
}
//弹框type值
const modalType = {
    WebNav: 'WebNav',
    WebPage: 'WebPage',
    WebBtn: 'WebBtn',
    WebNavDetail: 'WebNavDetail',
    WebPageDetail: 'WebPageDetail',
    WebBtnDetail: 'WebBtnDetail',
    AppBtnDetail: 'AppBtnDetail',
    AppFunc: 'AppFunc',
    AppBtn: 'AppBtn'
}

//菜单类型
const menuType = {
    nav: 80,
    page: 81,
    btn: 82
}

const store = {
    submitFormData: 0, //提交表单数据
    handleType: null, //弹框中添加还是编辑
    webHandleVisible: false, //web操作的弹框控制显隐
    webDetailVisible: false, //web详情弹框控制显隐
    type: null, //弹框要做什么事
    modalTitle: '', //弹框title
    terIndex: terObj.WEB,
    refreshList: 0,  //用于刷新列表
    visible: false,
    sysValue: '',//所属空间code
    sysName: '', //所属空间名称
    detailData: {},//查看菜单详情数据
    editParams: {},//编辑菜单数据
    saveContinue: 0,//保存并继续添加
    pageLevelOneData: {},//一级页面信息
    selectedMenus: [], //已选中的项
    loading: false
}

const open_modal = 'open_modal';
const close_modal = 'close_modal';

const Actions = {
    //打开弹框
    [open_modal](state, action) {
        state.webHandleVisible = true;
        state.visible = true;
    },
    //关闭弹框
    [close_modal](state, action) {
        state.webHandleVisible = false;
        state.webDetailVisible = false;
        state.visible = false;
    },
    //设置已选中的菜单项
    set_selectedMenus(state, { payload }) {
        state.selectedMenus = payload;
    },
    //刷新列表
    refresh_list(state) {
        state.refreshList = state.refreshList + 1;
    },
    //设置所属系统
    change_sys(state, { payload }) {
        if (payload) {
            state.sysValue = payload.platform;
            state.sysName = payload.platformName;
        }
    },
    //设置所属终端
    change_ter(state, { payload }) {
        state.terIndex = payload;
    },
    //设置弹框相关信息
    set_handle_info(state, { payload }) {
        state.type = payload.modalType;
        state.modalTitle = payload.modalTitle;
        state.handleType = payload.handleType;
        if (payload.handleType === 'add') {
            state.editParams = {};
            state.pageLevelOneData = {};
        }
    },
    //弹框保存
    sure_handle(state, action) {
        state.submitFormData = state.submitFormData + 1;
    },
    //设置详情信息
    get_web_detail(state, { payload }) {
        state.detailData = payload.detailData;
        state.webDetailVisible = true;
        state.modalTitle = payload.modalTitle;
        state.type = payload.type;
        state.handleType = payload.handleType;
    },
    //编辑
    set_edit_params(state, { payload }) {
        state.editParams = payload;
    },
    //保存并继续添加
    sure_handle_continue(state) {
        state.saveContinue++;
    },
    //设置一级页面信息
    set_pageLevelOneData(state, { payload }) {
        state.pageLevelOneData = payload;
    },
    //设置弹框loading
    change_loading(state) {
        state.loading = !state.loading;
    }
}
const reducer = (state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    Actions[action.type] && Actions[action.type](newState, action);
    return newState;
}
const Context = createContext(null);


export {
    Context,
    reducer,
    store,
    modalType,
    terObj,
    menuType
}