import React from 'react';


//门户管理
export default props => {
    return <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1969 12.0311H7.67446V10.3804H6.32831V12.0311H3.80589C3.43419 12.0311 3.13281 12.3325 3.13281 12.7042C3.13281 13.0759 3.43419 13.3772 3.80589 13.3772H10.1969C10.5686 13.3772 10.87 13.0759 10.87 12.7042C10.87 12.3325 10.5686 12.0311 10.1969 12.0311Z" fill="#6A7E9C"/>
        <path d="M12.0721 11.0536H1.92999C1.11346 11.0536 0.449219 10.3893 0.449219 9.57281V2.10381C0.449219 1.28729 1.11346 0.623047 1.92999 0.623047H12.0721C12.8886 0.623047 13.5529 1.28729 13.5529 2.10381V6.54251C13.5529 6.91426 13.2515 7.21559 12.8798 7.21559C12.5081 7.21559 12.2067 6.91426 12.2067 6.54251V2.10381C12.2067 2.03085 12.1451 1.9692 12.0721 1.9692H1.92999C1.85703 1.9692 1.79537 2.03085 1.79537 2.10381V9.57281C1.79537 9.64577 1.85703 9.70742 1.92999 9.70742H12.0721C12.1451 9.70742 12.2067 9.64577 12.2067 9.57281V8.49082C12.2067 8.11912 12.5081 7.81775 12.8798 7.81775C13.2515 7.81775 13.5529 8.11912 13.5529 8.49082V9.57281C13.5529 10.3893 12.8886 11.0536 12.0721 11.0536Z" fill="#6A7E9C"/>
        <path d="M7.07151 8.69211C6.98312 8.69211 6.89559 8.6747 6.81393 8.64087C6.73226 8.60704 6.65806 8.55746 6.59556 8.49495C6.53306 8.43245 6.48348 8.35824 6.44966 8.27657C6.41583 8.1949 6.39843 8.10737 6.39844 8.01898V3.66234C6.39844 3.29058 6.69981 2.98926 7.07151 2.98926C7.44321 2.98926 7.74459 3.29058 7.74459 3.66234V5.73213L7.9254 5.45159C7.98639 5.35696 8.07013 5.27914 8.16897 5.22525C8.26781 5.17136 8.37859 5.14311 8.49117 5.14311H10.6597C11.0314 5.14311 11.3327 5.44443 11.3327 5.81618C11.3327 6.18794 11.0314 6.48926 10.6597 6.48926H8.85818L7.63727 8.38357C7.5763 8.47821 7.49255 8.55604 7.39371 8.60995C7.29488 8.66385 7.18409 8.6921 7.07151 8.69211Z" fill="#BABABA"/>
        <path d="M5.70315 6.4528H3.44651C3.07476 6.4528 2.77344 6.15143 2.77344 5.77973C2.77344 5.40803 3.07476 5.10665 3.44651 5.10665H5.3265L6.45118 3.27383C6.64557 2.95695 7.05997 2.85766 7.3769 3.0522C7.69373 3.24659 7.79297 3.6611 7.59853 3.97787L6.27683 6.13172C6.21664 6.22982 6.13229 6.31085 6.03186 6.36707C5.93142 6.42328 5.81825 6.4528 5.70315 6.4528Z" fill="#6A7E9C"/>
    </svg>

}
