import { Breadcrumb } from 'antd'
import React, {
    Component,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState
} from 'react'
import style from './BreadcrumbFC.module.scss'
import { useHistory, useLocation } from 'react-router-dom'

const Noop = () => {}
const Context = createContext({
    list: [],
    setList: Noop
})

/**
 * @param {{
 *  path:string,
 *  name:string,
 *  replace:boolean,
 *  title:string
 * }[]} beforeBreadcrumb
 * @param list
 */
const BreadcrumbFC = ({ beforeBreadcrumb = [], list = [] }) => {
    if (!Array.isArray(beforeBreadcrumb) || !Array.isArray(list)) {
        throw new Error('BreadcrumbFC组件参数错误. beforeBreadcrumb,list 必须是数组 ')
    }
    const history = useHistory()
    const { pathname } = useLocation()

    const getPath = useCallback((route) => route.path ? route.path : (route?.menuUrl ?? ''), [])
    const handleJump = useCallback((route) => {
        const jump = route.replace ? history.replace : history.push
        const path = getPath(route)
        if (path && path !== pathname) {
            jump(path)
        }
    }, [getPath, history.push, history.replace, pathname])
    const breadcrumbs = useMemo(() => beforeBreadcrumb.concat(list), [beforeBreadcrumb, list])
    const title = useMemo(() => {
        const route = breadcrumbs[breadcrumbs.length - 1]
        if (route) {
            return route?.title ?? (route?.name ?? '')
        }
        return ''
    }, [breadcrumbs])
    return (
        <div className={style.breadcrumb_box}>
            <Breadcrumb separator={
                <span>{'/'}</span>}>
                {breadcrumbs
                    ?.filter(Boolean)
                    ?.map?.((route, i, arr) => (
                        <Breadcrumb.Item
                            key={i}
                            className={
                                i < arr.length - 1
                                    ? getPath(route)
                                        ? 'pointer'
                                        : 'default'
                                    : 'default'
                            }
                            onClick={() => i < arr.length - 1 && handleJump(route)}
                        >
                            {(route?.name || route?.menuName) ?? ''}
                        </Breadcrumb.Item>
                    ))}
            </Breadcrumb>
            <div className={style.breadcrumb_title}>
                {title}
            </div>
        </div>
    )
}
export default BreadcrumbFC

/**
 * @param {{
 *  path:string,
 *  name:string,
 *  replace:boolean,
 * }[]} list
 */
export const useBreadcrumbFC = (list) => {
    const { setList } = useContext(Context)
    useEffect(() => {
        setList(list)
    }, [list])
    useLayoutEffect(() => () => setList([]), [])
}
/**
 * @param {{
 *  path:string,
 *  name:string,
 *  replace:boolean,
 * }[]} list
 */
export const BreadcrumbFCLine = ({ list }) => {
    useBreadcrumbFC(list)
    return null
}
export const BreadcrumbFCContext = Context