import { post } from '../../until/axios_instans';

//选择调研模板列表
export function getQuestionnaireList(data) {
    return post("/api/wenjuan/api/v1/platform/chooseTemplateList", data);
}

//删除调研模板
export function deleteList(data) {
    return post("/api/wenjuan/api/v1/platform/deleteTemplate", data);
}

