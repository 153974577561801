import React, { Component } from 'react';
import { Empty, Tree } from 'antd';
import './index.scss';
let { TreeNode } = Tree;

export default class index extends Component {
    state = {
        expandedKeys: [],
        checkedKeys: []
    }
    componentDidMount() {
        let { checkedKeys } = this.props;
        if (checkedKeys) {
            this.setState({ checkedKeys })
        }
    }
    //选中
    onCheck = (checkedKeys, arr) => {
        let { checkedNodes } = arr;
        console.log(checkedNodes,checkedKeys)
        this.setState({
            checkedKeys: checkedNodes.map(v => ({ areaId: v.key, areaName: v.props.title }))
        })
    }
    //递归方法遍历树形控件
    renderTree = (data, idx) => {
        return data.map(item => {
            if (!item.children) {
                return (
                    <TreeNode title={item.areaName} key={item.areaId} />
                )
            } else {
                return (
                    <TreeNode title={item.areaName} key={item.areaId}>
                        {this.renderTree(item.children)}
                    </TreeNode>
                )
            }
        })

    };
    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys
        })
    }
    del = item => {
        let { checkedKeys } = this.state;
        checkedKeys = checkedKeys.filter(v => v.areaId !== item.areaId);
        this.setState({ checkedKeys });
    }
    render() {
        let { dataList, option } = this.props;
        let { expandedKeys, checkedKeys } = this.state;
        return (
            <div className='data_box'>
                <div className="left">
                    <div className="left_top">
                        <ul>
                            <li>
                                <span>成员名称</span><span>{option.fullName}</span>
                            </li>
                            <li>
                                <span>所属机构</span><span>{option.organizationName}</span>
                            </li>
                            <li>
                                <span>角色名称</span><span>{option.roleName}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="left_bottom">
                        <p className='title'>请选择数据范围</p>
                        <div className="tree_box">
                            <Tree
                            defaultExpandAll={true}
                            checkable
                            // onExpand={this.onExpand}
                            onCheck={this.onCheck}
                            // expandedKeys={expandedKeys}
                            checkedKeys={checkedKeys.map(v => v.areaId)}
                            >
                                 {this.renderTree(dataList)}
                            </Tree>
                        </div>
                    </div>  
                </div>
                <div className="right">
                    {
                        checkedKeys && checkedKeys.length ?
                            <div className='selelist_box'>
                                {
                                    checkedKeys.map(v => {
                                        return <span key={v.key}>
                                            {v.areaName}
                                            <img onClick={() => this.del(v)} src={require('@/assets/img/delete_icon.png')} alt="" />
                                        </span>
                                    })
                                }
                            </div>
                            :
                            <div className='data_empty'>
                                <Empty image={require('@/assets/img/data_empty.png')} description='暂无数据范围' />
                            </div>
                    }
                </div>
            </div>
        )
    }
}
