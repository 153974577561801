import { post, get } from '@/until/axios_instans';

//账号信息回显
export function getAccountNumInfo(data) {
    return get('/api/ucenter/api/v1/userInfo/getAccountNumInfo', data);
}
//保存新密码
export function savePassword(data) {
    return post('/api/ucenter/api/v1/userInfo/savePassword', data);
}

//获取验证码
export let sendTelNum = (data) => {
    return post('/api/ucenter/api/v1/userCenter/sms/sendTelNum',data)
}
//验证验证码
export let verificationTelNum = (data) => {
    return post('/api/ucenter/api/v1/userCenter/sms/verificationTelNum',data)
}
//密码加密
export let encryptionPassword = (data) => {
    return post('/api/ucenter/api/v1/partner/trans',data)
}