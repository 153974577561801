import { post } from '../../until/axios_instans';

//查询审核列表
export function getList(data) {
  return post(window.apiPrefix + "/api/v1/rcenter/manage/examine/page", data);
}

//审核
export function pass(data) {
  return post(window.apiPrefix + "/api/v1/rcenter/manage/examine/pass", data);
}
