import { result } from './result'

export default [
    {
        name: "活动大赛",
        path: "/main/huodongdasai",
        component: result.ActivitySeries,
        children: [
            {
                name: "赛事类别管理",
                path: "/main/huodongdasai/addClasses",
                component: result.AddClasses
            }, {
                path: "/main/huodongdasai",
                redirect: "/main/huodongdasai/addClasses"
            }
        ],
    },
]