import React, { Component } from 'react';
import './index.scss';
import { Layout } from 'antd';
import RouterView from '@/router/router_view';
import Menu from '@/components/Menu';
import OBreadcrumb from '@/components/BreadcrumbNews';
import routerList from '@/router/router_config.js';
import { connect } from 'react-redux';
const { Sider, Content } = Layout;
const imgUrl='cvec-space-front-guanli/zixun/icon/'


const menuList = [
    {
        menuName: '栏目管理',
        id: 'sub1',
        icon:imgUrl+'management.png',
        iconActive:imgUrl+'management-selected.png',
        menuUrl: '/main/group-column/column'
    },
    {
        menuName: '新闻管理',
        id: 'sub2',
        icon:imgUrl+'news.png',
        children: [
            {
                menuName: '已发布',
                id: '21',
                menuUrl: '/main/group-column/published'
            },
            {
                menuName: '草稿箱',
                id: '22',
                menuUrl: '/main/group-column/drafts'
            },
            {
                menuName: '回收站',
                id: '23',
                menuUrl: '/main/group-column/recycleBin'
            },
            {
                menuName: '新增新闻',
                id: '24',
                menuUrl: '/main/group-column/addnews',
                isHade:true
            },
        ]
    },
    {
        menuName: '专题管理',
        id: 'sub3',
        icon: imgUrl+'special.png',
        children: [
            {
                menuName: '专题配置',
                id: '31',
                menuUrl: '/main/group-column/thematicConfiguration'
            },
            {
                menuName: '新增专题',
                id: '32',
                menuUrl: '/main/group-column/addThematic',
                isHade:true
            },
        ]
    },
    {
        menuName: '推送管理',
        id: 'sub4',
        icon: imgUrl+'Push.png',
        iconActive:imgUrl+'push-selected.png',
        menuUrl: '/main/group-column/pushManagement'
    },
]

class index extends Component {
    render() {
        const { menuList } = this.props;
        let path = this.props.location.pathname;
        let propsMenuList = menuList.find((v) => v.menuName === "资讯");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        console.log('propsMenuList',propsMenuList)
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        let  three;
        let two = propsMenuList.find(v => path.includes(v.menuUrl))
        propsMenuList && propsMenuList.forEach(v => {
            if (v) {
                if (v.children && v.children.length > 0 ) {
                    v.children.forEach(k => {
                        if (k.menuUrl === path) {
                            two = v;
                            three = k;
                        }
                    })
                }
            }
        })
        let arr = [{ ...one, title: one.name }, two, three];
        console.log("div",arr,propsMenuList)
        return (
            <Layout className='resource_box News_inzixun'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    <Menu {...this.props} openArr={[]} menuList={propsMenuList} />
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    {console.log('arrr',arr)}
                    {
                        arr[1] ? <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} /> : ''
                    }
                    {/*<OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />*/}
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}
index = connect(mapStateToProps)(index);
export default index;
