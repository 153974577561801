import React from 'react';

const word_icon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5556 0H2.44444C1.1 0 0 1.1 0 2.44444V19.5556C0 20.9 1.1 22 2.44444 22H19.5556C20.9 22 22 20.9 22 19.5556V2.44444C22 1.1 20.9 0 19.5556 0ZM15.2778 17.1111H13.4444L11 7.94445L8.55556 17.1111H6.72222L3.78889 4.88889H5.86667L7.7 14.0556L10.1444 4.88889H11.8556L14.3 14.0556L16.1333 4.88889H18.2111L15.2778 17.1111Z" fill="#3E78ED" />
</svg>;

const ppt_icon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="2.5" fill="#F07661" />
    <path d="M16.2909 9.49901C16.2909 10.3054 15.6423 10.998 14.805 10.998H6V16H7.70903V11.998L6.8545 12.998H14.8049C16.5531 12.998 18 11.4529 18 9.49901C18 7.54509 16.5531 6 14.8049 6H6V7.99999H14.8049C15.6423 8.00002 16.2909 8.69264 16.2909 9.49901Z" fill="white" />
</svg>;

const xls_icon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="2.5" fill="#27CDA2" />
    <path d="M15.405 5.2998L5.27265 15.4139C4.90848 15.7773 4.9092 16.366 5.2743 16.7285C5.63941 17.0912 6.23064 17.0904 6.59486 16.7269L16.7272 6.61289C17.0914 6.2494 17.0907 5.66079 16.7256 5.29818C16.3604 4.93562 15.7692 4.93634 15.405 5.29982V5.2998Z" fill="white" />
    <path d="M5.27266 6.58616L15.405 16.7002C15.7692 17.0637 16.3604 17.0644 16.7256 16.7018C17.0907 16.3392 17.0914 15.7506 16.7272 15.3871L6.59487 5.2731C6.23065 4.90961 5.63942 4.90887 5.27431 5.27145C4.90921 5.63406 4.90846 6.22268 5.27266 6.58616Z" fill="white" />
</svg>;

const mp4_icon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="2.5" fill="#2C7FFF" />
    <path d="M15.6107 11.9351L8.02043 16.5125C7.91313 16.5671 7.79347 16.5929 7.67346 16.5875C7.55346 16.5818 7.43698 16.5452 7.33461 16.4813C7.23225 16.417 7.14789 16.3273 7.08965 16.2203C7.03106 16.1136 7.00035 15.9934 7 15.8713V6.71693C7.00035 6.59451 7.03106 6.47424 7.08965 6.36761C7.14789 6.26099 7.23225 6.17123 7.33461 6.10697C7.43698 6.04271 7.55346 6.00609 7.67346 6.00071C7.79347 5.99532 7.91313 6.02081 8.02043 6.07574L15.6107 10.6528C15.7275 10.7123 15.826 10.8039 15.8948 10.9173C15.9636 11.0304 16 11.1611 16 11.2939C16 11.4271 15.9636 11.5575 15.8948 11.6709C15.826 11.784 15.7275 11.8755 15.6107 11.9351Z" fill="white" />
</svg>;

const mp3_icon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="2.5" fill="#F3B714" />
    <path d="M16.1 11.4286H15.5V10.0857C15.5 7.83286 13.4813 6 11 6C8.5187 6 6.5 7.83286 6.5 10.0857V11.4286H5.9C5.66131 11.4286 5.43239 11.5189 5.2636 11.6796C5.09482 11.8404 5 12.0584 5 12.2857V15.1429C5 15.3702 5.09482 15.5882 5.2636 15.749C5.43239 15.9097 5.66131 16 5.9 16H6.5C6.73869 16 6.96761 15.9097 7.1364 15.749C7.30518 15.5882 7.4 15.3702 7.4 15.1429V10.0857C7.4 8.30543 9.0149 6.85714 11 6.85714C12.9851 6.85714 14.6 8.30543 14.6 10.0857V15.1429C14.6 15.3702 14.6948 15.5882 14.8636 15.749C15.0324 15.9097 15.2613 16 15.5 16H16.1C16.3387 16 16.5676 15.9097 16.7364 15.749C16.9052 15.5882 17 15.3702 17 15.1429V12.2857C17 12.0584 16.9052 11.8404 16.7364 11.6796C16.5676 11.5189 16.3387 11.4286 16.1 11.4286Z" fill="white" />
    <path d="M11.6351 14.0357H11.6252C11.5382 14.0338 11.4536 14.008 11.3817 13.9613C11.3098 13.9146 11.2536 13.849 11.2199 13.7726L10.5278 12.1963L10.3622 12.6152C10.33 12.6968 10.2724 12.7671 10.1972 12.8167C10.1219 12.8663 10.0326 12.8929 9.94103 12.8929H8.60002C8.48068 12.8929 8.36622 12.8477 8.28183 12.7673C8.19743 12.687 8.15002 12.578 8.15002 12.4643C8.15002 12.3506 8.19743 12.2416 8.28183 12.1613C8.36622 12.0809 8.48068 12.0357 8.60002 12.0357H9.62993L10.085 10.8849C10.1501 10.7206 10.3136 10.6106 10.4981 10.6072C10.6733 10.5997 10.8503 10.7083 10.9214 10.8703L11.6597 12.5517L11.7929 12.282C11.8293 12.2085 11.8869 12.1463 11.959 12.1028C12.0311 12.0592 12.1147 12.0361 12.2 12.036H13.4C13.5194 12.036 13.6338 12.0812 13.7182 12.1615C13.8026 12.2419 13.85 12.3509 13.85 12.4646C13.85 12.5783 13.8026 12.6873 13.7182 12.7676C13.6338 12.848 13.5194 12.8932 13.4 12.8932H12.4856L12.0422 13.79C12.0058 13.8635 11.9482 13.9256 11.8761 13.9692C11.804 14.0127 11.7205 14.0357 11.6351 14.0357Z" fill="white" />
</svg>;

const img_icon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="2.5" fill="#46B034" />
    <path d="M5.35181 7.00541C5.35181 7.39638 5.50458 7.77133 5.77653 8.04779C6.04847 8.32425 6.41731 8.47956 6.8019 8.47956C7.18649 8.47956 7.55533 8.32425 7.82727 8.04779C8.09922 7.77133 8.25199 7.39638 8.25199 7.00541C8.25199 6.61444 8.09922 6.23948 7.82727 5.96302C7.55533 5.68656 7.18649 5.53125 6.8019 5.53125C6.41731 5.53125 6.04847 5.68656 5.77653 5.96302C5.50458 6.23948 5.35181 6.61444 5.35181 7.00541Z" fill="white" />
    <path d="M7.26478 11.1803C8.34081 11.1803 9.3814 13.8313 10.8457 13.8313C12.3102 13.8313 12.7712 9.36621 14.4326 9.36621C16.0942 9.36621 17.3693 12.9261 17.8136 14.4644C18.2581 16.0026 17.7497 16.4922 15.504 16.4922C13.2581 16.4922 6.1264 16.3962 5.23237 16.3962C4.33812 16.3962 3.79956 15.7224 4.40943 14.4241C5.01909 13.1256 6.18875 11.1801 7.26478 11.1801V11.1803Z" fill="white" />
</svg>;

export const teachingIcon = <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.875 15C1.875 18.481 3.25781 21.8194 5.71922 24.2808C8.18064 26.7422 11.519 28.125 15 28.125C18.481 28.125 21.8194 26.7422 24.2808 24.2808C26.7422 21.8194 28.125 18.481 28.125 15C28.125 11.519 26.7422 8.18064 24.2808 5.71922C21.8194 3.25781 18.481 1.875 15 1.875C11.519 1.875 8.18064 3.25781 5.71922 5.71922C3.25781 8.18064 1.875 11.519 1.875 15Z" fill="#52B2FF" />
    <path d="M13.7988 22.9541V18.4424C13.7988 18.1113 14.0684 17.8418 14.3994 17.8418H14.5459C14.877 17.8418 15.1465 18.1113 15.1465 18.4424V22.9541C15.1465 23.2852 14.877 23.5547 14.5459 23.5547H14.3994C14.0684 23.5547 13.7988 23.2852 13.7988 22.9541Z" fill="#A5D1F9" />
    <path d="M22.1484 20.4785H7.64648C6.94922 20.4785 6.38672 19.9131 6.38672 19.2188V10.166C6.38672 9.46875 6.95215 8.90625 7.64648 8.90625H22.1484C22.8457 8.90625 23.4082 9.47168 23.4082 10.166V19.2188C23.4082 19.9131 22.8428 20.4785 22.1484 20.4785ZM19.6289 23.9355H10.1367C9.76465 23.9355 9.46289 23.6338 9.46289 23.2617C9.46289 22.8896 9.76465 22.5879 10.1367 22.5879H19.6289C20.001 22.5879 20.3027 22.8896 20.3027 23.2617C20.3027 23.6338 20.001 23.9355 19.6289 23.9355Z" fill="white" />
    <path d="M16.3594 9.96094H8.03906C7.68457 9.96094 7.39453 10.251 7.39453 10.6055V18.75C7.39453 19.1074 7.68164 19.3945 8.03906 19.3945H12.4629L16.3594 9.96094Z" fill="#52B2FF" />
</svg>;

export const trainIcon = <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.875 15C1.875 18.481 3.25781 21.8194 5.71922 24.2808C8.18064 26.7422 11.519 28.125 15 28.125C18.481 28.125 21.8194 26.7422 24.2808 24.2808C26.7422 21.8194 28.125 18.481 28.125 15C28.125 11.519 26.7422 8.18064 24.2808 5.71922C21.8194 3.25781 18.481 1.875 15 1.875C11.519 1.875 8.18064 3.25781 5.71922 5.71922C3.25781 8.18064 1.875 11.519 1.875 15Z" fill="#27CDA2" />
    <path d="M11.7307 24.4133L11.6311 24.3137C11.511 24.1936 11.511 24.0032 11.6311 23.8831L14.5549 20.9592C14.675 20.8391 14.8655 20.8391 14.9856 20.9592L15.0852 21.0588C15.2053 21.179 15.2053 21.3694 15.0852 21.4895L12.1614 24.4133C12.0413 24.5334 11.8508 24.5334 11.7307 24.4133ZM18.3665 24.4016C18.22 24.5481 17.9856 24.5481 17.8391 24.4016L15.7415 22.304C15.595 22.1575 15.595 21.9231 15.7415 21.7766C15.8879 21.6301 16.1223 21.6301 16.2688 21.7766L18.3665 23.8743C18.51 24.0208 18.51 24.2551 18.3665 24.4016Z" fill="#6EE4C6" />
    <path d="M15.002 25.2656C14.6299 25.2656 14.3281 24.9639 14.3281 24.5918V19.207C14.3281 18.835 14.6299 18.5332 15.002 18.5332C15.374 18.5332 15.6758 18.835 15.6758 19.207V24.5918C15.6758 24.9639 15.374 25.2656 15.002 25.2656Z" fill="#6EE4C6" />
    <path d="M21.5215 19.8926H8.47852C7.43555 19.8926 6.5918 19.0488 6.5918 18.0059V10.998C6.5918 9.95508 7.43555 9.11133 8.47852 9.11133H21.5244C22.5674 9.11133 23.4111 9.95508 23.4111 10.998V18.0088C23.4082 19.0488 22.5644 19.8926 21.5215 19.8926Z" fill="white" />
    <path d="M14.7305 13.8281H10.2012C9.87305 13.8281 9.60938 13.5645 9.60938 13.2363V13.0723C9.60938 12.7441 9.87305 12.4805 10.2012 12.4805H14.7305C15.0586 12.4805 15.3223 12.7441 15.3223 13.0723V13.2363C15.3223 13.5645 15.0586 13.8281 14.7305 13.8281ZM19.8926 17.1973H10.3125C9.94043 17.1973 9.63867 16.8955 9.63867 16.5234C9.63867 16.1514 9.94043 15.8496 10.3125 15.8496H19.8926C20.2646 15.8496 20.5664 16.1514 20.5664 16.5234C20.5664 16.8955 20.2646 17.1973 19.8926 17.1973Z" fill="#27CDA2" />
</svg>;

export const practicalIcon = <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.875 15C1.875 18.481 3.25781 21.8194 5.71922 24.2808C8.18064 26.7422 11.519 28.125 15 28.125C18.481 28.125 21.8194 26.7422 24.2808 24.2808C26.7422 21.8194 28.125 18.481 28.125 15C28.125 11.519 26.7422 8.18064 24.2808 5.71922C21.8194 3.25781 18.481 1.875 15 1.875C11.519 1.875 8.18064 3.25781 5.71922 5.71922C3.25781 8.18064 1.875 11.519 1.875 15Z" fill="#F07661" />
    <path d="M7.42969 11.7188C7.42969 11.7188 11.7158 7.80474 15.2578 8.92388L19.7314 17.6836C19.7314 17.6836 17.3086 16.5645 11.9033 20.6661L7.42969 11.7188Z" fill="white" />
    <path d="M15.9922 10.4854C15.9922 10.4854 18.6143 10.9746 21.2217 8.54883L25.6953 17.4961C25.6953 17.4961 22.7129 20.4785 17.6797 19.7314C17.6797 19.7314 17.1201 19.3594 18.4238 18.7998C18.4238 18.7998 19.7275 18.7998 19.7275 17.6807L15.9922 10.4854Z" fill="#F9EAEA" />
    <path d="M17.6807 19.7344C17.6807 19.7344 17.0596 19.1133 18.7676 18.7705C18.7676 18.7705 19.916 18.6152 19.7314 17.6836C19.7314 17.6836 18.3633 17.3115 16.8105 17.9619L17.5869 19.7021L17.6807 19.7344Z" fill="white" />
    <path d="M17.6807 19.7344C17.6807 19.7344 17.0596 19.1133 18.7676 18.7705C18.7676 18.7705 19.916 18.6152 19.7314 17.6836C19.7314 17.6836 18.3633 17.3115 16.8105 17.9619L17.5869 19.7021L17.6807 19.7344Z" fill="#F96E6E" />
    <path d="M17.0706 27.9343L7.65747 10.4646C7.42017 10.0222 6.86646 9.85816 6.42408 10.0955L6.2981 10.1628C5.85572 10.4001 5.69165 10.9539 5.92896 11.3962L14.9436 28.1248C15.6702 28.1218 16.3762 28.0486 17.0706 27.9343Z" fill="white" />
</svg>;

export const stopNumIcon = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3974 4.86608C19.6373 4.14284 21.1697 4.13943 22.4128 4.85714L32.2486 10.5359C33.4917 11.2536 34.255 12.5824 34.2486 14.0178L34.1973 25.5352C34.191 26.9515 33.4362 28.2589 32.2128 28.9725L22.264 34.7756C21.0241 35.4989 19.4917 35.5023 18.2486 34.7846L8.41282 29.1059C7.1697 28.3881 6.40647 27.0594 6.41286 25.6239L6.46414 14.1065C6.47045 12.6902 7.22527 11.3828 8.4487 10.6692L18.3974 4.86608Z" fill="#888888" />
    <path d="M25.9184 16.4608C25.9443 16.4867 25.9648 16.5174 25.9788 16.5512C25.9928 16.585 26 16.6213 26 16.6578C26 16.6944 25.9928 16.7307 25.9788 16.7645C25.9648 16.7983 25.9443 16.829 25.9184 16.8549L22.2733 20.5L25.9184 24.1451C25.9443 24.171 25.9648 24.2017 25.9788 24.2355C25.9928 24.2693 26 24.3056 26 24.3421C26 24.3787 25.9928 24.415 25.9788 24.4488C25.9648 24.4826 25.9443 24.5133 25.9184 24.5392L24.5392 25.9184C24.5133 25.9443 24.4826 25.9648 24.4488 25.9788C24.415 25.9928 24.3787 26 24.3421 26C24.3056 26 24.2693 25.9928 24.2355 25.9788C24.2017 25.9648 24.171 25.9443 24.1451 25.9184L20.5 22.2733L16.8549 25.9184C16.829 25.9443 16.7983 25.9648 16.7645 25.9788C16.7307 25.9928 16.6944 26 16.6579 26C16.6213 26 16.585 25.9928 16.5512 25.9788C16.5174 25.9648 16.4867 25.9443 16.4608 25.9184L15.0816 24.5392C15.0557 24.5133 15.0352 24.4826 15.0212 24.4488C15.0072 24.415 15 24.3787 15 24.3421C15 24.3056 15.0072 24.2693 15.0212 24.2355C15.0352 24.2017 15.0557 24.171 15.0816 24.1451L18.7267 20.5L15.0816 16.8549C15.0557 16.829 15.0352 16.7983 15.0212 16.7645C15.0072 16.7307 15 16.6944 15 16.6578C15 16.6213 15.0072 16.585 15.0212 16.5512C15.0352 16.5174 15.0557 16.4867 15.0816 16.4608L16.4608 15.0816C16.4867 15.0557 16.5174 15.0352 16.5512 15.0212C16.585 15.0072 16.6213 15 16.6579 15C16.6944 15 16.7307 15.0072 16.7645 15.0212C16.7983 15.0352 16.829 15.0557 16.8549 15.0816L20.5 18.7267L24.1451 15.0816C24.171 15.0557 24.2017 15.0352 24.2355 15.0212C24.2693 15.0072 24.3056 15 24.3421 15C24.3787 15 24.415 15.0072 24.4488 15.0212C24.4826 15.0352 24.5133 15.0557 24.5392 15.0816L25.9184 16.4608Z" fill="white" />
</svg>;

export const triallNumIcon = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3974 4.86608C19.6373 4.14284 21.1697 4.13943 22.4128 4.85714L32.2486 10.5359C33.4917 11.2536 34.255 12.5824 34.2486 14.0178L34.1973 25.5352C34.191 26.9515 33.4362 28.2589 32.2128 28.9725L22.264 34.7756C21.0241 35.4989 19.4917 35.5023 18.2486 34.7846L8.41282 29.1059C7.1697 28.3881 6.40647 27.0594 6.41286 25.6239L6.46414 14.1065C6.47045 12.6902 7.22527 11.3828 8.4487 10.6692L18.3974 4.86608Z" fill="#4FDEB3" />
    <path d="M19 14.3571C19 14.2624 19.0395 14.1716 19.1098 14.1046C19.1802 14.0376 19.2755 14 19.375 14L21.625 14C21.7245 14 21.8198 14.0376 21.8902 14.1046C21.9605 14.1716 22 14.2624 22 14.3571L22 18.6429C22 18.7376 21.9605 18.8284 21.8902 18.8954C21.8198 18.9624 21.7245 19 21.625 19L19.375 19C19.2755 19 19.1802 18.9624 19.1098 18.8954C19.0395 18.8284 19 18.7376 19 18.6429L19 14.3571Z" fill="white" />
    <path d="M19 22.3571C19 22.2624 19.0395 22.1716 19.1098 22.1046C19.1802 22.0376 19.2755 22 19.375 22L21.625 22C21.7245 22 21.8198 22.0376 21.8902 22.1046C21.9605 22.1716 22 22.2624 22 22.3571L22 26.6429C22 26.7376 21.9605 26.8284 21.8902 26.8954C21.8198 26.9624 21.7245 27 21.625 27L19.375 27C19.2755 27 19.1802 26.9624 19.1098 26.8954C19.0395 26.8284 19 26.7376 19 26.6429L19 22.3571Z" fill="white" />
</svg>;

export const formalNumIcon = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3974 4.86608C19.6373 4.14284 21.1697 4.13943 22.4128 4.85714L32.2486 10.5359C33.4917 11.2536 34.255 12.5824 34.2486 14.0178L34.1973 25.5352C34.191 26.9515 33.4362 28.2589 32.2128 28.9725L22.264 34.7756C21.0241 35.4989 19.4917 35.5023 18.2486 34.7846L8.41282 29.1059C7.1697 28.3881 6.40647 27.0594 6.41286 25.6239L6.46414 14.1065C6.47045 12.6902 7.22527 11.3828 8.4487 10.6692L18.3974 4.86608Z" fill="#FF802C" />
    <path d="M21.372 15C21.5003 15 21.6053 15.1069 21.6053 15.2374V23.8547H23.7662C23.9616 23.8547 24.0695 24.0833 23.95 24.2376L20.6837 28.4462C20.5904 28.5679 20.4095 28.5679 20.3162 28.4462L17.0501 24.2405C16.9305 24.0862 17.0384 23.8577 17.2338 23.8577H19.3889V15.2374C19.3889 15.1068 19.4939 15 19.6222 15H21.372Z" fill="white" />
    <circle cx="20.5" cy="14.5" r="2.5" fill="white" />
</svg>;

export const allNumIcon = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3974 4.86608C19.6373 4.14284 21.1697 4.13943 22.4128 4.85714L32.2486 10.5359C33.4917 11.2536 34.255 12.5824 34.2486 14.0178L34.1973 25.5352C34.191 26.9515 33.4362 28.2589 32.2128 28.9725L22.264 34.7756C21.0241 35.4989 19.4917 35.5023 18.2486 34.7846L8.41282 29.1059C7.1697 28.3881 6.40647 27.0594 6.41286 25.6239L6.46414 14.1065C6.47045 12.6902 7.22527 11.3828 8.4487 10.6692L18.3974 4.86608Z" fill="#1C6EFF" />
    <path d="M27.3336 13.7382C27.4054 13.6967 27.4907 13.6855 27.5707 13.7069C27.6508 13.7284 27.7191 13.7808 27.7605 13.8525L28.698 15.4764C28.7394 15.5481 28.7507 15.6334 28.7292 15.7135C28.7078 15.7935 28.6554 15.8618 28.5836 15.9032L21.4384 20.0285V28.2303C21.4384 28.3132 21.4055 28.3927 21.3469 28.4513C21.2883 28.5099 21.2088 28.5428 21.1259 28.5428H19.2509C19.168 28.5428 19.0885 28.5099 19.0299 28.4513C18.9713 28.3927 18.9384 28.3132 18.9384 28.2303V20.1221L11.6309 15.9033C11.5953 15.8828 11.5642 15.8554 11.5392 15.8229C11.5142 15.7903 11.4959 15.7531 11.4853 15.7135C11.4746 15.6738 11.4719 15.6325 11.4773 15.5918C11.4827 15.5511 11.496 15.5119 11.5165 15.4763L12.454 13.8525C12.4955 13.7808 12.5637 13.7284 12.6438 13.7069C12.7238 13.6855 12.8091 13.6967 12.8809 13.7382L20.1073 17.9103L27.3336 13.7382Z" fill="white" />
</svg>;

export const uploadIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M1 11V16C1 16.5523 1.44772 17 2 17H16C16.5523 17 17 16.5523 17 16V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M4.21967 4.46963C3.92678 4.76253 3.92678 5.2374 4.21967 5.53029C4.51256 5.82319 4.98744 5.82319 5.28033 5.53029L8.25 2.56063V13.25C8.25 13.6642 8.58579 14 9 14C9.41421 14 9.75 13.6642 9.75 13.25V2.57534L12.705 5.53029C12.9978 5.82319 13.4727 5.82319 13.7656 5.53029C14.0585 5.2374 14.0585 4.76253 13.7656 4.46963L9.52297 0.226994C9.23008 -0.0658991 8.7552 -0.0658991 8.46231 0.226994L4.21967 4.46963Z" fill="white" />
</svg>;

export const deleteIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M15.6943 3.66372L15.0842 15.1407C15.0432 15.9134 14.7204 16.6403 14.182 17.1721C13.6437 17.7039 12.9307 18.0002 12.1897 18H5.84085C5.09992 18 4.38715 17.7036 3.84896 17.1718C3.31078 16.6401 2.98806 15.9132 2.94712 15.1407L2.33695 3.66372H0.610169C0.448342 3.66372 0.293144 3.59659 0.178715 3.47709C0.0642856 3.3576 0 3.19554 0 3.02655C0 2.85756 0.0642856 2.6955 0.178715 2.576C0.293144 2.45651 0.448342 2.38938 0.610169 2.38938H17.3898C17.5517 2.38938 17.7069 2.45651 17.8213 2.576C17.9357 2.6955 18 2.85756 18 3.02655C18 3.19554 17.9357 3.3576 17.8213 3.47709C17.7069 3.59659 17.5517 3.66372 17.3898 3.66372H15.6943ZM3.55881 3.66372L4.16517 15.0706C4.18896 15.5179 4.37588 15.9386 4.68753 16.2464C4.99917 16.5543 5.41188 16.7257 5.84085 16.7257H12.1897C12.6186 16.7257 13.0313 16.5543 13.343 16.2464C13.6546 15.9386 13.8415 15.5179 13.8653 15.0706L14.4717 3.66372H3.55881ZM6.71186 1.27434H11.2881C11.45 1.27434 11.6052 1.20721 11.7196 1.08771C11.834 0.968222 11.8983 0.806156 11.8983 0.637168C11.8983 0.468181 11.834 0.306114 11.7196 0.186622C11.6052 0.06713 11.45 0 11.2881 0H6.71186C6.55004 0 6.39484 0.06713 6.28041 0.186622C6.16598 0.306114 6.10169 0.468181 6.10169 0.637168C6.10169 0.806156 6.16598 0.968222 6.28041 1.08771C6.39484 1.20721 6.55004 1.27434 6.71186 1.27434Z" fill="white" />
    <path d="M6.87952 7.40719V12.5069C6.87952 12.6759 6.9438 12.838 7.05823 12.9575C7.17266 13.077 7.32786 13.1441 7.48969 13.1441C7.65151 13.1441 7.80671 13.077 7.92114 12.9575C8.03557 12.838 8.09986 12.6759 8.09986 12.5069V7.40719C8.09986 7.2382 8.03557 7.07613 7.92114 6.95664C7.80671 6.83715 7.65151 6.77002 7.48969 6.77002C7.32786 6.77002 7.17266 6.83715 7.05823 6.95664C6.9438 7.07613 6.87952 7.2382 6.87952 7.40719ZM9.93036 7.40719V12.5069C9.93036 12.6759 9.99465 12.838 10.1091 12.9575C10.2235 13.077 10.3787 13.1441 10.5405 13.1441C10.7024 13.1441 10.8576 13.077 10.972 12.9575C11.0864 12.838 11.1507 12.6759 11.1507 12.5069V7.40719C11.1507 7.2382 11.0864 7.07613 10.972 6.95664C10.8576 6.83715 10.7024 6.77002 10.5405 6.77002C10.3787 6.77002 10.2235 6.83715 10.1091 6.95664C9.99465 7.07613 9.93036 7.2382 9.93036 7.40719Z" fill="white" />
</svg>;

export const lookIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M17.8053 16.9028L14.9133 14.1141C16.401 12.6135 17.321 10.5861 17.321 8.35117C17.321 3.74646 13.4357 0 8.66048 0C3.88522 0 0 3.74646 0 8.35117C0 12.9559 3.88522 16.7023 8.66048 16.7023C10.6384 16.7023 12.4584 16.0522 13.9181 14.9717L16.8626 17.8111C16.9243 17.871 16.9977 17.9185 17.0786 17.9509C17.1594 17.9833 17.2461 18 17.3336 18C17.4212 18 17.5078 17.9833 17.5887 17.9509C17.6695 17.9185 17.7429 17.871 17.8046 17.8111C17.9296 17.6907 17.9999 17.5274 18 17.3571C18.0001 17.1867 17.9301 17.0233 17.8053 16.9028ZM1.33238 8.35117C1.33238 4.45503 4.62003 1.28479 8.66048 1.28479C12.7009 1.28479 15.9886 4.45503 15.9886 8.35117C15.9886 12.2473 12.7009 15.4175 8.66048 15.4175C4.62003 15.4175 1.33238 12.2473 1.33238 8.35117Z" fill="white" />
    <path d="M11.6956 7.70842H9.32658V5.46002C9.32658 5.28965 9.25639 5.12625 9.13145 5.00578C9.00652 4.88531 8.83707 4.81763 8.66039 4.81763C8.4837 4.81763 8.31425 4.88531 8.18932 5.00578C8.06438 5.12625 7.9942 5.28965 7.9942 5.46002V7.70842H5.66253C5.48584 7.70842 5.3164 7.7761 5.19146 7.89657C5.06653 8.01704 4.99634 8.18044 4.99634 8.35081C4.99634 8.52119 5.06653 8.68458 5.19146 8.80506C5.3164 8.92553 5.48584 8.99321 5.66253 8.99321H7.9942V11.2416C7.9942 11.412 8.06438 11.5754 8.18932 11.6958C8.31425 11.8163 8.4837 11.884 8.66039 11.884C8.83707 11.884 9.00652 11.8163 9.13145 11.6958C9.25639 11.5754 9.32658 11.412 9.32658 11.2416V8.99321H11.6956C11.8722 8.99321 12.0417 8.92553 12.1666 8.80506C12.2916 8.68458 12.3617 8.52119 12.3617 8.35081C12.3617 8.18044 12.2916 8.01704 12.1666 7.89657C12.0417 7.7761 11.8722 7.70842 11.6956 7.70842Z" fill="white" />
</svg>;

export const fangdaIcon = <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0991 11.6364L9.70955 9.11115C11.4932 6.59057 11.0046 3.02002 8.62098 1.13508C6.2374 -0.749865 2.85732 -0.233507 1.07372 2.2855C0.377214 3.27113 0 4.46707 0 5.69754C0 8.84434 2.41328 11.3963 5.39238 11.3963C6.55669 11.3963 7.68834 10.9977 8.62098 10.2616L11.009 12.7853C11.309 13.1039 11.7976 13.1039 12.0991 12.7869C12.3991 12.4698 12.3991 11.9535 12.0991 11.6364ZM5.3909 9.76876C3.26424 9.80015 1.51628 8.00467 1.4851 5.75718C1.45539 3.50969 3.15434 1.66242 5.281 1.62946H5.38941C7.51607 1.62946 9.24026 3.45162 9.24026 5.69911C9.24026 7.9466 7.51904 9.76876 5.3909 9.76876Z" fill="#BEBEBE" />
</svg>;

export const shugang = <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="4" height="16" rx="2" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="0.285714" y1="-4.18944e-07" x2="10.3078" y2="4.15105" gradientUnits="userSpaceOnUse">
    <stop stop-color="#6EA8FF"/>
    <stop offset="1" stop-color="#485AFE"/>
    </linearGradient>
    </defs>
</svg>;

export const max_icon_type = {
    'pdf': 'RC_Framefile7.png',
    'doc': 'RC_Framefile6.png',
    'docx': 'RC_Framefile6.png',
    'txt': 'RC_Framefile6.png',
    'xls': 'RC_Framefile2.png',
    'xlsx': 'RC_Framefile2.png',
    'ppt': 'RC_Framefile5.png',
    'pptx': 'RC_Framefile5.png',
    'mp4': 'RC_Framefile8.png',
    'flv': 'RC_Framefile8.png',
    'f4v': 'RC_Framefile8.png',
    'avi': 'RC_Framefile8.png',
    'mpeg': 'RC_Framefile8.png',
    'rm': 'RC_Framefile8.png',
    'rmvb': 'RC_Framefile8.png',
    'mov': 'RC_Framefile8.png',
    'wmv': 'RC_Framefile8.png',
    'swf': 'RC_Framefile8.png',
    'mkv': 'RC_Framefile8.png',
    'mp3': 'RC_Framefile4.png',
    'ogg': 'RC_Framefile4.png',
    'wav': 'RC_Framefile4.png',
    'jpg': 'RC_Framefile3.png',
    'jpeg': 'RC_Framefile3.png',
    'png': 'RC_Framefile3.png',
    'bmp': 'RC_Framefile3.png',
    'gif': 'RC_Framefile3.png',
    'all': 'RC_Framefile1.png',
};

export default {
    'doc': word_icon,
    'docx': word_icon,
    'txt': word_icon,
    "xls": xls_icon,
    "xlsx": xls_icon,
    "ppt": ppt_icon,
    "pptx": ppt_icon,
    "avi": mp4_icon,
    "flv": mp4_icon,
    "f4v": mp4_icon,
    "mpeg": mp4_icon,
    "rm": mp4_icon,
    "rmvb": mp4_icon,
    "mov": mp4_icon,
    "wmv": mp4_icon,
    "swf": mp4_icon,
    "mkv": mp4_icon,
    "wav": mp3_icon,
    "mp3": mp3_icon,
    "ogg": mp3_icon,
    "bmp": img_icon,
    "png": img_icon,
    "jpeg": img_icon,
    "jpg": img_icon,
    "gif": img_icon,
    "pdf": <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="22" rx="2.5" fill="#ED5050" />
        <path d="M15.2489 11.6441C14.5482 11.6563 13.8502 11.7372 13.165 11.8856C12.3317 11.1119 11.6331 10.2025 11.0981 9.19526C11.6703 7.32412 11.696 6.04795 11.2689 5.44436C11.1686 5.31083 11.0396 5.20192 10.8917 5.12577C10.7439 5.04962 10.5808 5.00819 10.4148 5.00459C10.2091 4.98543 10.0023 5.02647 9.81901 5.1228C9.63576 5.21913 9.48384 5.36668 9.38141 5.54783C8.78356 6.54807 9.64618 8.53131 10.0476 9.33323C9.59291 10.7718 9.00379 12.1634 8.28819 13.4894C5.09395 14.869 5 15.7055 5 16.0073C5.00543 16.1896 5.06104 16.3669 5.16059 16.5192C5.26015 16.6715 5.39972 16.7928 5.56369 16.8695C5.71184 16.9627 5.88472 17.0079 6.05905 16.9989C6.91313 16.9989 7.85262 16.059 8.88605 14.2223C10.1607 13.6783 11.4813 13.2512 12.8319 12.9462C13.5248 13.5378 14.3864 13.8911 15.2916 13.955C15.8553 13.955 16.9998 13.955 16.9998 12.8082C17.0083 12.4202 16.7777 11.6786 15.2489 11.6441ZM6.11884 16.0417H6.01635C6.3068 15.6011 6.72414 15.261 7.21206 15.0674C6.96829 15.5085 6.58258 15.8522 6.11884 16.0417ZM10.2013 6.04795C10.2455 6.03217 10.2938 6.03217 10.338 6.04795H10.4405C10.616 6.60914 10.616 7.21132 10.4405 7.7725C10.1754 7.23973 10.0914 6.63372 10.2013 6.04795ZM11.9095 12.5495C11.1429 12.7299 10.3873 12.9544 9.64618 13.2221V13.1531H9.57785C9.9451 12.4202 10.2782 11.6441 10.5771 10.8767V10.8077C10.9618 11.398 11.4054 11.947 11.9009 12.446H11.807L11.9095 12.5495ZM15.3258 12.9893C14.9793 12.9761 14.6379 12.9001 14.318 12.7651C14.5991 12.7092 14.8857 12.6861 15.1721 12.6961C15.8297 12.6961 15.9663 12.8599 15.9663 12.9634C15.7548 13.022 15.5327 13.0309 15.3172 12.9893H15.3258Z" fill="white" />
    </svg>,
    "": <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="22" rx="2.5" fill="#BABABA" />
        <path d="M14.0213 15.6643H7.84391C6.34289 15.7303 5.07053 14.6003 5.00287 13.1393C4.93453 11.6786 6.09622 10.4406 7.59724 10.3743C7.59324 8.59924 9.00626 7.12589 10.8263 7.00755C12.6463 6.88955 14.248 8.1669 14.4863 9.92693C16.0204 10.1569 17.1127 11.4996 16.9907 13.0053C16.8687 14.5113 15.574 15.671 14.0217 15.6643H14.0213ZM8.20458 14.7023H14.0213C14.7277 14.7023 15.3804 14.3357 15.7334 13.7406C15.9067 13.4498 15.9982 13.1174 15.9982 12.7788C15.9982 12.4402 15.9067 12.1079 15.7334 11.817C15.38 11.2216 14.7277 10.8549 14.0213 10.8553C13.8903 10.8553 13.7647 10.8046 13.672 10.7143C13.6263 10.6702 13.5899 10.6174 13.565 10.559C13.5401 10.5006 13.5272 10.4378 13.527 10.3743C13.527 9.04625 12.421 7.96957 11.0563 7.96957C9.6916 7.96957 8.58525 9.04625 8.58525 10.3743V10.4753C9.7536 10.7993 10.5599 11.837 10.5619 13.0193C10.5619 13.191 10.4679 13.35 10.3149 13.436C10.2394 13.4783 10.1543 13.5005 10.0678 13.5005C9.98123 13.5005 9.89612 13.4783 9.8206 13.436C9.74616 13.3947 9.68405 13.3344 9.6407 13.2611C9.59734 13.1879 9.57429 13.1044 9.57393 13.0193C9.57393 12.0916 8.80225 11.3386 7.84891 11.3359C6.89356 11.3359 6.11922 12.0896 6.11922 13.0193C6.11922 13.9487 6.89356 14.7027 7.84891 14.7027H8.04158C8.09558 14.6937 8.15091 14.6937 8.20491 14.7027L8.20458 14.7023Z" fill="white" />
    </svg>,
    "magnifier": <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0991 11.6364L9.70955 9.11115C11.4932 6.59057 11.0046 3.02002 8.62098 1.13508C6.2374 -0.749865 2.85732 -0.233507 1.07372 2.2855C0.377214 3.27113 0 4.46707 0 5.69754C0 8.84434 2.41328 11.3963 5.39238 11.3963C6.55669 11.3963 7.68834 10.9977 8.62098 10.2616L11.009 12.7853C11.309 13.1039 11.7976 13.1039 12.0991 12.7869C12.3991 12.4698 12.3991 11.9535 12.0991 11.6364ZM5.3909 9.76876C3.26424 9.80015 1.51628 8.00467 1.4851 5.75718C1.45539 3.50969 3.15434 1.66242 5.281 1.62946H5.38941C7.51607 1.62946 9.24026 3.45162 9.24026 5.69911C9.24026 7.9466 7.51904 9.76876 5.3909 9.76876Z" fill="#BEBEBE" />
    </svg>,
}