import { post ,get} from "../../until/axios_instans";

//设备供应商管理-查询列表
export function list(data) {
  return post("/api/aiTraining/v1/cvecequipmentsupplier/list", data);
}

//gwn-设备分类管理-查询
export function cvecequipmentcategory(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/list", data);
}

//gwn-设备分类管理-分类级别下拉
export function listLevel(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/listLevel", data);
}
// gwn-设备分类管理-新增
export function cvecequipmentcategorysave(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/save", data);
}
//gwn-设备分类管理-修改
export function cvecequipmentcategoryedit(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/edit", data);
}
//gwn-设备分类管理-删除
export function cvecequipmentcategoryremove(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/remove", data);
}

//gwn-设备分类管理-查询详情
export function cvecequipmentcategorydetail(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/detail", data);
}
// 课程资源管理-资源类型下拉（所有: 包含混合资源）
export function getAllResourceTypeEnumList(data) {
  return post("/api/aiTraining/v1/resource/getAllResourceTypeEnumList", data);
}
// 课程资源管理-启动类型下拉列表
export function getStartTypeEnumList(data) {
  return post("/api/aiTraining/v1/resource/getStartTypeEnumList", data);
}
// 设备供应商管理-详情
export function detail(data) {
  return post("/api/aiTraining/v1/cvecequipmentsupplier/detail", data);
}
// 设备供应商管理-删除
export function remove(data) {
  return post("/api/aiTraining/v1/cvecequipmentsupplier/remove", data);
}
// -设备供应商管理-新增

export function save(data) {
  return post("/api/aiTraining/v1/cvecequipmentsupplier/save", data);
}

// -设备供应商管理-编辑
export function edit(data) {
  return post("/api/aiTraining/v1/cvecequipmentsupplier/edit", data);
}
// xjf-省下拉列表
export function province(data) {
  return post("/api/gongyong/v1/cvecregion/province", data);
}
// }xjf-市下拉列表
export function city(data) {
  return post("/api/gongyong/v1/cvecregion/city", data);
}
// xjf-区|县下拉列表
export function area(data) {
  return post("/api/gongyong/v1/cvecregion/area", data);
}

// -设备型号管理-查询详情
export function page(data) {
  return post("/api/aiTraining/v1/cvecequipmenttype/page", data);
}
// n-设备型号管理-新增

export function shebei_save(data) {
  return post("/api/aiTraining/v1/cvecequipmenttype/save", data);
}
// 设备型号管理-编辑
export function shebei_edit(data) {
  return post("/api/aiTraining/v1/cvecequipmenttype/edit", data);
}
// 供应商
export function optionlist(data) {
  return post("/api/aiTraining/v1/cvecequipmentsupplier/optionlist", data);
}
// 设备型号管理-删除
export function shebei_remove(data) {
  return post("/api/aiTraining/v1/cvecequipmenttype/remove", data);
}
// gwn-设备分类管理-查询下拉分类
export function listByLevel(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/listByLevel", data);
}
// -查询子分类
export function listByParentId(data) {
  return post("/api/aiTraining/v1/cvecequipmentcategory/listByParentId", data);
}

// -硬件初始安装包管理(12)wk-列表查询
export function cvecequipmentsoftwarelist(data) {
  return post("/api/aiTraining//v1/cvecequipmentsoftware/list", data);
}

// -硬件初始安装包管理(12)wk-添加安装包
export function cvecequipmentsoftwareaddSoft(data) {
  return post("/api/aiTraining/v1/cvecequipmentsoftware/addSoft", data);
}

// -硬件初始安装包管理(12)wk-安装包提取记录
export function getSoftwareDownloadList(data) {
  return post(
    "/api/aiTraining/v1/equipmentsoftwaredownload/getSoftwareDownloadList",
    data
  );
}

// gwn-设备型号管理-查询详情
export function cvecequipmenttypedetail(data) {
  return post("/api/aiTraining/v1/cvecequipmenttype/detail", data);
}

// wk-查看安装包详情
export function cvecequipmentsoftwaregetInfoById(data) {
  return post("/api/aiTraining/v1/cvecequipmentsoftware/getInfoById", data);
}
// 提供商管理列表
export function providerTableList(data) {
  return post("/api/aiTraining/v1/resourcesupplier/list", data);
}
// 提供商管理查看详情
export function providerView(data) {
  return post("/api/aiTraining/v1/resourcesupplier/detail", data);
}
// 提供商管理编辑
export function providerEdit(data) {
  return post("/api/aiTraining/v1/resourcesupplier/edit", data);
}
//提供商删除
export function providerDelete(data) {
  return post("/api/aiTraining/v1/resourcesupplier/remove", data);
}
//提供商下拉列表
export function merchantOptionList(data) {
  return post("/api/aiTraining/v1/resourcesupplier/merchant/optionlist", data);
}
//版本号下拉列表
export function versionOptionList(data) {
  return post("/api/aiTraining/v1/resourcesupplier/version/optionlist", data);
}
//提供商管理新增
export function providerCreate(data) {
  return post("/api/aiTraining/v1/resourcesupplier/save", data);
}

// 机构申请查看详情
export function mechanismList(data) {
  return post("/api/aiTraining/v1/resource-apply/list", data);
}
// 机构申请处理状态
export function mechanismResult(data) {
  return post("/api/aiTraining/v1/resource-apply/process-status/list", data);
}
// //提供商删除
// export function providerDelete(data) {
//   return post("/api/aiTraining/v1/resourcesupplier/remove", data);
// }
// //提供商管理新增
// export function providerCreate(data) {
//   return post("/api/aiTraining/v1/resourcesupplier/save", data);
// }
//所有机构的下拉列表

export function anyMechanismOrg(data) {
  return post("/api/aiTraining/v1/organization/getOrganizationList", data);
}

// 设备注册管理 列表查询
export function pageListEquipmentManager(data) {
  return post(
    "/api/aiTraining/v1/cvecregisterequipment/pageListEquipmentManager",
    data
  );
}
// 设备注册管理 查看详情
export function details(data) {
  return post("/api/aiTraining/v1/cvecregisterequipment/details", data);
}
// wk-硬件绑定情况查看
export function bandInfo(data){
  return post('/api/aiTraining/v1/cvecregisterequipment/bandInfo',data)
}
// 设备注册管理 删除
export function delEquipment(data) {
  return post("/api/aiTraining/v1/cvecregisterequipment/delEquipment", data);
}
// 设备型号下拉查询

export function cvecequipmenttypeoptionlist(data) {
  return post("/api/aiTraining/v1/cvecequipmenttype/optionlist", data);
}

// --所有机构下拉列表
export function getOrganizationList(data) {
  return post("/api/aiTraining/v1/organization/getOrganizationList", data);
}

// 课程资源管理-查询资源列表

export function Curriculumoptionlist(data) {
  return post("/api/aiTraining/v1/resource/list", data);
}

//资源类型下拉
export function getResourceTypeEnumList(data) {
  return post("/api/aiTraining/v1/resource/getResourceTypeEnumList", data);
}
// 版本号列表
export function listByResourceId(data) {
  return post("/api/aiTraining/v1/resourceversion/listByResourceId", data);
}
//运行方式列表
export function getResourceRunModeEnumList(data) {
  return post("/api/aiTraining/v1/resource/getResourceRunModeEnumList", data);
}

//适配硬件下拉
export function getResourceAdaptiveHardwareEnumList(data) {
  return post(
    "/api/aiTraining/v1/resource/getResourceAdaptiveHardwareEnumList",
    data
  );
}
//链接有效期列表

export function getValidTimeEnumList(data) {
  return post("/api/aiTraining/v1/resource/getValidTimeEnumList", data);
}

// gwn-生成地址链接
export function createAddr(data) {
  return post("/api/aiTraining/v1/resource/createAddr", data);
}

// gwn-资源接收人列表
export function getUserInfoByTel(data) {
  return post("/api/aiTraining/v1/resource/getUserInfoByTel", data);
}

// gwn-课程资源使用管理-发送
export function resourcedownloadSend(data) {
  return post("/api/aiTraining/v1/resourcedownload/send", data);
}

//学科级别下拉
export function getEducationStageEnumList(data) {
  return post("/api/aiTraining/v1/resource/getEducationStageEnumList", data);
}
//专业大类

export function eduSubList(data) {
  return post("/api/gongyong/v1/cvecsubject/edu-sub/list", data);
}
// 查询资源详情
export function Curriculumopdetail(data) {
  return post("/api/aiTraining/v1/resource/detail", data);
}
//新增资源
export function createCurriculumop(data) {
  return post("/api/aiTraining/v1/resource/save", data);
}
//编辑资源
export function editCurriculumop(data) {
  
  return post("/api/aiTraining/v1/resource/edit", data);
}
//删除资源
export function removeCurriculumop(data) {
  return post("/api/aiTraining/v1/resource/remove", data);
}

// 校区管理-下拉列表查询
export function regoptionList(data) {
  return post("/api/aiTraining/v1/cvecorgcampus/optionList", data);
}
// 场地管理-下拉列表查询
export function cvecorgplaceoptionList(data) {
  return post("/api/aiTraining/v1/cvecorgplace/optionList", data);
}
// 实训教室管理-下拉列表查询
export function cvecorgclassroomoptionList(data) {
  return post("/api/aiTraining/v1/cvecorgclassroom/optionList", data);
}
// -资源供应商下拉查询
export function resourcesupplieroptionlist(data) {
  return post("/api/aiTraining/v1/resourcesupplier/optionlist", data);
}
//机构申请详情

export function anyMechanismView(data) {
  return post("/api/aiTraining/v1/resource-apply/detail", data);
}
//资源供应商详情
export function resourceProviderDetail(data) {
  return post("/api/aiTraining/v1/resource/detail2", data);
}

//机构申请处理

export function resourceUpdate(data) {
  return post("/api/aiTraining/v1/resource-apply/update", data);
}
// -绑定信息(含批量)—提交
export function equipmentBandOrg(data) {
  return post(
    "/api/aiTraining/v1/cvecregisterequipment/equipmentBandOrg",
    data
  );
}
// 注册设备
export function cvecregisterequipmentregister(data) {
  return post("/api/aiTraining/v1/cvecregisterequipment/register", data);
}

export function resourcedetail(data) {
  return post("/api/aiTraining/v1/resource/detail", data);
}

//省下拉列表
export function queryProvince(data) {
  return post("/api/gongyong/v1/cvecregion/province", data);
}

//市下拉列表
export function queryCity(data) {
  return post("/api/gongyong/v1/cvecregion/city", data);
}

//区下拉列表
export function queryArea(data) {
  return post("/api/gongyong/v1/cvecregion/area", data);
}

// wk-创建地址链接
export function createAddressPassword(data) {
  return post("/api/aiTraining/v1/equipmentsoftwaredownload/createAddressPassword", data);
}

// wk-获取接收人用户信息
export function getUserInfo(data) {
  return post("/api/aiTraining//v1/equipmentsoftwaredownload/getUserInfo", data);
}
// wk-发送短信消息和站内信
export function sendDownloadPage(data) {
  return post("/api/aiTraining/v1/equipmentsoftwaredownload/sendDownloadPage", data);
}
//授权管理列表
export function resourceorgTablelist(data) {
  return post("/api/aiTraining/v1/resourceorg/pagelist", data);
}

//授权管理 撤销授权
export function resourceorgRevocation(data) {
  return post("/api/aiTraining/v1/resourceorg/backAuth", data);
}

//授权管理 添加授权
export function resourceorgAddRevocation(data) {
  return post("/api/aiTraining/v1/resourceorg/authOrg", data);
}

//授权管理 对接级别列表
export function resourceorgJointLevelList(data) {
  return post("/api/aiTraining/v1/resource/jointLevelList", data);
}

//授权方式下拉
export function resourceorgOptionAuthTypeList(data) {
  return post("/api/aiTraining/v1/resourceorg/optionAuthTypeList", data);
}

//授权记录查看
export function resourceorgAuthList(data) {
  return post("/api/aiTraining/v1/resourceorg/authList", data);
}
// 使用详情下载记录


export function resourcedownloadrecordlist(data) {
  return post("/api/aiTraining/v1/resourcedownloadrecord/list", data);

}





//wk-安装包sahnchu
export function cvecequipmentsoftwaredelSoft(data) {
  return post("/api/aiTraining//v1/cvecequipmentsoftware/delSoft", data);
}

//wk-安装包sahnchu
export function cvecequipmentsoftwareupdateById(data) {
  return post("/api/aiTraining//v1/cvecequipmentsoftware/updateById", data);
}
//gwn-章节目录列表查询
export function resourcechapterlist(data) {
  return post("/api/aiTraining/v1/resourcechapter/list", data);
}
//gwn-章节目录添加
export function resourcechaptersave(data) {
  return post("/api/aiTraining/v1/resourcechapter/save", data);
}


//gwn-章节目录下拉列表
export function resourcechapteroptionList(data) {
  return post("/api/aiTraining/v1/resourcechapter/optionList", data);
}









//gwn-章节目录编辑
export function resourcechapteredit(data) {
  return post("/api/aiTraining/v1/resourcechapter/edit", data);
}


// 查询操作记录
export function pageListdara(data) {
  return post("/api/aiTraining/v1/cvecoperationrecord/pageList", data);
}






// gwn-章节目录删除
export function resourcechapterremove(data) {
  return post("/api/aiTraining/v1/resourcechapter/remove", data);
}



