/*回收箱*/
import React, { Component } from 'react';
import {Form, Select, Button, Input, Table, Spin, Checkbox, Modal, message} from 'antd';
import './index.scss';
import RenderButton from "../../../../components/renderButton";
import {
    getPlatformList,
    selectNewsParentCategory,
    selectNewsTwoCategory,
    selectNewsInRecycle,
    deleteNews,
    recoverNews,
    clearRecycle
} from "../../../../api/information";
const { Option } = Select;
function compareSortFunction(propeties) {
    let compareSort = (rowa, rowb) => sortFunction(rowa[propeties], rowb[propeties]);
    return compareSort;
}

function sortFunction(a, b) {
    if (!a) {
        a = "";
    }
    if (!b) {
        b = "";
    }
    if ((typeof a) !== "number") {
        return a.localeCompare(b);
    }
}
class RecycleBin extends Component {
    state = {
        total:0,
        pageNum: 1,
        pageSize: 10,
        columns: [
            {title: '新闻标题', dataIndex: 'newsTitle', key: 'newsTitle', align: 'center',ellipsis: true},
            {title: '所属栏目', dataIndex: '', key: '', align: 'center',render:(record)=>{
                console.log(record.twoCategoryName,'000000000');
                    if(record.twoCategoryName){
                        return record.twoCategoryName
                    }else{
                        return record.oneCategoryName
                    }
                }},
            {title: '所属业务系统', dataIndex: 'platformName', key: 'platformName', align: 'center'},
            {title: '发布终端', dataIndex: 'releaseTerminal', key: 'releaseTerminal', align: 'center',render: (text,record) =>{
                    if(record.releaseTerminal === 1){
                        return 'WEB'
                    }else if(record.releaseTerminal === 2){
                        return 'APP'
                    }else{
                        return 'WEB/APP'
                    }
                }},
            {title: <div>创建时间</div>, dataIndex: 'createTime', key: 'createTime', align: 'center',sorter: compareSortFunction("createTime"),defaultSortOrder :'descend'},
            {
                title: '操作',  align: 'center',
                width: 220,
                render: (text, record) => {
                    return <p className='action vertical_j'>
                        <div>
                            <RenderButton id='NC_01_B07_c_btnrestore'>
                                <span onClick={()=>this.setState({classifyEditVisibily:true,id:record.id,releaseStatus:record.releaseStatus})}>恢复</span>
                            </RenderButton>
                            <RenderButton id='NC_01_B07_c_btndelete'>
                                <span onClick={()=>this.showDeleteConfirm(record.id)}>彻底删除</span>
                            </RenderButton>
                        </div>
                    </p>
                }
            }
        ],
        dataSource: [],
        tableLoading: false, // 是否显示加载中
        selectedRowKeys: [],// 选择的新闻id
        disabled: false, // 是否可选择
        search: '',//搜索框文本
        oneCategoryId: '',
        twoCategoryId: '',
        platform: '',
        organizationId: null,
        asc: true,
        platformList: [],
        useType: 1,
        parentCategoryList:[],
        twoCategoryList:[],
        classifyEditVisibily: false, // 弹层是否显示
        recoverType: null, // 恢复类型
        releaseStatus: null, // record恢复类型
        id: '',
        checked:false
    }
    componentDidMount() {
        this.getPlatformList()
        this.selectNewsParentCategory()
        this.selectNewsTwoCategory()
        this.selectNewsInRecycle()
    }
    //查询一级栏目
    selectNewsParentCategory = async () =>{
        let data={
            organizationId: this.state.organizationId,
            useType:this.state.useType,
            platform: this.state.platform
        }
        await selectNewsParentCategory(data).then(e => {
            e.splice(0, 0, {
                id:'',
                categoryName: '全部一级栏目'
            });
            this.setState({parentCategoryList: e})
        })
    }
    //查询二级栏目
    selectNewsTwoCategory = async () =>{
        let data={
            organizationId: this.state.organizationId,
            useType:this.state.useType,
            parentId: this.state.oneCategoryId
        }
        await selectNewsTwoCategory(data).then(e => {
            e.splice(0, 0, {
                id:'',
                categoryName: '全部二级栏目'
            });
            this.setState({twoCategoryList: e})
        })
    }
    //加载业务系统列表
    getPlatformList = async()=>{
        let data={
            organizationId: this.state.organizationId,
            queryType: this.state.useType
        }
        await getPlatformList(data).then(e => {
            e.splice(0, 0, {
                platform:'',
                platformName: '全部系统'
            });
            this.setState({platformList: e})
        })
    }
    //新闻列表
    selectNewsInRecycle = async ()=>{
        let data={
            search: this.state.search,
            oneCategoryId: this.state.oneCategoryId,
            twoCategoryId: this.state.twoCategoryId,
            platform: this.state.platform,
            organizationId: this.state.organizationId,
            asc: this.state.asc,
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
            useType: this.state.useType
        }
        await selectNewsInRecycle(data).then(e=>{
            if(this.state.selectedRowKeys.length>0){
                for (let item in e.data){
                    if(this.state.selectedRowKeys.indexOf(e.data[item].id)===-1){
                        this.setState({
                            checked:false
                        })
                        break;
                    }else{
                        this.setState({
                            checked:true
                        })
                    }
                }
            }
            this.setState({
                dataSource:e.data,total:e.total,tableLoading:false,disabled:e.data.length<1?true:false
            })
        })
    }
    //分页切换
    changePage=(pageNum)=>{
        this.setState({
            pageNum:pageNum
        },()=>{
            //列表方法
            this.selectNewsInRecycle()
        })
    }

    showSizeChange = (current, size) => {
        this.setState({pageSize: size,pageNum:1}, () => {
            this.selectNewsInRecycle()
        })
    }
    // 多选
    onSelectChange = selectedRowKeys => {
        for (let item in this.state.dataSource){
            if(selectedRowKeys.indexOf( this.state.dataSource[item].id)===-1){
                this.setState({
                    checked:false
                })
                break;
            }else{
                this.setState({
                    checked:true
                })
            }
        }
        this.setState({ selectedRowKeys });
    }
    //全选
    onSelectAll = (selected, selectedRows, changeRows)=>{
        let selectedIds = this.state.selectedRowKeys
        if(selected ===true){
            selectedRows.forEach((item)=> {
                selectedIds.push(item.id)
            });
        }else{
            let arr=[]
            this.state.dataSource.forEach((item)=>{
                arr.push(item.id)
            })
            let rs=[]
            selectedIds.forEach((i)=>{
                if(arr.indexOf(i) === -1){
                    rs.push(i)
                }
            })
            selectedIds=rs
        }
        this.setState({
            selectedRowKeys: selectedIds,
            checked: selected
        })
    }

    // 点击全选
    onChange = e => {
        let selectedRowKeys = this.state.selectedRowKeys
        if (e.target.checked) {
            this.state.dataSource.forEach((item)=>{
                selectedRowKeys.push(item.id)
            })
            this.setState({
                selectedRowKeys: selectedRowKeys,
                checked:true
            });
        } else {
            let arr=[]
            this.state.dataSource.forEach((item)=>{
                arr.push(item.id)
            })
            let rs=[]
            selectedRowKeys.forEach((i)=>{
                if(arr.indexOf(i) === -1){
                    rs.push(i)
                }
            })
            selectedRowKeys=rs
            this.setState({
                selectedRowKeys: selectedRowKeys,
                checked:false
            });
        }
    }
    showDeleteConfirm = (id) => { // 删除提示
        let arr = []
        if(id != undefined){
            arr.push(id)
        }else{
            arr = this.state.selectedRowKeys
        }
        this.hint({
            'title': '温馨提示',
            'content': '您是否要彻底删除此条数据？删除后不可找回',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '删除',
            'isCancel': true,
            'sure':  () => {this.deleteNews(arr)}
        })
    }

    showQkCon= () => { // 清空回收站
        this.hint({
            'title': '温馨提示',
            'content': '您是否要清空回收站，此操作清空后不可找回',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': () => {this.clearRecycle()}
        })
    }
    //清空回收站
    clearRecycle(){
        let data ={
            organizationId:this.state.oneCategoryId,
            useType:this.state.useType
        }
        clearRecycle(data).then(e=>{
            message.destroy()
            message.success('清空成功')
            this.selectNewsInRecycle()
        })
    }
    //删除函数
    deleteNews = (arr) => {
        deleteNews(arr).then(res=>{
            message.destroy()
            message.success('删除成功')
            this.setState({
                pageNum:1
            })
            this.selectNewsInRecycle()
        })
    }
    changefbType = (tyep) => { // 恢复状态的选择
        if(tyep === 1){
            this.setState({releaseType: tyep,recoverType:tyep})
        }else{
            this.setState({recoverType:tyep})
        }
    }
    //table 时间列排序
    tableOnChange =(pagination, filters, sorter, extra) =>{
        let sort =true;
        if(typeof(sorter.order)!=='undefined'){
            if(sorter.order.replace('end','') ==='asc'){
                sort = false
            }
        }
        this.setState({
            asc: sort
        },()=>{
            this.selectNewsInRecycle()
        })
    }
    //业务系统change事件
    platformChange = e =>{
        this.setState({
            platform: e,
            pageNum: 1,
            oneCategoryId: '',
            twoCategoryId: ''
        },()=>{
            this.selectNewsParentCategory()
            this.selectNewsInRecycle()
        })
    }
    //一级栏目change事件
    oneCategoryChange = e =>{
        this.setState({
            oneCategoryId: e,
            twoCategoryId: '',
            pageNum: 1
        },()=>{
            this.selectNewsTwoCategory()
            this.selectNewsInRecycle()
        })
    }
    //二级栏目change事件
    twoCategoryChange = e =>{
        this.setState({
            twoCategoryId: e,
            pageNum: 1
        },()=>{
            this.selectNewsInRecycle()
        })
    }
    //搜索
    search =()=> {
        this.setState({
            pageNum: 1
        }, () => {
            this.selectNewsInRecycle()
        })
    }
    //恢复
    recoverNews=(id)=> {
        if(this.state.recoverType === null){
            message.warning('请选择恢复位置')
            return
        }
        let data = {
            newsId: id,
            recoverType: this.state.recoverType ===1?1:this.state.releaseStatus
        }
        recoverNews(data).then(res => {
            this.setState({
                recoverType:null,
                classifyEditVisibily:false
            })
            message.destroy()
            message.success('恢复成功')
            this.selectNewsInRecycle()
        })
    }
    render(){
        let { columns,total,pageSize,dataSource,pageNum,tableLoading,selectedRowKeys ,classifyEditVisibily,recoverType,platformList, parentCategoryList, twoCategoryList,id  } = this.state;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
            onChange: this.changePage,
            onShowSizeChange: this.showSizeChange
        }
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelectAll: this.onSelectAll
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
          <div className='recycle_bin'>
              {/*<div className="filter_controll">*/}
              {/*    <div className="item_wapper" style={{marginBottom: '20px'}}>*/}
              {/*        <div>*/}
              {/*            <span style={{ marginRight: '20px' }}>请选择业务系统</span>*/}
              {/*            <Select placeholder='请选择业务系统' value={this.state.platform} onChange={(e) => this.platformChange(e)}>*/}
              {/*                {platformList.length ? platformList.map((item, index) => {*/}
              {/*                    return <Option key={item.platform} value={item.platform}>{item.platformName}</Option>*/}
              {/*                }) : ""}*/}
              {/*            </Select>*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*            <span style={{ marginRight: '20px' }}>一级栏目</span>*/}
              {/*            <Select placeholder='请选择一级栏目' value={this.state.oneCategoryId} onChange={(e) => this.oneCategoryChange(e)}>*/}
              {/*                {parentCategoryList.length ? parentCategoryList.map((item, index) => {*/}
              {/*                    return <Option key={item.id} value={item.id}>{item.categoryName}</Option>*/}
              {/*                }) : ""}*/}
              {/*            </Select>*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*            <span style={{ marginRight: '20px' }}>二级栏目</span>*/}
              {/*            <Select placeholder='请选择二级栏目' value={this.state.twoCategoryId} onChange={(e) => this.twoCategoryChange(e)}>*/}
              {/*                {twoCategoryList.length ? twoCategoryList.map((item, index) => {*/}
              {/*                    return <Option key={item.id} value={item.id}>{item.categoryName}</Option>*/}
              {/*                }) : ""}*/}
              {/*            </Select>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className="in_school_content">
                  <div className='top'>
                      <div className="left">
                          <RenderButton id='NC_01_B07_c_btnempty'>
                              <Button type="primary" disabled={this.state.disabled} onClick={()=> this.showQkCon()}>清空回收站</Button>
                          </RenderButton>
                      </div>
                      <div className="right">
                          <RenderButton id='NC_01_B07_c_btnConfirmrelease'>
                              <Input.Search className="lcl_left" onChange={(e) => this.setState({ search: e.target.value })} onSearch={this.search} placeholder='请输入新闻标题' />
                          </RenderButton>
                          <span className='all_total'>共{this.state.total}条新闻资讯</span>
                      </div>
                  </div>
                  <div className="bottomss">
                      {
                        tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                            <div className='all_wapper'>
                              <Table
                                  className='organ-table organ-table-live'
                                  columns={columns}
                                  dataSource={dataSource}
                                  pagination={pagination}
                                  rowSelection={rowSelection}
                                  onChange={this.tableOnChange}
                                  rowKey={(v)=>v.id}
                                  locale={{
                                      emptyText: <>
                                          <div className="Empty-conet-zixun">
                                              <div className="img">
                                                  <img src={window.$$setImgSrc('AE_empty.jpg')}/>
                                                  <span>暂无数据</span>
                                              </div>
                                          </div>
                                      </>
                                  }}
                                 />
                                 <div className='check_box'>
                                     <Checkbox
                                         disabled={this.state.disabled}
                                         onChange={this.onChange}
                                     >
                                         全选
                                     </Checkbox>
                                     <RenderButton id='NC_01_B07_c_btnbatchdelete'>
                                         <Button type="primary" disabled={!hasSelected} onClick={()=>this.showDeleteConfirm()}>批量删除</Button>
                                     </RenderButton>
                                 </div>
                            </div>
                        )
                      }
                  </div>
                  {/*恢复弹层*/}
                  <Modal
                      title={'请选择恢复位置'}
                      width={660}
                      wrapClassName='modal_con relese_modal'
                      visible={classifyEditVisibily}
                      destroyOnClose={true}
                      closable={false}
                      id={id}
                      recoverType={recoverType}
                      footer={
                          <div className='modal_btn_j'>
                              <Button onClick={()=> this.setState({classifyEditVisibily: false,recoverType:null})}>取消</Button>
                              <Button onClick={()=> this.recoverNews(id)} type='primary'>确定</Button>
                          </div>
                      }>
                      <div className='select_btn_wapper'>
                          <div className={recoverType==0?'active_btn':''} onClick={(record) =>this.changefbType(0)}>
                              {
                                  recoverType ===0 ?<img  src={require('@/assets/img/zixun/fb_active.png')}/> : <img  src={require('@/assets/img/zixun/fb.png')}/>
                              }
                              <div>原发布模块</div>
                          </div>
                          <div className={recoverType===1?'active_btn':''} onClick={() => this.changefbType(1)}>
                              {
                                  recoverType === 1 ? <img  src={require('@/assets/img/zixun/cgx_active.png')}/> : <img  src={require('@/assets/img/zixun/cgx.png')}/>
                              }
                              <div>草稿箱</div>
                          </div>
                      </div>
                  </Modal>
              </div>
          </div>
        )
    }
}

RecycleBin = Form.create()(RecycleBin);
export default RecycleBin;
