import React, { Component } from "react";
import "./index.scss";
import { Input, Button, Table, Modal, Form, Select, message, Spin  } from "antd";
import MaxLengthInput from "../../../components/hhinput";
import {
  providerTableList,
  providerView,
  providerEdit,
  providerDelete,
  providerCreate,
  pageListdara,
  versionOptionList,
  merchantOptionList
} from "../../../api/shixun2";
const { Option } = Select;
const Yichang = () => {
  return (
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 11.5L0 11.5C9.59018e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5L0 11.5Z"
          fill="#F3B714"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6516 6.32578V13.2258C12.6516 13.8609 12.1367 14.3758 11.5016 14.3758C10.8665 14.3758 10.3516 13.8609 10.3516 13.2258V6.32578C10.3516 5.69065 10.8665 5.17578 11.5016 5.17578C12.1367 5.17578 12.6516 5.69065 12.6516 6.32578ZM11.5016 17.8258C12.1367 17.8258 12.6516 17.3109 12.6516 16.6758C12.6516 16.0407 12.1367 15.5258 11.5016 15.5258C10.8664 15.5258 10.3516 16.0407 10.3516 16.6758C10.3516 17.3109 10.8664 17.8258 11.5016 17.8258Z"
          fill="white"
        />
      </svg>
  );
};
export class index extends Component {
  state = {
    visible: false, ///添加
    operation: false, //操作
    isshouw: "add", //新增 编辑
    delete: false, //删除,
    // 3个搜搜
    dataSource: [],
    dataSourceLogin: true,//loging 大表戈
    supplierName: "",
    supplierContact: "",
    supplierPhone: "",
    // 操作记录
    pageNum: 1,
    pageSize: 10,
    total: 0,
    dataSourceoperation: [],//操作表格
    datadataSourceoperationLoding: true,
    merchantOptionListData: [], //供应商下拉列表
    versionOptionListData: [], //版本号下拉列表
    saveSupilyLoading:false   //编辑，添加提供商的loading状态
  };

  getDataList = (pp) => {
    return pageListdara({
      operateType: 5,
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    }).then(res => {


      if (res && res.data) {
        this.setState(() => {
          return {
            dataSourceoperation: res.data,
            total: res.total,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            datadataSourceoperationLoding: false
          }
        })
      } else {
        message.info('数据错误')
      }
    })
  }
  //pageSize变化
  onShowSizeChange_login = (current, pageSize) => {
    console.log(current, pageSize, ":sdd")
    this.setState({
      pageNum: current,
      pageSize: pageSize,
    }, () => {
      this.getDataList({
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      });
    }
    );
  };
  //pageNum变化
  onPageNumChange_login = (current, pageSize) => {
    console.log(current, pageSize, ":sdd")
    this.setState({
      pageNum: current,
    }, () => {
      this.getDataList({
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      });
    }
    );
  }
  // 删除Model
  componentDidMount() {
    this.selectcha();
    // 获取供应商名称列表
    this.getSupliList();
    // 获版本号列表
    this.getVersionList();
  }
  getSupliList = async ()=>{
    const res = await merchantOptionList();
    res && this.setState({merchantOptionListData: res})
  }
  getVersionList = async ()=>{
    const res = await versionOptionList();
    res && this.setState({versionOptionListData: res})
  }
  deleteModal = () => {
    this.setState({
      delete: true,
    });
  };

  deleteOk = (record) => {
    const { supplierId } = this.state;
    providerDelete({ supplierId }).then(() => {
      message.success("删除成功");
      this.setState({
        delete: false,
      });
      this.selectcha();
    });
  };

  deleteCancel = (e) => {
    console.log(e);
    this.setState({
      delete: false,
    });
  };
  // 操作
  operationModal = () => {
    this.getDataList({
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    })
    this.setState({
      operation: true,
    });
  };

  operationleOk = (e) => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };

  operationCancel = (e) => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };
  // 添加
  showModal = (record) => {
    if (this.state.isshouw === "add") {
      this.setState(() => {
        return {
          visible: true,
        };
      });
    } else if (this.state.isshouw === "edit") {
      this.setState(
        () => {
          return {
            visible: true,
          };
        },
        () => {
          providerView({ supplierId: record.supplierId }).then((res) => {
            console.log(res);
            this.setState({
              editData: res,
            });
            this.props.form.setFieldsValue(res);
          });
        }
      );
    } else if (this.state.isshouw === "xiang") {
      // maxLength
      this.setState(
        () => {
          return {
            visible: true,
          };
        },
        () => {
          providerView({ supplierId: record.supplierId }).then((res) => {
            console.log(res);
            this.props.form.setFieldsValue(res);
          });
        }
      );
    }
  };

  handleOk = (e) => {
    if (this.state.isshouw === "add") {
      e.preventDefault();

      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          // console.log(values)
          // return false;
          this.setState({saveSupilyLoading: true})
          let timer = setTimeout(()=>{
            this.setState({saveSupilyLoading: false});
            clearTimeout(timer)
          },5000)
          providerCreate(values).then(() => {
            this.setState({saveSupilyLoading: false})
            this.props.form.setFieldsValue({
              merchantCode: undefined,
              supplierPhone: undefined,
              supplierContact: undefined,
              version:undefined
            });
            message.success("添加成功");
            this.selectcha();
            this.setState(() => {
              return {
                visible: false,

                editId: null,
              };
            });
          });
        }
      });
      // 编辑
    } else if (this.state.isshouw === "edit") {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          const { editData } = this.state;
          if (
            editData["merchantCode"] == values["merchantCode"] &&
            editData["supplierPhone"] == values["supplierPhone"] &&
            editData["supplierContact"] == values["supplierContact"]
          ) {
            message.error("请修改后再保存");
          } else {
            this.setState({saveSupilyLoading: true})
            let timer = setTimeout(()=>{
              this.setState({saveSupilyLoading: false});
              clearTimeout(timer)
            },5000)
            providerEdit({ ...values, supplierId: this.state.supplierId }).then(
              (res) => {
                this.setState({saveSupilyLoading: false})
                this.selectcha();
                message.success("编辑成功");

                this.setState(() => {
                  return {
                    visible: false,

                    editId: null,
                  };
                });
              }
            );
          }
        }
      });
    } else {
      this.setState(() => {
        return {
          visible: false,

          editId: null,
        };
      });
    }
  };

  handleCancel = (e) => {
    console.log(e);

    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.form.setFieldsValue({
          merchantCode: undefined,
          supplierPhone: undefined,
          supplierContact: undefined,
        });
      }
    );
  };

  //表格的查询输入框
  Search = (target) => {
    const { name, value } = target;
    console.log(name, value, "d");
    this.setState(() => {
      return {
        [name]: value,
      };
    });
  };
  selectcha = () => {
  
    const { supplierName, supplierContact, supplierPhone } = this.state;
    console.log(supplierName, supplierContact, supplierPhone, "121");
    providerTableList({
      supplierName,
      supplierContact,
      supplierPhone,
    }).then(
      (res) => {
        console.log(res);
        this.setState({ dataSource: res, dataSourceLogin: false });
      },
      () => {
        console.log("异常处理");
      }
    );
  };
  restte = () => {
    const { supplierName, supplierContact, supplierPhone } = this.state;
    this.setState(
      () => {
        return {
          supplierName: undefined,
          supplierPhone: undefined,
          supplierContact: undefined,
        };
      },
      () => {
        this.selectcha();
      }
    );
  };
  onChange = (value) => {
    console.log(value);
  };
  render() {
    const { dataSourceoperation,merchantOptionListData, versionOptionListData,saveSupilyLoading } = this.state
    const { getFieldDecorator } = this.props.form;
    const { RenderButton } = React;

    const formItemLayout_aaaa = {
      labelCol: {
        xs: { span: 22 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 14 },
      },
    };


    const columnsoperation = [
      {
        title: "操作人",
        dataIndex: "account",
        key: "name",
        align: "center",
      },
      {
        title: "提供商管理",
        dataIndex: "operationDesc",
        key: "age",
        align: "center",
      },
      {
        title: "操作时间",
        dataIndex: "createTime",
        key: "operationTime",
        align: "center",
      },
      {
        title: "动作",
        dataIndex: "actionDscp",
        key: "operationActionDscp",
        align: "center",
      },
    ];
    // 首页表格

    const columns = [
      {
        title: "提供商编号",
        dataIndex: "supplierId",
        key: "supplierId",
        align: "center",
        width: 230,
      },
      {
        title: "提供商名称",
        dataIndex: "supplierName",
        key: "supplierName",
        width: 230,
        align: "center",
      },

      {
        title: "联系人",
        dataIndex: "supplierContact",
        key: "supplierContact",
        width: 230,
        align: "center",
      },
      {
        title: "联系电话",
        dataIndex: "supplierPhone",
        key: "supplierPhone",
        width: 230,
        align: "center",
      },{
        title: "接口版本",
        dataIndex: "version",
        key: "version",
        width: 230,
        align: "center",
      },
      {
        title: "创建人",
        dataIndex: "createUserName",
        key: "createUserName",
        width: 230,
        align: "center",
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
        key: "updateTime",
        width: 230,
        align: "center",
      },

      {
        title: "操作",
        align: "center",
        width: 180,
        fixed: 'right',
        render: (v, b, value) => {
          // console.log(v, b, 'vvvbbb')

          return (
            <div
              className="action vertical_j"
              style={{ cursor: "pointer", display: "flex",marginLeft:30 }}
            >
              <RenderButton id="ABB-01-B01-b_btnView">


                <span
                  onClick={() => {
                    this.setState(
                      () => {
                        return {
                          isshouw: "xiang",
                        };
                      },
                      () => {
                        this.showModal(b);
                      }
                    );
                  }}
                >
                  查看
              </span>
              </RenderButton>
              <RenderButton id="ABB-01-B01-b_btnEdit">
                <span
                  onClick={() => {
                    this.setState(
                      () => {
                        return {
                          isshouw: "edit",
                          editId: "111111111111111",
                          supplierId: b.supplierId,
                        };
                      },
                      () => {
                        this.showModal(b);
                      }
                    );
                  }}
                >
                  编辑
              </span>
              </RenderButton>
              {/* <RenderButton id="ABB-01-B01-b_btnDel">
                <span
                  onClick={() => {
                    this.setState({
                      supplierId: b.supplierId,
                    });
                    this.deleteModal(b);
                  }}
                >
                  删除
              </span>
              </RenderButton> */}
            </div>
          );
        },
      },
    ];

    const { dataSource } = this.state;
    return (
      <div className="Trainingresource_management">
        <div className="Trainingresource_management_top">
          <div>
            <Input
              placeholder="资源提供商名称"
              name="supplierName"
              onChange={(e) => this.Search(e.target)}
              value={this.state.supplierName}
              style={{ width: 230 }}
            />
            <Input
              placeholder="联系人"
              name="supplierContact"
              onChange={(e) => this.Search(e.target)}
              value={this.state.supplierContact}
              style={{ width: 230, marginLeft: 25 }}
            />
            <Input
              placeholder="联系人电话"
              name="supplierPhone"
              onChange={(e) => this.Search(e.target)}
              value={this.state.supplierPhone}
              style={{ width: 230, marginLeft: 25 }}
            />
          </div>
          <div>
            <Button
              style={{ marginRight: 20 }}
              onClick={() => {
                this.restte();
              }}
            >
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.selectcha();
              }}
            >
              查询
            </Button>
          </div>
        </div>
        <div className="Trainingresource_management_main">
          <RenderButton id="ABB-01-B01-b_btnAdd">
            <Button
              type="primary"
              onClick={() => {
                this.setState(
                  () => {
                    return {
                      isshouw: "add",
                    };
                  },
                  () => {
                    this.props.form.setFieldsValue({
                      supplierName: undefined,
                      version:undefined
                    });
                    this.showModal();
                  }
                );
              }}
            >
              添加
          </Button>
          </RenderButton>
          <RenderButton id="ABB-01-B01-b_btnOperatingRecord">
            <Button style={{ marginLeft: 20 }} onClick={this.operationModal}>
              操作记录
          </Button>
          </RenderButton>
        </div>
        <div className="Trainingresource_management_button">
          <Table dataSource={dataSource} rowKey='supplierId' columns={columns} pagination={false}
            loading={this.state.dataSourceLogin}

            scroll={{ y: "calc(100vh - 460px)", x: "calc(100% - 700px)" }}

          />
          
        </div>
        <div>
          <Modal
            title={
              <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
                {this.state.isshouw === "add"
                  ? "添加提供商"
                  : this.state.isshouw === "edit"
                    ? "修改提供商"
                    : "查看提供商"}
              </div>
            }
            // key={this.state.visible}
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            bodyStyle={{ height: 270 }}
            width={660}
            centered
            closable={false}
            wrapClassName="Trainingresource_add"
          >
            <Spin spinning={saveSupilyLoading}>
            <Form {...formItemLayout_aaaa}>
              <Form.Item label="提供商名称">
                {getFieldDecorator("merchantCode", {
                  rules: [
                    { required: true, message: "请选择提供商名称" }
                  ],
                })(
                  <Select placeholder={'请选择提供商名称'} disabled={this.state.isshouw === "xiang"}>
                    {merchantOptionListData.map(({type, desc})=>(
                      <Option key={type} value={type}>{desc}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="联系人">
                {getFieldDecorator("supplierContact", {
                  rules: [
                    { required: true, message: "请输入资源提供商联系人" },
                  ],
                })(
                  this.state.isshouw !== "xiang" ? <MaxLengthInput
                    isEdit={this.state.isshouw === "xiang"}
                    placeholder="请输入资源提供商联系人"
                    style={{ height: 40, paddingRight: 50 }}
                    maxLength={10}
                  ></MaxLengthInput> : <Input disabled={true} style={{ height: 40, paddingRight: 50 }}></Input>
                )}
              </Form.Item>
              <Form.Item label="联系电话">
                {getFieldDecorator("supplierPhone", {
                  rules: [
                    { required: true, message: "请输入联系人手机号码" },
                    {
                      pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                      message: "请输入正确的手机号",
                    },
                  ],
                })(
                  <Input
                    // isEdit={this.state.isshouw === "xiang"}
                    disabled={this.state.isshouw === "xiang"}
                    placeholder="请输入联系电话"
                    style={{ height: 40, paddingRight: 50 }}
                  ></Input>
                )}
              </Form.Item>
              <Form.Item label="接口版本">
                {getFieldDecorator("version", {
                  rules: [
                    { required: true, message: "请选择接口版本" }
                  ],
                })(
                  <Select placeholder={'请选择接口版本'} disabled={this.state.isshouw === "xiang"}>
                    {versionOptionListData.map(({type, desc})=>(
                      <Option key={type} value={type}>{desc}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Form>
            </Spin>
          </Modal>
        </div>
        <Modal
          title={
            <div style={{ fontWeight: 'bold', color: "#333", fontSize: 18 }}>
              操作记录
            </div>
          }
          visible={this.state.operation}
          // onOk={this.operationOk}
          onCancel={this.operationleOk}
          bodyStyle={{ height: 500 }}
          width={1000}
          centered
          closable={false}
          wrapClassName="Trainingresource_Ttitle"
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={this.operationleOk}
              style={{ width: 90, height: 38 }}
            >
              确定
            </Button>,
          ]}
        >
          <div>
            <Table
              dataSource={dataSourceoperation}
              columns={columnsoperation}
              scroll={{ y: 360 }}
              rowKey='recordId'
              loading={this.state.datadataSourceoperationLoding}
              pagination={
                {
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '30'],
                  showSizeChanger: true,
                  onShowSizeChange: (a, b) => this.onShowSizeChange_login(a, b),
                  onChange: (a, b) => this.onPageNumChange_login(a, b),
                  current: this.state.pageNum,
                  pageSize: this.state.pageSize,
                  total: this.state.total,
                }
              }
            />
          </div>
        </Modal>
        {/* 删除 */}
        <Modal
          title={
            <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
              删除提示:{
                  
                    dataSource.find((v, i) => {
                        return v.supplierId === this.state.supplierId
                    })?.supplierName
                


              }
            </div>
          }
          visible={this.state.delete}
          onOk={this.deleteOk}
          onCancel={this.deleteCancel}
          bodyStyle={{ height: 130 }}
          width={450}
          centered
          closable={false}
          wrapClassName="details_delete_she_our"
        >
          <div>
            <p>
              <span>
                <Yichang></Yichang>
              </span>
              <span>是否确定删除此条提供商？</span>
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create()(index);
