import { post, get } from '../../until/axios_instans';
// 用户登录接口
export function login(data) {
    return post('/api/ucenter/api/v1/userLogin/operate/login', data);
}
//获取项目参数配置
export function getConfig(data) {
    return post('/api/gongyong/api/v1/address/type', data);
}

//获取用户左侧菜单列表
export function getMenu(data) {
    return post('/api/ucenter/api/v1/permission/routers', data);
}

//获取用户按钮权限
export function getRole(data) {
    return post('/api/ucenter/api/v1/permission/buttons', data);
}

//获取用户按钮权限
export function quitLogin(data) {
    return post('/api/ucenter/api/v1/userLogin/quitLogin', data);
}
//加密
export function getModulusExponent(data) {
    return post('/api/ucenter/api/v1/encode/modulusExponent', data);
}
//获取机构信息
export function getSchoolInfo(id) {
    return get('/api/university/portal/schoolInfo/' + id);
}

// 获取退出登录接口
export let logout = (data) => {
    return post("/api/ucenter/api/v1/userLogin/quitLogin", data)
}
// 获取权限路由
export let routersAuth = (data) => {
    return post("/api/ucenter/api/v1/permission/routersAuth", data)
}
