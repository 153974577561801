import React, { Component } from 'react'
// import './index.scss';
import { Form, Input, Radio, Select } from 'antd';
import { getEnterprisList } from '@/api/member/index.js';
import setName from 'classnames';

class index extends Component {
    state = {
        enterprisList: [],
        cyxm: 0
    }
    componentDidMount() {
        this.getEnterprisList();
    }
    //机构下拉
    getEnterprisList = () => {
        getEnterprisList().then(res => {
            if (res) {
                this.setState({ enterprisList: res });
            }
        })
    }
    setLen = val => this.setState(val)
    render() {
        const { getFieldDecorator } = this.props.form;
        let { enterprisList,cyxm } = this.state;
        return (
            <div className="wrapper">
                <Form>
                    <Form.Item>
                        <div className='form_item'>
                            <span style={{ textAlign: 'right' }}><span className='red'>*</span>成员姓名</span>
                            {
                                getFieldDecorator('fullName', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入成员姓名'
                                        }
                                    ]
                                })(
                                    <Input
                                    maxLength={20}
                                        onChange={e => this.setLen({ cyxm: e.target.value.length })}
                                        suffix={<span className='len'><span className={setName('current_len', cyxm === 0 ? 'zero' : '')}>{cyxm}</span>/20</span>}
                                        placeholder='请输入成员姓名' />
                                )
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='form_item'>
                            <span style={{ textAlign: 'right' }}><span className='red'>*</span>手机号码</span>
                            {
                                getFieldDecorator('telNum', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入手机号码'
                                        },
                                        {
                                            pattern: /^1\d{10}$/,
                                            message: '请填写正确的手机号'
                                        }
                                    ]
                                })(
                                    <Input maxLength={11} placeholder='请输入手机号码' />
                                )
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='form_item'>
                            <span style={{ textAlign: 'right' }}><span className='red'>*</span>性别</span>
                            {
                                getFieldDecorator('sex', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择性别'
                                        }
                                    ]
                                })(
                                    <Radio.Group style={{ width: '100%' }}>
                                        <Radio value='0'>男</Radio>
                                        <Radio value='1'>女</Radio>
                                    </Radio.Group>
                                )
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='form_item'>
                            <span style={{ textAlign: 'right' }}><span className='red'>*</span>所属机构</span>
                            {
                                getFieldDecorator('organizationId', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择所属机构'
                                        }
                                    ]
                                })(
                                    <Select placeholder='请选择所属机构'>
                                        {
                                            enterprisList.map(v => {
                                                return <Select.Option value={v.id} key={v.id}>{v.enterpriseName}</Select.Option>
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </div>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

index = Form.create()(index);

export default index;