import { post } from '@/until/axios_instans';


//查询平台分类
export function selectPlatformCategory(data) {
    return post('/api/peixun/api/v1/ts/category/selectPlatformCategory', data)
}
//修改分类
export function updateCategory(data) {
    return post('/api/peixun/api/v1/ts/category/updateCategory', data)
}
//新增分类
export function addCategory(data) {
    return post('/api/peixun/api/v1/ts/category/addCategory', data)
}
//删除一级分类
export function deleteOneCategory(data) {
    return post('/api/peixun/api/v1/ts/category/deleteOneCategory', data)
}

//标签管理
let label = "/api/peixun/api/v1/ts/label/";

//查询标签列表
export function selectLabel(data){
    return post(label+"selectLabel",data)
}

//查询机构下所有标签
export function selectAllLabel(data){
    return post(label+"selectAllLabel",data)
}

//新增标签
export function insertLabel(data){
    return post(label+"insertLabel",data)
}

//修改标签
export function updateLabel(data){
    return post(label+"updateLabel",data)
}

//删除标签
export function deleteLabel(data){
    return post(label+"deleteLabel",data)
}

//启用/禁用标签
export function disableLabel(data){
    return post(label+"disableLabel",data)
}

//查询标签关联数据
export function selectRelationInfo(data){
    return post(label+"selectRelationInfo",data)
}

//删除关联标签
export function delectLabelRelation(data){
    return post(label+"delectLabelRelation",data)
}
//查询所有标签列表
export function selectAllLabelList(data){
    return post(label+"selectAllLabelList",data)
}

//查询使用标签机构列表
export function selectLabelUseEnterprise(data){
    return post(label+"selectLabelUseEnterprise",data)
}
//查询使用分类机构列表
export function selectUseEnterprise(data) {
    return post('/api/peixun/api/v1/ts/category/selectUseEnterprise', data)
}

