import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/temporaryProject/action'
import {Button, Select, Col, Table, Input, Checkbox, message, Form, Modal, Pagination} from 'antd';
import MyTable from '@/components/Table';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {getTempHotLists, updateTempHotResources, getSubjectList} from '@/api/temporaryProject/temporaryProject'
import SetHot from './components/index'
import ReportReason from "../report/components";
const {Option} = Select
const mapStateToProps = ({TEMPORARYPROJECT}) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
class TemporaryProject extends React.Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "100"]
        },
        name: '',
        btnDisabled:true,  //重置按钮
        ids: [],//资源id集合 这里定一个集合来处理
        seniorId: '',//热门方向id
        seniorName: '',//热门方向名称
        subJectList: [],//资源分类（热门方向）
        subJectListValue: '',//资源分类默认值
        value: 'test',
        visible: false,
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        dataSource: [],
        columns: [
            {title: '资源名称', dataIndex: 'resourceName', key: 'resourceName'},
            {title: '作者', dataIndex: 'authorName', key: 'authorName', align: 'center',},
            {title: '资源类型', dataIndex: 'resourceFormName', key: 'resourceFormName', align: 'center',},
            {title: '原学习方向分类', dataIndex: 'resourceHotSeniorName', key: 'resourceHotSeniorName', align: 'center',},
            {title: '资源格式', dataIndex: 'resourceType', key: 'resourceType', align: 'center',},
            {title: '上传人', dataIndex: 'createUserName', key: 'createUserName', align: 'center',},
            {title: '上传时间', dataIndex: 'createTime', key: 'createTime', align: 'center',},
            {
                title: '操作', dataIndex: 'address', key: 'address', align: 'center', fixed: 'right',
                width: 220,
                render: (text, record) => <p className='action vertical_j'>
                    <React.RenderButton
                        text='查看'
                        id="OBS-RC-01-02-b_btnView"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                    >
                        <span onClick={() => this.props.history.push(`/transResource/temporaryProject/${record.resourceId}`)}>查看</span>
                    </React.RenderButton>
                    <React.RenderButton
                        text='设置'
                        id="OBS-RC-01-02-b_btnSet"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                    >
                        <span onClick={() => this.setUp(record)}>设置</span>
                    </React.RenderButton>
                   {/* <span>通过</span>
                    <span>不通过</span>*/}
                </p>
            }
        ]
    }

    componentDidMount() {
        this.getTempHotLists()
    }

//列表查询
    async getTempHotLists() {
        const {name, pagination} = this.state
        const hotLists = await getTempHotLists({
            "name": name,
            "pageNum": pagination.current,
            "pageSize": pagination.pageSize,
        })
        if(!hotLists)return;
        pagination.total = hotLists.total
        console.log(hotLists)
        this.setState({
            dataSource: hotLists.data,
            pagination: pagination
        },()=>{
            window.elGoTop && window.elGoTop('body');
        })
    }



    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const {pagination} = this.state;
        pagination.current = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.getTempHotLists())
    };

//分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.current = pageNumber;
        this.getTempHotLists()
        this.setState({pagination})
    }
    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({selectedRowKeys, expandedRowKeys})
    }
    //全选反选切换
    checkedAll = () => {
        let {selectedRowKeys, dataSource} = this.state;
        if (selectedRowKeys.length === dataSource.length) {
            this.setState({selectedRowKeys: [], expandedRowKeys: []});
            return;
        }
        const keys = Object.keys(dataSource);
        const index = [];
        keys.forEach(item => {
            index.push(Number(item));
        });
        this.setState({
            selectedRowKeys: index,
            expandedRowKeys: dataSource.map(v => v.resourceId)
        });
    }
    //批量设置学习方向
    batchEdit = () => {
        const {selectedRowKeys,expandedRowKeys} = this.state;
        if (!selectedRowKeys.length) {
            message.warning('请选择');
            return;
        }
       /* let ids = []
        ids.push(expandedRowKeys)*/
        console.log('expandedRowKeysidsqqqq',expandedRowKeys)
        this.setState({
            ids:expandedRowKeys,
            visible: !this.state.visible
        })
    }
    //批量冻结
    //保存弹框
/*    save = () => {
        this.setState({
            visible: !this.state.visible,
            },()=>{
            this.updateTempHotResources()
        })
    }*/
//保存
    refreshData = () => {
        this.setState({
            visible: false,
            expandedRowKeys: [],
            selectedRowKeys: [],
            ids: []
        }, () =>  this.getTempHotLists())
    }
    //取消弹框
    checkCancel = () => {
        this.setState({
            visible: false
        })
    }

    //设置
    setUp = (record) => {
        console.log('record',record)
      /*  let ids = []
        ids.push(record.resourceId)*/
        this.setState({
            ids: [record.resourceId],
            visible: !this.state.visible
        })
    }
    //搜索查询
    onHotChange = ({target: {value}}) => {
        this.setState({
            name: value
        });
    };
       //检索查询
    queryBtn = () => {
        let  pagination=this.state.pagination;
        pagination.current=1
        this.setState({
            pagination:pagination
        }, () => this.getTempHotLists());
    }
    //检索重置
    resetBtn = () => {
        this.setState({
            name: ''
        }, () => this.getTempHotLists());

    }

    render() {
        const {columns, selectedRowKeys, dataSource, visible, pagination, name, subJectList, subJectListValue} = this.state;
        const formItemLayout = {
            labelCol: {span: 0},
            wrapperCol: {span: 24},
        };
        const {getFieldDecorator} = this.props.form;
        /*   const rowSelection = {
               onChange: this.selectedRowChange,
           };*/
        return (
            <div className="publicstyle temporaryProject_box ">
                <div className=' padd '>
                    <div className="top m20">
                        <Col className='top_l btn_box' span={12}>
                            <Input value={name} onChange={this.onHotChange.bind(this)} onPressEnter ={()=>this.queryBtn()}  placeholder='输入资源名称/上传人/作者/原热门方向'/>
                            <Button className='reset_btn' onClick={() => this.resetBtn()} disabled={!name}>重置</Button>

                            {/*<Button type='primary' className='ant_blue' onClick={() => this.queryBtn()}>查询</Button>*/}
                        </Col>
                    </div>
                    <div className='check_container' style={{position: 'relative'}}>
                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                options={
                                    {
                                        dataSource,
                                        columns,
                                        rowSelection: {
                                            selectedRowKeys,
                                            // ...rowSelection
                                            onChange: this.selectedRowChange,
                                        },
                                        pagination: {onChange: this.pageChange, ...pagination,onShowSizeChange: this.onShowSizeChange}
                                    }
                                }

                                isDrab={false}
                                fixededit={true}
                            />
                        </DndProvider>
                        <div className="Grounding_position">
                        <label>
                            <Checkbox
                                checked={selectedRowKeys.length === dataSource.length ? true : false}
                                onChange={this.checkedAll}
                            />
                            <span className="Grounding_select">全选</span>
                        </label>
                            <Button className='ant_blue' onClick={this.batchEdit} type='primary'>批量设置学习方向</Button>
                        </div>
                    </div>
                </div>
               {/* <Modal
                    title='设置学习方向'
                    wrapClassName='modal_box'
                    visible={visibily}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.canale()}>取消</Button>
                            <Button onClick={() => this.save()} type='primary'>保存</Button>
                        </div>
                    }
                >
                    <Select placeholder="请选择学习方向" onChange={this.subJectListChange} style={{width: '500px'}}>
                        {subJectList && subJectList.length ? subJectList.map((item, index) => {
                            return <Option key={index} value={index}>{item.name}</Option>
                        }) : ""}

                    </Select>
                </Modal>*/}
                <SetHot
                    ids={this.state.ids}
                    visible={visible}
                    checkCancel={this.checkCancel.bind(this)}
                    refreshData={this.refreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                ></SetHot>
            </div>
        )
    }
}

TemporaryProject = connect(mapStateToProps, mapDispatchToProps)(TemporaryProject);
TemporaryProject = Form.create()(TemporaryProject)
export default TemporaryProject;
