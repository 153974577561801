import React from 'react';
import {Form, Input, Button, Row, Col, Modal, message,} from 'antd';
import {selectCommonWords, deleteCommonWords, pauseLive, frozenAndForceEndLive,addCommonWords} from '@/api/lives/live'
// import Cookies from "js-cookie";

const {TextArea} = Input;

class Banned extends React.Component {
    state = {
        commonWordList: [],
        commonWord:"",   // 常用语
        reportType: this.props.reportType,   // 暂停3  禁止2  冻结1
        userId : localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "",
    };

    componentWillMount() {
        // 查询所有常用语
        this.selectCommonWords()
    }

    selectCommonWords = () => {
        selectCommonWords({userId: this.state.userId}).then(e => {
            console.log("常用语：：：", e)
            this.setState({commonWordList: e})
        })
    }

    // 删除
    delete = (id) => {
        let that = this;
        Modal.confirm({
            title: "温馨提示",
            content: "确定要删除吗？删除后将无法恢复！",
            okText: "确定",
            cancelText: "关闭",
            centered: true,
            className: "ModalConfirm-pic",
            onOk() {
                deleteCommonWords({commonWordId: id, userId: that.state.userId}).then(e => {
                    message.destroy()
                    message.success("删除成功")
                    that.selectCommonWords()
                })
            },
            onCancel() {
            },
        });
    }

    // 选中常用语
    selectText = (commonWord) => {
        this.props.form.setFieldsValue({title: commonWord})
    }

    returnMessage = () => {
        const {reportType} = this.state;
        if (reportType === 1) {
            return "请输入冻结直播原因"
        } else if (reportType === 2) {
            return "请输入禁止开播原因"
        } else if (reportType === 3) {
            return "请输入暂停直播原因"
        }else if (reportType === 4) {
            return "请输入强制结束原因"
        }
    }

    reasonsSubmission = () => {
        const {reportType,taskId,isReport} = this.props;
        this.props.form.validateFields(async (err, info) => {
            if (!err) {
                console.log("提交了操作：：：reportType:::",reportType)
                if (reportType === 3) {
                    pauseLive({createUserId: this.state.userId, processDesc: info.title, taskId}).then(e=>{
                        message.destroy();
                        message.success("操作成功")
                        if (!this.props.page){
                            this.props.cancel();
                        }else{
                            console.log("111111111");
                            this.props.pauseCancel();
                        }
                        this.props.getList();
                    })
                }else if (reportType === 4){
                    frozenAndForceEndLive({taskId,lastProcessType:3,isReport:isReport,reason:info.title,userId:this.state.userId}).then(e=>{
                        message.destroy();
                        message.success("操作成功");
                        this.props.cancel();

                        this.props.getList();
                    })
                }else{
                    frozenAndForceEndLive({taskId,lastProcessType:reportType,isReport:isReport,reason:info.title,userId:this.state.userId}).then(e=>{
                        message.destroy();
                        message.success("操作成功");
                        this.props.cancel();
                        this.props.getList();
                    })
                }
            }
        })
    };

    commonWord = (e) =>{
        console.log("e::::",e.target.value);
        this.setState({commonWord:e.target.value})
    };

    addCommonWords = () =>{
      const {commonWord} = this.state;
        if(commonWord){
            addCommonWords({userId:this.state.userId,commonWord}).then(e=>{
                this.selectCommonWords();
                this.setState({commonWord:""})
            })
        }else{
            message.destroy();
            message.warning("常用语不能为空")
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        const {title, commonWordList, reportType} = this.state;
        return (
            <div className="form-warp">
                <Form className="form-Banned">
                    <Form.Item className="Item-li TextArea">
                        {
                            getFieldDecorator('title', {
                                initialValue: title,
                                rules: [
                                    {
                                        required: true,
                                        message: this.returnMessage(),
                                    },
                                    {
                                        validator: this.onChangeUrl
                                    },
                                    {
                                        max: 100,
                                        message: "最多只能填写100字符"
                                    }
                                ]
                            })(
                                <TextArea
                                    placeholder={reportType === 1 ? "请输入冻结直播原因" : reportType === 2 ? "请输入禁止开播原因" : reportType === 3 ? "请输入暂停直播原因" :
                                        reportType === 4 ? "请输入强制结束原因" : ""}
                                    rows={3} className="input"/>
                            )
                        }
                        <span
                            className="text-right">{this.props.form.getFieldValue(`title`) ? this.props.form.getFieldValue(`title`).length : 0}/100</span>
                    </Form.Item>
                    <Row>
                        <Col span={24}>
                            <h2>常用语</h2>
                        </Col>
                        <Col span={24}>
                            {
                                commonWordList?.map((item, index) => {
                                    return (
                                        <li onClick={() => this.selectText(item.commonWord)}>
                                            <p>{item.commonWord}</p>
                                            <span>
                                                <i onClick={() => this.delete(item.id)}>删除</i>
                                            </span>
                                        </li>
                                    )
                                })
                            }
                            {
                                commonWordList?.length >= 10 ? "" : <div className="input-warp">
                                    <TextArea rows={2} className="Text-input" onChange={(e)=>this.commonWord(e)} value={this.state.commonWord}/>
                                    <span className="add-span" onClick={()=>this.addCommonWords()}>+添加常用语</span>
                                </div>
                            }
                        </Col>
                    </Row>
                </Form>
                <div className="ant-modal-footera">
                    <div className='pos-btn'>
                        <Button onClick={() => this.props.cancel()}>取消</Button>
                        <Button type="primary" onClick={() => this.reasonsSubmission()}>确定</Button>
                    </div>
                </div>
            </div>
        );
    }
}

Banned = Form.create()(Banned);
export default Banned;
