import React, { Component } from 'react';
import {Select, Button, Input, Table, Modal, message, Form, Radio, DatePicker} from 'antd';
import { importRecordDetail } from '../../../../api/platform-school/index'
import './index.scss';
import {twoImportMembers,importSchoolTeachers,againImport,checkAgainSchoolTeachers} from "../../../../api/platform-business";
import {getCitySel, getProvinceSel, globalSel,getOrganizatioMembers,addOrganizatioMembers,updateOrganizatioMembers,updateImportFacultyOrMajor,formattingPassword,downTemplate,generalImport} from '../../../../api/platform-business/index'
import moment from "moment";
const { Option } = Select;
class index extends Component {
    state = {
        dataSource: [], // 导入异常列表
        facultyList: [], // 院系list
        recordId: "",
        createTime: '',
        importUserName: '',
        fileName: '',
        peopleTotalNum: '',
        formData:{},//文件(暂时不用)
        enterpriseId:'',//机构id
        updateImportFaculty:false,
        isDelete: false,// 删除弹层
        isRepassword: false,// 重置密码
        propertyList: [], // 教师类型下拉列表
        professionalCallList: [], // 职称下拉列表
        professionalCall: '', // 职称
        property: '', // 教师类型
        teacherNameOrNum: '', // 教师工号/教师名称
        propertyDicType: 19, // 教师类型字典中的类型
        professionalCallDicType: 16, // 职称字典中的类型
        nameLength: 0, // 新增弹框姓名长度
        positionLength: 0, // 新增弹框职务长度
        provinceSel: [], // 所在地省下拉列表
        citySel: [], // 所在地市
        yuanxiaoSel:[],
        orgMember: {}, //机构成员json  有是修改没是新增
        orgMemberId:"", //删除时用
        highestDegreeOption:[],//学位下拉
        highestEducationOption:[],//学历下拉
        cardTypeOption:[{
            code:'0',
            name:'身份证'
        }, {
            code:'1',
            name:'护照'
        }],//证件下拉
        Batch:false,
        btnActive: false,
        errorMessage:'',
        batchRef:{},//文件
    }


    componentDidMount() {
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        console.log("jsonparam",jsonparam)
        this.setState({
            recordId: jsonparam.recordId,
            enterpriseId: jsonparam.enterpriseId,
            jumpType:jsonparam.jumpType?jsonparam.jumpType:"",
        },()=> {
            this.importRecordDetail()
        })
    }

    async importRecordDetail(){
        const param = {
            recordId: this.state.recordId
        }
        console.log("param",param)
        const res = await importRecordDetail(param)
        this.setState({
            dataSource: res.importErrVoList,
            facultyList: res.facultyList,
            createTime: res.createTime,
            importUserName: res.importUserName,
            fileName: res.fileName,
            peopleTotalNum: res.peopleTotalNum,
            importStatus: res.importStatus
        })
    }
    updateFacultyName = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("values",values)
                    let record= {
                        indexType:1,
                        indexNum:this.state.indexNum,
                        facultyName: values.facultyName,
                        recordId: this.state.recordId,
                    }
                updateImportFacultyOrMajor(record).then(res => {
                        if (res) {
                            this.mesSuccess('保存成功');
                            this.setState({
                                updateImportFaculty:false
                            },()=>{
                                this.importRecordDetail()
                            })
                        }
                    })
                }
    })
    }
    updateImportFaculty = (v,index) => {
        console.log("index",index)
        this.setState({
            updateImportFaculty:true,
            indexNum:index,
        },()=>{
            this.props.form.setFieldsValue({
                facultyName: v,
            })
        })
    }
    //以下结果再校验
    commitBatch = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        checkAgainSchoolTeachers({recordId:recordId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('校验成功');
                this.importRecordDetail()
                // if (Object.prototype.toString.call(res.message) === '[object String]' && res.message !== 'success') {
                //     this.setState({ errorMessage: res.message, fileName: this.batchRef.state.batchTitle });
                // }
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
            console.log("重新拉取列表")
            this.importRecordDetail()
        });
    }
    //以下结果再导入
    importSchoolTeachers = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        importSchoolTeachers({recordId:recordId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('导入成功');
                this.importRecordDetail()
                // if (Object.prototype.toString.call(res.message) === '[object String]' && res.message !== 'success') {
                //     this.setState({ errorMessage: res.message, fileName: this.batchRef.state.batchTitle });
                // }
            }
        }).catch(err => {
            console.log("err",err)
        })
        this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
            console.log("重新拉取列表")
            this.importRecordDetail()
        });
    }
    //确定导入
    sureImport = () => {
        let {recordId} = this.state
        console.log("recordId:  ",recordId)
        importSchoolTeachers({recordId:recordId,enterpriseId:this.state.enterpriseId}).then(res => {
            if (res) {
                console.log("res", res)
                message.destroy();
                this.mesSuccess('导入成功');

                // this.institutionsDetail(this.state.enterpriseId)
                // if (Object.prototype.toString.call(res.message) === '[object String]' && res.message !== 'success') {
                //     this.setState({ errorMessage: res.message, fileName: this.batchRef.state.batchTitle });
                // }
                this.setState({ batch: false, errorMessage: '', btnActive: false },()=>{
                    console.log("重新拉取列表")
                    this.importRecordDetail()
                });
            }
        }).catch(err => {
            console.log("err",err)
        })
    }
    //确定导入后跳页
    institutionsDetail = enterpriseId => {
        console.log("enterpriseId: ",enterpriseId)
        this.props.history.push(`/main/platform-business/member-detail?${encodeURI(JSON.stringify(enterpriseId))}`);
    }
    // 姓名输入框长度
    nameLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                nameLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                nameLength:value.length
            })
        } else {
            callback();
        }
    }

    // 姓名输入框长度
    positionLength =  (rule, value, callback) => {
        if(value.length === 0){
            this.setState({
                positionLength:0
            })
            callback();
        }else if (value && value.length!==0){
            this.setState({
                positionLength:value.length
            })
            callback();
        } else {
            callback();
        }
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号')
        } else {
            callback();
        }
    }

    //省下拉
    getProvinceSel = async () => {
        let res = await getProvinceSel();
        res && this.setState({ provinceSel: res });
    }
    //省下拉框change
    changeProvince = (e, item) => {
        this.setState({ provinceCode: e, citySel: [] }, () =>
            this.getCitySel()
        );
    }

    //市下拉
    getCitySel = async () => {
        if (this.state.provinceCode) {
            let res = await getCitySel({ provinceCode: this.state.provinceCode });
            res && this.setState({ citySel: res });
        }
    }
    //市下拉框change
    changeCity = (e, item) => {
        this.setState({
            cityId: e
        });
    }
    //查看弹窗
    view = (record) => {
        console.log("查看得传值",record)
        this.setState({
            isTeacher: true,
        },()=>{
            this.props.form.setFieldsValue({
                orgMemberName: record.orgMemberName,
                jobNum: record.jobNum,
                sex:record.sex,
                telNum:record.telNum,
                birthday:moment(record.birthday),
                nativePlace:record.nativePlace,
                email:record.email,
                cardType: "1",
                idCard:record.idCard,
                highestEducation:record.highestEducation?Number(record.highestEducation):"",
                highestDegree:record.highestDegree?Number(record.highestDegree):"",
                inductionTime:record.inductionTime,
                professionalCall:record.professionalCall?Number(record.professionalCall):"",
                identityName:record.identityName,
                position:record.position,
                roleId:record.roleId,
                cityName:record.nativePlace?record.nativePlace.split("-")[1]:"",
                provinceName:record.nativePlace?record.nativePlace.split("-")[0]:"",
            });
        });
    }
    // 不导入
    async handImport(record) {
        Modal.confirm({
            title: '温馨提示',
            content: '您确定不导入吗',
            okText: '确定',
            cancelText: '取消',
            onOk() {
            },
            onCancel() {
            }
        });
    }
    goBack = () => {
        this.props.history.push(`/main/platform-business/tea-manage?${encodeURI(JSON.stringify({enterpriseId:this.state.enterpriseId}))}`);
    }
    render() {
        const { dataSource,createTime,importUserName,fileName,facultyList,peopleTotalNum,updateImportFaculty, importStatus,provinceSel,citySel,positionLength,yuanxiaoSel,highestDegreeOption,highestEducationOption,cardTypeOption,propertyList } = this.state;
        const { getFieldDecorator,getFieldValue } = this.props.form;
        const columns = [
            {title: '序号', dataIndex: 'source', key: 'source', align: 'center', render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '教师姓名', dataIndex: 'errUserName',key:'errUserName',width:'50', },
            {title: '手机', dataIndex: 'errTelNum',key:'errTelNum',width:'50', },
            {title: '工号/学号', dataIndex: 'errJobNum',key:'errJobNum',width:'50', },
            {title: '所属院系', dataIndex: 'errFacultyName',key:'errFacultyName', width:'50',},
            {title: '教师类型', dataIndex: 'errTeacherType', key:'errTeacherType',width:'50',},
            {title: '教师职称', dataIndex: 'errProfessionalCall',key:'errProfessionalCall', width:'50',},
            {title: '异常原因', dataIndex: 'errReason', key:'errReason',width:'50',},
            {title: '状态', align: 'center', render: (text, item, index ) =>  <span className='serial'>
                    {
                        item.errStatus === 1 ? '校验失败' : item.importStatus === 2 ? '校验成功':
                            item.errStatus === 3 ?  '导入成功' : item.errStatus === 4 ?  '不再倒入' : ''
                    }</span>,},
            // {
            //     title: '操作',
            //     key: 'operate',
            //     align:'center',
            //     fixed:'right',
            //     width:'200',
            //     render:(record)=><div className="op-span">
            //         <span className="but" onClick={() => this.view(record)}>修改</span>
            //         <span className="but" onClick={() => this.handImport(record)}>不导入</span>
            //     </div>},
        ];
        return (
            <div className='result_box'>
                <div className="oneline">
                    <div className="one">
                        <p>导入人：{importUserName}</p><p>导入时间：{createTime}</p><p>导入文件名称：{fileName}</p>
                    </div>
                    <div className="two">
                        <p>导入结果：</p><p className="ok">导入成功</p>
                    </div>
                    <div className="three">
                        <p>新增院系（{facultyList?facultyList.length:0}个）：
                            {
                                facultyList && facultyList.map((v,index) => {
                                    return <span className="but">{v} </span>
                                        // <Button type="primary" onClick={() =>this.updateImportFaculty(v,index)}>修改</Button>
                                })
                            }
                        </p>
                    </div>
                    <div className="four">
                        <p>新增教师（{peopleTotalNum}人）</p><p className="attention">提示：导入角色为教师，如果需要配置管理员请导入后去机构管理-详情页面-绑定管理员账号</p>
                    </div>
                    <div className="five">
                        {importStatus ===0?<Button type="primary" onClick={this.sureImport}>确认导入</Button>:
                            <Button type="primary" onClick={this.goBack}>返回</Button>}
                        <p>导入校验结果：{dataSource.length>0? "校验失败"+dataSource.length+"条":"暂无失败校验"}</p>
                    </div>
                </div>
                <div className="twoline">
                    <div className="topline">
                        <div className="left">
                            {/*<Button className="buttonl" type="primary" onClick={this.commitBatch}>以下结果再次校验</Button>*/}
                            {/*<Button className="buttonm" onClick={this.importSchoolTeachers}>以下结果再导入</Button>*/}
                        </div>
                        <div className="right">共失败{dataSource?dataSource.length:0}人</div>
                    </div>
                    <div className="tablelist">
                        <Table  columns={columns} dataSource={dataSource} />
                    </div>
                </div>
                <Modal
                    title='修改院系名称'
                    visible={updateImportFaculty}
                    destroyOnClose={true}
                    closable={false}
                    className='binding_modal'
                    footer={
                        <div className='foot_botton'>
                            <Button className="addbutton" onClick={this.updateFacultyName}>确认修改</Button>
                            <Button className="delete" onClick={() => this.setState({ updateImportFaculty: false })}>取消</Button>
                        </div>
                    }
                >
                    <Form className='lt_form_wapper'>
                        <Form.Item label={`院系名称`}>
                            {getFieldDecorator('facultyName', {
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入院系名称",
                                    },
                                ]
                            })(<Input  className="input" maxLength={100}/>)}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}
index = Form.create()(index);
export default index;
