import React, { Component } from 'react';
import { Input, Button, Table, Pagination } from "antd";
import { getVmOrgManagePage } from "../../api/school_settlement";
import iconType from '../../icon_type';
import TextOverflowEllipsis from '../../components/text_overflow_ellipsis';
import ConfigureApplicationModal from '../../components/config_application_modal';

import styles from './index.module.scss';

class VirtualOrganizationManage extends Component {
    state = {
        dataSource: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        searchValue: "",
        configAppVisible: false,
        enterpriseId: '',
    };

    componentDidMount() {
        this.getDataSource();
    };

    getDataSource = async () => {
        const {pageNum,pageSize,total,searchValue} = this.state;
        const params = {pageNum,pageSize,total};
        if(searchValue){
            params.vmOrgName = searchValue;
        };
        try {
            const res = await getVmOrgManagePage(params);
            this.setState({dataSource:res.data,total:res.total});
        } catch (error) {}
    }

    //分页
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => {
            this.getDataSource();
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({pageNum:1, pageSize },() => {
            this.getDataSource();
        })
    };

    /*搜索*/
    onSearch = e => {
        this.setState({ searchValue: e.target.value },);
    };

    handleCancel = () => {
        this.setState({ configAppVisible: false })
    }
    handleConfigAppVisible = (enterpriseId) => {
        this.setState({
            configAppVisible: true,
            enterpriseId,
        })
    }

    columns = [
        { 
            title: '序号', 
            dataIndex: 'upDownatus', 
            key: 'upDownStus', 
            align: 'center',
            render:(text,r,i) => i+1
        },
        {
            title: '虚拟机构名称',
            dataIndex: 'enterpriseName',
            key: 'enterpriseName',
            align: 'center',
            width: 200,
            render: (text) => <TextOverflowEllipsis text={text} />
        },
        { 
            title: '机构识别码', 
            dataIndex: 'enterpriseCode', 
            key: 'enterpriseCode', 
            align: 'center', 
        },
        { 
            title: '所属范围', 
            dataIndex: 'vmOrgRangeName', 
            key: 'vmOrgRangeName', 
            align: 'center',
        },
        { 
            title: '加入日期', 
            dataIndex: 'settledDate', 
            key: 'settledDate', 
            align: 'center', 
        },
        { 
            title: '到期日期', 
            dataIndex: 'settledEndDate', 
            key: 'settledEndDate', 
            align: 'center', 
        },
        {
            title: '操作',
            dataIndex: 'op',
            key: 'op',
            width: 300,
            align: 'center',
            render: (_, r) => {
                const { id } = r;
                return (
                    <div className="action vertical_j">
                        <React.RenderButton id="OBSMM-01-A03-b-btnGoInNeirong">
                        <span onClick={() => { this.props.history.push(`/main/institutional-settlement/virtual_organization_detail?id=${id}`) }}>进入详情</span>
                        </React.RenderButton>
                        <React.RenderButton id="OBSMM-01-A03-b-btnUpdataSchoolInformation">
                        <span onClick={() => { this.props.history.push(`/main/institutional-settlement/updata_virtual_organization?id=${id}`) }}>修改机构信息</span>
                        </React.RenderButton>
                        <React.RenderButton id="OBSMM-01-A03-b-btnConfigureApplication">
                        <span onClick={() => this.handleConfigAppVisible(id)}>配置应用</span>
                        </React.RenderButton>
                    </div>
                )
            }
        },
    ];
    
    render() {
        const { 
            dataSource, searchValue, 
            total, pageNum, pageSize,
            configAppVisible, enterpriseId,
        } = this.state;
        
        return (
            <div className={styles.VirtualOrganizationManage}>
                <div className={styles['bottom_file_type_box']}>
                    <React.RenderButton id="OBSMM-01-A03-b-btnAddSchool">
                    <Button type="primary" onClick={()=>this.props.history.push('/main/institutional-settlement/create_virtual_organization')}>创建虚拟机构</Button>
                    </React.RenderButton>
                    <div className={styles['file_type_r']}>
                        <Input
                            placeholder="请输入虚拟机构名称"
                            style={{ width: 230 }}
                            value={searchValue}
                            onChange={this.onSearch}
                            prefix={iconType.magnifier}
                        />
                        <React.RenderButton id="OBSMM-01-A03-b-btnSearch">
                        <Button onClick={this.getDataSource} className={styles['resetbtn']}>查 询</Button>
                        </React.RenderButton>
                        <span style={{ color: "#888" }}>
                            共{this.state.total > 99999 ? "99999+" : total}个虚拟机构入驻
                                </span>
                    </div>
                </div>
                <div className={styles['manage_mechanism_manage_table']}>
                    <Table
                        dataSource={dataSource}
                        columns={this.columns}
                        pagination={false}
                        rowKey="id"
                        scroll={{
                            y: true
                        }}
                        locale={{
                            emptyText:<img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                        }}
                        footer={() => {
                            return <Pagination
                                showSizeChanger
                                pageSize={pageSize}
                                current={pageNum}
                                total={total}
                                onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                onChange={p => this.pageChange(p)}
                            />
                        }} 
                    />
                </div>
                <ConfigureApplicationModal
                    configAppVisible={configAppVisible}
                    onCancel={this.handleCancel}
                    enterpriseId={enterpriseId}
                />
            </div>
        );
    }
}


export default VirtualOrganizationManage;
