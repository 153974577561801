import React, { Component } from 'react'
import { Button, Form, Input, Row, Col, Select, Divider, Table, Pagination } from 'antd';
import S from 'gongyongicon';
import { get } from 'lodash';

import TextOverflowEllipsis from '../../components/text_overflow_ellipsis';
import ViewStudentInformation from '../../components/view_student_information';
import { getStudentPage, selectFacultyByOrgId, selectMajorByFacultyId, selectClassByMajorId, resetUserPassword, deleteStudent } from '../../api/school_settlement';
import iconType from '../../icon_type';

import style from './index.module.scss';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xxl: { span: 5 },
        xl: { span: 6 },
        lg: { span: 6 },
    },
    wrapperCol: {
        xxl: { span: 18 },
        xl: { span: 16 },
        lg: { span: 16 },
    },
};
export default Form.create()(class StudentManagement extends Component {
    enterpriseId = this.getUrlType(this.props.location.search, 'id')
    state = {
        isExpand: false,
        data: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        facultyByOrgData: [],
        majorByFacultyData: [],
        classByMajorData: [],
        userId: '',
        visible: false,
    }
    async componentDidMount() {
        const facultyByOrgData = await selectFacultyByOrgId({ organization: this.enterpriseId });
        this.setState({
            facultyByOrgData,
        })
        this.getSchoolIntoManagePage();
    }
    getSchoolIntoManagePage = async () => {
        const { pageNum, pageSize } = this.state;
        const value = this.props.form.getFieldsValue();
        const res = await getStudentPage({
            ...value,
            pageNum,
            pageSize,
            organization: this.enterpriseId,
        });
        this.setState({
            data: get(res, 'data', []),
            total: get(res, 'total', 0),
        })
    }
    handleReset = () => {
        this.props.form.resetFields();
        this.getSchoolIntoManagePage();
    }
    //分页
    pageChange = (pageNum) => {
        this.setState({ pageNum }, () => {
            this.getSchoolIntoManagePage();
        })
    };

    //pageSize变化
    onShowSizeChange = pageSize => {
        this.setState({ pageNum: 1, pageSize }, () => {
            this.getSchoolIntoManagePage();
        })
    };
    handleFaChange = async (id) => {
        //获取专业信息
        const res = await selectMajorByFacultyId({ id });
        this.setState({
            majorByFacultyData: res || []
        })
        this.props.form.setFieldsValue({
            majorId: undefined,
            classId: undefined,
        })
    }
    handleMajorByFaChange = async (id) => {
        //获取班级信息
        const res = await selectClassByMajorId({ id });
        this.setState({
            classByMajorData: res || []
        })
        this.props.form.setFieldsValue({
            classId: undefined,
        })
    }
    delete = (id) => {
        this.hint({
            type: 'warning',
            title: '温馨提示',
            content: '您确定要删除该用户吗？',
            isCancel: true,
            sure: async () => {
                try {
                    await deleteStudent({ organization: this.enterpriseId, id })
                    this.getSchoolIntoManagePage();
                    this.mesSuccess('删除成功');
                } catch (error) {
                    this.mesWarning('删除失败');
                }
            }
        });
    }
    resetPaw = (userId) => {
        this.hint({
            type: 'warning',
            title: '温馨提示',
            content: '您确定要将此用户密码重置吗？重置后密码为Admin123456',
            isCancel: true,
            sure: async () => {
                try {
                    await resetUserPassword({ enterpriseId: this.enterpriseId, userId })
                    this.getSchoolIntoManagePage();
                    this.mesSuccess('操作成功');
                } catch (error) {
                    this.mesWarning('操作失败');
                }
            }
        });
    }
    see = (userId) => {
        this.setState({
            visible: true,
            userId
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            isExpand, data, pageNum, pageSize, total,
            facultyByOrgData = [], majorByFacultyData, classByMajorData,
            visible, userId,
        } = this.state;
        const { RenderButton } = React;
        const columns = [
            {
                title: '姓名',
                dataIndex: 'fullName',
                key: 'fullName',
                align: 'center',
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                align: 'center',
                render: text => text === 1 ? '男' : '女'
            },
            {
                title: '学号',
                dataIndex: 'jobNum',
                key: 'jobNum',
                align: 'center',
            },
            {
                title: '院系',
                dataIndex: 'facultyName',
                key: 'facultyName',
                align: 'center',
            },
            {
                title: '专业',
                dataIndex: 'majorName',
                key: 'majorName',
                align: 'center',
            },
            {
                title: '行政班级',
                dataIndex: 'className',
                key: 'className',
                align: 'center',
            },
            {
                title: '手机号',
                dataIndex: 'telNum',
                key: 'telNum',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'op',
                key: 'op',
                align: 'center',
                width: 300,
                render: (_, r) => {
                    const { id } = r;
                    return (
                        <div className="action vertical_j">
                            <RenderButton id="OBSMM-01-B04-a-btnShowData">
                            <span onClick={() => this.see(id)}>查看</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-B04-a-btnUpdata">
                            <span onClick={() => this.props.history.push(`/main/institutional-settlement/virtual_student_updata?id=${this.enterpriseId}&userId=${id}`)}>修改</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-B04-a-btnDelete">
                            <span onClick={() => this.delete(id)}>删除</span>
                            </RenderButton>
                            <RenderButton id="OBSMM-01-B04-a-btnResetPassword">
                            <span onClick={() => this.resetPaw(id)}>重置密码</span>
                            </RenderButton>
                        </div>
                    )
                }
            },
        ];

        return (
            <div className={style.StudentManagement}>
                <div className={style.search}>
                    <Form {...formItemLayout} colon={false}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="院系">
                                    {getFieldDecorator("facultyId", {

                                    })(
                                        <Select
                                            placeholder="请选择院系"
                                            onChange={this.handleFaChange}
                                        >
                                            {facultyByOrgData.map(item => <Option value={item.id}>{item.facultyName}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="专业">
                                    {getFieldDecorator("majorId", {

                                    })(
                                        <Select
                                            placeholder="请选择专业"
                                            onChange={this.handleMajorByFaChange}
                                        >
                                            {majorByFacultyData.map(item => <Option value={item.id}>{item.majorName}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="行政班级">
                                    {getFieldDecorator("classId", {

                                    })(
                                        <Select
                                            placeholder="请选择行政班级"
                                        >
                                            {classByMajorData.map(item => <Option value={item.id}>{item.className}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {!isExpand ? (
                                <Col span={6} className={style.expand}>
                                    <Button style={{ marginRight: 20 }} onClick={this.handleReset}>重 置</Button>
                                    <Button type="primary" onClick={this.getSchoolIntoManagePage}>查 询</Button>
                                    <div onClick={() => { this.setState({ isExpand: true }) }} style={{ color: '#3e78ed', cursor: 'pointer', marginLeft: 20 }}>
                                        <span style={{ paddingRight: 5 }}>展开</span><S.XiaArrow />
                                    </div>
                                </Col>
                            ) : null}
                        </Row>
                        {isExpand && (
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item label="搜索学生" style={{ marginBottom: 14 }}>
                                        {getFieldDecorator("key", {

                                        })(
                                            <Input placeholder="请输入学生名字/手机号" prefix={iconType.magnifier} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={18} className={style.expand}>
                                    <Button style={{ marginRight: 20 }} onClick={this.handleReset}>重 置</Button>
                                    <Button type="primary" onClick={this.getSchoolIntoManagePage}>查 询</Button>
                                    <div onClick={() => { this.setState({ isExpand: false }) }} style={{ color: '#3e78ed', cursor: 'pointer', marginLeft: 20 }}>
                                        <span style={{ paddingRight: 5 }}>收起</span><S.ShangArrow />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
                <div className={style.table_list}>
                    <div className={style.create_school}>
                        <RenderButton id="OBSMM-01-B04-a-btnAddStudent">
                        <Button type="primary" onClick={() => this.props.history.push(`/main/institutional-settlement/virtual_student_add?id=${this.enterpriseId}`)}>新增学生</Button>
                        </RenderButton>
                        <div>共{total}个人</div>
                    </div>
                    <Divider />
                    <div className={style.antd_table_wrap}>
                        <Table
                            rowKey="id"
                            dataSource={data}
                            columns={columns}
                            pagination={false}
                            scroll={{
                                y: true
                            }}
                            locale={{
                                emptyText: <img alt="" src={window.$$setImgSrc('CS_nodataImg.png')}></img>
                            }}
                            footer={() => {
                                return <Pagination
                                    showSizeChanger
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                    onShowSizeChange={(c, z) => this.onShowSizeChange(z)}
                                    onChange={p => this.pageChange(p)}
                                />
                            }}
                        />
                    </div>
                </div>
                <ViewStudentInformation
                    enterpriseId={this.enterpriseId}
                    userId={userId}
                    visible={visible}
                    onCancel={() => this.setState({ visible: false })}
                />
            </div>
        )
    }
})
