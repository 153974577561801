import React from 'react';
import './index.scss';
import {
    Table, Tabs,
} from 'antd';
import TableEmpty from '../../page/authorityManagement/components/TableEmpty/TableEmpty'
import {loginLog,logPage} from '../../api/journal/index'
let columns = []
class Journal extends React.Component {
    state = {
        dataSource: [],
        status: '0',
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ['20', '40', '60', '80']
        },
    }

    componentDidMount() {
        this.loginLog()
    }
    //登录日志列表
    loginLog = async () =>{
        columns = [
            { title: '用户名称', dataIndex: 'userId', key: 'userId', align: 'center',render: (text) => text ?? '- -' },
            { title: '登录方式', dataIndex: 'loginType', key: 'loginType', align: 'center',render: (text) => {
                return text === 100001 ? '用户名密码' : text === 100002 ? "短信" : text === 100003 ? '手机密码' :
                    text === 100004 ? '邮箱密码' : text === 100005 ? "机构密码" : ''
                } },
            {title: '登录类型', dataIndex: 'loginWay', key: 'loginWay', align: 'center',render: (text) => text ?? '- -' },
            {title: '登录时间', dataIndex: 'createTime', key: 'createTime', align: 'center',render: (text) => text ?? '- -' },
        ]
        const {pagination} = this.state
        const res = await loginLog({
             pageNum: pagination.current,
             pageSize: pagination.pageSize,
         })
        pagination.total = res ? res.total : 0
        this.setState({dataSource: res?.data,pagination})
    }
    //查询操作日志列表
    logPage = async () =>{
        columns = [
            { title: '用户名称', dataIndex: 'account', key: 'account', align: 'center',render: (text) => text ?? '- -' },
            {title: '操作时间', dataIndex: 'createTime', key: 'createTime', align: 'center',render: (text) => text ?? '- -' },
            {title: '操作描述', dataIndex: 'operationDesc', key: 'operationDesc', align: 'center',render: (text) => text ?? '- -' },
            {title: '系统标识名称', dataIndex: 'platformName', key: 'platformName', align: 'center',render: (text) => text ?? '- -' },
            { title: '操作类型', dataIndex: 'action', key: 'action', align: 'center',render: (text) => {
                    return text === 1 ? '增加' : text === 2 ? "删除" : text === 3 ? '编辑' : '--'
                } },

        ]
        const {pagination} = this.state
        const res = await logPage({
            platform:"",
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            type: 2
        })
        pagination.total = res ? res.total : 0
        this.setState({dataSource: res?.data,pagination})
    }
    //运营空间列表
    logOperation = async () =>{
        columns = [
            { title: '用户名称', dataIndex: 'account', key: 'account', align: 'center',render: (text) => text ?? '- -' },
            {title: '操作时间', dataIndex: 'createTime', key: 'createTime', align: 'center',render: (text) => text ?? '- -' },
            {title: '操作描述', dataIndex: 'operationDesc', key: 'operationDesc', align: 'center',render: (text) => text ?? '- -' },
            {title: '系统标识名称', dataIndex: 'platformName', key: 'platformName', align: 'center',render: (text) => text ?? '- -' },
            { title: '操作类型', dataIndex: 'action', key: 'action', align: 'center',render: (text) => {
                    return text === 1 ? '增加' : text === 2 ? "删除" : text === 3 ? '编辑' : '--'
                } },
        ]
        const {pagination} = this.state
        const res = await logPage({
            platform:"OBS",
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            type: 2
        })
        pagination.total = res ? res.total : 0
        this.setState({dataSource: res?.data,pagination})
    }
    //分页
    pageChange = (pageNumber) => {
        const { pagination,status } = this.state;
        pagination.current = pageNumber;
        this.setState({ pagination },  () => {
            if(status === '0'){
                this.loginLog()
            }else if(status === '1'){
                this.logPage()
            }else{
                this.logOperation()
            }
        })
    }


    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const { pagination,status } = this.state;
        console.log('status',status,status===0)
        pagination.current = 1;
        pagination.pageSize = pageSize;
        this.setState({ pagination },  () => {
            if(status === '0'){
                this.loginLog()
            }else if(status === '1'){
                this.logPage()
            }else{
                this.logOperation()
            }
        })
    };
    roleStatusChange = (status) => {
        console.log('status',status,status==='0')
        this.setState({
            pagination: { ...this.state.pagination, current: 1 },
            status: status
        }, () => {
            if(status === '0'){
                this.loginLog()
            }else if(status === '1'){
                this.logPage()
            }else{
                this.logOperation()
            }
        })
    }
    render() {
        const {
            pagination,
            dataSource,
        } = this.state;
        return (
            <div className='journal_box'>
                <div className='journal_tabs'>
                    <Tabs defaultActiveKey={''} onChange={this.roleStatusChange}>
                        <Tabs.TabPane key={'0'} tab={'登录日志'} />
                        <Tabs.TabPane key={'1'} tab={'个人/管理'} />
                        <Tabs.TabPane key={'2'} tab={'运营空间'} />
                    </Tabs>
                </div>
                <div className={`role_container ui_table`} style={{ position: 'relative' }}>
                    <Table
                        rowKey={(record, i) => i}
                        dataSource={dataSource}
                        columns={columns}
                        scroll={{ y: 670 }}
                        pagination={{
                            onChange: this.pageChange,
                            ...pagination,
                            onShowSizeChange: this.onShowSizeChange
                        }}
                        locale={{
                            emptyText: <TableEmpty />
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default Journal;
