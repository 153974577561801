import React, { Component } from 'react';
import './index.scss';
import {Col, Button, message, Modal, Input} from 'antd';
import {getResourceDetail,shareResource,resourceShelfOperate,getShelvesReason} from '@/api/grounding';
import { connect } from 'react-redux';
import listObj from '../../store/OBS/transResource/action';
import {browsingResource} from '@/api/transResource'
import OffShelf from "../../components/OffShelfModal";
import FilePreview from "@/components/FilePreview"; //资源查看
const mapStateToProps = ({ TRANSRESOURCE: { options } }) => {return {}}
const mapDispatchToProps = (dispatch) => {return {};}
class resourceDetail extends Component {
    state = {
        params:{
            id: "",
            shelvesType: 0  //0上架   1下架
        },
        reasonParams:{
            id: "",
            pageNum: 1,
            pageSize: 4
        },
        total: 0,
        lookVisible: false,
        shelvesReason: "",
        shelvesReasonList: [],
        fileId: '',
        resourceDetails:{}
    }
    goBack = () => {
        this.props.history.goBack()
    }

    componentDidMount() {
        const  {id, status} =this.props.match.params;
        const {reasonParams} = this.state;
        let params = {
            id: id,
            shelvesType: status
        }
        reasonParams.id = id;
        this.setState({
            params:params,
            reasonParams
        },()=>{
            this.getResourceDetail()
            this.getShelvesReason()
        })
    }

    async getResourceDetail() {
        let {params} = this.state;
        const resourceDetails = await getResourceDetail(params)
        console.log(resourceDetails,'resourceDetails')
        this.setState({
            fileId:  resourceDetails.transInfo && resourceDetails.transInfo.transId ? resourceDetails.transInfo.transId : resourceDetails.fileId,
            resourceDetails: resourceDetails
        })
    }

    async getShelvesReason() {
        let {reasonParams} = this.state;
        const shelvesReasonList = await getShelvesReason(reasonParams)
        console.log(shelvesReasonList, 'shelvesReasonList')
        this.setState({
            shelvesReasonList: shelvesReasonList.data,
            total: shelvesReasonList.total
        })
    }

    //向上共享
    shareResource=()=>{
        let {params} = this.state;
        shareResource([params.id]).then(res=>{
            return message.warning("已成功共享，请等待审核结果！")
        })
    };

    //上下架
    batchSure=()=>{
        let {params} = this.state;
        // 当前资源是下架 进行上架操作
        if(params.shelvesType == 0){
            let data = {
                ids: [params.id],
                shelvesType: 1
            };
            resourceShelfOperate(data).then(res => {
                if(res.code === 0){
                    message.success("上架成功！")
                    this.props.history.goBack()
                }
            })
        }else{
            this.setState({
                lookVisible: true,
            });
        }
    };

    lookCancel =()=>{
        this.setState({
            lookVisible: false,
            ids: []
        }, () =>  this.props.history.goBack())
    }

    lookClose = () => {
        this.setState({lookVisible: false})
    };

    getNext = () => {
        let {reasonParams} = this.state;
        reasonParams.pageNum++
        this.setState({reasonParams},()=>this.getShelvesReason())
    };

    render() {
        const {resourceDetails, params, reasonParams, shelvesReasonList, total, fileId} = this.state
        return (
            <div className='trans_box'>
                <div className="header bg_fff">
                    <div className="back" onClick={()=>this.goBack()}>
                        <img alt="" src={require("@/assets/img/grounding/back.png")} className="back-icon"/>
                        返回
                    </div>
                    <div className='title'>
                        <h2>{resourceDetails.resourceName}</h2>
                    </div>
                </div>
                <div className="show_box">
                    <div className="left">
                        <div className="watch">
                            {/* resourceType   文件类型 1,"文档",2,"视频",3,"音频" 4,"图片"
                                 resourceContent     txt文件值
                                fileId             文件ID
                                pageSize           pdf文档默认显示几页 不传 默认为 2
                                startTime          视频默认第几秒开始  不传 默认为 0 */}
                            {/* txet文档ID  733566D96F8D128256C6B7D1535919717FFE3ADDBBB4BF31F5CE1EFD585C2AD9
                                doc文档ID   bd23f32efc5149e68d3db315d4d65dcb
                                视频ID  1E95B3EFBB161B8FB63CABA2B9F8C90B88FFDC476F21142D5676AC307BF501E0
                                音频ID  B3D00837947C4079CD027A82723AD39FE1E801C34A9EB974363211B1BA3A07FA
                            */}
                            <FilePreview pageSize={1} startTime={0} fileId={fileId} resourceType={resourceDetails.resourceType} resourceContent={resourceDetails.resourceContent}/>
                        </div>
                        <div className="watch_bottom bg_fff">
                            <Col span={15}>
                                {/* <span>
                                    🐖
                            </span>*/}
                                <img className='iconphoto' src={require('@/assets/img/iconphoto.png')}/>
                                <span>
                                    上传人所属机构:{resourceDetails.unitName}  上传人:{resourceDetails.createName} 丨 {resourceDetails.shelvesTime} {resourceDetails.shelfStatus == 1 ? "上架": "下架"}
                            </span>
                            </Col>
                            <Col className='end' span={9}>
                                <span style={{ marginRight: '36px' }}>
                                    {/*<b>🐕</b>*/}
                                    <span>浏览量：{resourceDetails.browseNum}</span>
                                </span>
                                <span>
                                    {/*<b>🐕</b>*/}
                                    <span>收藏量：{resourceDetails.collectNum}</span>
                                </span>
                            </Col>
                        </div>
                    </div>
                    <div className="right bg_fff">
                        <div className="right_top">
                            <div className="title">
                                <h2>{resourceDetails.resourceName}</h2>
                            </div>
                            <div className='auth'>
                                {/*  <img src={`/api/media/api/v1/media/showImage/${detailData.coverFileId}`} alt="" />*/}
                                <img src={resourceDetails.authorImg} alt="" />
                                <span>
                                    作者：{resourceDetails.authorName ? resourceDetails.authorName: "未知"}
                            </span>
                            </div>
                            <div className="affiliation">
                                职校分类：<span className={!resourceDetails.stageName?"":"affiname"}>{resourceDetails.stageName}</span><span className={!resourceDetails.bigMajorName?"":"affiname"}>{resourceDetails.bigMajorName}</span><span>{resourceDetails.medMajorName}</span>
                            </div>
                            {resourceDetails.resourceHotSeniorName ? <div className='tag'>
                                <span>{resourceDetails.resourceHotSeniorName}</span>
                            </div>: ''}
                        </div>
                        <div className="right_bottom">
                            <h2>一句话介绍</h2>
                            <p>
                                {resourceDetails.simpleDesc}
                            </p>
                        </div>
                        {params.shelvesType == 0?
                        <div className="right_bottom">
                            <h2>下架原因   <button className='morebtn' disabled={!(total > reasonParams.pageNum * reasonParams.pageSize)} onClick={()=>this.getNext()}>下一批</button></h2>
                            <div className="xiajia_box">
                                {shelvesReasonList && shelvesReasonList.length>0 ?
                                    shelvesReasonList.map((item, index)=>(
                                        <div className="xiajia_cont" key={index}>
                                            <p className="xiajia_header">
                                                <span className="xiajia_time">时间：{item.operationTime}</span>
                                                <span className="xiajia_name">操作人：{item.shelvesUserName}</span>
                                            </p>
                                            <p className="xiajia_desc">{item.shelvesReason}</p>
                                        </div>
                                    )
                                ): ""}

                            </div>
                        </div>:""}
                    </div>
                </div>
                <div className="action_bttom pos_f bg_fff">
                    <div className='action'>
                        {/*{params.shelvesType == 1?<Button className='ant_blue' onClick={() => this.shareResource()}>向上共享</Button>:""}*/}
                        <Button className={params.shelvesType == 1?"blue":"ant_blue"} onClick={()=>{this.batchSure()}}>{params.shelvesType == 1? "下架资源": "上架资源"}</Button>
                        <Button className='blue' onClick={() => this.goBack()}>返回</Button>
                    </div>
                </div>
                <OffShelf
                    ids={[params.id]}
                    visible={this.state.lookVisible}
                    checkCancel={()=>this.lookClose()}
                    refreshData={()=>this.lookCancel()}
                    wrappedComponentRef={form => {
                        this.offShelfForm = form;
                    }}
                />
            </div>
        )
    }
}
resourceDetail = connect(mapStateToProps, mapDispatchToProps)(resourceDetail);
export default resourceDetail;
