import React, { Component } from "react";

import {
  Button,
  Input,
  Table,
  Modal,
  Form,
  Upload,
  Drawer,
  Select,
  Empty,
  Checkbox,
  Spin,
  message,
  Tooltip
} from "antd";

import MaxLengthInput from "../../../components/hhinput";

import {
  Sou,
  Yichang,
  Word,
  Xiazai,
  LodingBase64,
  HuiXian,
  SouT,
  Shanchuicon,
  ChengGongicon,

} from "../../../components/shixunsvg1.20/index";
import { uploadFile } from "../../../api/ali";
import {
  cvecequipmentsoftwarelist,
  cvecequipmentsoftwareaddSoft,
  cvecequipmentsoftwaregetInfoById,
  cvecequipmentsoftwaredelSoft,
  cvecequipmentsoftwareupdateById,
  createAddressPassword,
  getUserInfo,
  sendDownloadPage,
  pageListdara,
} from "../../../api/shixun2";
import "./index.scss";
const { Option } = Select;

export class index extends Component {

  state = {

    delete: false,//删除,

    addvisible: false,//添加
    operation: false,//操作记录
    visible: false,//查看抽屉
    __chakan: {},
    visibleEcho: false,//第一个弹框 回显

    visibleEchoTWO: false, //第2个弹框 回显
    isup: 'add',
    Installation_anzhuangbao: false,//第三个弹框   安装包 手机号 
    isOneOpen: true,//是否第一次打开，还没有搜索
    InputSearchValue: "",//搜索框的值
    dataSourceTable: [],//操作的table
    dataSourceTableLoding: true,//操作的lodigm
    dataSource: [],//大表格
    curInstallation: {},  //某一行数据
    dayNum: undefined,
    addressPassword: {}, //地址和密码
    teacherInfo: null, //老师信息
    isSendNode: false,
    dataSourceLogin: true,//大表戈loging
    AddUploadres: {},//存放上传的文件
    isUploadresEnd: false,//存放上传的文件的上产是否完成
    // 操作记录
    pageNum: 1,
    pageSize: 10,
    total: 0,
    Inputbox: '',//输入框   
  }
  // 操作记录
  operationModal = () => {
    this.getDataList({
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    })
    this.setState({
      operation: true,
    });
  };

  operationOk = e => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };

  operationCancel = e => {
    console.log(e);
    this.setState({
      operation: false,
    });
  };
  // 复选框
  onChange_check = (e) => {
    this.setState({
      isSendNode: e.target.checked
    })
  }

  // 回显的下拉框
  onChange = (value) => {
    this.setState({
      dayNum: value,
    });
  };
  // 第3个弹框  安装包
  Installation_anzhuangbaoModal = () => {
    this.setState({
      visibleEchoTWO: false,
      Installation_anzhuangbao: true,
    });
  };

  Installation_anzhuangbaoOk = async () => {
    const {
      dayNum,
      addressPassword,
      isSendNode,
      teacherInfo,
      curInstallation,
    } = this.state;
    if (!teacherInfo) {
      message.warning("没有老师信息");
      return;
    }
    await sendDownloadPage({
      downloadId: addressPassword.downloadId,
      dayNum,
      downloadPageUrl: addressPassword.address,
      password: addressPassword.password,
      isSendNode: isSendNode ? 2 : 1,
      receiveOrgName: teacherInfo.enterpriseName,
      receivePhone: teacherInfo.telNum,
      // receiveUserId
      receiveUserId: teacherInfo.userId,
      receiveUserName: teacherInfo.userName,
      softwareId: curInstallation.softwareId,
    });
    this.setState({
      Installation_anzhuangbao: false,
    });
    message.success('提交成功');
  };
  Installation_anzhuangbaoCancel = (e) => {
    console.log(e);
    this.setState({
      Installation_anzhuangbao: false,
    });
  };

  //第E2个弹框 回显
  visibleEchoTWOModal = async () => {
    const { curInstallation, dayNum } = this.state;
    if (!dayNum) {
      message.warning("有效时间不能为空");
      return;
    }
    const res = await createAddressPassword({
      dayNum,
      softwareId: curInstallation.softwareId,
    });
    this.setState({
      visibleEchoTWO: true,
      visibleEcho: false,
      addressPassword: res || {},
    });
  };

  visibleEchoTWOOk = (e) => {
    console.log(e);
    this.setState({
      visibleEchoTWO: false,
    });
  };
  visibleEchoTWOCancel = (e) => {
    console.log(e);
    this.setState({
      visibleEchoTWO: false,
    });
  };

  //第一个弹框 回显
  visibleEchoModal = (record) => {
    this.setState({
      visibleEcho: true,
      curInstallation: record,
      // Installation_anzhuangbao: true
    });
  };

  visibleEchoOk = (e) => {
    console.log(e);
    this.setState({
      visibleEcho: false,
    });
  };

  visibleEchoCancel = (e) => {
    console.log(e);
    this.setState({
      visibleEcho: false,
    });
  };

  // 查看抽屉

  showDrawer = async (v) => {
    const d = await cvecequipmentsoftwaregetInfoById({
      softwareId: v.softwareId,
    });

    this.setState({
      visible: true,
      __chakan: d,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // 添加

  addvisibleModal = () => {
    if (this.state.isup === "add") {
      this.setState(() => {
        return {
          addvisible: true,
        };
      });
    } else if (this.state.isup === "edit") {
      this.setState(() => {
        return {
          addvisible: true,
        };
      });
    }
  };

  addvisibleOk = (e) => {
    this.props.form.setFieldsValue({
      softwareDownloadUrl: this.state.AddUploadres,
    });
    e.preventDefault();
    if (this.state.isup === "add") {
      this.props.form.validateFields(
        [
          "softwareName",
          "softwareVersion",
          "softwareEnvironment",
          "softwareRange",
          "softwareExplain",
          "softwareDownloadUrl",
        ],
        async (err, values) => {
          const {
            AddUploadres: { originFileName, fileSize, mediaType, fpName },
          } = this.state;
          if (!err) {
            if(!fpName){
            return  this.mesSuccess('请上传安装包')
 
            }
            const { code } = await cvecequipmentsoftwareaddSoft({
              ...values,
              softwareSize: fileSize,
              softwareType: mediaType,
              softwareLoadOriginName: originFileName,
              softwareDownloadUrl: fpName,
            });
            if (code === 0) {
              this.getData();
              message.success("添加成功");
            } else {
              message.warning("添加失败");
            }

            this.setState(() => {
              return {
                addvisible: false,
                AddUploadres: null,
                // AddUploadres:{},
              };
            });
          }
        }
      );
      // 编辑
    } else {
      e.preventDefault();
      
      this.props.form.validateFields(
        [
          "softwareName",
          "softwareVersion",
          "softwareEnvironment",
          "softwareRange",
          "softwareExplain",
          "softwareDownloadUrl",
          
        ],
        async (err, values) => {
           const len= Object.keys(values.softwareDownloadUrl).length
           if(!len){
           return  this.mesSuccess('请上传安装包')

           }

          const {
            AddUploadres: {
              originFileName,
              fileSize,
              mediaType,
              fpName,
              
            },
            __chakan: {
              softwareId,
            }
          } = this.state;
          if (!err) { 
            const { code } = await cvecequipmentsoftwareupdateById({
              ...values,
              softwareSize: fileSize+"",
              softwareType: mediaType,
              softwareLoadOriginName: originFileName,
              softwareDownloadUrl: fpName,
              softwareId: softwareId,
            });
            if (code === 0) {
              this.getData();
              message.success("修改成功");
            } else {
              message.warning("修改失败");
            }
            this.setState(() => {
              return {
                addvisible: false,
                AddUploadres: {},
              };
            });
          }
        }
      );
    }
  };
  async componentDidMount() {
    this.getData();
    // this.getDataList();
  }
  getData = async () => {
    const { Inputbox } = this.state;
    const _data = await cvecequipmentsoftwarelist({
      softwareNameLike: Inputbox
    });

    this.setState(() => {
      return {
        dataSource: _data,
        dataSourceLogin: false,
      };
    });
  }
  getDataList() {
    return pageListdara({
      operateType: 4,
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    }).then(res => {
      if (res && res.data) {
        this.setState(() => {
          return {
            dataSourceTable: res.data,
            total: res.total,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            dataSourceTableLoding: false
          }
        })
      } else {
        message.info('数据错误')
      }
    })

  }
  //pageSize变化
  onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize, ":sdd")
    this.setState({
      pageNum: current,
      pageSize: pageSize,
    }, () => {
      this.getDataList({
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      });
    }
    );
  };

  //pageNum变化
  onPageNumChange = (current, pageSize) => {
    console.log(current, pageSize, ":sdd")
    this.setState({
      pageNum: current,
    }, () => {
      this.getDataList({
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      });
    }
    );
  }
  //搜索框
  searchVlueChange = (e) => {
    const val = e.target.value;
    this.setState(() => {
      return {
        InputSearchValue: val,
      };
    });
  };

  //回车搜索
  searchEnter = async () => {
    const { InputSearchValue } = this.state;
    //调接口
    const res = await getUserInfo({
      telNum: InputSearchValue,
    });
    //返回值
    if (res?.code === 0) {
      this.setState(() => {
        return {
          isOneOpen: false,
          teacherInfo: null,
        };
      });
    } else {
      this.setState(() => {
        return {
          isOneOpen: false,
          teacherInfo: res,
        };
      });
    }
  };

  addvisibleCancel = (e) => {
    this.props.form.resetFields();
    this.setState({
      addvisible: false,
      AddUploadres: null,
    });
  };
  // 删除Model

  deleteModal = () => {
    this.setState({
      delete: true,
    });
  };

  deleteOk = async (e) => {
    const {
      curdeleteData: { softwareId },
    } = this.state;

    const { code } = await cvecequipmentsoftwaredelSoft({
      softwareId,
    });
    if (code === 0) {
      message.success("删除成功");
    } else {
      message.warning("删除失败");
    }
    this.setState(
      {
        delete: false,
        curdeleteData: null,
      },
      () => {
        this.getData();
      }
    );
  };

  deleteCancel = (e) => {
    console.log(e);
    this.setState({
      delete: false,
      curdeleteData: null,
    });
  };
  //上传图片
  changeLoad = async (file) => {

    this.setState(() => {
      return {
        isUploadresEnd: true,
      };
    });
    try {
      const fromdata = new FormData();
      fromdata.append("file", file);
      fromdata.append("folder", "ab");

      uploadFile(fromdata).then((res) => {
        if (res) {
          this.setState(() => {
            return {
              AddUploadres: res,
              isUploadresEnd: false,
            };
          });
        } else {
          console.log(res);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  //下载wenjain
  dowloadFile = async ({ softwareDownloadUrl }) => {
    const link = document.createElement("a");
    link.href = window.$$imgSrc(softwareDownloadUrl);
    link.setAttribute("download", `titleType.exe`);
    link.click();
  };
  //删除文件
  deleteUpres = (upres) => {
    this.setState(() => {
      return {
        AddUploadres: {},
      };
    });
  };
TQ=(v)=>{
  this.props.history.push(
    `/main/PracticalTraining/installationXiang?softwareId=${v.softwareId}`
  );
}


  render() {
    const { getFieldDecorator } = this.props.form;
    const { dataSource, dataSourceTable, __chakan } = this.state;
    const { RenderButton } = React;
    const formItemLayout_aaaa = {
      labelCol: {
        xs: { span: 2 },

        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 2 },
        sm: { span: 12 },
      },

      // klgfjkfdljg
    };
    const columnsTable = [
      {
        title: "操作人",
        dataIndex: "account",
        key: "name",
      },
      {
        title: "初始安装管理",
        dataIndex: "operationDesc",
        key: "age",
      },
      {
        title: "操作时间",
        dataIndex: "createTime",
        key: "address",
      },
      {
        title: "动作",
        dataIndex: "actionDscp",
        key: "address",
      },
    ].map((i) => {
      i.align = "center"
      return i
    });

    // Table
    const columns = [
      {
        title: "安装包名称",
        dataIndex: "softwareName",
        key: "softwareName",

        align: "center",
        width: 230,
        render: (a, b, c) => {
          // debugger
          return <div >


            <Tooltip title={b.softwareName} placement="bottom" overlayClassName="TableTooltip">


              <span>{b.softwareName && b.softwareName.length > 15 ? b.softwareName.substring(0, 16) + '...' : b.softwareName}</span>


            </Tooltip> </div>

        }
      }
      ,
      {
        title: "文件类型",
        dataIndex: "softwareType",
        key: "softwareType",
        width: 230,
        align: "center",
      },

      {
        title: "版本号",
        dataIndex: "softwareVersion",
        key: "softwareVersion",
        width: 230,
        align: "center",
      },
      {

        
        title: "适配系统",
        dataIndex: "softwareEnvironment",
        key: "softwareEnvironment",

        width: 230,
        align: "center",
        render: (a, b, c) => <div >

          <Tooltip title={b.softwareEnvironment} placement="bottom" overlayClassName="TableTooltip">
            <span>{b.softwareEnvironment && b.softwareEnvironment.length > 15 ? b.softwareEnvironment.substring(0, 15) + '...' : b.softwareEnvironment}</span>

          </Tooltip>
        </div>

      },
      {
        width: 230,
        title: "资源大小",
        dataIndex: "softwareSizeFormat",
        key: "softwareSizeFormat",
        align: "center",
      },
      {
        title: "操作",
        align: "center",
        width: 350,
        fixed: "right",
        render: (v, b, value) => {
          return (
            <div className="action vertical_j" style={{ cursor: "pointer" }}>
              {/* <RenderButton id=""></RenderButton> */}
              <RenderButton id="ABB-01-A04-b_btnExtractRecord">
                <span
                  onClick={() => {
                  this.TQ(v)
                  }}
                >
                  提取记录
                </span>
              </RenderButton>
              <RenderButton id="ABB-01-A04-b_btnView">
                <span onClick={() => this.showDrawer(v)}>查看</span></RenderButton>
              <RenderButton id="ABB-01-A04-b_btnEdit">
                <span
                  onClick={async () => {
                    const d = await cvecequipmentsoftwaregetInfoById({
                      softwareId: v.softwareId,
                    });

                    this.setState(
                      () => {
                        return {
                          isup: "edit",
                          __chakan: {
                            // originFileName: d?.softwareLoadOriginName,
                            // mediaType: d?.softwareType,
                            // fpName: d?.softwareDownloadUrl,
                            // fileSize: d?.softwareSize,
                            // fileName: d?.softwareName,
                            softwareId: d?.softwareId,
                          },
                          AddUploadres: {
                            originFileName: d?.softwareLoadOriginName,
                            mediaType: d?.softwareType,
                            fpName: d?.softwareDownloadUrl,
                            fileSize: d?.softwareSize,
                            fileName: d?.softwareName,
                            softwareId: d?.softwareId,
                          },
                        };
                      },
                      () => {
                        this.addvisibleModal();
                        this.props.form.setFieldsValue({
                          softwareEnvironment: v.softwareEnvironment,
                          softwareExplain: d.softwareExplain,
                          softwareId: d.softwareId,
                          softwareLoadOriginName: d.softwareLoadOriginName,
                          softwareName: d.softwareName,
                          softwareRange: d.softwareRange,
                          softwareSize: d.softwareSize,
                          softwareType: d.softwareType,
                          softwareVersion: d.softwareVersion,
                        });
                      }
                    );
                  }}
                >
                  修改
                </span>
              </RenderButton>
              <RenderButton id="ABB-01-A04-b_btnDel">
                <span
                  onClick={() => {
                    this.setState(
                      () => {
                        return {
                          curdeleteData: v,
                        };
                      },
                      () => {
                        this.deleteModal();
                      }
                    );
                  }}
                >
                  删除
                </span>
              </RenderButton>
              <RenderButton id="ABB-01-A04-b_btnCreateLinks">  <span onClick={() => this.visibleEchoModal(v)}>创建链接</span></RenderButton>

            </div>
          );
        },
      },
    ];

    return (
      <div className="installation_management">
        <div className="installation_management_top">
          <div>
            <RenderButton id="ABB-01-A04-b_btnAdd">
              <Button
                type="primary"
                onClick={() => {
                  this.setState(
                    () => {
                      return {
                        isup: "add",
                      };
                    },
                    () => {
                      this.addvisibleModal();
                    }
                  );
                }}
              >
                添加
              </Button>
            </RenderButton>
            {/* <Button
              type="primary"
              onClick={() => {
                this.setState(
                  () => {
                    return {
                      isup: "add",
                    };
                  },
                  () => {
                    this.addvisibleModal();
                  }
                );
              }}
            >
              添加
            </Button> */}
            <Button style={{ marginRight: 12 }} onClick={this.operationModal}>
              操作记录
            </Button>
          </div>
          <div>
            <span>
              <Sou></Sou>
              <Input
                placeholder="安装包名称"
                style={{ position: "relative", width: 230, paddingLeft: 27 }}
                value={this.state.Inputbox}
                onChange={value => {
                  this.setState({
                    Inputbox: value.target.value

                  })
                  console.log(value, 'vvv')
                }}
                onPressEnter={this.getData}
              />
            </span>{" "}
            <span>共{this.state.dataSource?.length}个安装包</span>
          </div>
        </div>

        <div className="installation_management_buttom">
          <Table

            dataSource={dataSource}
            columns={columns}
            rowKey="softwareId"
            loading={this.state.dataSourceLogin}
            pagination={false}
            scroll={{ y: "calc(100vh - 320px)", x: "calc(100% - 320px)" }}
          />
        </div>
        {/* 操作记录 */}
        <Modal
          title={
            <div style={{ fontWeight: 'bold', color: "#333", fontSize: 18 }}>
              操作记录
            </div>
          }
          visible={this.state.operation}
          onCancel={this.operationCancel}
          bodyStyle={{ height: 500 }}
          width={1000}
          centered
          closable={false}
          wrapClassName="installation_elemt"
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={this.operationOk}
              style={{ width: 90, height: 38 }}
            >
              我知道了
            </Button>,
          ]}
        >
          <div>
            <Table
              dataSource={dataSourceTable}

              columns={columnsTable}
              scroll={{ y: 350 }}
              loading={this.state.dataSourceTableLoding}

              pagination={
                {
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '30'],
                  showSizeChanger: true,
                  onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                  onChange: (a, b) => this.onPageNumChange(a, b),
                  current: this.state.pageNum,
                  pageSize: this.state.pageSize,
                  total: this.state.total,
                }
              }
            />
            ;
          </div>
        </Modal>
        {/* 删除 */}
        <Modal
          title={
            <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
              删除安装文件
            </div>
          }
          visible={this.state.delete}
          onOk={this.deleteOk}
          onCancel={this.deleteCancel}
          bodyStyle={{ height: 130 }}
          width={450}
          centered
          closable={false}
          wrapClassName="details_delete_shi"
        >
          <div>
            <p>
              <span>
                <Yichang></Yichang>
              </span>
              <span>您确定要删除
                
              {
                  
                  dataSource.find((v, i) => {
                      return v.softwareId 
                  })?.softwareName
              


            }
                
                安装文件么？</span>
            </p>
          </div>
        </Modal>
        <Modal
          title={
            <div style={{ fontSize: 18, color: "#333", fontWeight: 500 }}>
              {this.state.isup === "add" ? "添加安装包" : "修改安装包"}
            </div>
          }
          visible={this.state.addvisible}
          onOk={this.addvisibleOk}
          onCancel={this.addvisibleCancel}
          bodyStyle={{ height: 660 }}
          width={660}
          centered
          centered={true}
          destroyOnClose
          wrapClassName="Device_information_instllation"
        >
          <Form {...formItemLayout_aaaa}>
            <Form.Item label="安装包">
              {getFieldDecorator("softwareName", {
                rules: [{ required: true, message: "请输入安装包名称" }],
              })(
                <MaxLengthInput
                  placeholder="请输入安装包名称"
                  style={{ height: 40, paddingRight: 50 }}
                  maxLength={30}
                ></MaxLengthInput>
              )}
            </Form.Item>
            <Form.Item label="版本号">
              {getFieldDecorator("softwareVersion", {
                rules: [{ required: true, message: "请输入版本号" }],
              })(
                <MaxLengthInput
                  placeholder="请输入版本号"
                  style={{ height: 40, paddingRight: 50 }}
                  maxLength={10}
                ></MaxLengthInput>
              )}
            </Form.Item>

            <Form.Item label="适配系统">
              {getFieldDecorator("softwareEnvironment", {
                rules: [{ required: true, message: "请输入适配系统" }],
              })(
                <MaxLengthInput
                  placeholder="请输入适配系统"
                  style={{ height: 40, paddingRight: 57 }}
                  maxLength={100}
                ></MaxLengthInput>
              )}
            </Form.Item>

            <Form.Item label="适用场景">
              {getFieldDecorator("softwareRange", {
                rules: [{ required: true, message: "请输入适用场景" }],
              })(
                <MaxLengthInput
                  placeholder="请输入适用场景"
                  type={"textarea"}
                  isTextarea={true}
                  style={{
                    height: 100,
                    paddingRight: 50,
                    resize: "none",
                  }}
                  maxLength={300}
                ></MaxLengthInput>
              )}
            </Form.Item>

            <Form.Item label="备注说明">
              {getFieldDecorator("softwareExplain", {
                rules: [{ required: true, message: "请输入备注说明" }],
              })(
                <MaxLengthInput
                  placeholder="请输入备注说明"
                  type={"textarea"}
                  isTextarea={true}
                  style={{ height: 100, paddingRight: 50, resize: "none" }}
                  maxLength={300}
                ></MaxLengthInput>
              )}
            </Form.Item>
            <Form.Item label="上传安装包">
              {getFieldDecorator("softwareDownloadUrl", {
                rules: [{ required: true, message: "请上传安装包" }],
              })(
                <>
                  {Object.keys(this.state.AddUploadres || {}).length === 0 &&
                    !this.state.isUploadresEnd ? (
                    <div className="opload_l">
                      <span>
                        {" "}
                        <img
                        alt=''
                          src={window.$$img(
                            "cvec-space-front-geren/zhihuijiaoxue/uploadimg.png"
                          )}
                        ></img>
                      </span>

                      <Upload
                        accept="*"
                        showUploadList={false}
                        beforeUpload={(file) => this.changeLoad(file)}
                      >
                        <span>上传文件</span>
                      </Upload>
                    </div>
                  ) : this.state.isUploadresEnd ? (
                    <Spin
                      spinning
                      indicator={
                        <>
                          {" "}
                          <LodingBase64 />
                          &emsp; 正在上传中...
                        </>
                      }
                    ></Spin>
                  ) : (
                    <div
                      className="res_ups"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ChengGongicon></ChengGongicon> &emsp;{" "}
                      {this.state.AddUploadres.originFileName}&emsp;
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.deleteUpres(this.state.AddUploadres);
                        }}
                      >
                        {" "}
                        <Shanchuicon></Shanchuicon>
                      </span>
                    </div>
                  )}
                </>
              )}
            </Form.Item>
          </Form>
        </Modal>
        <div>
          <Drawer
            title="安装包详情"
            placement="right"
            closable={false}
            // onClose={this.onClose}
            visible={this.state.visible}
            width={600}
            className="information_tion"
          >
            <p>
              <span>安装包名称:</span>
              <span>{__chakan.softwareName}</span>
            </p>
            <p>
              <span>文件类型:</span>
              <span>{__chakan.softwareType}</span>
            </p>

            <p>
              <span>版本号:</span>
              <span>{__chakan.softwareVersion}</span>
            </p>

            <p style={{ display: "flex" }}>
              <span>适配系统:</span>
              <span>
                <span >{__chakan.softwareEnvironment}</span></span>
            </p>

            <p style={{ display: "flex" }}>
              <span>适用场景:</span>
              <span className="parameters">
                <span>{__chakan.softwareRange}</span>
              </span>
            </p>

            <p style={{ display: "flex" }}>
              <span>使用说明:</span>
              <span className="parameters">
                <span>{__chakan.softwareExplain}</span>
              </span>
            </p>

            <p style={{ display: "flex" }}>
              <span>上传安装包:</span>
              <span
                onClick={() => {
                  this.dowloadFile(__chakan);
                }}
                style={{ display: "flex", cursor: "pointer" }}
              >
                <Word />

                <span style={{ marginLeft: 10 }}>
                  {__chakan.softwareLoadOriginName}
                </span>
                <span>
                  <span style={{ marginLeft: 10 }}>
                    <Xiazai />
                  </span>
                  <span style={{ marginLeft: 10 }}>下载</span>
                </span>
              </span>
            </p>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "right",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
              }}
            >
              <Button
                onClick={this.onClose}
                type="primary"
                style={{
                  width: 90,
                  height: 40,
                  position: "relative",
                  right: 400,
                }}
              >
                我知道了
              </Button>
            </div>
          </Drawer>
        </div>
        <Modal
          title={
            <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
              {/* 展示安装包名称 */}
              {
                  dataSource.find((v,i)=>{
                
                    return v.softwareId===this.state.curInstallation.softwareId
               
                  })?.softwareName
              
                }
           
            </div>
          }
          visible={this.state.visibleEcho}
          // onOk={this.visibleEchoOk}

          // onCancel={this.visibleEchoCancel}
destroyOnClose
          bodyStyle={{ height: 250 }}
          width={660}
          centered
          closable={false}
          footer={null}
          wrapClassName="details_delete_Ecoh"
        >
          <div>
            <p>
              <HuiXian />
            </p>
            <p style={{ marginTop: 20, paddingLeft: 42 }}>
              <span style={{ color: "#333", fontSize: 14 }}>链接有效期</span>
              <span>
                <Select
                  style={{ width: 330, marginLeft: 20 }}
                  placeholder="请选择有效期"
                  onChange={this.onChange}
                  value={this.state.dayNum}
                >
                  <Option value="1">1天</Option>
                  <Option value="3">3天</Option>
                  <Option value="7">7天</Option>
                </Select>
              </span>
            </p>

            <div
              style={{
                position: "absolute",

                bottom: 0,

                width: "100%",

                borderTop: "1px solid #e8e8e8",

                padding: "10px 16px",

                textAlign: "right",

                left: 0,

                background: "#fff",

                borderRadius: "0 0 4px 4px",
              }}
            >
              <Button
                onClick={this.visibleEchoCancel}
                style={{ width: 98, height: 40, marginRight: 20 }}
              >
                取消
              </Button>

              <Button
                onClick={this.visibleEchoTWOModal}
                type="primary"
                style={{ width: 128, height: 40 }}
              >
                创建地址链接
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          title={
            <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
              {
                  dataSource.find((v,i)=>{
                
                    return v.softwareId===this.state.curInstallation.softwareId
               
                  })?.softwareName
              
                }
            </div>
          }
          visible={this.state.visibleEchoTWO}
          bodyStyle={{ height: 330 }}
          width={660}
          centered
          destroyOnClose
          closable={false}
          footer={null}
          wrapClassName="details_delete_Ecoh"
        >
          <div>
            <p>
              <HuiXian />
            </p>
            <p style={{ marginTop: 20, paddingLeft: 42 }}>
              <span
                style={{
                  color: "#333",
                  fontSize: 14,
                  width: 80,
                  display: "inline-block",
                  textAlign: "right",
                }}
              >
                链接有效期
              </span>

              <span>
                {" "}
                <Input
                  placeholder="请输入链接有效期"
                  disabled
                  value={this.state.addressPassword.address}
                  style={{ width: 347, height: 40, marginLeft: 10 }}
                />
              </span>
            </p>
            <p style={{ marginTop: 20, paddingLeft: 42 }}>
              <span
                style={{
                  color: "#333",
                  fontSize: 14,
                  width: 80,
                  display: "inline-block",
                  textAlign: "right",
                }}
              >
                提取密码
              </span>

              <span>
                {" "}
                <Input
                  placeholder="请输入提取密码"
                  disabled
                  value={this.state.addressPassword.password}
                  style={{ width: 347, height: 40, marginLeft: 10 }}
                />
              </span>
            </p>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "right",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
              }}
            >
              <Button
                onClick={this.visibleEchoTWOCancel}
                style={{ width: 98, height: 40, marginRight: 20 }}
              >
                取消
              </Button>

              <Button
                onClick={this.Installation_anzhuangbaoModal}
                type="primary"
                style={{ width: 128, height: 40 }}
              >
                发送链接及密码
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          title={
            <div className="title">
              <div
                style={{
                  fontWeight: 500,
                  color: "#333",
                  fontSize: 18,
                  lineHeight: "38px",
                }}
              >
                安装包使用者设置
              </div>
              <div>
                <span style={{ marginRight: 20 }}>手机号</span>
                <span>
                  <SouT></SouT>
                  <Input
                    placeholder="请输入手机号"
                    style={{
                      position: "relative",
                      width: 330,
                      paddingLeft: 27,
                    }}
                    onChange={this.searchVlueChange}
                    onPressEnter={this.searchEnter}
                  />
                </span>
              </div>
            </div>
          }
          visible={this.state.Installation_anzhuangbao}
          bodyStyle={{ height: 350 }}
          width={800}
          centered
          closable={false}
          destroyOnClose
          footer={null}
          wrapClassName="installation_anzhuangbao"
        >
          {this.state.isOneOpen && (
            <div>
              <Empty
                image={window.$$imgSrc(
                  "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg"
                )}
                description={"暂无内容"}
              />
            </div>
          )}

          {!this.state.isOneOpen && this.state.teacherInfo ? (
            <div>
              <div className="sou_dao_l">
                <div>
                  <div>
                    <span></span>
                    <span>使用者详情</span>{" "}
                  </div>
                  <div>
                    <span>
                      <img
                      alt=''
                        src={window.$$imgSrc(
                          this.state.teacherInfo.portraitId
                            ? this.state.teacherInfo.portraitId
                            : "base/image/227219bf6b344a098daf95bec40eb473.jpg_s.jpg"
                        )}
                      />
                    </span>
                    <span>
                      <span>
                        {this.state.teacherInfo.userName}-
                        {this.state.teacherInfo.enterpriseName}
                      </span>

                      <span>
                        <span>
                          部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门:
                        </span>
                        <span style={{ marginLeft: 5 }}>
                          {" "}
                          {this.state.teacherInfo.factoryName}-
                          {this.state.teacherInfo.positions}
                        </span>
                      </span>
                      <span>
                        <span>手机号码:</span>{" "}
                        <span style={{ marginLeft: 5 }}>
                          {this.state.teacherInfo.telNum}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : !this.state.isOneOpen ? (
            <div>
              <Empty
                image={window.$$imgSrc(
                  "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg"
                )}
                description={"没有找到您想要的内容"}
              />
            </div>
          ) : null}
          <div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "right",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="footer_wen">
                <span>
                  <Checkbox onChange={this.onChange_check}></Checkbox>
                </span>
                <span>
                  站内信通知的同时，向使用者手机发送手机短信通知提取地址和密码
                </span>
              </div>

              <div>
                <Button
                  onClick={this.Installation_anzhuangbaoCancel}
                  style={{ width: 98, height: 40, marginRight: 20 }}
                >
                  取消
                </Button>

                <Button
                  onClick={this.Installation_anzhuangbaoOk}
                  type="primary"
                  style={{ width: 108, height: 40 }}
                >
                  确定发送
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Form.create()(index);
