/*机构标签使用情况*/
import React, { Component } from 'react';
import {Form, Select, Button, Input, Table,Spin,Checkbox} from 'antd';
import './index.scss';
import RenderButton from "../../../../components/renderButton";
import {selectLabelUseEnterprise,deleteLabel} from "../../../../api/peixun/index";
import TopTitle from "../../../../components/BreadPeixun";
const { Option } = Select;

let jsonparam = null;
class UseAgency extends Component {
    state = {
        total:0,
        pageNum: 1,
        pageSize: 5,
        columns: [
            {title: '序号', dataIndex: 'index', key: 'index', align: 'center',width: 100,render: (text, item, index) => <span className='serial'>{index + 1}</span>,},
            {title: '在用机构', dataIndex: 'enterpriseName', key: '', align: 'center',},
            {title: '使用时间', dataIndex: 'createTime', key: '', align: 'center',}
        ],
        dataSource: [],
        tableLoading: false, // 是否显示加载中
        id:'',
        labelName:'',
    }
    componentDidMount() {
        //地址栏截取
        const data = this.props.location.search;
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        jsonparam = JSON.parse(codeParam);
        this.setState({
            id:jsonparam.id,
            labelName:jsonparam.labelName
        },()=>{
            this.selectenterpriseList()
        })
    }

    //查询机构列表
    selectenterpriseList = async () =>{
        const {labelName,pageNum,pageSize} = this.state
        let data = {
            name:labelName,
            pageNum: pageNum,
            pageSize: pageSize,
        }
        await selectLabelUseEnterprise(data).then(e=>{
            if(e){
                this.setState({
                    dataSource:e.data,
                    total:e.total,
                })
            }
        })
    }

    //分页切换
    changePage = () => {
    }
    deleteAgency = () => { // 删除提示
        this.hint({
            'title': '温馨提示',
            'content': '删除该标签后，将无法被其他机构使用。您确定要删除该标签吗？',
            'type': 'warning',
            'cancelText': '取消',
            'okText': '删除',
            'isCancel': true,
            'sure': this.delete
        })
    }
    //删除函数
    delete = async () => {
        const {id} = this.state
        let data = {
            id : id
        }
        await deleteLabel(data).then(e => {
            if(e){
                this.mesSuccess("删除成功", 2000)
                this.props.history.goBack()
            }else {
                this.mesError("删除失败", 2000)
            }
        })
    }
    render() {
        let { columns,total,pageSize,dataSource,pageNum,tableLoading,selectedRowKeys,labelName  } = this.state;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            onChange: this.changePage
        }
        const topTitleData = {
            span_1: "培训中心",
            span_2: "分类标签",
            span_3: "标签管理",
            span_4: "标签查看",
            span_1_c: "#333",
            span_2_c: "#333",
            span_3_c: "#333",
            container: "标签查看",
        };
        return (
          <div className='useAgency'>
              <TopTitle {...topTitleData} />
              <div className="in_school_content">
                  <div className='top'>
                      <div className="left_top">
                          标签名称：{labelName}
                      </div>
                      <div className="right_top">
                          <Button type="primary" className='ant-btn' onClick={()=>this.deleteAgency()}>删除标签</Button>
                      </div>
                  </div>
                  <div className='top'>
                      <div className="left">
                          在用机构列表
                      </div>
                      <div className="right">
                          共{total}条机构在用
                      </div>
                  </div>
                  <div className="bottomss">
                      {
                        tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                            <div className='all_wapper'>
                              <Table
                                  className='organ-table'
                                  columns={columns}
                                  dataSource={dataSource}
                                  pagination={pagination}
                                  locale={{
                                      emptyText: <>
                                          <div className="Empty-conet">
                                              <div className="img">
                                                  <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                                  <span>暂无数据</span>
                                              </div>
                                          </div>
                                      </>
                                  }}
                                 />
                            </div>
                        )
                      }
                  </div>
              </div>
          </div>
        )
    }
}

UseAgency = Form.create()(UseAgency);
export default UseAgency;
