
import reducers from './reducers'
let defaultState = {
    dataSource: [
        { id: 1, name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.' },
        { id: 2, name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.' },
        { id: 3, name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.' }
    ],
    columns: [
        { title: '专题名称', dataIndex: 'name', key: 'name' },
        { title: '更新时间', dataIndex: 'age', key: 'age' },
        {
            title: '操作', dataIndex: 'address', key: 'address',
            // render: () => <p className='action vertical_j'>
            //     <span>添加子专题</span>
            //     <span>编辑</span>
            //     <span>删除</span>
            // </p>
        }
    ],
    visibily: false,
    isDrab:false
}
const Label = (state = defaultState, action) => {
    let Newstate = JSON.parse(JSON.stringify(state))
    reducers[action.type] && reducers[action.type](Newstate, action)
    return Newstate
}

export default Label
