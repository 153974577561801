
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';

import { Layout } from 'antd';
import OBreadcrumb from '../../components/Breadcrumb'
import './index.scss'
import { Redirect } from 'react-router-dom';
import RouterView from '../../router/router_view';
import routerList from '@/router/router_config.js';
import Menu from '@/components/Menu';
const { Sider, Content } = Layout;

const mapStateToProps = ({ MAIN }) => {
    const { menuList } = MAIN;
    return {
        menuList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
}




export default connect(mapStateToProps, mapDispatchToProps)(class PractTrain extends React.Component {
    render() {
         let { menuList } = this.props;
        // const menuList = [{
        //     id: "574e880f7be345d48fac879e8fd774a5",
        //     menuName: "实训",
        //     parentId: "0",
        //     menuUrl: "/main/practTrain",
        //     children: [
        //         // {
        //         //     id: "a35f36b2df824fcd9453d21e8fcbe169",
        //         //     menuName: "实训配置管理",
        //         //     parentId: "574e880f7be345d48faQQQQQ79e8fd774a5",
        //         //     menuUrl: '/main/practTrain/configPage',
        //         //     children: [
        //         //
        //         //         {
        //         //             id: "f055dbe919124091bf586b2嗯嗯嗯7f30e2738",
        //         //             menuName: "实训配置管理",
        //         //             parentId: "a35f36b2df824fcd9453d21e8fcbe169",
        //         //             menuUrl: '/main/practTrain/configPage',
        //         //             children: [
        //         //
        //         //             ],
        //         //             createTime: "2021-01-05 14:18:54"
        //         //         }
        //         //     ],
        //         //     createTime: "2021-01-05 14:18:54"
        //         // },
        //         {
        //             id: "362dc75ada7848ce91ad7affff584e98fbd4",
        //             menuName: "实训硬件管理",
        //             parentId: "574e880f7be345d48fac879e8fd774a5",
        //             menuUrl: null,
        //             children: [
        //                 // {
        //                 //     id: "fe89ade74644EWswewec590f440634c2",
        //                 //     menuName: "硬件分类管理",
        //                 //     parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //                 //     menuUrl: "/main/practTrain/hardware_class",
        //                 //     children: [
        //                 //
        //                 //     ],
        //                 //     createTime: "2021-01-05 14:18:55"
        //                 // },
        //                 // {
        //                 //     id: "fe89ade74EWc590f440634c2",
        //                 //     menuName: "硬件设备管理",
        //                 //     parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //                 //     menuUrl: '/main/practTrain/hard_facility',
        //                 //     children: [
        //                 //
        //                 //     ],
        //                 //     createTime: "2021-01-05 14:18:55"
        //                 // },
        //                 {
        //                     id: "fe89ade74644REER0f440634c2",
        //                     menuName: "安装文件管理",
        //                     parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //                     menuUrl: '/main/practTrain/hard_file',
        //                     children: [
        //
        //                     ],
        //                     createTime: "2021-01-05 14:18:55"
        //                 },
        //                 // {
        //                 //     id: "fe89ade746444dafRQRE90f440634c2",
        //                 //     menuName: "追踪记录",
        //                 //     parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //                 //     menuUrl: '/main/practTrain/hard_record',
        //                 //     children: [
        //                 //
        //                 //     ],
        //                 //     createTime: "2021-01-05 14:18:55"
        //                 // },
        //
        //             ],
        //             createTime: "2021-01-05 14:18:55"
        //         },
        //         // {
        //         //     id: "362dc75ada7848ce9ewewww1ad7a584e98fbd4",
        //         //     menuName: "实训资源管理",
        //         //     parentId: "574e880f7be345d48fac879e8fd774a5",
        //         //     menuUrl: null,
        //         //     children: [
        //         //         {
        //         //             id: "fe89ade746444dafa34ceWWWweeweew590f440634c2",
        //         //             menuName: "资源分类管理",
        //         //             parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //         //             menuUrl: "/main/practTrain/course_class",
        //         //             children: [
        //         //
        //         //             ],
        //         //             createTime: "2021-01-05 14:18:55"
        //         //         },
        //         //         {
        //         //             id: "fe89ade746444dafa34ceweewXZZeew590f440634c2",
        //         //             menuName: "课程资源管理",
        //         //             parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //         //             menuUrl: "/main/practTrain/course-mg",
        //         //             children: [
        //         //
        //         //             ],
        //         //             createTime: "2021-01-05 14:18:55"
        //         //         },
        //         //         {
        //         //             id: "fe89ade746444dafaZXXZ34ceweeweew590f440634c2",
        //         //             menuName: "追踪日记记录",
        //         //             parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //         //             menuUrl: '/main/practTrain/course-log',
        //         //             children: [
        //         //
        //         //             ],
        //         //             createTime: "2021-01-05 14:18:55"
        //         //         },
        //         //
        //         //     ],
        //         //     createTime: "2021-01-05 14:18:55"
        //         // },
        //         {
        //             id: "362dc75ada7848ce91ad7wewea584e98fbd4",
        //             menuName: "申请资源管理",
        //             parentId: "574e880f7be345d48fac879e8fd774a5",
        //             menuUrl: null,
        //             children: [
        //                 {
        //                     id: "fe89ade746444dafa34c590f440634c2",
        //                     menuName: "资源下发申请",
        //                     parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //                     menuUrl: "/main/practTrain/applay-mg",
        //                     children: [
        //
        //                     ],
        //                     createTime: "2021-01-05 14:18:55"
        //                 },
        //
        //             ],
        //             createTime: "2021-01-05 14:18:55"
        //         },
        //         {
        //             id: "362dc75ada7848ce91ad7a5vds84e98fbd4",
        //             menuName: "实训硬件资源",
        //             parentId: "574e880f7be345d48fac879e8fd774a5",
        //             menuUrl: null,
        //             children: [
        //                 // {
        //                 //     id: "fe89ade74644QWWWWWW0f440634c2",
        //                 //     menuName: "硬件设备清单",
        //                 //     parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //                 //     menuUrl: '/main/practTrain/hardware-resource',
        //                 //     children: [
        //                 //
        //                 //     ],
        //                 //     createTime: "2021-01-05 14:18:55"
        //                 // },
        //                 {
        //                     id: "fe89ade746444QWQWQWQWQWQWQW440634c2",
        //                     menuName: "zSpace设备到平台注册",
        //                     parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //                     menuUrl:'/main/practTrain/hardware-regisrtry',
        //                     children: [
        //
        //                     ],
        //                     createTime: "2021-01-05 14:18:55"
        //                 },
        //
        //             ],
        //             createTime: "2021-01-05 14:18:55"
        //         },
        //         // {
        //         //     id: "362dc75ada7848dsvvvvvvce91ad7a584e98fbd4",
        //         //     menuName: "实训内容资源",
        //         //     parentId: "574e880f7be345d48fac879e8fd774a5",
        //         //     menuUrl: null,
        //         //     children: [
        //         //         {
        //         //             id: "fe89ade7464EWf440634c2",
        //         //             menuName: "虚拟仿真资源清单",
        //         //             parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //         //             menuUrl: '/main/practTrain/content-list',
        //         //             children: [
        //         //
        //         //             ],
        //         //             createTime: "2021-01-05 14:18:55"
        //         //         }, {
        //         //             id: "fe89ade74DSDSDS590f440634c2",
        //         //             menuName: "通用在线课程清单",
        //         //             parentId: "362dc75ada7848ce91ad7a584e98fbd4",
        //         //             menuUrl: '/main/practTrain/content-resource',
        //         //             children: [
        //         //
        //         //             ],
        //         //             createTime: "2021-01-05 14:18:55"
        //         //         },
        //         //
        //         //     ],
        //         //     createTime: "2021-01-05 14:18:55"
        //         // }
        //     ],
        //     createTime: "2021-01-05 14:18:54"
        // },]

        let path = this.props.location.pathname;
        let one = routerList.filter(e => e.component).find(v => path.includes(v.path)).children.find(j => path.includes(j.path));
        one.menuUrl = one.path;
        one.menuName = one.name;
        let two, three;
        menuList[0] && menuList[0].children.forEach(v => {
            v.children && v.children.forEach(k => {
                if (k.menuUrl === path) {
                    two = v;
                    three = k;
                }
            })
        })
        let arr = [one, two, three];
        let propsMenuList = menuList.find(v => v.menuName === "实训");
        propsMenuList = propsMenuList ? propsMenuList.children : [];
        let id = propsMenuList[0] && propsMenuList[0].id;
        return (
            localStorage.getItem('userInfo') ? <Layout className='resource_box'>
                <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
                    {/* <SiderLogo></SiderLogo> */}
                    {id ? <Menu {...this.props} openArr={[id]} menuList={propsMenuList} /> : null}
                </Sider>
                <Layout>
                    {/* <OHeader /> */}
                    <OBreadcrumb list={arr} ref={e => this.crumb = e} {...this.props} />
                    <Content style={{ margin: '10px 16px 0' }} className='layout-right'>
                        <RouterView routers={this.props.routers}></RouterView>
                    </Content>
                </Layout>
            </Layout> : <Redirect to='/login' />
        )
    }
})
