import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

const addStyle = {
    background: '#3e78ed',
    color: '#ffffff',
    border: 'solid 1px #3e78ed'
}
const delStyle = {
    color: '#3e78ed',
    cursor: 'pointer',
    fontSize: '14px'
}

function buttons(text, event) {                             //按钮集合
    return {
        'table_btn': <span {...event} style={delStyle}>{text}</span>,
        'sure_btn': <Button {...event} style={addStyle}>{text}</Button>,
        'cancel_btn': <Button {...event}>{text}</Button>
    }
}

/**
 * text 按钮的文字
 * id   按钮唯一id | 必传
 * type 按钮类型 对应buttons↑的key
 * children 使用自定义按钮
 * event 事件
 */
class RenderButton extends Component {
    render() {
        const { text, id, buttonRole, type, children, event } = this.props;
        if (!buttonRole.includes(id)) {                      //判断按钮权限中是否包含传过来的id
            return null;
        }
        if (buttons(text)[type]) {                          //判断type
            return buttons(text, event)[type]
        } else if (children) {                              //自定义按钮
            return children;
        } else {                                            //缺少参数
            console.error('RenderButton缺少必要参数');
            return null;
        }
    }
}

const mapStateToProps = ({ MAIN }) => {
    const { buttonRole } = MAIN;
    return {
        buttonRole
    }
}


RenderButton = connect(mapStateToProps)(RenderButton);
export default RenderButton;


