import React, { Component } from 'react'
// import './index.scss';
import { Form, Select } from 'antd';
import { roleSel, createUser, globalSel } from '@/api/member/index.js';
const { Option } = Select;

let pStyle = {
    color: '#E75301',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0
}

class index extends Component {
    state = {
        statusList: [],
        roleList: [],
        userNum: '',
        identityName: ''  //身份名称
    }
    //角色下拉
    getRoleSel = () => {
        roleSel().then(res => {
            if (res) {
                this.setState({ roleList: res });
            }
        })
    }
    //身份下拉
    getStatusSel = () => {
        globalSel({ type: 7 }).then(res => {
            if (res) {
                this.setState({ statusList: res })
            }
        })
    }
    componentDidMount() {
        this.getRoleSel();
        this.createUser();
        this.getStatusSel();
    }
    createUser = () => {
        createUser().then(res => {
            if (res) {
                this.setState({ userNum: res });
            }
        })
    }
    change = code => {
        let value = this.state.statusList.find(v => v.code === code);
        if (value) {
            this.setState({ identityName: value.name });
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let { statusList, roleList, userNum } = this.state;
        return (
            <div className="wrapper">
                <Form>
                    <Form.Item>
                        <div className='form_item'>
                            <span><span className='red' style={{ opacity: 0 }}>*</span>成员账号</span>
                            <span>{userNum}</span>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='form_item'>
                            <span><span className='red' style={{ opacity: 0 }}>*</span>账号密码</span>
                            <p style={{ marginRight: '22px', marginBottom: 0 }}>*******</p>
                            <p style={pStyle}><img src={require('@/assets/img/warning.png')} style={{ marginRight: '6px' }} alt='' />密码为用户手机尾号后6位</p>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='form_item'>
                            <span><span className='red'>*</span>设置身份</span>
                            {
                                getFieldDecorator('identityId', {
                                    initialValue: undefined,
                                    rules:[
                                        {
                                            required:true,
                                            message:'请选择成员身份'
                                        }
                                    ]
                                })(
                                    <Select onChange={this.change} placeholder='请选择成员身份'>
                                        {
                                            statusList.map(v => {
                                                return <Option value={v.code} key={v.code}>{v.name}</Option>
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className='form_item'>
                            <span><span className='red'>*</span>设置角色</span>
                            {
                                getFieldDecorator('roleId', {
                                    initialValue: undefined,
                                    rules:[
                                        {
                                            required:true,
                                            message:'请选择成员角色'
                                        }
                                    ]
                                })(
                                    <Select placeholder='请选择成员角色'>
                                        {
                                            roleList.map(v => {
                                                return <Option value={v.roleName} key={v.roleName}>{v.roleName}</Option>
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </div>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

index = Form.create()(index);

export default index;