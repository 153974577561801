
import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { AAA} from '@store/OBS/audit/action'
import { Switch } from 'antd';


const mapStateToProps = ({AUDIT}) => {
    return {
    
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    
    };
}




let Audit = props=>{
const {RenderButton} = React;
    return <div className='audit_box'>
    <div className="admin_box">
        <div className='item'>
            <h2>
                <div className="left">
                    <i></i>
                    <span>经济管理学院</span>
                </div>
                <div className="right">
                    <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked />
                </div>
            </h2>
            <p>
                *免审开关开启后，则需要到此处审核的资源全部 自动审核通过*
            </p>

        </div>
        <div className='item'>
            <h2>
                <div className="left">
                    <i></i>
                    <span>管理学院</span>
                </div>
                <div className="right">
                    <RenderButton
                        text='免审开关'
                        id="btnCheckSwitch"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                    >
                        <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked />
                    </RenderButton>
                </div>
                {/*<div className="right">*/}
                {/*    <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked />*/}
                {/*</div>*/}
            </h2>
            <p>
                *免审开关开启后，则需要到此处审核的资源全部 自动审核通过*
            </p>

        </div>
    </div>
</div>
}

export default connect(mapStateToProps, mapDispatchToProps)(Audit);