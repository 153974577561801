import React from 'react';
import {Button, Form, Input, message, Modal, Select, DatePicker, Radio, Table, Icon, Checkbox} from "antd";
import '../index.scss';
import {courseList} from '@/api/craftsman-app/index'
import TransferClassify from "../../components/transferclassify";

const {TextArea} = Input;
const {Option} = Select;
const {Search} = Input;
class addCourse extends React.Component {
    state = {
        data:{},
        transferVisible:false,  //弹窗
        pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            keyWord:null,
            pageSizeOptions: ["10", "20", "30", "100"], //默认分页
        },
        modalData:[
        ],
        selectedRowKeys:[]
    }
    componentDidMount() {

    }
    UNSAFE_componentWillReceiveProps(nextProps) {
    }

    //检索查询
    onSearchChange = () => {
        let pagination = this.state.pagination;
        pagination.pageNum = 1
        this.setState({
            pagination: pagination
        }, () => this.courseList());
    }

    //搜索条件修改
    onKeyNameChange = ({target: {value}}) => {
        console.log("value--",value)
        let pagination = this.state.pagination;
        pagination.keyWord=value
        this.setState({
            pagination: pagination
        });
    };

    //版块列表
    async courseList() {
       let pagination = this.state.pagination
        const res = await courseList(this.state.pagination);
        pagination.total = res.total
        this.setState({
            modalData: res.data,
            pagination:pagination
        })
    }
    equipmentSave = (record) => {
        if (this.state.selectedRowKeys.length>0){
            this.setState({
                transferVisible: !this.state.transferVisible,
            })
        }else {
            message.error("没有选择可以添加的课程，请先添加课程")
            return
        }

    }

    //
    checkPreCancel = () => {
        this.props.form.resetFields()//清空表单
        this.setState({
            transferVisible:false
        })
    }

    checkCancel = () => {
        this.props.form.resetFields()//清空表单
        this.props.checkCancel()
        this.setState({
            transferVisible:false
        })
    }
    //分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination},()=> this.courseList())
    }
    //pageSize变化
    onShowSizeChange = (pageNum, pageSize) => {
        const {pagination} = this.state;
        pagination.pageNum = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.courseList())
    };
    render() {
        const {saveLoading,data,pagination,selectedRowKeys,total,pageSize} = this.state;
        const {visible} = this.props
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 14}
        };
        const modalColumns = [

            {title: '课程编号', dataIndex: 'courseCode', key: 'courseCode', align: 'center'},
            {title: '课程名称', dataIndex: 'courseName', key: 'courseName', align: 'center'},
            {title: '课程类型', dataIndex: 'courseType', key: 'courseType', align: 'center',
                render: (text, record) =>
                { //课程类型 0-在线课 1-混合课 2-线下课
                    return (
                        <span>{(text===0 && "在线课")|| (text===1 && "混合课") ||  (text===2 && "线下课") }</span>
                    )
                }
             },
            {title: '开放班级', dataIndex: 'isOpenClass', key: 'isOpenClass', align: 'center',
                render:(text,record) =>
                {//是否开放班级 0否 1是
                    return (
                        <span>{(text===0 && "否") || (text===1 && "是")}</span>
                    )
                }
            },
            {title: '课程教师', dataIndex: 'courseTeacher', key: 'courseTeacher', align: 'center'},
            {title: '推送状态', dataIndex: 'isDiscoverCourse', key: 'isDiscoverCourse', align: 'center',
                render:(text,record) =>
                {//是否开放班级 0否 1是
                    return (
                        <span>{(text===0 && "否") || (text===1 && "是")}</span>
                    )
                }
            },
            {title: '操作', dataIndex: 'edit', key: 'edit', align: 'center',
                render: (text, record, index) => <p className='action vertical_j'>
                    查看详情
                </p>
            },
        ];
        const {getFieldDecorator,getFieldValue} = this.props.form;
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.state.selectedRowKeys= selectedRowKeys
            },
            getCheckboxProps: (record) => ({
                disabled: record.isDiscoverCourse === 1, // Column configuration not to be checked
                name: record.courseName,
            }),
        };
        return (
            <div>
                <Modal
                    title=""
                    wrapClassName='training_modal_box discoverModal'
                    visible={visible}
                    closable={false}
                    width='1200px'
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.checkCancel()}>取消</Button>
                            <Button loading={saveLoading} onClick={() => this.equipmentSave()} type='primary'>保存</Button>
                        </div>
                    }
                >
                    <div className="discover_header">
                        <p className="header_tit">添加课程</p>
                        <div>
                            <span className="relevance">关联内容</span>
                            <Search className='formsearch'
                                    placeholder="请输入课程名称或者课程编号"
                                    allowClear
                                    value={pagination.keyWord}
                                    onChange={this.onKeyNameChange.bind(this)}
                                    onSearch={this.onSearchChange}
                                    style={{width: "330px",marginLeft:'20px'}}
                                    // prefix={<Icon type="search" style={{color: "#BEBEBE"}}/>}
                            />

                        </div>
                    </div>
                    <Table
                        rowKey={(v) => v.courseCode}
                        rowSelection={{
                            ...rowSelection,
                        }}
                        columns={modalColumns}
                        dataSource={this.state.modalData}
                        pagination={
                            {
                                total:pagination.total,
                                pageSize:pagination.pageSize,
                                onChange: this.pageChange,
                                onShowSizeChange: this.onShowSizeChange,
                                pageSizeOptions: ['10', '20', '30', '100'],
                                showSizeChanger: true,
                            }
                        }
                    />
                </Modal>
                <TransferClassify
                    TransferType={1}
                    selectedRowKeys={selectedRowKeys}
                    transferVisible={this.state.transferVisible}
                    checkCancel={this.checkCancel.bind(this)}
                    checkPreCancel={this.checkPreCancel.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
            </div>


        )
    }
}

addCourse = Form.create()(addCourse)
export default addCourse;
