import { post } from '@/until/axios_instans';

// 院校教师列表查询
export function selectAcademyTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/selectAcademyTeacher', data)
}

// 学校成员管理列表查询
export function schoolMembersTable(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/schoolMembersTable', data)
}

// 新增院校教师信息
export function addAcademyTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/addAcademyTeacher', data)
}

// 查询院校学生列表
export function selectAcademyStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/selectAcademyStudent', data)
}
// 导入记录列表
export function selectImportRecord(data) {
    return post('/api/ucenter/api/v1/academy/import/selectImportRecord', data)
}
// 导入记录详情
export function importRecordDetail(data) {
    return post('/api/ucenter/api/v1/academy/import/importRecordDetail', data)
}
// 重置用户密码
export function resetUserPassword(data) {
    return post('/api/ucenter/api/v1/sharing/resetUserPassword', data)
}
// 删除院校教师信息
export function deleteAcademyTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/deleteAcademyTeacher', data)
}
// 不导入
export function updateNotImport(data) {
    return post('/api/ucenter/api/v1/sharing/updateNotImport', data)
}
// 管理机构成员管理列表
export function virtualOrgMembers(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/virtualOrgMembers', data)
}
// 查询虚拟机构教师管理列表
export function selectVirtualOrgTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/selectVirtualOrgTeacher', data)
}
// 新增院校学生信息
export function addAcademyStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/addAcademyStudent', data)
}
// 编辑院校学生信息
export function updateAcademyStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/updateAcademyStudent', data)
}
// 查看院校学生信息
export function seeAcademyStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/seeAcademyStudent', data)
}
// 查询虚拟机构学生管理列表
export function selectVirtualOrgStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/selectVirtualOrgStudent', data)
}
// 新增虚拟机构学生管理列表
export function saveVirtualOrgTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/saveVirtualOrgTeacher', data)
}

// 查看院校教师信息
export function seeAcademyTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/seeAcademyTeacher', data)
}
// 修改院校教师信息
export function updateAcademyTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/updateAcademyTeacher', data)
}
// 查看虚拟机构学生管理详情
export function seeVirtualOrgStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/seeVirtualOrgStudent', data)
}
// 新增虚拟机构学生
export function saveVirtualOrgStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/saveVirtualOrgStudent', data)
}
// 修改虚拟机构学生
export function updateVirtualOrgStudent(data) {
    return post('/api/ucenter/api/v1/orgMembers/student/updateVirtualOrgStudent', data)
}
// 修改虚拟机构教师
export function updateVirtualOrgTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/updateVirtualOrgTeacher', data)
}
// 修改虚拟机构教师
export function seeVirtualOrgTeacher(data) {
    return post('/api/ucenter/api/v1/orgMembers/teacher/seeVirtualOrgTeacher', data)
}
// 根据机构Id获取班级信息
export function getClassDetail(data) {
    return post('/api/ucenter/api/v1/sharing/getClassDetail', data)
}