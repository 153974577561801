/*分类查看*/
import React, { Component } from 'react';
import {Form, Select, Button, Input, Table,Spin,Checkbox} from 'antd';
import './index.scss';
import RenderButton from "../../../../components/renderButton";
import {selectUseEnterprise} from '../../../../api/peixun/index'
import TopTitle from "../../../../components/BreadPeixun";

const { Option } = Select;

class ClassiLook extends Component {
    state = {
        total:0,
        pageNum: 1,
        pageSize: 5,
        columns: [
            {title: '序号', dataIndex: 'index', key: 'index',width: 100,
                align: 'center',
                render: (text, record, index) => <p>{index + 1}</p>},
            {title: '在用机构', dataIndex: 'enterpriseName', key: '', align: 'center',},
            {title: '使用时间', dataIndex: 'createTime', key: '', align: 'center',}
        ],
        dataSource: [],
        tableLoading: false, // 是否显示加载中
        oneLevelName:'',  // 当前页面传过来的一级分类名称
        twoLevelName:'',  // 当前页面传过来的二级分类名称
    }
    componentDidMount() {
        const data = this.props.location.search;
        const param = data.split("?")[1];
        const codeParam = param.includes("%") ? decodeURI(param) : param;
        const jsonparam = JSON.parse(codeParam);
        this.setState({oneLevelName:jsonparam.oneLevelName,twoLevelName:jsonparam.twoLevelName},()=>{
            this.selectUseEnterprise()
        })
    }

    selectUseEnterprise = ()=>{
        const {twoLevelName} = this.state
        selectUseEnterprise({name:twoLevelName}).then(e=>{
            this.setState({
                total:e.total,
                dataSource:e.data
            })
        })
    }

    //分页切换
    changePage = (cur) => { // 分页
        this.setState({
            pageNum: cur
        }, () => {
            //列表方法
            this.selectUseEnterprise()
        })
    }
    render() {
        let { columns,total,pageSize,dataSource,pageNum,tableLoading,selectedRowKeys,twoLevelName ,oneLevelName } = this.state;
        let pagination = {
            pageNum: pageNum,
            pageSize: pageSize,
            total: total,
            current: pageNum,
            onChange: this.changePage
        }
        const topTitleData = {
            span_1: "培训中心",
            span_2: "分类标签",
            span_3: "分类管理",
            span_4: "分类查看",
            span_1_c: "#333",
            span_2_c: "#333",
            span_3_c: "#333",
            container: "分类查看",
        };
        return (
          <div className='classLook'>
              <TopTitle {...topTitleData} />
              <div className="filter_controll">
                  <div className='top_title'>分类信息</div>
                  <div className='top_bottom'>
                      <span style={{marginRight: '90px'}}>二级分类：{twoLevelName}</span>
                      <span>所属一级分类：{oneLevelName}</span>
                  </div>
              </div>
              <div className="in_school_content">
                  <div className='return_wapper' onClick={() => this.props.history.goBack()}>
                      <img src={require('@/assets/img/zixun/return.png')}/>
                      <span>返回</span>
                  </div>
                  <div className='top'>
                      <div className="left">
                          在用机构列表
                      </div>
                      <div className="right">
                          共计{total}个机构在用
                      </div>
                  </div>
                  <div className="bottomss">
                      {
                        tableLoading ? <Spin className="Spin-content" tip="加载中"/> : (
                            <div className='all_wapper'>
                              <Table
                                  className='organ-table'
                                  columns={columns}
                                  dataSource={dataSource}
                                  pagination={pagination}
                                  locale={{
                                      emptyText: <>
                                          <div className="Empty-conet">
                                              <div className="img">
                                                  <img src={require('@/assets/img/zixun/pic-4.png')}/>
                                                  <span>暂无数据</span>
                                              </div>
                                          </div>
                                      </>
                                  }}
                                 />
                            </div>
                        )
                      }
                  </div>
              </div>
          </div>
        )
    }
}

ClassiLook = Form.create()(ClassiLook);
export default ClassiLook;
