import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {AAA} from '@store/OBS/check/action';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import './index.scss';
import {Button, Input, Col, Checkbox, Modal, Row, Form, Tag, message, Select} from 'antd';
import MyTable from '@/components/Table';
import {moveFn, set_visibily, set_active, set_drab, change_all, open} from '@store/OBS/special/action';
import {getSubjectList,delSubject,addSubject,updateSubject,sortItem} from '@/api/special/special'
import {getBaseSchoolMajorList} from '@/api/recommend/recommend'
import update from "immutability-helper";
import { ExclamationCircleFilled } from '@ant-design/icons';
const {Option} = Select;
const { confirm } = Modal;
const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}

class Special extends React.Component {
    state = {
        pagination: {
            current: 1,
            pageSize:10,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "100"]
        },
        highSchoolMajorList:[],
        midSchoolMajorList:[],
        highSchoolMajorChildList:[],
        midSchoolMajorChildList:[],
        highMajor:{oneLevel:{},towLevel:{}},
        midMajor:{oneLevel:{},towLevel:{}},
        title:'添加热门方向',
        inputNum:0,
        highid:undefined,
        highchildid:undefined,
        midid:undefined,
        midchildid:undefined,
        isTable:true,
        name:"",//方向名称 全模糊
        keyName:"",
        id:null,//主键id
        displayOrder:"",//排序
        validFlag:"",//删除状态
        status:"",//停用标志
        orderAscfield:"",//升序字段逗号分隔（空默认displayorder ）
        orderDescfield:"",//降序字段逗号分隔（空默认createtime）
        isActive: true,
        visibily: false,
        isDrab: false,
        value: 'test',
        expandedRowKeys: [],
        selectedRowKeys: [],
        activeIndex: 0,
        dataSource: [
        ],
        columns: [
            {title: '资源分类', dataIndex: 'name', key: 'name', align: 'center',},
            {title: '对应高职专业大类', dataIndex: 'high', key: 'high', align: 'center', render:(text,record)=>{
                    /*oneLevel":{"id":"51","typeName":"农林牧渔大类"},"towLevel":{"id":"5101","typeName":"农业类"*/
                    if(record.high){
                        let newObg=JSON.parse(record.high)
                        if(newObg.oneLevel){
                            return<p>
                                <span>{newObg.oneLevel.typeName}</span>
                            </p>
                        }else
                            {
                            return   <span></span>
                        }
                    }
            }
            },
            {title: '对应中职专业大类', dataIndex: 'middle', key:'middle', align: 'center',render:(text,record)=>{
                    if(record.middle){
                        let newObg=JSON.parse(record.middle)
                        if(newObg.oneLevel){
                            return<p>
                                <span>{newObg.oneLevel.typeName}</span>
                            </p>
                        }else
                        {
                            return   <span></span>
                        }
                    }
                }},
            {title: '更新时间', dataIndex: 'updateTime',key: 'updateTime', align: 'center',},
            {
                title: '操作', dataIndex: 'address', key: 'address', align: 'center', fixed: 'right',
                render: (text, record) => <p className='action vertical_j'>
                    <React.RenderButton
                        text='编辑'
                        id="OBS-RC-01-01-b_btnEdit"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                    >
                        <span onClick={() => this.updateDialog(record)}>编辑</span>
                    </React.RenderButton>
                    <React.RenderButton
                        text='删除'
                        id="OBS-RC-01-01-b_btnDel"
                        // type='table_btn'
                        // event={{
                        //     onClick: () => {
                        //         console.log('click')
                        //     },
                        //     onMouseOver:()=>{
                        //         console.log(222)
                        //     }
                        // }}
                    >
                        <span onClick={() => this.delSubject(record)}>删除</span>
                    </React.RenderButton>
                    {/*<span onClick={() => this.updateDialog(record)}>编辑</span>*/}
                    {/*<span onClick={() => this.delSubject(record)}>删除</span>*/}
                </p>
            }
        ]
    }

    componentDidMount() {
        this.getSubjectList()
        this.getBaseSchoolMajorList("1",0)
        this.getBaseSchoolMajorList("2",0)
    }
    //中高职专业列表
    async getBaseSchoolMajorList(type,seniorId) {
        const baseSchoolMajorList = await getBaseSchoolMajorList({"stageId":type})
        if (seniorId==0){
            //一级
            if (type==2){
                this.setState({
                    highSchoolMajorList: baseSchoolMajorList
                })
            }else if(type==1){
                this.setState({
                    midSchoolMajorList: baseSchoolMajorList
                })
            }
        }
    }

    async getSubjectList() {
        const {name, pagination,keyName} = this.state
        console.log("keyName---",keyName)
        const specialList = await getSubjectList({
            "pageNum": pagination.current,
            "pageSize": pagination.pageSize,
            "name":keyName,
            "validFlag":1,
        })
        pagination.total = specialList?specialList.total:""
        this.setState({
            dataSource:specialList?specialList.data:"",
            pagination: pagination
        },()=>{
            window.elGoTop && window.elGoTop('body');
        })
    }
    handleParth=(item)=>{
        let newObg=JSON.parse(item.high)
      return    newObg.oneLevel&&newObg.oneLevel.typeName

    }
    //删除资源方向
    async delSubject(record) {
        let that=this
        if (record.delStatus!=1){
            message.error('暂不可删，请通知技术和运营进行删除');
            return
        }
        confirm({
            title: '警告',
            content: '您确定要删除该条信息，删除后不可恢复，是否继续？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
               delSubject({"id":record.id}).then(res => {
                   if (res && res.code == 0) {
                       message.success("删除成功")
                       that.resetBtn()
                       // this.props.form.resetFields();
                   }
               })
            },
            onCancel() {

            }
        });

    }
    //编辑资源方向
    async updateSubject (record){
        const data={
            "id":record.id,                //类型：String  必有字段  备注：专题名称id（这里就不区分子级父级了。）
            "name":record.name,
            "high":record.high,
            "middle":record.middle
        }
        let res = await updateSubject(data)
        if (res && res.code == 0) {
            message.success("编辑成功")
        }
    }

    //保存新排序
    async sortItem (){
        const data={
            "resourceHotUpdateSpecialList":this.state.dataSource,                //类型：String  必有字段  备注：专题名称id（这里就不区分子级父级了。）
        }
        console.log("data---",data)
        let res = await sortItem(data)
        if (res && res.code == 0) {
            message.success("排序成功")
        }
    }

    //添加资源方向
    async addSubject (){
        const data={
            "name":this.state.name,
            "high":JSON.stringify(this.state.highMajor),
            "middle":JSON.stringify(this.state.midMajor)
        }
        let res = await addSubject(data)
        if (res && res.code == 0) {
            message.success("添加成功")
        }
    }
    //分页
    pageChange = (pageNumber) => {
        if (this.state.isDrab){
            message.warning("请先保存或取消当前页排序")
            return
        }
        const {pagination} = this.state;
        pagination.current = pageNumber;
        this.getSubjectList();
        this.setState({pagination})
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        const {pagination} = this.state;
        pagination.current = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.getSubjectList())
    };
    //选择行
    selectedRowChange = (selectedRowKeys, selectedRows) => {
        let expandedRowKeys = selectedRows.map(v => v.resourceId)
        this.setState({selectedRowKeys, expandedRowKeys})
    }
    change = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys})
    }
    add = () => {
        this.setState({
            name: '',
            keyName:'',
            inputNum:0,
            id:'',
            highid:undefined,
            highchildid:undefined,
            midid:undefined,
            midchildid:undefined,
            highMajor:{oneLevel:{},towLevel:{}},
            midMajor:{oneLevel:{},towLevel:{}},
            highSchoolMajorChildList:[],
            midSchoolMajorChildList:[],
            visibily: !this.state.visibily,
            title:'添加热门方向'
        })
        this.props.form.resetFields();
    }

    updateDialog = (value) => {
        // console.log("highObg.oneLevel.id---",value)
        let highObg=JSON.parse(value.high)
        let midObg=JSON.parse(value.middle)
        let highid,highchildid,midid,midchildid

        if(highObg&&highObg.oneLevel){
           highid= highObg.oneLevel.id
           if(highid){
               this.hithTypeChange(highid).then(()=>{
                  if(highObg.towLevel){
                      highchildid=highObg.towLevel.id
                      if(highchildid){
                          // console.log("highchildid--",highchildid)
                          this.hithTypeChildChange(highchildid)
                      }
                  }
               })
           }
        }

        if(midObg&&midObg.oneLevel){
            midid= midObg.oneLevel.id
            if(midid){
                this.midTypeChange(midid).then(()=>{
                    if(midObg.towLevel){
                        midchildid=midObg.towLevel.id
                        if(midchildid){
                            this.midTypeChildChange(midchildid)
                        }
                    }
                })

            }
        }
        this.setState({
            id:value.id,
            name:value.name,
            inputNum:value.name.length,
            highid:highid,
            highchildid:highchildid,
            midid:midid,
            midchildid:midchildid,
            visibily: !this.state.visibily,
            title:'编辑热门方向'
        })
    }

    //添加或更新
    save = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
            if (values.id) {
                //修改
                let record={
                    "id":values.id,
                    "name":values.name,
                    "high":JSON.stringify(this.state.highMajor),
                    "middle":JSON.stringify(this.state.midMajor)
                }
                console.log("tttt---",JSON.stringify(record))
                this.updateSubject(record).then(()=>{
                    this.setState({
                        visibily: !this.state.visibily
                    })
                    this.resetBtn()
                    this.props.form.resetFields();
                });
            }else {
                this.state.name=values.name
                //添加
                this.addSubject().then(()=>{
                    this.setState({
                        visibily: !this.state.visibily
                    })
                    this.resetBtn()
                    this.props.form.resetFields();
                });
              }
            }
        })
    }
    canale = () => {
        this.setState({
            visibily: !this.state.visibily,
            name: ''
        })
    }
    sort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
            isTable:false
        })
    }
    saveSort = () => {
      this.sortItem().then(()=>{
          this.setState({
              isDrab: !this.state.isDrab,
              isTable:true
          })
          this.props.form.resetFields();
      });
    }
    cancelSort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
            isTable:true
        })
    }
    closeBtn = () => {
        this.setState({
            isActive: !this.state.isActive,
        })
    }
    //拖拽事件
    moveRow = (dragIndex, hoverIndex) => {
        const {dataSource} = this.state;
        // console.log("dataSource---",dataSource)
        let oldDataSource =JSON.parse(JSON.stringify(dataSource));
        const dragRow = dataSource[dragIndex];
        this.setState(
            update(this.state, {
                dataSource: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            }),()=>{
                //重新排序
                let newTempDataSource=JSON.parse(JSON.stringify(this.state.dataSource))
                for (var i=0;i<newTempDataSource.length;i++){
                    newTempDataSource[i].displayOrder=dataSource[i].displayOrder;
                }
                this.setState({dataSource:newTempDataSource})
            });
        this.setState({
            hasChanged: true,
        });
    };
    //搜索查询
    onHotChange = ({target: {value}}) => {
        this.setState({
            keyName: value
        });
    };
    //检索查询
    queryBtn = () => {
        let  pagination=this.state.pagination;
        pagination.current=1
        this.setState({
            pagination:pagination
        }, () => this.getSubjectList());
    }
    //检索重置
    resetBtn = () => {
        let  pagination=this.state.pagination;
        pagination.current=1
        this.setState({
            name: '',
            keyName:'',
            id:'',
            highid:'',
            highchildid:'',
            midid:'',
            midchildid:'',
            pagination:pagination
        }, () => this.getSubjectList());
    }
    //切换
    hithTypeChange =async (value) => {
        let selitem=this.state.highSchoolMajorList.find( (n)=> n.id==value )
        let temphighMajor=this.state.highMajor;
        temphighMajor.oneLevel.id=selitem.id
        temphighMajor.oneLevel.typeName=selitem.typeName
        temphighMajor.towLevel=null
        // midMajor:{oneLevel:{},towLevel},
        this.setState({
            highSchoolMajorChildList: selitem.subList,
            highMajor:temphighMajor,
            highid:value,
            highchildid:""
        })
    };
    //中职一级切换
    midTypeChange =async (value) => {
        let selitem=this.state.midSchoolMajorList.find( (n)=> n.id==value )
        console.log("midSchoolMajorChildList---",selitem)
        let tempMidMajor=this.state.midMajor;
        tempMidMajor.oneLevel.id=selitem.id
        tempMidMajor.oneLevel.typeName=selitem.typeName
        tempMidMajor.towLevel={}
        console.log("tempMidMajor---",tempMidMajor)
            this.setState({
                midSchoolMajorChildList: selitem.subList,
                midMajor:tempMidMajor,
                midid:value,
                midchildid:""
            })
    };

    //二级切换
    hithTypeChildChange = (value) => {
        console.log("value---",value)
        console.log("this.state.highSchoolMajorChildList.----",this.state.highSchoolMajorChildList)
        let selitem=this.state.highSchoolMajorChildList.find( (n)=> n.id==value )
        let towlevel={}
        towlevel.id=selitem.id
        towlevel.typeName=selitem.typeName
        let temphighMajor=this.state.highMajor;
        temphighMajor.towLevel=towlevel
        // midMajor:{oneLevel:{},towLevel},
        this.setState({
            highMajor:temphighMajor,
            highchildid:value
        })
    };
    //中职二级切换
    midTypeChildChange = (value) => {
        let selitem=this.state.midSchoolMajorChildList.find( (n)=> n.id===value )
        let towlevel={}
        towlevel.id=selitem.id
        towlevel.typeName=selitem.typeName
        let tempMidMajor=this.state.midMajor;
        tempMidMajor.towLevel=towlevel
        this.setState({
            midMajor:tempMidMajor,
            midchildid:value
        })
    };

    changeTitle(e) {
        const value = e.target.value
        this.setState({
            inputNum: value.length
        })
    }
    render() {
        const {columns, selectedRowKeys, pagination, isDrab, isActive, visibily, dataSource,name,id,isTable,highSchoolMajorList,midSchoolMajorList,highSchoolMajorChildList,midSchoolMajorChildList,highid,highchildid,midid,midchildid,keyName,title,inputNum} = this.state;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16},
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle special_box'>
                <div className="padd">
                    <div className="top" style={{display: isDrab ? 'none' : 'flex'}}>
                        <Col span={12} className="sort_boxs">
                            <React.RenderButton
                                text='添加热门方向'
                                id="OBS-RC-01-01-b_btnAdd"
                                // type='table_btn'
                                // event={{
                                //     onClick: () => {
                                //         console.log('click')
                                //     },
                                //     onMouseOver:()=>{
                                //         console.log(222)
                                //     }
                                // }}
                            >
                                <Button className='sure_sort' type='primary' onClick={() => this.add()}>添加热门方向</Button>
                            </React.RenderButton>
                            <div onClick={() => this.sort()}>
                                <img src={require("@/assets/img/sort.png")} className="sort_img"/>
                                <span className='sort' > 专题排序</span>
                            </div>
                        </Col>
                        <Col className='top_r btn_box' span={12}>
                            <Input value={keyName} onChange={this.onHotChange.bind(this)} onPressEnter={()=>this.queryBtn()} placeholder='资源分类/专业大类'/>
                            <Button className='reset_btn' disabled={!keyName}  onClick={() => this.resetBtn()}>重置</Button>
                        </Col>
                    </div>
                    <div className="top" style={{display: isDrab ? 'flex' : 'none'}}>
                        <Col span={12}>
                            <Button className='sure_sort' type='primary' onClick={() => this.saveSort()}>保存排序</Button>
                            <Button className='cancel_sort' onClick={() => this.cancelSort()}>取消排序</Button>
                            <ExclamationCircleFilled  style={{marginRight:'10px'}} className="sort1_img"/>
                            <span className='save_hyn'>拖拽排序后请保存</span>
                        </Col>
                        {/*<Col className='top_r' span={12}>
                <p className='vertical_j trans'>
                    <span onClick={() => change_all(2)}>全部折叠</span>
                    <span onClick={() => change_all(1)}>全部展开</span>
                </p>
            </Col>*/}
                    </div>
                    <div className="alert" style={{display: isActive ? 'flex' : 'none'}}>
            <span>
                <ExclamationCircleFilled  style={{marginRight:'10px'}}/>
                查询到{pagination.total}个热门方向
                         </span>
                        <span onClick={() => this.closeBtn()}>
                X
                         </span>
                    </div>
                    <div className={`check_container ${isTable?"isShowTable":"isnoneTable"}`} style={{position: 'relative'}}>
                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                options={
                                    {
                                        dataSource,
                                        columns,
                                        rowSelection: {
                                            selectedRowKeys,
                                            // ...rowSelection
                                            onChange: this.selectedRowChange,
                                        },
                                        pagination: { onChange: this.pageChange, ...pagination,onShowSizeChange: this.onShowSizeChange},
                                        onRow: (record, index) => ({
                                            index,
                                            moveRow: this.moveRow,
                                        })
                                    }
                                }

                                isDrab={isDrab}
                                fixededit={true}
                            />
                        </DndProvider>
                    </div>
                </div>
                <Modal
                    title={title}
                    width={'660px'}
                    wrapClassName='modal_box'
                    visible={visibily}
                    closable={false}
                    destroyOnClose={true}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.canale()}>取消</Button>
                            <Button onClick={() => this.save()} type='primary'>保存</Button>
                        </div>
                    }
                  >
                    <Form
                        {...formItemLayout}
                    >
                        <Form.Item
                            label="专业方向名称"
                        >
                            {
                                getFieldDecorator('name', { initialValue: name,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入专业方向名称'
                                        },
                                        {max: 15, message: '专业方向名称不能超过15字'}
                                    ]
                                })(<Input placeholder='请输入专业方向名称'  onChange={this.changeTitle.bind(this)} suffix={<span>{inputNum}/15</span>}/>)
                            }
                        </Form.Item>
                        <Form.Item style={{display: "none"}}>
                            {getFieldDecorator('id',{
                                initialValue: id
                            })(
                                <Input
                                    type="text"
                                    placeholder="id"
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="高职专业大类"
                        >
                            <Row gutter={30}>
                                <Col span={12}>
                                    <Select value={highid} placeholder="请选择"  onChange={this.hithTypeChange}>
                                        {highSchoolMajorList && highSchoolMajorList.length>0 ?
                                            highSchoolMajorList.map((item, index)=>(
                                             <Option key={index} value={item.id}> {item.typeName}</Option>
                                            )): ""}

                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <Select value={highchildid} placeholder="请选择" onChange={this.hithTypeChildChange}>
                                        {highSchoolMajorChildList && highSchoolMajorChildList.length>0 ?
                                            highSchoolMajorChildList.map((item, index)=>(
                                                <Option key={index} value={item.id}> {item.typeName}</Option>
                                            )): ""}
                                    </Select>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label="中职专业大类"
                        >
                            <Row gutter={30}>
                                <Col span={12}>
                                    <Select value={midid} placeholder="请选择" onChange={this.midTypeChange}>
                                        {midSchoolMajorList && highSchoolMajorList.length>0 ?
                                            midSchoolMajorList.map((item, index)=>(
                                                <Option key={item.id} value={item.id}>{item.typeName}</Option>
                                            )): ""}
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <Select value={midchildid} placeholder="请选择" onChange={this.midTypeChildChange}>
                                        {midSchoolMajorChildList && midSchoolMajorChildList.length>0 ?
                                            midSchoolMajorChildList.map((item, index)=>(
                                                <Option key={index} value={item.id}> {item.typeName}</Option>
                                            )): ""}
                                    </Select>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}


Special = connect(mapStateToProps, mapDispatchToProps)(Special);
Special = Form.create()(Special)
export default Special;
