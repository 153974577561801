import { Form, Input } from 'antd';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import './MaxLengthInputFC.scss'
// 英文算半个
export const countText = (text) => {
    let length = 0
    if (typeof text === 'string') {
        for (const char of text) {
            if (char.charCodeAt(0) >= 0 && char.charCodeAt(0) <= 128) {
                length += 0.5
            } else {
                length += 1
            }
        }
    }
    return Math.ceil(length)
}

// by antd
function fixEmojiLength(value, maxLength) {
    return [...(value || '')].slice(0, maxLength).join('');
}

/**
 * @param onChange {function}
 * @param maxLength {number}
 * @param value {string}
 * @param type {string}
 * @param showCount {boolean}
 * @param format {function}
 * @param props {object}
 * @param ref
 */
const MaxLengthInputFC = React.forwardRef((
    {
        defaultValue = '',
        value = defaultValue,
        onChange,
        maxLength = Number.MAX_SAFE_INTEGER,
        type = 'input',
        showCount = true,
        format = text => text,
        warpProps = {},
        countTextLength = (text) => text?.length ?? 0,
        addonAfter,
        onBlur,
        ...props
    },
    ref
) => {
    const [focus, setFocus] = useState(false)
    const refCompositionIsStarted = useRef(false)
    if (typeof format !== 'function') {
        throw new Error('format 必须是函数')
    }
    value = format(value)
    const [v, setV] = useState(() => {
        if (defaultValue !== undefined && defaultValue !== null) {
            return format(defaultValue)
        }
        return value
    })


    const valueLengthInTheRange = useMemo(() => {
        if (maxLength === undefined) {
            return () => true
        } else {
            return (text) => countTextLength(text) <= maxLength
        }
    }, [countTextLength, maxLength])

    const handleChange = useCallback((e) => {
        let text = e.target.value
        text = format(text)
        if (!refCompositionIsStarted.current &&
            v.length >= maxLength &&
            text.length > maxLength
        ) {
            return
        }
        const newV = refCompositionIsStarted.current ? text : fixEmojiLength(text, maxLength)
        setV(newV)
        onChange && onChange(newV)
    }, [format, maxLength, onChange, v])

    const handleCompositionStart = () => {
        refCompositionIsStarted.current = true
    }

    const handleCompositionEnd = (e) => {
        let text = e.target.value
        refCompositionIsStarted.current = false
        const resultText = refCompositionIsStarted.current ? text : fixEmojiLength(text, maxLength);
        setV(resultText)
        onChange && onChange(resultText)
    }

    useLayoutEffect(() => {
        // 初始已format
        if (value !== v) {
            setV(fixEmojiLength(value, maxLength))
        }
    }, [maxLength, value])
    return (
        <label
            className={`MaxLengthInputFC ${type === 'input' ? '_input' : '_textarea'} ${focus ? '_focus' : ''}`}
            {...warpProps}
            onClick={() => setFocus(true)}
        >
            {type === 'input' && (
                <Input
                    className={'MaxLengthInputFC_input'}
                    value={v}
                    onChange={handleChange}
                    ref={ref}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                    onBlur={(e) => {
                        handleCompositionEnd({ target: { value: v } })
                        onBlur && onBlur(e)
                    }}
                    {...props}
                />
            )}
            {type !== 'input' && (
                <Input.TextArea
                    className={'MaxLengthInputFC_textarea'}
                    value={v}
                    onChange={handleChange}
                    ref={ref}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                    {...props}
                    onBlur={(e) => {
                        setFocus(false)
                        onBlur && onBlur(e)
                    }}
                />
            )}
            {showCount && maxLength !== undefined && (
                <span className='MaxLengthInputFC_count'>
                    <span>{countTextLength(v)}</span>
                    <span>/</span>
                    <span>{maxLength}</span>
                </span>
            )}
            {addonAfter && (
                <span className='MaxLengthInputFC_addonAfter'>
                    {addonAfter}
                </span>
            )}
        </label>
    )
})

export default MaxLengthInputFC

