import React from 'react';
import {Button, Form,  Modal} from "antd";
import {processingResults} from '@/api/practTrain/index.js'
import '../index.scss';

class ProgressModel extends React.Component {
    state = {
        applyId: null,
        addressVisible: false,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let {applyId} = this.state;
        if(applyId !== nextProps.applyId){
            this.setState({
                applyId: nextProps.applyId
            })
        }
    }

    generateAddress = () =>{
        let {applyId} = this.state;
        processingResults({applyId: applyId,applyStatus: 1}).then(res =>{
            if(res && res.code === 0){
                this.mesSuccess("下发成功！")
                this.props.getList()
                this.props.checkCancel()
            }
        })
    }

    render() {
        const {progressVisible} = this.props
        return (
            <div>
                <Modal
                    width='660px'
                    title="处理进度确认"
                    wrapClassName='practTrain_modal_box'
                    visible={progressVisible}
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.props.checkCancel()}>取消</Button>
                            <Button onClick={() => this.generateAddress()} type='primary'>已全部下发</Button>
                        </div>
                    }
                >
                    <div className="">您是否已经将学校申请的资源已经全部下发到客户的Landesk中？</div>
                </Modal>
            </div>

        )
    }
}

ProgressModel = Form.create()(ProgressModel)
export default ProgressModel;

