import React, { Component } from 'react'
import { Table } from 'antd'

import { getSoftwareDownloadList } from '../../../api/shixun2'
import './index.scss'
function _get_query(name) {
  const search = window.location.href
  if (!search) return null
  const item = search.split('&').find(v => {
    return v.includes(name)
  });
  if (item) {
    const _item_arr = item.split('=')
    return _item_arr[1]
  } else {
    console.log(`${name} is not in search`)
    return null
  }
}
export class index extends Component {
  state = {
    dataSource: [],
    pageSize: 10,
    pageNum: 1,
    total: 0,
    softwareId: _get_query('softwareId'),

  }
  async componentDidMount() {
    const { pageSize,
      pageNum, softwareId } = this.state
    this.getdata({
      pageNum, pageSize,
      softwareId
    })
  }

  getdata = async (arg) => {
    const { data, pageSize, pageNum, total } = await getSoftwareDownloadList(arg)

    this.setState(() => {
      return {
        dataSource: data,
        pageSize, pageNum, total
      }
    })
  }
  //pageSize变化
  onShowSizeChange_login = (current, pageSize) => {
    this.setState({
      pageNum: current,
      pageSize: pageSize,
    }, () => {
      const {  softwareId } = this.state
      this.getdata({
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum,
        softwareId
      });
    }
    );
  };

  //pageNum变化
  onPageNumChange_login = (current, pageSize) => {
    this.setState({
      pageNum: current,
    }, () => {
      const {  softwareId } = this.state

      this.getdata({softwareId,
        pageSize: this.state.pageSize,
        pageNum: this.state.pageNum
      });
    }
    );
  }
  render() {

    const columns = [
      {
        title: '序号',
        dataIndex: 'name',
        key: 'name',
        align: "center",
        render: (v, item, index) => {
          return <span>{index + 1}</span>
        }
      },
      {
        title: '收件人名字',
        dataIndex: 'userName',
        key: 'userName',
        align: "center",
      },
      {
        title: '收件人手机号',
        dataIndex: 'userPhone',
        key: 'userPhone',
        align: "center",
      },
      {
        title: '所属机构',
        dataIndex: 'orgName',
        key: 'orgName',
        align: "center",
      },
      {
        // "progress":2,                //类型：Number  必有字段  备注：提取进度( 1.已打开链接 2.已下载)
        title: '提取进度',
        dataIndex: 'progressDesc',
        key: 'progressDesc',
        align: "center",
       
      },
      {
        title: '提取时间',
        dataIndex: 'createTime',
        key: 'createTime',
        align: "center",
      },
      {
        // "isSendNode":0,                //类型：Number  必有字段  备注：发送方式(0无 1站内信 2.站内信+短信)
        title: '发送方式',
        dataIndex: 'sendNode',
        key: 'sendNode',
        align: "center",
        render: (sendNode) => {
          return <span>{sendNode}</span>
        }
      },
      {
        title: '发送人',
        dataIndex: 'sendUserName',
        key: 'sendUserName',
        align: "center",
      },
    ];
    const { dataSource, 
      } = this.state
    return (
      <div className="installationXiang_jhaha">
        <Table dataSource={dataSource} columns={columns}
          scroll={{ y: "calc(100vh - 370px)" }}
          pagination={{
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30'],
            showSizeChanger: true,
            onShowSizeChange: (a, b) => this.onShowSizeChange_login(a, b),
            onChange: (a, b) => this.onPageNumChange_login(a, b),
            current: this.state.pageNum,
            pageSize: this.state.pageSize,
            total: this.state.total,
          }} />
      </div>
    )
  }
}

export default index
