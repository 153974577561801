/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react';
import { Select, Checkbox } from 'antd';

import styles from './index.module.scss';

const DropDownMultipleSelection = (props, ref) => {
    const { value: v = [], data = [], codeKeyName, keyName, onChange } = props;
    const [list, setList] = useState([]);
    const [value, setValue] = useState([]);

    useEffect(() => {
        const fn = () => {
            let obj = {
                [codeKeyName]: 0,
                [keyName]: '全选',
                checked: false,
            }
            let arr = [], newValue = [];
            if (v.length) {
                arr = data.map(item => {
                    if (v.includes(item[codeKeyName])) {
                        item.checked = true;
                        newValue.push(item[keyName]);
                    }
                    return item;
                })
                if (v.length === data.length) {
                    obj.checked = true;
                }
            }
            setList([obj, ...data]);
            setValue(newValue);
        };
        fn();
    }, [data]);

    const del = (it) => {
        const arrs = [];
        const codeArr = [];
        const arr = list.map(its => {
            if (it === its[keyName]) {
                its.checked = !its.checked;
            };
            if (its.checked && its[codeKeyName] !== 0) {
                arrs.push(its[keyName]);
                codeArr.push(its[codeKeyName]);
            };
            return its;
        });
        arr[0].checked = false;
        setList(arr);
        setValue(arrs);
        onChange(codeArr);
    };

    const change = (it) => {
        let arr = [];
        if (it[codeKeyName] === 0) {
            if (it.checked) {
                arr = list.map(its => {
                    its.checked = false;
                    return its;
                })
                setValue([]);
                onChange([]);
            } else {
                arr = list.map(its => {
                    its.checked = true;
                    return its;
                })
                setValue(arr.map(item => item[keyName]).slice(1));
                onChange(arr.map(item => item[codeKeyName]).slice(1));
            }
            setList(arr);
            return;
        };
        arr = list.map(its => {
            if (it[codeKeyName] === its[codeKeyName]) {
                its.checked = !its.checked;
            };
            return its;
        });
        const firstChecked = arr.slice(1).every(item => item.checked);
        arr[0].checked = firstChecked;
        if (firstChecked) {
            const values = arr.map(item => item[keyName])
            setValue(values.slice(1));
            onChange(arr.map(item => item[codeKeyName]).slice(1));
        } else {
            const values = arr.filter(item => item.checked);
            setValue(values.map(item => item[keyName]));
            onChange(values.map(item => item[codeKeyName]));
        }
        setList(arr);
    };

    return (
        <div ref={ref} onMouseDown={e => { e.preventDefault() }}>
            <Select
                value={value}
                style={{ width: 500 }}
                placeholder={props.placeholder || '请选择'}
                size='large'
                mode="tags"
                showArrow
                onDeselect={del}
                getPopupContainer={triggerNode => triggerNode.parentElement}
                dropdownRender={() => {
                    const remain = list.length % 4;
                    return <div className={styles["range_checked_select"]}>
                        {
                            list.map((it, inx) => {
                                return <div
                                    key={it[codeKeyName]}
                                    className={`${styles["select_item"]} ${(inx + remain) < list.length ? styles["select_item_border"] : ''}`}
                                    onClick={e => { change(it); e.preventDefault() }}
                                >
                                    <Checkbox checked={Boolean(it.checked)}>{it[keyName]}</Checkbox>
                                </div>
                            })
                        }
                    </div>
                }}
            />
        </div>
    )
}

export default forwardRef(DropDownMultipleSelection);