import React, { Component } from 'react';
import { Sigh, Resolved, Unresolved } from '../../../components/svgConfig/index';
import { getQuestionById } from '../../../api/helpCenter/index';
import './index.scss';
import TopTitle from "../../../components/Breadcrumbtongyong";
export default class index extends Component {
    state = {
        info: {}
    }
    componentDidMount() {
        getQuestionById({ id: this.GetQueryString('id') }).then(res => {
            if (res) {
                this.setState({
                    info: res
                })
            }
        })
    }
    // 获取地址栏参数
    GetQueryString(name) {
        const search = window.location.href;
        if (!search) return null;
        if (!search.split("?")[1]) return null;
        const item = search.split("?")[1].split("&");
        if (!item) return null;
        const fi = item.find((v) => v.includes(name))?.split("=")[1];
        if (item) {
            return fi;
        } else {
            console.log(`${name} is not in search`);
            return null;
        }
    }
    render() {
        const { info } = this.state;
        const topTitleData = {
            span_1: "通用管理",
            span_2: "帮助中心",
            span_3: "内容列表",
            span_4: "帮助详情",
            span_3_fun: res => {
                this.props.history.go(-1)
            },
            span_1_c: "#333",
            span_2_c: "#333",
            span_3_c: "#333",
            container:"帮助详情",
        };
        return (
           <div className={'problemaDetail_cont'}>
               <TopTitle {...topTitleData} />
               <div className="problemaDetail">
                   <div className='return_wapper' onClick={() => this.props.history.goBack()}>
                       <img src={require('@/assets/img/zixun/return.png')}/>
                       <span>返回</span>
                   </div>
                   <div className='problemaDetail_top'>
                       <span><Sigh /> {info.title || '--'}</span>
                       <span><span><Resolved /> 已解决（{info.resolvedNum || 0}）</span><span><Unresolved /> 未解决（{info.unresolvedNum || 0}）</span></span>
                   </div>
                   <div className="Problem_text">
                       <div className="problemaDetail_content" dangerouslySetInnerHTML={{ __html: info.details && info.details}}></div>
                   </div>
               </div >
           </div>
        )
    }
}
