import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import './index.scss';
import {Button, Input, Col, Modal, Row, Form, message, Select, Radio, Icon, Empty,Table} from 'antd';
import MyTable from '@/components/Table';
import update from "immutability-helper";
import {ExclamationCircleFilled} from '@ant-design/icons';
import RelevanceModel from "./components/index";
import {homePageModuleList,homePageCourseList,deleteHomePageCourse,addCourseList,firstSelect,secondSelect,addCourse,moduleCourseSort,} from '@/api/craftsman-app/index'
const {Search} = Input;
const {Option} = Select;
const mapStateToProps = ({CHECK}) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {};
}

class CourseManage extends React.Component {
    state = {
        relevanceVisible:false,   //关联版块弹窗
        selectedRowKeys:[],
        empty:true,
        pagination: {
            pageSizeOptions: ["10", "20", "30", "100"], //默认分页
            pageNum: 1,
            pageSize: 10,
            total: 0,
            moduleId:'',    //板块id
            categoryId:'',       //分类id
            categoryIdParent:"",
        },
        addCourseListParams: {
            courseName:'',  //课程名称搜索字段
            firstCategoryId:'', //一级分类id
            secondCategoryId:'', //二级分类id
        },
        parentId:'',       //父id
        courseCode:'',  //课程编号
        displayOrder: "",//排序
        visible: false,
        isDrab: false,
        dataSource: [],
        oneClassifyList:[],  //一级分类列表
        twoClassifyList:[], //二级分类列表
        plateList:[], //版块列表
        courseLists:[],  //首页课程列表
        addCourseList:[], //添加首页课程列表
        courseList:[],    //添加课程列表
        newDataSouse:[]  //排序表单数据
    }
    componentDidMount() {
        this.homePageModuleList()
        this.homePageCourseList()
        this.firstSelect()
    }
    //版块列表
    async homePageModuleList() {
        const plateList = await homePageModuleList({})
        this.setState({
            plateList: plateList
        })
    }
    //板块筛选
    moduleTypeChange = ({target: {value}}) => {
        const {pagination} = this.state;
        pagination.moduleId = value
        this.setState({
            pagination
        }, () => {
            this.homePageCourseList()
        })
    };
    //首页课程列表
    async homePageCourseList() {
        const {pagination,addCourseListParams} = this.state
        const res = await homePageCourseList({
            "courseName": addCourseListParams.courseName,
            "moduleId" : pagination.moduleId,
            "pageNum": pagination.pageNum,
            "pageSize": pagination.pageSize,
        })
        pagination.total = res ? res.courseList.total : 0
        this.setState({
            dataSource: res ? res.courseList.data : '',
            courseLists:res ? res : '',
            pagination: pagination,
        }, () => {
            window.elGoTop && window.elGoTop('body')
        })
    }
    //搜索查询
    onHotChange = ({target: {value}}) => {
        const {addCourseListParams} = this.state;
        const {pagination} = this.state
        addCourseListParams.courseName = value
        this.setState({pagination});
    };
    //检索查询
    queryBtn = () => {
        let pagination = this.state.pagination;
        pagination.pageNum = 1
        this.setState({
            pagination: pagination
        }, () => this.homePageCourseList());
    }
    //分页
    pageChange = (pageNumber) => {
        const {pagination} = this.state;
        pagination.pageNum = pageNumber;
        this.setState({pagination},()=> this.homePageCourseList())
    }
    //pageSize变化
    onShowSizeChange = (pageNum, pageSize) => {
        const {pagination} = this.state;
        pagination.pageNum = 1;
        pagination.pageSize = pageSize;
        this.setState({pagination}, () => this.homePageCourseList())
    };
    //添加首页课程列表
    async addCourseList() {
        const {addCourseListParams} = this.state
        const res = await addCourseList(addCourseListParams)
        this.setState({
            addCourseList:res ? res : '',
        })
    }
    //一级分类查询
    oneClassifyChange = (value) => {
        const {addCourseListParams} = this.state
        addCourseListParams.firstCategoryId = value
        addCourseListParams.secondCategoryId = ''
        this.setState({
            parentId: value,
            addCourseListParams,
            twoClassifyList:[]
        }, () => {
            if(value){
                this.secondSelect()
            }
            this.addCourseList()
        })
    };

    //二分级分类查询
    twoClassifyChange = (value) => {
        const {addCourseListParams} = this.state
        addCourseListParams.secondCategoryId = value
        this.setState({
            addCourseListParams
        }, () => {
            this.addCourseList()
        })
    };



    // 课程分类一级分类下拉列表
    async firstSelect() {
        const {pagination} = this.state
        const res = await firstSelect({
            "categoryId" :pagination.categoryIdParent
        })
        this.setState({
            oneClassifyList: res
        })
    }
    // 课程分类二级分类下拉列表
    async secondSelect() {
        const {parentId} = this.state
        const res = await secondSelect({"parentId" :parentId})
        this.setState({
            twoClassifyList: res
        })
    }
    //新增课程
    homeCourse = (record) => {
        this.setState({
            visible: !this.state.visible,
        },()=>{
            this.addCourseList()
            this.firstSelect()
            this.secondSelect()
        })
    }
    //弹窗成员姓名查询
    onSearchChange = ()=>{
        let pagination = this.state.pagination;
        pagination.pageNum = 1
        this.setState({
            pagination: pagination
        }, () => this.addCourseList());
    }
    sort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
        })
    }
    //保存新排序
    async sortItem (){
       const {newDataSouse} = this.state
        let res = await moduleCourseSort({courseSortList :newDataSouse})
        if (res && res.code == 0) {
            message.success("排序成功")
        }
    }
    saveSort = () => {
        this.sortItem().then(()=>{
            this.setState({
                isDrab: !this.state.isDrab,
            })
            this.props.form.resetFields();
            this.homePageCourseList();
        },()=>{

        });
    }
    cancelSort = () => {
        this.setState({
            isDrab: !this.state.isDrab,
        })
    }
    //拖拽事件
    moveRow = (dragIndex, hoverIndex) => {
        const {dataSource} = this.state;
        let oldDataSource = JSON.parse(JSON.stringify(dataSource));
        const dragRow = dataSource[dragIndex];
        this.setState(
            update(this.state, {
                dataSource: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            }), () => {
                //重新排序
                let newTempDataSource = JSON.parse(JSON.stringify(this.state.dataSource))
                let newDataSouse = []
                for (var i = 0; i < newTempDataSource.length; i++) {
                    newDataSouse[i]=newTempDataSource[i].homepageCourseId
                    // newTempDataSource[i].displayOrder = dataSource[i].displayOrder;
                }

                this.setState({dataSource: newTempDataSource,newDataSouse:newDataSouse})
            });
        this.setState({
            hasChanged: true,
        });
    };

    //关闭首页展示
    closeShow(record) {
        let that = this;
        Modal.confirm({
            title: '温馨提醒',
            content: "您确认要关闭 【" + record.courseName + " 】课程在App端首页的展现么?确认后，此课程将不会在App端的首页上展现。（但您仍旧可以通过App端的【发现】查看此课程）",
            type: 'warning',
            isCancel: true,
            onOk: () => {
                deleteHomePageCourse({homepageCourseId: record.homepageCourseId}).then((res) => {
                    if (res.code === 0) {
                        message.success("关闭成功");
                        that.homePageCourseList();
                    }
                });
            },
            onCancel() {
            },
        });
    }

    //关联版块弹窗
    addCourseModal = (record) => {
        let {relevanceVisible,courseList} = this.state;
        this.setState({
            relevanceVisible: !relevanceVisible,
            record:record,
            courseList:courseList
        })
    }
    //删除添加的课程
    delModule=(index)=>{
        const {courseList} = this.state
        courseList.splice(index, 1);
        this.setState({
            courseList: courseList
        },)
    }
    //保存课程
    saveCourse() {
        let that = this;
        const {courseList} = this.state
        addCourse({courseList}).then((res) => {
            if (res.code === 0) {
                that.homePageCourseList();
                that.setState({
                    visible:false,
                    courseList:[]
                })
            }
        });
    }
    //取消弹框
    checkCancel = () => {
        this.setState({
            visible: false,
            relevanceVisible:false,
        })
    }
    //刷新数据
    refreshData = () => {
        this.setState({
            relevanceVisible: false,
            courseList:{},
        }, () =>this.homePageCourseList())
    }

    relevanceCancel = () => {
        const {selectedRowKeys} = this.state
        this.setState({
            relevanceVisible:false,

        })
    }
    render() {
        const {addCourseListParams,plateList,pagination, isDrab, visible, dataSource,selectedRowKeys,courseLists,addCourseList,oneClassifyList,twoClassifyList,} = this.state;
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 16},
        };
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };
        let columns = [
            {title: '课程编号', dataIndex: 'courseCode', key: 'courseCode', align: 'center'},
            {title: '课程名称', dataIndex: 'courseName', key: 'courseName', align: 'center',width:'200px'},
            {title: '课程类型', dataIndex: 'courseType', key: 'courseType', align: 'center',
                render: (text, record) =>
                { //课程类型 0-在线课 1-混合课 2-线下课
                    return (
                        <span>{(text===0 && "在线课")|| (text===1 && "混合课") ||  (text===2 && "线下课") }</span>
                    )
                }
            },
            {title: '所在版块', dataIndex: 'moduleName', key: 'moduleName', align: 'center',width:'200px'},
            {title: '所属机构', dataIndex: 'originName', key: 'originName', align: 'center',width:'200px'},
            {title: '开放班级', dataIndex: 'isOpenClass', key: 'isOpenClass', align: 'center',
                render:(text,record) =>
                {//是否开放班级 0否 1是
                   return (
                       <span>{(text===0 && "否") || (text===1 && "是")}</span>
                   )
                }
            },
            {title: '课程教师', dataIndex: 'courseTeacher', key: 'courseTeacher', align: 'center'},
            {title: '推送到首页时间', dataIndex: 'createTime', key: 'createTime', align: 'center',
                render:(text,record)=>{
                    return <div>{text ? text.substring(0,10) :'---'}</div>;
                }
            },
            {
                title: '操作', dataIndex: 'homepageCourseId', key: 'homepageCourseId', fixed: 'right', align: 'center',
                render: (text, record, index) => <p className='action vertical_j'>
                    <span onClick={()=>this.closeShow(record)}>关闭首页展示</span>
                </p>
            }
        ]
        const modalColumns = [
            {title: '课程编号', dataIndex: 'courseCode', key: 'courseCode', align: 'center'},
            {title: '课程名称', dataIndex: 'courseName', key: 'courseName', align: 'center'},
            {title: '所属机构', dataIndex: 'originName', key: 'originName', align: 'center'},
            {title: '课程教师', dataIndex: 'courseTeacher', key: 'courseTeacher', align: 'center'},
            {title: '操作', dataIndex: 'edit', key: 'edit', align: 'center',
                render: (text, record, index) => <p className='action vertical_j'>
                    <span onClick={()=>this.addCourseModal(record)}>添加</span>
                </p>
            },
        ];
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='publicstyle course_manage'>
                <div className="search_btn_box">
                        <Row className="navtop mb20_fff">
                            <Col span={2}>
                                <div className='left' >
                                    所在版块
                                </div>
                            </Col>
                            <Col span={22}>
                                <Radio.Group buttonStyle="solid"  onChange={this.moduleTypeChange} value={pagination.moduleId}>
                                    <Radio.Button value="">全部</Radio.Button>
                                    {
                                        plateList && plateList.length ?plateList.map((item,index)=>{
                                            return <Radio.Button value={item.moduleId} key={index}>{item.moduleName}</Radio.Button>
                                        }) : ""
                                    }
                                </Radio.Group>
                            </Col>
                        </Row>
                </div>
                <div className="padd">
                    <div className="top" style={{display: isDrab ? 'none' : 'flex'}}>
                        <Col span={12} className="sort_boxs">
                            <Button className='sure_sort' type='primary' onClick={() => this.homeCourse()}>新增课程</Button>
                            {this.state.pagination.moduleId ?  <Button className='cancel_sort' onClick={() => this.sort()}>调整排序</Button>: ""

                            }
                        </Col>
                        <Col className='top_r btn_box' span={12}>
                            <Input value={addCourseListParams.courseName} onChange={this.onHotChange.bind(this)} onPressEnter={()=>this.queryBtn()} placeholder='请输入课程名称或者课程编号'/>
                            <span className="all_course">共{courseLists.courseNum}门课程</span>
                        </Col>
                    </div>
                    <div className="top" style={{display: isDrab ? 'flex' : 'none'}}>
                        <Col span={12}>
                            <Button className='sure_sort' type='primary' onClick={() => this.saveSort()}>保存排序</Button>
                            <Button className='cancel_sort' onClick={() => this.cancelSort()}>取消排序</Button>
                            <ExclamationCircleFilled style={{marginRight: '10px'}} className="sort1_img"/>
                            <span className='save_hyn'>拖拽排序后请保存</span>
                        </Col>
                    </div>
                    <div className="check_container"
                         style={{position: 'relative'}}>
                        <DndProvider backend={HTML5Backend}>
                            <MyTable
                                enumeration='homepageCourseId'
                                className="index_block"
                                options={
                                    {
                                        dataSource,
                                        columns,
                                        pagination: {onChange: this.pageChange, ...pagination, showSizeChanger:true, onShowSizeChange: this.onShowSizeChange},
                                        onRow: (record, index) => ({
                                            index,
                                            moveRow: this.moveRow,
                                        })
                                    }
                                }

                                isDrab={isDrab}
                                fixededit={true}
                            />
                        </DndProvider>
                    </div>
                </div>
                {/*关联版块 弹窗 start*/}
                <RelevanceModel
                    relevanceVisible={this.state.relevanceVisible}
                    record={this.state.record}
                    courseList={this.state.courseList}
                    checkCancel={this.relevanceCancel.bind(this)}
                    refreshData={this.refreshData.bind(this)}
                    wrappedComponentRef={form => {
                        this.reportReasonForm = form;
                    }}
                />
                {/*关联版块 弹窗 end*/}
                {/*新增课程 弹窗 start*/}
                <Modal
                    title=""
                    width='1000px'
                    wrapClassName='modal_box roleModal courseModal'
                    visible={visible}
                    destroyOnClose={true}
                    onCancel={() => {this.setState({visible: !visible})}}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => {
                                this.setState({visible: !visible,courseList:[]})
                            }}>取消</Button>
                            <Button onClick={() => this.saveCourse()} type='primary'>保存</Button>
                        </div>
                    }
                >
                    <Row className='roleModalcont course_cont'>
                        <Col span={17}>
                            <div className="role_flex">
                                <h2 className='roleModalName'>新增课程</h2>
                                <ExclamationCircleFilled style={{marginRight: '10px'}} className="sort1_img"/>
                                <span className='save_hyn'>说明：您在下方的课程列表中，选择添加，可以添加到首页展现</span>
                            </div>
                            <div className='role-l'>
                                <div style={{margin:'0 30px'}}>
                                    <div className="select_box">
                                        <div className="items_select">
                                            <span>一级分类</span>
                                            <Select placeholder="请选择一级分类" className="w230" onChange={this.oneClassifyChange} value={addCourseListParams.firstCategoryId} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                <Option  value=''>不限</Option>
                                                {oneClassifyList && oneClassifyList.length ? oneClassifyList.map((item, index) => {
                                                    return <Option key={index} value={item.categoryId}>{item.categoryName}</Option>
                                                }) : ""}
                                            </Select>
                                        </div>
                                        <div className="items_select">
                                            <span>二级分类</span>
                                            <Select placeholder="请选择二级分类" className="w230" onChange={this.twoClassifyChange} value={addCourseListParams.secondCategoryId} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                <Option  value=''>不限</Option>
                                                    {twoClassifyList && twoClassifyList.length ? twoClassifyList.map((item, index) => {
                                                        return <Option key={index}
                                                                       value={item.categoryId}>{item.categoryName}</Option>
                                                    }) : ""}
                                            </Select>
                                        </div>
                                </div>
                                    <div className='l_item'>
                                        <span>共{addCourseList.courseNum}门课程</span>
                                        <Input className='formsearch'  style={{width: "50%"}} value={addCourseListParams.courseName} onChange={this.onHotChange.bind(this)} onPressEnter={()=>this.onSearchChange()} placeholder='请输入课程名称或者课程编号'/>
                                    </div>
                                </div>
                                <Row className="table_h300">
                                    <Col span={24}>
                                        <Table
                                            rowKey={(record) => record.categoryId}
                                            columns={modalColumns}
                                            dataSource={this.state.addCourseList.addCourseList}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={7}>
                            <h2 className='roleModalName' style={{padding:"10px 30px 9px 30px"}}>已添加课程</h2>
                            <div className='memberbox'>
                                <ul className='memberList'>
                                    {
                                        this.state.courseList && this.state.courseList.length ? this.state.courseList.map((item, index) => {
                                            return <li key={index}>
                                                <div className="course_max">
                                                    <span className="module_name">{item.moduleName}</span>
                                                    <span className='membername'>{item.courseName}</span>
                                                    <p className="student_info">
                                                        <span>{item.courseTeacher}</span>
                                                        <span> {item.originName}</span>
                                                    </p>

                                                </div>
                                                <img className='icondel' onClick={() =>this.delModule(index)} src={require('../../../../assets/capacity-auth/icon-del.png')}/>
                                            </li>
                                        }): <div className='data_empty'>
                                            <Empty image={require('@/assets/img/data_empty.png')} description='暂无数据范围' />
                                        </div>
                                    }
                                </ul>
                                <p className='memberNum'>已选<span>{this.state.courseList.length}个</span>课程</p>
                            </div>
                        </Col>
                    </Row>
                </Modal>
                {/*新增课程 弹窗 end*/}
            </div>
        )
    }
}


CourseManage = connect(mapStateToProps, mapDispatchToProps)(CourseManage);
CourseManage = Form.create()(CourseManage)
export default CourseManage;
