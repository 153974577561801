/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect,forwardRef} from 'react';
import { queryProvince } from '../../api/components';
import { Select, Checkbox } from 'antd';
import styles from './index.module.scss';

const RangeCheckedSelect = (props,ref) => {
    const {onChange,value=[]} = props;
    const [list,setList] = useState([]);

    useEffect(()=>{
        const fn = async () => {
            try {
                const res = await queryProvince();
                setList([{provinceCode:0,name:'全国'},...res]);
            } catch (error) {
                console.log(error);
            }
        };
        fn();
    },[]);

    const del = (it) => {
        const codes = [...value];
        list.forEach(its => {
            if(it === its.name){
                const inx = value.indexOf(Number(its.provinceCode));
                codes.splice(inx,1);
            };
        });
        onChange(codes);
    };

    const change = (code) => {
        if(!Array.isArray(value) || value.length === 0){
            onChange([code]);
            return;
        };
        if(code === 0){
            if(value.includes(0)){
                onChange([]);
                return;
            };
            onChange([0]);
            return;
        };
        const codes = [];
        value.forEach(its => {
            if(its === 0 || code === its) return;
            codes.push(its);
        });
        if(!value.includes(code)){
            codes.push(code);
        };
        onChange(codes);
    };

    const getVal = (list,val) => {
        const arr = [];
        list.forEach(it=>{
            if(val.includes(Number(it.provinceCode))){
                arr.push(it.name);
            };
        });
        return arr;
    };

    return (
        <div ref={ref} onMouseDown={e=>{e.preventDefault()}}>
            <Select 
                value={getVal(list,value)}
                style={{width:500}}
                placeholder="请选择所管范围"
                size='large'
                mode="tags"
                showArrow
                onDeselect={del}
                getPopupContainer={triggerNode => triggerNode.parentElement}
                dropdownRender={() => {
                    const remain = list.length%4;
                    return <div className={styles["range_checked_select"]}>
                        {
                            list.map((it,inx) => {
                                return <div 
                                    key={it.provinceCode} 
                                    className={`${styles["select_item"]} ${(inx+remain) < list.length ? styles["select_item_border"] : ''}`} 
                                    onClick={e=>{change(Number(it.provinceCode));e.preventDefault()}}
                                >
                                    <Checkbox checked={Array.isArray(value) && value.includes(Number(it.provinceCode))}>{it.name}</Checkbox>
                                </div>
                            })
                        }
                    </div>
                }}
            />
        </div>
    )
}

export default forwardRef(RangeCheckedSelect);