import React from 'react';
import {Button, Form, Input, Modal, message, Select} from "antd";
import '../index.scss';
import {getEquipmentList, equipmentRegisterSave} from '@/api/practTrain/index.js'

const {Option} = Select;

class AddRegistrationModel extends React.Component {
  state = {
    oneClassifyDetail: {},
    addressVisible: false,
    equipmentList: [],
    detail: {},
    detailNew: {},
    params:{
      equipmentCategoryName:'虚拟设备',
      equipmentSupplier:'中教云',
      equipmentSite:'也未艾',
    }

  }

  componentDidMount() {
    this.getEquipmentList()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      detail: nextProps.detail,
      detailNew: nextProps.detailNew
    })
  }

  async getEquipmentList() {
    const equipmentList = await getEquipmentList()
    this.setState({
      equipmentList
    })
    console.log('sdfd', equipmentList)
  }

  checkCancel = () => {
    this.props.form.resetFields()//清空表单
    this.props.regCancel()
  }
  checkSave = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({saveLoading: true})
        const {detailNew, equipmentList,params} = this.state
        const form = this.props.form.getFieldsValue()
        // const equipment = equipmentList.find(equipment => equipment.type === form.equipmentNameCode)
        let detailResult = {
          ...detailNew,
          ...form,
          ...params,
          // equipmentName: equipment.desc
          equipmentName: form.equipmentNameCode
        }
        equipmentRegisterSave(detailResult).then(res => {
          console.log('dddd', res)
          if (res && res.code === 0) {
            this.mesSuccess("添加成功")
            this.props.form.resetFields()//清空表单
            this.props.regCancel()
            this.props.checkSave()
            this.setState({saveLoading: false})
          } else {
            console.log('dddd222', res.message)
            this.mesError(res.message)
            this.setState({saveLoading: false})
          }
        })
      }
    })

  }
  checkSaveContinue = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({saveLoading: true})
        const {detailNew, equipmentList,params} = this.state
        const form = this.props.form.getFieldsValue()
        // const equipment = equipmentList.find(equipment => equipment.type === form.equipmentNameCode)
        let detailResult = {
          ...detailNew,
          ...form,
          ...params,
          // equipmentName: equipment.desc
          equipmentName: form.equipmentNameCode,
        }
        equipmentRegisterSave(detailResult).then(res => {
          if(res && res.code === 0){
            this.mesSuccess("添加成功")
            this.props.form.resetFields()//清空表单
            this.props.regCancel()
            this.props.checkSaveContinue()
            this.setState({saveLoading: false})
          }else{
            this.mesError(res.message)
            this.setState({saveLoading: false})
          }
        })

      }
    })

  }

  render() {
    const {saveLoading, detail, equipmentList} = this.state;
    const {regVisible} = this.props
    const formItemLayout = {
      labelCol: {span: 5},
      wrapperCol: {span: 16}
    };
    const {getFieldDecorator, getFieldValue} = this.props.form;
    return (
      <div>
        <Modal
          width='660px'
          title=" zSpace 设备注册"
          wrapClassName='practTrain_modal_box'
          visible={regVisible}
          closable={false}
          footer={
            <div className='modal_btn_j'>
              <Button onClick={() => this.checkCancel()}>取消</Button>
              <Button  onClick={() => this.checkSave()}>确认添加</Button>
              <Button  onClick={() => this.checkSaveContinue()}
                      type='primary'>确认添加并继续</Button>
            </div>
          }
        >
          <Form {...formItemLayout} className="practTrain_form">
            <Form.Item label='设备名称' colon={false}>
              {
                getFieldDecorator('equipmentNameCode', {
                  initialValue: detail.equipmentNameCode ? detail.equipmentNameCode : equipmentList[0] ? equipmentList[0].type : "",
                  rules: [
                    {
                      required: false,
                      message: '请选择设备'
                    },
                  ]
                })(
                  <Select placeholder="请选择设备" disabled={true}
                          getPopupContainer={triggerNode => triggerNode.parentNode}>
                    {equipmentList && equipmentList.length ? equipmentList.map((item, index) => {
                      return <Option key={index} value={item.type}>{item.desc}</Option>
                    }) : ""}
                  </Select>
                )
              }
            </Form.Item>
            <Form.Item label='设备主板号' colon={false}>
              {
                getFieldDecorator('equipmentBoard', {
                  initialValue: detail.equipmentBoard,
                  rules: [
                    {
                      required: true,
                      message: '请输入设备主板号'
                    },
                  ]
                })(<Input placeholder='请输入设备主板号' className="sold-box"
                          suffix={<span
                            className="suffixStyle">{getFieldValue('equipmentBoard') ? getFieldValue('equipmentBoard').length : "0"}/30</span>}/>)
              }
            </Form.Item>

          </Form>
        </Modal>
      </div>

    )
  }
}

AddRegistrationModel = Form.create()(AddRegistrationModel)
export default AddRegistrationModel;

