import { post ,get} from '../../until/axios_instans';

//角色列表
export function roleList(data) {
  return post("/api/ucenter/api/v1/role/rolePage", data);
}
//角色状态（检索）
export function roleStatus(data) {
  return post("/api/ucenter/api/v1/role/roleStatus", data);
}
//修改角色
export function updateRole(data) {
  return post("/api/ucenter/api/v1/role/updateRole", data);
}
//分配用户保存
export function assigningUserSave(data) {
  return post("/api/ucenter/api/v1/role/assigningUserSave", data);
}
//删除角色
export function deleteRole(data) {
  return post("/api/ucenter/api/v1/role/deleteRole", data);
}
//启用禁用角色
export function updateRoleStatus(data) {
  return post("/api/ucenter/api/v1/role/updateRoleStatus", data);
}
//添加角色
export function addRole(data) {
  return post("/api/ucenter/api/v1/role/addRole", data);
}
//修改角色
export function editRole(data) {
  return post("/api/ucenter/api/v1/role/editRole", data);
}
//用户列表（分配用户 ——成员搜索 ——现有用户列表）
export function userList(data) {
  return post("/api/ucenter/api/v1/role/userList", data);
}
//现有用户列表（分配用户）
export function existUserList(data) {
  return post("/api/ucenter/api/v1/role/existUserList", data);
}
//菜单权限-系统列表
export function menuSysList() {
  return post("/api/ucenter/api/v1/role/systemList");
}
//菜单权限-菜单列表
export function getMenuList(data) {
  return post("/api/ucenter/api/v1/menu/upMenuList", data);
}
//角色详情
export function roleDetail(data) {
  return post("/api/ucenter/api/v1/role/roleDetail", data);
}
//适用机构
export function organizationList(data) {
  return post("/api/ucenter/api/v1/role/organizationList", data);
}

//根据身份获取角色信息
export function selectRoleList(data) {
  return post("/api/ucenter/api/v1/role/identityRoleList", data);
}
//根据角色获取菜单
export function roleListMenu(data) {
  return post("/api/ucenter/api/v1/menu/role/menu", data);
}

//角色关联app权限
export function relevanceAppRole(data){
  return post("/api/ucenter/api/v1/role/bindRoleMenu", data);
}


//动态设置title  icon
export let schoolInfo = (data) => {
  return get("/api/university/portal/schoolInfo/"+data)
}


//分页查询角色机构数据-lbx
export function roleOrganizationPage(data){
  return post("/api/ucenter/api/v1/role/roleOrganizationPage", data);
}
