


import React from 'react';


//院校空间配置
export default props => {
    return<svg {...props}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.96376 13.1923H3.82933C3.45763 13.1923 3.15625 12.891 3.15625 12.5193C3.15625 12.1476 3.45763 11.8462 3.82933 11.8462H6.96376C7.33546 11.8462 7.63684 12.1476 7.63684 12.5193C7.63684 12.891 7.33551 13.1923 6.96376 13.1923Z" fill="#6A7E9C"/>
        <path d="M12.533 13.17H9.61408C9.00411 13.17 8.50781 12.6738 8.50781 12.0638V6.63405C8.50781 6.02408 9.00411 5.52783 9.61408 5.52783H12.533C13.143 5.52783 13.6393 6.02408 13.6393 6.63405V12.0638C13.6392 12.6738 13.143 13.17 12.533 13.17ZM9.85396 11.8238H12.2931V6.87399H9.85396V11.8238Z" fill="#BABABA"/>
        <path d="M6.96295 13.0341C6.5912 13.0341 6.28987 12.7327 6.28987 12.361V10.6633L1.96571 10.7018H1.95973C1.16776 10.7018 0.523438 10.0575 0.523438 9.2655V2.39381C0.523438 1.60184 1.16776 0.95752 1.95973 0.95752H11.9662C12.7581 0.95752 13.4025 1.60184 13.4025 2.39381V4.20482C13.4025 4.57657 13.1011 4.8779 12.7294 4.8779C12.3577 4.8779 12.0563 4.57657 12.0563 4.20482V2.39381C12.0563 2.34578 12.0142 2.30367 11.9662 2.30367H1.95973C1.9117 2.30367 1.86959 2.34578 1.86959 2.39381V9.26544C1.86959 9.31299 1.91089 9.35477 1.95827 9.35563L6.95697 9.3111L6.96295 9.31105C7.14145 9.31109 7.31262 9.38202 7.43884 9.50824C7.56505 9.63445 7.63598 9.80563 7.63603 9.98413V12.361C7.63603 12.7327 7.3347 13.0341 6.96295 13.0341Z" fill="#6A7E9C"/>
    </svg>


}