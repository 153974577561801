import React, { Component } from "react";

import "./index.scss";
export default class BreadcrumbShixun extends Component {
  render() {
    const {
      span_1 = "院校空间配置",
      span_1_c = "#333",
      span_2 = "",
      span_2_c = "#333",
      span_3 = "",
      span_3_c = "#888",
      span_4 = "",
      span_4_c = "#888",
      container = "首页板块设置",
      container_fs = 22,
      container_col = "#333",
      con = "",
      span_1_fun,
      span_2_fun,
      span_3_fun,
      span_4_fun,
    } = this.props;
    return (
        <div className="BreadcrumbPeixun_top_title_wrap">
          <div className="BreadcrumbShixun_top_title_container">
            <p>
            <span onClick={span_1_fun} style={{ color: span_1_c }}>
              {span_1}
            </span>
              <span
                  onClick={span_2_fun}
                  style={{ display: span_2 ? "inline" : "none", color: span_2_c }}
              >
              {span_2}
            </span>
              <span
                  onClick={span_3_fun}
                  style={{ display: span_3 ? "inline" : "none", color: span_3_c }}
                  className = {span_3_fun?'span_3_hover':''}
              >
              {span_3}
            </span>
              <span
                  onClick={span_4_fun}
                  style={{ display: span_4 ? "inline" : "none", color: span_4_c }}
              >
              {span_4}
            </span>
            </p>
            <h3 style={{ fontSize: container_fs, color: container_col }}>
              <span> {container}</span>
              {con && (
                  <p>
                    <i></i>
                    <span>{con}</span>
                  </p>
              )}
            </h3>
          </div>
        </div>
    );
  }
}
